import { combineReducers } from 'redux';

import {
  createAgeByIdReducer,
  createSequenceCountingByIdReducer,
  createSequenceHandlingReducer,
  serializeReducers,
} from 'state/common/reducerFactories';
import { PublisherMap } from 'state/publishers/publishersState';
import * as snapAdminActions from 'state/snapAdmin/actions/snapAdminActions';

import * as publishersActions from '../actions/publishersActions';

import { EMPTY_OBJECT, Sequence } from 'config/constants';
import u from 'utils/safeUpdeep';

import { COPY_STORY } from 'types/actions/editionsActionTypes';

const updateDetails = createSequenceHandlingReducer(
  {},
  [
    publishersActions.UPDATE_PUBLISHER_DETAILS,
    snapAdminActions.SAVE_ONE_PUBLISHER_SETTING_DATA,
    publishersActions.UPDATE_HOST_ACCOUNT,
  ],
  {
    start: (state: any, action: any) => {
      const publisherId = action.publisherId || action?.params?.publisherId;

      const update = {};
      if (!(publisherId in state)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        update[publisherId] = {
          data: null,
          error: null,
          loading: 1,
          age: 0,
        };
      } else {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        update[publisherId] = {
          loading: state[publisherId].loading + 1,
        };
      }
      return u(update, state);
    },
    done: (state: any, action: any) => {
      const publisherId = action.publisherId || action?.params?.publisherId;

      // Enabled features are fetched seperately, in order for update not to override them they need to be deleted.
      const payload = action.payload || EMPTY_OBJECT;
      delete payload.enabledFeatures;

      return u(
        {
          [publisherId]: {
            data: payload,
            error: action.error,
            loading: state[publisherId].loading - 1,
            age: action.meta.timestamp,
          },
        },
        state
      );
    },
  }
);

const graphQLUpdateDetails = (state = {}, action: any) => {
  const publisherId = action.publisherId || action?.params?.publisherId;
  if (
    action.type === publishersActions.SET_PUBLISHER_DETAILS_GRAPHQL ||
    action.type === publishersActions.SET_PUBLISHER_DETAILS
  ) {
    return u(
      {
        [publisherId]: {
          data: action.payload,
        },
      },
      state
    );
  }

  return state;
};

const detailsById = serializeReducers([updateDetails, graphQLUpdateDetails]);

const editionIdMapper = {
  start(state: any, action: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    return u(
      {
        [action.params.publisherId]: u.constant(action.payload.result),
      },
      state
    );
  },
};

const handleGetActiveEditions = createSequenceHandlingReducer(
  {},
  publishersActions.GET_ACTIVE_EDITIONS,
  editionIdMapper
);

const activeEditionsById = (state = {}, action: any) => {
  if (action.type === COPY_STORY && action.sequence === Sequence.DONE) {
    if (action.error) {
      return state;
    }
    return u(
      {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        [action.params.publisherId]: (previousState = []) => previousState.concat([action.payload.result]),
      },
      state
    );
  }
  return handleGetActiveEditions(state, action);
};

const liveEditionsById = createSequenceHandlingReducer({}, [publishersActions.GET_LIVE_EDITIONS], editionIdMapper);

const hasAvailableStoriesById = createSequenceHandlingReducer(
  {},
  [publishersActions.GET_IF_PUBLISHER_HAS_AVAILABLE_STORIES],
  {
    start(state: PublisherMap<boolean>, action: any) {
      return state;
    },
    done(state: PublisherMap<boolean>, action: any) {
      if (action.error) {
        return state;
      }
      const { result } = action.payload;
      return u(
        {
          [action.params.publisherId]: result?.length > 0,
        },
        state
      );
    },
  }
);

const unavailableEditionsById = createSequenceHandlingReducer(
  {},
  [publishersActions.GET_UNAVAILABLE_EDITIONS],
  editionIdMapper
);

const loadingActiveEditionsById = createSequenceCountingByIdReducer(
  'publisherId',
  publishersActions.GET_ACTIVE_EDITIONS
);

const loadingUnavailableEditionsById = createSequenceCountingByIdReducer(
  'publisherId',
  publishersActions.GET_UNAVAILABLE_EDITIONS
);

const lastUpdatedActiveEditionsById = createAgeByIdReducer('publisherId', publishersActions.GET_ACTIVE_EDITIONS);

const lastUpdatedUnavailableEditionById = createAgeByIdReducer(
  'publisherId',
  publishersActions.GET_UNAVAILABLE_EDITIONS
);

// ------------------------------------
// Reducer
// ------------------------------------
export default combineReducers({
  detailsById,
  activeEditionsById,
  unavailableEditionsById,
  liveEditionsById,
  hasAvailableStoriesById,
  loadingActiveEditionsById,
  loadingUnavailableEditionsById,
  lastUpdatedActiveEditionsById,
  lastUpdatedUnavailableEditionById,
});
