// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'deno... Remove this comment to see the full error message
import { denormalize } from 'denormalizr';
import { groupBy, map, get, some, flatMap, compact, flatten } from 'lodash';
import { createSelector as s } from 'reselect';
import { createObjectSelector } from 'reselect-map';

import * as autocompleteSelectors from 'state/autocomplete/selectors/autocompleteSelectors';
import { createKeySelector, createDynamicKeySelector, reselectById } from 'state/common/selectorFactories';
import { richSnapSchema, discoverSnapSchema } from 'state/snaps/schema/snapsSchema';
import * as tilesSelectors from 'state/tiles/selectors/tilesSelectors';
import * as transactionsActions from 'state/transactions/actions/transactionsActions';

import { normalizeDecoratedSnaps } from '../actions/snapNormalization';
import { getNextBottomSnap, isTopSnap } from '../schema/snapEntityHelpers';

import { SnapTag, EMPTY_ARRAY } from 'config/constants';
import { State } from 'src/types/rootState';
import { functionRef } from 'utils/functionUtils';

import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';
import type { NormalizedSnap, Snap, BottomSnap } from 'types/snaps';
import { SnapRelationship } from 'types/snaps';
import type { AggregatedSnapTag } from 'types/tags';
import type { Tile } from 'types/tiles';

function getSnapData(state: any) {
  return state.snaps || {};
}
export const getSnapMap = createKeySelector(getSnapData, 'byId', {});
export const getLoadingMap = createKeySelector(getSnapData, 'loadingById', {});
export const getSavingMap = createKeySelector(getSnapData, 'savingById', {});
export const getPendingSaveMap = createKeySelector(getSnapData, 'pendingBuildStatusFetchById', {});
export const getErrorMap = createKeySelector(getSnapData, 'errorById', {});
export const getLastUpdatedMap = createKeySelector(getSnapData, 'lastUpdatedById', {});
export const isLoadingByIds = s(getLoadingMap, loadingMap => (snapIds: any) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  some(snapIds, snapId => Boolean(loadingMap[snapId]))
);
export const isSavingByIds = s(getSavingMap, savingMap => (snapIds: any) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  some(snapIds, snapId => Boolean(savingMap[snapId]))
);
export const pendingBuildStatusFetchByIds = s(getPendingSaveMap, pendingSaveMap => (snapIds: any) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  some(snapIds, snapId => Boolean(pendingSaveMap[snapId]))
);
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
export const isLoadingById = s(getLoadingMap, loadingMap => (snapId: any) => Boolean(loadingMap[snapId]));
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
export const isSavingById = s(getSavingMap, savingMap => (snapId: any) => Boolean(savingMap[snapId]));
// @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
export const creativeIdBySnapId = s(getSnapMap, snapMap => (snapId: any) => get(snapMap[snapId], 'creativeId'));
export const getErrorById = createDynamicKeySelector<any, string>(getErrorMap, null);
export const getLastUpdatedById = createDynamicKeySelector<any, string>(getLastUpdatedMap, null);
// Returns the snap as is with the ids as replaced by normalizr
export const getNormalizedSnapById = createDynamicKeySelector<NormalizedSnap | undefined | null, SnapId>(
  getSnapMap,
  null
);
export const getAllSnaps = createObjectSelector(
  getSnapMap, // createObjectSelector iterates through this object values
  getSnapMap,
  tilesSelectors.getTileMap,
  (snap, snapMap, tileMap) => {
    const denormalizedSnap = denormalize(
      snap,
      {
        richSnap: snapMap,
      },
      richSnapSchema
    );
    // TODO Provide a hook for snap decorators to add denormalizing
    //      selectors, rather than hardcoding this here.
    const decoration = (snap as any).decorations && (snap as any).decorations.discover;
    if (decoration) {
      denormalizedSnap.decorations.discover = denormalize(
        decoration,
        {
          tile: tileMap,
        },
        discoverSnapSchema
      );
    }
    return denormalizedSnap;
  }
);
// Returns the unnormalized snap by replacing the relatedSnap ids with
// the entities that were extracted by Normalizr when the snap was loaded
export const getSnapById = reselectById<Snap | null, SnapId>(
  null,
  getAllSnaps,
  (state: any, snapId: any) => snapId,
  (allSnaps: any, snapId: any): Snap => {
    return allSnaps[snapId] || null;
  }
);
export const getVideoAssetIdBySnapId = reselectById<AssetID | undefined | null, SnapId>(
  null,
  (state: any, snapId: any) => getSnapById(state)(snapId),
  (snap?: Snap | null) => get(snap, 'videoAssetId') || get(snap, 'longformVideoAssetId')
);
export const getTagsBySnapId = reselectById<AggregatedSnapTag[] | undefined | null, SnapId>(
  null,
  (state: any, snapId: any) => getSnapById(state)(snapId),
  functionRef(autocompleteSelectors, 'getSccCodeToTextMapping'),
  (snap: any, sccTagsMap: any): AggregatedSnapTag[] => {
    if (!snap) {
      return [];
    }
    const decoration = snap.decorations && snap.decorations.discover;
    if (!decoration) {
      return [];
    }
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; value: unknown; }[]' is not ... Remove this comment to see the full error message
    return flatMap(decoration.tags, (decorationTags, type) => {
      const mappedTags =
        type === SnapTag.SCC ? decorationTags.map((sccTag: any) => sccTagsMap[sccTag]) : decorationTags;
      // Remove possible empty tags
      const compactedTags = compact(mappedTags);
      return compactedTags.map(cTag => ({
        type,
        value: cTag,
      }));
    });
  }
);
export const getAggregatedTagsBySnapIds = s(
  (state: State) => getTagsBySnapId(state),
  getTagsBySnapIdFn => (snapIds: any): AggregatedSnapTag[] => {
    if (!snapIds) {
      return [];
    }
    const tags = flatten(snapIds.map((snapId: any) => getTagsBySnapIdFn(snapId)));
    const groupedTags = groupBy(tags, 'value');
    return map(groupedTags, items => ({
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...items[0],
      usage: items.length,
    }));
  }
);
export const getSetOfSnapsContainsAtLeastOneSCCTagBySnapIds = s(
  (state: State) => getTagsBySnapId(state),
  getTagsBySnapIdFn => (snapIds: any): boolean => {
    if (!snapIds) {
      return false;
    }
    const tags = flatten(snapIds.map((snapId: any) => getTagsBySnapIdFn(snapId)));
    return tags.filter(tag => (tag as any).type === SnapTag.SCC).length >= 1;
  }
);
transactionsActions.registerEntityTransaction(richSnapSchema.getKey(), getSnapById, normalizeDecoratedSnaps);
export const getTilesByWholeSnapId = reselectById<Tile[] | undefined | null, SnapId>(
  null,
  (state: any, snapId: any) => getSnapById(state)(snapId),
  (snap: any) => {
    const tiles = get(snap, ['decorations', 'discover', 'tiles']);
    return tiles ? normalizeEmpty(tiles) : null;
  }
);
export const getBottomSnapByWholeSnapId = reselectById<BottomSnap | undefined | null, SnapId>(
  null,
  (state: any, snapId: any) => getSnapById(state)(snapId),
  (snap: any) => {
    return getNextBottomSnap(snap) || null;
  }
);
// TODO: move to a common module
function normalizeEmpty(array: any) {
  return array && !array.length ? EMPTY_ARRAY : array;
}
export const getTopSnapId = reselectById<SnapId | undefined | null, SnapId>(
  null,
  (state: any, snapId: any) => getSnapById(state)(snapId),
  (state: any, snapId: any) => snapId,
  (snap: any, snapId: any) => {
    if (!snap || isTopSnap(snap)) {
      return snapId;
    }
    return get(snap, ['relatedSnapIds', SnapRelationship.TOP]);
  }
);
