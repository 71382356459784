import { combineReducers } from 'redux';

import {
  createEntityReducer,
  serializeReducers,
  createSequenceHandlingReducer,
  createRemoverByIdReducer,
  createClearingReducer,
  createSequenceCountingReducer,
} from 'state/common/reducerFactories';
import * as pubUserManagementActions from 'state/publisherUserManagement/actions/publisherUserManagementActions';

// TODO victor maybe should ignore content_sharing property update for user, especially create a new user with same email
const updatePublisherUsers = createEntityReducer('user', [
  pubUserManagementActions.GET_PUBLISHER_USERS,
  pubUserManagementActions.EDIT_PUBLISHER_USER,
]);

const removePublisherUserById = createSequenceHandlingReducer({}, pubUserManagementActions.DELETE_PUBLISHER_USER, {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    return createRemoverByIdReducer('userId', pubUserManagementActions.DELETE_PUBLISHER_USER)(state, action);
  },
});

const clearPublisherUsers = createClearingReducer({}, pubUserManagementActions.CLEAR_PUBLISHER_USERS);

const publisherUsersByUserId = serializeReducers([updatePublisherUsers, removePublisherUserById, clearPublisherUsers]);

const updateUserWhoNeedsMoreInfo = createSequenceHandlingReducer(
  null,
  pubUserManagementActions.ADD_EXISTING_USER_TO_PUBLISHER_BY_SNAP_USERNAME,
  {
    start(state: any) {
      return state;
    },
    done(state: any, action: any) {
      if (!action.payload) {
        return action.params.snapUsername;
      }
      return state;
    },
  }
);

const clearUserWhoNeedsMoreInfo = createClearingReducer(null, pubUserManagementActions.CLEAR_USER_WHO_NEED_MORE_INFO);

const userWhoNeedsMoreInfoToBeCreated = serializeReducers([updateUserWhoNeedsMoreInfo, clearUserWhoNeedsMoreInfo]);

export default combineReducers({
  publisherUsersLoading: createSequenceCountingReducer(pubUserManagementActions.GET_PUBLISHER_USERS),
  publisherUsersByUserId,
  userWhoNeedsMoreInfoToBeCreated,
  savingUser: createSequenceCountingReducer([
    pubUserManagementActions.ADD_EXISTING_USER_TO_PUBLISHER_BY_SNAP_USERNAME,
    pubUserManagementActions.CREATE_NEW_USER_BY_SNAP_USERNAME,
    pubUserManagementActions.CREATE_NEW_USER_BY_EMAIL,
    pubUserManagementActions.EDIT_PUBLISHER_USER,
  ]),
});
