import invariant from 'invariant';

import { DeeplinkType, STORIES_EVERYWHERE_BASE_URL } from 'config/constants';
import type { DeeplinkTypeEnum } from 'config/constants';
import * as deeplinkAPI from 'utils/apis/deeplinkAPI';
import * as discoverAPI from 'utils/apis/discoverAPI';
import * as scsAPI from 'utils/apis/scsAPI';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';
import type { Snapcode, SnapcodeEntity } from 'types/snapcodes';

const SNAPCODE_BASE_URL = 'https://www.snapchat.com/deeplink/discover/snapcode/';

export function base64encode(input: string) {
  return btoa(input);
}

function encodeQueryParam(val: string) {
  return encodeURIComponent(val).replace('%20', '+');
}

export function getSnapcodeImageUrl(snapcode: Snapcode) {
  const deeplinkUrl = snapcode.fullDeepLinkUrl
    .replace(/^https:\/\/.*\.snapchat\.com/, '')
    .replace(/^https:\/\/.*\.snap-dev\.net/, '');
  // TODO: sthorsen handle invalid deeplinks?
  const encodedDeeplink = base64encode(deeplinkUrl);

  // When a snapcode is updated, its image URL doesn't change. Hence, we need a cache-buster to
  // force the browser to show the updated image.
  return `${SNAPCODE_BASE_URL}${encodedDeeplink}?cb=${encodeQueryParam(snapcode.cb)}`;
}

export function getActionUrl(publisherName: string, editionId: EditionID, businessProfileId: string): string {
  return deeplinkAPI.edition({
    publisher: publisherName,
    edition_id: -editionId,
    profileId: businessProfileId,
  });
}

export const createSnapcodeFromSnapcodeEntity = (
  linkableType: DeeplinkTypeEnum,
  linkableId: SnapId | PublisherID | EditionID
) => (snapcode: SnapcodeEntity): Snapcode => {
  // Publisher deeplinks are still handled by discover-service
  const downloadLinkUrl =
    linkableType === DeeplinkType.PUBLISHER
      ? discoverAPI.snapcode.download({ linkableType, linkableId, snapcodeId: snapcode.id })
      : scsAPI.snapcode.download({ linkableType, linkableId, snapcodeId: snapcode.id });

  invariant(snapcode.cb, 'snapcode.cb');

  // Support for new style deeplink urls that are relative to story.snapchat.com
  const fullDeepLinkUrl = snapcode.deepLinkUrl.startsWith('/p/')
    ? `${STORIES_EVERYWHERE_BASE_URL.replace(/\/$/, '')}${snapcode.deepLinkUrl}`
    : `https://www.snapchat.com${snapcode.deepLinkUrl}`;

  return {
    id: snapcode.id,
    name: snapcode.name,
    shareableHeadline: snapcode.shareableHeadline,
    fullDeepLinkUrl,
    downloadLinkUrl,
    cb: snapcode.cb,
  };
};
