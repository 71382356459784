import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

export const Audience = enumObject({
  ANYONE: 'ANYONE',
  NOBODY_UNDER_18: 'NOBODY_UNDER_18',
  NOBODY_IN_SENSITIVE_COUNTRIES: 'NOBODY_IN_SENSITIVE_COUNTRIES',
  SUBSCRIBERS_ONLY: 'SUBSCRIBERS_ONLY',
});

export type AudienceEnum = Enum<typeof Audience>;

export const StoryModerationStatus = enumObject({
  NONE: 'NONE', // For legacy editions before moderation was enabled
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
});

export type StoryModerationStatusEnum = Enum<typeof StoryModerationStatus>;

export const StoryModerationScope = enumObject({
  UNSET: 'UNSET',
  WHOLE_STORY: 'WHOLE_STORY',
  TILES_ONLY: 'TILES_ONLY',
});

export type StoryModerationScopeEnum = Enum<typeof StoryModerationScope>;

// https://github.sc-corp.net/Snapchat/content-platform/blob/main/discover-service/media-types/src/main/java/snapchat/discover/mediatypes/ModerationViolation.java
// https://livegrep.sc-corp.net/view/Snapchat/search/ranking/indexing/proto/snap_mod_features.proto#L146
export const ModerationViolation = enumObject({
  UNKNOWN: 'UNKNOWN',
  SEXUAL_IMAGERY: 'SEXUAL_IMAGERY',
  SEXUAL_CAPTION: 'SEXUAL_CAPTION',
  SEXUAL_SOLICITATION: 'SEXUAL_SOLICITATION',
  SEX_ACTS: 'SEX_ACTS',
  PROFANITY: 'PROFANITY',
  HARASSMENT: 'HARASSMENT',
  HATE_SPEECH: 'HATE_SPEECH',
  PRIVACY_INVASION: 'PRIVACY_INVASION',
  THREATS: 'THREATS',
  HUMAN_VIOLENCE: 'HUMAN_VIOLENCE',
  ANIMAL_VIOLENCE: 'ANIMAL_VIOLENCE',
  WEAPONS: 'WEAPONS',
  MARIJUANA: 'MARIJUANA',
  TOBACCO: 'TOBACCO',
  ALCOHOL: 'ALCOHOL',
  DRUGS: 'DRUGS',
  SELF_HARM_SUICIDE: 'SELF_HARM_SUICIDE',
  TERRORIST: 'TERRORIST',
  COMMERCIAL_SPAM: 'COMMERCIAL_SPAM',
  BODILY_FLUIDS: 'BODILY_FLUIDS',
  DANGEROUS_DRIVING: 'DANGEROUS_DRIVING',
  UNSAFE_URL: 'UNSAFE_URL',
  ABUSIVE_LANGUAGE: 'ABUSIVE_LANGUAGE',
  RACY: 'RACY',
  MEME: 'MEME',
  INTELLECTUAL_PROPERTY: 'INTELLECTUAL_PROPERTY',
  CLICKBAIT: 'CLICKBAIT',
  BODY_IMAGERY: 'BODY_IMAGERY',
  RELATIONSHIPS_INTIMACY: 'RELATIONSHIPS_INTIMACY',
  SEXUALIZED_OBJECTS: 'SEXUALIZED_OBJECTS',
  HUMAN_OR_ANIMAL_VIOLENCE: 'HUMAN_OR_ANIMAL_VIOLENCE',
  // additional reasons from snap mod features: // https://livegrep.sc-corp.net/view/Snapchat/search/ranking/indexing/proto/snap_mod_features.proto#L146
  ADULT_CONTENT: 'ADULT_CONTENT',
  COMMERCIAL_PROMOTION_AND_SOLICITATION: 'COMMERCIAL_PROMOTION_AND_SOLICITATION',
  CREATIVE_TOOLS_AND_QUALITY: 'CREATIVE_TOOLS_AND_QUALITY',
  CSAM: 'CSAM',
  DANGEROUS_OR_HARMFUL_ACTIVITIES: 'DANGEROUS_OR_HARMFUL_ACTIVITIES',
  DECEPTIVE_CONTENT_OR_FALSE_INFORMATION: 'DECEPTIVE_CONTENT_OR_FALSE_INFORMATION',
  INVASION_OF_PRIVACY: 'INVASION_OF_PRIVACY',
  NEWS_AND_POLITICAL_COMMENTARY: 'NEWS_AND_POLITICAL_COMMENTARY',
  REGULATED_GOODS: 'REGULATED_GOODS',
  SELF_HARM: 'SELF_HARM',
  SEXUAL_SERVICES: 'SEXUAL_SERVICES',
  SPAM: 'SPAM',
  TERRORISM_OR_EXTREMISM: 'TERRORISM_OR_EXTREMISM',
  VIOLENCE: 'VIOLENCE',
});

export type ModerationViolationEnum = Enum<typeof ModerationViolation>;

export enum TileViolationLevel {
  NONE = 'NONE',
  SUBSCRIBER_ONLY = 'SUBSCRIBER_ONLY',
  HIGH = 'HIGH',
  MODERATE = 'MODERATE',
}

export enum ModerationFilterEnum {
  // Include all moderation outcomes.
  DEFAULT = 'DEFAULT',
  // Content is found to contain sensitive content or in violation of content guidelines.
  // Distribution is limited (not selected for feed etc.) and cannot reach a wider audience.
  LIMITED_DISTRIBUTION = 'LIMITED_DISTRIBUTION',
  // Content is live and is able to reach a wider audience.
  PUBLIC_VIEWABLE_CONTENT = 'PUBLIC_VIEWABLE_CONTENT',
}

// https://livegrep.sc-corp.net/view/Snapchat/pb_schema/proto/impala/common/moderation_status.proto#L49
export enum ModerationSummaryEnum {
  // Content is submitted and in moderation queue. Pending human/auto moderation processing.
  SUBMITTED = 'SUBMITTED',
  // Content is found to contain sensitive content or in violation of content guidelines.
  // Distribution is limited (not selected for feed etc.) and cannot reach a wider audience.
  LIMITED_DISTRIBUTION = 'LIMITED_DISTRIBUTION',
  // Content is live and is able to reach a wider audience.
  LIVE = 'LIVE',
}
