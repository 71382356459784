import { SnapType } from 'types/snaps';
import type { VideoSnap } from 'types/snaps';
import type { Stage } from 'types/stages';

type VideoStage = Stage<VideoSnap, Partial<VideoSnap>, void>;

export const VIDEO_STAGE_CONFIG: VideoStage = {
  stageName: SnapType.VIDEO,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: ['headline', 'callToAction', 'lens'],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => properties,
};
