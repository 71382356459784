export const ErrorContexts = {
  ADD_NEW_USER: 'ADD_NEW_USER',
  ADD_NEW_SNAP_TO_EDITION: 'ADD_NEW_SNAP_TO_EDITION',
  CREATE_NEW_PUBLISHER_INVALID_DETAILS: 'CREATE_NEW_PUBLISHER_INVALID_DETAILS',
  CREATE_NEW_PUBLISHER_INVALID_NAME: 'CREATE_NEW_PUBLISHER_INVALID_NAME',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
  GET_EDITION: 'GET_EDITION',
  GET_EDITION_SNAP_IDS: 'GET_EDITION_SNAP_IDS',
  SAVE_EDITION: 'SAVE_EDITION',
  DELETE_SNAP_FROM_EDITION: 'DELETE_SNAP_FROM_EDITION',
  LOAD_ACTIVE_EDITIONS_FOR_PUBLISHER: 'LOAD_ACTIVE_EDITIONS_FOR_PUBLISHER',
  COPY_SNAP_TO_EDITION: 'COPY_SNAP_TO_EDITION',
  SAVE_SNAP: 'SAVE_SNAP',
  LOAD_SNAP: 'LOAD_SNAP',
  ENABLE_LIVE_EDITION_LOCK: 'ENABLE_LIVE_EDITION_LOCK',
  DISABLE_LIVE_EDITION_LOCK: 'DISABLE_LIVE_EDITION_LOCK',
  TAKE_DOWN_LIVE_EDITION: 'TAKE_DOWN_LIVE_EDITION',
  LOAD_SNAPS: 'LOAD_SNAPS',
  LOAD_STORY_SNAPCODES: 'LOAD_STORY_SNAPCODES',
  LOAD_SNAP_SNAPCODES: 'LOAD_SNAP_SNAPCODES',
  LOAD_PUBLISHER_SNAPCODES: 'LOAD_PUBLISHER_SNAPCODES',
  UPDATE_STORY_SNAPCODE: 'UPDATE_STORY_SNAPCODE',
  UPDATE_SNAP_SNAPCODE: 'UPDATE_SNAP_SNAPCODE',
  CREATE_STORY_SNAPCODE: 'CREATE_STORY_SNAPCODE',
  CREATE_SNAP_SNAPCODE: 'CREATE_SNAP_SNAPCODE',
  CREATE_PUBLISHER_SNAPCODE: 'CREATE_PUBLISHER_SNAPCODE',
  ARCHIVE_EDITION: 'ARCHIVE_EDITION',
  DELETE_EDITION: 'DELETE_EDITION',
  HIDE_EDITION: 'HIDE_EDITION',
  VALIDATE_SNAP_UPLOAD: 'VALIDATE_SNAP_UPLOAD',
  VALIDATE_MEDIA: 'VALIDATE_MEDIA',
  UPLOAD_MEDIA: 'UPLOAD_MEDIA',
  UPDATE_USER: 'UPDATE_USER',
  DELETE_SUBTITLES: 'DELETE_SUBTITLES',
  GENERATE_SUBTITLES: 'GENERATE_SUBTITLES',
  DELETE_VIDEO: 'DELETE_VIDEO',
  COPY_STORY: 'COPY_STORY',
  EXPORT_TO_AD_MANAGER: 'EXPORT_TO_AD_MANAGER',
  SAVE_ONE_PUBLISHER_DATA: 'SAVE_ONE_PUBLISHER_DATA',
  GET_HOMEPAGE_DATA: 'GET_HOMEPAGE_DATA',
  SET_EDITION_STATE: 'SET_EDITION_STATE',
  SET_EDITION_STATE_INVALID_DATE: 'SET_EDITION_STATE_INVALID_DATE',
  CREATE_STORY: 'CREATE_STORY',
  UPDATE_TILE_LOGO: 'UPDATE_TILE_LOGO',
  SAVE_TILE_LOGO: 'SAVE_TILE_LOGO',
  DELETE_TILE_LOGO: 'DELETE_TILE_LOGO',
  ARTICLE_INGESTION: 'ARTICLE_INGESTION',
  IMPORT_TILE_FROM_TOPSNAP: 'IMPORT_TILE_FROM_TOPSNAP',
  UNKNOWN: 'UNKNOWN',
  UPLOAD_PUBLISHER_ICON: 'UPLOAD_PUBLISHER_ICON',
  UPLOAD_TILE_ICON: 'UPLOAD_TILE_ICON',
  UPDATE_PUBLISHER_DETAILS: 'UPDATE_PUBLISHER_DETAILS',
  SUPPORT_RAISE_TICKET: 'SUPPORT_RAISE_TICKET',
  UPDATE_SEASONS: 'UPDATE_SEASONS',
  CREATE_EPISODE: 'CREATE_EPISODE',
  UPDATE_EPISODE: 'UPDATE_EPISODE',
  TOO_MANY_TERM_FILTERS: 'TOO_MANY_TERM_FILTERS',
  MAKE_ALL_STORIES_UNAVAILABLE: 'MAKE_ALL_STORIES_UNAVAILABLE',
  ASSOCIATE_HOST_USER: 'ASSOCIATE_HOST_USER',
  LOAD_ORG_ONBOARDINGS_LIST: 'LOAD_ORG_ONBOARDINGS_LIST',
  CREATE_ORG_ONBOARDING: 'CREATE_ORG_ONBOARDING',
  DELETE_ORG_ONBOARDING: 'DELETE_ORG_ONBOARDING',
  CREATE_FEED: 'CREATE_FEED',
  UPDATE_FEED: 'UPDATE_FEED',
  REVENUE: 'REVENUE',
};

export type ErrorContextEnum = typeof ErrorContexts[keyof typeof ErrorContexts];
