import { arrayOf } from 'normalizr';

import { userSchema } from 'state/snapAdmin/schema/snapAdminSchema';
import { User, UserId } from 'state/user/userState';

import { CALL_API } from 'redux/middleware/apiMiddleware';
import { preprocessAndFinalize } from 'redux/middleware/requestProcessing';
import * as discoverAPI from 'utils/apis/discoverAPI';
import { assertArg } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { getRolesForSaving } from 'utils/userManagementUtils';

import { BusinessProfileID, PublisherID } from 'types/publishers';
import { GetState } from 'types/redux';

export const GET_PUBLISHER_USERS = 'pubUserMng/GET_PUBLISHER_USERS';
export const CREATE_NEW_USER_BY_EMAIL = 'pubUserMng/CREATE_NEW_USER_BY_EMAIL';
export const CREATE_NEW_USER_BY_SNAP_USERNAME = 'pubUserMng/CREATE_NEW_USER_BY_SNAP_USERNAME';
export const ADD_EXISTING_USER_TO_PUBLISHER_BY_SNAP_USERNAME =
  'pubUserMng/ADD_EXISTING_USER_TO_PUBLISHER_BY_SNAP_USERNAME';
export const CLEAR_USER_WHO_NEED_MORE_INFO = 'pubUserMng/CLEAR_USER_WHO_NEED_MORE_INFO';
export const EDIT_PUBLISHER_USER = 'pubUserMng/EDIT_PUBLISHER_USER';
export const DELETE_PUBLISHER_USER = 'pubUserMng/DELETE_PUBLISHER_USER';
export const CLEAR_PUBLISHER_USERS = 'pubUserMng/CLEAR_PUBLISHER_USERS';

const getPublisherUsers = (publisherId: PublisherID) => {
  return {
    type: GET_PUBLISHER_USERS,
    meta: {
      [CALL_API]: {
        endpoint: discoverAPI.users.publisherUsers({ publisherId }),
      },
      schema: arrayOf(userSchema),
    },
  };
};

const clearPublisherUsers = () => {
  return {
    type: CLEAR_PUBLISHER_USERS,
  };
};

const editUser = ({ businessProfileId, user }: { businessProfileId: BusinessProfileID; user: User }) => {
  return (dispatch: any) =>
    dispatch({
      type: EDIT_PUBLISHER_USER,
      payload: user,
      meta: {
        [CALL_API]: {
          method: 'post',
          endpoint: discoverAPI.users.editUser({ businessProfileId, userId: user.id }),
          body: {
            snapUsername: user.snapUsername,
            username: user.username,
            email: user.email,
            resourceRoles: getRolesForSaving(user.resourceRoles),
          },
        },
        schema: userSchema,
      },
    }).catch(apiErrorHandler(dispatch, ErrorContexts.UPDATE_USER));
};

const deletePublisherUser = (publisherId: PublisherID, userId: UserId) => {
  return {
    type: DELETE_PUBLISHER_USER,
    params: { userId },
    meta: {
      [CALL_API]: {
        method: 'delete',
        endpoint: discoverAPI.users.deletePublisherUser({ publisherId, userId }),
      },
    },
  };
};

const createNewPublisherUserWithEmail = (
  publisherId: PublisherID,
  { username, email }: { username: string; email: string }
) => {
  return {
    type: CREATE_NEW_USER_BY_EMAIL,
    meta: {
      [CALL_API]: {
        method: 'post',
        endpoint: discoverAPI.users.createPublisherUserWithEmail({ publisherId }),
        body: { username, email },
        ...preprocessAndFinalize,
      },
    },
  };
};

const createNewPublisherUserWithSnapUsername = (
  publisherId: PublisherID,
  { snapUsername, username, email }: { snapUsername: string; username: string; email: string }
) => {
  return {
    type: CREATE_NEW_USER_BY_SNAP_USERNAME,
    meta: {
      [CALL_API]: {
        method: 'post',
        endpoint: discoverAPI.users.createPublisherUserWithSnapUsername({ publisherId }),
        body: { snapUsername, username, email },
      },
    },
  };
};

const addExistingUserToPublisherBySnapUsername = (publisherId: PublisherID, snapUsername: string) => {
  return {
    type: ADD_EXISTING_USER_TO_PUBLISHER_BY_SNAP_USERNAME,
    params: { publisherId, snapUsername },
    meta: {
      [CALL_API]: {
        method: 'put',
        endpoint: discoverAPI.users.addExistingUserToPublisher({ publisherId }),
        body: { snapUsername },
      },
    },
  };
};

const clearUserWhoNeedsMoreInfo = () => {
  return {
    type: CLEAR_USER_WHO_NEED_MORE_INFO,
  };
};

// TODO: This needs to be done as part of a transaction
// Used only to onboard users, not employees
const createUserAndAddPublisherRoles = ({ businessProfileId, publisherId, user }: any) => {
  return async (dispatch: any, getState: GetState) => {
    let result;

    if (user.snapUsername) {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(user.snapUsername).is.string();

      try {
        // If called with email and username, that means we have already requested them from the user because they were missing
        // Therefore we need to create a new user
        if (user.email || user.username) {
          result = await dispatch(createNewPublisherUserWithSnapUsername(publisherId, user));
        } else {
          result = await dispatch(addExistingUserToPublisherBySnapUsername(publisherId, user.snapUsername));

          // User did not exist in the backend. We need to ask for more info (email and name)
          if (!result.payload) {
            return null;
          }
        }
      } catch (error) {
        return apiErrorHandler(dispatch, ErrorContexts.ADD_NEW_USER)(error);
      }
    } else {
      result = await dispatch(createNewPublisherUserWithEmail(publisherId, user));
    }

    const resultUser = result.payload;
    resultUser.resourceRoles = user.resourceRoles;

    // Add the user's roles
    return dispatch(editUser({ businessProfileId, user: resultUser }));
  };
};

export {
  clearPublisherUsers,
  clearUserWhoNeedsMoreInfo,
  createUserAndAddPublisherRoles,
  deletePublisherUser,
  editUser,
  getPublisherUsers,
};
