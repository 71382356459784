import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

import { EMPTY_ARRAY } from 'config/constants';

import { ZoomType } from 'types/grid';
import { FilterByEnum } from 'types/mediaLibrary';

function getMediaLibraryData(state: any) {
  return state.mediaLibrary;
}
export const currentFilter = createKeySelector(getMediaLibraryData, 'currentFilter', FilterByEnum.RECENT);
export const getMediaList = createKeySelector(getMediaLibraryData, 'mediaList', EMPTY_ARRAY);
export const getMediaById = s(getMediaList, mediaList => (mediaId: any) =>
  mediaList.find(media => (media as any).id === mediaId)
);
export const getZoomState = createKeySelector(getMediaLibraryData, 'zoomState', ZoomType.ZOOMED_OUT);
export const getNextCursor = createKeySelector(getMediaLibraryData, 'nextCursor', '');
export const hasMoreResults = createKeySelector(getMediaLibraryData, 'hasMoreResults', true);
export const isLoading = createKeySelector(getMediaLibraryData, 'isLoading', false);
