import { get } from 'lodash';
import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { assetSchema } from 'state/asset/schema/assetSchema';
import { convertToCMSSubtitles } from 'state/subtitles/schema/subtitlesSchema';

import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';

import { assertAssetId, AssetBuildStatus } from 'types/assets';
import type { AssetID } from 'types/assets';
import { SnapType } from 'types/snaps';
import type { Snap } from 'types/snaps';

export const LOAD_ASSET_INFO = 'asset/LOAD_ASSET_INFO';
export const SUBMIT_VIDEO_TILE_PREVIEW_BUILD = 'asset/SUBMIT_VIDEO_TILE_PREVIEW_BUILD';
export function loadAssetInfo(assetId: AssetID) {
  return (dispatch: Dispatch) => {
    assertAssetId(assetId);
    return dispatch(
      createCallAction(
        {
          type: LOAD_ASSET_INFO,
          params: {
            assetId,
          },
          meta: {
            schema: assetSchema,
          },
        },
        {
          endpoint: mediaLibraryAPI.asset.info({ assetId }),
          finalizer: convertToCMSSubtitles,
        }
      )
    );
  };
}
export const loadAssetInfoUntilResolved = (assetId: AssetID, retries: number, callback: (a: any) => void) => (
  dispatch: Dispatch
) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => CallActi... Remove this comment to see the full error message
  return dispatch(loadAssetInfo(assetId)).then((action: any) => {
    if (get(action, ['payload', 'entities', 'assets', assetId, 'type'], 'UNKNOWN') === 'UNKNOWN' && retries > 0) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => any' is ... Remove this comment to see the full error message
      return setTimeout(() => dispatch(loadAssetInfoUntilResolved(assetId, retries - 1, callback)), 5 * 1000);
    }
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    return callback();
  });
};

export const loadAssetInfoUntilBuildFinished = (
  assetId: AssetID,
  retries: number,
  callback: (assetId: AssetID, assetBuildStatus: AssetBuildStatus) => void
) => async (dispatch: Dispatch) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => CallActi... Remove this comment to see the full error message
  return dispatch(loadAssetInfo(assetId)).then(
    (action: any) => {
      const buildStatus: AssetBuildStatus = get(
        action,
        ['payload', 'entities', 'assets', assetId, 'status'],
        AssetBuildStatus.IN_PROGRESS
      );
      if (retries > 0 && buildStatus === AssetBuildStatus.IN_PROGRESS) {
        return setTimeout(() => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => any' is ... Remove this comment to see the full error message
          dispatch(loadAssetInfoUntilBuildFinished(assetId, retries - 1, callback));
        }, 1000);
      }
      return callback(assetId, buildStatus);
    },
    () => {
      return callback(assetId, AssetBuildStatus.FAILURE);
    }
  );
};

export function loadSnapAssetInfo(snap?: Snap | null): (a: Dispatch) => Promise<any> {
  return (dispatch: Dispatch): Promise<any> => {
    if (!snap) {
      return Promise.resolve();
    }
    if (!(snap as any).videoAssetId && !(snap as any).imageAssetId && !(snap as any).assetId) {
      return Promise.resolve();
    }
    switch (snap.type) {
      case SnapType.VIDEO: {
        // @ts-expect-error ts-migrate(2739) FIXME: Type 'AnyAction' is missing the following properti... Remove this comment to see the full error message
        return dispatch(loadAssetInfo(snap.videoAssetId));
      }
      case SnapType.IMAGE: {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'AnyAction' is not assignable to type 'Promis... Remove this comment to see the full error message
        return dispatch(loadAssetInfo(snap.imageAssetId));
      }
      case SnapType.UNKNOWN:
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'AnyAction' is not assignable to type 'Promis... Remove this comment to see the full error message
        return dispatch(loadAssetInfo(snap.assetId));
      default:
        return Promise.resolve();
    }
  };
}
export function submitTilePreviewBuild(assetId: AssetID, trimStart: number, trimDuration: number): any {
  return createCallAction(
    {
      type: SUBMIT_VIDEO_TILE_PREVIEW_BUILD,
    },
    {
      method: 'put',
      endpoint: mediaLibraryAPI.asset.buildVideoTile({
        assetId,
        trimStart,
        trimDuration,
      }),
    }
  );
}
