import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder('/');

export const login = {
  ticket: builder.createAPICall('login/ticket'),
};

export const creator = {
  notFound: builder.createAPICall('notFound'),
  home: builder.createAPICall('user/:hostUsername/home'),
  noPermission: builder.createAPICall('user/:hostUsername/noPermission'),
  newEdition: builder.createAPICall('user/:hostUsername/newEdition'),
  shows: builder.createAPICall('user/:hostUsername/home/shows'),
  settings: builder.createAPICall('user/:hostUsername/settings'),
  admin: builder.createAPICall('user/:hostUsername/admin'),
  contact: builder.createAPICall('user/:hostUsername/contact'),
  archive: builder.createAPICall('user/:hostUsername/archive'),
  mediaLibrary: builder.createAPICall('user/:hostUsername/media'),
  mediaLibraryForTopSnap: builder.createAPICall('user/:hostUsername/media/edition/:editionId/snap/:snapId'),
  mediaLibraryForAttachment: builder.createAPICall(
    'user/:hostUsername/media/edition/:editionId/snap/:snapId/attachment/:attachmentId'
  ),
  creativeTemplates: builder.createAPICall('user/:hostUsername/creative/templates'),
};

export const creatorAnalytics = {
  revenueAnalytics: builder.createAPICall('user/:hostUsername/analytics/revenue'),
  profileAnalytics: builder.createAPICall('user/:hostUsername/analytics/profile'),
  analytics: builder.createAPICall('user/:hostUsername/analytics'),
  behaviorAnalytics: builder.createAPICall('user/:hostUsername/analytics/behavior'),
  insightsAnalytics: builder.createAPICall('user/:hostUsername/analytics/insights'),
  insightsAnalyticsReport: builder.createAPICall('user/:hostUsername/analytics/insights/report'),
  storiesAnalytics: builder.createAPICall('user/:hostUsername/analytics/stories'),
  storiesAnalyticsV2: builder.createAPICall('user/:hostUsername/analytics/stories_v2'),
  spotlightAnalytics: builder.createAPICall('user/:hostUsername/analytics/spotlight'),
  storyAnalytics: builder.createAPICall('user/:hostUsername/analytics/story/:editionId'),
};

export const creatorEditor = {
  snap: builder.createAPICall('user/:hostUsername/edition/:editionId/snap/:snapId'),
  edition: builder.createAPICall('user/:hostUsername/edition/:editionId'),
  attachment: builder.createAPICall('user/:hostUsername/edition/:editionId/snap/:snapId/:attachmentType/:attachmentId'),
  spotlight: builder.createAPICall('user/:hostUsername/spotlight'),
};

export const publisher = {
  noPermission: builder.createAPICall('publisher/:publisherId/noPermission'),
  curationToolStory: builder.createAPICall('publisher/:publisherId/curation/edition/:editionId/snap/:snapId'),
};

export const debug = {
  snap: builder.createAPICall('v2/debug/user/:hostUsername/snap/:snapId'),
  edition: builder.createAPICall('v2/debug/user/:hostUsername/edition/:editionId'),
  creator: builder.createAPICall('v2/debug/user/:hostUsername'),
};

export const snapAdmin = {
  newPublisher: builder.createAPICall('snap_admin/publishers/new'),
  notification: builder.createAPICall('snap_admin/notification'),
  users: builder.createAPICall('snap_admin/users'),
  features: builder.createAPICall('snap_admin/features'),
  onboarding: builder.createAPICall('snap_admin/onboarding'),
};

export const curation = {
  main: builder.createAPICall('curation'),
};
