export const DEFAULT_VIDEO_LIBRARY_SORT_OPTION = '-created_at';

export const defaultVideoParams = {
  search_term: '',
  searchTerm: '',
  sort: DEFAULT_VIDEO_LIBRARY_SORT_OPTION,
  offset: '0',
  limit: 10,
};

export const modalVideoParams = {
  search_term: '',
  searchTerm: '',
  sort: DEFAULT_VIDEO_LIBRARY_SORT_OPTION,
  offset: '0',
  limit: 10,
};
