import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

import type { UploadResult } from 'types/media';

export type TimeRange = {
  minTimestampSecs: number;
  maxTimestampSecs: number;
};

export type MediaDuration = {
  minTimestampMsecs: number;
  maxTimestampMsecs: number;
};

export type Range = {
  min: number;
  max: number;
};

export type OrderByLatest = 'LATEST';
export type OrderByEarliest = 'EARLIEST';
export type OrderByNumScreenshots = 'NUM_SCREENSHOTS';
export type OrderByNumShares = 'NUM_SHARES';
export type OrderByNumSends = 'NUM_SENDS';
export type OrderByNumBoosts = 'NUM_BOOSTS';
export type OrderByNumLongWatches = 'NUM_LONG_WATCHES';
export type OrderByTotalWatchTimeSecs = 'TOTAL_WATCH_TIME_SECS';
export type OrderByAverageWatchTimeSecs = 'AVERAGE_WATCH_TIME_SECS';
export type OrderByStats =
  | OrderByNumScreenshots
  | OrderByNumShares
  | OrderByNumSends
  | OrderByNumBoosts
  | OrderByNumLongWatches
  | OrderByTotalWatchTimeSecs
  | OrderByAverageWatchTimeSecs;
export type OrderBy = OrderByLatest | OrderByEarliest | OrderByStats;

export const OrderByType: {
  LATEST: OrderByLatest;
  EARLIEST: OrderByEarliest;
} = enumObject({
  LATEST: 'LATEST',
  EARLIEST: 'EARLIEST',
});

export const OrderByEngagementStatsType: {
  NUM_SCREENSHOTS: OrderByNumScreenshots;
  NUM_SHARES: OrderByNumShares;
  NUM_SENDS: OrderByNumSends;
  NUM_BOOSTS: OrderByNumBoosts;
  NUM_LONG_WATCHES: OrderByNumLongWatches;
  TOTAL_WATCH_TIME_SECS: OrderByTotalWatchTimeSecs;
  AVERAGE_WATCH_TIME_SECS: OrderByAverageWatchTimeSecs;
} = enumObject({
  NUM_SCREENSHOTS: 'NUM_SCREENSHOTS',
  NUM_SHARES: 'NUM_SHARES',
  NUM_SENDS: 'NUM_SENDS',
  NUM_BOOSTS: 'NUM_BOOSTS',
  NUM_LONG_WATCHES: 'NUM_LONG_WATCHES',
  TOTAL_WATCH_TIME_SECS: 'TOTAL_WATCH_TIME_SECS',
  AVERAGE_WATCH_TIME_SECS: 'AVERAGE_WATCH_TIME_SECS',
});

export type AddTopic = 'ADD_TOPIC';
export type RemoveTopic = 'REMOVE_TOPIC';

export const TopicActionType: {
  ADD_TOPIC: AddTopic;
  REMOVE_TOPIC: RemoveTopic;
} = enumObject({
  ADD_TOPIC: 'ADD_TOPIC',
  REMOVE_TOPIC: 'REMOVE_TOPIC',
});

export type CuratorAdded = 'CURATOR_ADDED';
export type UserAdded = 'USER_ADDED';
export type TopicType = CuratorAdded | UserAdded;

export const TopicOwnerType: {
  CURATOR_ADDED: CuratorAdded;
  USER_ADDED: UserAdded;
} = enumObject({
  CURATOR_ADDED: 'CURATOR_ADDED',
  USER_ADDED: 'USER_ADDED',
});

export type TopicString = string;

export type Topics = {
  [k in TopicString]: TopicType;
};

export type TopicSnap = {
  snapId?: string;
  multiSnapBundleId?: string | null;
  topics: Array<TopicString>;
};

export type SnapSourceCameraRoll = 'IS_LOADED_FROM_CAMERA_ROLL';
export type SnapSourceMemories = 'IS_LOADED_FROM_MEMORIES';
export type SnapSourceSnapCamera = 'IS_LOADED_FROM_SNAP_CAMERA';
export type SnapSourceUnknown = 'UNKNOWN';
export type SnapSource = SnapSourceCameraRoll | SnapSourceMemories | SnapSourceUnknown | SnapSourceSnapCamera;

export const SnapSourceType: {
  IS_LOADED_FROM_CAMERA_ROLL: SnapSourceCameraRoll;
  IS_LOADED_FROM_MEMORIES: SnapSourceMemories;
  IS_LOADED_FROM_SNAP_CAMERA: SnapSourceSnapCamera;
  UNKNOWN: SnapSourceUnknown;
} = enumObject({
  IS_LOADED_FROM_CAMERA_ROLL: 'IS_LOADED_FROM_CAMERA_ROLL',
  IS_LOADED_FROM_MEMORIES: 'IS_LOADED_FROM_MEMORIES',
  IS_LOADED_FROM_SNAP_CAMERA: 'IS_LOADED_FROM_SNAP_CAMERA',
  UNKNOWN: 'UNKNOWN',
});

export type OurStory = 'OUR_STORY';
export type Spotlight = 'SPOTLIGHT';
export type Both = 'BOTH';
export type OurStoryDestination = OurStory | Spotlight | Both;

export const OurStoryDestinationType: {
  OUR_STORY: OurStory;
  SPOTLIGHT: Spotlight;
  BOTH: Both;
} = enumObject({
  OUR_STORY: 'OUR_STORY',
  SPOTLIGHT: 'SPOTLIGHT',
  BOTH: 'BOTH',
});

export type AnyoneCanView = 'ANYONE_CAN_VIEW';
export type NobodyCanView = 'NOBODY_CAN_VIEW';
export type NobodyInSensitiveCountryCanView = 'NOBODY_IN_SENSITIVE_COUNTRY_CAN_VIEW';
export type NobodyInModerateCountryCanView = 'NOBODY_IN_MODERATE_COUNTRY_CAN_VIEW';
export type ModerationAudience =
  | AnyoneCanView
  | NobodyCanView
  | NobodyInSensitiveCountryCanView
  | NobodyInModerateCountryCanView;

export const ModerationAudienceType: {
  ANYONE_CAN_VIEW: AnyoneCanView;
  NOBODY_CAN_VIEW: NobodyCanView;
  NOBODY_IN_SENSITIVE_COUNTRY_CAN_VIEW: NobodyInSensitiveCountryCanView;
  NOBODY_IN_MODERATE_COUNTRY_CAN_VIEW: NobodyInModerateCountryCanView;
} = enumObject({
  ANYONE_CAN_VIEW: 'ANYONE_CAN_VIEW',
  NOBODY_CAN_VIEW: 'NOBODY_CAN_VIEW',
  NOBODY_IN_SENSITIVE_COUNTRY_CAN_VIEW: 'NOBODY_IN_SENSITIVE_COUNTRY_CAN_VIEW',
  NOBODY_IN_MODERATE_COUNTRY_CAN_VIEW: 'NOBODY_IN_MODERATE_COUNTRY_CAN_VIEW',
});

export const ModerationMode = enumObject({
  ModerationModeManual: 'MANUAL',
  ModerationModeAuto: 'AUTO',
});
export type ModerationModeEnum = Enum<typeof ModerationMode>;

// base64 encoded bytes of this proto -
// https://github.sc-corp.net/Snapchat/pb_schema/blob/master/proto/context/hint.proto
export type ContextHint = string;

export type MapDimensions = {
  height: number;
  width: number;
};

export type Coordinates = {
  latitude: number;
  longitude: number;
};

export type CurationGeoLocationMapBound = {
  southWest: Coordinates;
  northEast: Coordinates;
};

export type CurationGeoLocationMetadata = {
  label: string;
  mapBounds?: CurationGeoLocationMapBound;
  coordinates?: Coordinates[];
};

export type CurationFilterProps = {
  mapDimensions: MapDimensions;
};

export type SnapEngagement = {
  numRawWatches: number;
  numLongWatches: number;
  numScreenshots: number;
  numSends: number;
  numShares: number;
  numBoosts: number;
  numImpressions: number;
  numFavorites: number;
  numHides: number;
  totalWatchTimeSeconds: number;
  averageWatchTimeSeconds: number;
};

export const EngagementStatsFields = enumObject({
  NUM_RAW_WATCHES: 'numRawWatches',
  NUM_LONG_WATCHES: 'numLongWatches',
  NUM_SCREENSHOTS: 'numScreenshots',
  NUM_IMPRESSIONS: 'numImpressions',
  NUM_SENDS: 'numSends',
  NUM_SHARES: 'numShares',
  NUM_BOOSTS: 'numBoosts',
  NUM_FAVORITES: 'numFavorites',
  NUM_HIDES: 'numHides',
  TOTAL_WATCH_TIME_SECS: 'totalWatchTimeSecs',
  AVERAGE_WATCH_TIME_SECS: 'averageWatchTimeSecs',
});

export type SnapEngagementLast24Hours = SnapEngagement;
export type SnapEngagementLast14Days = SnapEngagement;

export type BaseCuratedSnap = {
  id: string;
  thumbnailUrl: string;
  mediaUrl: string;
  dateTimestamp: number;
  locationLabel: string;
  username?: string;
  contextHint: ContextHint;
  snapSource: SnapSource;
  locationCoordinates: Coordinates;
  topics: {
    [x: string]: TopicType;
  };
  isHighQuality?: boolean;
  key: string;
  iv: string;
  rawMediaUrl: string;
  mediaDuration: number;
  snapEngagements: [SnapEngagementLast24Hours, SnapEngagementLast14Days];
  musicTrackId: string | undefined | null;
  lensId: string | undefined | null;
  multiSnapBundleId: string | undefined | null;
  moderationAudience: void | ModerationAudience;
  moderatedBy: void | ModerationModeEnum;
  snapJoinId: string;
  isEvergreen: boolean;
  isUniversal: boolean;
  isStarred: boolean;
  locale: string;
};

export type MediaTypeImage = 'IMAGE';
export type MediaTypeVideo = 'VIDEO';

export type ImageCuratedSnap = BaseCuratedSnap & {
  mediaType: MediaTypeImage;
};

export type VideoCuratedSnap = BaseCuratedSnap & {
  originalVideoUrl: string | undefined | null;
  overlayImageUrl: string | undefined | null;
  mediaType: MediaTypeVideo;
  stitchedAssetUrl: string;
};

export type CuratedSnap = ImageCuratedSnap | VideoCuratedSnap;

export type CuratedSnapMediaType = MediaTypeImage | MediaTypeVideo;

export const CuratedSnapType: {
  IMAGE: MediaTypeImage;
  VIDEO: MediaTypeVideo;
} = enumObject({
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
});

export type CuratedSnapTypeEnum = Enum<typeof CuratedSnapType>;

export type SnapLocation = {
  label: string;
  coordinates: Coordinates[];
};

export type LandscapeCameraOrientation = 'LANDSCAPE';
export type PortraitCameraOrientation = 'PORTRAIT';
export type CameraOrientation = LandscapeCameraOrientation | PortraitCameraOrientation;

export const CameraOrientationType: {
  PORTRAIT: PortraitCameraOrientation;
  LANDSCAPE: LandscapeCameraOrientation;
} = enumObject({
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
});

export type FrontCameraFace = 'FRONT';
export type BackCameraFace = 'BACK';
export type CameraFace = FrontCameraFace | BackCameraFace;

export const CameraFaceType: {
  FRONT: FrontCameraFace;
  BACK: BackCameraFace;
} = enumObject({
  FRONT: 'FRONT',
  BACK: 'BACK',
});

export type MyStarred = 'MY_STARRED';
export type Starred = 'STARRED';
export type ModeratorStarred = 'MODERATOR_STARRED';
export type NotStarred = 'NOT_STARRED';
export type CurationStarredStatus = MyStarred | Starred | ModeratorStarred | NotStarred;

export const CurationStarredStatusType: {
  MY_STARRED: MyStarred;
  STARRED: Starred;
  MODERATOR_STARRED: ModeratorStarred;
  NOT_STARRED: NotStarred;
} = enumObject({
  MY_STARRED: 'MY_STARRED',
  STARRED: 'STARRED',
  MODERATOR_STARRED: 'MODERATOR_STARRED',
  NOT_STARRED: 'NOT_STARRED',
});

export type UploadImportedMediaWithoutOverlayResult = {
  flattenMediaResult: UploadResult;
};

export type CuratedSticker = {
  label: string;
  value: string;
};

export type UploadImportedMediaWithOverlayResult = {
  flattenMediaResult: UploadResult;
  baseMediaResult: UploadResult;
  overlayMediaResult: UploadResult;
};

export type UploadImportedMediaResult = UploadImportedMediaWithOverlayResult | UploadImportedMediaWithoutOverlayResult;

export type CurationFilterGroupID = string;

// CurationFilerGroup swagger
export type HasCaption = 'HAS_CAPTION';
export type NoCaption = 'NO_CAPTION';
export type CaptionType = HasCaption | NoCaption;

export const HasCaptionEnum: {
  HAS_CAPTION: HasCaption;
  NO_CAPTION: NoCaption;
} = enumObject({
  HAS_CAPTION: 'HAS_CAPTION',
  NO_CAPTION: 'NO_CAPTION',
});

// CurationFilerGroup swagger
export type HasField = 'HAS_FIELD';
export type NoField = 'NO_FIELD';
export type FieldType = HasField | NoField;

export const HasFieldType: {
  HAS_FIELD: HasField;
  NO_FIELD: NoField;
} = enumObject({
  HAS_FIELD: 'HAS_FIELD',
  NO_FIELD: 'NO_FIELD',
});

export type TwentyFourHours = 'TWENTY_FOUR_HOURS';
export type FourteenDays = 'FOURTEEN_DAYS';
export type EngagementStatsMode = TwentyFourHours | FourteenDays;

export const EngagementStatsModeType: {
  TWENTY_FOUR_HOURS: TwentyFourHours;
  FOURTEEN_DAYS: FourteenDays;
} = enumObject({
  TWENTY_FOUR_HOURS: 'TWENTY_FOUR_HOURS',
  FOURTEEN_DAYS: 'FOURTEEN_DAYS',
});

export type SnapOrderChronological = 'CHRONOLOGICAL';
export type SnapOrderRevChronological = 'REVCHRONOLOGICAL';
export type SnapOrderType = SnapOrderChronological | SnapOrderRevChronological | OrderByStats;

export const SnapOrder: {
  CHRONOLOGICAL: SnapOrderChronological;
  REVCHRONOLOGICAL: SnapOrderRevChronological;
  NUM_SCREENSHOTS: OrderByNumScreenshots;
  NUM_SHARES: OrderByNumShares;
  NUM_SENDS: OrderByNumSends;
  NUM_BOOSTS: OrderByNumBoosts;
  NUM_LONG_WATCHES: OrderByNumLongWatches;
  TOTAL_WATCH_TIME_SECS: OrderByTotalWatchTimeSecs;
  AVERAGE_WATCH_TIME_SECS: OrderByAverageWatchTimeSecs;
} = enumObject({
  CHRONOLOGICAL: 'CHRONOLOGICAL',
  REVCHRONOLOGICAL: 'REVCHRONOLOGICAL',
  NUM_SCREENSHOTS: 'NUM_SCREENSHOTS',
  NUM_SHARES: 'NUM_SHARES',
  NUM_SENDS: 'NUM_SENDS',
  NUM_BOOSTS: 'NUM_BOOSTS',
  NUM_LONG_WATCHES: 'NUM_LONG_WATCHES',
  TOTAL_WATCH_TIME_SECS: 'TOTAL_WATCH_TIME_SECS',
  AVERAGE_WATCH_TIME_SECS: 'AVERAGE_WATCH_TIME_SECS',
});

export type CurationFilterGroupSwagger = {
  configurationId?: string;
  configurationName?: string;
  location?: CurationGeoLocationMetadata;
  timeRange?: TimeRange;
  mediaType?: CuratedSnapMediaType;
  caption?: string;
  hasCaption?: CaptionType;
  cameraOrientation?: CameraOrientation;
  lensId?: string;
  geofilterId?: string;
  cameraFace?: CameraFace;
  sticker?: CuratedSticker;
  usernames?: Array<string | undefined | null>;
  topics?: Array<string>;
  orderBy: SnapOrderType;
  starredStatus?: CurationStarredStatus;
  musicTrackId?: string;
  ourStoryDestinations?: OurStoryDestination;
  hasLens?: FieldType;
  hasSticker?: FieldType;
  hasTopic?: FieldType;
  hasGeofilter?: FieldType;
  hasMusicTrack?: FieldType;
  mediaDuration?: MediaDuration;
  moderationAudience?: ModerationAudience;
  hasZoom?: FieldType;
  retakeTrials?: Range;
  clientEditTimeBeforeSendMsecs?: Range;
  numImpressions?: Range;
  numRawWatches?: Range;
  numLongWatches?: Range;
  numScreenshots?: Range;
  numSends?: Range;
  numShares?: Range;
  numHides?: Range;
  numFavorites?: Range;
  numBoosts?: Range;
  totalWatchTimeSecs?: Range;
  averageWatchTimeSecs?: Range;
  contentTags?: Array<string>;
  hasContentTag?: FieldType;
  multiSnapBundleIds?: Array<string>;
  hasMultiSnapBundleId?: FieldType;
  rawStoryIds?: Array<string>;
};

export type CurationFilterGroup = {
  configurationName: string;
  configurationId: string;
};

export type TrimVideoStatusSucceeded = 'SUCCEEDED';
export type TrimVideoStatusNotFound = 'NOT_FOUND';
export type TrimVideoStatusEmpty = 'EMPTY';
export type TrimVideoStatusType = TrimVideoStatusSucceeded | TrimVideoStatusNotFound | TrimVideoStatusEmpty;

export const TrimVideoStatus: {
  SUCCEEDED: TrimVideoStatusSucceeded;
  NOT_FOUND: TrimVideoStatusNotFound;
  EMPTY: TrimVideoStatusEmpty;
} = enumObject({
  SUCCEEDED: 'SUCCEEDED',
  NOT_FOUND: 'NOT_FOUND',
  EMPTY: 'EMPTY',
});

export type TrimMetadata = {
  trimStart: number;
  trimEnd: number;
};

export type DecryptMediaStatusSucceeded = 'SUCCEEDED';
export type DecryptMediaStatusNotFound = 'NOT_FOUND';
export type DecryptMediaStatusEmpty = 'EMPTY';
export type DecryptMediaStatusType = DecryptMediaStatusSucceeded | DecryptMediaStatusNotFound | DecryptMediaStatusEmpty;
export const DecryptMediaStatus: {
  SUCCEEDED: DecryptMediaStatusSucceeded;
  NOT_FOUND: DecryptMediaStatusNotFound;
  EMPTY: DecryptMediaStatusEmpty;
} = enumObject({
  SUCCEEDED: 'SUCCEEDED',
  NOT_FOUND: 'NOT_FOUND',
  EMPTY: 'EMPTY',
});

export type DecryptMediaResponse = {
  payload: {
    status: DecryptMediaStatusType;
    rawMediaURL: string;
  };
};
