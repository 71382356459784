import { get } from 'lodash';
import { combineReducers } from 'redux';

import { createSequenceHandlingReducer } from 'state/common/reducerFactories';
import { TrendingActionType } from 'state/trending/actions/trendingActions';

import type { State } from 'types/rootState';

const topics = createSequenceHandlingReducer([], TrendingActionType.GET_TOPICS, {
  start(state: State) {
    return state;
  },
  done(state: State, action: any) {
    const topicsPayload = get(action.payload, 'topics');
    if (topicsPayload != null) {
      return topicsPayload.map((topicHistory: any) => get(topicHistory, 'topics'));
    }
    return [];
  },
});

export default combineReducers({
  topics,
});
