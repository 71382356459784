import { range } from 'lodash';
import { DurationInputArg2 } from 'moment';
import moment from 'moment-timezone';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { assertArg } from './assertionUtils';

export type TimeOption = {
  value: number;
  label: string;
};
export function guesstimateEditionEndDate(startDate: any) {
  if (!moment.isMoment(startDate)) {
    throw new Error('guesstimateEditionEndDate() expects a Moment instance');
  }
  return startDate.clone().add(1, 'day');
}
export function isInThePastOrPresent(startDate: any) {
  if (startDate instanceof Date) {
    startDate = startDate.getTime(); // eslint-disable-line no-param-reassign
  }
  return startDate <= Date.now();
}
export function isInTheFuture(endDate: any) {
  return !isInThePastOrPresent(endDate);
}
/*
  Default to 10 minutes, reasonable time for user/publisher data.
*/
export function isExpired(timestamp: number, value: number = 10, units: DurationInputArg2 = 'minutes') {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(timestamp).is.number();
  // Evaluates timestamp ignorant of the timezone, timestamps should not have tz information.
  const expiresAt = moment(timestamp);
  expiresAt.add(value, units);
  if (!expiresAt.isValid()) {
    throw new Error('invalid timestamp');
  }
  return expiresAt.isBefore(moment(moment.now()));
}
// Takes in a date object, and returns time in HH:MM TZ format
export function getFormattedTimeString(date: any) {
  const tz = moment().tz(moment.tz.guess()).format('z');
  return `${moment(date).format('HH:mm')} ${tz}`;
}
// Takes in a date object, and returns date in a localised short date format
export function getFormattedDateString(date: any) {
  return `${moment(date).format('M/D/YYYY')}`;
}
export function getFormattedFullTimestampString(date: any) {
  const tz = moment().tz(moment.tz.guess()).format('z');
  return `${moment(date).format('M/D/YYYY HH:mm:ss')} ${tz}`;
}
// Returns whether a date is 1/1/1970
export function isStartOfEpoch(date: string) {
  return date === '1970-01-01T00:00:00.000Z';
}
export function isMomentStartOfEpoch(date: moment.Moment) {
  return moment(date).format('YYYY-MM-DD') === '1970-01-01';
}
// Returns the time difference to a date in terms of the largest unit of time
export function formatRelativeTime(positive: boolean, hours: any, minutes: any, seconds: any) {
  if (!positive) {
    if (hours > 24) {
      const days = Math.floor(hours / 24);
      return (
        <FormattedMessage
          id="relative-time-future-days"
          description="How many days an event is in the future"
          defaultMessage={'in {days}d'}
          values={{ days }}
        />
      );
    }
    if (hours > 0) {
      return (
        <FormattedMessage
          id="relative-time-future-hours"
          description="How many hours an event is in the future"
          defaultMessage={'in {hours}h'}
          values={{ hours }}
        />
      );
    }
    if (minutes > 0) {
      return (
        <FormattedMessage
          id="relative-time-future-minutes"
          description="How many minutes an event is in the future"
          defaultMessage={'in {minutes}m'}
          values={{ minutes }}
        />
      );
    }
    return (
      <FormattedMessage
        id="relative-time-future-seconds"
        description="How many seconds an event is in the future"
        defaultMessage={'in {seconds}s'}
        values={{ seconds: seconds.toString() }}
      />
    );
  }
  if (hours > 23) {
    const days = Math.floor(hours / 24);
    return (
      <FormattedMessage
        id="relative-time-past-days"
        description="How many days an event is in the past"
        defaultMessage={'{days}d ago'}
        values={{ days }}
      />
    );
  }
  if (hours > 0) {
    return (
      <FormattedMessage
        id="relative-time-past-hours"
        description="How many hours an event is in the past"
        defaultMessage={'{hours}h ago'}
        values={{ hours }}
      />
    );
  }
  if (minutes > 0) {
    return (
      <FormattedMessage
        id="relative-time-past-minutes"
        description="How many minutes an event is in the past"
        defaultMessage={'{minutes}m ago'}
        values={{ minutes }}
      />
    );
  }
  return (
    <FormattedMessage
      id="relative-time-past-seconds"
      description="How many seconds an event is in the past"
      defaultMessage={'{seconds}s ago'}
      values={{ seconds: seconds.toString() }}
    />
  );
}
export function formatTimeToTwoMetrics(positive: boolean, hours: number, minutes: number, seconds: number) {
  const days = Math.floor(hours / 24);
  // Using 'toString' to convert some values below because FormattedMessage seems to ignore when
  // a value is zero.
  if (days >= 365) {
    return (
      <FormattedMessage
        id="relative-time-years-days"
        description="How many years and days an event is in the past"
        defaultMessage={'{years}y {days}d'}
        values={{ years: Math.floor(days / 365), days: (days % 365).toString() }}
      />
    );
  }
  if (days > 0) {
    return (
      <FormattedMessage
        id="relative-time-days-hours"
        description="How many days and hours an event is in the past"
        defaultMessage={'{days}d {hours}h'}
        values={{ days, hours: (hours % 24).toString() }}
      />
    );
  }
  if (hours > 0) {
    return (
      <FormattedMessage
        id="relative-time-hours-minutes"
        description="How many hours and minutes an event is in the past"
        defaultMessage={'{hours}h {minutes}m'}
        values={{ hours, minutes: minutes.toString() }}
      />
    );
  }
  if (minutes > 0) {
    return (
      <FormattedMessage
        id="relative-time-minutes-seconds"
        description="How many minutes and seconds an event is in the past"
        defaultMessage={'{minutes}m {seconds}s'}
        values={{ minutes, seconds: seconds.toString() }}
      />
    );
  }
  return (
    <FormattedMessage
      id="relative-time-seconds"
      description="How many seconds an event is in the past"
      defaultMessage={'{seconds}s'}
      values={{ seconds: seconds.toString() }}
    />
  );
}
// TODO: all these format functions need refactoring because they have similar logic
// and could be grouped together somehow
export function formatTimeLeft(positive: boolean, hours: any, minutes: any, seconds: any) {
  if (positive) {
    return <div>---</div>;
  }
  if (hours > 24) {
    const days = Math.floor(hours / 24);
    return (
      <FormattedMessage
        id="relative-time-future-days-left"
        description="How many days an event is in the future"
        defaultMessage={'{days}d left'}
        values={{ days }}
      />
    );
  }
  if (hours > 0) {
    return (
      <FormattedMessage
        id="relative-time-future-hours-left"
        description="How many hours an event is in the future"
        defaultMessage={'{hours}h left'}
        values={{ hours }}
      />
    );
  }
  if (minutes > 0) {
    return (
      <FormattedMessage
        id="relative-time-future-minutes-left"
        description="How many minutes an event is in the future"
        defaultMessage={'{minutes}m left'}
        values={{ minutes }}
      />
    );
  }
  return (
    <FormattedMessage
      id="relative-time-future-seconds-left"
      description="How many seconds an event is in the future"
      defaultMessage={'{seconds}s left'}
      values={{ seconds: seconds.toString() }}
    />
  );
}
// Used to generate the time options for a selector in 30 mins interval containing AM and PM
export const generateSelectorTimeOptions = (): TimeOption[] => {
  const halfHourIncrements = 48;
  // allow minute offsets for debugging and special cases (e.g. commerce launch)
  const offset = (global as any).timeOffset ? (global as any).timeOffset : 0;
  return range(halfHourIncrements).map(count => {
    let hour = Math.floor((count / 2) % 12);
    if (hour === 0) {
      hour = 12;
    }
    const hourString = `${`0${hour.toString()}`.substr(-2)}`;
    const offsetString = ` - ${offset > 0 ? offset : ''}`;
    const minute = `${count % 2 ? '3' : '0'}0`;
    const meridien = count < 24 ? 'AM' : 'PM';
    const label = `${hourString}:${minute} ${meridien}${offset > 0 ? offsetString : ''}`;
    return {
      value: count * 30 - offset,
      label,
    };
  });
};
// computes delta hours, minutes and seconds for the given date compared to the current date
export function computeDeltaTimeUnits(inputDate: Date) {
  // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  let delta = (new Date() - inputDate) / 1000;
  const hours = Math.floor(delta / 3600);
  delta -= hours * 3600;
  const minutes = Math.floor(delta / 60);
  delta -= minutes * 60;
  const seconds = Math.floor(delta);
  return { hours, minutes, seconds };
}
// Useful for SDSDatePicker
export const getDisabledStartTimeHours = (
  startDate: moment.Moment,
  endDate: moment.Moment | undefined | null,
  selectedDateTimezone: string,
  isSchedulingEndDate: boolean
) => () => {
  const disabledHours = [];
  if (startDate) {
    for (let i = 0; i < 24; i++) {
      const currentHour = moment().tz(selectedDateTimezone).startOf('hour');
      const addHoursToStartDate = moment(startDate).tz(selectedDateTimezone).startOf('day').add(i, 'hours');
      const isBeforeNow = addHoursToStartDate.isBefore(currentHour);
      const isAfterEndDate =
        isSchedulingEndDate && addHoursToStartDate.isAfter(moment(endDate).tz(selectedDateTimezone));
      if (isBeforeNow || isAfterEndDate) {
        disabledHours.push(i);
      }
    }
  }
  return disabledHours;
};
export const getDisabledEndTimeHours = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  selectedDateTimezone: string
) => () => {
  const disabledHours = [];
  if (endDate) {
    for (let i = 0; i < 24; i++) {
      const currentHour = moment.tz(selectedDateTimezone).startOf('hour');
      const addHoursToEndDate = moment.tz(endDate, selectedDateTimezone).startOf('day').add(i, 'hours');
      const isBeforeNow = addHoursToEndDate.isBefore(currentHour);
      const isBeforeStartDate = addHoursToEndDate.isBefore(moment.tz(startDate, selectedDateTimezone).startOf('hour'));
      if (isBeforeNow || isBeforeStartDate) {
        disabledHours.push(i);
      }
    }
  }
  return disabledHours;
};
export const getDisabledStartTimeMinutes = (
  startDate: moment.Moment,
  endDate: moment.Moment | undefined | null,
  selectedDateTimezone: string,
  isSchedulingEndDate: boolean
) => (selectedHour: number) => {
  const disabledMinutes = [];
  if (startDate) {
    const time = moment(startDate).tz(selectedDateTimezone);
    if (selectedHour === time.hour()) {
      for (let i = 0; i < 60; i++) {
        const currentMinute = moment().tz(selectedDateTimezone).startOf('minute');
        const addMinutesToStartDate = moment(startDate).tz(selectedDateTimezone).startOf('hour').add(i, 'minutes');
        const isBeforeNow = addMinutesToStartDate.isBefore(currentMinute);
        const isAfterEndDate =
          isSchedulingEndDate &&
          addMinutesToStartDate.isSameOrAfter(moment(endDate).tz(selectedDateTimezone).startOf('minute'));
        if (isBeforeNow || isAfterEndDate) {
          disabledMinutes.push(i);
        }
      }
    }
  }
  return disabledMinutes;
};
export const getDisabledEndTimeMinutes = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  selectedDateTimezone: string
) => (selectedHour: number) => {
  const disabledMinutes = [];
  if (endDate) {
    const time = moment(endDate).tz(selectedDateTimezone);
    if (selectedHour === time.hour()) {
      for (let i = 0; i < 60; i++) {
        const currentMinute = moment().tz(selectedDateTimezone).startOf('minute');
        const addMinutesToEndDate = moment(endDate).tz(selectedDateTimezone).startOf('hour').add(i, 'minutes');
        const isBeforeNow = addMinutesToEndDate.isBefore(currentMinute);
        const isBeforeStartDate = addMinutesToEndDate.isSameOrBefore(moment(startDate).tz(selectedDateTimezone));
        if (isBeforeNow || isBeforeStartDate) {
          disabledMinutes.push(i);
        }
      }
    }
  }
  return disabledMinutes;
};
