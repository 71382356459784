import type { CameraAttachmentSnap } from 'types/cameraAttachment';
import { SnapType } from 'types/snaps';
import type { Stage } from 'types/stages';

type CameraAttachmentStage = Stage<CameraAttachmentSnap, Partial<CameraAttachmentSnap>, void>;

export const CAMERA_ATTACHMENT_STAGE_CONFIG: CameraAttachmentStage = {
  stageName: SnapType.CAMERA_ATTACHMENT,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: ['lenses', 'sendToBehavior'],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => properties,
};
