import is from 'is_js';
import { mapValues, keyBy, forOwn } from 'lodash';
import log from 'loglevel';
import { normalize, arrayOf } from 'normalizr';

import {
  getAllSnapSchemaKeys,
  getDefaultSnapEndpoint,
  getDecorationSnapEndpoints,
} from '../endpoints/snapEndpointRegistry';

import { assertArg } from 'utils/assertionUtils';

import { splitByEndpoint } from './snapDecoration';

import { assertSnapId } from 'types/common';

/*
 * Applies Normalizr over the supplied properties object so that any properties
 * that belong to entities nested within the snap (e.g. tiles) are extracted into
 * their normalized form.
 */
export function buildNormalizedPropertiesForEndpoint(snapId: any, properties: any, endpointInfo: any) {
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointInfo).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointInfo.name).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointInfo.snapSchema).is.object();

  const propertiesForEndpoint = splitByEndpoint(properties)[endpointInfo.name];

  if (!propertiesForEndpoint) {
    log.warn('setSnapProperties was called with no properties for the supplied endpoint - this should not happen');
  }

  return normalize({ id: snapId, ...propertiesForEndpoint }, endpointInfo.snapSchema);
}

/*
 * Given a map of entities as generated by Normalizr, returns a map contianing
 * only entities that are not some form of snap (i.e. richSnap, discoverSnap).
 *
 * Given:
 *
 *   {
 *     richSnap: {
 *       11: { ... },
 *       22: { ... },
 *     },
 *     discoverSnap: {
 *       33: { ... },
 *       44: { ... },
 *     },
 *     tile: {
 *       55: { ... },
 *       66: { ... },
 *     },
 *     edition: {
 *       77: { ... },
 *       88: { ... },
 *     },
 *   }
 *
 * Will return:
 *
 *   {
 *     tile: {
 *       55: { ... },
 *       66: { ... },
 *     },
 *     edition: {
 *       77: { ... },
 *       88: { ... },
 *     },
 *   }
 *
 */
export function extractNonSnapEntities(entities: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entities).is.object();

  const nonSnapEntities = {};
  const snapSchemaKeys = getAllSnapSchemaKeys();

  Object.keys(entities).forEach(entityName => {
    if (snapSchemaKeys.indexOf(entityName) === -1) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      nonSnapEntities[entityName] = entities[entityName];
    }
  });

  return nonSnapEntities;
}

// Alert: this is a bit weird :)
//
// This function addresses a peculiarity with the way the `relatedSnaps` map
// is returned from the server. In order to prevent circular references, the
// server only expands some of the relationships when returning data for
// related snaps. For example:
//
//         top snap             << The snap that's actually being requested
//            |
//  `BOTTOM` relationship
//            |
//       bottom snap            << Bottom snap data bundled into the response
//
// In this scenario the app is loading data for the top snap, and the server
// automatically bundles in the data for the bottom snap to reduce the number
// of API calls that the app has to make. Both snaps have a `relatedSnaps` map,
// and as snaps are modelled as a double-linked list, they point to each other.
// However, as JSON is not capable of representing circular references, the
// `bottomSnap.relatedSnaps[TOP]` relationship is returned as null.
//
// This causes problems when the data is run through Denormalizr, as the null
// references causes Denormalizr to assume that the reference has been deleted.
// This has the effect of the top snap disappearing from the components panel
// in the editor.
//
// To get around this, we replace the `relatedSnapIds` with the `relatedSnapIds`
// map. The ids listed in relatedSnapIds are always complete (as there's no
// circular reference issue with an id), and as Denormalizr will automatically
// resolve the id to the entity we have stored, the relationships end up correctly
// modelled in the app.
//
// Note that this does _not_ mean that we discard the related snap data that
// the server has automatically included in the response, because by the time
// the data hits the reducer it has already been separated out into entities
// by Normalizr.
function replaceRelatedSnapsForOneSnap(snap: any) {
  if (snap.relatedSnapIds) {
    return { ...snap, relatedSnaps: snap.relatedSnapIds };
  }
  return snap;
}

export function replaceRelatedSnapsMapWithRelatedSnapIdsMap(originalSnaps: any) {
  if (is.array(originalSnaps)) {
    return originalSnaps.map(replaceRelatedSnapsForOneSnap);
  }
  if (is.object(originalSnaps)) {
    return mapValues(originalSnaps, replaceRelatedSnapsForOneSnap);
  }

  return originalSnaps;
}

/*
 * Extracts the normalized decorated snap, including non-snap entities inside the decoration.
 *
 * Given:
 * [
 *  {
 *    id: 1,
 *    decorations: {
 *      discover: {
 *        id: 1,
 *        tiles: [{ id: 2 }],
 *      },
 *    },
 *  },
 * ]
 *
 * Will return:
 *
 * {
 *  result: [1],
 *  entities: {
 *    richSnap: {
 *      1: {
 *        id: 1,
 *        decorations: {
 *          discover: {
 *            id: 1,
 *            tiles: [2],
 *          },
 *        },
 *      },
 *    },
 *    tile: {
 *      2: { id: 2 },
 *    },
 *  },
 * }
 */

export function normalizeDecoratedSnaps(entityArray: any) {
  const defaultSnapEndpoint = getDefaultSnapEndpoint();
  const decorationSnapEndpoints = keyBy(getDecorationSnapEndpoints(), 'name');

  const entityArrayWithAdjustedRelatedSnaps = replaceRelatedSnapsMapWithRelatedSnapIdsMap(entityArray);
  const normalized = normalize(entityArrayWithAdjustedRelatedSnaps, arrayOf(defaultSnapEndpoint.snapSchema));
  if (!normalized.result) {
    return normalized;
  }

  const nonSnapEntitiesArray: any = [];

  const defaultSnapSchemaKey = defaultSnapEndpoint.snapSchema.getKey();
  normalized.entities[defaultSnapSchemaKey] = mapValues(
    normalized.entities[defaultSnapSchemaKey],
    (richSnap, snapId) => {
      if (!richSnap.decorations) {
        return richSnap;
      }

      return {
        ...richSnap,
        decorations: mapValues(richSnap.decorations, (decoration, name) => {
          if (!decorationSnapEndpoints[name]) {
            return decoration;
          }

          const normalizedDecoration = normalize(decoration, decorationSnapEndpoints[name].snapSchema);
          if (!normalizedDecoration.result) {
            return decoration;
          }

          nonSnapEntitiesArray.push(extractNonSnapEntities(normalizedDecoration.entities));
          return normalizedDecoration.entities[decorationSnapEndpoints[name].snapSchema.getKey()][snapId];
        }),
      };
    }
  );

  // Add non snap entities to normalized entities
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'nonSnapEntities' implicitly has an 'any... Remove this comment to see the full error message
  nonSnapEntitiesArray.forEach(nonSnapEntities =>
    forOwn(nonSnapEntities, (entities, entityName) => {
      normalized.entities[entityName] = normalized.entities[entityName] || {};
      Object.assign(normalized.entities[entityName], entities);
    })
  );

  return normalized;
}
