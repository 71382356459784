export enum ModalType {
  ADD_PUBLISHER_DETAILS = 'ADD_PUBLISHER_DETAILS',
  ALERT = 'ALERT',
  CREATE_FEATURE_FLAG = 'CREATE_FEATURE_FLAG',
  COPY_TO_PUBLISHER = 'COPY_TO_PUBLISHER',
  CREATE_EPISODE = 'CREATE_EPISODE',
  GENERATE_SUBTITLES = 'GENERATE_SUBTITLES',
  CREATE_STORY = 'CREATE_STORY',
  DELETE_SNAP_COMPONENT = 'DELETE_SNAP_COMPONENT',
  FEATURE_BULK_UPDATE = 'FEATURE_BULK_UPDATE',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD',
  LOAD_LOCAL_CONTENT = 'LOAD_LOCAL_CONTENT',
  SPONSORED_CONTENT_MODAL = 'SPONSORED_CONTENT_MODAL',
  NEW_USER = 'NEW_USER',
  OPEN_EPISODE_CREATOR = 'OPEN_EPISODE_CREATOR',
  PLAY_VIDEO = 'PLAY_VIDEO',
  RENAME_STORY = 'RENAME_STORY',
  SCHEDULE_STORY = 'SCHEDULE_STORY',
  SCHEDULE_EPISODE = 'SCHEDULE_EPISODE',
  STORY_SNAPCODE_SHARING_MODAL = 'STORY_SNAPCODE_SHARING_MODAL',
  TAKE_SCREENSHOT_MODAL = 'TAKE_SCREENSHOT_MODAL',
  ONE_TIME_CONFIRMATION = 'ONE_TIME_CONFIRMATION',
  VIDEO_UPLOAD = 'VIDEO_UPLOAD',
  MANAGE_SUBTITLES = 'MANAGE_SUBTITLES',
  RSS_FEED_MODAL = 'RSS_FEED_MODAL',
  UPDATE_EPISODE = 'UPDATE_EPISODE',
  ORG_ONBOARDING = 'ORG_ONBOARDING',
  PUBLISHER_HELP_MODAL = 'PUBLISHER_HELP_MODAL',
  REPLACE_STORY_MEDIA = 'REPLACE_STORY_MEDIA',
  TILE_VIOLATION_APPEAL = 'TILE_VIOLATION_APPEAL',
  SPOTLIGHT_DETAILS = 'SPOTLIGHT_DETAILS',
  REVENUE_CSV_MODAL = 'REVENUE_CSV_MODAL',
  EDITION_ANALYTICS_CSV_MODAL = 'EDITION_ANALYTICS_CSV_MODAL',
  DIALOG = 'DIALOG',
  CREATE_CONTENT = 'CREATE_CONTENT',
}
