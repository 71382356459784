import { normalize, arrayOf } from 'normalizr';

import { createSequenceHandlingReducer, createByIdReducer, serializeReducers } from 'state/common/reducerFactories';
import * as showsActions from 'state/shows/actions/showsActions';
import { showSchema } from 'state/shows/schema/showsSchema';

import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

export const showsById = createSequenceHandlingReducer({}, showsActions.LOAD_SHOWS, {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }

    const normalized = normalize(action.payload, arrayOf(showSchema));
    if ('result' in normalized) {
      return u(wrapEntitiesInUpdeepConstants(normalized.entities.show), state);
    }

    return state;
  },
});

const byId = serializeReducers([showsById]);

export const shows = createByIdReducer('publisherId', byId);

export default shows;
