import classNames from 'classnames';
import { memoize } from 'lodash';
import React from 'react';

import { StatusMessageSeverity } from 'config/constants';

import SDSButton from 'views/common/components/SDSButton/SDSButton';

import style from './ToastMessage.scss';

const SEVERITY_TO_CLASS = {
  [StatusMessageSeverity.ERROR]: style.severityError,
  [StatusMessageSeverity.WARNING]: style.severityWarning,
  [StatusMessageSeverity.INFO]: style.severityInfo,
};

type Props = {
  toastMessage: {
    severity: any; // TODO: PropTypes.oneOf(Object.values(StatusMessageSeverity))
    messages: {
      plainText: string;
      link?: string;
    }[];
  };
  buttons?: any[];
};

export class ToastMessage extends React.Component<Props> {
  handleClick = memoize(button => () => {
    button.onClick(this.props.toastMessage);
  });

  renderButton(button: any, index: any) {
    return (
      <SDSButton
        type={button.buttonColor}
        onClick={this.handleClick(button)}
        key={index}
        data-test="toastMessage.dismiss"
      >
        {button.body}
      </SDSButton>
    );
  }

  renderButtons() {
    if (!this.props.buttons || this.props.buttons.length === 0) {
      return null;
    }
    return (
      <div className={style.footer}>{this.props.buttons.map((button, index) => this.renderButton(button, index))}</div>
    );
  }

  render() {
    return (
      // The reason for two nested elements is because of animation. While the inner one animates scale,
      // the outer animates dimensions. The animation looks better this way.
      <div className={style.toastMessageParent} data-test="toastMessage.div">
        <div className={style.toastMessage}>
          <div className={style.body}>
            <div className={classNames(style.severity, SEVERITY_TO_CLASS[this.props.toastMessage.severity])} />
            <div className={style.textMessage}>
              <div>
                {this.props.toastMessage.messages.map(message => {
                  if (message.link) {
                    return (
                      <a target="_blank" href={message.link}>
                        {message.plainText}
                      </a>
                    );
                  }
                  return message.plainText;
                })}
              </div>
            </div>
          </div>
          {/* @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1. */}
          {this.renderButtons(this.props.buttons)}
        </div>
      </div>
    );
  }
}

export default ToastMessage;
