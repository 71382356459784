import { gql } from '@apollo/client';

export const MEDIA_PUBLISHER_FIELDS = gql`
  fragment MediaPublisherFields on Publisher {
    id
    businessProfileId
    orgId
    tier
    topsnapLimit
    videoModeEnabled
    enabledFeatures
  }
`;

export const MEDIA_GET_PUBLISHER = gql`
  ${MEDIA_PUBLISHER_FIELDS}
  query MediaPublisherDetailsQuery($publisherId: ID!) {
    publisher(id: $publisherId) {
      ...MediaPublisherFields
    }
  }
`;
