import { SSAPI_PROXY_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder(SSAPI_PROXY_BASE_URL);

export const feedIngestion = {
  createFeed: builder.createAPICall('feeds'),
  updateFeed: builder.createAPICall('feeds/:feedId'),
  deleteFeed: builder.createAPICall('feeds/:feedId'),
  getFeedsByOwnerId: builder.createAPICall('feeds/byOwner/:ownerId'),
  executeAllFeedsByOwnerId: builder.createAPICall('feeds/byOwner/:ownerId/execute'),
};
