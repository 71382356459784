import _ from 'lodash';

import { assertArg } from 'utils/assertionUtils';

export function stopEventPropagation(event?: Event | null) {
  if (event) {
    event.stopPropagation();
  }
}

export const chainEventHandlers = (handlers: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(handlers).is.array();

  return (event: Event) => {
    handlers.forEach((handler: any) => handler(event));
  };
};

export const onEnterKey = (func: any) => (event: KeyboardEvent) => {
  if (event.key === 'Enter') {
    func(event);
  }
};

export const onEscKey = (func: any) => (event: KeyboardEvent) => {
  if (event.key === 'Escape') {
    func(event);
  }
};

export const blurOnEnterKey = onEnterKey((event: any) => {
  if (event?.target?.blur) {
    event.target.blur();
  }
});

let hiddenInput: any;

export function copyToClipboard(text: any) {
  if (!hiddenInput) {
    hiddenInput = document.createElement('input');
    hiddenInput.id = 'copy-to-clipboard-hidden-input';
    hiddenInput.type = 'text';
    hiddenInput.style.position = 'fixed';
    hiddenInput.style.opacity = '0';
    (hiddenInput as any).style.zIndex = -1;
    (document as any).body.appendChild(hiddenInput);
  }
  hiddenInput.setAttribute('value', text);
  hiddenInput.select();
  document.execCommand('copy');
  hiddenInput.setAttribute('value', '');
}

// Helps testing as 'document.hidden' is readonly property
export function isDocumentHidden() {
  const { prefixHidden } = getHiddenProp();
  if (prefixHidden) {
    return _.get(global, ['document', prefixHidden], {});
  }

  return false;
}

export function getHiddenProp(): any {
  const prefixes = ['', 'webkit', 'moz', 'ms', 'o'];
  // otherwise loop over all the known prefixes until we find one
  for (let i = 0; i < prefixes.length; i++) {
    const prefixHidden = prefixes[i] ? `${prefixes[i]}Hidden` : 'hidden';
    if (prefixHidden in window.document) {
      return { prefix: prefixes[i], prefixHidden };
    }
  }

  // otherwise it's not supported
  return null;
}

export function requestAnimationFrame(func: any): any {
  if (global.requestAnimationFrame) {
    return global.requestAnimationFrame(func);
  }

  return global.setTimeout(func, 0);
}

export function reload() {
  window.location.reload();
}
