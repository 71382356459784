// @flow
import * as apiMiddlewareActions from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { inventoryBundleItemMappingListSchema } from 'state/inventory/schema/inventorySchema';
import * as inventorySelectors from 'state/inventory/selectors/inventorySelectors';

import { EMPTY_ARRAY } from 'config/constants';
import * as proxyAPI from 'utils/apis/proxyAPI';
import * as dateUtils from 'utils/dateUtils';

import {
  BundleStatusType,
  TargetableByType,
  BundleElementType,
  INVENTORY_BUNDLE_TYPE_EXISTING,
  INVENTORY_BUNDLE_TYPE_NEW,
  InventoryBundle,
} from 'types/inventory';
import { Dispatch, GetState } from 'types/redux';

export const LOAD_INVENTORY_ITEM = 'inventory/LOAD_INVENTORY_ITEM';
export const LOAD_INVENTORY_BUNDLES = 'inventory/LOAD_INVENTORY_BUNDLES';
export const LOAD_INVENTORY_BUNDLE_ITEM_MAPPINGS = 'inventory/LOAD_INVENTORY_BUNDLE_ITEM_MAPPINGS';
export const CREATE_INVENTORY_BUNDLES = 'inventory/CREATE_INVENTORY_BUNDLES';
export const CREATE_INVENTORY_BUNDLE_ITEM_MAPPINGS = 'inventory/CREATE_INVENTORY_BUNDLE_ITEM_MAPPINGS';
export const DELETE_INVENTORY_BUNDLE_ITEM_MAPPINGS = 'inventory/DELETE_INVENTORY_BUNDLE_ITEM_MAPPINGS';
const EXPIRED_TIMEOUT = 4;
const INVENTORY_BUNDLES_LIMIT = 5;
const INVENTORY_BUNDLE_ITEM_MAPPINGS_LIMIT = 10;
export const loadInventoryItem: any = (organizationId: any, businessProfileId: any) => {
  return apiMiddlewareActions.createCallAction(
    {
      type: LOAD_INVENTORY_ITEM,
      params: {
        organizationId,
        businessProfileId,
      },
    },
    {
      method: 'GET',
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 2.
      endpoint: proxyAPI.inventory.getInventoryItemByProfileId(organizationId, businessProfileId),
    }
  );
};
export const loadInventoryBundles: any = () => {
  return apiMiddlewareActions.createCallAction(
    {
      type: LOAD_INVENTORY_BUNDLES,
      bailout: (state: any) =>
        !dateUtils.isExpired(inventorySelectors.getActiveInventoryBundlesAge(state), EXPIRED_TIMEOUT, 'minutes'),
    },
    {
      method: 'GET',
      endpoint: proxyAPI.inventory.listInventoryBundles(),
    }
  );
};
export const loadInventoryBundleItemMappings: any = (publisherId: any) => {
  return apiMiddlewareActions.createCallAction(
    {
      type: LOAD_INVENTORY_BUNDLE_ITEM_MAPPINGS,
      params: { publisherId },
      meta: {
        schema: inventoryBundleItemMappingListSchema,
      },
    },
    {
      method: 'GET',
      endpoint: proxyAPI.inventory.listInventoryBundleItemMappings(publisherId),
    }
  );
};
export const createInventoryBundles: any = ({ body }: any) => {
  return apiMiddlewareActions.createCallAction(
    {
      type: CREATE_INVENTORY_BUNDLES,
    },
    {
      method: 'PUT',
      endpoint: proxyAPI.inventory.createInventoryBundles(),
      body,
    }
  );
};
export const createInventoryBundleItemMappings: any = ({ body }: any) => {
  return apiMiddlewareActions.createCallAction(
    {
      type: CREATE_INVENTORY_BUNDLE_ITEM_MAPPINGS,
      meta: {
        schema: inventoryBundleItemMappingListSchema,
      },
    },
    {
      method: 'PUT',
      endpoint: proxyAPI.inventory.createInventoryBundleItemMappings(),
      body,
    }
  );
};
export const deleteInventoryBundleItemMappings: any = ({ body, inventoryBundleIds }: any) => {
  return apiMiddlewareActions.createCallAction(
    {
      type: DELETE_INVENTORY_BUNDLE_ITEM_MAPPINGS,
      params: { inventoryBundleIds },
    },
    {
      method: 'PUT',
      endpoint: proxyAPI.inventory.deleteInventoryBundleItemMappings(),
      body,
    }
  );
};
// Helper function to create new inventory bundles
const batchCreateInventoryBundles = (newBundles: string[] | undefined): any => async (dispatch: Dispatch) => {
  if (newBundles && newBundles.length < 1) {
    return EMPTY_ARRAY;
  }
  const inventoryBundles = newBundles
    ? newBundles.map((bundleName: string) => {
        return {
          displayName: bundleName,
          status: BundleStatusType.ACTIVE,
          targetableBy: TargetableByType.ALL,
        };
      })
    : [];
  const result: any[] = [];
  while (inventoryBundles.length) {
    const inventoryBundlesSegment = inventoryBundles.splice(0, INVENTORY_BUNDLES_LIMIT);
    // @ts-ignore
    const { payload } = await dispatch(createInventoryBundles({ body: inventoryBundlesSegment })); // eslint-disable-line
    result.push(...payload.inventoryBundles);
  }
  return result;
};
// Helper function to create new inventory bundle item mappings
const batchCreateInventoryBundleItemMappings = (bundleIds: string[], publisherItemId: string) => async (
  dispatch: Dispatch
) => {
  if (bundleIds.length < 1) {
    return;
  }
  const inventoryBundleItemMappings = bundleIds.map(bundleId => {
    return {
      parentBundleId: bundleId,
      elementType: BundleElementType.INVENTORY_ITEM,
      elementId: publisherItemId,
    };
  });
  while (inventoryBundleItemMappings.length) {
    const mappingsSegment = inventoryBundleItemMappings.splice(0, INVENTORY_BUNDLE_ITEM_MAPPINGS_LIMIT);
    await dispatch(createInventoryBundleItemMappings({ body: mappingsSegment })); // eslint-disable-line
  }
};
export const saveInventoryData = (
  publisherItemId: string,
  bundles: {
    [tag: string]: string[];
  }
) => async (dispatch: Dispatch, getState: GetState) => {
  const inventoryBundleItemMappingsByBundleIdMap = inventorySelectors.getInventoryBundleItemMappingsByBundleIdMap(
    getState()
  );
  const inventoryBundleByBundleNameMap: {
    [key: string]: InventoryBundle;
  } = inventorySelectors.getActiveInventoryBundlesByNameMap(getState());
  const defaultInventoryBundleIds = Object.keys(inventoryBundleItemMappingsByBundleIdMap);
  if (bundles[INVENTORY_BUNDLE_TYPE_NEW]) {
    const bundle = bundles[INVENTORY_BUNDLE_TYPE_NEW] || EMPTY_ARRAY;
    const newBundles = [...bundle];
    newBundles.forEach(newBundleName => {
      if (newBundleName in inventoryBundleByBundleNameMap) {
        // Add this bundle to INVENTORY_BUNDLE_TYPE_EXISTING
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const bundleId = inventoryBundleByBundleNameMap[newBundleName].id;
        if (!bundles[INVENTORY_BUNDLE_TYPE_EXISTING]?.includes(bundleId)) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          bundles[INVENTORY_BUNDLE_TYPE_EXISTING].push(bundleId);
        }
        // Remove this bundle from INVENTORY_BUNDLE_TYPE_NEW
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const index = bundles[INVENTORY_BUNDLE_TYPE_NEW].indexOf(newBundleName);
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        bundles[INVENTORY_BUNDLE_TYPE_NEW].splice(index, 1);
      }
    });
  }
  // Get new inventory bundles to be created
  const newInventoryBundles = await dispatch(batchCreateInventoryBundles(bundles[INVENTORY_BUNDLE_TYPE_NEW]));
  // Get all the bundle ids for creating new inventory bundle item mappings
  const newBundleIds = (newInventoryBundles as any)
    .map((bundle: any) => {
      return bundle.id;
    })
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    .concat(bundles[INVENTORY_BUNDLE_TYPE_EXISTING].filter(bundleId => !defaultInventoryBundleIds.includes(bundleId)));
  // Create new inventory bundle item mappings
  await dispatch(batchCreateInventoryBundleItemMappings(newBundleIds, publisherItemId));
  // Delete any inventory bundle item mappings that were removed.
  const deleteBundleIds = defaultInventoryBundleIds
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    .filter(bundleId => !bundles[INVENTORY_BUNDLE_TYPE_EXISTING].includes(bundleId))
    .filter(Boolean);
  const deleteMappingIds = deleteBundleIds
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .map(bundleId => inventoryBundleItemMappingsByBundleIdMap[bundleId].id);
  if (deleteMappingIds.length > 0) {
    await dispatch(deleteInventoryBundleItemMappings({ body: deleteMappingIds, inventoryBundleIds: deleteBundleIds }));
  }
};
