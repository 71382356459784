import { combineReducers } from 'redux';

import * as rootActions from 'state/root/actions/rootActions';

// Note: Be wary with increasing the size of this. If an action contains something
//       large in its payload, like an image, in theory we can use a lot of memory.
//       Lower values should be fine though, given that most actions do not contain
//       large objects.
const MAX_RECENT_ACTIONS = 30;

const lastActionIdBeforeStoreReset = (state = 0, action: any) => {
  if (action.type === rootActions.RESET_STORE) {
    return action.actionId;
  }

  return state;
};

const recentActions = (state = [], action: any) => {
  const newState = [...state, action];

  if (newState.length > MAX_RECENT_ACTIONS) {
    newState.shift();
  }

  return newState;
};

const prevAction = (state = {}, action: any) => {
  return action;
};

export default combineReducers({
  lastActionIdBeforeStoreReset,
  prevAction,
  recentActions,
});
