import { BuildType, SSAPI_PROXY_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder(SSAPI_PROXY_BASE_URL);

export const story = {
  update: builder.createAPICall('scs/updateStory'),
  existing: builder.createAPICall('edition/:editionId'),
  status: builder.createAPICall('edition/:editionId/status'),
  statusMultiple: builder.createAPICall('edition/status', {
    query: ['id'],
  }),
  snapIds: builder.createAPICall('edition/:editionId/snapIds'),
  state: builder.createAPICall('edition/:editionId/state'),
  stateBatch: builder.createAPICall('editions/states'),
  create: builder.createAPICall('edition', {
    query: ['numberOfNewSnaps'],
    convertQueryCasing: (arg: any) => arg,
  }),
  duplicate: builder.createAPICall('edition/:editionId/duplicate', {
    query: ['destinationPublisherId'],
    convertQueryCasing: (arg: any) => arg,
  }),
  debug: builder.createAPICall('edition/:editionId/debug'),
  splitSubtitle: builder.createAPICall('edition/:editionId/split-subtitle', {
    query: ['subtitleMediaId', 'requestFreshTranscode'],
    convertQueryCasing: (arg: any) => arg,
  }),
  loadStoryShotAdSlots: builder.createAPICall('scs/ads/getShotAdSlots/:storyId'),
};

export const snapcode = {
  new: builder.createAPICall('snapcode/:linkableType/:linkableId'),
  load: builder.createAPICall('snapcode/:linkableType/:linkableId'),
  update: builder.createAPICall('snapcode/:linkableType/:linkableId/:deeplinkId'),
  preview: builder.createAPICall('snapcode/:linkableType/:linkableId/preview', {
    query: ['buildSetting'],
    convertQueryCasing: (arg: any) => arg,
  }),
  download: builder.createAPICall('snapcode/:linkableType/:linkableId/:snapcodeId/download'),
};

export const shows = {
  list: builder.createAPICall('shows/stage', {
    query: ['publisherId', 'businessProfileId'],
    convertQueryCasing: (arg: any) => arg,
  }),
  createEpisode: builder.createAPICall('shows/:publisherId/episodeStage', {
    query: ['seasonDisplayName', 'seasonNumber'],
    convertQueryCasing: (arg: any) => arg,
  }),
  updateEpisode: builder.createAPICall('shows/:publisherId/episodeStage', {
    query: ['seasonNumber', 'hash'],
    convertQueryCasing: (arg: any) => arg,
  }),
  unlinkEpisode: builder.createAPICall('shows/:publisherId/unlinkEpisode'),
  updateSeasons: builder.createAPICall('shows/:publisherId/updateSeasons', {
    query: ['hash'],
  }),
  createExtra: builder.createAPICall('shows/:publisherId/extraStage', {
    query: ['seasonDisplayName', 'seasonNumber'],
    convertQueryCasing: (arg: any) => arg,
  }),
  unlinkExtra: builder.createAPICall('shows/:publisherId/unlinkExtra'),
};

export const discover = {
  snap: {
    existing: builder.createAPICall('snap/:snapId'),

    multiple: builder.createAPICall('snap/getLargeSnapList'),

    duplicate: builder.createAPICall('snap/:snapId/duplicate', {
      query: ['snapId'],
      convertQueryCasing: (arg: any) => arg,
    }),
  },
};

export const richSnap = {
  snap: {
    new: builder.createAPICall('discover/snap', { convertQueryCasing: (arg: any) => arg }),
    existing: builder.createAPICall('discover/snap/:snapId', { convertQueryCasing: (arg: any) => arg }),
    multiple: builder.createAPICall('discover/snap/getLargeSnapList'),

    build: {
      trigger: builder.createAPICall(`discover/snap/:snapId/build/${BuildType.RICHSNAP}`, {
        convertQueryCasing: (arg: any) => arg,
      }),
    },
    debug: builder.createAPICall('discover/snap/:snapId/debug'),
  },
};

export const hnCategory = {
  getAll: builder.createAPICall('categories'),
};
