// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { PollLayoutTypes } from '@snapchat/web-attachments/lib/polls/markup/components/PollLayouts/PollLayouts';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';

import { PollScreen } from 'config/constants';

import type { AssetID } from './assets';
import type { SnapId } from './common';
import type { EditionID } from './editions';

// workaround for https://github.com/facebook/flow/issues/2405
type Exact<T> = T & Partial<T>;

// Avoiding type spread because of these two issues:
// https://github.com/babel/babel-eslint/issues/443
// https://youtrack.jetbrains.com/issue/WEB-26408
//
// Not working around with flow comment annotations because babel-plugin-flow-runtime doesn't see them.
// https://github.com/codemix/flow-runtime/issues/97

export type { AssetID } from './assets';
export type AttachmentID = number;
export type PollID = SnapId;

export type PollLayoutType = typeof PollLayoutTypes[keyof typeof PollLayoutTypes];

export type EmojiType = {
  id: string;
};

export type ExplanationPageModel = {
  description?: string | null;
  imageAssetId?: AssetID;
};

export type PollEditorProperties = Partial<{
  updateAppButton?: string;
  updateAppHeadline?: string;
}>;

export type EditorProperties = Exact<{
  backgroundAssetId?: AssetID;
  backgroundColor?: string;
  closedHeadline?: string;
  correctAnswer?: number;
  correctAnswerEmoji?: EmojiType;
  explanationPage?: ExplanationPageModel;
  font?: string;
  headingTextColor?: string;
  incorrectAnswerEmoji?: EmojiType;
  layout?: PollLayoutType;
  notVotedHeadline?: string;
  shouldShowLabels?: boolean;
  shouldShowVotesCount?: boolean;
  showExplanationScreen?: boolean;
  votedHeadline?: string;
  wideLogoMedia?: string;
  publisherLogo?: string;
}>;

export type PollType = typeof POLL_TYPE[keyof typeof POLL_TYPE];

type CommonPollPropertiesOpen = {
  appHtml?: string | null;
  attachmentIds: AttachmentID[];
  id: PollID;
  imageAssetIds: AssetID[];
  pollType: PollType;
};

type CommonOption = {
  optionType: 'POLL_OPTION';
};

/** one of the 2 or 4 options or choices in a poll */
export type Option = Exact<
  CommonOption & {
    description?: string | null;
    imageAssetId?: AssetID | null;
    outcomeIndex?: number | null;
  }
>;

export type Options = Option[];

export type ServerQuestion = Exact<{
  id: string | null;
  editorProperties: string | null;
  options: Options;
}>;
export type Question = Exact<{
  id: string | null;
  editorProperties: EditorProperties;
  pollType: PollType;
  options: Options;
}>;

type ServerPollPropertiesOpen = CommonPollPropertiesOpen & {
  editorHtml: string | null;
  questions: ServerQuestion[];
  outcomes?: ServerQuestion | null;
  durationInDays?: number | null;
};

export type ServerPollProperties = Exact<ServerPollPropertiesOpen>;

// @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
export const SERVER_POLL_PROPERTIES: keyof ServerPollProperties[] = [
  'appHtml',
  'attachmentIds',
  'id',
  'imageAssetIds',
  'pollType',
  'editorHtml',
  'questions',
  'outcomes',
  'durationInDays',
];

export type Questions = Question[];

type PollPropertiesOpen = CommonPollPropertiesOpen & {
  questions: Questions;
  editorHtml: PollEditorProperties;
  outcomes: Question | undefined | null;
  durationInDays: number;
};

export type PollProperties = Exact<PollPropertiesOpen>;

export type PollPageData = Exact<{
  editionID?: EditionID;
  publisherName?: string;
}>;

export type PollScreenEnum = typeof PollScreen[keyof typeof PollScreen];

export type PollEditorState = {
  selectedScreen?: PollScreenEnum;
  selectedOutcomeIndex?: number;
  selectedQuestionIndex?: number;
};

export type SetEditorPropertiesFn = (a: Partial<EditorProperties>) => void;

export type CreateAssetUrlFn = (assetId: AssetID) => string;
