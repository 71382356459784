import type { CheckboxStateEnum } from 'config/constants';

export enum CountryCodeCategory {
  // This category should always be used - either as the default if only one category is used, or as
  // the "all categories" option if multiple are used
  DEFAULT = 'DEFAULT',
  DISCOVER_FEED = 'DISCOVER_FEED',
  SEARCH = 'SEARCH',
  SHARE = 'SHARE',
  SNAPCODE = 'SNAPCODE',
}

export type PickedCountryCodesForCategory = string[] | string;
export type PickedCountryCodes = {
  [k in CountryCodeCategory]?: PickedCountryCodesForCategory;
} & {
  [CountryCodeCategory.DEFAULT]: PickedCountryCodesForCategory;
};

export type Country = {
  code: string;
  name: string;
  picked?: {
    [k in CountryCodeCategory]: boolean;
  };
};
export type Countries = {
  [x: string]: Country;
};
export type Region = {
  name: string;
  countries: Countries;
};
export type Regions = {
  [x: string]: Region;
};
export type CountryRegion = {
  country: Country;
  region: Region;
};

export type DerivedState = {
  checkboxState: {
    [k in CountryCodeCategory]: CheckboxStateEnum;
  };
  numPicked: {
    [k in CountryCodeCategory]: number;
  };
  numTotal: number;
  pickedCountryCodes: PickedCountryCodes;
};

export type ContentAccessList = {
  isAllowlist: boolean;
  list: string[];
};
export type ContentAccessLists = {
  [k in Exclude<CountryCodeCategory, CountryCodeCategory.DEFAULT>]: ContentAccessList;
} & {
  [CountryCodeCategory.DEFAULT]?: ContentAccessList;
};
