import React, { memo, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { ZENDESK_BASE_URL } from 'config/constants';
import { externalLink } from 'icons/SDS/allIcons';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { openInNewWindow } from 'utils/locationUtils';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import styles from './HelpCenterLink.scss';

type Props = {
  destination?: HelpCenterDestination;
  destinationLabel?: string;
  linkId?: string;
  'data-test': string;
  customLink?: string;
  customLabel?: React.ReactChild;
  clearDefaultPadding?: boolean;
};

export enum HelpCenterDestination {
  ARTICLES = 'articles',
  ANALYTICS_GLOSSARY = 'analytics_glossary',
  ANALYTICS_REDUCING_DROP_OFF = 'analytics_reducing_drop_off',
  ANALYTICS_OPTIMIZING_FOR_TIME_SPENT = 'analytics_optimizing_for_time_spent',
  ANALYTICS_OPTIMIZING_FOR_UNIQUE_VIEWERS = 'analytics_optimizing_for_unique_viewers',
  CONTENT_SHARING = 'content_sharing',
  CONTENT_TAGGING = 'content_tagging',
  TILES = 'tiles',
  TILE_OPTIMIZER = 'tile_optimizer',
  STORY_SCORING = 'story_scoring',
  BRANDING_BEST_PRACTICES = 'branding_best_practices',
  SEASONS_AND_EPISODES = 'seasons_and_episodes',
  SHOWS = 'shows',
  SINGLE_ASSET = 'single_asset',
  SUBTITLES = 'subtitles',
  HOST_USER_ASSOCIATION = 'host_user_association',
  VIEW_GUIDELINES = 'view_guidelines',
  USER_MANAGEMENT = 'user_management',
  CREATE_STORY = 'create_story',
  REVIEW_BASICS = 'review_basics',
  BRANDING_GUIDELINES = 'branding_guidelines',
  ANALYTICS_OVERVIEW = 'analytics_overview',
  HELP_CENTER = 'help_center',
  CONTACT_US = 'contact_us',
  UPLOADING_GUIDELINES = 'uploading_guidelines',
  STORY_SPEC = 'story_spec',
  STORY_TILES = 'story_tiles',
  HEADLINES_GUIDELINES = 'headlines_guidelines',
  SUBSCRIBE_SNAP = 'subscribe_snap',
  STORY_SUBTITLES = 'story_subtitles',
  QUESTIONS = 'questions',
  SPOTLIGHT = 'spotlight',
  PUBLISHING_DISABLED = 'publishing_disabled',
}

function getLinkURL(props: Props) {
  let url: string = ZENDESK_BASE_URL;
  switch (props.destination) {
    case HelpCenterDestination.ARTICLES:
      url = `${url}/hc/en-us/articles/115015586407-Creating-Articles`;
      break;
    case HelpCenterDestination.ANALYTICS_GLOSSARY:
      url = `${url}/hc/en-us/articles/5288787896467`;
      break;
    case HelpCenterDestination.ANALYTICS_REDUCING_DROP_OFF:
      url = `${url}/hc/en-us/articles/5289517485587`;
      break;
    case HelpCenterDestination.ANALYTICS_OPTIMIZING_FOR_TIME_SPENT:
      url = `${url}/hc/en-us/articles/360059840074-Optimizing-for-Time-Spent`;
      break;
    case HelpCenterDestination.CONTENT_SHARING:
      url = `${url}/hc/en-us/articles/227862987-Channel-Content-Sharing`;
      break;
    case HelpCenterDestination.CONTENT_TAGGING:
      url = `${url}/hc/en-us/articles/5289140716307`;
      break;
    case HelpCenterDestination.TILES:
      url = `${url}/hc/en-us/articles/227813947-Creating-Tiles`;
      break;
    case HelpCenterDestination.TILE_OPTIMIZER:
      url = `${url}/hc/en-us/articles/360043979753-Tile-Optimizer-Results`;
      break;
    case HelpCenterDestination.STORY_SCORING:
      url = `${url}/hc/en-us/articles/1500006719521-Story-Scoring`;
      break;
    case HelpCenterDestination.BRANDING_BEST_PRACTICES:
      url = `${url}/hc/en-us/articles/5289710019219`;
      break;
    case HelpCenterDestination.SEASONS_AND_EPISODES:
      url = `${url}/hc/en-us/articles/1500003298562-Seasons-Episodes-Shows-Partners`;
      break;
    case HelpCenterDestination.SHOWS:
      url = `${url}/hc/en-us/articles/360043265493`;
      break;
    case HelpCenterDestination.SINGLE_ASSET:
      url = `${url}/hc/en-us/articles/1500001307941`;
      break;
    case HelpCenterDestination.SUBTITLES:
      url = `${url}/hc/en-us/articles/5289652578451`;
      break;
    case HelpCenterDestination.ANALYTICS_OPTIMIZING_FOR_UNIQUE_VIEWERS:
      url = `${url}/hc/en-us/articles/360000758247-Optimizing-for-Unique-Viewers`;
      break;
    case HelpCenterDestination.HOST_USER_ASSOCIATION:
      url = `${url}/hc/en-us/articles/5288535481619`;
      break;
    case HelpCenterDestination.VIEW_GUIDELINES:
      url = `${url}/hc/en-us/categories/203990128-Content-Guidelines`;
      break;
    case HelpCenterDestination.USER_MANAGEMENT:
      url = `${url}/hc/en-us/articles/5289430905747-How-to-manage-user-permissions-in-Story-Studio`;
      break;
    case HelpCenterDestination.CREATE_STORY:
      url = `${url}/hc/en-us/articles/1500001307941-How-to-create-a-story-using-a-full-length-vertical-video`;
      break;
    case HelpCenterDestination.REVIEW_BASICS:
      url = `${url}/hc/en-us`;
      break;
    case HelpCenterDestination.BRANDING_GUIDELINES:
      url = `${url}/hc/en-us/articles/5567441715347-How-to-set-up-your-Channel-Branding`;
      break;
    case HelpCenterDestination.ANALYTICS_OVERVIEW:
      url = `${url}/hc/en-us/sections/5568692824467-How-to-Analyze-Performance-with-Analytics`;
      break;
    case HelpCenterDestination.HELP_CENTER:
      url = `${url}/hc/en-us`;
      break;
    case HelpCenterDestination.CONTACT_US:
      url = `${url}/hc/en-us`;
      break;
    case HelpCenterDestination.UPLOADING_GUIDELINES:
      url = `${url}/hc/en-us/sections/5567870834835-How-to-Create-and-Publish-a-Story-`;
      break;
    case HelpCenterDestination.STORY_SPEC:
      url = `${url}/hc/en-us/articles/5289333804563-Snap-Content-Specifications`;
      break;
    case HelpCenterDestination.STORY_TILES:
      url = `${url}/hc/en-us/articles/227813947-How-to-upload-and-create-Tiles`;
      break;
    case HelpCenterDestination.HEADLINES_GUIDELINES:
      url = `${url}/hc/en-us/articles/5288617774995-Tile-and-Headline-Best-Practices`;
      break;
    case HelpCenterDestination.SUBSCRIBE_SNAP:
      url = `${url}/hc/en-us/articles/360049581294-Growing-Subscriber-Base-Best-Practices-`;
      break;
    case HelpCenterDestination.STORY_SUBTITLES:
      url = `${url}/hc/en-us/articles/5289652578451-How-to-add-subtitles-to-your-story`;
      break;
    case HelpCenterDestination.QUESTIONS:
      url = `${url}/hc/en-us`;
      break;
    case HelpCenterDestination.SPOTLIGHT:
      url = `${url}/hc/en-us/articles/16047512412691-How-to-Post-to-Spotlight-from-Story-Studio`;
      break;
    case HelpCenterDestination.PUBLISHING_DISABLED:
      url = `${url}/hc/en-us/articles/15047233083923`;
      break;
    default:
      break;
  }
  return url;
}

function getButtonLabel(props: Props): React.ReactChild {
  switch (props.destination) {
    case HelpCenterDestination.VIEW_GUIDELINES:
      return (
        <FormattedMessage
          id="help-center-link-guidelines"
          description="Link to help center"
          defaultMessage="View Guidelines"
        />
      );
    case HelpCenterDestination.USER_MANAGEMENT:
      return (
        <FormattedMessage
          id="help-center-link-user-management"
          description="Link to help center"
          defaultMessage="User Management"
        />
      );
    case HelpCenterDestination.CREATE_STORY:
      return (
        <FormattedMessage
          id="help-center-link-create-story"
          description="Link to help center"
          defaultMessage="How to Create and Publish a Story"
        />
      );
    case HelpCenterDestination.REVIEW_BASICS:
      return (
        <FormattedMessage
          id="help-center-link-review-basics"
          description="Link to help center"
          defaultMessage="View Review Basics"
        />
      );
    case HelpCenterDestination.BRANDING_GUIDELINES:
      return (
        <FormattedMessage
          id="help-center-link-branding-guidelines"
          description="Link to help center"
          defaultMessage="View Channel Branding Guidelines"
        />
      );
    case HelpCenterDestination.ANALYTICS_OVERVIEW:
      return (
        <FormattedMessage
          id="help-center-link-analytics-overview"
          description="Link to help center"
          defaultMessage="Analytics Overview"
        />
      );
    case HelpCenterDestination.HELP_CENTER:
      return (
        <FormattedMessage
          id="help-center-link-help-center"
          description="Link to help center"
          defaultMessage="Help Center"
        />
      );
    case HelpCenterDestination.CONTACT_US:
      return (
        <FormattedMessage
          id="help-center-link-contact-us"
          description="Link to help center"
          defaultMessage="Contact Us"
        />
      );
    case HelpCenterDestination.UPLOADING_GUIDELINES:
      return (
        <FormattedMessage
          id="help-center-uploading-guidelines"
          description="Link to uploading story guidelines"
          defaultMessage="How to Upload a Story"
        />
      );
    case HelpCenterDestination.STORY_SPEC:
      return (
        <FormattedMessage
          id="help-center-story-spec"
          description="Link to working with video story spec"
          defaultMessage="Story Specs"
        />
      );
    case HelpCenterDestination.STORY_TILES:
      return (
        <FormattedMessage
          id="help-story-tiles-link"
          description="Link on working with story tiles"
          defaultMessage="Tile Specs"
        />
      );
    case HelpCenterDestination.HEADLINES_GUIDELINES:
      return (
        <FormattedMessage
          id="help-story-headline-link"
          description="Link on working with story headlines"
          defaultMessage="Headline Guidelines"
        />
      );
    case HelpCenterDestination.SUBSCRIBE_SNAP:
      return (
        <FormattedMessage
          id="help-story-follow-snap-link"
          description="Link on working with story studio end snap"
          defaultMessage="Grow Your Followers"
        />
      );
    case HelpCenterDestination.STORY_SUBTITLES:
      return (
        <FormattedMessage
          id="help-story-subtitle-snap-link"
          description="Link on working with story studio subtitle snap"
          defaultMessage="Subtitles"
        />
      );
    case HelpCenterDestination.QUESTIONS:
      return (
        <FormattedMessage
          id="help-questions-link"
          description="Link on to frequently asked questions"
          defaultMessage="Check out the Help Center"
        />
      );
    default:
      return (
        <FormattedMessage id="help-center-link-title" description="Link to help center" defaultMessage="Help Center" />
      );
  }
}

function getDestination(props: Props): string {
  if (props.destination === null) {
    return HelpCenterDestination.HELP_CENTER;
  }
  return props.destination!;
}

function HelpCenterLink(props: Props) {
  const url = props.customLink ? props.customLink : getLinkURL(props);
  const destination = props.customLink ? 'custom' : getDestination(props);
  const openLink = useCallback(() => {
    incrementCounter(GrafanaMetrics.HELP_LINKS, { link: destination });
    openInNewWindow(url);
  }, [url, destination]);
  const buttonLabel = props.customLabel ? props.customLabel : getButtonLabel(props);
  return (
    <SDSButton
      type={ButtonType.LINK}
      inlineIcon={externalLink}
      className={props.clearDefaultPadding ? styles.removePadding : ''}
      onClick={openLink}
      data-test={`${props['data-test']}.helpcenter`}
    >
      {buttonLabel}
    </SDSButton>
  );
}

export default memo(HelpCenterLink);
