import { Publisher, PublisherFeature } from 'types/publishers';

export const isRevenueDashboardEnabled = (isMonetizationAnalystViewer: boolean, activePublisher: Publisher | null) => {
  if (!activePublisher) {
    return false;
  }

  const { advertisingEnabled, publisherFeatures } = activePublisher;

  const hasFixedPayments = publisherFeatures && publisherFeatures.includes(PublisherFeature.FIXED_PAYMENTS);

  if (!isMonetizationAnalystViewer || !advertisingEnabled || hasFixedPayments) {
    return false;
  }

  return true;
};
