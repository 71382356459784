import is from 'is_js';
import _ from 'lodash';

import * as transactionsActions from '../actions/transactionsActions';

import u from 'utils/safeUpdeep';

const appendToEntityArrays = (entityMap: any, oldEntityMap: any) => {
  const ret = {};
  _.forOwn(oldEntityMap, (value, key) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ret[key] = value;
  });

  _.forOwn(entityMap, (value, key) => {
    const temp = (oldEntityMap && oldEntityMap[key]) || [];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ret[key] = temp.concat(value);
  });
  return ret;
};

const removeFromEntityArray = (entityMap: any, oldEntityMap: any) => {
  const ret = {};
  _.forOwn(oldEntityMap, (value, key) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ret[key] = value;
  });

  _.forOwn(entityMap, (value, key) => {
    const temp = (oldEntityMap && oldEntityMap[key]) || [];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    ret[key] = _.without(temp, ...value);
  });
  return ret;
};

const transactions = (state = {}, action: any) => {
  if (is.object(action.payload) && action.payload.transactionType) {
    const { transactionType, entityMap } = action.payload;

    switch (
      action.type // eslint-disable-line default-case
    ) {
      case transactionsActions.START_TRANSACTION:
        return u(
          {
            [transactionType]: {
              entityMap: appendToEntityArrays.bind(undefined, entityMap),
            },
          },
          state
        );

      case transactionsActions.COMPLETE_TRANSACTION:
      case transactionsActions.ROLLBACK_TRANSACTION:
        return u(
          {
            [transactionType]: {
              entityMap: removeFromEntityArray.bind(undefined, entityMap),
            },
          },
          state
        );
      default:
    }
  }

  return state;
};

export default transactions;
