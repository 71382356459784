// @flow
import { Environments, ENV } from 'config/constants';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

export const INVENTORY_BUNDLE_TYPE_NEW = 'INVENTORY_BUNDLE_TYPE_NEW';
export const INVENTORY_BUNDLE_TYPE_EXISTING = 'INVENTORY_BUNDLE_TYPE_EXISTING';

export const BundleElementType = enumObject({
  STATUS_UNSET: 'STATUS_UNSET',
  INVENTORY_BUNDLE: 'INVENTORY_BUNDLE',
  INVENTORY_ITEM: 'INVENTORY_ITEM',
});

export const BundleStatusType = enumObject({
  STATUS_UNSET: 'STATUS_UNSET',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
});

export const TargetableByType = enumObject({
  STATUS_UNSET: 'STATUS_UNSET',
  ALL: 'ALL',
  AD_OPS_ONLY: 'AD_OPS_ONLY',
  SALES_ONLY: 'SALES_ONLY',
  MPP_ONLY: 'MPP_ONLY',
  DIRECT_SALES_ONLY: 'DIRECT_SALES_ONLY',
  NONE: 'NONE',
});

export const DefaultShowsBundles =
  ENV === Environments.PROD
    ? {
        INVENTORY_BUNDLE_TYPE_EXISTING: [
          // Snap Commercials (Shows Bundle)
          '9f09b50c-e91c-4788-989f-e5024e797c11',
          // Commercials/Skippable Backfill Eligible
          '6feaee72-4aa3-4589-bc5d-3c6f6e8df80e',
        ],
        INVENTORY_BUNDLE_TYPE_NEW: [],
      }
    : {};

export const DefaultPublisherBundles =
  ENV === Environments.PROD
    ? {
        INVENTORY_BUNDLE_TYPE_EXISTING: [
          // Holding Tank - Discover - MPP (Auction) - Protected Bundle
          '0fb62768-9db9-4d15-afee-2f842cd9ef3e',
          // Holding Tank - Discover - Content (Auction) - Protected Bundle
          '42f4a00f-09c1-4e0a-b717-b1fdd50c2e27',
        ],
        INVENTORY_BUNDLE_TYPE_NEW: [],
      }
    : {};

export type BundleElementTypeEnum = Enum<typeof BundleElementType>;
export type BundleStatusTypeEnum = Enum<typeof BundleStatusType>;
export type TargetableByTypeEnum = Enum<typeof TargetableByType>;

export type InventoryBundle = {
  id: string;
  displayName: string;
  status: BundleStatusTypeEnum;
  targetableBy: TargetableByTypeEnum;
};

export type InventoryBundleItemMapping = {
  id: string;
  parentBundleId: string;
  elementType: BundleElementTypeEnum;
  elementId: string;
};
