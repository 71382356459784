import * as React from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { StatusMessageButton } from 'config/constants';

import { ButtonType } from 'views/common/components/SDSButton/SDSButton';

import type { StatusMessageButtonEnum } from 'types/notifications';

type Button = {
  body: ReactNode;
  buttonColor: string;
  onClick: any;
};

type Buttons = {
  [key in StatusMessageButtonEnum]: Button;
};

const getButtons = (buttonFunctions: { dismiss: (message: any) => any; login: () => any }) => {
  return {
    [StatusMessageButton.DISMISS]: {
      body: (
        <FormattedMessage
          id="dismiss-toast-notification"
          description="Button label for dismissing a toast notification"
          defaultMessage="Dismiss"
        />
      ),
      buttonColor: ButtonType.SECONDARY,
      onClick: buttonFunctions.dismiss,
    },
  };
};

export const getButtonConfig = (
  message: {
    buttons: Array<StatusMessageButtonEnum>;
  },
  buttonFunctions: {
    dismiss: (dismissMessage: any) => any;
    login: () => any;
  }
): Array<Button> => {
  const buttons: Buttons = getButtons(buttonFunctions);
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(Button | undefined)[]' is not assignable to... Remove this comment to see the full error message
  return message.buttons.map((button: StatusMessageButtonEnum) => buttons[button]);
};
