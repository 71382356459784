import { createSelector as s } from 'reselect';

import { createKeySelector, createDynamicKeySelector } from 'state/common/selectorFactories';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';

import { EMPTY_OBJECT } from 'config/constants';

import type { Tile, TileID } from 'types/tiles';

export const getTileData = (state: any) => state.tiles || EMPTY_OBJECT;
export const getTileMap = createKeySelector(getTileData, 'byId', EMPTY_OBJECT);
export const getTileById = createDynamicKeySelector<Tile | undefined | null, TileID>(getTileMap, null);

export const getTileLogosByPublisherId = s(snapAdminSelectors.getTileLogosByPublisherId, tileLogosFun => {
  return (publisherId: any) => {
    return tileLogosFun(publisherId);
  };
});
