import type { LogoDisplayEnum } from 'config/constants';

import type { TagMap } from 'types/publishers';
import type { TileLogo } from 'types/tileLogos';

type PublisherProperties = {
  formalName?: string;
  description?: string;
  primaryColor?: string;
  secondaryColor?: string;
  tags?: TagMap;
  defaultNumSnaps?: number;
  horizontalIconId?: string;
  targetSnapLengthMs?: number;
};

export type TileLogoComponent = {
  componentId: string;
  logo?: TileLogo;
};

export type EditableTileLogo = {
  id: number | undefined | null;
  componentId: string;
  color?: string;
  blob?: string;
  isDeleted?: boolean;
  mediaId?: string;
};

export type EditablePublisherProperties = PublisherProperties & {
  horizontalIconBlob?: string;
  squareIconBlob?: string;
  squareHeroImageBlob?: string;
  squareHeroImageBitmojiTemplateId?: string;
  profileLogoDisplay: LogoDisplayEnum;
  tileLogoComponents?: EditableTileLogo[];
  targetSnapLengthMs?: number;
  horizontalIconId?: string;
  autoApproveComments?: boolean;
};

export enum PropertyKeys {
  FORMAL_NAME = 'formalName',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  COMPANY = 'company',
  DESCRIPTION = 'description',
  HORIZONTAL_ICON_BLOB = 'horizontalIconBlob',
  SQUARE_ICON_BLOB = 'squareIconBlob',
  SQUARE_HERO_IMAGE_BLOB = 'squareHeroImageBlob',
  SQUARE_HERO_IMAGE_BITMOJI_TEMPLATE_ID = 'squareHeroImageBitmojiTemplateId',
  SQUARE_FILLED_ICON_ID = 'defaultFilledIconId',
  PROFILE_LOGO_DISPLAY = 'profileLogoDisplay',
  PRIMARY_COLOR = 'primaryColor',
  SECONDARY_COLOR = 'secondaryColor',
  TAGS = 'tags',
  TIER = 'tier',
  NUM_SNAPS = 'defaultNumSnaps',
  TILE_LOGOS = 'tileLogoComponents',
  HORIZONTAL_ICON_ID = 'horizontalIconId',
  TARGET_SNAP_LENGTH = 'targetSnapLengthMs',
  DYNAMIC_EDITION_AUTO_CREATE_STORY_TIME = 'dynamicEditionAutoCreateStoryTimeUTC',
  AUTO_APPROVE_COMMENTS = 'autoApproveComments',
}

export type GeneratedPublisherProperties = {
  horizontalIconId?: string;
  defaultFilledIconId?: string;
  squareHeroImageId?: string;
};

export type GeneratedPublisherPropertiesKey = keyof GeneratedPublisherProperties;

export type MediaLoading = {
  squareHeroImage: boolean;
  horizontalIcon: boolean;
  squareIcon: boolean;
  tileLogos: boolean;
};

export const GeneratedPropertyKeys: {
  [key: string]: GeneratedPublisherPropertiesKey;
} = {
  // Transcoded Media Ids
  HORIZONTAL_ICON_ID: 'horizontalIconId',
  FILLED_ICON_ID: 'defaultFilledIconId',
  SQUARE_HERO_IMAGE_ID: 'squareHeroImageId',
};

export type PublisherValidation = {
  [key in PropertyKeys]?: boolean;
};
