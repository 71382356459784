import { gql } from '@apollo/client';

export const SETTINGS_PUBLISHER_FIELDS = gql`
  fragment SettingsPublisherFields on Publisher {
    id
    businessProfileId
    orgId
    hostUsername
    mutablePublisherName
    formalName
    description
    advertisingEnabled @include(if: $isSuperAdmin)
    advancedAdsEnabled @include(if: $isSuperAdmin)
    advancedAdsMinSlots @include(if: $isSuperAdmin)
    advancedAdsMaxSlots @include(if: $isSuperAdmin)
    advancedAdsMinSpacing @include(if: $isSuperAdmin)
    advancedAdsLatestFirstSlot @include(if: $isSuperAdmin)
    defaultAdSlots @include(if: $isSuperAdmin)
    defaultAdSlotsMillis @include(if: $isSuperAdmin)
    defaultNumSnaps
    isComScoreIntegrationEnabled @include(if: $isSuperAdmin)
    subscribable @include(if: $isSuperAdmin)
    topsnapLimit @include(if: $isSuperAdmin)
    rollingNewsEnabled @include(if: $isSuperAdmin)
    videoModeEnabled @include(if: $isSuperAdmin)
    adSetting @include(if: $isSuperAdmin)
    moderationLevel @include(if: $isSuperAdmin)
    targetSnapLengthMs
    dynamicEditionAutoCreateStoryTimeUTC {
      hour
      minute
    }
    audioClassification @include(if: $isSuperAdmin)
    adsPreRollEnabled @include(if: $isSuperAdmin)
    dynamicAdsEnabled @include(if: $isSuperAdmin)
    webviewLimit @include(if: $isSuperAdmin)
    tier @include(if: $isSuperAdmin)
    type @include(if: $isSuperAdmin)
    isOurStories @include(if: $isSuperAdmin)
    publisherFeatures @include(if: $isSuperAdmin)
    articleCSS @include(if: $isSuperAdmin)
    primaryColor
    secondaryColor
    primaryColorRGB
    secondaryColorRGB
    urlSafeFormalName
    tags {
      SCC
      WIKI
      MANUAL
    }
    nicknames @include(if: $isSuperAdmin)
    profileLogoDisplay
    squareHeroImageBitmojiTemplateId
    horizontalIconId
    squareHeroImageId
    defaultFilledIconId
    websiteUrl @include(if: $isSuperAdmin)
    tileLogos {
      mediaId
      color
    }

    primaryLanguage @include(if: $isSuperAdmin)
    homeCountry @include(if: $isSuperAdmin)
    defaultShareOption @include(if: $isSuperAdmin)
    geofenceIds @include(if: $isSuperAdmin)
    ageGate @include(if: $isSuperAdmin)
    allowlistCountry @include(if: $isSuperAdmin)
    blocklistCountry @include(if: $isSuperAdmin)
    allowlistDistributionCountry @include(if: $isSuperAdmin)
    blocklistDistributionCountry @include(if: $isSuperAdmin)
    allowlistLanguage @include(if: $isSuperAdmin)
    blocklistLanguage @include(if: $isSuperAdmin)
    isEmployeeOnly @include(if: $isSuperAdmin)
    searchable @include(if: $isSuperAdmin)
    localContent @include(if: $isSuperAdmin)
    publishingApprovals @include(if: $isSuperAdmin)
    contentAccessLists @include(if: $isSuperAdmin) {
      DISCOVER_FEED {
        isAllowlist
        list
      }
      SEARCH {
        isAllowlist
        list
      }
      SNAPCODE {
        isAllowlist
        list
      }
      SHARE {
        isAllowlist
        list
      }
    }
    publishingEnabled @include(if: $isSuperAdmin)
    regions @include(if: $isSuperAdmin)
    defaultSubtitlesLanguage @include(if: $isSuperAdmin)
    managerEmail @include(if: $isSuperAdmin)
    managerName @include(if: $isSuperAdmin)
    editorialRationale @include(if: $isSuperAdmin)
    editorialApproval
    permitAutoPublishFromFeed @include(if: $isSuperAdmin)
    enabledFeatures
  }
`;

export const SETTINGS_GET_PUBLISHER = gql`
  ${SETTINGS_PUBLISHER_FIELDS}
  query SettingsPublisherDetailsQuery($publisherId: ID!, $isSuperAdmin: Boolean!) {
    publisher(id: $publisherId) {
      ...SettingsPublisherFields
    }
  }
`;
