import { StageType } from 'config/constants';

import type { Feed } from 'types/feeds';
import type { Stage } from 'types/stages';

type FeedsStage = Stage<Feed, Partial<Feed>, void>;
export const AUTO_PUBLISH = 'auto';
export const MANUAL_PUBLISH = 'manual';
export const FEEDS_STAGE_CONFIG: FeedsStage = {
  stageName: StageType.HN_FEED,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: [
    'id',
    'ownerId',
    'url',
    'name',
    'categoryId',
    'templateId',
    'autoPublish',
    'approvalDelay',
    'enabled',
    'error',
    'feedType',
    'apiType',
    'useContentFromFeed',
    'useMediaFromFeed',
    'useMediaHeadlineFromFeed',
    'useUrlFromFeed',
  ],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => {
    const autoPublish = (properties as any).autoPublish && (properties as any).autoPublish === AUTO_PUBLISH;
    const approvalDelay = (properties as any).approvalDelay && (properties as any).approvalDelay * 60; // we show minutes in UI but FIS expects seconds
    return {
      ...properties,
      autoPublish,
      approvalDelay,
    };
  },
};
