import invariant from 'invariant';
import is from 'is_js';
import { concat, difference, get, isEqual, keyBy } from 'lodash';
import log from 'loglevel';
import { arrayOf } from 'normalizr';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { getEdition } from 'state/editions/actions/getEditionActions';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import { editionSubscriptionText, getEditionById } from 'state/editions/selectors/editionsSelectors';
import { createEpisode, unlinkEpisode, updateEpisode } from 'state/episodes/actions/episodesActions';
import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as notificationsActions from 'state/notifications/actions/notificationsActions';
import * as notificationsSelectors from 'state/notifications/selectors/notificationsSelectors';
import { recomputePollProperties } from 'state/poll/compute/pollCompute';
import type { ScheduleEpisodeParams } from 'state/publisherTools/actions/publisherToolsActions';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { getSeasonById } from 'state/seasons/selectors/seasonsSelectors';
import { loadShows } from 'state/shows/actions/showsActions';
import { getShows, isAutomaticEpisodeAllocationEnabled } from 'state/shows/selectors/showsSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import { loadSnaps } from 'state/snaps/actions/snapsActions';
import { isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';
import { getSnapById } from 'state/snaps/selectors/snapsSelectors';
import {
  addTileIdsToEdition,
  generateEditionTileIds,
  generateEditionTileIdsMultiple,
  removeEditionTileIds,
  removeTileIdsFromEdition,
} from 'state/tiles/schema/tilesIdUtils';
import { withTransaction } from 'state/transactions/actions/transactionsActions';

import type {
  AdPlacementModeEnum,
  AgeGateEnum,
  EditionScheduleEnum,
  SequenceEnum,
  ShareOptionEnum,
} from 'config/constants';
import {
  AdPlacementMode,
  AgeGate,
  EditionSchedule,
  FileType,
  Sequence,
  ShareOption,
  TransactionType,
  UploadPurpose,
} from 'config/constants';
import { ROOT_GET_PUBLISHER } from 'gql/queries/publisher/rootPublisherQuery';
import { PublisherQueryResponseType, PublisherQueryVarType } from 'gql/types/publisherQueryTypeEnum';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as adSnapIndexUtils from 'utils/adSnapIndexUtils';
import apolloClient from 'utils/apis/apollo';
import {
  buildHashForExistingKeys,
  buildHashForProperties,
  buildShallowDiff,
  pickPropertiesForHashing,
} from 'utils/apis/partialUpdateUtil';
import * as proxyAPI from 'utils/apis/proxyAPI';
import * as scsAPI from 'utils/apis/scsAPI';
import { assertArg, assertState } from 'utils/assertionUtils';
import { apiErrorHandler, isApiError } from 'utils/errors/api/apiErrorUtils';
import type { ErrorContextEnum } from 'utils/errors/errorConstants';
import { ErrorContexts } from 'utils/errors/errorConstants';
import type { InfoContextEnum } from 'utils/errors/infoMessage/infoMessageUtils';
import {
  clearInfoMessage,
  showInfoMessage,
  InfoContext,
  infoMessageHandler,
} from 'utils/errors/infoMessage/infoMessageUtils';
import * as gaUtils from 'utils/gaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { ModalType } from 'utils/modalConfig';
import { nowInUTC } from 'utils/timezoneUtils';

import {
  BLOCK_EDITION_EDITING,
  COPY_SNAP_TO_EDITION,
  COPY_STORY,
  CREATE_STORY,
  GET_EDITION_SNAP_IDS,
  SAVE_EDITION,
  SET_EDITION_PROPERTIES,
  SET_EDITION_SNAP_IDS,
  SET_EDITION_STATE,
  SET_NEW_STORY_INFO,
  MAKE_STORIES_UNAVAILABLE,
  DISCARD_LIVE_EDIT_CHANGES,
} from 'types/actions/editionsActionTypes';
import type { SnapId } from 'types/common';
import { assertSnapId } from 'types/common';
import type {
  BaseEdition,
  Edition,
  EditionID,
  NewStoryInfo,
  LiveEditionWithEpisodeNumber,
  ScheduledEditionWithEpisodeNumber,
} from 'types/editions';
import { StoryState, LiveEditStatus } from 'types/editions';
import type { BusinessProfileID, Publisher, PublisherID } from 'types/publishers';
import type { GetState, Dispatch } from 'types/redux';
import type { Segment } from 'types/segments';
import type { Episode } from 'types/shows';
import { SnapRelationship, SnapType, Snap } from 'types/snaps';
import { PostingStoryType } from 'types/storySnapEditor';

const DEFAULT_MAX_AGE_FOR_LOADING_MILLIS = 50000;
export function getEditionIfStale({
  editionId,
  maxAgeMillis = DEFAULT_MAX_AGE_FOR_LOADING_MILLIS,
}: {
  editionId: EditionID;
  maxAgeMillis?: number;
}) {
  const bailout = (state: any) => {
    const lastUpdated = editionsSelectors.getLastUpdatedById(state)(editionId) || 0;
    return Date.now() - lastUpdated < maxAgeMillis;
  };
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(state: any) => boolean' is not assignable t... Remove this comment to see the full error message
  return getEdition({ editionId, bailout });
}
export const setSnapIds = ({ editionId, snapIds }: { editionId: EditionID; snapIds: SnapId[] }) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapIds).is.array();
  const action = {
    type: SET_EDITION_SNAP_IDS,
    params: {
      editionId,
      snapIds,
    },
  };
  return action;
};
export const reloadSnaps = (editionId: EditionID) => (dispatch: Dispatch, getState: GetState) => {
  const edition: Edition | null = getEditionById(getState())(editionId);
  if (!edition) {
    return Promise.resolve();
  }
  return dispatch(loadSnaps({ snapIds: edition.snapIds }));
};

const getSnapIdsAction = (storyId: EditionID) => (dispatch: Dispatch, getState: GetState) => {
  const story = editionsSelectors.getEditionById(getState())(storyId);
  invariant(story, `could not find edition with id ${storyId}`);

  return dispatch(
    createCallAction(
      {
        type: GET_EDITION_SNAP_IDS,
        params: {
          editionId: storyId,
          previousSnapIds: story.snapIds.slice(),
        },
      },
      {
        endpoint: scsAPI.story.snapIds({ editionId: storyId }),
        finalizer: optimisticJsonFinalizer,
      }
    )
  );
};

export const getSnapIds = ({ editionId }: { editionId: EditionID }) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  try {
    const currentSnapIds = editionsSelectors.getEditionById(getState())(editionId)?.snapIds || [];
    const postFetchAction: { payload: Array<string> } = await dispatch(getSnapIdsAction(editionId));
    const newSnapIds = postFetchAction?.payload;

    const newSnapsAdded = difference(newSnapIds, currentSnapIds);
    if (newSnapsAdded.length > 0) {
      await dispatch(snapsActions.loadSnaps({ snapIds: newSnapsAdded }));
      dispatch(setSnapIds({ editionId, snapIds: newSnapIds }));
    } else if (!isEqual(newSnapIds, currentSnapIds)) {
      // Handle case where snaps may have been deleted or reordered
      dispatch(setSnapIds({ editionId, snapIds: newSnapIds }));
    }
    return Promise.resolve(postFetchAction);
  } catch (error: any) {
    log.warn('Suppressing GET_EDITION_SNAP_IDS error', error);
    error.alreadyHandled = true;
    return Promise.reject(error);
  }
};
export const getEditionIfRequired = ({ editionId }: { editionId: EditionID }) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  return dispatch(
    getEdition({
      editionId,
      bailout: () => !editionsSelectors.editionShouldBeLoaded(getState())(editionId),
    })
  );
};
export const setEditionProperties = (
  {
    editionId,
  }: {
    editionId: EditionID;
  },
  properties: Partial<BaseEdition>
) => ({
  type: SET_EDITION_PROPERTIES,
  params: { editionId },
  payload: properties,
});
export const saveEdition = (
  {
    editionId,
  }: {
    editionId: EditionID;
  },
  hash?: string | null,
  properties: Partial<BaseEdition> = {}
) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(hash).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  const edition = editionsSelectors.getEditionById(getState())(editionId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(edition).is.object();
  return (dispatch(
    createCallAction(
      {
        type: SAVE_EDITION,
        params: { editionId },
        meta: {
          schema: editionSchema,
        },
      },
      {
        method: 'put',
        endpoint: scsAPI.story.existing({ editionId, hash }),
        preprocessor: removeEditionTileIds,
        finalizer: generateEditionTileIds,
        body: properties,
      }
    )
  ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.SAVE_EDITION));
};
const sortByIdWithUndefinedAtEnd = (a: any, b: any) => {
  if (b.id === undefined) {
    return -1;
  }
  if (a.id === undefined) {
    return 1;
  }
  return a.id - b.id;
};
const calculateSegmentHashes = (originalEdition: any, changedSegments: any) => {
  if (!changedSegments) {
    return null;
  }
  // Segments need to be sorted by id for hash calculation
  const changedSegmentsById = keyBy(
    changedSegments.filter((segment: any) => segment.id !== undefined),
    'id'
  );
  const sortedOriginalSegments = (originalEdition.segments || []).slice().sort(sortByIdWithUndefinedAtEnd);
  // If we reached here there definitely have been segment changes
  // We need to include all original segmentIds and the existing props for the changed segments
  // Newly created segments and their props will not be included in the hash
  // Deleted segments ids have to be included in the hash
  return sortedOriginalSegments.map((originalSegment: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(originalSegment.id).is.number();
    let changedSegment = changedSegmentsById[originalSegment.id];
    // If existing segment not in the changed list it was deleted, let's hash with no diff
    if (!changedSegment) {
      changedSegment = { id: originalSegment.id };
    }
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(originalSegment.id).is.equal(changedSegment.id);
    const segmentShallowDiff = buildShallowDiff(originalSegment, changedSegment, ['id']);
    return buildHashForExistingKeys(originalSegment, segmentShallowDiff, [], ['id']);
  });
};
export const setEditionPropertiesAndSave = (
  {
    editionId,
  }: {
    editionId: EditionID;
  },
  propertyInputs: Partial<BaseEdition>
) => {
  return withTransaction(
    TransactionType.SET_EDITION_PROPERTY,
    { edition: editionId },
    (dispatch: any, getState: GetState) => {
      infoMessageHandler(dispatch, InfoContext.SAVING);
      return new Promise((resolve, reject) => {
        const currentEdition = editionsSelectors.getEditionById(getState())(editionId);
        invariant(currentEdition, `could not find edition with id ${editionId}`);
        const currentEditionWithoutTilesIds = removeTileIdsFromEdition(currentEdition);
        let properties = removeTileIdsFromEdition(propertyInputs);
        const segmentHashes = calculateSegmentHashes(currentEditionWithoutTilesIds, properties.segments);
        const shallowDiff = buildShallowDiff(
          currentEditionWithoutTilesIds,
          properties,
          ['id', 'publisherId'],
          ['segments'] // We'll be overriding this one below if it has changed
        );
        const propertiesForHashing = pickPropertiesForHashing(currentEditionWithoutTilesIds, shallowDiff, [
          'id',
          'publisherId',
        ]);
        if (segmentHashes) {
          propertiesForHashing.segments = segmentHashes;
          // The segment diff is already provided via properties. Here we only extract the segment ids
          // as we save the edition already normalized
          shallowDiff.segments = properties.segments;
          // Need to generate a temporary id placeholder for new segments
          properties.segments = properties.segments.map((segment: any, index: any) => {
            if (segment.id) {
              // Adding editionId to existing segment so their ids can be calculated by normalizr in the reducers.
              return { ...segment, editionId: currentEdition.id };
            }
            // Creates temporary id based on new segment hash
            const newSegmentHash = buildHashForProperties(segment, []);
            return { ...segment, id: `PLACEHOLDER-SEGMENT-${newSegmentHash}` };
          });
        }
        properties = addTileIdsToEdition({ id: currentEdition.id, ...properties });
        const hash = buildHashForProperties(propertiesForHashing, []);
        return Promise.resolve()
          .then(() => dispatch(setEditionProperties({ editionId }, properties)))
          .then(() => dispatch(saveEdition({ editionId }, hash, shallowDiff)))
          .then(resolve)
          .catch(reject);
      }).then(
        clearInfoMessage(getState, dispatch, InfoContext.SAVING),
        clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
      );
    },
    (dispatch: any, error: any) => {
      // withTransaction error handler
      // Api errors are treated inside saveEdition method so no need to send another notification
      if (!isApiError(error)) {
        dispatch(showInfoMessage(InfoContext.ERROR_SAVING_STORY));
        // TODO: replace Error with custom cms hierarchy
        error.alreadyHandled = true; // eslint-disable-line no-param-reassign
      }
      throw error;
    }
  );
};
function promptUnsavedAdChangesOnLeave(event: any) {
  const message =
    'You have unsaved ad placement changes as your ads are in an invalid state. Are you sure you want to leave?';
  event.returnValue = message; // eslint-disable-line no-param-reassign
  return message;
}
export function notifyInvalidAdLayout() {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    if (!notificationsSelectors.getInvalidAdToastOpened(getState())) {
      dispatch(notificationsActions.setInvalidAdToast(true));
      window.addEventListener('beforeunload', promptUnsavedAdChangesOnLeave);
      infoMessageHandler(dispatch, InfoContext.INVALID_AD_LAYOUT);
    }
    return Promise.resolve();
  };
}
export function clearInvalidAdLayoutNotification() {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    if (notificationsSelectors.getInvalidAdToastOpened(getState())) {
      dispatch(notificationsActions.setInvalidAdToast(false));
      window.removeEventListener('beforeunload', promptUnsavedAdChangesOnLeave);
      clearInfoMessage(getState, dispatch, InfoContext.INVALID_AD_LAYOUT)();
    }
    return Promise.resolve();
  };
}
export const blockEditionsEditing = ({ storyIds, sequence }: { storyIds: EditionID[]; sequence: SequenceEnum }) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(storyIds).is.array();
  return {
    type: BLOCK_EDITION_EDITING,
    params: { storyIds },
    sequence,
  };
};
// Calculates segment diffs when adding snap if the added snap would be inside an existing segment
const calculateSegmentDiffForAddedSnap = (edition: Edition, snapId: SnapId, index: number) => {
  const snapIdAfter = index === 0 ? null : edition.snapIds[index];
  const segments: Partial<Segment>[] = [];
  let changedSegments = false;
  if (snapIdAfter) {
    edition.segments.forEach(segment => {
      const indexOfSnapInSegment = segment.snapIds.indexOf(snapIdAfter);
      // We can't add snap to beginning or end of segment, so OK to ignore those cases
      if (indexOfSnapInSegment !== -1 && indexOfSnapInSegment !== 0) {
        const segmentSnapIds = segment.snapIds.slice();
        segmentSnapIds.splice(indexOfSnapInSegment, 0, snapId);
        segments.push({ id: segment.id, snapIds: segmentSnapIds });
        changedSegments = true;
      } else {
        segments.push({ id: segment.id });
      }
    });
  }
  if (changedSegments) {
    return segments;
  }
  return null;
};
export const addSnapAndSave = ({
  editionId,
  snapId,
  index,
}: {
  editionId: EditionID;
  snapId: SnapId;
  index: number;
}) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(index).is.number();
  const edition = editionsSelectors.getEditionById(getState())(editionId);
  invariant(edition, `could not find edition with id ${editionId}`);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(edition.snapIds).is.array();
  invariant(index <= edition.snapIds.length, `invalid index ${index}. snapIds ${edition.snapIds.toString()}`);
  const snapIds = edition.snapIds.slice();
  snapIds.splice(index, 0, snapId);
  const properties: Partial<BaseEdition> = {
    snapIds,
  };
  const segmentsDiff = calculateSegmentDiffForAddedSnap(edition, snapId, index);
  if (segmentsDiff) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<Segment>[]' is not assignable to typ... Remove this comment to see the full error message
    properties.segments = segmentsDiff;
  }
  return dispatch(setEditionPropertiesAndSave({ editionId }, properties));
};
export const removeSnapAndSave = ({ editionId, snapId }: { editionId: EditionID; snapId: SnapId }) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  assertSnapId(snapId);
  const edition = editionsSelectors.getEditionById(getState())(editionId);
  invariant(edition, `could not find edition with id ${editionId}`);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(edition.snapIds).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(edition.segments).is.array();
  const updates: Partial<BaseEdition> = {
    snapIds: edition.snapIds.filter(id => id !== snapId),
  };
  // Have to remove from segment as well, but only if a segment includes this snap id
  let segmentChanged = false;
  const segmentDiff: Partial<Segment>[] = [];
  edition.segments.forEach(segment => {
    if (segment.snapIds.includes(snapId)) {
      segmentDiff.push({ id: segment.id, snapIds: segment.snapIds.filter(id => id !== snapId) });
      segmentChanged = true;
    } else {
      segmentDiff.push({ id: segment.id });
    }
  });
  if (segmentChanged) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<Segment>[]' is not assignable to typ... Remove this comment to see the full error message
    updates.segments = segmentDiff;
  }
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(updates.snapIds.length + 1).is.equal(edition.snapIds.length);
  const updatedAdSnapIndexes = adSnapIndexUtils.getUpdatedAdSnapIndexesAfterRemovingSnap(
    getState,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId[] | undefined' is not ass... Remove this comment to see the full error message
    updates.snapIds,
    edition.adSnapIndexes
  );
  if (updatedAdSnapIndexes) {
    updates.adSnapIndexes = updatedAdSnapIndexes;
  }
  return dispatch(setEditionPropertiesAndSave({ editionId }, updates));
};
function recomputeSnapProperties(snap: Snap, editionId: EditionID, getState: GetState) {
  const snapType = snap.type;
  switch (snapType) {
    case SnapType.POLL:
      return recomputePollProperties(snap, editionId, getState);
    case SnapType.ARTICLE:
      return {
        storyId: String(editionId),
      };
    default:
      return {};
  }
}
function recomputeSnapPropertiesAndSave(snapId: SnapId, editionId: EditionID) {
  // @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
  return async (dispatch: Dispatch, getState: GetState) => {
    const snap = getSnapById(getState())(snapId);
    invariant(snap, `could not find snap with id ${snapId}`);
    const properties = recomputeSnapProperties(snap, editionId, getState);
    const empty = !properties || Object.getOwnPropertyNames(properties).length < 1;
    const promise = empty
      ? Promise.resolve({})
      : dispatch(snapsActions.setSnapPropertiesAndSave({ snapId }, properties));
    const bottomId = snap.relatedSnapIds[SnapRelationship.BOTTOM];
    if (bottomId) {
      return Promise.all([promise, dispatch(recomputeSnapPropertiesAndSave(bottomId, editionId))]).then(
        values => values[0]
      );
    }
    return promise;
  };
}

export const copySnapToEdition = ({
  sourceSnapId,
  destinationPublisherId,
  destinationStoryId,
  copySnapToNewStory,
}: {
  sourceSnapId: SnapId;
  destinationPublisherId: PublisherID;
  destinationStoryId: EditionID | undefined | null;
  copySnapToNewStory: boolean;
}) => async (dispatch: Dispatch, getState: GetState) => {
  if (destinationStoryId) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(destinationStoryId).is.number();
  }
  assertSnapId(sourceSnapId);
  const duplicateOptions = copySnapToNewStory
    ? { copyToNewEdition: copySnapToNewStory, newEditionPublisherId: destinationPublisherId }
    : { copyToEditionId: destinationStoryId };
  const copySnapAction = {
    type: COPY_SNAP_TO_EDITION,
    meta: {
      [CALL_API]: {
        endpoint: scsAPI.discover.snap.duplicate({
          snapId: sourceSnapId,
          ...duplicateOptions,
        }),
        finalizer: optimisticJsonFinalizer,
        method: 'put',
      },
    },
  };

  try {
    const copySnapResult = await dispatch(copySnapAction);
    const newSnapId = get(copySnapResult, ['payload', 'id']);
    // FIXME: this is a bit hacky
    const actualDestinationStoryId = parseInt(newSnapId.substring(0, newSnapId.indexOf('-')), 10);
    const selectEdition = () => editionsSelectors.getEditionById(getState())(actualDestinationStoryId);
    // Load the newly created snap
    await dispatch(snapsActions.loadSnap({ snapId: newSnapId }));
    // Recompute CMS-computed properties in the top and bottom snaps
    await dispatch(recomputeSnapPropertiesAndSave(newSnapId, actualDestinationStoryId));
    // Refresh the edition data to get the updated snapIds
    await dispatch(getEdition({ editionId: actualDestinationStoryId }));
    // We also need to ensure we have data for the last snap in the edition, in order to know
    // whether it is a subscribe snap (this is required for the ad snap index calculations)
    {
      const edition = selectEdition();
      invariant(edition, `could not find edition with id ${actualDestinationStoryId}`);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
      await dispatch(snapsActions.loadSnap({ snapId: edition.snapIds[edition.snapIds.length - 1] }));
    }
    // Insert a new ad snap index if necessary
    {
      const edition = selectEdition();
      invariant(edition, `could not find edition with id ${actualDestinationStoryId}`);
      const publisher = publishersSelectors.getPublisherDetailsDataById(getState())(edition.publisherId);
      if (!publisher) {
        const graphQLQuery = ROOT_GET_PUBLISHER;
        return dispatch(() => {
          return apolloClient.query<PublisherQueryResponseType, PublisherQueryVarType>({
            query: graphQLQuery,
            variables: { publisherId: String(edition.publisherId) },
          });
        }).then((res: any) => dispatch(publishersActions.setPublisherDetailsWithGraphQLResponse(res.data)));
      }
    }
    return actualDestinationStoryId;
  } catch (error) {
    return apiErrorHandler(dispatch, ErrorContexts.COPY_SNAP_TO_EDITION)(error);
  }
};
export const duplicateStory = ({
  sourceStoryId,
  destinationPublisherId,
}: {
  sourceStoryId: EditionID;
  destinationPublisherId: PublisherID;
}) => async (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(destinationPublisherId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(sourceStoryId).is.number();
  infoMessageHandler(dispatch, InfoContext.DUPLICATING);
  const edition = editionsSelectors.getEditionById(getState())(sourceStoryId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(edition).is.object();
  invariant(edition, `could not find edition with id ${sourceStoryId}`);
  if (edition.publisherId !== destinationPublisherId) {
    gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'duplicate-a-story', 'start');
  }
  const samePublisher = String(edition.publisherId === destinationPublisherId);
  const publisher = publishersSelectors.getActivePublisherDetails(getState());
  incrementCounterByPublisher(publisher, 'story.copy.started', { samePublisher });
  let promise;
  try {
    const result = await dispatch({
      type: COPY_STORY,
      meta: {
        [CALL_API]: {
          endpoint: scsAPI.story.duplicate({ editionId: sourceStoryId, destinationPublisherId }),
          finalizer: generateEditionTileIds,
          method: 'put',
        },
        schema: editionSchema,
      },
      params: { publisherId: destinationPublisherId },
    });
    const newEditionId = get(result, 'payload.result', null);
    const newTopSnapIds = get(result, `payload.entities.edition.${newEditionId}.snapIds`, []);
    // Load the newly created snap
    await dispatch(snapsActions.loadSnaps({ snapIds: newTopSnapIds }));
    // Recompute CMS-computed properties in the top and bottom snaps
    await Promise.all(
      newTopSnapIds.map((newSnapId: any) => dispatch(recomputeSnapPropertiesAndSave(newSnapId, newEditionId)))
    );
    await dispatch(publishersActions.getActiveEditionsForPublisher({ publisherId: edition.publisherId }));
    promise = Promise.resolve();
    if (edition.publisherId !== destinationPublisherId) {
      gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'duplicate-a-story', 'success');
    }
    incrementCounterByPublisher(publisher, 'story.copy.finished', { samePublisher });
  } catch (error) {
    if (edition.publisherId !== destinationPublisherId) {
      gaUtils.logGAEvent(gaUtils.GAUserActions.EDITION, 'duplicate-a-story', 'error');
    }
    promise = apiErrorHandler(dispatch, ErrorContexts.COPY_STORY)(error);
    incrementCounterByPublisher(publisher, 'story.copy.failed', { samePublisher });
  }
  await clearInfoMessage(getState, dispatch, InfoContext.DUPLICATING)();
  return promise;
};
export const copySnapOrStoryWithModal = ({
  sourceStoryId,
  sourceSnapId,
  destinationPublisherId,
  destinationStoryId,
  copySnapToNewStory,
  hideThisModal,
  copySnapFn,
  copyStoryFn,
}: {
  sourceStoryId: EditionID;
  sourceSnapId: SnapId | undefined | null;
  destinationPublisherId: PublisherID;
  destinationStoryId: EditionID | undefined | null;
  copySnapToNewStory: boolean;
  hideThisModal: () => void;
  copySnapFn: (a: any) => Promise<EditionID>;
  copyStoryFn: (a: any) => Promise<void>;
}) => async (dispatch: Dispatch, getState: GetState) => {
  hideThisModal();
  if (sourceSnapId) {
    // copying snap
    await copySnapFn({
      sourceSnapId,
      destinationPublisherId,
      destinationStoryId,
      copySnapToNewStory,
    });
  } else {
    // copying story
    await copyStoryFn({ sourceStoryId, destinationPublisherId });
  }
};

export const setEditionTitle = ({ editionId, title }: { editionId: EditionID; title: string }) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(title).is.string();
  return dispatch(setEditionPropertiesAndSave({ editionId }, { title }));
};
export const setLiveEditionLock = ({
  editionId,
  nextStatus,
  newsUpdate = false,
}: {
  editionId: EditionID;
  nextStatus: LiveEditStatus;
  newsUpdate: boolean;
}) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(nextStatus).is.inValues(LiveEditStatus);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(newsUpdate).is.boolean();
  const infoMsgContext =
    nextStatus === LiveEditStatus.IN_PROGRESS ? InfoContext.EDIT_LIVE_STORY : InfoContext.PUBLISH_LIVE_CHANGES;
  const errorContext =
    nextStatus === LiveEditStatus.IN_PROGRESS
      ? ErrorContexts.DISABLE_LIVE_EDITION_LOCK
      : ErrorContexts.ENABLE_LIVE_EDITION_LOCK;
  infoMessageHandler(dispatch, infoMsgContext);
  return (dispatch(
    createCallAction(
      {
        type: SET_EDITION_STATE,
        params: { editionId },
        meta: {
          schema: editionSchema,
        },
      },
      {
        method: 'put',
        endpoint: scsAPI.story.state({ editionId }),
        finalizer: generateEditionTileIds,
        body: { id: editionId, liveEditStatus: nextStatus, newsUpdate },
      }
    )
  ) as any)
    .then(
      clearInfoMessage(getState, dispatch, infoMsgContext),
      clearInfoMessage(getState, dispatch, infoMsgContext, true)
    )
    .catch(apiErrorHandler(dispatch, errorContext));
};
export const setEditionsState = (editionsDiff: Partial<BaseEdition>[], setStateImmediately: boolean = true) => {
  const ids = editionsDiff.map(edition => edition.id);
  const isMissingId = ids.some(id => typeof id !== 'number');
  invariant(!isMissingId, 'Missing id for one or more editions');
  return withTransaction(
    TransactionType.SET_EDITION_PROPERTY,
    { edition: ids },
    (dispatch: any, getState: GetState) => {
      return Promise.resolve()
        .then(() => {
          if (setStateImmediately) {
            editionsDiff.forEach(editionDiff => {
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
              dispatch(setEditionProperties({ editionId: editionDiff.id }, editionDiff));
            });
          }
        })
        .then(() => {
          return dispatch({
            type: SET_EDITION_STATE,
            meta: {
              [CALL_API]: {
                method: 'put',
                endpoint: scsAPI.story.stateBatch(),
                finalizer: generateEditionTileIdsMultiple,
                body: editionsDiff,
              },
              schema: arrayOf(editionSchema),
            },
            params: { editionsDiff },
          });
        });
    },
    (dispatch: any, error: any) => {
      // withTransaction error handler
      // Api errors are treated inside saveEdition method so no need to send another notification
      if (!isApiError(error)) {
        dispatch(showInfoMessage(InfoContext.ERROR_SAVING_STORY));
        // TODO: replace Error with custom cms hierarchy
        error.alreadyHandled = true; // eslint-disable-line no-param-reassign
      }
      throw error;
    }
  );
};
export const setEditionState = (
  editionId: EditionID,
  state: StoryState,
  params: Partial<BaseEdition> = {},
  setStateImmediately: boolean = true
) => (dispatch: Dispatch, getState: GetState) => {
  return dispatch(setEditionsState([{ id: editionId, state, ...params }], setStateImmediately));
};
export type CreateStoryParams = {
  publisherId: PublisherID;
  title: string;
  numberOfSnaps?: number;
  singleAssetVideoFile?: File;
  singleAssetVideoId?: string;
  postingStoryType?: PostingStoryType;
};
const getCreateStoryEndpoint = (params: any, state: any) => {
  return scsAPI.story.create(params);
};
export const createStory = ({
  publisherId,
  title,
  numberOfSnaps,
  singleAssetVideoFile,
  singleAssetVideoId,
  postingStoryType = PostingStoryType.UNKNOWN,
}: CreateStoryParams) => async (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(title).is.string.and.is.truthy();

  let numberOfNewSnaps = is.number(numberOfSnaps)
    ? numberOfSnaps
    : adSnapIndexUtils.getMinSnapsToSatisfyAdRules(getState, publisherId);
  // If single asset media, upload media first and get the resulting transcoded media id
  let singleAssetVideoTranscodedMediaId;
  if (singleAssetVideoFile) {
    // Forcing number of snaps to be 0, the service will auto-create needed snaps
    numberOfNewSnaps = 0;
    const claimMediaActionResult: any = await dispatch(
      mediaActions.uploadMedia(singleAssetVideoFile, FileType.VIDEO, {
        purpose: UploadPurpose.SINGLE_ASSET_STORY,
      })
    );
    singleAssetVideoTranscodedMediaId = claimMediaActionResult.payload.id;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(singleAssetVideoTranscodedMediaId).is.string.and.is.truthy();
  }
  if (singleAssetVideoId) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(singleAssetVideoId).is.string.and.is.truthy();
    numberOfNewSnaps = 0;
    singleAssetVideoTranscodedMediaId = singleAssetVideoId;
  }

  invariant(typeof numberOfNewSnaps === 'number', 'numberOfNewSnaps must be a number');
  const body = {
    title,
    publisherId,
    state: StoryState.NEW,
    postingStoryType,
    ...(singleAssetVideoTranscodedMediaId
      ? { videoTracks: [{ transcodedMediaId: singleAssetVideoTranscodedMediaId }] }
      : {}),
  };
  const params = {
    numberOfNewSnaps,
  };
  return dispatch({
    type: CREATE_STORY,
    meta: {
      [CALL_API]: {
        method: 'POST',
        endpoint: getCreateStoryEndpoint(params, getState()),
        preprocessor: removeEditionTileIds,
        finalizer: generateEditionTileIds,
        body,
      },
      schema: editionSchema,
    },
  });
};
export const scheduleEdition = (
  editionId: EditionID,
  startDate: string,
  endDate: string | undefined | null,
  scheduleType: EditionScheduleEnum
) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(startDate).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endDate).is.null.or.is.undefined.or.is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(scheduleType).is.inValues(EditionSchedule);
  const context = InfoContext.SCHEDULING_STORY;
  infoMessageHandler(dispatch, context);
  return dispatch(
    setEditionState(
      editionId,
      StoryState.SCHEDULED,
      { startDate, endDate, scheduleType },
      false // Do not set state immediately in redux store, wait for API return
    )
  ).then(clearInfoMessage(getState, dispatch, context), clearInfoMessage(getState, dispatch, context, true));
};
export const takeDownLiveEdition = ({ editionId }: { editionId: EditionID }) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const setReadyAction = await dispatch(setEditionState(editionId, StoryState.NEW));
  const edition = editionsSelectors.getEditionById(getState())(editionId);
  invariant(edition, `could not find edition with id ${editionId}`);
  await dispatch(publishersActions.reloadEditionStates(edition.publisherId));
  return setReadyAction;
};
export const setEditionShareOption = ({
  editionId,
  shareOption,
}: {
  editionId: EditionID;
  shareOption: ShareOptionEnum;
}) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(shareOption).is.inValues(ShareOption);
  return dispatch(setEditionPropertiesAndSave({ editionId }, { shareOption }));
};
export const setEditionAdPlacementMode = ({
  editionId,
  adPlacementMode,
}: {
  editionId: EditionID;
  adPlacementMode: AdPlacementModeEnum;
}) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(adPlacementMode).is.inValues(AdPlacementMode);
  return dispatch(setEditionPropertiesAndSave({ editionId }, { adPlacementMode }));
};
export const setEditionSnapIds = ({ editionId, snapIds }: { editionId: EditionID; snapIds: SnapId[] }) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapIds).is.array();
  return dispatch(setEditionPropertiesAndSave({ editionId }, { snapIds }));
};
const setEditionsStateAndFinalize = (
  editionsDiff: Partial<BaseEdition>[],
  {
    infoContext,
    errorContext,
    reload = false,
    goToHome = false,
  }: {
    infoContext?: InfoContextEnum;
    errorContext: ErrorContextEnum;
    reload?: boolean;
    goToHome?: boolean;
  }
) => async (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const firstEditionId = editionsDiff[0].id;
  invariant(firstEditionId, 'Missing id for one or more editions');
  const edition = editionsSelectors.getEditionById(getState())(firstEditionId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(edition).is.object();
  invariant(edition, `could not find edition with id ${firstEditionId}`);
  const { publisherId } = edition;
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(errorContext).is.string();
  // Info message is optional
  if (infoContext) {
    infoMessageHandler(dispatch, infoContext);
  }
  let returnAction = null;
  let rejected = false;
  try {
    returnAction = await dispatch(setEditionsState(editionsDiff, false));
    if (reload) {
      await dispatch(publishersActions.reloadEditionStates(publisherId));
    }
    if (goToHome) {
      await dispatch(routerActions.goToHomepage({ overwriteHistory: true, publisherId }));
    }
  } catch (error) {
    rejected = true;
    returnAction = apiErrorHandler(dispatch, errorContext)(error);
  } finally {
    if (infoContext) {
      clearInfoMessage(getState, dispatch, infoContext, rejected)();
    }
  }
  return returnAction;
};
export const archiveEdition = (editionId: EditionID) =>
  setEditionsStateAndFinalize([{ id: editionId, state: StoryState.ARCHIVED }], {
    infoContext: InfoContext.ARCHIVING,
    errorContext: ErrorContexts.ARCHIVE_EDITION,
    reload: true,
  });
export const hideStory = (editionId: EditionID) =>
  setEditionsStateAndFinalize([{ id: editionId, state: StoryState.HIDDEN }], {
    errorContext: ErrorContexts.HIDE_EDITION,
    reload: true,
  });
export const deleteStory = (editionId: EditionID) =>
  setEditionsStateAndFinalize([{ id: editionId, state: StoryState.DELETED }], {
    infoContext: InfoContext.DELETING_STORY,
    errorContext: ErrorContexts.DELETE_EDITION,
    goToHome: true,
  });
export const setAgeGate = (editionId: EditionID, ageGate: AgeGateEnum) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(ageGate).is.inValues(AgeGate);
  if (ageGate === AgeGate.EXPLICIT) {
    return dispatch(setEditionPropertiesAndSave({ editionId }, { ageGate, shareOption: ShareOption.NO_SHARE }));
  }
  return dispatch(setEditionPropertiesAndSave({ editionId }, { ageGate }));
};
export const setIsSponsored = (editionId: EditionID, isSponsored: boolean) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  return dispatch(setEditionPropertiesAndSave({ editionId }, { isSponsored }));
};
export const setEditionPrivateArchiveLock = ({
  editionId,
  hiddenLock,
}: {
  editionId: EditionID;
  hiddenLock: boolean;
}) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(hiddenLock).is.boolean();
  const edition = editionsSelectors.getEditionById(getState())(editionId);
  invariant(edition, `could not find edition with id ${editionId}`);
  infoMessageHandler(dispatch, InfoContext.SAVING);
  return dispatch(setEditionState(editionId, edition.state, { hiddenLock })).then(
    clearInfoMessage(getState, dispatch, InfoContext.SAVING),
    clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
  );
};
export const fetchFirstSnapOfEditions = (editionIds: EditionID[]) => (dispatch: Dispatch, getState: GetState) => {
  const snapIds = editionIds
    .map(editionId => editionsSelectors.getEditionById(getState())(editionId))
    .map(edition => get(edition, 'snapIds.0'))
    .filter(is.existy);
  if (!snapIds.length) {
    return null;
  }
  // If there are too many snapIds, then google's api will reject the request for being too large
  const snapIdSegments = [];
  while (snapIds.length) {
    const snapIdSegment = snapIds.splice(0, 100);
    snapIdSegments.push(snapIdSegment);
  }
  return Promise.all(
    snapIdSegments.map(snapIdSegment => {
      return dispatch(snapsActions.loadSnapsIfRequired({ snapIds: snapIdSegment }));
    })
  );
};
const moveLiveAndScheduledFutureStories = (
  liveAndScheduledFutureStories: (LiveEditionWithEpisodeNumber | ScheduledEditionWithEpisodeNumber)[],
  params: ScheduleEpisodeParams,
  publisherId: number,
  businessProfileId: BusinessProfileID,
  movingIndex: number | undefined
) => (dispatch: Dispatch, getState: GetState) => {
  // if rolling back the array of episodes need to be reversed to ensure slots free up at the right time
  const sortedLiveAndScheduledStories = liveAndScheduledFutureStories.sort((a, b) => {
    return a.episodeNumber - b.episodeNumber;
  });
  const stories =
    movingIndex && movingIndex > 0 ? sortedLiveAndScheduledStories.reverse() : sortedLiveAndScheduledStories;
  return stories.reduce(
    async (
      previousPromise: Promise<unknown>,
      story: LiveEditionWithEpisodeNumber | ScheduledEditionWithEpisodeNumber
    ) => {
      await previousPromise;
      const storyId = story.id.toString();
      const newEpisodeProperties = {
        id: storyId,
        showId: params.showId,
        seasonId: params.seasonId,
        episodeNumber: movingIndex ? story.episodeNumber + movingIndex : story.episodeNumber,
        title: story.title,
      };
      return dispatch(updateEpisode(publisherId, businessProfileId, params.seasonNumber, newEpisodeProperties)).catch(
        (err: Error) => {
          const publisher = publishersSelectors.getActivePublisherDetails(getState());
          incrementCounterByPublisher(publisher, 'Shows.moveEpisode.failed', { direction: movingIndex });
          return Promise.reject(err);
        }
      );
    },
    Promise.resolve()
  );
};
const unlinkScheduledEpisode = (episode: Episode, publisher: Publisher) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const story = editionsSelectors.getEditionById(getState())(episode.id);
  // if the story has ever been live then don't unlink it
  if (story?.firstLiveDate) {
    return;
  }
  const publisherId = publisher?.id;
  const businessProfileId = publisher?.businessProfileId;
  const shows = getShows(getState())(publisherId);
  const showId = shows.length ? shows[0].id : null;
  const id = episode.id.toString();
  if (episode && showId) {
    // unlink the story
    const episodeProperties = {
      id,
      showId,
      seasonId: episode.seasonId,
      episodeNumber: episode.episodeNumber,
      title: episode.title,
    };
    await dispatch(unlinkEpisode(publisherId, businessProfileId, episodeProperties))
      .then(() => {
        const currentSeason = getSeasonById(getState())(episode.seasonId);
        if (!currentSeason?.episodes.length) {
          return Promise.resolve();
        }
        const episodesAfterUnlinkedStory = currentSeason?.episodes
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Episode' is not assignable to pa... Remove this comment to see the full error message
          .map((ep: Episode) => episodesSelectors.getEpisodeById(getState())(ep))
          .filter((episodeMap: Episode | null | undefined) => {
            return episodeMap ? episodeMap.episodeNumber > episode.episodeNumber : false;
          });
        const params = {
          showId,
          seasonId: currentSeason?.id,
          seasonNumber: currentSeason?.seasonNumber,
        };
        const moveBackIndex = -1;
        return dispatch(
          moveLiveAndScheduledFutureStories(
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
            episodesAfterUnlinkedStory,
            params,
            publisherId,
            businessProfileId,
            moveBackIndex
          )
        );
      })
      .catch((err: Error) => {
        incrementCounterByPublisher(publisher, 'Shows.unlinkEpisode.failed.unlinkScheduledEpisode');
        log.error(`When making episode unavailible, failed to unlink the episode ${id}`, err);
        return Promise.reject(err);
      });
  }
};
export const makeStoriesUnavailable = (
  storyIds: EditionID[],
  scheduleImmediately: boolean | undefined | null = false
) => async (dispatch: Dispatch, getState: GetState) => {
  const moveToDraftList: Array<EditionID> = [];
  const hideList: any = [];
  const setEndDateList: any = [];
  // Sorting stories into different buckets according to what state slice needs to change
  storyIds.forEach(storyId => {
    const story = editionsSelectors.getEditionById(getState())(storyId);
    invariant(story, `could not find edition with id ${storyId}`);
    if (editionEntityHelpers.isScheduleToLive(story)) {
      moveToDraftList.push(storyId);
    } else if (editionEntityHelpers.isScheduleToArchive(story)) {
      hideList.push(storyId);
    } else if (editionEntityHelpers.isAvailable(story)) {
      setEndDateList.push(storyId);
    } else {
      throw new Error('Only available editions can be made unavailable');
    }
  });
  let editionsDiff: any = [];
  // If all editions can be moved to unavailable immediately don't even show the modal.
  if (setEndDateList.length > 0) {
    let endDate = {};
    if (scheduleImmediately) {
      endDate = nowInUTC();
    } else {
      const results: any = await dispatch(
        modalsActions.showModal(ModalType.SCHEDULE_STORY, 'ScheduleStory', {
          storyIds: setEndDateList,
          makeUnavailable: true,
        })
      );
      // If no endDate was returned the operation was cancelled
      if (!results.endDate) {
        return Promise.resolve();
      }
      endDate = results.endDate;
    }
    editionsDiff = concat(
      editionsDiff,
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
      setEndDateList.map(id => ({ id, endDate }))
    );
  }
  editionsDiff = concat(
    editionsDiff,
    moveToDraftList.map(id => ({ id, state: StoryState.NEW }))
  );
  editionsDiff = concat(
    editionsDiff,
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    hideList.map(id => ({ id, state: StoryState.HIDDEN }))
  );
  return dispatch(
    setEditionsStateAndFinalize(editionsDiff, {
      infoContext: InfoContext.MAKE_STORY_UNAVAILABLE,
      errorContext: ErrorContexts.SET_EDITION_STATE,
      reload: true,
    })
  ).then(() => {
    const publisher = publishersSelectors.getActivePublisherDetails(getState());
    const isShow = publishersSelectors.activePublisherIsShow(getState());
    if (isAutomaticEpisodeAllocationEnabled(getState()) && isShow && moveToDraftList.length) {
      const sortedEpisodes = moveToDraftList
        .map((id: EditionID) => {
          return episodesSelectors.getEpisodeById(getState())(id);
        })
        .sort((a: Episode | null | undefined, b: Episode | null | undefined) => {
          if (!a || !b) {
            return 0;
          }
          return a.episodeNumber + b.episodeNumber;
        });
      sortedEpisodes.reduce(async (previousPromise, episode) => {
        await previousPromise;
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        return dispatch(unlinkScheduledEpisode(episode, publisher));
      }, Promise.resolve());
    }
  });
};
const storyDiffs = (
  storyIds: EditionID[],
  startDate: any,
  endDate: any,
  hnBreakingNews: boolean | undefined | null = false
) => (dispatch: Dispatch, getState: GetState) => {
  return storyIds.map(storyId => {
    const story: Edition | undefined | null = editionsSelectors.getEditionById(getState())(storyId);
    if (!story) {
      throw new Error(`Missing story ${storyId}`);
    }
    let scheduleType;
    if (story.state === StoryState.NEW || story.state === StoryState.READY) {
      scheduleType = EditionSchedule.SCHEDULE_TO_GO_LIVE;
    } else if (story.state === StoryState.HIDDEN) {
      scheduleType = EditionSchedule.SCHEDULE_FOR_ARCHIVING;
    } else {
      throw new Error(`Cant schedule edition in state ${story.state}`);
    }
    return {
      id: story.id,
      state: StoryState.SCHEDULED,
      scheduleType,
      startDate,
      endDate,
      ...(hnBreakingNews ? { hnBreakingNews } : {}),
    };
  });
};
const makeStoriesAvailableInner = (storyIds: EditionID[], isFromHomepage: boolean | undefined | null = true) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  let hasAddedDetails = true;
  await dispatch(publishersActions.openPublisherDetailsModal('ScheduleStory')).catch(() => {
    hasAddedDetails = false;
  });
  if (!hasAddedDetails) {
    return Promise.resolve();
  }
  const results: any = await dispatch(
    modalsActions.showModal(ModalType.SCHEDULE_STORY, 'ScheduleStory', {
      storyIds,
      makeUnavailable: false,
      isFromHomepage,
    })
  );
  // If no startDate was returned the operation was cancelled
  if (!results.startDate) {
    return Promise.resolve();
  }
  const publisher = publishersSelectors.getActivePublisherDetails(getState());
  const isShow = publishersSelectors.activePublisherIsShow(getState());
  const editionsToSave: Partial<BaseEdition>[] = await dispatch(
    storyDiffs(storyIds, results.startDate, results.endDate)
  );
  return dispatch(
    setEditionsStateAndFinalize(editionsToSave, {
      infoContext: InfoContext.MAKE_STORY_AVAILABLE,
      errorContext: ErrorContexts.SET_EDITION_STATE,
      reload: true,
    })
  )
    .then(() => {
      if (isShow) {
        incrementCounterByPublisher(publisher, 'Shows.SchedulingEpisodeV1', { succeded: 'true' });
      }
    })
    .catch((err: Error) => {
      if (isShow) {
        incrementCounterByPublisher(publisher, 'Shows.SchedulingEpisodeV1', { succeded: 'false' });
      }
      return Promise.reject(err);
    });
};
const appendEpisodeMetadataIfNecessary = (
  params: ScheduleEpisodeParams,
  editionId: number,
  publisherId: number,
  businessProfileId: BusinessProfileID,
  seasonNumber: number,
  seasonDisplayName: string | undefined | null,
  liveAndScheduledFutureStories: LiveEditionWithEpisodeNumber[] | ScheduledEditionWithEpisodeNumber[]
) => async (dispatch: Dispatch, getState: GetState) => {
  // check if the story has episode metadata
  const episode = episodesSelectors.getEpisodeById(getState())(editionId);
  const id = editionId.toString();
  const publisher = publishersSelectors.getActivePublisherDetails(getState());
  if (episode) {
    // check if it the existing episode metadata matches the metadata we are going to assign to it
    if (episode.episodeNumber === params.episodeNumber && episode.seasonId === params.seasonId) {
      // if it matches then don't let the create episode go ahead since it's unneccessary
      log.info(`Will not append episode metadata for episode ${id} as it is already in season ${episode.seasonId}`);
      return Promise.resolve();
    }
    // if it doesn't match then unlink the current episode and let the creation go ahead
    const episodeProperties = {
      id,
      showId: params.showId,
      seasonId: params.seasonId,
      episodeNumber: params.episodeNumber,
      title: params.title,
    };
    await dispatch(unlinkEpisode(publisherId, businessProfileId, episodeProperties)).catch((err: Error) => {
      incrementCounterByPublisher(publisher, 'Shows.unlinkEpisode.failed.appendEpisodeMetadataIfNecessary.');
      log.error(`When appending the metadata, the episode already had metadata. Failed to unlink story ${id}`, err);
      return Promise.reject(err);
    });
  }
  // if the episode does not exist, then let the creation go ahead
  return dispatch(
    createEpisode(publisherId, businessProfileId, seasonNumber, seasonDisplayName, { ...params, id })
  ).catch((err: Error) => {
    incrementCounterByPublisher(publisher, 'Shows.createEpisode', { succeded: 'false' });
    log.error(
      `Failed to create episode for story ${editionId}, with season number ${seasonNumber} and episode number ${params.episodeNumber}`,
      err
    );

    // restore episodes numbers to whatever they were before the user tried to append the metadata
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
    dispatch(moveLiveAndScheduledFutureStories(liveAndScheduledFutureStories, params, publisherId, businessProfileId));
    // this ensures we don't try to go ahead with scheduling
    return Promise.reject(err);
  });
};
const makeEpisodeAvailable = (storyIds: EditionID[]) => async (dispatch: Dispatch, getState: GetState) => {
  return dispatch(
    modalsActions.showModal(ModalType.SCHEDULE_EPISODE, 'ScheduleEpisode', {
      storyIds,
      preScheduleEpisode: async (
        params: ScheduleEpisodeParams,
        publisherId: number,
        businessProfileId: BusinessProfileID,
        editionId: number | undefined,
        seasonNumber: number,
        seasonDisplayName: string | undefined | null,
        liveAndScheduledFutureStories: LiveEditionWithEpisodeNumber[] | ScheduledEditionWithEpisodeNumber[]
      ) => {
        if (!editionId) {
          return Promise.reject(new Error('no edition id provided'));
        }
        const moveForwardIndex = 1; // move all future episodes forward by 1
        // update the current edition with the episode metadata if necessary
        const story: Edition | undefined | null = editionsSelectors.getEditionById(getState())(editionId);
        const isEpisodeEditable = story?.state === StoryState.NEW || story?.state === StoryState.HIDDEN;
        if (isEpisodeEditable) {
          return dispatch(
            withTransaction(
              TransactionType.SET_EPISODE_PROPERTY,
              { edition: editionId },
              () => {
                return dispatch(
                  moveLiveAndScheduledFutureStories(
                    liveAndScheduledFutureStories,
                    params,
                    publisherId,
                    businessProfileId,
                    moveForwardIndex
                  )
                ).then(() =>
                  dispatch(
                    appendEpisodeMetadataIfNecessary(
                      params,
                      editionId,
                      publisherId,
                      businessProfileId,
                      seasonNumber,
                      seasonDisplayName,
                      liveAndScheduledFutureStories
                    )
                  )
                );
              },
              (dispatchError: any, error: any) => {
                // withTransaction error handler
                log.error(`Failed to append metadata to story ${editionId}`);
                throw error;
              }
            )
          );
        }
        return Promise.reject(new Error(`Cannot update live or scheduled episode ${editionId}`));
      },
      onScheduleEpisode: async (
        startDate: string | null | undefined,
        endDate: string | null | undefined,
        params: ScheduleEpisodeParams,
        publisherId: number,
        businessProfileId: BusinessProfileID,
        storyId: number,
        liveAndScheduledFutureStories: LiveEditionWithEpisodeNumber[] | ScheduledEditionWithEpisodeNumber[],
        isEpisodeLocked: boolean
      ) => {
        // check if the story has episode metadata
        await dispatch(loadShows(publisherId, businessProfileId));
        const episode = episodesSelectors.getEpisodeById(getState())(storyId);

        if (!episode) {
          log.error(`Will not schedule episode without metadata ${storyId}`);
          return Promise.reject(`Should not schedule episode ${storyId} without metadata`);
        }

        const editionsToSave = await dispatch(storyDiffs(storyIds, startDate, endDate));
        const publisher = publishersSelectors.getActivePublisherDetails(getState());
        return dispatch(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
          setEditionsStateAndFinalize(editionsToSave, {
            infoContext: InfoContext.MAKE_STORY_AVAILABLE,
            errorContext: ErrorContexts.SET_EDITION_STATE,
            reload: true,
          })
        )
          .then(() => {
            incrementCounterByPublisher(publisher, 'Shows.SchedulingEpisodeV2', { succeded: 'true' });
          })
          .catch((err: Error) => {
            // if scheduling fails unlink the episode
            incrementCounterByPublisher(publisher, 'Shows.SchedulingEpisodeV2', { succeded: 'false' });
            log.error(`Failed to schedule episode ${storyId}`, err);

            if (isEpisodeLocked || !episode) {
              return Promise.reject(err);
            }
            const id = storyId.toString();
            const episodeProperties = {
              id,
              showId: params.showId,
              seasonId: params.seasonId,
              episodeNumber: params.episodeNumber,
              title: params.title,
            };
            return dispatch(unlinkEpisode(publisherId, businessProfileId, episodeProperties))
              .then(() => {
                // restore episodes numbers to whatever they were before the user tried to schedule
                return dispatch(
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
                  moveLiveAndScheduledFutureStories(
                    liveAndScheduledFutureStories,
                    params,
                    publisherId,
                    businessProfileId
                  )
                );
              })
              .catch((error: Error) => {
                incrementCounterByPublisher(publisher, 'Shows.unlinkEpisode.failed.onScheduleEpisode');
                log.error(`After failing to schedule episode, failed to unlink the episode ${id}`, err);
                return Promise.reject(error);
              });
          });
      },
    })
  );
};
const makeStoryAvailableImmediately = (
  storyIds: EditionID[],
  hnBreakingNews: boolean | undefined | null = false
) => async (dispatch: Dispatch, getState: GetState) => {
  const editionsToSave = await dispatch(storyDiffs(storyIds, nowInUTC(), null, hnBreakingNews));
  return dispatch(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
    setEditionsStateAndFinalize(editionsToSave, {
      infoContext: InfoContext.MAKE_STORY_AVAILABLE,
      errorContext: ErrorContexts.SET_EDITION_STATE,
      reload: true,
    })
  );
};
export const makeStoriesAvailable = (
  storyIds: EditionID[],
  isFromHomepage: boolean | undefined | null = false,
  scheduleImmediately: boolean | undefined | null = false,
  hnBreakingNews: boolean | undefined | null = false
) => async (dispatch: Dispatch, getState: GetState) => {
  // Prevent the UI from editing the edition
  dispatch(blockEditionsEditing({ storyIds, sequence: Sequence.START }));
  const state = getState();
  const isShow = publishersSelectors.activePublisherIsShow(state);
  try {
    if (isAutomaticEpisodeAllocationEnabled(state) && isShow) {
      await dispatch(makeEpisodeAvailable(storyIds));
    } else if (scheduleImmediately) {
      await dispatch(makeStoryAvailableImmediately(storyIds, hnBreakingNews));
    } else {
      await dispatch(makeStoriesAvailableInner(storyIds, isFromHomepage));
    }
  } finally {
    // Regardless of the outcome allow edition editing
    dispatch(blockEditionsEditing({ storyIds, sequence: Sequence.DONE }));
  }
};
export function getEditionsWithFirstSnaps(editionsIds: EditionID[]) {
  return (dispatch: Dispatch) => {
    return Promise.all(editionsIds.map(editionId => dispatch(getEditionIfStale({ editionId })))).then(() => {
      return dispatch(fetchFirstSnapOfEditions(editionsIds));
    });
  };
}
export const setNewStoryInfo = (info?: NewStoryInfo | null) => {
  return {
    type: SET_NEW_STORY_INFO,
    params: {
      info,
    },
  };
};
export const makeUnselectedStoriesUnavailable = (
  excludedStoryIds: EditionID[]
): ((b: Dispatch, a: GetState) => Promise<unknown>) => {
  return (dispatch: Dispatch, getState: GetState) => {
    return (dispatch(
      createCallAction(
        {
          type: MAKE_STORIES_UNAVAILABLE,
        },
        {
          method: 'POST',
          endpoint: proxyAPI.stories.makeStoriesUnavailable({
            businessProfileId: publishersSelectors.getActivePublisherBusinessProfileId(getState()),
            excludeId: excludedStoryIds,
          }),
        }
      )
    ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.MAKE_ALL_STORIES_UNAVAILABLE));
  };
};

export const generateSubscriptionOverlayIfNeeded = (snap: Snap, storyId: EditionID) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  if (
    isSubscribeSnap(snap) &&
    !snap.overlayImageAssetId &&
    publishersSelectors.activePublisherHasAddedRequiredDetails(getState())
  ) {
    const subscriptionText = await editionSubscriptionText(getState())(storyId);
    return dispatch(snapsActions.setSnapPropertiesAndSave({ snapId: snap.id }, { subscriptionText }));
  }

  return Promise.resolve();
};

export const discardLiveEditChanges = (storyId: EditionID) => (dispatch: Dispatch) => {
  return dispatch(
    createCallAction(
      {
        type: DISCARD_LIVE_EDIT_CHANGES,
        params: { editionId: storyId },
        meta: {
          schema: editionSchema,
        },
      },
      {
        method: 'POST',
        endpoint: proxyAPI.story.discardLiveEditChanges({ editionId: storyId }),
        finalizer: generateEditionTileIds,
      }
    )
  );
};

export const reloadStoryAndSnaps = (storyId: EditionID) => async (dispatch: Dispatch) => {
  await dispatch(getEdition({ editionId: storyId }));
  await dispatch(reloadSnaps(storyId));
};
