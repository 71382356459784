import { combineReducers } from 'redux';

import { createSequenceHandlingReducer, createMergeReducer, serializeReducers } from 'state/common/reducerFactories';
import { VALIDATE_EMAIL } from 'state/user/actions/userActions';

import * as actions from '../actions/authActionTypes';
import { CLEAR_ASSOCIATED_USER, SET_ASSOCIATED_USER } from '../actions/authActionTypes';

import { EMPTY_OBJECT, Sequence } from 'config/constants';
import u from 'utils/safeUpdeep';

const authType = (state = null, action: any) => {
  if (action.type === actions.AUTH_TYPE) {
    return action.payload.authType;
  }
  return state;
};

const loading = createSequenceHandlingReducer(false, [actions.GOOGLE_LOGIN], {
  start(state: any, action: any) {
    return true;
  },

  done(state: any, action: any) {
    return false;
  },
});

const loaded = createSequenceHandlingReducer(false, [actions.GOOGLE_LOGIN, actions.LOGOUT], {
  start(state: any, action: any) {
    return false;
  },

  done(state: any, action: any) {
    if (action.type === actions.LOGOUT) {
      return false;
    }
    return true;
  },
});

const receiveUserTypes = new Set([actions.GOOGLE_REFRESH_TOKEN, actions.GOOGLE_LOGIN]);

const authAge = createSequenceHandlingReducer(0, [actions.GOOGLE_LOGIN, actions.REFRESH_TOKEN], {
  start(state: any) {
    return state;
  },

  done(state: any, action: any) {
    return action.meta.timestamp;
  },
});

const googleUser = createSequenceHandlingReducer(
  null,
  [actions.GOOGLE_LOGIN, actions.LOGOUT, actions.GOOGLE_REFRESH_TOKEN],
  {
    start(state: any, action: any) {
      if (action.type === actions.GOOGLE_LOGIN) {
        return null;
      }
      return state;
    },

    done(state: any, action: any) {
      if (receiveUserTypes.has(action.type) && !action.error) {
        return action.payload || state;
      }
      return null;
    },
  }
);

const authRefreshLoop = createMergeReducer([actions.SET_REFRESH_LOOP]);

const gapi = createSequenceHandlingReducer({}, [actions.PRELOAD_GAPI], {
  start(state: any, action: any) {
    return {
      loaded: false,
      failed: false,
    };
  },

  done(state: any, action: any) {
    if (action.error) {
      return {
        loaded: true,
        failed: true,
      };
    }
    return {
      loaded: true,
      failed: false,
    };
  },
});

const lastRichsnapToken = createSequenceHandlingReducer(null, [actions.SET_RICHSNAP_COOKIE], {
  start(state: any, action: any) {
    return state;
  },

  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    return action.payload.token;
  },
});

const authFailed = (state = false, action: any) => {
  if (action.type === actions.FLAG_AUTH_FAILED) {
    return action.payload;
  }
  return state;
};

const ticket = (state = null, action: any) => {
  switch (action.type) {
    case actions.SNAP_AUTH_TICKET:
      return action.ticket;

    case actions.SNAP_REFRESH_TOKEN:
      if (action.sequence === Sequence.DONE && !action.error) {
        return action.payload || state;
      }
      return state;

    default:
      return state;
  }
};

const snapUsername = createSequenceHandlingReducer(null, [VALIDATE_EMAIL], {
  start(state: any, action: any) {
    return state;
  },

  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    return action.payload.snapUsername;
  },
});

const snapAuth = combineReducers({
  snapUsername,
  ticket,
});

const setAsociatedUser = createSequenceHandlingReducer(EMPTY_OBJECT, [SET_ASSOCIATED_USER], {
  start(state: any, action: any) {
    return u({ isAssociating: true }, state);
  },
  done(state: any, action: any) {
    if (action.error) {
      return u({ isAssociating: false }, state);
    }
    return u({ isAssociating: false, ...action.params, ...action.payload }, state);
  },
});

const clearAssociatedUser = (state = null, action: any) => {
  if (action.type === CLEAR_ASSOCIATED_USER) {
    return EMPTY_OBJECT;
  }
  return state;
};

const associatedUser = serializeReducers([setAsociatedUser, clearAssociatedUser]);

export default combineReducers({
  authAge,
  authFailed,
  authRefreshLoop,
  authType,
  gapi,
  googleUser,
  lastRichsnapToken,
  loaded,
  loading,
  snapAuth,
  associatedUser,
});
