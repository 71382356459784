// Sensitive countries: https://jira.sc-corp.net/browse/PUB-5491
export const CountryList = [
  { value: 'af', label: 'Afghanistan (AF)', sensitive: true },
  { value: 'ab', label: 'Abkhazian (AB)' },
  { value: 'al', label: 'Albania (AL)' },
  { value: 'dz', label: 'Algeria (DZ)', sensitive: true },
  { value: 'as', label: 'American Samoa (AS)' },
  { value: 'ad', label: 'Andorra (AD)' },
  { value: 'ao', label: 'Angola (AO)' },
  { value: 'ai', label: 'Anguilla (AI)' },
  { value: 'aq', label: 'Antarctica (AQ)' },
  { value: 'ag', label: 'Antigua and Barbuda (AG)' },
  { value: 'ar', label: 'Argentina (AR)' },
  { value: 'am', label: 'Armenia (AM)' },
  { value: 'aw', label: 'Aruba (AW)' },
  { value: 'au', label: 'Australia (AU)' },
  { value: 'at', label: 'Austria (AT)' },
  { value: 'az', label: 'Azerbaijan (AZ)' },
  { value: 'bs', label: 'Bahamas (BS)' },
  { value: 'bh', label: 'Bahrain (BH)', sensitive: true },
  { value: 'bd', label: 'Bangladesh (BD)' },
  { value: 'bb', label: 'Barbados (BB)' },
  { value: 'by', label: 'Belarus (BY)' },
  { value: 'be', label: 'Belgium (BE)' },
  { value: 'bz', label: 'Belize (BZ)' },
  { value: 'bj', label: 'Benin (BJ)' },
  { value: 'bm', label: 'Bermuda (BM)' },
  { value: 'bt', label: 'Bhutan (BT)' },
  { value: 'bo', label: 'Bolivia (BO)' },
  { value: 'bq', label: 'Bonaire (BQ)' },
  { value: 'ba', label: 'Bosnia and Herzegovina (BA)' },
  { value: 'bw', label: 'Botswana (BW)' },
  { value: 'bv', label: 'Bouvet Island (BV)' },
  { value: 'br', label: 'Brazil (BR)' },
  { value: 'io', label: 'British Indian Ocean Territory (IO)' },
  { value: 'bn', label: 'Brunei Darussalam (BN)', sensitive: true },
  { value: 'bg', label: 'Bulgaria (BG)' },
  { value: 'bf', label: 'Burkina Faso (BF)' },
  { value: 'bi', label: 'Burundi (BI)' },
  { value: 'kh', label: 'Cambodia (KH)' },
  { value: 'cm', label: 'Cameroon (CM)' },
  { value: 'ca', label: 'Canada (CA)' },
  { value: 'cv', label: 'Cape Verde (CV)' },
  { value: 'ky', label: 'Cayman Islands (KY)' },
  { value: 'cf', label: 'Central African Republic (CF)' },
  { value: 'td', label: 'Chad (TD)' },
  { value: 'cl', label: 'Chile (CL)' },
  { value: 'cn', label: 'China (CN)' },
  { value: 'cx', label: 'Christmas Island (CX)' },
  { value: 'cc', label: 'Cocos (Keeling) Islands (CC)' },
  { value: 'co', label: 'Colombia (CO)' },
  { value: 'km', label: 'Comoros (KM)' },
  { value: 'cg', label: 'Congo (CG)' },
  { value: 'cd', label: 'Democratic Republic of the Congo (CD)' },
  { value: 'ck', label: 'Cook Islands (CK)' },
  { value: 'cr', label: 'Costa Rica (CR)' },
  { value: 'hr', label: 'Croatia (HR)' },
  { value: 'cu', label: 'Cuba (CU)' },
  { value: 'cw', label: 'CuraÃ§ao (CW)' },
  { value: 'cy', label: 'Cyprus (CY)' },
  { value: 'cz', label: 'Czech Republic (CZ)' },
  { value: 'ci', label: "CÃ´te d'Ivoire (CI)" },
  { value: 'dk', label: 'Denmark (DK)' },
  { value: 'dj', label: 'Djibouti (DJ)' },
  { value: 'dm', label: 'Dominica (DM)' },
  { value: 'do', label: 'Dominican Republic (DO)' },
  { value: 'ec', label: 'Ecuador (EC)' },
  { value: 'eg', label: 'Egypt (EG)', sensitive: true },
  { value: 'sv', label: 'El Salvador (SV)' },
  { value: 'gq', label: 'Equatorial Guinea (GQ)' },
  { value: 'er', label: 'Eritrea (ER)' },
  { value: 'ee', label: 'Estonia (EE)' },
  { value: 'et', label: 'Ethiopia (ET)' },
  { value: 'fk', label: 'Falkland Islands (Malvinas) (FK)' },
  { value: 'fo', label: 'Faroe Islands (FO)' },
  { value: 'fj', label: 'Fiji (FJ)' },
  { value: 'fi', label: 'Finland (FI)' },
  { value: 'fr', label: 'France (FR)' },
  { value: 'gf', label: 'French Guiana (GF)' },
  { value: 'pf', label: 'French Polynesia (PF)' },
  { value: 'tf', label: 'French Southern Territories (TF)' },
  { value: 'ga', label: 'Gabon (GA)' },
  { value: 'gm', label: 'Gambia (GM)' },
  { value: 'ge', label: 'Georgia (GE)' },
  { value: 'de', label: 'Germany (DE)' },
  { value: 'gh', label: 'Ghana (GH)' },
  { value: 'gi', label: 'Gibraltar (GI)' },
  { value: 'gr', label: 'Greece (GR)' },
  { value: 'gl', label: 'Greenland (GL)' },
  { value: 'gd', label: 'Grenada (GD)' },
  { value: 'gp', label: 'Guadeloupe (GP)' },
  { value: 'gu', label: 'Guam (GU)' },
  { value: 'gt', label: 'Guatemala (GT)' },
  { value: 'gg', label: 'Guernsey (GG)' },
  { value: 'gn', label: 'Guinea (GN)' },
  { value: 'gw', label: 'Guinea-Bissau (GW)' },
  { value: 'gy', label: 'Guyana (GY)' },
  { value: 'ht', label: 'Haiti (HT)' },
  { value: 'hm', label: 'Heard Island and McDonald Mcdonald Islands (HM)' },
  { value: 'va', label: 'Holy See (Vatican City State) (VA)' },
  { value: 'hn', label: 'Honduras (HN)' },
  { value: 'hk', label: 'Hong Kong (HK)' },
  { value: 'hu', label: 'Hungary (HU)' },
  { value: 'is', label: 'Iceland (IS)' },
  { value: 'in', label: 'India (IN)', sensitive: true },
  { value: 'id', label: 'Indonesia (ID)', sensitive: true },
  { value: 'ir', label: 'Iran, Islamic Republic of (IR)', sensitive: true },
  { value: 'iq', label: 'Iraq (IQ)', sensitive: true },
  { value: 'ie', label: 'Ireland (IE)' },
  { value: 'im', label: 'Isle of Man (IM)' },
  { value: 'il', label: 'Israel (IL)' },
  { value: 'it', label: 'Italy (IT)' },
  { value: 'jm', label: 'Jamaica (JM)' },
  { value: 'jp', label: 'Japan (JP)' },
  { value: 'je', label: 'Jersey (JE)' },
  { value: 'jo', label: 'Jordan (JO)', sensitive: true },
  { value: 'kz', label: 'Kazakhstan (KZ)' },
  { value: 'ke', label: 'Kenya (KE)' },
  { value: 'ki', label: 'Kiribati (KI)' },
  { value: 'kp', label: "Korea, Democratic People's Republic of (KP)" },
  { value: 'kr', label: 'Korea, Republic of (KR)' },
  { value: 'kw', label: 'Kuwait (KW)', sensitive: true },
  { value: 'kg', label: 'Kyrgyzstan (KG)' },
  { value: 'la', label: "Lao People's Democratic Republic (LA)" },
  { value: 'lv', label: 'Latvia (LV)' },
  { value: 'lb', label: 'Lebanon (LB)' },
  { value: 'ls', label: 'Lesotho (LS)' },
  { value: 'lr', label: 'Liberia (LR)' },
  { value: 'ly', label: 'Libya (LY)', sensitive: true },
  { value: 'li', label: 'Liechtenstein (LI)' },
  { value: 'lt', label: 'Lithuania (LT)' },
  { value: 'lu', label: 'Luxembourg (LU)' },
  { value: 'mo', label: 'Macao (MO)' },
  { value: 'mk', label: 'Macedonia, the Former Yugoslav Republic of (MK)' },
  { value: 'mg', label: 'Madagascar (MG)' },
  { value: 'mw', label: 'Malawi (MW)' },
  { value: 'my', label: 'Malaysia (MY)', sensitive: true },
  { value: 'mv', label: 'Maldives (MV)' },
  { value: 'ml', label: 'Mali (ML)' },
  { value: 'mt', label: 'Malta (MT)' },
  { value: 'mh', label: 'Marshall Islands (MH)' },
  { value: 'mq', label: 'Martinique (MQ)' },
  { value: 'mr', label: 'Mauritania (MR)', sensitive: true },
  { value: 'mu', label: 'Mauritius (MU)' },
  { value: 'yt', label: 'Mayotte (YT)' },
  { value: 'mx', label: 'Mexico (MX)' },
  { value: 'fm', label: 'Micronesia, Federated States of (FM)' },
  { value: 'md', label: 'Moldova, Republic of (MD)' },
  { value: 'mc', label: 'Monaco (MC)' },
  { value: 'mn', label: 'Mongolia (MN)' },
  { value: 'me', label: 'Montenegro (ME)' },
  { value: 'ms', label: 'Montserrat (MS)' },
  { value: 'ma', label: 'Morocco (MA)', sensitive: true },
  { value: 'mz', label: 'Mozambique (MZ)' },
  { value: 'mm', label: 'Myanmar (MM)' },
  { value: 'na', label: 'Namibia (NA)' },
  { value: 'nr', label: 'Nauru (NR)' },
  { value: 'np', label: 'Nepal (NP)' },
  { value: 'nl', label: 'Netherlands (NL)' },
  { value: 'nc', label: 'New Caledonia (NC)' },
  { value: 'nz', label: 'New Zealand (NZ)' },
  { value: 'ni', label: 'Nicaragua (NI)' },
  { value: 'ne', label: 'Niger (NE)' },
  { value: 'ng', label: 'Nigeria (NG)', sensitive: true },
  { value: 'nu', label: 'Niue (NU)' },
  { value: 'nf', label: 'Norfolk Island (NF)' },
  { value: 'mp', label: 'Northern Mariana Islands (MP)' },
  { value: 'no', label: 'Norway (NO)' },
  { value: 'om', label: 'Oman (OM)', sensitive: true },
  { value: 'pk', label: 'Pakistan (PK)' },
  { value: 'pw', label: 'Palau (PW)' },
  { value: 'ps', label: 'Palestine, State of (PS)' },
  { value: 'pa', label: 'Panama (PA)' },
  { value: 'pg', label: 'Papua New Guinea (PG)' },
  { value: 'py', label: 'Paraguay (PY)' },
  { value: 'pe', label: 'Peru (PE)' },
  { value: 'ph', label: 'Philippines (PH)' },
  { value: 'pn', label: 'Pitcairn (PN)' },
  { value: 'pl', label: 'Poland (PL)' },
  { value: 'pt', label: 'Portugal (PT)' },
  { value: 'pr', label: 'Puerto Rico (PR)' },
  { value: 'qa', label: 'Qatar (QA)', sensitive: true },
  { value: 'ro', label: 'Romania (RO)' },
  { value: 'ru', label: 'Russian Federation (RU)' },
  { value: 'rw', label: 'Rwanda (RW)' },
  { value: 're', label: 'Reunion (RE)' },
  { value: 'bl', label: 'Saint Barthelemy (BL)' },
  { value: 'sh', label: 'Saint Helena (SH)' },
  { value: 'kn', label: 'Saint Kitts and Nevis (KN)' },
  { value: 'lc', label: 'Saint Lucia (LC)' },
  { value: 'mf', label: 'Saint Martin (French part) (MF)' },
  { value: 'pm', label: 'Saint Pierre and Miquelon (PM)' },
  { value: 'vc', label: 'Saint Vincent and the Grenadines (VC)' },
  { value: 'ws', label: 'Samoa (WS)' },
  { value: 'sm', label: 'San Marino (SM)' },
  { value: 'st', label: 'Sao Tome and Principe (ST)' },
  { value: 'sa', label: 'Saudi Arabia (SA)', sensitive: true },
  { value: 'sn', label: 'Senegal (SN)' },
  { value: 'rs', label: 'Serbia (RS)' },
  { value: 'sc', label: 'Seychelles (SC)' },
  { value: 'sl', label: 'Sierra Leone (SL)' },
  { value: 'sg', label: 'Singapore (SG)' },
  { value: 'sx', label: 'Sint Maarten (Dutch part) (SX)' },
  { value: 'sk', label: 'Slovakia (SK)' },
  { value: 'si', label: 'Slovenia (SI)' },
  { value: 'sb', label: 'Solomon Islands (SB)' },
  { value: 'so', label: 'Somalia (SO)', sensitive: true },
  { value: 'za', label: 'South Africa (ZA)' },
  { value: 'gs', label: 'South Georgia and the South Sandwich Islands (GS)' },
  { value: 'ss', label: 'South Sudan (SS)', sensitive: true },
  { value: 'es', label: 'Spain (ES)' },
  { value: 'lk', label: 'Sri Lanka (LK)' },
  { value: 'sd', label: 'Sudan (SD)', sensitive: true },
  { value: 'sr', label: 'Suriname (SR)' },
  { value: 'sj', label: 'Svalbard and Jan Mayen (SJ)' },
  { value: 'sz', label: 'Swaziland (SZ)' },
  { value: 'se', label: 'Sweden (SE)' },
  { value: 'ch', label: 'Switzerland (CH)' },
  { value: 'sy', label: 'Syrian Arab Republic (SY)', sensitive: true },
  { value: 'tw', label: 'Taiwan, Province of China (TW)' },
  { value: 'tj', label: 'Tajikistan (TJ)' },
  { value: 'tz', label: 'United Republic of Tanzania (TZ)' },
  { value: 'th', label: 'Thailand (TH)' },
  { value: 'tl', label: 'Timor-Leste (TL)' },
  { value: 'tg', label: 'Togo (TG)' },
  { value: 'tk', label: 'Tokelau (TK)' },
  { value: 'to', label: 'Tonga (TO)' },
  { value: 'tt', label: 'Trinidad and Tobago (TT)' },
  { value: 'tn', label: 'Tunisia (TN)', sensitive: true },
  { value: 'tr', label: 'Turkey (TR)', sensitive: true },
  { value: 'tm', label: 'Turkmenistan (TM)' },
  { value: 'tc', label: 'Turks and Caicos Islands (TC)' },
  { value: 'tv', label: 'Tuvalu (TV)' },
  { value: 'ug', label: 'Uganda (UG)' },
  { value: 'ua', label: 'Ukraine (UA)' },
  { value: 'ae', label: 'United Arab Emirates (AE)', sensitive: true },
  { value: 'gb', label: 'United Kingdom (GB)' },
  { value: 'us', label: 'United States (US)' },
  { value: 'um', label: 'United States Minor Outlying Islands (UM)' },
  { value: 'uy', label: 'Uruguay (UY)' },
  { value: 'uz', label: 'Uzbekistan (UZ)' },
  { value: 'vu', label: 'Vanuatu (VU)' },
  { value: 've', label: 'Venezuela (VE)' },
  { value: 'vn', label: 'Viet Nam (VN)' },
  { value: 'vg', label: 'British Virgin Islands (VG)' },
  { value: 'vi', label: 'US Virgin Islands (VI)' },
  { value: 'wf', label: 'Wallis and Futuna (WF)' },
  { value: 'eh', label: 'Western Sahara (EH)' },
  { value: 'ye', label: 'Yemen (YE)', sensitive: true },
  { value: 'zm', label: 'Zambia (ZM)' },
  { value: 'zw', label: 'Zimbabwe (ZW)' },
  { value: 'ax', label: 'Aland Islands (AX)' },
];

export const LanguageList = [
  { value: 'ab', label: 'Abkhazian (AB)' },
  { value: 'aa', label: 'Afar (AA)' },
  { value: 'af', label: 'Afrikaans (AF)' },
  { value: 'sq', label: 'Albanian (SQ)' },
  { value: 'am', label: 'Amharic (AM)' },
  { value: 'ar', label: 'Arabic (AR)' },
  { value: 'hy', label: 'Armenian (HY)' },
  { value: 'as', label: 'Assamese (AS)' },
  { value: 'ay', label: 'Aymara (AY)' },
  { value: 'az', label: 'Azerbaijani (AZ)' },
  { value: 'ba', label: 'Bashkir (BA)' },
  { value: 'eu', label: 'Basque (EU)' },
  { value: 'bn', label: 'Bengali, Bangla (BN)' },
  { value: 'dz', label: 'Bhutani (DZ)' },
  { value: 'bh', label: 'Bihari (BH)' },
  { value: 'bi', label: 'Bislama (BI)' },
  { value: 'br', label: 'Breton (BR)' },
  { value: 'bg', label: 'Bulgarian (BG)' },
  { value: 'my', label: 'Burmese (MY)' },
  { value: 'be', label: 'Byelorussian (BE)' },
  { value: 'km', label: 'Cambodian (KM)' },
  { value: 'ca', label: 'Catalan (CA)' },
  { value: 'zh', label: 'Chinese (ZH)' },
  { value: 'co', label: 'Corsican (CO)' },
  { value: 'hr', label: 'Croatian (HR)' },
  { value: 'cs', label: 'Czech (CS)' },
  { value: 'da', label: 'Danish (DA)' },
  { value: 'nl', label: 'Dutch (NL)' },
  { value: 'en', label: 'English, American (EN)' },
  { value: 'eo', label: 'Esperanto (EO)' },
  { value: 'et', label: 'Estonian (ET)' },
  { value: 'fo', label: 'Faeroese (FO)' },
  { value: 'fj', label: 'Fiji (FJ)' },
  { value: 'fi', label: 'Finnish (FI)' },
  { value: 'fr', label: 'French (FR)' },
  { value: 'fy', label: 'Frisian (FY)' },
  { value: 'gd', label: 'Gaelic (Scots Gaelic) (GD)' },
  { value: 'gl', label: 'Galician (GL)' },
  { value: 'ka', label: 'Georgian (KA)' },
  { value: 'de', label: 'German (DE)' },
  { value: 'el', label: 'Greek (EL)' },
  { value: 'kl', label: 'Greenlandic (KL)' },
  { value: 'gn', label: 'Guarani (GN)' },
  { value: 'gu', label: 'Gujarati (GU)' },
  { value: 'ha', label: 'Hausa (HA)' },
  { value: 'iw', label: 'Hebrew (IW)' },
  { value: 'hi', label: 'Hindi (HI)' },
  { value: 'hu', label: 'Hungarian (HU)' },
  { value: 'is', label: 'Icelandic (IS)' },
  { value: 'id', label: 'Indonesian (ID)' },
  { value: 'ia', label: 'Interlingua (IA)' },
  { value: 'ie', label: 'Interlingue (IE)' },
  { value: 'ik', label: 'Inupiak (IK)' },
  { value: 'ga', label: 'Irish (GA)' },
  { value: 'it', label: 'Italian (IT)' },
  { value: 'ja', label: 'Japanese (JA)' },
  { value: 'jw', label: 'Javanese (JW)' },
  { value: 'kn', label: 'Kannada (KN)' },
  { value: 'ks', label: 'Kashmiri (KS)' },
  { value: 'kk', label: 'Kazakh (KK)' },
  { value: 'rw', label: 'Kinyarwanda (RW)' },
  { value: 'ky', label: 'Kirghiz (KY)' },
  { value: 'rn', label: 'Kirundi (RN)' },
  { value: 'ko', label: 'Korean (KO)' },
  { value: 'ku', label: 'Kurdish (KU)' },
  { value: 'lo', label: 'Laothian (LO)' },
  { value: 'la', label: 'Latin (LA)' },
  { value: 'lv', label: 'Latvian, Lettish (LV)' },
  { value: 'ln', label: 'Lingala (LN)' },
  { value: 'lt', label: 'Lithuanian (LT)' },
  { value: 'mk', label: 'Macedonian (MK)' },
  { value: 'mg', label: 'Malagasy (MG)' },
  { value: 'ms', label: 'Malay (MS)' },
  { value: 'ml', label: 'Malayalam (ML)' },
  { value: 'mt', label: 'Maltese (MT)' },
  { value: 'mi', label: 'Maori (MI)' },
  { value: 'mr', label: 'Marathi (MR)' },
  { value: 'mo', label: 'Moldavian (MO)' },
  { value: 'mn', label: 'Mongolian (MN)' },
  { value: 'na', label: 'Nauru (NA)' },
  { value: 'ne', label: 'Nepali (NE)' },
  { value: 'no', label: 'Norwegian (NO)' },
  { value: 'oc', label: 'Occitan (OC)' },
  { value: 'or', label: 'Oriya (OR)' },
  { value: 'om', label: 'Oromo, Afan (OM)' },
  { value: 'ps', label: 'Pashto, Pushto (PS)' },
  { value: 'fa', label: 'Persian (FA)' },
  { value: 'pl', label: 'Polish (PL)' },
  { value: 'pt', label: 'Portuguese (PT)' },
  { value: 'pa', label: 'Punjabi (PA)' },
  { value: 'qu', label: 'Quechua (QU)' },
  { value: 'rm', label: 'Rhaeto-Romance (RM)' },
  { value: 'ro', label: 'Romanian (RO)' },
  { value: 'ru', label: 'Russian (RU)' },
  { value: 'sm', label: 'Samoan (SM)' },
  { value: 'sg', label: 'Sangro (SG)' },
  { value: 'sa', label: 'Sanskrit (SA)' },
  { value: 'sr', label: 'Serbian (SR)' },
  { value: 'sh', label: 'Serbo-Croatian (SH)' },
  { value: 'st', label: 'Sesotho (ST)' },
  { value: 'tn', label: 'Setswana (TN)' },
  { value: 'sn', label: 'Shona (SN)' },
  { value: 'sd', label: 'Sindhi (SD)' },
  { value: 'si', label: 'Singhalese (SI)' },
  { value: 'ss', label: 'Siswati (SS)' },
  { value: 'sk', label: 'Slovak (SK)' },
  { value: 'sl', label: 'Slovenian (SL)' },
  { value: 'so', label: 'Somali (SO)' },
  { value: 'es', label: 'Spanish (ES)' },
  { value: 'su', label: 'Sudanese (SU)' },
  { value: 'sw', label: 'Swahili (SW)' },
  { value: 'sv', label: 'Swedish (SV)' },
  { value: 'tl', label: 'Tagalog (TL)' },
  { value: 'tg', label: 'Tajik (TG)' },
  { value: 'ta', label: 'Tamil (TA)' },
  { value: 'tt', label: 'Tatar (TT)' },
  { value: 'te', label: 'Telugu (TE)' },
  { value: 'th', label: 'Thai (TH)' },
  { value: 'bo', label: 'Tibetan (BO)' },
  { value: 'ti', label: 'Tigrinya (TI)' },
  { value: 'to', label: 'Tonga (TO)' },
  { value: 'ts', label: 'Tsonga (TS)' },
  { value: 'tr', label: 'Turkish (TR)' },
  { value: 'tk', label: 'Turkmen (TK)' },
  { value: 'tw', label: 'Twi (TW)' },
  { value: 'uk', label: 'Ukrainian (UK)' },
  { value: 'ur', label: 'Urdu (UR)' },
  { value: 'uz', label: 'Uzbek (UZ)' },
  { value: 'vi', label: 'Vietnamese (VI)' },
  { value: 'vo', label: 'Volapuk (VO)' },
  { value: 'cy', label: 'Welsh (CY)' },
  { value: 'wo', label: 'Wolof (WO)' },
  { value: 'xh', label: 'Xhosa (XH)' },
  { value: 'ji', label: 'Yiddish (JI)' },
  { value: 'yo', label: 'Yoruba (YO)' },
  { value: 'zu', label: 'Zulu (ZU)' },
];

export const LanguageListMap = LanguageList.reduce((acc, { value, label }) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  acc[value] = label;
  return acc;
}, {});
