import { parse } from 'url';

export const URL_PREFIX_REGEX = /^https?/;

const PARAM = ':param';

const USER_TOKEN = 'user';

// Strips out parameters from URLs and splits URL into urlFormat and query
export function scrubUrlForReporting(endpoint: any, requestType: any) {
  if (!isValidUrl(endpoint)) {
    return {};
  }
  // TODO: parsedUrl, parsedUrl.pathname, and parsedUrl.query could be undefined
  const parsedUrl: any = parse(endpoint, true);
  return scrubParsedUrlForReporting({
    host: parsedUrl.host,
    path: parsedUrl.pathname,
    query: parsedUrl.query,
    requestType,
  });
}

export function scrubParsedUrlForReporting({
  host,
  path,
  query,
  requestType,
}: {
  host?: string;
  path: string;
  query?: any;
  requestType?: string;
}) {
  // PathName
  let pathName = path
    .split('/')
    .map((element: string, index: number, tokens: Array<string>) => {
      if (isParam(element)) {
        return PARAM;
      }
      // Some URLS are of the form /user/<username>/edition/<edition_number>; Username is alphanumeric and doesn't
      // have a distinct pattern which can can be used to distinguish it from other tokens in the path
      if (tokens[index - 1] === USER_TOKEN) {
        return PARAM;
      }
      if (isLabelWithParam(element)) {
        return obfuscateLabelWithParam(element);
      }
      return element;
    })
    .join('/');

  if (requestType !== undefined) {
    pathName = `${requestType}:${pathName}`;
  }

  // host will include the port num as well
  const labelInfo: any = {
    host,
  };

  // Search part
  if (query) {
    const queryArray: any = [];
    Object.keys(query).forEach(property => queryArray.push(`${property}=:param`));
    if (queryArray.length > 0) {
      labelInfo.search = queryArray.join('&');
    }
  }

  return {
    pathName,
    label: labelInfo,
  };
}

function isParam(element: any) {
  const hasNumbersOnly = /^\d+$/.test(element); // Any other ID
  const hasAlphaNumsAndDashes = /^[A-Za-z0-9-]+$/.test(element) && element.indexOf('-') !== -1; // userId
  return hasNumbersOnly || hasAlphaNumsAndDashes;
}

function isLabelWithParam(element: any) {
  return element.indexOf('_') !== -1; // Some params look like upload/publisherId_xxxx/editionId_xxx
}

function obfuscateLabelWithParam(element: any) {
  return element
    .split('_')
    .map((subElement: any) => {
      return isParam(subElement) ? PARAM : subElement;
    })
    .join('_');
}

function isValidUrl(url: any) {
  return URL_PREFIX_REGEX.test(url);
}
