import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import { warningTriangle, help } from 'icons/SDS/allIcons';
import type { Enum } from 'utils/enum';

import Icon from 'views/common/components/Icon/Icon';

import style from './AlertBox.scss';

export const AlertType = {
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
};

export type AlertTypeEnum = Enum<typeof AlertType>;

type Props = {
  type: AlertTypeEnum;
  children: ReactNode;
  className?: string;
};

export class AlertBox extends React.PureComponent<Props> {
  renderIcon() {
    if (this.props.type === AlertType.ERROR) {
      return <Icon inlineIcon={warningTriangle} className={style.errorIcon} />;
    }
    if (this.props.type === AlertType.WARNING) {
      return <Icon inlineIcon={warningTriangle} className={style.warningIcon} />;
    }
    if (this.props.type === AlertType.INFO) {
      return <Icon inlineIcon={help} className={style.infoIcon} />;
    }
    return null;
  }

  render() {
    return (
      <div
        className={classNames(style.containerBox, this.props.className, {
          [style.error]: this.props.type === AlertType.ERROR,
          [style.warning]: this.props.type === AlertType.WARNING,
          [style.info]: this.props.type === AlertType.INFO,
        })}
      >
        <div className={style.iconContainer}>{this.renderIcon()}</div>
        {this.props.children}
      </div>
    );
  }
}

export default AlertBox;
