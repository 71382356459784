import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSCheckbox from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSDropdown, { DropdownSize, DropdownType } from 'views/common/components/SDSDropdown/SDSDropdown';
import { createSDSDropdownOptions } from 'views/common/components/SDSDropdownOptions/SDSDropdownOptions';
import SDSInput from 'views/common/components/SDSInput/SDSInput';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSPanelColumn from 'views/common/components/SDSPanel/SDSPanelColumn/SDSPanelColumn';
import SDSPanelRow from 'views/common/components/SDSPanel/SDSPanelRow/SDSPanelRow';
import PublisherDetailOption from 'views/onboarding/components/PublisherDetailOptionRow/PublisherDetailOptionRow';

import style from './NewUserExperienceMailingForm.scss';

import { DropdownOption } from 'types/SDSDropdown';
import { PropertyKeys } from 'types/publisherSettings';

export class NewUserExperienceMailingForm extends React.Component {
  handleMailingListSubmit = () => {
    // Todo(jalonge): Implement calls to the backend service
  };

  renderMarketingOption() {
    return (
      <PublisherDetailOption
        title={
          <FormattedMessage
            id="publisher-getting-started-marketing-snapchat"
            description="Label on the mail marketing option"
            defaultMessage="Marketing about Snapchat"
          />
        }
        selectedKey="marketing-snapchat"
        buttons={[
          { key: 'yes-button', text: getMessageFromId('yes-button') },
          { key: 'no-button', text: getMessageFromId('no-button') },
        ]}
        onKeySelected={this.handleMailingListSubmit}
        data-test={'publisherModal.gettingStarted.email-subscription-option'}
      />
    );
  }

  renderSubtitleBlock() {
    return (
      <div className={style.subTitle}>
        <FormattedMessage
          id="mailing-form-description"
          defaultMessage="Don’t miss out on partnership updates, Story Studio product updates, as well as invitations to Snap partner events and training sessions by opting-in to receive our partner newsletter."
          description="SubHeader text for the mailchimp subscription form"
        />
      </div>
    );
  }

  renderTitleBlock() {
    return (
      <div className={style.header}>
        <FormattedMessage
          id="mailing-form-list-header"
          defaultMessage="One last thing!"
          description="Header text for the mailchimp subscription form"
        />
      </div>
    );
  }

  renderInterestsCheckBox(businessSubscriptionLabel: JSX.Element) {
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel
          labelTitle={
            <FormattedMessage
              id="publisher-getting-started-interests-label"
              description="Label for the interests options on the mailing list subscription form"
              defaultMessage="Interests"
            />
          }
          data-test={'publisherModal.gettingStarted.interests-label'}
        >
          <div className={style.checkBoxContainer}>
            <SDSCheckbox onChange={this.handleMailingListSubmit} />
            {businessSubscriptionLabel}
          </div>
          <div className={style.checkBoxContainer}>
            <SDSCheckbox onChange={this.handleMailingListSubmit} />
            {businessSubscriptionLabel}
          </div>
          <div className={style.checkBoxContainer}>
            <SDSCheckbox onChange={this.handleMailingListSubmit} />
            {businessSubscriptionLabel}
          </div>
        </SDSLabel>
      </div>
    );
  }

  renderLanguageDropDown() {
    const languageOptions: Array<DropdownOption> = [
      {
        value: 'English',
        label: 'English',
        disabled: false,
      },
    ];

    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel
          labelTitle={
            <FormattedMessage
              id="publisher-getting-started-language-options-label"
              defaultMessage="Language Options"
              description="Label for the mailing form language option drop-down"
            />
          }
          data-test={'publisherModal.gettingStarted.language-option-label'}
        >
          <SDSDropdown
            disableClear
            dropdownWidthMatchContent
            value="English"
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
          >
            {createSDSDropdownOptions(languageOptions)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  }

  renderEmailInput() {
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSInput
          labelTitle={
            <FormattedMessage
              id="publisher-getting-started-email-label"
              defaultMessage="Email"
              description="Label for the email input on the mail list subscription form"
            />
          }
          disabled
          data-test="publisherModal.gettingStarted.email-label"
          key={PropertyKeys.EMAIL}
        />
      </div>
    );
  }

  renderLastNameInput() {
    return (
      <div className={classNames(style.formInputContainer, style.inputContainer)}>
        <SDSInput
          labelTitle={
            <FormattedMessage
              id="publisher-getting-started-lastname-label"
              description="Label for the last name input on the mailing list subscription form"
              defaultMessage="Last Name"
            />
          }
          data-test="publisherModal.gettingStarted.last-name-label"
          key={PropertyKeys.LAST_NAME}
        />
      </div>
    );
  }

  renderFirstNameInput() {
    return (
      <div className={classNames(style.formInputContainer, style.inputContainer)}>
        <SDSInput
          labelTitle={
            <FormattedMessage
              id="publisher-getting-started-firstname-label"
              description="Label for the first name input on the mailing list"
              defaultMessage="First Name"
            />
          }
          data-test="publisherModal.gettingStarted.first-name-label"
          key={PropertyKeys.FIRST_NAME}
        />
      </div>
    );
  }

  renderCompanyInput() {
    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSInput
          labelTitle={
            <FormattedMessage
              id="publisher-getting-started-company-label"
              description="Label for the company input on the mailing list subscription form"
              defaultMessage="Company"
            />
          }
          data-test="publisherModal.gettingStarted.company"
          key={PropertyKeys.COMPANY}
        />
      </div>
    );
  }

  renderCountryDropDown() {
    const countryOptions: Array<DropdownOption> = [
      {
        value: 'United Kingdom',
        label: 'United Kingdom',
        disabled: false,
      },
    ];

    return (
      <div className={classNames(style.inputContainer, style.formInputContainer)}>
        <SDSLabel
          labelTitle={
            <FormattedMessage
              id="publisher-getting-started-country-option-label"
              description="Label for the country option on the mailing list subscription form"
              defaultMessage="Country"
            />
          }
          data-test={'country-option-label'}
        >
          <SDSDropdown
            disableClear
            dropdownWidthMatchContent
            value="United Kingdom"
            type={DropdownType.GREY}
            size={DropdownSize.MEDIUM}
          >
            {createSDSDropdownOptions(countryOptions)}
          </SDSDropdown>
        </SDSLabel>
      </div>
    );
  }

  render() {
    const businessSubscriptionLabel = (
      <FormattedMessage
        id="publisher-getting-started-business-update-label"
        defaultMessage="Business updates"
        description="Checkbox text to select if user wants to opt in or out for business updates"
      />
    );

    return (
      <div>
        {this.renderTitleBlock()}
        {this.renderSubtitleBlock()}
        <SDSPanelRow>
          <SDSPanelColumn className={style.leftContent} key="mailing-form-column1">
            {this.renderFirstNameInput()}
            {this.renderCompanyInput()}
            {this.renderCountryDropDown()}
            {this.renderInterestsCheckBox(businessSubscriptionLabel)}
          </SDSPanelColumn>
          <SDSPanelColumn className={style.rightContent} key="mailing-form-column2">
            {this.renderLastNameInput()}
            {this.renderEmailInput()}
            {this.renderLanguageDropDown()}
            {this.renderMarketingOption()}
          </SDSPanelColumn>
        </SDSPanelRow>
      </div>
    );
  }
}
