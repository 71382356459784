import { withSentryRouting } from '@sentry/react';
import { Dimensions } from '@snapchat/graphene';
import _, { isEqual, pick, omit, without, noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, match as ReactRouterMatch } from 'react-router';

import * as grapheneUtils from 'utils/grapheneUtils';
import { logMemoryUsage } from 'utils/performance/performanceUtils';
import { wrapInTiming } from 'utils/router/routerUtils';

type OwnGatedRouteInternalProps<Params extends { [K in keyof Params]?: string } = {}> = {
  onEnter?: (...args: any[]) => any;
  onExit?: (...args: any[]) => any;
  async?: boolean;
  loadingView?: React.ReactNode;
  isEditionView?: boolean;
  match: ReactRouterMatch<Params>;
  location?: string | any;
  history?: any;
  component?: any;
  render?: any;
  ignoreHooksOnUpdate?: boolean;
};

type GatedRouteInternalState = any;

type GatedRouteInternalProps = OwnGatedRouteInternalProps & typeof GatedRouteInternal.defaultProps;

class GatedRouteInternal extends React.Component<GatedRouteInternalProps, GatedRouteInternalState> {
  static defaultProps = {
    async: false,
    loadingView: null,
    onExit: noop,
    onEnter: wrapInTiming(() => Promise.resolve()),
    ignoreHooksOnUpdate: false,
  };

  static contextTypes = {
    onEnterPromise: PropTypes.object,
  };

  static childContextTypes = {
    onEnterPromise: PropTypes.object,
  };

  _onEnterPromise: any;

  unmounted: any;

  stopTimer = grapheneUtils.createTimer();

  initViewTimer: (metricsName: string, dimensions: Dimensions | undefined) => number;

  constructor(props: GatedRouteInternalProps) {
    super(props);
    this.state = {};

    this.initViewTimer = grapheneUtils.createTimer();
  }

  getChildContext() {
    return {
      onEnterPromise: this._onEnterPromise || this.context.onEnterPromise || Promise.resolve(),
    };
  }

  intervalId: any;

  componentDidMount() {
    this.intervalId = setInterval(logMemoryUsage, 5000, this.props.match.path);
    this.onEnter(this.props, this.context, this.props.match).then(() => {
      this.initViewTimer('initView', {
        path: this.props.match.path,
        isExact: this.props.match.isExact.toString(),
      });
    });
  }

  componentDidUpdate(prevProps: GatedRouteInternalProps, prevState: GatedRouteInternalState) {
    if (!prevState.loaded && this.state.loaded) {
      // this one is to verify if the global timer given by routerUtils means the same as this (since it's a bit cryptic)
      grapheneUtils.globalTimerManager.endGlobalTimer(grapheneUtils.GlobalTimer.FIRST_PAGE_LOAD);
      if (this.props.isEditionView) {
        this.stopTimer('edition_page_load');
      }
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (this.props.match.isExact) {
      grapheneUtils.incrementCounter('page_load_error_counter', {
        path: _.get(this.props, ['match', 'path'], 'undefined'),
      });
    }
    throw error;
  }

  onEnter<Params extends { [K in keyof Params]?: string } = {}>(
    props: any,
    context: any,
    match: ReactRouterMatch<Params>
  ) {
    const parentPromise = context.onEnterPromise || Promise.resolve();
    const ownOnEnterPromise = props.onEnter ? props.onEnter({ match, parentPromise }) : Promise.resolve();

    this._onEnterPromise = Promise.all([parentPromise, ownOnEnterPromise]);

    if (props.async) {
      this.setState({ loaded: true });
      return Promise.resolve();
    }

    this._onEnterPromise = this._onEnterPromise.then(() => {
      if (!this.unmounted && _.isEqual(this.props.match, match)) {
        this.setState({ loaded: true });
      }
    });

    return this._onEnterPromise;
  }

  onExit<Params extends { [K in keyof Params]?: string } = {}>(props: any, match: ReactRouterMatch<Params>) {
    this.setState({ loaded: false });
    props.onExit({ match });
  }

  UNSAFE_componentWillReceiveProps(nextProps: any, nextContext: any) {
    // React can reuse components so we must test if the component should actually 'unmount' and 'remount'
    const shouldRemount =
      !_.isEqual(this.props.match.params, nextProps.match.params) || this.props.onEnter !== nextProps.onEnter;

    if (shouldRemount && !nextProps.ignoreHooksOnUpdate) {
      this.onExit(this.props, this.props.match);
      this.onEnter(nextProps, nextContext, nextProps.match);
    }
  }

  shouldComponentUpdate(nextProps: GatedRouteInternalProps, nextState: GatedRouteInternalState, nextContext: any) {
    return (
      (nextProps && !isEqual(this.props, nextProps)) ||
      (nextState && !isEqual(this.state, nextState)) ||
      (nextContext && !isEqual(this.context, nextContext))
    );
  }

  componentWillUnmount() {
    this.onExit(this.props, this.props.match);
    this.unmounted = true;
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  renderChild() {
    if (this.props.children) {
      return this.props.children;
    }

    const { match, location, history } = this.props;

    if (this.props.component) {
      return React.createElement(this.props.component, { match, location, history });
    }

    if (this.props.render) {
      return this.props.render({ match, location, history });
    }

    return null;
  }

  render() {
    return (this.state.loaded ? this.renderChild() : this.props.loadingView) || null;
  }
}

// Can't use the propTypes because they get stripped when compiling so we need to define them here.
const RoutePropKeys = ['children', 'component', 'exact', 'location', 'path', 'render', 'sensitive', 'strict'];

const GatedRoute = (props: any) => {
  const routePropKeys = React.useMemo(() => without(RoutePropKeys, 'children', 'render', 'component'), []);
  const routeForwardProps = React.useMemo(() => pick(props, routePropKeys), [routePropKeys, props]);
  const nonRouteProps = React.useMemo(() => omit(props, routePropKeys), [routePropKeys, props]);

  const renderGatedRoute = React.useCallback(routeProps => <GatedRouteInternal {...nonRouteProps} {...routeProps} />, [
    nonRouteProps,
  ]);

  // Rendering inside Route so we have access to the match object
  return <Route {...routeForwardProps} render={renderGatedRoute} />;
};

export default withSentryRouting(GatedRoute);
