// Story getting started help modal
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { BITMOJI_TEMPLATES } from 'config/bitmojiConfig';

import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import { HelpModalContent } from 'views/modals/containers/NewUserExperienceHelpModal/NewUserExperienceHelpModal';

export const modalHeader: React.ReactChild = (
  <FormattedMessage
    id="story-welcome-header"
    defaultMessage="Hey there!"
    description="Header text for the welcome to new story"
  />
);

const storyModalSubHeader: React.ReactChild = (
  <FormattedMessage
    id="story-welcome-sub-header"
    defaultMessage="You’re ready to upload your first Story! Here are a few tips to help you get started."
    description="Sub header for new story welcome page"
  />
);

const storyModalSubHeader2: React.ReactChild = (
  <FormattedMessage
    id="story-welcome-sub-header2"
    defaultMessage="Now that you’ve uploaded your story, you must add a few more elements before publishing. Make sure you have the following ready:"
    description="Sub header for new story welcome page"
  />
);

export const STORY_GETTING_STARTED: HelpModalContent[] = [
  {
    id: 'story-getting-started-section1',
    modalSubHeader: storyModalSubHeader,
    modalSections: [
      {
        id: 'upload-guidelines',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_GUITAR,
        sectionHeader: (
          <FormattedMessage
            id="uploading-guidelines"
            description="Label for uploading guidelines"
            defaultMessage="Uploading Guide"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="uploading-help-content-guidelines"
            description="Content guideline information on story uploads"
            defaultMessage="Everything you need to know about uploading a Story to Story Studio can be found in our handy guide. Make sure you check it out before getting started."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.UPLOADING_GUIDELINES,
        },
      },
      {
        id: 'story-source-video-spec-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_NICE_SNAP,
        sectionHeader: (
          <FormattedMessage
            id="story-source-video-spec-header"
            description="Header text on how to define video specs"
            defaultMessage="Source Video Specs"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="story-source-video-spec-content"
            description="Information on how to define video specs"
            defaultMessage="Videos should be 1080x1920 or better, and no more than 1GB."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.STORY_SPEC,
        },
      },
    ],
  },
  {
    id: 'story-getting-started-section2',
    modalSubHeader: storyModalSubHeader,
    modalSections: [
      {
        id: 'story-tiles-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_POINT_UPWARD,
        sectionHeader: (
          <FormattedMessage
            id="story-tiles-label"
            description="Label text for link on how to work with story tiles"
            defaultMessage="Tiles"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="story-tiles-content"
            description="Information on how story tiles"
            defaultMessage="Tiles are how your content is merchandised to Snapchatters in the feed, and must be guideline compliant."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.STORY_TILES,
        },
      },
      {
        id: 'story-headlines-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_OKAYS,
        sectionHeader: (
          <FormattedMessage
            id="help-story-headlines-header"
            description="Header text on how to work with story headlines"
            defaultMessage="Headlines"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-story-headlines-content"
            description="Content on how to work with story headline"
            defaultMessage="Headlines are overlaid over your tiles to contextualize your content to Snapchatters in the feed, and also must be guidelines compliant."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.HEADLINES_GUIDELINES,
        },
      },
    ],
  },
  {
    id: 'story-getting-started-section3',
    modalSubHeader: storyModalSubHeader2,
    modalSections: [
      {
        id: 'story-end-snap-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_CORRECT,
        sectionHeader: (
          <FormattedMessage
            id="story-end-snap-header"
            description="Header text for story studio end snap section"
            defaultMessage="End Snap"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-story-end-snap-content"
            description="Story studio end snap content"
            defaultMessage="The Subscribe Snap is the final Snap in your Story that encourages Snapchatters to subscribe to future Stories from your Profile."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.SUBSCRIBE_SNAP,
        },
      },
      {
        id: 'help-subtitle-snap-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_LAPTOP_READ,
        sectionHeader: (
          <FormattedMessage
            id="help-subtitle-snap-header"
            description="Header text for subtitles section"
            defaultMessage="Subtitles"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-subtitle-snap-content"
            description="Subtitles section content"
            defaultMessage="Subtitles are automatically generated on Stories with dialogue. You can also choose to upload Subtitle files manually."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.STORY_SUBTITLES,
        },
      },
    ],
  },
];
