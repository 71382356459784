import invariant from 'invariant';
import _ from 'lodash';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { loadAssetInfo } from 'state/asset/actions/assetActions';
import { getEditionById, editionSubscriptionText } from 'state/editions/selectors/editionsSelectors';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { isLowResolutionTopsnapsEnabled, isSpectaclesVideoEnabled } from 'state/features/selectors/featuresSelectors';
import { getFrameResultById, frameUnique, getUploadResultById } from 'state/media/selectors/mediaSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import { isShowPublisher } from 'state/publishers/schema/publisherEntityHelpers';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import { isSubscribeSnap, topsnapHasOverlay } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import { loadSubtitlesTrack } from 'state/subtitles/actions/subtitlesActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import {
  Sequence,
  ErrorType,
  UploadPurpose,
  FileType,
  VideoMode,
  CrossOrigin,
  MlsMediaProfile,
} from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import { EditionID } from 'src/types/editionID';
import { PublisherID } from 'src/types/publishers';
import { GetState } from 'src/types/redux';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import { story } from 'utils/apis/scsAPI';
import { assertArg, assertState } from 'utils/assertionUtils';
import { extractSnapIdFromComponentId, buildComponentIdForSnapId } from 'utils/componentUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import {
  clearInfoMessage,
  InfoContext,
  infoMessageHandler,
  showInfoMessage,
} from 'utils/errors/infoMessage/infoMessageUtils';
import { getMessageForMediaError, mediaErrorHandler } from 'utils/errors/media/mediaErrorUtils';
import * as formUtils from 'utils/formUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import makeIntlMessage from 'utils/intlMessages/intlMessages';
import { loadImage } from 'utils/media/ImageLoader';
import { createAssetUrl } from 'utils/media/assetUtils';
import { isBlob, blobAsUrl } from 'utils/media/blobUtils';
import { getFileType } from 'utils/media/fileUtils';
import * as fileValidation from 'utils/media/fileValidation';
import { SIZE_ERROR } from 'utils/media/fileValidation';
import * as mediaValidation from 'utils/media/mediaValidation';
import { getValidationOptions } from 'utils/media/mediaValidationConfig';
import * as videoConversion from 'utils/media/videoConversion';
import * as videoUtils from 'utils/media/videoUtils';
import { logPromiseTiming } from 'utils/metricsUtils';
import { ModalType } from 'utils/modalConfig';
import * as promiseUtils from 'utils/promiseUtils';
import { isS3Endpoint } from 'utils/uploadUtils';

import { parseSubtitles } from 'views/common/components/SubtitlesPreview/subtitleParser';
import { updateSubtitlesState } from 'views/subtitles/state/actions/subtitlesEditorActions';

import * as uploadRequestBuilder from './uploadRequestBuilder';

import { assertAssetId, createMediaID } from 'types/assets';
import { MediaInfo } from 'types/media';
import { SnapType, TopSnap, TopsnapType } from 'types/snaps';

export const GRAB_FRAME = 'media/GRAB_FRAME';
export function openPreview(assetId: any) {
  return (dispatch: any) => {
    return dispatch(modalsActions.showModal(ModalType.PLAY_VIDEO, 'openPreview', { assetId }));
  };
}
// @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
export const getValidThumbnailUrl = (thumbnailUrl: any) => loadImage(thumbnailUrl).then((image: any) => image.src);
export const UPLOAD_MEDIA = 'media/UPLOAD_MEDIA';
export const UPLOAD_MEDIA_GET_URL = 'media/UPLOAD_MEDIA_GET_URL';
export const CLAIM_MEDIA = 'media/CLAIM_MEDIA';
export const GET_FRESH_TRANSCODE = 'media/GET_FRESH_TRANSCODE';
export const UPDATE_SUBTITLES = 'media/UPDATE_SUBTITLES';
export const SPLIT_SUBTITLES = 'media/SPLIT_SUBTITLES';
export const RESET_ACTIVE_MEDIA_ID = 'media/RESET_ACTIVE_MEDIA_ID';
const MEDIA_ERROR = 'MediaError';
const applyShowMediaRestrictions = ({ publisher, snap, purpose, fileType }: any) => (dispatch: any) => {
  if (
    isShowPublisher(publisher) &&
    purpose === UploadPurpose.TOP_SNAP &&
    snap &&
    !snapEntityHelpers.isSubscribeSnap(snap) &&
    fileType === FileType.IMAGE
  ) {
    dispatch(showInfoMessage(InfoContext.SHOW_TOPSNAP_IMAGE_DISALLOWED));
    const error = new Error('Show topsnap image disallowed');
    (error as any).alreadyHandled = true;
    throw error;
  }
};
export const getOptionsForMediaInfo = ({ purpose, customValidationOptions, maxDurationSeconds, ...rest }: any) => (
  mediaInfo: MediaInfo
) => {
  const validationOptions = getValidationOptions(purpose, mediaInfo);
  const options = { ...validationOptions, ...customValidationOptions, ...rest };
  if (purpose === UploadPurpose.TOP_SNAP) {
    // Max duration for topsnaps can be overridden on a per-publisher basis
    if (maxDurationSeconds) {
      options.maxDurationSeconds = maxDurationSeconds;
    }
    if (customValidationOptions && customValidationOptions.allowLowResolutionTopsnaps) {
      options.minWidthPx = options.lowResMinWidthPx;
      options.minHeightPx = options.lowResMinHeightPx;
    }
  }
  return options;
};
export function uploadUrl(dispatch: any, url: string, fileType: FileType, params: any, dataUri: any) {
  return mediaValidation
    .validateMedia(dataUri, fileType, getOptionsForMediaInfo(params))
    .catch(mediaErrorHandler(dispatch, ErrorContexts.VALIDATE_MEDIA))
    .then((metadata: any) =>
      dispatch({
        type: UPLOAD_MEDIA,
        meta: {
          [CALL_API]: {
            method: 'post',
            endpoint: mediaLibraryAPI.asset.urlUpload(),
            body: formUtils.toFormData({
              url,
              entityOwner: params.entityOwner,
              mediaProfile: MlsMediaProfile.REGULAR_IMAGE,
            }),
            withProgress: true,
          },
        },
        params: {
          componentId: params.componentId,
          snapId: params.snapId,
        },
        dataUri,
      })
    )
    .catch((error: any) => {
      if (error && error.name === SIZE_ERROR) {
        return mediaErrorHandler(dispatch, ErrorContexts.UPLOAD_MEDIA)(error);
      }
      return apiErrorHandler(dispatch, ErrorContexts.UPLOAD_MEDIA, { uri: url })(error);
    });
}

const MAX_MEDIA_SIZE_BYTES = 1024 * 1024 * 1024; // 1GB
export function uploadUsingPostToSignedUrl(
  validateFunction: () => Promise<any>,
  dispatch: any,
  file: File,
  fileType: FileType,
  params: any,
  dataUri: any
) {
  return validateFunction()
    .catch(mediaErrorHandler(dispatch, ErrorContexts.VALIDATE_MEDIA))
    .then(() =>
      dispatch({
        type: UPLOAD_MEDIA_GET_URL,
        meta: {
          [CALL_API]: {
            method: 'get',
            endpoint: mediaLibraryAPI.asset.getUploadUrl({ entityOwner: params.entityOwner }),
            finalizer: optimisticJsonFinalizer,
          },
        },
      })
    )
    .then((uploadMediaGetUrlAction: any) => {
      fileValidation.validateFile(file, { maxSizeBytes: MAX_MEDIA_SIZE_BYTES });
      return dispatch({
        type: UPLOAD_MEDIA,
        meta: {
          [CALL_API]: {
            method: 'put',
            endpoint: uploadMediaGetUrlAction.payload.url,
            body: file,
            withProgress: true,
            finalizer: optimisticJsonFinalizer,
          },
          mediaMetadata: uploadMediaGetUrlAction.metadata,
        },
        params: {
          overlayUrl: params?.overlayUrl,
          baseUrl: params?.baseUrl,
        },
        dataUri,
        mediaId: uploadMediaGetUrlAction.payload.mediaId,
      });
    })
    .then((uploadMediaAction: any) => {
      return claimMediaIfS3Upload(uploadMediaAction, dispatch, file, params).catch((error: any) => {
        // eslint-disable-next-line no-param-reassign
        error.name = MEDIA_ERROR;

        try {
          const validationError = JSON.parse(error.fetchResults?.data)?.validationStatus;
          // eslint-disable-next-line no-param-reassign
          error.intlError = makeIntlMessage(getMessageForMediaError(validationError));
        } catch (err) {
          // failed to parse message
          // eslint-disable-next-line no-param-reassign
          error.intlError = makeIntlMessage('error-mls-claim');
        }

        return Promise.reject(error);
      });
    })
    .catch((error: any) => {
      if (error && error.name) {
        return mediaErrorHandler(dispatch, ErrorContexts.UPLOAD_MEDIA)(error);
      }
      return apiErrorHandler(dispatch, ErrorContexts.UPLOAD_MEDIA, { uri: file })(error);
    });
}
// We don't always want to dispatch claim media action.
// So I'm passing dispatch as a second paramter.
export function claimMediaIfS3Upload(uploadMediaAction: any, dispatch: any, file: File | undefined, params: any) {
  const endpoint = _.get(uploadMediaAction, ['meta', CALL_API, 'endpoint'], '');
  const { dataUri, meta } = uploadMediaAction;
  if (isS3Endpoint(endpoint)) {
    const publisherFlags = {
      isShow: params.isShow,
      isDynamicEditions: params.isDynamicEditions,
    };
    const mediaProfile = uploadRequestBuilder.uploadPurposeToMlsMediaAction(
      params.purpose,
      publisherFlags,
      file,
      meta.mediaMetadata
    );
    const requestCreativeSuiteIngestion = mediaProfile === MlsMediaProfile.SINGLE_VIDEO_STORY_MEDIA;
    const request =
      mediaProfile === MlsMediaProfile.VTT_SUBTITLES
        ? uploadRequestBuilder.buildClaimSubtitlesRequest(endpoint, params.subtitlesLanguage)
        : uploadRequestBuilder.buildClaimMediaRequest(endpoint, mediaProfile, requestCreativeSuiteIngestion);
    return dispatch({
      type: CLAIM_MEDIA,
      meta: {
        [CALL_API]: request,
      },
      params,
      dataUri,
    });
  }
  return Promise.resolve(uploadMediaAction);
}
export function getFreshTranscodeWithAssetType(mediaLibraryId: any, assetType: any) {
  return (dispatch: any) => {
    return dispatch(
      createCallAction(
        {
          type: GET_FRESH_TRANSCODE,
        },
        {
          endpoint: mediaLibraryAPI.asset.getFreshTranscode({ mediaLibraryId, assetType }),
        }
      )
    );
  };
}
export function getFreshTranscode(mediaLibraryId: any) {
  return (dispatch: any) => {
    return dispatch(
      createCallAction(
        {
          type: GET_FRESH_TRANSCODE,
        },
        {
          endpoint: mediaLibraryAPI.asset.getFreshTranscode({ mediaLibraryId }),
          finalizer: optimisticJsonFinalizer,
        }
      )
    );
  };
}

export function uploadMedia(
  blobOrUrl: any,
  fileType: FileType,
  params: {
    purpose: UploadPurpose;
    editionId?: EditionID;
    publisherId?: PublisherID;
    isShow?: boolean;
    isDynamicEditions?: boolean;
    isSingleAssetVideo?: boolean;
    entityOwner?: string | null;
    force?: boolean;
    hidden?: boolean;
    customValidationOptions?: {
      allowLowResolutionTopsnaps: boolean;
      isSpectaclesVideoEnabled: boolean;
    };
    maxDurationSeconds?: number;
  }
) {
  return (dispatch: any, getState: GetState) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(blobOrUrl).is.existy();
    incrementCounter('MediaAction.uploadMedia');
    let entityOwner;
    // Populate isShow to the upload params. This is used to decided which media profile to use for top snaps.
    const isShow = publishersSelectors.activePublisherIsShow(getState());
    params.isShow = isShow; // eslint-disable-line no-param-reassign
    const isDynamicEditions = publishersSelectors.activePublisherIsDynamicEditions(getState());
    params.isDynamicEditions = isDynamicEditions; // eslint-disable-line no-param-reassign
    params.isSingleAssetVideo = params.purpose === UploadPurpose.SINGLE_ASSET_STORY; // eslint-disable-line no-param-reassign

    if (params.purpose === UploadPurpose.TILE_LOGO) {
      entityOwner = params?.publisherId?.toString();
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(entityOwner).is.string();
      params.entityOwner = entityOwner; // eslint-disable-line no-param-reassign
    } else if (!params.entityOwner) {
      entityOwner = userSelectors.getActivePublisherIdAsString(getState());
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(entityOwner).is.string();
      params.entityOwner = entityOwner; // eslint-disable-line no-param-reassign
    }
    const dataUri = blobAsUrl(blobOrUrl);
    const validateFunction = () => {
      return params.force
        ? Promise.resolve()
        : mediaValidation.validateMedia(dataUri, fileType, getOptionsForMediaInfo(params));
    };
    if (isBlob(blobOrUrl)) {
      const uploadUsingPostToSignedUrlPromise = () =>
        uploadUsingPostToSignedUrl(validateFunction, dispatch, blobOrUrl, fileType, params, dataUri);
      return logPromiseTiming(uploadUsingPostToSignedUrlPromise, 'signedUrlUpload', params.purpose);
    }
    const uploadUrlPromise = () => uploadUrl(dispatch, blobOrUrl, fileType, params, dataUri);
    return logPromiseTiming(uploadUrlPromise, 'directPostUpload', params.purpose);
  };
}

export function validateMedia(dataUri: any, fileType: FileType | null, options: any) {
  return (dispatch: any, getState: GetState) => {
    return mediaValidation
      .validateMedia(dataUri, fileType, options)
      .catch(mediaErrorHandler(dispatch, ErrorContexts.VALIDATE_MEDIA));
  };
}
export function grabFrame(blobOrUrl: any, width: any, height: any) {
  const dataUri = blobAsUrl(blobOrUrl);
  const frameKey = frameUnique(dataUri, width, height);
  return (dispatch: any) => {
    return Promise.resolve(
      dispatch({
        type: GRAB_FRAME,
        sequence: Sequence.START,
        frameKey,
      })
    )
      .then(started => videoConversion.grabFrame(dataUri, width, height))
      .then(result =>
        dispatch({
          type: GRAB_FRAME,
          sequence: Sequence.DONE,
          frameKey,
          payload: { result: blobAsUrl(result), width, height },
        })
      )
      .catch(err => {
        return Promise.resolve(
          dispatch({
            type: GRAB_FRAME,
            sequence: Sequence.DONE,
            frameKey,
            error: err,
          })
        ).then(() => Promise.reject(err));
      });
  };
}
export function grabFrameOnce(blobOrUrl: any, width: any, height: any) {
  const dataUri = blobAsUrl(blobOrUrl);
  const frameKey = frameUnique(dataUri, width, height);
  return (dispatch: any, getState: GetState) => {
    const result = getFrameResultById(getState())(frameKey);
    if (result && !(result as any).error) {
      return Promise.resolve({
        payload: {
          result,
          width,
          height,
        },
      });
    }
    return dispatch(grabFrame(blobOrUrl, width, height));
  };
}
export const uploadMediaGetResult = (blobOrUrl: any, fileType: FileType, params: any) => (
  dispatch: any,
  getState: GetState
) => {
  return dispatch(uploadMediaGetResult.uploadMedia(blobOrUrl, fileType, params)).then(() => {
    const dataUri = blobAsUrl(blobOrUrl);
    return getUploadResultById(getState())(dataUri);
  });
};
uploadMediaGetResult.uploadMedia = uploadMedia;
export const updateSubtitles = (mediaId: any, subtitlesMediaId: any) => async (dispatch: any) => {
  const subtitleTrack = {
    id: subtitlesMediaId,
    src: createAssetUrl(subtitlesMediaId),
  };
  // load the video
  const {
    payload: {
      entities: { assets },
    },
  } = await dispatch(loadAssetInfo(mediaId));
  const videoAssetDuration = assets[mediaId]?.durationMillis;
  // load the subtitles
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: any; src: string; }' is no... Remove this comment to see the full error message
  const subtitlesTrack = await dispatch(loadSubtitlesTrack(subtitleTrack));
  const parsedSubtitles = parseSubtitles(subtitlesTrack?.payload);
  // without this the subtitles body won't know it needs to update
  await dispatch(
    updateSubtitlesState(subtitlesMediaId, {
      subtitleFragments: parsedSubtitles,
    })
  );
  // check if any of the subtitles fragments end after the end of the video
  const fragmentsLongerThanVideoDuration = parsedSubtitles.filter(
    ({ timestamp: { endTimeMs } }) => endTimeMs > videoAssetDuration
  );
  // check that none of the subtitles fragments exceed the length of the video;
  if (fragmentsLongerThanVideoDuration.length) {
    return Promise.reject(
      new Error('At least one of the subtitle tracks exceeds the length of the entire video')
    ).finally(() => dispatch(showInfoMessage(InfoContext.ERROR_INCORRECT_VTT)));
  }
  const updateSubtitlesAction = createCallAction(
    {
      type: UPDATE_SUBTITLES,
    },
    {
      method: 'post',
      endpoint: mediaLibraryAPI.mediaLibrary.updateSubtitles({ mediaLibraryId: mediaId, subtitlesMediaId }),
    }
  );
  return dispatch(updateSubtitlesAction);
};
export const updateSubtitlesList = (mediaId: any, subtitlesMediaIds: any) => async (dispatch: any) => {
  const updateSubtitlesAction = createCallAction(
    {
      type: UPDATE_SUBTITLES,
    },
    {
      method: 'post',
      endpoint: mediaLibraryAPI.mediaLibrary.updateSubtitlesList({ mediaLibraryId: mediaId, subtitlesMediaIds }),
    }
  );
  return dispatch(updateSubtitlesAction);
};
export const splitSubtitles = (editionId: any, subtitleMediaId: any, requestFreshTranscode = true) => (
  dispatch: any
) => {
  return dispatch(
    createCallAction(
      {
        type: SPLIT_SUBTITLES,
      },
      {
        method: 'post',
        endpoint: story.splitSubtitle({ editionId, subtitleMediaId, requestFreshTranscode }),
      }
    )
  );
};
export const UPLOAD_MEDIA_FINALIZE = 'media/UPLOAD_MEDIA_FINALIZE';
const FILE_TYPES_TO_ASSET_ID_FIELDS: Partial<{ [key in FileType]: string }> = {
  [FileType.IMAGE]: 'imageAssetId',
  [FileType.VIDEO]: 'videoAssetId',
};
const FILE_TYPES_TO_RICH_SNAP_TYPES: Partial<{ [key in FileType]: SnapType }> = {
  [FileType.IMAGE]: SnapType.IMAGE,
  [FileType.VIDEO]: SnapType.VIDEO,
};
const FILE_TYPES_TO_ADDITIONAL_SNAP_PARAMS_GENERATOR: Partial<
  { [key in FileType]: (publisher?: any, topsnap?: any, mediaInfo?: any) => any }
> = {
  [FileType.IMAGE]: () => {
    return {
      creativeId: null,
    };
  },
  [FileType.VIDEO]: (publisher: any, topsnap: any, mediaInfo: any) => {
    const noLoop = _.get(publisher, ['videoModeEnabled'], false) && !snapEntityHelpers.isSubscribeSnap(topsnap);
    return {
      videoMode: noLoop ? VideoMode.ONCE : VideoMode.LOOPING,
      creativeId: null,
      circular: mediaInfo ? videoUtils.isCircularVideo(mediaInfo) : false,
    };
  },
};
const RICH_SNAP_TYPES_TO_FILE_TYPES = {
  [TopsnapType.IMAGE]: FileType.IMAGE,
  [TopsnapType.VIDEO]: FileType.VIDEO,
  [TopsnapType.BITMOJI_REMOTE_VIDEO]: null,
  [TopsnapType.BITMOJI_REMOTE_WEB]: null,
  [TopsnapType.CAMEOS_CONTENT]: null,
  [TopsnapType.SINGLE_ASSET]: null,
  [TopsnapType.UNKNOWN]: null,
};
const uploadPurposesThatNeedEditionId = [
  UploadPurpose.TILE_IMAGE,
  UploadPurpose.TILE_CROP_IMAGE,
  UploadPurpose.HAPPENING_NOW_TILE_IMAGE,
  UploadPurpose.HAPPENING_NOW_TILE_CROP_IMAGE,
  UploadPurpose.ARTICLE_IMAGE,
  UploadPurpose.POLL_IMAGE,
  UploadPurpose.EDITION_SUBTITLES,
];

export function finalizeUpload(
  mediaId: any,
  mediaInfo: any,
  fileType: FileType,
  params: any,
  setEditionPropertiesAndSave: any
) {
  return async (dispatch: any, getState: GetState) => {
    const activePublisher = publishersSelectors.getActivePublisherDetails(getState());
    let updateParam;
    let fileTypeIsChanging;
    switch (params.purpose) {
      case UploadPurpose.CURATED_SNAP_MEDIA:
      case UploadPurpose.TOP_SNAP: {
        const snapId = extractSnapIdFromComponentId(params.componentId);
        const topsnap = snapsSelectors.getSnapById(getState())(snapId) as TopSnap;
        const currentFileType = topsnap?.type ? RICH_SNAP_TYPES_TO_FILE_TYPES[topsnap.type] : null;
        const assetIdField = FILE_TYPES_TO_ASSET_ID_FIELDS[fileType];
        fileTypeIsChanging = fileType !== currentFileType;
        let additionalParams = {};
        if (FILE_TYPES_TO_ADDITIONAL_SNAP_PARAMS_GENERATOR[fileType]) {
          // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          additionalParams = FILE_TYPES_TO_ADDITIONAL_SNAP_PARAMS_GENERATOR[fileType](
            activePublisher,
            topsnap,
            mediaInfo
          );
        }

        if (fileTypeIsChanging) {
          const newSnapType = FILE_TYPES_TO_RICH_SNAP_TYPES[fileType];
          updateParam = {
            // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
            [assetIdField]: mediaId,
            type: newSnapType,
            ...additionalParams,
          };
        } else {
          updateParam = {
            // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
            [assetIdField]: mediaId,
            ...additionalParams,
          };
        }
        if (params.name) {
          updateParam = { ...updateParam, name: params.name };
        }

        if (params.editionId && topsnap && isSubscribeSnap(topsnap) && !topsnapHasOverlay(topsnap as TopSnap)) {
          const subscriptionText = await editionSubscriptionText(getState())(params.editionId);
          updateParam = { ...updateParam, subscriptionText };
        }

        break;
      }
      case UploadPurpose.SINGLE_ASSET_STORY: {
        // Do Nothing. Caller is responsible for handling asset ID.
        break;
      }
      case UploadPurpose.TILE_IMAGE: {
        // Do Nothing. Caller is responsible for handling asset ID.
        break;
      }
      case UploadPurpose.TILE_CROP_IMAGE:
        // Do nothing. We upload the image so we can get the asset ID, which is uploaded elsewhere.
        break;
      case UploadPurpose.HAPPENING_NOW_TILE_IMAGE: {
        // Do Nothing. Caller is responsible for handling asset ID.
        break;
      }
      case UploadPurpose.HAPPENING_NOW_TILE_CROP_IMAGE: {
        // Do nothing. We upload the image so we can get the asset ID, which is uploaded elsewhere.
        break;
      }
      case UploadPurpose.TILE_LOGO:
        // Do nothing. We upload the image so we can get the asset ID, which is uploaded elsewhere.
        break;
      case UploadPurpose.ARTICLE_IMAGE:
        // Do nothing. We upload the image so we can get the asset ID, which is uploaded elsewhere.
        break;
      case UploadPurpose.OVERLAY_MEDIA:
      case UploadPurpose.CURATED_SNAP_OVERLAY_MEDIA:
        updateParam = { overlayImageAssetId: mediaId };
        break;
      case UploadPurpose.POLL_IMAGE:
        break;
      case UploadPurpose.SUBTITLE: {
        const { assetId } = params;
        const component = componentsSelectors.getActiveComponent(getState());
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        const videoSnapId = component.snap.id;
        return dispatch(updateSubtitles(assetId, createMediaID(mediaId)))
          .then(() => dispatch(getFreshTranscode(assetId)))
          .then((transcodeResponse: any) => {
            dispatch(
              snapsActions.setSnapPropertiesAndSave(
                { snapId: videoSnapId },
                { longformVideoAssetId: transcodeResponse.payload.id },
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
                ErrorType.SAVE_SNAP_MEDIA
              )
            );
          });
      }
      case UploadPurpose.EDITION_SUBTITLES: {
        const { editionId } = params;
        const edition = getEditionById(getState())(editionId);
        if (edition && edition.videoTracks && edition.videoTracks.length > 0) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          const videoTrackIdToUpdate = edition.videoTracks[0].transcodedMediaId;
          return (
            dispatch(updateSubtitles(videoTrackIdToUpdate, createMediaID(mediaId)))
              .then(() => dispatch(getFreshTranscode(videoTrackIdToUpdate)))
              // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'newTranscodedMediaId' implicitly ... Remove this comment to see the full error message
              .then(({ payload: { id: newTranscodedMediaId } }) => {
                invariant(edition, `Edition not found: ${editionId}`);
                const updatedVideoTracks = u({ 0: { transcodedMediaId: newTranscodedMediaId } }, edition.videoTracks);
                invariant(
                  setEditionPropertiesAndSave,
                  `setEditionPropertiesAndSave must be defined for: ${params.purpose}`
                );
                return dispatch(
                  setEditionPropertiesAndSave(
                    { editionId },
                    {
                      videoTracks: updatedVideoTracks,
                    }
                  )
                );
              })
          );
        }
        return dispatch(splitSubtitles(params.editionId, mediaId)).catch((error: any) => {
          infoMessageHandler(dispatch, InfoContext.ERROR_BAD_VTT);
          const updatedError = error;
          updatedError.alreadyHandled = true;
          throw updatedError;
        });
      }
      case UploadPurpose.TOPSNAP_SUBTITLES: {
        const { snapId, assetId } = params;
        infoMessageHandler(dispatch, InfoContext.SAVING);
        return dispatch(updateSubtitles(assetId, createMediaID(mediaId)))
          .then(() => dispatch(getFreshTranscode(assetId)))
          .then((transcodeResponse: any) => {
            dispatch(
              snapsActions.setSnapPropertiesAndSave(
                { snapId },
                { videoAssetId: transcodeResponse.payload.id },
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
                ErrorType.SAVE_SNAP_MEDIA
              )
            );
            return transcodeResponse.payload.id;
          })
          .finally(clearInfoMessage(getState, dispatch, InfoContext.SAVING));
      }
      default:
        throw new Error(`Unhandled purpose ${params.purpose}`);
    }
    // updateParams is non null only when working on snaps
    if (updateParam) {
      const snapId = params.snapId || extractSnapIdFromComponentId(params.componentId);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
      return dispatch(snapsActions.setSnapPropertiesAndSave({ snapId }, updateParam, ErrorType.SAVE_SNAP_MEDIA));
    }
    return null;
  };
}
export const uploadMediaAndFinalize = ({ media, fileType, params, setEditionPropertiesAndSave }: any) => (
  dispatch: any,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(params.componentId).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(params.purpose).is.inValues(UploadPurpose);
  if (uploadPurposesThatNeedEditionId.indexOf(params.purpose) !== -1) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(params.editionId).is.number();
  }
  const publisher = publishersSelectors.getActivePublisherDetails(getState());
  const snap = params.snapId ? snapsSelectors.getSnapById(getState())(params.snapId) : null;
  dispatch(
    applyShowMediaRestrictions({
      publisher,
      snap,
      fileType,
      purpose: params.purpose,
    })
  );
  let assetId: any;
  // Used to track the upload status therefore we want to send a start and done action
  // This may result in any one upload being tracked as two at a certain point
  // However, this shouldn't matter as we are just testing for a truthy value.
  dispatch({
    type: UPLOAD_MEDIA_FINALIZE,
    params,
    sequence: Sequence.START,
    payload: {},
  });
  const sendDone = () =>
    dispatch({
      type: UPLOAD_MEDIA_FINALIZE,
      params,
      sequence: Sequence.DONE,
      payload: {},
    });
  const isUploadingVideoTopsnap = params.purpose === UploadPurpose.TOP_SNAP && fileType === FileType.VIDEO;
  // TODO (piers) Mass rename of 'media' to 'asset' throughout
  const uploadAndSaveSnapIfChanged = () => {
    return Promise.all([
      dispatch(uploadMediaGetResult(media, fileType, { ...params, creatorUsername: params.name })),
      isUploadingVideoTopsnap ? mediaValidation.getVideoInfo(blobAsUrl(media)) : Promise.resolve(),
    ])
      .then(([mediaResult, mediaInfo]) => {
        const mediaAssetId = mediaResult.mediaId;
        if (params.subtitlesMediaIds?.length) {
          return dispatch(updateSubtitlesList(mediaAssetId, params.subtitlesMediaIds))
            .then(() => dispatch(getFreshTranscode(mediaAssetId)))
            .then((transcodeResponse: any) => [transcodeResponse.payload.id, mediaInfo]);
        }
        return [mediaAssetId, mediaInfo];
      })
      .then(([mediaAssetId, mediaInfo]) => {
        assetId = mediaAssetId;
        return dispatch(finalizeUpload(assetId, mediaInfo, fileType, params, setEditionPropertiesAndSave));
      });
  };
  return Promise.resolve()
    .then(uploadAndSaveSnapIfChanged)
    .then(...promiseUtils.finallyPromise(sendDone))
    .then(() => ({ assetId }));
};
export const uploadMediaToTopsnap = ({ file, snapId, editionId, extraParams }: any) => (
  dispatch: any,
  getState: GetState
) => {
  const state = getState();
  const maxDurationSeconds = editorSelectors.getTopsnapDurationLimit(state);
  const params = {
    componentId: buildComponentIdForSnapId(snapId),
    purpose: UploadPurpose.TOP_SNAP,
    customValidationOptions: {
      allowLowResolutionTopsnaps: isLowResolutionTopsnapsEnabled(state),
      isSpectaclesVideoEnabled: isSpectaclesVideoEnabled(state),
    },
    maxDurationSeconds,
    editionId,
    snapId,
    ...extraParams,
  };
  return dispatch(
    uploadMediaAndFinalize({
      media: file,
      params,
      fileType: getFileType(file),
    })
  );
};
export const DUPLICATE_ASSET = 'media/DUPLICATE_ASSET';
export const duplicateAsset = ({ assetId, autoBuild }: any = {}) => {
  return (dispatch: any, getState: GetState) => {
    return dispatch({
      type: DUPLICATE_ASSET,
      meta: {
        [CALL_API]: {
          method: 'put',
          endpoint: mediaLibraryAPI.asset.duplicate({ assetId, autoBuild }),
          finalizer: optimisticJsonFinalizer,
        },
      },
    });
  };
};
export const UPDATE_ASSET_PROPERTIES = 'media/UPDATE_ASSET_PROPERTIES';
export const updateAssetProperties = ({ assetId, type, properties }: any) => {
  assertAssetId(assetId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(properties).is.object();
  const wrapperProperties = u(
    {
      id: assetId,
      type,
    },
    properties
  );
  return {
    type: UPDATE_ASSET_PROPERTIES,
    meta: {
      [CALL_API]: {
        method: 'put',
        body: wrapperProperties,
        endpoint: mediaLibraryAPI.asset.info({ assetId }),
      },
    },
  };
};

export const prepareVideo = async (src: any, currentTime: any) => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.crossOrigin = CrossOrigin.USE_CREDENTIALS;
    const onCanPlayThrough = () => {
      videoElement.removeEventListener('canplaythrough', onCanPlayThrough);
      videoElement.currentTime = currentTime;
    };

    const onSeeked = () => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeVideoElementListeners();
      resolve(videoElement);
    };
    const onError = (event: any) => {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      removeVideoElementListeners();
      reject(event.target.error);
    };

    const removeVideoElementListeners = () => {
      videoElement.removeEventListener('canplaythrough', onCanPlayThrough);
      videoElement.removeEventListener('seeked', onSeeked);
      videoElement.removeEventListener('error', onError);
    };

    videoElement.addEventListener('canplaythrough', onCanPlayThrough);
    videoElement.addEventListener('seeked', onSeeked);
    videoElement.addEventListener('error', onError);
    videoElement.preload = 'auto';
    videoElement.src = src;
  });
};
export const createStillFromVideoElement = async ({
  videoElement,
  targetWidth,
  targetHeight,
  trimWidth,
  trimHeight,
}: any) => {
  return new Promise(resolve => {
    const canvas = document.createElement('canvas');
    canvas.width = trimWidth;
    canvas.height = trimHeight;
    const context = canvas.getContext('2d');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    context.drawImage(
      videoElement,
      0,
      0,
      videoElement.videoWidth,
      videoElement.videoHeight,
      0,
      0,
      targetWidth,
      targetHeight
    );
    return canvas.toBlob(blob => {
      resolve(blob);
    });
  });
};
export const createThumbnailFromVideo = async ({
  src,
  videoWidth,
  videoHeight,
  trimWidth,
  trimHeight,
  currentTime,
}: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(src).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(videoWidth).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(videoHeight).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(trimWidth).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(trimHeight).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(currentTime).is.number();
  const videoElement = await exports.prepareVideo(src, currentTime);
  const stillPng = await exports.createStillFromVideoElement({
    videoElement,
    targetWidth: videoWidth,
    targetHeight: videoHeight,
    trimWidth,
    trimHeight,
  });
  return stillPng;
};
export const resetActiveMediaId = () => {
  return {
    type: RESET_ACTIVE_MEDIA_ID,
  };
};
