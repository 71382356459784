import log from 'loglevel';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'prom... Remove this comment to see the full error message
import promiseRetry from 'promise-retry';

import {
  authRefreshLoopId,
  getAuthFailed,
  getToken,
  lastRichsnapToken,
  getAuthType,
  getUserId,
} from 'state/auth/selectors/authSelectors';
import * as barrierActions from 'state/barrier/actions/barrierActions';
import { loopAction } from 'state/common/actionFactories';

import * as googleApi from '../utils/googleApi';
import { discoverApiPreloader } from '../utils/preloadApi';

import { AuthType, LocalStorage, SSAPI_PROXY_BASE_URL, MINUTES } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { GetState } from 'src/types/redux';
import * as gaUtils from 'utils/gaUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { localStorage } from 'utils/localStorageUtils';
import { PromiseSingleton } from 'utils/promiseUtils';

import * as authActionTypes from './authActionTypes';
import * as googleAuthActions from './googleAuthActions';
import * as snapAuthActions from './snapAuthActions';

const TOKEN_REFRESH_INTERVAL_MS = 10 * MINUTES;

export const login = () => (dispatch: any, getState: GetState) => {
  if (getAuthType(getState()) === AuthType.SNAPCHAT) {
    return dispatch(snapAuthActions.login());
  }

  return dispatch(googleAuthActions.login());
};

export const setRichsnapCookie = () => (dispatch: any, getState: GetState) =>
  dispatch({
    type: authActionTypes.SET_RICHSNAP_COOKIE,

    // Bailing out if the cookie is already set to the current token.
    bailout: (state: any) => lastRichsnapToken(state) === getToken(state),
    meta: {
      [CALL_API]: {
        endpoint: `${SSAPI_PROXY_BASE_URL}discover/ping`,
        method: 'HEAD',
        noReAuth: true,
      },
      bypassBarriers: [barrierActions.BarrierTypes.AUTH],
    },
    payload: {
      token: getToken(getState()),
    },
  });

export const load = () => ({
  type: authActionTypes.GOOGLE_LOGIN,
  payload: {
    promise: () => googleApi.load(),
  },
});

const reportGaAndRetry = (retry: any, attempt: any) => (err: any) => {
  gaUtils.logGAEvent(gaUtils.GAQoSMetrics.AUTH, (err && err.message) || 'Unknown', attempt);
  retry(err);
};

function reportTokenRefreshFailed(err: any) {
  gaUtils.logGAEvent(gaUtils.GAQoSMetrics.AUTH, 'token-refresh-failed', (err && err.message) || 'Unknown');
  incrementCounter('Login.SnapAuth', { action: 'refreshTokenFailed' });
  throw err;
}

// Using the promiseRetry library:
// https://github.com/IndigoUnited/node-promise-retry
export const refreshTokenWithRetry = () => (dispatch: any, getState: GetState) => {
  const authType = getAuthType(getState());

  return promiseRetry((retry: any, attempt: any) => {
    let promise;

    if (authType === AuthType.SNAPCHAT) {
      promise = dispatch(snapAuthActions.refreshToken());
    } else {
      promise = dispatch(googleAuthActions.refreshToken());
    }

    return promise.catch(reportGaAndRetry(retry, attempt));
  }, authActionTypes.TOKEN_REFRESH_RETRY_OPTIONS).catch(reportTokenRefreshFailed);
};

export const refreshToken = () => (dispatch: any, getState: GetState) =>
  dispatch({
    type: authActionTypes.REFRESH_TOKEN,
    payload: {
      promise: () => dispatch(refreshTokenWithRetry()),
    },
    meta: {
      bypassBarriers: [barrierActions.BarrierTypes.AUTH],
    },
  });

export const setAuthRefreshLoop = (payload: any) => ({
  type: authActionTypes.SET_REFRESH_LOOP,
  payload,
});

const authRefreshSingleton = new PromiseSingleton();

export const performAuthRefresh = () => (dispatch: any, getState: GetState) => {
  return authRefreshSingleton.execute(() => {
    return Promise.resolve()
      .then(() => dispatch(refreshToken()))
      .then(() => {
        // This can be done asynchronously
        dispatch(setRichsnapCookie());
        return Promise.resolve();
      });
  });
};

export const flagAuthAsFailedPermanently = () => (dispatch: any, getState: GetState) => {
  if (getAuthFailed(getState())) {
    return Promise.resolve();
  }

  dispatch({
    type: authActionTypes.FLAG_AUTH_FAILED,
    payload: true,
  });

  return dispatch(logoutWithError('EXPIRED_SESSION'));
};

export const performAuthRefreshLoop = (loopId: any) => (dispatch: any, getState: GetState) => {
  // Creating here to bind to loopId
  const loopedRefresh = loopAction(() => exports.performAuthRefresh(), {
    cancelIf: () => {
      const authFailure = getAuthFailed(getState());
      const activeLoop = authRefreshLoopId(getState());
      const isLoggedOut = getUserId(getState()) == null;

      const isCancelled = activeLoop !== loopId || authFailure || isLoggedOut;
      if (isCancelled) {
        log.info('Auth refresh loop was cancelled');
      }
      return isCancelled;
    },
    onError: (error: any) => {
      log.error(error);
      dispatch(exports.flagAuthAsFailedPermanently());
    },
    delay: TOKEN_REFRESH_INTERVAL_MS,
  });

  return dispatch(loopedRefresh);
};

export const initAuthRefreshLoop = () => (dispatch: any, getState: GetState) => {
  const activeLoop = authRefreshLoopId(getState());

  // Increments loop to be the next id, sets that one as active, so one is only active at a time
  const nextLoop = activeLoop + 1;

  dispatch(setAuthRefreshLoop({ activeLoopId: nextLoop }));
  setTimeout(() => dispatch(exports.performAuthRefreshLoop(nextLoop)), TOKEN_REFRESH_INTERVAL_MS);
};

export const logout = (redirect: any, query = {}, keepRedirect = true) => (dispatch: any, getState: GetState) => {
  if (getAuthType(getState()) === AuthType.SNAPCHAT) {
    return dispatch(snapAuthActions.logout({ redirect, query, keepRedirect }));
  }

  return dispatch(googleAuthActions.logout({ redirect, query, keepRedirect }));
};

export function logoutWithError(errorCode: any) {
  return (dispatch: any, getState: GetState) => {
    if (getAuthType(getState()) === AuthType.SNAPCHAT) {
      return dispatch(snapAuthActions.logoutWithError(errorCode));
    }
    return dispatch(googleAuthActions.logoutWithError(errorCode));
  };
}

export const preloadGapi = () => (dispatch: any) => {
  return dispatch({
    type: authActionTypes.PRELOAD_GAPI,
    payload: {
      // We don't need the payload of this, and it doesn't serialize.
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      promise: () => discoverApiPreloader.preloadGapi().then(() => ({})),
    },
  });
};

export const setAuthType = (type: any) => (dispatch: any) => {
  log.info('Treated as logged in via %s', type);
  return dispatch({
    type: authActionTypes.AUTH_TYPE,
    payload: {
      authType: type,
    },
  });
};

export const updateAuthTypeBasedOnLocalStorage = () => (dispatch: any, getState: GetState) => {
  // If there is auth type already set do nothing
  if (getAuthType(getState())) {
    return;
  }

  const authType = localStorage.getItem(LocalStorage.AUTH_TYPE);

  if (authType) {
    dispatch(setAuthType(authType));
    // return;
  }

  // TODO send to dual login
};
