import { BaseLogParam } from '@snapchat/graphene';
import { detect } from 'detect-browser';
import { isFunction, noop } from 'lodash';

import { MAX_MEMORY_USAGE_MB } from 'config/constants';
import { incrementCounter, LogParam, reportLevel, reportTimerWithDuration } from 'utils/grapheneUtils';
import { logTiming } from 'utils/metricsUtils';

type Connection = {
  downlink: number;
  effectiveType: string;
};

enum ResourceType {
  RESOURCE = 'resource',
  NAVIGATION = 'navigation',
  PAINT = 'paint',
}

export enum PageLoadType {
  /**
   * Represents the initial load of a page where no cached resources are available.
   * This type of load typically takes the longest time to complete.
   */
  COLD = 'cold',

  /**
   * Represents a page load where some resources have been cached and can be retrieved more quickly.
   * This type of load is faster than a cold load, but slower than a hot load.
   */
  WARM = 'warm',

  /**
   * Represents a page load where all necessary resources are cached and can be retrieved almost instantly.
   * This type of load is the fastest and typically occurs when a routing within the app
   */
  HOT = 'hot',
}

export enum PagePerformanceMarkers {
  ROUTE_START_LOAD = 'routeStartLoading',
  ROUTE_END_LOAD = 'routeEndLoading',
}

export enum PerformanceMarkerMetrics {
  ROUTE = 'route',
}

export type PageLoadParams = {
  match: {
    isExact: boolean;
    path: string;
  };
  path: string;
};

// We need to prevent the othere resources from making it into the logs
enum AllowedResources {
  APP = 'app',
  VENDOR = 'vendor',
  STYLES = 'styles',
  MANIFEST = 'manifest',
}

interface NavigatorWithConnection extends Navigator {
  customConnection?: Connection;
  mozConnection?: Connection;
  webkitConnection?: Connection;
}

const defaultLogValue = 'unknown';

let hasCapturedInitialPageLoadMetrics = false;

/**
 * Checks if the Navigation Timing API is supported in the current environment.
 * Some older browsers (like Chrome 69) is known to throw on method like measure (when called with undefined start)
 * @returns A boolean value indicating whether the Navigation Timing API is supported.
 */
export const isPerformanceTimingAPISupported = (): boolean => {
  return performance && isFunction(performance.getEntriesByType);
};

/**
 * Get the resource entry for the main app.js script
 */
const getAppResourceEntry = () => {
  const resources = performance.getEntriesByType(ResourceType.RESOURCE);
  return resources.filter(resource => getFileNameFromUrl(resource.name) === AllowedResources.APP)?.lastItem;
};

/**
 * Gets the browser connection property on the navigator browser API.
 * The connection object is named differently on some older browsers
 * @returns A network work speed value of 4g, 2g, slow etc
 */
const getBrowserNetworkSpeed = () => {
  if (navigator) {
    const customNavigator = navigator as NavigatorWithConnection;
    const possibleConnections = [
      customNavigator.customConnection,
      customNavigator.mozConnection,
      customNavigator.webkitConnection,
    ];
    const connection = possibleConnections.find(possibleConnection => possibleConnection);
    return connection?.effectiveType ?? defaultLogValue;
  }
  return defaultLogValue;
};

export const isColdLoad = (): boolean => {
  if (hasCapturedInitialPageLoadMetrics || !isPerformanceTimingAPISupported()) {
    return false;
  }

  const appResource = getAppResourceEntry() as PerformanceNavigationTiming;

  if (appResource) {
    // When the network transfer size is more than zero then the page is a cold loaded
    return appResource.transferSize > 0;
  }

  incrementCounter('page_load_without_app_bundle');
  return false;
};

export const getPageLoadType = (hasCapturedInitialLoad: boolean) => {
  // Once we have recorded an initial load every subsequent route changes becomes a hot load
  if (hasCapturedInitialLoad) {
    return PageLoadType.HOT;
  }

  if (isColdLoad()) {
    return PageLoadType.COLD;
  }

  return PageLoadType.WARM;
};

/**
 * Records the load time for resources and increments the appropriate
 * counters for each network type (e.g. 2G, 3G, 4G, etc.) used to fetch the resources.
 */
export function recordResourceLoadTime(path: string) {
  if (isPerformanceTimingAPISupported()) {
    const resources = performance.getEntriesByType(ResourceType.RESOURCE);
    const browser = detect(navigator?.userAgent)?.name || defaultLogValue;

    const networkSpeed = getBrowserNetworkSpeed();

    // Determine the number of logical processors available on the user's device
    const cpuCapacity = navigator.hardwareConcurrency || 1;

    const metrics: LogParam[] = [];

    resources.forEach((entry: PerformanceEntry) => {
      const resourceTiming = entry as PerformanceResourceTiming;
      const scriptName = getFileNameFromUrl(resourceTiming.name);
      const allowedResources = Object.values(AllowedResources).map(value => value.toString());
      if (allowedResources.includes(scriptName)) {
        const duration = Math.round(resourceTiming.duration);
        metrics.push({
          metricsName: 'resource_load_by_asset',
          dimensions: {
            browser,
            networkSpeed,
            scriptName,
            path,
            cpuCapacity: cpuCapacity.toString(),
            loadType: duration === 0 ? PageLoadType.COLD : PageLoadType.WARM,
          },
          milliSec: duration,
        });
      }
    });

    // Send the metrics to the monitoring system
    metrics.forEach(metric => {
      // Log metrics to grafana
      reportTimerWithDuration(metric);
    });
  }
}

/**
 * Records metrics related to the initial page load (warm or cold load) and sends them to Grafana.
 *
 * @param {string} path - The path of the current page.
 * @param {boolean} isInitialLoad - Determines if this is a route change or initial load
 * @param {string|null} authType - The authentication type used to load the page, or null if not applicable.
 * @returns {void}
 */
export function recordPageLoadCountMetrics(path: string, isInitialLoad: boolean, authType: string | null) {
  if (isPerformanceTimingAPISupported()) {
    const browser = detect(navigator?.userAgent)?.name || defaultLogValue;

    // Send metrics to grafana
    incrementCounter('page_load_by_type', {
      browser,
      path,
      ...(authType && { authType }),
      loadType: getPageLoadType(isInitialLoad),
    });
  }
}

/**
 * Removes all symbols from resource entry resource names,
 * e.g url/vendor.[fullhash][more-symbols].js becomes "vendor"
 */
function getFileNameFromUrl(url: string): string {
  const parts: string[] = url.split('/');
  let fileName = parts[parts.length - 1] || defaultLogValue;
  const hashIndex: number = fileName.indexOf('.');
  if (hashIndex > -1) {
    fileName = fileName.substring(0, hashIndex);
  }
  return fileName;
}

/**
 Records the First Paint (FP), the Largest Contentful Paint (LCP) and First Contentful Paint (FCP)
 timings as native metrics using the reportTimerWithDuration method.
 */
export const recordNativeMetrics = (path: string): void => {
  const observer = new PerformanceObserver(list => {
    const entries = list.getEntries();

    entries.forEach(entry => {
      // The entry names comes in the format first-paint, remove the symbols
      const metricsName = entry.name.replace(/-/g, '').toLowerCase();

      const duration = Math.round(entry.startTime);

      // Log metrics to google analytics
      logTiming(metricsName, path, duration);

      // Log metrics to grafana
      reportTimerWithDuration({
        metricsName,
        milliSec: Math.round(entry.startTime),
        dimensions: {
          browser: detect(navigator?.userAgent)?.name || defaultLogValue,
          path,
        },
      });
    });
  });

  observer.observe({ type: ResourceType.PAINT, buffered: true });
};

const getPageLoadDurationByType = (loadType: PageLoadType, startTime: number): number => {
  if (loadType === PageLoadType.HOT) {
    // Using the performance mark api we measure the duration between the route start and end marker
    // In some cases where the performance API is not supported we fall back to calculating manually
    return performance?.getEntriesByName(PerformanceMarkerMetrics.ROUTE)[0]?.duration || Date.now() - startTime;
  }

  // Other page load type such as warm or cold can use the performance.now to capture duration from navigationStart
  return performance?.now() || Date.now() - startTime;
};

/**
 Captures the route change load time or hot load timings.
 The page load time here is defined as a function of blocking calls on the page route
 Sends recorded metrics to Grafana
 */
export const recordPageLoadTimingMetrics = (
  params: PageLoadParams,
  path: string,
  isInitialLoad: boolean,
  startTime: number,
  authType: string | null
) => {
  const loadType = getPageLoadType(isInitialLoad);
  const duration = getPageLoadDurationByType(loadType, startTime);
  const logParam: LogParam = {
    metricsName: 'page_load_time',
    dimensions: { path, loadType, ...(authType && { authType }) },
    milliSec: duration,
  };
  if (params.match.isExact) {
    reportTimerWithDuration(logParam);
  }
};

/**
 Returns a safe performance now to support older
 browsers (< IE10).
 */
export const getPerformanceTiming = () => {
  if (isPerformanceTimingAPISupported()) {
    return performance.now();
  }

  return Date.now();
};

/**
 Records the time it takes for a bundle to be downloaded.
 */
export const recordBundleDownloadTimingMetrics = (path: string) => {
  const perfEntries = performance.getEntriesByType(ResourceType.NAVIGATION);
  if (perfEntries.length > 0) {
    const navigationEntry = perfEntries[0] as PerformanceNavigationTiming;
    // The bundle is downloaded between the unloadEventEnd and domContentLoadedEventEnd
    const domContentLoadedTime = navigationEntry.domContentLoadedEventEnd;
    const unloadTime = navigationEntry.unloadEventEnd;
    const timeDifference = domContentLoadedTime - unloadTime;
    reportTimerWithDuration({
      metricsName: 'bundle_download_time',
      milliSec: timeDifference,
      dimensions: {
        path,
      },
    });
  }
};

export const capturePageLoadMetrics = (
  path: string,
  startTime: number,
  params: PageLoadParams,
  timeStamp: number,
  authType: string | null
) => {
  // Capture page load counter
  recordPageLoadCountMetrics(path, hasCapturedInitialPageLoadMetrics, authType);

  // Record metrics for page load timing
  recordPageLoadTimingMetrics(params, path, hasCapturedInitialPageLoadMetrics, timeStamp, authType);

  // We only record resource based metrics once
  if (!hasCapturedInitialPageLoadMetrics) {
    // Records FP (First Paint), FCP (First Contentful Paint) and LCP (Largest Contentful Paint)
    recordNativeMetrics(path);

    // Records the load-time required for every bundled resources
    recordResourceLoadTime(path);

    recordBundleDownloadTimingMetrics(path);

    hasCapturedInitialPageLoadMetrics = true;
  }
};

export async function measureDuration<T>(
  func: () => Promise<T>,
  params: BaseLogParam,
  callback: (duration: number) => void = noop
): Promise<T> {
  const start = getPerformanceTiming();
  const result = await func();
  const durationMs = getPerformanceTiming() - start;
  reportTimerWithDuration({ ...params, milliSec: durationMs });
  callback(durationMs);
  return result;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function measureDurationWrap<T, A extends [unknown] | unknown[]>(
  func: (...args: A) => Promise<T>,
  params: BaseLogParam,
  callback: (duration: number, ...args: A) => void = noop
): (...args: A) => Promise<T> {
  return async (...args: A): Promise<T> =>
    measureDuration(
      () => func(...args),
      params,
      (duration: number) => callback(duration, ...args)
    );
}

export function logMemoryUsage(path: string): void {
  // @ts-ignore: Property 'memory' does not exist on type 'typeof performance'.
  const memoryInfo = window.performance.memory;
  // Convert bytes to megabytes for easier readability
  // window.performance.memory.usedJSHeapSize is an approximation of the memory used subject to odd browser quirks
  // it showed more accurate results than measureUserAgentSpecificMemory on local testing
  // We can consider using measureUserAgentSpecificMemory for longer interval monitoring and detecting regressions.
  const usedMemoryMB = Math.ceil(memoryInfo.usedJSHeapSize / (1024 * 1024));
  if (usedMemoryMB >= MAX_MEMORY_USAGE_MB) {
    incrementCounter('high_memory_usage', { path });
    reportLevel('memory.usage', { path }, usedMemoryMB);
  }
}
