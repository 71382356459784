import { combineReducers } from 'redux';

import { SET_SPOTLIGHT_SNAPS } from '../actions/storySnapsActions';

function spotlightStorySnaps(state = null, action: any) {
  switch (action.type) {
    case SET_SPOTLIGHT_SNAPS:
      return action.storySnapsData;
    default:
      return state;
  }
}

// ------------------------------------
// Reducer
// ------------------------------------
export default combineReducers({
  spotlightStorySnaps,
});
