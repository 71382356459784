import log from 'loglevel';

import * as editorActions from 'state/editor/actions/editorActions';
import { loadSnapPreviewIfMissing } from 'state/previews/actions/previewsActions';
import { parseSnapId } from 'state/snaps/schema/snapEntityHelpers';

import { assertSnapId } from 'types/common';
import type { SnapId } from 'types/common';

export const requireSnap = (store: any) => ({ match }: any): Promise<unknown> => {
  const { params } = match;
  const editionId: number | undefined | null = params.editionId ? parseInt(params.editionId, 10) : null;

  // Story studio proxy uses string snap ids while RSP is using number snap ids.
  // We assume that if the param is matching number it is a number otherwise it is string.
  const snapId: SnapId | undefined | null = parseSnapId(params.snapId);
  const attachmentId: SnapId | undefined | null = parseSnapId(params.attachmentId);

  assertSnapId(snapId);

  // Make flow happy
  if (!snapId) {
    return Promise.reject(new Error('Missing snapId'));
  }

  if (!editionId) {
    return Promise.reject(new Error('Missing editionid'));
  }

  return store
    .dispatch(
      editorActions.initializeEditor({
        topsnapId: snapId,
        editionId,
        attachmentId,
      })
    )
    .then(() => {
      // Loading snap preview. Just fire and forget (not returning the promise)
      store.dispatch(loadSnapPreviewIfMissing(snapId));
    })
    .catch(log.error);
};
