import { isSidebarVisible } from '../selectors/navLayoutSelectors';

import { NavLayoutFlags } from 'config/constants';
import { GetState } from 'src/types/redux';
import { assertArg } from 'utils/assertionUtils';

export const SET_LAYOUT_FLAG = 'navLayout/SET_LAYOUT_FLAG';

export function setLayoutFlag(key: any, value: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(key).is.inValues(NavLayoutFlags);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(value).is.not.undefined();

  return {
    type: SET_LAYOUT_FLAG,
    payload: {
      [key]: value,
    },
  };
}

export const toggleSidebar = () => (dispatch: any, getState: GetState) => {
  const currentVisibility = isSidebarVisible(getState());
  const nextVisibility = !currentVisibility;

  return dispatch(setLayoutFlag(NavLayoutFlags.SIDEBAR_VISIBLE, nextVisibility));
};
export const openSidebar = () => (dispatch: any, getState: GetState) =>
  dispatch(setLayoutFlag(NavLayoutFlags.SIDEBAR_VISIBLE, true));
export const closeSidebar = () => (dispatch: any, getState: GetState) =>
  dispatch(setLayoutFlag(NavLayoutFlags.SIDEBAR_VISIBLE, false));
