/* eslint-disable spaced-comment */

/**
 * NOTE ABOUT IMPORTING FILES HERE:
 *
 * When including a file here, use a relative path (./folder/file.js), as this gets imported
 *  into the selenium tests that don't know how to resolve absolute paths
 */

// NOTE: As well as being used here, the endpoints.config.json file is also read
//       by add_csp_header.py so that we have a single place to enumerate all of
//       the Snapchat-owned endpoints that the CMS will need to talk to.
import endpointsJSON from '../../config/endpoints.config.json';

import { getBasePath } from 'utils/basePathUtils';
import type { Enum } from 'utils/enum';
import { enumObject } from 'utils/enum';
import { isLocalhost } from 'utils/locationUtils';

import { TileCropType, TileSpecifications } from './tileConfig';

declare var __USE_DEV_ENDPOINTS__: boolean;

const endpoints: any = endpointsJSON;

export const Environments = enumObject({
  PROD: 'prod',
  DEV: 'dev',
  TEST: 'test',
});

// Simple story builder has three modes of display:
// - STORY - user can see the snap carousel and nothing else
// - EDITOR - user is editing a single snap
// - SNAP_PUB - we are displaying the snap publisher in embedded mode
export const EDITOR_MODES = {
  STORY: 'STORY',
  EDITOR: 'EDITOR',
  SNAP_PUB: 'SNAP_PUB',
  HAPPENING_NOW: 'HAPPENING_NOW',
  SNAP_PUB_AND_ARTICLE: 'SNAP_PUB_AND_ARTICLE',
  WEB_IMPORT: 'WEB_IMPORT',
};

export type EditorModes = keyof typeof EDITOR_MODES;

export type EditorMode = {
  editorMode: EditorModes;
  editorModeMetadata: string;
  editorModeOptions: {
    advancedMode?: boolean;
    trimMode: boolean;
  };
};

export const KB = 1024;

const env = __USE_DEV_ENDPOINTS__ ? Environments.DEV : Environments.PROD;

/* eslint-disable no-alert, no-console */
if (env === Environments.PROD && process.env.NODE_ENV === 'development') {
  console.log('%c--------------------------------------', 'color: red;   font-size: medium');
  console.log('%cUsing prod endpoints from a dev build.', 'color: red;   font-size: medium');
  console.log('%cCheck README.md for --env dev-endpoints.  ', 'color: black; font-size: medium');
  console.log('%c--------------------------------------', 'color: red;   font-size: medium');
}
/* eslint-enable no-alert, no-console */

export const ENV = env;

export const PROD_DISCOVER_CMS_URL = 'https://publish.snapchat.com';
export const DISCOVER_FRONTEND_HOSTNAME = endpoints.discoverFrontend[env];
export const DISCOVER_FRONTEND_BASE_URL = buildBaseUrl(DISCOVER_FRONTEND_HOSTNAME, endpoints.discoverFrontend.version);

export const DISCOVER_SERVICE_HOSTNAME = getBaseDomain(endpoints.discover[env], !!endpoints.discover.version);
export const DISCOVER_SERVICE_PATH_PREFIX = 'v1/';

export const DISCOVER_SERVICE_BASE_URL = buildBaseUrl(
  DISCOVER_SERVICE_HOSTNAME,
  endpoints.discover.version,
  DISCOVER_SERVICE_PATH_PREFIX
);

export const SSAPI_PROXY_HOSTNAME = endpoints.ssapiProxy[env];
export const SSAPI_PROXY_PATH_PREFIX = 'v1/';

export const SSAPI_PROXY_BASE_URL = buildBaseUrl(
  SSAPI_PROXY_HOSTNAME,
  endpoints.ssapiProxy.version,
  SSAPI_PROXY_PATH_PREFIX
);

export const SSAPI_PROXY_GRAPHQL_BASE_URL = `${buildBaseUrl(
  SSAPI_PROXY_HOSTNAME,
  endpoints.ssapiProxy.version
)}graphql`;

export const ADS_MANAGER_HOSTNAME = endpoints['ads-manager'][env];
export const ADS_MANAGER_BASE_URL = buildBaseUrl(ADS_MANAGER_HOSTNAME);

export const PUBLIC_SERVICE_HOSTNAME = endpoints.public[env];
export const PUBLIC_SERVICE_BASE_URL = buildBaseUrl(PUBLIC_SERVICE_HOSTNAME, endpoints.public.version);

export const PUBLIC_LENS_HOSTNAME = endpoints.publicLens[env];
export const PUBLIC_LENS_BASE_URL = buildBaseUrl(PUBLIC_LENS_HOSTNAME, endpoints.publicLens.version);

export const SELF_SERVE_CREATIVE_SUITE_GOOGLE_HOSTNAME = endpoints.creativeSuiteGoogleSelfServe[env];
export const SELF_SERVE_CREATIVE_SUITE_GOOGLE_BASE_URL = buildBaseUrl(SELF_SERVE_CREATIVE_SUITE_GOOGLE_HOSTNAME);

export const SELF_SERVE_CREATIVE_SUITE_SNAP_HOSTNAME = endpoints.creativeSuiteSnapSelfServe[env];
export const SELF_SERVE_CREATIVE_SUITE_SNAP_BASE_URL = buildBaseUrl(SELF_SERVE_CREATIVE_SUITE_SNAP_HOSTNAME);

export const STORIES_EVERYWHERE_HOSTNAME = endpoints.storiesEverywhere[env];
export const STORIES_EVERYWHERE_BASE_URL = buildBaseUrl(STORIES_EVERYWHERE_HOSTNAME);

export const STORIES_EVERYWHERE_EMBED_HOSTNAME = endpoints.storiesEverywhereEmbed[env];
export const STORIES_EVERYWHERE_EMBED_BASE_URL = buildBaseUrl(STORIES_EVERYWHERE_EMBED_HOSTNAME);

export const SNAP_SSO_HOSTNAME = endpoints.snapSSO[env];
export const SNAP_SSO_BASE_URL = buildBaseUrl(SNAP_SSO_HOSTNAME);
export const SNAP_SSO_CLIENT_ID = env === Environments.PROD ? 'pub-tools' : 'pub-tools-dev';

const ADS_GATEWAY_HOSTNAME = endpoints['ads-gateway'][env];
const ADS_GATEWAY_GRAPHQL_PATH = endpoints['ads-gateway'].path[env];
export const ADS_GATEWAY_GRAPHQL_BASE_URL = buildBaseUrl(
  ADS_GATEWAY_HOSTNAME,
  undefined,
  ADS_GATEWAY_GRAPHQL_PATH,
  'proxy'
);

function buildBaseUrl(hostname: any, version?: any, path = '', proxyPath?: string) {
  // Proxy Graphql queries via webpack proxy. See the config in: webpack.dev.js
  // Otherwise we get an access control origin error
  if (proxyPath && isLocalhost()) {
    return getWebpackProxyPath(proxyPath, path);
  }

  const protocol = hostname.startsWith('localhost') ? 'http' : 'https';
  const versionedHostname = getVersionPrefixedHostname(hostname, version);
  return `${protocol}://${versionedHostname}/${path}`;
}

function getWebpackProxyPath(proxyPath: string, endpointPath: string) {
  const path = [proxyPath, endpointPath].filter(Boolean).join('/');
  return `${window.location.protocol}//${window.location.host}/${path}`;
}

function getBaseDomain(domain: any, isVersioned: any) {
  return isVersioned ? domain.versioned : domain.base;
}

function getVersionPrefixedHostname(hostname: any, version: any) {
  return version ? `${version}-dot-${hostname}` : hostname;
}

export const BASE_PATH = getBasePath();
export const CLIENT_ID = '332125076207-mg6vl0dm8oucg5tmere9dip21b3hl7jb.apps.googleusercontent.com';

export const ACCESS_TOKEN_STORAGE_KEY = 'google_access_token';
export const GSI_CLIENT_URL = 'https://accounts.google.com/gsi/client';
export const GSI_BUNDLE_ID = 'GSI_CLIENT';

export const EndpointName = enumObject({
  RICH_SNAP: 'richSnap',
  DISCOVER: 'discover',
  DISCOVER_FRONTEND: 'discoverFrontend',
});

// High level types applied to the various components of a snap that show in the
// left hand panel of the editor.
export enum RichSnapComponentType {
  HEADLINE = 'HEADLINE',
  SNAP = 'SNAP',
  SNAP_PLACEHOLDER = 'SNAP_PLACEHOLDER',
  SUBTITLES = 'SUBTITLES',
  TILE = 'TILE',
  TILE_PLACEHOLDER = 'TILE_PLACEHOLDER',
  HEADLINE_TILE_PLACEHOLDER = 'HEADLINE_TILE_PLACEHOLDER',
}

export type RichSnapComponentTypeEnum = RichSnapComponentType;

export enum RichSnapAttachmentType {
  ATTACHMENT = 'ATTACHMENT',
}

export const RichSnapActiveComponentType = enumObject({
  ...RichSnapComponentType,
  ...RichSnapAttachmentType,
});
export type RichSnapActiveComponentTypeEnum = Enum<typeof RichSnapActiveComponentType>;

// High level types applied to the various components of a publisher's property
export enum RichPublisherComponentType {
  TILE_LOGO = 'TILE_LOGO',
  TILE_LOGO_PLACEHOLDER = 'TILE_LOGO_PLACEHOLDER',
}

export const ComponentTypes = enumObject({
  ...RichSnapComponentType,
  ...RichPublisherComponentType,
});
export type ComponentTypeEnum = Enum<typeof ComponentTypes>;

export const SnapTag = enumObject({
  SCC: 'SCC',
  WIKI: 'WIKI',
  MANUAL: 'MANUAL',
});

export type SnapTagEnum = Enum<typeof SnapTag>;

export const StageType = enumObject({
  SNAP: 'SNAP',
  EDITION: 'EDITION',
  PUBLISHER: 'PUBLISHER',
  HN_FEED: 'HN_FEED',
});

export const EditionStageType = enumObject({
  AD_SLOTS: 'AD_SLOTS',
});

// @see https://github.sc-corp.net/Snapchat/discover-publishing/blob/main/src/main/
//      java/discover/publishing/request/DiscoverPublishingConstants.java
export const VideoMode = enumObject({
  LOOPING: 'looping',
  ONCE: 'once',
});

// @see https://github.sc-corp.net/Snapchat/discover-publishing/blob/main/src/main/
//      java/discover/publishing/request/DiscoverPublishingConstants.java
export const ShareOption = enumObject({
  NO_SHARE: 'NO_SHARE',
  SHARE_LIVE: 'SHARE_LIVE',
  SHARE_LIVE_ARCHIVED: 'SHARE_LIVE_ARCHIVED',
});

// @see https://github.sc-corp.net/Snapchat/content-platform/blob/main/rich-snap-platform/
//      shared-model/src/main/java/snapchat/richsnap/shared/PublishState.java
export const PublishState = enumObject({
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
  INHERITS_PARENT: 'INHERITS_PARENT',
});

export enum SharingMethod {
  DEFAULT = 'DEFAULT',
  DEEP_LINK = 'DEEP_LINK',
  DEEP_LINK_TOP_SNAP = 'DEEP_LINK_TOP_SNAP',
}

export enum UrlType {
  STANDARD = 'STANDARD',
  AFFILIATE = 'AFFILIATE',
}

export const CheetahStoryState = enumObject({
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
});

export type CheetahStoryStateEnum = Enum<typeof CheetahStoryState>;

export const CheetahStoriesAnalyticsSortableColumns = enumObject({
  STATUS: 'STATUS',
  AVAILABILITY: 'AVAILABILITY',
  TIME_VIEWED: 'TIME_VIEWED',
  UNIQUE_VIEWERS: 'UNIQUE_VIEWERS',
  SUBSCRIBERS: 'SUBSCRIBERS',
});

export const CheetahStoriesAnalyticsSortableColumnsV2 = enumObject({
  STATUS: 'status',
  AVAILABILITY: 'AVAILABILITY',
  TIME_VIEWED: 'TIME_VIEWED',
  UNIQUE_VIEWERS: 'UNIQUE_VIEWERS',
  SUBSCRIBERS: 'SUBSCRIBERS',
});

export type CheetahStoriesAnalyticsSortableColumnsEnum = Enum<typeof CheetahStoriesAnalyticsSortableColumns>;

export const SimplifiedCheetahEditionState = enumObject({
  DRAFT: 'DRAFT',
  SCHEDULED: 'SCHEDULED',
  AVAILABLE: 'AVAILABLE',
  UNAVAILABLE: 'UNAVAILABLE',
});

export type SimplifiedCheetahEditionStateEnum = Enum<typeof SimplifiedCheetahEditionState>;

// @see https://github.sc-corp.net/Snapchat/content-platform/blob/main/rich-snap-platform/
//      shared-model/src/main/java/snapchat/richsnap/shared/CropPosition.java
export const CropPosition = enumObject({
  TOP: 'TOP',
  MIDDLE: 'MIDDLE',
  BOTTOM: 'BOTTOM',
  NONE: 'NONE',
});

export type CropPositionEnum = Enum<typeof CropPosition>;

// @see https://github.sc-corp.net/Snapchat/content-platform/blob/main/rich-snap-platform/
//      shared-model/src/main/java/snapchat/richsnap/shared/BuildType.java
export const BuildType = enumObject({
  RICHSNAP: 'RICHSNAP',
  RICHSNAP_CACHE_TEST: 'RICHSNAP_CACHE_TEST',
  VIDEOASSET_THUMBNAIL: 'VIDEOASSET_THUMBNAIL',
  VIDEO_METADATA: 'VIDEO_METADATA',
  SUBTITLE: 'SUBTITLE',
  TILE: 'TILE',
  TILE_LOGO: 'TILE_LOGO',
  IMAGE_PREVIEW: 'IMAGE_PREVIEW',
  VIDEO_PREVIEW: 'VIDEO_PREVIEW',
});

// @see https://github.sc-corp.net/Snapchat/content-platform/blob/main/discover-service/
//      media-types/src/main/java/snapchat/discover/mediatypes/EditionScheduleType.java
export const EditionSchedule = enumObject({
  SCHEDULE_TO_GO_LIVE: 'SCHEDULE_TO_GO_LIVE',
  SCHEDULE_FOR_ARCHIVING: 'SCHEDULE_FOR_ARCHIVING',
});

export type EditionScheduleEnum = Enum<typeof EditionSchedule>;

export const CollectionChannelType = enumObject({
  DISCOVER: 'DISCOVER',
  SHARED_STORY: 'SHARED_STORY',
});

export const AdSetting = enumObject({
  SKIPPABLE: 'SKIPPABLE',
  NON_SKIPPABLE: 'NON_SKIPPABLE',
});

export const AdPlacementMode = enumObject({
  UNKNOWN_UNSET: 'UNKNOWN_UNSET',
  FIXED_AD_SLOTS: 'FIXED_AD_SLOTS',
  OPTIONAL_AD_SLOTS: 'OPTIONAL_AD_SLOTS',
});

export const ModerationLevel = enumObject({
  STANDARD: 'STANDARD',
  NEWS: 'NEWS',
});

export const LogoDisplay = enumObject({
  BOTTOM: 'BOTTOM',
  OFF: 'OFF',
});

export type LogoDisplayEnum = Enum<typeof LogoDisplay>;

export const EditorialStatus = enumObject({
  TO_BE_REVIEWED: 'TO_BE_REVIEWED',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
});

export type EditorialStatusEnum = Enum<typeof EditorialStatus>;

export const DEFAULT_LOGO_COLOR = '#9A9FA7';

export const SECONDS = 1000;
export const MINUTES = 60 * SECONDS;
export const HOURS = 60 * MINUTES;

export const GLOBAL_PUBLISHER_NAME = 'GLOBAL';
export const DEFAULT_GEO = 'GLOBAL';
export const DEFAULT_GEO_TYPE = 'GLOBAL';
export const CSV_DEFAULT_GEO = 'All Regions';

export const DEFAULT_TIME_ZONE = 'America/New_York';
export const PT_TIME_ZONE = 'America/Los_Angeles';

export const EMPTY_ARRAY = Object.freeze([]) as [] | never[];
export const EMPTY_OBJECT = Object.freeze({});

export const SNAP_PUBLISHER_PUBLISH_MESSAGE_NAME = 'sp-publish';
export const STORIES_EVERYWHERE_PUBLISH_MESSAGE_NAME = 'se-publish';

export enum FileType {
  IMAGE = 'image',
  VIDEO = 'video',
  TEXT = 'text',
  AUDIO = 'audio',
  UNKNOWN = 'unknown',
}

export const UriType = enumObject({
  DATA_URI: 'data',
  BLOB_URI: 'blob',
  HTTP_URL: 'http',
});

export const Sequence = enumObject({
  START: 'start',
  DONE: 'done',
  PROGRESS: 'progress',
});

export type SequenceEnum = Enum<typeof Sequence>;

export enum UploadPurpose {
  ARTICLE_IMAGE = 'article_image',
  LONGFORM_VIDEO = 'longform_video',
  OVERLAY_MEDIA = 'overlay_media',
  SUBTITLE = 'subtitle',
  TILE_IMAGE = 'tile_image',
  TILE_CROP_IMAGE = 'tile_crop_image',
  HAPPENING_NOW_TILE_IMAGE = 'happening_now_tile_image',
  HAPPENING_NOW_TILE_CROP_IMAGE = 'happening_now_tile_crop_image',
  TOP_SNAP = 'top_snap',
  TOPSNAP_SUBTITLES = 'top_snap_subtitles',
  EDITION_SUBTITLES = 'edition_subtitles',
  POLL_IMAGE = 'poll_image',
  TILE_LOGO = 'TILE_LOGO',
  SQUARE_HERO_IMAGE = 'square_hero_image',
  SQUARE_ICON = 'square_icon',
  WIDE_ICON = 'wide_icon',
  SINGLE_ASSET_STORY = 'single_asset_story',
  CURATED_SNAP_MEDIA = 'curated_snap_media',
  CURATED_SNAP_OVERLAY_MEDIA = 'curated_snap_overlay_media',
  SPOTLIGHT = 'spotlight',
  PUBLIC_STORY = 'public_story',
}

export enum MlsMediaProfile {
  UNKNOWN_MEDIA_PROFILE = 'UNKNOWN_MEDIA_PROFILE',
  RAW_MEDIA = 'RAW_MEDIA',
  TOP_SNAP_MEDIA = 'TOP_SNAP_MEDIA',
  TOP_SNAP_OVERLAY_MEDIA = 'TOP_SNAP_OVERLAY_MEDIA',
  DISCOVER_FEED_TILE = 'DISCOVER_FEED_TILE',
  HAPPENING_NOW_SQUARE_TILE = 'HAPPENING_NOW_SQUARE_TILE',
  HAPPENING_NOW_SNAP = 'HAPPENING_NOW_SNAP',
  LONGFORM_VIDEO = 'LONGFORM_VIDEO',
  REGULAR_IMAGE = 'REGULAR_IMAGE',
  ANIMATED_IMAGE = 'ANIMATED_IMAGE',
  POLL_OPTION_IMAGE = 'POLL_OPTION_IMAGE',
  PROFILE_HERO_IMAGE = 'PROFILE_HERO_IMAGE',
  SQUARE_FORMAT_PUBLISHER_LOGO = 'SQUARE_FORMAT_PUBLISHER_LOGO',
  WIDE_FORMAT_PUBLISHER_LOGO = 'WIDE_FORMAT_PUBLISHER_LOGO',
  SHOWS_SNAP_MEDIA = 'SHOWS_SNAP_MEDIA',
  CIRCULAR_SNAP_MEDIA = 'CIRCULAR_SNAP_MEDIA',
  VTT_SUBTITLES = 'VTT_SUBTITLES',
  SINGLE_VIDEO_STORY_MEDIA = 'SINGLE_VIDEO_STORY_MEDIA',
  CURATED_SNAP_MEDIA = 'CURATED_SNAP_MEDIA',
  CURATED_SNAP_OVERLAY_MEDIA = 'CURATED_SNAP_OVERLAY_MEDIA',
}

export const QueryEntryType = enumObject({
  MEDIA: 'MEDIA',
  IMPORTED_SNAP: 'IMPORTED_SNAP',
});
export type QueryEntryTypeEnum = Enum<typeof QueryEntryType>;

export const UploadFormat = enumObject({
  ONE_FILE: 'one_file',
  MULTIPLE_FILES: 'multiple_files',
  URL: 'url',
});

export const AgeGate = enumObject({
  UNRESTRICTED: 'UNRESTRICTED',
  EXPLICIT: 'EXPLICIT',
});

export const KeyCode = enumObject({
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PAUSE: 19,
  CAPS_LOCK: 20,
  ESCAPE: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  INSERT: 45,
  DELETE: 46,
  KEY_0: 48,
  KEY_1: 49,
  KEY_2: 50,
  KEY_3: 51,
  KEY_4: 52,
  KEY_5: 53,
  KEY_6: 54,
  KEY_7: 55,
  KEY_8: 56,
  KEY_9: 57,
  KEY_A: 65,
  KEY_B: 66,
  KEY_C: 67,
  KEY_D: 68,
  KEY_E: 69,
  KEY_F: 70,
  KEY_G: 71,
  KEY_H: 72,
  KEY_I: 73,
  KEY_J: 74,
  KEY_K: 75,
  KEY_L: 76,
  KEY_M: 77,
  KEY_N: 78,
  KEY_O: 79,
  KEY_P: 80,
  KEY_Q: 81,
  KEY_R: 82,
  KEY_S: 83,
  KEY_T: 84,
  KEY_U: 85,
  KEY_V: 86,
  KEY_W: 87,
  KEY_X: 88,
  KEY_Y: 89,
  KEY_Z: 90,
  LEFT_META: 91,
  RIGHT_META: 92,
  SELECT: 93,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105,
  MULTIPLY: 106,
  ADD: 107,
  SUBTRACT: 109,
  DECIMAL: 110,
  DIVIDE: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145,
  SEMICOLON: 186,
  EQUALS: 187,
  COMMA: 188,
  DASH: 189,
  PERIOD: 190,
  FORWARD_SLASH: 191,
  GRAVE_ACCENT: 192,
  OPEN_BRACKET: 219,
  BACK_SLASH: 220,
  CLOSE_BRACKET: 221,
  SINGLE_QUOTE: 222,
});

export const LiveState = enumObject({
  LIVE: 'LIVE',
  PRE_LIVE: 'PRE_LIVE',
  LIVE_EMPLOYEE_ONLY: 'LIVE_EMPLOYEE_ONLY',
});

export const ChannelState = enumObject({
  UPCOMING: 'UPCOMING',
  EXPIRED: 'EXPIRED',
  LIVE: 'LIVE',
});

export const HIDDEN_FIELD = 'BELOW_MINIMUM';

export const DropzoneType = enumObject({
  CHEETAH_TILE: 'cheetah_tile',
  CUSTOM_REPLACE: 'custom',
  MODAL_VIDEO: 'modal_video',
  NEW_OVERLAY: 'new_overlay',
  NEW_TILE_IMAGE: 'new_tile_image',
  NEW_TILE_LOGO: 'new_tile_logo',
  NEW_TILE_LOGO_ZONE: 'NEW_TILE_LOGO_ZONE',
  GENERIC_IMAGE_UPLOAD: 'GENERIC_IMAGE_UPLOAD',
  REPLACE: 'replace',
  SUBTITLE: 'subtitle',
  TOPSNAP: 'topsnap',
  TOPSNAP_IMAGE: 'topsnap_image',
  TOPSNAP_VIDEO: 'topsnap_video',
  TOPSNAP_CLICK_UPLOAD: 'topsnap_click_upload',
  TOPSNAP_SUBTITLES: 'topsnap_subtitles',
  EDITION_SUBTITLES: 'edition_subtitles',
  SINGLE_ASSET_SUBTITLES: 'single_asset_subtitles',
  VIDEO: 'video',
  VIDEO_REPLACE: 'video_replace',
  VIDEO_CLICK_BROWSE: 'video_click_browse',
  REPLACE_TILE_IMAGE: 'replace_tile_image',
  REPLACE_TILE_LOGO: 'replace_tile_logo',
  PROFILE_IMAGE: 'profile_image',
  POLL_IMAGE: 'poll_image',
  MODAL_IMAGE: 'modal_image',
  POLL_IMAGE_REPLACE: 'poll_image_replace',
  SOUNDTRACK: 'soundtrack',
  SOUNDTRACK_REPLACE: 'soundtrack_replace',
  SINGLE_ASSET_STORY: 'single_asset_story',
});

export type DropzoneTypeEnum = Enum<typeof DropzoneType>;

export const ReplaceDropzoneType: Set<string> = new Set([
  DropzoneType.REPLACE,
  DropzoneType.REPLACE_TILE_IMAGE,
  DropzoneType.POLL_IMAGE_REPLACE,
  DropzoneType.REPLACE_TILE_LOGO,
  DropzoneType.SOUNDTRACK_REPLACE,
  DropzoneType.CUSTOM_REPLACE,
]);

export const TransactionType = enumObject({
  SET_SNAP_PROPERTY: 'set_snap_property',
  SET_EDITION_PROPERTY: 'set_edition_property',
  SET_EPISODE_PROPERTY: 'set_episode_property',
  SET_TOPIC_STICKER_PROPERTY: 'set_topic_sticker_property',
});

// TODO: separate the actions caused errors from the real error types
export const ErrorType = enumObject({
  ARTICLE_SAVE: 'article_save',
  UPLOAD: 'upload',
  MEDIA_VALIDATION_ERROR: 'media_validation_error',
  HORIZONTAL_SINGLE_ASSET: 'horizontal_single_asset',
  SAVE_SNAP_MEDIA: 'save_snap_media',
  SAVE_TILE_MEDIA: 'save_tile_media',
  SET_SNAP_PROPERTY: 'set_snap_property',
  SET_EDITION_PROPERTY: 'set_edition_property',
  DELETE: 'delete',
  ADD_INTERACTION: 'add_interaction',
  LOAD: 'load',
  INCOMPLETE_VIDEO: 'incomplete_video',
  NO_POSTER_ERROR: 'no_poster_error',
  SAVE_LONGFORM_VIDEO: 'save_longform_video',
  SAVE_TOPSNAP_VIDEO: 'save_topsnap_video',
  CONCURRENT_EDIT: 'concurrent_edit',
  USER_NEEDS_LOGIN: 'user_needs_login',
  URI_TYPE_ERROR: 'uri_type_error',
});

export const PreviewStates = enumObject({
  EMPTY: 'EMPTY',
  PROCESSING: 'PROCESSING',
  COMPLETE: 'COMPLETE',
  UPLOADING: 'UPLOADING',
  TEMP_PREVIEW: 'TEMP_PREVIEW',
  SAVING: 'SAVING',
});

export enum ContentStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADING = 'LOADING',
  LOADED_CLEAN = 'LOADED_CLEAN',
  LOADED_DIRTY = 'LOADED_DIRTY',
  LOAD_ERROR = 'LOAD_ERROR',
  SAVING = 'SAVING',
  SAVE_ERROR = 'SAVE_ERROR',
}

// @see https://github.sc-corp.net/Snapchat/snapchat/blob/main/libs/admanager-api-wire-model/
// src/main/java/snapchat/admanager/api/wiremodel/creativeV2/CallToAction.java
// It is the expected behavior that these will be displayed in English within the UI.
export const CallToActionOptions = enumObject({
  BLANK: '',
  APP_STORE: 'APP STORE',
  APPLY_NOW: 'APPLY NOW',
  BOOK_NOW: 'BOOK NOW',
  BUY_TICKETS: 'BUY TICKETS',
  DOWNLOAD: 'DOWNLOAD',
  EXPLORE: 'EXPLORE',
  FIND_OUT: 'FIND OUT',
  GET_NOW: 'GET NOW',
  INSTALL_NOW: 'INSTALL NOW',
  LEARN_MORE: 'LEARN MORE',
  LISTEN: 'LISTEN',
  MORE: 'MORE',
  OPEN_APP: 'OPEN APP',
  ORDER_NOW: 'ORDER NOW',
  PLAY: 'PLAY',
  PLAY_GAME: 'PLAY GAME',
  PLAY_STORE: 'PLAY STORE',
  POLL: 'TAKE POLL',
  READ: 'READ',
  RESPOND: 'RESPOND',
  SEND_TO_OUR_STORY: 'SEND TO OUR STORY',
  SHOP_NOW: 'SHOP NOW',
  SHOW: 'SHOW',
  SHOWTIMES: 'SHOWTIMES',
  SIGN_IN: 'SIGN IN',
  SIGN_UP: 'SIGN UP',
  SWIPE_UP: 'SWIPE UP',
  USE_APP: 'USE APP',
  TAKE_QUIZ: 'TAKE QUIZ',
  TRY: 'TRY',
  TRY_LENS: 'TRY LENS',
  VIEW: 'VIEW',
  VIEW_MORE: 'VIEW MORE',
  VOTE: 'VOTE NOW',
  WATCH: 'WATCH',
  WATCH_EPISODE: 'WATCH EPISODE',
  WATCH_MORE: 'WATCH MORE',
  WATCH_TRAILER: 'WATCH TRAILER',
  WATCH_VIDEO: 'WATCH VIDEO',
  NOTIFY_ME: 'NOTIFY ME!',
  UNLOCK_STICKERS: 'UNLOCK STICKERS',
});

export type CallToActionOptionsEnum = Enum<typeof CallToActionOptions>;

export enum CrossOrigin {
  USE_CREDENTIALS = 'use-credentials',
  ANONYMOUS = 'anonymous',
  NONE = '',
}

export const LiveAnalyticsStatus = enumObject({
  STILL_PROCESSING: 'STILL_PROCESSING',
  NO_LIVE_EDITION: 'NO_LIVE_EDITION',
  NO_ANALYTICS_DATA: 'NO_ANALYTICS_DATA',
  READY: 'READY',
});

export type LiveAnalyticsStatusEnum = Enum<typeof LiveAnalyticsStatus>;

export const StatusMessageSeverity = enumObject({
  INFO: 'INFO',
  WARNING: 'WARNING',
  ERROR: 'ERROR',
});

export const StatusMessageButton = enumObject({
  DISMISS: 'DISMISS',
  RETRY: 'RETRY',
  LOGIN: 'LOGIN',
});

export const NotificationScope = enumObject({
  GLOBAL: 'GLOBAL',
  EDITION: 'EDITION',
  SNAP: 'SNAP',
  SHOW: 'SHOW',
  SEASON: 'SEASON',
  EPISODE: 'EPISODE',
});

export enum AudioClassification {
  NORMAL_DIALOGUE = 'NORMAL_DIALOGUE',
  DIALOGUE_SUBTITLE_BURN = 'DIALOGUE_SUBTITLE_BURN',
  DIALOGUE_IMPACT_BURN = 'DIALOGUE_IMPACT_BURN',
  DIALOGUE_HEAVY = 'DIALOGUE_HEAVY',
  NO_DIALOGUE = 'NO_DIALOGUE',
}

export const SpeedBumpInputText = enumObject({
  TAKE_DOWN: 'TAKE DOWN',
  SET_PRIVATE: 'SET PRIVATE',
});

export const GatedAlertType = enumObject({
  STRING_MATCH: 'stringMatch',
});

export const DeeplinkType = enumObject({
  SNAP: 'SNAP',
  EDITION: 'EDITION',
  PUBLISHER: 'PUBLISHER',
});

export type DeeplinkTypeEnum = Enum<typeof DeeplinkType>;

// @see https://github.sc-corp.net/Snapchat/content-platform/blob/main/discover-service/media-types/
//      src/main/java/snapchat/discover/mediatypes/EditionStatus.java
export const EditionStatus = enumObject({
  ALL_TILES_FLAGGED_BY_MODERATION: 'ALL_TILES_FLAGGED_BY_MODERATION',
  NOT_READY: 'NOT_READY',
  PUBLISHABLE: 'PUBLISHABLE',
  MISSING_SUBSCRIBE_SNAPS: 'MISSING_SUBSCRIBE_SNAPS',
  ILLEGAL_EPISODE_STATE: 'ILLEGAL_EPISODE_STATE',
  ILLEGAL_PUBLISH_ORDER: 'ILLEGAL_PUBLISH_ORDER',
  INCOMPLETE_PROFILE: 'INCOMPLETE_PROFILE',
  INCOMPLETE_SNAPS: 'INCOMPLETE_SNAPS',
  INCOMPLETE_TILES: 'INCOMPLETE_TILES',
  OVERSIZED_SNAPS: 'OVERSIZED_SNAPS',
  BUILD_FAILED: 'BUILD_FAILED',
  NO_SNAPS: 'NO_SNAPS',
  MISSING_TILES: 'MISSING_TILES',
  MISSING_TAGS: 'MISSING_TAGS',
  CONTENT_DELETED: 'CONTENT_DELETED',
  STITCHED_VIDEO_STALE: 'STITCHED_VIDEO_STALE',
  GENERATING_SUBTITLES: 'GENERATING_SUBTITLES',
  SHOT_DETECTION_FAILED: 'SHOT_DETECTION_FAILED',
  SHOT_DETECTION_IN_PROGRESS: 'SHOT_DETECTION_IN_PROGRESS',
});

export const StoryStatus = enumObject({
  ...EditionStatus,
  SAVING: 'SAVING',
  HIDDEN_LOCK: 'HIDDEN_LOCK',
  STITCHED_VIDEO_MISSING: 'STITCHED_VIDEO_MISSING',
  STITCHED_VIDEO_BUILDING: 'STITCHED_VIDEO_BUILDING',
  STITCHED_VIDEO_ERROR: 'STITCHED_VIDEO_ERROR',
});

export type StoryStatusEnum = Enum<typeof StoryStatus>;

export const Event = enumObject({
  BUILD_FAILURE: 'BUILD_FAILURE',
});

export const AnimationEventType = enumObject({
  START: 'START',
  END: 'END',
});

export const AspectRatio = enumObject({
  ONExONE: { width: 1, height: 1, tolerance: 0 },
  NINExSIXTEEN: { width: 9, height: 16, tolerance: 4 },
});

export const CROP_PROPERTIES = [
  'croppedImageAssetId',
  'baseVerticalCropPosition',
  'baseHorizontalCropPosition',
  'imageCropPositions',
  'generatedBlobURI',
];

export const DragTypes = enumObject({
  STORY_SEGMENT: 'STORY_SEGMENT',
  STORY_SNAP: 'STORY_SNAP',
});

export const NavLayoutFlags = enumObject({
  SIDEBAR_VISIBLE: 'sidebarVisible',
});

export const LogType = enumObject({
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR',
});

export const ContentLifeSpan = enumObject({
  STANDARD: 'STANDARD',
  EVERGREEN: 'EVERGREEN',
  TIMELY: 'TIMELY',
});

export type ContentLifeSpanEnum = Enum<typeof ContentLifeSpan>;

export const getContentLifeSpanChoices = () => {
  return [
    { value: ContentLifeSpan.EVERGREEN, label: 'Evergreen' },
    { value: ContentLifeSpan.TIMELY, label: 'Timely' },
  ];
};

export const IdPropertyByTileContainer = enumObject({
  SNAP: 'snapId',
  EDITION: 'editionId',
  SEGMENT: 'segmentId',
});

export type IdPropertyByTileContainerEnum = Enum<typeof IdPropertyByTileContainer>;

export const LocalStorage = enumObject({
  ANALYTICS_COUNTRY_CODES: 'analtyicsCountryCodes',
  ATTACHMENT: 'attachment',
  AUTH_TYPE: 'AUTH_TYPE',
  CURRENT_PUBLISHER: 'current_publisher',
  CURRENT_HOST_USERNAME: 'current_host_username',
  DISMISS_ARTICLE_INGESTION_CONFIRM_MODAL: 'DISMISS_ARTICLE_INGESTION_CONFIRM_MODAL',
  DISMISS_SEGMENTS_INFO_BOX: 'DISMISS_SEGMENTS_INFO_BOX',
  DISMISS_NEW_SEGMENT_SIMPLE_CONFIRM_MODAL: 'DISMISS_NEW_SEGMENT_SIMPLE_CONFIRM_MODAL',
  DISMISS_HEADLINE_SAVE_CONFIRM_MODAL: 'DISMISS_HEADLINE_SAVE_CONFIRM_MODAL',
  DISMISS_LOGO_SAVE_CONFIRM_MODAL: 'DISMISS_LOGO_SAVE_CONFIRM_MODAL',
  DISMISS_GENERATE_SUBTITLES_MODAL: 'DISMISS_GENERATE_SUBTITLES_MODAL',
  LOCALE_ID: 'localeId',
  SHOW_TILES: 'show-tiles',
  FORCED_ROLES: 'FORCED_ROLES',
  LAST_VIEWED_REVISE_FILTER: 'LAST_VIEWED_REVISE_FILTER',
  DONT_SHOW_HELP_MODALS_PUBLISHER: 'DONT_SHOW_HELP_MODALS_PUBLISHER',
  DONT_SHOW_HELP_MODALS_STORY: 'DONT_SHOW_HELP_MODALS_STORY',
  SPOTLIGHT_TERMS: 'SPOTLIGHT_TERMS',
});

export enum ImageType {
  GIF = 'image/gif',
  PNG = 'image/png',
  JPEG = 'image/jpeg',
}

export const ImageFileExtension = enumObject({
  GIF: 'gif',
  PNG: 'png',
  JPEG: 'jpg',
});

export const CheckboxState = enumObject({
  CHECKED: 'CHECKED',
  UNCHECKED: 'UNCHECKED',
  INDETERMINATE: 'INDETERMINATE',
});

export type CheckboxStateEnum = Enum<typeof CheckboxState>;

export const SelectionType = enumObject({
  RADIO: 'RADIO',
  CHECKBOX: 'CHECKBOX',
  TRISTATE_CHECKBOX: 'TRISTATE_CHECKBOX',
});

export const TileButtonRenderType = enumObject({
  NONE: 'NONE',

  // A tile preview that opens the editor when clicked
  BLANK: 'BLANK',

  // `Not Allowed` icon in a frame is rendered
  NOT_ALLOWED: 'NOT_ALLOWED',

  // A tile preview is rendered (logo of the publisher and headline, with no image)
  PLACEHOLDER: 'PLACEHOLDER',

  // Single tile (exclusively)
  SINGLE_TILE: 'SINGLE_TILE',

  // All tiles (including variants) rendered
  TILE_WITH_VARIANTS: 'TILE_WITH_VARIANTS',
});

export type TileButtonRenderTypeEnum = Enum<typeof TileButtonRenderType>;

export const MAX_TILE_HEIGHT = TileSpecifications[TileCropType.VERTICAL].height;
export const MINIMUM_TILE_COUNT = 1;
export const MAXIMUM_TILE_COUNT = 4;
export const DEFAULT_TRIM_DURATION = 3000;

export const BrandingContentType = enumObject({
  MINI_PROFILE: 'MINI_PROFILE',
  SEARCH: 'SEARCH',
  TILE_LOGO: 'TILE_LOGO',
  SUBSCRIBE_BAR: 'SUBSCRIBE_BAR',
  SNAPCODE: 'SNAPCODE',
});

export const EMPTY_STORY_SNAPS = 0;
export const INITIAL_STORY_SNAPS = 8;

export const DEFAULT_ANALYTICS_GEO = 'Global';

export type AgeGateEnum = Enum<typeof AgeGate>;
export type ShareOptionEnum = Enum<typeof ShareOption>;
export type StageTypeEnum = Enum<typeof StageType>;
export type AdPlacementModeEnum = Enum<typeof AdPlacementMode>;

/**
 * COUNTRY: get only country list data
 * GLOBAL: get the summarized global data (default app behavior for "Global" keyword)
 * GLOBAL_ALL(only for the download CSV case): get GLOBAL + full country list breakdown data option
 */
export const AnalyticsCountryType = enumObject({
  COUNTRY: 'COUNTRY',
  GLOBAL: 'GLOBAL',
  GLOBAL_ALL: 'GLOBAL_ALL',
});

export const AnalyticsCsvDetailLevel = enumObject({
  STORIES_AND_SNAPS: 'STORIES_AND_SNAPS',
  STORIES_ONLY: 'STORIES_ONLY',
});

export const ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT = Object.freeze({
  tileStats: EMPTY_ARRAY,
  globalAvgTotalTimeViewed: -1,
  globalUniqueTopsnapsPerUser: -1,
});

export const ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT = Object.freeze({
  storyStats: EMPTY_ARRAY,
  globalAvgUniqueDau: -1,
  globalAvgTimeViewed: -1,
  globalAvgEditionSubscribeCount: -1,
});

export const AnalyticsInsightsTableType = enumObject({
  TOP_PERFORMING_TILES: 'TOP_PERFORMING_TILES',
  TOP_PERFORMING_STORIES: 'TOP_PERFORMING_STORIES',
});

export type AnalyticsInsightsTableTypeEnum = Enum<typeof AnalyticsInsightsTableType>;

export const TrafficSourceType = enumObject({
  DISCOVER_FEED: 'discover_feed',
  DISCOVER_FEED_SUBSCRIPTION: 'discover_feed',
  PREMIUM_FEED: 'premium_feed',
  SEARCH: 'search',
  PROFILE: 'profile',
  CHAT: 'chat',
  MAP: 'map',
  WEB: 'web',
});

export type TrafficSourceTypeEnum = Enum<typeof TrafficSourceType>;

export const trafficSourceToDisplayableNameMap = Object.freeze({
  [TrafficSourceType.DISCOVER_FEED]: 'Discover Feed',
  [TrafficSourceType.DISCOVER_FEED_SUBSCRIPTION]: 'Subscription Rail',
  [TrafficSourceType.PREMIUM_FEED]: 'Premium Feed',
  [TrafficSourceType.SEARCH]: 'Search',
  [TrafficSourceType.PROFILE]: 'Profile',
  [TrafficSourceType.CHAT]: 'Chat',
  [TrafficSourceType.MAP]: 'Map',
  [TrafficSourceType.WEB]: 'Web',
});

export const ProgrammingGenders = enumObject({
  GENDER_UNKNOWN: 'GENDER_UNKNOWN',
  MALE: 'MALE',
  FEMALE: 'FEMALE',
});

export const ProgrammingGenderMap = enumObject({
  [ProgrammingGenders.GENDER_UNKNOWN]: 'unknown',
  [ProgrammingGenders.MALE]: 'male',
  [ProgrammingGenders.FEMALE]: 'female',
});

export const ProgrammingAgeGroups = enumObject({
  AGE_UNKNOWN: 'AGE_UNKNOWN',
  AGE_13_TO_17: 'AGE_13_TO_17',
  AGE_18_TO_20: 'AGE_18_TO_20',
  AGE_21_TO_24: 'AGE_21_TO_24',
  AGE_25_TO_34: 'AGE_25_TO_34',
  AGE_35_PLUS: 'AGE_35_PLUS',
});

export const ProgrammingAgeGroupMap = enumObject({
  [ProgrammingAgeGroups.AGE_UNKNOWN]: 'unknown',
  [ProgrammingAgeGroups.AGE_13_TO_17]: '13 - 17',
  [ProgrammingAgeGroups.AGE_18_TO_20]: '18 - 20',
  [ProgrammingAgeGroups.AGE_21_TO_24]: '21 - 24',
  [ProgrammingAgeGroups.AGE_25_TO_34]: '25 - 34',
  [ProgrammingAgeGroups.AGE_35_PLUS]: '35 +',
});

export const ProgrammingTabs = enumObject({
  DEMOGRAPHICS_TAB: 'DEMOGRAPHICS_TAB',
  INTERESTS_TAB: 'INTERESTS_TAB',
  GEOGRAHPY_TAB: 'GEOGRAHPY_TAB',
});

export const ProgrammingTargets = enumObject({
  AGE_GROUP: 'AGE_GROUP',
  GENDER: 'GENDER',
  INTEREST: 'INTEREST',
  COUNTRY: 'COUNTRY',
});

export const Inclusivity = enumObject({
  INCLUDE: 'INCLUDE',
  EXCLUDE: 'EXCLUDE',
});

export const VIDEO_UPLOAD_ANALYTICS = enumObject({
  MEDIA_UPLOAD: 'media-upload',
  VALIDATION_MEDIA_ERROR_HANDLER: 'validation-media-error-handler',
  GET_UPLOAD_ENDPOINT_RESOLVE_URL: 'get-upload-endpoint-resolve-url',
  PERFORM_UPLOAD_TO_ENDPOINT: 'perform-upload-to-endpoint',
  UPLOAD_COMPLETE: 'upload-complete',
  UPLOAD_TO_ENDPOINT_RESULT: 'upload-to-endpoint-result',
  SAVE_UPLOADED_VIDEO_ID: 'save-uploaded-video-id',
  GET_SNAP_BY_ID: 'get-snap-by-id',
  RESET_SEARCH_RESULTS: 'reset-search-results',
  SEND_DONE: 'send-done',
  FETCH_VIDEO_INFORMATION_UNTIL_COMPLETE: 'fetch-video-information-until-complete',
  UPLOAD_MEDIA_ERROR_HANDLER: 'upload-media-error-handler',
  UPLOAD_CATCH: 'upload-catch',
  VALIDATE_FILE: 'validate-file',
  VALIDATE_FILE_INVALID: 'validate-file-invalid',
  VALIDATE_FILE_TYPE: 'validate-file-type',
  VALIDATE_FILE_TYPE_INVALID: 'validate-file-type-invalid',
  VALIDATE_FILE_SIZE: 'validate-file-size',
  VALIDATE_FILE_SIZE_INVALID_PROPERTY: 'validate-file-size-invalid-property',
  VALIDATE_FILE_SIZE_INVALID_SIZE: 'validate-file-size-invalid-size',
  VALIDATE_MEDIA_INVALID_URI: 'validate-media-invalid-uri',
  VALIDATE_MEDIA_INVALID_URI_TYPE: 'validate-media-invalid-uri-type',
  VALIDATE_MEDIA: 'validate-media',
  VALIDATE_MEDIA_INVALID_OPTIONS: 'validate-media-invalid-options',
  VALIDATE_MEDIA_UNKNOWN_FILE_TYPE: 'validate-media-unknown-file-type',
});

// Used for editor state selectors
export const ComponentScope = enumObject({
  SNAP: 'SNAP',
  TILE: 'TILE',
  SHOW: 'SHOW',
  SINGLE_ASSET_STORY_EDITOR: 'SINGLE_ASSET_STORY_EDITOR',
  SUBTITLES: 'SUBTITLES',
});

export const PollScreen = enumObject({
  UNANSWERED: 'UNANSWERED',
  RESPONDED: 'RESPONDED',
  ENDED: 'ENDED',
  EXPLANATION: 'EXPLANATION',
});

export const RETAKE_BUTTON = '\u21BA';

/**
 * https://snapchat.quip.com/4k01ALINvaEe
 *
 * The country codes shall be ordered in priority according to the
 * markets where we have the most content broadcasted: US, UK, CA, AU, FR, DE.
 * If the country codes to be displayed are not an aforementioned country, then they shall appear alphabetical.
 */
export const COUNTRY_CODE_ORDER = ['US', 'GB', 'CA', 'AU', 'FR', 'DE'];

export const DailyMetricType = enumObject({
  NORMAL: 'NORMAL',
  LOYALTY: 'LOYALTY',
  ATTACHMENT_CONVERSION: 'ATTACHMENT_CONVERSION',
  RANGE_VIEWERS: 'RANGE_VIEWERS',
});

export const SortDirection = enumObject({
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
});

export type SortDirectionEnum = Enum<typeof SortDirection>;

export const FeatureWhitelistMode = enumObject({
  INCLUSIVE: 'INCLUSIVE',
  EXCLUSIVE: 'EXCLUSIVE',
});

export type FeatureWhitelistModeEnum = Enum<typeof FeatureWhitelistMode>;

export const FeatureCategory = enumObject({
  IN_DEVELOPMENT: 'IN_DEVELOPMENT',
  PRODUCTION_READY: 'PRODUCTION_READY',
});

export type FeatureCategoryEnum = Enum<typeof FeatureCategory>;

export const AuthType = enumObject({
  SNAPCHAT: 'SNAPCHAT',
  GOOGLE: 'GOOGLE',
});

export type AuthTypeEnum = Enum<typeof AuthType>;

export enum BearerPrefix {
  BEARER = 'Bearer',
  SNAP_BEARER = 'SnapBearer',
}

export const StoryGraph = enumObject({
  ATTACHMENT_CONVERSION: 'ATTACHMENT_CONVERSION',
  ATTACHMENT_TIME_SPENT: 'ATTACHMENT_TIME_SPENT',
  SCREENSHOTS_SHARES: 'SCREENSHOTS_SHARES',
  SNAP_DROP_OFF: 'SNAP_DROP_OFF',
  TOPSNAP_TIME_SPENT: 'TOPSNAP_TIME_SPENT',
  UNIQUE_VIEWERS: 'UNIQUE_VIEWERS',
});
export type StoryGraphEnum = Enum<typeof StoryGraph>;

export const DailyGraph = enumObject({
  UNIQUE_DAU: 'UNIQUE_DAU',
  UNIQUE_MAU: 'UNIQUE_MAU',
  SUBSCRIBERS: 'SUBSCRIBERS',
  USER_LOYALTY: 'USER_LOYALTY',
  GENDER_DEMOGRAPHICS: 'GENDER_DEMOGRAPHICS',
  AGE_DEMOGRAPHICS: 'AGE_DEMOGRAPHICS',
  UNIQUE_TOPSNAPS: 'UNIQUE_TOPSNAPS',
  TIME_SPENT: 'TIME_SPENT',
  ATTACHMENT_CONVERSION: 'ATTACHMENT_CONVERSION',
  VIEW_COUNTS: 'VIEW_COUNTS',
  SCREENSHOT_SHARES: 'SCREENSHOT_SHARES',
});

export type DailyGraphEnum = Enum<typeof DailyGraph>;

export const AnalyticsAttachmentType = enumObject({
  ARTICLE: 'text',
  COMMERCE: 'commerce',
  CAMERA_ATTACHMENT: 'camera_attachment',
  FACTUAL_QUESTION: 'factual_question',
  NOTIFICATIONS: 'notifications',
  OPEN_QUESTION: 'open_question',
  POLL: 'poll',
  REMOTE_WEB: 'remote_web',
  SUBSCRIBE: 'subscribe',
  VIDEO: 'video',
  VOTE: 'vote',
});

export type AnalyticsAttachmentTypeEnum = Enum<typeof AnalyticsAttachmentType>;

export const AuthMigrationError = enumObject({
  UNKNOWN: 'unknown',
  EMAIL_MISMATCH: 'email-mismatch',
});

export const MaxNumTileLogos = 8;
export const ORIGINAL_KEYS = '__originalKeys__';

export const SegmentCategorisationEligibility = enumObject({
  DISABLED: 'DISABLED',
  TOO_SHORT: 'TOO_SHORT',
  AD_AFTER_LAST_SNAP: 'AD_AFTER_LAST_SNAP',
  ELIGIBLE: 'ELIGIBLE',
});

export type SegmentCategorisationEligibilityEnum = Enum<typeof SegmentCategorisationEligibility>;

export enum ExternalSnapSource {
  UNKNOWN = 'UNKNOWN',
  STORY_STUDIO = 'STORY_STUDIO',
  SNAP_PUBLISHER = 'SNAP_PUBLISHER',
  STORY_KIT_OUR_STORIES = 'STORY_KIT_OUR_STORIES',
  STORY_KIT_POPULAR_STORIES = 'STORY_KIT_POPULAR_STORIES',
  STORY_KIT_OFFICIAL_STORIES = 'STORY_KIT_OFFICIAL_STORIES',
  CONTENT_API = 'CONTENT_API',
}

export const TimeChangeType = enumObject({
  TIME: 'TIME',
  DATE: 'DATE',
});

export const MAX_EPISODES_PER_SEASON = 75;

export const ScsMagneticSegment = enumObject({
  LEFT: 'LEFT',
  RIGHT: 'RIGHT',
  NONE: 'NONE',
  NEW: 'NEW',
});

export type ScsMagneticSegmentEnum = Enum<typeof ScsMagneticSegment>;

export const TranslationEntityType = enumObject({
  HEADLINES: 'HEADLINES',
  SUBTITLES: 'SUBTITLES',
});

export type TranslationEntityTypeEnum = Enum<typeof TranslationEntityType>;

export const IMPORTED_SNAP_ID_PREFIX = 'ImportedSnapID-';

export const ADMIN_SETTINGS_KEYS = enumObject({
  TIER: 'tier',
  PUBLISHER_TYPE: 'publisherType',
  ADVERTISING_ENABLED: 'advertisingEnabled',
  ADVANCED_ADS_ENABLED: 'advancedAdsEnabled',
});

export const Key = enumObject({
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  ENTER: 'Enter',
});

export const SDSMultiTagsInputMode = enumObject({
  TAGS: 'tags',
  MULTIPLE: 'multiple',
});

export const MAGIC_SEARCH_URL =
  ENV === Environments.DEV ? 'https://magic-dev.sc-corp.net/' : 'https://magic.sc-corp.net/';

// Story Studio Lite
export const STORY_STUDIO_LITE_URL = ENV === Environments.PROD ? 'https://my.snapchat.com' : 'https://my.snap-dev.net';
export const ONBOARDING_WIZARD_ROUTE = '/profile/new';

export function scancodeImageUrl(uuidWithNoDashes: string) {
  return `https://app.snapchat.com/web/deeplink/snapcode?data=${uuidWithNoDashes}&version=1&type=svg&image_style=LENS`;
}

export function scancodeUnlockUrl(uuidWithNoDashes: string) {
  return PUBLIC_SERVICE_BASE_URL;
}

export const SCANCODE_PARAMETERS_FOR_UUID = 'unlock/?type=SNAPCODE&uuid=';

export function scancodeBaseUrlWithUuid(strippedUuid: string) {
  return `${PUBLIC_LENS_BASE_URL}${SCANCODE_PARAMETERS_FOR_UUID}${strippedUuid}`;
}

export const CURATION_MAP_DEFAULT_LOCATIONS = enumObject({
  US: {
    southWest: {
      latitude: 18.7655620028831,
      longitude: -179.9,
    },
    northEast: {
      latitude: 71.5407249966462,
      longitude: -66.8854430000034,
    },
  },
  GB: {
    southWest: {
      latitude: 49.8026640114418,
      longitude: -8.71865998255999,
    },
    northEast: {
      latitude: 60.9454539947733,
      longitude: 1.8673999944869,
    },
  },
  FR: {
    southWest: {
      latitude: 41.3011210083,
      longitude: -5.2389389958,
    },
    northEast: {
      latitude: 51.1840749958,
      longitude: 9.6597169884,
    },
  },
  IN: {
    southWest: {
      latitude: 6.65298500967984,
      longitude: 68.0733627002922,
    },
    northEast: {
      latitude: 35.5133318496614,
      longitude: 97.3955619998756,
    },
  },
  AE: {
    southWest: {
      latitude: 22.6315130339382,
      longitude: 51.3979690001722,
    },
    northEast: {
      latitude: 26.1177838435012,
      longitude: 56.4812379910831,
    },
  },
  UNSET: {
    southWest: {
      latitude: 51.50792778721983,
      longitude: -0.14114856719970703,
    },
    northEast: {
      latitude: 51.51703525365046,
      longitude: -0.12033462524414062,
    },
  },
});

export const CURATION_TOOL_V3_ZOOMED_IN_HEIGHT = 444;
export const CURATION_TOOL_V3_ZOOMED_IN_WIDTH = 250;
export const CURATION_TOOL_V3_ZOOMED_OUT_HEIGHT = 266;
export const CURATION_TOOL_V3_ZOOMED_OUT_WIDTH = 150;

export const DEFAULT_VOLUME = 0.5;

// these are limits for Single Asset Story snaps
export const SAS_MAX_SNAP_DURATION_MS = 20000; // 20 sec
export const SAS_MIN_SNAP_DURATION_MS = 2000; // 2 sec

export const ZENDESK_BASE_URL = 'https://publish-snapchat.zendesk.com';

export const BITMOJI_BASE = 'https://render.bitstrips.com';

export const CDN_BASE_URLS = ['https://cf-st.sc-cdn.net', 'https://bolt-gcdn.sc-cdn.net'];

export const INTERNAL_IMG_URLS = [
  'https://us-east-1.story-studio.snap-dev.net',
  'https://us-east-1.story-studio.snapchat.com',
];

export const IMAGE_DURATION_SECS = 10;

export const MAX_MEMORY_USAGE_MB = 400;
export const UNIFIED_LOGIN_ROLLOUT_PERCENTAGE = 50;
