import { pick } from 'lodash';

import { getActivePublisherId, getPublisherById } from 'state/user/selectors/userSelectors';

import { transformFromServerPoll, transformToServerPoll } from '../stages/pollStageConfig';

import { State } from 'src/types/rootState';

import type { EditionID } from 'types/editions';
import { SERVER_POLL_PROPERTIES } from 'types/polls';
import type { Snap } from 'types/snaps';

export function recomputePollProperties(snap: Snap, editionId: EditionID, getState: () => State) {
  const state = getState();
  const publisherId = getActivePublisherId(state);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const publisher = getPublisherById(state)(publisherId);

  if (snap.type !== 'POLL') {
    return {};
  }

  const serverPoll = pick(snap, SERVER_POLL_PROPERTIES);

  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  const opts = { edition: editionId, publisher: publisher.name };

  // TODO: return only properties computed from other properties -- not everything from pollStage
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Partial<PollSnap>' is not assign... Remove this comment to see the full error message
  return transformToServerPoll(transformFromServerPoll(serverPoll), opts);
}
