import { SSAPI_PROXY_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder(SSAPI_PROXY_BASE_URL);

export const mediaLibrary = {
  getMedia: builder.createAPICall('discover/media-library/media', {
    query: ['businessProfileId'],
    convertQueryCasing: (arg: any) => arg,
  }),

  updateSubtitles: builder.createAPICall('discover/media-library/:mediaLibraryId/updateSubtitles', {
    query: ['subtitlesMediaId'],
    convertQueryCasing: (arg: any) => arg,
  }),

  updateSubtitlesList: builder.createAPICall('discover/media-library/:mediaLibraryId/updateSubtitlesList', {
    query: ['subtitlesMediaIds'],
    convertQueryCasing: (arg: any) => arg,
  }),
};

export const asset = {
  info: builder.createAPICall('discover/asset/:assetId'),
  download: builder.createAPICall('discover/asset/:assetId/download', {
    convertQueryCasing: (arg: any) => arg,
  }),
  forceDownload: builder.createAPICall('discover/asset/:assetId/download', {
    query: ['downloadFilename'],
    convertQueryCasing: (arg: any) => arg,
  }),
  build: builder.createAPICall('discover/asset/:assetId/build/:buildTypeId'),
  downloadBuild: builder.createAPICall('discover/asset/:assetId/download/:buildTypeId/:buildSettingId', {
    convertQueryCasing: (arg: any) => arg,
  }),
  downloadMatcher: builder.createAPIMatcher('discover/asset/:assetId/download'),
  upload: builder.createAPICall('discover/asset/upload/:path', {
    query: ['autoBuild'],
    convertQueryCasing: (arg: any) => arg,
    multipartPath: true,
  }),
  urlUpload: builder.createAPICall('discover/asset/urlUpload', {
    convertQueryCasing: (arg: any) => arg,
    multipartPath: true,
  }),
  // TODO: 'legacyPath' part of path is not used by service, is here just to match the endpoint.
  getUploadUrl: builder.createAPICall('discover/asset/getUploadUrl/legacyPath', {
    query: ['entityOwner'],
    convertQueryCasing: (arg: any) => arg,
    multipartPath: true,
  }),
  claimMedia: builder.createAPICall('discover/asset/claimMedia', {
    query: ['signedURL', 'mediaProfile', 'requestCreativeSuiteIngestion'],
    convertQueryCasing: (arg: any) => arg,
  }),
  claimSubtitles: builder.createAPICall('discover/asset/claimSubtitles', {
    query: ['signedURL', 'language'],
    convertQueryCasing: (arg: any) => arg,
  }),
  getFreshTranscode: builder.createAPICall('discover/asset/getFreshTranscode', {
    query: ['mediaLibraryId'],
    convertQueryCasing: (arg: any) => arg,
  }),
  // TODO: 'legacyPath' part of path is not used by service, is here just to match the endpoint.
  longformUpload: builder.createAPICall('discover/asset/longformUpload/legacyPath', {
    query: ['entityOwner'],
    // TODO (piers) Make passThrough the default?
    convertQueryCasing: (arg: any) => arg,
    multipartPath: true,
  }),
  subtitles: builder.createAPICall('discover/asset/:assetId/subtitles'),
  duplicate: builder.createAPICall('discover/asset/duplicate/:assetId', {
    query: ['autoBuild'],
    convertQueryCasing: (arg: any) => arg,
  }),
  query: builder.createAPICall('discover/asset/query/:path', {
    query: ['entityOwner', 'type'],
    convertQueryCasing: (arg: any) => arg,
  }),
  buildVideoTile: builder.createAPICall('discover/asset/buildVideoTile/:assetId', {
    query: ['trimStart', 'trimDuration'],
    convertQueryCasing: (arg: any) => arg,
  }),
};

export const brightcove = {
  videos: builder.createAPICall('discover/brightcove/videos', {
    // TODO (piers) Get search_term changed to searchTerm on the backend
    query: ['limit', 'sort', 'offset', 'search_term'],
    // TODO (piers) Make passThrough the default?
    convertQueryCasing: (arg: any) => arg,
  }),
  videoCount: builder.createAPICall('discover/brightcove/videoCount', {
    query: ['offset', 'search_term'],
    // TODO (piers) Make passThrough the default?
    convertQueryCasing: (arg: any) => arg,
  }),
  videoMetadata: builder.createAPICall('discover/brightcove/asset/:assetId/videoMetadata'),
  videoThumbnails: builder.createAPICall('discover/brightcove/asset/:assetId/videoThumbnails'),
  videoDelete: builder.createAPICall('discover/brightcove/asset/:assetId'),
  longformVideoCatalog: builder.createAPICall('discover/brightcove/asset/longformVideoCatalog', {
    query: ['id'],
  }),
};
