import log from 'loglevel';

import { ACCESS_TOKEN_STORAGE_KEY, ErrorType } from 'config/constants';

import { discoverApiPreloader, UserInfo } from './preloadApi';

export function serializableUser(user: UserInfo) {
  return {
    id: user.sub,
    name: user.name,
    email: user.email,
    auth: {
      access_token: user.accessToken,
      expires_at: user.expiresAt,
    },
  };
}

export function ensureApiLoaded(method: any) {
  if (!discoverApiPreloader.hasLoadedApi()) {
    const message = `${method} called before api preload, we cannot guarantee this to work because of popup-blockers`;
    log.error(message);
    throw new Error(message);
  }
}

/*
Load will only return the user data if the user is already signed in.

The user gets signed in automatically when gapi.auth2.init is called, thus we don't need
another sign in call

Docs: https://developers.google.com/identity/sign-in/web/reference#googleauthsigninoptions
*/
export async function load() {
  ensureApiLoaded('load');

  const storedAccessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

  if (!storedAccessToken) {
    const error = new Error('not yet signed in');
    Object.assign(error, { errorType: ErrorType.USER_NEEDS_LOGIN });
    return Promise.reject(error);
  }

  const userInfo = await discoverApiPreloader.loadUserInfo();

  if (!userInfo) {
    const error = new Error('cannot load user from access token');
    Object.assign(error, { errorType: ErrorType.USER_NEEDS_LOGIN });
    return Promise.reject(error);
  }

  return serializableUser(userInfo);
}

export async function signIn() {
  ensureApiLoaded('signIn');

  let userInfo = discoverApiPreloader.getLoadedUserInfo();

  if (!userInfo) {
    userInfo = await discoverApiPreloader.loadUserInfo();
  }

  return serializableUser(userInfo);
}

export async function refreshToken() {
  ensureApiLoaded('refreshToken');

  const userInfo = await discoverApiPreloader.loadUserInfo();

  return serializableUser(userInfo);
}

export function signOut() {
  ensureApiLoaded('signOut');

  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);

  return Promise.resolve(true);
}
