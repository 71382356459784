import React from 'react';

import * as intlMessages from 'utils/intlMessages/intlMessages';

import SpinnerIcon from 'views/common/components/SpinnerIcon/SpinnerIcon';

import style from './LoadingView.scss';

type Props = {
  messageId?: string;
};

type State = any;

class LoadingView extends React.Component<Props, State> {
  mounted: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loaded: false,
    };

    this.mounted = true;
  }

  UNSAFE_componentWillMount() {
    // The css might not be loaded yet so we must wait or the spinner will look awful for an instant.
    if (document.readyState === 'complete') {
      this.setState({ loaded: true });
    } else {
      global.window.addEventListener('load', this.updateLoadedState, { capture: true, once: true });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  updateLoadedState = () => {
    if (this.mounted) {
      this.setState({ loaded: true });
    }
  };

  render() {
    if (this.state.loaded) {
      return (
        <div className={style.root}>
          <SpinnerIcon className={style.spinner} />
          {this.props.messageId ? intlMessages.getMessageFromId(this.props.messageId) : null}
        </div>
      );
    }

    return null;
  }
}

export default LoadingView;
