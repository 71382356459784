import hoistStatics from 'hoist-non-react-statics';
import React from 'react';
import type { Ref } from 'react';

import { PlatformConsumer } from 'context/platform/platformContext';

import type { GenericComponent } from 'types/components';

type ForwardedProps = {
  forwardedRef: Ref<any>;
};

export function withPlatform<Props, State>(ComposedComponent: GenericComponent<Props>) {
  class GetPlatform extends React.Component<Props & ForwardedProps, State> {
    render() {
      const { forwardedRef, ...rest } = this.props;
      return (
        <PlatformConsumer>
          {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ ref: (Props & ForwardedProps)["forwardedRe... Remove this comment to see the full error message */}
          {platform => <ComposedComponent ref={forwardedRef} {...rest} platform={platform} />}
        </PlatformConsumer>
      );
    }
  }

  // As ref is not actually a prop, the wrapped component won't pass it to the
  // composed component. This uses the new forward ref React API to pass this ref
  // over to the composed component.
  const forwardRefToComponent = (props: Props, ref: any) => {
    return <GetPlatform forwardedRef={ref} {...props} />;
  };

  forwardRefToComponent.displayName = `withPlatform(${ComposedComponent.displayName || ComposedComponent.name})`;

  // We forward the ref here so that the displayName is passed accordingly
  // $FlowFixMe flow does not have forwardRef definition yet https://github.com/facebook/flow/pull/6510
  const ForwardedComponent = React.forwardRef(forwardRefToComponent);

  // Copy static methods/properties onto the wrapped component

  return hoistStatics(ForwardedComponent, ComposedComponent);
}
