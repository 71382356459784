import { omit, sortBy } from 'lodash';
import { createSelector as s } from 'reselect';

import {
  createKeySelector,
  createDynamicKeySelector,
  createListOfIdsByProperty,
  reselectById,
} from 'state/common/selectorFactories';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { EMPTY_ARRAY, EMPTY_OBJECT, RichPublisherComponentType, MaxNumTileLogos } from 'config/constants';
import { buildComponentId, buildComponentIdForTileLogoId } from 'utils/componentUtils';
import { functionRef } from 'utils/functionUtils';

export const TILE_LOGO_PLACEHOLDER_COMPONENT_ID = buildComponentId(RichPublisherComponentType.TILE_LOGO_PLACEHOLDER, 0);
const getEntities = (state = {}) => {
  return (state as any).snapAdmin || {};
};
export const getCmsNotificationSetting = createKeySelector(getEntities, 'cmsNotificationSetting', {});
export const getAdminIsSavingUser = s(getEntities, entities => {
  return entities.savingUser > 0;
});
export const isShowingCmsNotification = s(
  createKeySelector(getCmsNotificationSetting, 'showNotificationMessage', 'false'),
  cmsNotificationSetting => cmsNotificationSetting === 'true'
);

export const shouldShowTileViolationBar = s(
  functionRef(featuresSelectors, 'isModerateTileViolation'),
  functionRef(featuresSelectors, 'isHighTileViolation'),
  functionRef(featuresSelectors, 'isSubscriberOnlyTileViolation'),
  (moderateTileViolationEnabled: boolean, highTileViolationEnabled: boolean, subscriberOnlyTileEnabled: boolean) => {
    return moderateTileViolationEnabled || highTileViolationEnabled || subscriberOnlyTileEnabled;
  }
);
export const isShowingNotificationMessage = s(
  isShowingCmsNotification,
  shouldShowTileViolationBar,
  (hasShowingCmsNotification: boolean, hasShowTileViolationBar: boolean) => {
    return hasShowingCmsNotification || hasShowTileViolationBar;
  }
);
export const getActiveEditPublisher = createKeySelector(getEntities, 'activeEditPublisher', {});
export const getTileLogosByPublisherId = reselectById(
  EMPTY_ARRAY,
  (state: any, publisherId: any) => publishersSelectors.getPublisherDetailsDataById(state)(publisherId),
  (publisher: any) => {
    if (publisher && publisher.tileLogos) {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'logo' implicitly has an 'any' type.
      return publisher.tileLogos.map((logo, index) => ({
        id: logo.mediaId,
        color: logo.color,
        order: index,
      }));
    }
    return EMPTY_ARRAY;
  }
);
export const getTileLogoById = s(
  getTileLogosByPublisherId,
  userSelectors.getActivePublisherId,
  (getTileLogosByPublisherIdFn, activePublisherId) => {
    return (logoId: any) => {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      const publisherLogos = getTileLogosByPublisherIdFn(activePublisherId);
      const foundLogo = publisherLogos.find(logo => (logo as any).id === logoId);
      return foundLogo ? { id: (foundLogo as any).id, metadata: omit(foundLogo, ['id']) } : {};
    };
  }
);
export const getTileLogoComponents = s(
  getTileLogosByPublisherId,
  userSelectors.getActivePublisherId,
  (getTileLogosFn, activePublisherId) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    const logos = getTileLogosFn(activePublisherId);
    const tileLogoPlaceholderComponent = {
      componentId: TILE_LOGO_PLACEHOLDER_COMPONENT_ID,
    };
    if (logos.length > 0) {
      let tileLogos = logos.map(logo => ({
        componentId: buildComponentIdForTileLogoId((logo as any).id),
        logo,
      }));
      tileLogos = sortBy(tileLogos, ['order']);
      if (Object.keys(tileLogos).length < MaxNumTileLogos) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ componentId: string; }' is not... Remove this comment to see the full error message
        tileLogos.push(tileLogoPlaceholderComponent);
      }
      return tileLogos;
    }
    return [tileLogoPlaceholderComponent];
  }
);
export const getTileLogoComponentsOnly = s(getTileLogoComponents, tileLogoComponents => {
  const tileLogos = tileLogoComponents.filter(
    tileLogoComponent => tileLogoComponent.componentId !== TILE_LOGO_PLACEHOLDER_COMPONENT_ID
  );
  return tileLogos.length === 0 ? EMPTY_ARRAY : tileLogos;
});
export const getAdminSettingTempState = createKeySelector(getEntities, 'adminSettingTempState', EMPTY_OBJECT);
const getPendingTileLogoMap = createKeySelector(getAdminSettingTempState, 'pendingTileLogoState', EMPTY_OBJECT);
export const getPendingTileLogoById = createDynamicKeySelector(getPendingTileLogoMap, EMPTY_OBJECT);
const getSavingTileLogoMap = createKeySelector(getEntities, 'tileLogosSavingStatus', EMPTY_OBJECT);
export const getSavingTileLogoById = createDynamicKeySelector(getSavingTileLogoMap, false);
export const getUsers = createKeySelector(getEntities, 'users', {});
export const getUserIdsAlphabeticalByName = createListOfIdsByProperty(getUsers, 'username');
export const getUserById = createDynamicKeySelector(getUsers, null);
export const getUsersLoadingCount = createKeySelector(getEntities, 'usersLoading', 0);
