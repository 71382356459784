import { DocumentNode, gql } from '@apollo/client';

import { CREATOR_FIELDS } from 'gql/queries/creator/creatorQuery';
import { ADMIN_PUBLISHER_FIELDS } from 'gql/queries/publisher/adminPublisherQuery';
import { ANALYTICS_PUBLISHER_FIELDS } from 'gql/queries/publisher/analyticsPublisherQuery';
import { EDITIONS_PUBLISHER_FIELDS } from 'gql/queries/publisher/editionsPublisherQuery';
import { HOME_PUBLISHER_FIELDS } from 'gql/queries/publisher/homePublisherQuery';
import { MEDIA_PUBLISHER_FIELDS } from 'gql/queries/publisher/mediaPublisherQuery';
import { ROOT_PUBLISHER_FIELDS } from 'gql/queries/publisher/rootPublisherQuery';
import { SETTINGS_PUBLISHER_FIELDS } from 'gql/queries/publisher/settingsPublisherQuery';
import { PublisherQueryType } from 'gql/types/publisherQueryTypeEnum';

export const ADMIN_GET_CREATOR_PUBLISHER = gql`
  ${CREATOR_FIELDS}
  ${ADMIN_PUBLISHER_FIELDS}
  query AdminCreatorPublisherQuery($input: CreatorRequest!) {
    creator(input: $input) {
      ...CreatorFields
      publisher {
        ...AdminPublisherFields
      }
    }
  }
`;

export const ANALYTICS_GET_CREATOR_PUBLISHER = gql`
  ${CREATOR_FIELDS}
  ${ANALYTICS_PUBLISHER_FIELDS}
  query AnalyticsCreatorPublisherQuery($input: CreatorRequest!) {
    creator(input: $input) {
      ...CreatorFields
      publisher {
        ...AnalyticsPublisherFields
      }
    }
  }
`;

export const EDITIONS_GET_CREATOR_PUBLISHER = gql`
  ${CREATOR_FIELDS}
  ${EDITIONS_PUBLISHER_FIELDS}
  query EditionsCreatorPublisherQuery($input: CreatorRequest!) {
    creator(input: $input) {
      ...CreatorFields
      publisher {
        ...EditionsPublisherFields
      }
    }
  }
`;

export const HOME_GET_CREATOR_PUBLISHER = gql`
  ${CREATOR_FIELDS}
  ${HOME_PUBLISHER_FIELDS}
  query HomeCreatorPublisherQuery($input: CreatorRequest!) {
    creator(input: $input) {
      ...CreatorFields
      publisher {
        ...HomePublisherFields
      }
    }
  }
`;

export const MEDIA_GET_CREATOR_PUBLISHER = gql`
  ${CREATOR_FIELDS}
  ${MEDIA_PUBLISHER_FIELDS}
  query MediaCreatorPublisherQuery($input: CreatorRequest!) {
    creator(input: $input) {
      ...CreatorFields
      publisher {
        ...MediaPublisherFields
      }
    }
  }
`;

export const ROOT_GET_CREATOR_PUBLISHER = gql`
  ${CREATOR_FIELDS}
  ${ROOT_PUBLISHER_FIELDS}
  query RootCreatorPublisherQuery($input: CreatorRequest!) {
    creator(input: $input) {
      ...CreatorFields
      publisher {
        ...RootPublisherFields
      }
    }
  }
`;

export const SETTINGS_GET_CREATOR_PUBLISHER = gql`
  ${CREATOR_FIELDS}
  ${SETTINGS_PUBLISHER_FIELDS}
  query SettingsCreatorPublisherQuery($input: CreatorRequest!, $isSuperAdmin: Boolean!) {
    creator(input: $input) {
      ...CreatorFields
      publisher {
        ...SettingsPublisherFields
      }
    }
  }
`;

export const CREATOR_PUBLISHER_QUERIES: { [key in PublisherQueryType]: DocumentNode } = {
  [PublisherQueryType.Admin]: ADMIN_GET_CREATOR_PUBLISHER,
  [PublisherQueryType.Home]: HOME_GET_CREATOR_PUBLISHER,
  [PublisherQueryType.Analytics]: ANALYTICS_GET_CREATOR_PUBLISHER,
  [PublisherQueryType.Media]: MEDIA_GET_CREATOR_PUBLISHER,
  [PublisherQueryType.Settings]: SETTINGS_GET_CREATOR_PUBLISHER,
  [PublisherQueryType.Root]: ROOT_GET_CREATOR_PUBLISHER,
  [PublisherQueryType.Editions]: EDITIONS_GET_CREATOR_PUBLISHER,
};
