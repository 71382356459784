import { assertArg, assertState } from 'utils/assertionUtils';

import type { Stage } from 'types/stages';

// each stage can have different State/Staged types, so allow anything
const config: Map<string, Stage<any, any, any>> = new Map();

export function registerStageConfig<State extends {}, Staged extends unknown, UnstageOpts extends unknown>(
  stage: Stage<State, Staged, UnstageOpts>
) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(stage).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(stage.properties).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(stage.transformToStaged).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(stage.transformFromStaged).is.function();

  config.set(stage.stageName, stage);
}

export function deregisterStagingHook(stageName: string) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(stageName).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(config.has(stageName)).is.truthy();

  config.delete(stageName);
}

function assertArgs(stageName: any, instance: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(stageName).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(instance).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(config.has(stageName)).is.truthy();
}

function transformInstance(instance: any, properties: any, transformFn: any) {
  // TODO: try _.pick instead
  const extractedProperties = properties.reduce((acc: any, property: any) => {
    acc[property] = instance[property]; // eslint-disable-line no-param-reassign
    return acc;
  }, {});

  return transformFn(extractedProperties);
}

export function transformInstanceFromStaged<Staged extends {}>(stageName: string, instance: Staged) {
  assertArgs(stageName, instance);
  const instanceConfig: any = config.get(stageName);
  if (!instanceConfig) {
    throw new Error(`Missing config for stage ${stageName}`);
  }

  return transformInstance(
    instance,
    instanceConfig.properties.concat(instanceConfig.propertiesForWriting || []),
    instanceConfig.transformFromStaged
  );
}

export function transformInstanceToStaged<State extends {}>(stageName: string, instance: State) {
  assertArgs(stageName, instance);
  const instanceConfig: any = config.get(stageName);
  if (!instanceConfig) {
    throw new Error(`Missing config for stage ${stageName}`);
  }

  return transformInstance(
    instance,
    instanceConfig.properties.concat(instanceConfig.propertiesForReading || []),
    instanceConfig.transformToStaged
  );
}
