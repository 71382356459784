import { createSelector as s } from 'reselect';

import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publisherSelectors from 'state/publishers/selectors/publishersSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { AdPlacementMode } from 'config/constants';
import { functionRef } from 'utils/functionUtils';

export const getShouldShowAdMarkers = s(
  functionRef(publisherSelectors, 'isDynamicAdsEnabled'),
  functionRef(featuresSelectors, 'isOptionalAdsOverrideEnabled'),
  functionRef(featuresSelectors, 'isAlwaysShowAdControlsEnabled'),
  functionRef(publisherStoryEditorSelectors, 'getActiveEdition'),
  functionRef(userSelectors, 'getAdControlsEnabled'),
  (
    allowDynamicAds: boolean,
    isOptionalAdsOverrideEnabled: boolean,
    isAlwaysShowAdControlsEnabled: boolean,
    activeEdition: any,
    isAdControlsEnabled: boolean
  ) => {
    if (isAlwaysShowAdControlsEnabled) {
      return true;
    }

    if (allowDynamicAds && isOptionalAdsOverrideEnabled) {
      return activeEdition.adPlacementMode === AdPlacementMode.FIXED_AD_SLOTS;
    }

    return !allowDynamicAds && isAdControlsEnabled;
  }
);
