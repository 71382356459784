import { get } from 'lodash';
import log from 'loglevel';

import {
  clearEditionDateRange,
  clearEditionList,
  clearStoryList,
  setAnalyticsStatsType,
} from 'state/analytics/actions/analyticsActions';
import { logout, preloadGapi, updateAuthTypeBasedOnLocalStorage } from 'state/auth/actions/authActions';
import { setSnapAuthTicket } from 'state/auth/actions/snapAuthActions';
import { getAuthType } from 'state/auth/selectors/authSelectors';
import { autocompleteSCCGetTags } from 'state/autocomplete/actions/autocompleteActions';
import * as editorActions from 'state/editor/actions/editorActions';
import { loadAllFeatures } from 'state/features/admin/actions/featuresActions';
import * as mediaLibraryTrayActions from 'state/mediaLibrary/actions/mediaLibraryTrayActions';
import { showModal } from 'state/modals/actions/modalsActions';
import { loadOrgOnboardingsList } from 'state/orgOnboardings/actions/orgOnboardingsActions';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publisherToolsActions from 'state/publisherTools/actions/publisherToolsActions';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import { isShowPublisher } from 'state/publishers/schema/publisherEntityHelpers';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import * as rootActions from 'state/root/actions/rootActions';
import * as routerActions from 'state/router/actions/routerActions';
import * as routerSelectors from 'state/router/selectors/routerSelectors';
import * as showsActions from 'state/shows/actions/showsActions';
import { authZendesk, getZendeskJWTToken } from 'state/supportTickets/actions/supportTicketsActions';
import * as userActions from 'state/user/actions/userActions';
import { setUseUnifiedLogin } from 'state/user/actions/userActions';
import { hasClaimForActivePublisher, isActiveCreatorPublisher } from 'state/user/selectors/userSelectors';

import { AuthType, ZENDESK_BASE_URL } from 'config/constants';
import { PublisherQueryType } from 'gql/types/publisherQueryTypeEnum';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import * as grapheneUtils from 'utils/grapheneUtils';
import { reportTimerWithDuration } from 'utils/grapheneUtils';
import { ModalType } from 'utils/modalConfig';
import { getPerformanceTiming } from 'utils/performance/performanceUtils';
import { requireCreatorPublisherInfo } from 'utils/router/requireCreatorPublisherInfo';
import { requireEdition } from 'utils/router/requireEdition';
import { requireHostUsername } from 'utils/router/requireHostUsername';
import { requireLogin, snapAuthSignInIfUserExists } from 'utils/router/requireLogin';
import requirePublisherInfo from 'utils/router/requirePublisherInfo';
import { requireUncachedPublishers } from 'utils/router/requirePublishersUncached';
import { requireSnap } from 'utils/router/requireSnap';
import requireUserInfo from 'utils/router/requireUserInfo';
import { wrapInTiming, wrapInTimingWithStore } from 'utils/router/routerUtils';
import { getPublisherIdOrActiveCreatorPublisherId, redirectionPopupOptions } from 'utils/routerUtils';

import { AnalyticsStatsType } from 'types/analytics';
import { Claim } from 'types/permissions';
import type { Store } from 'types/redux';

export const requireClaim = (claim: Claim, store: Store) =>
  wrapInTiming(({ match }: any) => {
    return store.dispatch(requireUserInfo()).then(() => {
      if (hasClaimForActivePublisher(store.getState(), claim)) {
        return Promise.resolve();
      }
      log.error('User has no claim on publisher');
      return store.dispatch(
        routerActions.goToNoPermission({
          publisherId: match.params.publisherId,
          overwriteHistory: true,
        })
      );
    });
  });

export const clearMediaLibraryHook = (store: Store) =>
  wrapInTiming(async () => {
    store.dispatch(mediaLibraryTrayActions.clearTray());
  });

export const clearAnalyticsHook = (store: Store) =>
  wrapInTiming(async () => {
    store.dispatch(clearEditionList());
    store.dispatch(clearStoryList());
  });

export const clearStoryAnalyticsHook = (store: Store) =>
  wrapInTiming(async () => {
    store.dispatch(clearEditionDateRange());
    store.dispatch(setAnalyticsStatsType(AnalyticsStatsType.ALL));
  });

const fetchPublisherInfo = (store: Store, match: any, queryEnum?: PublisherQueryType) => {
  // Needs to set active publisher ahead of time because this hook is invoked asynchronously
  // and the child components derive isPublisherToolsEnabled from the activePublisherId
  store.dispatch(autocompleteSCCGetTags());
  return (
    requirePublisherInfo(
      store,
      queryEnum
    )({ match })
      // If could not get publisher then redirect to no permission view
      .catch((error: Error) => {
        log.error(`Failed to fetch publisher ${match.params.publisherId}`, error);
        return store.dispatch(
          routerActions.goToNoPermission({ publisherId: match.params.publisherId, overwriteHistory: true })
        );
      })
  );
};

const preloadAllStories = (store: Store, match: any, parentPromise: any) => {
  const publisherId = getPublisherIdOrActiveCreatorPublisherId({ match }, store);
  if (!publisherId) {
    return Promise.reject(new Error('No publisher id yet'));
  }
  return store
    .dispatch(publisherToolsActions.initializeHomepage({ publisherId, parentPromise }))
    .catch((error: Error) => {
      log.error(`Failed to fetch stories for ${publisherId}`, error);
      return store.dispatch(routerActions.goToNoPermission({ publisherId, overwriteHistory: true }));
    });
};

export const enterHomePageForCreatorHook = (store: Store) =>
  wrapInTimingWithStore(async ({ match, parentPromise }: any) => {
    const startTime = getPerformanceTiming();
    await requireCreatorPublisherInfo(store, PublisherQueryType.Home)({ match });
    if (isActiveCreatorPublisher(store.getState())) {
      await preloadAllStories(store, match, parentPromise);
    }
    const endTime = getPerformanceTiming();
    const duration = endTime - startTime;
    reportTimerWithDuration({
      metricsName: 'stories_creator_details_load_time',
      milliSec: duration,
    });
  }, store);

export const requireCreatorAndPublisherInfoHook = (store: Store, queryType: PublisherQueryType) =>
  wrapInTimingWithStore(async ({ match }: any) => {
    const startTime = getPerformanceTiming();
    await requireCreatorPublisherInfo(store, queryType)({ match });
    const endTime = getPerformanceTiming();
    const duration = endTime - startTime;
    reportTimerWithDuration({
      metricsName: 'stories_creator_pages_details_load_time',
      milliSec: duration,
    });
  }, store);

export const preloadActiveEditions = (store: Store) =>
  wrapInTiming(({ match }: any) => {
    const publisherId = getPublisherIdOrActiveCreatorPublisherId({ match }, store);
    if (!publisherId) {
      return Promise.reject(new Error('No publisher id yet'));
    }
    return store.dispatch(publishersActions.getActiveEditionsForPublisher({ publisherId }));
  });

const preloadAllShows = (store: Store, match: any) => {
  const publisherId = getPublisherIdOrActiveCreatorPublisherId({ match }, store);
  if (!publisherId) {
    return Promise.reject(new Error('No publisher id yet'));
  }

  const publisher = publishersSelectors.getPublisherDetailsDataById(store.getState())(publisherId);
  if (publisher && isShowPublisher(publisher)) {
    return store.dispatch(showsActions.loadShows(publisherId, publisher.businessProfileId));
  }

  return Promise.resolve();
};

export const enterShowsHook = (store: Store) =>
  wrapInTiming(({ match, parentPromise }: any) => {
    return preloadAllStories(store, match, parentPromise).then(() => preloadAllShows(store, match));
  });

export const requireLoginAndUserInfoHook = (store: Store) =>
  wrapInTiming(() => {
    return requireLogin(store)()
      .then(() => store.dispatch(requireUserInfo()))
      .then(() => store.dispatch(userActions.setDefaultActivePublisherId()));
  });

export const requireLoginHook = (store: Store) => requireLogin(store);

export const requireLoginAndHostUsernameHook = (store: Store) =>
  wrapInTimingWithStore(async ({ match }: any) => {
    const startTime = getPerformanceTiming();

    await requireLogin(store)();
    // If there is no auth state, it means requireLogin redirects to login and we should not continue
    const userNeedsLogin = !getAuthType(store.getState());
    if (!userNeedsLogin) {
      await requireHostUsername(store)({ match });
    }

    const endTime = getPerformanceTiming();
    const duration = endTime - startTime;
    reportTimerWithDuration({
      metricsName: 'creator_forwarder',
      dimensions: {
        userNeedsLogin: userNeedsLogin ? 'true' : 'false',
      },
      milliSec: duration,
    });
  }, store);

export const requirePublisherInfoAndEditionHook = (store: Store, queryParam: PublisherQueryType) =>
  wrapInTiming((params: any) => {
    const { match } = params;
    return requireCreatorAndPublisherInfoHook(store, queryParam)({ match }).then(() => requireEdition(store)(params));
  });

export const requireEditionHook = (store: Store) =>
  wrapInTiming((params: any) => {
    return requireEdition(store)(params);
  });

export const requireSnapHook = (store: Store) =>
  wrapInTiming((params: any) => {
    return requireSnap(store)(params);
  });

// exit hook don't need gaTiming
export const clearEditionHook = (store: Store) => () => {
  return store.dispatch(publisherStoryEditorActions.clearActiveEdition());
};

// exit hook don't need gaTiming
export const clearTopsnapHook = (store: Store) => () => {
  return Promise.all([
    store.dispatch(editorActions.clearActiveTopsnap()),
    store.dispatch(editorActions.clearActiveComponent()),
  ]);
};

const preloadGapiAndHandleErrors = (store: any) => {
  return store.dispatch(preloadGapi()).catch((error: any) => store.dispatch(routerActions.goToLogin({ error })));
};

export const sanitizeTicketIfAnyHook = (store: Store, basePath: string) => (): Promise<unknown> => {
  const query = routerSelectors.getQuery(store.getState());
  if (query.useUnifiedLogin) {
    store.dispatch(setUseUnifiedLogin(true));
  }

  if (query.ticket) {
    grapheneUtils.incrementCounter('Login.SnapAuth', { action: 'authTicketReceived' });
    store.dispatch(setSnapAuthTicket(query.ticket));
    delete query.ticket;

    // If there is a ticket, allow user in the system
    return store.dispatch(routerActions.goToSnapContinue({ query: { redirect: query.redirect }, basePath }));
  }

  grapheneUtils.incrementCounter('Login.SnapAuth', { action: 'noAuthTicketReceived' });
  return Promise.resolve();
};

export const clearStoreAndSanitizeTicketIfAny = (store: Store) =>
  wrapInTiming(() => {
    store.dispatch(rootActions.resetStore);

    return sanitizeTicketIfAnyHook(store, '/login/snap_continue')();
  });

export const clearStoryPreloadGapiAndSanitizeTicketIfAny = (store: Store) =>
  wrapInTiming(() => {
    store.dispatch(rootActions.resetStore);

    return preloadGapiAndHandleErrors(store).then(() => sanitizeTicketIfAnyHook(store, '/login/snap_continue')());
  });

export const requireUserInfoHook = (store: Store) =>
  wrapInTiming(() => {
    const query = routerSelectors.getQuery(store.getState());
    const redirect = query && query.redirect ? query.redirect : '/';
    return store.dispatch(snapAuthSignInIfUserExists(redirect));
  });

export const zendeskLoginHook = (store: Store) =>
  wrapInTiming(() => {
    return requireLogin(store)()
      .then(() => {
        // User may land on this link when they are still not logged in
        // requireLogin will handle the error silenty and take the user to login page
        // If we detect user is on login page do not ask for JWT token
        const path = routerSelectors.getPathName(store.getState());
        if (path.startsWith('/login')) {
          throw new Error('401: User is not logged in');
        }

        return store.dispatch(getZendeskJWTToken());
      })
      .then((result: any) => {
        const jwtToken = get(result, 'payload.jwtToken', null);

        const query = routerSelectors.getQuery(store.getState());
        return authZendesk({ jwtToken, returnTo: query.return_to }, ZENDESK_BASE_URL);
      })
      .catch((error: any) => {
        // Nothing to do here if already on login page
        const path = routerSelectors.getPathName(store.getState());
        if (path.startsWith('/login')) {
          return Promise.resolve();
        }

        // Can get here if user is logged in but something goes wrong with requesting the JWT token
        // Take user to homepage and throw informative message
        store.dispatch(routerActions.goToLink({ path: '/', overwriteHistory: true }));
        return apiErrorHandler(store.dispatch, ErrorContexts.SUPPORT_RAISE_TICKET)(error);
      });
  });

export const zendeskLogoutHook = (store: Store) =>
  wrapInTiming(() => {
    store.dispatch(updateAuthTypeBasedOnLocalStorage());

    const authType = getAuthType(store.getState());
    const promise = authType === AuthType.SNAPCHAT ? Promise.resolve() : preloadGapiAndHandleErrors(store);

    return promise.then(() => store.dispatch(logout(undefined, undefined, false)));
  });

export const requireFeaturesManagementHook = (claim: string, store: Store) => () => {
  return store.dispatch(loadAllFeatures());
};

export const requireUncachedPublishersHook = (store: Store) =>
  wrapInTiming(() => {
    return store.dispatch(requireUncachedPublishers());
  });

export const requireOrgOnboardingsHook = (store: Store) =>
  wrapInTiming(() => {
    return store.dispatch(loadOrgOnboardingsList());
  });

export const redirectToCreatorFromPublisherHook = (store: Store) =>
  wrapInTimingWithStore(({ match }: any) => {
    const startTime = getPerformanceTiming();
    return fetchPublisherInfo(store, match, PublisherQueryType.Root)
      .then(() => {
        const publisherDetails = getActivePublisherDetails(store.getState());
        if (publisherDetails?.hostUsername) {
          store.dispatch(
            routerActions.goToCreatorPathFromPublisher({
              match,
              hostUsername: publisherDetails.hostUsername,
            })
          );
        }
      })
      .then(() => {
        const endTime = getPerformanceTiming();
        const duration = endTime - startTime;
        reportTimerWithDuration({
          metricsName: 'publisher_to_creator_forward',
          dimensions: {
            path: match.path,
          },
          milliSec: duration,
        });
      })
      .then(() => {
        store.dispatch(showModal(ModalType.ALERT, 'PublisherToUserRedirection', redirectionPopupOptions));
      });
  }, store);
