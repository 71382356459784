// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import global from 'global';
import log from 'loglevel';

import { apiErrorHandler, isApiError } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { InfoContext, showInfoMessage } from 'utils/errors/infoMessage/infoMessageUtils';

const handleError = (message: any, dispatch: any, error: any, swallow = false) => {
  // Errors can be thrown in two ways:
  // - as the error type, e.g. throw new Error(''), or
  // - as an event with the type parameter set to 'error':
  /*
    const xhr = new XMLHttpRequest();
    xhr.onerror = e => console.log(e instanceof Error, e.type === 'error'); // false true
    xhr.open('foo', 'bar://baz');
    xhr.send();
  */

  if (!error) {
    log.warn(message);
    return undefined;
  }

  if (!(error instanceof Error || error?.type === 'error')) {
    log.warn('Thrown exception was not of type Error:', error);
  } else if (!error.alreadyHandled) {
    log.warn(message, error);

    // TODO: instead of testing for error type here, rely on an error handler chain in
    // which each error handler calls the next in the chain if it can't treat the error itself

    // TODO: generic error reporting is disabled in prod because we had too many errors
    // and users were getting confused. We should fix all these errors and then re-enable this
    // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '__DEBUG__'.
    if (__DEBUG__ || __TEST__) {
      apiErrorHandler(dispatch, ErrorContexts.UNKNOWN)(error);
      if (!isApiError(error)) {
        dispatch(showInfoMessage(InfoContext.UNKNOWN_ERROR));
      }
    }
    // TODO: handle more errors by relying on a custom error hierarchy that allows errors to
    // be enriched with context information instead of using a generic error message here.

    error.alreadyHandled = true; // eslint-disable-line no-param-reassign
  }

  return swallow ? undefined : Promise.reject(error);
};

export const actionCreatorErrorHandler = (dispatch: any) => (err: any) => {
  return handleError('Caught exception from action creator:', dispatch, err);
};

export const dispatchErrorHandler = (dispatch: any) => (err: any) => {
  return handleError('Caught exception from action dispatch:', dispatch, err);
};

export const installUnhandledErrorHandler = (store: any) => {
  global.addEventListener('unhandledrejection', (event: any) => {
    // This is the last error handler in the chain so the error handler must swallow
    // the error or we'll have an infinite loop as this event will be fired again
    return handleError('Caught unhandled exception:', store.dispatch, event.reason, true);
  });
};
