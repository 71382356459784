import { getShouldUseUnifiedLogin } from 'state/auth/selectors/authSelectors';
import { autocompleteSCCGetTags } from 'state/autocomplete/actions/autocompleteActions';
import { setPublisherDetailsWithGraphQLResponse } from 'state/publishers/actions/publishersActions';
import { goToCreatorNoPermission, goToLanding, goToNotFound } from 'state/router/actions/routerActions';
import { loadShows } from 'state/shows/actions/showsActions';
import { setActiveCreator, setActivePublisher } from 'state/user/actions/userActions';
import { getUseUnifiedLoginOverride, hasClaimForPublisher } from 'state/user/selectors/userSelectors';

import { PublisherQueryType } from 'gql/types/publisherQueryTypeEnum';
import { fetchCreatorPublisher } from 'utils/apis/graphQLUtils';
import { getCreatorHostUsernameFromRouteOrStorage } from 'utils/routerUtils';

import { Creator } from 'types/creator';
import { Claim } from 'types/permissions';
import { TierLevel } from 'types/publishers';
import { Store } from 'types/redux';

export const requireCreatorPublisherInfo = (store: Store, queryType: PublisherQueryType) => async ({ match }: any) => {
  const hostUsername = getCreatorHostUsernameFromRouteOrStorage({ match });
  if (!hostUsername) {
    // TODO(gcan): Handle missing hostUsername in url
    await store.dispatch(goToLanding());
    return;
  }

  // SuperAdmin is a global claim, so we don't need a publisherId.
  const isSuperAdmin = hasClaimForPublisher(store.getState())(null, Claim.SUPER_ADMIN_VIEWER, false);
  const shouldUseUnifiedLogin =
    getShouldUseUnifiedLogin(store.getState()) || getUseUnifiedLoginOverride(store.getState());
  try {
    const creator = await fetchCreatorPublisher(queryType, hostUsername, isSuperAdmin);
    // Only when the user is a super admin or shouldUseUnifiedLogin is true, allow non publisher profiles
    if (!creator.publisher && !isSuperAdmin && !shouldUseUnifiedLogin) {
      await store.dispatch(goToCreatorNoPermission({ hostUsername }));
      return;
    }

    const publisherId = parseInt(creator.publisherId, 10);

    const activeCreator: Creator = {
      hostUsername,
      publisherId,
      title: creator.title,
      orgId: creator.publisher?.orgId || '',
      businessProfileId: creator.businessProfileId,
      hostUserId: creator.hostUserId,
      isTestProfile: creator.isTestProfile,
      publishingEnabled: creator.publisher?.publishingEnabled,
    };
    store.dispatch(autocompleteSCCGetTags());
    await store.dispatch(setActiveCreator(activeCreator));

    if (publisherId) {
      await store.dispatch(setActivePublisher(publisherId));
      await store.dispatch(setPublisherDetailsWithGraphQLResponse(creator.publisher, creator.userProperties));
      if (creator?.publisher?.tier === TierLevel.SHOW) {
        store.dispatch(loadShows(publisherId, creator.businessProfileId));
      }
    }
  } catch (error) {
    if (error instanceof Error && error.message.indexOf('Not found') !== -1) {
      await store.dispatch(goToNotFound({ hostUsername }));
    } else {
      await store.dispatch(goToCreatorNoPermission({ hostUsername }));
    }
  }
};
