import _ from 'lodash';

import { UploadPurpose, BuildType, MlsMediaProfile } from 'config/constants';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';

import { AssetType } from 'types/assets';

type BlobOrURL = File | string;

type MediaMetadata = {
  width?: string;
  height?: string;
};

// TODO: should the CMS be responsible for deciding the media profile from upload purpose?  Or is that the backend's job?
const uploadPurposeToMlsMediaActionMap: { [key in UploadPurpose]: MlsMediaProfile | null } = {
  [UploadPurpose.TOP_SNAP]: MlsMediaProfile.TOP_SNAP_MEDIA,
  [UploadPurpose.OVERLAY_MEDIA]: MlsMediaProfile.TOP_SNAP_OVERLAY_MEDIA,
  [UploadPurpose.CURATED_SNAP_MEDIA]: MlsMediaProfile.CURATED_SNAP_MEDIA,
  [UploadPurpose.CURATED_SNAP_OVERLAY_MEDIA]: MlsMediaProfile.CURATED_SNAP_OVERLAY_MEDIA,
  [UploadPurpose.TILE_LOGO]: MlsMediaProfile.WIDE_FORMAT_PUBLISHER_LOGO,
  [UploadPurpose.TILE_IMAGE]: MlsMediaProfile.RAW_MEDIA,
  [UploadPurpose.TILE_CROP_IMAGE]: MlsMediaProfile.DISCOVER_FEED_TILE,
  [UploadPurpose.HAPPENING_NOW_TILE_IMAGE]: MlsMediaProfile.RAW_MEDIA,
  [UploadPurpose.HAPPENING_NOW_TILE_CROP_IMAGE]: MlsMediaProfile.HAPPENING_NOW_SQUARE_TILE,
  [UploadPurpose.LONGFORM_VIDEO]: MlsMediaProfile.LONGFORM_VIDEO,
  [UploadPurpose.POLL_IMAGE]: MlsMediaProfile.POLL_OPTION_IMAGE,
  [UploadPurpose.ARTICLE_IMAGE]: MlsMediaProfile.REGULAR_IMAGE,
  [UploadPurpose.TOPSNAP_SUBTITLES]: MlsMediaProfile.VTT_SUBTITLES,
  [UploadPurpose.EDITION_SUBTITLES]: MlsMediaProfile.VTT_SUBTITLES,
  [UploadPurpose.SUBTITLE]: MlsMediaProfile.VTT_SUBTITLES,
  [UploadPurpose.SINGLE_ASSET_STORY]: MlsMediaProfile.SINGLE_VIDEO_STORY_MEDIA,
  [UploadPurpose.WIDE_ICON]: MlsMediaProfile.WIDE_FORMAT_PUBLISHER_LOGO,
  [UploadPurpose.SQUARE_ICON]: MlsMediaProfile.SQUARE_FORMAT_PUBLISHER_LOGO,
  [UploadPurpose.SQUARE_HERO_IMAGE]: MlsMediaProfile.PROFILE_HERO_IMAGE,
  // UGC content is uploaded to bolt directly and bypasses MLS.
  [UploadPurpose.SPOTLIGHT]: MlsMediaProfile.UNKNOWN_MEDIA_PROFILE,
  [UploadPurpose.PUBLIC_STORY]: MlsMediaProfile.UNKNOWN_MEDIA_PROFILE,
};

const showUploadPurposeToMlsMediaActionMap: Partial<{ [key in UploadPurpose]: MlsMediaProfile | null }> = {
  [UploadPurpose.TOP_SNAP]: MlsMediaProfile.SHOWS_SNAP_MEDIA,
};

const happeningNowToMlsMediaActionMap: Partial<{ [key in UploadPurpose]: MlsMediaProfile | null }> = {
  [UploadPurpose.TOP_SNAP]: MlsMediaProfile.HAPPENING_NOW_SNAP,
};

export function uploadPurposeToMlsMediaAction(
  uploadPurpose: UploadPurpose,
  publisherFlags: {
    isShow?: boolean;
    isHappeningNow?: boolean;
    isDynamicEditions?: boolean;
  },
  blobOrUrl: BlobOrURL | undefined,
  mediaMetadata: MediaMetadata
) {
  let mediaProfile = uploadPurposeToMlsMediaActionMap[uploadPurpose] || MlsMediaProfile.TOP_SNAP_MEDIA;
  if (
    mediaProfile === MlsMediaProfile.REGULAR_IMAGE &&
    !!blobOrUrl &&
    typeof blobOrUrl !== 'string' &&
    blobOrUrl.type === 'image/gif'
  ) {
    mediaProfile = MlsMediaProfile.ANIMATED_IMAGE;
  }

  const isVideo = !!blobOrUrl && typeof blobOrUrl !== 'string' && blobOrUrl.type === 'video/mp4';
  const isSquare = mediaMetadata && mediaMetadata.width === mediaMetadata.height;
  const isCircularVideo = isSquare && isVideo;
  if (mediaProfile === MlsMediaProfile.TOP_SNAP_MEDIA && isCircularVideo) {
    mediaProfile = MlsMediaProfile.CIRCULAR_SNAP_MEDIA;
  }

  if (publisherFlags.isHappeningNow || publisherFlags.isDynamicEditions) {
    return happeningNowToMlsMediaActionMap[uploadPurpose] || mediaProfile;
  }

  if (publisherFlags.isShow) {
    if (isCircularVideo) {
      return MlsMediaProfile.CIRCULAR_SNAP_MEDIA;
    }

    return showUploadPurposeToMlsMediaActionMap[uploadPurpose] || mediaProfile;
  }

  return mediaProfile;
}

export const getAutoBuildParam = (purpose: UploadPurpose, assetType: AssetType) => {
  const autoBuild = [];
  switch (assetType) {
    case AssetType.IMAGE:
      autoBuild.push(BuildType.IMAGE_PREVIEW);
      break;
    case AssetType.VIDEO:
      autoBuild.push(BuildType.VIDEOASSET_THUMBNAIL, BuildType.VIDEO_METADATA, BuildType.VIDEO_PREVIEW);
      break;
    default:
      break;
  }

  switch (purpose) {
    case UploadPurpose.HAPPENING_NOW_TILE_CROP_IMAGE:
    case UploadPurpose.TILE_CROP_IMAGE:
      autoBuild.push(BuildType.TILE);
      break;
    case UploadPurpose.TILE_LOGO:
      autoBuild.length = 0;
      autoBuild.push(BuildType.TILE_LOGO);
      break;
    default:
      break;
  }
  return autoBuild;
};

export function buildClaimMediaRequest(
  signedURL: string,
  mediaProfile: MlsMediaProfile,
  requestCreativeSuiteIngestion: boolean
) {
  const endpoint = mediaLibraryAPI.asset.claimMedia({ signedURL, mediaProfile, requestCreativeSuiteIngestion });
  return {
    method: 'get',
    endpoint,
    finalizer: optimisticJsonFinalizer,
  };
}

export function buildClaimSubtitlesRequest(signedURL: string, language: string) {
  const endpoint = mediaLibraryAPI.asset.claimSubtitles({ signedURL, language });
  return {
    method: 'get',
    endpoint,
    finalizer: optimisticJsonFinalizer,
  };
}
