import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';

import * as scsAPI from 'utils/apis/scsAPI';

import type { PublisherID } from 'types/publishers';

export const LOAD_SHOWS = 'shows/LOAD_SHOWS';
export function loadShows(publisherId: PublisherID, businessProfileId: string) {
  return (dispatch: Dispatch) => {
    return dispatch(
      createCallAction(
        {
          type: LOAD_SHOWS,
          params: {
            publisherId,
          },
        },
        {
          endpoint: scsAPI.shows.list({
            publisherId,
            businessProfileId,
          }),
        }
      )
    );
  };
}
