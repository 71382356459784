import { memoize } from 'lodash';
import { createSelector as s } from 'reselect';

import { createKeyThenDynamicKeySelector } from 'state/common/selectorFactories';

import { State } from 'src/types/rootState';

import { AttachmentOptions, ServerAttachment } from 'types/attachments';

const getAttachmentsData = (state: State) => {
  return state.attachments || {};
};

export const getAttachmentById = createKeyThenDynamicKeySelector<ServerAttachment | null, string>(
  getAttachmentsData,
  'byId',
  null
);

export const getEnabledAttachmentOptions = s(
  (state: State) => state,
  state => {
    return memoize((allAttachmentOptions: AttachmentOptions[]) => {
      return allAttachmentOptions.filter((option: AttachmentOptions) => option.enabled(state));
    });
  }
);
