import { SSAPI_PROXY_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder(SSAPI_PROXY_BASE_URL);

export const attachments = {
  ingestArticle: builder.createAPICall('discover/attachments/ingest', {
    query: ['snapId', 'publisherId', 'url'],
    convertQueryCasing: (arg: any) => arg,
  }),
};

export const attachment = {
  existing: builder.createAPICall('discover/publisher/:businessProfileId/attachment/:attachmentId', {
    query: ['attachmentType', 'fileType'],
    convertQueryCasing: (arg: any) => arg,
  }),
};

export const subtitles = {
  generateSubtitles: builder.createAPICall('edition/:editionId/generate-subtitles', {
    query: ['language'],
    convertQueryCasing: (arg: any) => arg,
  }),
  deleteStorySubtitles: builder.createAPICall('edition/:storyId/delete-subtitles', {
    query: ['language'],
    convertQueryCasing: (arg: any) => arg,
  }),
};

export const publisherEditionHistoryInfo = {
  getPublisherEditionHistoryInfo: builder.createAPICall('publisher/:publisherId/publisherEditionHistoryInfo'),
};

export const publisher = {
  activeEditions: builder.createAPICall('publisher/:publisherId/editions/active'),
  liveEditions: builder.createAPICall('publisher/:publisherId/editions/live'),
  editions: builder.createAPICall('publisher/:publisherId/editions', {
    convertQueryCasing: (arg: any) => arg,
  }),
  updatePublisher: builder.createAPICall('publishers/:publisherId'),
};

export const stories = {
  makeStoriesUnavailable: builder.createAPICall('stories/makeStoriesUnavailable/:businessProfileId', {
    query: ['excludeId'],
    convertQueryCasing: (arg: any) => arg,
  }),
};

export const story = {
  discardLiveEditChanges: builder.createAPICall('edition/:editionId/discardLiveEditChanges'),
};

export const inventory = {
  getInventoryItemByProfileId: builder.createAPICall(
    'accountservice/organizations/:organizationId/publisher/:businessProfileId/getInventoryItem'
  ),
  createInventoryBundles: builder.createAPICall('accountservice/createInventoryBundles'),
  listInventoryBundles: builder.createAPICall('accountservice/listInventoryBundles'),
  createInventoryBundleItemMappings: builder.createAPICall('accountservice/createInventoryBundleItemMappings'),
  listInventoryBundleItemMappings: builder.createAPICall(
    'accountservice/publisher/:publisherId/listInventoryBundleItemMappings'
  ),
  deleteInventoryBundleItemMappings: builder.createAPICall('accountservice/deleteInventoryBundleItemMappings'),
};
