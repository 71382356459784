import invariant from 'invariant';
import type { Dispatch } from 'redux';

import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as segmentsSelectors from 'state/segments/selectors/segmentsSelectors';

import type { GetState } from 'types/redux';
import type { Segment, SegmentReduxID } from 'types/segments';

export const setSegmentPropertiesAndSave = (
  {
    segmentId,
  }: {
    segmentId: SegmentReduxID;
  },
  propertyInputs: Partial<Segment>
) => (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
  return Promise.resolve().then(() => {
    const segment = segmentsSelectors.getSegmentById(getState())(segmentId);
    invariant(segment, `could not find segment with id ${segmentId}`);

    const edition = editionsSelectors.getEditionById(getState())(segment.editionId);

    invariant(edition, `could not find edition with id ${segment.editionId}`);

    const segmentsDiffs: Partial<Segment>[] = edition.segments.map(seg => {
      if (seg.id === segment.id) {
        return { id: seg.id, ...propertyInputs };
      }
      return { id: seg.id };
    });
    return dispatch(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
      editionsActions.setEditionPropertiesAndSave({ editionId: segment.editionId }, { segments: segmentsDiffs })
    );
  });
};
