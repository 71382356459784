export type Urn = string;

export type ParsedUrn = {
  scope: Scope;
  attribute: Attribute | undefined | null;
  value: string | undefined | null;
};

export type ResourceRole = {
  allowedActions: SecureAction[];
  claims: Claim[];
  resource: Urn;
  roleId: Roles;
  explicit: boolean;
  roleInheritance: boolean;
};

export enum Claim {
  AD_EXPORTER = 'AD_EXPORTER',
  AD_PLACER = 'AD_PLACER',
  ADMIN_USER_MANAGER = 'ADMIN_USER_MANAGER',
  ADVANCED_CURATOR = 'ADVANCED_CURATOR',
  ADVANCED_STYLER = 'ADVANCED_STYLER',
  ANALYTICS_VIEWER = 'ANALYTICS_VIEWER',
  ANALYTICS_DAILY_VIEWER = 'ANALYTICS_DAILY_VIEWER',
  ARTICLE_EDITOR = 'ARTICLE_EDITOR',
  ATTACHMENT_VIEWER = 'ATTACHMENT_VIEWER',
  CAMERA_ATTACHMENT_EDITOR = 'CAMERA_ATTACHMENT_EDITOR',
  CURATED_SNAP_METADATA_EDITOR = 'CURATED_SNAP_METADATA_EDITOR',
  CURATION_SNAP_DOWNLOADER = 'CURATION_SNAP_DOWNLOADER',
  DEBUG_ENTITY_VIEWER = 'DEBUG_ENTITY_VIEWER',
  HAPPENING_NOW_FEEDS_VIEWER = 'HAPPENING_NOW_FEEDS_VIEWER',
  HAPPENING_NOW_FEEDS_WRITER = 'HAPPENING_NOW_FEEDS_WRITER',
  INTERNAL_ADMIN_DASHBOARD_VIEWER = 'INTERNAL_ADMIN_DASHBOARD_VIEWER',
  LOCKED_EDITION_PUBLISHER = 'LOCKED_EDITION_PUBLISHER',
  LONGFORM_VIDEO_EDITOR = 'LONGFORM_VIDEO_EDITOR',
  MEDIA_EDITOR = 'MEDIA_EDITOR',
  MONETIZATION_ANALYST_VIEWER = 'MONETIZATION_ANALYST_VIEWER',
  NOTIFICATION_BANNER_EDITOR = 'NOTIFICATION_BANNER_EDITOR',
  OLD_SC_ADMIN_EDITOR = 'OLD_SC_ADMIN_EDITOR',
  OPINION_POLL_EDITOR = 'OPINION_POLL_EDITOR',
  PERSONALITY_QUIZ_EDITOR = 'PERSONALITY_QUIZ_EDITOR',
  PRIVATE_ARCHIVER = 'PRIVATE_ARCHIVER',
  PUBLISHER_CREATOR = 'PUBLISHER_CREATOR',
  PUBLISHER_HOST_USER_BUSINESS_EDITOR = 'PUBLISHER_HOST_USER_BUSINESS_EDITOR',
  PUBLISHER_HOST_USER_PROFILE_EDITOR = 'PUBLISHER_HOST_USER_PROFILE_EDITOR',
  PUBLISHER_ONBOARDING = 'PUBLISHER_ONBOARDING',
  PUBLISHER_SETTINGS_EDITOR = 'PUBLISHER_SETTINGS_EDITOR',
  PUBLISHER_USER_MANAGER = 'PUBLISHER_USER_MANAGER',
  REVENUE_VIEWER = 'REVENUE_VIEWER',
  SNAPCODE_PREVIEWER = 'SNAPCODE_PREVIEWER',
  SNAP_COPIER = 'SNAP_COPIER',
  SNAP_DUPLICATOR = 'SNAP_DUPLICATOR',
  SNAP_ID_COPIER = 'SNAP_ID_COPIER',
  SNAP_SNAPCODE_EDITOR = 'SNAP_SNAPCODE_EDITOR',
  SNAP_SNAPCODE_VIEWER = 'SNAP_SNAPCODE_VIEWER',
  SNAP_SOURCE_COPIER = 'SNAP_SOURCE_COPIER',
  STORY_AGE_GATE_EDITOR = 'STORY_AGE_GATE_EDITOR',
  STORY_BLOCKER = 'STORY_BLOCKER',
  STORY_CONTENT_EDITOR = 'STORY_CONTENT_EDITOR',
  STORY_COPIER = 'STORY_COPIER',
  STORY_CREATOR = 'STORY_CREATOR',
  STORY_DELETER = 'STORY_DELETER',
  STORY_DRAFT_EDITOR = 'STORY_DRAFT_EDITOR',
  STORY_DUPLICATOR = 'STORY_DUPLICATOR',
  STORY_MIGRATOR = 'STORY_MIGRATOR',
  STORY_PUBLISHER = 'STORY_PUBLISHER',
  STORY_SEARCH_SNAP_CURATOR = 'STORY_SEARCH_SNAP_CURATOR',
  STORY_SHAREABLE_EDITOR = 'STORY_SHAREABLE_EDITOR',
  STORY_VIEWER = 'STORY_VIEWER',
  SUBSCRIPTIONS_MANAGER = 'SUBSCRIPTIONS_MANAGER',
  SUPER_AD_PLACER = 'SUPER_AD_PLACER',
  SUPER_ADMIN_VIEWER = 'SUPER_ADMIN_VIEWER',
  SUPER_NAV_VIEWER = 'SUPER_NAV_VIEWER',
  SUPER_ONBOARDING_MANAGER = 'SUPER_ONBOARDING_MANAGER',
  SUPER_PUBLISHER_SETTINGS_EDITOR = 'SUPER_PUBLISHER_SETTINGS_EDITOR',
  SUPER_STORY_DUPLICATOR = 'SUPER_STORY_DUPLICATOR',
  TILE_AUTOGENERATOR = 'TILE_AUTOGENERATOR',
  TILE_OPTIMIZER_IMPRESSIONS_VIEWER = 'TILE_OPTIMIZER_IMPRESSIONS_VIEWER',
  TOPIC_STICKER_CRUD = 'TOPIC_STICKER_CRUD',
  TOPSNAP_DOWNLOADER = 'TOPSNAP_DOWNLOADER',
  TOPSNAP_SHAREABLE_TOGGLER = 'TOPSNAP_SHAREABLE_TOGGLER',
  TOPSNAP_VIDEO_LOOP_EDITOR = 'TOPSNAP_VIDEO_LOOP_EDITOR',
  TRIVIA_QUIZ_EDITOR = 'TRIVIA_QUIZ_EDITOR',
  VOTE_EDITOR = 'VOTE_EDITOR',
}

export enum SecureAction {
  READ_CONTAINER_ENTITY = 'READ_CONTAINER_ENTITY',
  POST_TO_SPOTLIGHT = 'POST_TO_SPOTLIGHT',
}

export enum Scope {
  GLOBAL = 'GLOBAL',
  PUBLISHER = 'PUBLISHER',
  AD_PUBLISHERS = 'AD_PUBLISHERS',
}

export enum Attribute {
  ID = 'ID',
}

export enum Roles {
  ADMIN = 'admin',
  BUSINESS_ADMIN = 'businessAdmin',
  ANALYST = 'analyst',
  CONTRIBUTOR = 'contributor',
  DEFAULT_NO_PERMISSION = 'default_no_permission',
  MONETIZATION_ANALYST = 'monetizationAnalyst',
  SUPER_ADMIN = 'superAdmin',
  VIEWER = 'viewer',
  ORG_ADMIN = 'orgAdmin',
  CURATOR = 'curator',
  CURATOR_READER = 'curator_reader',
}
