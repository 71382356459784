import { find, get } from 'lodash';
import { createSelector as s } from 'reselect';

import { createDynamicKeySelector, createKeySelector, reselectById } from 'state/common/selectorFactories';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'config/constants';

import type {
  Asset,
  AssetID,
  CloudVisionContentAnnotation,
  ExplicitContentAnnotation,
  MediaAnnotations,
} from 'types/assets';
import type { State } from 'types/rootState';
import { SnapType } from 'types/snaps';

export const getAssetData = (state: State) => state.asset;

export const getAssetMap = createKeySelector(getAssetData, 'byId', EMPTY_OBJECT);

export const getAssetById = createDynamicKeySelector<Asset | undefined | null, string | number>(getAssetMap, null);

export const getExternalIds = s(getAssetById, getAssetByIdFn => (assetId: AssetID) => {
  const asset = getAssetByIdFn(assetId);

  if (!asset) {
    return null;
  }

  const externalIds = get(asset, 'externalIds', []);
  if (!externalIds.length) {
    return null;
  }

  return find(externalIds, externalId => Boolean(externalId.snapPublisherId));
});

export const getCreativeId = s(getExternalIds, getExternalIdsFn => (assetId: AssetID) => {
  const snapPublisherExternalIds = getExternalIdsFn(assetId);
  return snapPublisherExternalIds?.snapPublisherId?.creativeId || null;
});

export const getExternalMediaId = s(getExternalIds, getExternalIdsFn => (assetId: AssetID) => {
  const snapPublisherExternalIds = getExternalIdsFn(assetId);
  return (
    snapPublisherExternalIds?.snapPublisherId?.mediaId || snapPublisherExternalIds?.snapPublisherId?.creativeId || null
  );
});

export const getCreativeIdFromSnap = s(getCreativeId, getCreativeIdFn => (snap: any) => {
  if (!snap) {
    return null;
  }

  switch (snap.type) {
    case SnapType.VIDEO: {
      return getCreativeIdFn(snap.videoAssetId);
    }
    case SnapType.IMAGE: {
      return getCreativeIdFn(snap.imageAssetId);
    }
    case SnapType.UNKNOWN: {
      return getCreativeIdFn(snap.assetId);
    }
    default:
      return null;
  }
});

export const getMediaAnnotationsTagsFromId = s(getAssetById, getAssetByIdFn => (assetId: AssetID) => {
  const asset = getAssetByIdFn(assetId);

  if (!asset) {
    return null;
  }

  return get(asset, ['mediaAnnotations', 'tags'], null);
});

const getMediaAnnotationsByAssetId = reselectById<MediaAnnotations | null, AssetID>(
  null,
  (state: State, assetId: AssetID) => getAssetById(state)(assetId),
  (asset: Asset) => asset?.mediaAnnotations
);

export const getExplicitContentAnnotations = reselectById<ExplicitContentAnnotation[], AssetID>(
  EMPTY_ARRAY,
  (state: State, assetId: AssetID) => getMediaAnnotationsByAssetId(state)(assetId),
  (mediaAnnotations: MediaAnnotations | null) => mediaAnnotations?.explicitContentAnnotations || EMPTY_ARRAY
);

export const getCloudVisionContentAnnotations = reselectById<CloudVisionContentAnnotation[], AssetID>(
  EMPTY_ARRAY,
  (state: State, assetId: AssetID) => getMediaAnnotationsByAssetId(state)(assetId),
  (mediaAnnotations: MediaAnnotations | null) => mediaAnnotations?.cloudVisionAnnotations || EMPTY_ARRAY
);

export const getAssetUserAttatchmentsFromId = s(getAssetById, getAssetByIdFn => (assetId: AssetID) => {
  const asset = getAssetByIdFn(assetId);

  if (!asset?.strippedContextHint) {
    return null;
  }

  // strippedContextHint contains the attatchments that a user can add to a snap, and a boolean indicating if they added it or not
  // this returns an array of strings of the attatchments the user did add
  return Object.keys(asset.strippedContextHint).filter(key => asset.strippedContextHint[key]);
});

export const getMediaAnnotationsTagsFromSnap = s(
  getMediaAnnotationsTagsFromId,
  getMediaAnnotationsTagsFromIdFn => (snap: any) => {
    if (!snap) {
      return null;
    }

    switch (snap.type) {
      case SnapType.SINGLE_ASSET:
      case SnapType.VIDEO: {
        return getMediaAnnotationsTagsFromIdFn(snap.videoAssetId);
      }
      case SnapType.IMAGE: {
        return getMediaAnnotationsTagsFromIdFn(snap.imageAssetId);
      }
      default:
        return null;
    }
  }
);
