import { SortDirection } from 'react-virtualized';
import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

import { CheetahStoriesAnalyticsSortableColumns, CheetahStoryState, EMPTY_ARRAY } from 'config/constants';
import type { CheetahStoryStateEnum } from 'config/constants';
import * as dateUtils from 'utils/dateUtils';

import type { CheetahAnalyticsStory, CheetahAnalyticsStories } from 'types/analytics';
import { StoryState } from 'types/editions';
import type { State } from 'types/rootState';

export const getStoriesAnalyticsPage = (state: State) => {
  return state.analytics || {};
};

export const getSortedColumn = createKeySelector(
  getStoriesAnalyticsPage,
  'sortedColumn',
  CheetahStoriesAnalyticsSortableColumns.STATUS
);
export const getSortOrder = createKeySelector(getStoriesAnalyticsPage, 'sortOrder', SortDirection.ASC);

export const getStoryList = (state: State): any => {
  const analytics = state && state.analytics;
  return (analytics && analytics.storyList) || EMPTY_ARRAY;
};

export const getAvailability = (story: CheetahAnalyticsStory) => {
  const isAvailable = story.metadata.state === StoryState.LIVE || story.metadata.state === StoryState.ARCHIVED;
  return isAvailable ? CheetahStoryState.AVAILABLE : CheetahStoryState.UNAVAILABLE;
};

const cheetahStoryStatePriority: {
  [key in CheetahStoryStateEnum]: number;
} = {
  [CheetahStoryState.AVAILABLE]: 1,
  [CheetahStoryState.UNAVAILABLE]: 2,
};

const compareValues = (valueA: any, valueB: any) => {
  if (valueA < valueB) {
    return -1;
  }
  if (valueA > valueB) {
    return 1;
  }

  return 0;
};

const storyHasInvalidDate = (story: CheetahAnalyticsStory) => {
  return !story.metadata.postTime || dateUtils.isMomentStartOfEpoch(story.metadata.postTime);
};

const rankStoryByInvalidDate = (storyA: CheetahAnalyticsStory, storyB: CheetahAnalyticsStory) => {
  const storyAInvalidDate = storyHasInvalidDate(storyA);
  const storyBInvalidDate = storyHasInvalidDate(storyB);

  const storyAStatus = storyAInvalidDate ? 0 : 1;
  const storyBStatus = storyBInvalidDate ? 0 : 1;

  return compareValues(storyAStatus, storyBStatus);
};

const compareStoryPriority = (storyA: CheetahAnalyticsStory, storyB: CheetahAnalyticsStory) => {
  const storyAState = getAvailability(storyA);
  const storyBState = getAvailability(storyB);

  const storyAPriority = cheetahStoryStatePriority[storyAState];
  const storyBPriority = cheetahStoryStatePriority[storyBState];

  return compareValues(storyAPriority, storyBPriority);
};

const compareStoryStartTime = (storyA: CheetahAnalyticsStory, storyB: CheetahAnalyticsStory) => {
  const storyATime = storyHasInvalidDate(storyA) ? 0 : storyA.metadata.postTime.toDate().getTime();
  const storyBTime = storyHasInvalidDate(storyB) ? 0 : storyB.metadata.postTime.toDate().getTime();

  return compareValues(storyATime, storyBTime);
};

const compareStorySubscribers = (storyA: CheetahAnalyticsStory, storyB: CheetahAnalyticsStory) => {
  return compareValues(storyA.stats.editionSubscribeCount, storyB.stats.editionSubscribeCount);
};

const compareStoryUniqueViewers = (storyA: CheetahAnalyticsStory, storyB: CheetahAnalyticsStory) => {
  return compareValues(storyA.stats.uniqueDau, storyB.stats.uniqueDau);
};

const compareStoryTimeViewed = (storyA: CheetahAnalyticsStory, storyB: CheetahAnalyticsStory) => {
  // for live stories which haven't got data yet we can get NaN for the avgTimeViewed
  const storyATimeViewed = Number.isNaN(Number(storyA.stats.avgTimeViewed)) ? 0 : storyA.stats.avgTimeViewed;
  const storyBTimeViewed = Number.isNaN(Number(storyB.stats.avgTimeViewed)) ? 0 : storyB.stats.avgTimeViewed;
  return compareValues(storyATimeViewed, storyBTimeViewed);
};

const sortStoriesByStatus = (stories: CheetahAnalyticsStories) => {
  return stories.sort((firstStory: CheetahAnalyticsStory, secondStory: CheetahAnalyticsStory) => {
    return (
      compareStoryPriority(firstStory, secondStory) ||
      compareStoryStartTime(secondStory, firstStory) ||
      compareValues(firstStory.metadata.editionId, secondStory.metadata.editionId)
    );
  });
};

const sortStoriesBySubscribers = (stories: CheetahAnalyticsStories) => {
  return stories.sort((firstStory: CheetahAnalyticsStory, secondStory: CheetahAnalyticsStory) => {
    return (
      compareStorySubscribers(secondStory, firstStory) ||
      compareValues(firstStory.metadata.editionId, secondStory.metadata.editionId)
    );
  });
};

const sortStoriesByUniqueViewers = (stories: CheetahAnalyticsStories) => {
  return stories.sort((firstStory: CheetahAnalyticsStory, secondStory: CheetahAnalyticsStory) => {
    return (
      compareStoryUniqueViewers(secondStory, firstStory) ||
      compareValues(firstStory.metadata.editionId, secondStory.metadata.editionId)
    );
  });
};

const sortStoriesByTimeViewed = (stories: CheetahAnalyticsStories) => {
  return stories.sort((firstStory: CheetahAnalyticsStory, secondStory: CheetahAnalyticsStory) => {
    return (
      compareStoryTimeViewed(secondStory, firstStory) ||
      compareValues(firstStory.metadata.editionId, secondStory.metadata.editionId)
    );
  });
};

const sortStoriesByLiveDate = (stories: CheetahAnalyticsStories) => {
  return stories.sort((firstStory: CheetahAnalyticsStory, secondStory: CheetahAnalyticsStory) => {
    return (
      rankStoryByInvalidDate(firstStory, secondStory) ||
      compareStoryStartTime(firstStory, secondStory) ||
      compareValues(firstStory.metadata.editionId, secondStory.metadata.editionId)
    );
  });
};

export const getSortedAllStories = s(
  getStoryList,
  getSortedColumn,
  getSortOrder,
  (allStories, sortedColumn, sortOrder) => {
    let sortedStories;
    switch (sortedColumn) {
      case CheetahStoriesAnalyticsSortableColumns.SUBSCRIBERS:
        sortedStories = sortStoriesBySubscribers(allStories.slice());
        break;
      case CheetahStoriesAnalyticsSortableColumns.AVAILABILITY:
        sortedStories = sortStoriesByLiveDate(allStories.slice());
        break;
      case CheetahStoriesAnalyticsSortableColumns.UNIQUE_VIEWERS:
        sortedStories = sortStoriesByUniqueViewers(allStories.slice());
        break;
      case CheetahStoriesAnalyticsSortableColumns.TIME_VIEWED:
        sortedStories = sortStoriesByTimeViewed(allStories.slice());
        break;
      case CheetahStoriesAnalyticsSortableColumns.STATUS:
      default:
        // we shouldn't use sort order on status
        return sortStoriesByStatus(allStories.slice());
    }

    return sortOrder === SortDirection.ASC ? sortedStories.reverse() : sortedStories;
  }
);
