import log from 'loglevel';
import { Dispatch, Middleware } from 'redux';

import { hasActionExpired } from 'state/actions/selectors/actionSelectors';

const expiredActionCatcherMiddleware: Middleware = ({ getState }: any) => (next: Dispatch) => (action: any) => {
  if (hasActionExpired(action)(getState())) {
    log.info(
      'This path should have never been reached! A middleware is not handling expired actions.',
      'Failure to handle expired actions may result in unstable store state.'
    );
  }

  return next(action);
};

export default expiredActionCatcherMiddleware;
