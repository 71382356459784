import { enumObject } from 'utils/enum';

export type ZoomedIn = 'ZOOMED_IN';
export type ZoomedOut = 'ZOOMED_OUT';
export type Zoom = ZoomedIn | ZoomedOut;

export const ZoomType: {
  ZOOMED_IN: ZoomedIn;
  ZOOMED_OUT: ZoomedOut;
} = enumObject({
  ZOOMED_IN: 'ZOOMED_IN',
  ZOOMED_OUT: 'ZOOMED_OUT',
});

export type Dimensions = {
  height: number;
  width: number;
};

export type GridDimensions = {
  [k in Zoom]: Dimensions;
};
