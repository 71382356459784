import _ from 'lodash';

import type { AuthTypeEnum } from 'config/constants';
import {
  ADS_GATEWAY_GRAPHQL_BASE_URL,
  AuthType,
  BearerPrefix,
  DISCOVER_FRONTEND_BASE_URL,
  DISCOVER_SERVICE_BASE_URL,
  SSAPI_PROXY_BASE_URL,
  SSAPI_PROXY_GRAPHQL_BASE_URL,
} from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { assertArg } from 'utils/assertionUtils';

export const AUTH_HEADERS_BY_BASE_URL = {
  [DISCOVER_FRONTEND_BASE_URL]: 'Authorization',
  [DISCOVER_SERVICE_BASE_URL]: 'X-SC-Web-Client-Id',
  [SSAPI_PROXY_BASE_URL]: 'X-SC-Web-Client-Id',
  [SSAPI_PROXY_GRAPHQL_BASE_URL]: 'X-SC-Web-Client-Id',
  [ADS_GATEWAY_GRAPHQL_BASE_URL]: 'Authorization',
};

export function addAuthHeaderIfRequired(action: any, token: string | undefined | null, authType: AuthTypeEnum) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(action).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(action.meta).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(action.meta[CALL_API]).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(action.meta[CALL_API].endpoint).is.string();

  const callApiMeta = action.meta[CALL_API];
  const header = getAuthHeaderForEndpoint(callApiMeta.endpoint);
  const headerType = authType === AuthType.SNAPCHAT ? BearerPrefix.SNAP_BEARER : BearerPrefix.BEARER;

  // Dealing with external endpoint, don't allow for cookies to be included
  if (!header) {
    return action;
  }

  const headers = token ? { [header]: `${headerType} ${token}` } : undefined;

  return _.merge({}, action, {
    meta: {
      [CALL_API]: {
        headers,
        credentials: 'include', // Allow the server to set cookies
      },
    },
  });
}

export function getAuthHeaderForEndpoint(endpoint: any) {
  let header;
  Object.keys(AUTH_HEADERS_BY_BASE_URL).some(baseUrl => {
    if (endpoint.startsWith(baseUrl)) {
      header = AUTH_HEADERS_BY_BASE_URL[baseUrl];
      return true;
    }
    return false;
  });

  return header;
}
