import invariant from 'invariant';
import { get, merge } from 'lodash';
import log from 'loglevel';

import * as articleActions from 'state/article/actions/articleActions';
import { getFields, isUnsaved } from 'state/article/selectors/articleSelectors';
import { loadSnapAssetInfo } from 'state/asset/actions/assetActions';
import * as buildStatusActions from 'state/buildStatus/actions/buildStatusActions';
import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import { createActivatingAction, createClearingAction } from 'state/common/actionFactories';
import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as editorStateActions from 'state/editor/actions/editorStateActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import type { OpenSnapEditorType } from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import { shouldUseSingleAssetEditor } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as publisherToolsSelectors from 'state/publisherTools/selectors/publisherToolsSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import {
  getActiveStoryTimelineSnapBySnapId,
  getDefaultActiveTab,
  getSingleAssetActiveTab,
  getSingleAssetPlayerCurrentTime,
  getSingleAssetPlayerPendingCurrentTime,
} from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import { getData, isDirty } from 'state/stages/selectors/stagesSelectors';
import * as tilesActions from 'state/tiles/actions/tilesActions';
import type { TileContainer } from 'state/tiles/schema/tilesEntityHelpers';
import { getTileId } from 'state/tiles/schema/tilesEntityHelpers';
import * as tilesSelectors from 'state/tiles/selectors/tilesSelectors';

import { loadSnapPreviewIfMissing } from '../../previews/actions/previewsActions';
import * as editorSelectors from '../selectors/editorSelectors';

import {
  ComponentScope,
  CROP_PROPERTIES,
  FileType,
  LocalStorage,
  RichSnapActiveComponentType,
  RichSnapComponentType,
  UploadPurpose,
} from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { buildComponentIdForSnapId, buildComponentIdForTileId } from 'utils/componentUtils';
import { functionRef } from 'utils/functionUtils';
import { localStorage } from 'utils/localStorageUtils';
import { MediaOutputDimensions } from 'utils/media/mediaConfig';
import * as tileCropUtils from 'utils/media/tileCropUtils';

import { assertAssetId } from 'types/assets';
import { SnapProblem } from 'types/build';
import type { SnapId } from 'types/common';
import { assertSnapId, assertSnapIdOrNull } from 'types/common';
import type { Edition, EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import type { Dispatch, GetState, ReduxAction, ThunkAction } from 'types/redux';
import type { SegmentReduxID } from 'types/segments';
import type { ShowID, ShowsEditorStateUpdeep } from 'types/shows';
import type { SingleAssetStoryEditorState, TimelineSnap } from 'types/singleAssetStoryEditor';
import { ConfigTab } from 'types/singleAssetStoryEditor';
import { SnapType } from 'types/snaps';
import type { Snap, TopSnap } from 'types/snaps';
import type { Tile, TileEditorState, TileID, TileShape } from 'types/tiles';

export const SET_ACTIVE_TOPSNAP = 'editor/SET_ACTIVE_TOPSNAP';
export const SET_ACTIVE_EDITION = 'editor/SET_ACTIVE_EDITION';
export const SET_ACTIVE_COMPONENT = 'editor/SET_ACTIVE_COMPONENT';
export const SET_ACTIVE_TILE = 'editor/SET_ACTIVE_TILE';
export const SET_EDITOR_CONFIG_PROPERTIES = 'editor/SET_EDITOR_CONFIG_PROPERTIES';
export const SET_EDITOR_CONFIG_TILE_PROPERTIES = 'editor/SET_EDITOR_CONFIG_TILE_PROPERTIES';
export const REMOVE_EDITOR_CONFIG_TILE_PROPERTIES = 'editor/REMOVE_EDITOR_CONFIG_TILE_PROPERTIES';
export const REBUILD_SNAP = 'editor/REBUILD_SNAP';
export const SET_STATUS_MESSAGE = 'editor/SET_STATUS_MESSAGE';
export const SET_ACTIVE_OVERLAY = 'editor/SET_ACTIVE_OVERLAY';
export const SET_UNREAD_ATTACHMENT = 'editor/SET_UNREAD_ATTACHMENT';
export const setActiveTopsnap = createActivatingAction(
  SET_ACTIVE_TOPSNAP,
  'snap',
  functionRef(snapsSelectors, 'getSnapById')
);
export const clearActiveTopsnap = createClearingAction(SET_ACTIVE_TOPSNAP, 'snap');
export const setActiveEdition = createActivatingAction(
  SET_ACTIVE_EDITION,
  'edition',
  functionRef(editionsSelectors, 'getEditionById')
);
export const setActiveComponentId = (componentId: string) => (dispatch: Dispatch) =>
  dispatch({
    type: SET_ACTIVE_COMPONENT,
    payload: {
      componentId,
    },
  });
export const setUnreadAttachment = createActivatingAction(
  SET_UNREAD_ATTACHMENT,
  'attachment',
  functionRef(snapsSelectors, 'getSnapById')
);
export const clearUnreadAttachment = createClearingAction(SET_UNREAD_ATTACHMENT, 'attachment');

export function updateActiveComponentIfNeeded({
  storyId,
  newSnapId,
  wholeSnapId,
}: {
  storyId: EditionID;
  newSnapId: SnapId;
  wholeSnapId: SnapId;
}) {
  assertSnapId(newSnapId);
  assertSnapId(wholeSnapId);
  return (dispatch: Dispatch, getState: GetState) => {
    const defaultTab = getDefaultActiveTab(getState())(storyId);
    const activeTab = editorSelectors.getSingleAssetEditorActiveTab(getState())(storyId, defaultTab);
    if (activeTab !== ConfigTab.SNAP) {
      return dispatch(setActiveComponentBasedOnTab(storyId, activeTab));
    }

    if (editorSelectors.getActiveWholeSnapId(getState()) === wholeSnapId) {
      return dispatch(setActiveComponentId(buildComponentIdForSnapId(newSnapId)));
    }
    return Promise.resolve();
  };
}

export const clearActiveComponent = createClearingAction(SET_ACTIVE_COMPONENT, 'component');
export const initializeSnapComponentPreviews = () => (dispatch: Dispatch, getState: GetState) => {
  const snapComponents = componentsSelectors.getComponentsForActiveSnap(getState());
  return Promise.all(
    snapComponents.map(snapComponent => {
      if ((snapComponent as any).componentType === RichSnapComponentType.SNAP) {
        return dispatch(loadSnapPreviewIfMissing((snapComponent as any).snap.id));
      }
      return null;
    })
  );
};
export const setEditorConfigProperties = (diffObject: any): ReduxAction => ({
  type: SET_EDITOR_CONFIG_PROPERTIES,
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; payload: any; }' is not assi... Remove this comment to see the full error message
  payload: diffObject,
});
export const setEditorConfigTileProperties = (tileId: TileID, properties: {}) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileId).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  return {
    type: SET_EDITOR_CONFIG_TILE_PROPERTIES,
    payload: {
      tileId,
      properties,
    },
  };
};
export const removeEditorConfigTileProperties = (tileId: TileID, properties: string | string[]) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileId).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.string.or.is.array();
  return {
    type: REMOVE_EDITOR_CONFIG_TILE_PROPERTIES,
    payload: {
      tileId,
      properties,
    },
  };
};
const unloadBottomSnapStage = (): ThunkAction => (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
  const activeBottomSnap = editorSelectors.getActiveBottomsnap(getState());
  if (activeBottomSnap) {
    return dispatch(stagesActions.discardData(activeBottomSnap.id));
  }
  return Promise.resolve();
};
export const unloadBottomSnap = () => {
  return (dispatch: Dispatch) => {
    return Promise.all([dispatch(articleActions.unloadArticle()), dispatch(unloadBottomSnapStage())]);
  };
};

export function saveBottomSnapLocally() {
  return (dispatch: Dispatch, getState: GetState) => {
    const bottomSnapId = editorSelectors.getActiveBottomsnap(getState())?.id;
    let fields = null;
    if (isUnsaved(getState())) {
      fields = getFields(getState());
    } else if (isDirty(getState())(bottomSnapId)) {
      fields = getData(getState())(bottomSnapId);
    }
    if (fields) {
      localStorage.setItem(getAttachmentKey(bottomSnapId), JSON.stringify(fields));
    }
  };
}

export function getLocalBottomSnapFields(bottomSnapId: SnapId) {
  return () => {
    if (bottomSnapId) {
      const fieldsString = localStorage.getItem(getAttachmentKey(bottomSnapId));
      return fieldsString ? JSON.parse(fieldsString) : null;
    }
    return null;
  };
}

export function removeLocalBottomSnap(bottomSnapId: SnapId) {
  return () => localStorage.removeItem(getAttachmentKey(bottomSnapId));
}

function getAttachmentKey(bottomSnapId: any) {
  return `${LocalStorage.ATTACHMENT}-${bottomSnapId}`;
}

// Thunk to initialize article editor OR unified rich snap editor
// articleId is optional - if provided the activeComponent will be set to the article
export const initializeEditor = ({
  topsnapId,
  editionId,
  attachmentId,
}: {
  topsnapId: SnapId;
  editionId: EditionID;
  attachmentId: SnapId | undefined | null;
}) => (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
  assertSnapId(topsnapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number.or.is.null();
  assertSnapIdOrNull(attachmentId);
  // editionId will be null when the editor is rendered in Ads Self Serve
  const includeEdition = editionId !== null;
  const isSingleAssetEditor = editionId ? shouldUseSingleAssetEditor(getState())(editionId) : false;
  const setActiveSnapPromiseGenerator = () => {
    return Promise.all([
      dispatch(unloadBottomSnap()),
      // Note: At the snap editor level, some parts of the application need to read things like
      //       the ageGate status of the parent edition in order to know whether the 'share' toggle
      //       should be displayed, etc. For this reason it's necessary to call setActiveEdition()
      //       before calling setActiveTopsnap().
      includeEdition && dispatch(setActiveEdition(editionId)),
      dispatch(setActiveTopsnap(topsnapId)),
      dispatch(
        updateActiveComponentIfNeeded({
          storyId: editionId,
          newSnapId: attachmentId || topsnapId,
          wholeSnapId: topsnapId,
        })
      ),
      isSingleAssetEditor && dispatch(initSingleAssetSnap(editionId, topsnapId)),
    ]);
  };
  const loadSnapPromiseGenerator = () => {
    return Promise.all([
      dispatch(snapsActions.loadSnapIfRequired({ snapId: topsnapId })),
      editionId && dispatch(editionsActions.getEditionIfRequired({ editionId })),
    ]);
  };
  // We set the active topsnap immediately if we already have a (possible stale) topsnap
  // This will make some transitions smoother while we fetch the latest version
  const topsnap: Snap | undefined | null = snapsSelectors.getSnapById(getState())(topsnapId);
  const hasTopsnap = topsnap !== null;
  const hasSameTopsnap = hasTopsnap && get(editorSelectors.getActiveTopsnap(getState()), 'id', null) === topsnapId;
  return Promise.resolve()
    .then(() => (hasTopsnap ? setActiveSnapPromiseGenerator() : null))
    .then(() => dispatch(loadSnapAssetInfo(topsnap)))
    .then(() => (hasSameTopsnap ? null : loadSnapPromiseGenerator()))
    .then(() => (hasTopsnap ? null : setActiveSnapPromiseGenerator()));
};
export const showNewTilePlaceholder = (placeholderComponentId?: string) => (dispatch: Dispatch) => {
  dispatch(setActiveComponentId(placeholderComponentId || componentsSelectors.TILE_PLACEHOLDER_COMPONENT_ID));
};
export const showNewInteractionPlaceholder = () => (dispatch: Dispatch) => {
  dispatch(setEditorConfigProperties({ showNewInteractionPlaceholder: true }));
  dispatch(setActiveComponentId(componentsSelectors.SNAP_PLACEHOLDER_COMPONENT_ID));
};
export const hideNewInteractionPlaceholder = () => (dispatch: Dispatch): Promise<unknown> => {
  dispatch(setEditorConfigProperties({ showNewInteractionPlaceholder: false }));
  return Promise.resolve();
};

export function addBottomSnapAndMakeActive({
  topsnapId,
  type,
  snapProperties = {},
  interactionRouteParams,
}: {
  topsnapId: SnapId;
  type: SnapType;
  snapProperties: any;
  interactionRouteParams: {};
}) {
  assertSnapId(topsnapId);
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> =>
    Promise.resolve()
      .then(() => dispatch(snapsActions.markSnapSaveAsPending(topsnapId)))
      .then(() => dispatch(hideNewInteractionPlaceholder()))
      .then(() => dispatch(snapsActions.addInteractionAndSave({ snapId: topsnapId }, type, snapProperties)))
      .then(attachmentId => {
        if (editorSelectors.getActiveWholeSnapId(getState()) === topsnapId) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
          dispatch(setActiveComponentId(buildComponentIdForSnapId(attachmentId)));
          if (interactionRouteParams) {
            dispatch(
              routerActions.goToAttachment(merge(interactionRouteParams, { attachmentId, attachmentType: type }))
            );
          }
        }
        return Promise.resolve();
      });
}

export function removeBottomSnapAndMakeTopsnapActive({
  topsnapId,
  bottomSnapId,
  interactionRouteParams,
}: {
  topsnapId: SnapId;
  bottomSnapId: SnapId;
  interactionRouteParams: OpenSnapEditorType;
}) {
  assertSnapId(topsnapId);
  assertSnapId(bottomSnapId);
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> =>
    Promise.resolve()
      .then(() => dispatch(unloadBottomSnap()))
      .then(() => dispatch(snapsActions.deleteInteraction({ snapId: topsnapId }, bottomSnapId)))
      .then(() => {
        if (editorSelectors.getActiveWholeSnapId(getState()) === topsnapId) {
          const storyId = editorSelectors.getActiveEditionId(getState());
          const isSingleAssetEditor = storyId ? shouldUseSingleAssetEditor(getState())(storyId) : false;
          if (isSingleAssetEditor) {
            dispatch(onTabChangedHandler(storyId, ConfigTab.SNAP));
          } else {
            dispatch(setActiveComponentId(buildComponentIdForSnapId(topsnapId)));
          }
          if (interactionRouteParams) {
            dispatch(publisherStoryEditorModeActions.openSnapEditor(interactionRouteParams));
          }
        }
        return Promise.resolve();
      });
}

export const saveCurrentVerticalCropTile = (
  tileContainer: TileContainer,
  originalTile: Tile,
  originalPurpose?: UploadPurpose
) => (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
  const tile = originalTile;
  const tileId = getTileId(tile);
  const pendingTile = editorSelectors.getPendingTileById(getState())(tileId);
  const tileUri = pendingTile && pendingTile.generatedBlobURI;
  const { snapId, editionId, segmentId } = tileContainer;
  // this should be retrieved as close to the action dispatching as possible,
  //  after a we've awaited for promises, nobody can guarantee the active publisher hasn't changed
  const activePublisherId = publishersSelectors.getActivePublisherId(getState());
  const relatedSnapId = snapId;
  if (!tileUri || !relatedSnapId) {
    return Promise.resolve();
  }
  const croppedImageObject = {};
  let componentId = '';
  if (relatedSnapId) {
    componentId = buildComponentIdForSnapId(relatedSnapId);
  }
  let purpose = originalPurpose;
  // If the purpose is already set, then keep it as it is.
  if (!purpose) {
    purpose = UploadPurpose.TILE_CROP_IMAGE;
  }
  return fetch(tileUri)
    .then(response => response.blob())
    .then(blob =>
      dispatch(
        mediaActions.uploadMediaAndFinalize({
          media: blob,
          fileType: FileType.IMAGE,
          params: {
            purpose,
            componentId,
            entityId: tileId,
            editionId,
            segmentId,
            snapId: relatedSnapId,
          },
        })
      )
    )
    .then(result => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(result).is.object();
      assertAssetId((result as any).assetId);
      (croppedImageObject as any).croppedImageAssetId = (result as any).assetId;
      return dispatch(setEditorConfigTileProperties(tileId, croppedImageObject));
    })
    .then(() => {
      (croppedImageObject as any).baseHorizontalCropPosition = get(pendingTile, 'baseHorizontalCropPosition');
      (croppedImageObject as any).baseVerticalCropPosition = get(pendingTile, 'baseVerticalCropPosition');
      // reset the Horizontal and Collapse crop to center
      const mergedTile = merge({}, tile, croppedImageObject, tileCropUtils.ensureBaseCropPositionsAreCentered());
      return dispatch(setTileCroppingAndSave(tileContainer, mergedTile, activePublisherId));
    });
};

export function setTileCroppingAndSave(
  tileContainer: TileContainer,
  properties: TileShape,
  publisherId?: PublisherID | null
): (b: Dispatch, a: GetState) => Promise<ReduxAction> {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TileShape' is not assignable to ... Remove this comment to see the full error message
  const tileId = getTileId(properties);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileId).is.string();
  return (dispatch: Dispatch, getState: GetState): Promise<ReduxAction> => {
    // Fill in missing crops (V2 from V1 crops, V1 from V2 crops, etc)
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TileShape' is not assignable to ... Remove this comment to see the full error message
    let propertiesCopy: TileShape = updateMissingCrops(properties);
    const primaryColor = publishersSelectors.getActivePublisherPrimaryColor(getState());
    // set the default tile logo automatically if not set already IF tile logos are available
    let tileLogos = [];
    if (publisherId) {
      tileLogos = tilesSelectors.getTileLogosByPublisherId(getState())(publisherId);
      // no tile logo or overlay color and there are tile logos available
      if ((!propertiesCopy.logoImageAssetId || !propertiesCopy.logoReadStateOverlayColor) && tileLogos.length > 0) {
        propertiesCopy = tileCropUtils.ensureTileLogoIsSet(propertiesCopy, tileLogos[0], primaryColor);
      }
    }
    return dispatch(tilesActions.updateTileAndSave(tileContainer, tileId, propertiesCopy)).then(() =>
      dispatch(removeEditorConfigTileProperties(tileId, CROP_PROPERTIES))
    );
  };
}

function updateMissingCrops(properties: Tile) {
  // eslint-disable-next-line prefer-object-spread
  const propertiesCopy = Object.assign({}, properties);
  if ('imageCropPositions' in properties) {
    propertiesCopy.imageCropPositions = tileCropUtils.positionMissingCrops(properties.imageCropPositions || {});
  }
  return propertiesCopy;
}

// NOTE: only dispatch directly from UI!!,
//  - do not chain after another promise, as the active edition/snap might have changed since the chain started
export function updateTileAndSave(tileId: TileID, tileProperties: TileShape) {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const snapId: SnapId | undefined | null = editorSelectors.getActiveWholeSnapId(getState());
    const segmentId: SegmentReduxID | undefined | null = editorSelectors.getActiveSegmentReduxId(getState());
    const tileContainer: TileContainer = { snapId, segmentId };
    return dispatch(tilesActions.updateTileAndSave(tileContainer, tileId, tileProperties));
  };
}

export function updateSnapEditorState(snapId: SnapId, properties: any) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(editorStateActions.updateEditorState(ComponentScope.SNAP, snapId, properties));
    return Promise.resolve();
  };
}

export function updateTileEditorState(tileId: TileID, tileEditorStateProperties: Partial<TileEditorState>) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(editorStateActions.updateEditorState(ComponentScope.TILE, tileId, tileEditorStateProperties));
    return Promise.resolve();
  };
}

export function updateShowEditorState(showId: ShowID, tileEditorStateProperties: Partial<ShowsEditorStateUpdeep>) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(editorStateActions.updateEditorState(ComponentScope.SHOW, showId, tileEditorStateProperties));
    return Promise.resolve();
  };
}

export function discardTileState(tileId: TileID) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(editorStateActions.discardData(ComponentScope.TILE, tileId));
    return Promise.resolve();
  };
}

export function updateSingleAssetEditorState(
  storyId: EditionID,
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '$DeepShape'.
  singleAssetEditorStateProperties: $DeepShape<SingleAssetStoryEditorState>
) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(
      editorStateActions.updateEditorState(
        ComponentScope.SINGLE_ASSET_STORY_EDITOR,
        storyId,
        singleAssetEditorStateProperties
      )
    );
    return Promise.resolve();
  };
}

export function discardSingleAssetEditorState(storyId: EditionID) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(editorStateActions.discardData(ComponentScope.SINGLE_ASSET_STORY_EDITOR, storyId));
    return Promise.resolve();
  };
}

export function addNewTile() {
  return (dispatch: Dispatch) => {
    dispatch(showNewTilePlaceholder());
  };
}

export function activateTileComponent(componentId?: string | null, placeholderComponentId?: string) {
  return (dispatch: Dispatch) => {
    if (!componentId) {
      dispatch(showNewTilePlaceholder(placeholderComponentId));
    } else {
      dispatch(setActiveComponentId(componentId));
    }
  };
}

export function activateFirstTileByStoryId(storyId: EditionID) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const firstTile: Tile | undefined | null = publisherToolsSelectors.getPresentationalTileForEdition(getState())(
      storyId
    );
    const edition: Edition | undefined | null = editionsSelectors.getEditionById(getState())(storyId);
    invariant(edition, 'Active Edition must not be null');
    invariant(firstTile, 'Tile must not be null');
    const firstSnapId = edition.snapIds[0];
    const tileComponentId = buildComponentIdForTileId(firstTile.id);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId | undefined' is not assig... Remove this comment to see the full error message
    const snapComponentId = buildComponentIdForSnapId(firstSnapId);
    await dispatch(setActiveEdition(edition.id));
    await dispatch(setActiveTopsnap(firstSnapId));
    await dispatch(
      publisherStoryEditorModeActions.openSnapEditor({
        publisherId: edition.publisherId,
        editionId: edition.id,
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'SnapId | undefined' is not assignable to typ... Remove this comment to see the full error message
        snapId: firstSnapId,
      })
    );
    await dispatch(setActiveComponentId(snapComponentId));
    await dispatch(activateTileComponent(tileComponentId));
  };
}

export function editTileById(componentId?: string | null, placeholderComponentId?: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    const activateTile = () => dispatch(activateTileComponent(componentId, placeholderComponentId));
    const activeComponent = componentsSelectors.getActiveComponent(getState());
    const snap = get(activeComponent, 'snap', null);
    const edition = editorSelectors.getActiveEdition(getState());
    const isSingleAssetEditor = shouldUseSingleAssetEditor(getState())(edition?.id);
    // if coming from Attachment tab and there is an attachment, then (snap && snapEntityHelpers.isBottomSnap(snap)) is true since the snap here is the attachment
    // We check if isSingleAssetEditor to prevent showing the non-SAS Tile UI when changing Tile from Attachment tab
    if (!isSingleAssetEditor && snap && snapEntityHelpers.isBottomSnap(snap)) {
      const topsnapId = editorSelectors.getActiveWholeSnapId(getState());
      const activePublisherDetails = publishersSelectors.getActivePublisherDetails(getState());
      invariant(edition, 'Active Edition must not be null');
      invariant(topsnapId, 'Topsnap must not be null');
      const locationTracker = () =>
        dispatch(
          publisherStoryEditorModeActions.openSnapEditor({
            publisherId: activePublisherDetails?.id,
            editionId: edition.id,
            snapId: topsnapId,
          })
        );
      dispatch(routerActions.trackLocationChange(locationTracker)).then(activateTile, (err: string) => {
        if (err !== routerActions.LOCATION_CHANGE_REJECTED) {
          log.error('Unknown error while trying to activate the Tile:', err);
          return Promise.reject(err);
        }
        // Occurs when the user tries to navigate away from a bottom snap that has modifications and cancels it
        log.error('Error while trying to change the location:', err);
        return null;
      });
    } else {
      activateTile();
    }
  };
}

export function deleteTileById(tileId: TileID) {
  return (dispatch: Dispatch, getState: GetState) => {
    const edition = editorSelectors.getActiveEdition(getState());
    invariant(edition, 'Active Edition must not be null');
    const snapId = editorSelectors.getActiveWholeSnapId(getState());
    const segmentId = editorSelectors.getActiveSegmentReduxId(getState());
    const tileContainer: TileContainer = { snapId, segmentId };
    const componentsOfTypeTile = componentsSelectors.getComponentsOfTypeTile(getState());
    const tileComponentId = buildComponentIdForTileId(tileId);
    const activeComponent = componentsSelectors.getActiveComponent(getState());
    // If tile to be deleted is the one selected, select another one. This must be done
    // before the tile is deleted or the component will be invalidated and the UI will
    // briefly change due to having no active component
    if (activeComponent && tileComponentId === (activeComponent as any).componentId) {
      const filteredTileComponents = componentsOfTypeTile.filter(
        (component: any) => component.componentId !== tileComponentId
      );
      // Activate first tile or tile placeholder
      const firstTile = filteredTileComponents.length > 0 ? filteredTileComponents[0]?.tile : undefined;
      const componentToSelect = firstTile
        ? buildComponentIdForTileId(getTileId(firstTile))
        : componentsSelectors.TILE_PLACEHOLDER_COMPONENT_ID;
      dispatch(setActiveComponentId(componentToSelect));
    }
    return dispatch(tilesActions.deleteTileAndSave(tileContainer, tileId));
  };
}

export function duplicateTile(tile: Tile) {
  return (dispatch: Dispatch, getState: GetState) => {
    const edition = editorSelectors.getActiveEdition(getState());
    invariant(edition, 'Active Edition must not be null');
    const snapId = editorSelectors.getActiveWholeSnapId(getState());
    invariant(snapId, 'Snap id must not be null');
    const snap: TopSnap | undefined | null = snapsSelectors.getSnapById(getState())(snapId) as any;
    invariant(snap, 'Snap must not be null');
    const editionId = editorSelectors.getActiveEditionId(getState());
    const segmentId = editorSelectors.getActiveSegmentReduxId(getState());
    const isDuplicate = true;
    return snap
      ? dispatch(
          tilesActions.createTileFromExistingSource({
            tile,
            editionId,
            snap,
            snapId,
            segmentId,
            isDuplicate,
          })
        )
      : null;
  };
}

function createEmptyCanvas(width: number, height: number) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

export function disableLogoOnTile(
  tileContainer: TileContainer,
  tile: Tile,
  publisherId: PublisherID,
  editionId: EditionID
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const purpose = UploadPurpose.TILE_LOGO;
    const outputDimensions = MediaOutputDimensions[purpose];
    if (!outputDimensions) {
      throw new Error(`No media output dimensions found for ${purpose}`);
    }
    const canvas = createEmptyCanvas(outputDimensions.width, outputDimensions.height);
    const params = {
      purpose,
      publisherId,
      editionId,
      hidden: true,
    };
    canvas.toBlob(blob => {
      dispatch(mediaActions.uploadMedia(blob, FileType.IMAGE, params)).then(result => {
        const logoImageAssetId = (result as any).payload.id;
        dispatch(
          exports.setTileCroppingAndSave(
            tileContainer,
            { ...tile, logoImageAssetId, isLogoEnabled: false },
            publisherId
          )
        );
      });
    }, 'image/png');
  };
}

export function fetchEditionBuildStatusAndRefreshStaleBuilds({
  editionId,
  noBailout,
}: {
  editionId: EditionID;
  noBailout?: boolean;
}) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const result = await dispatch(buildStatusActions.fetchEditionBuildStatus({ editionId, noBailout }));
    const isReadOnly = editionsSelectors.editionIsReadOnly(getState())(editionId);
    if (!isReadOnly && result) {
      const editionBuildStatus = buildStatusSelectors.getEditionBuildStatusById(getState())(editionId);
      editionBuildStatus?.snapStatuses.forEach((snapId: any) => {
        const snapBuildStatus = buildStatusSelectors.getDiscoverSnapBuildStatus(getState())(snapId);
        if (snapBuildStatus?.status && snapBuildStatus.status === SnapProblem.BUILD_STALE) {
          dispatch(snapsActions.forceSnapRebuild({ snapId }));
        }
      });
    }
    return result;
  };
}

const setupTilePanel = (wholeSnapId: SnapId, storyId: EditionID) => (dispatch: Dispatch, getState: GetState) => {
  const tile = publisherToolsSelectors.getPresentationalTileForSnap(getState())(wholeSnapId, storyId);
  const firstTileComponentId = tile ? buildComponentIdForTileId(tile.id) : null;
  dispatch(editTileById(firstTileComponentId));
};

export function setActiveComponentBasedOnTab(storyId: EditionID, tab: ConfigTab) {
  return (dispatch: Dispatch, getState: GetState) => {
    const activeWholeSnapId = editorSelectors.getActiveWholeSnapId(getState());
    if (!activeWholeSnapId) {
      return;
    }
    const topsnapComponentId = buildComponentIdForSnapId(activeWholeSnapId);
    const activeBottomSnapId = get(editorSelectors.getActiveBottomsnap(getState()), 'id', null);
    switch (tab) {
      case ConfigTab.ADVERTS:
      case ConfigTab.SUBSCRIBE_SNAP:
      case ConfigTab.SNAP:
        dispatch(setActiveComponentId(topsnapComponentId));
        break;
      case ConfigTab.TILES:
        dispatch(setupTilePanel(activeWholeSnapId, storyId));
        break;
      case ConfigTab.ATTACH:
        if (activeBottomSnapId) {
          dispatch(clearUnreadAttachment());
          dispatch(setActiveComponentId(buildComponentIdForSnapId(activeBottomSnapId)));
        } else {
          dispatch(showNewInteractionPlaceholder());
        }
        break;
      case ConfigTab.SUBTITLES:
        dispatch(setActiveComponentId(componentsSelectors.SUBTITLES_COMPONENT_ID));
        break;
      case ConfigTab.VIDEO:
        dispatch(showNewInteractionPlaceholder());
        break;
      default:
        break;
    }
  };
}

export function onTabChangedHandler(storyId: EditionID, tab: ConfigTab) {
  return (dispatch: Dispatch, getState: GetState) => {
    const currentTab = getSingleAssetActiveTab(getState())(storyId);
    if (tab !== currentTab) {
      dispatch(
        updateSingleAssetEditorState(storyId, {
          activeConfigTab: tab,
        })
      );
      dispatch(setActiveComponentBasedOnTab(storyId, tab));
    }
  };
}

// The function is called every time a snap changes in SAS
export function initSingleAssetSnap(storyId: EditionID, snapId: SnapId) {
  return (dispatch: Dispatch, getState: GetState) => {
    const snap = editorSelectors.getActiveTopsnap(getState());
    const isSubscribeSnap = snap ? snapEntityHelpers.isSubscribeSnap(snap) : false;
    // If the user has landed on subscribe snap, we need to update to the correct tab, pause video and hide ad
    // Subscribe snaps are outside of the timeline, hence no need to update pendingCurrentTime
    if (isSubscribeSnap) {
      dispatch(
        updateSingleAssetEditorState(storyId, {
          videoPlayer: {
            isPlaying: false,
          },
          isShowingAd: false,
        })
      );
      return dispatch(onTabChangedHandler(storyId, ConfigTab.SUBSCRIBE_SNAP));
    }
    const timelineSnap: TimelineSnap | undefined | null = getActiveStoryTimelineSnapBySnapId(getState())(snapId);
    if (!timelineSnap) {
      return Promise.resolve();
    }
    // If user was previously on subscribe snap, we need to update the tab
    const activeTab = getSingleAssetActiveTab(getState())(storyId);
    if (activeTab === ConfigTab.SUBSCRIBE_SNAP) {
      dispatch(onTabChangedHandler(storyId, getDefaultActiveTab(getState())(storyId)));
    }
    // If we are entering the story and the activeComponent is an attachment (e.g. importing video from media library), switch to attach tab
    const activeComponentType = componentsSelectors.getActiveComponentType(getState());
    if (activeComponentType === RichSnapActiveComponentType.ATTACHMENT && activeTab !== ConfigTab.ATTACH) {
      dispatch(onTabChangedHandler(storyId, ConfigTab.ATTACH));
    }
    const pendingCurrentTime = getSingleAssetPlayerPendingCurrentTime(getState())(storyId);
    const playheadTick = getSingleAssetPlayerCurrentTime(getState())(storyId);
    // If pending time already within snap time frame do nothing
    // If snap changed due to video playing, do not force playhead to change
    if (
      (pendingCurrentTime >= timelineSnap.startTimeMs &&
        pendingCurrentTime < timelineSnap.startTimeMs + timelineSnap.durationMs) ||
      (playheadTick >= timelineSnap.startTimeMs && playheadTick < timelineSnap.startTimeMs + timelineSnap.durationMs)
    ) {
      return Promise.resolve();
    }
    return dispatch(
      updateSingleAssetEditorState(storyId, {
        videoPlayer: {
          pendingCurrentTime: timelineSnap.startTimeMs,
        },
      })
    );
  };
}
