import { discoverSnapSchema, richSnapSchema } from '../schema/snapsSchema';

import { EndpointName } from 'config/constants';
import * as scsAPI from 'utils/apis/scsAPI';
import { functionRef } from 'utils/functionUtils';

import { registerSnapEndpoint } from './snapEndpointRegistry';

export default function initSnapEndpoints() {
  registerSnapEndpoint({
    name: EndpointName.RICH_SNAP,
    urlBuilder: functionRef(scsAPI.richSnap.snap, 'existing'),
    urlBuilderMultiple: functionRef(scsAPI.richSnap.snap, 'multiple'),
    snapSchema: richSnapSchema,
    isDefault: true,
    supportsPartialUpdates: true,
  });

  registerSnapEndpoint({
    name: EndpointName.DISCOVER,
    urlBuilder: functionRef(scsAPI.discover.snap, 'existing'),
    urlBuilderMultiple: functionRef(scsAPI.discover.snap, 'multiple'),
    snapSchema: discoverSnapSchema,
  });
}
