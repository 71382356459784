import { Schema } from 'normalizr';

import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import { createChainedFn } from 'utils/functionUtils';

export const subtitleTracksSchema = new Schema('subtitles', { idAttribute: 'id' });

const renameSubtitle = (subtitle: any) => ({
  ...subtitle,
  assetId: subtitle.id,
  source: subtitle.src,
});

const updateAsset = (asset: any) => {
  if (asset && asset.subtitles) {
    asset.subtitles = asset.subtitles.map((subtitle: any) => renameSubtitle(subtitle)); // eslint-disable-line
  }
  return asset;
};

export const convertToCMSSubtitles = createChainedFn([optimisticJsonFinalizer, body => updateAsset(body)]);
