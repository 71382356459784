import { Operation, ServerError, ServerParseError } from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { GraphQLError } from 'graphql';
import log from 'loglevel';

import { incrementCounter } from 'utils/grapheneUtils';

export const logErrors = ({
  graphQLErrors,
  networkError,
  operation,
}: {
  graphQLErrors?: ReadonlyArray<GraphQLError>;
  networkError?: Error | ServerError | ServerParseError | NetworkError;
  operation?: Operation;
}) => {
  const operationName = operation?.operationName ?? 'unknown';
  const context = operation?.getContext() ?? {};

  if (graphQLErrors) {
    graphQLErrors.forEach(graphQLError => {
      const statusCode = context.response?.status || 0;
      log.error('GraphQLError operation:', operationName, 'status:', statusCode, 'error:', graphQLError);
      incrementCounter('apollo_graphql_error', {
        operationName,
        statusCode: `${statusCode}`,
      });
    });
  }

  if (networkError) {
    const statusCode =
      (networkError as ServerError | ServerParseError).statusCode ?? (context.response?.statusCode || 0);

    log.error('GraphQLNetworkError operation:', operationName, 'status:', statusCode, 'error:', networkError);

    incrementCounter('apollo_network_error', {
      operationName,
      statusCode: `${statusCode}`,
    });
  }
};

export const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  logErrors({ graphQLErrors, networkError, operation });
});
