import _ from 'lodash';
import React from 'react';

const compareReactElements = (left: any, right: any) => {
  // If comparing react elements we must avoid comparing the fibers because it will lead to infinite loop
  let reactElements = 0;
  if (React.isValidElement(left) && (left as any)._owner) {
    reactElements += 1;
    left = _.omit(left, ['_owner']); // eslint-disable-line no-param-reassign
  }
  if (React.isValidElement(right) && (right as any)._owner) {
    reactElements += 1;
    right = _.omit(right, ['_owner']); // eslint-disable-line no-param-reassign
  }
  switch (reactElements) {
    case 0:
      return undefined;
    case 1:
      return false;
    case 2:
      return _.isEqualWith(left, right, compareReactElements);
    default:
      return false;
  }
};
export const updateIfPropsAndStateChanged = <P extends {}, S extends {}>(
  props: P,
  state: S | undefined | null,
  nextProps: P,
  nextState?: S
) => {
  const propsNoFunc = props ? _.omitBy(props, _.isFunction) : props;
  const stateNoFunc = state ? _.omitBy(state, _.isFunction) : state;
  const nextPropsNoFunc = nextProps ? _.omitBy(nextProps, _.isFunction) : nextProps;
  const nextStateNoFunc = nextState ? _.omitBy(nextState, _.isFunction) : nextState;
  return (
    (!!nextProps && !_.isEqualWith(propsNoFunc, nextPropsNoFunc, compareReactElements)) ||
    (!!nextState && !_.isEqualWith(stateNoFunc, nextStateNoFunc, compareReactElements))
  );
};
export const validationFunctionByElementType = (type: () => unknown) => (
  props: {
    [key: string]: any;
  },
  propName: string,
  componentName: string
) => {
  if (props[propName].type !== type) {
    return new Error(
      `Expected ${propName} in ${componentName} to have type ${type.name}, got ${typeof props[propName]}`
    );
  }
  return null;
};
