import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import type { CallAction, MetaType } from 'state/apiMiddleware/actions/apiMiddlewareActions';

import { FeedApiType, FeedType } from 'src/types/rssFeed';
import { feedIngestion } from 'utils/apis/feedIngestion';

import type { FeedID } from 'types/feeds';
import type { HNCategoryID } from 'types/hnCategories';
import type { BusinessProfileID } from 'types/publishers';

export const CREATE_FEED = 'feeds/CREATE_FEED';
export const UPDATE_FEED = 'feeds/UPDATE_FEED';
export const DELETE_FEED = 'feeds/DELETE_FEED';
export const EXECUTE_FEED = 'feeds/EXECUTE_FEED';
export const GET_FEEDS_BY_OWNER_ID = 'feeds/GET_FEEDS_BY_OWNER_ID';
export const EXECUTE_ALL_FEEDS_BY_OWNER_ID = 'feeds/EXECUTE_ALL_FEEDS_BY_OWNER_ID';

export function createFeed(
  businessProfileId: BusinessProfileID,
  url: string,
  name: string,
  categoryId: HNCategoryID,
  templateId: string,
  autoPublish: boolean,
  approvalDelay: number,
  feedType: FeedType,
  apiType: FeedApiType,
  useContentFromFeed: boolean,
  useMediaFromFeed: boolean,
  useMediaHeadlineFromFeed: boolean,
  useUrlFromFeed: boolean
): CallAction<{
  type: string;
  meta?: MetaType;
}> {
  return createCallAction(
    { type: CREATE_FEED },
    {
      method: 'POST',
      endpoint: feedIngestion.createFeed(),
      body: {
        ownerId: businessProfileId,
        url,
        name,
        categoryId,
        templateId,
        autoPublish,
        approvalDelay,
        feedType,
        apiType,
        useContentFromFeed,
        useMediaFromFeed,
        useMediaHeadlineFromFeed,
        useUrlFromFeed,
      },
    }
  );
}

export function updateFeed(
  feedId: FeedID,
  name?: string | null,
  categoryId?: HNCategoryID | null,
  templateId?: string | null,
  autoPublish?: boolean | null,
  approvalDelay?: number | null,
  enabled?: boolean | null,
  useContentFromFeed?: boolean | null,
  useMediaFromFeed?: boolean | null,
  useMediaHeadlineFromFeed?: boolean | null,
  useUrlFromFeed?: boolean | null
): CallAction<{
  type: string;
  meta?: MetaType;
}> {
  return createCallAction(
    { type: UPDATE_FEED },
    {
      method: 'PUT',
      endpoint: feedIngestion.updateFeed({ feedId }),
      body: {
        name,
        categoryId,
        templateId,
        autoPublish,
        approvalDelay,
        enabled,
        useContentFromFeed,
        useMediaFromFeed,
        useMediaHeadlineFromFeed,
        useUrlFromFeed,
      },
    }
  );
}

export function deleteFeed(
  feedId: FeedID
): CallAction<{
  type: string;
  meta?: MetaType;
}> {
  return createCallAction(
    {
      type: DELETE_FEED,
      feedId,
    },
    {
      method: 'DELETE',
      endpoint: feedIngestion.deleteFeed({ feedId }),
    }
  );
}

export function getFeedsByOwnerId(
  businessProfileId: BusinessProfileID
): CallAction<{
  type: string;
  meta?: MetaType;
}> {
  return createCallAction(
    {
      type: GET_FEEDS_BY_OWNER_ID,
      params: {
        ownerId: businessProfileId,
      },
    },
    {
      endpoint: feedIngestion.getFeedsByOwnerId({ ownerId: businessProfileId }),
    }
  );
}

export function executeAllFeedsByOwnerId(
  businessProfileId: BusinessProfileID
): CallAction<{
  type: string;
  meta?: MetaType;
}> {
  return createCallAction(
    { type: EXECUTE_ALL_FEEDS_BY_OWNER_ID },
    {
      method: 'POST',
      endpoint: feedIngestion.executeAllFeedsByOwnerId({ ownerId: businessProfileId }),
    }
  );
}
