import attachmentsConfig from '@snapchat/web-attachments/lib/attachments.json';

import { ENV } from 'config/constants';
import { assertArg, assertState } from 'utils/assertionUtils';

const DEFAULT_TARGET = 'main';

export function getClientAttachments(attachmentType: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(attachmentType).is.string();

  const attachments = (attachmentsConfig as any)[DEFAULT_TARGET][attachmentType];
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(attachments).is.object();

  const envAttachments = attachments[ENV];
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(envAttachments).is.array();

  envAttachments.forEach((attachment: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(attachment.id).is.numeric();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(attachment.type).is.string();
  });

  return envAttachments;
}
