import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

import { State } from 'src/types/rootState';
import { assertArg } from 'utils/assertionUtils';

export function getActions(state: State) {
  return state.actions || null;
}

export const getLastActionIdBeforeStoreReset = createKeySelector(getActions, 'lastActionIdBeforeStoreReset', 0);

export const hasActionExpired = (action: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(action).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(action.actionId).is.number();

  return s(
    getLastActionIdBeforeStoreReset,
    lastActionIdBeforeStoreReset => lastActionIdBeforeStoreReset > action.actionId
  );
};
