import type { DocumentNode } from '@apollo/client';
import { OperationVariables } from '@apollo/client/core/types';
import type { QueryFunctionOptions, QueryResult } from '@apollo/client/react/types/types';
import hoistStatics from 'hoist-non-react-statics';
import is from 'is_js';
import { mapValues, get } from 'lodash';
import log from 'loglevel';
import React from 'react';
import type { ComponentType } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux'; // eslint-disable-line no-restricted-imports

import { useProxyQuery } from 'utils/apis/graphQLUtils';
import { assertArg } from 'utils/assertionUtils';
import { actionCreatorErrorHandler, dispatchErrorHandler } from 'utils/errors/errorHandlers';
import { isPromise } from 'utils/promiseUtils';

const wrapDispatchInErrorHandler = (action: any) => {
  return (dispatch: any, ...dispatchArgs: any[]) => {
    try {
      const actionReturn = action(dispatch, ...dispatchArgs);
      if (!isPromise(actionReturn)) {
        return actionReturn;
      }
      return actionReturn.catch(dispatchErrorHandler(dispatch));
    } catch (err) {
      return dispatchErrorHandler(dispatch)(err);
    }
  };
};

const wrapInErrorHandler = (mapDispatchToProps: any) => {
  return mapValues(mapDispatchToProps, actionCreator => {
    // Wrapping the action creator so we can catch errors thrown by action creators
    return (...args: any[]) => {
      try {
        const action = actionCreator(...args);
        if (!is.function(action)) {
          return action;
        }

        // Wrapping the dispatch return so we can catch errors thrown when dispatching actions
        return wrapDispatchInErrorHandler(action);
      } catch (err) {
        // Leveraging redux-thunk to return a rejected promise
        return (dispatch: any) => actionCreatorErrorHandler(dispatch)(err);
      }
    };
  });
};

export const intlConnect = (
  mapStateToProps: any,
  mapDispatchToProps: any,
  options?: {
    s?: boolean;
  }
) => (component: any): any => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(component).is.not.undefined();

  if (is.function(mapDispatchToProps)) {
    log.warn(
      `mapDispatchToProps for component ${component.displayName} is a function, error handler will not be installed`
    );
  } else if (is.object(mapDispatchToProps)) {
    mapDispatchToProps = wrapInErrorHandler(mapDispatchToProps); // eslint-disable-line no-param-reassign
  }
  const forwardRef = get(options, 'forwardRef');

  return hoistStatics(
    injectIntl(
      connect(mapStateToProps, mapDispatchToProps, null, { forwardRef })(component),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ forwardRef: any; }' is not ass... Remove this comment to see the full error message
      { forwardRef }
    ),
    component
  );
};

type GraphQLInnerComponent<TProps, TData, TVariables extends OperationVariables> = ComponentType<
  TProps & {
    queryResult: QueryResult<TData, TVariables>;
  }
>;

type GraphQLConnector<TProps, TData, TVariables extends OperationVariables> = (
  Component: GraphQLInnerComponent<TProps, TData, TVariables>
) => ComponentType<TProps>;

type QueryHookOptions<TData, TVariables extends OperationVariables> = QueryFunctionOptions<TData, TVariables> & {
  query?: DocumentNode;
};

export type WithGraphQL<TProps, TData, TVariables extends OperationVariables> = TProps & {
  queryResult: QueryResult<TData, TVariables>;
};

// TODO: look into TypedDocumentNode with https://github.com/dotansimha/graphql-typed-document-node
export function graphqlConnect<TProps, TData, TVariables extends OperationVariables>(
  query: (a: TProps) => QueryHookOptions<TData, TVariables>
): GraphQLConnector<TProps, TData, TVariables> {
  return (Component: GraphQLInnerComponent<TProps, TData, TVariables>) => {
    return (props: TProps) => {
      const opts = query(props);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'DocumentNode | undefined' is not... Remove this comment to see the full error message
      const queryResult = useProxyQuery(opts.query, opts);
      return <Component {...props} queryResult={queryResult} />;
    };
  };
}
