import { assertArg } from 'utils/assertionUtils';

declare var __TEST__: boolean;

/**
 * Takes an input (any value) and an array of functions.
 *
 * Calls each function in turn providing the result of the previous function, and
 * returns the final result.
 */
export function applyFnChain(input: unknown, chain: Array<(a: unknown) => unknown> = []) {
  return chain.reduce((result, nextFn) => nextFn(result), input);
}

export function createChainedFn(chain: Array<(a: unknown) => unknown>) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(chain).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(chain.length).is.above(0);

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  chain.forEach(chainFn => assertArg(chainFn).is.function());

  return (input: unknown) => applyFnChain(input, chain);
}

export function limitCallOnce(fn: () => void) {
  let called = false;
  return () => {
    if (called) {
      return;
    }
    called = true;
    fn();
  };
}

// Utility method to make it easier to stub functions provided to reducer/action/selector factories
export const functionRef = (parentObject: any, methodName: string) => {
  if (__TEST__) {
    return (...args: Array<any>) => parentObject[methodName](...args);
  }

  return parentObject[methodName];
};
