import { flatten, get, isEmpty } from 'lodash';
import { createSelector as s } from 'reselect';

import { getAssetById } from 'state/asset/selectors/assetSelectors';
import { createKeySelector, reselectById } from 'state/common/selectorFactories';
import { getActiveComponentId } from 'state/editor/selectors/componentsSelectors';
import {
  getActiveTopsnap,
  getSingleAssetEditorState,
  getActiveWholeSnapId,
  getActiveEditionId,
} from 'state/editor/selectors/editorSelectors';
import {
  getEditionSnaps,
  getTranscodedMediaIdForSingleSnapStory,
  getTranscodedMediaIdForStory,
  shouldUseSingleSnapBuilder,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getPresentationalTileForSnap } from 'state/publisherTools/selectors/publisherToolsSelectors';
import {
  ShotAdSlotsState,
  ShotAdSlotsById,
  ShotAdSlotsDetails,
  SingleAssetStoriesState,
} from 'state/singleAssetStory/singleAssetStoriesState';
import { getSubscribeSnap, getUnifiedSingleSnap, isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';
import { getSubtitlesByActiveLanguageAndVideoAssetId } from 'state/subtitles/selectors/subtitlesSelectors';
import { getTileById } from 'state/tiles/selectors/tilesSelectors';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'config/constants';
import { extractTileIdFromComponentId, isTileComponentId } from 'utils/componentUtils';
import { functionRef } from 'utils/functionUtils';
import { getVideoAssetPosterUrl, createAssetUrl } from 'utils/media/assetUtils';

import * as subtitlesEditorSelectors from 'views/subtitles/state/selectors/subtitlesEditorSelectors';

import { MimeType } from 'types/assets';
import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import { State } from 'types/rootState';
import { ConfigTab, VideoInfo } from 'types/singleAssetStoryEditor';
import type { AdShotSlot, ExtendedShot, TimelineSnap, Advert } from 'types/singleAssetStoryEditor';
import type { Shot, SingleAssetSnap, TopSnap } from 'types/snaps';

function getSingleAssetStories(state: State): SingleAssetStoriesState {
  return state.singleAssetStories || EMPTY_OBJECT;
}
const getShotAdSlots = createKeySelector<ShotAdSlotsState>(getSingleAssetStories, 'shotAdSlots', {
  byStoryId: {},
  lastUpdatedByStoryId: {},
  loadingByStoryId: {},
});
const getShotAdSlotsMap = createKeySelector<ShotAdSlotsById>(getShotAdSlots, 'byStoryId', EMPTY_OBJECT);
export const getDefaultActiveTab = (state: State) => (storyId: EditionID) => ConfigTab.SNAP;

export const getSingleAssetActiveTab = s(
  getSingleAssetEditorState,
  getDefaultActiveTab,
  (getEditorStateFn, getDefaultActiveTabFn) => (storyId: EditionID): ConfigTab => {
    return getEditorStateFn(storyId)?.activeConfigTab || getDefaultActiveTabFn(storyId);
  }
);
export const getSingleAssetIsShowingAd = s(
  getSingleAssetEditorState,
  getEditorStateFn => (storyId: EditionID): boolean => {
    return getEditorStateFn(storyId)?.isShowingAd || false;
  }
);
export const getSingleAssetIsInDebugMode = s(
  getSingleAssetEditorState,
  getEditorStateFn => (storyId: EditionID): boolean => {
    return getEditorStateFn(storyId)?.isInDebugMode || false;
  }
);
export const getSingleAssetIsEditingAds = s(
  getSingleAssetEditorState,
  getEditorStateFn => (storyId: EditionID): boolean => {
    return getEditorStateFn(storyId)?.isEditingAds || false;
  }
);
export const getSingleAssetPlayerState = s(getSingleAssetEditorState, getEditorStateFn => (storyId: EditionID) => {
  return getEditorStateFn(storyId)?.videoPlayer || EMPTY_OBJECT;
});
export const getSingleAssetPlayerCurrentTime = s(getSingleAssetPlayerState, playerStateFn => (storyId: EditionID) => {
  return playerStateFn(storyId)?.currentTime || 0;
});
export const getSingleAssetPlayerPendingCurrentTime = s(
  getSingleAssetPlayerState,
  playerStateFn => (storyId: EditionID) => {
    return playerStateFn(storyId)?.pendingCurrentTime || 0;
  }
);
export const getSingleAssetPlayerIsPlaying = s(getSingleAssetPlayerState, playerStateFn => (storyId: EditionID) => {
  return playerStateFn(storyId)?.isPlaying || false;
});
export const getSingleAssetPlayerDuration = s(getSingleAssetPlayerState, playerStateFn => (storyId: EditionID) => {
  return playerStateFn(storyId)?.totalDuration || 0;
});
export const getShotAdSlotsByStoryId = reselectById<AdShotSlot[], EditionID>(
  [],
  (state: State, editionId: EditionID) => getShotAdSlotsMap(state)[editionId],
  (adSlots: ShotAdSlotsDetails) => adSlots?.shotAdSlots || EMPTY_ARRAY
);
export const getVideoInfoForStory = s(
  getTranscodedMediaIdForStory,
  getAssetById,
  getSubtitlesByActiveLanguageAndVideoAssetId,
  functionRef(subtitlesEditorSelectors, 'getSubtitlesPreviewUrl'),
  (
    getTranscodedMediaIdForStoryFn,
    getAssetByIdFn,
    getSubtitlesByActiveLanguageAndVideoAssetIdFn,
    subtitlesPreviewUrlFn
  ) => (storyId: EditionID): VideoInfo => {
    const transcodedMediaId = getTranscodedMediaIdForStoryFn(storyId);
    if (!transcodedMediaId) {
      return EMPTY_OBJECT;
    }
    const asset = getAssetByIdFn(transcodedMediaId);
    if (!asset) {
      return EMPTY_OBJECT;
    }
    const src =
      asset.mimeType === MimeType.HLS
        ? asset.hlsManifest
        : createAssetUrl(transcodedMediaId, { forceSourceMedia: true });
    const mimeType = asset.mimeType !== MimeType.HLS ? MimeType.MP4 : MimeType.HLS;
    const subtitlesTrack = getSubtitlesByActiveLanguageAndVideoAssetIdFn(transcodedMediaId);
    // replace the source url with subtitles preview url
    const activeSubtitleTrack = subtitlesTrack && {
      ...subtitlesTrack,
      src: (subtitlesPreviewUrlFn as any)(transcodedMediaId),
    };
    // TODO: poster
    return {
      mimeType,
      src,
      transcodedMediaId,
      poster: getVideoAssetPosterUrl(transcodedMediaId) || '',
      activeSubtitleTrack,
    };
  }
);

// We don't need to wait for the shots to be populated if we use Single Snap Builder, since the whole story has only one Snap
// For that reason, we populate a single dummy shot with the duration equal to the duration of the snap media
export const getUnifiedSingleSnapWithSingleShot = reselectById<TopSnap[], EditionID>(
  EMPTY_ARRAY,
  (state: State, storyId: EditionID) => getEditionSnaps(state)(storyId),
  (state: State, storyId: EditionID) => getTranscodedMediaIdForSingleSnapStory(state)(storyId),
  (state: State) => getAssetById(state),
  (snaps: SingleAssetSnap[], singleSnapMediaId: any, getAssetByIdFn: any) => {
    const unifiedSnap = getUnifiedSingleSnap(snaps);
    if (unifiedSnap) {
      const singleSnapMedia = getAssetByIdFn(singleSnapMediaId);
      const singleSnapDuration = singleSnapMedia?.durationMillis || 0;
      return {
        ...unifiedSnap,
        ...{
          decorations: {
            discover: {
              shots: [
                {
                  id: '',
                  startTimeMs: 0,
                  durationMs: singleSnapDuration,
                  startFrame: 0,
                  adScore: null,
                },
              ],
            },
          },
        },
      };
    }
    return unifiedSnap || null;
  }
);

export const getSingleAssetSnaps = reselectById<TopSnap[], EditionID>(
  EMPTY_ARRAY,
  (state: State, storyId: EditionID) => getEditionSnaps(state)(storyId),
  (state: State, storyId: EditionID) => shouldUseSingleSnapBuilder(state)(storyId),
  (state: State, storyId: EditionID) => getUnifiedSingleSnapWithSingleShot(state)(storyId),
  (snaps: TopSnap[], isSingleSnapBuilder: boolean, singleSnapWithSingleShot: any) => {
    // If it is a Single Snap Story the snaps should include only:
    // [snapWithSingleShot, subscribeSnap]
    if (isSingleSnapBuilder && singleSnapWithSingleShot) {
      const subscriptionSnap = getSubscribeSnap(snaps);
      return [singleSnapWithSingleShot, ...(subscriptionSnap ? [subscriptionSnap] : [])];
    }
    return snaps.filter(snap => isSubscribeSnap(snap) || snap?.decorations?.discover?.shots?.length);
  }
);
export const getShotsForStory = reselectById<ExtendedShot[], EditionID>(
  EMPTY_ARRAY,
  (state: State, storyId: EditionID) => getSingleAssetSnaps(state)(storyId),
  (snaps: TopSnap[]) => {
    const shots = flatten(
      snaps.map(snap => {
        const snapShots = get(snap, ['decorations', 'discover', 'shots']) || [];
        return snapShots.map(shot => ({ ...shot, snapId: snap.id }));
      })
    );
    return shots.map((shot: Shot, index: number) => {
      return {
        ...shot,
        isFirst: index === 0,
        isLast: index === shots.length - 1,
        index,
      };
    });
  }
);
export const getTimelineSnaps = reselectById<TimelineSnap[], EditionID>(
  EMPTY_ARRAY,
  (state: State, storyId: EditionID) => getSingleAssetSnaps(state)(storyId),
  (snaps: SingleAssetSnap[]) =>
    snaps
      .filter(snap => !isSubscribeSnap(snap as TopSnap))
      .map((snap, i, { length }) => {
        const shots = snap.decorations.discover.shots || [];
        const startTimeMs = shots[0]?.startTimeMs || 0;
        const durationMs = shots.reduce((total, shot) => total + shot.durationMs, 0);
        return {
          snapId: snap.id,
          startTimeMs,
          durationMs,
          isFirst: i === 0,
          isLast: i === length - 1,
          index: i,
          shots,
          notes: snap.name,
          tags: snap.decorations?.discover?.tags,
          videoAssetId: snap.videoAssetId,
        };
      })
);
type TimelineSnapByIdMap = {
  [k in SnapId]: TimelineSnap;
};
const getTimelineSnapsById = reselectById<TimelineSnapByIdMap, EditionID>(
  EMPTY_OBJECT,
  (state: any, storyId: any) => getTimelineSnaps(state)(storyId),
  (timelineSnaps: TimelineSnap[]) => {
    return timelineSnaps.reduce((acc, timelineSnap) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[timelineSnap.snapId] = timelineSnap; // eslint-disable-line no-param-reassign
      return acc;
    }, {});
  }
);
export const getActiveStoryTimelineSnapBySnapId = reselectById<TimelineSnap | undefined | null, SnapId>(
  null,
  (state: any) => getActiveEditionId(state),
  (state: any) => getTimelineSnapsById(state),
  (state: any, snapId: any) => snapId,
  (activeStoryId: any, getTimelineSnapsByIdFn: any, snapId: any) => getTimelineSnapsByIdFn(activeStoryId)?.[snapId]
);
export const getActiveTimelineSnap = reselectById<TimelineSnap | undefined | null, EditionID>(
  null,
  (state: any, storyId: any) => getTimelineSnapsById(state)(storyId),
  getActiveWholeSnapId,
  (timelineSnapsByIdMap: TimelineSnapByIdMap, snapId: SnapId) => timelineSnapsByIdMap && timelineSnapsByIdMap[snapId]
);
export const getTimelineAds = reselectById<Advert[], EditionID>(
  EMPTY_ARRAY,
  (state: State, storyId: EditionID) => getSingleAssetSnaps(state)(storyId),
  (state: any, storyId: any) => getShotAdSlotsByStoryId(state)(storyId),
  (snaps: TopSnap[], ads: AdShotSlot[]) => {
    const shots = flatten(
      snaps.map(snap => {
        return get(snap, ['decorations', 'discover', 'shots']) || [];
      })
    );
    return ads
      .filter(ad => !isEmpty(shots[ad.shotIndex - 1]))
      .map(ad => ({
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        startTimeMs: shots[ad.shotIndex - 1].durationMs + shots[ad.shotIndex - 1].startTimeMs,
        shotIndex: ad.shotIndex,
      }));
  }
);
export const getActiveTileOrPresentationalTile = s(
  getActiveComponentId,
  getTileById,
  getPresentationalTileForSnap,
  (activeComponentId, getTileByIdFn, getPresentationalTileForSnapFn) => (
    activeWholeSnapId: SnapId | undefined | null,
    activeEditionId: EditionID
  ) => {
    if (activeComponentId && isTileComponentId(activeComponentId)) {
      const tileId = extractTileIdFromComponentId(activeComponentId);
      return getTileByIdFn(tileId);
    }
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId | null | undefined' is no... Remove this comment to see the full error message
    return getPresentationalTileForSnapFn(activeWholeSnapId, activeEditionId);
  }
);

export const isSubscribeSnapSelected = s(getActiveTopsnap, topsnap => {
  return topsnap ? isSubscribeSnap(topsnap) : false;
});
