// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { FormData } from 'global';
import is from 'is_js';
import _ from 'lodash';
import { Middleware } from 'redux';

import { hasActionExpired } from 'state/actions/selectors/actionSelectors';

import { Sequence } from 'config/constants';
import { scrubUrlForReporting } from 'utils/apis/apiUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { logTiming, SiteSpeed } from 'utils/metricsUtils';
import { onExpiredAction, rejectWithErrorAction } from 'utils/middlewareUtils';
import { getPerformanceTiming } from 'utils/performance/performanceUtils';

import { getAdditionalDimensions } from './apiMiddlewareAdditionalMetrics';
import fetchWithProgress from './fetchWithProgress';

import { ApiError, OnProgressEvent } from 'types/errors';

const CONTENT_TYPE = 'Content-Type';
const JSON_CONTENT_TYPE = 'application/json';
const CONTENT_LENGTH = 'Content-Length';
// Decorate actions with special 'CALL_API' meta property to trigger this middleware.
// Example:
// {
//   type: 'MY_ACTION',
//   meta: {
//     @api/CALL_API: {
//       endpoint: 'http://url',
//       method: 'get', // optional -- defaults to GET
//       headers: {}, // optional
//       responseHeaders: {} // Optional -- Get header info from the response
//       body: {}, // optional -- only on type PUT/POST
//       preprocessor: req => newReq // optional -- allows the request to be manipulated before the request is made
//       finalizer: resp => newResp // optional -- allows transforming response on success
//     }
//   }
// }
/**
 * Action key that carries API call info interpreted by this Redux middleware.
 */
export const CALL_API = '@api/CALL_API';
/**
 * A Redux middleware that interprets actions with CALL_API info specified.
 * Performs the call and promises when such actions are dispatched.
 * @returns {Function} Middleware function.
 */
export default function init(fetchFn = fetch, fetchWithProgressFn = fetchWithProgress) {
  return function apiMiddleware(this: any, { getState }: any): Middleware {
    return (next: any) => (action: any) => {
      const callAPI = action && action.meta && action.meta[CALL_API];
      if (!callAPI || action.error) {
        return next(action);
      }
      const { preprocessor, finalizer, successMeta, failureMeta } = callAPI;
      const { type, payload } = action;
      const { endpoint } = callAPI;
      if (typeof endpoint !== 'string') {
        throw new Error('Specify a string endpoint URL.');
      }
      if (typeof type !== 'string') {
        throw new Error('Expected action type to be a string.');
      }
      if (typeof finalizer !== 'undefined' && typeof finalizer !== 'function') {
        throw new Error('Expected finalizer to either be undefined or a function.');
      }
      if (typeof preprocessor !== 'undefined' && typeof preprocessor !== 'function') {
        throw new Error('Expected preprocessor to either be undefined or a function.');
      }
      function actionWith(data: any) {
        const callApiCopy = { ...action.meta[CALL_API] };
        delete callApiCopy.finalizer;
        const meta = { ...action.meta, ...data.meta, [CALL_API]: callApiCopy };
        return { ...action, ...data, meta };
      }
      // dispatch start action
      next(actionWith({ sequence: Sequence.START }));
      let reqOptions = {
        method: callAPI.method || 'get',
        headers: callAPI.headers,
        credentials: callAPI.credentials,
        redirect: callAPI.redirect,
        body: callAPI.body,
        mode: callAPI.mode || 'cors',
      };
      if (preprocessor) {
        reqOptions = preprocessor(reqOptions);
      }
      if (
        callAPI.withProgress &&
        reqOptions.body instanceof FormData &&
        typeof reqOptions.body.entries === 'function'
      ) {
        const size = Array.from(reqOptions.body.entries(), ([key, prop]) =>
          typeof prop === 'string' ? prop.length : prop.size
        ).reduce((val, initial) => val + initial, 0);
        // Generating initial progress action with approximate size because 'onprogress' is first called only after a delay
        next(
          actionWith({
            sequence: Sequence.PROGRESS,
            meta: {
              totalSizeBytes: size,
              progressBytes: 0,
              progressPercent: 0,
            },
          })
        );
      }
      // handle JSON
      if (reqOptions.method.toLowerCase() !== 'get') {
        if (
          typeof FormData === 'undefined' ||
          !(reqOptions.body instanceof FormData || reqOptions.body instanceof Blob || reqOptions.body instanceof File)
        ) {
          reqOptions.headers = reqOptions.headers || {};
          const contentType = reqOptions.headers[CONTENT_TYPE] || JSON_CONTENT_TYPE;
          if (contentType === JSON_CONTENT_TYPE && is.object(reqOptions.body)) {
            reqOptions.body = JSON.stringify(reqOptions.body);
            reqOptions.headers[CONTENT_TYPE] = JSON_CONTENT_TYPE;
          }
        }
      }
      function onProgress(event: OnProgressEvent) {
        next(
          actionWith({
            sequence: Sequence.PROGRESS,
            meta: {
              totalSizeBytes: event.total || 0,
              progressBytes: event.loaded || 0,
              progressPercent: Math.floor(((event.loaded || 0) / (event.total || 1)) * 100),
            },
          })
        );
      }
      const fetchFunc = callAPI.withProgress ? fetchWithProgressFn.bind(this, onProgress) : fetchFn;

      // If a middleware that came before forces the api to fail we don't even make a network call
      // One example of this is the auth middleware failing auth
      if (action.forceApiError instanceof Error) {
        return rejectWithErrorAction(action.forceApiError, action, {
          sequence: Sequence.DONE,
          payload,
          meta: failureMeta,
        })(getState);
      }

      return performApiCall(fetchFunc, endpoint, reqOptions, finalizer, action).then(
        (finalResult: any) => {
          const { data, headers } = finalResult;
          if (hasActionExpired(action)(getState())) {
            return onExpiredAction(action);
          }
          // If the action is expecting any response headers, check if the response contains them
          const responseHeadersList = _.get(action, ['meta', CALL_API, 'responseHeaders'], []);
          const headerMap = responseHeadersList.reduce(
            // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'map' implicitly has an 'any' type.
            (map, header) => ({
              ...map,
              [header]: headers.get(header),
            }),
            {}
          );
          // Combine the expected response headers with the existing headers i.e auth token
          const combinedRequestResponseHeaders = {
            ..._.get(action, ['meta', CALL_API, 'headers']),
            ...headerMap,
          };
          // eslint-disable-next-line no-param-reassign
          action = _.merge({}, action, {
            meta: {
              [CALL_API]: {
                headers: combinedRequestResponseHeaders,
              },
            },
          });
          return next(
            actionWith({
              sequence: Sequence.DONE,
              meta: successMeta,
              payload: data || payload, // Make *sure* orig query params are attached somewhere (for ID)
            })
          );
        },
        (caughtObj: any) => {
          return rejectWithErrorAction(caughtObj, action, {
            sequence: Sequence.DONE,
            payload,
            meta: failureMeta,
          })(getState);
        }
      );
    };
  };
}
function isJsonContentType(res: any) {
  let contentTypes = res.headers.get(CONTENT_TYPE);
  if (!Array.isArray(contentTypes)) {
    if (contentTypes === undefined || contentTypes === null) {
      contentTypes = [];
    } else {
      contentTypes = [contentTypes];
    }
  }
  return contentTypes.some((contentType: any) => contentType.indexOf(JSON_CONTENT_TYPE) !== -1);
}
export async function parseResponse(response: Response, endpoint: any, startTimeMs: any) {
  if (!isJsonContentType(response)) {
    const data = await response.text();
    return { data, response };
  }
  try {
    const data = await response.json();
    return { data, response };
  } catch (error) {
    if (String(error).indexOf('SyntaxError:') === 0) {
      const contentLength = response.headers.get(CONTENT_LENGTH);
      // Interpret an empty response as null - rather than a parse error
      // Fix an issue with deleting collections
      if (contentLength === '0') {
        return { data: null, response };
      }
    }
    const duration = startTimeMs && Date.now() - startTimeMs;
    throw new Error(
      `Error returned from response.json(); in apiMiddleware.js for ${endpoint} after ${duration}ms: ${error}`
    );
  }
}
function parseHostString(scrubbedUrl: any) {
  return _.snakeCase(_.get(scrubbedUrl, ['label', 'host'], 'unknown_host'));
}
function parseLabelString(actionType: any, options: any) {
  const actionTypeString = actionType ? actionType.toLowerCase() : 'no_action_type_declared';
  const methodString = _.get(options, ['method'], 'undefined').toUpperCase();
  return `${actionTypeString}_${methodString}`;
}
function reportApiRequestCounter(
  metricsName: any,
  scrubbedUrl: any,
  actionType: any,
  options: any,
  action: any,
  status: any
) {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ purpose: any; size: string; st... Remove this comment to see the full error message
  grapheneUtils.incrementCounter(metricsName, {
    host: parseHostString(scrubbedUrl),
    label: parseLabelString(actionType, options),
    ...(status ? { status: status.toString() } : {}),
    ...getAdditionalDimensions(action),
  });
}
export function performApiCall(
  fetchFn:
    | ((input: RequestInfo, init?: RequestInit | undefined) => Promise<Response>)
    | ((url: string, opts?: {} | undefined) => Promise<Response>),
  endpoint: any,
  options: RequestInit = {},
  finalizer = (input: any) => input,
  action = {}
) {
  const actionType = (action as any).type || null;
  const startTimeMs = getPerformanceTiming();
  const scrubbedUrl = scrubUrlForReporting(endpoint, (options as any).method);
  return fetchFn(endpoint, options)
    .catch((error: any) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 6 arguments, but got 5.
      reportApiRequestCounter('api_request_network_failure_counter', scrubbedUrl, actionType, options, action);
      throw error;
    })
    .then((response: Response) => {
      const latencyMs = getPerformanceTiming() - startTimeMs;
      const additionalDimensions = getAdditionalDimensions(action) as { [key: string]: string };
      const dimensions = {
        host: parseHostString(scrubbedUrl),
        label: parseLabelString(actionType, options),
        ...additionalDimensions,
      };
      grapheneUtils.reportTimerWithDuration({
        metricsName: 'api_latency',
        dimensions,
        milliSec: latencyMs,
      });
      logTiming(SiteSpeed.API_LOAD_TIMES, (scrubbedUrl as any).pathName, startTimeMs, (scrubbedUrl as any).label);
      return response;
    })
    .then((response: Response) => parseResponse(response, endpoint, startTimeMs))
    .then(({ data, response }) => {
      const { ok, status, statusText, headers } = response;
      reportApiRequestCounter('api_request_counter', scrubbedUrl, actionType, options, action, status);
      if (!ok) {
        if (status === 404 && (options as any).method.toLowerCase() === 'delete') {
          return { data: null, headers: null }; // 404 on DELETE is considered ok
        }
        const error = new ApiError(
          `Server returned error status code ${status} (${statusText}).` +
            `\n  Method: ${(options as any).method}` +
            `\n  Endpoint: ${endpoint}` +
            `\n  Details: ${JSON.stringify(data)}`,
          {
            status,
            statusText,
            data,
          }
        );
        return Promise.reject(error);
      }
      return { data, headers };
    })
    .then((result: any) => {
      return { data: finalizer(result.data), headers: result.headers };
    });
}
