// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import global from 'global';
import _ from 'lodash';
import log from 'loglevel';

import { LocalStorage } from 'config/constants';

const browserStorage = global.localStorage;

// Must leave keys that are used by angular or we'll end up being logged out of angular
const localStorageKeysNotToRemove = [
  LocalStorage.CURRENT_PUBLISHER, // TODO(chris|PUB-14343): Clean up old publisher ID code.
  LocalStorage.CURRENT_HOST_USERNAME,
  LocalStorage.LOCALE_ID,
  LocalStorage.DONT_SHOW_HELP_MODALS_PUBLISHER,
  LocalStorage.DONT_SHOW_HELP_MODALS_STORY,
];

const localStorageKeyPrefixesToKeep = [LocalStorage.ATTACHMENT];

let cache = {};

export const clearLocalStorage = () => {
  const localStorageKeys = [];

  for (let i = 0; i < browserStorage.length; ++i) {
    localStorageKeys.push(browserStorage.key(i));
  }

  const localStorageKeysToRemove = _.without(localStorageKeys, ...localStorageKeysNotToRemove);
  localStorageKeysToRemove.forEach(clearItem);
};

function clearItem(key: string) {
  const shouldKeep = localStorageKeyPrefixesToKeep.some(prefix => key.startsWith(prefix));
  if (!shouldKeep) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    delete cache[key];
    browserStorage.removeItem(key);
  }
}

function getItem(key: string): any {
  if (!(key in cache)) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    cache[key] = browserStorage.getItem(key);
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return cache[key];
}

function setItem(key: string, value?: string | null) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  delete cache[key];
  try {
    browserStorage.setItem(key, value);
  } catch (ex) {
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    // setItem() may throw an exception if the storage is full. Particularly,
    // in Mobile Safari (since iOS 5) it always throws when the user enters private mode.
    // (Safari sets the quota to 0 bytes in private mode, unlike other browsers,
    // which allow storage in private mode using separate data containers.)
    // Hence developers should make sure to always catch possible exceptions from setItem().
    log.error(ex);
  }
}

function removeItem(key: string) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  delete cache[key];
  browserStorage.removeItem(key);
}

// Just for testing
export function clearCache() {
  cache = {};
}

export const localStorage = {
  clear: clearLocalStorage,
  getItem,
  setItem,
  removeItem,
  getParsedJSON: (key: string) => {
    let item;

    try {
      item = JSON.parse(getItem(key));
    } catch (e) {
      // Note this intentionally generates both client and server side errors
      log.error('localStorageUtils.getParsedJSON: invalid JSON', e);
      item = null;
    }

    return item;
  },
};
