import { DISCOVER_SERVICE_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder(DISCOVER_SERVICE_BASE_URL);

export const icons = {
  icon: builder.createAPICall('icons', { query: ['icon'] }),
};

export const features = {
  createFeature: builder.createAPICall('features/:featureId'),
  fullList: builder.createAPICall('features/fullList'),
  fullListByPublisher: builder.createAPICall('features/fullList/bp_id/:businessProfileId'),
  enabledByPublisher: builder.createAPICall('features/bp_id/:businessProfileId/enabled'),
  set: builder.createAPICall('features/set/bp_id/:businessProfileId/:feature', {
    query: ['enable'],
  }),
  enableForAll: builder.createAPICall('features/:featureId/enableForAll'),
  disableForAll: builder.createAPICall('features/:featureId/disableForAll'),
  addToTier: builder.createAPICall('features/add/feature/:featureId/tier/:tierId'),
  removeFromTier: builder.createAPICall('features/remove/feature/:featureId/tier/:tierId'),
  bulkUpdate: builder.createAPICall('features/:featureId/bulkUpdate', {
    query: ['enable'],
  }),
};

export const user = {
  getPublisherList: builder.createAPICall('user/publishers'),
  getPublisherListUncached: builder.createAPICall('user/:userId/publishers/uncached'),
  login: builder.createAPICall('user/login/temp', {
    query: ['forcedRoles'],
    convertQueryCasing: (arg: any) => arg,
  }),
  setFlag: builder.createAPICall('user/flags', {
    query: ['userFlag', 'enable'],
    convertQueryCasing: (arg: any) => arg,
  }),
};

export const publishers = {
  debug: builder.createAPICall('publisher/:publisherId/debug?includeEnabledFeatures=false'),
  getOrgs: builder.createAPICall('publishers/orgs/'),
  getOrganization: builder.createAPICall('publisher/:bpid/org'),
  validateHostUserToken: builder.createAPICall('publishers/:bpid/hostUser?token=:token'),
  associateHostUser: builder.createAPICall('publishers/:bpid/hostUser?token=:token'),
};

export const analyticsV4 = {
  analyticsServiceStatus: builder.createAPICall('analyticsV4/servicestatus'),

  analyticsDaily: builder.createAPICall('analyticsV4/publisher/:publisherId/daily/:type', {
    query: ['startDate', 'endDate', 'countries', 'metric'],
  }),

  analyticsCalendar: builder.createAPICall('analyticsV4/publisher/:publisherId/editions', {
    query: ['endDate', 'startDate', 'analyticsStatsType'],
  }),

  analyticsStorySummary: builder.createAPICall('analyticsV4/publisher/:publisherId/editions/summary/GLOBAL', {
    query: ['endDate', 'startDate', 'analyticsStatsType'],
  }),

  analyticsEdition: builder.createAPICall('analyticsV4/edition/:editionId/GLOBAL', {
    query: ['analyticsStatsType'],
  }),

  analyticsEdition24Hours: builder.createAPICall('analyticsV4/edition/:editionId/24hours/:geoType', {
    query: ['date', 'countries', 'analyticsStatsType'],
  }),

  analyticsEditionDaily: builder.createAPICall('analyticsV4/edition/:editionId/daily/:geoType', {
    query: ['startDate', 'endDate', 'countries', 'analyticsStatsType'],
  }),

  analyticsEditionByCountries: builder.createAPICall('analyticsV4/edition/:editionId/COUNTRY', {
    query: ['countries', 'analyticsStatsType'],
  }),

  analyticsSnaps: builder.createAPICall('analyticsV4/edition/:editionId/snaps/:geoType', {
    query: ['countries', 'analyticsStatsType'],
  }),

  analyticsPollTimelines: builder.createAPICall('analyticsV4/edition/:editionId/pollTimeline'),

  analyticsInsightsSLCS: builder.createAPICall('insights/publisher/:publisherId/slc', {
    query: ['countries'],
  }),

  analyticsInsightsTopPerformingTiles: builder.createAPICall('insights/publisher/:publisherId/toptiles'),

  analyticsInsightsTopPerformingStories: builder.createAPICall('insights/publisher/:publisherId/topstories', {
    query: ['startDate', 'endDate'],
  }),

  editionCSV: builder.createAPICall('analyticsCSV/edition/:editionId', {
    query: ['geoType', 'countries', 'analyticsStatsType'],
  }),

  editionListCSV: builder.createAPICall('analyticsCSV/publisher/:publisherId/editions', {
    query: ['geoType', 'countries', 'endDate', 'startDate', 'analyticsStatsType'],
  }),

  revenueCSV: builder.createAPICall('analyticsCSV/revenue/:hostUserId/bundles', {
    query: ['publisherId', 'startDate', 'endDate', 'granularity'],
  }),
  editionAnalyticsCSV: builder.createAPICall('analyticsCSV/bundle/:bundleId', {
    query: ['publisherId', 'hostUserId', 'country'],
  }),
  profileAnalyticsCSV: builder.createAPICall('analyticsCSV/profile/:hostUserId', {
    query: ['publisherId', 'startDate', 'endDate'],
  }),
};

export const subscription = {
  create: builder.createAPICall('subscription'),
  fetch: builder.createAPICall('subscription', {
    query: ['publisherId'],
    convertQueryCasing: (arg: any) => arg,
  }),
  delete: builder.createAPICall('subscription/:subscriptionId/'),
};

export const snapcode = {
  new: builder.createAPICall('snapcode/:linkableType/:linkableId'),
  load: builder.createAPICall('snapcode/:linkableType/:linkableId'),
  update: builder.createAPICall('snapcode/:linkableType/:linkableId/:deeplinkId'),
  preview: builder.createAPICall('snapcode/:linkableType/:linkableId/preview', {
    query: ['buildSetting'],
    convertQueryCasing: (arg: any) => arg,
  }),
  download: builder.createAPICall('snapcode/:linkableType/:linkableId/:snapcodeId/download'),

  // Just for SSAPI matching
  publisher: builder.createAPICall('snapcode/PUBLISHER/:linkableId'),
};

// Todo: Change all publisher channel data url back to 'scadmin/publishers?region=ALL' when completely migrated
export const snapAdmin = {
  createPublisher: builder.createAPICall('scadmin/publisher'),
  singlePublisherSettingData: builder.createAPICall('scadmin/publisher/:publisherId/temp'),
  publisherLevelService: builder.createAPICall('publisher/:publisherId'),
};

export const settings = {
  cmsSetting: builder.createAPICall('settings?:params'),
  setCmsSetting: builder.createAPICall('settings'),
};

export const users = {
  snapAdminUsers: builder.createAPICall('scadmin/accounts/temp'),
  deleteSnapAdminUser: builder.createAPICall('scadmin/accounts/:userId/roles'),
  editSnapAdminUser: builder.createAPICall('scadmin/accounts/:userId/resourceRoles'),
  createSnapAdminUser: builder.createAPICall('scadmin/accounts'),
  publisherUsers: builder.createAPICall('publishers/:publisherId/members/temp'),
  deletePublisherUser: builder.createAPICall('publishers/:publisherId/members/:userId/roles'),
  editUser: builder.createAPICall('user/:userId/businessProfileId/:businessProfileId'),
  createPublisherUserWithEmail: builder.createAPICall('users/publishers/:publisherId/new_account'),
  createPublisherUserWithSnapUsername: builder.createAPICall('users/publishers/:publisherId/new_snap_account'),
  addExistingUserToPublisher: builder.createAPICall('publishers/:publisherId/members/add_existing_user'),
};

export const supportTickets = {
  auth: builder.createAPICall('zendeskSSO/auth'),
  raiseTicket: builder.createAPICall('zendeskSSO/raiseTicket'),
};

export const orgOnboardings = {
  getOrgOnboardingList: builder.createAPICall('orgonboarding/list'),
  createOrgOnboarding: builder.createAPICall('orgonboarding/create'),
  deleteOrgOnboarding: builder.createAPICall('orgonboarding/delete/:onboardingId'),
};
