import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { BITMOJI_TEMPLATES } from 'config/bitmojiConfig';

import { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import { HelpModalContent } from 'views/modals/containers/NewUserExperienceHelpModal/NewUserExperienceHelpModal';

export const modalHeader: React.ReactChild = (
  <FormattedMessage
    id="publisher-welcome-header"
    defaultMessage="Welcome to Story Studio"
    description="Header text for the welcome to story studio"
  />
);

const modalSubHeader: React.ReactChild = (
  <FormattedMessage
    id="publisher-welcome-sub-header"
    defaultMessage="The Content Management System where you will upload, manage and publish your content."
    description="Sub header for the welcome publisher page"
  />
);

// Publisher getting started help modal content
export const PUBLISHER_GETTING_STARTED: HelpModalContent[] = [
  {
    id: 'publisher-getting-started-section1',
    modalSubHeader,
    modalSections: [
      {
        id: 'content-guidelines',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_CHECKBOX,
        sectionHeader: (
          <FormattedMessage
            id="content-guidelines"
            description="Label for a zendesk option"
            defaultMessage="Content Guidelines"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-content-guidelines"
            description="Content guideline information"
            defaultMessage="The Content Guidelines support our mission by encouraging the broadest range of self-expression, while also making sure Snapchatters can use our services safely every day."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.VIEW_GUIDELINES,
        },
      },
      {
        id: 'help-content-manage-user-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_LAPTOP,
        sectionHeader: (
          <FormattedMessage
            id="help-content-manage-user-header2"
            description="Header text on how to manage users"
            defaultMessage="Manage Users in your Organization"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-content-manage-user-content2"
            description="Information on how to manage users"
            defaultMessage="Add members on your team to Story Studio with varying degrees of access and permissions."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.USER_MANAGEMENT,
        },
      },
    ],
  },
  {
    id: 'publisher-getting-started-section2',
    modalSubHeader,
    modalSections: [
      {
        id: 'help-content-update-branding-label',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_COOL,
        sectionHeader: (
          <FormattedMessage
            id="help-content-update-branding-link-label"
            description="Label text for link on how to update your branding"
            defaultMessage="Update your branding"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-branding--content"
            description="Information on how to update your branding"
            defaultMessage="We provide guidelines and best practices to ensure your content looks its best on Snapchat."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.BRANDING_GUIDELINES,
        },
      },
      {
        id: 'help-content-upload-story-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_SELFIE,
        sectionHeader: (
          <FormattedMessage
            id="help-content-upload-story-header"
            description="Header text on how to upload a story"
            defaultMessage="How to upload your first story"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-content-upload-story-content"
            description="Content on how to upload story"
            defaultMessage="Simply upload a full-length, vertical video that is between 3-5 minutes. Videos should be 1080x1920 or better, and no more than 1GB. Once your first Story is uploaded, simply click “Request Review” in the Stories uploader."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.CREATE_STORY,
        },
      },
    ],
  },
  {
    id: 'publisher-getting-started-section3',
    modalSubHeader,
    modalSections: [
      {
        id: 'help-content-story-studio-analytics-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_GRAPH,
        sectionHeader: (
          <FormattedMessage
            id="help-content-story-studio-analytics-header"
            description="Header text for story studio analytics section"
            defaultMessage="Story Studio Analytics"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-content-story-studio-analytics-content"
            description="Story studio analytics content"
            defaultMessage="Understand how your content resonates with Snapchatters by getting familar with the robust Analytics Story Studio provides."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.ANALYTICS_OVERVIEW,
        },
      },
      {
        id: 'help-content-questions-header',
        sectionBitmojiTemplate: BITMOJI_TEMPLATES.USER_QUESTION,
        sectionHeader: (
          <FormattedMessage
            id="help-content-questions-header"
            description="Header text for questions section"
            defaultMessage="Questions?"
          />
        ),
        sectionContent: (
          <FormattedMessage
            id="help-content-questions-content"
            description="Help center question section content"
            defaultMessage="The Story Studio Help Center has a full suite of guides to help you through the Stories creation and management process."
          />
        ),
        link: {
          linkDestination: HelpCenterDestination.HELP_CENTER,
        },
      },
    ],
  },
];
