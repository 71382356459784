import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';

import { orgOnboardings } from 'utils/apis/discoverAPI';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';

import { SequenceAction } from 'types/common';
import { OrgOnboarding, OrgOnboardingCreate, OrgOnboardingId, OrgOnboardingServer } from 'types/orgOnboarding';
import { Dispatch } from 'types/redux';

export const LOAD_ORG_ONBOARDINGS_LIST = 'orgonboardings/LOAD_ORG_ONBOARDINGS_LIST';
export const CREATE_ORG_ONBOARDING = 'orgonboardings/CREATE_ORG_ONBOARDING';
export const DELETE_ORG_ONBOARDING = 'orgonboardings/DELETE_ORG_ONBOARDING';

export type LoadOrgOnboardingsAction = SequenceAction<Array<OrgOnboarding>>;
export type CreateOrgOnboardingAction = SequenceAction<OrgOnboarding>;
export type DeleteOrgOnboardingAction = SequenceAction<OrgOnboarding>;

export type OrgOnboardingAction = LoadOrgOnboardingsAction | CreateOrgOnboardingAction | DeleteOrgOnboardingAction;

const orgOnboardingFinaliser = (onboarding: OrgOnboardingServer): OrgOnboarding => {
  return { ...onboarding, createdAt: new Date(onboarding.createdAt) };
};

export const loadOrgOnboardingsList = () => (dispatch: Dispatch) =>
  dispatch(
    createCallAction(
      {
        type: LOAD_ORG_ONBOARDINGS_LIST,
      },
      {
        method: 'GET',
        endpoint: orgOnboardings.getOrgOnboardingList(),
        finalizer: (input: any) => input.map(orgOnboardingFinaliser),
      }
    )
  ).catch(apiErrorHandler(dispatch, ErrorContexts.LOAD_ORG_ONBOARDINGS_LIST));

export const createOrgOnboarding = (orgOnboarding: OrgOnboardingCreate) => (dispatch: Dispatch) =>
  dispatch(
    createCallAction(
      {
        type: CREATE_ORG_ONBOARDING,
      },
      {
        method: 'POST',
        endpoint: orgOnboardings.createOrgOnboarding(),
        body: orgOnboarding,
        finalizer: (input: any) => orgOnboardingFinaliser(input),
      }
    )
  ).catch(apiErrorHandler(dispatch, ErrorContexts.CREATE_ORG_ONBOARDING));

export const deleteOrgOnboarding = (onboardingId: OrgOnboardingId) => (dispatch: Dispatch) =>
  dispatch(
    createCallAction(
      {
        type: DELETE_ORG_ONBOARDING,
      },
      {
        method: 'DELETE',
        endpoint: orgOnboardings.deleteOrgOnboarding({ onboardingId }),
        finalizer: (input: any) => orgOnboardingFinaliser(input),
      }
    )
  ).catch(apiErrorHandler(dispatch, ErrorContexts.DELETE_ORG_ONBOARDING));
