import { SAS_MIN_SNAP_DURATION_MS, SAS_MAX_SNAP_DURATION_MS } from 'config/constants';

import { SnapId } from 'types/common';
import type { ExtendedShot, ShotWithSnapId, Advert, TimelineSnap, ChapterSummary } from 'types/singleAssetStoryEditor';

export const getPlayingSnapOrShot = (timeInMs: number, items: TimelineSnap[] | ExtendedShot[] | ChapterSummary[]) => {
  let start = 0;
  let end = items.length - 1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    const item = items[mid];
    // This will never happen but ensures proper typing.
    if (!item) {
      return null;
    }

    if (timeInMs >= item.startTimeMs && timeInMs < item.startTimeMs + item.durationMs) {
      return item;
    }

    if (item.startTimeMs < timeInMs) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  return null;
};

export const getNextSnapOrShot = (timeInMs: number, items: TimelineSnap[] | ExtendedShot[] | ChapterSummary[]) => {
  const playingItem = getPlayingSnapOrShot(timeInMs, items);

  if (playingItem && playingItem.index < items.length - 1) {
    return items[playingItem.index + 1];
  }

  return null;
};

export const getPreviousSnapOrShot = (timeInMs: number, items: TimelineSnap[] | ExtendedShot[] | ChapterSummary[]) => {
  const playingItem = getPlayingSnapOrShot(timeInMs, items);

  if (playingItem && playingItem.index > 0) {
    return items[playingItem.index - 1];
  }

  return null;
};

const adBeforeShot = (index: number, ads: Advert[]) => {
  let start = 0;
  let end = ads.length - 1;

  while (start <= end) {
    const mid = Math.floor((start + end) / 2);
    const ad = ads[mid];
    // This will never happen but ensures proper typing.
    if (!ad) {
      return false;
    }

    if (ad.shotIndex === index) {
      return true;
    }

    if (ad.shotIndex < index) {
      start = mid + 1;
    } else {
      end = mid - 1;
    }
  }

  return false;
};

export const secondsToMs = (seconds: number) => {
  return Math.round(seconds * 1000);
};

export const msToSeconds = (ms: number) => {
  return ms / 1000;
};

// max time update tick interval is ~250ms, we use 300ms to ensure we don't miss the ad
const HITBOX_SIZE_MS = 300;

export const getMockAdTimeInMs = (
  newTimeInMs: number,
  shots: ExtendedShot[],
  videoIsPlaying: boolean,
  ads: Advert[]
) => {
  const nextShot = getNextSnapOrShot(newTimeInMs, shots);

  if (nextShot) {
    // if the time is within 300ms of the upcoming ad
    const inAdHitbox = newTimeInMs > nextShot.startTimeMs - HITBOX_SIZE_MS;

    if (adBeforeShot(nextShot.index, ads) && inAdHitbox && videoIsPlaying) {
      return nextShot.startTimeMs;
    }
  }

  return null;
};

export const isPositionWithinBounds = (position: number, node: HTMLDivElement) => {
  return position >= 0 && position <= node.getBoundingClientRect().width;
};

export const getMinutesSecondsMilliseconds = (timeMs: number) => {
  // This returns minutes, seconds and milliseconds.
  return new Date(timeMs).toISOString().slice(14, -2);
};

export const getTimeString = (startTime: number, endTime: number) => {
  return `${getMinutesSecondsMilliseconds(startTime)} - ${getMinutesSecondsMilliseconds(endTime)}`;
};

// returns shots that can be moved between snaps while keeping snaps within 2-20 sec duration limit
export const getValidShotsForSnaps = (firstSnap: TimelineSnap, secondSnap: TimelineSnap) => {
  const validShots: ShotWithSnapId[] = [];
  const startTimeMs = firstSnap.startTimeMs;
  const endTimeMs = secondSnap.startTimeMs + secondSnap.durationMs;

  if (secondSnap.durationMs < SAS_MAX_SNAP_DURATION_MS && firstSnap.durationMs > SAS_MIN_SNAP_DURATION_MS) {
    for (let i = firstSnap.shots.length - 1; i >= 0; i--) {
      const shot = firstSnap.shots[i];
      const time = shot?.startTimeMs;
      const firstSnapLongerThanMin = time && time - startTimeMs >= SAS_MIN_SNAP_DURATION_MS;
      const secondSnapShorterThanMax = time && endTimeMs - time <= SAS_MAX_SNAP_DURATION_MS;

      if (shot && firstSnapLongerThanMin && secondSnapShorterThanMax) {
        validShots.push({ ...shot, snapId: firstSnap.snapId });
      } else {
        break;
      }
    }
  }

  if (firstSnap.durationMs < SAS_MAX_SNAP_DURATION_MS && secondSnap.durationMs > SAS_MIN_SNAP_DURATION_MS) {
    for (let i = 0; i < secondSnap.shots.length; i++) {
      const shot = secondSnap.shots[i];
      const time = shot && shot.startTimeMs + shot.durationMs;
      const secondSnapLongerThanMin = time && endTimeMs - time >= SAS_MIN_SNAP_DURATION_MS;
      const firstSnapShorterThanMax = time && time - startTimeMs <= SAS_MAX_SNAP_DURATION_MS;

      if (shot && secondSnapLongerThanMin && firstSnapShorterThanMax) {
        validShots.push({ ...shot, snapId: secondSnap.snapId });
      } else {
        break;
      }
    }
  }

  return validShots;
};

export const isThereShotAtTime = (
  shots: ShotWithSnapId[] | ExtendedShot[],
  timeMs: number,
  rightSnapId: SnapId | undefined
) => {
  for (let i = 0; i < shots.length; i++) {
    const shot = shots[i];
    if (shot && shot.startTimeMs + (shot.snapId === rightSnapId ? shot.durationMs : 0) === timeMs) {
      return true;
    }
  }
  return false;
};
