// Based on the mime type, works out what the higher-level file type category
// is (e.g. image, video, text).
import { FileType } from 'src/config/constants';
import { MediaPreviewMetadata } from 'src/types/media';

import { FileLike } from 'types/common';

export function getFileType(file: FileLike | string): FileType | null {
  const mimeType = getMimeType(file);
  if (mimeType) {
    const segments = mimeType.split('/');
    if (segments.length > 1) {
      return segments[0] as FileType;
    }
    throw new Error(`Could not parse mime type '${mimeType}'`);
  }
  return null;
}
export function getMimeType(file: FileLike | string) {
  if (typeof (file as any).name === 'string') {
    const name = (file as any).name;
    // Extreme hack: Manually set file.type to 'video/quicktime', because
    // dragging and dropping a .mov or .qt file will lead to a blank file.type
    // field, unlike dragging and dropping other file types.
    //
    // Note: This has been ported over from the Angular codebase so presumably
    // there was a reason for adding it, but to be honest it feels a bit fishy
    // that we have to rely on the file name/extension.
    if (name.match('.mov$') || name.match('.qt$')) {
      return 'video/quicktime';
      // Apparently we also need to do the same for vtt.
    }
    if (name.match('.vtt$')) {
      return 'text/vtt';
    }
    if (name.match('.srt')) {
      return 'text/srt';
    }
  }
  if (!(file as any).type) {
    throw new Error('File is missing `type` property.');
  }
  return String((file as any).type);
}

export async function blobUrlToFile(blobUrl: string, fileName: string): Promise<FileLike> {
  return new File([await fetch(blobUrl).then(r => r.blob())], fileName, {});
}

export const makeMediaPreviewMetadata = (file: File): MediaPreviewMetadata => {
  return {
    src: URL.createObjectURL(file),
    name: file.name,
  };
};
