import { get } from 'lodash';
import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import * as assetActions from 'state/asset/actions/assetActions';
import { getEditionSnaps } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { getSnapById } from 'state/snaps/selectors/snapsSelectors';
import { getSubtitlesByLanguageCode, getSubtitlesByVideoAssetId } from 'state/subtitles/selectors/subtitlesSelectors';

import type { SubtitleLanguageEnum, SubtitleTrack } from 'constants/subtitles';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import * as proxyAPI from 'utils/apis/proxyAPI';
import { bulkDownloadFiles } from 'utils/files/downloadFilesUtil';
import { createAssetUrl } from 'utils/media/assetUtils';

import type { AssetID } from 'types/assets';
import type { ActionWithParams, SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { GetState } from 'types/redux';
import type { VideoSnap } from 'types/snaps';

export const LOAD_SUBTITLES_TRACK = 'subtitles/LOAD_SUBTITLES_TRACK';
export const DELETE_STORY_SUBTITLES = 'subtitles/DELETE_STORY_SUBTITLES';
export const SET_SUBTITLES_PREVIEW = 'subtitles/SET_PREVIEW';
export const SET_SUBTITLES_LANGUAGE = 'subtitles/SET_LANGUAGE';

export function loadSubtitlesForSnap(topsnapId: SnapId) {
  return (dispatch: Dispatch, getState: GetState) => {
    const snap = getSnapById(getState())(topsnapId);
    const videoAssetId = get(snap, 'videoAssetId');
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => AnyActio... Remove this comment to see the full error message
    return dispatch(loadSubtitles(videoAssetId));
  };
}

export function loadSubtitles(videoAssetId: AssetID) {
  return (dispatch: Dispatch) => {
    if (videoAssetId) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => CallActi... Remove this comment to see the full error message
      return dispatch(assetActions.loadAssetInfo(videoAssetId));
    }
    return Promise.resolve();
  };
}

export type LoadSubtitlesAction = ActionWithParams<{
  videoAssetId: AssetID;
  subtitles: SubtitleTrack[];
}>;

export function loadSubtitlesTrack(subtitleTrack: SubtitleTrack): (a: Dispatch) => Promise<string> {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(dispatch: Dispatch) => CallAction<{ type: s... Remove this comment to see the full error message
  return (dispatch: Dispatch) => {
    return dispatch(
      createCallAction(
        {
          type: LOAD_SUBTITLES_TRACK,
          params: {
            trackId: subtitleTrack.id,
          },
        },
        {
          endpoint: subtitleTrack.src,
        }
      )
    );
  };
}

export function setSubtitlesPreview(previewVisible: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SUBTITLES_PREVIEW,
      params: { previewVisible },
    });
  };
}

export type SetSubtitlesPreviewAction = ActionWithParams<{
  previewVisible: boolean;
}>;

export function setSubtitlesLanguage(language: SubtitleLanguageEnum) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_SUBTITLES_LANGUAGE,
      params: { language },
    });
  };
}

export type SetSubtitlesLanguageAction = ActionWithParams<{
  language: string;
}>;

export function deleteStorySubtitles(storyId: EditionID, language: string) {
  return (dispatch: Dispatch) => {
    return dispatch(
      createCallAction(
        {
          type: DELETE_STORY_SUBTITLES,
        },
        {
          endpoint: proxyAPI.subtitles.deleteStorySubtitles({
            storyId,
            language,
          }),
          method: 'POST',
        }
      )
    );
  };
}

export function downloadStorySubtitles(storyId: EditionID, language: string) {
  return async (dispatch: Dispatch, getState: GetState) => {
    const snaps = getEditionSnaps(getState())(storyId) as VideoSnap[];

    // helps us track the index of the snap the subtitles came from, which is used
    // in the filename. It also helps ignore subtitle values leftover in the subtitles
    // react store
    const subtitleAssetIndexMap = snaps.reduce((acc, snap, index) => {
      const subtitleAssetIds: string[] = snap.subtitlesAssetIds || [];
      subtitleAssetIds.forEach(element => {
        acc.set(element, index);
      });
      return acc;
    }, new Map());

    // if a video doesn't have subtitles, or the subtitles are already loaded in state, filter them out
    const videoAssetIdsToFetch = snaps
      .filter(
        snap =>
          (snap.subtitlesAssetIds || []).length > 0 &&
          Boolean(snap.videoAssetId) &&
          getSubtitlesByVideoAssetId(getState())(snap.videoAssetId).length === 0
      )
      .map(snap => snap.videoAssetId);

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => AnyActio... Remove this comment to see the full error message
    await Promise.all(videoAssetIdsToFetch.map(id => dispatch(loadSubtitles(id))));

    // copied so we don't change memoised state by calling .sort() later
    const rawSubtitles = [...getSubtitlesByLanguageCode(getState())(language)];
    const subtitlesToDownload = rawSubtitles.filter(item => subtitleAssetIndexMap.get(item.id) !== undefined);

    // subtitles are just a list of IDs organised alphabetically, we sort here to ensure we download files in index order
    subtitlesToDownload.sort(subtitlesByIndexComparator(subtitleAssetIndexMap));

    const downloaderInput = subtitlesToDownload.map(item => {
      const filename = `Story_${storyId}_Subtitles_Snap_${subtitleAssetIndexMap.get(item.id) + 1}_${language}.vtt`;
      return {
        href: createAssetUrl(item.id, { downloadFilename: filename }, mediaLibraryAPI.asset.forceDownload),
        filename,
      };
    });

    await bulkDownloadFiles(downloaderInput);
  };
}

// comparator to sort a list of IDs by the index found in a map of IDs to index
function subtitlesByIndexComparator(subtitlesIndexMap: Map<string, string>) {
  return (a: SubtitleTrack, b: SubtitleTrack) => {
    const valueA = subtitlesIndexMap.get(a.id) || '';
    const valueB = subtitlesIndexMap.get(b.id) || '';
    if (valueA < valueB) {
      return -1;
    }
    if (valueA > valueB) {
      return 1;
    }
    return 0;
  };
}
