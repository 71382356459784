import {
  getChoiceCountForLayout,
  getLayoutType,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/web-attachments/lib/polls/markup/components/PollLayouts/PollLayoutsFactory';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { DEEP_LINK_PARAMS, POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';
import _ from 'lodash';

import { getClientAttachments } from 'utils/attachmentUtils';
import { createAssetUrl } from 'utils/media/assetUtils';

import { renderHtml } from './pollClientRenderer';

import type { EditionID } from 'types/editions';
import type {
  PollProperties,
  ServerPollProperties,
  PollEditorProperties,
  ServerQuestion,
  Question,
  PollType,
  Options,
} from 'types/polls';
import { EditorProperties, SERVER_POLL_PROPERTIES } from 'types/polls';
import { SnapType } from 'types/snaps';
import type { Stage } from 'types/stages';

type UnstagingOpts = {
  edition: EditionID;
  publisher: string;
};
export const POLL_STAGE_CONFIG: Stage<ServerPollProperties, PollProperties, UnstagingOpts> = {
  stageName: SnapType.POLL,
  properties: SERVER_POLL_PROPERTIES,
  transformToStaged: transformFromServerPoll,
  transformFromStaged: transformToServerPoll,
};
export const DEFAULT_POLL_DURATION_DAYS = 30;
export function createEmptyQuestion(pollType: PollType): Question {
  return {
    pollType,
    id: null,
    options: [],
    editorProperties: {},
  };
}
/** Parse datastore representation */
export function transformFromServerPoll(pollProperties: ServerPollProperties): PollProperties {
  const deserializedEditorHtml = deserialize(pollProperties.editorHtml, {});
  const editorHtml = migrateEditorHtml(deserializedEditorHtml);
  const { id, pollType, appHtml, imageAssetIds, attachmentIds, durationInDays } = pollProperties;
  const questions = pollProperties.questions.map(transformQuestionFromServer).map(appendPollType(pollType));
  const outcomes = fromServerOutcomes(pollProperties);
  if (questions.length === 0) {
    questions.push(createEmptyQuestion(pollType));
  }
  return {
    id,
    pollType,
    appHtml,
    imageAssetIds,
    attachmentIds,
    editorHtml,
    questions,
    outcomes,
    durationInDays: durationInDays || DEFAULT_POLL_DURATION_DAYS,
  };
}
/** Pack into datastore representation */
export function transformToServerPoll(
  pollProperties: PollProperties,
  opts: UnstagingOpts
): Partial<ServerPollProperties> {
  const editorHtml = serialize(pollProperties.editorHtml);
  const questions = pollProperties.questions.map(transformToServerQuestion);
  const appHtml = generateAppHtml(pollProperties, opts.publisher, opts.edition);
  const outcomes = toServerOutcomes(pollProperties.outcomes);
  const imageAssetIds = getImageAssets(pollProperties);
  const attachmentIds = getClientAttachments(SnapType.POLL).map((attachment: any) => attachment.id);
  const { id, pollType, durationInDays } = pollProperties;
  return {
    id,
    pollType,
    editorHtml,
    appHtml,
    imageAssetIds,
    attachmentIds,
    questions,
    outcomes,
    durationInDays,
  };
}
function migrateEditorHtml(editorHtml: any): PollEditorProperties {
  const editorProperties = {};
  if (editorHtml.updateAppButton) {
    (editorProperties as any).updateAppButton = editorHtml.updateAppButton;
  }
  if (editorHtml.updateAppHeadline) {
    (editorProperties as any).updateAppHeadline = editorHtml.updateAppHeadline;
  }
  return editorProperties;
}
function appendPollType(pollType: PollType) {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id?: string | null | undefined; editorProp... Remove this comment to see the full error message
  return (question: Partial<Question>): Question => ({
    pollType,
    ...question,
  });
}
function transformQuestionFromServer({ id, editorProperties, options }: ServerQuestion): Partial<Question> {
  const deserializedProperties: EditorProperties = deserialize(editorProperties, {});
  if (deserializedProperties.wideLogoMedia) {
    deserializedProperties.publisherLogo = createAssetUrl(deserializedProperties.wideLogoMedia);
  }
  return {
    id,
    options,
    editorProperties: deserializedProperties,
  };
}
function fromServerOutcomes(poll: ServerPollProperties): Question | undefined | null {
  const { pollType } = poll;
  const appendPollTypeFn = appendPollType(pollType);
  if (poll.outcomes) {
    return appendPollTypeFn(transformQuestionFromServer(poll.outcomes));
  }
  if (pollType === POLL_TYPE.OPEN_QUESTION) {
    return createEmptyQuestion(pollType);
  }
  return null;
}
function toServerOutcomes(outcomes?: Question | null): ServerQuestion | undefined | null {
  if (!outcomes) {
    return null;
  }
  return createServerQuestion(outcomes, outcomes.options);
}
export function generateAppHtml(pollProperties: PollProperties, publisherName: string, editionId: EditionID) {
  const pageData = {
    [DEEP_LINK_PARAMS]: {
      publisherName,
      editionId,
    },
  };
  return renderHtml(pollProperties, pageData);
}
function transformToServerQuestion(question: Question): ServerQuestion {
  const layoutType = getLayoutType(question);
  const renderCount = getChoiceCountForLayout(layoutType);
  const options = _.take(question.options, renderCount);
  return createServerQuestion(question, options);
}
function createServerQuestion(question: Question, options: Options): ServerQuestion {
  return {
    id: question.id,
    // Publisher logo is used to display the logo in the CMS but the wideLogoMedia is used in the app.
    editorProperties: serialize(_.omit(question.editorProperties, ['publisherLogo'])),
    options,
  };
}
function getImageAssets(pollProperties: any) {
  const questions = _.concat(pollProperties.questions, pollProperties.outcomes).filter(Boolean);
  const optionsAssetIds = _.flatten(questions.map(question => question.options)).map(option => option.imageAssetId);
  const additionalAssets = _.flatten(
    questions
      .map(question => question.editorProperties)
      .map(editorProps => [
        (editorProps.explanationPage && editorProps.explanationPage.imageAssetId) || null,
        editorProps.backgroundAssetId || null,
      ])
  );
  return _.concat(optionsAssetIds, additionalAssets).filter(Boolean);
}
function serialize<T>(properties: T): string {
  return encodeURI(JSON.stringify(properties));
}
function deserialize<T extends {}>(data: string | null, defaultValue: T): T {
  if (!data) {
    return defaultValue;
  }
  return JSON.parse(decodeURI(data));
}
