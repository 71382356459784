import _ from 'lodash';

import { DEFAULT_ADS_MIN_SLOTS } from 'config/adsConfig';
import { ShareOption, DEFAULT_ANALYTICS_GEO } from 'config/constants';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';
import { isInfiniteAdsEnabled } from 'utils/publisherSettings/publisherSettingsUtils';

import {
  CountryList,
  LanguageList,
} from 'views/onboarding/containers/SettingsView/tabs/SuperAdminTab/countryLanguageLists';

import { ContentAccessLists, CountryCodeCategory } from 'types/countries';
import { Publisher, PublisherType, PublisherFeature } from 'types/publishers';

export const allCountries = CountryList.map(country => country.value);
export const GeneratedPublisherType = enumObject({
  PUBLISHER: 'Publisher',
  OUR_STORIES: 'Our Stories',
});
export type GeneratedPublisherTypeEnum = Enum<typeof GeneratedPublisherType>;
export const NONE = 'None';
// These are the default properties to set when creating a new publisher
export const PUBLISHER_DEFAULTS = {
  primaryColor: '#999999',
  secondaryColor: '#FFFFFF',
};
// These are the default properties to set when creating a show publisher
// TODO (mchrapana): should we maybe set those in account service?
export const SHOW_PUBLISHER_DEFAULTS = {
  topsnapLimit: 20,
  videoModeEnabled: true,
  dynamicAdsEnabled: true,
};
export const findPublisherType = (publisher: Publisher) => {
  if (publisher.isOurStories) {
    return GeneratedPublisherType.OUR_STORIES;
  }
  return GeneratedPublisherType.PUBLISHER;
};
const getCountriesAvailable = _.memoize(contentAccessList => {
  if (!contentAccessList || !contentAccessList.list) {
    return [];
  }
  if (contentAccessList.isWhitelist) {
    return contentAccessList.list;
  }
  return allCountries.filter(country => !contentAccessList.list.includes(country));
});
const getAllAvailableCountries = _.memoize(contentAccessLists => {
  if (!contentAccessLists) {
    return [];
  }
  const allAvailable = [
    ...getCountriesAvailable(contentAccessLists[CountryCodeCategory.DISCOVER_FEED]),
    ...getCountriesAvailable(contentAccessLists[CountryCodeCategory.SEARCH]),
    ...getCountriesAvailable(contentAccessLists[CountryCodeCategory.SHARE]),
    ...getCountriesAvailable(contentAccessLists[CountryCodeCategory.SNAPCODE]),
  ];
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Set<any>' is not an array type or a string t... Remove this comment to see the full error message
  return [...new Set(allAvailable)];
});
export const generatePublisherDetails = _.memoize(pub => {
  const publisher = {};
  (publisher as any).name = pub.name.trim();
  (publisher as any).type = pub.test ? PublisherType.TEST : PublisherType.NORMAL;
  (publisher as any).isOurStories = pub.publisherType === GeneratedPublisherType.OUR_STORIES;
  (publisher as any).audioClassification = pub.audioClassification;
  if (isInfiniteAdsEnabled(pub)) {
    // The first value on defaultAdSlots defines when the first ad should show up
    (publisher as any).advancedAdsLatestFirstSlot = _.get(pub, 'advancedAdsLatestFirstSlot', pub.defaultAdSlots[0]);
    (publisher as any).advancedAdsMinSlots = _.get(pub, 'advancedAdsMinSlots', DEFAULT_ADS_MIN_SLOTS);
    // We need to set this number to be really high to make sure that all ads are going to show up
    (publisher as any).advancedAdsMaxSlots = _.get(pub, 'advancedAdsMaxSlots', 999);
    // The last number before the INFNITE_ADS_ID (*) should define the min spacing between ads
    // So if we have: 2,3,INFINITE_ADS_ID, then 3 will be the minimum spacing between ads
    (publisher as any).advancedAdsMinSpacing = _.get(
      pub,
      'advancedAdsMinSpacing',
      pub.defaultAdSlots[pub.defaultAdSlots.length - 2]
    );
  }
  // TODO (dlipowicz): Remove when whitelistCountry has been replaced by localContent in the ranking team
  (publisher as any).whitelistCountry = pub.localContent;
  (publisher as any).homeCountry = pub.homeCountry;
  if (pub.contentAccessLists) {
    const contentAccessLists = Object.keys(pub.contentAccessLists).reduce((acc, key) => {
      if (
        !pub.contentAccessLists[key] ||
        !pub.contentAccessLists[key].list ||
        pub.contentAccessLists[key].list === DEFAULT_ANALYTICS_GEO
      ) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        acc[key] = { isWhitelist: false, list: [] };
        return acc;
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[key] = {
        isWhitelist: pub.contentAccessLists[key].isWhitelist,
        list: pub.contentAccessLists[key].list.map((val: any) => val.toLowerCase()),
      };
      return acc;
    }, {});
    // TODO (dlipowicz): Remove when whitelistDistributionCountry has been replaced by contentAccessLists in the ranking team
    (publisher as any).whitelistDistributionCountry = getAllAvailableCountries(contentAccessLists);
    // TODO (dlipowicz): Remove when blacklistCountry has been replaced by contentAccessLists in the ranking team
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const whitelistDiscoverFeed = getCountriesAvailable(contentAccessLists[CountryCodeCategory.DISCOVER_FEED]);
    if (whitelistDiscoverFeed) {
      if (whitelistDiscoverFeed.length === 0) {
        (publisher as any).blacklistCountry = [];
      } else {
        let countries = (publisher as any).whitelistDistributionCountry;
        if (countries.length === 0) {
          countries = CountryList.map(country => country.value);
        }
        (publisher as any).blacklistCountry = countries.filter(
          (country: any) => !whitelistDiscoverFeed.includes(country)
        );
      }
    }
    (publisher as any).contentAccessLists = contentAccessLists;
    (publisher as any).contentAccessLists[CountryCodeCategory.DEFAULT] = undefined;
  }
  return publisher;
});
export const findPublisherTypeWithTest = (publisher: Publisher) => {
  const test = publisher.type === PublisherType.TEST;
  const publisherType = findPublisherType(publisher);
  return {
    publisherType,
    test,
  };
};
export const formatContentAccessLists = (contentAccessLists: ContentAccessLists) => {
  return (Object.keys(CountryCodeCategory) as CountryCodeCategory[]).reduce(
    (acc: ContentAccessLists, key: CountryCodeCategory) => {
      if (key === CountryCodeCategory.DEFAULT) {
        return acc;
      }
      const contentAccessList = contentAccessLists && contentAccessLists[key];
      if (!contentAccessList || (!contentAccessList.isAllowlist && contentAccessList.list.length === 0)) {
        // Default is all countries are avilable
        acc[key] = { isAllowlist: true, list: allCountries };
      } else if (contentAccessList.isAllowlist) {
        acc[key] = contentAccessList;
      } else {
        // If the list was previously a blocklist, convert to a allowlist
        acc[key] = {
          isAllowlist: true,
          list: allCountries.filter(country => !contentAccessList.list.includes(country.toUpperCase())),
        };
      }
      return acc;
    },
    {} as ContentAccessLists
  );
};
export const formatPublisherForEditing = (publisher: Publisher) => {
  const publisherTypeWithTest = findPublisherTypeWithTest(publisher);
  let { defaultShareOption } = publisher;
  if (defaultShareOption === ShareOption.SHARE_LIVE) {
    defaultShareOption = ShareOption.SHARE_LIVE_ARCHIVED;
  }
  const primaryLanguage = publisher.primaryLanguage && publisher.primaryLanguage.toLowerCase();
  let { allowlistLanguage } = publisher;
  if (publisher.blocklistLanguage && publisher.blocklistLanguage.length > 0) {
    const blocklist = LanguageList.map(country => country.value);
    // The blocklist is being deprecated - convert the blocklist to an allowlist if one exists.
    allowlistLanguage = blocklist.filter(country => !publisher.blocklistLanguage.includes(country));
  }
  const contentAccessLists = formatContentAccessLists(publisher.contentAccessLists);
  return {
    ...publisherTypeWithTest,
    primaryLanguage,
    defaultShareOption,
    allowlistLanguage,
    contentAccessLists,
    id: publisher.id,
  };
};
export const preparePublisherForSaving = (publisher: Publisher) => {
  const editedPublisher = { ...publisher };
  return _.omit(editedPublisher, ['publisherType', 'test']);
};
export const PublisherStatus = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
};
export const publisherTypeChoices = [
  { value: GeneratedPublisherType.PUBLISHER, label: 'Publisher' },
  { value: GeneratedPublisherType.OUR_STORIES, label: 'Our Stories' },
  { value: PublisherFeature.TEAM_SNAPCHAT, label: 'Team Snapchat' },
  { value: PublisherFeature.FRIENDS_CAROUSEL, label: 'Friends Carousel' },
];
export const togglePublisherFeatures = (
  publisherFeatures: PublisherFeature[],
  newFeatures: PublisherFeature[],
  enabledValues: boolean[]
) => {
  newFeatures.forEach((newFeature, i) => {
    const enabled = enabledValues[i];
    if (enabled && !publisherFeatures.includes(newFeature)) {
      // Add item
      publisherFeatures.push(newFeature);
    } else if (!enabled && publisherFeatures.includes(newFeature)) {
      // Remove item
      const index = publisherFeatures.indexOf(newFeature);
      publisherFeatures.splice(index, 1);
    }
  });
  return publisherFeatures;
};
