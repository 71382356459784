import { get } from 'lodash';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { replace } from 'state/router/actions/routerActions';
import { getRedirectPath } from 'state/router/selectors/routerSelectors';

import { SNAP_SSO_CLIENT_ID } from 'config/constants';
import { snapSSO } from 'utils/apis/snapSSOAPI';
import * as grapheneUtils from 'utils/grapheneUtils';
import * as localStorageUtils from 'utils/localStorageUtils';
import { redirectToUrl } from 'utils/locationUtils';
import { errorCodeToMessage } from 'utils/router/authErrorUtils';

import * as authActionTypes from './authActionTypes';

import type { UrlQuery } from 'types/endpoint';
import type { Dispatch, GetState } from 'types/redux';

// No need of dispatch, because we are redirecting user to another page
// We will lose his redux state up till now
export const login = () => (): Promise<unknown> => {
  grapheneUtils.incrementCounter('Login.SnapAuth', { action: 'redirectToAccountService' });
  redirectToUrl(
    snapSSO.login({
      client_id: SNAP_SSO_CLIENT_ID,
      referrer: window.location.href,
    })
  );
  return Promise.resolve();
};

const logoutFromSnapchatOnly = createCallAction(
  {
    type: authActionTypes.LOGOUT,
  },
  {
    method: 'POST',
    endpoint: snapSSO.logout(),
    credentials: 'include',
  }
);

export const logout = ({
  redirect = '/login',
  query = {},
  keepRedirect = true,
}: {
  redirect: string;
  query: UrlQuery;
  keepRedirect: boolean;
}) => (dispatch: Dispatch, getState: GetState) => {
  grapheneUtils.incrementCounter('Login.SnapAuth', { action: `logout.${get(query, 'error', 'noError')}` });
  return dispatch(logoutFromSnapchatOnly).then(() => {
    localStorageUtils.clearLocalStorage();
    return dispatch(replace(getRedirectPath(getState())({ query, newPath: redirect, keepRedirect })));
  });
};

export const logoutWithError = (errorCode: any) => (dispatch: Dispatch) => {
  return dispatch(exports.logout({ query: errorCodeToMessage(errorCode), redirect: '/login' }));
};

export const setSnapAuthTicket = (ticket: string) => ({
  type: authActionTypes.SNAP_AUTH_TICKET,
  ticket,
});

export const refreshToken = () => (dispatch: Dispatch) => {
  grapheneUtils.incrementCounter('Login.SnapAuth', { action: 'refreshToken' });
  return dispatch(
    createCallAction(
      {
        type: authActionTypes.SNAP_REFRESH_TOKEN,
      },
      {
        endpoint: snapSSO.refreshToken(),
        method: 'POST',
        // include the necessary accounts cookies with the request
        credentials: 'include',
        // don't automatically follow redirects to the login page if user is not logged-in as it would fail due to CORS
        redirect: 'manual',
      }
    )
  ).catch(() => {
    return dispatch(logoutWithError('EXPIRED_SESSION'));
  });
};

export const logoutFromSnapAndShowLogin = () => (dispatch: Dispatch) => {
  return dispatch(logoutFromSnapchatOnly).then(() => dispatch(login()));
};
