import _ from 'lodash';

import * as editorActions from 'state/editor/actions/editorActions';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as tilesActions from 'state/tiles/actions/tilesActions';

import { RichSnapComponentType } from 'config/constants';
import { GetState } from 'src/types/redux';
import { buildComponentIdForTileId } from 'utils/componentUtils';

const TILE_COMPONENT_TYPES = new Set([
  RichSnapComponentType.TILE,
  RichSnapComponentType.TILE_PLACEHOLDER,
  RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER,
]);
export default (on: any) => {
  on(tilesActions.NOTIFY_TILE_ID_CHANGED, reactivateTileComponent);
};
// The id for a given tile is derived from its image asset id, and so changes whenever the
// image changes. In turn, the component id is derived from the tile id. As such, whenever
// the tile id changes, we need to re-activate the tile component in the UI using the newly
// created id.
function reactivateTileComponent(action: any, dispatch: any, getState: GetState) {
  const { editionId, newTileId, snapId, segmentId } = _.get(action, ['params']) || {};
  if (snapId || editionId || segmentId) {
    const activeEdition = editorSelectors.getActiveEdition(getState());
    const activeTopsnap = editorSelectors.getActiveTopsnap(getState());
    const activeSegmentId = editorSelectors.getActiveSegmentReduxId(getState());
    const activeComponent = componentsSelectors.getActiveComponent(getState());
    if (activeEdition) {
      const userHasSameTopsnapSelected = activeTopsnap && snapId === activeTopsnap.id;
      const userHasSameSegmentSelected = segmentId === activeSegmentId;
      const userHasTileComponentSelected =
        (activeComponent && TILE_COMPONENT_TYPES.has((activeComponent as any).componentType)) ||
        activeComponent === null;
      if (userHasTileComponentSelected && (userHasSameTopsnapSelected || userHasSameSegmentSelected)) {
        const componentId = newTileId ? buildComponentIdForTileId(newTileId) : null;
        if (activeComponent === null || (activeComponent && componentId !== (activeComponent as any).componentId)) {
          dispatch(editorActions.activateTileComponent(componentId));
        }
      }
    }
  }
}
