import invariant from 'invariant';

import {
  RichSnapComponentType,
  RichSnapActiveComponentType,
  RichPublisherComponentType,
  ComponentTypeEnum,
} from 'config/constants';

import type { SnapId } from 'types/common';
import type { RichSnapComponent, RichSnapComponentId } from 'types/components';
import { Snap } from 'types/snaps';
import { Tile, TileID } from 'types/tiles';

export const isTileComponent = (component?: RichSnapComponent | null): boolean => {
  if (!component) {
    return false;
  }
  return (
    component.componentType === RichSnapComponentType.TILE_PLACEHOLDER ||
    component.componentType === RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER ||
    component.componentType === RichSnapComponentType.TILE
  );
};

const tileComponentIdRegex = new RegExp(
  `${RichSnapComponentType.TILE}|${RichSnapComponentType.TILE_PLACEHOLDER}|${RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER}`
);
export const isTileComponentId = (tileId: string) => {
  return tileComponentIdRegex.test(tileId);
};

const subtitleComponentIdRegex = new RegExp(`${RichSnapComponentType.SUBTITLES}`);
export const isSubtitleComponentId = (componentId: string) => {
  return subtitleComponentIdRegex.test(componentId);
};

export const isSnapComponent = (component: RichSnapComponent): boolean => {
  if (!component) {
    return false;
  }
  return (
    component.componentType === RichSnapComponentType.SNAP_PLACEHOLDER ||
    component.componentType === RichSnapComponentType.SNAP
  );
};

export const bottomHasSnapNoAttachmentsCheck = (snapId: SnapId) => {
  // In the attachment editor page in segments, if there is no attachment the snapId is SNAP_PLACEHOLDER-0
  // getSnapByIdFn('SNAP_PLACEHOLDER-0') returns null and stops the isBottomSnap(snap) from working
  // so the bottomSnapNoAttachmentsCheck is needed to handle this specific case
  if (typeof snapId === 'number') {
    return false;
  }
  return snapId.match(new RegExp(`${RichSnapActiveComponentType.SNAP_PLACEHOLDER}`));
};

export const buildComponentId = (componentType: ComponentTypeEnum, id: number | string) => {
  return `${componentType}-${id}`;
};

export const buildComponentIdForSnapId = (snapId: SnapId) => {
  return buildComponentId(RichSnapComponentType.SNAP, snapId);
};

export const buildComponentIdForSnap = (snap: Snap) => {
  return buildComponentIdForSnapId(snap.id);
};

export const buildComponentIdForTileId = (tileId: TileID) => {
  return buildComponentId(RichSnapComponentType.TILE, tileId);
};

export const buildComponentIdForTileLogoId = (tileLogoId: string | number) => {
  return buildComponentId(RichPublisherComponentType.TILE_LOGO, tileLogoId);
};

export const buildComponentIdForTile = (tile: Tile) => {
  return buildComponentIdForTileId(tile.id);
};

export const entityIdFromComponentId = (componentType: ComponentTypeEnum, id: RichSnapComponentId) => {
  const regex = new RegExp(`^${componentType}`);
  invariant(regex.test(id), `Invalid component id (${id}) for type (${componentType})`);

  const entityId = id.replace(new RegExp(`^${componentType}-`), '');

  // Id might not be numeric
  const numericId = parseInt(entityId, 10);
  return Number.isNaN(Number(entityId)) ? entityId : numericId;
};

export const extractSnapIdFromComponentId = (componentId: RichSnapComponentId): SnapId => {
  return entityIdFromComponentId(RichSnapComponentType.SNAP, componentId);
};

export const extractTileLogoIdFromComponentId = (componentId: RichSnapComponentId) => {
  if (componentId.match(new RegExp(`${RichPublisherComponentType.TILE_LOGO_PLACEHOLDER}`))) {
    return entityIdFromComponentId(RichPublisherComponentType.TILE_LOGO_PLACEHOLDER, componentId);
  }

  return entityIdFromComponentId(RichPublisherComponentType.TILE_LOGO, componentId);
};

export const extractTileIdFromComponentId = (componentId: RichSnapComponentId) => {
  if (componentId.match(new RegExp(`${RichSnapComponentType.TILE_PLACEHOLDER}`))) {
    return entityIdFromComponentId(RichSnapComponentType.TILE_PLACEHOLDER, componentId);
  }

  return entityIdFromComponentId(RichSnapComponentType.TILE, componentId);
};
