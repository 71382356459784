import type { Merge } from 'type-fest';

import type { ShareOptionEnum, SnapTagEnum, ExternalSnapSource } from 'config/constants';

import { AnalyticsV2Snap } from 'views/analytics/components/AnalyticsTopsnapPreview/AnalyticsTopsnapPreview';

import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';
import type { ServerPollProperties } from 'types/polls';

export enum SnapRelationship {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

export type Shot = {
  id: string;
  videoTrackId: string;
  startTimeMs: number;
  durationMs: number;
  startFrame: number;
  endFrame: number;
};

export type Chapter = {
  id: number | string;
  shots: Shot[] | undefined | null;
};
export type Chapters = {
  shotDetectionStartDate: number;
  shotDetectionEndDate: number;
  chapters: Chapter[] | undefined | null;
};

export type CameoSnapModel = {
  previewUrl: string;
  cameoId: number | undefined | null | string | undefined | null;
  revision: number | undefined | null | string | undefined | null;
};

export enum TopType {
  IMAGE = 'IMAGE',
}

export enum BottomType {
  REMOTE_WEB = 'REMOTE_WEB',
}

export type SnapCommon = {
  readonly id: SnapId;
  // Individual snap types use string constants instead of enum values due to flow issue:
  // https://github.com/facebook/flow/issues/2377
  readonly relatedSnaps?: {
    [SnapRelationship.TOP]: TopSnap;
    [SnapRelationship.BOTTOM]: BottomSnap;
  };
  readonly relatedSnapIds: {
    [key in SnapRelationship]: SnapId;
  };
  readonly shareOption: ShareOptionEnum;
  overlayImageAssetId?: AssetID;
  name: string | undefined | null;
  decorations: {
    discover: {
      tags: {
        [key in SnapTagEnum]: string[];
      };
      shots: Shot[] | undefined | null;
    };
  };
  isContentDeleted: boolean;
  externalSnapDeeplink?: string;
  lastUpdatedAt: string;
  createdAt: string;
  storyId?: string;
  cameoSnapModel: CameoSnapModel | undefined | null;
  externalSnapSource: ExternalSnapSource;
  // This id should be the our story snap id in the form W7...
  originalSnapId: string;
  readonly categoryId: string | undefined | null;
  readonly creativeId?: CreativeID;
};
// flow comment syntax below is workaround for these two issues:
// https://github.com/babel/babel-eslint/issues/443
// https://youtrack.jetbrains.com/issue/WEB-26408

export type CreativeID = string;

export type UnknownSnap = Merge<
  SnapCommon,
  {
    type: TopsnapType.UNKNOWN;
    readonly assetId: AssetID;
  }
>;

type Lens = {
  id: number | undefined | null;
  scancodeId: string | undefined | null;
};

export type VideoSnap = Merge<
  SnapCommon,
  {
    type: TopsnapType.VIDEO;
    readonly videoAssetId: AssetID;
    readonly imageAssetId: AssetID;
    readonly subtitlesAssetIds?: AssetID[];
    readonly headline: string | undefined | null;
    readonly callToAction: string | undefined | null;
    readonly circular: boolean | undefined | null;
    readonly lens?: Lens | null;
  }
>;

export type BitmojiVideoSnap = Merge<
  SnapCommon,
  {
    type: TopsnapType.BITMOJI_REMOTE_VIDEO;
    readonly remoteVideoId: AssetID;
  }
>;

export type ImageSnap = Merge<
  SnapCommon,
  {
    type: TopsnapType.IMAGE;
    readonly imageAssetId: AssetID;
    readonly lens?: Lens | null;
  }
>;

export type SubscribeSnap = Merge<
  SnapCommon,
  {
    type: BottomSnapType.SUBSCRIBE;
    readonly imageAssetId: AssetID;
    readonly creativeId?: CreativeID;
    readonly subscriptionText?: string;
    readonly subscriptionNotificationText?: string;
  }
>;

export type WebPageSnap = Merge<
  SnapCommon,
  {
    imageAssetIds: Array<string>;
    longformVideoAssetIds: Array<string>;
    audioAssetIds: Array<string>;
    appHtml: string;
    editorHtml: string;
    attachmentIds: Array<number>;
    processingComplete: boolean;
  }
>;

export type PollSnap = Merge<
  Merge<WebPageSnap, ServerPollProperties>,
  {
    type: BottomSnapType.POLL;
  }
>;

export type ArticleSnap = Merge<
  WebPageSnap,
  {
    type: BottomSnapType.ARTICLE;
    originalUrl?: string;
  }
>;

export type WebSnap = {
  remoteUrl: string;
  allowExternalSharing: boolean;
  sharingMethod: string;
  allowedCountries: Array<string>;
  useReferrer: boolean;
};

export type RemoteWebSnap = Merge<
  Merge<SnapCommon, WebSnap>,
  {
    type: BottomSnapType.REMOTE_WEB;
  }
>;

export type BitmojiRemoteWebSnap = Merge<
  Merge<SnapCommon, WebSnap>,
  {
    type: TopsnapType.BITMOJI_REMOTE_WEB;
  }
>;

export type CameoSnap = Merge<
  SnapCommon,
  {
    type: TopsnapType.CAMEOS_CONTENT;
  }
>;

export type LongformVideoSnap = Merge<
  SnapCommon,
  {
    type: BottomSnapType.LONGFORM_VIDEO;
    readonly longformVideoAssetId: number | undefined | null;
    readonly manifestUrl: string | undefined | null;
  }
>;

export type CameraAttachmentSnap = Merge<
  SnapCommon,
  {
    type: BottomSnapType.CAMERA_ATTACHMENT;
    settingsNamespace: string | undefined | null;
  }
>;

export type SingleAssetSnap = Merge<
  SnapCommon,
  {
    type: TopsnapType.SINGLE_ASSET;
    videoAssetId: AssetID | null | undefined;
    chapters?: Chapters;
    isAdsDisabled?: boolean;
  }
>;

export type TopSnap =
  | VideoSnap
  | ImageSnap
  | UnknownSnap
  | BitmojiRemoteWebSnap
  | BitmojiVideoSnap
  | CameoSnap
  | SingleAssetSnap;

export type BottomSnap = ArticleSnap | PollSnap | RemoteWebSnap | LongformVideoSnap | SubscribeSnap;

// TODO(bkotsopoulos): BitmojiRemoteWebSnap should be with TopSnap, but there are too many
// places that take a TopSnap type and expect imageAssetId to be present.
// ehouston: Added CameoSnap here since it dosn't use imageAssetId either.
export type Snap = TopSnap | BottomSnap;

export type NormalizedSnap = {
  id: SnapId;
  type: SnapType;
  relatedSnaps: {
    [k in SnapRelationship]: Snap | AnalyticsV2Snap;
  };
  relatedSnapIds: {
    [k in SnapRelationship]: SnapId;
  };
};

export type TagsMap = {
  [k in SnapTagEnum]: string[];
};

export enum TopsnapType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  SINGLE_ASSET = 'SINGLE_ASSET',
  CAMEOS_CONTENT = 'CAMEOS_CONTENT',
  UNKNOWN = 'UNKNOWN',
  BITMOJI_REMOTE_VIDEO = 'BITMOJI_REMOTE_VIDEO',
  BITMOJI_REMOTE_WEB = 'BITMOJI_REMOTE_WEB',
}

export enum BottomSnapType {
  ARTICLE = 'ARTICLE',
  CAMERA_ATTACHMENT = 'CAMERA_ATTACHMENT',
  LONGFORM_VIDEO = 'LONGFORM_VIDEO',
  POLL = 'POLL',
  REMOTE_WEB = 'REMOTE_WEB',
  SUBSCRIBE = 'SUBSCRIBE',
}

export const SnapType = {
  ...TopsnapType,
  ...BottomSnapType,
};

export type SnapType = TopsnapType | BottomSnapType;
