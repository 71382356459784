import classNames from 'classnames';
import { memoize } from 'lodash';
import React from 'react';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import createFilterOptions from 'react-select-fast-filter-options';
import VirtualizedSelect from 'react-virtualized-select';
import InlineSVG from 'svg-inline-react';

import { logout } from 'state/auth/actions/authActions';
import { setActivePublisher } from 'state/user/actions/userActions';

import { CrossOrigin } from 'config/constants';
import secondaryButtonStyle from 'styles/secondaryButton.scss';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';

import PublisherDropdownMenu from 'views/common/components/PublisherDropdownMenu/PublisherDropdownMenu';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/next.svg.inline' or its... Remove this comment to see the full error message
import nextIcon from 'images/next.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/profilePlaceholder.svg.... Remove this comment to see the full error message
import profilePlaceholderIcon from 'images/profilePlaceholder.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/signOut.svg.inline' or ... Remove this comment to see the full error message
import signOutIcon from 'images/signOut.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'images/x.svg.inline' or its co... Remove this comment to see the full error message
import xIcon from 'images/x.svg.inline';

import style from './PublisherDropdown.scss';

import type { PublisherID, Publisher } from 'types/publishers';

type $Call1<F extends (...args: any) => any, A> = F extends (a: A, ...args: any) => infer R ? R : never;
type $Call3<F extends (...args: any) => any, A, B, C> = F extends (a: A, b: B, c: C, ...args: any) => infer R
  ? R
  : never;
const ROW_HEIGHT = 60;
const ROW_PADDING_OFFSET = 5;
type Props = {
  publisherLogoUrl: string;
  mutablePublisherName: string;
  publisherId: PublisherID;
  publishers: Publisher[];
  userName: string;
  logout: $Call3<typeof logout, string, {}, boolean>;
  setActivePublisher: $Call1<typeof setActivePublisher, PublisherID>;
  onDropdownOpen: () => void;
  onDropdownClose: () => void;
};
type State = {
  isOpen: boolean;
  isPublisherSelectionOpen: boolean;
};
export class PublisherDropdown extends React.Component<Props, State> {
  state = {
    isOpen: false,
    isPublisherSelectionOpen: false,
  };

  getOptions = memoize(publishers => {
    const { mutablePublisherName, publisherLogoUrl, publisherId } = this.props;
    const options = publishers
      .filter((publisher: any) => publisher.id !== publisherId)
      .map((publisher: any) => ({
        label: publisher.mutablePublisherName,
        icon: publisher.defaultFilledIconId && createAssetUrl(publisher.defaultFilledIconId),
        value: publisher.id,
      }));
    // Move the active publisher to the top of the list so it always shows up first
    return [{ label: mutablePublisherName, icon: publisherLogoUrl, value: publisherId }, ...options];
  });

  setPublisher = (publisherId: PublisherID) => () => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    this.props.setActivePublisher(publisherId);
    this.closeDropdown();
  };

  closeDropdown = () => {
    this.props.onDropdownClose();
    this.setState({
      isOpen: false,
      isPublisherSelectionOpen: false,
    });
  };

  openDropdown = () => {
    this.props.onDropdownOpen();
    this.setState({
      isOpen: true,
    });
  };

  openPublisherSelection = () => {
    this.setState({
      isPublisherSelectionOpen: true,
    });
  };

  handleLogout = () => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
    this.props.logout(undefined, {}, false);
  };

  optionRenderer = memoize(({ style: optionStyle, key, option }) => {
    const { publisherId } = this.props;
    return (
      <div style={optionStyle} key={key}>
        <PublisherDropdownMenu
          menuLogoIcon={option.icon}
          menuName={option.label}
          onClick={this.setPublisher(option.value)}
          menuSubtitle={
            option.value === publisherId ? getMessageFromId('publisher-dropdown-subtitle-currently-selected') : null
          }
          menuActionSvg={nextIcon}
        />
      </div>
    );
  });

  renderDropdownToggle = () => {
    if (!this.state.isOpen) {
      return (
        <div
          onClick={this.openDropdown}
          className={classNames(style.toggleButton, secondaryButtonStyle.secondaryButton)}
        >
          <span className={(style as any).publisherName}>{this.props.mutablePublisherName}</span>
          <div className={style.publisherLogo}>
            <img
              className={style.publisherLogoIcon}
              crossOrigin={CrossOrigin.USE_CREDENTIALS}
              src={this.props.publisherLogoUrl}
              alt="publisherLogo"
            />
          </div>
        </div>
      );
    }
    return (
      <div onClick={this.closeDropdown} className={classNames(style.closeButton, secondaryButtonStyle.secondaryButton)}>
        <InlineSVG className={style.closeIcon} src={xIcon} />
      </div>
    );
  };

  renderDropdown = () => {
    if (!this.state.isOpen) {
      return null;
    }
    if (!this.state.isPublisherSelectionOpen) {
      const isSinglePublisher = this.props.publishers.length === 1;
      return (
        <div className={style.dropdown}>
          <PublisherDropdownMenu
            menuLogoIcon={this.props.publisherLogoUrl}
            menuName={getMessageFromId(
              isSinglePublisher ? 'publisher-dropdown-current-publisher' : 'publisher-dropdown-switch-publisher'
            )}
            menuSubtitle={this.props.mutablePublisherName}
            menuActionSvg={isSinglePublisher ? null : nextIcon}
            onClick={isSinglePublisher ? null : this.openPublisherSelection}
            data-test="PublisherDropdown.dropdown.switchPublisher"
          />
          <PublisherDropdownMenu
            menuLogoSvg={profilePlaceholderIcon}
            menuName={getMessageFromId('signout-nav-click')}
            menuSubtitle={this.props.userName}
            menuActionSvg={signOutIcon}
            onClick={this.handleLogout}
            data-test="PublisherDropdown.dropdown.logoutPublisher"
          />
        </div>
      );
    }
    const options = this.getOptions(this.props.publishers);
    return (
      <div className={style.publishersDropdown}>
        <VirtualizedSelect
          autoBlur
          autoFocus
          openOnFocus
          options={options}
          optionHeight={ROW_HEIGHT + ROW_PADDING_OFFSET}
          filterOptions={createFilterOptions({ options })}
          optionRenderer={this.optionRenderer}
        />
      </div>
    );
  };

  render() {
    return (
      <RootCloseWrapper noWrap onRootClose={this.closeDropdown}>
        <div className={style.container}>
          {this.renderDropdownToggle()}
          {this.renderDropdown()}
        </div>
      </RootCloseWrapper>
    );
  }
}
export default PublisherDropdown;
