import {
  DocumentNode,
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useQuery,
  useMutation,
} from '@apollo/client';
import { OperationVariables } from '@apollo/client/core/types';
import log from 'loglevel';

import { GetCreators } from 'gql/queries/creator/__ssp-generated__/GetCreators';
import { CREATOR_PUBLISHER_QUERIES } from 'gql/queries/creator/creatorPublisherQuery';
import { GET_CREATORS } from 'gql/queries/creator/creatorsQuery';
import { SETTINGS_GET_PUBLISHER } from 'gql/queries/publisher/settingsPublisherQuery';
import { GET_USER_INFO } from 'gql/queries/user/userInfoQuery';
import {
  PublisherQueryResponseType,
  PublisherQueryType,
  PublisherQueryVarType,
} from 'gql/types/publisherQueryTypeEnum';

import apolloClient from './apollo';

function useClientQuery<T, U extends OperationVariables>(
  clientName: string,
  query: DocumentNode | TypedDocumentNode<T, U>,
  queryOptions?: QueryHookOptions<T, U>
): QueryResult<T, U> {
  return useQuery<T, U>(query, { ...queryOptions, context: { ...queryOptions?.context, clientName } });
}

export function useProxyQuery<T, U extends OperationVariables>(
  query: DocumentNode | TypedDocumentNode<T, U>,
  queryOptions?: QueryHookOptions<T, U>
): QueryResult<T, U> {
  return useClientQuery<T, U>('story-studio-proxy', query, queryOptions);
}

export function useAdsQuery<T, U extends OperationVariables>(
  query: DocumentNode | TypedDocumentNode<T, U>,
  queryOptions?: QueryHookOptions<T, U>
): QueryResult<T, U> {
  return useClientQuery<T, U>('ads-gateway', query, queryOptions);
}

function useClientMutation<T, U>(
  clientName: string,
  mutation: DocumentNode | TypedDocumentNode<T, U>,
  mutationOptions?: MutationHookOptions<T, U>
): MutationTuple<T, U> {
  return useMutation<T, U>(mutation, { ...mutationOptions, context: { ...mutationOptions?.context, clientName } });
}

export function useProxyMutation<T, U>(
  mutation: DocumentNode | TypedDocumentNode<T, U>,
  mutationOptions?: MutationHookOptions<T, U>
): MutationTuple<T, U> {
  return useClientMutation<T, U>('story-studio-proxy', mutation, mutationOptions);
}

export async function fetchPublisherDataWithApolloQuery(
  query: DocumentNode,
  publisherId: string,
  isSuperAdmin: Boolean = false
) {
  const variablesForQuery = query === SETTINGS_GET_PUBLISHER ? { publisherId, isSuperAdmin } : { publisherId };

  const { data, error } = await apolloClient.query<PublisherQueryResponseType, PublisherQueryVarType>({
    query,
    variables: { ...variablesForQuery },
    fetchPolicy: 'network-only',
  });

  if (error) {
    log.error('Failed to fetch Publisher data: ', error.message);
    return error;
  }
  return data.publisher;
}

export async function fetchCreatorPublisher(
  queryType: PublisherQueryType,
  username: String,
  isSuperAdmin: Boolean = false
) {
  const query = CREATOR_PUBLISHER_QUERIES[queryType];
  const extra = queryType === PublisherQueryType.Settings ? { isSuperAdmin } : {};
  const variables = {
    input: {
      username,
    },
    ...extra,
  };
  const { data } = await apolloClient.query({ query, variables, fetchPolicy: 'network-only' });
  return data.creator;
}

export async function fetchUserInfo(getAllProfiles: Boolean) {
  const variables = {
    input: {
      getAllProfiles,
    },
  };
  const { data } = await apolloClient.query({ query: GET_USER_INFO, variables, fetchPolicy: 'network-only' });
  return data;
}

export async function fetchCreators(): Promise<GetCreators> {
  const { data } = await apolloClient.query({ query: GET_CREATORS, fetchPolicy: 'cache-first' });
  return data;
}
