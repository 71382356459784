import { CDN_BASE_URLS, CrossOrigin, INTERNAL_IMG_URLS } from 'config/constants';
import { isDataUri } from 'utils/uriUtils';

export type ImageLoaderResponse = {
  url: string;
  identifier: string;
  canLoad: boolean;
};

export class ImageLoader {
  _crossOrigin: typeof CrossOrigin[keyof typeof CrossOrigin];

  constructor({ crossOrigin = CrossOrigin.USE_CREDENTIALS }: any = {}) {
    this._crossOrigin = crossOrigin;
  }

  getCrossOrigin() {
    return this._crossOrigin;
  }

  loadImages(imageURIs: any) {
    if (!Array.isArray(imageURIs)) {
      throw new Error('ImageLoader.loadImages() expects an array');
    }

    return Promise.all(imageURIs.map(this.loadImage.bind(this)));
  }

  loadImage(imageURI: any): any {
    if (typeof imageURI !== 'string' || imageURI === '') {
      throw new Error('ImageLoader.loadImage() expects an imageURI');
    }

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.crossOrigin = this.getCORS(imageURI, image);

      image.onload = () => {
        resolve(image);
      };
      image.onerror = () => {
        reject(new Error(`Could not load image: ${imageURI}`));
      };
      image.src = imageURI;
    });
  }

  canLoadImage(imageURI: string, node: string): Promise<ImageLoaderResponse> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      const result: ImageLoaderResponse = {
        url: imageURI,
        identifier: node,
        canLoad: false,
      };
      image.crossOrigin = this.getCORS(imageURI, image);

      image.onload = () => {
        result.canLoad = true;
        resolve(result);
      };
      image.onerror = () => {
        resolve(result);
      };
      image.src = imageURI;
    });
  }

  private getCORS(imageURI: string, image: HTMLImageElement) {
    if (INTERNAL_IMG_URLS.some(internalURL => imageURI.startsWith(internalURL))) {
      return CrossOrigin.USE_CREDENTIALS;
    }

    if (this._crossOrigin && !isDataUri(imageURI) && !CDN_BASE_URLS.some(cdnBase => imageURI.startsWith(cdnBase))) {
      return this._crossOrigin;
    }

    return null;
  }

  async loadImageToBlobAndImage(imageURI: string) {
    const image = await this.loadImage(imageURI);
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return Promise.reject();
    }
    ctx.drawImage(image, 0, 0);
    return new Promise(resolve =>
      canvas.toBlob(blob => {
        resolve({ blob, image });
      })
    );
  }
}

export const loadImage = (imageURI: any, options: any) => new ImageLoader(options).loadImage(imageURI);
export const canLoadImage = (imageUrl: string, node: string) => new ImageLoader().canLoadImage(imageUrl, node);
export const loadImageToBlobAndImage = (imageURI: any, options: any) =>
  new ImageLoader(options).loadImageToBlobAndImage(imageURI);
