import type { ReactNode } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ModerationAppealStatus, TileBuildStatus } from 'types/build';
import type { AudienceEnum, ModerationViolationEnum } from 'types/moderation';
import { Audience, ModerationViolation } from 'types/moderation';

export const subscribersOnlyShort = (
  <FormattedMessage
    id="followers-only-short"
    description="Story will only be visible to followers"
    defaultMessage="Followers only"
  />
);

export const nooneUnder18Short = (
  <FormattedMessage id="over-18-only" description="Only suitable for users over 18" defaultMessage="Over 18 Only" />
);

export const nooneInSensitiveCountriesShort = (
  <FormattedMessage
    id="no-sensitive-countries"
    description="Will be excluded from sensitive countries"
    defaultMessage="No Sensitive Countries"
  />
);

export const nooneUnder18 = (
  <FormattedMessage
    id="noone-under-18"
    description="Only suitable for users over 18"
    defaultMessage="People under 18 cannot see this content"
  />
);

export const nooneInSensitiveCountries = (
  <FormattedMessage
    id="noone-in-sensitive-countries"
    description="Will be excluded from sensitive countries"
    defaultMessage="Not suitable for sensitive countries"
  />
);

export const subscribersOnly = (
  <FormattedMessage
    id="followers-only"
    description="Only suitable for existing followers"
    defaultMessage="The content will appear only to followers."
  />
);

export const audienceToShortString = (audience: AudienceEnum): ReactNode | undefined | null => {
  switch (audience) {
    case Audience.NOBODY_UNDER_18:
      return nooneUnder18Short;
    case Audience.NOBODY_IN_SENSITIVE_COUNTRIES:
      return nooneInSensitiveCountriesShort;
    case Audience.SUBSCRIBERS_ONLY:
      return subscribersOnlyShort;
    case Audience.ANYONE:
      return null;
    default:
      throw new Error(`Unknown audience seen: ${audience}`);
  }
};

export const audienceToLongString = (audience: AudienceEnum): ReactNode | undefined | null => {
  switch (audience) {
    case Audience.NOBODY_IN_SENSITIVE_COUNTRIES:
      return nooneInSensitiveCountries;
    case Audience.NOBODY_UNDER_18:
      return nooneUnder18;
    case Audience.SUBSCRIBERS_ONLY:
      return subscribersOnly;
    case Audience.ANYONE:
      return null;
    default:
      throw new Error(`Unknown audience seen: ${audience}`);
  }
};

export const shouldShowModeration = (audienceList?: Array<AudienceEnum> | null): boolean => {
  if (!audienceList || audienceList.length === 0) {
    return false;
  }

  if (audienceList.length === 1 && audienceList[0] === Audience.ANYONE) {
    return false;
  }

  return true;
};

export const canAppealModeration = (tileBuildStatus: TileBuildStatus): boolean =>
  shouldShowModeration(tileBuildStatus.audience) &&
  tileBuildStatus.moderationAppealStatus === ModerationAppealStatus.AVAILABLE;

export const unknownViolation = (
  <FormattedMessage id="unknown-violation" description="Type of moderation violation" defaultMessage="Unknown" />
);

export const sexualImagery = (
  <FormattedMessage
    id="sexual-imagery-violation"
    description="Type of moderation violation"
    defaultMessage="Sexual Imagery"
  />
);

export const sexualCaption = (
  <FormattedMessage
    id="sexual-caption-violation"
    description="Type of moderation violation"
    defaultMessage="Sexual Caption"
  />
);

export const sexualSolicitation = (
  <FormattedMessage
    id="sexual-solicitation-violation"
    description="Type of moderation violation"
    defaultMessage="Sexual Solicitation"
  />
);

export const sexActs = (
  <FormattedMessage id="sex-acts-violation" description="Type of moderation violation" defaultMessage="Sex Acts" />
);

export const profanity = (
  <FormattedMessage id="profanity-violation" description="Type of moderation violation" defaultMessage="Profanity" />
);

export const harassment = (
  <FormattedMessage id="harassment-violation" description="Type of moderation violation" defaultMessage="Harassment" />
);

export const hateSpeech = (
  <FormattedMessage
    id="hate-speech-violation"
    description="Type of moderation violation"
    defaultMessage="Hate Speech"
  />
);

export const privacyInvasion = (
  <FormattedMessage
    id="privacy-invasion-violation"
    description="Type of moderation violation"
    defaultMessage="Privacy Invasion"
  />
);

export const threats = (
  <FormattedMessage id="threats-violation" description="Type of moderation violation" defaultMessage="Threats" />
);

export const humanViolence = (
  <FormattedMessage
    id="human-violence-violation"
    description="Type of moderation violation"
    defaultMessage="Human Violence"
  />
);

export const animalViolence = (
  <FormattedMessage
    id="animal-violence-violation"
    description="Type of moderation violation"
    defaultMessage="Animal Violence"
  />
);

export const weapons = (
  <FormattedMessage id="weapons-violation" description="Type of moderation violation" defaultMessage="Weapons" />
);

export const marijuana = (
  <FormattedMessage id="marijuana-violation" description="Type of moderation violation" defaultMessage="Marijuana" />
);

export const tobacco = (
  <FormattedMessage id="tobacco-violation" description="Type of moderation violation" defaultMessage="Tobacco" />
);

export const alcohol = (
  <FormattedMessage id="alcohol-violation" description="Type of moderation violation" defaultMessage="Alcohol" />
);

export const drugs = (
  <FormattedMessage id="drugs-violation" description="Type of moderation violation" defaultMessage="Drugs" />
);

export const selfHarmSuicide = (
  <FormattedMessage
    id="self-harm-suicide-violation"
    description="Type of moderation violation"
    defaultMessage="Self Harm Suicide"
  />
);

export const terrorist = (
  <FormattedMessage id="terrorist-violation" description="Type of moderation violation" defaultMessage="Terrorist" />
);

export const commericalSpam = (
  <FormattedMessage
    id="commercial-spam-violation"
    description="Type of moderation violation"
    defaultMessage="Commercial Spam"
  />
);

export const bodilyFluids = (
  <FormattedMessage
    id="bodily-fluids-violation"
    description="Type of moderation violation"
    defaultMessage="Bodily Fluids"
  />
);

export const dangeriousDriving = (
  <FormattedMessage
    id="dangerious-driving-violation"
    description="Type of moderation violation"
    defaultMessage="Dangerious Driving"
  />
);

export const unsafeUrl = (
  <FormattedMessage id="unsafe-url-violation" description="Type of moderation violation" defaultMessage="Unsafe URL" />
);

export const abusiveLanguage = (
  <FormattedMessage
    id="abusive-language-violation"
    description="Type of moderation violation"
    defaultMessage="Abusive Language"
  />
);

export const racy = (
  <FormattedMessage id="racy-violation" description="Type of moderation violation" defaultMessage="Racy" />
);

export const meme = (
  <FormattedMessage id="meme-violation" description="Type of moderation violation" defaultMessage="Meme" />
);

export const intellectualProperty = (
  <FormattedMessage
    id="intellectual-property-violation"
    description="Type of moderation violation"
    defaultMessage="Intellectual Property"
  />
);

export const clickbait = (
  <FormattedMessage id="clickbait-violation" description="Type of moderation violation" defaultMessage="Clickbait" />
);

export const bodyImagery = (
  <FormattedMessage
    id="body-imagery-violation"
    description="Type of moderation violation"
    defaultMessage="Body Imagery"
  />
);

export const relationshipsIntimacy = (
  <FormattedMessage
    id="relantionships-intimacy-violation"
    description="Type of moderation violation"
    defaultMessage="Relationships Intimacy"
  />
);

export const sexualizedObjects = (
  <FormattedMessage
    id="sexualized-objects-violation"
    description="Type of moderation violation"
    defaultMessage="Sexualized Objects"
  />
);

export const humanOrAnimalViolence = (
  <FormattedMessage
    id="human-or-animal-violence-violation"
    description="Type of moderation violation"
    defaultMessage="Violent or Disturbing"
  />
);
export const adultContent = (
  <FormattedMessage
    id="adult-content-violation"
    description="Adult content moderation violation"
    defaultMessage="Adult Content"
  />
);

export const commercialPromotionAndSolicitation = (
  <FormattedMessage
    id="commercial-promotion-and-solicitation-violation"
    description="Commercial promotion and solicitation moderation violation"
    defaultMessage="Commercial Promotion and Solicitation"
  />
);

export const creativeToolsAndQuality = (
  <FormattedMessage
    id="creative-tools-and-quality-violation"
    description="Creative tools and quality moderation violation"
    defaultMessage="Creative Tools and Quality"
  />
);

export const csam = (
  <FormattedMessage id="csam-violation" description="CSAM moderation violation" defaultMessage="CSAM" />
);

export const dangerousOrHarmfulActivities = (
  <FormattedMessage
    id="dangerous-or-harmful-activities-violation"
    description="Dangerous or harmful activities moderation violation"
    defaultMessage="Dangerous or Harmful Activities"
  />
);

export const deceptiveContentOrFalseInformation = (
  <FormattedMessage
    id="deceptive-content-or-false-information-violation"
    description="Deceptive content or false information moderation violation"
    defaultMessage="Deceptive Content or False Information"
  />
);

export const invasionOfPrivacy = (
  <FormattedMessage
    id="invasion-of-privacy-violation"
    description="Invasion of privacy moderation violation"
    defaultMessage="Invasion of Privacy"
  />
);

export const newsAndPoliticalCommentary = (
  <FormattedMessage
    id="news-and-political-commentary-violation"
    description="News and political commentary moderation violation"
    defaultMessage="News and Political Commentary"
  />
);

export const regulatedGoods = (
  <FormattedMessage
    id="regulated-goods-violation"
    description="Regulated goods moderation violation"
    defaultMessage="Regulated Goods"
  />
);

export const selfHarm = (
  <FormattedMessage id="self-harm-violation" description="Self-harm moderation violation" defaultMessage="Self Harm" />
);

export const sexualServices = (
  <FormattedMessage
    id="sexual-services-violation"
    description="Sexual services moderation violation"
    defaultMessage="Sexual Services"
  />
);

export const spam = (
  <FormattedMessage id="spam-violation" description="Spam moderation violation" defaultMessage="Spam" />
);

export const terrorismOrExtremism = (
  <FormattedMessage
    id="terrorism-or-extremism-violation"
    description="Terrorism or extremism moderation violation"
    defaultMessage="Terrorism or Extremism"
  />
);

export const violence = (
  <FormattedMessage id="violence-violation" description="Violence moderation violation" defaultMessage="Violence" />
);

export const other = (
  <FormattedMessage
    id="other-violation-message"
    description="Other violation"
    defaultMessage="Your {Snap/Tile Image/Title} has content that may not be appropriate"
  />
);

export const inModerationSubscribersOnly = (
  <FormattedMessage
    id="story-in-moderation-queue-followers-only"
    description="Story has not yet been moderated so will be visible only to followers"
    defaultMessage="Story in moderation queue: Followers only"
  />
);

export const tilesInModerationQeueue = (
  <FormattedMessage
    id="tiles-in-moderation-queue"
    description="Story tiles have not yet been moderated"
    defaultMessage="Tiles in moderation queue"
  />
);

export const storyReachAffected = (
  <FormattedMessage
    id="story-reach-affected"
    description="All leading tiles flagged by moderation so story reach is affected"
    defaultMessage="Story's reach affected by content violations"
  />
);

export const violationToString = (violation: ModerationViolationEnum): ReactNode | undefined | null => {
  switch (violation) {
    case ModerationViolation.SEXUAL_IMAGERY:
      return sexualImagery;
    case ModerationViolation.SEXUAL_CAPTION:
      return sexualCaption;
    case ModerationViolation.SEXUAL_SOLICITATION:
      return sexualSolicitation;
    case ModerationViolation.SEX_ACTS:
      return sexActs;
    case ModerationViolation.PROFANITY:
      return profanity;
    case ModerationViolation.HARASSMENT:
      return harassment;
    case ModerationViolation.HATE_SPEECH:
      return hateSpeech;
    case ModerationViolation.PRIVACY_INVASION:
      return privacyInvasion;
    case ModerationViolation.THREATS:
      return threats;
    case ModerationViolation.HUMAN_VIOLENCE:
      return humanViolence;
    case ModerationViolation.ANIMAL_VIOLENCE:
      return animalViolence;
    case ModerationViolation.WEAPONS:
      return weapons;
    case ModerationViolation.MARIJUANA:
      return marijuana;
    case ModerationViolation.TOBACCO:
      return tobacco;
    case ModerationViolation.ALCOHOL:
      return alcohol;
    case ModerationViolation.DRUGS:
      return drugs;
    case ModerationViolation.SELF_HARM_SUICIDE:
      return selfHarmSuicide;
    case ModerationViolation.TERRORIST:
      return terrorist;
    case ModerationViolation.COMMERCIAL_SPAM:
      return commericalSpam;
    case ModerationViolation.BODILY_FLUIDS:
      return bodilyFluids;
    case ModerationViolation.DANGEROUS_DRIVING:
      return dangeriousDriving;
    case ModerationViolation.UNKNOWN:
      return unknownViolation;
    case ModerationViolation.UNSAFE_URL:
      return unsafeUrl;
    case ModerationViolation.ABUSIVE_LANGUAGE:
      return abusiveLanguage;
    case ModerationViolation.RACY:
      return racy;
    case ModerationViolation.MEME:
      return meme;
    case ModerationViolation.INTELLECTUAL_PROPERTY:
      return intellectualProperty;
    case ModerationViolation.CLICKBAIT:
      return clickbait;
    case ModerationViolation.BODY_IMAGERY:
      return bodyImagery;
    case ModerationViolation.RELATIONSHIPS_INTIMACY:
      return relationshipsIntimacy;
    case ModerationViolation.SEXUALIZED_OBJECTS:
      return sexualizedObjects;
    case ModerationViolation.HUMAN_OR_ANIMAL_VIOLENCE:
      return humanOrAnimalViolence;
    case ModerationViolation.ADULT_CONTENT:
      return adultContent;

    case ModerationViolation.COMMERCIAL_PROMOTION_AND_SOLICITATION:
      return commercialPromotionAndSolicitation;

    case ModerationViolation.CREATIVE_TOOLS_AND_QUALITY:
      return creativeToolsAndQuality;

    case ModerationViolation.CSAM:
      return csam;

    case ModerationViolation.DANGEROUS_OR_HARMFUL_ACTIVITIES:
      return dangerousOrHarmfulActivities;

    case ModerationViolation.DECEPTIVE_CONTENT_OR_FALSE_INFORMATION:
      return deceptiveContentOrFalseInformation;

    case ModerationViolation.INVASION_OF_PRIVACY:
      return invasionOfPrivacy;

    case ModerationViolation.NEWS_AND_POLITICAL_COMMENTARY:
      return newsAndPoliticalCommentary;

    case ModerationViolation.REGULATED_GOODS:
      return regulatedGoods;

    case ModerationViolation.SELF_HARM:
      return selfHarm;

    case ModerationViolation.SEXUAL_SERVICES:
      return sexualServices;

    case ModerationViolation.SPAM:
      return spam;

    case ModerationViolation.TERRORISM_OR_EXTREMISM:
      return terrorismOrExtremism;

    case ModerationViolation.VIOLENCE:
      return violence;
    default:
      return other; // fallback for backward compatibility
  }
};

// Render violations list converting violation to readable text separated with a separator.
export const renderViolations = (violations: ModerationViolationEnum[], separator: string = ','): JSX.Element => {
  return (
    <>
      {violations.map((violation, index) => {
        const violationText = violationToString(violation);
        if (index === 0) {
          return <span key={violation}>{violationText}</span>;
        }
        return (
          <span key={violation}>
            {separator} {violationText}
          </span>
        );
      })}
    </>
  );
};
