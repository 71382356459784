/* eslint-disable camelcase */

import moment from 'moment-timezone';

import {
  GetProfileAnalytics_profileAnalytics,
  GetProfileAnalytics_profileAnalytics_uniqueViewers13To17ForTimestamp,
} from 'gql/queries/analytics/__ssp-generated__/GetProfileAnalytics';
import {
  GetRevenue_revenue_Revenue_unfiltered,
  GetRevenue_revenue_Revenue_unfiltered_bundlesRevenue,
  GetRevenue_revenue_Revenue_unfiltered_profileRevenue,
  GetRevenue_revenue_Revenue_unfiltered_profileRevenue_aggregate,
} from 'gql/queries/analytics/__ssp-generated__/GetRevenue';
import {
  GetSavedStoryAnalytics_savedStoryAnalytics_savedStoryAnalytics_savedStoryInsights,
  GetSavedStoryAnalytics_savedStoryAnalytics_savedStoryAnalytics_savedStoryInsights_savedStoryMetadata,
  GetSavedStoryAnalytics_savedStoryAnalytics_savedStoryAnalytics_savedStoryKPIs,
} from 'gql/queries/analytics/__ssp-generated__/GetSavedStoryAnalytics';
import { GetSnapAnalytics_snapAnalytics_snapAnalytics_analyticsEntries_analytics } from 'gql/queries/analytics/__ssp-generated__/GetSnapAnalytics';
import {
  GetSpotlightAnalytics_spotlightAnalytics_profileSpotlightAnalytics,
  GetSpotlightAnalytics_spotlightAnalytics_profileSpotlightAnalytics_aggregate,
  GetSpotlightAnalytics_spotlightAnalytics_profileSpotlightAnalytics_timeSeries_summary,
  GetSpotlightAnalytics_spotlightAnalytics_spotlightAnalyticsForPage,
} from 'gql/queries/analytics/__ssp-generated__/GetSpotlightAnalytics';
import { GetSpotlightPosts_spotlightAnalytics_spotlightAnalyticsForPage } from 'gql/queries/analytics/__ssp-generated__/GetSpotlightPosts';
import {
  GetStoryPageAnalytics_snapAnalytics_snapAnalytics_analyticsEntries,
  GetStoryPageAnalytics_snapAnalytics_snapAnalytics_analyticsEntries_analytics,
  GetStoryPageAnalytics_storyAnalytics_StoryAnalyticsResult_profileStoryAnalytics,
  GetStoryPageAnalytics_tileAnalytics_tileAnalytics,
} from 'gql/queries/analytics/__ssp-generated__/GetStoryPageAnalytics';
import { GetStoryDraftingSnaps_storyDraftingSnaps } from 'gql/queries/spotlight/__ssp-generated__/GetStoryDraftingSnaps';
import { Enum, enumObject } from 'utils/enum';

import type { SnapId } from 'types/common';
import { StoryState } from 'types/editions';
import type { EditionID } from 'types/editions';
import type { Tile as SnapTile } from 'types/tiles';

type PollChoice = {
  assetId: string;
  choice_id: string;
  displayPercentage?: string;
  publisher_text?: string;
  vote_count: number;
};

export type PollQuestion = {
  choices: PollChoice[];
  correctAnswer: boolean;
  title: string;
  total_votes: number;
};

export type Poll = {
  questions: PollQuestion[];
  outcomes: PollQuestion;
};

export type ServiceStatus = {
  status?: string;
};

// TODO: these metrics are very similar across snaps, processed snaps and stories, unify
export type SnapMetricResults = {
  id: string | undefined | null;
  eventTime: string | undefined | null;
  publisherId: string | undefined | null;
  publisherName: string | undefined | null;
  editionId: string | undefined | null;
  revisionId: string | undefined | null;
  geo: string | undefined | null;
  snap_id: string | undefined | null;
  snap_index: number;
  media_name: string | undefined | null;
  media_type: string;
  media_id: number;
  overlay_media_id: string | undefined | null;
  longform_media_type: string | undefined | null;
  snap_time_sec: number;
  snap_time_ms: number;
  total_time_viewed: string | undefined | null;
  avg_total_time_viewed: string | undefined | null;
  top_snap_time_viewed: string | undefined | null;
  longform_time_viewed: string | undefined | null;
  top_snap_view_count: string | undefined | null;
  longform_view_count: string | undefined | null;
  longform_text_view_count: string | undefined | null;
  longform_video_view_count: string | undefined | null;
  total_share_count: string | undefined | null;
  total_screenshot_count: string | undefined | null;
  subscriber_count: string | undefined | null;
  subscriber_count_25: string | undefined | null;
  subscriber_count_50: string | undefined | null;
  subscriber_count_75: string | undefined | null;
  publisher_subscribe_count: string | undefined | null;
  unique_dau: string | undefined | null;
  unique_dau_25: string | undefined | null;
  unique_dau_50: string | undefined | null;
  unique_dau_75: string | undefined | null;
  unique_edition_completers: string | undefined | null;
  unique_mau: string | undefined | null;
  unique_viewers: string | undefined | null;
  unique_subscriber_viewers: string | undefined | null;
  unique_top_snap_view_count: string | undefined | null;
  unique_top_snap_view_per_user: string | undefined | null;
  unique_longform_viewers: string | undefined | null;
  longform_avg_conversion: string | undefined | null;
  longform_conversion_rate: string | undefined | null;
  completion_rate: string | undefined | null;
  snapExitRate: string | undefined | null;
  poll: Poll | undefined | null;
  segment_id: string | undefined | null;
  dau_1_day: string | undefined | null;
  dau_2_days: string | undefined | null;
  dau_3_4_days: string | undefined | null;
  dau_5_6_7_days: string | undefined | null;
  unique_13_17_viewers: string | undefined | null;
  unique_18_24_viewers: string | undefined | null;
  unique_25_34_viewers: string | undefined | null;
  unique_35_plus_viewers: string | undefined | null;
  unique_female_viewers: string | undefined | null;
  unique_male_viewers: string | undefined | null;
  unique_unknown_age_viewers: string | undefined | null;
  unique_unknown_gender_viewers: string | undefined | null;
  unique_female_13_17_viewers: string | undefined | null;
  unique_female_18_20_viewers: string | undefined | null;
  unique_female_21_24_viewers: string | undefined | null;
  unique_female_25_34_viewers: string | undefined | null;
  unique_female_35_plus_viewers: string | undefined | null;
  unique_female_unknown_viewers: string | undefined | null;
  unique_male_13_17_viewers: string | undefined | null;
  unique_male_18_20_viewers: string | undefined | null;
  unique_male_21_24_viewers: string | undefined | null;
  unique_male_25_34_viewers: string | undefined | null;
  unique_male_35_plus_viewers: string | undefined | null;
  unique_male_unknown_viewers: string | undefined | null;
  unique_unknown_13_17_viewers: string | undefined | null;
  unique_unknown_18_20_viewers: string | undefined | null;
  unique_unknown_21_24_viewers: string | undefined | null;
  unique_unknown_25_34_viewers: string | undefined | null;
  unique_unknown_35_plus_viewers: string | undefined | null;
  unique_unknown_unknown_viewers: string | undefined | null;
};

export type FormattedSnapMetricResults = {
  id: string | undefined | null;
  eventTime: string | undefined | null;
  publisherId: string | undefined | null;
  publisherName: string | undefined | null;
  editionId: string;
  revisionId: string | undefined | null;
  geo: string | undefined | null;
  snapId: string;
  snapIndex: number;
  mediaName: string | undefined | null;
  mediaType: string | undefined | null;
  mediaId: string | undefined | null;
  overlayMediaId: string | undefined | null;
  longformMediaType: string;
  snapTimeSec: string | undefined | null;
  totalTimeViewed: string | undefined | null;
  avgTotalTimeViewed: string | undefined | null;
  topSnapTimeViewed: string | undefined | null;
  longformTimeViewed: string | undefined | null;
  topSnapViewCount: string | undefined | null;
  segmentId: string | undefined | null;
  longformViewCount: string | undefined | null;
  longformTextViewCount: string | undefined | null;
  longformVideoViewCount: string | undefined | null;
  totalShareCount: string | undefined | null;
  totalScreenshotCount: string | undefined | null;
  subscriberCount: string | undefined | null;
  publisherSubscribeCount: string | undefined | null;
  uniqueDau: string | undefined | null;
  uniqueEditionCompleters: string | undefined | null;
  uniqueMau: string | undefined | null;
  uniqueViewers: string | undefined | null;
  uniqueTopSnapViewCount: string | undefined | null;
  uniqueTopSnapViewPerUser: string | undefined | null;
  uniqueLongformViewers: string | undefined | null;
  longformAvgConversion: string | undefined | null;
  longformConversionRate: string | undefined | null;
  completionRate: string | undefined | null;
  snapExitRate: string | undefined | null;
  poll: string | undefined | null;
  dau1Day: string | undefined | null;
  dau2Days: string | undefined | null;
  dau34Days: string | undefined | null;
  dau567Days: string | undefined | null;
  unique1317Viewers: string | undefined | null;
  unique1824Viewers: string | undefined | null;
  unique2534Viewers: string | undefined | null;
  unique35PlusViewers: string | undefined | null;
  uniqueFemaleViewers: string | undefined | null;
  uniqueMaleViewers: string | undefined | null;
  uniqueUnknownAgeViewers: string | undefined | null;
  uniqueUnknownGenderViewers: string | undefined | null;
  uniqueFemale1317Viewers: string | undefined | null;
  uniqueFemale1820Viewers: string | undefined | null;
  uniqueFemale2124Viewers: string | undefined | null;
  uniqueFemale2534Viewers: string | undefined | null;
  uniqueFemale35PlusViewers: string | undefined | null;
  uniqueFemaleUnknownViewers: string | undefined | null;
  uniqueMale1317Viewers: string | undefined | null;
  uniqueMale1820Viewers: string | undefined | null;
  uniqueMale2124Viewers: string | undefined | null;
  uniqueMale2534Viewers: string | undefined | null;
  uniqueMale35PlusViewers: string | undefined | null;
  uniqueMaleUnknownViewers: string | undefined | null;
  uniqueUnknown1317Viewers: string | undefined | null;
  uniqueUnknown1820Viewers: string | undefined | null;
  uniqueUnknown2124Viewers: string | undefined | null;
  uniqueUnknown2534Viewers: string | undefined | null;
  uniqueUnknown35PlusViewers: string | undefined | null;
  uniqueUnknownUnknownViewers: string | undefined | null;
  uniqueSubscriberViewers: string | undefined | null;
};

export type FormattedSnapMetrics = FormattedSnapMetricResults[];

export type ProcessedSnapMetricResultsV2 = GetSnapAnalytics_snapAnalytics_snapAnalytics_analyticsEntries_analytics & {
  longformConversionRate: string | undefined | null;
  longformTimeViewed: string | undefined | null;
  uniqueLongformViewers: string | undefined | null;
  topSnapTimeViewed: string | undefined | null;
  uniqueDau: string | undefined | null;
  completionRate: string | undefined | null;
  snapExitRate: string | undefined | null;
  snapTimeSec: string | undefined | null;
  snapId: string;
  mediaId: string | undefined | null;
  mediaType: string | undefined | null;
};

export type ProcessedSnapMetricResults = GetSnapAnalytics_snapAnalytics_snapAnalytics_analyticsEntries_analytics & {
  id: string | undefined | null;
  eventTime: string | undefined | null;
  publisherId: string | undefined | null;
  publisherName: string | undefined | null;
  editionId: string | undefined | null;
  revisionId: string | undefined | null;
  geo: string | undefined | null;
  snapId: string;
  reachAffectedByModeration?: boolean | undefined | null;
  snapIndex: number | undefined | null;
  mediaName: string | undefined | null;
  mediaType: string | undefined | null;
  mediaId: string | undefined | null;
  overlayMediaId: string | undefined | null;
  longformMediaType: string | undefined | null;
  snapTimeSec: string | undefined | null;
  totalTimeViewed: string | undefined | null;
  avgTotalTimeViewed: string | undefined | null;
  topSnapTimeViewed: string | undefined | null;
  longformTimeViewed: string | undefined | null;
  topSnapViewCount: string | undefined | null;
  longformViewCount: string | undefined | null;
  longformTextViewCount: string | undefined | null;
  longformVideoViewCount: string | undefined | null;
  totalShareCount: string | undefined | null;
  totalScreenshotCount: string | undefined | null;
  subscriberCount: string | undefined | null;
  segmentId: string | undefined | null;
  publisherSubscribeCount: string | undefined | null;
  uniqueDau: string | undefined | null;
  uniqueEditionCompleters: string | undefined | null;
  uniqueMau: string | undefined | null;
  uniqueViewers: string | undefined | null;
  uniqueTopSnapViewCount: string | undefined | null;
  uniqueTopSnapViewPerUser: string | undefined | null;
  uniqueLongformViewers: string | undefined | null;
  longformAvgConversion: string | undefined | null;
  longformConversionRate: string | undefined | null;
  completionRate: string | undefined | null;
  snapExitRate: string | undefined | null;
  poll: string | undefined | null;
  dau1Day: string | undefined | null;
  dau2Days: string | undefined | null;
  dau34Days: string | undefined | null;
  dau567Days: string | undefined | null;
  unique1317Viewers: string | undefined | null;
  unique1824Viewers: string | undefined | null;
  unique2534Viewers: string | undefined | null;
  unique35PlusViewers: string | undefined | null;
  uniqueFemaleViewers: string | undefined | null;
  uniqueMaleViewers: string | undefined | null;
  uniqueUnknownAgeViewers: string | undefined | null;
  uniqueUnknownGenderViewers: string | undefined | null;
  uniqueFemale1317Viewers: string | undefined | null;
  uniqueFemale1820Viewers: string | undefined | null;
  uniqueFemale2124Viewers: string | undefined | null;
  uniqueFemale2534Viewers: string | undefined | null;
  uniqueFemale35PlusViewers: string | undefined | null;
  uniqueFemaleUnknownViewers: string | undefined | null;
  uniqueMale1317Viewers: string | undefined | null;
  uniqueMale1820Viewers: string | undefined | null;
  uniqueMale2124Viewers: string | undefined | null;
  uniqueMale2534Viewers: string | undefined | null;
  uniqueMale35PlusViewers: string | undefined | null;
  uniqueMaleUnknownViewers: string | undefined | null;
  uniqueUnknown1317Viewers: string | undefined | null;
  uniqueUnknown1820Viewers: string | undefined | null;
  uniqueUnknown2124Viewers: string | undefined | null;
  uniqueUnknown2534Viewers: string | undefined | null;
  uniqueUnknown35PlusViewers: string | undefined | null;
  uniqueUnknownUnknownViewers: string | undefined | null;
  uniqueSubscriberViewers: string | undefined | null;
  snapIndexWithoutAds: number;
  src: string | undefined | null;
  overlayMediaSrc: string | undefined | null;
  mediaUrl: string;
  seekPoint: string;
  duration: number;
  chapterId?: string;
};

export type ProcessedSnapsMetrics = ProcessedSnapMetricResults[];

export type StoryMetricKPI = {
  uniqueDau: string | undefined | null;
  publisherSubscribeCount: number | undefined | null;
  uniqueTopSnapViewPerUser: number | undefined | null;
  avgTotalTimeViewed: number;
  longformConversionRate: number;
  totalShareCount: number | undefined | null;
  uniqueTopSnapViewCount: number | undefined | null;
  uniqueSubscriberViewers: number | undefined | null;
  totalScreenshotCount: number | undefined | null;
  uniqueViewers: number | undefined | null;
  topSnapViewCount: number | undefined | null;
  longformViewCount: number | undefined | null;
  avgViewedPercentage: number | undefined | null;
  reachAffectedByModeration: boolean;
  uniqueViewersMale: number | undefined | null;
  uniqueViewersFemale: number | undefined | null;
  uniqueViewers13to17: number | undefined | null;
  uniqueViewers18to24: number | undefined | null;
  uniqueViewers25to34: number | undefined | null;
  uniqueViewers35Plus: number | undefined | null;
  publisherSubscribeCountPaid: number | undefined | null;
};

export type StoryMetricKPIPaid = {
  uniqueDau: string | undefined | null;
  uniqueDauPaid: string | undefined | null;
  publisherSubscribeCount: string | undefined | null;
  publisherSubscribeCountPaid: string | undefined | null;
  adsStats?: Record<string, unknown>[];
};

export type StoryMetricResults = {
  id: string | undefined | null;
  eventTime: string | undefined | null;
  publisherId: string | undefined | null;
  publisherName: string | undefined | null;
  editionId: string | undefined | null;
  revisionId: string | undefined | null;
  geo: string | undefined | null;
  snapId: string | undefined | null;
  snapIndex: number | undefined | null;
  mediaName: string | undefined | null;
  mediaType: string | undefined | null;
  mediaId: string | undefined | null;
  overlayMediaId: string | undefined | null;
  longformMediaType: string | undefined | null;
  snapTimeSec: string | undefined | null;
  totalTimeViewed: string | undefined | null;
  avgTotalTimeViewed: string | undefined | null;
  topSnapTimeViewed: string | undefined | null;
  longformTimeViewed: string | undefined | null;
  topSnapViewCount: string | undefined | null;
  longformViewCount: string | undefined | null;
  longformTextViewCount: string | undefined | null;
  longformVideoViewCount: string | undefined | null;
  totalShareCount: string | undefined | null;
  totalScreenshotCount: string | undefined | null;
  subscriberCount: string | undefined | null;
  publisherSubscribeCount: string | undefined | null;
  uniqueDau: string | undefined | null;
  uniqueEditionCompleters: string | undefined | null;
  uniqueMau: string | undefined | null;
  uniqueSubscriberViewers: string | undefined | null;
  uniqueViewers: string | undefined | null;
  uniqueTopSnapViewCount: string | undefined | null;
  uniqueTopSnapViewPerUser: string | undefined | null;
  uniqueLongformViewers: string | undefined | null;
  longformAvgConversion: string | undefined | null;
  longformConversionRate: string | undefined | null;
  completionRate: string | undefined | null;
  snapExitRate: string | undefined | null;
  poll: string | undefined | null;
  dau1Day: string | undefined | null;
  dau2Days: string | undefined | null;
  dau34Days: string | undefined | null;
  dau567Days: string | undefined | null;
  unique1317Viewers: string | undefined | null;
  unique1824Viewers: string | undefined | null;
  unique2534Viewers: string | undefined | null;
  unique35PlusViewers: string | undefined | null;
  uniqueFemaleViewers: string | undefined | null;
  uniqueMaleViewers: string | undefined | null;
  uniqueUnknownAgeViewers: string | undefined | null;
  uniqueUnknownGenderViewers: string | undefined | null;
  uniqueFemale1317Viewers: string | undefined | null;
  uniqueFemale1820Viewers: string | undefined | null;
  uniqueFemale2124Viewers: string | undefined | null;
  uniqueFemale2534Viewers: string | undefined | null;
  uniqueFemale35PlusViewers: string | undefined | null;
  uniqueFemaleUnknownViewers: string | undefined | null;
  uniqueMale1317Viewers: string | undefined | null;
  uniqueMale1820Viewers: string | undefined | null;
  uniqueMale2124Viewers: string | undefined | null;
  uniqueMale2534Viewers: string | undefined | null;
  uniqueMale35PlusViewers: string | undefined | null;
  uniqueMaleUnknownViewers: string | undefined | null;
  uniqueUnknown1317Viewers: string | undefined | null;
  uniqueUnknown1820Viewers: string | undefined | null;
  uniqueUnknown2124Viewers: string | undefined | null;
  uniqueUnknown2534Viewers: string | undefined | null;
  uniqueUnknown35PlusViewers: string | undefined | null;
  uniqueUnknownUnknownViewers: string | undefined | null;
  uniqueViewersMale: string | undefined | null;
  uniqueViewersFemale: string | undefined | null;
  uniqueViewers13to17: string | undefined | null;
  uniqueViewers18to24: string | undefined | null;
  uniqueViewers25to34: string | undefined | null;
  uniqueViewers35Plus: string | undefined | null;
};

export type StoryMetricResultsV2 = {
  // KPIs
  uniqueDau: string;
  publisherSubscribeCount: string;
  uniqueTopSnapViewPerUser: string | null;
  avgTotalTimeViewed: string | null;
  longformConversionRate: string;
  totalShareCount: string;
  totalScreenshotCount: string;
  avgViewedPercentage: string;
  longformViewCount: string;
  topSnapViewCount: string;
  uniqueSubscriberViewers: string;
  reachAffectedByModeration: boolean;

  // Demographics
  uniqueMale1317Viewers: string;
  uniqueMale1820Viewers: string;
  uniqueMale2124Viewers: string;
  uniqueMale2534Viewers: string;
  uniqueMale35PlusViewers: string;
  uniqueMaleUnknownViewers: string;
  uniqueFemale1317Viewers: string;
  uniqueFemale1820Viewers: string;
  uniqueFemale2124Viewers: string;
  uniqueFemale2534Viewers: string;
  uniqueFemale35PlusViewers: string;
  uniqueFemaleUnknownViewers: string;
  uniqueUnknown1317Viewers: string;
  uniqueUnknown1820Viewers: string;
  uniqueUnknown2124Viewers: string;
  uniqueUnknown2534Viewers: string;
  uniqueUnknown35PlusViewers: string;
  uniqueUnknownUnknownViewers: string;
};

export type StoryMetrics = {
  [key: string]: StoryMetricResults;
};

type DailyMetricResults = {
  eventTime: string;
  total_time_viewed: string;
  top_snap_time_viewed: string;
  longform_time_viewed: string;
  top_snap_view_count: string;
  longform_view_count: string;
  longform_text_view_count: string;
  longform_video_view_count: string;
  total_share_count: string;
  total_screenshot_count: string;
  subscriber_count: string;
  unique_dau: string;
  unique_subscriber_viewers: string;
  unique_edition_completers: string;
  unique_mau: string;
  unique_viewers: string;
  unique_top_snap_view_count: string;
  unique_top_snap_view_per_user: string;
  longform_avg_conversion: string;
  dau_1_day: string;
  dau_2_days: string;
  dau_3_4_days: string;
  dau_5_6_7_days: string;
  unique_13_17_viewers: string;
  unique_18_24_viewers: string;
  unique_25_34_viewers: string;
  unique_35_plus_viewers: string;
  unique_female_viewers: string;
  unique_male_viewers: string;
  unique_unknown_age_viewers: string;
  unique_unknown_gender_viewers: string;
  unique_female_13_17_viewers: string;
  unique_female_18_20_viewers: string;
  unique_female_21_24_viewers: string;
  unique_female_25_34_viewers: string;
  unique_female_35_plus_viewers: string;
  unique_female_unknown_viewers: string;
  unique_male_13_17_viewers: string;
  unique_male_18_20_viewers: string;
  unique_male_21_24_viewers: string;
  unique_male_25_34_viewers: string;
  unique_male_35_plus_viewers: string;
  unique_male_unknown_viewers: string;
  unique_unknown_13_17_viewers: string;
  unique_unknown_18_20_viewers: string;
  unique_unknown_21_24_viewers: string;
  unique_unknown_25_34_viewers: string;
  unique_unknown_35_plus_viewers: string;
  unique_unknown_unknown_viewers: string;
  date: string;
  longform_avg_conversion_25: string;
  longform_avg_conversion_50: string;
  longform_avg_conversion_75: string;
  avg_time_spent_25: string;
  avg_time_spent_50: string;
  avg_time_spent_75: string;
};

export type DailyMetrics = {
  [key: string]: DailyMetricResults;
};

export type Kpis = {
  dau: string | undefined | null;
  mau: string | undefined | null;
  coreUser: string | undefined | null;
  timeViewedSecs: string | undefined | null;
  completionRate: string | undefined | null;
  longformConversionRate: string | undefined | null;
  totalTimeViewed: string | undefined | null;
  uniqueTopsnapViewCount: string | undefined | null;
  uniqueTopsnapViewPerUser: string | undefined | null;
};

type KpiComparison = {
  currentRange: Kpis;
  previousRange: Kpis;
};

type Choice = {
  timestamp: number;
  runningTotal: number;
};

type TimelinesByChoice = {
  [key: string]: Choice[];
};

type PollTimeline = {
  pollTitle: string;
  timelinesByChoice: TimelinesByChoice;
  startTimestamp: number;
  endTimestamp: number;
};

export type AnalyticsStoryMetadata = {
  analyticsURL?: string;
  editionId: EditionID;
  expireTimePt: string | undefined | null;
  expireTimeMilli: number | undefined | null;
  firstSnapId: SnapId;
  headline?: string;
  isEmpty?: boolean;
  postTime: moment.Moment;
  postTimeMilli: number;
  postTimePt: string;
  state: StoryState;
  storySnapsCount: number;
  tileId: number;
  tileCover: SnapTile;
  tileImageURL?: string;
};

export type AnalyticsStories = AnalyticsStoryMetadata[];

export type CheetahAnalyticsStoryStats = {
  avgTimeViewed: number;
  editionSubscribeCount: number;
  uniqueDau: number;
};

export type CheetahAnalyticsStory = {
  metadata: AnalyticsStoryMetadata;
  stats: CheetahAnalyticsStoryStats;
};

export type CheetahAnalyticsStories = CheetahAnalyticsStory[];

export type PollTimelines = {
  [key: number]: PollTimeline;
};

export type TileStat = {
  editionId: number | string;
  headline: string;
  tileId: number | string;
  impressionPercentage: string;
  indexedCtr: string;
  avgTotalTimeViewed: string;
  uniqueTopsnapsPerUser: string;
  tileImageUrl: string;
  tileLogoUrl: string;
};

export type TileStats = TileStat[];

export type SnapMetrics = {
  [key: string]: SnapMetricResults;
};

export type DailyAnalytics = {
  kpiComparison: KpiComparison;
  regions: any; // TODO: flow: specify type,
  dailyMetrics: DailyMetrics;
};

export type LifestyleCategory = {
  value: string;
  score: number;
};

export type TopLifestyleCategories = LifestyleCategory[];

export type TrafficSource = {
  value: string;
  score: number;
  previousScore: number;
};

export type TrafficSources = TrafficSource[];

export type TopPerformingTilesStats = {
  tileStats: TileStats;
  globalAvgTotalTimeViewed: number;
  globalUniqueTopsnapsPerUser: number;
};

export type TopPerformingStoriesStats = {
  storyStats: CheetahAnalyticsStories;
  globalAvgUniqueDau: number;
  globalAvgTimeViewed: number;
  globalAvgEditionSubscribeCount: number;
};

export type InsightsAnalytics = {
  slcs: TopLifestyleCategories;
  trafficSources: TrafficSources;
  topPerformingTiles: TopPerformingTilesStats;
  topPerformingStories: TopPerformingStoriesStats;
};

export type StoryAnalytics = {
  editionMetrics: StoryMetrics;
  tileStats: TileStats;
};

export type SnapAnalytics = {
  index: number;
  longformMediaType: string;
  mediaName: string;
  mediaType?: string;
  snapIndex: number;
  src: string;
  type?: string;
  uniqueTopsnapViewerCount: number;
  snapId: string;
};

export type PreviewFrame = SnapAnalytics & {
  imageRow?: HTMLElement;
  imgErr?: Error;
  imgSrc?: string;
  index: number;
  overlayMediaSrc?: string;
  publisherLogoSrc?: string;
  title?: string;
};

export type Tile = {
  croppedImageAssetId?: number;
  headline: string;
};

export type Edition = {
  id?: number;
  tiles?: Tile[];
};

export type EditionDateRange = {
  from: moment.Moment | undefined | null;
  to: moment.Moment | undefined | null;
};

export type StorySnapMetrics = {
  name: string;
  conversion: number;
  uniques: number;
  screenshots: number;
  segmentId: string | undefined | null;
  shares: number;
  dropOffRates: number;
  mediaId: number;
  mediaType: string;
  poll: Poll | undefined | null;
  timeSpent: number;
  runTime: number;
  attachmentTimeSpent: number;
  completionRate: number;
  paidUniques?: number;
  paidShares?: number;
  paidScreenshots?: number;
  paidDropOffRates?: number;
  paidTimeSpent?: number;
  organicTimeSpent?: number;
  paidCompletionRate?: number;
  organicCompletionRate?: number;
};

export type StoryDemographMetrics = {
  name: string;
  paidFemale: number;
  paidUnknown: number;
  paidMale: number;
  female: number;
  male: number;
  unknown: number;
};

export type StoryDemographMetricsV2 = {
  name: string;
  paidFemale: number;
  paidUnknown: number;
  paidMale: number;
  female: number;
  male: number;
  unknown: number;
};

export type EditionDaily = {
  [k: string]: {
    dailyMetrics: {
      [k: string]: {
        editionDau: number;
      };
    };
    editionMetrics: StoryMetrics;
  };
};

export const AnalyticsStatsType = enumObject({
  ALL: 'ALL',
  PAID: 'PAID',
});

export type AnalyticsStatsTypeEnum = Enum<typeof AnalyticsStatsType>;

export type EmptyDemographicsObject = Record<string, null | string | undefined | number>;

// Revenue type aliases.
export type RevenueData = GetRevenue_revenue_Revenue_unfiltered;
export type ProfileRevenue = GetRevenue_revenue_Revenue_unfiltered_profileRevenue;
export type RevenueSummary = GetRevenue_revenue_Revenue_unfiltered_profileRevenue_aggregate;
export type RevenueMetric = Exclude<keyof RevenueSummary, '__typename'>;
export type BundlesRevenue = GetRevenue_revenue_Revenue_unfiltered_bundlesRevenue;

// Spotlight analytics type aliases
export type SpotlightMetric = Exclude<
  keyof GetSpotlightAnalytics_spotlightAnalytics_profileSpotlightAnalytics_timeSeries_summary,
  '__typename'
>;
export type SpotlightSummary = GetSpotlightAnalytics_spotlightAnalytics_profileSpotlightAnalytics_aggregate;
export type ProfileSpotlightAnalytics = GetSpotlightAnalytics_spotlightAnalytics_profileSpotlightAnalytics;

export type StoryAnalyticsResult = GetStoryPageAnalytics_storyAnalytics_StoryAnalyticsResult_profileStoryAnalytics;
// Missing fields
// readonly reachAffectedByModeration: boolean;

export type TileAnalyticsResult = ReadonlyArray<GetStoryPageAnalytics_tileAnalytics_tileAnalytics | null>;
export type TileAnalyticsMetric = GetStoryPageAnalytics_tileAnalytics_tileAnalytics | null | undefined;
export type SnapAnalyticsResultEntry = GetStoryPageAnalytics_snapAnalytics_snapAnalytics_analyticsEntries;
export type SnapAnalyticsResult = ReadonlyArray<GetStoryPageAnalytics_snapAnalytics_snapAnalytics_analyticsEntries | null>;
export type SnapAnalyticsEntry = GetStoryPageAnalytics_snapAnalytics_snapAnalytics_analyticsEntries_analytics;
export type StoryAnalyticsStat = GetStoryPageAnalytics_storyAnalytics_StoryAnalyticsResult_profileStoryAnalytics;
export type SpotlightAnalytics =
  | GetSpotlightAnalytics_spotlightAnalytics_spotlightAnalyticsForPage
  | GetSpotlightPosts_spotlightAnalytics_spotlightAnalyticsForPage;
export type ScheduledSpotlight = GetStoryDraftingSnaps_storyDraftingSnaps | null | undefined;

// Profile Analytics Section
export type ProfileSummary = GetSpotlightAnalytics_spotlightAnalytics_profileSpotlightAnalytics_aggregate;
export type ProfileAnalytics = GetProfileAnalytics_profileAnalytics;
export type ProfileGraphDataPoint = GetProfileAnalytics_profileAnalytics_uniqueViewers13To17ForTimestamp;
export type ProfileTimeSeriesResult = GetProfileAnalytics_profileAnalytics_uniqueViewers13To17ForTimestamp;

// Stories Analytics Section
export type StoriesAnalytics = GetSavedStoryAnalytics_savedStoryAnalytics_savedStoryAnalytics_savedStoryKPIs;

export enum ProfileMetricEnum {
  UniqueViewers = 'uniqueViewers',
  UniqueViewersSubscribers = 'uniqueViewersSubscribers',
  TotalTimeViewedMs = 'totalTimeViewedMs',
  TotalSubscribers = 'totalSubscribers',
}
export enum ProfileSubMetricEnum {
  AgeUniqueViewers = 'ageUniqueViewers',
  GenderUniqueViewers = 'genderUniqueViewers',
}
export type ProfileMetric = 'uniqueViewers' | 'uniqueViewersSubscribers' | 'totalTimeViewedMs' | 'totalSubscribers';
export type StoriesMetric =
  | 'uniqueViewers'
  | 'timeSpentMs'
  | 'timeViewedMs'
  | 'screenshots'
  | 'shares'
  | 'totalStoryViews'
  | 'attachmentConversion';

// Saved Story Analytics Section
export type SavedStoryAnalyticsResult = GetSavedStoryAnalytics_savedStoryAnalytics_savedStoryAnalytics_savedStoryInsights;
export type SavedStoryAnalyticsMetadata = Exclude<
  keyof GetSavedStoryAnalytics_savedStoryAnalytics_savedStoryAnalytics_savedStoryInsights_savedStoryMetadata,
  '__typename'
>;
