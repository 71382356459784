import type { Dispatch } from 'redux';

import { assertArg } from 'utils/assertionUtils';
import { ModalType } from 'utils/modalConfig';

import type { GetState } from 'types/redux';

export const SHOW_MODAL = 'modals/SHOW_MODAL';
export const HIDE_MODAL = 'modals/HIDE_MODAL';
export const SET_MODAL_CONFIG_PROPERTIES = 'modal/SET_MODAL_CONFIG_PROPERTIES';

// export it for testing
export let uniqueId = 0; // eslint-disable-line
export const showModal = (modalType: ModalType, modalSource: string, options: {} = {}) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(modalSource).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(options).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(modalType).is.inArray(Object.values(ModalType));

  return (dispatch: Dispatch, getState: GetState) => {
    return dispatch({
      type: SHOW_MODAL,
      payload: {
        modalType,
        modalId: `${modalSource}_${uniqueId++}`,
        options,
      },
    });
  };
};

export const hideModal = (modalId: string, results: {} = {}) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(modalId).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(results).is.object();
  return {
    type: HIDE_MODAL,
    payload: {
      modalId,
      results,
    },
  };
};

export const setModalConfigProperties = (modalId: string, diffObject: {}) => ({
  type: SET_MODAL_CONFIG_PROPERTIES,
  payload: {
    modalId,
    diffObject,
  },
});
