/* eslint-disable space-infix-ops */

export enum TileCropType {
  /* V1 (everywhere else) */
  VERTICAL = 'VERTICAL', // regular preview
  HORIZONTAL = 'HORIZONTAL', // 'featured' preview 1 per 'row' in
  COLLAPSED = 'COLLAPSED', // when the edition has been read we render this one

  /* V2 (my stories) */
  // https://snapchat.quip.com/BuibAf3aHa5O
  HORIZONTAL_V2 = 'HORIZONTAL_V2', // 1 edition in a row     [       ]
  MEDIUM_V2 = 'MEDIUM_V2', // 2 editions in a row    [  ] [  ]
  VERTICAL_V2 = 'VERTICAL_V2', // 3 editions in a row    [ ][ ][ ]
  NARROW_V2 = 'NARROW_V2', // 4+ editions in a row   [][][][]..
  COLLAPSED_V2 = 'COLLAPSED_V2', // edition has been read

  CHEETAH = 'CHEETAH',
  SQUARE = 'SQUARE',
}

export type TileConfig = {
  cropType: TileCropType;
  scale: number;
  fontSize?: number;
  lineHeight?: number;
  paddingLeft?: number;
  paddingBottom?: number;
};

/**
 * Coordinates might be defined from the top or the center of the crop
 */
export enum CoordinatesOrigin {
  TOP = 'TOP',
  CENTER = 'CENTER',
}

/**
 * Definition of tile dimensions for usage in croppie and tile previews
 * {
 *  [Tile Crop Type Id] : {
 *    - width (int) - final asset tile width
 *    - height (int) - final asset tile height
 *    - fromBaseImage (bool) - whether this should be rendered from the base image or the cropped one
 *    - version (enum) - version identifier for tile crop
 *    - coordinatesOrigin (CoordinatesOrigin enum) - where should zero coordinate be considered in the tile crop
 *   }
 * }
 */

type TileSpecification = {
  width: number;
  height: number;
  fromBaseImage?: boolean;
  coordinatesOrigin: CoordinatesOrigin;
  defaultCoordinate?: number;
};

export const TileSpecifications: {
  [key in TileCropType]: TileSpecification;
} = {
  /* V1 */
  [TileCropType.VERTICAL]: {
    width: 540,
    height: 900,
    fromBaseImage: true,
    coordinatesOrigin: CoordinatesOrigin.TOP,
    defaultCoordinate: 0,
  },
  [TileCropType.HORIZONTAL]: {
    width: 540,
    height: 450,
    coordinatesOrigin: CoordinatesOrigin.TOP,
    defaultCoordinate: 0,
  },
  [TileCropType.COLLAPSED]: {
    width: 540,
    height: 90,
    coordinatesOrigin: CoordinatesOrigin.TOP,
    defaultCoordinate: 0,
  },

  /* V2 */
  // https://snapchat.quip.com/BuibAf3aHa5O

  // 1 tile in a v2 tile carousel
  [TileCropType.HORIZONTAL_V2]: {
    width: 540,
    height: 259, // aspect ratio: 0.479
    coordinatesOrigin: CoordinatesOrigin.CENTER,
    defaultCoordinate: 450,
  },

  // 2 tiles in a v2 tile carousel
  [TileCropType.MEDIUM_V2]: {
    width: 540,
    height: 525, // aspect ratio: 0.972
    coordinatesOrigin: CoordinatesOrigin.CENTER,
    defaultCoordinate: 450,
  },

  // 3 tiles in a v2 tile carousel
  [TileCropType.VERTICAL_V2]: {
    width: 540,
    height: 800, // aspect ratio: 1.48
    coordinatesOrigin: CoordinatesOrigin.CENTER,
    defaultCoordinate: 450,
  },

  // 4 or more tiles in a v2 tile carousel
  [TileCropType.NARROW_V2]: {
    width: 540,
    height: 945, // aspect ratio: 1.75
    coordinatesOrigin: CoordinatesOrigin.CENTER,
  },

  // 4 read stories in a v2 tile carousel
  [TileCropType.COLLAPSED_V2]: {
    width: 540,
    height: 216, // aspect ratio: 0.4
    coordinatesOrigin: CoordinatesOrigin.CENTER,
    defaultCoordinate: 450,
  },

  [TileCropType.CHEETAH]: {
    width: 540,
    height: 900,
    fromBaseImage: true,
    coordinatesOrigin: CoordinatesOrigin.TOP,
    defaultCoordinate: 0,
  },
  [TileCropType.SQUARE]: {
    width: 160,
    height: 160,
    fromBaseImage: true,
    coordinatesOrigin: CoordinatesOrigin.TOP,
    defaultCoordinate: 0,
  },
};

export enum TileFormat {
  /* collection channel preview sizes */
  EXTRA_LARGE = 'EXTRA_LARGE',
  LARGE = 'LARGE',
  MEDIUM = 'MEDIUM',
  SMALL = 'SMALL',
  EXTRA_SMALL = 'EXTRA_SMALL',

  /* V1 crops */
  PRE_VERTICAL = 'PRE_VERTICAL',
  PRE_HORIZONTAL = 'PRE_HORIZONTAL',
  PRE_COLLAPSED = 'PRE_COLLAPSED',

  /* V2 crops */
  PRE_VERTICAL_V2 = 'PRE_VERTICAL_V2',
  PRE_HORIZONTAL_V2 = 'PRE_HORIZONTAL_V2',
  PRE_COLLAPSED_V2 = 'PRE_COLLAPSED_V2',
  PRE_NARROW_V2 = 'PRE_NARROW_V2',
  PRE_MEDIUM_V2 = 'PRE_MEDIUM_V2',

  /* Cheetah crops */
  PRE_CHEETAH = 'PRE_CHEETAH',
  CHEETAH_DEFAULT = 'CHEETAH_DEFAULT',
  CHEETAH_SMALL = 'CHEETAH_SMALL',
  CHEETAH_MEDIUM = 'CHEETAH_MEDIUM',

  /* Analytics Insights crops */
  REPORT_SIZE = 'REPORT_SIZE',
}

/**
 * Specifications that dictate how to preview the tiles throughout the CMS
 *
 * ChannelPreview and TilePreview require different parameters
 *
 * @see Quip spec: https://snapchat.quip.com/SZu8AUERt5xL
 * @see iOS code: https://github.sc-corp.net/Snapchat/phantom/blob/master/Libraries/SCUIKit/SCUIKit/Tile/View/SCTileCoverView.m
 */

export type TileFormatConfigType = {
  cropType: TileCropType;
  scale: number;
  fontSize?: number;
  lineHeight?: number;
  paddingLeft?: number;
  paddingBottom?: number;
};

export const TileFormatConfig: Record<TileFormat, TileFormatConfigType> = {
  /**
   * Definition reference (consumed by ChannelPreview)
   *
   * {
   *  - cropType (TileCropType enum) - dimensions to use
   *  - scale (float) - ChannelPreview uses this to calculate positions and dimensions from the
   *                   original asset to its representation in the CMS.
   *  - fontSize (float) - ChannelPreview renders this font size
   *  - lineHeight (float) - ChannelPreview uses this for css
   *  - paddingLeft (float) - ChannelPreview uses this for css
   *  - paddingBottom (float) - ChannelPreview uses this for css
   * }
   */
  /**
   * Collections EXTRA_LARGE Preview (Vertical format), consumed by ChannelPreview
   */
  [TileFormat.EXTRA_LARGE]: {
    cropType: TileCropType.HORIZONTAL,
    scale: 1,
    fontSize: 21,
    lineHeight: 32,
    paddingLeft: 16,
    paddingBottom: 10,
  },

  /**
   * Collections LARGE Preview (Vertical format), consumed by ChannelPreview
   */
  [TileFormat.LARGE]: {
    cropType: TileCropType.VERTICAL,
    scale: 2 / 3,
    fontSize: 18,
    lineHeight: 26,
    paddingLeft: 12,
    paddingBottom: 9,
  },

  /**
   * Collections MEDIUM Preview (Vertical format), consumed by ChannelPreview
   */
  [TileFormat.MEDIUM]: {
    cropType: TileCropType.VERTICAL,
    scale: 1 / 2,
    fontSize: 13,
    lineHeight: 18,
    paddingLeft: 9,
    paddingBottom: 5,
  },

  /**
   * Collections MEDIUM Preview (Vertical format), consumed by ChannelPreview
   */
  [TileFormat.CHEETAH_MEDIUM]: {
    cropType: TileCropType.CHEETAH,
    scale: 1 / 2,
    fontSize: 13,
    lineHeight: 18,
    paddingLeft: 9,
    paddingBottom: 5,
  },

  /**
   * Collections SMALL Preview (Vertical format), consumed by ChannelPreview
   */
  [TileFormat.SMALL]: {
    cropType: TileCropType.VERTICAL,
    scale: 1 / 3,
    fontSize: 11,
    lineHeight: 15,
    paddingLeft: 5,
    paddingBottom: 4,
  },

  /**
   * Collections SMALL Preview for cheetah (Cheetah format), consumed by ChannelPreview
   */
  [TileFormat.CHEETAH_SMALL]: {
    cropType: TileCropType.CHEETAH,
    scale: 1 / 3,
    fontSize: 11,
    lineHeight: 15,
    paddingLeft: 5,
    paddingBottom: 4,
  },

  /**
   * Collections EXTRA_SMALL Preview (Vertical format), consumed by ChannelPreview
   */
  [TileFormat.EXTRA_SMALL]: {
    cropType: TileCropType.VERTICAL,
    scale: 1 / 4,
    fontSize: 10,
    lineHeight: 13,
    paddingLeft: 5,
    paddingBottom: 4,
  },

  /**
   * Definition reference (consumed by TilePreview):
   *
   * {
   *  - cropType (string reference to TileCropType) - dimensions to use
   *  - scale (float) - TilePreview uses this internally to scale coordinates and dimensions
   *                   from the original image size to what's going to be rendered in the CMS.
   *                   avoid modifying this property to alter a specific instance scale, for those
   *                   cases, use a wrapping <div with CSS property transform: scale(x)
   *
   *                   scale should be given in the following format:
   *                     (approximate desired width without css scale / real width)
   *
   *  - coordinatesOrigin (CoordinatesOrigin enum) - where should zero coordinate
   *                      be considered in the tile crop
   * }
   */

  /**
   * V1 Crops
   */

  /**
   * Vertical format preview consumed by TilePreview and TileBigPreview
   */
  [TileFormat.PRE_VERTICAL]: {
    cropType: TileCropType.VERTICAL,
    scale: 88 / 540, // (approximate desired width without css scale / real width)
  },

  /**
   * Horizontal format preview consumed by TilePreview and TileBigPreview
   */
  [TileFormat.PRE_HORIZONTAL]: {
    cropType: TileCropType.HORIZONTAL,
    scale: 376 / 540, // (approximate desired width without css scale / real width)
  },

  /**
   * Collapsed format preview consumed by TilePreview and TileBigPreview
   */
  [TileFormat.PRE_COLLAPSED]: {
    cropType: TileCropType.COLLAPSED,
    scale: 376 / 540, // (approximate desired width without css scale / real width)
  },

  /* V2 */
  // https://snapchat.quip.com/BuibAf3aHa5O

  /**
   * V2 format preview consumed by TilePreview and TileBigPreview
   * - Represents the V2 tile crop when there is 1 tile in a carousel
   */
  [TileFormat.PRE_HORIZONTAL_V2]: {
    cropType: TileCropType.HORIZONTAL_V2,
    scale: 364.5 / 540, // (approximate desired width without css scale / real width)
  },

  /**
   * V2 format preview consumed by TilePreview and TileBigPreview
   * - Represents the V2 tile crop when there are 2 tiles in a carousel
   */
  [TileFormat.PRE_MEDIUM_V2]: {
    cropType: TileCropType.MEDIUM_V2,
    scale: 180.36 / 540, // (approximate desired width without css scale / real width) 0.334
  },

  /**
   * V2 format preview consumed by TilePreview and TileBigPreview
   * - Represents the V2 tile crop when there are 3 tiles in a carousel
   */
  [TileFormat.PRE_VERTICAL_V2]: {
    cropType: TileCropType.VERTICAL_V2,
    scale: 118.8 / 540, // (approximate desired width without css scale / real width)
  },

  /**
   * V2 format preview consumed by TilePreview and TileBigPreview
   * - Represents the V2 tile crop when there are 4 or more tiles in a carousel
   */
  [TileFormat.PRE_NARROW_V2]: {
    cropType: TileCropType.NARROW_V2,
    scale: 115.5 / 540, // (approximate desired width without css scale / real width)
  },

  /**
   * V2 format preview consumed by TilePreview and TileBigPreview
   * - Represents the V2 tile crop when the story has been read, and there is 1 item
   */
  [TileFormat.PRE_COLLAPSED_V2]: {
    cropType: TileCropType.COLLAPSED_V2,
    scale: 101.52 / 540, // (approximate desired width without css scale / real width)
  },

  [TileFormat.PRE_CHEETAH]: {
    cropType: TileCropType.CHEETAH,
    scale: 176 / 540,
  },

  [TileFormat.CHEETAH_DEFAULT]: {
    cropType: TileCropType.CHEETAH,
    scale: 88 / 540, // (approximate desired width without css scale / real width)
  },

  [TileFormat.REPORT_SIZE]: {
    cropType: TileCropType.VERTICAL,
    fontSize: 10,
    lineHeight: 13,
    paddingLeft: 5,
    paddingBottom: 4,
    scale: 120 / 540,
  },
};
