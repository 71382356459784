import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as routerActions from 'state/router/actions/routerActions';
import { isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';

import { SET_EDITOR_MODE } from '../actionIds/publisherStoryEditorActionIds';

import { EDITOR_MODES } from 'config/constants';
import type { EditorModes } from 'config/constants';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';
import type { Dispatch, GetState } from 'types/redux';

export type OpenSnapEditorType = {
  publisherId: PublisherID | undefined | null;
  editionId: EditionID | undefined | null;
  snapId: SnapId;
  overwriteHistory?: boolean;
  mode?: EditorModes;
};

export const openSnapEditor = ({
  publisherId,
  editionId,
  snapId,
  overwriteHistory,

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"STORY" |... Remove this comment to see the full error message
  mode = EDITOR_MODES.EDITOR,
}: OpenSnapEditorType) => (dispatch: Dispatch, getState: GetState): Promise<any> => {
  const hasAddedPublisherDetails = publishersSelectors.activePublisherHasAddedRequiredDetails(getState());

  if (!hasAddedPublisherDetails) {
    const topsnap = snapsSelectors.getSnapById(getState())(snapId);
    if (topsnap && isSubscribeSnap(topsnap)) {
      return dispatch(publishersActions.openPublisherDetailsModal('DraftStoryBar')).catch(() => {});
    }
  }

  dispatch(exports.setEditorMode(mode));

  return dispatch(
    routerActions.goToSnap({
      publisherId,
      editionId,
      snapId,
      overwriteHistory,
    })
  );
};

export const setEditorMode = (mode: EditorModes, metadata: string = '', options: {} = {}) => {
  return {
    type: SET_EDITOR_MODE,
    payload: {
      mode,
      metadata,
      options,
    },
  };
};
