import type { EditionID } from 'types/editionID';
import type { Segment, SegmentReduxID, SegmentID } from 'types/segments';

export const getSegmentReduxIdFromEditionAndSegmentId = (
  editionId: EditionID,
  segmentId: SegmentID
): SegmentReduxID => {
  return `${editionId}-${segmentId}`;
};

export const getSegmentReduxId = (segment: Segment): SegmentReduxID => {
  return getSegmentReduxIdFromEditionAndSegmentId(segment.editionId, segment.id);
};

const reduxIdRegex = /^[0-9]+-([0-9]+)$/;
export const getOriginalSegmentIdFromReduxId = (segmentId: SegmentReduxID): SegmentID | undefined | null => {
  const matchedArray = reduxIdRegex.exec(segmentId);
  if (!matchedArray) {
    return null;
  }

  const segmentIdString = matchedArray[1];
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  return parseInt(segmentIdString, 10) || null;
};
