export enum FeatureFlag {
  ADVANCED_CURATION = 'ADVANCED_CURATION',
  ALWAYS_SHOW_AD_CONTROLS = 'ALWAYS_SHOW_AD_CONTROLS',
  BITMOJI_CONTENT = 'BITMOJI_CONTENT',
  CALL_TO_ACTION = 'CALL_TO_ACTION',
  CALL_TO_ACTION_OVERRIDE = 'CALL_TO_ACTION_OVERRIDE',
  CAMEOS_CONTENT = 'CAMEOS_CONTENT',
  CONTENT_ANNOTATIONS = 'CONTENT_ANNOTATIONS',
  CURATED_LAYER = 'CURATED_LAYER',
  CUSTOM_DYNAMIC_EDITION_AUTO_CREATE_TIME = 'CUSTOM_DYNAMIC_EDITION_AUTO_CREATE_TIME',
  DISABLE_SEASONS_EPISODES = 'DISABLE_SEASONS_EPISODES',
  EDITION_SEGMENTS = 'EDITION_SEGMENTS',
  HOST_USER_REQUIRED = 'HOST_USER_REQUIRED',
  GEOFENCED_WEBVIEW_ATTACHMENTS = 'GEOFENCED_WEBVIEW_ATTACHMENTS',
  GENERATE_SUBTITLES_ON_UPLOAD = 'GENERATE_SUBTITLES_ON_UPLOAD',
  INFINITE_ADS_PLACER = 'INFINITE_ADS_PLACER',
  LONGFORM_VIDEO_LIVESTREAMING = 'LONGFORM_VIDEO_LIVESTREAMING',
  LOW_RESOLUTION_TOPSNAPS = 'LOW_RESOLUTION_TOPSNAPS',
  MODERATION_TILES_ONLY = 'MODERATION_TILES_ONLY',
  NAVBAR_LANGUAGE_DROPDOWN = 'NAVBAR_LANGUAGE_DROPDOWN',
  OPTIONAL_ADS_OVERRIDE = 'OPTIONAL_ADS_OVERRIDE',
  ORIGINAL_ARTICLE_URL = 'ORIGINAL_ARTICLE_URL',
  POLLS_EDITABLE_TITLE = 'POLLS_EDITABLE_TITLE',
  PRODUCT_PLACEMENT = 'PRODUCT_PLACEMENT',
  PUBLISHING_PAUSED = 'PUBLISHING_PAUSED',
  REMOTE_WEB = 'REMOTE_WEB',
  REMOTE_WEB_LIMIT = 'REMOTE_WEB_LIMIT',
  PUBLISHER_COMMENTS_SETTING = 'PUBLISHER_COMMENTS_SETTING',
  REMOTE_WEB_WITH_REFERRER = 'REMOTE_WEB_WITH_REFERRER',
  SHOW_EXTRAS = 'SHOW_EXTRAS',
  SHOWS_SCHEDULING_V2 = 'SHOWS_SCHEDULING_V2',
  SINGLE_ASSET_STORY_EDITOR = 'SINGLE_ASSET_STORY_EDITOR',
  SAS_UNIFICATION = 'SAS_UNIFICATION',
  MAKE_ALL_STORIES_UNAVAILABLE = 'MAKE_ALL_STORIES_UNAVAILABLE',
  SEND_TO_NO_LOCATION = 'SEND_TO_NO_LOCATION',
  SEND_TO_SPOTLIGHT = 'SEND_TO_SPOTLIGHT',
  SHOWS_TO_SPOTLIGHT = 'SHOWS_TO_SPOTLIGHT',
  SNAP_PUB_INGESTION = 'SNAP_PUB_INGESTION',
  SPECTACLES_TOPSNAP = 'SPECTACLES_TOPSNAP',
  SUBTITLES_MULTI_LANG = 'SUBTITLES_MULTI_LANG',
  STORY_SEARCH_SNAP_CURATION = 'STORY_SEARCH_SNAP_CURATION',
  TILE_HEADLINE_VALIDATION = 'TILE_HEADLINE_VALIDATION',
  TILE_HEADLINE_VISIBILITY_PICKER = 'TILE_HEADLINE_VISIBILITY_PICKER',
  TILE_VIOLATION_MODERATE_WARNING = 'TILE_VIOLATION_MODERATE_WARNING',
  TILE_VIOLATION_HIGH_WARNING = 'TILE_VIOLATION_HIGH_WARNING',
  TILE_VIOLATION_SUBSCRIBER_ONLY = 'TILE_VIOLATION_SUBSCRIBER_ONLY',
  TOPSNAP_LOOP_VIDEO = 'TOPSNAP_LOOP_VIDEO',
  TOPSNAP_WEBVIEW = 'TOPSNAP_WEBVIEW',
  UPDATED_PROFILE_PREVIEW = 'UPDATED_PROFILE_PREVIEW',
  SAS_SNAPCODES = 'SAS_SNAPCODES',
  HAPPENING_NOW_FEEDS = 'HAPPENING_NOW_FEEDS',
  NEW_USER_EXPERIENCE = 'NEW_USER_EXPERIENCE',
  SHOW_USE_CHECKBOXES_FOR_NON_SUPER_ADMINS = 'SHOW_USE_CHECKBOXES_FOR_NON_SUPER_ADMINS',
  TILE_VIOLATION_APPEAL = 'TILE_VIOLATION_APPEAL',
  REMOVE_TILE_LOGO_OFF_TOGGLE = 'REMOVE_TILE_LOGO_OFF_TOGGLE',
  // TODO (mmeroi): remove once the spotlight scheduling feature has launched
  SPOTLIGHT_SCHEDULING = 'SPOTLIGHT_SCHEDULING',
  // TODO (mmeroi): remove once the spotlight management has launched
  SPOTLIGHT_MANAGEMENT = 'SPOTLIGHT_MANAGEMENT',
  // TODO (mmeroi): remove once the Frodo has launched
  PUBLIC_STORY_POSTING = 'PUBLIC_STORY_POSTING',
  // TODO (mmeroi): remove once Snap Publisher is deprecated
  SNAP_PUBLISHER = 'SNAP_PUBLISHER',
  DISABLE_ADS_CONTORL = 'DISABLE_ADS_CONTORL',
  STORY_ANALYTICS_V2 = 'STORY_ANALYTICS_V2',
  STORY_ANALYTICS_PROFILES_V2 = 'STORY_ANALYTICS_PROFILES_V2',
  STORIES_ANALYTICS = 'STORIES_ANALYTICS',
  POLL_ATTACHMENTS = 'POLL_ATTACHMENTS',
  SPOTLIGHT_MODERATION_REJECTION_INFO = 'SPOTLIGHT_MODERATION_REJECTION_INFO',
}
