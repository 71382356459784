import { combineReducers } from 'redux';

import { createMergeReducer, createSequenceCountingReducer } from 'state/common/reducerFactories';
import * as supportTicketsActions from 'state/supportTickets/actions/supportTicketsActions';

import { EMPTY_OBJECT } from 'config/constants';

const formFields = (state = {}, action: any) => {
  if (action.type === supportTicketsActions.FLUSH) {
    return EMPTY_OBJECT;
  }

  return createMergeReducer([
    supportTicketsActions.DESCRIPTION,
    supportTicketsActions.CATEGORY,
    supportTicketsActions.PRIORITY,
    supportTicketsActions.SUBJECT,
    supportTicketsActions.URL,
    supportTicketsActions.SAVE_SCREENSHOT,
    supportTicketsActions.CLEAR_SCREENSHOT,
  ])(state, action);
};

export default combineReducers({
  formFields,
  isSubmittingTicket: createSequenceCountingReducer(supportTicketsActions.RAISE_TICKET),
});
