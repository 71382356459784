import type { ReactNode } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ResourceRole, Roles } from 'types/permissions';

const PRIORITY_MAP = {
  [Roles.SUPER_ADMIN]: 1,
  [Roles.ADMIN]: 5,
  [Roles.MONETIZATION_ANALYST]: 7,
  [Roles.ANALYST]: 8,
  [Roles.CONTRIBUTOR]: 9,
  [Roles.VIEWER]: 11,
  [Roles.BUSINESS_ADMIN]: 12,
  [Roles.ORG_ADMIN]: 13,
};

export const ROLES_TO_INTL_MESSAGE = defineMessages({
  [Roles.DEFAULT_NO_PERMISSION]: {
    id: 'no-roles-label',
    description: 'this user has no roles',
    defaultMessage: 'None',
  },
  [Roles.SUPER_ADMIN]: {
    id: 'superAdmin-label',
    description: 'super admin role label',
    defaultMessage: 'Super Admin',
  },
  [Roles.ADMIN]: {
    id: 'admin-label',
    description: 'admin role label',
    defaultMessage: 'Profile Admin',
  },
  [Roles.CONTRIBUTOR]: {
    id: 'contributor-label',
    description: 'contributor role label',
    defaultMessage: 'Editor',
  },
  [Roles.VIEWER]: {
    id: 'viewer-label',
    description: 'viewer role label',
    defaultMessage: 'Viewer',
  },
  [Roles.MONETIZATION_ANALYST]: {
    id: 'monetization-analyst-label',
    description: 'monetization analyst role label',
    defaultMessage: 'Monetization Analyst',
  },
  [Roles.ANALYST]: {
    id: 'analyst-label',
    description: 'analyst role label',
    defaultMessage: 'Analyst',
  },
  [Roles.ORG_ADMIN]: {
    id: 'orgAdmin-label',
    description: 'org adming role label',
    defaultMessage: 'Org Admin',
  },
  [Roles.BUSINESS_ADMIN]: {
    id: 'businessAdmin-label',
    description: 'business admin role label',
    defaultMessage: 'Business Admin',
  },
  [Roles.CURATOR]: {
    id: 'curator-role-label',
    description: 'curator role label',
    defaultMessage: 'Curator',
  },
  [Roles.CURATOR_READER]: {
    id: 'curator-read-role-label',
    description: 'curator reader role label',
    defaultMessage: 'Curator Read',
  },
});

export const ROLES_TO_INTL_DESCRIPTION = defineMessages({
  [Roles.SUPER_ADMIN]: {
    id: 'super-admin-description',
    description: 'describes what a super admin can do',
    defaultMessage: "Superhuman, you're awesome!",
  },
  [Roles.ADMIN]: {
    id: 'admin-user-description',
    description: 'describes what an admin user can do',
    defaultMessage: 'Manage content, Analyst, Manage users',
  },
  [Roles.MONETIZATION_ANALYST]: {
    id: 'monetization-analyst-description',
    description: 'describes what a monetization analyst can do',
    defaultMessage: 'View revenue only',
  },
  [Roles.ANALYST]: {
    id: 'analyst-user-description',
    description: 'describes what an analyst can do',
    defaultMessage: 'View analytics',
  },
  [Roles.CONTRIBUTOR]: {
    id: 'editor-user-description',
    description: 'describes what an editor user can do',
    defaultMessage: 'View and edit content',
  },
  [Roles.VIEWER]: {
    id: 'viewer-user-description',
    description: 'describes what a viewer user can do',
    defaultMessage: 'View content',
  },
  [Roles.ORG_ADMIN]: {
    id: 'org-admin-user-description-2',
    description: 'describes what an org admin user can do',
    defaultMessage: 'Admin of all profiles in the same org',
  },
  [Roles.BUSINESS_ADMIN]: {
    id: 'businessAdmin-description-3',
    description: 'describes what a business admin can do',
    defaultMessage: 'Supplements Profile Admin with Org Level permissions',
  },
  [Roles.CURATOR]: {
    id: 'curator-role-description',
    description: 'describes what curator user can do',
    defaultMessage: 'An advanced curator',
  },
  [Roles.CURATOR_READER]: {
    id: 'curator-reader-role-description',
    description: 'describes what curator reader can do',
    defaultMessage: 'A curator with read access',
  },
});

export type RoleForUserCreationTable = {
  orgRole: boolean;
  publisherRole: boolean;
  role: FormattedMessage.MessageDescriptor;
  description: FormattedMessage.MessageDescriptor;
};

export const ROLES_FOR_USER_CREATION_TABLE = {
  [Roles.SUPER_ADMIN]: {
    orgRole: false,
    publisherRole: false,
    role: ROLES_TO_INTL_MESSAGE[Roles.SUPER_ADMIN],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.SUPER_ADMIN],
  },
  [Roles.ADMIN]: {
    orgRole: false,
    publisherRole: true,
    role: ROLES_TO_INTL_MESSAGE[Roles.ADMIN],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.ADMIN],
  },
  [Roles.MONETIZATION_ANALYST]: {
    orgRole: false,
    publisherRole: true,
    role: ROLES_TO_INTL_MESSAGE[Roles.MONETIZATION_ANALYST],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.MONETIZATION_ANALYST],
  },
  [Roles.ANALYST]: {
    orgRole: false,
    publisherRole: true,
    role: ROLES_TO_INTL_MESSAGE[Roles.ANALYST],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.ANALYST],
  },
  [Roles.CONTRIBUTOR]: {
    orgRole: false,
    publisherRole: true,
    role: ROLES_TO_INTL_MESSAGE[Roles.CONTRIBUTOR],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.CONTRIBUTOR],
  },
  [Roles.VIEWER]: {
    orgRole: false,
    publisherRole: true,
    role: ROLES_TO_INTL_MESSAGE[Roles.VIEWER],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.VIEWER],
  },
  [Roles.BUSINESS_ADMIN]: {
    orgRole: true,
    publisherRole: true,
    role: ROLES_TO_INTL_MESSAGE[Roles.BUSINESS_ADMIN],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.BUSINESS_ADMIN],
  },
  [Roles.ORG_ADMIN]: {
    orgRole: true,
    publisherRole: true,
    role: ROLES_TO_INTL_MESSAGE[Roles.ORG_ADMIN],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.ORG_ADMIN],
  },
  [Roles.CURATOR]: {
    orgRole: false,
    publisherRole: false,
    role: ROLES_TO_INTL_MESSAGE[Roles.CURATOR],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.CURATOR],
  },
  [Roles.CURATOR_READER]: {
    orgRole: false,
    publisherRole: false,
    role: ROLES_TO_INTL_MESSAGE[Roles.CURATOR_READER],
    description: ROLES_TO_INTL_DESCRIPTION[Roles.CURATOR_READER],
  },
};

export type UserCreationTableRoleName = keyof typeof ROLES_FOR_USER_CREATION_TABLE;

export const getFilteredRolesTextForPublisherList = (rolesToRemove: any): Array<ReactNode> =>
  Object.keys(ROLES_FOR_USER_CREATION_TABLE)
    .filter(
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      key => ROLES_FOR_USER_CREATION_TABLE[key].publisherRole && (!rolesToRemove || rolesToRemove.indexOf(key) === -1)
    )

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    .map(key => ROLES_FOR_USER_CREATION_TABLE[key].role);

export const RolesTextForAdminList: Array<ReactNode> = Object.keys(ROLES_FOR_USER_CREATION_TABLE)

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  .map(key => ROLES_FOR_USER_CREATION_TABLE[key].role);

// Changes in INHERITANCE_MAP should be reflected in the role claims in the backend:
// https://github.sc-corp.net/Snapchat/content-platform/blob/main/discover-service/permissions-model/src/main/scala/snapchat/discover/permissions/RolesToClaimsMapping.scala#L14-L14
export const INHERITANCE_MAP: {
  [key in Roles]: UserCreationTableRoleName[];
} = {
  [Roles.SUPER_ADMIN]: [Roles.ADMIN, Roles.ANALYST, Roles.CONTRIBUTOR, Roles.VIEWER, Roles.MONETIZATION_ANALYST],
  [Roles.ADMIN]: [Roles.ANALYST, Roles.CONTRIBUTOR, Roles.VIEWER],
  [Roles.MONETIZATION_ANALYST]: [],
  [Roles.ANALYST]: [Roles.VIEWER],
  [Roles.CONTRIBUTOR]: [Roles.VIEWER],
  [Roles.VIEWER]: [],
  [Roles.ORG_ADMIN]: [
    Roles.BUSINESS_ADMIN,
    Roles.ADMIN,
    Roles.ANALYST,
    Roles.CONTRIBUTOR,
    Roles.VIEWER,
    Roles.MONETIZATION_ANALYST,
  ],
  [Roles.CURATOR]: [],
  [Roles.CURATOR_READER]: [],
  [Roles.BUSINESS_ADMIN]: [],
  [Roles.DEFAULT_NO_PERMISSION]: [],
};

// Changes in FORCE_ROLES_SELECTION should be reflected in the role claims in the backend:
// https://github.sc-corp.net/Snapchat/content-platform/blob/main/discover-service/permissions-model/src/main/scala/snapchat/discover/permissions/RolesToClaimsMapping.scala#L14-L14

// This is similar to INHERITANCE_MAP, but without requiring the roles to inherit form each other
// The first use case was to force a BUSINESS_ADMIN to also select ADMIN, but that inheritance does not
// exist in the backend because BUSINESS_ADMIN is an org level role and would propagate the ADMIN role down.
export const FORCE_ROLES_SELECTION: { [key in Roles]: UserCreationTableRoleName[] } = {
  [Roles.SUPER_ADMIN]: [],
  [Roles.ADMIN]: [],
  [Roles.MONETIZATION_ANALYST]: [],
  [Roles.ANALYST]: [],
  [Roles.CONTRIBUTOR]: [],
  [Roles.VIEWER]: [],
  [Roles.ORG_ADMIN]: [],
  [Roles.CURATOR]: [],
  [Roles.CURATOR_READER]: [],
  [Roles.BUSINESS_ADMIN]: [Roles.ADMIN, Roles.ANALYST, Roles.CONTRIBUTOR, Roles.VIEWER, Roles.MONETIZATION_ANALYST],
  [Roles.DEFAULT_NO_PERMISSION]: [],
};

const sortByRoles = (roleA: any, roleB: any) => {
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return PRIORITY_MAP[roleA.roleId] - PRIORITY_MAP[roleB.roleId];
};

// TODO the logic maybe wrong if the role relation change, need to fix it in the future
export const hideRoles = (roles: ResourceRole[]) => {
  let rolesToHide: Roles[] = [];
  roles.forEach(role => {
    if (INHERITANCE_MAP[role.roleId] && INHERITANCE_MAP[role.roleId].length > rolesToHide.length) {
      rolesToHide = INHERITANCE_MAP[role.roleId];
    }
  });
  return roles.filter(role => !rolesToHide.includes(role.roleId) && INHERITANCE_MAP[role.roleId]);
};

export const prettyPrintRoles = (roles: ResourceRole[]) => {
  const rolesInOrder = roles.sort(sortByRoles);
  return hideRoles(rolesInOrder).map(role => ROLES_TO_INTL_MESSAGE[role.roleId]);
};

export const getRolesForSaving = (roles: any) => {
  return roles.filter((role: any) => role.explicit);
};

export const getResourceRolesForSaving = (roles: any) => {
  return getRolesForSaving(roles).map((role: any) => ({
    roleId: role.name,
  }));
};

export const filterEmailDomain = (users: any) => {
  const set = new Set();
  Object.values(users).forEach((user: any) => {
    const { email } = user;
    const emailDomain = email.substring(email.lastIndexOf('@'));
    set.add(emailDomain);
  });
  // @ts-expect-error ts-migrate(2569) FIXME: Type 'Set<unknown>' is not an array type or a stri... Remove this comment to see the full error message
  return [...set];
};
