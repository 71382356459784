import loadjs from 'loadjs';
import _ from 'lodash';
import log from 'loglevel';

import { ACCESS_TOKEN_STORAGE_KEY, CLIENT_ID, GSI_BUNDLE_ID, GSI_CLIENT_URL } from 'config/constants';

const TEN_MINUTES = 60 * 5 * 1000;

interface TokenResponse {
  // eslint-disable-next-line camelcase
  access_token: string;
  // eslint-disable-next-line camelcase
  expires_in: number;
}

interface TokenClientConfig {
  // eslint-disable-next-line camelcase
  client_id: string;
  callback: (response: TokenResponse) => void;
  hint?: string;
  prompt: string;
  // eslint-disable-next-line camelcase
  error_callback: (err: ErrorResponse) => void;
  scope: string;
}

interface ErrorResponse {
  type: 'popup_failed_to_open' | 'popup_closed' | 'unknown';
}

interface TokenClient {
  requestAccessToken(): void;
}

export interface UserInfo {
  sub: string;
  name: string;
  email: string;
  accessToken: string;
  expiresAt: number;
}

export interface PreloadedApi {
  gapi: any;
  userInfo?: UserInfo;
}

declare global {
  // @ts-ignore
  const google: {
    accounts: {
      oauth2: {
        initTokenClient(config: TokenClientConfig): TokenClient;
      };
    };
  };
}

/*
  Preload the api because otherwise, we can't respond to clicks without
  being popup blocked.
*/
/* This class keeps a local state of whether the api is loaded, so it only happens once */
class ApiPreloader {
  preloaded: PreloadedApi | null = null;

  preloadPromise = null;

  clientId = null;

  constructor(clientId: any) {
    this.clientId = clientId;
  }

  loadGoogleApi(): Promise<void> {
    return new Promise(resolve => {
      loadjs(GSI_CLIENT_URL, GSI_BUNDLE_ID);
      loadjs.ready(GSI_BUNDLE_ID, () => {
        resolve();
      });
    });
  }

  getLoadedUserInfo() {
    return _.get(this, ['preloaded', 'userInfo']);
  }

  hasLoadedApi() {
    return Boolean(this.preloaded);
  }

  async preloadGapiUngated() {
    const gapi = await this.loadGoogleApi();

    this.preloaded = {
      gapi,
    };

    return this.preloaded;
  }

  async loadUserProfile(accessToken: string, expiresAt: number): Promise<UserInfo> {
    const request = new Request('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
      method: 'GET',
      cache: 'no-cache',
    });

    try {
      const response = await fetch(request);
      const resp = await response.json();
      return {
        sub: resp.sub,
        name: resp.name,
        email: resp.email,
        accessToken,
        expiresAt,
      };
    } catch (error) {
      throw new Error('Error retrieving user info');
    }
  }

  async loadUserInfo(): Promise<UserInfo> {
    if (!this.preloaded) {
      return Promise.reject('google api not loaded.');
    }

    const storedTokenJson = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);

    // Provide login hint for refresh token use case.
    let loginHint: string | undefined;
    let loginPrompt = 'select_account';

    if (storedTokenJson) {
      const { accessToken, expiresAt } = JSON.parse(storedTokenJson);
      if (Date.now() < expiresAt - TEN_MINUTES) {
        if (!this.preloaded.userInfo) {
          const userInfo = await this.loadUserProfile(accessToken, expiresAt);
          this.preloaded.userInfo = userInfo;
        }
        return this.preloaded.userInfo!;
      }
      // Refresh token path. Do not prompt and provide hint for auto refresh.
      loginHint = this.preloaded?.userInfo?.email;
      loginPrompt = '';
    }

    return new Promise((resolve, reject) => {
      if (!loadjs.isDefined(GSI_BUNDLE_ID)) {
        loadjs(GSI_CLIENT_URL, GSI_BUNDLE_ID);
      }

      loadjs.ready(GSI_BUNDLE_ID, () => {
        // @ts-ignore
        google.accounts.oauth2
          .initTokenClient({
            client_id: CLIENT_ID,
            scope: 'email profile',
            hint: loginHint,
            prompt: loginPrompt,
            callback: (response: TokenResponse) => {
              // Persist access token.
              const expiresAt = Date.now() + 1000 * response.expires_in;
              localStorage.setItem(
                ACCESS_TOKEN_STORAGE_KEY,
                JSON.stringify({
                  accessToken: response.access_token,
                  expiresAt: Date.now() + 1000 * response.expires_in,
                })
              );
              this.loadUserProfile(response.access_token, expiresAt).then(userInfo => {
                // @ts-ignore
                this.preloaded.userInfo = userInfo;
                resolve(userInfo);
              });
            },
            error_callback: ({ type }: ErrorResponse) => {
              let message;
              switch (type) {
                case 'popup_failed_to_open':
                  message = 'Popup failed to open. Make sure popups are enabled for this page and refresh. ';
                  break;
                case 'popup_closed':
                  message = 'Popup closed before sign in completed. Refresh and try again.';
                  break;
                default:
                  message = 'Unknown error occurred with sign in';
              }
              reject(new Error(message));
            },
          })
          .requestAccessToken();
      });
    });
  }

  // Guarantees load will only happen once, and will resolve when available.
  preloadGapi() {
    if (this.preloadPromise) {
      return this.preloadPromise;
    }

    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Promise<null>' is not assignable to type 'nu... Remove this comment to see the full error message
    this.preloadPromise = this.preloadGapiUngated();

    // This often happens when a developer does a deploy, but does not allow the client id access to the url
    // Can be fixed by adding your url to
    // https://console.cloud.google.com/apis/credentials?project=pentalift-hrd&organizationId=191087036201
    // OAuth2.0 client ID: "CMS Client Id"
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    this.preloadPromise.catch((error: any) => {
      log.error(error);
    });

    return this.preloadPromise;
  }
}

export const isGapiThirdPartyCookieError = (errorObj: any) =>
  errorObj.error === 'idpiframe_initialization_failed' &&
  (_.includes(errorObj.details, "Failed to read the 'localStorage' property from 'Window'") ||
    _.includes(errorObj.details, 'Cookies are not enabled in current environment.'));

export const discoverApiPreloader = new ApiPreloader(CLIENT_ID);
