import core from '@ffmpeg/core';
import { createFFmpeg, FFmpeg, FSMethodArgs, FSMethodNames, LogCallback, ProgressCallback } from '@ffmpeg/ffmpeg';
import { detect } from 'detect-browser';

import WasmError from '../errors/WasmError';

import { Environments } from 'config/constants';
import { FFmpegLog, FFMPEGLOGTypes } from 'utils/ffmpeg/FFmpegCommands';
import { incrementCounter } from 'utils/grapheneUtils';
import { measureDurationWrap } from 'utils/performance/performanceUtils';
import { loadWasm } from 'utils/wasm';

import FileReaderUtil from './FileReaderUtil';

const dummyFFmpeg: FFmpeg = {
  load: () => Promise.resolve(),
  isLoaded: () => false,
  run: (...args: string[]) => Promise.resolve(),
  FS: <Method extends FSMethodNames>(method: Method, ...args: FSMethodArgs[Method]): any => {},
  setProgress: (progress: ProgressCallback) => {},
  setLogger: (log: LogCallback) => {},
  setLogging: (logging: boolean) => {},
};

export class FFmpegLib {
  private ffmpeg: FFmpeg;

  loadPromise: Promise<void>;

  triggerLoad: () => void;

  constructor(createFFmpegFunc = createFFmpeg) {
    // Uses file-loader, so `core` will be the url to the ffmpeg-core.js file.
    const isTest = process.env.NODE_ENV === Environments.TEST;
    this.ffmpeg = isTest
      ? dummyFFmpeg
      : createFFmpegFunc({
          corePath: core,
          log: false,
        });

    // Bind the loadVideoFileToFFmpeg method to the FFmpegLib instance
    this.loadVideoFileToFFmpeg = this.loadVideoFileToFFmpeg.bind(this);

    // Triggering wasm download on page load, and providing an immediate load function
    const { loadPromise, triggerLoad } = loadWasm<void>(
      () =>
        this.ffmpeg.load().catch(err => {
          throw new WasmError('Failed to load wasm', err);
        }),
      6000
    );

    // Avoid unhandled exception when compiling
    loadPromise.catch(() => {});

    this.loadPromise = loadPromise;
    this.triggerLoad = triggerLoad;
  }

  async extractFrameImpl(fileUrl: string, seekPoints: string[]): Promise<string[]> {
    const frameUrls = [];
    const file = await this.extractFileFromURL(fileUrl);
    await this.loadVideoFileToFFmpeg(file);

    // eslint-disable-next-line no-restricted-syntax
    for (const seekPoint of seekPoints) {
      const frameFileName = `frame_${seekPoint}.png`;

      // eslint-disable-next-line no-await-in-loop
      await this.ffmpeg.run('-ss', `${seekPoint}`, '-i', file.name, '-vframes', '1', '-s', '130x231', frameFileName);
      const data = this.ffmpeg.FS('readFile', frameFileName);
      const frameUrl = URL.createObjectURL(new File([data.buffer], frameFileName, { type: 'image/png' }));
      frameUrls.push(frameUrl);
    }

    // release the video file after all frames are extracted
    this.ffmpeg.FS('unlink', file.name);

    return frameUrls;
  }

  async resizeImage(inputFileName: string, outputFileName: string, width: number, height: number) {
    await this.ffmpeg.run('-i', inputFileName, '-vf', `scale=${width}:${height}`, outputFileName);
  }

  async extractFirstFramesImpl(videoURLs: string[]): Promise<string[]> {
    const frames: string[] = [];
    const ffmpegInstance = this.ffmpeg;
    const loadVideoFile = this.loadVideoFileToFFmpeg;
    const currentInstance = this;
    async function processVideos(index: number) {
      if (index < videoURLs.length) {
        const videoURL = videoURLs[index];
        if (!videoURL) {
          frames.push('');
        } else {
          const file = await currentInstance.extractFileFromURL(videoURL);
          const frameFileName = `frame_${index}.png`;
          const seekPoint = '00:00:02';
          await loadVideoFile(file);
          if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'binary/octet-stream') {
            frames.push(videoURL);
            await processVideos(index + 1);
            return;
          }
          await ffmpegInstance.run('-ss', `${seekPoint}`, '-i', file.name, '-vframes', '1', frameFileName);
          // Copying resulting data back from WASM sandbox.
          const data = ffmpegInstance.FS('readFile', frameFileName);
          const frameShotUrl = URL.createObjectURL(new File([data.buffer], frameFileName, { type: 'image/png' }));
          frames.push(frameShotUrl);
          await processVideos(index + 1);
        }
      }
    }

    await processVideos(0);
    return frames;
  }

  private async extractFileFromURL(videoURL: string) {
    const response = await fetch(videoURL!);
    const blob = await response.blob();
    const timestamp = new Date().getTime();
    const mimeType = blob.type;
    const fileType = mimeType.split('/')[1];
    const temporaryFileName = `file_${timestamp}.${fileType}`;
    return new File([blob], temporaryFileName, { type: blob.type });
  }

  async loadVideoFileToFFmpeg(videoFile: File) {
    try {
      // Triggering loading of library if it's not already loaded
      this.triggerLoad();

      // Waiting for library to load
      await this.loadPromise;

      // Read the video file
      const videoData = new Uint8Array(await FileReaderUtil.readAsArrayBuffer(videoFile));

      // Mapping shared array buffer to file in the WASM sandbox
      this.ffmpeg.FS('writeFile', videoFile.name, videoData);
    } catch (e) {
      const browser = detect(navigator?.userAgent)?.name || 'unknown';
      incrementCounter('FFmpegLib.wasmUnsupported', {
        browser,
      });
      throw e;
    }
  }

  async createFileFromURLImpl(url: string) {
    const response = await fetch(url);
    const blob = await response.blob();
    const timestamp = new Date().getTime();
    const mimeType = blob.type;
    const fileType = mimeType.split('/')[1];
    const temporaryFileName = `file_${timestamp}.${fileType}`;
    return new File([blob], temporaryFileName, { type: blob.type });
  }

  async isVideoCorruptedImpl(videoFile: File): Promise<boolean> {
    try {
      await this.loadVideoFileToFFmpeg(videoFile);
      const errorLogs = this.extractErrorLogs();
      const command = ['-v', 'error', '-i', videoFile.name, '-f', 'null', '-'];
      await this.ffmpeg.run(...command);
      return errorLogs.length > 0;
    } catch (e) {
      return false;
    }
  }

  extractErrorLogs() {
    const errorLogs: FFmpegLog[] = [];
    this.ffmpeg.setLogger((commandLog: FFmpegLog) => {
      if (commandLog.type === FFMPEGLOGTypes.ERROR) {
        errorLogs.push(commandLog);
      }
    });
    return errorLogs;
  }
}

const ffmpegLib = new FFmpegLib();

export const loadMediaFileToFFmpeg = measureDurationWrap(
  (mediaFile: File) => ffmpegLib.loadVideoFileToFFmpeg(mediaFile),
  {
    metricsName: 'media.load_media_file_to_ffmpeg',
  }
);

export const extractFrame = measureDurationWrap(
  (videoFileUrl: string, seekPoints: string[]) => ffmpegLib.extractFrameImpl(videoFileUrl, seekPoints),
  {
    metricsName: 'media.extract_frame',
  }
);

export const isVideoCorrupted = measureDurationWrap((videoFile: File) => ffmpegLib.isVideoCorruptedImpl(videoFile), {
  metricsName: 'media.is_video_corrupted',
});

export const extractFirstFrames = measureDurationWrap(
  (videoFileUrls: string[]) => ffmpegLib.extractFirstFramesImpl(videoFileUrls),
  {
    metricsName: 'media.extract_first_frames',
  }
);

export const createFileFromURL = measureDurationWrap((fileUrl: string) => ffmpegLib.createFileFromURLImpl(fileUrl), {
  metricsName: 'media.create_file_from_url',
});
