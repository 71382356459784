import { fetchDebugPublisher, fetchDebugPublisherEnabledFeatures } from 'state/debug/debugActions';
import { goToNotFound } from 'state/router/actions/routerActions';
import { getActiveCreatorHostUsername, getActivePublisherId } from 'state/user/selectors/userSelectors';

import { PublisherQueryType } from 'gql/types/publisherQueryTypeEnum';
import { requireCreatorPublisherInfo } from 'utils/router/requireCreatorPublisherInfo';
import { wrapInTiming } from 'utils/router/routerUtils';

import { Publisher } from 'types/publishers';
import { Store } from 'types/redux';

export const requirePublisherDebugInfo = (store: Store) =>
  wrapInTiming(async ({ match }: any) => {
    await requireCreatorPublisherInfo(store, PublisherQueryType.Root)({ match });
    const publisherId = getActivePublisherId(store.getState());
    if (!publisherId) {
      return store.dispatch(goToNotFound({ hostUsername: getActiveCreatorHostUsername(store.getState()) }));
    }
    let publisher: Publisher | null = null;
    await store.dispatch(fetchDebugPublisher(publisherId));
    const { debug } = store.getState();
    publisher = debug.debugPublishersById[publisherId].publisher;
    return store.dispatch(fetchDebugPublisherEnabledFeatures(publisherId, publisher?.businessProfileId));
  });
