import { combineReducers } from 'redux';

import { createEntityReducer, createSequenceErrorsByIdReducer } from 'state/common/reducerFactories';

import * as attachmentsActions from '../actions/attachmentsActions';

const byId = createEntityReducer('attachment', [attachmentsActions.LOAD_ATTACHMENT]);

const errorsById = createSequenceErrorsByIdReducer('attachmentId', [attachmentsActions.LOAD_ATTACHMENT]);

export default combineReducers({
  byId,
  errorsById,
});
