import { BuildType } from 'config/constants';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import * as publicAPI from 'utils/apis/publicAPI';

import type { AssetID } from 'types/polls';

export const getRichSnapAssetUrl = (assetId: any, optionsOverride: any = {}) => {
  if (!assetId) {
    return null;
  }

  return mediaLibraryAPI.asset.downloadBuild({
    assetId,
    buildTypeId: BuildType.IMAGE_PREVIEW,
    buildSettingId: 'baseline',
    gracefulMissing: true,
    fallback: true,
    buildIfMissing: true,
    ...optionsOverride,
  });
};

type UrlCreator = (a: AssetID) => string;

export function createAssetUrl(
  assetId: string,
  options: any = {},
  urlCreator: UrlCreator = mediaLibraryAPI.asset.download
) {
  return urlCreator({ assetId, ...options });
}

// Helper method for the logos migration.
export function createAssetUrlOrDefault(assetId: any, defaultUrl: string) {
  if (assetId) {
    return mediaLibraryAPI.asset.download({ assetId });
  }

  return defaultUrl;
}

export const getPublicTileAssetUrl = (assetId: any, optionsOverride: any = {}) => {
  if (!assetId) {
    return null;
  }

  return publicAPI.asset.download({
    assetId,
    buildType: BuildType.TILE.toLowerCase(),
    ...optionsOverride,
  });
};

export const getPublicImageAssetUrl = (assetId: AssetID) => {
  if (!assetId) {
    return null;
  }

  return publicAPI.asset.download({
    assetId,
    buildType: BuildType.IMAGE_PREVIEW,
  });
};

export const getImagePreviewUrl = (assetId: any, optionsOverride: any = {}) => {
  return getRichSnapAssetUrl(assetId, {
    buildTypeId: BuildType.IMAGE_PREVIEW,
    ...optionsOverride,
  });
};

export const getVideoPreviewUrl = (assetId: any, optionsOverride: any = {}) => {
  return getRichSnapAssetUrl(assetId, {
    buildTypeId: BuildType.VIDEO_PREVIEW,
    ...optionsOverride,
  });
};

export const getVideoAssetPosterUrl = (assetId: any, optionsOverride: any = {}) => {
  return getRichSnapAssetUrl(assetId, {
    buildTypeId: BuildType.VIDEOASSET_THUMBNAIL,
    buildSettingId: 'first_frame',
    fallback: false,
    ...optionsOverride,
  });
};
