import invariant from 'invariant';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { setEditionProperties } from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { withTransaction } from 'state/transactions/actions/transactionsActions';

import { LocalStorage, TransactionType } from 'config/constants';
import { buildHashForProperties } from 'utils/apis/partialUpdateUtil';
import { isApiError } from 'utils/errors/api/apiErrorUtils';
import {
  infoMessageHandler,
  clearInfoMessage,
  InfoContext,
  showInfoMessage,
} from 'utils/errors/infoMessage/infoMessageUtils';
import { localStorage } from 'utils/localStorageUtils';
import { ModalType } from 'utils/modalConfig';

import { OneTimeConfirmationModalOptions } from 'views/modals/components/OneTimeConfirmationModal/OneTimeConfirmationModal';

import type { EditionID, BaseEdition } from 'types/editions';
import type { Dispatch, GetState, ThunkAction } from 'types/redux';

const showNewSegmentModal = (dispatch: any, hasNewSegment: any) => {
  const dismissNewSegmentSimpleConfirmModal = localStorage.getItem(
    LocalStorage.DISMISS_NEW_SEGMENT_SIMPLE_CONFIRM_MODAL
  );

  const options: OneTimeConfirmationModalOptions = {
    visible: true,
    title: (
      <FormattedMessage
        id="first-segment-created-header"
        defaultMessage="New segment"
        description="Header text of a modal when you first create a segment"
      />
    ),
    body: (
      <>
        <FormattedMessage
          id="first-segment-created-what-is-segment"
          defaultMessage="Segments are thematic groupings of Snaps, which can be distributed independently from other Snaps in your Story."
          description="Body text of a modal when you first create a segment"
        />
        <br />
        <br />
        <FormattedMessage
          id="first-segment-created-segment-distribution"
          defaultMessage="By creating a segment, you acknowledge that we may distribute this segment independently from the rest of your Story."
          description="Body text of a modal when you first create a segment"
        />
      </>
    ),
    onConfirm: (dontShowAgain: any) =>
      localStorage.setItem(LocalStorage.DISMISS_NEW_SEGMENT_SIMPLE_CONFIRM_MODAL, dontShowAgain),
    disableCancel: true,
  };

  if (hasNewSegment) {
    if (!(dismissNewSegmentSimpleConfirmModal === 'true')) {
      dispatch(showModal(ModalType.ONE_TIME_CONFIRMATION, 'NewSegmentConfirmation', options));
    }
  }
};

// Wraps any story update in a transaction that sets the edition properties immediately. This requires the caller to calculate
// what the edition final state will be which all callers currently do because they use DS API.
const wrapInSetEditionProperties = (
  {
    editionId,
  }: {
    editionId: EditionID;
  },
  properties: Partial<BaseEdition>,
  action: ThunkAction
) => {
  return withTransaction(
    TransactionType.SET_EDITION_PROPERTY,
    { edition: editionId },
    (dispatch: any, getState: GetState) => {
      infoMessageHandler(dispatch, InfoContext.SAVING);

      return new Promise((resolve, reject) => {
        const currentEdition = editionsSelectors.getEditionById(getState())(editionId);
        invariant(currentEdition, `could not find edition with id ${editionId}`);

        showNewSegmentModal(dispatch, properties.segments ? properties.segments.some(segment => !segment.id) : false);

        const updatedProperties = properties;
        if (properties.segments) {
          // Need to generate a temporary id placeholder for new segments
          updatedProperties.segments = properties.segments.map((segment, index) => {
            if (segment.id) {
              // Adding editionId to existing segment so their ids can be calculated by normalizr in the reducers.
              return { ...segment, editionId: currentEdition.id };
            }

            // Creates temporary id based on new segment hash

            const newSegmentHash = buildHashForProperties(segment, []);
            return { ...segment, id: `PLACEHOLDER-SEGMENT-${newSegmentHash}` };
          });
        }

        return Promise.resolve()
          .then(() => dispatch(setEditionProperties({ editionId }, updatedProperties)))
          .then(() => dispatch(action))
          .then(resolve)
          .catch(reject);
      }).then(
        clearInfoMessage(getState, dispatch, InfoContext.SAVING),
        clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
      );
    },
    (dispatch: any, error: any) => {
      // withTransaction error handler
      // Api errors are treated inside saveEdition method so no need to send another notification
      if (!isApiError(error)) {
        dispatch(showInfoMessage(InfoContext.ERROR_SAVING_STORY));

        // TODO: replace Error with custom cms hierarchy
        error.alreadyHandled = true; // eslint-disable-line no-param-reassign
      }
      throw error;
    }
  );
};

export const createStoryAction = (
  {
    editionId,
  }: {
    editionId: EditionID;
  },
  properties: Partial<BaseEdition>,
  action: ThunkAction
): ThunkAction => {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const edition = editionsSelectors.getEditionById(getState())(editionId);

    invariant(edition, `could not find edition with id ${editionId}`);

    return dispatch(wrapInSetEditionProperties({ editionId }, properties, action));
  };
};
