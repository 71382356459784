import React, { ReactNode } from 'react';

import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';

import { useRequestReviewMutation } from 'gql/hooks/useRequestReviewMutation';
import { intlConnect } from 'utils/connectUtils';

import { State as RootState } from 'types/rootState';

export type WithRequestReviewMutationProps = {
  requestReviewError?: string;
  requestReviewData?: any;
  requestReview: any;
};

type OwnProps = {
  children: ReactNode;
};

const mapStateToProps = (state: RootState) => {
  const activePublisher = getActivePublisherDetails(state);
  return {
    businessProfileId: activePublisher?.businessProfileId,
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

export default function withRequestReviewMutation(Component: typeof React.Component) {
  function WrappedComponent(props: Props) {
    const { requestReview, data, error } = useRequestReviewMutation();

    return (
      <Component
        {...props}
        requestReview={requestReview}
        requestReviewData={data}
        requestReviewError={error}
        data-test="withRequestReviewMutation.component"
      >
        {props.children}
      </Component>
    );
  }

  return intlConnect(mapStateToProps, null)(WrappedComponent);
}
