import _, { flatten, union } from 'lodash';
import { combineReducers } from 'redux';

import {
  createSequenceHandlingReducer,
  createEntityReducer,
  createSequenceCountingByIdReducer,
  createSequenceErrorsByIdReducer,
  createEntityAgeReducer,
  serializeReducers,
} from 'state/common/reducerFactories';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';

import { Sequence } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { assertArg } from 'utils/assertionUtils';
import u from 'utils/safeUpdeep';

export type MediaState = {
  loadedAt: number;
  results: any[];
  params: SearchOption;
};

export type SearchOption = {
  limit?: number;
  sort?: string;
  offset?: string;
  searchTerm?: string;
  keepExistingMedia?: boolean;
};

export type VideoCount = {
  count: number;
  offset: string;
};

function getSearchTerm(action: any) {
  return action.params.searchTerm;
}
const videoCounts = createSequenceHandlingReducer({}, videoLibraryActions.GET_VIDEO_COUNT, {
  start(state: any, action: any) {
    return u(
      {
        [action.params.searchTerm]: null,
        [action.params.offset]: null,
      },
      state
    );
  },

  done(state: any, action: any) {
    return u(
      {
        [action.params.searchTerm]: {
          count: action?.payload?.count,
          offset: action?.payload?.offset,
        },
      },
      state
    );
  },
});

const videoCountsErrors = createSequenceErrorsByIdReducer(getSearchTerm, videoLibraryActions.GET_VIDEO_COUNT);
const videoCountsLoading = createSequenceCountingByIdReducer(getSearchTerm, videoLibraryActions.GET_VIDEO_COUNT);

function getEndpoint(action: any) {
  return action.meta[CALL_API].endpoint;
}
const videoLibraryResultMapSequence = createSequenceHandlingReducer({}, videoLibraryActions.GET_VIDEO_RESULTS, {
  start(state: any, action: any) {
    return u(
      {
        [getEndpoint(action)]: null,
      },
      state
    );
  },

  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    const newMediaResult = _.get(action, ['payload', 'result']);

    const previousMediaStates: MediaState[] = Object.values(state);
    const previousMediaResult = flatten(previousMediaStates.map(mediaState => mediaState?.results || []));
    const shouldKeepExistingMedia = action.params?.keepExistingMedia;
    return u(
      {
        [getEndpoint(action)]: {
          loadedAt: Date.now(),
          results: shouldKeepExistingMedia ? union(previousMediaResult, newMediaResult) : newMediaResult,
          params: action.params,
        },
      },
      state
    );
  },
});

const videoLibraryResultMapInvalidate = (state: any, action: any) => {
  if (action.type === videoLibraryActions.INVALIDATE_SEARCH_RESULTS) {
    return {};
  }
  return state;
};
const videoLibraryResultMap = serializeReducers([videoLibraryResultMapSequence, videoLibraryResultMapInvalidate]);

const videoResultsLoading = createSequenceCountingByIdReducer(getEndpoint, videoLibraryActions.GET_VIDEO_RESULTS);

const videoResults = (state = {}, action: any) => {
  switch (action.type) {
    case videoLibraryActions.FETCH_VIDEO_INFO: {
      if (action.sequence !== Sequence.DONE || action.error) {
        return state;
      }
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.payload).is.object();
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.payload.entities).is.object();
      // assume only fetch one video, referenceId could be different, so we need to use params id here
      const thisVideoResult = action.payload.entities.videoResult;
      if (thisVideoResult && Object.values(thisVideoResult).length > 0) {
        return u(
          {
            [action.params.assetId]: Object.values(thisVideoResult)[0],
          },
          state
        );
      }
      return state;
    }
    default:
      return createEntityReducer('videoResult', [videoLibraryActions.GET_VIDEO_RESULTS])(state, action);
  }
};

const videoResultErrors = createSequenceErrorsByIdReducer(getEndpoint, videoLibraryActions.GET_VIDEO_RESULTS);
const videoResultAges = createEntityAgeReducer('videoResult', [
  videoLibraryActions.GET_VIDEO_RESULTS,
  videoLibraryActions.FETCH_VIDEO_INFO,
]);

const imageAssets = createEntityReducer('imageAsset', [
  videoLibraryActions.GET_VIDEO_RESULTS,
  videoLibraryActions.FETCH_VIDEO_INFO,
]);

function currentSearch(state = {}, action: any) {
  if (action.type !== videoLibraryActions.SET_CURRENT_SEARCH) {
    return state;
  }
  return { ...action.payload };
}

const subtitlesResults = createEntityReducer('subtitle', [
  videoLibraryActions.GET_VIDEO_RESULTS,
  videoLibraryActions.FETCH_VIDEO_INFO,
]);

const subtitlesDelete = (state: any, action: any) => {
  if (action.type === videoLibraryActions.DELETE_VIDEO_SUBTITLE && action.sequence === Sequence.DONE) {
    const { assetId } = action.params;
    return u(u.omit(assetId), state);
  }
  return state;
};

const videoSubtitles = serializeReducers([subtitlesResults, subtitlesDelete]);

const subtitleProcessingStatuses = (state = {}, action: any) => {
  switch (action.type) {
    case videoLibraryActions.FLAG_VIDEO_SUBTITLE_PROCESSING: {
      return u(
        {
          [action.params.assetId]: true,
        },
        state
      );
    }

    case videoLibraryActions.FETCH_VIDEO_INFO: {
      if (action.sequence === Sequence.DONE && !action.error) {
        const subtitleEntities = _.get(action, ['payload', 'entities', 'subtitle']) || {};
        const hasSubtitles = Object.keys(subtitleEntities).length > 0;

        // If the video now has subtitles, flag that they're no longer processing
        if (hasSubtitles) {
          return u(
            {
              [action.params.assetId]: false,
            },
            state
          );
        }
      }

      return state;
    }

    default:
      return state;
  }
};

const videoMetadataByAssetId = createSequenceHandlingReducer({}, videoLibraryActions.GET_VIDEO_METADATA, {
  start(state: any, action: any) {
    return state;
  },

  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    if (!action.payload || action.payload.length !== 1) {
      return state;
    }

    return u(
      {
        [action.params.assetId]: action.payload[0],
      },
      state
    );
  },
});

export default combineReducers({
  videoCounts,
  videoCountsLoading,
  videoCountsErrors,
  videoResults,
  videoResultAges,
  videoResultsLoading,
  videoResultErrors,
  videoLibraryResultMap,
  currentSearch,
  imageAssets,
  // TODO (piers) Rename to just `subtitles` in order to match schema name
  videoSubtitles,
  subtitleProcessingStatuses,
  videoMetadataByAssetId,
});
