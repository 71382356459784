// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'data... Remove this comment to see the full error message
import toBlob from 'data-uri-to-blob';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { URL } from 'global';

import { ErrorType } from 'config/constants';
import { assertState } from 'utils/assertionUtils';
import { isUri } from 'utils/uriUtils';

const blobUtils = {
  isBlob,
  isBlobUrl,
  blobAsUrl,
  dataUriToBlob,
  blobUrlToBlob,
  createFileFromBlob,
};

export function isBlob(obj: any) {
  return obj instanceof Blob;
}

export function isBlobUrl(obj?: string | null) {
  if (!obj) {
    return false;
  }

  return obj.startsWith('blob:');
}

export function blobAsUrl(blobOrUrl: any) {
  if (blobUtils.isBlob(blobOrUrl)) {
    // has a property we attached indicating it is a blob url refering to this object
    // this avoids having to create a new one, as they are unique.
    if (blobOrUrl.blobUrl) {
      return blobOrUrl.blobUrl;
    }

    blobOrUrl.blobUrl = URL.createObjectURL(blobOrUrl); // eslint-disable-line no-param-reassign

    return blobOrUrl.blobUrl;
  }

  if (isUri(blobOrUrl)) {
    return blobOrUrl;
  }
  const err: Error & {
    errorType?: typeof ErrorType[keyof typeof ErrorType];
  } = new Error('not a uri or a blob');
  err.errorType = ErrorType.URI_TYPE_ERROR;
  throw err;
}

export async function blobUrlToBlob(blobUrl: any, fetchFn: any = fetch) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(blobUtils.isBlobUrl(blobUrl)).is.truthy();
  const response = await fetchFn(blobUrl);
  return response.blob();
}

// Used to make stubbing possible in tests, as trying to stub
// `toBlob` directly from the data-uri-to-blob package results
// in an error: 'Cannot stub non-existent own property toBlob'.
export function dataUriToBlob(dataUri: any) {
  return toBlob(dataUri);
}

export function createFileFromBlob(blob: Blob) {
  const timestamp = new Date().getTime();
  const temporaryFileName = `file_${timestamp}.${getFileExtensionFromBlob(blob)}`;
  return new File([blob], temporaryFileName, { type: blob.type });
}

function getFileExtensionFromBlob(blob: Blob) {
  const mimeType = blob.type;
  return mimeType.split('/')[1];
}

export default blobUtils;
