export type Enum<T> = T[keyof T];

export function enumObject<T>(dictionary: T): T {
  return Object.freeze(dictionary);
}

export function getEnumValueFromString<T>(enumType: T, str: string | undefined): T[keyof T] | undefined {
  if (str) {
    // @ts-ignore
    const enumValues = Object.values(enumType) as string[];
    if (enumValues.includes(str)) {
      return enumType[str as keyof T];
    }
  }
  return undefined;
}
