import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ErrorCodes } from 'utils/errors/api/apiErrorConstants';
import { ErrorContexts } from 'utils/errors/errorConstants';

import type { Notification } from 'types/notifications';

const uploadMediaError = (
  apiResponse: any,
  messagePerErrorCode: any,
  options: any,
  error: any,
  errorCodeParamExtractor: any,
  contextConfig: any,
  mergeContextAndErrorCodeMessages: any,
  mergeAndGenerateRichErrorMessage: any,
  StatusMessageButton: any,
  contextMessage: any
): Notification | null => {
  if (_.get(apiResponse, ['params'], []).some((param: any) => param.indexOf('Unsupported mimeType') >= 0)) {
    const errorCodeConfig = messagePerErrorCode[ErrorCodes.INVALID_DOCUMENT_TYPE];
    const uriLink = options.uri;
    const linkMessage = (
      <FormattedMessage
        id="error-message-url-link-media-file"
        description="Error message displayed to show a link for the media file"
        defaultMessage="Click here to see the file."
      />
    );
    const params = errorCodeConfig.paramExtractor(error, options) || errorCodeParamExtractor(error);

    return {
      severity: contextConfig.severity,
      scope: contextConfig.notificationScope,
      plainMessage: mergeContextAndErrorCodeMessages(contextMessage, errorCodeConfig.message, params),
      richMessage: mergeAndGenerateRichErrorMessage(
        contextMessage,
        errorCodeConfig.message,
        params,
        linkMessage,
        uriLink
      ),
      buttons: [StatusMessageButton.DISMISS],
    };
  }
  return null;
};

export const apiSpecificErrorContext = (
  errorContexts: any,
  apiResponse: any,
  messagePerErrorCode: any,
  options: any,
  error: any,
  errorCodeParamExtractor: any,
  contextConfig: any,
  mergeContextAndErrorCodeMessages: any,
  mergeAndGenerateRichErrorMessage: any,
  StatusMessageButton: any,
  contextMessage: any
) => {
  switch (errorContexts) {
    case ErrorContexts.UPLOAD_MEDIA:
      return () =>
        uploadMediaError(
          apiResponse,
          messagePerErrorCode,
          options,
          error,
          errorCodeParamExtractor,
          contextConfig,
          mergeContextAndErrorCodeMessages,
          mergeAndGenerateRichErrorMessage,
          StatusMessageButton,
          contextMessage
        );
    default:
      return () => null;
  }
};
