import { reselectById } from 'state/common/selectorFactories';
import { getEditorState } from 'state/editor/selectors/editorSelectors';
import {
  getSubtitlesSourceByVideoAssetId,
  getSubtitlesAssetIdByVideoAssetId,
} from 'state/subtitles/selectors/subtitlesSelectors';

import { ComponentScope } from 'config/constants';
import { State } from 'src/types/rootState';

import { createSubtitleFile } from 'views/common/components/SubtitlesPreview/subtitleParser';
import type { SubtitlesEditorState } from 'views/subtitles/state/types/subtitles';

import type { AssetID } from 'types/assets';

export const getSubtitlesEditorState = reselectById<SubtitlesEditorState | undefined | null, AssetID>(
  null,
  (state: State, assetId: any) => getEditorState(state)(ComponentScope.SUBTITLES, assetId),
  (subtitlesEditorState: SubtitlesEditorState): SubtitlesEditorState => subtitlesEditorState
);

export const getSubtitlesEditorText = reselectById<string | undefined | null, AssetID>(
  null,
  (state: State, assetId: any) => getSubtitlesEditorState(state)(getSubtitlesAssetIdByVideoAssetId(state)(assetId)),
  (subtitlesEditorState: SubtitlesEditorState) => {
    if (!subtitlesEditorState || !subtitlesEditorState.isEditing || !subtitlesEditorState.subtitleFragments) {
      return null;
    }

    return createSubtitleFile(subtitlesEditorState.subtitleFragments);
  }
);

const subtitlesUrlsMap: {
  [key: string]: string;
} = {};

export const getSubtitlesEditorTextUrl = reselectById<string | undefined | null, AssetID>(
  null,
  (state: State, assetId: any) => assetId,
  (state: State, assetId: any) => getSubtitlesEditorText(state)(assetId),
  (assetId: AssetID, subtitlesText: string) => {
    if (subtitlesUrlsMap[assetId]) {
      // Clean up an existing url, which won't be used anymore
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      URL.revokeObjectURL(subtitlesUrlsMap[assetId]);
    }

    if (!subtitlesText) {
      return null;
    }
    subtitlesUrlsMap[assetId] = URL.createObjectURL(new Blob([subtitlesText], { type: 'text/vtt' }));
    return subtitlesUrlsMap[assetId];
  }
);

export const getSubtitlesPreviewUrl = reselectById<string | undefined | null, AssetID>(
  null,
  (state: State, assetId: any) => getSubtitlesEditorTextUrl(state)(assetId),
  (state: State, assetId: any) => getSubtitlesSourceByVideoAssetId(state)(assetId),
  (editorUrl: string, sourceUrl: string) => editorUrl || sourceUrl
);
