import React, { ReactNode, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { showModal } from 'state/modals/actions/modalsActions';
import { getTileViolationLevel } from 'state/moderation/selectors/moderationSelectors';

import { ZENDESK_BASE_URL } from 'config/constants';
import { help } from 'icons/SDS/allIcons';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { openInNewWindow } from 'utils/locationUtils';
import { ModalType } from 'utils/modalConfig';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';
import SDSButton, { ButtonType, ButtonTypeEnum } from 'views/common/components/SDSButton/SDSButton';

import * as style from './TileViolationWarning.scss';

import { TileViolationLevel } from 'types/moderation';
import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

const mapStateToProps = (state: State) => {
  return {
    tileViolationLevel: getTileViolationLevel(state),
  };
};

const mapDispatchToProps = {
  showDialog: showModal,
};

type StateProps = ReturnType<typeof mapStateToProps> & ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = StateProps;

type ViolationConfig = {
  message: ReactNode;
  label: JSX.Element;
  buttonType: ButtonTypeEnum;
};

const violationWarningLabel = (
  <FormattedMessage
    id="label-tile-violation-warning"
    description="Label displayed for the tile violation warning button"
    defaultMessage="Profile Restriction Warning"
  />
);

const violationRestrictedLabel = (
  <FormattedMessage
    id="label-tile-violation-restricted"
    description="Label displayed for the tile violation restricted distribiution button"
    defaultMessage="Profile Restricted"
  />
);

const VIOLATION_CONFIGS = new Map<TileViolationLevel, ViolationConfig>([
  [
    TileViolationLevel.SUBSCRIBER_ONLY,
    {
      message: (
        <FormattedMessage
          id="notification-bar-tile-violation-subscriberOnly"
          description="Message displayed for tile violation subscriber only"
          defaultMessage="Due to an excess number of Tile violations, your channel is now receiving limited distribution."
        />
      ),
      label: violationRestrictedLabel,
      buttonType: ButtonType.WARNING_RED,
    },
  ],
  [
    TileViolationLevel.HIGH,
    {
      message: (
        <FormattedMessage
          id="notification-bar-tile-violation-high"
          description="Message displayed for tile violation high"
          defaultMessage="Your channel has an excessive number of Tile violations. Your next tile violation will result in your channel receiving limited distribution."
        />
      ),
      label: violationWarningLabel,
      buttonType: ButtonType.WARNING_ORANGE,
    },
  ],
  [
    TileViolationLevel.MODERATE,
    {
      message: (
        <FormattedMessage
          id="notification-bar-tile-violation-moderate"
          description="Message displayed for tile violation moderate"
          defaultMessage="Your channel is at risk of restricted viewership due to a high number of Tile violations."
        />
      ),
      label: violationWarningLabel,
      buttonType: ButtonType.WARNING_YELLOW,
    },
  ],
]);

export function TileViolationWarning({ tileViolationLevel, showDialog }: Props) {
  const config = VIOLATION_CONFIGS.get(tileViolationLevel);
  const onClick = useCallback(() => {
    incrementCounter('TileViolationWarning.Details');
    const modalConfig: DialogModalOptions = {
      visible: true,
      onConfirm: () => {
        incrementCounter('TileViolationWarning.LearnMore');
        openInNewWindow(`${ZENDESK_BASE_URL}/hc/en-us/articles/1500006354281-Content-Violations-`);
      },
      title: config?.label,
      body: config?.message,
      okText: (
        <FormattedMessage
          id="content-violation-learn-more-button"
          description="Button shown on tile violation warning alert taking user to content violations article"
          defaultMessage="Learn More"
        />
      ),
    };
    showDialog(ModalType.DIALOG, 'TileViolationWarning', modalConfig);
  }, [config, showDialog]);

  if (!config) {
    return null;
  }
  return (
    <div className={style.warning} data-test="TileViolationWarning.Warning">
      <SDSButton
        block
        inlineIcon={help}
        type={config.buttonType}
        onClick={onClick}
        data-test="TileViolationWarning.Button"
      >
        {config.label}
      </SDSButton>
    </div>
  );
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(TileViolationWarning);
