import _ from 'lodash';

import u from 'utils/safeUpdeep';

// When an entity is loaded or saved, the value that the server delivers will sometimes
// have fewer fields than the entity we have locally(intentionally, when the version
// on the server has changed and some fields have been deleted). If we just merge the
// response into the existing entity we have in the store, the removal of values will
// not be reflected in the final entity (as non-present values can have no effect on
// the merge).
//
// To address this, we can wrap each entity in a u.constant() so that the entity in the store
// will be replaced outright rather than merged with the new entity.
//
// @see https://github.com/substantial/updeep#uconstantobject
export function wrapEntitiesInUpdeepConstants(entities: any) {
  return _.mapValues(entities, entity => u.ifDiffConstant(entity));
}
