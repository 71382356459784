import is from 'is_js';
import _, { get } from 'lodash';
import moment from 'moment-timezone';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'vali... Remove this comment to see the full error message
import validator from 'validator';

import { EMPTY_ARRAY, ExternalSnapSource, ShareOption, SnapTag } from 'config/constants';

import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';
import { isNumericId } from 'types/common';
import { ChapterSummary } from 'types/singleAssetStoryEditor';
import type { BottomSnap, SingleAssetSnap, Snap, TagsMap, TopSnap } from 'types/snaps';
import { BottomSnapType, Chapter, Chapters, SnapRelationship, SnapType, TopsnapType } from 'types/snaps';

const MAX_SCC_TAGS = 3;
const MAX_WIKI_TAGS = 5;

export function topsnapHasMedia(topsnap: TopSnap) {
  return topsnapHasImageMedia(topsnap) || topsnapHasVideoMedia(topsnap) || topsnapHasUnknownMedia(topsnap);
}

export function topsnapHasOverlay(topsnap: TopSnap | null) {
  return is.existy(topsnap?.overlayImageAssetId);
}

export function topsnapHasVideoMedia(topsnap: TopSnap | null) {
  return topsnap?.type === TopsnapType.VIDEO && is.existy(topsnap?.videoAssetId);
}

export function topsnapHasImageMedia(topsnap: TopSnap | null) {
  return topsnap?.type === TopsnapType.IMAGE && is.existy(topsnap?.imageAssetId);
}

export function topsnapHasUnknownMedia(topsnap: TopSnap | null) {
  return topsnap?.type === TopsnapType.UNKNOWN && is.existy(topsnap?.assetId);
}

export function topsnapHasSCCTags(topsnap?: TopSnap | null): boolean {
  return (topsnap?.decorations?.discover?.tags?.[SnapTag.SCC]?.length || 0) >= 1;
}

export function parseSnapId(snapId?: string | null): SnapId | undefined | null {
  const snapIdParam: string | undefined | null = snapId ? String(snapId) : null;
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  const parsedSnapId: SnapId | undefined | null = isNumericId(snapIdParam) ? parseInt(snapIdParam, 10) : snapIdParam;
  return parsedSnapId;
}

export function topsnapHasAnyImage(topsnap: TopSnap) {
  return topsnapHasImageMedia(topsnap) || topsnapHasOverlay(topsnap);
}

export function getAssetId(topsnap: TopSnap): AssetID {
  const assetId = get(topsnap, 'imageAssetId') || get(topsnap, 'videoAssetId') || get(topsnap, 'assetId');
  if (is.string(assetId) && isNumericId(assetId)) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'string'.
    return parseInt(assetId, 10);
  }
  return assetId;
}

// Given a snap, returns a full array of its related bottom snaps
// by following the list of relatedSnaps[BOTTOM] linkages all the way
// to the end.
export function getAllBottomSnaps(snap: Snap) {
  const allSnaps = [];
  let currentSnap = snap;
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'BottomSnap | null | undefined' is not assign... Remove this comment to see the full error message
  // eslint-disable-next-line no-cond-assign
  while ((currentSnap = getNextBottomSnap(currentSnap))) {
    allSnaps.push(currentSnap);
  }
  return allSnaps;
}

export function getNextTopSnap(snap: Snap) {
  return snap && snap?.relatedSnaps?.[SnapRelationship.TOP];
}

export function getNextBottomSnap(snap: Snap): BottomSnap | undefined | null {
  return snap && snap?.relatedSnaps?.[SnapRelationship.BOTTOM];
}

export function hasBottomSnap(snap: Snap) {
  return is.existy(getNextBottomSnap(snap));
}

export function bottomSnapHasMedia(bottomsnap: BottomSnap) {
  return (
    (bottomsnap.type === BottomSnapType.LONGFORM_VIDEO && is.existy(bottomsnap?.longformVideoAssetId)) ||
    ((bottomsnap.type === BottomSnapType.POLL || bottomsnap.type === BottomSnapType.ARTICLE) &&
      is.existy(bottomsnap?.appHtml))
  );
}

export function isSubscribeSnap(topsnap: Snap) {
  const nextSnap = getNextBottomSnap(topsnap);
  if (!nextSnap) {
    return false;
  }
  return nextSnap?.type === SnapType.SUBSCRIBE;
}

export function isEmptyTopsnap(topsnap: Snap) {
  return (
    (topsnap?.type === SnapType.IMAGE && !topsnap.imageAssetId) ||
    (topsnap?.type === SnapType.VIDEO && !topsnap.videoAssetId) ||
    (topsnap?.type === SnapType.UNKNOWN && !topsnap.assetId)
  );
}

export function isShareable(snap: Snap) {
  return snap?.shareOption !== ShareOption.NO_SHARE;
}

export function isTopSnap(snap: Snap) {
  return snap && !snap?.relatedSnapIds?.[SnapRelationship.TOP];
}

export function isCuratedSnap(snap: Snap) {
  return (
    isTopSnap(snap) && snap?.externalSnapSource === ExternalSnapSource.STORY_KIT_OUR_STORIES && !!snap?.originalSnapId
  );
}

export function isBottomSnap(snap: Snap) {
  return snap && !!snap.relatedSnapIds?.[SnapRelationship.TOP];
}

export function getAllSnapIdsForSnap(snap: Snap) {
  if (!snap.relatedSnapIds) {
    return [snap.id];
  }

  const relatedSnapIds = Object.keys(snap.relatedSnapIds).map(
    snapType => snap.relatedSnapIds[snapType as SnapRelationship]
  );
  return [snap.id, ...relatedSnapIds];
}

// Get the single (non-subscription) Snap if a story is Single Snap SAS
export const getUnifiedSingleSnap = (snaps: SingleAssetSnap[]) => {
  return snaps
    .filter(snap => snap && !isSubscribeSnap(snap as TopSnap))
    .find(snap => Boolean(snap.chapters) && Boolean(snap.videoAssetId));
};

export const isAdvertisingDisabledForUnifiedSingleSnap = (snaps: SingleAssetSnap[]) => {
  return getUnifiedSingleSnap(snaps)?.isAdsDisabled;
};

function getChapterSummary(chapter: Chapter, index: number, isLast: boolean): ChapterSummary {
  if (chapter.shots && chapter.shots.length > 0) {
    return {
      id: chapter.id,
      startTimeMs: chapter.shots[0]?.startTimeMs || 0,
      durationMs: chapter.shots.reduce((sum, shot) => sum + shot.durationMs, 0),
      index,
      isFirst: index === 0,
      isLast,
    };
  }
  return {
    id: 0,
    startTimeMs: 0,
    durationMs: 0,
    index,
    isFirst: index === 0,
    isLast,
  };
}

export const getChaptersSummary = (chapters?: Chapters) => {
  if (!chapters?.chapters) {
    return undefined;
  }
  return chapters.chapters.map((chapter, index, chaptersList) =>
    getChapterSummary(chapter, index, index === chaptersList.length - 1)
  );
};

export const getSubscribeSnap = (snaps: TopSnap[]) => {
  return snaps.find(snap => snap && isSubscribeSnap(snap));
};

export function isNewlyCreatedSnap(snap: TopSnap) {
  if (!snap) {
    return false;
  }
  // If a snap's lastUpdatedAt value is within 100ms of the creation time, we can assume it's a newly created snap
  const timeDifference = moment(snap.lastUpdatedAt).diff(moment(snap.createdAt));
  // Can't rely only on timestamp as the snap might have been copied
  const hasAssetId = !!getAssetId(snap);
  return timeDifference < 100 && !hasAssetId;
}

export function asTopSnap(snap?: Snap | null): TopSnap | undefined | null {
  if (!snap || !isTopSnap(snap)) {
    return null;
  }
  return (snap as any) as TopSnap;
}

/**
 * Due to a bug in Concorde we can't get the correct top snap ID from bottom snap.
 * Hardcode the logic for getting top snap ID at the moment.
 */
export function getTopSnapIdFromBottomSnapId(id: SnapId): SnapId | undefined | null {
  const components = String(id).split('-');
  if (components.length !== 3) {
    return null;
  }
  return `${components[0]}-${components[1]}`;
}

export function getAssignToAllTags(tagsOnSourceSnap: TagsMap, tagsOnDestinationSnap: TagsMap) {
  // how many SCC tags are needed on destination snap to reach max needed
  const numOfNeededSCCTags = tagsOnDestinationSnap?.SCC
    ? MAX_SCC_TAGS - tagsOnDestinationSnap?.SCC.length
    : MAX_SCC_TAGS;
  // if the destination snap has no tags then add all tags from source snap
  if (!tagsOnDestinationSnap || _.isEmpty(tagsOnDestinationSnap)) {
    return { ...tagsOnSourceSnap, MANUAL: tagsOnSourceSnap.MANUAL || EMPTY_ARRAY };
  }
  // retain wiki tags and add new ones on top of them (removing duplicates)
  const allWikiTags = [...(tagsOnSourceSnap.WIKI || EMPTY_ARRAY), ...(tagsOnDestinationSnap.WIKI || EMPTY_ARRAY)];
  const newWikiTags = _.uniq(allWikiTags).slice(0, MAX_WIKI_TAGS);
  // if the destination snap has 3 SCC already then only add wiki tags
  if (numOfNeededSCCTags <= 0) {
    return { ...tagsOnDestinationSnap, WIKI: newWikiTags, MANUAL: tagsOnDestinationSnap.MANUAL || EMPTY_ARRAY };
  }
  // retain scc tags on the destination snap and add as many unique SCC from source as needed/availible
  const allSCCTags = [...(tagsOnDestinationSnap.SCC || EMPTY_ARRAY), ...(tagsOnSourceSnap.SCC || EMPTY_ARRAY)];
  const newSCCTags = _.uniq(allSCCTags).slice(0, MAX_SCC_TAGS);
  return {
    ...tagsOnDestinationSnap,
    SCC: newSCCTags,
    WIKI: newWikiTags,
    MANUAL: tagsOnDestinationSnap.MANUAL || EMPTY_ARRAY,
  };
}
