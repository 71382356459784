import { createKeySelector, createDynamicKeySelector } from 'state/common/selectorFactories';
import { ActiveModal, ModalConfigMap } from 'state/modals/modalsState';

import { ModalConfig, ModalId } from 'types/modal';
import { State } from 'types/rootState';

export const getModals = (state: State) => state.modals;

export const getModalConfigMap = createKeySelector<ModalConfigMap>(getModals, 'modalConfig', {});
export const getModalConfigById = createDynamicKeySelector<ModalConfig | null, ModalId>(getModalConfigMap, null);
export const getActiveModals = createKeySelector<ActiveModal[]>(getModals, 'activeModals', []);
