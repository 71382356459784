import { combineReducers } from 'redux';

import * as reducerFactory from 'state/common/reducerFactories';

import * as stagesActions from '../actions/stagesActions';
import type { Action } from '../actions/stagesActions';

import u from 'utils/safeUpdeep';

const stageReducer = combineReducers({
  dirty: reducerFactory.serializeReducers([
    reducerFactory.createSequenceHandlingReducer(false, [stagesActions.COMMIT], {
      start(state: any, action: any) {
        return state;
      },
      done(state: any, action: any) {
        if (action.error) {
          return state;
        }
        return false;
      },
    }),
    <Staged extends {}>(state: boolean = false, action: Action<Staged>) => {
      switch (action.type) {
        case stagesActions.STAGE:
          return false;
        case stagesActions.UPDATE_PROPERTIES:
        case stagesActions.REMOVE_PROPERTIES:
          return true;
        default:
          return state;
      }
    },
  ]),
  data: <Staged extends {}>(state = null, action: Action<Staged>) => {
    switch (action.type) {
      case stagesActions.STAGE:
      case stagesActions.UPDATE_PROPERTIES:
        return state ? u(action.payload, state) : action.payload || null;
      case stagesActions.REMOVE_PROPERTIES: {
        return state ? u(u.omit(action.payload.propertyList), state) : null;
      }
      default:
        return state;
    }
  },
});

export const stage = reducerFactory.createByIdReducer('stageId', stageReducer);
export const discard = reducerFactory.createRemoverByIdReducer('stageId', stagesActions.DISCARD);

export default reducerFactory.serializeReducers([stage, discard]);
