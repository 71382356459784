import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import { createStoryAction } from 'state/scs/actions/storyActionHelpers';
import { generateEditionTileIds } from 'state/tiles/schema/tilesIdUtils';

import * as scsAPI from 'utils/apis/scsAPI';

import type { EditionID, BaseEdition } from 'types/editions';
import type { Dispatch, GetState, ThunkAction } from 'types/redux';
import type { SnapReorderOperation } from 'types/scs';

export const UPDATE_STORY = 'scs/story/UPDATE_STORY';

export const reorderSnaps = (
  {
    editionId,
    operation,
  }: {
    editionId: EditionID;
    operation: SnapReorderOperation;
  },
  properties: Partial<BaseEdition>
): ThunkAction =>
  createStoryAction(
    { editionId },
    properties,
    (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
      const action = createCallAction(
        {
          type: UPDATE_STORY,
          params: { editionId },
          meta: {
            schema: editionSchema,
          },
        },
        {
          method: 'put',
          endpoint: scsAPI.story.update(),
          finalizer: generateEditionTileIds,
          body: { storyId: editionId, reorderOperation: operation },
        }
      );

      return dispatch(action);
    }
  );
