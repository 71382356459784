import { isMediaLibraryID, toAssetID } from 'types/assets';

export function addBrightcovePreviewHandlers(editorContainerNode: any, openBrightcovePreview: any) {
  const videoImages = editorContainerNode.querySelectorAll('.in-preview-content img[rel]');

  // videoImages may be empty, in this case `...videoImages` will fail because the result will be an object
  if (videoImages.length === 0) {
    return;
  }

  [...videoImages].forEach(videoImage => {
    // eslint-disable-next-line no-param-reassign
    videoImage.onclick = (event: any) => {
      const assetId = toAssetID(event.target.getAttribute('rel'));
      openBrightcovePreview(assetId);
    };
  });
}

export function idFromBrightcoveReferenceId(referenceId: any) {
  if (isMediaLibraryID(referenceId)) {
    return referenceId;
  }

  const stringId = String(referenceId);
  const strippedId = stringId.slice(stringId.lastIndexOf('-') + 1);
  const id = parseInt(strippedId, 10);
  return Number.isNaN(Number(id)) ? strippedId : id;
}
