import invariant from 'invariant';
import _ from 'lodash';

import type { Enum } from 'utils/enum';
import { enumObject } from 'utils/enum';

export const SubtitlePosition = enumObject({
  BOTTOM: 'BOTTOM',
  TOP: 'TOP',
});

export type SubtitlePositionEnum = Enum<typeof SubtitlePosition>;

export type SubtitleTimestamp = {
  startTimeMs: number;
  endTimeMs: number;
};

export type SubtitleFragment = {
  timestamp: SubtitleTimestamp;
  subtitles: string;
  position: SubtitlePositionEnum;
};

export const EMPTY_SUBTITLE_FRAGMENT: SubtitleFragment = {
  timestamp: {
    startTimeMs: 0,
    endTimeMs: 0,
  },
  subtitles: '',
  position: SubtitlePosition.BOTTOM,
};

const MILLISECONDS_SEPARATOR_REGEX = /[.,]/g;
const TIMESTAMP_REGEX = /(\d+:\d\d:\d\d[,.]\d\d\d) --> (\d\d:\d\d:\d\d[,.]\d\d\d)( line:\d{1,2}%)?/;
const LINE_METADATA_REGEX = / ?line:(\d{1,2})%/;
const TAGS_REGEX = /<[^>]*>/g;
const BR_REGEX = /<br\s*\/?>/i;

export function parseSubtitles(rawSubtitle: string): SubtitleFragment[] {
  return rawSubtitle
    .split(/\r?\n[\s]*\r?\n/)
    .map(fragment => {
      const subtitleLines = fragment.split(/\r?\n/);
      let metadata = null;
      const fragmentLinesToDisplay: any = [];
      let foundMetadata = false;

      subtitleLines.forEach(line => {
        if (foundMetadata && line.length > 0) {
          // We want to remove all tags from displayed line
          fragmentLinesToDisplay.push(line.replace(TAGS_REGEX, ''));
        } else if (line.match(TIMESTAMP_REGEX)) {
          foundMetadata = true;
          metadata = line;
        }
      });

      return { metadata, subtitles: fragmentLinesToDisplay };
    })
    .filter(({ metadata, subtitles }) => metadata != null && subtitles.length > 0)
    .map(({ metadata, subtitles }) => {
      invariant(metadata != null, 'Unreachable code. Null metadata is filtered out.');
      const parsedCueMetadata = parseCueMetadata(metadata);
      return {
        subtitles: subtitles.join('<br>'),
        timestamp: parsedCueMetadata.timestamp,
        position: parsedCueMetadata.position,
      };
    });
}

export enum SubtitlesValidationErrorType {
  TIMESTAMP_ERROR,
}

export type SubtitlesValidationError = {
  type: SubtitlesValidationErrorType;
  argument?: string;
};

function toDisplayTimings(durationMs: number) {
  const [, /* hh */ mm, ss, ms] = subtitleTimingsFromMilliseconds(durationMs);
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return [mm, ss, Math.floor(ms / 10)].map(timing => padZeros(timing, 2));
}

export function toDisplayTimestamp(durationMs: number) {
  const [mm, ss, ms] = toDisplayTimings(durationMs);
  return `${mm}:${ss}.${ms}`;
}

export function validateFragments(subtitleFragments: SubtitleFragment[]): SubtitlesValidationError[] {
  const invalidFragments = subtitleFragments?.filter(
    fragment => !_.isEmpty(fragment.subtitles) && fragment.timestamp.startTimeMs >= fragment.timestamp.endTimeMs
  );

  if (invalidFragments?.length) {
    return [
      {
        type: SubtitlesValidationErrorType.TIMESTAMP_ERROR,
        argument: invalidFragments.map(fragment => toDisplayTimestamp(fragment.timestamp.startTimeMs)).join(', '),
      },
    ];
  }

  return [];
}

function replaceAll(value: string, search: string, replacement: string) {
  return value.replace(new RegExp(search, 'g'), replacement).trim();
}

export function isContentEmpty(value: string) {
  const processedText = replaceAll(value, '&nbsp;', ' ');
  return _.isEmpty(processedText);
}

export function createSubtitleFile(subtitleFragments: SubtitleFragment[]): string {
  const combinedFragments = subtitleFragments
    .filter(fragment => !isContentEmpty(fragment.subtitles))
    .map(fragment => {
      const processedSubString = replaceAll(fragment.subtitles, '&nbsp;', ' ');
      const combinedSubString = processedSubString.split(BR_REGEX).join('\n');
      return (
        `${toStringTimestamp(fragment.timestamp)}${positionString(fragment.position)}\n` +
        `${combinedSubString.replace(/<[^>]*>/g, '')}\n`
      );
    });

  return `WEBVTT\n\n${combinedFragments.join('\n')}`;
}

function parseCueMetadata(timestamp: string) {
  const matches = timestamp.match(TIMESTAMP_REGEX);
  invariant(matches && matches.length === 4, `Invalid timestamp: ${timestamp}`);
  return {
    timestamp: {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      startTimeMs: timestampToMilliseconds(matches[1]),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      endTimeMs: timestampToMilliseconds(matches[2]),
    },
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    position: lineMetadataToPosition(matches[3]),
  };
}

export function subtitleTimingsFromMilliseconds(duration: number) {
  const ms = duration % 1000;
  const ss = Math.floor((duration / 1000) % 60);
  const mm = Math.floor((duration / (1000 * 60)) % 60);
  const hh = Math.floor(duration / (1000 * 60 * 60));

  return [hh, mm, ss, ms];
}

function timestampToMilliseconds(timestamp: string) {
  const [hours, minutes, seconds, milliseconds] = timestamp
    .replace(MILLISECONDS_SEPARATOR_REGEX, ':')
    .split(':')
    .map(num => parseInt(num, 10));

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  return timingsToMilliseconds([hours, minutes, seconds, milliseconds]);
}

function lineMetadataToPosition(lineMetadata: string) {
  if (lineMetadata === undefined) {
    return SubtitlePosition.BOTTOM;
  }
  const linePositionMatch = lineMetadata.match(LINE_METADATA_REGEX);
  invariant(linePositionMatch && linePositionMatch.length === 2, `Invalid line metadata: ${lineMetadata}`);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
  return parseInt(linePositionMatch[1], 10) < 50 ? SubtitlePosition.TOP : SubtitlePosition.BOTTOM;
}

export function timingsToMilliseconds([hours, minutes, seconds, milliseconds]: Array<number>) {
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return milliseconds + (seconds + 60 * minutes + 3600 * hours) * 1000;
}

function positionString(position: SubtitlePositionEnum) {
  return position === SubtitlePosition.TOP ? ' line:10%' : '';
}

function toStringTimestamp(timestamp: SubtitleTimestamp) {
  const startTime = timestampFromMilliseconds(timestamp.startTimeMs);
  const endTime = timestampFromMilliseconds(timestamp.endTimeMs);

  return `${startTime} --> ${endTime}`;
}

const PADDING_CONFIG = [2, 2, 2, 3];
export function timestampFromMilliseconds(duration: number) {
  const [hh, mm, ss, ms] = subtitleTimingsFromMilliseconds(duration)
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
    .map((timing, index) => padZeros(timing, PADDING_CONFIG[index]));

  return `${hh}:${mm}:${ss}.${ms}`;
}

export function padZeros(duration: number, length: number) {
  return _.padStart(String(duration.toFixed()), length, '0');
}
