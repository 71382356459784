// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { MenuItem, Nav, Navbar, NavDropdown } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { intlShape } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import { logout } from 'state/auth/actions/authActions';
import * as featureSelectors from 'state/features/selectors/featuresSelectors';
import { toggleSidebar } from 'state/navLayout/actions/navLayoutActions';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import { goToHomepage, push } from 'state/router/actions/routerActions';
import { setActiveCreator, setLocale } from 'state/user/actions/userActions';
import * as userSelectors from 'state/user/selectors/userSelectors';
import { LOCALES } from 'state/user/userState';

import { LocalStorage } from 'config/constants';
import { intlConnect } from 'utils/connectUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';

import PublisherSearchDropdown from 'views/common/components/PublisherSearchDropdown/PublisherSearchDropdown';
import TileViolationWarning from 'views/common/components/TileViolationWarning/TileViolationWarning';
import { makeOptionsWithGroups } from 'views/dashboard/components/PublisherOption/PublisherOption';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module '!svg-inline-loader?removeSVGTa... Remove this comment to see the full error message
import hamburgerIcon from '!svg-inline-loader?removeSVGTagAttrs=true!images/menu.svg.inline';

import ForcedRolesDropdown from './ForcedRolesDropdown/ForcedRolesDropdown';
import { hasAnyForcedRoles } from './ForcedRolesDropdown/forcedRolesUtils';
import style from './SnapNavBar.scss';

import { Creator } from 'types/creator';
import { Claim } from 'types/permissions';
import { PublisherType, PublisherWithGroup } from 'types/publishers';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const mapStateToProps = state => ({
  user: userSelectors.getUserInfo(state),
  publishers: userSelectors.getPublishers(state),
  groupedPublishers: userSelectors.getGroupedPublishers(state),
  activePublisherId: userSelectors.getActivePublisherId(state),
  activePublisher: userSelectors.getActivePublisher(state),
  activeCreator: userSelectors.getActiveCreator(state),
  shouldRenderLocaleOptions:
    featureSelectors.isNavbarLanguageDropdownEnabled(state) ||
    userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
  activeLocaleId: userSelectors.getActiveLocaleId(state),
  isEmployee: userSelectors.hasSnapchatEmployeePermissions(state),
});
const mapDispatchToProps = {
  setActiveCreator,
  push,
  setLocale,
  logout,
  toggleSidebar,
  clearActiveStory: publisherStoryEditorActions.clearActiveEdition,
  goToHomepage,
};
const ARROW_OFFSET = 175;
export const HIDE_PUBLISHER_PATH_SET = new Set(['/content_review']);

type OwnSnapNavBarProps = {
  location: any;
  user?: any;
  publishers?: any[];
  groupedPublishers?: {
    [key: string]: any[];
  };
  activePublisherId?: number | string;
  activePublisher?: any;
  activeCreator: Creator;
  shouldRenderLocaleOptions?: boolean;
  activeLocaleId?: string;
  topPadding?: boolean;
  hideNavbar?: boolean;
  useLegacyStyle?: boolean;
  className?: string;
  isEmployee?: boolean;
  setLocale: typeof setLocale;
};

type DispatchProps = {
  setActiveCreator: (activeCreator: Creator) => void;
  push: typeof push;
  setLocale: (locale: string) => void;
  logout: (redirect: any, query: any, keepRedirect: boolean) => void;
  toggleSidebar: () => void;
  clearActiveStory: () => void;
  goToHomepage: (params: any) => any;
};

type SnapNavBarState = any;
type SnapNavBarProps = OwnSnapNavBarProps & DispatchProps & typeof SnapNavBar.defaultProps;
export class SnapNavBar extends React.Component<SnapNavBarProps, SnapNavBarState> {
  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    topPadding: false,
  };

  state = {
    hasForcedRoles: hasAnyForcedRoles(),
  };

  componentDidMount() {
    incrementCounter('navbar_current_language_counter', { language: this.props.activeLocaleId || 'default' });
    window.addEventListener('storage', e => {
      if (e.key === LocalStorage.AUTH_TYPE && e.oldValue && !e.newValue) {
        this.handleLogout(e);
      }
    });
  }

  componentDidUpdate(prevProps: SnapNavBarProps) {
    if (prevProps.activeLocaleId !== this.props.activeLocaleId) {
      window.location.reload();
    }
  }

  setCreator = (creator: Creator) => {
    if (creator.hostUsername !== this.props.activeCreator?.hostUsername) {
      this.props.clearActiveStory();
      this.props.setActiveCreator(creator);
    }
  };

  handleLocaleSelect = (locale: { abbr: string }) => async (event: MouseEvent) => {
    event.preventDefault();
    const localeId = locale.abbr;
    if (localeId !== this.props.activeLocaleId) {
      await this.props.setLocale(localeId);
    }
  };

  handleLogout = (event: any) => {
    this.props.logout(undefined, {}, false);
  };

  publisherSelect = (publisher: PublisherWithGroup) => {
    if (publisher.isGroup) {
      return;
    }
    // Input is a publisher, but we need to pass a creator to setActiveCreator
    this.setCreator({
      ...publisher,
      publisherId: publisher.id,
      isTestProfile: publisher.groupType === PublisherType.TEST,
    });
  };

  goToHomepage = () => {
    this.props.goToHomepage({ overwriteHistory: true, publisherId: this.props.activePublisherId });
  };

  onForcedRolesUpdate = () => {
    this.setState({ hasForcedRoles: hasAnyForcedRoles() });
  };

  renderPublisherName() {
    const { groupedPublishers, location } = this.props;
    if (HIDE_PUBLISHER_PATH_SET.has(_.get(location, ['pathname'], ''))) {
      return null;
    }
    const activePublisherName = _.get(this, ['props', 'activePublisher', 'mutablePublisherName'], '');
    const optionsWithGroups = makeOptionsWithGroups(groupedPublishers);

    return (
      <PublisherSearchDropdown
        title={activePublisherName}
        options={optionsWithGroups}
        optionSelected={this.publisherSelect}
        onWelcomePage={this.props.useLegacyStyle}
        data-test="SnapNavBar.PublisherSearchDropdown"
      />
    );
  }

  render() {
    const { topPadding, hideNavbar, className, useLegacyStyle } = this.props;
    const username = _.get(this, ['props', 'user', 'username'], '');
    const mergedNavBarClassName = classNames({
      [style.topPadding]: topPadding,
      [style.navBar]: true,
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [className]: true,
      [style.legacyStyle]: useLegacyStyle,
      [style.hiddenPrint]: true,
    });
    return (
      <Navbar className={mergedNavBarClassName} onToggle={this.props.toggleSidebar}>
        <Navbar.Header>
          {hideNavbar ? (
            <div className={style.noToggle} />
          ) : (
            <Navbar.Toggle className={style.toggle}>
              <InlineSVG src={hamburgerIcon} element="div" data-test="SnapNavBar.hamburgerToggle" />
            </Navbar.Toggle>
          )}
          <Navbar.Brand>
            <div className={style.brand} onClick={this.goToHomepage} data-test="SnapNavBar.HomepageIcon">
              {getMessageFromId('story-studio')}
            </div>
          </Navbar.Brand>
        </Navbar.Header>
        <Nav className={style.nav}>
          <TileViolationWarning />
          {this.renderPublisherName()}
          <NavDropdown
            title={
              <div id="username" className={classNames({ [style.forcedRoles]: this.state.hasForcedRoles })}>
                {username}
              </div>
            }
            id="signout-nav-dropdown"
            className={style.signoutNavDropdown}
            arrowOffsetLeft={ARROW_OFFSET}
            pullRight
          >
            {this.props.shouldRenderLocaleOptions &&
              LOCALES.map((locale, index) => (
                <MenuItem
                  eventKey={locale.abbr}
                  key={locale.abbr}
                  href={locale.abbr}
                  onClick={this.handleLocaleSelect(locale)}
                >
                  <div className={classNames({ [style.menuItemSelected]: this.props.activeLocaleId === locale.abbr })}>
                    {locale.localLang}
                  </div>
                </MenuItem>
              ))}
            {this.props.shouldRenderLocaleOptions && <MenuItem divider />}
            <ForcedRolesDropdown className={style.signoutNavDropdown} onUpdate={this.onForcedRolesUpdate} />
            {this.props.isEmployee && <MenuItem divider />}
            <MenuItem key="signout" eventKey="signout" onClick={this.handleLogout} data-test="signOut.nav.dropDown">
              {getMessageFromId('signout-nav-click')}
            </MenuItem>
          </NavDropdown>
        </Nav>
      </Navbar>
    );
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(SnapNavBar);
