import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import style from './SDSPanelColumn.scss';

type Props = {
  className?: string;
  children?: ReactNode;
};

export default function SDSPanelColumn(props: Props) {
  return (
    <div className={classNames(style.column, props.className)} data-test="settingsCardColumn">
      {props.children}
    </div>
  );
}
