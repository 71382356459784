// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import PollClient from '@snapchat/web-attachments/lib/polls/markup/components/PollClient/PollClient';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import type { LayoutOverrides } from '@snapchat/web-attachments/lib/polls/markup/components/PollLayouts/PollLayoutsFactory';
import React from 'react';
import ReactDOMServer from 'react-dom/server';

import { EMPTY_OBJECT } from 'config/constants';

import type { PollProperties, PollPageData } from 'types/polls';

const defaultPollPageData = {
  publisherName: undefined,
  editionID: undefined,
};

export function renderHtml(
  poll: PollProperties,
  pageData: PollPageData = defaultPollPageData,

  layoutOverrides: LayoutOverrides = EMPTY_OBJECT,
  selectedQuestionIndex?: number | null
): string {
  const html = ReactDOMServer.renderToStaticMarkup(
    <PollClient
      poll={poll}
      snapId={poll.id}
      pageData={pageData}
      layoutOverrides={layoutOverrides}
      forceShowMode={!!selectedQuestionIndex}
      selectedQuestionIndex={selectedQuestionIndex}
    />
  );
  return `<!DOCTYPE html>${html}`;
}
