import { get } from 'lodash';

import { loadAssetInfo } from 'state/asset/actions/assetActions';
import { getExternalMediaId } from 'state/asset/selectors/assetSelectors';
import { createRetryAction } from 'state/common/actionFactories';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as previewsSelectors from 'state/previews/selectors/previewsSelectors';
import {
  getStoryChapters,
  getTranscodedMediaIdForSingleSnapStory,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { topsnapHasMedia, getAssetId } from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';
import * as videoLibrarySelectors from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { SECONDS, ErrorType, Sequence, CrossOrigin } from 'config/constants';
import { Dispatch, GetState } from 'src/types/redux';
import * as creativeSuiteAPI from 'utils/apis/creativeSuiteAPI';
import { buildHashForExistingKeys } from 'utils/apis/partialUpdateUtil';
import { assertState } from 'utils/assertionUtils';
import * as assetUtils from 'utils/media/assetUtils';
import { snapPublisherFrameThumbnailImage } from 'utils/snapPublisherUtils';

import { assertSnapId } from 'types/common';
import { EditionID } from 'types/editionID';
import { Chapter, SnapType } from 'types/snaps';

export const SAVE_SNAP_PREVIEW = 'preview/SAVE_SNAP_PREVIEW';
export const TOGGLE_MUTE_PREVIEW = 'preview/TOGGLE_MUTE_PREVIEW';
export const SET_PREVIEW_VOLUME = 'preview/SET_PREVIEW_VOLUME';
/*
  Because all of the methods need a large variety of variables or to call
  additional actions. These methods are all written in such a way that they
  could be invoked as an action directly as we already need to
  provide the methods with dispatch and getState. However, none of them
  dispatch any actions such that their results are written in the store, they
  simply resolve to their result.

  Since most of these are asyncronous, they will all return a promise.
*/
const generateNullPreview = (snap: any) => (dispatch: any, getState: GetState) => {
  return Promise.resolve(null);
};
// forcedType can be used to force the type of the snap in cases where we know the type but this
// hasn't propagated to the snap in redux yet
// for example, from previewsObservers::loadPublishableSnapPreviews
const generateTopsnapPreview = (snap: any, forcedType = null) => (dispatch: any, getState: GetState) => {
  return Promise.resolve().then(() => {
    if (!topsnapHasMedia(snap)) {
      return null;
    }
    const type = forcedType || snap.type;
    if (type === SnapType.IMAGE) {
      return assetUtils.getImagePreviewUrl(getAssetId(snap));
    }
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(type).is.inArray([SnapType.VIDEO, SnapType.SINGLE_ASSET]);
    const assetId = getAssetId(snap);
    const uploadedMediaUrl = mediaSelectors.getUploadUrlByMediaId(getState())(assetId);
    const richSnapDownloadUrl = assetUtils.createAssetUrl(assetId);
    const topsnapThumbnailUrl = assetUtils.getVideoAssetPosterUrl(getAssetId(snap));
    const dimensions = {
      width: get(snap, 'circular', false) ? 220 : 124,
      height: 220,
    };
    const generatePreviewWithMediaUrl = (mediaUrl: any) =>
      Promise.resolve()
        .then(() => mediaActions.getValidThumbnailUrl(topsnapThumbnailUrl))
        .catch(() =>
          Promise.resolve()
            .then(() => dispatch(mediaActions.grabFrameOnce(mediaUrl, dimensions.width, dimensions.height)))
            .then(
              () =>
                // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
                mediaSelectors.getFrameResultByUrlWidthHeight(getState())(mediaUrl, dimensions.width, dimensions.height)
                  .result
            )
        );
    // https://jira.sc-corp.net/browse/PUB-4488
    // When a user uploads an asset to story studio we can either use that upload url or the
    // rsp download url to generate a preview from
    // We prioritise the upload url but fallback to the RSP url if this fails
    if (!uploadedMediaUrl) {
      return generatePreviewWithMediaUrl(richSnapDownloadUrl);
    }
    return Promise.resolve()
      .then(() => generatePreviewWithMediaUrl(uploadedMediaUrl))
      .catch(() => generatePreviewWithMediaUrl(richSnapDownloadUrl));
  });
};
const RETRY_TIMEOUT = 15 * SECONDS;
const RETRY_MAX = 20;
const generateVideoPreviewOnce = (snap: any) => (dispatch: any, getState: GetState) => {
  // We can't generate a preview if the snap doesn't have a video yet
  if (!snap.longformVideoAssetId) {
    return Promise.resolve(null);
  }
  return Promise.resolve(
    dispatch(
      videoLibraryActions.fetchVideoInformation({
        assetId: snap.longformVideoAssetId,
      })
    )
  ).then(() => {
    const videoResult = videoLibrarySelectors.getVideoResultById(getState())(snap.longformVideoAssetId);
    const thumbSrc = get(videoResult, ['images', 'poster', 'src']);
    if (thumbSrc && videoResult.complete) {
      return thumbSrc;
    }
    const err = new Error('no poster found');
    (err as any).errorType = ErrorType.NO_POSTER_ERROR;
    return Promise.reject(err);
  });
};
/*
  This action by default will be retried because if the video is still building
  because it was just uploaded - a very frequent case, we need to wait until.

  There is no harm in resolving the action much later than normal because we check the hash
    before saving the preview data.

  Approximate time for the previews to rebuild is a minute. A new upload can take
    approximately 5 minutes so we allow retrying for 20 - 15 second intervals.
*/
const generateVideoPreview = createRetryAction(generateVideoPreviewOnce, {
  maxRetries: RETRY_MAX,
  timeout: RETRY_TIMEOUT,
  errorsMatching: (err: any) => {
    return get(err, 'errorType') === ErrorType.NO_POSTER_ERROR;
  },
});
const invalidSnapType = (snap: any) => (dispatch: any, getState: GetState) =>
  Promise.reject(new Error(`No preview option found for RichSnapType: ${snap.type}`));

const snapTypeToInit = {
  [SnapType.VIDEO]: generateTopsnapPreview,
  [SnapType.SINGLE_ASSET]: generateTopsnapPreview,
  [SnapType.IMAGE]: generateTopsnapPreview,
  [SnapType.UNKNOWN]: generateTopsnapPreview,
  [SnapType.ARTICLE]: generateNullPreview,
  [SnapType.LONGFORM_VIDEO]: generateVideoPreview,
  [SnapType.POLL]: generateNullPreview,
  [SnapType.SUBSCRIBE]: generateNullPreview,
  [SnapType.REMOTE_WEB]: generateNullPreview,
  [SnapType.BITMOJI_REMOTE_WEB]: generateNullPreview,
  [SnapType.CAMERA_ATTACHMENT]: generateNullPreview,
  [SnapType.BITMOJI_REMOTE_VIDEO]: generateNullPreview,
  [SnapType.CAMEOS_CONTENT]: generateNullPreview,
};

const createSnapPreview = (snapId: any, forcedType: any) => (dispatch: any, getState: GetState) => {
  assertSnapId(snapId);
  const snap = snapsSelectors.getNormalizedSnapById(getState())(snapId);
  const initialize = snap ? snapTypeToInit[snap.type] : null;
  if (!initialize) {
    return dispatch(invalidSnapType(snap));
  }
  return dispatch(initialize(snap, forcedType));
};
const previewHash = (snap: any) =>
  buildHashForExistingKeys(snap, {
    imageAssetId: true,
    videoAssetId: true,
    longformVideoAssetId: true,
    assetId: true,
  });

export const loadSnapPreview = (snapId: any, forcedType = null) => (dispatch: any, getState: GetState) => {
  assertSnapId(snapId);
  const snap = snapsSelectors.getNormalizedSnapById(getState())(snapId);
  const snapHash = previewHash(snap);
  dispatch({
    type: SAVE_SNAP_PREVIEW,
    params: { snapId },
    sequence: Sequence.START,
    payload: {
      hash: snapHash,
      // Does not set the result to null. Will retain old value until the change is committed.
      // This avoids two issues: preview images 'blinking' and a costly react render being triggered multiple times
    },
  });
  return createSnapPreview(snapId, forcedType)(dispatch, getState)
    .then(
      // Also store null results, to note down that they
      // don't need to be loaded.
      // send a time stamp, so we only store newer results
      (result: any) =>
        dispatch({
          type: SAVE_SNAP_PREVIEW,
          sequence: Sequence.DONE,
          params: { snapId },
          payload: {
            result,
            hash: snapHash,
          },
        })
    )
    .catch(() => {
      dispatch({
        type: SAVE_SNAP_PREVIEW,
        params: { snapId },
        sequence: Sequence.START,
        payload: {
          result: null,
          hash: null,
        },
      });
    });
};
/*
  This one checks if there is already a preview being generated for this hash,
  otherwise nothing happens.
*/
export const loadSnapPreviewIfMissing = (snapId: any, forcedType = null) => (dispatch: any, getState: GetState) => {
  assertSnapId(snapId);
  const snap = snapsSelectors.getNormalizedSnapById(getState())(snapId);
  const snapHash = previewHash(snap);
  const snapPreviewMetadata = previewsSelectors.getSnapPreviewMetadataById(getState())(snapId);
  if (snap == null || (snapPreviewMetadata as any).hash === snapHash) {
    return Promise.resolve();
  }
  return dispatch(loadSnapPreview(snapId, forcedType));
};

const loadSnapPublisherFramePreview = (mediaId: string, frame: number) => {
  return new Promise((resolve, reject) => {
    const MAX_RETRIES = 3;
    const RETRY_INTERVAL_MS = 1000;

    let retriesCount = 0;

    const loadImage = () => {
      const image = new Image();
      image.crossOrigin = CrossOrigin.USE_CREDENTIALS;
      // Configure retries on error or reject when max retries reached.
      image.onerror = () => {
        if (retriesCount < MAX_RETRIES) {
          retriesCount++;
          setTimeout(loadImage, RETRY_INTERVAL_MS);
        } else {
          reject();
        }
      };
      // Fulfill the promise on image load.
      image.onload = () => {
        resolve(image);
      };
      image.src = snapPublisherFrameThumbnailImage(mediaId, frame);
    };

    loadImage();
  });
};

export const loadChapterPreviewIfMissing = (chapter: Chapter, storyId: EditionID, mediaId: string) => async (
  dispatch: Dispatch
) => {
  if (!chapter.shots || !chapter.shots[0]) {
    return;
  }
  const startFrame = chapter.shots[0].startFrame;
  const url = creativeSuiteAPI.creative.previewImage(mediaId, startFrame)();

  const snapId = `${storyId}-${chapter.id}`;
  // This is not an actual snap so we mock the hash.
  const snapHash = 'CHAPTERS_GENERATED';

  await dispatch({
    type: SAVE_SNAP_PREVIEW,
    sequence: Sequence.START,
    params: { snapId },
    payload: {
      hash: snapHash,
    },
  });
  await dispatch({
    type: SAVE_SNAP_PREVIEW,
    sequence: Sequence.DONE,
    params: { snapId },
    payload: {
      result: url,
      hash: snapHash,
    },
  });
};

export const loadChaptersPreview = (storyId: EditionID) => async (dispatch: Dispatch, getState: GetState) => {
  const chapters = getStoryChapters(getState())(storyId);
  const transcodedMediaId = getTranscodedMediaIdForSingleSnapStory(getState())(storyId);
  if (!chapters || !chapters.chapters || !transcodedMediaId) {
    return;
  }
  await dispatch(loadAssetInfo(transcodedMediaId));
  const mediaId = getExternalMediaId(getState())(transcodedMediaId);
  // Snap Publisher may take a while to auth so we try to fetch first frame before attempting to load all images.
  await loadSnapPublisherFramePreview(mediaId, 0);
  await Promise.all(chapters.chapters.map(chapter => dispatch(loadChapterPreviewIfMissing(chapter, storyId, mediaId))));
};

export const toggleMutePreview = () => {
  return (dispatch: any) => {
    return dispatch({
      type: TOGGLE_MUTE_PREVIEW,
    });
  };
};
export const setPreviewVolume = (volume: any) => {
  return (dispatch: any) => {
    return dispatch({
      type: SET_PREVIEW_VOLUME,
      volume,
    });
  };
};
