import is from 'is_js';
import _ from 'lodash';

import * as notificationsActions from 'state/notifications/actions/notificationsActions';

import { StatusMessageSeverity, NotificationScope, StatusMessageButton } from 'config/constants';
import { MediaValidation } from 'utils/errors/media/mediaConstants';
import * as intlMessages from 'utils/intlMessages/intlMessages';

const parseErrorForMessageId = (error: any) => {
  if (is.string(error) && error.indexOf('Failed to fetch') >= 0) {
    return 'connection-error-on-upload';
  }
  if (error instanceof ProgressEvent) {
    return 'connection-error-on-upload';
  }
  return 'error-reading-media-file';
};

export function buildNotificationMessageParams(error: any) {
  const intlMessageFromError = _.get(error, ['intlError', 'intlMessageId']);
  const intlMessageParams = _.get(error, ['intlError', 'values']) || {};

  return {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.SNAP,
    plainMessage: intlMessages.getMessageFromId(
      intlMessageFromError || parseErrorForMessageId(error),
      intlMessageParams
    ),
    buttons: [StatusMessageButton.DISMISS],
  };
}

export function mediaErrorHandler(dispatch: any, context: any) {
  return (error: any): any => {
    if (error instanceof Error && (error as any)?.alreadyHandled) {
      return Promise.reject(error);
    }
    dispatch(notificationsActions.sendNotificationMessage(buildNotificationMessageParams(error), { context }));
    // TODO: replace Error with custom cms hierarchy
    if (error instanceof Error) {
      (error as any).alreadyHandled = true; // eslint-disable-line no-param-reassign
    }
    return Promise.reject(error);
  };
}

export const getMessageForMediaError = (errorMessage: string) => {
  switch (errorMessage) {
    case MediaValidation.EXCESSIVE_BITRATE:
      return 'media-validation-error-excessive-bitrate';
    case MediaValidation.VARIABLE_FRAME_RATE:
      return 'media-validation-error-variable-frame-rate';
    case MediaValidation.UNEXPECTED_FILE_TYPE:
      return 'media-validation-error-unexpected-file-type';
    case MediaValidation.UNEXPECTED_DURATION:
      return 'media-validation-error-unexpected-duration';
    case MediaValidation.WRONG_ASPECT_RATIO:
      return 'media-validation-error-wrong-aspect-ratio';
    case MediaValidation.NO_SOUND:
      return 'media-validation-error-no-sound';
    default:
      return 'error-mls-claim';
  }
};
