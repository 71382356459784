import { combineReducers } from 'redux';

import * as autocompleteActions from 'state/autocomplete/actions/autocompleteActions';
import { TagMap } from 'state/autocomplete/autocompleteState';

import { EMPTY_OBJECT, Sequence } from 'config/constants';

const sccTags = (state: TagMap = EMPTY_OBJECT, action: any) => {
  switch (action.type) {
    case autocompleteActions.GET_AUTOCOMPLETE_SCC_SUGGESTIONS:
      if (action.sequence !== Sequence.START) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------

export default combineReducers({
  sccTags,
});
