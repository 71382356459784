/* eslint-disable new-cap */
import is from 'is_js';
import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import * as articleActions from 'state/article/actions/articleActions';
import { createActivatingIdReducer } from 'state/common/reducerFactories';
import * as editorActions from 'state/editor/actions/editorActions';
import editorState from 'state/editor/reducers/editorStateReducers';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';

import { EMPTY_OBJECT, ErrorType } from 'config/constants';

import {
  CREATE_INTERACTION,
  LOAD_SNAP,
  SET_SNAP_PROPERTIES,
  UPDATE_INTERACTION,
} from 'types/actions/snapsActionsTypes';

const activeTopsnapId = createActivatingIdReducer('snapId', editorActions.SET_ACTIVE_TOPSNAP);
const activeEditionId = createActivatingIdReducer('editionId', editorActions.SET_ACTIVE_EDITION);
const activeTileId = createActivatingIdReducer('tileId', editorActions.SET_ACTIVE_TILE);
const unreadAttachmentId = createActivatingIdReducer('attachmentId', editorActions.SET_UNREAD_ATTACHMENT);
const activeComponentId = createActivatingIdReducer('componentId', editorActions.SET_ACTIVE_COMPONENT);
const CLEAR_NON_UPLOAD_ERROR_ACTIONS = new Set([
  SET_SNAP_PROPERTIES,
  UPDATE_INTERACTION,
  CREATE_INTERACTION,
  LOAD_SNAP,
  UPDATE_INTERACTION,
  mediaActions.UPLOAD_MEDIA,
  articleActions.NOTIFY_ARTICLE_EDITED,
  articleActions.SAVE_ARTICLE,
  videoLibraryActions.DELETE_VIDEO_SUBTITLE,
  videoLibraryActions.UPLOAD_TO_ENDPOINT,
]);
const FORCE_CLEAR_ERROR_ACTIONS = new Set([editorActions.SET_ACTIVE_TOPSNAP, editorActions.SET_ACTIVE_COMPONENT]);
const statusMessage = (state = {}, action: any) => {
  if (action.type === editorActions.SET_STATUS_MESSAGE) {
    return { ...action.payload };
  }
  if (FORCE_CLEAR_ERROR_ACTIONS.has(action.type)) {
    return is.empty(state) ? state : EMPTY_OBJECT;
  }
  if ((state as any).errorType !== ErrorType.UPLOAD && CLEAR_NON_UPLOAD_ERROR_ACTIONS.has(action.type)) {
    return is.empty(state) ? state : EMPTY_OBJECT;
  }
  if ((state as any).errorType === ErrorType.UPLOAD && action.type === mediaActions.UPLOAD_MEDIA) {
    return is.empty(state) ? state : EMPTY_OBJECT;
  }
  return state;
};
function setEditorTilePropertiesHandler(state: any, action: any) {
  const update = {
    pendingTiles: {
      [action.payload.tileId]: action.payload.properties,
    },
  };
  return u(update, state);
}
function removeEditorTilePropertiesHandler(state: any, action: any) {
  return u(
    {
      pendingTiles: {
        [action.payload.tileId]: u.omit(action.payload.properties),
      },
    },
    state
  );
}
const editorConfig = (state = {}, action: any) => {
  switch (action.type) {
    case editorActions.SET_EDITOR_CONFIG_TILE_PROPERTIES:
      return setEditorTilePropertiesHandler(state, action);
    case editorActions.REMOVE_EDITOR_CONFIG_TILE_PROPERTIES:
      return removeEditorTilePropertiesHandler(state, action);
    case editorActions.SET_EDITOR_CONFIG_PROPERTIES:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default combineReducers({
  activeTopsnapId,
  activeEditionId,
  activeComponentId,
  activeTileId,
  unreadAttachmentId,
  editorConfig,
  statusMessage,
  editorState,
});
