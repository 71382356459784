import type { SubtitleTrack } from 'constants/subtitles';

import type { AssetID, MimeTypeEnum } from 'types/assets';
import type { SnapId } from 'types/common';
import type { Shot } from 'types/snaps';

export type VideoPlayerState = {
  currentTime: number; // Gets updated when player ticks,
  pendingCurrentTime: number; // Gets updated by processes outside of the player, i.e. change snap, go forward, back, click on timeline,
  totalDuration: number;
  isPlaying: boolean;
};

export enum ConfigTab {
  VIDEO = 'VIDEO',
  ADVERTS = 'ADVERTS',
  TILES = 'TILES',
  SNAPS = 'SNAPS',
  SNAP = 'SNAP',
  ATTACH = 'ATTACH',
  SUBSCRIBE_SNAP = 'SUBSCRIBE_SNAP',
  SUBTITLES = 'SUBTITLES',
}

export enum SingleAssetTranscodeStatus {
  SUCCESS = 'SUCCESS',
  BUILDING = 'BUILDING',
  FAILED = 'FAILED',
}

export type AdShotSlot = {
  shotIndex: number;
  allowedIndexes: number[];
};

export type VideoInfo = {
  id?: string;
  transcodedMediaId?: string;
  src?: string | null;
  poster?: string;
  activeSubtitleTrack?: SubtitleTrack | null;
  mimeType?: MimeTypeEnum;
};

export type ShotID = string;

export type ExtendedShot = {
  id: ShotID;
  snapId: SnapId;
  startTimeMs: number;
  durationMs: number;
  isFirst: boolean;
  isLast: boolean;
  index: number;
  startFrame: number;
};

export type ShotWithSnapId = Shot & {
  snapId: SnapId;
};

export type TimelineSnap = {
  snapId: SnapId;
  startTimeMs: number;
  durationMs: number;
  isFirst: boolean;
  isLast: boolean;
  index: number;
  shots: Shot[];
  notes?: string | undefined;
  tags?: { [k in string]: [string] };
  videoAssetId: AssetID;
};

export type ChapterSummary = {
  id: number | string;
  index: number;
  startTimeMs: number;
  durationMs: number;
  isFirst: boolean;
  isLast: boolean;
};

export type Advert = {
  startTimeMs: number;
  shotIndex: number;
};

export enum AdTimelineElementType {
  AD = 'AD',
  AD_PLACEHOLDER = 'AD_PLACEHOLDER',
}

// Support types for AdTimeline which uses a list with heterogeneous types

export type AdElement = {
  type: AdTimelineElementType.AD;
  adSlot: AdShotSlot;
  width: number;
  markerPosition: number;
};

export type AdPlaceholderElement = {
  type: AdTimelineElementType.AD_PLACEHOLDER;
  index: number;
  width: number;
  markerPosition: number;
  validPositionForMoving: boolean;
};

export type AdTimelineElement = AdElement | AdPlaceholderElement;

export type ShadeArea = {
  width: number;
  left: number;
  key: number;
  adTimelineElements: AdTimelineElement[];
};

export type SingleAssetStoryEditorState = {
  videoPlayer?: VideoPlayerState;
  activeConfigTab?: ConfigTab;
  adSlotBeingDragged?: AdElement;
  isShowingAd?: boolean;
  isInDebugMode?: boolean;
  isEditingAds?: boolean;
};

export enum SingleAssetUploadState {
  NONE = 'NONE',
  UPLOADING = 'UPLOADING',
  COMPLETE = 'COMPLETE',
}
