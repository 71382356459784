import { StorySnapResponse } from 'gql/queries/spotlight/storySnaps';

export const SET_SPOTLIGHT_SNAPS = 'storySnapsActions/SET_SPOTLIGHT_SNAPS';

export const setSpotlightStorySnaps = (storySnapsData: StorySnapResponse) => {
  return (dispatch: any) => {
    return dispatch({
      type: SET_SPOTLIGHT_SNAPS,
      storySnapsData,
    });
  };
};
