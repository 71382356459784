import is from 'is_js';
import stringify from 'json-stable-stringify';
import md5 from 'md5';

import { assertArg } from 'utils/assertionUtils';

export class HashBuilder {
  _properties = {};

  addPrimitive(name: any, value: any) {
    if (!is.existy(value)) {
      return;
    }
    insertValue(name, value, this._properties);
  }

  addUnorderedArray(name: any, array: any) {
    if (!is.existy(array)) {
      return;
    }
    const values = array.filter(is.existy).sort();
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const container = (this._properties[name] = []);
    values.forEach((value: any, index: any) => {
      insertValue(index, value, container);
    });
  }

  addOrderedArray(name: any, array: any) {
    if (!is.existy(array)) {
      return;
    }
    const values = array.filter(is.existy);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const container = (this._properties[name] = []);
    values.forEach((value: any, index: any) => {
      insertValue(index, value, container);
    });
  }

  addObject(name: any, object: any) {
    if (!is.existy(object)) {
      return;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const container = (this._properties[name] = {});
    Object.keys(object).forEach(key => {
      insertValue(key, object[key], container);
    });
  }

  toString() {
    return stringify(this._properties);
  }

  toHash() {
    return generateHash(this.toString());
  }
}
type Options = {
  unorderedArrayProperties?: unknown[];
};
// Used to generate hashes that are sent to the server along with snap update requests
// in order to protect against accidental overwrites.
//
// See server-side implementation at:
//
//     https://github.sc-corp.net/Snapchat/content-platform/blob/main/rich-snap-platform/
//     domain/src/main/java/snapchat/richsnap/domain/WireModelHashBuilder.java
//
// TODO: cycle detection?
export function build(propertiesToHash: {}, options?: Options) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(propertiesToHash).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(options).is.object.or.is.undefined();
  const hashBuilder = new HashBuilder();
  addAllProperties(hashBuilder, propertiesToHash, options);
  return hashBuilder;
}
// *** WARNING ***
//
// Note that we _intentionally_ don't recurse here. The snap structure does not include
// any nested objects/arrays, and if in future it changes so that nested objects are
// introduced, we will probably need to rethink the hashing logic anyway (hash each tier
// of the tree from the bottom up rather than pushing all properties into a flat array and
// then hashing that).
function addAllProperties(hashBuilder: any, properties: any, options = {}) {
  const unorderedArrayProperties = (options as any).unorderedArrayProperties || [];
  Object.keys(properties).forEach(name => {
    const value = properties[name];
    if (Array.isArray(value)) {
      if (unorderedArrayProperties.indexOf(name) > -1) {
        hashBuilder.addUnorderedArray(name, value);
      } else {
        hashBuilder.addOrderedArray(name, value);
      }
    } else if (is.object(value)) {
      hashBuilder.addObject(name, value);
    } else {
      hashBuilder.addPrimitive(name, value);
    }
  });
}
function stringifyObject(objectToHash: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(objectToHash).is.object();
  return build(objectToHash).toString();
}
function insertValue(key: any, value: any, container: any) {
  if (is.string(value) || is.number(value) || is.boolean(value)) {
    container[key] = value; // eslint-disable-line no-param-reassign
  } else if (is.array(value)) {
    container[key] = []; // eslint-disable-line no-param-reassign
    value.forEach((val: any, index: any) => insertValue(index, val, container[key]));
  } else if (is.object(value)) {
    container[key] = stringifyObject(value); // eslint-disable-line no-param-reassign
  } else if (is.not.existy(value)) {
    // Do nothing - nulls are skipped
  } else {
    throw new Error(`Unexpected value type encountered when trying to build hash: ${typeof value}`);
  }
}
function generateHash(propertiesString: any) {
  return md5(propertiesString);
}
