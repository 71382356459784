import { normalize, arrayOf } from 'normalizr';
import { combineReducers } from 'redux';

import {
  createSequenceHandlingReducer,
  serializeReducers,
  createRemoverByIdReducer,
} from 'state/common/reducerFactories';
import * as extrasActions from 'state/extras/actions/extrasActions';
import * as showsActions from 'state/shows/actions/showsActions';
import { showSchema } from 'state/shows/schema/showsSchema';

import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

export const extrasById = createSequenceHandlingReducer({}, showsActions.LOAD_SHOWS, {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }

    const normalized = normalize(action.payload, arrayOf(showSchema));
    if ('result' in normalized) {
      return u(wrapEntitiesInUpdeepConstants(normalized.entities.extra), state);
    }
    return state;
  },
});

export const updateById = createSequenceHandlingReducer({}, [extrasActions.CREATE_EXTRA], {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error || !action.payload) {
      return state;
    }

    return u.updateIn(action.payload.id, action.payload, state);
  },
});

export const unlinkExtra = createSequenceHandlingReducer({}, [extrasActions.UNLINK_EXTRA], {
  start(state: any) {
    return state;
  },
  done: createRemoverByIdReducer('extraId', [extrasActions.UNLINK_EXTRA]),
});

const byId = serializeReducers([extrasById, updateById, unlinkExtra]);

export default combineReducers({
  byId,
});
