import React, { createRef, SyntheticEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { getUserBitmojiAvatarId } from 'state/user/selectors/userSelectors';

import { getBitmojiUrl, BITMOJI_TEMPLATES, DEFAULT_BITMOJI_AVATAR } from 'config/bitmojiConfig';
import { CrossOrigin } from 'config/constants';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';

import HelpCenterLink, { HelpCenterDestination } from 'views/common/components/HelpCenterLink/HelpCenterLink';
import Icon from 'views/common/components/Icon/Icon';
import SDSButton, { ButtonSize, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSCarousel, { SDSCarouselRef } from 'views/common/components/SDSCarousel/SDSCarousel';
import SDSCheckbox, { CheckboxEvent } from 'views/common/components/SDSCheckbox/SDSCheckbox';
import SDSCustomModal from 'views/common/components/SDSCustomModal/SDSCustomModal';
import SDSPanelRow from 'views/common/components/SDSPanel/SDSPanelRow/SDSPanelRow';
import { NewUserExperienceMailingForm } from 'views/modals/containers/NewUserExperienceHelpModal/NewUserExperienceMailingForm/NewUserExperienceMailingForm';

import style from './NewUserExperienceHelpModal.scss';

export type HelpModalConfig = {
  hasMailingForm: boolean;
  modalTitle: JSX.Element;
  modalSlideContents: HelpModalContent[];
  onDontShowModalAgain: (dontShowAgain: boolean) => void;
};

export type HelpModalContent = {
  id: string;
  modalSubHeader: React.ReactChild;
  modalSections: ModalSection[];
};

export enum HelpModalTypes {
  NEW_PUBLISHER_MODAL = 'new_publisher_help_modal',
  NEW_STORY_MODAL = 'new_story_help_modal',
}

export type ModalSection = {
  id: string;
  sectionBitmojiTemplate?: BITMOJI_TEMPLATES;
  sectionHeader: React.ReactChild;
  sectionContent: React.ReactChild;
  link?: ModalLink;
};

export type ModalLink = {
  linkDestination: HelpCenterDestination;
};

export type ModalOption = {
  modalConfig: HelpModalConfig;
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = {
  hideModal: (modalId: string) => void;
  setShowHelpModalFlag: (showModal: boolean, storage: String) => Promise<void>;
  modalId: string;
  options: ModalOption;
} & StateProps;

type OwnState = {
  isLastSlide: boolean;
  dontShowModal: boolean;
};

export const mapStateToProps = (state: State) => {
  return {
    isLastSlide: false,
    bitmojiAvatarId: getUserBitmojiAvatarId(state) || DEFAULT_BITMOJI_AVATAR,
  };
};

export class NewUserExperienceHelpModal extends React.Component<Props, OwnState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLastSlide: false,
      dontShowModal: false,
    };
  }

  private carouselRef = createRef<SDSCarouselRef>();

  closeModal = () => {
    this.props.hideModal(this.props.modalId);
  };

  handleSlideChange = (currentIndex: number, nextIndex: number) => {
    this.setState(state => ({
      isLastSlide: this.getSlideSize() - 1 <= nextIndex,
    }));
  };

  handleMailingListSubmit = () => {
    // Todo(jalonge): Implement calls to the backend service
  };

  getSlideSize = (): number => {
    const modalConfig: HelpModalConfig = this.props.options.modalConfig;
    const contentSize = modalConfig.modalSlideContents.length;
    return modalConfig.hasMailingForm ? contentSize + 1 : contentSize;
  };

  handleNextSlideClick = () => {
    this.carouselRef.current?.next();
  };

  handleShowHelpModalClick = (event: CheckboxEvent<boolean> | SyntheticEvent) => {
    event.preventDefault();
    const dontShowModal = !this.state.dontShowModal;
    this.setState(state => ({ dontShowModal }));
    this.props.options.modalConfig.onDontShowModalAgain(dontShowModal);
  };

  renderModalHelpContent(): Array<React.ReactChild> {
    const { modalSlideContents, hasMailingForm } = this.props.options.modalConfig as HelpModalConfig;
    const helpContents = modalSlideContents.map((modalData, index) => this.renderModalSection(modalData));

    if (hasMailingForm) {
      this.renderMailingForm(helpContents);
    }

    return helpContents;
  }

  renderModalSection(modalData: HelpModalContent) {
    return (
      <section key={modalData.id}>
        <div className={style.subTitle} data-test={`helpModal.subTitle-${modalData.id}`}>
          {modalData.modalSubHeader}
        </div>
        <div className={style.contentRow}>{this.renderModalRow(modalData.modalSections)}</div>
      </section>
    );
  }

  renderModalRow(modalSections: ModalSection[]) {
    return (
      <SDSPanelRow>
        {modalSections.map(modalRow => (
          <div key={modalRow.id} className={style.contentSection}>
            {this.renderBitmojiAvatar(modalRow.sectionBitmojiTemplate, this.props.bitmojiAvatarId)}
            <div className={style.contentHeaderLabel} data-test={`helpModal.contentHeaderLabel-${modalRow.id}`}>
              {modalRow.sectionHeader}
            </div>
            <div className={style.modalContent} data-test={`helpModal.modalContent-${modalRow.id}`}>
              {modalRow.sectionContent}
            </div>
            {modalRow.link && (
              <div className={style.contentViewLink}>
                <HelpCenterLink
                  destination={modalRow.link.linkDestination}
                  data-test={`helpModal.UploadStoryHelp.tagLabel-${modalRow.id}`}
                />
              </div>
            )}
          </div>
        ))}
      </SDSPanelRow>
    );
  }

  renderMailingForm(helpContents: Array<React.ReactChild>) {
    helpContents.push(<NewUserExperienceMailingForm key="mailing-form-section" />);
  }

  renderCarouselActionButton(): React.ReactChild {
    if (this.state.isLastSlide && this.props.options.modalConfig.hasMailingForm) {
      return (
        <SDSButton
          type={ButtonType.PRIMARY}
          size={ButtonSize.LARGE}
          onClick={this.handleMailingListSubmit}
          data-test="newPublisher.helpModal.submitButton"
        >
          <FormattedMessage id="help-modal-follow-btn-label" description="Add Button Label" defaultMessage="Add" />
        </SDSButton>
      );
    }
    if (this.state.isLastSlide && !this.props.options.modalConfig.hasMailingForm) {
      return (
        <SDSButton
          type={ButtonType.PRIMARY}
          size={ButtonSize.LARGE}
          onClick={this.closeModal}
          data-test="newPublisher.helpModal.publishReadyButton"
        >
          <FormattedMessage
            id="helpModal-ready-to-publish-label"
            description="Ready to publish button Label"
            defaultMessage="I am ready to publish!"
          />
        </SDSButton>
      );
    }
    return (
      <SDSButton
        type={ButtonType.PRIMARY}
        size={ButtonSize.LARGE}
        onClick={this.handleNextSlideClick}
        data-test="newPublisher.helpModal.nextButton"
      >
        <FormattedMessage id="helpModal-label" description="Next Button Label" defaultMessage="Next" />
      </SDSButton>
    );
  }

  renderDontShowModalCheckBox() {
    return (
      <div className={style.rowContainer}>
        <div onClick={this.handleShowHelpModalClick}>
          <SDSCheckbox
            onChange={this.handleShowHelpModalClick}
            checked={this.state.dontShowModal}
            data-test={`newPublisher.helpModal.checkBox-${this.state.dontShowModal}`}
          />
          {
            <FormattedMessage
              id="publisher-getting-started-dont-show-modal-label"
              defaultMessage="Do not show me this again"
              description="Checkbox text to select if user wants to opt in or out of seeing help modals"
            />
          }
        </div>
        <div className={style.controlsContainer}>{this.renderCarouselActionButton()}</div>
      </div>
    );
  }

  renderBitmojiAvatar(bitmojiTemplateId = BITMOJI_TEMPLATES.USER_LAPTOP, bitmojiAvatarId: string) {
    return (
      <div className={style.modalIcon} data-test={`PublisherHelpModal.modalIcon-${bitmojiTemplateId}`}>
        <Icon
          className={style.userAvatar}
          crossOrigin={CrossOrigin.ANONYMOUS}
          img={getBitmojiUrl(bitmojiTemplateId!, bitmojiAvatarId)}
        />
      </div>
    );
  }

  render() {
    return (
      <SDSCustomModal
        visible
        width={900}
        footer={null}
        onClose={this.closeModal}
        title={this.props.options.modalConfig.modalTitle}
      >
        <SDSCarousel beforeChange={this.handleSlideChange} ref={this.carouselRef} dots>
          {this.renderModalHelpContent()}
        </SDSCarousel>
        {this.renderDontShowModalCheckBox()}
      </SDSCustomModal>
    );
  }
}

export default intlConnect(mapStateToProps, null)(NewUserExperienceHelpModal);
