import { omit, pick, memoize, last } from 'lodash'; // eslint-disable-line object-curly-newline

import { createAssetUrl } from '../media/assetUtils';

import { INFINITE_ADS_ID } from 'config/adsConfig';
import { AudioClassification, ORIGINAL_KEYS } from 'config/constants';

import { PropertyKeys } from 'types/publisherSettings';
import type { EditablePublisherProperties, EditableTileLogo, TileLogoComponent } from 'types/publisherSettings';
import type { Publisher } from 'types/publishers';

type FormatPublisherProperties = {
  publisher: Publisher;
  tileLogoComponents: TileLogoComponent[] | EditableTileLogo[];
};

const tileLogoComponentsToEditableTileLogo = memoize((tileLogoComponents: TileLogoComponent[]): EditableTileLogo[] => {
  return tileLogoComponents.map((tileLogo: TileLogoComponent) => {
    return {
      componentId: tileLogo.componentId,
      id: tileLogo.logo ? tileLogo.logo.id : null,
      color: tileLogo.logo ? tileLogo.logo.color : '',
      mediaId: tileLogo.logo ? tileLogo.logo.mediaId : '',
    };
  });
});

export const isInfiniteAdsEnabled = (publisher: Publisher): boolean => {
  return last(publisher.defaultAdSlots) === INFINITE_ADS_ID;
};

export const isSubtitleBurnEnabled = (audioClassification: AudioClassification): boolean => {
  return audioClassification === AudioClassification.DIALOGUE_SUBTITLE_BURN;
};

export const formatPublisherProperties = memoize(({ publisher, tileLogoComponents }: FormatPublisherProperties) => {
  const values: string[] = Object.values(PropertyKeys) as any[];
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<Publisher>' is not assignable to typ... Remove this comment to see the full error message
  const properties: EditablePublisherProperties = pick(publisher, values);

  if (publisher.horizontalIconId) {
    properties.horizontalIconBlob = createAssetUrl(publisher.horizontalIconId);
  }
  if (publisher.squareHeroImageId) {
    properties.squareHeroImageBlob = createAssetUrl(publisher.squareHeroImageId);
  }
  if (publisher.squareHeroImageBitmojiTemplateId) {
    properties.squareHeroImageBitmojiTemplateId = publisher.squareHeroImageBitmojiTemplateId;
  }
  if (publisher.defaultFilledIconId) {
    properties.squareIconBlob = createAssetUrl(publisher.defaultFilledIconId);
  }
  if (publisher.tags) {
    properties.tags = omit(publisher.tags, ORIGINAL_KEYS);
  }
  if (tileLogoComponents) {
    const firstTileLogo = tileLogoComponents[0];
    if (firstTileLogo && 'logo' in firstTileLogo) {
      properties.tileLogoComponents = tileLogoComponentsToEditableTileLogo(tileLogoComponents);
    } else {
      properties.tileLogoComponents = tileLogoComponents as EditableTileLogo[];
    }
  }

  return properties;
});
