import type { ComponentType } from 'react';

import { RichSnapComponentType, RichSnapComponentTypeEnum } from 'config/constants';

import type { Snap } from 'types/snaps';
import type { Tile } from 'types/tiles';

export type GenericComponent<Props> = ComponentType<Props>;

export type RichSnapComponentId = string;

type RichSnapComponentBase = {
  componentId: RichSnapComponentId;
  componentType: RichSnapComponentTypeEnum;
};

export type RichSnapComponentTile = RichSnapComponentBase & {
  tile: Tile;
};

export type RichSnapComponentSnap = RichSnapComponentBase & {
  snap: Snap;
};

export type RichSnapComponentTilePlaceholder = RichSnapComponentBase & {};

export type RichSnapComponent = RichSnapComponentTile | RichSnapComponentSnap | RichSnapComponentTilePlaceholder;

// Helper functions for enforcing proper typing on rich snap components.
export function isComponentTile(component: RichSnapComponent): component is RichSnapComponentTile {
  return component.componentType === RichSnapComponentType.TILE;
}
export function isComponentTilePlaceholder(
  component: RichSnapComponent
): component is RichSnapComponentTilePlaceholder {
  return component.componentType === RichSnapComponentType.TILE_PLACEHOLDER;
}
export function isComponentSnap(component: RichSnapComponent): component is RichSnapComponentSnap {
  return component.componentType === RichSnapComponentType.SNAP;
}
