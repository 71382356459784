import { createSelector as s } from 'reselect';

import type { ArticleFields } from 'state/article/articleState';
import * as attachmentsSelectors from 'state/attachments/selectors/attachmentsSelectors';
import { createKeySelector } from 'state/common/selectorFactories';

import { ContentStatus } from 'config/constants';
import { AttachmentType, ServerAttachment } from 'src/types/attachments';

import type { State } from 'types/rootState';

export function getArticle(state: State) {
  return state.article || null;
}
export const getStatus = createKeySelector<ContentStatus | null>(getArticle, 'status', null);
export const getFields = createKeySelector<ArticleFields | null>(getArticle, 'fields', null);

const UNSAVED_STATUSES = new Set([ContentStatus.LOADED_DIRTY, ContentStatus.SAVING, ContentStatus.SAVE_ERROR]);
export const isUnsaved = s(getStatus, status => status && UNSAVED_STATUSES.has(status));

export const getAttachments = s(getFields, attachmentsSelectors.getAttachmentById, (fields, getAttachmentByIdFn) => {
  const attachmentIds = fields?.attachmentIds || [];
  return attachmentIds.reduce<
    Partial<
      {
        [key in AttachmentType]: ServerAttachment;
      }
    >
  >((acc, id) => {
    const attachment = getAttachmentByIdFn(id);
    if (attachment) {
      acc[attachment.type] = attachment;
    }
    return acc;
  }, {});
});
