import { get } from 'lodash';
import log from 'loglevel';

import { UriType, CrossOrigin } from 'config/constants';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import { assertArg, assertState } from 'utils/assertionUtils';
import { loadImage } from 'utils/media/ImageLoader';
import { createAssetUrl } from 'utils/media/assetUtils';
import { dataUriToBlob } from 'utils/media/blobUtils';
import { flatten } from 'utils/media/imageCompositor';
import { getUriType } from 'utils/uriUtils';

import { assertAssetId, isMediaID, toAssetID } from 'types/assets';

export function ensureAllImagesAreAssets({
  articleHTMLBuilder,
  existingImageAssetIds,
  entityOwner,
  loadAssetInfoFn,
  uploadFn,
}: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(articleHTMLBuilder).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(existingImageAssetIds).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityOwner).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(loadAssetInfoFn).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(uploadFn).is.function();

  const imageUrlsArray = articleHTMLBuilder.extractImageUrls();
  const imageUrlsMap = {};

  // Upload each image found in the article to the asset endpoint, and build a map
  // containing the newly uploaded URLs mapped against the original URLs.
  const promises = imageUrlsArray.map((oldUri: any) =>
    ensureImageIsAsset(oldUri, existingImageAssetIds, entityOwner, loadAssetInfoFn, uploadFn).then(newUri => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      imageUrlsMap[oldUri] = newUri;
    })
  );

  // Wait for all uploads to complete, and then overwrite the existing image URLs in the
  // article with the new URLs.
  return Promise.all(promises).then(() => {
    articleHTMLBuilder.replaceImageUrls(imageUrlsMap);
  });
}

export function ensureAllVideosAreTranscodedMediaIds({ articleHTMLBuilder, videoAssetIds, getFreshTranscode }: any) {
  const mediaIds = articleHTMLBuilder.extractVideoIds().filter(isMediaID);
  if (mediaIds.length === 0) {
    return Promise.resolve();
  }

  const mediaIdToTrancodedMediaIdMap = {};

  // get fresh transcode for each media id.
  const promises = mediaIds.map((mediaId: any) =>
    getFreshTranscode(mediaId).then((response: any) => {
      const assetId = response.payload.id;
      assertAssetId(assetId);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      mediaIdToTrancodedMediaIdMap[mediaId] = assetId;
      return assetId;
    })
  );

  // Wait for all fresh transcodes to complete, and then overwrite the existing mediaIds in the
  // article with the transcodedMediaIds.
  return Promise.all(promises).then(() => {
    articleHTMLBuilder.replaceMediaIds(mediaIdToTrancodedMediaIdMap);
  });
}

export function embedImageAspectRatios(articleHTMLBuilder: any) {
  return articleHTMLBuilder.embedImageAspectRatios(defaultImageLoad);
}

function ensureImageIsAsset(
  imageUri: any,
  existingImageAssetIds: any,
  entityOwner: any,
  loadAssetInfoFn: any,
  uploadFn: any
) {
  return new Promise((resolve, reject) => {
    if (isAssetUrl(imageUri)) {
      if (assetBelongsToThisSnap(imageUri, existingImageAssetIds)) {
        // If the asset already belongs to this snap then we can just bail here
        resolve(imageUri);
      } else {
        // If it doesn't belong to this snap, we copy it to this snap by downloading
        // it from the existing URL and then re-uploading it as a new asset. This
        // ensures that if the asset belongs to a different publisher then the entity
        // owner will be set correctly.
        copyAssetIfBelongsToAnotherEntityOwner(imageUri, entityOwner, loadAssetInfoFn, uploadFn)
          .then(resolve)
          .catch(reject);
      }
    } else {
      doUpload(imageUri, uploadFn).then(resolve).catch(reject);
    }
  });
}

function isAssetUrl(url: any) {
  return !!mediaLibraryAPI.asset.downloadMatcher(url);
}

function getAssetId(imageUri: any) {
  return toAssetID(mediaLibraryAPI.asset.downloadMatcher(imageUri).assetId);
}

function assetBelongsToThisSnap(imageUri: any, existingImageAssetIds: any) {
  const assetId = getAssetId(imageUri);
  assertAssetId(assetId);
  return existingImageAssetIds.indexOf(assetId) !== -1;
}

function buildAssetUrlFromUploadResponse(response: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(response).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(response.payload).is.object();
  assertAssetId(response.payload.id);

  return createAssetUrl(response.payload.id);
}

// TODO: move this logic to the server side.
//  It would be faster: the browser doesn't need to download and reupload.
//  It would be simpler: the JS logic would be replaced with a single server call.
//  It would be more flexible: for JS to access the blob it needs CORS,
//      and to restrict access to the asset the server requires auth credentials,
//      but the server can't redirect to a CDN (like Bolt's) with "access-control-allow-origin: *"
//      because browsers refuse to load an image with crossOrigin=use-credentials when the server allows all origins.
function copyAssetIfBelongsToAnotherEntityOwner(imageUri: any, entityOwner: any, loadAssetInfoFn: any, uploadFn: any) {
  const assetId = getAssetId(imageUri);

  return loadAssetInfoFn(assetId).then((result: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(result).is.object();
    const assetInfo = get(result, ['payload', 'entities', 'assets', assetId], null);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(assetInfo).is.object();

    if (entityOwnerMatches(assetInfo, entityOwner)) {
      return imageUri;
    }

    return defaultImageLoad(imageUri)
      .then((image: any) => convertImageToBlob(image, assetInfo))
      .then((dataUri: any) => doUpload(dataUri, uploadFn));
  });
}

function defaultImageLoad(imageUri: any) {
  return loadImage(imageUri, { crossOrigin: CrossOrigin.USE_CREDENTIALS });
}

function entityOwnerMatches(assetInfo: any, entityOwner: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(assetInfo.entityOwner).is.string();

  return assetInfo.entityOwner === entityOwner;
}

function convertImageToBlob(image: any, assetInfo: any) {
  if (!assetInfo.mimeType) {
    // Should never happen, but worth having a warning just in case
    log.warn(`Mime type was not known for asset ${assetInfo.id} - falling back to image/png`);
  }

  // Fall back to PNG if the mimeType isn't known
  const mimeType = assetInfo.mimeType || 'image/png';

  // Set quality to 1 so that the copying process isn't lossy
  const quality = 1;

  return flatten([image], { mimeType, quality });
}

function doUpload(imageUri: any, uploadFn: any) {
  const uriType = getUriType(imageUri);
  const blobOrUrl = uriType === UriType.DATA_URI ? dataUriToBlob(imageUri) : imageUri;

  return uploadFn(blobOrUrl).then(buildAssetUrlFromUploadResponse);
}
