import { isGapiThirdPartyCookieError } from 'state/auth/utils/preloadApi';

export const errorStrings = {
  EMPLOYEE_USE_GOOGLE: 'employee-use-google',
  INVALID_ACCOUNT: 'invalid-account-email-support',
  POPUP_CLOSED_BY_USER: 'popup-closed-by-user',
  ENABLE_THIRD_PARTY_COOKIES: 'enable-third-party-cookies',
  UNKNOWN: 'unknown',
  USE_SNAP_AUTH: 'use-snap-auth',
  EXPIRED_SESSION: 'expired-session',
};

export function errorCodeToMessage(error: string) {
  return {
    error: errorCodeToString(error),
  };
}

function errorCodeToString(error: string) {
  if (!error) {
    return errorStrings.UNKNOWN;
  }

  if (error === 'AUTH_REJECTED') {
    return errorStrings.INVALID_ACCOUNT;
  }

  if (error === 'popup_closed_by_user') {
    return errorStrings.POPUP_CLOSED_BY_USER;
  }

  if (isGapiThirdPartyCookieError(error)) {
    return errorStrings.ENABLE_THIRD_PARTY_COOKIES;
  }

  if (error === 'USER_MUST_USE_SNAP_AUTH') {
    return errorStrings.USE_SNAP_AUTH;
  }

  if (error === 'EMPLOYEES_MUST_LOGIN_VIA_GOOGLE') {
    return errorStrings.EMPLOYEE_USE_GOOGLE;
  }

  if (error === 'EXPIRED_SESSION') {
    return errorStrings.EXPIRED_SESSION;
  }

  return errorStrings.UNKNOWN;
}

export function isExpiredSessionError(path: string) {
  return path.includes(`error=${errorStrings.EXPIRED_SESSION}`);
}
