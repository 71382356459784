import is from 'is_js';

import { assertArg } from 'utils/assertionUtils';
import { makeDelayPromise } from 'utils/promiseUtils';

import type { Dispatch, GetState, ReduxAction } from 'types/redux';

/**
 * Allows for standardized creation of actions that set an active entity id. For example, if you have a reducer
 * that maintains a list of Edition entities and you want to select one of them, usual practice is to maintain
 * an activeEditionId property in the reducer. Setting this id will usually be accomplished by a boiler-platey
 * action creator called `setActiveEditionId` or similar. Using this factory, you can create one of these action
 * creators with a one liner:
 *
 *     const setActiveEdition = createActivatingAction(SET_ACTIVE_EDITION, 'edition', getEditionById);
 *
 * Note that the getEditionById argument is expected to be a selector that returns a matching entity from the
 * store when supplied an id. The action creator will validate that this entity exists, and throw an error if
 * it doesn't exist.
 */
export const createActivatingAction = (
  actionType: string,
  entityName: string,
  entitySelector: any,
  validateEntity: boolean = true
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(actionType).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityName).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entitySelector).is.function();

  return (entityId: any) => (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    if (!entityId) {
      throw new Error(`The entityId argument is required when creating action ${actionType}`);
    }

    const entity = entitySelector(getState())(entityId);

    if (!entity && validateEntity) {
      throw new Error(`Could not find ${entityName} matching id ${entityId} when creating action ${actionType}`);
    }

    dispatch({
      type: actionType,
      payload: {
        [`${entityName}Id`]: entityId,
        [entityName]: entity,
      },
    });
    return Promise.resolve();
  };
};

export const createClearingAction = (actionType: any, entityName: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(actionType).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityName).is.string();

  return (): ReduxAction => ({
    type: actionType,

    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: any; payload: { [x: string]: null; }... Remove this comment to see the full error message
    payload: {
      [`${entityName}Id`]: null,
    },
  });
};

/*
  Creates an action from an existing action method

  It must return a promise after going though the store chain.

  maxRetries determines the number of total calls that can be made to the action.

  timeout is either a number or function, which determines the delay in milliseconds
    if it is a function is it passed the number of retries done so far.

  errorsMatching is a function that determines whether to perform a retry.
    It is passed the error as an argument.
    If it returns true, a retry will be done.
    If it returns false, the action will be rejected and no retry will be performed.
*/

export const createRetryAction = (actionMethod: any, { maxRetries, timeout, errorsMatching }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(actionMethod).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(maxRetries).is.number.and.is.above(1);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(timeout).is.number.or.is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(errorsMatching).is.function();

  let retryCount = 0;

  function dispatchWithRetries(...args: any[]) {
    return (dispatch: any, getState: GetState) =>
      Promise.resolve()
        .then(() => dispatch(actionMethod(...args)))
        .catch(err => {
          retryCount += 1;
          if (retryCount > maxRetries || !errorsMatching(err)) {
            return Promise.reject(err);
          }

          return makeDelayPromise(getTimeout(timeout, retryCount)).then(() => dispatch(dispatchWithRetries(...args)));
        });
  }

  return (...args: any) => dispatchWithRetries(...args);
};

function getTimeout(timeout: any, retryCount: any) {
  if (is.number(timeout)) {
    return timeout;
  }
  return timeout(retryCount);
}

/*
  Sets up a repeating action that will cancel on receiving an error, or if
  cancelIf returns true.
*/
export const loopAction = (actionMethod: any, { cancelIf, onError, delay }: any): any => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(actionMethod).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(onError).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(cancelIf).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(delay).is.number();

  function loopedActionMethod(dispatch: any, getState: GetState) {
    if (cancelIf()) {
      return Promise.resolve();
    }

    // Don't repeat action on error
    return Promise.resolve(dispatch(actionMethod())).then((): any => {
      setTimeout(() => dispatch(loopedActionMethod), delay);
    }, onError);
  }

  return loopedActionMethod;
};
