import Modal from 'antd/lib/modal';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import style from './SDSCustomModal.scss';

// Modals from snapnet have z-index 1050 and from antd 1000,
// so in order to avoid occluding of SDSModal, the higher value is used.
const Z_INDEX = 1050;

type Props = {
  visible: boolean;
  title?: JSX.Element;
  footer: JSX.Element | null;
  width?: number;
  children: ReactNode;
  'data-test'?: string;
  isBodyCentered?: boolean;
  onClose: () => void;
};

function SDSCustomModal(props: Props) {
  return (
    <Modal
      className={classNames(style.modal, { [style.centerBody]: props.isBodyCentered })}
      visible={props.visible}
      title={props.title}
      footer={props.footer}
      width={props.width}
      closable
      onCancel={props.onClose}
      zIndex={Z_INDEX}
    >
      {props.children}
    </Modal>
  );
}

export default SDSCustomModal;
