export type LangParsedData = {
  [id: string]: string;
};

export interface LangData {
  [id: string]:
    | {
        defaultMessage?: string;
        description?: string;
      }
    | string;
}

export const parseLangFile = (data: LangData): LangParsedData => {
  return Object.keys(data).reduce((acc, key) => {
    const langData = data[key];
    if (typeof langData === 'object' && langData.defaultMessage) {
      acc[key] = langData.defaultMessage;
    }
    return acc;
  }, {} as LangParsedData);
};
