import invariant from 'invariant';
import { get, indexOf } from 'lodash';
import { createSelector as s } from 'reselect';

import * as articleSelectors from 'state/article/selectors/articleSelectors';
import { createKeySelector, createDynamicKeySelector, reselectById } from 'state/common/selectorFactories';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import editorStateSelectors from 'state/editor/selectors/editorStateSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getSegmentReduxId } from 'state/segments/schema/segmentEntityHelpers';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as stagesSelectors from 'state/stages/selectors/stagesSelectors';
import * as transactionsSelectors from 'state/transactions/selectors/transactionsSelectors';

import { AgeGate, ComponentScope, EMPTY_OBJECT } from 'config/constants';
import type { AgeGateEnum } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import * as componentUtils from 'utils/componentUtils';
import { functionRef } from 'utils/functionUtils';
import * as sentryServerLogging from 'utils/logging/sentryServerLoggingUtil';

import { assertSnapId } from 'types/common';
import type { SnapId } from 'types/common';
import type { Edition, EditionID } from 'types/editions';
import type { State } from 'types/rootState';
import { ConfigTab } from 'types/singleAssetStoryEditor';
import type { Snap } from 'types/snaps';
import type { PendingTile, TileEditorState, TileID } from 'types/tiles';

export const getEditor = (state: State) => {
  return state.editor || {};
};
export const getStatusMessage = createKeySelector(getEditor, 'statusMessage', {});
export const getEditorConfig = createKeySelector(getEditor, 'editorConfig', {});
export const getPendingTileMap = createKeySelector(getEditorConfig, 'pendingTiles', {});

export const getPendingTileById = createDynamicKeySelector<PendingTile | null, TileID>(getPendingTileMap, null);
export const getActiveWholeSnapId = createKeySelector<SnapId | null>(getEditor, 'activeTopsnapId', null);
export const getActiveEditionId = createKeySelector(getEditor, 'activeEditionId', 0);
export const getUnreadAttachmentId = createKeySelector(getEditor, 'unreadAttachmentId', null);

export const getActiveTopsnap: (a: State) => Snap | null = s(
  getActiveWholeSnapId,
  snapsSelectors.getSnapById,
  (activeTopsnapId, getSnapByIdFn) => {
    if (activeTopsnapId) {
      return getSnapByIdFn(activeTopsnapId);
    }
    return null;
  }
);
export const getActiveBottomsnap = s(getActiveTopsnap, activeTopsnap => {
  return activeTopsnap?.relatedSnaps?.BOTTOM || null;
});
export const getActiveEdition: (a: State) => Edition | undefined | null = s(
  getActiveEditionId,
  editionsSelectors.getEditionById,
  (activeEditionId, getEditionByIdFn): Edition | undefined | null => {
    if (activeEditionId) {
      return getEditionByIdFn(activeEditionId);
    }
    return null;
  }
);
export const getActiveSnapIndex = s(getActiveEdition, getActiveWholeSnapId, (activeEdition, activeSnapId) => {
  return indexOf(activeEdition ? activeEdition.snapIds : [], activeSnapId);
});
export const getPreviousTopsnapIdWithContent: (a: State) => SnapId | undefined | null = s(
  getActiveEdition,
  getActiveSnapIndex,
  functionRef(snapsSelectors, 'getSnapById'),
  (activeEdition, activeSnapIndex, getSnapByIdFn) => {
    // @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
    const getPreviousTopsnapIdImpl = (snapIndex: any) => {
      if (!activeEdition || snapIndex === 0) {
        return null;
      }
      const previousTopsnapId = activeEdition.snapIds[snapIndex - 1];
      const snap = (getSnapByIdFn as any)(previousTopsnapId);
      if (get(snap, 'isContentDeleted')) {
        return getPreviousTopsnapIdImpl(snapIndex - 1);
      }
      return previousTopsnapId;
    };
    return getPreviousTopsnapIdImpl(activeSnapIndex);
  }
);
export const getNextTopsnapIdWithContent: (a: State) => SnapId | undefined | null = s(
  getActiveEdition,
  getActiveSnapIndex,
  functionRef(snapsSelectors, 'getSnapById'),
  (activeEdition, activeSnapIndex, getSnapByIdFn) => {
    // @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
    const getNextTopsnapIdImpl = (snapIndex: any) => {
      if (!activeEdition || activeSnapIndex >= activeEdition.snapIds.length - 1) {
        return null;
      }
      const previousTopsnapId = activeEdition.snapIds[snapIndex + 1];
      const snap = (getSnapByIdFn as any)(previousTopsnapId);
      if (get(snap, 'isContentDeleted')) {
        return getNextTopsnapIdImpl(snapIndex + 1);
      }
      return previousTopsnapId;
    };
    return getNextTopsnapIdImpl(activeSnapIndex);
  }
);
export const getActiveSegment = s(getActiveWholeSnapId, getActiveEdition, (activeSnapId, activeEdition) => {
  if (activeSnapId && activeEdition) {
    return editionEntityHelpers.findSegmentForSnap(activeEdition, activeSnapId);
  }
  return null;
});
// Returns the redux id, as that is the index we use to get the segment from redux store
export const getActiveSegmentReduxId = s(getActiveSegment, activeSegment => {
  return activeSegment ? getSegmentReduxId(activeSegment) : null;
});
export const getSharedToolbarId = createKeySelector(getEditorConfig, 'sharedToolbarId', null);
export const getHoverCropPosition = createKeySelector(getEditorConfig, 'hoverCropPosition', null);
export const articleContentFieldIsFocused = createKeySelector(getEditorConfig, 'articleContentFieldIsFocused', false);
export const isShowingNewInteractionPlaceholder = createKeySelector(
  getEditorConfig,
  'showNewInteractionPlaceholder',
  false
);
// Used for a single snap in a whole snap
export const snapHasTransactionOrIsUploading = s(
  functionRef(transactionsSelectors, 'hasOngoingTransactionsBySnapId'),
  functionRef(mediaSelectors, 'isUploadingByComponentId'),
  (hasTransactionsBySnapId, isUploadingByComponentId) => ({ snapId }: { snapId: SnapId }) => {
    assertSnapId(snapId);
    invariant(snapId, 'snapId');
    if ((hasTransactionsBySnapId as any)(snapId)) {
      return true;
    }
    const componentId = componentUtils.buildComponentIdForSnapId(snapId);
    return (isUploadingByComponentId as any)(componentId);
  }
);
// Currently we are locking the UI whenever there's a transaction or an uploading job in progress
// To be used for the whole snap. The function will check all connected snaps
export const wholeSnapHasTransactionOrUploading = s(
  snapHasTransactionOrIsUploading,
  functionRef(snapsSelectors, 'getSnapById'),
  (snapHasTransactionOrIsUploadingFn, getSnapByIdFn) => (snapId: any) => {
    // Snap may be deleted by the time the function is called
    const snap = (getSnapByIdFn as any)(snapId);
    if (!snap) {
      return false;
    }
    // We need to check if none of the subSnaps has any uploads in progress
    const snapIds = snapEntityHelpers.getAllSnapIdsForSnap(snap);
    return snapIds.some(id => snapHasTransactionOrIsUploadingFn({ snapId: id }));
  }
);
// if any snaps in the story are uploading media or have an action in progress returns true
// if there is no edition or no snaps in the edition, default to false
export const areSnapsUploadingOrLockedByATransaction = s(
  editionsSelectors.getEditionById,
  wholeSnapHasTransactionOrUploading,
  (getEditionByIdFn, wholeSnapHasTransactionOrUploadingFn) => (editionId: any) => {
    const story = getEditionByIdFn(editionId);
    const snapIds = story?.snapIds;
    return snapIds?.length ? snapIds.map(snapId => wholeSnapHasTransactionOrUploadingFn(snapId)).includes(true) : false;
  }
);
export const wholeSnapUploadProgressSummary = reselectById<number, SnapId>(
  1,
  (state: any, snapId: any) => snapsSelectors.getSnapById(state)(snapId),
  functionRef(mediaSelectors, 'getUploadProgressSummaryByComponentId'),
  (snap: any, uploadProgress: any) => {
    if (!snap) {
      return 1; // 100%
    }
    const progress = snapEntityHelpers
      .getAllSnapIdsForSnap(snap)
      .map(componentUtils.buildComponentIdForSnapId)
      .map(componentId => uploadProgress?.[componentId])
      .filter(componentProgress => componentProgress)
      .reduce(
        (componentProgress, initial) => {
          return {
            totalSizeBytes: componentProgress.totalSizeBytes + initial.totalSizeBytes,
            progressBytes: componentProgress.progressBytes + initial.progressBytes,
          };
        },
        { totalSizeBytes: 0, progressBytes: 0 }
      );
    return progress.totalSizeBytes === 0 ? 1 : progress.progressBytes / progress.totalSizeBytes;
  }
);
export const isWholeSnapOrEditionLocked = s(
  wholeSnapHasTransactionOrUploading,
  functionRef(editionsSelectors, 'editionIsReadOnly'),
  (wholeSnapHasTransactionOrUploadingFn, editionIsReadOnlyFn) => ({ snapId, editionId }: any) => {
    return wholeSnapHasTransactionOrUploadingFn(snapId) || (editionIsReadOnlyFn as any)(editionId);
  }
);
export const isReadOnly = s(
  getActiveEditionId,
  functionRef(editionsSelectors, 'editionIsReadOnly'),
  (activeEditionId, editionIsReadOnlyFn) => {
    return (editionIsReadOnlyFn as any)(activeEditionId);
  }
);
export const isLocked = s(
  isReadOnly,
  snapHasTransactionOrIsUploading,
  (readOnly, actionInProgressForSnapId) => ({ snapId }: { snapId: SnapId }) => {
    assertSnapId(snapId);
    return Boolean(readOnly || actionInProgressForSnapId({ snapId }));
  }
);
// Tile may belong to the edition or the snap. At this point it is unsafe to believe one or the other
export const isTileLockedForEdit = s(
  isLocked,
  functionRef(editionsSelectors, 'getEditionSavingById'),
  (isLockedFn, getEditionSavingByIdFn) => ({ snapId, editionId }: { snapId: SnapId; editionId: EditionID }) => {
    assertSnapId(snapId);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(editionId).is.number();
    return Boolean(isLockedFn({ snapId }) || (getEditionSavingByIdFn as any)(editionId));
  }
);

export const getTopsnapDurationLimit = s(
  functionRef(publishersSelectors, 'getActivePublisherDetails'),
  activePublisherDetails => {
    return activePublisherDetails && (activePublisherDetails as any).topsnapLimit;
  }
);
export const getAgeGate = s(
  getActiveEdition,
  (activeEdition?: Edition | null): AgeGateEnum => {
    if (activeEdition) {
      return activeEdition.ageGate;
    }
    return AgeGate.UNRESTRICTED;
  }
);
export const hasDirtyBottomStages = s(
  getActiveBottomsnap,
  functionRef(stagesSelectors, 'isDirty'),
  (bottomsnap, isDirtyFn) => {
    return Boolean(bottomsnap && (isDirtyFn as any)(bottomsnap.id));
  }
);
export const getBottomSnapHasPendingChanges = s(
  functionRef(articleSelectors, 'isUnsaved'),
  hasDirtyBottomStages,
  (isArticleUnsaved, hasDirtyStage) => {
    return isArticleUnsaved || hasDirtyStage;
  }
);
export const isSaving = s(
  getActiveTopsnap,
  getActiveBottomsnap,
  functionRef(snapsSelectors, 'isSavingById'),
  (topsnap, bottomsnap, isSavingByIdFn) => {
    return (topsnap && (isSavingByIdFn as any)(topsnap.id)) || (bottomsnap && (isSavingByIdFn as any)(bottomsnap.id));
  }
);

// isValidCameraAttachment currently requires an attachment with a single lens
export const isValidCameraAttachment = s(getActiveBottomsnap, bottomsnap => {
  return get(bottomsnap, ['lenses', 'length']) === 1 && get(bottomsnap, ['lenses', '0', 'lensScancodeId']);
});
export const getEditorState = editorStateSelectors(getEditor, 'editorState');
export const getSnapEditorState = s(getEditorState, getEditorStateFn => {
  return (snapId: any) => {
    return getEditorStateFn(ComponentScope.SNAP, snapId);
  };
});
export const getTileEditorState = s(getEditorState, getEditorStateFn => {
  return (tileId: any) => {
    return getEditorStateFn(ComponentScope.TILE, tileId) || EMPTY_OBJECT;
  };
});
export const getSelectedTileLanguage = reselectById<string | undefined | null, TileID>(
  null,
  (state: any, tileId: any) => getTileEditorState(state)(tileId),
  (tileEditorState: TileEditorState, primaryLanguage: any) => (tileEditorState && tileEditorState.language) || null
);
export const getShowEditorState = s(getEditorState, getEditorStateFn => {
  return (showId: any) => {
    return getEditorStateFn(ComponentScope.SHOW, showId) || null;
  };
});
export const getSingleAssetEditorState = s(getEditorState, getEditorStateFn => {
  return (storyId: EditionID) => {
    return getEditorStateFn(ComponentScope.SINGLE_ASSET_STORY_EDITOR, storyId) || EMPTY_OBJECT;
  };
});
export const getSingleAssetEditorActiveTab = s(getSingleAssetEditorState, getSingleAssetEditorFn => {
  return (storyId: any, defaultTab: ConfigTab) => {
    return get(getSingleAssetEditorFn(storyId), 'activeConfigTab', defaultTab);
  };
});
sentryServerLogging.setProvider('activeTopsnapId', getActiveWholeSnapId);
sentryServerLogging.setProvider('activeEditionId', getActiveEditionId);
