import _ from 'lodash';
import moment from 'moment-timezone';
import * as React from 'react';
import { IntlProvider, MessageValue } from 'react-intl';

import { assertState } from 'utils/assertionUtils';
import { fetchLocaleJson, locales } from 'utils/i18nLibrary';

import intlMessageConfig from './intlMessageConfig';

// The redux state cannot store complex elements like react elements, so intlMessages offers a way to store
// messages that must adhere to i18n in a separate, non-redux store. These can be referenced by id, which *can* be
// stored in redux if needed.
const intlErrorMap = {};

export function getRangeMessage(from: moment.Moment, to: moment.Moment, format: string) {
  const formattedToday = moment().format(format);
  const formattedYesterday = moment().subtract(1, 'day').format(format);
  const formattedFrom = from.format(format);
  const formattedTo = to.format(format);

  // we're only dealing with one day
  if (formattedFrom === formattedTo) {
    // we're checking today
    if (formattedFrom === formattedToday) {
      return getMessageFromId('time-range-today', { today: formattedToday });
    }

    // we're checking yesterday
    if (formattedFrom === formattedYesterday) {
      return getMessageFromId('time-range-yesterday', { yesterday: formattedYesterday });
    }

    // just return the single day
    return formattedFrom;
  }

  // return the range
  return `${formattedFrom} - ${formattedTo}`;
}

export function registerIntlMessage({ intlMessage, params }: any) {
  const { id } = intlMessage.props;
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(id).is.not.inArray(Object.keys(intlErrorMap));
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(params).is.array();

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  params.forEach((param: any) => assertState(param).is.string.or.is.number());

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(intlMessage).is.object();

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  intlErrorMap[id] = {
    params,
    intlMessage,
  };

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return intlErrorMap[id];
}

export function hasIntlMessage(intlMessageId: any) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const intlDetails = intlErrorMap[intlMessageId];
  return Boolean(intlDetails);
}

export type IntlMessage = {
  intlMessageId: string;
  values?: {} | null;
};

export function makeIntlMessage(intlMessageId: string, values?: {} | null): IntlMessage {
  return {
    intlMessageId,
    values,
  };
}

export function getMessageFromIntlMessage(err: any) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const intlDetails = intlErrorMap[err.intlMessageId];
  if (!intlDetails) {
    return null;
  }

  const message = intlDetails.intlMessage;
  const { values } = err;

  return !values ? message : React.cloneElement(message, { values });
}

export function getMessageFromId(intlMessageId: string, values?: {} | null): any {
  const intlMessage = makeIntlMessage(intlMessageId, values);
  return getMessageFromIntlMessage(intlMessage);
}

// Used when we need the actual translated message rather than the react object
export function getLocalisedMessageFromId(context: any, intlMessageId: string, values?: any) {
  const msgObject = getMessageFromId(intlMessageId, values);
  if (!msgObject) {
    return null;
  }

  return context.intl.formatMessage(msgObject.props, values);
}

export async function getMessageBodyFromId(intlMessageId: string, values?: {}, localeId?: string) {
  const message = getMessageFromId(intlMessageId, values);
  return getMessageBody(message, values, localeId);
}

const getIntlContextForLocaleId = _.memoize(async (localeId = 'en') => {
  const locale = locales.has(localeId) ? localeId : 'en';
  const localeJson = await fetchLocaleJson(locale.toLowerCase());
  const intlProvider = new IntlProvider({ locale, messages: localeJson }, {});
  const { intl } = intlProvider.getChildContext();
  return intl;
});

export async function getLocalisedMessageFromIdAndLocaleId(localeId: string, intlMessageId: string, values: {}) {
  const msgObject = getMessageFromId(intlMessageId, values);
  if (!msgObject) {
    return null;
  }

  const intl = await getIntlContextForLocaleId(localeId);
  return intl.formatMessage(msgObject.props, values);
}

export async function getMessageBody(
  intlMessage: React.ReactElement,
  values?: { [key: string]: MessageValue },
  localeId?: string
): Promise<string> {
  const intl = await getIntlContextForLocaleId(localeId);

  const { id, defaultMessage, description } = intlMessage.props || intlMessage;
  return intl.formatMessage({ id, defaultMessage, description }, values);
}

export function getMessageBodyFromIntlMessage(
  intlMessage: React.ReactElement,
  values?: { [key: string]: MessageValue }
) {
  const { id } = intlMessage.props || intlMessage;
  return getMessageFromIntlMessage(makeIntlMessage(id, values));
}

export function deregister(key: any) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  delete intlErrorMap[key];
}

intlMessageConfig(registerIntlMessage);

export default makeIntlMessage;
