import { ApolloError, DocumentNode } from '@apollo/client';
import log from 'loglevel';

import { setPublisherDetailsWithGraphQLResponse } from 'state/publishers/actions/publishersActions';
import { loadShows } from 'state/shows/actions/showsActions';
import { setActivePublisher } from 'state/user/actions/userActions';
import { getActivePublisherId, hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { fetchPublisherDataWithApolloQuery } from '../apis/graphQLUtils';
import { reportTimerWithDuration } from '../grapheneUtils';

import { ADMIN_GET_PUBLISHER } from 'gql/queries/publisher/adminPublisherQuery';
import { ANALYTICS_GET_PUBLISHER } from 'gql/queries/publisher/analyticsPublisherQuery';
import { EDITIONS_GET_PUBLISHER } from 'gql/queries/publisher/editionsPublisherQuery';
import { HOME_GET_PUBLISHER } from 'gql/queries/publisher/homePublisherQuery';
import { MEDIA_GET_PUBLISHER } from 'gql/queries/publisher/mediaPublisherQuery';
import { ROOT_GET_PUBLISHER } from 'gql/queries/publisher/rootPublisherQuery';
import { SETTINGS_GET_PUBLISHER } from 'gql/queries/publisher/settingsPublisherQuery';
import { PublisherQueryType } from 'gql/types/publisherQueryTypeEnum';
import { getPerformanceTiming } from 'utils/performance/performanceUtils';
import { getPublisherIdOrActiveCreatorPublisherId } from 'utils/routerUtils';

import { Claim } from 'types/permissions';
import { TierLevel } from 'types/publishers';

// Ensures we create new query and add here if we add any new enum to PublisherQueryType
const PUBLISHER_QUERY_CONFIG: { [key in PublisherQueryType]: DocumentNode } = {
  [PublisherQueryType.Admin]: ADMIN_GET_PUBLISHER,
  [PublisherQueryType.Home]: HOME_GET_PUBLISHER,
  [PublisherQueryType.Analytics]: ANALYTICS_GET_PUBLISHER,
  [PublisherQueryType.Media]: MEDIA_GET_PUBLISHER,
  [PublisherQueryType.Settings]: SETTINGS_GET_PUBLISHER,
  [PublisherQueryType.Root]: ROOT_GET_PUBLISHER,
  [PublisherQueryType.Editions]: EDITIONS_GET_PUBLISHER,
};

// Depending on the enum we pass we can fetch different publisher fields, if none is given we fetch the 'root' fields.
const requirePublisherInfo = (store: any, pubQueryEnum?: PublisherQueryType) => async ({ match }: any) => {
  const publisherId = getPublisherIdOrActiveCreatorPublisherId({ match }, store);

  const currentActivePublisherId = getActivePublisherId(store.getState());
  // Only set active publisher if:
  // 1. The current one is null for first time loading.
  // 2. Publisher id do not match the activePublisherId, which may cause by user click backward/forward button on the
  // browser.
  // Be very careful to call setActivePublisher, which may trigger a redirect request in
  // SnapSideNav, then might let us end up in an infinite loop.
  // See https://jira.sc-corp.net/browse/PUB-13721 for more info.
  if (currentActivePublisherId == null || (publisherId && publisherId !== currentActivePublisherId)) {
    await store.dispatch(setActivePublisher(publisherId));
  }

  const query = PUBLISHER_QUERY_CONFIG[pubQueryEnum ?? PublisherQueryType.Root];
  const isUserSuperAdmin = hasClaimForActivePublisher(store.getState(), Claim.SUPER_ADMIN_VIEWER);

  const performanceStart = getPerformanceTiming();

  const response = await fetchPublisherDataWithApolloQuery(query, publisherId!.toString(), isUserSuperAdmin);

  if (response !== null && !(response instanceof ApolloError)) {
    // Update redux store with response
    store.dispatch(setPublisherDetailsWithGraphQLResponse(response));
    const performanceEnd = getPerformanceTiming();
    reportTimerWithDuration({
      metricsName: 'PublisherInfoAndFeatureLoadTime',
      milliSec: performanceEnd - performanceStart,
    });
    // We need to require the shows data cause it will be necessary to load the settings page
    // Note the promise is not returned so this call is done asynchronously
    if ('tier' in response && response.tier === TierLevel.SHOW) {
      store.dispatch(loadShows(publisherId!, response.businessProfileId));
    }

    return null;
  }

  log.error('Error setting publisher for PublisherId =', publisherId, ': Null response or Apollo Error');
  return null;
};

export default requirePublisherInfo;
