import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { fetchEditionBuildStatus } from 'state/buildStatus/actions/buildStatusActions';
import { reloadSnaps } from 'state/editions/actions/editionsActions';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { loadSnap } from 'state/snaps/actions/snapsActions';
import { loadSubtitlesForSnap } from 'state/subtitles/actions/subtitlesActions';

import * as proxyAPI from 'utils/apis/proxyAPI';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { GetState } from 'types/redux';

export const GENERATE_SUBTITLES = 'content-recognition/GENERATE_SUBTITLES';
const generateSubtitlesForEditionOrSnap = (
  editionId: EditionID,
  language: string,
  snapId?: SnapId | null
): ((b: Dispatch, a: GetState) => Promise<unknown>) => {
  return (dispatch: Dispatch) => {
    const snapParams = snapId ? { snapId } : {};
    return (dispatch(
      createCallAction(
        {
          type: GENERATE_SUBTITLES,
          meta: {
            schema: editionSchema,
          },
        },
        {
          endpoint: proxyAPI.subtitles.generateSubtitles({
            editionId,
            language,
            ...snapParams,
          }),
          method: 'POST',
        }
      )
    ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.GENERATE_SUBTITLES));
  };
};
export function generateSubtitlesForEdition(editionId: EditionID, language: string): (a: Dispatch) => Promise<unknown> {
  return (dispatch: Dispatch) => {
    return (
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(b: Dispatch<AnyAction>, a: GetS... Remove this comment to see the full error message
      dispatch(generateSubtitlesForEditionOrSnap(editionId, language))
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        .then(() => dispatch(reloadSnaps(editionId)))
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        .then(() => dispatch(fetchEditionBuildStatus({ editionId })))
    );
  };
}
export function generateSubsForSnapAndReload(
  editionId: EditionID,
  language: string,
  snapId: SnapId
): (a: Dispatch) => Promise<unknown> {
  return (dispatch: Dispatch) => {
    return (
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(b: Dispatch<AnyAction>, a: GetS... Remove this comment to see the full error message
      dispatch(generateSubtitlesForEditionOrSnap(editionId, language, snapId))
        .then(() =>
          Promise.all([
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
            dispatch(loadSnap({ snapId })),
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
            dispatch(fetchEditionBuildStatus({ editionId })),
          ])
        )
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        .then(() => dispatch(loadSubtitlesForSnap(snapId)))
    );
  };
}

export const generateSubtitlesForActivePublisher = (editionId: EditionID) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const defaultSubtitlesLanguage = publishersSelectors.getActivePublisherDefaultSubtitlesLanguage(getState());
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(a: Dispatch<AnyAction>) => Prom... Remove this comment to see the full error message
  return dispatch(generateSubtitlesForEdition(editionId, defaultSubtitlesLanguage));
};
