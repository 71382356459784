import is from 'is_js';
import { some } from 'lodash';
import log from 'loglevel';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import { ZENDESK_BASE_URL } from 'config/constants';
import { creator, creatorAnalytics, snapAdmin } from 'utils/apis/localRoutes';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { constructURL } from 'utils/linkUtils';
import * as locationUtils from 'utils/locationUtils';
import { ModalType } from 'utils/modalConfig';
import { isScreenshotSupportAvailable } from 'utils/platform/platformUtils';
import { isRouteActive } from 'utils/router/routerUtils';
import { isRevenueDashboardEnabled } from 'utils/storytellerMonetizationUtils';

type IsRouteActiveResult = {
  active: boolean;
  clickable: boolean;
};

type Item = {
  path: string;
  title: React.ReactElement<typeof FormattedMessage> | string;
  accessible: boolean;
  isRouteActive?: (a: string) => IsRouteActiveResult;
  clickHandler?: (a: string) => void;
};

type Group = {
  key: string;
  title: React.ReactElement<typeof FormattedMessage>;
  items: Item[];
};

const defaultClickHandler = ({ push }: any) => (path: string) => {
  // TODO @yyan location change should change to a redux action.
  // https://jira.sc-corp.net/browse/PUB-13948
  push(path);
};

const newWindowClickHandler = () => (path: string) => {
  locationUtils.openInNewWindow(path);
};

const defaultIsRouteActive = (path: string, pathname: any, routeParams: any): IsRouteActiveResult => {
  const pathInstance = constructURL(path, { params: routeParams, failOnMissingParam: false });
  const active = isRouteActive(pathInstance, pathname);
  return {
    active,
    clickable: !active,
  };
};

const noopIsRouteActive = (path: string): IsRouteActiveResult => {
  return {
    active: false,
    clickable: true,
  };
};

const getSidebarGroups = (props: any): Group[] => {
  return [
    {
      key: 'content',
      title: getMessageFromId('content-sidebar-indicator'),
      items: [
        {
          path: creator.home.path,
          title: getMessageFromId('sidebar-content-homepage'),
          accessible: props.isStoryViewer,
        },
        {
          title: getMessageFromId('media-library-title'),
          path: creator.mediaLibrary.path,
          accessible: props.isStoryViewer && !props.isSingleAssetStoryEditorEnabled && props.isActiveCreatorPublisher,
        },
        {
          path: creatorAnalytics.analytics.path,
          title: getMessageFromId('analytics'),
          accessible: props.isAnalyticsViewer && props.isActiveCreatorPublisher,
        },
        {
          path: creator.creativeTemplates.path,
          title: getMessageFromId('sidenav-template-option'),
          accessible: props.isActiveCreatorPublisher,
        },
      ],
    },
    {
      key: 'snap-support',
      title: getMessageFromId('snap-support-sidebar-indicator'),
      items: [
        {
          title: 'User Management',
          path: creator.admin.path,
          accessible: props.isPublisherUserManager && props.isActiveCreatorPublisher,
        },
        {
          path: creator.settings.path,
          title: getMessageFromId('settings-help'),
          accessible: props.isActiveCreatorPublisher,
        },
        {
          path: creator.contact.path,
          title: getMessageFromId('contact-title-sidebar'),
          accessible: true,
          clickHandler: path => {
            if (isScreenshotSupportAvailable()) {
              props.showModal(ModalType.TAKE_SCREENSHOT_MODAL, 'sidebar', {});
            } else {
              props.push(path);
            }
          },
        },
        {
          path: `${ZENDESK_BASE_URL}/hc/en-us`,
          title: getMessageFromId('help-center-sidebar'),
          accessible: true,
          clickHandler: newWindowClickHandler(),
          isRouteActive: noopIsRouteActive,
        },
      ],
    },
    {
      key: 'snap-internal',
      title: getMessageFromId('snap-internal-sidebar-indicator'),
      items: [
        {
          path: snapAdmin.notification.path,
          title: getMessageFromId('title-notification-message'),
          accessible: props.isNotificationBannerEditor,
        },
        {
          path: snapAdmin.users.path,
          title: getMessageFromId('title-admin-user-management'),

          accessible: props.isAdminUserManager,
        },
        {
          path: snapAdmin.features.path,
          title: getMessageFromId('feature-flags'),
          accessible: props.isNotificationBannerEditor,
        },
        {
          path: snapAdmin.onboarding.path,
          title: getMessageFromId('org-onboarding'),
          accessible: props.isSuperOnboardingManager,
        },
      ],
    },
  ];
};

export const getSidebarConfig = ({ props, params }: any): any =>
  getSidebarGroups({ ...props })
    .map(sidebarConfig => ({
      key: sidebarConfig.key,
      title: sidebarConfig.title,
      links: sidebarConfig.items
        .map(item => {
          const itemPath = item.path;
          const path = constructURL(itemPath, { params, failOnMissingParam: false });

          if (!path || !item.accessible) {
            return null;
          }

          return {
            key: itemPath,
            path,
            title: item.title,
            checkIfRouteActive: (item.isRouteActive || defaultIsRouteActive).bind(undefined, itemPath),
            clickHandler: (item.clickHandler || defaultClickHandler(props)).bind(undefined, path),
          };
        })
        .filter(is.object),
    }))
    // Only display non-empty lists to user
    .filter(sidebarConfig => sidebarConfig.links.length);

const getSidebarGroupsSimpleStoryBuilder = (props: any): Group[] => {
  return [
    {
      key: 'publisher-stories',
      title: getMessageFromId('story-studio'),
      items: [
        {
          path: creator.home.path,
          title: getMessageFromId('sidebar-content-homepage'),
          accessible: props.isStoryViewer,
        },
        {
          path: creatorAnalytics.analytics.path,
          title: getMessageFromId('analytics'),
          accessible: props.isAnalyticsViewer && props.isDailyAnalyticsViewer,
        },
        {
          path: creatorAnalytics.storiesAnalytics.path,
          title: getMessageFromId('analytics'),
          accessible: props.isAnalyticsViewer && !props.isDailyAnalyticsViewer,
        },
        {
          path: creatorAnalytics.revenueAnalytics.path,
          title: getMessageFromId('revenue'),
          accessible: isRevenueDashboardEnabled(props.isMonetizationAnalystViewer, props.activePublisherDetails),
        },
        {
          path: creator.creativeTemplates.path,
          title: getMessageFromId('sidenav-template-option'),
          accessible: props.isActiveCreatorPublisher,
        },
        {
          path: creator.settings.path,
          title: getMessageFromId('publisher-details-settings-header'),
          accessible: props.isActiveCreatorPublisher,
        },
        {
          title: getMessageFromId('media-library-title'),
          path: creator.mediaLibrary.path,
          accessible: props.isStoryViewer && props.isMediaEditor && !props.isSingleAssetStoryEditorEnabled,
        },
        {
          path: creator.contact.path,
          title: getMessageFromId('contact-title-sidebar'),
          accessible: true,
          clickHandler: path => {
            if (isScreenshotSupportAvailable()) {
              props.showModal(ModalType.TAKE_SCREENSHOT_MODAL, 'sidebar', {});
            } else {
              props.push(path);
            }
          },
        },
        {
          path: `${ZENDESK_BASE_URL}/hc/en-us`,
          title: getMessageFromId('help-center-sidebar'),
          accessible: true,
          clickHandler: newWindowClickHandler(),
          isRouteActive: noopIsRouteActive,
        },
      ],
    },
    {
      key: 'administration',
      title: getMessageFromId('administration-sidebar-indicator'),
      items: [
        {
          path: creator.admin.path,
          accessible: props.isPublisherUserManager && props.isActiveCreatorPublisher,
          title: getMessageFromId('users'),
        },
      ],
    },
  ];
};

export const getSidebarConfigSimpleStoryBuilder = ({ props, params }: any): any =>
  getSidebarGroupsSimpleStoryBuilder({ ...props })
    .filter(sideBarConfig => some(sideBarConfig.items, item => item.accessible))
    .map((sidebarConfig: Group) => ({
      key: sidebarConfig.key,
      title: sidebarConfig.title,
      links: sidebarConfig.items
        .map(item => {
          const itemPath = item.path || '';
          if (!itemPath) {
            log.warn('Missing path in sidebar config item', item);
          }
          const path = constructURL(itemPath, { params, failOnMissingParam: false });

          if (!path || !item.accessible) {
            return null;
          }

          return {
            key: itemPath,
            path,
            title: item.title || '',
            checkIfRouteActive: (item.isRouteActive || defaultIsRouteActive).bind(undefined, itemPath),
            clickHandler: (item.clickHandler || defaultClickHandler(props)).bind(undefined, path),
          };
        })
        .filter(is.object),
    }));
