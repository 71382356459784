import { SSAPI_PROXY_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder(SSAPI_PROXY_BASE_URL);

export const autocomplete = {
  wikipedia: builder.createAPICall('autocomplete/wikipedia', {
    query: ['search'],
  }),
  scc: builder.createAPICall('autocomplete/v2/scc'),
};
