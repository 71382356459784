import classNames from 'classnames';
import * as React from 'react';
import { MouseEventHandler } from 'react';
import InlineSVG from 'svg-inline-react';

import { CrossOrigin } from 'config/constants';

import style from './Icon.scss';

type Props = {
  className?: string;
  icon?: string;
  img?: string;
  inlineIcon?: string;
  onClick?: MouseEventHandler;
  crossOrigin?: CrossOrigin;
  'data-test'?: string;
  alt?: string;
};

export default function Icon(props: Props) {
  if (props.img) {
    return (
      <img
        src={props.img}
        alt={props.alt}
        crossOrigin={props.crossOrigin || CrossOrigin.USE_CREDENTIALS}
        className={props.className}
        onClick={props.onClick}
      />
    );
  }

  if (props.icon) {
    return <span className={classNames('icon', `icon-${props.icon}`, props.className)} onClick={props.onClick} />;
  }

  if (props.inlineIcon) {
    const inlineStyle = classNames(style.inline, props.className);
    return (
      <InlineSVG
        src={props.inlineIcon}
        className={inlineStyle}
        onClick={props.onClick}
        data-test={props['data-test']}
      />
    );
  }

  return null;
}
