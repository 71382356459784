import { defineMessages } from 'react-intl';

import { enumObject } from 'utils/enum';

export enum SubscriberEvent {
  MODERATION = 'MODERATION',
}

export const SubscriberType = enumObject({
  DISCOVER_USER: 'DISCOVER_USER',
  CONTACT: 'CONTACT',
});

export const NotificationMethod = enumObject({
  EMAIL: 'EMAIL',
  SMS: 'SMS',
});

export const SUBSCRIBE_EVENT_TO_INTL_MESSAGE = defineMessages({
  [SubscriberEvent.MODERATION]: {
    id: 'moderation-subscription-label',
    description: 'label that appears in a subscription table',
    defaultMessage: 'Moderation',
  },
});

export const SUBSCRIBE_EVENT_TO_INTL_DESCRIPTION = defineMessages({
  [SubscriberEvent.MODERATION]: {
    id: 'moderation-report-description',
    description: 'Describes the type of emails the user will receive once subscribed',
    defaultMessage: 'Content violation emails',
  },
});

export const SUBSCRIBE_EVENT_TABLE = {
  [SubscriberEvent.MODERATION]: {
    name: SUBSCRIBE_EVENT_TO_INTL_MESSAGE[SubscriberEvent.MODERATION],
    description: SUBSCRIBE_EVENT_TO_INTL_DESCRIPTION[SubscriberEvent.MODERATION],
  },
};
