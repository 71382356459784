import { combineReducers } from 'redux';

import { createSequenceHandlingReducer, createRemoverByIdReducer } from 'state/common/reducerFactories';

import * as subscriptionsActions from '../actions/subscriptionsActions';

import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

export const byId = createSequenceHandlingReducer(
  {},
  [
    subscriptionsActions.FETCH_SUBSCRIPTION,
    subscriptionsActions.CREATE_SUBSCRIPTION,
    subscriptionsActions.DELETE_SUBSCRIPTION,
  ],
  {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (action.error) {
        return state;
      }
      if (action.type !== subscriptionsActions.DELETE_SUBSCRIPTION) {
        const subscriptions = action.payload.entities.subscription;
        return u(wrapEntitiesInUpdeepConstants(subscriptions), state);
      }
      return createRemoverByIdReducer('subscriptionId', subscriptionsActions.DELETE_SUBSCRIPTION)(state, action);
    },
  }
);

export default combineReducers({
  byId,
});
