import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import { features } from 'utils/apis/discoverAPI';

import { debugSnapEndpoint, debugEditionEndpoint, debugPublisherEndpoint } from './debugApis';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { BusinessProfileID, PublisherID } from 'types/publishers';

export const FETCH_DEBUG_SNAP = 'debug/FETCH_DEBUG_SNAP';

export const fetchDebugSnap = (snapId: SnapId) => ({
  type: FETCH_DEBUG_SNAP,
  params: {
    snapId,
  },
  meta: {
    [CALL_API]: {
      endpoint: debugSnapEndpoint(snapId),
      finalizer: optimisticJsonFinalizer,
    },
  },
});

export const FETCH_DEBUG_EDITION = 'debug/FETCH_DEBUG_EDITION';

export const fetchDebugEdition = (editionId: EditionID) => ({
  type: FETCH_DEBUG_EDITION,
  params: {
    editionId,
  },
  meta: {
    [CALL_API]: {
      endpoint: debugEditionEndpoint(editionId),
      finalizer: optimisticJsonFinalizer,
    },
  },
});

export const FETCH_DEBUG_PUBLISHER = 'debug/FETCH_DEBUG_PUBLISHER';

export const fetchDebugPublisher = (publisherId: PublisherID) => ({
  type: FETCH_DEBUG_PUBLISHER,
  params: {
    publisherId,
  },
  meta: {
    [CALL_API]: {
      endpoint: debugPublisherEndpoint(publisherId),
      finalizer: optimisticJsonFinalizer,
    },
  },
});

export const FETCH_DEBUG_PUBLISHER_ENABLED_FEATURES = 'debug/FETCH_DEBUG_PUBLISHER_ENABLED_FEATURES';

export const fetchDebugPublisherEnabledFeatures = (
  publisherId: PublisherID,
  businessProfileId?: BusinessProfileID
) => ({
  type: FETCH_DEBUG_PUBLISHER_ENABLED_FEATURES,
  params: {
    publisherId,
  },
  meta: {
    [CALL_API]: {
      endpoint: features.enabledByPublisher({ businessProfileId }),
      finalizer: optimisticJsonFinalizer,
    },
  },
});
