import _ from 'lodash';
/**
 * @param clearAndSelectItemType Optional, type of the single selection action
 */
export const createSelectedItemsList = (toggleActionType: any, clearActionType: any, clearAndSelectItemType: any) => {
  return (state = [], action: any) => {
    // Select only the item in the payload
    if (clearAndSelectItemType && action.type === clearAndSelectItemType) {
      return [action.payload.item];
    }
    // payload.snap could be null if the snap comes from a previous search and no longer
    // exists in redux store, but payload.snapId should never be null
    switch (action.type) {
      case toggleActionType: {
        // If item is currently selected, deselect it
        if (state.filter(item => (item as any).id === action.payload.itemId).length > 0) {
          return state.filter(item => (item as any).id !== action.payload.itemId);
        }
        // Otherwise, select it, and select any items in between if shift selected
        if (action.payload.item) {
          if (action.payload.shiftSelectedItems && action.payload.shiftSelectedItems.length > 0) {
            // Filter out already selected ones and add all of remaining shift selected items
            const selectedItemsIdSet = {};
            state.forEach(item => {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              selectedItemsIdSet[(item as any).id] = true;
            });
            const filteredShiftSelectedItems = action.payload.shiftSelectedItems.filter((item: any) => {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              return !selectedItemsIdSet[item.id];
            });
            return _.concat(state, filteredShiftSelectedItems);
          }
          return _.concat(state, action.payload.item);
        }
        return state;
      }
      case clearActionType: {
        return [];
      }
      default: {
        return state;
      }
    }
  };
};
