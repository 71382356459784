import invariant from 'invariant';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { getAuthType } from 'state/auth/selectors/authSelectors';
import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as showsActions from 'state/shows/actions/showsActions';

import { AuthType } from 'config/constants';
import { pickKeysFinalizer, pickKeysPreprocessor } from 'redux/middleware/requestProcessing';
import * as creativeSuiteAPI from 'utils/apis/creativeSuiteAPI';
import { buildHashForExistingKeys, buildShallowDiff } from 'utils/apis/partialUpdateUtil';
import * as scsAPI from 'utils/apis/scsAPI';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { registerIntlMessage } from 'utils/intlMessages/intlMessages';
import * as locationUtils from 'utils/locationUtils';
import { ModalType } from 'utils/modalConfig';

import type { PublisherID, BusinessProfileID } from 'types/publishers';
import type { GetState, Dispatch } from 'types/redux';
import { EpisodeProperties } from 'types/shows';
import type { Episode, EditableEpisodeProperties } from 'types/shows';

export const UPDATE_EPISODE = 'episodes/UPDATE_EPISODE';
export const CREATE_EPISODE = 'episodes/CREATE_EPISODE';
export const UNLINK_EPISODE = 'episodes/UNLIK_EPISODE';

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="single-asset-wrong-dimensions"
      description="Error message displayed to user when video aspect ratio requires it to be uploaded via episode creator"
      defaultMessage="The video you are uploading isn't supported as it hasn't been edited into a vertical format. Would you like to use our Video Conversion tool for this?" /* eslint max-len: ["error", 250] */
    />
  ),
  params: [],
});

const getUpdateDiffAndHashForEpisode = (existingEpisode: Episode, update: EditableEpisodeProperties) => {
  const excludedProperties = [
    'isPartOfShow',
    'numberOfSnaps',
    'seasonDisplayName',
    'seasonNumber',
    'title',
    'seasonId',
  ];
  const diff = buildShallowDiff(existingEpisode, update, ['id'], excludedProperties);
  const hash = buildHashForExistingKeys(existingEpisode, diff, [], ['id']);

  const diffWithShowId = {
    ...diff,
    showId: update.showId,
    seasonId: update.seasonId,
    title: existingEpisode.title, // can stop sending this once we have removed validation on the server
  };

  return { update: diffWithShowId, hash };
};

export const createEpisode = (
  publisherId: PublisherID,
  businessProfileId: BusinessProfileID,
  seasonNumber: number,
  seasonDisplayName: string | undefined | null,
  episodeProperties: EditableEpisodeProperties
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(
      createCallAction(
        {
          type: CREATE_EPISODE,
          params: {
            publisherId,
          },
        },
        {
          method: 'POST',
          endpoint: scsAPI.shows.createEpisode({ publisherId, seasonNumber, seasonDisplayName }),
          body: episodeProperties,
          preprocessor: pickKeysPreprocessor(Object.values(EpisodeProperties)),
          finalizer: pickKeysFinalizer(Object.keys(episodeProperties)),
        }
      )
    ).catch(apiErrorHandler(dispatch, ErrorContexts.CREATE_EPISODE));
    return dispatch(showsActions.loadShows(publisherId, businessProfileId));
  };
};

export const updateEpisode = (
  publisherId: PublisherID,
  businessProfileId: BusinessProfileID,
  seasonNumber: number | undefined | null,
  episodeProperties: EditableEpisodeProperties
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const existingEpisode = episodesSelectors.getEpisodeById(getState())(episodeProperties.id);
    invariant(existingEpisode, `could not find episode with id: ${episodeProperties.id}`);
    const { update, hash } = getUpdateDiffAndHashForEpisode(existingEpisode, episodeProperties);
    await dispatch(
      createCallAction(
        {
          type: UPDATE_EPISODE,
          params: {
            publisherId,
          },
        },
        {
          method: 'PUT',
          endpoint: scsAPI.shows.updateEpisode({ publisherId, seasonNumber, hash }),
          body: update,
          preprocessor: pickKeysPreprocessor(Object.values(EpisodeProperties)),
          finalizer: pickKeysFinalizer(Object.keys(episodeProperties)),
        }
      )
    ).catch(apiErrorHandler(dispatch, ErrorContexts.UPDATE_EPISODE));
    return dispatch(showsActions.loadShows(publisherId, businessProfileId));
  };
};

export const unlinkEpisode = (
  publisherId: PublisherID,
  businessProfileId: BusinessProfileID,
  episodeProperties: EditableEpisodeProperties
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(
      createCallAction(
        {
          type: UNLINK_EPISODE,
          params: {
            publisherId,
            episodeId: episodeProperties.id,
          },
        },
        {
          method: 'DELETE',
          endpoint: scsAPI.shows.unlinkEpisode({ publisherId }),
          body: episodeProperties,
          preprocessor: pickKeysPreprocessor(Object.values(EpisodeProperties)),
          finalizer: pickKeysFinalizer(Object.keys(episodeProperties)),
        }
      )
    ).then(() => {
      return dispatch(showsActions.loadShows(publisherId, businessProfileId));
    });
  };
};

export function openEpisodeCreatorModal(publisherId: PublisherID) {
  return (dispatch: Dispatch, getState: GetState) => {
    const isSnapSSO = getAuthType(getState()) === AuthType.SNAPCHAT;

    return dispatch(
      modalsActions.showModal(ModalType.OPEN_EPISODE_CREATOR, 'openEpisodeCreator', {
        onConfirm: () => {
          locationUtils.openInNewWindow(
            creativeSuiteAPI.creative.createEpisode(isSnapSSO)({
              publisherId,
            })
          );
        },
        cancelText: intlMessages.getMessageFromId('cancel-button-modal'),
        confirmText: intlMessages.getMessageFromId('confirm-button-modal'),
        body: intlMessages.getMessageFromId('single-asset-wrong-dimensions'),
      })
    );
  };
}
