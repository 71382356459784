import { memoize, sortBy } from 'lodash';
import { Moment } from 'moment';
import moment from 'moment-timezone';

type timezoneType = {
  offset: number;
  name: string;
  prettyName: string;
};

// There are more than 500 timezone names, we only show a few that encompass all possible timezones
const filteredTimezoneList = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Juneau',
  'America/Los_Angeles',
  'America/Tijuana',
  'America/Vancouver',
  'America/Denver',
  'America/Edmonton',
  'America/Hermosillo',
  'America/Phoenix',
  'America/Chicago',
  'America/Mexico_City',
  'America/Winnipeg',
  'America/Bogota',
  'America/Detroit',
  'Pacific/Easter',
  'America/Montreal',
  'America/New_York',
  'America/Rio_Branco',
  'America/Toronto',
  'America/Halifax',
  'America/Manaus',
  'America/St_Johns',
  'America/Bahia',
  'America/Belem',
  'America/Buenos_Aires',
  'America/Recife',
  'America/Santiago',
  'America/Noronha',
  'America/Sao_Paulo',
  'Atlantic/Canary',
  'Africa/Casablanca',
  'Europe/Dublin',
  'Europe/London',
  'Africa/Algiers',
  'Europe/Amsterdam',
  'Europe/Berlin',
  'Europe/Brussels',
  'Europe/Budapest',
  'Africa/Lagos',
  'Europe/Madrid',
  'Europe/Oslo',
  'Europe/Paris',
  'Europe/Prague',
  'Europe/Rome',
  'Europe/Stockholm',
  'Africa/Tunis',
  'Europe/Warsaw',
  'Asia/Amman',
  'Africa/Cairo',
  'Asia/Jerusalem',
  'Africa/Johannesburg',
  'Europe/Kaliningrad',
  'Asia/Aden',
  'Africa/Kampala',
  'Europe/Moscow',
  'Africa/Nairobi',
  'Asia/Riyadh',
  'Europe/Volgograd',
  'Asia/Dubai',
  'Asia/Yekaterinburg',
  'Asia/Kolkata',
  'Asia/Omsk',
  'Asia/Krasnoyarsk',
  'Asia/Novosibirsk',
  'Asia/Hong_Kong',
  'Asia/Irkutsk',
  'Asia/Manila',
  'Australia/Perth',
  'Asia/Singapore',
  'Asia/Taipei',
  'Australia/Eucla',
  'Asia/Seoul',
  'Asia/Tokyo',
  'Asia/Yakutsk',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Asia/Vladivostok',
  'Australia/Adelaide',
  'Australia/Hobart',
  'Australia/Melbourne',
  'Asia/Sakhalin',
  'Australia/Sydney',
  'Asia/Kamchatka',
  'Pacific/Auckland',
  'Pacific/Chatham',
];

export const getTimezoneList = memoize(() => {
  const timezoneList = filteredTimezoneList.map(
    (timezoneName: string): timezoneType => {
      const now = moment.tz(timezoneName);

      // Timezone names are in 'America/Los_Angeles' format
      const timezoneNameWithSpaces = timezoneName.replace('_', ' ');

      return {
        name: timezoneName,
        offset: now.utcOffset(),
        prettyName: `(GMT ${now.format('Z')}) ${timezoneNameWithSpaces}`,
      };
    }
  );

  return sortBy(timezoneList, ['offset']);
});

export const guessTimezone = memoize(
  (getTimezoneListFn = getTimezoneList): timezoneType => {
    // Since not all timezones are in the standard list we must guess the timezone, then match
    // to one that exists in the list that has the same offset

    const guessedTimezone = moment.tz.guess();

    const now = moment.tz(guessedTimezone);
    const guessedUtcOffset = now.utcOffset();

    // First search by name
    let matchingTimezone = getTimezoneListFn().find((timezone: timezoneType) => timezone.name === guessedTimezone);

    // Then let's try by utc offset
    if (!matchingTimezone) {
      matchingTimezone = getTimezoneListFn().find((timezone: timezoneType) => timezone.offset === guessedUtcOffset);
    }

    // If we could not find, get the first one.
    if (!matchingTimezone) {
      matchingTimezone = getTimezoneListFn()[0];
    }

    return matchingTimezone;
  }
);

export const generateStartDateTimezones = (
  startDate: moment.Moment,
  endDate: moment.Moment | undefined | null,
  endDateTimezone: string,
  endDateSchedulingEnabled: boolean
) => {
  return getTimezoneList().map(timezone => {
    const isAfterEndDate =
      endDateSchedulingEnabled &&
      moment(startDate).tz(timezone.name, true).isAfter(moment(endDate).tz(endDateTimezone, true), 'hour');

    const isBeforeNow = moment(startDate).tz(timezone.name, true).isBefore(moment().tz(timezone.name), 'hour');
    return {
      label: timezone.prettyName,
      value: timezone.name,
      disabled: isAfterEndDate || isBeforeNow,
    };
  });
};

export const generateEndDateTimezones = (
  startDate: moment.Moment,
  endDate: moment.Moment,
  startDateTimezone: string
) => {
  return getTimezoneList().map(timezone => {
    const isBeforeStartDate = moment(endDate)
      .tz(timezone.name, true)

      .isBefore(moment(startDate).tz(startDateTimezone, true), 'hour');

    const isBeforeNow = moment(endDate).tz(timezone.name, true).isBefore(moment().tz(timezone.name), 'hour');
    return {
      label: timezone.prettyName,
      value: timezone.name,
      disabled: isBeforeStartDate || isBeforeNow,
    };
  });
};

export const nowInUTC = () => {
  const now = moment().format();
  return moment.utc(now).format();
};

export const formatDateAndTime = (date: number | Moment): string => {
  return moment.tz(date, guessTimezone().name).format('YYYY-MM-DD HH:mm:ss z');
};

export const formatDate = (date: number | Moment): string => {
  return moment.tz(date, guessTimezone().name).format('YYYY-MM-DD');
};

export const formatDateShortMonth = (date: number | Moment): string => {
  return moment.tz(date, guessTimezone().name).format('ll');
};
