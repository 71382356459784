import { createSelector as s } from 'reselect';

import { getEnabledFeatures } from 'state/publishers/selectors/publishersSelectors';

import { FeatureFlag } from 'types/featureFlags';
import { State } from 'types/rootState';

const makeFeatureFlag = (feature: FeatureFlag): ((state: State) => boolean) =>
  s(getEnabledFeatures, enabledFeatures => {
    return enabledFeatures.includes(feature);
  });

export const isSnapPublisherIngestionEnabled = makeFeatureFlag(FeatureFlag.SNAP_PUB_INGESTION);
export const isCallToActionEnabled = makeFeatureFlag(FeatureFlag.CALL_TO_ACTION);
export const isCustomDynamicEditionAutoCreateTimeEnabled = makeFeatureFlag(
  FeatureFlag.CUSTOM_DYNAMIC_EDITION_AUTO_CREATE_TIME
);
export const isTopsnapLoopVideoEnabled = makeFeatureFlag(FeatureFlag.TOPSNAP_LOOP_VIDEO);
export const isPollsTitleEditable = makeFeatureFlag(FeatureFlag.POLLS_EDITABLE_TITLE);
export const isSpectaclesVideoEnabled = makeFeatureFlag(FeatureFlag.SPECTACLES_TOPSNAP);
export const isCallToActionOverrideEnabled = makeFeatureFlag(FeatureFlag.CALL_TO_ACTION_OVERRIDE);
export const isEditionSegmentsEnabled = makeFeatureFlag(FeatureFlag.EDITION_SEGMENTS);
export const isShowsSchedulingV2Enabled = makeFeatureFlag(FeatureFlag.SHOWS_SCHEDULING_V2);
export const isTopSnapWebViewEnabled = makeFeatureFlag(FeatureFlag.TOPSNAP_WEBVIEW);
export const isBitmojiContentEnabled = makeFeatureFlag(FeatureFlag.BITMOJI_CONTENT);
export const isPublisherCommentsSettingEnabled = makeFeatureFlag(FeatureFlag.PUBLISHER_COMMENTS_SETTING);
export const isCameoContentEnabled = makeFeatureFlag(FeatureFlag.CAMEOS_CONTENT);
export const isTileHeadlineValidationEnabled = makeFeatureFlag(FeatureFlag.TILE_HEADLINE_VALIDATION);
export const isTileHeadlineVisibilityPickerEnabled = makeFeatureFlag(FeatureFlag.TILE_HEADLINE_VISIBILITY_PICKER);
export const isStorySearchSnapCurationEnabled = makeFeatureFlag(FeatureFlag.STORY_SEARCH_SNAP_CURATION);
export const isNavbarLanguageDropdownEnabled = makeFeatureFlag(FeatureFlag.NAVBAR_LANGUAGE_DROPDOWN);
export const isModerationTilesOnlyEnabled = makeFeatureFlag(FeatureFlag.MODERATION_TILES_ONLY);
export const isInfiniteAdsPlacerEnabled = makeFeatureFlag(FeatureFlag.INFINITE_ADS_PLACER);
export const isGeofencedWebviewAttachmentsEnabled = makeFeatureFlag(FeatureFlag.GEOFENCED_WEBVIEW_ATTACHMENTS);
export const isLowResolutionTopsnapsEnabled = makeFeatureFlag(FeatureFlag.LOW_RESOLUTION_TOPSNAPS);
export const isRemoteWebWithReferrerEnabled = makeFeatureFlag(FeatureFlag.REMOTE_WEB_WITH_REFERRER);
export const isExtrasEnabled = makeFeatureFlag(FeatureFlag.SHOW_EXTRAS);
export const isAdvancedCurationEnabled = makeFeatureFlag(FeatureFlag.ADVANCED_CURATION);
export const isMakeAllStoriesUnavailableEnabled = makeFeatureFlag(FeatureFlag.MAKE_ALL_STORIES_UNAVAILABLE);
export const isSubtitlesMultiLanguageEnabled = makeFeatureFlag(FeatureFlag.SUBTITLES_MULTI_LANG);
export const isCameosContentEnabled = makeFeatureFlag(FeatureFlag.CAMEOS_CONTENT);
export const isLongformAttachmentLivestreamingEnabled = makeFeatureFlag(FeatureFlag.LONGFORM_VIDEO_LIVESTREAMING);
export const isOptionalAdsOverrideEnabled = makeFeatureFlag(FeatureFlag.OPTIONAL_ADS_OVERRIDE);
export const isOriginalArticleUrlEnabled = makeFeatureFlag(FeatureFlag.ORIGINAL_ARTICLE_URL);
export const isAlwaysShowAdControlsEnabled = makeFeatureFlag(FeatureFlag.ALWAYS_SHOW_AD_CONTROLS);
export const isRemoteWebLimitEnabled = makeFeatureFlag(FeatureFlag.REMOTE_WEB_LIMIT);
export const isRemoteWebEnabled = makeFeatureFlag(FeatureFlag.REMOTE_WEB);
export const isUpdatedProfilePreviewEnabled = makeFeatureFlag(FeatureFlag.UPDATED_PROFILE_PREVIEW);
export const isHappeningNowFeedsEnabled = makeFeatureFlag(FeatureFlag.HAPPENING_NOW_FEEDS);
export const isHostUserRequired = makeFeatureFlag(FeatureFlag.HOST_USER_REQUIRED);
export const isPublishingPaused = makeFeatureFlag(FeatureFlag.PUBLISHING_PAUSED);
export const isNewUserExperienceEnabled = makeFeatureFlag(FeatureFlag.NEW_USER_EXPERIENCE);
export const isProductPlacementEnabled = makeFeatureFlag(FeatureFlag.PRODUCT_PLACEMENT);
export const isFeedCheckboxesDisplayEnabled = makeFeatureFlag(FeatureFlag.SHOW_USE_CHECKBOXES_FOR_NON_SUPER_ADMINS);
export const isTileLogoOffOptionDisabled = makeFeatureFlag(FeatureFlag.REMOVE_TILE_LOGO_OFF_TOGGLE);
export const isDisableAdsControlEnabled = makeFeatureFlag(FeatureFlag.DISABLE_ADS_CONTORL);
export const isSeasonsEpisodesDisabled = makeFeatureFlag(FeatureFlag.DISABLE_SEASONS_EPISODES);

// Single asset
export const isSASUnificationEnabled = makeFeatureFlag(FeatureFlag.SAS_UNIFICATION);
export const isSingleAssetStoryEditorEnabled = makeFeatureFlag(FeatureFlag.SINGLE_ASSET_STORY_EDITOR);
export const isSingleAssetSnapcodeEnabled = makeFeatureFlag(FeatureFlag.SAS_SNAPCODES);

// Curation
export const isCuratedLayerEnabled = makeFeatureFlag(FeatureFlag.CURATED_LAYER);

export const isSendToSpotlightEnabled = makeFeatureFlag(FeatureFlag.SEND_TO_SPOTLIGHT);
export const isSendToNoLocationEnabled = makeFeatureFlag(FeatureFlag.SEND_TO_NO_LOCATION);

export const isContentAnnotationsEnabled = makeFeatureFlag(FeatureFlag.CONTENT_ANNOTATIONS);

// Tile violation
export const isModerateTileViolation = makeFeatureFlag(FeatureFlag.TILE_VIOLATION_MODERATE_WARNING);
export const isHighTileViolation = makeFeatureFlag(FeatureFlag.TILE_VIOLATION_HIGH_WARNING);
export const isSubscriberOnlyTileViolation = makeFeatureFlag(FeatureFlag.TILE_VIOLATION_SUBSCRIBER_ONLY);
export const isTileViolationAppealEnabled = makeFeatureFlag(FeatureFlag.TILE_VIOLATION_APPEAL);

// UGC content
export const isShowsToSpotlightEnabled = makeFeatureFlag(FeatureFlag.SHOWS_TO_SPOTLIGHT);
export const isSpotlightManagementEnabled = makeFeatureFlag(FeatureFlag.SPOTLIGHT_MANAGEMENT);
export const isSpotlightSchedulingEnabled = makeFeatureFlag(FeatureFlag.SPOTLIGHT_SCHEDULING);
export const isStoryAnalyticsV2Enabled = makeFeatureFlag(FeatureFlag.STORY_ANALYTICS_V2);
export const isStoryAnalyticsProfileV2Enabled = makeFeatureFlag(FeatureFlag.STORY_ANALYTICS_PROFILES_V2);
export const isStoriesAnalyticsV2Enabled = makeFeatureFlag(FeatureFlag.STORIES_ANALYTICS);
export const isPublicStoryPostingEnabled = makeFeatureFlag(FeatureFlag.PUBLIC_STORY_POSTING);

export const isPollAttachmentsEnabled = makeFeatureFlag(FeatureFlag.POLL_ATTACHMENTS);
export const showSpotlightRejectionModerationReason = makeFeatureFlag(FeatureFlag.SPOTLIGHT_MODERATION_REJECTION_INFO);
export const isSnapPublisherEnabled = makeFeatureFlag(FeatureFlag.SNAP_PUBLISHER);
