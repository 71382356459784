import { Action as ReduxAction } from 'redux';

import { assertArg } from 'utils/assertionUtils';

export type Action<T> = ReduxAction<string> & {
  error?: Error;
  payload?: T;
};

export type ActionWithParams<T> = ReduxAction<string> & {
  params: T;
};

export type ActionWithParamsAndPayload<TPayload, TParams> = Action<TPayload> & ActionWithParams<TParams>;

type IntlConfig = {
  locale: string;
  messages: {
    [id: string]: string;
  };
  defaultLocale: string;
};

export type Context = {
  intl: IntlConfig;
};

const IS_NUMBER_REGEX = /^\d+$/;

export function isNumericId(str: any) {
  return typeof str === 'number' || Boolean((str || '').match(IS_NUMBER_REGEX));
}

export type SequenceAction<T> = Action<T> & {
  sequence?: string;
};

export type SnapId = number | string;

export type FileLike =
  | File
  | {
      type: string;
      name: string;
      preview?: string;
    };

export function assertLinkableId(linkableId: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(linkableId).is.number.or.is.string();
}

export function assertSnapId(snapId: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapId).is.number.or.is.string();
}

export function assertSnapIdOrUndefined(snapId: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapId).is.number.or.is.string.or.is.undefined(snapId);
}

export function assertSnapIdOrNull(snapId: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapId).is.number.or.is.string.or.is.null(snapId);
}

export function assertAllSnapIds(snapIds: any) {
  snapIds.forEach(assertSnapId);
}

export function assertUrl(url: any) {
  // @ts-expect-error ts-migrate(2554)
  assertArg(url, 'url').is.url();
}

export function isSnapIdType(snapId: any): boolean {
  return typeof snapId === 'number' || typeof snapId === 'string';
}
