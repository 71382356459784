import { combineReducers } from 'redux';

import { createTimestampReducer, serializeReducers } from 'state/common/reducerFactories';
import { GET_PUBLISHERS } from 'state/publishers/actions/publishersActions';
import { UserPreference } from 'state/user/userState';

import * as userActions from '../actions/userActions';
import { SET_SEEN_USER_HELP_MODAL, SET_SHOW_HELP_MODAL_FLAG } from '../actions/userActions';

import { EMPTY_OBJECT, Sequence, LocalStorage } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { localStorage } from 'utils/localStorageUtils';
import u from 'utils/safeUpdeep';

import { Action } from 'types/common';

// @ts-expect-error ts-migrate(2556) FIXME: Expected 1-2 arguments, but got 0 or more.
const setStorageItem = (...args: any[]) => localStorage.setItem(...args);
// @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 0 or more.
const getStorageItem = (...args: any[]) => localStorage.getItem(...args);
// ------------------------------------
// Action Handlers
// ------------------------------------
function info(state = null, action: any) {
  switch (action.type) {
    case userActions.STORE_USER_ID: {
      const { userId } = action.payload;
      return u(
        {
          userId,
        },
        state || {}
      );
    }
    case userActions.SET_USER_INFO: {
      const user = action.payload.userInfo;
      return u({ userId: user.id }, user);
    }
    case userActions.INFO:
      // TODO: display toast on error
      if (action.sequence === Sequence.DONE) {
        if (!action.error && action.payload && action.payload.user) {
          const { user } = action.payload;
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
          assertArg(user.id).is.string();
          // this part is done for the backward compatibility
          // TODO directly use id, fix other place that do not use userSelect userId first
          return u({ userId: user.id }, user);
        }
      }
      return state;
    case userActions.SET_USER_FLAG:
      if (action.sequence === Sequence.DONE) {
        return u({ userFlags: action.payload }, state || {});
      }
      return state;
    default:
      return state;
  }
}

const infoAge = createTimestampReducer(userActions.INFO);

function activePublisherId(state = null, action: any) {
  switch (action.type) {
    case userActions.SET_ACTIVE_PUBLISHER:
      setStorageItem(LocalStorage.CURRENT_PUBLISHER, String(action.activePublisherId));
      return action.activePublisherId;
    default:
      return state;
  }
}

function activeCreator(state = null, action: any) {
  switch (action.type) {
    case userActions.SET_ACTIVE_CREATOR:
      setStorageItem(LocalStorage.CURRENT_HOST_USERNAME, String(action.activeCreator?.hostUsername));
      return action.activeCreator;
    default:
      return state;
  }
}

function adControlsEnabled(state: any, action: any) {
  let updatedState = state;
  if (typeof state === 'undefined') {
    try {
      updatedState = JSON.parse(getStorageItem('adControlsEnabled')) || true;
    } catch (e) {
      updatedState = true;
    }
  }

  switch (action.type) {
    case userActions.SET_AD_CONTROLS:
      setStorageItem('adControlsEnabled', JSON.stringify(action.adControlsEnabled));
      return action.adControlsEnabled;
    default:
      return updatedState;
  }
}

function showTilesEnabled(state = true, action: any) {
  const localState = localStorage.getItem(LocalStorage.SHOW_TILES)
    ? localStorage.getItem(LocalStorage.SHOW_TILES) === 'true'
    : state;
  if (action.type !== userActions.SET_SHOW_TILES) {
    return localState;
  }
  localStorage.setItem(LocalStorage.SHOW_TILES, action.payload.showTilesEnabled.toString());
  return action.payload.showTilesEnabled;
}

// ------------------------------------
// Reducer
// ------------------------------------

function publishers(state = [], action: any) {
  if (action.sequence !== Sequence.DONE) {
    return state;
  }
  switch (action.type) {
    case GET_PUBLISHERS:
      // TODO: display toast on error
      if (!action.error && action.payload) {
        return action.payload;
      }
      break;
    default:
      break;
  }
  return state;
}

function publishersById(state = {}, action: any) {
  if (action.sequence !== Sequence.DONE) {
    return state;
  }
  switch (action.type) {
    case GET_PUBLISHERS: {
      const publisherArray = publishers([], action);

      const publisherMap = {};
      publisherArray.forEach((publisher: any) => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        publisherMap[publisher.id] = publisher;
      });
      return publisherMap;
    }
    default:
      return state;
  }
}

function publishersByBusinessProfileId(state = {}, action: any) {
  if (action.sequence !== Sequence.DONE) {
    return state;
  }
  switch (action.type) {
    // Optimize for first load
    case GET_PUBLISHERS: {
      const publisherArray = publishers([], action);

      const publisherByBusinessProfileId = {};
      publisherArray.forEach((publisher: any) => {
        const id = publisher.businessProfileId;
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        publisherByBusinessProfileId[id] = publisher;
      });
      return publisherByBusinessProfileId;
    }
    default:
      return state;
  }
}

function lastUpdatedPublishersDate(state = 0, action: any) {
  switch (action.type) {
    case GET_PUBLISHERS: {
      if (action.sequence !== Sequence.DONE) {
        return state;
      }

      return action.meta.timestamp;
    }
    default:
      return state;
  }
}

function locale(
  state = {
    localeId: getStorageItem('localeId'),
    msg: {},
  },
  action: any
) {
  switch (action.type) {
    case userActions.SET_LOCALE:
      return {
        localeId: action.localeId,
        msg: action.messages,
      };
    default:
      return state;
  }
}

function lastViewedReviseFilter(state: any, action: any) {
  let currentState = state;
  if (typeof currentState === 'undefined') {
    try {
      currentState = JSON.parse(getStorageItem(LocalStorage.LAST_VIEWED_REVISE_FILTER)) || EMPTY_OBJECT;
    } catch (e) {
      currentState = EMPTY_OBJECT;
    }
  }

  let updatedState;
  switch (action.type) {
    case userActions.SET_LAST_VIEWED_REVISE_FILTER:
      updatedState = u({ [action.publisherId]: u.ifDiffConstant(action.time) }, currentState);
      setStorageItem(LocalStorage.LAST_VIEWED_REVISE_FILTER, JSON.stringify(updatedState));
      return updatedState;
    default:
      return currentState;
  }
}

function initializeUserPreference(): UserPreference {
  return {
    hasSeenNewUserModalInSession: false,
    doNotShowNewUserModalAgain: JSON.parse(getStorageItem(LocalStorage.DONT_SHOW_HELP_MODALS_PUBLISHER)) ?? false,
  };
}

const dontShowHelpModal = (state = initializeUserPreference(), action: Action<boolean>) => {
  if (action.type === SET_SHOW_HELP_MODAL_FLAG) {
    const doNotShowNewUserModalAgain = action.payload ?? false;
    setStorageItem(LocalStorage.DONT_SHOW_HELP_MODALS_PUBLISHER, String(doNotShowNewUserModalAgain));
    return u({ doNotShowNewUserModalAgain }, state);
  }
  return state;
};

const hasSeenPublisherHelpModal = (state = initializeUserPreference(), action: Action<boolean>) => {
  if (action.type === SET_SEEN_USER_HELP_MODAL) {
    return u({ hasSeenNewUserModalInSession: true }, state);
  }
  return state;
};

const selectedAdAccountId = (state = null, action: any) => {
  switch (action.type) {
    case userActions.SET_SELECTED_AD_ACCOUNT_ID:
      return action.adAccountId;
    default:
      return state;
  }
};

const useUnifiedLogin = (state = null, action: any) => {
  switch (action.type) {
    case userActions.SET_USE_UNIFIED_LOGIN:
      return action.useUnifiedLogin;
    default:
      return state;
  }
};

const preferences = serializeReducers([hasSeenPublisherHelpModal, dontShowHelpModal]);

// ------------------------------------
// Reducer
// ------------------------------------
export default combineReducers({
  info,
  infoAge,
  publishers,
  lastUpdatedPublishersDate,
  publishersById,
  publishersByBusinessProfileId,
  adControlsEnabled,
  activePublisherId,
  activeCreator,
  locale,
  showTilesEnabled,
  lastViewedReviseFilter,
  preferences,
  selectedAdAccountId,
  useUnifiedLogin,
});
