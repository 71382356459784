import { combineReducers } from 'redux';

import { createActivatingIdReducer, createSequenceCountingByIdReducer } from 'state/common/reducerFactories';

import * as actionIds from '../actionIds/publisherStoryEditorActionIds';

const activeEditionId = createActivatingIdReducer('editionId', actionIds.SET_ACTIVE_EDITION);

const ingestingArticleBySnapId = createSequenceCountingByIdReducer('snapId', actionIds.ARTICLE_IMPORT);

const editorMode = createActivatingIdReducer('mode', actionIds.SET_EDITOR_MODE);

const editorModeMetadata = createActivatingIdReducer('metadata', actionIds.SET_EDITOR_MODE);

const editorModeOptions = createActivatingIdReducer('options', actionIds.SET_EDITOR_MODE);

const lastVisitedSnapPublisherUrl = createActivatingIdReducer(
  'lastVisitedSnapPublisherUrl',
  actionIds.SET_LAST_VISITED_SNAP_PUB_URL
);

const isMediaLibraryDrawerVisible = (state = false, action: any) => {
  if (action.type === actionIds.TOGGLE_MEDIA_LIBRARY_DRAWER) {
    return !state;
  }
  return state;
};

export default combineReducers({
  editorMode,
  editorModeMetadata,
  editorModeOptions,
  activeEditionId,
  ingestingArticleBySnapId,
  isMediaLibraryDrawerVisible,
  lastVisitedSnapPublisherUrl,
});
