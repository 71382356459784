import _ from 'lodash';

import { OnProgressEvent } from 'types/errors';

// Shamelessly copied from https://github.com/github/fetch/blob/master/fetch.js
function parseHeaders(rawHeaders: any) {
  const headers = new Headers();
  // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
  // https://tools.ietf.org/html/rfc7230#section-3.2
  const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/, ' ');
  preProcessedHeaders.split(/\r?\n/).forEach((line: any) => {
    const parts = line.split(':');
    const key = parts.shift().trim();
    if (key) {
      const value = parts.join(':').trim();
      headers.append(key, value);
    }
  });
  return headers;
}
export default function fetchWithProgress(
  onProgress: (event: OnProgressEvent) => void,
  url: string,
  opts: RequestInit = {}
): Promise<Response> {
  return new Promise((res, rej) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = (e: ProgressEvent) => {
      if (e.target == null) {
        rej();
        return;
      }
      const response = new Response(
        'response' in e.target ? (e.target as any).response : (e.target as any).responseText,
        {
          status: (e.target as any).status,
          statusText: (e.target as any).statusText,
          headers: parseHeaders((e.target as any).getAllResponseHeaders() || ''),
        }
      );
      res(response);
    };
    xhr.onerror = rej;
    if (xhr.upload) {
      xhr.upload.onprogress = event => {
        onProgress({
          total: event.total || 0,
          loaded: event.loaded || 0,
        });
      };
    }
    if ((opts as any).credentials === 'include') {
      xhr.withCredentials = true;
    }
    xhr.open((opts as any).method || 'get', url);
    _.forOwn((opts as any).headers || {}, (value, key) => {
      xhr.setRequestHeader(key, value);
    });
    xhr.send((opts as any).body);
  });
}
