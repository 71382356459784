// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'deno... Remove this comment to see the full error message
import { denormalize } from 'denormalizr';
import _ from 'lodash';
import { createSelector as s } from 'reselect';

import { createKeySelector, createKeyThenDynamicKeySelector, reselectById } from 'state/common/selectorFactories';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import { modalVideoParams } from 'state/videoLibrary/actions/videoLibraryActionConfig';
import { SearchOption, VideoCount } from 'state/videoLibrary/reducers/videoLibraryReducers';
import { videoResult as videoResultSchema } from 'state/videoLibrary/schema/videoLibrarySchema';

import { VideoMetadata } from '../videoLibraryState';

import { SECONDS, PreviewStates, UploadPurpose, EMPTY_OBJECT } from 'config/constants';
import { State } from 'src/types/rootState';
import { functionRef } from 'utils/functionUtils';

import { AssetID } from 'types/assets';

export function getVideoLibrary(state: State) {
  return state.videoLibrary || EMPTY_OBJECT;
}
const getImageAssets = createKeySelector(getVideoLibrary, 'imageAssets', {});
const getSubtitles = createKeySelector(getVideoLibrary, 'videoSubtitles', {});
const getSubtitleProcessingStatuses = createKeySelector(getVideoLibrary, 'subtitleProcessingStatuses', {});
const getNormalizedVideoLibraryResults = createKeyThenDynamicKeySelector(
  getVideoLibrary,
  'videoLibraryResultMap',
  null
);
const getVideoCountLoadingBySearchTerm = createKeyThenDynamicKeySelector(getVideoLibrary, 'videoCountsLoading', null);
const getVideoResultLoadingById = createKeyThenDynamicKeySelector(getVideoLibrary, 'videoResultsLoading', null);
const getNormalizedVideoResultsById = createKeyThenDynamicKeySelector(getVideoLibrary, 'videoResults', null);
export const getVideoCountBySearchTerm = createKeyThenDynamicKeySelector(getVideoLibrary, 'videoCounts', null);
const getVideoMetadataById = createKeyThenDynamicKeySelector(getVideoLibrary, 'videoMetadataByAssetId', null);
export const getVideoSrcById = reselectById<{ src: string } | null, AssetID>(
  null,
  (state: State, assetId: AssetID) => getVideoMetadataById(state)(assetId),
  (videoMetadata: VideoMetadata) => {
    return videoMetadata?.renditions?.[0] || null;
  }
);
export const getVideoResultById = s(
  getImageAssets,
  getSubtitles,
  getNormalizedVideoResultsById,
  (imageAssets, subtitles, getNormalizedVideoResultsByIdFn) => (id: any) => {
    const normalizedVideoResult = getNormalizedVideoResultsByIdFn(id);
    if (!normalizedVideoResult) {
      return null;
    }
    return denormalize(
      normalizedVideoResult,
      {
        imageAsset: imageAssets,
        subtitle: subtitles,
      },
      videoResultSchema
    );
  }
);
export const getVideoLibraryResults = s(
  getNormalizedVideoLibraryResults,
  getVideoResultById,
  (getNormalizedVideoLibraryResultsFn, getVideoResultByIdFn) => (endpoint: any) => {
    const videoLibraryResult = getNormalizedVideoLibraryResultsFn(endpoint);
    if (!videoLibraryResult) {
      return null;
    }
    return (videoLibraryResult as any).results.map(getVideoResultByIdFn);
  }
);
export const shouldNotLoadVideoLibraryResults = s(
  getNormalizedVideoLibraryResults,
  getNormalizedVideoLibraryResultsFn => (endpoint: any) => {
    const previousResult = getNormalizedVideoLibraryResultsFn(endpoint);
    if (!previousResult) {
      return false;
    }
    const { loadedAt } = previousResult;
    return loadedAt + 60 * SECONDS > Date.now();
  }
);
const getCurrentSearch = createKeySelector(getVideoLibrary, 'currentSearch', {
  endpoint: '',
  params: modalVideoParams,
});
export const getCurrentSearchResults = s(
  getCurrentSearch,
  getVideoCountBySearchTerm,
  getVideoLibraryResults,
  getVideoResultLoadingById,
  getVideoCountLoadingBySearchTerm,
  (...inputs) => {
    const [
      currentSearch,
      getVideoCountBySearchTermFn,
      getVideoLibraryResultsFn,
      getVideoResultLoadingByIdFn,
      getVideoCountLoadingBySearchTermFn,
    ] = inputs;
    const endpoint = currentSearch.endpoint;
    let params: SearchOption = currentSearch.params;
    const searchTerm = params?.searchTerm;
    let loading = 0;
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    loading += getVideoResultLoadingByIdFn((currentSearch as any).endpoint);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    loading += getVideoCountLoadingBySearchTermFn(searchTerm);
    loading += !params ? 1 : 0;
    const videoCountResponse: VideoCount = getVideoCountBySearchTermFn(searchTerm || '') || { count: 0, offset: '' };
    const offset = videoCountResponse?.offset;
    const count = videoCountResponse.count;
    params = { ...params, ...{ offset } };
    return {
      count,
      results: getVideoLibraryResultsFn((currentSearch as any).endpoint),
      loading,
      params,
      endpoint,
      searchTerm,
    };
  }
);
// TODO (piers) Rename media > asset as part of the renaming PR
export const getActivePreviewMediaId = s(functionRef(componentsSelectors, 'getActiveComponent'), activeComponent => {
  return _.get(activeComponent, ['snap', 'longformVideoAssetId']);
});
export const getActiveVideoResultPreview = s(
  getActivePreviewMediaId,
  getVideoResultById,
  (assetId, getVideoResultByIdFn) => {
    return getVideoResultByIdFn(assetId);
  }
);
export const getVideoPreviewState = s(
  getVideoResultById,
  getActivePreviewMediaId,
  functionRef(mediaSelectors, 'getActiveUploadCountsForComponentIdByPurpose'),
  functionRef(editorSelectors, 'getEditorConfig'),
  (getVideoResultByIdFn, assetId, getActiveUploadCountsForComponentIdByPurposeFn, editorConfig) => (
    componentId: any
  ) => {
    const preview = getVideoResultByIdFn(assetId);
    let previewState;
    if (!preview) {
      previewState = PreviewStates.EMPTY;
    } else if (!preview.complete) {
      previewState = PreviewStates.PROCESSING;
    } else {
      previewState = PreviewStates.COMPLETE;
    }
    if (
      previewState !== PreviewStates.COMPLETE &&
      (getActiveUploadCountsForComponentIdByPurposeFn as any)(componentId, UploadPurpose.LONGFORM_VIDEO)
    ) {
      previewState = PreviewStates.UPLOADING;
    }
    if ((editorConfig as any).replaceLongformVideoPending) {
      if ((editorConfig as any).isSaving) {
        previewState = PreviewStates.SAVING;
      } else {
        previewState = PreviewStates.TEMP_PREVIEW;
      }
    }
    return previewState;
  }
);
export const getVideoSubtitlesByVideoId = s(getVideoResultById, getVideoResultByIdFn => {
  return (assetId: any) => {
    const videoResult = getVideoResultByIdFn(assetId);
    const textTracks = _.get(videoResult, ['textTracks']);
    if (textTracks && textTracks.length > 0) {
      // Return the most recently uploaded subtitle file
      return textTracks[textTracks.length - 1];
    }
    return null;
  };
});
export const subtitleProcessingStatusByVideoId = s(getSubtitleProcessingStatuses, subtitleProcessingStatuses => {
  return (assetId: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return subtitleProcessingStatuses[assetId] || false;
  };
});
