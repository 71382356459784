import { Html5Entities } from 'html-entities';
import is from 'is_js';
import moment from 'moment-timezone';
import { SortDirection } from 'react-virtualized';
import { combineReducers } from 'redux';

import { createSequenceCountingReducer, createSequenceHandlingReducer } from 'state/common/reducerFactories';

import * as analyticsActions from '../actions/analyticsActions';

import {
  BuildType,
  FileType,
  Sequence,
  DEFAULT_ANALYTICS_GEO,
  LocalStorage,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT,
  ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT,
  CheetahStoriesAnalyticsSortableColumns,
} from 'config/constants';
import { creatorAnalytics } from 'utils/apis/localRoutes';
import { constructURL } from 'utils/linkUtils';
import * as assetUtils from 'utils/media/assetUtils';
import { camelCaseKeys, recursiveCamelCaseKeys } from 'utils/objectUtils';

import { versionCounter } from 'views/analytics/containers/AnalyticsDailyView/analyticsVersionCounter';

import { AnalyticsStatsType } from 'types/analytics';

const decoder = new Html5Entities();
function defineFetchClear(fetchAction: any, clearAction: any) {
  return (state = null, action: any) => {
    let replacementState: any = state;
    switch (action.type) {
      case fetchAction:
        switch (action.sequence) {
          case Sequence.START:
            break;
          case Sequence.DONE:
            if (!action.error && action.payload) {
              const payload = camelCaseKeys(action.payload);
              replacementState = {
                ...(replacementState || {}),
                [action.meta.params.analyticsStatsType]: { ...payload },
              };
              break;
            }
            break;
          default:
            break;
        }
        break;
      case clearAction:
        replacementState = null;
        break;
      default:
        break;
    }
    return replacementState;
  };
}
const snaps = defineFetchClear(analyticsActions.FETCH_SNAPS, analyticsActions.CLEAR_SNAPS);
function getCountryCodesFromLocalStorage() {
  try {
    const json = localStorage.getItem(LocalStorage.ANALYTICS_COUNTRY_CODES);
    const obj = json && JSON.parse(json);
    return (obj && obj.countryCodes) || DEFAULT_ANALYTICS_GEO;
  } catch (e) {
    return DEFAULT_ANALYTICS_GEO;
  }
}
// countryCodes could be either 'Global' or string array so we need to wrap it as { countryCodes: newCountryCodes }
function setCountryCodesToLocalStorage(newCountryCodes: any) {
  try {
    localStorage.setItem(LocalStorage.ANALYTICS_COUNTRY_CODES, JSON.stringify({ countryCodes: newCountryCodes }));
  } catch (e) {
    // no-op
  }
}
function countryCodes(state = getCountryCodesFromLocalStorage(), action: any) {
  switch (action.type) {
    case analyticsActions.LOAD_COUNTRY_CODES:
      return getCountryCodesFromLocalStorage();
    case analyticsActions.SET_COUNTRY_CODES:
      if (action.payload !== 'Global' && !Array.isArray(action.payload)) {
        return state;
      }
      return Array.isArray(action.payload) ? [...action.payload] : action.payload;
    case analyticsActions.PERSIST_COUNTRY_CODES:
      setCountryCodesToLocalStorage(state);
      return state;
    default:
      return state;
  }
}
const DEFAULT_EDITION_DATE_RANGE = { from: null, to: null };
function editionDateRange(state = DEFAULT_EDITION_DATE_RANGE, action: any) {
  let nextState = state;
  switch (action.type) {
    case analyticsActions.SET_EDITION_DATE_RANGE:
      nextState = {
        ...action.payload,
      };
      break;
    case analyticsActions.CLEAR_EDITION_DATE_RANGE:
      // @ts-expect-error ts-migrate(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
      nextState = {};
      break;
    default:
      break;
  }
  return nextState;
}
function edition(state = {}, action: any) {
  let nextState = state;
  switch (action.type) {
    case analyticsActions.FETCH_EDITION:
      if (action.sequence === Sequence.DONE && !action.error && action.payload) {
        nextState = {
          ...nextState,
          [action.meta.params.analyticsStatsType]: { ...recursiveCamelCaseKeys(action.payload, {}) },
        };
      }
      break;
    case analyticsActions.CLEAR_EDITION:
      nextState = {};
      break;
    default:
      break;
  }
  return nextState;
}
function edition24Hours(state = {}, action: any) {
  let nextState = state;
  switch (action.type) {
    case analyticsActions.FETCH_EDITION_24_HOURS:
      if (action.sequence === Sequence.DONE && !action.error && action.payload) {
        nextState = {
          ...nextState,
          [action.meta.params.analyticsStatsType]: { ...action.payload },
        };
      }
      break;
    case analyticsActions.CLEAR_EDITION_24_HOURS:
      nextState = {};
      break;
    default:
      break;
  }
  return nextState;
}
function editionDaily(state = {}, action: any) {
  let nextState = state;
  switch (action.type) {
    case analyticsActions.FETCH_EDITION_DAILY:
      if (action.sequence === Sequence.DONE && !action.error && action.payload) {
        nextState = {
          ...nextState,
          [action.meta.params.analyticsStatsType]: {
            editionMetrics: recursiveCamelCaseKeys(action.payload.editionMetrics, {}),
            dailyMetrics: action.payload.dailyMetrics,
          },
        };
      }
      break;
    case analyticsActions.CLEAR_EDITION_DAILY:
      nextState = {};
      break;
    default:
      break;
  }
  return nextState;
}
function editionListLoading(state = 0, action: any) {
  // TODO: we should support loading for all of these
  switch (action.type) {
    case analyticsActions.FETCH_EDITION_LIST:
      switch (action.sequence) {
        case Sequence.START:
          return state + 1;
        case Sequence.DONE:
          return state - 1;
        default:
          return state;
      }
    default:
      return state;
  }
}
export const pollTimeline = createSequenceHandlingReducer({}, analyticsActions.FETCH_POLL_TIMELINE, {
  start(state: any, action: any) {
    return state;
  },
  done(state: any, action: any) {
    let newState = state;
    if (!action.error && action.payload) {
      newState = {
        ...action.payload,
      };
    }
    return newState;
  },
});
export const serviceStatus = createSequenceHandlingReducer({}, analyticsActions.FETCH_SERVICE_STATUS, {
  start(state: any, action: any) {
    return state;
  },
  done(state: any, action: any) {
    let newState = state;
    if (!action.error && action.payload) {
      newState = {
        status: action.payload.serviceStatus,
      };
    }
    return newState;
  },
});
export const publisherEditionHistoryInfo = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  analyticsActions.FETCH_INSIGHTS_REPORT_STORIES_PUBLISHED_INFO,
  {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      let newState = state;
      if (!action.error && action.payload) {
        newState = {
          ...action.payload,
        };
      }
      return newState;
    },
  }
);
function postProcessServerValue(valStr: any) {
  const parsedVal = parseFloat(valStr);
  const newVal = parsedVal === 0 || parsedVal === -1 || Number.isNaN(Number(parsedVal)) ? undefined : valStr;
  return newVal;
}
function daily(state = {}, action: any) {
  let replacementState = state || {};
  switch (action.type) {
    case analyticsActions.FETCH_DAILY:
      switch (action.sequence) {
        case Sequence.START:
          break;
        case Sequence.DONE:
          if (!action.error && action.payload) {
            const { payload, metric, version } = action;
            if (version < versionCounter.version) {
              // Avoid the race condition between old and new requests
              break;
            }
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'dailyMetrics' does not exist on type '{}... Remove this comment to see the full error message
            const { dailyMetrics: oldDailyMetrics = {}, kpiComparison: oldKpiComparison = {} } = replacementState;
            const { dailyMetrics: newDailyMetrics = {}, kpiComparison: newKpiComparison = {} } = payload;
            // A. Deal with Daily Metrics
            if (newDailyMetrics) {
              // @ts-expect-error ts-migrate(2569) FIXME: Type 'Set<string>' is not an array type or a strin... Remove this comment to see the full error message
              const dailyKeys = [...new Set(Object.keys(newDailyMetrics).concat(...Object.keys(oldDailyMetrics)))];
              dailyKeys.forEach(key => {
                // when date entry is missing in new response
                if (metric === 'LOYALTY') {
                  // Preserve only loyalty data fields from LOYALTY query.
                  newDailyMetrics[key] = {
                    ...(oldDailyMetrics[key] || {}),
                    dau_1_day: newDailyMetrics[key]?.dau_1_day,
                    dau_2_days: newDailyMetrics[key]?.dau_2_days,
                    dau_3_4_days: newDailyMetrics[key]?.dau_3_4_days,
                    dau_5_6_7_days: newDailyMetrics[key]?.dau_5_6_7_days,
                  };
                } else {
                  newDailyMetrics[key] = newDailyMetrics[key] || oldDailyMetrics[key];
                }
                const oldEntry = oldDailyMetrics[key] || {};
                const newEntry = newDailyMetrics[key] || {};
                const keys = new Set([...Object.keys(newEntry), ...Object.keys(oldEntry)]);
                keys.forEach(entryKey => {
                  const oldVal = oldEntry[entryKey];
                  const newVal = postProcessServerValue(newEntry[entryKey]);
                  newEntry[entryKey] = newVal || oldVal;
                });
              });
            }
            // B. Deal with KPI
            if (is.object(newKpiComparison)) {
              if (is.object(newKpiComparison.currentRange)) {
                const { currentRange: oldCurrentRange = {} } = oldKpiComparison;
                Object.keys(newKpiComparison.currentRange).forEach(key => {
                  const oldVal = oldCurrentRange[key];
                  const newVal = postProcessServerValue(newKpiComparison.currentRange[key]);
                  newKpiComparison.currentRange[key] = Math.max(oldVal || 0, newVal || 0);
                });
              }
              if (is.object(newKpiComparison.previousRange)) {
                const { previousRange: oldPreviousRange = {} } = oldKpiComparison;
                Object.keys(newKpiComparison.previousRange).forEach(key => {
                  const oldVal = oldPreviousRange[key];
                  const newVal = postProcessServerValue(newKpiComparison.previousRange[key]);
                  newKpiComparison.previousRange[key] = Math.max(oldVal || 0, newVal || 0);
                });
              }
            }
            replacementState = {
              ...payload,
            };
            break;
          }
          break;
        default:
          break;
      }
      break;
    case analyticsActions.CLEAR_DAILY:
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{}'.
      replacementState = null;
      versionCounter.version++;
      break;
    default:
      break;
  }
  return replacementState;
}
const insightsLifeStyleAux = createSequenceHandlingReducer(
  EMPTY_ARRAY,
  analyticsActions.FETCH_INSIGHTS_LIFESTYLE_CATEGORIES,
  {
    start(state: any, action: any) {
      return state || EMPTY_ARRAY;
    },
    done(state: any, action: any) {
      const newState = state || EMPTY_ARRAY;
      if (action.error || !action.payload) {
        return newState;
      }
      const { payload } = action;
      return payload.slcs;
    },
  }
);
function insightsLifeStyle(state = EMPTY_ARRAY, action: any) {
  const newState = state || EMPTY_ARRAY;
  switch (action.type) {
    case analyticsActions.FETCH_INSIGHTS_LIFESTYLE_CATEGORIES:
      return insightsLifeStyleAux(state, action);
    case analyticsActions.CLEAR_INSIGHTS_LIFESTYLE_CATEGORIES:
      return EMPTY_ARRAY;
    default:
      return newState;
  }
}

const fetchInsightsTopPerformingTilesAux = createSequenceHandlingReducer(
  ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT,
  analyticsActions.FETCH_INSIGHTS_TOP_PERFORMING_TILES,
  {
    start(state: any, action: any) {
      return state || ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT;
    },
    done(state: any, action: any) {
      if (action.error || !action.payload) {
        return state || ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT;
      }
      return { ...action.payload };
    },
  }
);
function topPerformingTiles(state = ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT, action: any) {
  const newState = state || ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT;
  switch (action.type) {
    case analyticsActions.FETCH_INSIGHTS_TOP_PERFORMING_TILES:
      return fetchInsightsTopPerformingTilesAux(state, action);
    case analyticsActions.CLEAR_INSIGHTS_TOP_PERFORMING_TILES:
      return ANALYTICS_INSIGHTS_TOP_PERFORMING_TILES_DEFAULT;
    default:
      return newState;
  }
}
const fetchInsightsTopPerformingStoriesAux = createSequenceHandlingReducer(
  ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT,
  analyticsActions.FETCH_INSIGHTS_TOP_PERFORMING_STORIES,
  {
    start(state: any, action: any) {
      return state || ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT;
    },
    done(state: any, action: any) {
      if (action.error || !action.payload) {
        return state || ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT;
      }
      return { ...action.payload };
    },
  }
);
function topPerformingStories(state = ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT, action: any) {
  const newState = state || ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT;
  switch (action.type) {
    case analyticsActions.FETCH_INSIGHTS_TOP_PERFORMING_STORIES:
      return fetchInsightsTopPerformingStoriesAux(state, action);
    case analyticsActions.CLEAR_INSIGHTS_TOP_PERFORMING_STORIES:
      return ANALYTICS_INSIGHTS_TOP_PERFORMING_STORIES_DEFAULT;
    default:
      return newState;
  }
}

const insights = combineReducers({
  slcs: insightsLifeStyle,
  topPerformingTiles,
  topPerformingStories,
});
function storyList(state = [], action: any) {
  let replacementState = state;
  switch (action.type) {
    case analyticsActions.CHEETAH_FETCH_STORY_LIST:
      if (!action.error && action.payload) {
        replacementState = [];
        const { hostUsername } = action.meta.params;
        const payload = camelCaseKeys(action.payload);
        const storyKeys = is.object(payload) ? Object.keys(payload).reverse() : EMPTY_ARRAY;
        storyKeys.forEach(storyId => {
          if (!payload[storyId]) {
            return;
          }
          const storyInformation = camelCaseKeys(payload[storyId]);
          const { metadata } = storyInformation;
          const params = {
            editionId: storyId,
            hostUsername,
          };
          let postTime = null;
          if (is.string(metadata.postTimePt) && metadata.postTimePt) {
            postTime = moment(metadata.postTimePt);
          } else {
            postTime = moment();
          }
          metadata.postTime = postTime;
          metadata.analyticsURL = constructURL(creatorAnalytics.storyAnalytics.path, { params });
          if (!is.string(metadata.headline) || !metadata.headline) {
            metadata.headline = '';
          } else {
            metadata.headline = decoder.decode(metadata.headline);
          }
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          replacementState.push(storyInformation);
        });
        if (!replacementState.length) {
          replacementState = EMPTY_ARRAY;
        }
      } else {
        replacementState = EMPTY_ARRAY;
      }
      break;
    case analyticsActions.CHEETAH_CLEAR_STORY_LIST:
      replacementState = EMPTY_ARRAY;
      break;
    default:
      break;
  }
  return replacementState;
}
function editionList(state = EMPTY_ARRAY, action: any) {
  let replacementState = state;
  switch (action.type) {
    case analyticsActions.FETCH_EDITION_LIST:
      if (!action.error && action.payload) {
        replacementState = [];
        const { hostUsername } = action.params;
        const payload = camelCaseKeys(action.payload);
        const editionKeys = is.object(payload) ? Object.keys(payload) : EMPTY_ARRAY;
        editionKeys.forEach(editionId => {
          if (!payload[editionId]) {
            return;
          }
          const editionInformation = camelCaseKeys(payload[editionId]);
          const params = {
            editionId,
            hostUsername,
          };
          let postTime = null;
          let postTimeMilli = null;
          if (is.string(editionInformation.postTimePt) && editionInformation.postTimePt) {
            postTime = moment(editionInformation.postTimePt);
            postTimeMilli = editionInformation.postTimeMilli;
          } else {
            postTime = moment();
            postTimeMilli = postTime.valueOf();
          }
          editionInformation.postTime = postTime;
          editionInformation.postTimeMilli = postTimeMilli;
          editionInformation.analyticsURL = constructURL(creatorAnalytics.storyAnalytics.path, { params });
          if (!is.string(editionInformation.headline) || !editionInformation.headline) {
            editionInformation.headline = '';
          } else {
            editionInformation.headline = decoder.decode(editionInformation.headline);
          }
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          replacementState.push(editionInformation);
        });
        if (!replacementState.length) {
          replacementState = EMPTY_ARRAY;
        }
      } else {
        replacementState = EMPTY_ARRAY;
      }
      break;
    case analyticsActions.CLEAR_EDITION_LIST:
      replacementState = EMPTY_ARRAY;
      break;
    default:
      break;
  }
  return replacementState.sort((editionA, editionB) => {
    return (editionB as any).postTimeMilli - (editionA as any).postTimeMilli;
  });
}
function processedSnaps(state = [], action: any) {
  let nextState = state;
  switch (action.type) {
    case analyticsActions.FETCH_SNAPS: {
      if (action.sequence !== Sequence.DONE) {
        break;
      }
      if (!action.payload || action.error) {
        break;
      }
      const payload = camelCaseKeys(action.payload);
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      nextState = Object.values(payload)
        .map(snap => {
          if (!snap) {
            return {};
          }
          const remappedSnap = camelCaseKeys(snap);
          remappedSnap.snapIndex = parseInt(remappedSnap.snapIndex, 10);
          return remappedSnap;
        })
        .filter(snap => is.number(snap.snapIndex))
        .sort((a, b) => a.snapIndex - b.snapIndex)
        .map((snap, index) => {
          const snapCopy = {
            mediaType: undefined,
            overlayMediaSrc: undefined,
            src: undefined,
            longformMediaType: undefined,
            ...snap,
            snapIndexWithoutAds: index + 1,
          };
          snapCopy.mediaType = snapCopy.mediaType ? snapCopy.mediaType.toLowerCase() : '';
          const buildTypeId = snapCopy.mediaType === FileType.VIDEO ? BuildType.VIDEO_PREVIEW : BuildType.IMAGE_PREVIEW;
          snapCopy.src = assetUtils.getRichSnapAssetUrl(snap.mediaId, { buildTypeId });
          if (snap.overlayMediaId) {
            snapCopy.overlayMediaSrc = assetUtils.getImagePreviewUrl(snap.overlayMediaId);
          }
          if (snapCopy.longformMediaType) {
            snapCopy.longformMediaType = snapCopy.longformMediaType.toLowerCase();
          }
          return snapCopy;
        });
      break;
    }
    case analyticsActions.CLEAR_SNAPS:
      nextState = [];
      break;
    default:
      break;
  }
  return nextState;
}
const sortedColumn = (state = CheetahStoriesAnalyticsSortableColumns.STATUS, action: any) => {
  switch (action.type) {
    case analyticsActions.SET_SORTED_COLUMN:
      return action.payload.sortCriteria;
    default:
      return state;
  }
};
const sortOrder = (state = SortDirection.ASC, action: any) => {
  switch (action.type) {
    case analyticsActions.SET_SORT_ORDER:
      return action.order;
    default:
      return state;
  }
};
const analyticsStatsType = (state = AnalyticsStatsType.ALL, action: any) => {
  switch (action.type) {
    case analyticsActions.SET_ANALYTICS_STATS_TYPE:
      return action.analyticsStatsType;
    default:
      return state;
  }
};
// ------------------------------------
// Reducer
// ------------------------------------
export default combineReducers({
  daily,
  insights,
  editionList,
  storyList,
  edition,
  edition24Hours,
  editionDaily,
  snaps,
  processedSnaps,
  editionListLoading,
  storyListLoading: createSequenceCountingReducer(analyticsActions.CHEETAH_FETCH_STORY_LIST),
  pollTimeline,
  serviceStatus,
  countryCodes,
  editionDateRange,
  sortedColumn,
  sortOrder,
  publisherEditionHistoryInfo,
  analyticsStatsType,
});
