export const CREATE_STORY = 'editions/CREATE_STORY';
export const GET_EDITION_SNAP_IDS = 'editions/GET_EDITION_SNAP_IDS';
export const SET_EDITION_SNAP_IDS = 'editions/SET_EDITION_SNAP_IDS';
export const SAVE_EDITION = 'editions/SAVE_EDITION';
export const SET_EDITION_PROPERTIES = 'editions/SET_EDITION_PROPERTIES';
export const COPY_SNAP_TO_EDITION = 'editions/COPY_SNAP_TO_EDITION';
export const SET_EDITION_STATE = 'editions/SET_EDITION_STATE';
export const BLOCK_EDITION_EDITING = 'editions/BLOCK_EDITION_EDITING';
export const COPY_STORY = 'editions/COPY_STORY';
export const SET_NEW_STORY_INFO = 'editions/SET_NEW_STORY_INFO';
export const GET_EDITION = 'editions/GET_EDITION';
export const MAKE_STORIES_UNAVAILABLE = 'editions/MAKE_STORIES_UNAVAILABLE';
export const DISCARD_LIVE_EDIT_CHANGES = 'editions/DISCARD_LIVE_EDIT_CHANGES';
export const SET_SELECTED_AD_ACCOUNT_ID = 'editions/SET_SELECTED_AD_ACCOUNT_ID';
