export const checkExceededMaxLength = (value: string | undefined | null, maxLength: number) => {
  if (maxLength && value) {
    return Number(value.length) > maxLength;
  }
  return false;
};

export const validateMinMaxValue = (minValue: number, maxValue: number) => (value: string) => {
  if (value) {
    return parseInt(value, 10) >= minValue && parseInt(value, 10) <= maxValue;
  }
  return false;
};
