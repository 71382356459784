import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { getActivePublisherBusinessProfileId } from 'state/publishers/selectors/publishersSelectors';

import { trending } from 'utils/apis/trendingAPI';
import { enumObject } from 'utils/enum';

import type { GetState } from 'types/redux';

export type GetTopics = 'trending/GET_TOPICS';

export const TrendingActionType: {
  GET_TOPICS: GetTopics;
} = enumObject({
  GET_TOPICS: 'trending/GET_TOPICS',
});

export function getTopics({
  limit,
  lookbackHours,
}: {
  limit: number;
  lookbackHours: number;
}): (b: Dispatch, a: GetState) => Promise<unknown> {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    // @ts-expect-error ts-migrate(2739) FIXME: Type 'CallAction<{ type: "trending/GET_TOPICS"; }>... Remove this comment to see the full error message
    return dispatch(
      createCallAction(
        {
          type: TrendingActionType.GET_TOPICS,
        },
        {
          method: 'POST',
          endpoint: trending.topics({
            businessProfileId: getActivePublisherBusinessProfileId(getState()),
          }),
          body: {
            limit,
            lookbackHours,
          },
        }
      )
    );
  };
}
