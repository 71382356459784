import React from 'react';
import type { ReactNode } from 'react';

import { isMobile } from 'utils/platform/platformUtils';

export const Platforms = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
};

export type PlatformsEnum = typeof Platforms[keyof typeof Platforms];

export const PlatformContext = React.createContext<PlatformsEnum>(Platforms.DESKTOP);

type Props = {
  children?: ReactNode;
};

export class PlatformProvider extends React.PureComponent<Props> {
  render() {
    return (
      <PlatformContext.Provider value={isMobile() ? Platforms.MOBILE : Platforms.DESKTOP}>
        {this.props.children}
      </PlatformContext.Provider>
    );
  }
}

export const PlatformConsumer = PlatformContext.Consumer;
