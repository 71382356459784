/* eslint-disable spaced-comment, no-redeclare */
import { connectRouter } from 'connected-react-router';
import type { History } from 'history';
import { combineReducers } from 'redux';

import actions from 'state/actions/reducers/actionsReducers';
import analytics from 'state/analytics/reducers/analyticsReducers';
import article from 'state/article/reducers/articleReducers';
import asset from 'state/asset/reducers/assetReducers';
import attachments from 'state/attachments/reducers/attachmentsReducers';
import auth from 'state/auth/reducers/authReducers';
import autocomplete from 'state/autocomplete/reducers/autocompleteReducers';
import buildStatus from 'state/buildStatus/reducers/buildStatusReducers';
import debug from 'state/debug/debugReducers';
import editions from 'state/editions/reducers/editionsReducers';
import editor from 'state/editor/reducers/editorReducers';
import episodes from 'state/episodes/reducers/episodesReducers';
import extras from 'state/extras/reducers/extrasReducers';
import features from 'state/features/admin/reducers/featuresReducers';
import feeds from 'state/feeds/reducers/feedsReducers';
import homepage from 'state/homepage/reducers/homepageReducers';
import inventory from 'state/inventory/reducers/inventoryReducers';
import media from 'state/media/reducers/mediaReducers';
import mediaLibrary from 'state/mediaLibrary/reducers/mediaLibraryReducers';
import mediaLibraryTray from 'state/mediaLibrary/reducers/mediaLibraryTrayReducers';
import modals from 'state/modals/reducers/modalsReducers';
import navLayout from 'state/navLayout/reducers/navLayoutReducers';
import notifications from 'state/notifications/reducers/notificationsReducers';
import orgOnboardings from 'state/orgOnboardings/reducers/orgOnboardingsReducers';
import organisations from 'state/organisations/reducers/organisationsReducers';
import previews from 'state/previews/reducers/previewsReducers';
import publisherStoryEditor from 'state/publisherStoryEditor/reducers/publisherStoryEditorReducers';
import publisherUserManagement from 'state/publisherUserManagement/reducers/publisherUserManagementReducers';
import publishers from 'state/publishers/reducers/publishersReducers';
import * as rootActions from 'state/root/actions/rootActions';
import extraRouter from 'state/router/reducers/extraRouterReducers';
import seasons from 'state/seasons/reducers/seasonsReducers';
import segments from 'state/segments/reducers/segmentsReducers';
import shows from 'state/shows/reducers/showsReducers';
import singleAssetStories from 'state/singleAssetStory/reducers/singleAssetStoryReducers';
import snapAdmin from 'state/snapAdmin/reducers/snapAdminReducers';
import snapcodes from 'state/snapcodes/reducers/snapcodesReducers';
import snaps from 'state/snaps/reducers/snapsReducers';
import stages from 'state/stages/reducers/stagesReducers';
import storySnaps from 'state/storySnaps/reducers/storySnapsReducers';
import subscriptions from 'state/subscriptions/reducers/subscriptionsReducers';
import subtitles from 'state/subtitles/reducers/subtitlesReducers';
import supportTickets from 'state/supportTickets/reducers/supportTicketsReducers';
import tiles from 'state/tiles/reducers/tilesReducers';
import transactions from 'state/transactions/reducers/transactionsReducers';
import trending from 'state/trending/reducers/trendingReducers';
import user from 'state/user/reducers/userReducers';
import videoLibrary from 'state/videoLibrary/reducers/videoLibraryReducers';

const appReducer = (history: History) =>
  combineReducers({
    actions,
    analytics,
    article,
    asset,
    attachments,
    auth,
    autocomplete,
    buildStatus,
    debug,
    editions,
    editor,
    episodes,
    extraRouter,
    extras,
    features,
    feeds,
    homepage,
    inventory,
    mediaLibrary,
    mediaLibraryTray,
    media,
    modals,
    navLayout,
    notifications,
    organisations,
    orgOnboardings,
    previews,
    publishers,
    publisherStoryEditor,
    publisherUserManagement, // Deals with a publisher's users management
    router: connectRouter(history),
    seasons,
    segments,
    shows,
    singleAssetStories,
    snapAdmin,
    snapcodes,
    snaps,
    stages,
    subscriptions,
    subtitles,
    supportTickets,
    tiles,
    transactions,
    trending,
    storySnaps,
    user,
    videoLibrary,
  });

const rootReducer = (history: History) => {
  const reducer = appReducer(history);
  return (state: any, action: any) => {
    let updatedState = state;

    // We should preserve the router information as it stores what is the last url the website was pointing to
    // Resetting it will cause unexpected behavior. We also preserve locale information.
    if (action.type === rootActions.RESET_STORE) {
      updatedState = {
        router: state.router,
        user: {
          locale: state.user.locale,
        },
      };
    }

    return reducer(updatedState, action);
  };
};

export default rootReducer;
