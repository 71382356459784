import { omit } from 'lodash';
import React from 'react';
import type { ReactNode, ComponentType } from 'react';

import { isMobile } from 'utils/platform/platformUtils';

type Props = {
  mobile: ComponentType<any>;
  desktop: ComponentType<any>;
  children?: ReactNode | null;
};

export class PlatformRenderer extends React.Component<Props> {
  render() {
    const { mobile, desktop, children } = this.props;
    const componentProps = omit(this.props, ['mobile', 'desktop', 'children']);

    if (isMobile()) {
      return React.createElement(mobile, componentProps, children);
    }

    return React.createElement(desktop, componentProps, children);
  }
}

export default PlatformRenderer;
