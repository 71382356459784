import { get } from 'lodash';

import { IdPropertyByTileContainer } from 'config/constants';
import type { IdPropertyByTileContainerEnum } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { SegmentReduxID } from 'types/segments';
import type { Tile, TileID } from 'types/tiles';

export type ContainerId = EditionID | SnapId | SegmentReduxID;
export type TileContainer = {
  editionId?: EditionID | null;
  snapId?: SnapId | null;
  segmentId?: SegmentReduxID | null;
};
export function createTileContainer(
  editionId?: EditionID | null,
  snapId?: SnapId | null,
  segmentId?: SegmentReduxID | null
) {
  const container = {};
  if (editionId) {
    (container as any).editionId = editionId;
  }
  if (segmentId) {
    (container as any).segmentId = segmentId;
  }
  if (snapId) {
    (container as any).snapId = snapId;
  }
  return container;
}
export function getTileIdOrNull(tile: Tile | undefined | null): TileID | undefined | null {
  return tile?.id || null;
}
export function getPendingHeadline(pendingTile?: Tile | null): string | undefined | null {
  const headline = get(pendingTile, 'headline');
  return typeof headline === 'string' ? headline.trim() : null;
}
export function getTileId(tile: Tile): TileID {
  const tileId = getTileIdOrNull(tile);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileId).is.string();
  // Keep flow happy
  if (!tileId) {
    throw new Error('tileId should exist');
  }
  return tileId;
}
export function isCropping(tile?: Tile | null): boolean {
  // judge whether the tile exist
  if (!tile || !tile.baseImageAssetId) {
    return false;
  }
  // then judge isCropping based on the baseImageAssetId and croppedImageAssetId relations
  if (!get(tile, 'croppedImageAssetId')) {
    return true;
  }
  return false;
}
export const describeTileContainer = (
  tileContainer: TileContainer
): {
  container: {
    [k in IdPropertyByTileContainerEnum]: ContainerId;
  };
  containerIdProperty: IdPropertyByTileContainerEnum;
} => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileContainer).is.object();
  let containerIdProperty;
  if (tileContainer.segmentId) {
    containerIdProperty = IdPropertyByTileContainer.SEGMENT;
  } else if (tileContainer.snapId) {
    containerIdProperty = IdPropertyByTileContainer.SNAP;
  } else {
    containerIdProperty = IdPropertyByTileContainer.EDITION;
  }
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(containerIdProperty).is.string();
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const containerId: ContainerId | undefined | null = tileContainer[containerIdProperty];
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(containerId).is.number.or.is.string();
  // Keep flow happy
  if (!containerId) {
    throw new Error('containerId is not valid');
  }
  return { container: { [containerIdProperty]: containerId }, containerIdProperty };
};
export const isHappeningNowHeadlineTruncated = (tile?: Tile | null) => {
  if (!tile || !tile.headline) {
    return false;
  }
  return tile.headline.length >= 90;
};
export function getSelectableLocalizations(tile: Tile | undefined | null, primaryLanguage: string) {
  if (!tile) {
    return [];
  }
  const tileLocalizations = get(tile, 'tileLocalizations') || [];
  if (tileLocalizations.length === 0) {
    return [];
  }
  return [
    {
      language: primaryLanguage,
      headline: get(tile, 'headline') || '',
    },
    ...tileLocalizations,
  ];
}
