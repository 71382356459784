import { map } from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';

import SDSTile from 'views/common/components/SDSTile/SDSTile';

import style from './SDSTilesGroup.scss';

type TileParams = {
  key: any;
  selected: boolean;
  callback: () => void;
  text: string | ReactNode;
  disabled?: boolean;
  icon?: ReactNode;
  'data-test'?: string;
};

type Props = {
  tiles: Array<TileParams>;
  disabled?: boolean;
};

export default class SDSTilesGroup extends React.PureComponent<Props> {
  render() {
    const { tiles, disabled } = this.props;

    return (
      <div className={style.tileGroupContainer}>
        {map(tiles, tile => (
          <div
            className={style.tileContainer}
            key={String(tile.key)}
            data-test={tile.key ? `SDSTile.tile.${tile.key}` : 'SDSTile.tile'}
          >
            <SDSTile
              title={tile.text}
              type="radio"
              handleClick={tile.callback}
              selected={tile.selected}
              key={String(tile.key)}
              disabled={disabled || tile.disabled}
            />
          </div>
        ))}
      </div>
    );
  }
}
