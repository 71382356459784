import moment from 'moment-timezone';
import type { Dispatch } from 'redux';

import { getSortedAllStories } from 'state/analytics/selectors/storiesAnalyticsSelectors';
import * as apiMiddlewareActions from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { loadSnapPreviewIfMissing } from 'state/previews/actions/previewsActions';
import { loadSnapsIfRequired } from 'state/snaps/actions/snapsActions';
import { getActiveCreatorHostUsername } from 'state/user/selectors/userSelectors';
import type { UserId } from 'state/user/userState';

import type { CheetahStoriesAnalyticsSortableColumnsEnum } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer, standardJsonFinalizers } from 'redux/middleware/requestProcessing';
import * as discoverAPI from 'utils/apis/discoverAPI';
import * as proxyAPI from 'utils/apis/proxyAPI';

import { AnalyticsStatsTypeEnum } from 'types/analytics';
import type { GetState } from 'types/redux';

// ------------------------------------
// Constants
// ------------------------------------
export const FETCH_DAILY = 'analytics/FETCH_DAILY';
export const CLEAR_DAILY = 'analytics/CLEAR_DAILY';
export const FETCH_INSIGHTS_LIFESTYLE_CATEGORIES = 'analytics/FETCH_INSIGHTS_LIFESTYLE_CATEGORIES';
export const CLEAR_INSIGHTS_LIFESTYLE_CATEGORIES = 'analytics/CLEAR_INSIGHTS_LIFESTYLE_CATEGORIES';
export const FETCH_INSIGHTS_TOP_PERFORMING_TILES = 'analytics/FETCH_INSIGHTS_TOP_PERFORMING_TILES';
export const CLEAR_INSIGHTS_TOP_PERFORMING_TILES = 'analytics/CLEAR_INSIGHTS_TOP_PERFORMING_TILES';
export const FETCH_INSIGHTS_TOP_PERFORMING_STORIES = 'analytics/FETCH_INSIGHTS_TOP_PERFORMING_STORIES';
export const CLEAR_INSIGHTS_TOP_PERFORMING_STORIES = 'analytics/CLEAR_INSIGHTS_TOP_PERFORMING_STORIES';
export const FETCH_INSIGHTS_REPORT_STORIES_PUBLISHED_INFO = 'analytics/FETCH_INSIGHTS_REPORT_STORIES_PUBLISHED_INFO';
export const FETCH_EDITION = 'analytics/FETCH_EDITION';
export const CLEAR_EDITION = 'analytics/CLEAR_EDITION';
export const FETCH_EDITION_24_HOURS = 'analytics/FETCH_EDITION_24_HOURS';
export const FETCH_SERVICE_STATUS = 'analytics/FETCH_SERVICE_STATUS';
export const CLEAR_EDITION_24_HOURS = 'analytics/CLEAR_EDITION_24_HOURS';
export const FETCH_EDITION_DAILY = 'analytics/FETCH_EDITION_DAILY';
export const CLEAR_EDITION_DAILY = 'analytics/CLEAR_EDITION_DAILY';
export const FETCH_EDITION_LIST = 'analytics/FETCH_EDITION_LIST';
export const CLEAR_EDITION_LIST = 'analytics/CLEAR_EDITION_LIST';
export const CHEETAH_FETCH_STORY_LIST = 'analytics/CHEETAH_FETCH_STORY_LIST';
export const CHEETAH_CLEAR_STORY_LIST = 'analytics/CHEETAH_CLEAR_STORY_LIST';
export const FETCH_SNAPS = 'analytics/FETCH_SNAPS';
export const CLEAR_SNAPS = 'analytics/CLEAR_SNAPS';
export const FETCH_POLL_TIMELINE = 'analytics/FETCH_POLL_TIMELINE';
export const LOAD_COUNTRY_CODES = 'analytics/LOAD_COUNTRY_CODES';
export const SET_COUNTRY_CODES = 'analytics/SET_COUNTRY_CODES';
export const SET_EDITION_DATE_RANGE = 'analytics/SET_EDITION_DATE_RANGE';
export const CLEAR_EDITION_DATE_RANGE = 'analytics/CLEAR_EDITION_DATE_RANGE';
export const PERSIST_COUNTRY_CODES = 'analytics/PERSIST_PICKED_COUNTRY_CODES';
export const SET_SORTED_COLUMN = 'analytics/SET_SORTED_COLUMN';
export const SET_SORT_ORDER = 'analytics/SET_SORT_ORDER';
export const SET_ANALYTICS_STATS_TYPE = 'analytics/SET_ANALYTICS_STATS_TYPE';

// ------------------------------------
// Actions
// ------------------------------------

type PublisherID = number;

type EditionID = number;
type Countries = unknown;

type DailyStatsOptions = {
  DailyStatsOptions: unknown;
  countries: Countries;
  endDate: Date;
  metric: unknown;
  publisherId: PublisherID;
  region: string;
  startDate: Date;
  type: unknown;
  version: number;
};

export const loadCountryCodes = () => ({
  type: LOAD_COUNTRY_CODES,
});

export const setCountryCodes = (newCountryCodes: any) => ({
  type: SET_COUNTRY_CODES,
  payload: newCountryCodes,
});

export const persistCountryCodes = () => ({
  type: PERSIST_COUNTRY_CODES,
});

export const fetchServiceStatus = (): ((b: Dispatch, a: GetState) => Promise<unknown>) => {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const endpointFunc = discoverAPI.analyticsV4.analyticsServiceStatus;
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ type: string; meta: { "@api/CALL_API": { e... Remove this comment to see the full error message
    return dispatch({
      type: FETCH_SERVICE_STATUS,
      meta: {
        [CALL_API]: {
          endpoint: endpointFunc(),
          finalizer: optimisticJsonFinalizer,
        },
      },
    });
  };
};

export const fetchDailyStats = ({
  publisherId,
  startDate,
  endDate,
  type,
  countries,
  metric,
  version,
}: Partial<DailyStatsOptions>): ((b: Dispatch, a: GetState) => Promise<unknown>) => {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const endpointFunc = discoverAPI.analyticsV4.analyticsDaily;
    // @ts-expect-error ts-migrate(2739) FIXME: Type '{ type: string; version: number | undefined;... Remove this comment to see the full error message
    return dispatch({
      type: FETCH_DAILY,
      version,
      metric,
      meta: {
        [CALL_API]: {
          endpoint: endpointFunc({
            publisherId,
            startDate,
            endDate,
            type,
            countries,
            metric,
          }),
          finalizer: optimisticJsonFinalizer,
        },
      },
    });
  };
};

export const clearDailyStats = () => ({
  type: CLEAR_DAILY,
});

type InsightsLifestyleCategoriesStatsOptions = {
  publisherId: PublisherID;
  countries: Countries;
};

export const fetchInsightsLifestyleCategoryStats = ({
  publisherId,

  countries,
}: InsightsLifestyleCategoriesStatsOptions) => (dispatch: Dispatch, getState: GetState) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsInsightsSLCS;

  return dispatch({
    type: FETCH_INSIGHTS_LIFESTYLE_CATEGORIES,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({
          publisherId,
          countries,
        }),
        finalizer: optimisticJsonFinalizer,
      },
    },
  });
};

export const clearInsightsLifestyleCategoryStats = () => ({
  type: CLEAR_INSIGHTS_LIFESTYLE_CATEGORIES,
});

type InsightsTopPerformingTilesStatsOptions = {
  publisherId: PublisherID;
};

export const fetchInsightsTopPerformingTilesStats = ({ publisherId }: InsightsTopPerformingTilesStatsOptions) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsInsightsTopPerformingTiles;

  return dispatch({
    type: FETCH_INSIGHTS_TOP_PERFORMING_TILES,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({
          publisherId,
        }),
        finalizer: optimisticJsonFinalizer,
      },
    },
  });
};

export const clearInsightsTopPerformingTilesStats = () => ({
  type: CLEAR_INSIGHTS_TOP_PERFORMING_TILES,
});

type InsightsTopPerformingStoriesStatsOptions = {
  publisherId: PublisherID;
  geoType: string;
  countries: Countries;
  startDate: Date | string;
  endDate: Date | string;
};

export const fetchInsightsTopPerformingStoriesStats = ({
  publisherId,
  geoType,
  countries,
  startDate,

  endDate,
}: InsightsTopPerformingStoriesStatsOptions) => (dispatch: Dispatch, getState: GetState) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsInsightsTopPerformingStories;

  // AnalyticsV4 endpoint does not take country info
  const params = { publisherId, startDate, endDate };

  return dispatch({
    type: FETCH_INSIGHTS_TOP_PERFORMING_STORIES,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc(params),
        finalizer: standardJsonFinalizers,
      },
    },
  });
};

export const clearInsightsTopPerformingStoriesStats = () => ({
  type: CLEAR_INSIGHTS_TOP_PERFORMING_STORIES,
});

type EditionDateRangeOptions = {
  from: moment.Moment | undefined | null;
  to: moment.Moment | undefined | null;
};

export const setEditionDateRange = (newDateRange: EditionDateRangeOptions) => ({
  type: SET_EDITION_DATE_RANGE,
  payload: newDateRange,
});

export const clearEditionDateRange = () => ({
  type: CLEAR_EDITION_DATE_RANGE,
});

type EditionStatsOptions = {
  geoType: string;
  countries: Countries;
  editionId: EditionID;
  endDate: Date;
  publisherId: PublisherID;
  startDate: Date;
  timeZone: string;
  analyticsStatsType: AnalyticsStatsTypeEnum;
};

export const fetchEditionStats = (editionId: EditionID, analyticsStatsType: AnalyticsStatsTypeEnum) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsEdition;

  return dispatch({
    type: FETCH_EDITION,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({ editionId, analyticsStatsType }),
        finalizer: optimisticJsonFinalizer,
      },
      params: { analyticsStatsType },
    },
  });
};

export const fetchEditionStatsByCountries = (
  editionId: number,
  countries: string | string[],
  analyticsStatsType: AnalyticsStatsTypeEnum
) => async (dispatch: Dispatch, getState: GetState) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsEditionByCountries;

  return dispatch({
    type: FETCH_EDITION,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({ editionId, countries, analyticsStatsType }),
        finalizer: optimisticJsonFinalizer,
      },
      params: { analyticsStatsType },
    },
  });
};

export const clearEditionStats = () => ({
  type: CLEAR_EDITION,
});

type Edition24HourStatsOptions = {
  geoType: string;
  countries: Countries;
  editionId: EditionID;
  startDate: Date | string;
  analyticsStatsType: AnalyticsStatsTypeEnum;
};

export const fetchEdition24HoursStats = ({
  editionId,
  startDate,
  countries,
  geoType,
  analyticsStatsType,
}: Edition24HourStatsOptions) => async (dispatch: Dispatch, getState: GetState) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsEdition24Hours;

  return dispatch({
    type: FETCH_EDITION_24_HOURS,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({
          editionId,
          date: startDate,
          countries,
          geoType,
          analyticsStatsType,
        }),
        finalizer: optimisticJsonFinalizer,
      },
      params: { analyticsStatsType },
    },
  });
};

export const clearEdition24HoursStats = () => ({
  type: CLEAR_EDITION_24_HOURS,
});

type EditionDailyStatsOptions = {
  geoType: string;
  countries: Countries;
  editionId: EditionID;
  endDate: Date | string;
  startDate: Date | string;
  analyticsStatsType: AnalyticsStatsTypeEnum;
};

export const fetchEditionDailyStats = ({
  editionId,
  startDate,
  endDate,
  countries,
  geoType,
  analyticsStatsType,
}: EditionDailyStatsOptions) => async (dispatch: Dispatch, getState: GetState) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsEditionDaily;

  return dispatch({
    type: FETCH_EDITION_DAILY,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({
          editionId,
          startDate,
          endDate,
          countries,
          geoType,
          analyticsStatsType,
        }),
        finalizer: optimisticJsonFinalizer,
      },
      params: { analyticsStatsType },
    },
  });
};

export const clearEditionDailyStats = () => ({
  type: CLEAR_EDITION_DAILY,
});

export const fetchEditionList = ({
  endDate,
  publisherId,
  startDate,
  analyticsStatsType,
}: Partial<EditionStatsOptions>) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const endpointFunc = discoverAPI.analyticsV4.analyticsCalendar;
    const hostUsername = getActiveCreatorHostUsername(getState());
    return dispatch(
      apiMiddlewareActions.createCallAction(
        {
          type: FETCH_EDITION_LIST,
          params: { endDate, publisherId, startDate, analyticsStatsType, hostUsername },
        },
        {
          method: 'GET',
          endpoint: endpointFunc({ endDate, publisherId, startDate, analyticsStatsType }),
          finalizer: optimisticJsonFinalizer,
        }
      )
    );
  };
};

export const sortStories = (column: CheetahStoriesAnalyticsSortableColumnsEnum) => {
  return {
    type: SET_SORTED_COLUMN,
    payload: {
      sortCriteria: column,
    },
  };
};

export const setSortOrder = (order: string) => {
  return {
    type: SET_SORT_ORDER,
    order,
  };
};

export const setAnalyticsStatsType = (analyticsStatsType: AnalyticsStatsTypeEnum) => {
  return {
    type: SET_ANALYTICS_STATS_TYPE,
    analyticsStatsType,
  };
};

export const fetchStorySummaryList = ({
  endDate,
  publisherId,
  startDate,
  analyticsStatsType,
}: Partial<EditionStatsOptions>) => async (dispatch: Dispatch, getState: GetState) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsStorySummary;
  const hostUsername = getActiveCreatorHostUsername(getState());
  return dispatch({
    type: CHEETAH_FETCH_STORY_LIST,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({ endDate, publisherId, startDate, analyticsStatsType }),
        finalizer: standardJsonFinalizers,
      },
      params: { endDate, publisherId, startDate, analyticsStatsType, hostUsername },
    },
  });
};

export const fetchStorySummaryListAndFirstSnapPreview = (params: Partial<EditionStatsOptions>) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
  await dispatch(fetchStorySummaryList(params));
  const stories = getSortedAllStories(getState());

  if (stories.length <= 0) {
    return Promise.resolve([]);
  }

  const firstSnapIds = stories.map(story => story.metadata.firstSnapId);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => any' is ... Remove this comment to see the full error message
  await dispatch(loadSnapsIfRequired({ snapIds: firstSnapIds }));
  return Promise.all(
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
    firstSnapIds.map(snapId => dispatch(loadSnapPreviewIfMissing(snapId)))
  );
};

export const clearEditionList = () => ({
  type: CLEAR_EDITION_LIST,
});

export const clearStoryList = () => ({
  type: CHEETAH_CLEAR_STORY_LIST,
});

type SnapStatsOptions = {
  countries: Countries;
  editionId: EditionID;
  geo: unknown;
  geoType: unknown;
  userId: UserId;
  analyticsStatsType: AnalyticsStatsTypeEnum;
};

export const fetchSnapStats = ({
  editionId,
  userId,
  geoType,
  countries,
  analyticsStatsType,
}: SnapStatsOptions) => async (dispatch: Dispatch, getState: GetState) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsSnaps;

  return dispatch({
    type: FETCH_SNAPS,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({ editionId, geoType, countries, analyticsStatsType }),
        finalizer: optimisticJsonFinalizer,
      },
      params: { editionId, userId, analyticsStatsType },
    },
  });
};

export const clearSnapStats = () => ({
  type: CLEAR_SNAPS,
});

type PollTimelineOptions = {
  editionId: EditionID;
};

export const fetchPollTimeline = ({ editionId }: PollTimelineOptions) => async (
  dispatch: Dispatch,
  getState: GetState
) => {
  const endpointFunc = discoverAPI.analyticsV4.analyticsPollTimelines;

  return dispatch({
    type: FETCH_POLL_TIMELINE,
    meta: {
      [CALL_API]: {
        endpoint: endpointFunc({ editionId }),
        finalizer: optimisticJsonFinalizer,
      },
      params: { editionId },
    },
  });
};

type PublisherEditionHistoryInfoOptions = {
  publisherId: PublisherID;
};

export const fetchPublisherEditionHistoryInfo = ({ publisherId }: PublisherEditionHistoryInfoOptions) => ({
  type: FETCH_INSIGHTS_REPORT_STORIES_PUBLISHED_INFO,
  meta: {
    [CALL_API]: {
      endpoint: proxyAPI.publisherEditionHistoryInfo.getPublisherEditionHistoryInfo({ publisherId }),
      finalizer: optimisticJsonFinalizer,
    },
    params: { publisherId },
  },
});

export const actions = {
  fetchDailyStats,
  clearDailyStats,
  fetchInsightsLifestyleCategoryStats,
  clearInsightsLifestyleCategoryStats,
  fetchInsightsTopPerformingTilesStats,
  clearInsightsTopPerformingTilesStats,
  fetchInsightsTopPerformingStoriesStats,
  clearInsightsTopPerformingStoriesStats,
  setEditionDateRange,
  clearEditionDateRange,
  fetchEditionStats,
  clearEditionStats,
  fetchEdition24HoursStats,
  clearEdition24HoursStats,
  fetchEditionDailyStats,
  clearEditionDailyStats,
  fetchEditionList,
  fetchServiceStatus,
  clearEditionList,
  fetchSnapStats,
  clearSnapStats,
  fetchPollTimeline,
  fetchPublisherEditionHistoryInfo,
};
