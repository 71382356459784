import { get } from 'lodash';
import { Dispatch, Middleware } from 'redux';

import { SHOW_MODAL, HIDE_MODAL } from 'state/modals/actions/modalsActions';

const modalsMiddleware: () => Middleware = () => {
  const activeModalsPromiseCallbacks = new Map();

  return (state: any) => (next: Dispatch) => (action: any) => {
    const actionResult = next(action);
    if (action.type === SHOW_MODAL) {
      const modalId = get(action, ['payload', 'modalId']);
      if (!modalId) {
        throw new Error('Missing modalId when creating new modal');
      }

      if (activeModalsPromiseCallbacks.has(modalId)) {
        throw new Error(`Error creating modal, modal with id ${modalId} already exists`);
      }

      const promise = new Promise((resolve, reject) => {
        activeModalsPromiseCallbacks.set(modalId, {
          resolve,
          reject,
        });
      });

      return promise;
    }
    if (action.type === HIDE_MODAL) {
      const modalId = get(action, ['payload', 'modalId']);
      if (!modalId) {
        throw new Error('Missing modalId when hiding modal');
      }

      const callbacks = activeModalsPromiseCallbacks.get(modalId);
      if (callbacks) {
        callbacks.resolve(action.payload.results);
        activeModalsPromiseCallbacks.delete(modalId);
      }
    }

    return actionResult;
  };
};

export default modalsMiddleware;
