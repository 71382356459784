import { get } from 'lodash';
import type { Dispatch } from 'redux';

import { replace } from 'state/router/actions/routerActions';
import { getRedirectPath } from 'state/router/selectors/routerSelectors';

import { createPromiseAction } from '../../promiseMiddleware/actions/promiseMiddlewareActions';
import * as googleApi from '../utils/googleApi';

import * as grapheneUtils from 'utils/grapheneUtils';
import * as localStorageUtils from 'utils/localStorageUtils';
import { errorCodeToMessage } from 'utils/router/authErrorUtils';

import * as authActionTypes from './authActionTypes';

import type { UrlQuery } from 'types/endpoint';
import type { GetState } from 'types/redux';

export const logoutWithError = (errorCode: any) => (dispatch: Dispatch) => {
  return dispatch(exports.logout({ query: errorCodeToMessage(errorCode) }));
};
export const login = () => (dispatch: Dispatch) => {
  grapheneUtils.incrementCounter('Login.GoogleAuth', { action: 'googleApi.signIn' });
  const promise = () => googleApi.signIn();
  const action = {
    type: authActionTypes.GOOGLE_LOGIN,
  };
  return (dispatch(createPromiseAction(action, promise)) as any).catch((errorMsg: any) => {
    dispatch(exports.logoutWithError(errorMsg.error));
    return Promise.reject();
  });
};
const resetAuthFailure = () => ({
  type: authActionTypes.FLAG_AUTH_FAILED,
  payload: false,
});
export const logout = ({
  redirect = '/login',
  query = {},
  keepRedirect = true,
}: {
  redirect: string;
  query: UrlQuery;
  keepRedirect: boolean;
}) => {
  grapheneUtils.incrementCounter('Login.GoogleAuth', { action: `logout.${get(query, 'error', 'noError')}` });
  return {
    type: authActionTypes.LOGOUT,
    payload: {
      promise: () =>
        googleApi.signOut().then(() => (doneAction: any, dispatch: any, getState: GetState) => {
          dispatch(doneAction);
          // Reset authFailure
          dispatch(resetAuthFailure());
          localStorageUtils.clearLocalStorage();
          return dispatch(replace(getRedirectPath(getState())({ query, newPath: redirect, keepRedirect })));
        }),
    },
  };
};
export const refreshToken = () => {
  grapheneUtils.incrementCounter('Login.GoogleAuth', { action: 'refreshToken' });
  return {
    type: authActionTypes.GOOGLE_REFRESH_TOKEN,
    payload: {
      promise: () => googleApi.refreshToken(),
    },
  };
};
