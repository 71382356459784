import { Schema, arrayOf } from 'normalizr';

import { episodeSchema } from 'state/episodes/schema/episodesSchema';
import { extraSchema } from 'state/extras/schema/extrasSchema';

export const seasonSchema = new Schema('season', { idAttribute: 'id' });

seasonSchema.define({
  episodes: arrayOf(episodeSchema),
  extras: arrayOf(extraSchema),
});
