import clone from 'clone';
import is from 'is_js';
import _ from 'lodash';

import { getDefaultSnapEndpoint } from '../endpoints/snapEndpointRegistry';

import { assertArg } from 'utils/assertionUtils';

import { assertSnapIdOrUndefined } from 'types/common';

/*
 * Helper used by snapsActions to handle merging results from different snap
 * endpoints into a single decorated snap.
 *
 * Given a `results` argument of the form:
 *
 *   [
 *     {
 *       params: {
 *         snapId: 42,
 *       },
 *       payload: {
 *         entities: {
 *           richSnap: {
 *             42: {
 *               id: 42,
 *               name: 'foo',
 *             },
 *           },
 *         },
 *       },
 *     },
 *     {
 *       params: {
 *         snapId: 42,
 *       },
 *       payload: {
 *         entities: {
 *           discoverSnap: {
 *             42: {
 *               id: 42,
 *               tiles: ['bar'],
 *             },
 *           },
 *         },
 *       },
 *     },
 *   ]
 *
 * And an `endpoints` argument of the form:
 *
 *   [
 *     {
 *       name: 'richSnap',
 *       ...other endpoint info
 *     },
 *     {
 *       name: 'discoverSnap',
 *       ...other endpoint info
 *     },
 *   ]
 *
 * Will return the following:
 *
 *   {
 *     id: 42,
 *     name: 'foo',
 *     decorations: {
 *       discover: {
 *         id: 42,
 *         tiles: ['bar'],
 *       },
 *     },
 *   }
 */
export function buildDecoratedSnaps(results: any, endpoints: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(results).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpoints).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(results.length).is.equal(endpoints.length);

  const defaultEndpointIndex = findDefaultEndpointIndex(endpoints);
  const defaultResult = results[defaultEndpointIndex];
  const defaultEndpoint = endpoints[defaultEndpointIndex];

  const decorationResults = results.filter((result: any) => result !== defaultResult);
  const decorationEndpoints = endpoints.filter((endpoint: any) => endpoint !== defaultEndpoint);

  const snapsById = getSnapDataFromResult(defaultResult, defaultEndpoint.snapSchema);

  return addDecorations(snapsById, decorationResults, decorationEndpoints);
}

function findDefaultEndpointIndex(endpoints: any) {
  const index = endpoints.findIndex((endpointInfo: any) => endpointInfo.isDefault);

  if (index === -1) {
    throw new Error('Could not find default endpoint - this should never happen');
  }

  return index;
}

function getSnapDataFromResult(result: any, snapSchema: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(result).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(result.params).is.object();
  assertSnapIdOrUndefined(result.params.snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(result.params.snapIds).is.array.or.is.undefined();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(result.payload).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(result.payload.entities).is.object();

  if (!result.params.snapId && !result.params.snapIds) {
    throw new Error('Result should have either snapId or snapIds param');
  }

  let snapIds = result.params.snapIds || [result.params.snapId];

  const schemaKey = snapSchema.getKey();
  const entityMap = result.payload.entities[schemaKey];

  if (!is.object(entityMap)) {
    throw new Error(`No entity map was found for schema key ${snapSchema.getKey()}`);
  }

  // [PUB-155] We may get updated bottom snap information. i.e. relatedSnaps information
  snapIds = _.union(snapIds, Object.keys(entityMap));

  const snaps = {};
  snapIds.forEach((snapId: any) => {
    const snap = entityMap[snapId];

    if (!is.object(snap)) {
      throw new Error(
        `No snap entity was found in response with schema key ${snapSchema.getKey()} and snap id ${snapId}`
      );
    }

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    snaps[snap.id] = snap;
  });

  return snaps;
}

function addDecorations(snapsById: any, decorationResults: any, decorationEndpoints: any) {
  const finalSnaps = {};

  _.forOwn(snapsById, (snap, snapId) => {
    if (snap.decorations) {
      throw new Error('Snaps are assumed to not already have a `decorations` property');
    }

    const finalSnap = clone(snap);
    finalSnap.decorations = {};

    decorationEndpoints.forEach((endpointInfo: any, index: any) => {
      const decorationData = getSnapDataFromResult(decorationResults[index], endpointInfo.snapSchema);
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (decorationData[snapId]) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        finalSnap.decorations[endpointInfo.name] = decorationData[snapId];
      }
    });

    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    finalSnaps[snapId] = finalSnap;
  });

  return finalSnaps;
}

/*
 * Helper used by snapsActions to handle splitting decorated snaps back into
 * separate objects for each endpoint.
 *
 * Given a snap of the form:
 *
 *   {
 *     id: 42,
 *     name: 'foo',
 *     decorations: {
 *       discover: {
 *         id: 42,
 *         tiles: ['bar'],
 *       },
 *     },
 *   }
 *
 * Will return the following:
 *
 *   {
 *     richSnap: {
 *       id: 42,
 *       name: 'foo',
 *     },
 *     discover: {
 *       id: 42,
 *       tiles: ['bar'],
 *     },
 *   }
 */
export function splitByEndpoint(snapOrProperties: any, defaultSnapEndpoint = getDefaultSnapEndpoint()) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapOrProperties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(defaultSnapEndpoint).is.object();

  const defaultProperties = clone(snapOrProperties);
  const decorationProperties = defaultProperties.decorations || {};

  delete defaultProperties.decorations;

  return {
    [defaultSnapEndpoint.name]: defaultProperties,
    ...decorationProperties,
  };
}

/*
 * Returns whether the supplied properties object contains data for the
 * supplied endpoint. For example, this would return true for endpoint
 * name 'discover':
 *
 *   {
 *     name: 'foo',
 *     decorations: {
 *       discover: {
 *         tiles: ['bar'],
 *       },
 *     },
 *   }
 *
 * Whereas this would not:
 *
 *   {
 *     name: 'foo',
 *   }
 *
 * And nor would this:
 *
 *   {
 *     name: 'foo',
 *     decorations: {
 *       discover: {},
 *     },
 *   }
 */
export function hasPropertiesForEndpoint(
  properties: any,
  endpointName: any,
  defaultSnapEndpoint = getDefaultSnapEndpoint()
) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointName).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(defaultSnapEndpoint).is.object();

  const updatedPropertiesForThisEndpoint = splitByEndpoint(properties)[endpointName];

  return is.existy(updatedPropertiesForThisEndpoint) && !is.empty(updatedPropertiesForThisEndpoint);
}
