import _ from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NotificationScope, StatusMessageSeverity } from 'config/constants';
import { ErrorCodes } from 'utils/errors/api/apiErrorConstants';
import { ErrorContexts } from 'utils/errors/errorConstants';

import {
  registerNewApiErrorContext,
  registerNewApiErrorMessage,
  registerNewApiErrorCode,
  missingParamValueParamExtractor,
  typeErrorParamExtractor,
} from './apiErrorUtils';

// API error messages are registered per error context and per error code. They are concatenated to generate the final
// message so they must be specified without a final period.
export const init = () => {
  // Catch-all error message. All errors that don't have more specific message will map to this one.
  registerNewApiErrorContext(
    ErrorContexts.UNKNOWN,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-generic"
      description="Message shown to user when an unknown error happened"
      defaultMessage="An unknown error happened"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.ADD_NEW_SNAP_TO_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-add-new-snap"
      description="Generic message the user sees when adding a snap to a story fails"
      defaultMessage="Failed to add new Snap to Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.GET_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-load-edition"
      description="Generic message the user sees when loading a story fails"
      defaultMessage="Failed to load Story"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.GET_EDITION,
    ErrorCodes.INVALID_PARAM_VALUE,
    <FormattedMessage
      id="error-message-load-edition-invalid-param-value"
      description="Error message when an edition could not be found"
      defaultMessage="This edition cannot be found, please try selecting an edition from the drafts section"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.SAVE_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-save-edition"
      description="Generic message the user sees when saving a story fails"
      defaultMessage="Failed to save Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.GET_EDITION_SNAP_IDS,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-update-snaps"
      description="Generic message the user sees if refreshing the snapIds has failed"
      defaultMessage="Unable to refresh Snaps list for story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.DELETE_SNAP_FROM_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-delete-snap-from-edition"
      description="Generic message the user sees when deleting a snap from a story fails"
      defaultMessage="Failed to delete Snap from Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.LOAD_ACTIVE_EDITIONS_FOR_PUBLISHER,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-load-active-editions"
      description="Generic message the user sees when loading active stories for a publisher fails"
      defaultMessage="Failed to load active Stories for publisher"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.COPY_SNAP_TO_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-copy-snap-to-edition"
      description="Generic message the user sees when copying a snap to a story fails"
      defaultMessage="Failed to copy Snap to Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.SAVE_SNAP,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-save-snap"
      description="Generic message the user sees when saving a snap fails"
      defaultMessage="Failed to save Snap"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.LOAD_SNAP,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-load-snap"
      description="Generic message the user sees when loading a snap fails"
      defaultMessage="Failed to load Snap"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.ENABLE_LIVE_EDITION_LOCK,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-enable-live-edition-lock"
      description="Generic message the user sees when enabling live story lock state fails"
      defaultMessage="Failed to enable Story for editing"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.ENABLE_LIVE_EDITION_LOCK,
    ErrorCodes.ILLEGAL_STATE,
    <FormattedMessage
      id="error-message-enable-live-edition-lock-illegal-state"
      description="Error message when user edit a live story because it is in the wrong state"
      defaultMessage="Failed to edit Live Story. Please make sure the Story is Live and not being edited already."
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.DISABLE_LIVE_EDITION_LOCK,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-disable-live-edition-lock"
      description="Generic message the user sees when disabling live story lock state fails"
      defaultMessage="Failed to re-publish Story"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.DISABLE_LIVE_EDITION_LOCK,
    ErrorCodes.ILLEGAL_STATE,
    <FormattedMessage
      id="error-message-disable-live-edition-lock-illegal-state"
      description="Error message when user can't republish a story because it has already been built"
      defaultMessage="Failed to re-publish Story. Please make sure the Story build has completed."
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.TAKE_DOWN_LIVE_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-take-down-live-edition"
      description="Generic message the user sees when taking down a live story fails"
      defaultMessage="Failed to take down the live Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.LOAD_SNAPS,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-load-snaps"
      description="Generic message the user sees when loading all snaps of a story fails"
      defaultMessage="Failed to load Story Snaps"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.LOAD_SNAP_SNAPCODES,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-load-snap-snapcodes"
      description="Generic message the user sees when loading snapcodes for a snap fails"
      defaultMessage="Failed to load Snapcodes for Snap"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.LOAD_STORY_SNAPCODES,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-load-story-snapcodes"
      description="Generic message the user sees when loading snapcodes for a story fails"
      defaultMessage="Failed to load Snapcodes for Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.LOAD_PUBLISHER_SNAPCODES,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-load-publisher-snapcodes"
      description="Generic message the user sees when loading snapcodes for a publisher fails"
      defaultMessage="Failed to load Snapcodes for Publisher"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.UPDATE_SNAP_SNAPCODE,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-update-snap-snapcode"
      description="Generic message the user sees when updating a snapcode for a snap fails"
      defaultMessage="Failed to update Snapcode for Snap"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.UPDATE_STORY_SNAPCODE,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-update-story-snapcode"
      description="Generic message the user sees when updating snapcode for a story fails"
      defaultMessage="Failed to update Snapcode for Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_SNAP_SNAPCODE,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-create-snap-snapcode"
      description="Generic message the user sees when creating a snapcode for a snap fails"
      defaultMessage="Failed to create Snapcode for Snap"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_STORY_SNAPCODE,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-create-story-snapcode"
      description="Generic message the user sees when creating snapcode for a story fails"
      defaultMessage="Failed to create Snapcode for Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_PUBLISHER_SNAPCODE,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-create-publisher-snapcode"
      description="Generic message the user sees when creating snapcode for a publisher fails"
      defaultMessage="Failed to create Snapcode for Publisher"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.DELETE_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-delete-edition"
      description="Generic message the user sees when deleting a story fails"
      defaultMessage="Failed to delete Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.ARCHIVE_EDITION,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-archive-edition"
      description="Generic message the user sees when archiving a story fails"
      defaultMessage="Failed to archive Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.COPY_STORY,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-duplicate-edition"
      description="Generic message the user sees when duplicating a story fails"
      defaultMessage="Failed to duplicate Story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.EXPORT_TO_AD_MANAGER,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-export-to-ad-manager"
      description="Generic message the user sees when exporting snap to ad manager fails"
      defaultMessage="Failed to export Snap"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.SET_EDITION_STATE,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-set-edition-state"
      description="Generic message the user sees when setting an edition state failed"
      defaultMessage="Failed to set Story state"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.SET_EDITION_STATE,
    ErrorCodes.ILLEGAL_STATE,
    <FormattedMessage
      id="error-message-set-edition-state-illegal-state"
      description="Error message when user tries to set the story state but it's in an illegal state"
      defaultMessage="Failed to update Story state. Please make sure the Story build has completed."
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.ADD_NEW_USER,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-adding-user"
      description="Generic message the user sees when there was an error with creating new users"
      defaultMessage="Something went wrong when adding a new user. Please try again or contact us"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.ADD_NEW_USER,
    ErrorCodes.USER_WITH_EMAIL_EXIST,
    <FormattedMessage
      id="error-user-email-exists"
      description="Message appears when admin tries to add new user with non unique email"
      defaultMessage="The email you've provided is already associated to a user on our system"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.ADD_NEW_USER,
    ErrorCodes.USER_IS_PART_OF_PUBLISHER,
    <FormattedMessage
      id="error-user-exists-on-pub"
      description="Message appears when admin tries to add new user to a publisher, where the user already exists"
      defaultMessage="The user has already been added to this publisher"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.SET_EDITION_STATE_INVALID_DATE,
    NotificationScope.EDITION,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-start-date-wrong"
      description="Error message when user tries to set the story publish date in the past"
      defaultMessage="Something went wrong. Please, double check the start time selected and try again"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.SET_EDITION_STATE_INVALID_DATE,
    ErrorCodes.INVALID_BODY_VALUE,
    <FormattedMessage
      id="error-message-set-edition-state-start_date_is_in_the_past"
      description="Error message when user tries to set the story publish date in the past"
      defaultMessage="You're trying to schedule an edition in the past. Please try again."
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.SET_EDITION_STATE_INVALID_DATE,
    ErrorCodes.ILLEGAL_STATE,
    <FormattedMessage
      id="error-message-set-edition-state-start_date_too_close_to_existing_one"
      description="Error message when user tries to schedule a story too close to an existing one"
      defaultMessage="Scheduling conflict, your story is scheduled within 5 minutes of another."
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.UPDATE_USER,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-updating-user"
      description="Generic message the user sees when there was an error with updating users"
      defaultMessage="Something went wrong when updating a user. Please try again or contact us"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.UPDATE_USER,
    ErrorCodes.USER_MUST_MIGRATE_SNAP_AUTH,
    <FormattedMessage
      id="error-adding-non-snap-auth-user"
      description="Generic message the user sees when adding a snap auth role without migrating to snap auth"
      defaultMessage="This user must migrate to login with Snap in order to receive this role"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.UPDATE_USER,
    ErrorCodes.USER_WITH_EMAIL_EXIST,
    <FormattedMessage
      id="error-update_user-email-exists"
      description="Message appears when admin tries to update a user with non unique email"
      defaultMessage="The email you've provided is already associated to a user on our system"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.FEATURE_FLAGS,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-feature-flags"
      description="Generic message the user sees when working on a feature"
      defaultMessage="Something went wrong when updating the feature flag. Please try again or contact us"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.FEATURE_FLAGS,
    ErrorCodes.FEATURE_HAS_CLAIMS,
    <FormattedMessage
      id="error-feature-has-claims"
      description="Generic message the user sees when working on a feature"
      defaultMessage="Feature has claims attached to it. Perfoming the action may result in unexpected behaviour. Remove claim first"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.ADD_NEW_USER,
    ErrorCodes.USER_DOES_NOT_EXIST,
    <FormattedMessage
      id="error-adding-snap-user"
      description="Generic message the user sees when adding a snap username"
      defaultMessage="This Snapchat username doesn't exist"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.UPDATE_USER,
    ErrorCodes.USER_DOES_NOT_EXIST,
    <FormattedMessage
      id="error-updating-snap-user"
      description="Generic message the user sees when updating a snap username"
      defaultMessage="This Snapchat username doesn't exist"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_NEW_PUBLISHER_INVALID_NAME,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-adding-publisher-name-invalid"
      description="Generic message the user sees when there was an error with adding a new publisher"
      defaultMessage="Something went wrong when adding a new publisher. Please try again"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.CREATE_NEW_PUBLISHER_INVALID_NAME,
    ErrorCodes.INVALID_BODY_VALUE,
    <FormattedMessage
      id="error-adding-snap-publisher-name-invalid"
      description="Generic message the user sees when adding a publisher with an already taken name"
      defaultMessage="The name you have chosen has already been taken by a publisher"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_NEW_PUBLISHER_INVALID_DETAILS,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-adding-publisher-details-invalid"
      description="Generic message the user sees when there was an error with adding a new publisher"
      defaultMessage="Something went wrong when adding a new publisher. Please try again"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.CREATE_NEW_PUBLISHER_INVALID_DETAILS,
    ErrorCodes.INVALID_BODY_VALUE,
    <FormattedMessage
      id="error-adding-snap-publisher-details-invalid"
      description="Generic message the user sees when there was an error with adding a new publisher"
      defaultMessage="Something went wrong when adding a new publisher. Please try again"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.UPLOAD_MEDIA,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-uplaod-media"
      description="Error default message when upload media goes wrong"
      defaultMessage="Failed to upload media"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_STORY,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-create-story"
      description="Generic message the user sees when creating a story fails"
      defaultMessage="Failed to create story"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.ARTICLE_INGESTION,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-article-ingestion"
      description="Generic message the user sees when article ingestion failed"
      defaultMessage="Failed to ingest article"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.GENERATE_SUBTITLES,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-generate-subtitles"
      description="Generic message the user sees when generating subtitles fails"
      defaultMessage="Failed to generate subtitles"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.MAKE_ALL_STORIES_UNAVAILABLE,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-message-make-all-stories-unavailable"
      description="Generic message the super admin sees when making all stories unavailable fails"
      defaultMessage="Failed to make unselected stories unavailable"
    />
  );

  // Configuring messages per error code
  registerNewApiErrorCode(
    ErrorCodes.UNKNOWN,
    <FormattedMessage
      id="error-message-error-code-unknown"
      description="Generic message for an API error code response"
      defaultMessage="Error status: {status}, error code: {code}"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.OVERWRITE_PROTECTION_FAILED,
    <FormattedMessage
      id="error-message-error-code-overwrite-protection-failed"
      description="Error message displayed on API error when there is write conflict"
      defaultMessage="Someone else appears to be modifying it at the same time as you"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.MISSING_PARAM_VALUE,
    <FormattedMessage
      id="error-message-error-code-missing-param-value"
      description="Error message displayed on API error when parameters are missing"
      defaultMessage="Parameters missing: {params}"
    />,
    missingParamValueParamExtractor
  );

  registerNewApiErrorCode(
    ErrorCodes.INVALID_BODY_VALUE,
    <FormattedMessage
      id="error-message-invalid-body-value"
      description="Error message displayed on API error when body is invalid"
      defaultMessage="Invalid parameters: {invalidParameters}. {invalidMessages}"
    />,
    (error: Error) => {
      const data = _.get(error, ['fetchResults', 'data']);
      return {
        invalidParameters: data.params[0],
        invalidMessages: data.params[1],
        ...data,
      };
    }
  );

  registerNewApiErrorCode(
    ErrorCodes.INVALID_DOCUMENT_TYPE,
    <FormattedMessage
      id="error-message-uplaod-media-invalid-type"
      description="Error message when upload media goes wrong with invalid type"
      defaultMessage="The {type} you uploaded or pasted is an unsupported format. Please remove it"
    />,
    typeErrorParamExtractor
  );

  registerNewApiErrorCode(
    ErrorCodes.USER_WITH_EMAIL_EXIST,
    <FormattedMessage
      id="error-adding-user-generic"
      description="Generic message the user sees when there was an error with creating new users"
      defaultMessage="Something went wrong when adding a new user. Please try again or contact us"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.DUPLICATE_EPISODE_NUMBER,
    <FormattedMessage
      id="error-duplicate-episode-number"
      description="The message the user sees when an episode number has already been used"
      defaultMessage="The episode number has already been used in this season"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.EXCEEDED_ARTICLE_SIZE,
    <FormattedMessage
      id="error-exceeded-article-size"
      description="The message the user sees when their articles have exceeded the max limit"
      defaultMessage="The article HTML size is too big"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.SANITIZATION_ERROR,
    <FormattedMessage
      id="error-article-sanitization-error"
      description="The message the user sees when their article had a sanitization error"
      defaultMessage="The article HTML is ill formatted"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.DIFF_BOT_ERROR,
    <FormattedMessage
      id="error-article-ingestion-diffbot-error"
      description="The message the user sees when their article fails to ingest because of DiffBot"
      defaultMessage="Article could not be imported due to ill formed content. Please try another URL"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.GRPC_INVALID_ARGUMENT,
    <FormattedMessage
      id="error-grpc-invalid-argument"
      description="Error message displayed on API error when user provided arguments are invalid"
      defaultMessage="Invalid argument: {message}"
    />,
    (error: any) => {
      let message = _.get(error, ['fetchResults', 'data', 'message']);
      // Remove full stops to avoid showing "..", since it is added by error handler to concatenate messages.
      if (message?.charAt(message.length - 1) === '.') {
        message = message.slice(0, -1);
      }
      return {
        message,
      };
    }
  );

  registerNewApiErrorContext(
    ErrorContexts.GET_HOMEPAGE_DATA,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-loading-homepage"
      description="Generic message the user sees when there was an error loading the homepage"
      defaultMessage="Something went wrong while loading the homepage. Please try again or contact us."
    />,
    {
      hideErrorCode: true,
    }
  );

  registerNewApiErrorContext(
    ErrorContexts.SUPPORT_RAISE_TICKET,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-raising-support-ticket"
      description="Generic message the user sees when there was an error with raising a support ticket"
      defaultMessage="Something went wrong while raising a support ticket. Please, send us an email to publish-support@snapchat.com."
    />,
    {
      hideErrorCode: true,
    }
  );

  registerNewApiErrorContext(
    ErrorContexts.IMPORT_TILE_FROM_TOPSNAP,
    NotificationScope.SNAP,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-importing-tile-from-topsnap"
      description="Generic message the user sees when there was an error importing tile from topsnap"
      defaultMessage="Something went wrong while importing the tile. Please try again or contact us."
    />,
    {
      hideErrorCode: true,
    }
  );

  registerNewApiErrorContext(
    ErrorContexts.UPLOAD_PUBLISHER_ICON,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-uploading-publisher-icon"
      description="Generic message the user sees when there was an error uploading the publisher icon"
      defaultMessage="Something went wrong whilst uploading your logos. Please try again or contact us."
    />,
    {
      hideErrorCode: true,
    }
  );

  registerNewApiErrorContext(
    ErrorContexts.UPLOAD_TILE_ICON,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-uploading-tile-icon"
      description="Generic message the user sees when there was an error uploading the tile icon"
      defaultMessage="Something went wrong whilst uploading your wide logo. Please try again or contact us."
    />,
    {
      hideErrorCode: true,
    }
  );

  registerNewApiErrorContext(
    ErrorContexts.UPDATE_PUBLISHER_DETAILS,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-updating-publisher-details"
      description="Generic message the user sees when there was an error updating the publisher details"
      defaultMessage="Something went wrong whilst saving your details. Please try again or contact us."
    />,
    {
      hideErrorCode: true,
    }
  );

  registerNewApiErrorContext(
    ErrorContexts.UPDATE_SEASONS,
    NotificationScope.SEASON,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-updating-publisher-season-settings"
      description="Generic message the user sees when there was an error updating the seasons of a Show"
      defaultMessage="Failed to update season"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_EPISODE,
    NotificationScope.EPISODE,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-creating-episode"
      description="Generic message the user sees when there was an error creating an episode"
      defaultMessage="Failed to create episode"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.CREATE_EPISODE,
    ErrorCodes.INVALID_PARAM_VALUE,
    <FormattedMessage
      id="error-creating-episode-invalid-param"
      description="Error message when the episode number submitted was negative"
      defaultMessage="The episode number can't be negative."
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.CREATE_EPISODE,
    ErrorCodes.DUPLICATE_EPISODE_NUMBER,
    <FormattedMessage
      id="error-creating-episode-duplicate-episode-number"
      description="Error message when an episode number has already been used"
      defaultMessage="The episode number has already been used in this season."
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.UPDATE_EPISODE,
    NotificationScope.EPISODE,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-updating-episode"
      description="Generic message the user sees when there was an error updating an episode"
      defaultMessage="Failed to update episode"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.UPDATE_EPISODE,
    ErrorCodes.INVALID_PARAM_VALUE,
    <FormattedMessage
      id="error-updating-episode-invalid-param"
      description="Error message when the episode number submitted was negative"
      defaultMessage="The episode number can't be negative."
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.UPDATE_EPISODE,
    ErrorCodes.DUPLICATE_EPISODE_NUMBER,
    <FormattedMessage
      id="error-updating-episode-duplicate-episode-number"
      description="Error message when an episode number has already been used"
      defaultMessage="The episode number has already been used in this season."
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.TOO_MANY_TERM_FILTERS,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-loading-curated-snaps-too-many-term-filters"
      description="Message the user sees when there was an error loading snaps on the
       curation tool because of the use of too many term filters"
      defaultMessage="There was an error when loading the curated snaps,
      a maximum of 500 term filters are allowed at once, please remove some filters"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.SAVE_ONE_PUBLISHER_DATA,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-saving-one-publisher-data"
      description="Error message when saving publisher settings api call returns an error."
      defaultMessage="Error saving publisher settings"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.ASSOCIATE_HOST_USER,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-associating-host-user"
      description="Error message when associating user fails."
      defaultMessage="Error associating user with the publisher"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.ASSOCIATE_HOST_USER,
    ErrorCodes.GRPC_INVALID_ARGUMENT,
    <FormattedMessage
      id="error-associating-host-user-invalid-argument"
      description="Error message when associating user fails with invalid argument."
      defaultMessage="Error associating user with the publisher. The username provided is already associated with a public profile."
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.LOAD_ORG_ONBOARDINGS_LIST,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-load-org-onboardings-list"
      description="Error message when loading organisation onboardings list fail."
      defaultMessage="Error loading organisation onboardings list"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_ORG_ONBOARDING,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-create-org-onboarding"
      description="Error message when createing new organisation onboarding fails."
      defaultMessage="Error creating new organisation onboarding"
    />
  );

  registerNewApiErrorMessage(
    ErrorContexts.CREATE_ORG_ONBOARDING,
    ErrorCodes.USER_DOES_NOT_EXIST,
    <FormattedMessage
      id="error-adding-snap-user-onboarding"
      description="Generic message the user sees when adding a snap username"
      defaultMessage="This Snapchat username doesn't exist"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.DELETE_ORG_ONBOARDING,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-delete-org-onboarding"
      description="Error message when deleting existing organisation onboarding fails."
      defaultMessage="Error deleting organisation onboarding"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.CREATE_FEED,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-create-feed"
      description="Error message the user sees when creating a feed fails."
      defaultMessage="Failed to create feed"
    />
  );

  registerNewApiErrorContext(
    ErrorContexts.UPDATE_FEED,
    NotificationScope.GLOBAL,
    StatusMessageSeverity.ERROR,
    <FormattedMessage
      id="error-update-feed"
      description="Error message the user sees when updating a feed fails."
      defaultMessage="Failed to update feed"
    />
  );

  registerNewApiErrorCode(
    ErrorCodes.TEMPLATE_NOT_FOUND,
    <FormattedMessage
      id="error-template-not-found"
      description="Error message the user sees when creating a feed with a template that does not exist."
      defaultMessage="Template not found"
    />
  );
};
