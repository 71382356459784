import { reportTimerWithDuration } from 'utils/grapheneUtils';

// From appengine/discover-service/media-types/src/main/java/snapchat/discover/mediatypes/MetricType.java
let lastPageviewPage: any = null;
let lastPageviewTime: any;
let timeOffPage = 0;
let startTimeOffPage: any;
let isVisible = true;

export const GrafanaMetrics = {
  // All new user experience help modal
  NEW_USER_EXPERIENCE_HELP_MODAL: 'open_help_modal',

  // Help center links
  HELP_LINKS: 'open_help_links',

  // All new media library UI V2 events
  MEDIA_V2: 'media.library.v2',

  // All new media library UI V1 events
  MEDIA_V1: 'media.library.v1',

  STORY_ANALYTICS: 'story_analytics',

  ANALYTIC_TABS: 'analytic_tabs',
};

export function init() {
  lastPageviewPage = null;
  lastPageviewTime = null;
  timeOffPage = 0;
  startTimeOffPage = null;

  document.addEventListener(
    'focus',
    () => {
      visibilityChange(true);
    },
    false
  );
  document.addEventListener(
    'blur',
    () => {
      visibilityChange(false);
    },
    false
  );
  window.addEventListener(
    'focus',
    () => {
      visibilityChange(true);
    },
    false
  );
  window.addEventListener(
    'blur',
    () => {
      visibilityChange(false);
    },
    false
  );
}

export function logGrafanaPageview(path: string) {
  const currentTime = new Date();
  if (lastPageviewPage && lastPageviewTime) {
    // This gives the amount of time since entering the page minus the amount of time the page was 'hidden'
    const millis = currentTime.getTime() - lastPageviewTime.getTime() - timeOffPage;
    reportTimerWithDuration({
      metricsName: 'pageviews',
      dimensions: {
        lastPageviewPage,
      },
      milliSec: millis,
    });
  }
  lastPageviewPage = path;
  lastPageviewTime = currentTime;
  timeOffPage = 0;
  startTimeOffPage = null;
}

export function logGrafanaTiming(category: string, variable: string, millis: number) {
  // dig for metrics in: graphene.timers.web.$flavors.$variants.$version.$app.loadingtiming.category.*.variable.*
  reportTimerWithDuration({
    metricsName: 'loadingtiming',
    dimensions: {
      category,
      variable,
    },
    milliSec: millis,
  });
}

function onHidden() {
  if (!isVisible) {
    return;
  }
  isVisible = false;

  startTimeOffPage = new Date();
}

function onVisible() {
  if (isVisible) {
    return;
  }
  isVisible = true;

  if (startTimeOffPage) {
    timeOffPage += new Date().getTime() - startTimeOffPage.getTime();
    startTimeOffPage = null;
  }
}

function visibilityChange(newVisibility: any) {
  if (newVisibility) {
    return onVisible();
  }

  return onHidden();
}
