import { History } from 'history';
import _ from 'lodash';
import * as redux from 'redux';
import { batchedSubscribe } from 'redux-batched-subscribe';

import createMiddleware from './createMiddleware';
import createObservers from './createObservers';
import rootReducer from './rootReducer';

export default function configureStore(history: History) {
  const observerMap = createObservers();
  const middleware = createMiddleware({ history, observerMap });
  const enhancers: redux.StoreEnhancer<unknown>[] = [];
  const composeEnhancers = redux.compose;

  // Client-side enhancers and middleware
  // Should be the last item. Debounces state change notifications. Since they tend to happen in bursts,
  // this significantly improves user experience by reducing react rendering passes
  enhancers.push(batchedSubscribe(_.debounce(notify => notify(), 50)));
  const middlewares = redux.applyMiddleware<redux.Dispatch>(...middleware.map(info => info.func));
  const enhancer: redux.StoreEnhancer<unknown, {}> = composeEnhancers(middlewares, ...enhancers);
  const store = redux.createStore(rootReducer(history), undefined, enhancer);
  // Expose the store for inspection during development

  if (__DEBUG__) {
    (window as any).store = store;
  }
  if ((module as any).hot) {
    // Enable Webpack hot module replacement for reducers
    (module as any).hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer').default; // eslint-disable-line global-require
      store.replaceReducer(nextRootReducer(history));
    });
  }
  return store;
}
