import clone from 'clone';
import { normalize } from 'normalizr';
import { combineReducers } from 'redux';

import {
  createEntityAgeReducer,
  createSequenceCountingByIdReducer,
  createSequenceErrorsByIdReducer,
  createTransactionalSequenceHandlingReducer,
  serializeReducers,
} from 'state/common/reducerFactories';
import { GENERATE_SUBTITLES } from 'state/contentRecognition/actions/contentRecognitionActions';
import type { DirtyById } from 'state/editions/editionsState';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as scsStoryActions from 'state/scs/actions/storyActions';

import { Sequence, TransactionType } from 'config/constants';
import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

import {
  BLOCK_EDITION_EDITING,
  COPY_STORY,
  CREATE_STORY,
  DISCARD_LIVE_EDIT_CHANGES,
  GET_EDITION,
  SAVE_EDITION,
  SET_EDITION_PROPERTIES,
  SET_EDITION_SNAP_IDS,
  SET_EDITION_STATE,
  SET_NEW_STORY_INFO,
} from 'types/actions/editionsActionTypes';
import { StoryState } from 'types/editions';
import type { EditionID } from 'types/editions';
import type { State } from 'types/rootState';

export const getEdition = (editionId: EditionID, state: State) => state.editions.byId[editionId];

const loadingById = createSequenceCountingByIdReducer((action: any) => action.params.editionId, [GET_EDITION]);

const lastUpdatedById = createEntityAgeReducer('edition', [
  GET_EDITION,
  SAVE_EDITION,
  CREATE_STORY,
  SET_EDITION_STATE,
  publishersActions.GET_ACTIVE_EDITIONS,
  publishersActions.GET_UNAVAILABLE_EDITIONS,
  publishersActions.GET_HOMEPAGE_STORIES,
  publishersActions.GET_SCHEDULED_LIVE_STORIES,
  scsStoryActions.UPDATE_STORY,
  DISCARD_LIVE_EDIT_CHANGES,
]);

const byIdSequence = serializeReducers([
  createTransactionalSequenceHandlingReducer(
    {},
    TransactionType.SET_EDITION_PROPERTY,
    [
      GET_EDITION,
      SAVE_EDITION,
      CREATE_STORY,
      publishersActions.GET_ACTIVE_EDITIONS,
      publishersActions.GET_UNAVAILABLE_EDITIONS,
      publishersActions.GET_HOMEPAGE_STORIES,
      publishersActions.GET_SCHEDULED_LIVE_STORIES,
      COPY_STORY,
      scsStoryActions.UPDATE_STORY,
      GENERATE_SUBTITLES,
      DISCARD_LIVE_EDIT_CHANGES,
    ],
    {
      start(state: any, action: any) {
        return state;
      },
      done(state: any, action: any) {
        if (action.error) {
          return state;
        }
        return u(wrapEntitiesInUpdeepConstants(action.payload.entities.edition), state);
      },
    }
  ),
  createTransactionalSequenceHandlingReducer({}, TransactionType.SET_EDITION_PROPERTY, [SET_EDITION_STATE], {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      const editionDiffs = action.params?.editionsDiff;
      const editionsToBeOmitted = editionDiffs
        ? editionDiffs.reduce((idsToBeOmitted: any, diff: any) => {
            return diff.state === StoryState.DELETED ? [...idsToBeOmitted, diff.id] : idsToBeOmitted;
          }, [])
        : [];

      const updatedState = editionsToBeOmitted.length ? u.omit(editionsToBeOmitted, state) : state;

      return u(wrapEntitiesInUpdeepConstants(action.payload.entities.edition), updatedState);
    },
  }),
]);

function setSnapIds(state: any, action: any) {
  return u(
    {
      [action.params.editionId]: {
        snapIds: u.ifDiff(action.params.snapIds),
      },
    },
    state
  );
}

function setEditionPropertiesHandler(state: any, action: any) {
  let payloadCopy = clone(action.payload);
  const { editionId } = action.params;

  // Normalize edition
  payloadCopy.id = editionId; // Normalization needs id
  const normalized = normalize(payloadCopy, editionSchema);
  if ('result' in normalized && normalized.entities.edition[editionId]) {
    payloadCopy = normalized.entities.edition[editionId];
  }

  return u(
    {
      [editionId]: u.ifDiff(payloadCopy),
    },
    state
  );
}

function byId(state = {}, action: any) {
  switch (action.type) {
    case SET_EDITION_SNAP_IDS:
      return setSnapIds(state, action);
    case SET_EDITION_PROPERTIES:
      return setEditionPropertiesHandler(state, action);
    default:
      break;
  }
  return byIdSequence(state, action);
}

const errorsById = createSequenceErrorsByIdReducer((action: any) => action.params.editionId, [GET_EDITION]);

function dirty(state: DirtyById = {}, action: any) {
  switch (action.type) {
    case SET_EDITION_PROPERTIES:
      return u(
        {
          [action.params.editionId]: true,
        },
        state
      );
    case scsStoryActions.UPDATE_STORY:
    case SAVE_EDITION:
      if (action.sequence !== Sequence.DONE || action.error) {
        return state;
      }
      return u(
        {
          [action.params.editionId]: false,
        },
        state
      );
    default:
      return state;
  }
}

const savingById = serializeReducers([
  createSequenceCountingByIdReducer('editionId', [
    SAVE_EDITION,
    DISCARD_LIVE_EDIT_CHANGES,
    scsStoryActions.UPDATE_STORY,
  ]),
  createSequenceCountingByIdReducer('storyIds', BLOCK_EDITION_EDITING),
]);

const newStoryInfo = (state = {}, action: any) => {
  if (action.type === SET_NEW_STORY_INFO) {
    const newState = action.params.info || null;

    return newState ? u(newState, state) : {};
  }
  return state;
};

export default combineReducers({
  byId,
  lastUpdatedById,
  loadingById,
  errorsById,
  savingById,
  dirty,
  newStoryInfo,
});
