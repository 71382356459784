/* eslint-disable new-cap */
import _ from 'lodash';
import { combineReducers } from 'redux';

import { createSequenceHandlingReducer } from 'state/common/reducerFactories';

import { SAVE_SNAP_PREVIEW, SET_PREVIEW_VOLUME, TOGGLE_MUTE_PREVIEW } from '../actions/previewsActions';

import { DEFAULT_VOLUME } from 'config/constants';
import u from 'utils/safeUpdeep';

const snapPreviews = createSequenceHandlingReducer({}, SAVE_SNAP_PREVIEW, {
  start(state: any, action: any) {
    const { snapId } = action.params;
    const { payload } = action;

    // Replace state on start regardless of hash
    return u(
      {
        [snapId]: u.ifDiff(payload),
      },
      state
    );
  },
  done(state: any, action: any) {
    const { snapId } = action.params;
    const { payload } = action;

    const currentHash = _.get(state, [snapId, 'hash'], null);

    // Do not store any data if the snap hash no longer matches
    if (currentHash !== payload.hash) {
      return state;
    }

    return u(
      {
        [snapId]: u.ifDiff(payload),
      },
      state
    );
  },
});

const isPreviewMuted = (state = false, action: any) => {
  if (action.type === TOGGLE_MUTE_PREVIEW) {
    return !state;
  }
  return state;
};

const previewVolume = (state = DEFAULT_VOLUME, action: any) => {
  if (action.type === SET_PREVIEW_VOLUME) {
    return action.volume;
  }
  return state;
};

export default combineReducers({
  snapPreviews,
  previewVolume,
  isPreviewMuted,
});
