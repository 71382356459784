import type { FileType } from 'config/constants';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

// Video top snap
export type MediaTypeVideoSnap = 'VIDEO_SNAP';
// Image top snap
export type MediaTypeImageSnap = 'IMAGE_SNAP';
// Unknown top snap
export type MediaTypeUnknownSnap = 'UNKNOWN_SNAP';
// Long form video
export type MediaTypeVideoAttachment = 'VIDEO_ATTACHMENT';
export type MediaType = MediaTypeVideoSnap | MediaTypeImageSnap | MediaTypeVideoAttachment | MediaTypeUnknownSnap;

export const MediaTypeEnum: {
  VIDEO_SNAP: MediaTypeVideoSnap;
  IMAGE_SNAP: MediaTypeImageSnap;
  UNKNOWN_SNAP: MediaTypeUnknownSnap;
  VIDEO_ATTACHMENT: MediaTypeVideoAttachment;
} = enumObject({
  VIDEO_SNAP: 'VIDEO_SNAP',
  IMAGE_SNAP: 'IMAGE_SNAP',
  UNKNOWN_SNAP: 'UNKNOWN_SNAP',
  VIDEO_ATTACHMENT: 'VIDEO_ATTACHMENT',
});

export type MediaItem = {
  id: string;
  fileName: string;
  mediaType: MediaType;
  mediaUrl: string;
  createdAt: number;
  contentLength: number;
  width: number;
  height: number;
  duration?: number;
  mimeType: string;
  haveBaseMediaAndOverlay?: boolean;
  creatorUsername: string | undefined | null;
  isCuratedMedia: boolean;
};

export const UploadAsEnum = enumObject({
  SNAP: 'SNAP',
  ATTACHMENT: 'ATTACHMENT',
});

export type UploadAsType = Enum<typeof UploadAsEnum>;

// Metadata of media to be uploaded. Used in MediaLibraryUploadModal
export type UploadedMedia = {
  fileType: FileType;
  width: number;
  height: number;
  duration?: number;
  // Allowed UploadAs options
  uploadAsOptions: UploadAsType[];
  validationError: string | undefined | null;
};

export type PreviewMedia = {
  images: {
    poster: {
      assetId: String;
      src: String;
    };
  };
  complete: boolean;
};

export type FilterByRecent = 'MediaLibraryFilters.recent';
export type FilterByVideoSnaps = 'MediaLibraryFilters.videoSnaps';
export type FilterBySnaps = 'MediaLibraryFilters.snaps';
export type FilterByImageSnaps = 'MediaLibraryFilters.imageSnaps';
export type FilterByVideoAttachments = 'MediaLibraryFilters.videoAttachments';
export type FilterByCuratedSnaps = 'MediaLibraryFilters.curatedSnaps';
export type FilterByType =
  | FilterByRecent
  | FilterBySnaps
  | FilterByVideoSnaps
  | FilterByImageSnaps
  | FilterByVideoAttachments
  | FilterByCuratedSnaps;

export const FilterByEnum: {
  RECENT: FilterByRecent;
  SNAPS: FilterBySnaps;
  VIDEO_SNAPS: FilterByVideoSnaps;
  IMAGE_SNAPS: FilterByImageSnaps;
  VIDEO_ATTACHMENTS: FilterByVideoAttachments;
  CURATED_SNAPS: FilterByCuratedSnaps;
} = enumObject({
  RECENT: 'MediaLibraryFilters.recent',
  SNAPS: 'MediaLibraryFilters.snaps',
  VIDEO_SNAPS: 'MediaLibraryFilters.videoSnaps',
  IMAGE_SNAPS: 'MediaLibraryFilters.imageSnaps',
  VIDEO_ATTACHMENTS: 'MediaLibraryFilters.videoAttachments',
  CURATED_SNAPS: 'MediaLibraryFilters.curatedSnaps',
});

// Whether selected media can only be added as top snap, video attachment, or any type (either
// top snap or video attachment)
export type TopSnapTarget = 'TOP_SNAP';
export type VideoAttachmentTarget = 'VIDEO_ATTACHMENT';
export type AnyTarget = 'ANY';
export type TargetType = TopSnapTarget | VideoAttachmentTarget | AnyTarget;

export const TargetEnum: {
  TOP_SNAP: TopSnapTarget;
  VIDEO_ATTACHMENT: VideoAttachmentTarget;
  ANY: AnyTarget;
} = enumObject({
  TOP_SNAP: 'TOP_SNAP',
  VIDEO_ATTACHMENT: 'VIDEO_ATTACHMENT',
  ANY: 'ANY',
});

// Mapping to asset https://github.sc-corp.net/Snapchat/pb_schema/blob/752b99538bcd41fb2a989d91c0506e339d81495d/proto/contentplatform/media-library.proto#L73
export const MediaAssetType = enumObject({
  DEFAULT_ASSET: 'DEFAULT_ASSET',
  OVERLAY: 'OVERLAY',
  BASE_MEDIA: 'BASE_MEDIA',
});
