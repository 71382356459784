// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'quer... Remove this comment to see the full error message
import { parse, stringify } from 'query-string';
import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

import { assertArg } from 'utils/assertionUtils';

export type Query = {
  error?: string;
  redirect?: string;
  message?: string;
};

const getRouter = (state: any) => {
  return state.router || {};
};

const getLocation = createKeySelector(getRouter, 'location', {});

export const getPathName = createKeySelector(getLocation, 'pathname', '');
export const getSearchString = createKeySelector(getLocation, 'search', '');

export const getQuery = s(getSearchString, searchString => parse(searchString));

export const getRedirectPath = s(
  getQuery,
  getPathName,
  (existingQuery, existingPathName) => ({
    query = {},
    newPath,
    keepRedirect = true,
  }: {
    query: Query;
    newPath: string;
    keepRedirect: boolean;
  }) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(newPath).is.string();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(query).is.object();
    let queryStr = {
      ...existingQuery,
      ...query,
    };

    // Preserve older redirect if the new basePath is the same as the old one
    if (existingPathName && existingPathName !== newPath && keepRedirect) {
      queryStr.redirect = existingPathName;
    }

    // Never persist snap_continue as a redirect parameter as it can lead to unexpected behavior
    // when a user wants to login with GoogleAuth immediately after unsuccessful SnapAuth login attempt
    if (queryStr.redirect && (queryStr.redirect.includes('snap_continue') || queryStr.redirect === '/')) {
      delete queryStr.redirect;
    }

    queryStr = stringify(queryStr);
    return newPath + (queryStr ? `?${queryStr}` : '');
  }
);
