// These should map to the api error codes in rich-snap-platform and discover-service, see
// https://github.sc-corp.net/Snapchat/content-platform/blob/main/rich-snap-platform/domain/src/main/java/snapchat/richsnap/domain/errors/ErrorCode.java
// https://github.sc-corp.net/Snapchat/content-platform/blob/main/discover-common/shared/src/main/java/snapchat/discover/common/errors/ErrorCode.java
export const ErrorCodes = {
  ILLEGAL_STATE: 'ILLEGAL_STATE',
  INVALID_DOCUMENT_TYPE: 'INVALID_DOCUMENT_TYPE',
  INVALID_PARAM_VALUE: 'INVALID_PARAM_VALUE',
  INVALID_BODY_VALUE: 'INVALID_BODY_VALUE',
  FEATURE_HAS_CLAIMS: 'FEATURE_HAS_CLAIMS',
  MISSING_BODY: 'MISSING_BODY',
  MIGRATION_EMAIL_MISMATCH: 'MIGRATION_EMAIL_MISMATCH',
  MISSING_PARAM_VALUE: 'MISSING_PARAM_VALUE',
  OVERWRITE_PROTECTION_FAILED: 'OVERWRITE_PROTECTION_FAILED',
  UNKNOWN: 'UNKNOWN',
  USER_IS_PART_OF_PUBLISHER: 'USER_IS_PART_OF_PUBLISHER',
  USER_WITH_EMAIL_EXIST: 'USER_WITH_EMAIL_EXIST',
  USER_MUST_MIGRATE_SNAP_AUTH: 'USER_MUST_MIGRATE_SNAP_AUTH',
  USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
  DUPLICATE_EPISODE_NUMBER: 'DUPLICATE_EPISODE_NUMBER',
  SANITIZATION_ERROR: 'SANITIZATION_ERROR',
  EXCEEDED_ARTICLE_SIZE: 'EXCEEDED_ARTICLE_SIZE',
  DIFF_BOT_ERROR: 'DIFF_BOT_ERROR',
  GRPC_INVALID_ARGUMENT: 'GRPC_INVALID_ARGUMENT',
  TEMPLATE_NOT_FOUND: 'TEMPLATE_NOT_FOUND',
};

export type ErrorCodesEnum = typeof ErrorCodes[keyof typeof ErrorCodes];
