import { gql } from '@apollo/client';

import { useProxyMutation } from 'utils/apis/graphQLUtils';

export const REQUEST_REVIEW = gql`
  mutation requestReview($businessProfileId: String!) {
    requestEditorialReview(input: { businessProfileId: $businessProfileId }) {
      editorialApproval
    }
  }
`;

export function useRequestReviewMutation() {
  const [requestReview, { data, error }] = useProxyMutation(REQUEST_REVIEW);
  if (error) {
    return { error };
  }
  return { requestReview, data };
}
