import is from 'is_js';
import _ from 'lodash';
import { createSelector as s } from 'reselect';
import { createObjectSelector } from 'reselect-map';

import { createKeySelector, createDynamicKeySelector } from 'state/common/selectorFactories';

import { EMPTY_OBJECT } from 'config/constants';

import { assertAssetId } from 'types/assets';
import { UploadProgress, UploadProgressDataPoint } from 'types/media';
import { State } from 'types/rootState';

export function frameUnique(blobUrl: any, width: any, height: any) {
  return [blobUrl, width, height].join('-');
}
export function getMedia(state: State) {
  return _.get(state, 'media') || {};
}
export const getUploadResults = createKeySelector(getMedia, 'uploadResults', {});
export const getActiveUploadCountsByPurpose = createKeySelector(getMedia, 'activeUploadsCountsByPurpose', {});
export const getActiveUploadCountsForComponentId = createDynamicKeySelector(getActiveUploadCountsByPurpose, {});
export const getActiveUploadCountsForComponentIdByPurpose = s(
  getActiveUploadCountsForComponentId,
  getActiveUploadCountsForComponentIdFn => (componentId: any, purpose: any) => {
    const activeUploadCounts = getActiveUploadCountsForComponentIdFn(componentId);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return activeUploadCounts[purpose] || 0;
  }
);
export const getUploadResultById = createDynamicKeySelector(getUploadResults, null);
export const isUploadingByComponentId = s(
  getActiveUploadCountsForComponentId,
  uploadCountsForComponentId => (componentId: any) => {
    const uploadCounts = uploadCountsForComponentId(componentId);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return Object.keys(uploadCounts).some(purpose => uploadCounts[purpose]);
  }
);
export const getCompletedUploadsByIds = s(
  getUploadResults,
  getUploadResultById,
  (uploadResults, getUploadResultByIdFn) => (blobUrls: any) => {
    return blobUrls
      .map((blobUrl: any) => {
        const job = getUploadResultByIdFn(blobUrl);
        if (!job) {
          return null;
        }
        return {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...job,
          blobUrl,
        };
      })
      .filter(is.existy)
      .filter((result: any) => !result.uploading);
  }
);
export const getUploadUrlByMediaId = s(getUploadResults, uploadResults => (assetId: any) => {
  assertAssetId(assetId);
  let mediaUrl = null;
  _.forOwn(uploadResults, (value, key) => {
    if ((value as any).mediaId === assetId) {
      mediaUrl = key;
      return false;
    }
    return true;
  });
  return mediaUrl;
});
export const getFrameResults = createKeySelector(getMedia, 'frameResults', {});
export const getFrameResultById = createDynamicKeySelector(getFrameResults, null);
export const getFrameResultByUrlWidthHeight = s(getFrameResultById, getFrameResultByIdFn => {
  return (blobUrl: any, width: any, height: any) => {
    const frameKey = frameUnique(blobUrl, width, height);
    return getFrameResultByIdFn(frameKey);
  };
});
const getUploadProgress = createKeySelector(getMedia, 'uploadProgress', {});
export const getUploadProgressByMediaId = createDynamicKeySelector(getUploadProgress, null);
export const getActiveMediaId = createKeySelector(getUploadProgress, 'activeMediaId', null);
export const getActiveMediaUploadProgressPercent = s(
  getUploadProgressByMediaId,
  getActiveMediaId,
  (getUploadProgressByMediaIdFn, activeMediaId) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    return _.get(getUploadProgressByMediaIdFn(activeMediaId), 'progressPercent', 0);
  }
);
export const getUploadProgressSummaryByComponentId = createObjectSelector(
  getUploadProgress,
  (uploadProgress: UploadProgress | null) => {
    if (!uploadProgress) {
      return EMPTY_OBJECT;
    }
    return Object.values(uploadProgress).reduce(
      (value: UploadProgressDataPoint, initial: UploadProgressDataPoint): UploadProgressDataPoint => {
        return {
          totalSizeBytes: value.totalSizeBytes + initial.totalSizeBytes || 0,
          progressBytes: value.progressBytes + initial.progressBytes || 0,
        };
      },
      { totalSizeBytes: 0, progressBytes: 0 } as UploadProgressDataPoint
    );
  }
);
