import * as apiMiddlewareActions from 'state/apiMiddleware/actions/apiMiddlewareActions';

import * as discoverAPI from 'utils/apis/discoverAPI';

export const LOAD_ALL_ORGS = 'organisation/LOAD_ALL_ORGS';
export const LOAD_ORG = 'organisation/LOAD_ORG';

export const loadAllOrgs = () => {
  return (dispatch: any) => {
    return dispatch(
      apiMiddlewareActions.createCallAction(
        {
          type: LOAD_ALL_ORGS,
        },
        {
          method: 'GET',
          endpoint: discoverAPI.publishers.getOrgs(),
        }
      )
    );
  };
};

export const loadOrg = ({ bpid }: any) => {
  return (dispatch: any) => {
    return dispatch(
      apiMiddlewareActions.createCallAction(
        {
          type: LOAD_ORG,
          params: { bpid },
        },
        {
          method: 'GET',
          endpoint: discoverAPI.publishers.getOrganization({ bpid }),
        }
      )
    );
  };
};
