import { combineReducers } from 'redux';

import { createSequenceHandlingReducer } from 'state/common/reducerFactories';
import * as organisationsActions from 'state/organisations/actions/organisationsActions';

import { EMPTY_OBJECT, EMPTY_ARRAY, Sequence } from 'config/constants';
import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

import type { Org } from 'types/organisations';

const orgsList = (state: Org[] = EMPTY_ARRAY, action: any) => {
  switch (action.type) {
    case organisationsActions.LOAD_ALL_ORGS:
      if (action.sequence !== Sequence.START) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};

export const isLoadingOrg = createSequenceHandlingReducer(false, organisationsActions.LOAD_ORG, {
  start(_: any) {
    // Set isLoading to true when API request starts
    return true;
  },
  done(_1: any, _2: any) {
    // Set back to false when the request finishes
    return false;
  },
});

const byBusinessProfileId = createSequenceHandlingReducer(EMPTY_OBJECT, [organisationsActions.LOAD_ORG], {
  start(state: any, action: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return EMPTY_OBJECT;
    }
    const orgMap = { [action.params.bpid]: action.payload };
    return u(wrapEntitiesInUpdeepConstants(orgMap), state);
  },
});

// ------------------------------------
// Reducer
// ------------------------------------

export default combineReducers({
  orgsList,
  byBusinessProfileId,
  isLoadingOrg,
});
