import { findIndex, concat, noop } from 'lodash';
import type { Dispatch } from 'redux';

import type { CuratedSnap } from 'types/curation';
import type { MediaItem } from 'types/mediaLibrary';
import type { GetState } from 'types/redux';

type ItemType = CuratedSnap | MediaItem;

/**
 * Returns items between start (exclusive) and end (inclusive)
 */
export const getItemsBetweenStartAndEnd = (
  startItem: ItemType | undefined | null,
  endItem: ItemType | undefined | null,
  itemsList: Array<any>
): Array<ItemType> => {
  if (!startItem || !endItem) {
    return [];
  }
  const startItemId = startItem.id;
  const endItemId = endItem.id;
  const startItemIndex = findIndex(itemsList, item => item.id === startItemId);
  const endItemIndex = findIndex(itemsList, item => item.id === endItemId);
  if (startItemIndex === -1 || endItemIndex === -1) {
    return [];
  }
  const slice = itemsList.slice(Math.min(startItemIndex, endItemIndex) + 1, Math.max(startItemIndex, endItemIndex));
  if (startItemIndex < endItemIndex) {
    return concat(slice, endItem);
  }

  return concat(endItem, slice);
};

export const createToggleItem = (
  getItemByIdFunc: any,
  getGridItemsFunc: any,
  getLastSelectedItemFunc: any,
  toggleActionType: any,
  callback: any = noop
) => {
  return (itemId: string, isShiftSelect: boolean = false) => {
    // If the item comes from a previous search, it may no longer be in the
    // store, and thus 'item' could be null. In that case, pass in itemId as well

    return (dispatch: Dispatch, getState: GetState) => {
      const item = getItemByIdFunc(getState())(itemId);
      const allGridItems = getGridItemsFunc(getState());
      const lastSelectedItem = getLastSelectedItemFunc(getState());
      callback(item, allGridItems);
      return dispatch({
        type: toggleActionType,
        payload: {
          itemId,
          item,
          // shiftSelectedItems contains all items between last selected item and current selected item
          shiftSelectedItems: isShiftSelect ? getItemsBetweenStartAndEnd(lastSelectedItem, item, allGridItems) : [],
        },
      });
    };
  };
};

export const createClearTray = (clearActionType: any) => {
  return () => (dispatch: Dispatch) => dispatch({ type: clearActionType });
};
