import log from 'loglevel';
import { Dispatch, Middleware } from 'redux';

import { actionCounter } from 'utils/middlewareUtils';

// Decorate every action with an actionId
// This is used when resetting the store upon logout to ignore any async actions that have arrived late
const actionCounterMiddleware: Middleware = () => (next: Dispatch) => (action: any) => {
  if (action.actionId) {
    log.warn(
      'Passing an action',
      action.type,
      'that already contains actionId. ActionIds should not be assigned manually'
    );
  }

  return next({ ...action, actionId: actionCounter() });
};

export default actionCounterMiddleware;
