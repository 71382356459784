import { NOTIFY_ARTICLE_EDITED } from 'state/article/actions/articleActions';
import { FETCH_EDITION_BUILD_STATUS } from 'state/buildStatus/actions/buildStatusActions';
import { SET_EDITOR_CONFIG_PROPERTIES } from 'state/editor/actions/editorActions';
import { SAVE_SNAP_PREVIEW } from 'state/previews/actions/previewsActions';
import { SET_CURRENT_SEARCH } from 'state/videoLibrary/actions/videoLibraryActions';

import { GET_EDITION_SNAP_IDS, SET_EDITION_SNAP_IDS } from 'types/actions/editionsActionTypes';

// Actions that are fired too frequently and not worthy of tracing in dev tools or breadcrumbs
export const BLOCKLIST_ACTIONS = [
  NOTIFY_ARTICLE_EDITED,
  SET_EDITOR_CONFIG_PROPERTIES,
  SET_CURRENT_SEARCH,
  FETCH_EDITION_BUILD_STATUS,
  GET_EDITION_SNAP_IDS,
  SET_EDITION_SNAP_IDS,
  SAVE_SNAP_PREVIEW,
];
