import { Html5Entities } from 'html-entities';
import { mapValues } from 'lodash';
import { combineReducers } from 'redux';

import * as assetActions from 'state/asset/actions/assetActions';
import {
  createSequenceCountingByIdReducer,
  createSequenceHandlingReducer,
  createSimpleReducer,
} from 'state/common/reducerFactories';
import * as subtitlesActions from 'state/subtitles/actions/subtitlesActions';
import type { SetSubtitlesPreviewAction, SetSubtitlesLanguageAction } from 'state/subtitles/actions/subtitlesActions';

import { EMPTY_OBJECT } from 'config/constants';
import { createAssetUrl } from 'utils/media/assetUtils';
import u from 'utils/safeUpdeep';

const decoder = new Html5Entities();

export type SubtitlesData = {};

const byId = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  [assetActions.LOAD_ASSET_INFO, subtitlesActions.LOAD_SUBTITLES_TRACK],
  {
    start(state: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return EMPTY_OBJECT;
      }
      if (action.type === assetActions.LOAD_ASSET_INFO) {
        const subtitleEntities = mapValues(action.payload.entities.subtitles, subtitleTrack => {
          if (subtitleTrack && subtitleTrack.src) {
            // Backend serves the source url which is pre-signed. But this expires after couple of minutes.
            // I'm changing that to download subtitles via the proxy so we don't have issue with expiring urls anymore
            const newSourceUrl = createAssetUrl(subtitleTrack.id);
            return {
              ...subtitleTrack,
              src: newSourceUrl,
              source: newSourceUrl,
            };
          }

          return subtitleTrack;
        });

        return u(subtitleEntities, state);
      }
      if (action.type === subtitlesActions.LOAD_SUBTITLES_TRACK) {
        const trackId = action.params.trackId;
        const track = {
          ...state[trackId],
          rawContent: decoder.decode(action.payload),
        };

        return u(
          {
            [trackId]: track,
          },
          state
        );
      }

      return EMPTY_OBJECT;
    },
  }
);

const previewVisible = createSimpleReducer(
  subtitlesActions.SET_SUBTITLES_PREVIEW,
  (state: boolean, action: SetSubtitlesPreviewAction) => action.params.previewVisible,
  true
);

const selectedLanguage = createSimpleReducer(
  subtitlesActions.SET_SUBTITLES_LANGUAGE,
  (state: string, action: SetSubtitlesLanguageAction) => action.params.language,
  ''
);

export const tracksLoadingById = createSequenceCountingByIdReducer('trackId', [subtitlesActions.LOAD_SUBTITLES_TRACK]);

export default combineReducers({
  byId,
  tracksLoadingById,
  previewVisible,
  selectedLanguage,
});
