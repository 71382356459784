import { Schema, arrayOf } from 'normalizr';

export const discoverSnapBuildStatusSchema = new Schema('discoverSnaps', { idAttribute: 'snapId' });

export const editionBuildStatusSchema = new Schema('editions', { idAttribute: 'editionId' });

export const segmentBuildStatusSchema = new Schema('segments', {
  idAttribute: entity => `${entity.editionId}-${entity.segmentId}`,
});

export const tileBuildStatusSchema = new Schema('tiles', { idAttribute: 'tileId' });

discoverSnapBuildStatusSchema.define({
  tileStatusWireModels: arrayOf(tileBuildStatusSchema),
});

segmentBuildStatusSchema.define({
  tileStatusWireModels: arrayOf(tileBuildStatusSchema),
});

editionBuildStatusSchema.define({
  snapStatuses: arrayOf(discoverSnapBuildStatusSchema),
  segmentStatuses: arrayOf(segmentBuildStatusSchema),
  tileStatuses: arrayOf(tileBuildStatusSchema),
});
