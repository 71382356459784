import _ from 'lodash';
import { createSelector as s } from 'reselect';

import { richSnapSchema } from 'state/snaps/schema/snapsSchema';

import { assertSnapId } from 'types/common';

export const getTransactions = (state: any) => state.transactions || {};

export const getTransactionByType = s(getTransactions, transactions => (transactionType: any) =>
  transactions[transactionType]
);

export const hasOngoingTransactionsBySnapId = s(getTransactions, transactions => (snapId: any) => {
  assertSnapId(snapId);

  return Object.keys(transactions).some(transactionType => {
    const transaction = transactions[transactionType];
    const entityIds = _.get(transaction, ['entityMap', `${richSnapSchema.getKey()}`]);
    return entityIds && entityIds.some((id: any) => id === snapId);
  });
});

export const hasOngoingTransactionsByStoryId = s(getTransactions, transactions => (storyId: any) => {
  return Object.keys(transactions).some(transactionType => {
    const transaction = transactions[transactionType];
    const entityIds = transaction.entityMap.edition;
    return entityIds && entityIds.some((id: any) => id === storyId);
  });
});
