// https://github.com/babel/babel-eslint/issues/485
/* eslint-disable no-use-before-define */

type ReduxAction = {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '$Subtype'.
  type: $Subtype<string>;
};

// We define PromiseAction as an opaque type to make it easier for types/redux.js to distinguish between
// dispatch(promiseAction) versus dispatch(action), where `action` is a simple action.

// Using flow comment syntax because WebStorm and ESLint don't understand the opaque keyword yet
export type /* :: opaque */ PromiseAction<A extends ReduxAction, T> = A & {
  payload: {
    promise: PromiseTypes<T>;
  };
};

type FuncReturningPromise<T> = () => Promise<T>;
type PromiseTypes<T> = Promise<T> | FuncReturningPromise<T>;

export function createPromiseAction<A extends ReduxAction, T>(
  action: A,
  promise: PromiseTypes<T>
): PromiseAction<A, T> {
  return {
    ...action,
    payload: { promise },
  };
}
