import { Dispatch, Middleware } from 'redux';

export default function bailoutMiddleware(): Middleware {
  return ({ getState }: any) => {
    return (next: Dispatch) => (action: any) => {
      const { bailout } = action;
      if (typeof bailout !== 'undefined' && typeof bailout !== 'function') {
        throw new Error('Expected bailout to either be undefined or a function.');
      }

      if (bailout && bailout(getState())) {
        return Promise.resolve();
      }

      return next(action);
    };
  };
}
