import type { Merge } from 'type-fest';

import type { AgeGateEnum, EditionScheduleEnum, AdPlacementModeEnum } from 'config/constants';

import type { SnapId } from './common';
import type { ContentAccessLists } from './countries';
import type { EditionID } from './editionID';
import type { PublisherID } from './publishers';
import type { Segment, SegmentID } from './segments';
import type { TileID, Tile } from './tiles';

import type { ExtendedBuildStatus } from 'types/build';
import { PostingStoryType } from 'types/storySnapEditor';

export type { EditionID } from './editionID';

export enum LiveEditStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  READY_FOR_PUBLISHING = 'READY_FOR_PUBLISHING',
  NONE_OR_PUBLISHED = 'NONE_OR_PUBLISHED',
}

export type VideoTrack = {
  id: string;
  transcodedMediaId: string;
};

export type DiscardStoryMedatada = {
  discardTiles: boolean;
  discardAttachments: boolean;
  discardTags: boolean;
};

export type BaseEdition = {
  adSnapIndexes: Array<number>;
  adPlacementMode: AdPlacementModeEnum;
  ageGate: AgeGateEnum;
  hiddenLock: boolean;
  id: EditionID;
  publisherId: PublisherID;
  categoryId?: string;
  liveEditStatus: LiveEditStatus;
  segments: Segment[];
  shareOption: string;
  snapIds: SnapId[];
  startDate: string | undefined | null;
  lastLiveDate: string | undefined | null;
  lastUpdatedAt: string | undefined | null;
  endDate: string | undefined | null;
  // Individual edition states use string constants instead of enum values due to flow issue:
  // https://github.com/facebook/flow/issues/2377
  state: StoryState;
  tiles: Tile[];
  title: string;
  scheduleType: EditionScheduleEnum | undefined | null;
  createdAt: string;
  firstLiveDate: string | undefined | null;
  videoTracks: VideoTrack[] | undefined | null;
  hnBreakingNews?: boolean;
  isSponsored: boolean;
  geoContentAccessList: ContentAccessLists;
  discardMetadata?: DiscardStoryMedatada;
  postingStoryType: PostingStoryType;
};

export type ScheduledEdition = Merge<
  BaseEdition,
  {
    state: ServerSideStoryState.SCHEDULED;
    startDate: string;
  }
>;

export type ScheduledEditionWithEpisodeNumber = Merge<
  ScheduledEdition,
  {
    episodeNumber: number;
  }
>;

export type PublishingEdition = Merge<
  BaseEdition,
  {
    state: UIStoryState.PUBLISHING;
    startDate: string;
  }
>;

export type LiveEdition = Merge<
  BaseEdition,
  {
    state: ServerSideStoryState.LIVE;
    startDate: string;
  }
>;

export type LiveEditionWithEpisodeNumber = Merge<
  LiveEdition,
  {
    episodeNumber: number;
  }
>;

export type ArchivedEdition = Merge<
  BaseEdition,
  {
    state: ServerSideStoryState.ARCHIVED;
    startDate: string;
  }
>;

export type InProgressEdition = Merge<
  BaseEdition,
  {
    state: UIStoryState.IN_PROGRESS;
    startDate: string;
  }
>;

export type NewEdition = Merge<
  BaseEdition,
  {
    state: ServerSideStoryState.NEW;
  }
>;

export type ReadyEdition = Merge<
  BaseEdition,
  {
    state: ServerSideStoryState.READY;
  }
>;

export type HiddenEdition = Merge<
  BaseEdition,
  {
    state: ServerSideStoryState.HIDDEN;
  }
>;

export type UnpublishingEdition = Merge<
  BaseEdition,
  {
    state: UIStoryState.UNPUBLISHING;
  }
>;

export type DeletedEdition = Merge<
  BaseEdition,
  {
    state: ServerSideStoryState.DELETED;
  }
>;

export type AvailableEdition = PublishingEdition | LiveEdition | ArchivedEdition | InProgressEdition;

export type UnavailableEdition = ScheduledEdition | NewEdition | ReadyEdition | HiddenEdition | UnpublishingEdition;

export type Edition = AvailableEdition | UnavailableEdition | DeletedEdition;

export type OrderedEdition = {
  story: Edition;
  storyBuildStatus: ExtendedBuildStatus;
  tile: TileID;
};

// normalized in the redux store
export type StoreEdition = {
  id: number;
  segments: SegmentID[];
  snapIds: SnapId[];
  tiles: TileID[];
  title: string;
};

export type StoreEditionMap = {
  [key in EditionID]: StoreEdition;
};

export type Stories = Array<BaseEdition>;

// Type used to represent story while it's being created and has no id. Only one is stored at any time.
export type NewStoryInfo = {
  firstFrameKey?: string;
  title: string;
};

export enum ServerSideStoryState {
  NEW = 'NEW',
  READY = 'READY',
  SCHEDULED = 'SCHEDULED',
  SCHEDULED_PENDING_APPROVAL = 'SCHEDULED_PENDING_APPROVAL',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  LIVE = 'LIVE',
  ARCHIVED = 'ARCHIVED',
  HIDDEN = 'HIDDEN',
  DELETED = 'DELETED',
  SCHEDULED_LIVE_EDIT_PENDING_APPROVAL = 'SCHEDULED_LIVE_EDIT_PENDING_APPROVAL',
  LIVE_EDIT_PENDING_APPROVAL = 'LIVE_EDIT_PENDING_APPROVAL',
}

enum UIStoryState {
  IN_PROGRESS = 'IN_PROGRESS', // This is a non-standard state to signal the edition has the liveEditState IN_PROGRESS
  PUBLISHING = 'PUBLISHING', // This is a non-standard state to signal the edition is between SCHEDULED and LIVE
  UNPUBLISHING = 'UNPUBLISHING', // This is a non-standard state to signal the edition is between LIVE/ARCHIVED and HIDDEN
  BIB_REJECTED = 'BIB_REJECTED', // This is a non-standard state to signal BIB has rejected a story
  BIB_REVISION_NEEDED = 'BIB_REVISION_NEEDED', // This is a non standard state to signal BIB has requested for a revision
}

export const StoryState = {
  ...ServerSideStoryState,
  ...UIStoryState,
};

export type StoryState = ServerSideStoryState | UIStoryState;
