import { StoryStatusEnum } from 'config/constants';
import { enumObject } from 'utils/enum';

import { BottomType, TopType } from './snaps';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type {
  AudienceEnum,
  ModerationViolationEnum,
  StoryModerationScopeEnum,
  StoryModerationStatusEnum,
} from 'types/moderation';
import { SegmentID } from 'types/segments';
import { SingleAssetTranscodeStatus } from 'types/singleAssetStoryEditor';
import { TileID } from 'types/tiles';

export enum SnapProblem {
  INCOMPLETE = 'INCOMPLETE',
  NO_BUILD = 'NO_BUILD',
  BUILD_STALE = 'BUILD_STALE',
  BUILDING = 'BUILDING',
  BUILD_ERROR = 'BUILD_ERROR',
  BUILD_OVERSIZED = 'BUILD_OVERSIZED',
  PROCESSING_INCOMPLETE = 'PROCESSING_INCOMPLETE',
  PUBLISHABLE = 'PUBLISHABLE',
  ILLEGAL_STATE = 'ILLEGAL_STATE',
  CONTENT_DELETED = 'CONTENT_DELETED',
}

export enum TileProblem {
  NO_BUILD = 'NO_BUILD',
  BUILDING = 'BUILDING',
  INCOMPLETE = 'INCOMPLETE',
  BUILD_ERROR = 'BUILD_ERROR',
  PUBLISHABLE = 'PUBLISHABLE',
  NON_FORWARD_COMPATIBLE = 'NON_FORWARD_COMPATIBLE',
}

export enum SegmentProblem {
  PUBLISHABLE = 'PUBLISHABLE',
  MISSING_TILES = 'MISSING_TILES',
  INCOMPLETE_TILES = 'INCOMPLETE_TILES',
  MISSING_TAGS = 'MISSING_TAGS',
}

// these dont have to be tile related
//  anything that can be checked can use this constant to define a key
//  that maps to a popover message
export enum ExtendedIncompleteStatus {
  ATTACHMENT_INCOMPLETE = 'ATTACHMENT_INCOMPLETE',
  INVALID_TILE_HEADLINE_TEXT = 'INVALID_TILE_HEADLINE_TEXT',
  GENERATING_SUBTITLES = 'GENERATING_SUBTITLES',
  INCONSISTENT_DIMENSIONS = 'INCONSISTENT_DIMENSIONS',
  MISSING_CATEGORY = 'MISSING_CATEGORY',
  MISSING_TAGS = 'MISSING_TAGS',
  MISSING_SUBSCRIBE_SNAP_OVERLAY = 'MISSING_SUBSCRIBE_SNAP_OVERLAY',
  SEGMENT_TILE_MISSING = 'SEGMENT_TILE_MISSING',
  SENSITIVE_CONTENT = 'SENSITIVE_CONTENT',
  SEGMENT_TILE_INCOMPLETE = 'SEGMENT_TILE_INCOMPLETE',
  SNAP_AND_TILE_MODERATION_FLAGGED = 'SNAP_AND_TILE_MODERATION_FLAGGED',
  SNAP_MODERATION_FLAGGED = 'SNAP_MODERATION_FLAGGED',
  SNAP_PART_OF_STITCHING_CONTAINS_IMAGE = 'SNAP_PART_OF_STITCHING_CONTAINS_IMAGE',
  TILE_BUILD_ERROR = 'TILE_BUILD_ERROR',
  TILE_LOGO_BUILD_ERROR = 'TILE_LOGO_BUILD_ERROR',
  TILE_INCOMPLETE = 'TILE_INCOMPLETE',
  TILE_MISSING_LOGO = 'TILE_MISSING_LOGO',
  TILE_MISSING = 'TILE_MISSING',
  TILE_MISSING_HEADLINE = 'TILE_MISSING_HEADLINE',
  TILE_MODERATION_FLAGGED = 'TILE_MODERATION_FLAGGED',
  TILE_NON_FORWARDS_COMPATIBLE = 'TILE_NON_FORWARDS_COMPATIBLE',
  UNHEALTHY_TILE_HEADLINE_TEXT = 'UNHEALTHY_TILE_HEADLINE_TEXT',
  WEBVIEW_LIMIT_REACHED = 'WEBVIEW_LIMIT_REACHED',
}

export enum InfoStatus {
  AUTO_GENENERATED_SUBS,
}

export type ExtendedBuildStatus = ExtendedIncompleteStatus | SnapProblem;

export const BUILDING_STATUSES = [SnapProblem.BUILDING, ExtendedIncompleteStatus.GENERATING_SUBTITLES];

export const INCOMPLETE_STATUSES = [
  ExtendedIncompleteStatus.TILE_INCOMPLETE,
  ExtendedIncompleteStatus.TILE_MISSING_LOGO,
  ExtendedIncompleteStatus.TILE_MISSING,
  ExtendedIncompleteStatus.MISSING_TAGS,
  ExtendedIncompleteStatus.ATTACHMENT_INCOMPLETE,
  ExtendedIncompleteStatus.TILE_MISSING_HEADLINE,
  ExtendedIncompleteStatus.INVALID_TILE_HEADLINE_TEXT,
  ExtendedIncompleteStatus.SEGMENT_TILE_MISSING,
  ExtendedIncompleteStatus.SEGMENT_TILE_INCOMPLETE,
  ExtendedIncompleteStatus.TILE_NON_FORWARDS_COMPATIBLE,
  ExtendedIncompleteStatus.MISSING_SUBSCRIBE_SNAP_OVERLAY,
  SnapProblem.INCOMPLETE,
];

export const TILE_ERROR_STATUSES = [
  ExtendedIncompleteStatus.TILE_BUILD_ERROR,
  ExtendedIncompleteStatus.TILE_LOGO_BUILD_ERROR,
  ExtendedIncompleteStatus.WEBVIEW_LIMIT_REACHED,
];

export const MODERATION_FLAGGED_STATUSES = [
  ExtendedIncompleteStatus.SNAP_AND_TILE_MODERATION_FLAGGED,
  ExtendedIncompleteStatus.SNAP_MODERATION_FLAGGED,
  ExtendedIncompleteStatus.TILE_MODERATION_FLAGGED,
];

export const NON_BLOCKING_STATUSES = [
  ...MODERATION_FLAGGED_STATUSES,
  ExtendedIncompleteStatus.UNHEALTHY_TILE_HEADLINE_TEXT,
  ExtendedIncompleteStatus.SENSITIVE_CONTENT,
];

export const SNAP_MEDIA_ERROR_STATUSES = [
  SnapProblem.NO_BUILD,
  SnapProblem.BUILD_ERROR,
  SnapProblem.CONTENT_DELETED,
  ExtendedIncompleteStatus.INCONSISTENT_DIMENSIONS,
  ExtendedIncompleteStatus.SNAP_PART_OF_STITCHING_CONTAINS_IMAGE,
  SnapProblem.BUILD_OVERSIZED,
];

export const ERROR_STATUSES = [SnapProblem.ILLEGAL_STATE, ...SNAP_MEDIA_ERROR_STATUSES, ...TILE_ERROR_STATUSES];

export const BuildSetting = enumObject({
  low9to16: 'low9to16',
  low2to3: 'low2to3',
  med2to3: 'med2to3',
  med9to16: 'med9to16',
  medlow9to16: 'medlow9to16',
});

export type EditionBuildStatus = {
  computedScore: ComputedEditionScore;
  editionStatus: StoryStatusEnum;
  singleAssetTranscodeStatus: SingleAssetTranscodeStatus;
  audience: Array<AudienceEnum>;
  moderationStatus: StoryModerationStatusEnum;
  moderationScope: StoryModerationScopeEnum;
  snapStatuses: Array<SnapId>;
  segmentStatuses: Array<SegmentID>;
  subtitlesStatus: SubtitlesStatus;
};

export type TileBuildStatus = {
  tileId: number;
  status: TileProblem;
  audience: Array<AudienceEnum>;
  moderationViolations: Array<ModerationViolationEnum>;
  moderationAppealStatus: ModerationAppealStatus;
};

export enum ModerationAppealStatus {
  AVAILABLE = 'AVAILABLE',
  SENT = 'SENT',
  REJECTED = 'REJECTED',
}

export type SegmentBuildStatus = {
  segmentId: number;
  editionId: EditionID;
  status: SegmentProblem;
  tileStatusWireModels: Array<TileID>;
  audience: Array<AudienceEnum>;
};

export type SubtitlesStatus = {
  allSubtitlesAreGenerated: boolean;
  languages: string[];
};

export enum StoryScoreType {
  TAGS = 'TAGS',
  SUBTITLES = 'SUBTITLES',
  LEADING_TILES = 'LEADING_TILES',
  MULTIPLE_TILES = 'MULTIPLE_TILES',
}

// This is ComputedStoryScore received from the server.
export type ComputedEditionScore = {
  percentageAchieved: number;
  scoreAchieved: number;
  failedScores: Array<StoryScoreType>;
  fulfilledScores: Array<StoryScoreType>;
};

// This is ComputedEditionScore transformed for components, property names differs slightly.
export type ComputedStoryScore = {
  achievedPercentage: number;
  achievedScore: number;
  fulfilledScores: StoryScoreType[];
  failedScores: StoryScoreType[];
};

export type SnapHealth = {
  snapId: SnapId;
  topType: TopType;
  bottomType: BottomType;
  status: SnapProblem;
  extendedStatus: ExtendedBuildStatus;
  message: null;
  processingComplete: Boolean;
  adsSensitive: Boolean;
  buildSize: any;
  tileStatusWireModels: Array<TileID>;
  audience: [AudienceEnum];
  moderationViolations: Array<ModerationViolationEnum>;
  moderationTags: {};
};

// Deprecate build status
export type BuildStatusType = SnapHealth;
