import { SnapType } from 'types/snaps';
import type { BitmojiRemoteWebSnap } from 'types/snaps';
import type { Stage } from 'types/stages';

type BitmojiRemoteWebStage = Stage<BitmojiRemoteWebSnap, Partial<BitmojiRemoteWebSnap>, void>;

export const BITMOJI_REMOTE_WEB_STAGE_CONFIG: BitmojiRemoteWebStage = {
  stageName: SnapType.BITMOJI_REMOTE_WEB,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: ['remoteUrl', 'allowExternalSharing', 'sharingMethod'],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => properties,
};
