import { union } from 'lodash';
import { combineReducers } from 'redux';

import { createSequenceHandlingReducer } from 'state/common/reducerFactories';
import * as mediaLibraryActions from 'state/mediaLibrary/actions/mediaLibraryActions';

import { EMPTY_ARRAY } from 'config/constants';

import { convertResponseToMediaItem } from 'views/mediaLibrary/utils/MediaLibraryUtils';

import { ZoomType } from 'types/grid';
import { FilterByEnum } from 'types/mediaLibrary';

const currentFilter = (state = FilterByEnum.RECENT, action: any) => {
  if (action.type === mediaLibraryActions.SET_FILTER) {
    return action.payload.filter;
  }
  return state;
};

const mediaList = createSequenceHandlingReducer(EMPTY_ARRAY, mediaLibraryActions.GET_MEDIA, {
  start(state: any, action: any) {
    if (!action.keepExistingSnaps) {
      return EMPTY_ARRAY;
    }
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    const convertedNewMedia = action.payload.media.map(convertResponseToMediaItem);
    // If response filters are specified, use the types to filter new media
    const filteredNewMedia = action.responseFilter
      ? convertedNewMedia.filter((newMedia: any) => {
          return action.responseFilter.indexOf(newMedia.mediaType) !== -1;
        })
      : convertedNewMedia;
    return union(state, filteredNewMedia);
  },
});

const zoomState = (state = ZoomType.ZOOMED_OUT, action: any) => {
  if (action.type === mediaLibraryActions.TOGGLE_ZOOM) {
    return state === ZoomType.ZOOMED_OUT ? ZoomType.ZOOMED_IN : ZoomType.ZOOMED_OUT;
  }
  return state;
};

const nextCursor = createSequenceHandlingReducer('', mediaLibraryActions.GET_MEDIA, {
  start(state: any, action: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    return action.payload.nextCursor;
  },
});

const hasMoreResults = createSequenceHandlingReducer(true, mediaLibraryActions.GET_MEDIA, {
  start(state: any, action: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }
    return action.payload.nextCursor !== '';
  },
});

const isLoading = createSequenceHandlingReducer(false, mediaLibraryActions.GET_MEDIA, {
  start(state: any, action: any) {
    return true;
  },
  done(state: any, action: any) {
    return false;
  },
});

export default combineReducers({
  currentFilter,
  mediaList,
  zoomState,
  nextCursor,
  hasMoreResults,
  isLoading,
});
