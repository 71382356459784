import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import { goToLanding } from 'state/router/actions/routerActions';

import { assertArg } from 'utils/assertionUtils';
import { getPublisherIdOrActiveCreatorPublisherId } from 'utils/routerUtils';

export const requireEdition = (store: any) => ({ match, parentPromise }: any) => {
  const publisherId = getPublisherIdOrActiveCreatorPublisherId({ match }, store);
  if (!publisherId) {
    return store.dispatch(goToLanding());
  }

  const editionId = parseInt(match.params.editionId, 10);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();

  return store.dispatch(
    publisherStoryEditorActions.initializePublisherStoryEditor({
      editionId,
      publisherId,
      publisherFetchPromise: parentPromise,
    })
  );
};
