import { ApolloProvider } from '@apollo/client';
import classNames from 'classnames';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'node... Remove this comment to see the full error message
import waves from 'node-waves';
import PropTypes from 'prop-types';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'quer... Remove this comment to see the full error message
import { parse } from 'query-string';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { wrapWithContext } from 'context/common/contextWrapper';
import { Platforms } from 'context/platform/platformContext';
import apolloClient from 'utils/apis/apollo';
import { intlConnect } from 'utils/connectUtils';
import { getMessageBodyFromId } from 'utils/intlMessages/intlMessages';

import ToastMessagesOverlay from 'views/common/containers/ToastMessagesOverlay/ToastMessagesOverlay';
import ModalsContainer from 'views/modals/containers/ModalsContainer/ModalsContainer';

import style from './App.scss';

import 'node-waves/dist/waves.css';
import { State } from 'types/rootState';

const mapStateToProps = (state: State) => ({
  primaryLanguage: publishersSelectors.getActivePublisherPrimaryLanguage(state),
  activePublisher: userSelectors.getActivePublisher(state),
  publishers: userSelectors.getPublishers(state),
});
type AppProps = {
  location: any;
  platform: string;
  primaryLanguage?: string;
  activePublisher?: any;
  publishers?: any[];
};
export class App extends React.Component<AppProps> {
  static path = '/';

  static childContextTypes = {
    embeddingEnvironment: PropTypes.string,
  };

  getChildContext() {
    const query = parse(this.props.location.search);
    const { embeddingEnvironment } = query;
    return { embeddingEnvironment };
  }

  async componentDidMount() {
    waves.init();
    await this.setTitle();
  }

  async componentDidUpdate() {
    await this.setTitle();
  }

  setTitle = async () => {
    const currentLocaleId = localStorage.getItem('localeId') || 'en';
    const pageTitle = await getMessageBodyFromId('story-studio', {}, currentLocaleId);
    // Important: when adding any data into the title (username, display name) make sure it's removed in Google Analytics for privacy.
    document.title = pageTitle;
  };

  shouldComponentUpdate(nextProps: AppProps) {
    if (!nextProps) {
      return false;
    }
    // react-router's withRouter injects match, location, and history props.  We only read location.
    const { props } = this;
    return nextProps.location !== props.location || (nextProps as any).children !== props.children;
  }

  render() {
    const { children, platform } = this.props;
    return (
      <DndProvider backend={HTML5Backend}>
        <ApolloProvider client={apolloClient}>
          <div className={classNames(style.container, { [style.mobile]: platform === Platforms.MOBILE })}>
            {children}
            <ModalsContainer />
            <ToastMessagesOverlay />
          </div>
        </ApolloProvider>
      </DndProvider>
    );
  }
}
export default wrapWithContext('router', 'platform')(intlConnect(mapStateToProps, null)(App));
