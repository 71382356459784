import { isArrayBuffer } from 'lodash';

export default class FileReaderUtil {
  static readAsUint8Array(file: File, chunkSize: number, offset: number): Promise<Uint8Array> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        const data = event.target?.result;
        if (isArrayBuffer(data)) {
          resolve(new Uint8Array(data));
        } else {
          reject(new Error("Didn't read file as array buffer"));
        }
      };

      reader.onerror = event => {
        const error = event?.target?.error || undefined;
        reject(Error(`Error reading file: ${error?.message}`));
      };

      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });
  }

  static readAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        const data = event.target?.result;
        if (isArrayBuffer(data)) {
          resolve(data);
        } else {
          reject(new Error("Didn't read file as array buffer"));
        }
      };

      reader.onerror = event => {
        const error = event?.target?.error || undefined;
        reject(Error(`Error reading file: ${error?.message}`));
      };

      reader.readAsArrayBuffer(file);
    });
  }
}
