import { normalize, arrayOf } from 'normalizr';
import { combineReducers } from 'redux';

import {
  createSequenceHandlingReducer,
  serializeReducers,
  createRemoverByIdReducer,
} from 'state/common/reducerFactories';
import * as episodesActions from 'state/episodes/actions/episodesActions';
import * as showsActions from 'state/shows/actions/showsActions';
import { showSchema } from 'state/shows/schema/showsSchema';

import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

export const episodesById = createSequenceHandlingReducer({}, showsActions.LOAD_SHOWS, {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }

    const normalized = normalize(action.payload, arrayOf(showSchema));
    if ('result' in normalized) {
      return u(wrapEntitiesInUpdeepConstants(normalized.entities.episode), state);
    }
    return state;
  },
});

export const updateById = createSequenceHandlingReducer(
  {},
  [episodesActions.UPDATE_EPISODE, episodesActions.CREATE_EPISODE],
  {
    start(state: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error || !action.payload) {
        return state;
      }

      return u.updateIn(action.payload.id, action.payload, state);
    },
  }
);

export const unlinkEpisode = createSequenceHandlingReducer({}, [episodesActions.UNLINK_EPISODE], {
  start(state: any) {
    return state;
  },
  done: createRemoverByIdReducer('episodeId', [episodesActions.UNLINK_EPISODE]),
});

const byId = serializeReducers([episodesById, updateById, unlinkEpisode]);

export default combineReducers({
  byId,
});
