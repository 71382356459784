// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { setTimeout } from 'global';
import log from 'loglevel';
import { valuesOf } from 'normalizr';
import type { Dispatch } from 'redux';

import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';

import { editionBuildStatusSchema } from '../schema/buildStatusSchema';
import {
  isEditionBuildStatusLoading,
  getEditionBuildStatusAgeById,
  getDiscoverSnapBuildStatus,
} from '../selectors/buildStatusSelectors';

import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as scsAPI from 'utils/apis/scsAPI';
import { assertArg } from 'utils/assertionUtils';
import { isExpired } from 'utils/dateUtils';
import * as grapheneUtils from 'utils/grapheneUtils';

import { SnapProblem } from 'types/build';
import { assertSnapId } from 'types/common';
import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { GetState } from 'types/redux';
import type { State } from 'types/rootState';

export const FETCH_EDITION_BUILD_STATUS = 'buildStatus/FETCH_EDITION_BUILD_STATUS';
export const FETCH_EDITION_BUILD_STATUS_MULTIPLE = 'buildStatus/FETCH_EDITION_BUILD_STATUS_MULTIPLE';
export const RESET_LOADING_EDITION_BUILD_STATUS = 'buildStatus/RESET_LOADING_EDITION_BUILD_STATUS';
export const ADD_SNAP_BUILD_STATUS_PLACEHOLDER = 'buildStatus/ADD_SNAP_PLACEHOLDER';
export const RETRY_INTERVAL = 5000;
export const EXPIRED_TIMEOUT = 2;
export const STUCK_IN_LOADING_TIMEOUT = 6;

export const resetLoadingEditionBuildStatus = () => {
  return {
    type: RESET_LOADING_EDITION_BUILD_STATUS,
  };
};
export const fetchEditionBuildStatus = ({ editionId, noBailout }: { editionId: EditionID; noBailout?: boolean }) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  return (dispatch({
    type: FETCH_EDITION_BUILD_STATUS,
    meta: {
      [CALL_API]: {
        endpoint: scsAPI.story.status({ editionId }),
        finalizer: optimisticJsonFinalizer,
      },
      schema: editionBuildStatusSchema,
    },
    bailout: (state: State) => {
      if (noBailout) {
        return false;
      }
      const isLoading = isEditionBuildStatusLoading(state)(editionId);
      const timestamp = getEditionBuildStatusAgeById(state)(editionId);
      const isStuckLoading = isExpired(timestamp, STUCK_IN_LOADING_TIMEOUT, 'seconds');
      // if the build status has been loading for longer than 6 seconds
      // then assume that it's stuck
      if (isLoading && isStuckLoading) {
        // clear the build status
        grapheneUtils.incrementCounter('EditionBuildStatus.clearBuildStatus');
        dispatch(resetLoadingEditionBuildStatus());
        // bypass the bailout
        return false;
      }
      return isLoading || !isExpired(timestamp, EXPIRED_TIMEOUT, 'seconds');
    },
    params: { editionIds: [editionId] },
  }) as any).catch((error: any) => {
    log.warn('Suppressing FETCH_EDITION_BUILD_STATUS error', error);
    // eslint-disable-next-line no-param-reassign
    error.alreadyHandled = true;
    return Promise.reject(error);
  });
};
export const fetchMultipleEditionBuildStatuses = ({ editionIds }: { editionIds: EditionID[] }) => {
  return (dispatch: Dispatch): Promise<unknown> => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(editionIds).is.array();
    if (editionIds.length === 0) {
      return Promise.resolve();
    }
    return Promise.resolve().then(() =>
      dispatch({
        type: FETCH_EDITION_BUILD_STATUS_MULTIPLE,
        meta: {
          [CALL_API]: {
            endpoint: scsAPI.story.statusMultiple({ id: editionIds }),
            finalizer: optimisticJsonFinalizer,
          },
          schema: valuesOf(editionBuildStatusSchema),
        },
        params: { editionIds },
      })
    );
  };
};
export const fetchActiveEditionBuildStatus = () => {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const editionId = publisherStoryEditorSelectors.getActiveEditionId(getState());
    // Guard against no edition being active
    if (!editionId) {
      return Promise.resolve();
    }
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(editionId).is.number();
    return (
      Promise.resolve()
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        .then(() => dispatch(fetchEditionBuildStatus({ editionId })))
    );
  };
};

const addSnapBuildStatusPlaceholder = ({ snapId, status }: any) => ({
  type: ADD_SNAP_BUILD_STATUS_PLACEHOLDER,
  payload: {
    snapId,
    status,
  },
});

export const duplicateSnapBuildStatus = ({
  snapId,
  duplicatedSnapId,
}: {
  snapId: SnapId;
  duplicatedSnapId: SnapId;
}) => {
  assertSnapId(snapId);
  assertSnapId(duplicatedSnapId);
  return (dispatch: Dispatch, getState: GetState) => {
    const buildStatus = getDiscoverSnapBuildStatus(getState())(snapId);
    const duplicatedSnapBuildStatus =
      !buildStatus || !buildStatus.status || buildStatus.status === SnapProblem.INCOMPLETE
        ? SnapProblem.INCOMPLETE
        : SnapProblem.BUILDING;
    return dispatch(addSnapBuildStatusPlaceholder({ snapId: duplicatedSnapId, status: duplicatedSnapBuildStatus }));
  };
};
