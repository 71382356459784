import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as reactRouter from 'react-router';

import { getActiveCreator } from 'state/user/selectors/userSelectors';

import { LocalStorage } from 'config/constants';

import { AlertOptions } from 'views/modals/containers/AlertModal/AlertModal';

import { Store } from 'types/redux';

export function withRouter(Component: any) {
  return reactRouter.withRouter(Component);
}

type RouteParams = {
  match: {
    params: {
      publisherId: string;
      hostUsername: string;
    };
  };
};

export const getPublisherIdFromRouteOrStorage = (params?: RouteParams) => {
  const rawPublisherId = params?.match?.params?.publisherId || localStorage.getItem(LocalStorage.CURRENT_PUBLISHER);
  return rawPublisherId != null ? parseInt(rawPublisherId, 10) : null;
};

export const getCreatorHostUsernameFromRouteOrStorage = (params: RouteParams) => {
  return params.match?.params?.hostUsername || localStorage.getItem(LocalStorage.CURRENT_HOST_USERNAME);
};

export const getPublisherIdOrActiveCreatorPublisherId = (params: RouteParams, store: Store) => {
  const hostUsername = params.match?.params?.hostUsername;
  if (hostUsername) {
    return getActiveCreator(store.getState())?.publisherId;
  }

  return getPublisherIdFromRouteOrStorage(params);
};

export const redirectionPopupOptions: AlertOptions = {
  onConfirm: () => {},
  body: (
    <>
      <FormattedMessage
        id="redirected-warning-message"
        description="Warning about redirection"
        defaultMessage="It looks like you're trying to access Story Studio using an old URL. We have redirected you to the correct URL. {br}Please update your bookmarks."
        values={{
          br: <br />,
        }}
      />
    </>
  ),
};
