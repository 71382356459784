// Min resolution for uploaded media
export const MIN_WIDTH = 540;
export const MIN_HEIGHT = 960;

// Max resolution for uploaded media (4K)
export const MAX_WIDTH = 1080 * 2;
export const MAX_HEIGHT = 1920 * 2;

// Duration restrictions for uploaded media
export const MIN_DURATION_SECS = 5;
export const MAX_DURATION_MINS = 5;
export const MAX_TOTAL_DURATION_SECS = 300;
// Accepted Media content-types
export const ACCEPTED_VIDEO_ONLY_FILE_TYPES = ['video/mp4', 'video/quicktime', 'video/webm'];

// Story snap player video height is determined based on browser height
// We allow maximum of 640 px and when the video is smaller than 500px we render a minimal video player
export const MIN_VIDEO_HEIGHT = 500;
export const MAX_VIDEO_HEIGHT = 640;
export const VIDEO_PADDING = 48; // Space left to render video after all the paddings, headers etc. Video height is windowHeight - VIDEO_PADDING
export const DEFAULT_ASPECT_RATIO = {
  width: 9,
  height: 16,
};

export const MAX_DESCRIPTION_CHAR_LENGTH = 160;
