import _ from 'lodash';
import type { Store } from 'redux';

import * as userActions from 'state/user/actions/userActions';
import { LocaleID, LOCALES, DEFAULT_LOCALE } from 'state/user/userState';

import { LocalStorage } from 'config/constants';

import { localStorage } from './localStorageUtils';

const localeAbbrSet = new Set<string>(LOCALES.map(locale => locale.abbr));

export const isValidLocale = (localeId: string): boolean => localeAbbrSet.has(localeId);
export function coerceLocale(localeId: string): LocaleID | null {
  if (isValidLocale(localeId)) {
    return localeId as LocaleID;
  }

  // http://www.ietf.org/rfc/bcp/bcp47.txt
  //  obs-language-tag = primary-subtag *( "-" subtag )
  //  primary-subtag   = 1*8ALPHA
  //  subtag           = 1*8(ALPHA / DIGIT)

  // Match en-US or other lang-SUBTAG combinations, remove subtag
  const longLocaleMatch = /^([a-z]{1,8})-[a-z0-9]{1,8}$/i.exec(localeId);

  if (!longLocaleMatch || !longLocaleMatch[1]) {
    return null;
  }

  const potentialLocale = longLocaleMatch[1].toLowerCase();

  if (isValidLocale(potentialLocale)) {
    return potentialLocale as LocaleID;
  }

  return null;
}

export function getLocaleOnInit() {
  const fromLocalStorage = exports.coerceLocale(localStorage.getItem(LocalStorage.LOCALE_ID));
  if (isValidLocale(fromLocalStorage)) {
    return fromLocalStorage;
  }

  const fromNavigator = exports.coerceLocale(_.get(window, ['navigator', 'language']));
  if (isValidLocale(fromNavigator)) {
    return fromNavigator;
  }

  return DEFAULT_LOCALE.abbr;
}

export async function initializeLocale(store: Store<{}, any>) {
  const locale = getLocaleOnInit();

  if (isValidLocale(locale)) {
    await store.dispatch(userActions.setLocale(locale));
  }
}
