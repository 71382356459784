import { EditionStageType } from 'config/constants';

import type { Stage } from 'types/stages';

type AdSlotsStage = Stage<
  {
    adSnapIndexes: number[];
  },
  {},
  void
>;

export const AD_SLOTS_STAGE_CONFIG: AdSlotsStage = {
  stageName: EditionStageType.AD_SLOTS,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: ['adSnapIndexes'],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => properties,
};
