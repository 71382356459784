import { getAuthType } from 'state/auth/selectors/authSelectors';

import {
  PageLoadParams,
  capturePageLoadMetrics,
  PagePerformanceMarkers,
  PerformanceMarkerMetrics,
} from 'utils/performance/performanceUtils';

import { Store } from 'types/redux';

export const isRouteActive = (path: any, currentPathname: any, options: any = { exact: true }) => {
  if (options.exact) {
    return path === currentPathname;
  }
  return currentPathname.startsWith(path);
};

//  We use performance.timeOrigin API to get the time from the navigation start and fall back to
// 'initialLoadingTiming' is set in index.html so we use it to capture the bundle download/eval time
let lastTimestamp = performance?.timeOrigin || (global as any).initialLoadingTiming || Date.now();
let firstTimestamp = lastTimestamp;

// Used for testing
export const resetTiming = () => {
  lastTimestamp = Date.now();
  firstTimestamp = lastTimestamp;
};

const logTiming = (params: PageLoadParams, startTime: number, authType: string | null = '') => {
  const path = params.path || (params.match && params.match.path);
  capturePageLoadMetrics(path, startTime, params, firstTimestamp || lastTimestamp, authType);
};

export const wrapInTiming = (promiseGenerator: any) => (params: any) => {
  const startTime = Date.now();
  if (lastTimestamp === null) {
    lastTimestamp = startTime;
  }
  return promiseGenerator(params)
    .then(() => logTiming(params, startTime))
    .catch((error: any) => {
      // Needs to clear timer if we set it or will spoil next timing values
      if (lastTimestamp === startTime) {
        lastTimestamp = null;
      }
      throw error;
    });
};

export const wrapInTimingWithStore = (promiseGenerator: any, store: Store) => (params: any) => {
  const startTime = Date.now();
  const authType = getAuthType(store.getState());
  if (lastTimestamp === null) {
    lastTimestamp = startTime;
  }
  performance?.mark(PagePerformanceMarkers.ROUTE_START_LOAD);
  return promiseGenerator(params)
    .then(() => {
      performance?.mark(PagePerformanceMarkers.ROUTE_END_LOAD);
      performance?.measure(
        PerformanceMarkerMetrics.ROUTE,
        PagePerformanceMarkers.ROUTE_START_LOAD,
        PagePerformanceMarkers.ROUTE_END_LOAD
      );
      logTiming(params, startTime, authType);
    })
    .catch((error: any) => {
      // Needs to clear timer if we set it or will spoil next timing values
      performance?.mark(PagePerformanceMarkers.ROUTE_END_LOAD);
      if (lastTimestamp === startTime) {
        lastTimestamp = null;
      }
      throw error;
    });
};

export const gaTiming = wrapInTiming(() => Promise.resolve());
