import invariant from 'invariant';
import { sortBy, identity, uniq, get, isEqual } from 'lodash';
import log from 'loglevel';

import { getShouldShowAdMarkers } from 'state/adMarkers/selectors/adMarkersSelectors';
import * as apiMiddlewareActions from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { loadAssetInfo } from 'state/asset/actions/assetActions';
import { getAssetById } from 'state/asset/selectors/assetSelectors';
import { setEditionPropertiesAndSave, getSnapIds, setSnapIds } from 'state/editions/actions/editionsActions';
import {
  getEditionById,
  getEditionSavingById,
  getEditionLoadingById,
  getEditionSubscribeSnap,
} from 'state/editions/selectors/editionsSelectors';
import { updateSingleAssetEditorState, discardSingleAssetEditorState } from 'state/editor/actions/editorActions';
import { getActiveWholeSnapId } from 'state/editor/selectors/editorSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { setEditorMode } from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import {
  getTranscodedMediaIdForStory,
  shouldUseSingleSnapBuilder,
} from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { ReplaceStoryMediaParams } from 'state/publisherTools/actions/publisherToolsActions';
import { goToPublisherStoryEditor, goToSnap } from 'state/router/actions/routerActions';
import { shotAdSlotsSchema } from 'state/singleAssetStory/schema/shotAdSlotsSchema';
import {
  getShotsForStory,
  getTimelineAds,
  getSingleAssetPlayerState,
  getDefaultActiveTab,
} from 'state/singleAssetStory/selectors/singleAssetStorySelectors';
import { loadSnap } from 'state/snaps/actions/snapsActions';
import { isSubscribeSnap } from 'state/snaps/schema/snapEntityHelpers';
import { getSnapById } from 'state/snaps/selectors/snapsSelectors';

import { EDITOR_MODES } from 'config/constants';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as scsAPI from 'utils/apis/scsAPI';
import { ModalType } from 'utils/modalConfig';
import {
  getPlayingSnapOrShot,
  getMockAdTimeInMs,
  getNextSnapOrShot,
  getPreviousSnapOrShot,
} from 'utils/singleAssetStoryEditorUtils';

import { AssetID, AssetType } from 'types/assets';
import type { SnapId } from 'types/common';
import type { Edition, EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';
import type { Dispatch, GetState } from 'types/redux';
import type { ChapterSummary, ExtendedShot, TimelineSnap } from 'types/singleAssetStoryEditor';
import { SnapRelationship } from 'types/snaps';

export const LOAD_SHOT_AD_SLOTS = 'singleAssetStory/LOAD_SHOT_AD_SLOTS';
export const SNAPID_POLLING_INTERVAL = 10 * 1000;

export const loadStoryShotAdSlots = (storyId: EditionID) => {
  return (dispatch: Dispatch) => {
    return dispatch(
      apiMiddlewareActions.createCallAction(
        {
          type: LOAD_SHOT_AD_SLOTS,
          params: { storyId },
          meta: { schema: shotAdSlotsSchema },
        },
        {
          method: 'GET',
          endpoint: scsAPI.story.loadStoryShotAdSlots({ storyId }),
          finalizer: optimisticJsonFinalizer,
        }
      )
    );
  };
};

let pollForSnapUpdatesInterval: any = null;

export const initializeSingleAssetStory = (publisherId: PublisherID, storyId: EditionID) => {
  return (dispatch: Dispatch, getState: GetState) => {
    dispatch(loadStoryShotAdSlots(storyId));
    dispatch(
      updateSingleAssetEditorState(storyId, {
        videoPlayer: {
          isPlaying: false,
        },
        activeConfigTab: getDefaultActiveTab(getState())(storyId),
        isShowingAd: false,
        isInDebugMode: false,
        isZoomed: false,
        isEditing: false,
      })
    );

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    dispatch(setEditorMode(EDITOR_MODES.EDITOR));

    pollForSnapUpdatesInterval = setInterval(
      () => dispatch(pollForSnapUpdates(publisherId, storyId)),
      SNAPID_POLLING_INTERVAL
    );

    const transcodedMediaId = getTranscodedMediaIdForStory(getState())(storyId);

    if (transcodedMediaId) {
      dispatch(loadAssetInfoAndUpdatePlayer(storyId, transcodedMediaId));
    } else {
      log.error(`No video track found for storyId: ${storyId}`);
    }
  };
};

function loadAssetInfoAndUpdatePlayer(storyId: EditionID, transcodedMediaId: AssetID) {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(loadAssetInfo(transcodedMediaId));

    const asset = getAssetById(getState())(transcodedMediaId);
    if (asset?.type === AssetType.VIDEO) {
      dispatch(
        updateSingleAssetEditorState(storyId, {
          videoPlayer: {
            totalDuration: asset.durationMillis,
          },
        })
      );
    }
  };
}

export function terminateSingleAssetStory(storyId: EditionID) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (pollForSnapUpdatesInterval) {
      clearInterval(pollForSnapUpdatesInterval);
      pollForSnapUpdatesInterval = null;
    }

    return dispatch(discardSingleAssetEditorState(storyId));
  };
}

export function pollForSnapUpdates(publisherId: PublisherID, storyId: EditionID) {
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const isLoading = getEditionLoadingById(getState())(storyId);
    const isSaving = getEditionSavingById(getState())(storyId);
    if (!isLoading && !isSaving) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
      return dispatch(getSnapIds({ editionId: storyId })).then((result: any) => {
        const snapIdsChanged = !isEqual(get(result, 'payload'), result.params.previousSnapIds);

        if (!snapIdsChanged) {
          return Promise.resolve();
        }

        const activeSnapId = getActiveWholeSnapId(getState());
        const videoPlayer = getSingleAssetPlayerState(getState())(storyId);

        return Promise.all([
          dispatch(loadStoryShotAdSlots(storyId)),
          dispatch(setActiveSnapBasedOnTimeAndAd(publisherId, storyId, activeSnapId, videoPlayer.currentTime)),
        ]);
      });
    }

    return Promise.resolve();
  };
}

const fromShotIndexToSnapIndex = (shotIndex: number, shotsForStory: ExtendedShot[], story: Edition): number => {
  invariant(shotIndex < shotsForStory.length, `Shot index ${shotIndex} out of bounds`);
  const shot = shotsForStory[shotIndex];
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const snapId = shot.snapId;

  // This is a O(n) operation. It's only called 2 times below so not currently worth optimizing. Reassess if that changes.
  const snapIndex = story.snapIds.indexOf(snapId);
  invariant(snapIndex > -1, 'Invalid snap index');
  return snapIndex;
};

export const updateAdSnapIndexes = (storyId: EditionID, newAdSnapIndexes: number[]): any => {
  return (dispatch: Dispatch, getState: GetState) => {
    return dispatch(setEditionPropertiesAndSave({ editionId: storyId }, { adSnapIndexes: newAdSnapIndexes })).then(() =>
      dispatch(loadStoryShotAdSlots(storyId))
    );
  };
};

export const moveShotAdSlot = (storyId: EditionID, fromShotAdSlotIndex: number, toShotAdSlotIndex: number) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const shotsForStory = getShotsForStory(getState())(storyId);
    const story = getEditionById(getState())(storyId);
    invariant(story, 'story not found');
    const adIndexes = getTimelineAds(getState())(storyId).map(timelineAd => {
      return fromShotIndexToSnapIndex(timelineAd.shotIndex, shotsForStory, story);
    });
    const fromSnapAdSlotIndex = fromShotIndexToSnapIndex(fromShotAdSlotIndex, shotsForStory, story);
    const toSnapAdSlotIndex = fromShotIndexToSnapIndex(toShotAdSlotIndex, shotsForStory, story);

    invariant(adIndexes.includes(fromSnapAdSlotIndex), 'Moving ad slot that is not set');

    const newAdSnapIndexes = uniq(
      sortBy(
        adIndexes.map(index => {
          if (index === fromSnapAdSlotIndex) {
            return toSnapAdSlotIndex;
          }

          return index;
        }),
        identity
      )
    );

    return dispatch(updateAdSnapIndexes(storyId, newAdSnapIndexes));
  };
};

export function updateVideoPlayerTimeAndCheckForAd(
  timeInMs: number,
  snapId: SnapId | undefined | null,
  storyId: EditionID,
  publisherId: PublisherID
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const videoPlayer = getSingleAssetPlayerState(state)(storyId);

    if (timeInMs !== videoPlayer.currentTime) {
      dispatch(setActiveSnapBasedOnTimeAndAd(publisherId, storyId, snapId, timeInMs < 0 ? 0 : timeInMs));
    }
  };
}

function setActiveSnapBasedOnTimeAndAd(
  publisherId: PublisherID,
  storyId: EditionID,
  currentSnapId: SnapId | undefined | null,
  timeInMs: number
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const videoPlayer = getSingleAssetPlayerState(state)(storyId);

    const currentSnap = currentSnapId ? getSnapById(getState())(currentSnapId) : null;

    // Not making changes if current time was not changed and on subscribe snap
    // We consider the initial state `undefined` to be the same as zero.
    const shouldUpdateTimeline =
      !(currentSnap && isSubscribeSnap(currentSnap)) || (videoPlayer.currentTime || 0) !== timeInMs;

    const ads = getTimelineAds(state)(storyId);
    const shots = getShotsForStory(state)(storyId);
    const mockAdTimeInMs =
      getShouldShowAdMarkers(state) && getMockAdTimeInMs(timeInMs, shots, videoPlayer.isPlaying, ads);

    const isShowingAd = Boolean(mockAdTimeInMs);

    // When showing ad, pause video
    const isPlaying = isShowingAd ? false : videoPlayer.isPlaying;

    if (shouldUpdateTimeline) {
      if (isShowingAd) {
        dispatch(
          goToPublisherStoryEditor({
            publisherId,
            editionId: storyId,
            overwriteHistory: false,
          })
        );
      } else {
        dispatch(setSnapForTime(mockAdTimeInMs || timeInMs, shots, currentSnapId, publisherId, storyId));
      }
    }

    dispatch(
      updateSingleAssetEditorState(storyId, {
        isShowingAd,
        videoPlayer: {
          isPlaying,
          currentTime: mockAdTimeInMs || timeInMs,
        },
      })
    );
  };
}

export function setSnapForTime(
  videoPlayerCurrentTime: number,
  shots: ExtendedShot[],
  currentSnapId: SnapId | undefined | null,
  publisherId: PublisherID,
  editionId: EditionID
) {
  const playingShot = getPlayingSnapOrShot(videoPlayerCurrentTime, shots);
  const playingSnapId = get(playingShot, 'snapId', null);

  return (dispatch: Dispatch, getState: GetState) => {
    if (playingSnapId) {
      if (playingSnapId !== currentSnapId) {
        dispatch(
          goToSnap({
            publisherId,
            editionId,
            snapId: playingSnapId,
            overwriteHistory: true,
          })
        );
      }
    } else if (currentSnapId) {
      // we are on empty part of timeline (no snaps)
      dispatch(goToPublisherStoryEditor({ publisherId, editionId, overwriteHistory: false }));

      // if clicking on empty timeline, from subscribe snap, set active tab to default
      dispatch(
        updateSingleAssetEditorState(editionId, {
          activeConfigTab: getDefaultActiveTab(getState())(editionId),
        })
      );
    }
  };
}

export const goToNextSnapOrShot = (
  storyId: EditionID,
  currentTime: number,
  items: TimelineSnap[] | ExtendedShot[] | ChapterSummary[]
) => {
  return (dispatch: Dispatch) => {
    const nextItem = getNextSnapOrShot(currentTime, items);

    if (nextItem) {
      dispatch(
        updateSingleAssetEditorState(storyId, {
          videoPlayer: {
            pendingCurrentTime: nextItem.startTimeMs,
          },
        })
      );
    }
  };
};

export const goToPreviousSnapOrShot = (
  storyId: EditionID,
  currentTime: number,
  items: TimelineSnap[] | ExtendedShot[] | ChapterSummary[]
) => {
  return (dispatch: Dispatch) => {
    const previousItem = getPreviousSnapOrShot(currentTime, items);

    if (previousItem) {
      dispatch(
        updateSingleAssetEditorState(storyId, {
          videoPlayer: {
            pendingCurrentTime: previousItem.startTimeMs,
          },
        })
      );
    }
  };
};

const onUnifiedReplace = (edition: Edition) => async (dispatch: Dispatch) => {
  // For unified single asset video track is not saved on the story level therefore we need to refetch the main snap.
  const mainSnapId = edition.snapIds[0];
  if (!mainSnapId) {
    return;
  }
  await dispatch(loadSnap({ snapId: mainSnapId }));
};

const onLegacyReplace = (storyId: EditionID) => async (dispatch: Dispatch, getState: GetState) => {
  // Once the video is updated, non-subscribe snaps need to be cleared to have them refetched when they are assigned new shots.
  const subscribeSnap = getEditionSubscribeSnap(getState())(storyId);
  const subscribeSnapId = subscribeSnap?.relatedSnapIds[SnapRelationship.TOP] || null;
  dispatch(setSnapIds({ editionId: storyId, snapIds: subscribeSnapId ? [subscribeSnapId] : [] }));
};

export const replaceStoryMediaPrompt = (storyId: EditionID, publisherId: PublisherID) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const edition = getEditionById(getState())(storyId);
    if (!edition) {
      return Promise.resolve();
    }
    // Since videoTrack is saved differently for unified stories we need to perform different clean up.
    const isUnifiedStory = shouldUseSingleSnapBuilder(getState())(storyId);
    const onReplace = isUnifiedStory ? onUnifiedReplace(edition) : onLegacyReplace(storyId);
    return dispatch(
      showModal(ModalType.REPLACE_STORY_MEDIA, 'replaceStoryMediaPrompt', {
        publisherId,
        storyId,
        onUpdateStory: async ({ singleAssetVideoId, discardMetadata }: ReplaceStoryMediaParams) => {
          // Video track id will be generated for the new media.
          const updatedProperties = {
            videoTracks: [{ id: '', transcodedMediaId: singleAssetVideoId }],
            discardMetadata,
          };
          await dispatch(setEditionPropertiesAndSave({ editionId: storyId }, updatedProperties));
          await dispatch(onReplace);
        },
      })
    );
  };
};
