import is from 'is_js';
import log from 'loglevel';
import { Middleware } from 'redux';

import { hasActionExpired } from 'state/actions/selectors/actionSelectors';

import { Sequence } from 'config/constants';
import { rejectWithErrorAction, onExpiredAction } from 'utils/middlewareUtils';
import { isPromise } from 'utils/promiseUtils';
// Modeled after https://github.com/pburtchaell/redux-promise-middleware/blob/2.4.0/src/index.js
/*
 Copyright 2015-current Patrick Burtchaell <patrick@pburtchaell.com>

 The above copyright notice and this permission notice shall be included
 in all copies or substantial portions of the Software.

 THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS
 OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF
 MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
 IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY
 CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT,
 TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
 SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */
const isThunk = is.function;
/**
 * @function promiseMiddleware
 * @description
 * @returns {function} thunk
 */
// Promise middleware only accepts a promise or a function that returns a promise
const isHandledByPromiseMiddleware = (arg: any) => isPromise(arg) || is.function(arg);
export default function promiseMiddleware(config: {} = {}): Middleware {
  return ({ dispatch, getState }: any) => {
    return (next: any) => (action: any) => {
      if (!action.payload || !isHandledByPromiseMiddleware(action.payload.promise) || action.error) {
        return next(action);
      }
      const { type, payload, meta, params, actionId } = action;
      const { promise, data } = payload;
      /**
       * Dispatch the first async handler. This tells the
       * reducer that an async action has been dispatched.
       */
      next({
        type,
        sequence: Sequence.START,
        ...(!data ? {} : { payload: data }),
        ...(!meta ? {} : { meta }),
        ...(!params ? {} : { params }),
        actionId,
      });
      const doneAction = {
        type,
        sequence: Sequence.DONE,
        ...(!meta ? {} : { meta }),
        ...(!params ? {} : { params }),
      };
      let promiseResult = promise;
      if (is.function(promise)) {
        promiseResult = promise();
        // If the function does not return a promise, abort further actions and pass to next. Preventing to fix the warning,
        // can cause unexpected store behavior as we already dispatch action.start that cannot be undone
        if (!isPromise(promiseResult)) {
          const errorMessage = `Action of type ${action.type} passed a function that does not return a promise.`;
          log.error(errorMessage, 'Please, fix this');
          return dispatch({
            ...doneAction,
            error: errorMessage,
          });
        }
      }
      /**
       * Re-dispatch one of:
       *  1. a thunk, bound to a resolved/rejected object containing ?meta and type
       *  2. a resolve/rejected action with the resolve/rejected object as a payload
       */
      return promiseResult
        .then((resolved = {}) => {
          if (hasActionExpired(action)(getState())) {
            return onExpiredAction(action);
          }
          const resolvedAction = {
            payload: undefined,
            ...doneAction,
            error: null,
          };
          if (isThunk(resolved)) {
            return dispatch((resolved as any).bind(null, resolvedAction));
          }
          if (resolved) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{}' is not assignable to type 'undefined'.
            resolvedAction.payload = resolved;
          }
          return dispatch(resolvedAction);
        })
        .catch((caughtObj: any) => {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
          return rejectWithErrorAction(caughtObj, {
            ...doneAction,
            payload: null,
            actionId,
          })(getState);
        });
    };
  };
}
