import _ from 'lodash';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import updeep from 'updeep';

import { assertArg } from 'utils/assertionUtils';

/*
  Requires an object to avoid replacing the entire tree on accident.

  Originally, updeep's behavior if the diffObject is a value is to return that
  value.

  So updeep(5, {}) === 5

  This can create a problem when say normalizr doesn't find entities of a certain
  type. Which would results in the call:

    updeep(action.payload.entities.widgets, {})

  returning a value of undefined.

  So now our store of widgets contains undefined instead of all of the widgets we had
  stored from before. This is obviously not ideal.

  This wrapper:
    Accepts falsey values as a noop.
    Throws on any truthy values that are not objects.

    Provides updeep.assert
      which asserts that both the diffObject and the originalObject are objects.
    Provides updeep.any
      which preserves the existing functionality.
    Copies all other properties from updeep to provide those functions as normal.
*/

const replacementUpdeep: any = function replacementUpdeep(diffObject: any, originalObject: any) {
  return assertUpdeep(diffObject || {}, originalObject);
};

export default replacementUpdeep;

// Note: This function still accepts arrays as they pass is.object
function assertUpdeep(diffObject: any, originalObject: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(diffObject).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(originalObject).is.object();

  return updeep(diffObject, originalObject);
}

function allowAny(...args: any[]) {
  return updeep.apply(updeep, args);
}

Object.keys(updeep).forEach(key => {
  replacementUpdeep[key] = updeep[key];
});

replacementUpdeep.assert = assertUpdeep;
replacementUpdeep.any = allowAny;

// Replaces only if objects are not deep equal
replacementUpdeep.ifDiff = (proposedStateChange: any) => {
  return updeep.if((currentState: any) => !_.isEqual(currentState, proposedStateChange), proposedStateChange);
};

replacementUpdeep.ifDiffConstant = (proposedStateChange: any) => {
  return updeep.if(
    (currentState: any) => !_.isEqual(currentState, proposedStateChange),
    updeep.constant(proposedStateChange)
  );
};

export const u = replacementUpdeep;
