import { createSelector as s } from 'reselect';

import { EMPTY_OBJECT } from 'config/constants';

import type { State } from 'types/rootState';

const getAutocomplete = (state: State) => {
  return state.autocomplete || EMPTY_OBJECT;
};

export const getSccTags = s(getAutocomplete, autocomplete => {
  return autocomplete?.sccTags || EMPTY_OBJECT;
});

export const getSccCodeToTextMapping = s(getSccTags, sccTags => {
  const filteredTag: { [key: string]: string } = {};
  Object.entries(sccTags).forEach(([key, tag]) => {
    filteredTag[key] = tag.displayName;
  });

  return filteredTag;
});
