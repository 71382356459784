/* eslint-disable camelcase */

import {
  AdminPublisherDetailsQuery,
  AdminPublisherDetailsQueryVariables,
  AdminPublisherDetailsQuery_publisher,
} from '../queries/publisher/__ssp-generated__/AdminPublisherDetailsQuery';
import {
  AnalyticsPublisherDetailsQuery,
  AnalyticsPublisherDetailsQueryVariables,
  AnalyticsPublisherDetailsQuery_publisher,
} from '../queries/publisher/__ssp-generated__/AnalyticsPublisherDetailsQuery';
import {
  EditionsPublisherDetailsQuery,
  EditionsPublisherDetailsQueryVariables,
  EditionsPublisherDetailsQuery_publisher,
} from '../queries/publisher/__ssp-generated__/EditionsPublisherDetailsQuery';
import {
  HomePublisherDetailsQuery,
  HomePublisherDetailsQueryVariables,
  HomePublisherDetailsQuery_publisher,
} from '../queries/publisher/__ssp-generated__/HomePublisherDetailsQuery';
import {
  MediaPublisherDetailsQuery,
  MediaPublisherDetailsQueryVariables,
  MediaPublisherDetailsQuery_publisher,
} from '../queries/publisher/__ssp-generated__/MediaPublisherDetailsQuery';
import {
  RootPublisherDetailsQuery,
  RootPublisherDetailsQueryVariables,
  RootPublisherDetailsQuery_publisher,
} from '../queries/publisher/__ssp-generated__/RootPublisherDetailsQuery';
import {
  SettingsPublisherDetailsQuery,
  SettingsPublisherDetailsQueryVariables,
  SettingsPublisherDetailsQuery_publisher,
} from '../queries/publisher/__ssp-generated__/SettingsPublisherDetailsQuery';

export enum PublisherQueryType {
  Admin = 'ADMIN',
  Home = 'HOME',
  Analytics = 'ANALYTICS',
  Media = 'MEDIA',
  Settings = 'SETTINGS',
  Root = 'ROOT',
  Editions = 'EDITIONS',
}

export type PublisherQueryResponseType =
  | AdminPublisherDetailsQuery
  | HomePublisherDetailsQuery
  | AnalyticsPublisherDetailsQuery
  | MediaPublisherDetailsQuery
  | SettingsPublisherDetailsQuery
  | RootPublisherDetailsQuery
  | EditionsPublisherDetailsQuery;

export type PublisherQueryVarType =
  | AdminPublisherDetailsQueryVariables
  | HomePublisherDetailsQueryVariables
  | AnalyticsPublisherDetailsQueryVariables
  | MediaPublisherDetailsQueryVariables
  | SettingsPublisherDetailsQueryVariables
  | RootPublisherDetailsQueryVariables
  | EditionsPublisherDetailsQueryVariables;

export type PublisherQueryResponsePublisherType =
  | AdminPublisherDetailsQuery_publisher
  | HomePublisherDetailsQuery_publisher
  | AnalyticsPublisherDetailsQuery_publisher
  | MediaPublisherDetailsQuery_publisher
  | SettingsPublisherDetailsQuery_publisher
  | RootPublisherDetailsQuery_publisher
  | EditionsPublisherDetailsQuery_publisher;
