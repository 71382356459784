import React from 'react';
import { createSelector as s } from 'reselect';

import {
  audienceToLongString,
  shouldShowModeration,
  inModerationSubscribersOnly,
  tilesInModerationQeueue,
  storyReachAffected,
} from 'state/buildStatus/schema/moderationHelpers';
import {
  getStoryAudience,
  getStoryModerationScope,
  getStoryModerationStatus,
} from 'state/buildStatus/selectors/buildStatusSelectors';
import {
  isHighTileViolation,
  isModerateTileViolation,
  isSubscriberOnlyTileViolation,
} from 'state/features/selectors/featuresSelectors';
import { areAllPresentationTilesForEditionFlaggedByModeration } from 'state/publisherTools/selectors/publisherToolsSelectors';

import { StoryModerationScope, StoryModerationStatus, TileViolationLevel } from 'types/moderation';

export const getStoryModerationMessage = s(
  getStoryModerationStatus,
  getStoryAudience,
  areAllPresentationTilesForEditionFlaggedByModeration,
  getStoryModerationScope,
  (storyModerationStatusFn, storyAudienceListFn, allTilesFlaggedByModerationFn, storyModerationScopeFn) => (
    storyId: any
  ) => {
    if (storyModerationStatusFn(storyId) === StoryModerationStatus.NONE) {
      return null;
    }

    if (storyModerationStatusFn(storyId) === StoryModerationStatus.IN_PROGRESS) {
      switch (storyModerationScopeFn(storyId)) {
        case StoryModerationScope.WHOLE_STORY:
          return inModerationSubscribersOnly;
        case StoryModerationScope.TILES_ONLY:
          return tilesInModerationQeueue;
        case StoryModerationScope.UNSET:
        default:
          return null;
      }
    }

    // If we reached to here, story is already moderated
    const storyAudienceList = storyAudienceListFn(storyId);
    if (shouldShowModeration(storyAudienceList)) {
      return (
        <>
          {storyAudienceList &&
            storyAudienceList.map((audience: any, i: any) => {
              const key = `audience-${i}`;
              return <div key={key}>{audienceToLongString(audience)}</div>;
            })}
        </>
      );
    }

    // The story may not be flagged by moderation, but all leading tiles might still be flagged
    if (allTilesFlaggedByModerationFn(storyId)) {
      return storyReachAffected;
    }

    return null;
  }
);

export const getTileViolationLevel = s(
  isModerateTileViolation,
  isHighTileViolation,
  isSubscriberOnlyTileViolation,
  (isModerate, isHigh, isSubscriberOnly) => {
    if (isSubscriberOnly) {
      return TileViolationLevel.SUBSCRIBER_ONLY;
    }
    if (isHigh) {
      return TileViolationLevel.HIGH;
    }
    if (isModerate) {
      return TileViolationLevel.MODERATE;
    }
    return TileViolationLevel.NONE;
  }
);
