import { combineReducers } from 'redux';

import {
  createSequenceCountingByIdReducer,
  createSequenceHandlingReducer,
  serializeReducers,
} from 'state/common/reducerFactories';
import {
  CREATE_FEED,
  DELETE_FEED,
  EXECUTE_ALL_FEEDS_BY_OWNER_ID,
  EXECUTE_FEED,
  GET_FEEDS_BY_OWNER_ID,
  UPDATE_FEED,
} from 'state/feeds/actions/feedsActions';

import { EMPTY_OBJECT } from 'config/constants';
import u from 'utils/safeUpdeep';

const byId = serializeReducers([
  createSequenceHandlingReducer(EMPTY_OBJECT, [GET_FEEDS_BY_OWNER_ID], {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      const feeds = action.payload.feeds;

      const feedsUpdate = feeds.reduce((updates: any, feed: any) => {
        updates[feed.id] = u.ifDiffConstant(feed); // eslint-disable-line no-param-reassign
        return updates;
      }, {});
      const updatedFeeds = u(feedsUpdate, state);

      const sweptFeeds = u.omitBy((value: any, key: any) => !feedsUpdate[key], updatedFeeds);

      return sweptFeeds;
    },
  }),
  createSequenceHandlingReducer(EMPTY_OBJECT, [CREATE_FEED, EXECUTE_ALL_FEEDS_BY_OWNER_ID, EXECUTE_FEED, UPDATE_FEED], {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      const feeds = action.payload.feeds || [action.payload.feed];

      const feedsUpdate = feeds.reduce((updates: any, feed: any) => {
        updates[feed.id] = u.ifDiffConstant(feed); // eslint-disable-line no-param-reassign
        return updates;
      }, {});

      return u(feedsUpdate, state);
    },
  }),
  createSequenceHandlingReducer(EMPTY_OBJECT, [DELETE_FEED], {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      return u.omit(action.feedId, state);
    },
  }),
]);

const byOwnerIdLoading = createSequenceCountingByIdReducer('ownerId', [GET_FEEDS_BY_OWNER_ID]);

export default combineReducers({
  byId,
  byOwnerIdLoading,
});
