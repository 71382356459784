import { merge } from 'lodash';
import { Schema } from 'normalizr';

export type MetaType = {
  '@api/CALL_API'?: {};
  schema?: Schema;
};

type ReduxAction = {
  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name '$Subtype'.
  type: $Subtype<string>;
  meta?: MetaType;
};

// We define CallAction as an opaque type to make it easier for types/redux.js to distinguish between
// dispatch(callAction) versus dispatch(action), where `action` is a simple action.

// Using flow comment syntax because WebStorm and ESLint don't understand the opaque keyword yet
export type /* :: opaque */ CallAction<A extends ReduxAction> = A & {
  meta: MetaType;
};
export type DoneAction<A extends ReduxAction> = A & {
  payload: any;
};

type CallOptions = {
  body?: unknown;
  endpoint: string;
  finalizer?: (input: unknown) => unknown;
  method?: string;
  preprocessor?: (request: unknown) => unknown;
  credentials?: string;
  redirect?: string;
};

export function createCallAction<A extends ReduxAction>(action: A, options: CallOptions): CallAction<A> {
  const spreadMeta = typeof action.meta === 'object' ? action.meta : {};
  const meta: MetaType = {
    ...spreadMeta,
    '@api/CALL_API': options,
  };

  return merge(action, { meta });
}
