import graphene, { BaseLogParam, PARTITION } from '@snapchat/graphene';
import type { Dimensions, Graphene } from '@snapchat/graphene';
import getWebConfig from '@snapchat/graphene/lib/config/web';
import BrowserNetworkHandler from '@snapchat/graphene/lib/network/BrowserNetworkHandler';
import _ from 'lodash';
import log from 'loglevel';

import { isShowPublisher } from 'state/publishers/schema/publisherEntityHelpers';

import { ENV, Environments } from 'config/constants';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

import { isLocalhost } from './locationUtils';

import { Publisher, PublisherType } from 'types/publishers';

const shouldReportMetrics: Boolean = !isLocalhost();
export type LogParam = BaseLogParam & { milliSec: number };

function getGrapheneFlavor() {
  if (isLocalhost()) {
    return 'local';
  }
  if (process.env.IS_SELENIUM) {
    return 'selenium';
  }

  return ENV === Environments.PROD ? 'production' : 'development';
}

export const initializeGraphene = (debugMode: boolean = false) => {
  const flavor = getGrapheneFlavor();
  const revision = isLocalhost() ? 'local' : process.env.REVISION || 'local';
  const config = getWebConfig({
    partitionName: PARTITION.DISCOVER_CMS,
    flavor,
  });
  log.info('Graphene Init: flavor:', flavor, 'revision:', revision);

  if (shouldReportMetrics) {
    graphene.initialize({
      networkHandler: new BrowserNetworkHandler(config),
      logTimeInterval: 5 * 1000,
      debugMode,
    });
    // Sending a heart beat counter event every minute
    graphene.sendHeartBeatCounter(revision);
  }
};
/*
Use this sparingly Global objects can:
- adds hidden dependency between files
- risks of memory leaks due to uncollected garbage stacking up
To avoid these problems we enforce all global metrics to be registered under the enum below
 */
export const GlobalTimer = enumObject({
  FIRST_PAGE_LOAD: 'first_page_load',
});
export type GlobalTimerEnum = Enum<typeof GlobalTimer>;
export class GlobalTimerManager {
  grapheneImpl: Graphene;

  startedTimers: any;

  constructor(grapheneImpl: Graphene, startedTimers: any = {}) {
    this.grapheneImpl = grapheneImpl;
    this.startedTimers = startedTimers;
  }

  startGlobalTimer = (timerKey: GlobalTimerEnum, explicitStartTime?: number) => {
    const timerStartTime = explicitStartTime || Date.now();
    this.startedTimers[timerKey] = timerStartTime;
  };

  endGlobalTimer = (timerKey: GlobalTimerEnum) => {
    if (this.startedTimers[timerKey]) {
      const timeToLog = Date.now() - this.startedTimers[timerKey];
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type 'Dimensions ... Remove this comment to see the full error message
      this.grapheneImpl.addTimer({ metricsName: timerKey, dimensions: null, milliSec: timeToLog });
      delete this.startedTimers[timerKey];
    }
  };
}
/*
Create local metrics by just doing:
 this.stopwatch = grapheneUtils.createTimer();
and stop with:
 this.stopwatch('my_custom_key', dimensions?);
 */
export function createTimer() {
  const timer = Date.now();
  return _.once((metricsName: string, dimensions: Dimensions | undefined = undefined) => {
    const timeElapsed = Date.now() - timer;
    if (shouldReportMetrics) {
      reportTimerWithDuration({
        metricsName,
        dimensions,
        milliSec: timeElapsed,
      });
    }
    return timeElapsed;
  });
}
// Just a few wrappers to not directly import Graphene everywhere
export function incrementCounter(
  metricsName: string,
  dimensions: Dimensions | undefined = undefined,
  value: number = 1
) {
  if (shouldReportMetrics) {
    graphene.increment({ metricsName, dimensions, value });
  }
}
export function reportTimer(metricsName: string, dimensions: Dimensions | undefined = undefined, startTime: number) {
  reportTimerWithDuration({
    metricsName,
    dimensions,
    // For older browser performance API is not supported
    // Fall back to Date.now with a lesser precision
    milliSec: Date.now() - startTime,
  });
}

export function reportTimerWithDuration(logParam: LogParam) {
  if (shouldReportMetrics) {
    /*
     * Graphene throws an exception resulting to a crash
     * when supplied with floating number we always convert to an integer
     * */
    graphene.addTimer({ ...logParam, ...{ milliSec: Math.round(logParam.milliSec) } });
  }
}

export function reportLevel(metricsName: string, dimensions: Dimensions | undefined = undefined, value: number = 1) {
  if (shouldReportMetrics) {
    graphene.addHistogram({ metricsName, dimensions, value });
  }
}
export function incrementCounterByPublisher(
  publisher: Publisher | null,
  metricName: string,
  dimensions?: any,
  value: number = 1
) {
  combinePublisherMetrics(publisher, metricName).forEach(metric => incrementCounter(metric, dimensions, value));
}
export function reportTimerByPublisher(
  publisher: Publisher | null,
  metricsName: string,
  dimensions: Dimensions | undefined = undefined,
  startTime: number
) {
  combinePublisherMetrics(publisher, metricsName).forEach(metric => reportTimer(metric, dimensions, startTime));
}
export function reportLevelByPublisher(
  publisher: Publisher | null,
  metricsName: string,
  dimensions: Dimensions | undefined = undefined,
  level: number
) {
  combinePublisherMetrics(publisher, metricsName).forEach(metric => reportLevel(metric, dimensions, level));
}
function getPublisherTypeForMetrics(publisher: Publisher) {
  return isShowPublisher(publisher) && publisher.type !== PublisherType.TEST ? 'SHOW' : publisher.type;
}
function combinePublisherMetrics(publisher: Publisher | null, metricName: string): string[] {
  if (!publisher || publisher.type === PublisherType.TEST) {
    return [];
  }
  const publisherType = getPublisherTypeForMetrics(publisher);
  const tier = publisher.tier;
  return [
    `${metricName}.aggregated`,
    `${metricName}.byType.${publisherType}.byTier.${tier}`,
    `${metricName}.byPublisher.${publisher.businessProfileId}`,
    `${metricName}.byPublisherName.${sanitizeMetric(publisher.mutablePublisherName)}`,
  ];
}
export const globalTimerManager = new GlobalTimerManager(graphene, {});
globalTimerManager.startGlobalTimer(GlobalTimer.FIRST_PAGE_LOAD, (window as any).initialLoadingTiming);
const invalidMetricCharactersRegex = /[^a-zA-Z0-9-_:]/g;
export function sanitizeMetric(metricName: string, replaceChar: string = '_') {
  if (!metricName) {
    return 'EMPTY';
  }
  return metricName.replace(invalidMetricCharactersRegex, replaceChar);
}
export default initializeGraphene;
