import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import type { ChangeEvent } from 'react';
import { FormattedMessage, intlShape } from 'react-intl';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import InlineSVG from 'svg-inline-react';

import { getLoadingNewSearchCriteria } from 'state/homepage/selectors/homepageSelectors';
import {
  getActivePublisherBusinessProfileId,
  getActivePublisherOrgId,
} from 'state/publishers/selectors/publishersSelectors';
import { push } from 'state/router/actions/routerActions';
import { hasClaimForActivePublisher } from 'state/user/selectors/userSelectors';

import { ONBOARDING_WIZARD_ROUTE, STORY_STUDIO_LITE_URL } from 'config/constants';
import { chevronUp, chevronDown, search, cross, plus } from 'icons/SDS/allIcons';
import * as localRoutes from 'utils/apis/localRoutes';
import { intlConnect } from 'utils/connectUtils';
import { getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';

import SDSButton, { ButtonType } from 'views/common/components/SDSButton/SDSButton';
import { filterPublishers } from 'views/dashboard/components/PublisherOption/PublisherOption';

import PublisherList from './PublisherList';
import styles from './PublisherSearchDropdown.scss';

import type { Context } from 'types/common';
import { Claim } from 'types/permissions';
import type { PublisherWithGroup } from 'types/publishers';
import type { State as RootState } from 'types/rootState';

type OwnProps = {
  title: string;
  options: Array<PublisherWithGroup>;
  optionSelected: (a: PublisherWithGroup) => void;
  onWelcomePage: boolean;
};

type StateProps = {
  isPublisherCreator: boolean;
  isDebugEntityViewer: boolean;
  isPublisherOnboarding: boolean;
  isLoading: boolean;
  orgId: string;
  profileId: string | null;
};

type DispatchProps = {
  push: typeof push;
};

type Props = OwnProps & DispatchProps & StateProps;

type State = {
  isShowing: boolean;
  value: string;
};

const mapDispatchToProps = {
  push,
};

const mapStateToProps = (state: RootState): StateProps => {
  return {
    isPublisherCreator: hasClaimForActivePublisher(state, Claim.PUBLISHER_CREATOR),
    isDebugEntityViewer: hasClaimForActivePublisher(state, Claim.DEBUG_ENTITY_VIEWER),
    isPublisherOnboarding: hasClaimForActivePublisher(state, Claim.PUBLISHER_ONBOARDING),
    isLoading: getLoadingNewSearchCriteria(state),
    orgId: getActivePublisherOrgId(state),
    profileId: getActivePublisherBusinessProfileId(state),
  };
};

export class PublisherSearchDropdown extends React.Component<Props, State> {
  searchInput: {
    current: null | HTMLInputElement;
  };

  static contextTypes: Context = {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'IntlShape' is not assignable to type 'IntlCo... Remove this comment to see the full error message
    intl: intlShape,
  };

  constructor(props: Props) {
    super(props);
    this.searchInput = React.createRef();

    this.state = {
      isShowing: false,
      value: '',
    };
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (this.props !== nextProps) {
      return true;
    }

    if (this.state.isShowing !== nextState.isShowing) {
      return true;
    }

    return this.state.value !== nextState.value;
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.isShowing && !prevState.isShowing && this.searchInput.current) {
      this.searchInput.current.focus();
    }
  }

  handleInputChange = (event: ChangeEvent<HTMLInputElement>) => this.setState({ value: event.target.value });

  hideList = () => this.setState({ value: '', isShowing: false });

  showList = () => {
    // Avoid user switch publisher quickly that lead into race condition
    if (this.props.isLoading) {
      return;
    }
    this.setState({ isShowing: true });
  };

  optionSelected = (option: PublisherWithGroup) => {
    this.props.optionSelected(option);
    this.hideList();
  };

  handleCrossClick = () => {
    this.setState({ value: '' });

    if (this.searchInput.current) {
      this.searchInput.current.blur();
    }
  };

  handleNewPublisherButtonClick = () => {
    this.hideList();

    // we pass 'refresh=true' param so Story Studio Lite knows that the user is already snap authed so we should try refreshing the token
    if (this.props.isPublisherOnboarding) {
      window.location.href = `${STORY_STUDIO_LITE_URL + ONBOARDING_WIZARD_ROUTE}?orgId=${this.props.orgId}&profileId=${
        this.props.profileId
      }&refresh=true`;
    } else if (this.props.isPublisherCreator) {
      this.props.push(localRoutes.snapAdmin.newPublisher());
    }
  };

  optionsKey = (options: Array<PublisherWithGroup>) => options?.length || '0';

  filterList = _.memoize(
    (options, value) => filterPublishers(options, value),
    (options, value) => `${this.optionsKey(options)}${value}`
  );

  renderRootButton() {
    const theme = this.props.onWelcomePage ? styles.lightTheme : styles.darkTheme;

    return (
      <div
        className={classNames(styles.rootButton, theme, styles.noSelect)}
        onClick={this.state.isShowing ? this.hideList : this.showList}
        data-test="publisherSearchDropdown.rootPublisher"
      >
        {this.props.title}
        <InlineSVG src={this.state.isShowing ? chevronUp : chevronDown} className={classNames(styles.chevron, theme)} />
      </div>
    );
  }

  renderClearButtonSection() {
    return (
      <div className={styles.clearButtonSection}>
        <InlineSVG
          src={cross}
          className={styles.icon}
          onClick={this.handleCrossClick}
          data-test="PublisherSearchDropdown.cross"
        />
      </div>
    );
  }

  renderNewPublisherButtonSection() {
    if (!this.props.isPublisherCreator && !this.props.isPublisherOnboarding) {
      return null;
    }

    return (
      <div className={styles.newPublisherButtonSection}>
        <SDSButton
          inlineIcon={plus}
          type={ButtonType.SECONDARY}
          onClick={this.handleNewPublisherButtonClick}
          data-test="PublisherSearchDropdown.newPublisherButton"
        >
          <FormattedMessage
            id="new-profile-button"
            description="Button for creating a new profile"
            defaultMessage="New profile"
          />
        </SDSButton>
      </div>
    );
  }

  renderIconSection() {
    return (
      <div className={styles.searchIconSection}>
        <InlineSVG src={search} className={styles.icon} />
      </div>
    );
  }

  renderSearchRow() {
    return (
      <div className={styles.searchRow}>
        {this.renderIconSection()}
        <input
          className={styles.input}
          type="text"
          value={this.state.value}
          onChange={this.handleInputChange}
          placeholder={getLocalisedMessageFromId(this.context, 'publisher-search-dropdown-filter-input-placeholder')}
          ref={this.searchInput}
          data-test="PublisherSearchDropdown.input"
        />
        {this.state.value && this.renderClearButtonSection()}
      </div>
    );
  }

  render() {
    const filteredList = this.filterList(this.props.options, this.state.value);

    return (
      <RootCloseWrapper // closes the dropdown when user clicks outside the dropdown
        noWrap
        onRootClose={this.hideList}
      >
        <div className={styles.anchor}>
          {this.renderRootButton()}
          {this.state.isShowing && (
            <div className={styles.dropdown} data-test="publisherSearchDropdown.publisherList">
              {this.renderSearchRow()}
              {this.renderNewPublisherButtonSection()}
              <PublisherList
                filteredList={filteredList}
                optionSelected={this.optionSelected}
                data-test="PublisherSearchDropdown.PublisherList"
                isDebugEntityViewer={this.props.isDebugEntityViewer}
              />
            </div>
          )}
        </div>
      </RootCloseWrapper>
    );
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(PublisherSearchDropdown);
