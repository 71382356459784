import {
  login,
  logout,
  logoutWithError,
  load,
  initAuthRefreshLoop,
  preloadGapi,
  updateAuthTypeBasedOnLocalStorage,
  setAuthType,
  performAuthRefresh,
} from 'state/auth/actions/authActions';
import { getAuthLoaded, getUser, getAuthType } from 'state/auth/selectors/authSelectors';
import { isGapiThirdPartyCookieError } from 'state/auth/utils/preloadApi';
import { goToLogin, replace } from 'state/router/actions/routerActions';
import { getQuery } from 'state/router/selectors/routerSelectors';
import { setUseUnifiedLogin } from 'state/user/actions/userActions';

import { AuthType, Environments, ErrorType, LocalStorage } from 'config/constants';
import { incrementCounter } from 'utils/grapheneUtils';
import { localStorage } from 'utils/localStorageUtils';

import { requireUserInfo } from './requireUserInfo';

import type { Dispatch, GetState, Store } from 'types/redux';

const checkUserRegisteredOnCMS = (hostUsername?: string) => (dispatch: Dispatch, getState: GetState) => {
  if (getUser(getState())) {
    return dispatch(requireUserInfo(hostUsername));
  }
  // oops, not logged in, so can't be here!
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  return dispatch(logoutWithError());
};

export const requireLogin: any = (store: Store): any => (params?: any) => {
  const hostUsername = params?.match?.params?.hostUsername || undefined;

  // If query has "useUnifiedLogin=true", we set user state accordingly and use v2 login
  // this only works for employees on a subset of snapchat accounts.
  // https://github.sc-corp.net/Snapchat/content-platform/blob/main/story-studio-proxy/graphql/src/main/scala/snapchat/storystudio/graphql/fetchers/user/UserInfoFetcher.scala
  const query = getQuery(store.getState());
  if (query?.useUnifiedLogin) {
    store.dispatch(setUseUnifiedLogin(true));
  }

  store.dispatch(updateAuthTypeBasedOnLocalStorage());
  const authType = getAuthType(store.getState());

  if (authType === AuthType.SNAPCHAT) {
    store.dispatch(initAuthRefreshLoop());
    return store.dispatch(performAuthRefresh()).then(() => store.dispatch(requireUserInfo(hostUsername)));
  }

  if (getAuthLoaded(store.getState())) {
    store.dispatch(initAuthRefreshLoop());
    return store.dispatch(performAuthRefresh()).then(() => store.dispatch(checkUserRegisteredOnCMS()));
  }

  return (
    store
      .dispatch(preloadGapi())
      .then(() => store.dispatch(load()))
      .then(() => store.dispatch(checkUserRegisteredOnCMS(hostUsername)))
      .then(() => store.dispatch(initAuthRefreshLoop()))
      // If we fail to authenticate with Google, redirect to login page
      .catch(error => {
        if (isGapiThirdPartyCookieError(error)) {
          return store.dispatch(goToLogin({ error }));
        }
        if (error.errorType === ErrorType.USER_NEEDS_LOGIN) {
          return store.dispatch(logout(undefined, { error: ErrorType.USER_NEEDS_LOGIN }));
        }
        return store.dispatch(logoutWithError(error));
      })
  );
};

export const signInIfUserExists = (redirect: string = '') => (dispatch: Dispatch) => {
  dispatch(setAuthType(AuthType.GOOGLE));

  return dispatch(login())
    .then(() => localStorage.setItem(LocalStorage.AUTH_TYPE, AuthType.GOOGLE))
    .then(() => dispatch(checkUserRegisteredOnCMS()))
    .then(() => dispatch(replace(redirect)))
    .then(() => incrementCounter('Login.GoogleAuth', { action: 'login.succeed' }))
    .then(() => {
      // Web assembly requires the shared array buffer browser functionality which requires 'same-origin' header
      // The google login requires popup, to update the browser policy we need to reload the location
      // https://stackoverflow.com/questions/70535752/enable-sharedarraybuffer-on-localhost
      // Running windows reload on test timeout the tests
      if (process.env.NODE_ENV !== Environments.TEST) {
        window.location.reload();
      }
    })
    .catch(() => incrementCounter('Login.GoogleAuth', { action: 'login.failed' }));
};

export const snapAuthSignInIfUserExists = (redirect: string = '/') => (dispatch: Dispatch) => {
  dispatch(setAuthType(AuthType.SNAPCHAT));
  localStorage.setItem(LocalStorage.AUTH_TYPE, AuthType.SNAPCHAT);
  return dispatch(requireUserInfo())
    .then(() => dispatch(replace(redirect)))
    .then(() => incrementCounter('Login.SnapAuth', { action: 'login.succeed' }))
    .catch(() => incrementCounter('Login.SnapAuth', { action: 'login.failed' }));
};

export default requireLogin;
