import { addBreadcrumb } from '@sentry/browser';
import { AnyAction, Dispatch, Middleware } from 'redux';

import { BLOCKLIST_ACTIONS } from 'redux/blockListActions';

export const breadcrumbMiddleware: Middleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  // We want to filter the actions that are too noisy
  if (!BLOCKLIST_ACTIONS.includes(action.type)) {
    addBreadcrumb({
      category: 'action',
      message: action?.sequence ? `${action?.type} (${action.sequence})` : action?.type,
      level: 'info',
    });
  }

  return next(action);
};

export default breadcrumbMiddleware;
