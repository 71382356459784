import { stringify } from 'querystring';
import { parse } from 'url';

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'esca... Remove this comment to see the full error message
import escapeRegex from 'escape-string-regexp';
import is from 'is_js';
import _ from 'lodash';

import { DISCOVER_FRONTEND_BASE_URL } from 'config/constants';
import { assertArg, assertState } from 'utils/assertionUtils';
import { snakeCaseKeys, camelCaseKeys, safeShallowMerge } from 'utils/objectUtils';

const regExMatchAllNumbers = /^[0-9]+/;

const regExMatchNonParamSegment = /^[^/&?]+/;
const regExMatchProtocol = /^\/\//;
let maximumCachedPaths = 1000;
const defaultParameterPrefix = ':';

let cachedPathsCount = 0;
let cachedParameterizedPaths = {};

type ParameterizedPathSegment = {
  type: 'parameter' | 'literal';
  value: string;
};

type PathParameters = {
  requiredParams: string[];
  parameterizedPath: ParameterizedPathSegment[];
};

function parsePathParameters(inputPath: string, parameterPrefix: string): PathParameters {
  if (inputPath in cachedParameterizedPaths) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return cachedParameterizedPaths[inputPath];
  }
  const pathSplitOnParameterPrefix = inputPath.split(parameterPrefix);
  const parameterizedPath: ParameterizedPathSegment[] = [];

  parameterizedPath.push({
    type: 'literal',
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    value: pathSplitOnParameterPrefix.shift(),
  });

  const requiredParams: string[] = [];
  pathSplitOnParameterPrefix.forEach(pathSegment => {
    const match = regExMatchNonParamSegment.exec(pathSegment);
    const matchAllNumbers = regExMatchAllNumbers.exec(pathSegment);
    if (!match || matchAllNumbers) {
      const matchProtocol = regExMatchProtocol.test(pathSegment);
      if (matchProtocol || matchAllNumbers) {
        parameterizedPath.push({
          type: 'literal',
          value: `${parameterPrefix}${pathSegment}`,
        });
        return;
      }
      throw new Error('colon not followed by parameterName');
    }
    const parameterName = match[0];
    const remaining = pathSegment.substr(parameterName.length);
    requiredParams.push(parameterName);
    parameterizedPath.push(
      {
        type: 'parameter',
        value: parameterName,
      },
      {
        type: 'literal',
        value: remaining,
      }
    );
  });
  if (cachedPathsCount > maximumCachedPaths) {
    cachedPathsCount = 0;
    cachedParameterizedPaths = {};
  }
  cachedPathsCount += 1;
  const parsedPath = {
    requiredParams,
    parameterizedPath,
  };
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  cachedParameterizedPaths[inputPath] = parsedPath;
  return parsedPath;
}

function setMaximumCachePaths(maximumCachedPathsInput: number) {
  maximumCachedPaths = maximumCachedPathsInput;
}

function constructURLFromParameterizedPath(parameterizedPath: ParameterizedPathSegment[], options: any = {}): string {
  const constructedURL = [];
  const failOnMissingParamTest = typeof options.failOnMissingParam === 'undefined' ? true : options.failOnMissingParam;
  const paramsObject = options.params || {};

  for (let i = 0; i < parameterizedPath.length; i++) {
    const pathSegment = parameterizedPath[i];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    switch (pathSegment.type) {
      case 'parameter':
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (!(pathSegment.value in paramsObject) || typeof paramsObject[pathSegment.value] === 'undefined') {
          if (failOnMissingParamTest) {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            throw new Error(`param ${pathSegment.value} not found in params`);
          } else {
            return '';
          }
        }
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        constructedURL.push(encodeURI(paramsObject[pathSegment.value]));
        break;
      case 'literal':
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        constructedURL.push(pathSegment.value);
        break;
      default:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        throw new Error(`${pathSegment.type} unknown type`);
    }
  }

  return constructedURL.join('');
}

function optionalQuery(params: any) {
  const paramsString = stringify(params);
  return paramsString.length > 0 ? `?${paramsString}` : '';
}

function isMissingParameter(params: any, requiredParams: string[]) {
  for (let i = 0; i < requiredParams.length; i++) {
    const paramKey = requiredParams[i];
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (typeof params[paramKey] !== 'undefined') {
      continue; // eslint-disable-line no-continue
    }
    return `${paramKey} is missing from given parameters`;
  }
  return false;
}

function requireParameters(params: any, requiredParams: string[]) {
  const missingParameters = isMissingParameter(params, requiredParams);
  if (missingParameters) {
    throw new Error(missingParameters);
  }
}

function constructRegexFromParameterizedPath(parameterizedPath: ParameterizedPathSegment[]) {
  let regexRaw = '';
  const paramOrder = [];
  for (let i = 0; i < parameterizedPath.length; i++) {
    const pathSegment = parameterizedPath[i];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    switch (pathSegment.type) {
      case 'parameter':
        regexRaw += '([^/?&]+)';
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        paramOrder.push(pathSegment.value);
        break;
      case 'literal':
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        regexRaw += escapeRegex(pathSegment.value);
        break;
      default:
        throw new Error(`unexpected type ${JSON.stringify(pathSegment)}`);
    }
  }
  const compiledRegex = new RegExp(`^${regexRaw}(?:\\?.*)?$`);
  return {
    compiledRegex,
    paramOrder,
  };
}

const helperFuncs = {
  parsePathParameters,
  setMaximumCachePaths,
  constructURLFromParameterizedPath,
  constructRegexFromParameterizedPath,
  optionalQuery,
  isMissingParameter,
  requireParameters,
};

declare const __TEST__: any;

export function getHelperFuncs() {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(__TEST__).is.truthy();
  return helperFuncs;
}

export function constructURL(inputPath: string, inputOptions: any, parameterPrefix: string = defaultParameterPrefix) {
  const { parameterizedPath } = helperFuncs.parsePathParameters(inputPath, parameterPrefix);
  return helperFuncs.constructURLFromParameterizedPath(parameterizedPath, inputOptions);
}

// Utility class for generating API URLs that are scoped to a particular baseURL
export class ScopedURLBuilder {
  _baseURL: string;

  constructor(baseURL: string) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(baseURL).is.string();
    this._baseURL = baseURL;
  }

  createAPICall(urlFormat: string, inputOptions: any = {}, parameterPrefix: string = defaultParameterPrefix) {
    this._addBaseURL(inputOptions);

    return createAPICall(urlFormat, inputOptions, parameterPrefix);
  }

  createAPIMatcher(urlFormat: string, inputOptions: any = {}) {
    this._addBaseURL(inputOptions);

    return createAPIMatcher(urlFormat, inputOptions);
  }

  _addBaseURL(inputOptions: any) {
    if (is.existy(inputOptions.baseURL)) {
      throw new Error('ScopedURLBuilder expects the baseURL option not to be supplied');
    }
    inputOptions.baseURL = this._baseURL; // eslint-disable-line no-param-reassign
  }
}

const PATH_PARAM_REGEX = '[^/&?]+';
const PATH_PARAM_REGEX_ALLOW_SLASH = '[^&?]+';

const createUrlRegExp = _.memoize(
  (
    parameterizedPath: Array<{
      type: string;
      value: string;
    }>,
    multipartPath: boolean
  ) => {
    const paramRegex = multipartPath ? PATH_PARAM_REGEX_ALLOW_SLASH : PATH_PARAM_REGEX;
    return parameterizedPath.reduce((acc, pathParam) => {
      const suffix = pathParam.type === 'literal' ? escapeRegex(pathParam.value) : paramRegex;
      return `${acc}${suffix}`;
    }, '');
  }
);

export function createAPICall(
  urlFormat: string,
  inputOptions: any = {},
  parameterPrefix: string = defaultParameterPrefix
) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(inputOptions).is.object();
  const baseURL = getBaseURL(inputOptions);
  const query = inputOptions.query || [];

  // Default to conversion
  const convertQueryCasing = inputOptions.convertQueryCasing || snakeCaseKeys;

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(convertQueryCasing).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(query).is.array();
  if (baseURL) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(urlFormat.indexOf('/')).is.not.equal(0);
  }

  const path = baseURL + urlFormat;
  const parsedURL = helperFuncs.parsePathParameters(path, parameterPrefix);
  const allRequiredParams = query.concat(parsedURL.requiredParams);

  function constructUrl(params?: any) {
    const copiedParams = {
      ...params,
    };
    requireParameters(copiedParams, allRequiredParams);

    const prependURL = helperFuncs.constructURLFromParameterizedPath(parsedURL.parameterizedPath, {
      params: copiedParams,
    });
    parsedURL.requiredParams.forEach(pathParam => {
      // Remove path parameters from queryParameters
      delete copiedParams[pathParam];
    });

    const casedParams = convertQueryCasing(copiedParams);
    return prependURL + optionalQuery(casedParams);
  }

  constructUrl.match = (url: string) => {
    const urlRegExp = createUrlRegExp(parsedURL.parameterizedPath, inputOptions.multipartPath);
    const exactUrlRegExp = `^${urlRegExp}$`;

    const basePath = url.split('?')[0];

    const urlMatcher = new RegExp(exactUrlRegExp, 'i');
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return Boolean(basePath.match(urlMatcher));
  };

  constructUrl.path = path;

  return constructUrl;
}

export function createAPIMatcher(
  urlFormat: string,
  inputOptions: any = {},
  parameterPrefix: string = defaultParameterPrefix
) {
  const baseURL = getBaseURL(inputOptions);
  const basePath = (baseURL && new URL(baseURL).pathname) || '';

  // path regex
  const { parameterizedPath } = helperFuncs.parsePathParameters(urlFormat, parameterPrefix);
  const { compiledRegex, paramOrder } = helperFuncs.constructRegexFromParameterizedPath(parameterizedPath);

  return (inputURL: string) => {
    const parsedInput = inputURL && inputURL.indexOf('/') > 0 && new URL(inputURL);
    const protocol = parsedInput && parsedInput.protocol;
    const host = parsedInput && parsedInput.host;
    const prefix = protocol && host ? `${protocol}//${host}${basePath}` : basePath;

    // skip the host to avoid matching ":8000" as a path parameter in "http://localhost:8000/foo/bar"
    const match = compiledRegex.exec(inputURL.substring(prefix.length));

    if (!match) {
      return false;
    }
    // TODO: should query be `new Map(parsedInput.searchParams)`?
    const { query } = parse(inputURL, true);
    const pathParams = {};
    paramOrder.forEach((paramKey, index) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      pathParams[paramKey] = match[index + 1];
    });

    return safeShallowMerge(pathParams, camelCaseKeys(query));
  };
}

function getBaseURL(inputOptions: any) {
  const { baseURL } = inputOptions;

  // Support the legacy API, which expected baseURL to be a boolean and used
  // the Discover Frontend URL if supplied as true.
  if (baseURL === true) {
    return DISCOVER_FRONTEND_BASE_URL;
  }
  if (is.string(baseURL)) {
    return baseURL;
  }

  return '';
}
