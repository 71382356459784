import log from 'loglevel';

import { CrossOrigin } from 'config/constants';
import { ImageLoader } from 'utils/media/ImageLoader';
import * as _imageCompositor from 'utils/media/imageCompositor';

import playImage from './play.png';

export default {
  addPlayIcon(
    imageUri: any,
    imageLoader = new ImageLoader({ crossOrigin: CrossOrigin.NONE }),
    imageCompositor = _imageCompositor
  ) {
    return new Promise((resolve, reject) => {
      imageLoader
        .loadImages([imageUri, playImage])
        .then(images => {
          resolve(imageCompositor.flatten(images));
        })
        .catch(err => {
          log.error('Error when trying to add play icon to image:', err);
          reject(err);
        });
    });
  },
};
