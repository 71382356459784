import { isString } from 'lodash';
import MediaInfoFactory from 'mediainfo.js';
import wasmModule from 'mediainfo.js/dist/MediaInfoModule.wasm';
import { MediaInfo as MediaInfoInterface } from 'mediainfo.js/dist/types';

import WasmError from '../errors/WasmError';
import { loadWasm } from '../wasm';

import { incrementCounter, reportLevel } from 'utils/grapheneUtils';
import { measureDurationWrap } from 'utils/performance/performanceUtils';

import FileReaderUtil from './FileReaderUtil';
import { MediaMetadata } from './MediaMetadata';

const locateWasmFile = (path: string, dir: string) => {
  if (dir.endsWith('/node_modules/mediainfo.js/dist/')) {
    // Called from node
    return dir + path;
  }
  // Called from browser
  return wasmModule;
};

// Triggering wasm download on page load, and providing an immediate load function
const { loadPromise, triggerLoad } = loadWasm<MediaInfoInterface>(
  () =>
    MediaInfoFactory({ locateFile: locateWasmFile }).catch(err => {
      throw new WasmError('Failed to load wasm', err);
    }),
  5000
);

// Avoid unhandled exception when compiling
loadPromise.catch(() => {
  /* empty */
});

async function getMediaMetadataImpl(file: File): Promise<MediaMetadata> {
  // Triggering loading of library if it's not already loaded
  triggerLoad();

  // Waiting for library to load
  const mediaInfo = await loadPromise;

  const promiseOrVoid = mediaInfo.analyzeData(
    () => file.size,
    (chunkSize: number, offset: number) => FileReaderUtil.readAsUint8Array(file, chunkSize, offset)
  );

  if (promiseOrVoid === null || promiseOrVoid === undefined) {
    throw new WasmError('Media info failed to return a promise');
  }

  const objectOrString = await promiseOrVoid;
  if (isString(objectOrString)) {
    throw new WasmError('Media info failed to return an object');
  }

  const metadata = new MediaMetadata(objectOrString);
  outputMediaMetrics(metadata);

  return metadata;
}

function bucketedDimension(dimension?: number): number | undefined {
  if (dimension === undefined) {
    return undefined;
  }

  // lower bound of 100
  if (dimension < 100) {
    return 100;
  }

  // upper bound of 9950, anything above that should equal 9999
  // we don't want 9950 to round up to 10000, even though that is the nearest 100
  if (dimension >= 9950) {
    return 9999;
  }

  // if the dimension is between 100 - 999, then round to nearest 10
  if (dimension < 1000) {
    return Math.round(dimension / 10) * 10;
  }

  // here the dimension is between 1000 - 9949, we should round to nearest 100
  return Math.round(dimension / 100) * 100;
}

// exported for testing
export function bucketedDimensionsMetric(width?: number, height?: number): string {
  return `${bucketedDimension(width)}x${bucketedDimension(height)}`;
}

function outputMediaMetrics(metadata: MediaMetadata) {
  incrementCounter(
    'media.metadata',
    {
      format: metadata.getGeneralFormat() || 'unknown',
      codec: metadata.getVideoFormat() || 'unknown',
      dimensions: bucketedDimensionsMetric(metadata.getVideoWidth(), metadata.getVideoHeight()),
      isStreamable: metadata.getIsStreamable() || 'unknown',
      encodingLibrary: metadata.getEncodingLibrary() || 'unknown',
      encodingLibraryName: metadata.getEncodingLibraryName() || 'unknown',
    },
    1
  );

  reportLevel('media.size_bytes', {}, metadata.getFileSizeBytes() || 0);

  reportLevel('media.duration', {}, metadata.getDurationSeconds() || 0);

  reportLevel('media.duration', {}, metadata.getFramerate() || 0);

  reportLevel('media.bitrate', {}, metadata.getVideoBitrate() || 0);

  reportLevel('media.pixel_aspect_ratio', {}, metadata.getVideoPixelAspectRatio() || 0);

  reportLevel('media.rotation', {}, metadata.getVideoRotation() || 0);
}

export const getMediaMetadata = measureDurationWrap(getMediaMetadataImpl, { metricsName: 'media.get_metadata' });
