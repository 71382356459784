import log from 'loglevel';
import { Middleware } from 'redux';

import { performAuthRefresh, flagAuthAsFailedPermanently } from 'state/auth/actions/authActions';
import * as authSelectors from 'state/auth/selectors/authSelectors';

import { AuthType } from 'config/constants';
import * as authUtils from 'utils/authUtils';
import * as dateUtils from 'utils/dateUtils';

import { CALL_API } from './apiMiddleware';

// Snap auth session token expired in 1 hour, we refresh them every 50 mins.
const SnapAuthTokenExpireTimeInMinutes = 50;

const authorize = ({ next, getState, action }: any) => {
  // Attach Bearer token to request if user is logged in
  const token = authSelectors.getToken(getState());
  const authType = authSelectors.getAuthType(getState());
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Readonly<{}> | null' is not assi... Remove this comment to see the full error message
  return next(authUtils.addAuthHeaderIfRequired(action, token, authType));
};

export default function apiAuthMiddleware({ getState, dispatch }: any): Middleware {
  return (next: any) => (action: any) => {
    const callAPI = action && action.meta && action.meta[CALL_API];
    const authType = authSelectors.getAuthType(getState());

    if (!callAPI) {
      return next(action);
    }

    // No auth header required
    if (!authUtils.getAuthHeaderForEndpoint(callAPI.endpoint)) {
      return next(action);
    }

    // Auth was never loaded for Google
    if (authType !== AuthType.SNAPCHAT && !authSelectors.getAuthLoaded(getState())) {
      return next(action);
    }

    const failedPermanently = authSelectors.getAuthFailed(getState());

    if (failedPermanently) {
      return next(action);
    }

    const authAge = authSelectors.getAuthAge(getState());

    // Reauthentication can be tested by commenting out the following lines in the browser console.
    if (!dateUtils.isExpired(authAge, SnapAuthTokenExpireTimeInMinutes)) {
      return authorize({ next, getState, action });
    }

    if (callAPI.noReAuth) {
      return authorize({ next, getState, action });
    }

    return Promise.resolve()
      .then(() => {
        return dispatch(performAuthRefresh());
      })
      .then(
        () => authorize({ next, getState, action }),
        error => {
          // Ensure we stop trying to reauth
          dispatch(flagAuthAsFailedPermanently());

          log.error('Failed to refresh authentication', error);

          // Send action through anyway, it should fail, but the calling code
          // may want to know that it failed. Forces api failure to avoid making network call.
          // eslint-disable-next-line no-param-reassign
          (error as any).fetchResults = {
            data: { code: 'EXPIRED_SESSION' },
          };
          const newAction = { ...action, forceApiError: error };
          return next(newAction);
        }
      );
  };
}
