import { combineReducers } from 'redux';

import * as reducerFactory from 'state/common/reducerFactories';

import { Sequence } from 'config/constants';
import u from 'utils/safeUpdeep';

import {
  FETCH_DEBUG_SNAP,
  FETCH_DEBUG_EDITION,
  FETCH_DEBUG_PUBLISHER,
  FETCH_DEBUG_PUBLISHER_ENABLED_FEATURES,
} from './debugActions';

import type { SequenceAction } from 'types/common';

export type DebugData = {};

function debugSnapsById(state: DebugData | undefined | null = null, action: SequenceAction<DebugData>) {
  switch (action.type) {
    case FETCH_DEBUG_SNAP:
      if (action.sequence === Sequence.DONE && !action.error && action.payload) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
}

function debugEditionsById(state: DebugData | undefined | null = null, action: SequenceAction<DebugData>) {
  switch (action.type) {
    case FETCH_DEBUG_EDITION:
      if (action.sequence === Sequence.DONE && !action.error && action.payload) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
}

function debugPublishersById(state: DebugData | undefined | null = null, action: SequenceAction<DebugData>) {
  switch (action.type) {
    case FETCH_DEBUG_PUBLISHER:
      if (action.sequence === Sequence.DONE && !action.error && action.payload) {
        return action.payload;
      }
      return state;
    case FETCH_DEBUG_PUBLISHER_ENABLED_FEATURES:
      if (action.sequence === Sequence.DONE && !action.error && action.payload) {
        return u(
          {
            publisher: {
              enabledFeatures: action.payload,
            },
          },
          state
        );
      }
      return state;
    default:
      return state;
  }
}

export default combineReducers({
  debugSnapsById: reducerFactory.createByIdReducer('snapId', debugSnapsById),
  debugEditionsById: reducerFactory.createByIdReducer('editionId', debugEditionsById),
  debugPublishersById: reducerFactory.createByIdReducer('publisherId', debugPublishersById),
});
