import { SNAP_SSO_BASE_URL, SNAP_SSO_CLIENT_ID } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

const builder = new ScopedURLBuilder(SNAP_SSO_BASE_URL);

export const snapSSO = {
  login: builder.createAPICall('accounts/sso', {
    query: ['client_id', 'referrer'],
  }),
  refreshToken: builder.createAPICall(`accounts/sso?client_id=${SNAP_SSO_CLIENT_ID}`),
  logout: builder.createAPICall(`accounts/client_logout?client_id=${SNAP_SSO_CLIENT_ID}`),
  signupWithoutCookies: builder.createAPICall(
    `accounts/signup?client_id=${SNAP_SSO_CLIENT_ID}&multi_user=true&skip_login=true&business=true&referrer=:referrer`
  ),
  loginWithCookies: builder.createAPICall(
    `accounts/sso?client_id=${SNAP_SSO_CLIENT_ID}&business=true&referrer=:referrer`
  ),
};
