import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query GetUserInfo($input: UserInfoInput!) {
    userInfo(input: $input) {
      id
      bitmojiAvatarId
      username
      snapUserId
      snapUsername
      email
      resourceRoles {
        roleId
        resource
        claims
        allowedActions
        explicit
        inherited
      }
    }
  }
`;
