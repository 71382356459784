import is from 'is_js';
import { mapValues, omit } from 'lodash';

import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import { createChainedFn } from 'utils/functionUtils';

import type { SnapId } from 'types/common';
import type { EditionID, BaseEdition } from 'types/editions';
import type { SegmentID } from 'types/segments';
import type { Tile, TileID } from 'types/tiles';

type ParentId = SegmentID | EditionID | SnapId;

const Prefixes: {
  [x: string]: string;
} = {
  snap: 'SNAP',
  edition: 'EDITION',
  segment: 'SEGMENT',
};

export const getTileId = (tile: Tile) => {
  return !tile.scsId ? tile.croppedImageAssetId : tile.scsId;
};

const hasRealId = (tileId?: TileID) => {
  if (!tileId) {
    return false;
  }

  const tileIdString: TileID = tileId;

  const prefixes: any = Object.values(Prefixes);

  return !prefixes.find((prefix: string) => tileIdString.startsWith(prefix));
};

const mapValueIfExists = (entity: any, key: any, mappingFunction: any) => {
  if (!entity || !entity[key]) {
    return entity;
  }

  return {
    ...entity,

    [key]: entity[key].map(mappingFunction),
  };
};

const updateTileId = (prefix: string, parentId: ParentId) => (tile: Tile): Tile => {
  const generatedTileId: TileID = `${prefix}-${parentId}-${tile.baseImageAssetId}`;

  if (hasRealId(tile.id)) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<Tile>' is not assignable to type 'Ti... Remove this comment to see the full error message
    return { ...tile, id: generatedTileId, scsId: tile.id } as Partial<Tile>;
  }

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Partial<Tile>' is not assignable to type 'Ti... Remove this comment to see the full error message
  return { ...tile, id: generatedTileId } as Partial<Tile>;
};

const updateEntityTiles = (prefix: any, entity: any) =>
  mapValueIfExists(entity, 'tiles', updateTileId(prefix, entity.id));

const mapArrayOrObject = (value: any, mappingFunction: any) => {
  if (is.array(value)) {
    return value.map((entity: any) => mappingFunction(entity));
  }
  if (is.object(value)) {
    return mapValues(value, entity => mappingFunction(entity));
  }
  return value;
};

const removeTileId = (tile: Tile): Tile => {
  if (hasRealId(tile.scsId)) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    return { ...omit(tile, ['scsId']), id: tile.scsId };
  }

  // @ts-expect-error ts-migrate(2322) FIXME: Type 'Omit<ImageTile | VideoTile, "id">' is not as... Remove this comment to see the full error message
  return omit(tile, ['id']);
};

const removeTileIdsFromEntity = (entity: any) => mapValueIfExists(entity, 'tiles', removeTileId);

const transformBody = (transform: any) => (request: any) => {
  if (is.object(request) && is.object(request.body)) {
    return {
      ...request,
      body: transform(request.body),
    };
  }

  return request;
};

export const generateSnapTileIds = createChainedFn([
  optimisticJsonFinalizer,
  body => updateEntityTiles(Prefixes.snap, body),
]);

export const generateSnapTileIdsMultiple = createChainedFn([
  optimisticJsonFinalizer,
  body => mapArrayOrObject(body, updateEntityTiles.bind(undefined, Prefixes.snap)),
]);

export const removeTileIdsFromSnap = removeTileIdsFromEntity;
export const removeSnapTileIds = transformBody(removeTileIdsFromEntity);
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
export const addIdToSnapTile = (snapId: SnapId, tile: Tile) => updateTileId(Prefixes.snap, snapId)(tile);

export const addTileIdsToEdition = (edition: Partial<BaseEdition>) => {
  const editionWithTileIds = updateEntityTiles(Prefixes.edition, edition);
  return mapValueIfExists(editionWithTileIds, 'segments', (segment: any) =>
    updateEntityTiles(Prefixes.segment, segment)
  );
};

export const generateEditionTileIds = createChainedFn([
  optimisticJsonFinalizer,
  (body: any) => addTileIdsToEdition(body),
]);

export const generateEditionTileIdsMultiple = createChainedFn([
  optimisticJsonFinalizer,
  body => mapArrayOrObject(body, addTileIdsToEdition),
]);

export const removeTileIdsFromEdition = (edition: Partial<BaseEdition>) => {
  const editionWithoutTileIds = mapValueIfExists(edition, 'tiles', removeTileId);
  return mapValueIfExists(editionWithoutTileIds, 'segments', (segment: any) =>
    mapValueIfExists(segment, 'tiles', removeTileId)
  );
};

export const removeEditionTileIds = transformBody(removeTileIdsFromEdition);
// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
export const addIdToEditionTile = (editionId: EditionID, tile: Tile) => updateTileId(Prefixes.edition, editionId)(tile);

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
export const addIdToSegmentTile = (segmentId: SegmentID, tile: Tile) => updateTileId(Prefixes.segment, segmentId)(tile);
