import { ApolloLink } from '@apollo/client';
import { get, snakeCase } from 'lodash';

import { scrubUrlForReporting } from 'utils/apis/apiUtils';
import { incrementCounter, reportTimerWithDuration } from 'utils/grapheneUtils';
import { getPerformanceTiming } from 'utils/performance/performanceUtils';

function parseHostString(scrubbedUrl: any) {
  return snakeCase(get(scrubbedUrl, ['label', 'host'], 'unknown_host'));
}

export const apiMetricsLink = new ApolloLink((operation, forward) => {
  const startTimeMs = getPerformanceTiming();
  return forward(operation).map(result => {
    const latencyMs = getPerformanceTiming() - startTimeMs;
    const context = operation.getContext();
    const requestUrl: string = get(context, ['response', 'url']);
    const scrubbedUrl = scrubUrlForReporting(requestUrl, 'graphql');
    // Nice idea to pass the grapheneDimensions into the context
    const { grapheneDimensions = {} } = operation.getContext();
    const operationName = operation.operationName;
    const clientName = context.clientName;
    const host = parseHostString(scrubbedUrl) || clientName || 'uknown_host';
    const clientNameDimensions = clientName ? { clientName } : {};
    incrementCounter('graphql_api_request_counter', {
      host,
      label: operationName,
      ...clientNameDimensions,
      status: context?.response?.status.toString() || 'noStatus',
      ...grapheneDimensions,
    });

    // Only log when latency is between 0 and 20 minutes
    if (latencyMs >= 0 && latencyMs < 20 * 60 * 1000) {
      reportTimerWithDuration({
        metricsName: 'api_latency',
        dimensions: {
          host,
          label: operationName,
          ...clientNameDimensions,
          ...grapheneDimensions,
        },
        milliSec: latencyMs,
      });
    }
    return result;
  });
});
