import _ from 'lodash';

import { withPlatform } from 'utils/platformUtils';
import { withRouter } from 'utils/routerUtils';

import type { GenericComponent } from 'types/components';

const CONTEXT_MAP = {
  router: withRouter,
  platform: withPlatform,
};

export type ContextName = keyof typeof CONTEXT_MAP;

export function wrapWithContext<Props>(
  ...contexts: Array<ContextName>
): (component: GenericComponent<Props>) => GenericComponent<Props> {
  return (component: GenericComponent<Props>): GenericComponent<Props> => {
    return _.reduce(
      contexts,
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      (composedComponent: GenericComponent<Props>, context: ContextName) => {
        const contextWrapper = CONTEXT_MAP[context];
        if (!contextWrapper) {
          return composedComponent;
        }
        return contextWrapper(composedComponent);
      },
      component
    );
  };
}
