import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import style from './SDSPanelRow.scss';

type Props = {
  className?: string;
  children?: ReactNode;
};

export default function SDSPanelRow(props: Props) {
  return <div className={classNames(style.row, props.className)}>{props.children}</div>;
}
