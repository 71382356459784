import registerPreviewsObservers from 'state/previews/observers/previewsObservers';
import registerTilesObservers from 'state/tiles/observers/tilesObservers';

import createObserverMap from './middleware/observerMap';

/**
 * Used in combination with the observerMiddleware. Observers are registered using
 * an 'on()' syntax, as follows:
 *
 *     on('MY_ACTION_TYPE', (action, dispatch, getState, getPrevState) => {
 *       // Observer implementation goes here
 *     });
 *
 * Observers can be used to implement side effects for actions or particular types
 * of change in the store. One example use-case is reloading snap previews from the
 * server whenever the snap image/video changes. Without the use of observers and
 * side-effects, this must be done explicitly by any actions that modify a snap's
 * image/video. With an observer, it can instead be done _in response_ to the change
 * taking place, which is similar to the paradigm of a React component being re-rendered
 * in response to a state change in the store.
 *
 * That said, it's worth noting that observers should be used sparingly. Compared
 * to an explicit approach, the observer/side-effect approach is less obvious to
 * anyone reading the code. Care should also be taken to avoid cycles - i.e. scenarios
 * where an observer dispatches an action that in turn results in the same observer
 * being invoked again.
 */
export default function createObservers() {
  const observerMap = createObserverMap();

  // Register observers here
  registerPreviewsObservers(observerMap.on);
  registerTilesObservers(observerMap.on);

  return observerMap;
}
