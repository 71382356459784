import { Breadcrumb, BreadcrumbHint, Event, Exception } from '@sentry/types';
import log from 'loglevel';

const filterUrls = [/v1\/metric/, /web\/metrics/, /v1\/errors/, /google-analytics/, /doubleclick\.net/];

export function beforeBreadcrumb(breadcrumb: Breadcrumb, hint?: BreadcrumbHint): Breadcrumb | null {
  if (
    (breadcrumb.category === 'fetch' || breadcrumb.category === 'xhr') &&
    filterUrls.some(regex => breadcrumb.data?.url?.match?.(regex))
  ) {
    return null;
  }

  const { data, ...rest } = breadcrumb;

  const sanitizedData = {
    ...data,
    url: data?.url ? removeSensitiveDataInUrl(data.url) : undefined,
  };

  return {
    ...rest,
    data: sanitizedData,
  };
}

function sanitizeEvent(event: Event) {
  const sanitizedEvent = { ...event };
  // Filtering url query string and cookies
  if (sanitizedEvent.request?.query_string) {
    sanitizedEvent.request.query_string = undefined; // eslint-disable-line no-param-reassign
  }
  if (sanitizedEvent.request?.cookies) {
    sanitizedEvent.request.cookies = undefined; // eslint-disable-line no-param-reassign
  }

  if (sanitizedEvent.request?.url) {
    const newURL = removeSensitiveDataInUrl(sanitizedEvent.request.url);
    sanitizedEvent.request.url = newURL.split('?')[0]; // eslint-disable-line no-param-reassign
  }

  if (sanitizedEvent.transaction) {
    sanitizedEvent.transaction = removeSensitiveDataInUrl(sanitizedEvent.transaction);
  }

  sanitizedEvent.breadcrumbs = sanitizedEvent.breadcrumbs?.map(breadcrumb => {
    const breadcrumbData = breadcrumb.data;
    if (breadcrumbData?.url) {
      breadcrumbData.url = removeSensitiveDataInUrl(breadcrumb.data?.url);
    }
    return { ...breadcrumb, data: breadcrumbData };
  });

  sanitizedEvent.spans = sanitizedEvent.spans?.map(span => {
    const currentSpan = span;
    if (currentSpan?.data && currentSpan.data?.url) {
      currentSpan.data.url = removeSensitiveDataInUrl(currentSpan.data.url);
      currentSpan.description = removeSensitiveDataInUrl(currentSpan.description!);
    }
    return currentSpan;
  });

  // Logging to console doesn't parse any error that are logged. We need to parse manually if we want sentry source
  // map resolution.
  if (sanitizedEvent.extra?.arguments) {
    const exceptions: Exception[] = (sanitizedEvent.extra.arguments as any[]).filter(arg => !!arg?.stack);

    if (exceptions.length > 0) {
      // eslint-disable-next-line no-param-reassign
      sanitizedEvent.exception = {
        values: (sanitizedEvent.exception?.values || []).concat(exceptions),
      };
    }
  }
  return sanitizedEvent;
}

export function beforeSend(event: Event): PromiseLike<Event | null> | Event | null {
  return sanitizeEvent(event);
}

export function beforeSendTransaction(event: Event): PromiseLike<Event | null> | Event | null {
  return sanitizeEvent(event);
}

export function removeSensitiveDataInUrl(url: string): string {
  return url
    .replace(/publisherId=(\d+)/, 'publisherId=:publisherId')
    .replace(/publisher\/(\d+)/, 'publisher/:publisher')
    .replace(/businessProfileId\/(\d+)/, 'businessProfileId/:businessProfileId')
    .replace(/businessProfileId=(\d+)/, 'businessProfileId=:businessProfileId')
    .replace(/bp_id\/(\d+)/, 'bp_id/:bp_id')
    .replace(/userId\/(\d+)/, 'userId/:userId')
    .replace(/user\/([\w-]+)/, 'user/:userId')
    .replace(/user=(\d+)/, 'user=:user');
}

const providers: any = new Map();
export function setProvider(name: any, provider: any) {
  if (typeof provider === 'function') {
    providers.set(name, provider);
    return;
  }
  log.error(`Expected ${name} to be a function. Provided type was '${typeof provider}'.`);
}
