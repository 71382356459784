import { Dispatch } from 'redux';

import { getPublishersUncached } from 'state/publishers/actions/publishersActions';

import { GetState } from 'types/redux';

export const requireUncachedPublishers = () => (dispatch: Dispatch, getState: GetState) => {
  const { info } = getState().user;
  if (!info?.userId) {
    return Promise.resolve();
  }
  return dispatch(getPublishersUncached(info.userId, true));
};
