import _ from 'lodash';
import log from 'loglevel';
import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import { createSequenceHandlingReducer } from 'state/common/reducerFactories';
import * as homepageActions from 'state/homepage/actions/homepageActions';
import { EMPTY_PAGINATION_STATE } from 'state/homepage/actions/homepageActions';
import { PaginationState } from 'state/homepage/homepageState';
import * as publishersActions from 'state/publishers/actions/publishersActions';

import { Sequence, EMPTY_OBJECT, EMPTY_ARRAY } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';

const orderedStoryIdList = createSequenceHandlingReducer(EMPTY_ARRAY, publishersActions.GET_HOMEPAGE_STORIES, {
  start(state: any, action: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }

    // i.e. Upon first homepage load, live should be on top
    let newEditionOrder = action.payload.result;
    if (action.keepExistingStories) {
      newEditionOrder = _.union(state, newEditionOrder);
    }

    return newEditionOrder;
  },
});

const searchCriteria = createSequenceHandlingReducer(EMPTY_OBJECT, publishersActions.GET_HOMEPAGE_STORIES, {
  start(state: any, action: any) {
    return action.searchCriteria;
  },
  done(state: any, action: any) {
    return state;
  },
});

const pagination = (state: PaginationState = EMPTY_PAGINATION_STATE, action: any) => {
  switch (action.type) {
    case homepageActions.SET_PAGINATION:
      return action.payload;
    case publishersActions.GET_HOMEPAGE_STORIES: {
      if (action.sequence === Sequence.START) {
        return action.pagination;
      }
      const actionPublisherId = action.params?.publisherId;
      if (actionPublisherId && actionPublisherId !== state.publisherId) {
        log.warn(
          `Action publisher id ${actionPublisherId} does not match state publisher Id ${state.publisherId}, ignore the response`
        );
        return state;
      }
      const nextPageCursor = _.get(action, ['meta', CALL_API, 'headers', publishersActions.HOMEPAGE_NEXT_PAGE_CURSOR]);
      return u({ cursor: nextPageCursor }, state);
    }
    default:
      return state;
  }
};

const loadingNewSearchCriteria = (state = false, action: any) => {
  switch (action.type) {
    case homepageActions.LOADING_NEW_SEARCH_CRITERIA:
      return true;
    case publishersActions.GET_HOMEPAGE_STORIES:
      return action.sequence === Sequence.DONE ? false : state;
    default:
      return state;
  }
};

const loadingMorePages = (state = false, action: any) => {
  switch (action.type) {
    case homepageActions.LOADING_MORE_PAGES:
      return true;
    case publishersActions.GET_HOMEPAGE_STORIES: {
      return action.sequence === Sequence.DONE ? false : state;
    }
    default:
      return state;
  }
};

// ------------------------------------
// Reducer
// ------------------------------------
export default combineReducers({
  orderedStoryIdList,
  searchCriteria,
  pagination,
  loadingNewSearchCriteria,
  loadingMorePages,
});
