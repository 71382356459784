import _ from 'lodash';
import log from 'loglevel';
import ReactGA from 'react-ga';

import { logoutWithError } from 'state/auth/actions/authActions';
import { getShouldUseUnifiedLogin } from 'state/auth/selectors/authSelectors';
import { getPublishers } from 'state/publishers/actions/publishersActions';
import * as userActions from 'state/user/actions/userActions';
import { setUserDataWithGraphQLResponse } from 'state/user/actions/userActions';
import { getUseUnifiedLoginOverride, getHashedUserId } from 'state/user/selectors/userSelectors';

import { reportTimerWithDuration } from '../grapheneUtils';

import { fetchUserInfo } from 'utils/apis/graphQLUtils';
import { getPerformanceTiming } from 'utils/performance/performanceUtils';

import type { Dispatch, GetState } from 'types/redux';

const getUserInfo = () => (dispatch: Dispatch, getState: GetState) => {
  if (getShouldUseUnifiedLogin(getState()) || getUseUnifiedLoginOverride(getState())) {
    return fetchUserInfo(true).then(response => dispatch(setUserDataWithGraphQLResponse(response)));
  }

  return dispatch(userActions.getUserInfo());
};

export const requireUserInfo = (hostUsername?: string) => (dispatch: Dispatch, getState: GetState) => {
  const startTime = getPerformanceTiming();

  return (dispatch(getUserInfo()) as any)
    .then(() => {
      dispatch(getPublishers());

      // User ID enables the analysis of groups of sessions, across devices
      const hashedUserId = getHashedUserId(getState());
      if (hashedUserId) {
        ReactGA.set({ userId: hashedUserId });
      }
      const getUserEndTime = getPerformanceTiming();
      reportTimerWithDuration({
        metricsName: 'userInfoTiming',
        milliSec: getUserEndTime - startTime,
      });
    })
    .then(() => {
      const endTime = getPerformanceTiming();
      reportTimerWithDuration({
        metricsName: 'requireUserInfo',
        milliSec: endTime - startTime,
      });
    })
    .catch((e: any) => {
      log.error('Failed to fetch user info', e);
      const errorCode = _.get(e, ['fetchResults', 'data', 'code']);
      // If we fail to get the userInfo, redirect to login
      dispatch(logoutWithError(errorCode));
      throw new Error(e);
    });
};
export default requireUserInfo;
