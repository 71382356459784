import { ReactElement } from 'react';
import { MessageValue } from 'react-intl';

import { ErrorCodesEnum } from 'utils/errors/api/apiErrorConstants';

import { NotificationScopeEnum, StatusMessageSeverityEnum } from 'types/notifications';

export class ApiError extends Error {
  fetchResults: {
    // Response code
    status: number;
    statusText: string;
    data: any;
  };

  constructor(message: string, fetchResults: { status: any; statusText: any; data: any }) {
    super(message);
    this.fetchResults = fetchResults;
  }
}

export type ApiErrorNotification = {
  notificationScope: NotificationScopeEnum;
  severity: StatusMessageSeverityEnum;
  message: ReactElement;
  messagesPerErrorCode: { [key in ErrorCodesEnum]: { message: ReactElement } };
  options: {
    hideErrorCode: boolean;
  };
};

export type ParamExtractor = (error: ApiError, options?: any) => { [key: string]: MessageValue };

export type ApiErrorCodeMessage = {
  message: JSX.Element;
  paramExtractor: ParamExtractor;
};

export type OnProgressEvent = {
  readonly loaded: number;
  readonly total: number;
};
