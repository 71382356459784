import is from 'is_js';
import _ from 'lodash';
import log from 'loglevel';
import { Action } from 'redux';

import * as transactionsActions from 'state/transactions/actions/transactionsActions';

import { Sequence } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import u from 'utils/safeUpdeep';

/**
 * Allows for simple handling of start() and done() sequence states in reducers:
 *
 *   const myReducer = createSequenceHandlingReducer({}, MY_ACTION_TYPE, {
 *     start(state, action) {
 *       // Do stuff
 *     },
 *
 *     done(state, action) {
 *       // Do stuff
 *     },
 *   });
 *
 * The actionType argument can also be passed as an array in order to handle multiple actions
 *
 */
export const createSequenceHandlingReducer = (defaultState: any, actionTypes: any, handlers: any) => {
  if (is.not.object(handlers) || is.not.function(handlers.start) || is.not.function(handlers.done)) {
    throw new Error('The handlers argument must be an object and define start() and done() handlers');
  }

  const actionTypesSet = actionTypesAsSet(actionTypes, 'createSequenceHandlingReducer');

  return (state: any = defaultState, action: any) => {
    if (!action.type) {
      log.error('Action does not have a type');
      return state;
    }

    if (actionTypesSet.has(action.type)) {
      if (!action.sequence) {
        log.error('Action ', action.type, ' does not have a sequence property');
        return state;
      }

      if (!handlers[action.sequence]) {
        // Progress handler is optional
        if (action.sequence !== Sequence.PROGRESS) {
          log.error(`Unrecognized sequence value: '${action.sequence}'`);
        }
        return state;
      }

      return handlers[action.sequence](state, action);
    }

    return state;
  };
};

export const createTransactionalSequenceHandlingReducer = (
  defaultState: any,
  transactionType: any,
  actionTypes: any,
  handlers: any
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(transactionType).is.string();

  return createSequenceHandlingReducer(
    defaultState,
    _.concat(actionTypes, [transactionsActions.ROLLBACK_TRANSACTION]),
    {
      start: handlers.start,
      done(state: any, action: any) {
        if (
          action.type === transactionsActions.ROLLBACK_TRANSACTION &&
          action.payload.transactionType !== transactionType
        ) {
          return state;
        }

        return handlers.done(state, action);
      },
    }
  );
};

export const createSequenceCountingReducer = (actionTypes: any) => {
  return createSequenceHandlingReducer(0, actionTypes, {
    start(state: any, action: any) {
      return state + 1;
    },

    done(state: any, action: any) {
      const nextState = state - 1;
      checkNotNegative(nextState, action);
      return nextState;
    },
  });
};

export const createByIdReducer = (entityIdField: any, reducer: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityIdField).is.string.or.is.function();

  return (state: any = {}, action: any) => {
    if (hasEntityId(entityIdField, action)) {
      // TODO: handle null
      const entityId: any = getEntityId(entityIdField, action);
      const previousEntryState = state[entityId];
      const newEntryState = reducer(previousEntryState, action);

      if (previousEntryState !== newEntryState) {
        return u({ [entityId]: u.constant(newEntryState) }, state);
      }
    }
    return state;
  };
};

const mergeEntityIdsObjects = (entityIds: any, generateFunc: any) => {
  if (!is.array(entityIds)) {
    entityIds = [entityIds]; // eslint-disable-line no-param-reassign
  }
  return Object.assign({}, ...entityIds.map(generateFunc));
};

export const createSequenceCountingByIdReducer = (entityIdField: any, actionTypes: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityIdField).is.string.or.is.function();

  return createSequenceHandlingReducer({}, actionTypes, {
    start(state: any, action: any) {
      if (!hasEntityId(entityIdField, action)) {
        return state;
      }

      const entityIds = getEntityId(entityIdField, action);

      const mergedObj = mergeEntityIdsObjects(entityIds, (entityId: any) => {
        const count = state[entityId] || 0;

        return {
          [entityId]: count + 1,
        };
      });

      return u(mergedObj, state);
    },

    done(state: any, action: any) {
      if (!hasEntityId(entityIdField, action)) {
        return state;
      }

      const entityIds = getEntityId(entityIdField, action);

      const mergedObj = mergeEntityIdsObjects(entityIds, (entityId: any) => {
        const count = state[entityId] || 0;
        const newCount = count - 1;

        checkNotNegative(newCount, action);

        return {
          [entityId]: newCount,
        };
      });

      return u(mergedObj, state);
    },
  });
};

export const createAgeByIdReducer = (entityIdField: any, actionTypes: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityIdField).is.string.or.is.function();

  return createSequenceHandlingReducer({}, actionTypes, {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (!hasEntityId(entityIdField, action)) {
        return state;
      }

      // TODO: handle null
      const entityId: any = getEntityId(entityIdField, action);

      if (action.error) {
        return state;
      }

      return u(
        {
          [entityId]: Date.now(),
        },
        state
      );
    },
  });
};

export const createTimestampReducer = (actionTypes: any) => {
  return createSequenceHandlingReducer(0, actionTypes, {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }
      const timestamp = _.get(action, ['meta', 'timestamp']);
      if (is.number(timestamp)) {
        return timestamp;
      }
      return state;
    },
  });
};

/*
  Tracks the timestamp when a normalizr entity was loaded
    for an entity given by the entityName input. The timestamp
    is stored at the id of every entity that was updated from this request.

  entityName - String
*/
export const createEntityAgeReducer = (entityName: any, actionTypes: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityName).is.string();
  warnOnEntityNameSlash(entityName);

  return createSequenceHandlingReducer({}, actionTypes, {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.payload.entities).is.object();
      if (!action.payload.entities[entityName]) {
        return state;
      }

      const updates = {};
      const now = Date.now();
      Object.keys(action.payload.entities[entityName]).forEach(entityKey => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        updates[entityKey] = now;
      });
      return u(updates, state);
    },
  });
};

/*
  Updates the entities given by normalizr of the specific entity
    given by entityName. If no errors are present, the action
    is required to have a payload of entities. It is optional whether
    the given entity is actually present or not.
*/
export const createEntityReducer = (entityName: any, actionTypes: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityName).is.string();
  warnOnEntityNameSlash(entityName);

  return createSequenceHandlingReducer({}, actionTypes, {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (action.error) {
        return state;
      }
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.payload).is.object();
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.payload.entities).is.object();

      // Only mutates the state if new entities are deep different
      return u(
        _.mapValues(action.payload.entities[entityName], entity => u.ifDiff(entity)),
        state
      );
    },
  });
};

/*
 Creates a very simple reducer that just merges the payload into the state with sequence,
 it's useful when you have a sequence action and you want to merge the action.payload into the state
 */
export const createSequenceMergeReducer = (actionTypes: any) => {
  return createSequenceHandlingReducer({}, actionTypes, {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.payload).is.object();
      return u(action.payload, state);
    },
  });
};

/*
 Creates a very simple reducer that just return the payload into the state with sequence,
 it's useful when you have a sequence action and you want to replace the state with action.payload

 eg: const cmsNotificationSetting = createSequenceSimpleReducer(snapAdminActions.GET_CMS_NOTIFICATION_SETTING);
 */
export const createSequenceSimpleReducer = (actionTypes: any) => {
  return createSequenceHandlingReducer({}, actionTypes, {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.payload).is.object();
      return action.payload;
    },
  });
};

export function createSimpleReducer<TState, TAction extends Action<string>>(
  actionTypes: string,
  reducer: (b: TState, a: TAction) => TState,
  defaultState: TState
): (b: TState, a: TAction) => TState {
  const actionTypesSet = actionTypesAsSet(actionTypes, 'createSimpleReducer');
  return (state: TState = defaultState, action: TAction): TState => {
    if (actionTypesSet.has(action.type)) {
      return reducer(state, action);
    }

    return state;
  };
}

export const createSequenceErrorsByIdReducer = (entityIdField: any, actionTypes: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityIdField).is.string.or.is.function();

  return createSequenceHandlingReducer({}, actionTypes, {
    start(state: any, action: any) {
      return u(
        mergeEntityIdsObjects(getEntityId(entityIdField, action), (entityId: any) => ({
          [entityId]: null,
        })),
        state
      );
    },

    done(state: any, action: any) {
      return u(
        mergeEntityIdsObjects(getEntityId(entityIdField, action), (entityId: any) => ({
          [entityId]: action.error,
        })),
        state
      );
    },
  });
};

export const createRemoverByIdReducer = (entityIdField: any, actionTypes: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityIdField).is.string();

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const actionTypesSet = actionTypesAsSet(actionTypes);
  return (state: any = {}, action: any) => {
    if (actionTypesSet.has(action.type) && !action.error) {
      return u(u.omit(getEntityId(entityIdField, action)), state);
    }
    return state;
  };
};

export const createClearingReducer = (defaultState: any, actionTypes: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const actionTypesSet = actionTypesAsSet(actionTypes);
  return (state: any = {}, action: any) => {
    if (actionTypesSet.has(action.type)) {
      return defaultState;
    }
    return state;
  };
};

export const createActivatingIdReducer = (entityIdField: any, actionTypes: any) => {
  const actionTypesSet = actionTypesAsSet(actionTypes, 'createActivatingIdReducer');

  return (state: any = null, action: any) => {
    if (actionTypesSet.has(action.type)) {
      return action.payload[entityIdField];
    }
    return state;
  };
};

/*
  Creates a very simple reducer that just merges the payload into the state.

  Useful for config options and such, which are synchronously dispatched actions
  that have no chance of failure.
*/
export const createMergeReducer = (actionTypes: any) => {
  const actionTypesSet = actionTypesAsSet(actionTypes, 'createMergeReducer');

  return (state: any = {}, action: any) => {
    if (actionTypesSet.has(action.type)) {
      return {
        ...state,
        ...action.payload,
      };
    }
    return state;
  };
};

export function serializeReducers(reducerList: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(reducerList).is.array();

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  reducerList.forEach((reducer: any) => assertArg(reducer).is.function());

  return (state: any, action: any) =>
    reducerList.reduce((currentState: any, reducer: any) => reducer(currentState, action), state);
}

function warnOnEntityNameSlash(entityName: any) {
  if (entityName.indexOf('/') >= 0) {
    log.warn(`Expected entityName to not have a slash - Is this an actionType? received: ${entityName}`);
  }
}

function actionTypesAsSet(actionTypes: string | Array<string>, fnName: any) {
  if (typeof actionTypes === 'string') {
    return new Set([actionTypes]);
  }

  /* often these errors can be thrown due to a dependency loop */
  if (!is.array(actionTypes)) {
    throw new Error(
      `The actionTypes argument to ${String(fnName)}() must be an array or a string received: ${JSON.stringify(
        actionTypes
      )}`
    );
  }
  actionTypes.forEach(actionType => {
    if (!is.string(actionType)) {
      throw new Error(`actionType must be a string received: ${JSON.stringify(actionTypes)}`);
    }
  });
  return new Set(actionTypes);
}

function checkNotNegative(value: any, action: any) {
  if (value < 0) {
    log.warn(`Counting reducer resulted in a negative value for action type ${action.type}`);
  }
}

function hasEntityId(entityIdField: any, action: any) {
  return is.existy(getEntityId(entityIdField, action, { failSilently: true }));
}

// The entityIdField argument can optionally be provided as a function rather than a string.
// In this case it will be supplied the action object, and is expected to return an entity id.
function getEntityId(entityIdField: any, action: any, { failSilently = false } = {}) {
  let errorArgs;

  if (is.function(entityIdField)) {
    const entityId = entityIdField(action);

    if (is.existy(entityId)) {
      return entityId;
    }

    errorArgs = ['entityIdField function did not return an entity id. Action was:', action];
  } else {
    if (is.object(action.params) && is.existy(action.params[entityIdField])) {
      return action.params[entityIdField];
    }

    errorArgs = [`Expected action to have a ${entityIdField} param. Action was:`, action];
  }

  if (!failSilently) {
    log.error(...errorArgs);
  }

  return failSilently ? null : '<MISSING_ID>';
}
