import _ from 'lodash';

import * as changeHashBuilder from 'utils/changeHashBuilder';

function arrayKeyReducer(reducedObject: any, currentValue: any) {
  return {
    ...reducedObject,
    ...currentValue,
  };
}

function keyValueMapper(updatedObject: any) {
  return (key: any) => ({
    [key]: updatedObject[key],
  });
}

const pickPropertiesForHashing = function pickPropertiesForHashing(
  existingObject: any,
  keysToHashOver: any = {},
  requiredProperties: any = []
) {
  return _.pick(existingObject, _.concat(requiredProperties, Object.keys(keysToHashOver)));
};

const buildHashForProperties = function buildHashForProperties(
  propertiesForHashing: any,
  unorderedArrayProperties: any = []
) {
  return changeHashBuilder.build(propertiesForHashing, { unorderedArrayProperties }).toHash();
};

// TODO @yyan we should handle existingObject = null case here, right now it still return a hash for null value.
const buildHashForExistingKeys = function buildHashForExistingKeys(
  existingObject: any,
  keysToHashOver: any = {},
  unorderedArrayProperties: any = [],
  requiredProperties: any = []
) {
  const propertiesForHashing = pickPropertiesForHashing(existingObject, keysToHashOver, requiredProperties);

  return buildHashForProperties(propertiesForHashing, unorderedArrayProperties);
};

const buildShallowDiff = function buildShallowDiff(
  existingObject: {} | undefined | null,
  updatedObject: any = {},
  requiredProperties: string[] = [],
  propertiesToExclude: string[] = []
) {
  const requiredKeys = _.pick(existingObject, requiredProperties);

  const modifiedDiff = Object.keys(updatedObject)
    .filter(potentialUpdateKey => propertiesToExclude.indexOf(potentialUpdateKey) < 0)
    .filter(
      potentialUpdateKey =>
        existingObject &&
        !_.isEqual(
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          existingObject[potentialUpdateKey],
          updatedObject[potentialUpdateKey]
        )
    )
    .map(keyValueMapper(updatedObject))
    .reduce(arrayKeyReducer, {});

  return {
    ...requiredKeys,
    ...modifiedDiff,
  };
};

export { buildShallowDiff, buildHashForExistingKeys, pickPropertiesForHashing, buildHashForProperties };
