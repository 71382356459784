import is from 'is_js';
import { get, defaults } from 'lodash';

import { DEFAULT_LOGO_COLOR } from 'config/constants';

import type { TileLogo, RawMetadataTileLogo } from 'types/tileLogos';

export function getTileLogoMetadata(tileLogo: any) {
  return tileLogo.metadata;
}

function metadataPropertyExtractorFactory(propertyToReturn: string, fallbackDefault: any) {
  return (tileLogo: any, fallbackValue: any) => {
    const fallbackValueReturn = fallbackValue === undefined ? fallbackDefault : fallbackValue;
    const metadata = getTileLogoMetadata(tileLogo);
    return get(metadata, propertyToReturn, fallbackValueReturn) || fallbackValueReturn;
  };
}

export const getTileLogoColor = metadataPropertyExtractorFactory('color', DEFAULT_LOGO_COLOR);
export const getTileLogoOrder = metadataPropertyExtractorFactory('order', 0);

export const parseTileLogoMetadata = (tileLogo: RawMetadataTileLogo, order: number = 0): TileLogo => {
  const originalMetadata = get(tileLogo, 'metadata', DEFAULT_LOGO_COLOR);
  let finalMetadata = {};
  try {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    finalMetadata = JSON.parse(originalMetadata);
  } catch (error) {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | null' is not assignable to type '{}... Remove this comment to see the full error message
    finalMetadata = originalMetadata;
  }

  return defaults(
    {
      metadata:
        is.string(finalMetadata) || !finalMetadata
          ? {
              color: tileLogo.metadata || DEFAULT_LOGO_COLOR,
              order,
              needsMetadataSchemaMigration: true,
            }
          : finalMetadata,
    },
    tileLogo
  );
};

export function sortTileLogos(a: TileLogo, b: TileLogo): number {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const orderA = getTileLogoOrder(a);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const orderB = getTileLogoOrder(b);
  return orderA === orderB ? b.id - a.id : orderA - orderB;
}
