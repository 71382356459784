import Modal from 'antd/lib/modal';
import classNames from 'classnames';
import React, { ReactNode, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import SDSButton, { ButtonSize, ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './SDSDialog.scss';

// Modals from snapnet have z-index 1050 and from antd 1000,
// so in order to avoid occluding of SDSModal, the higher value is used.
const Z_INDEX = 1050;

type Props = {
  visible: boolean;
  width?: number;
  onOk: () => void | Promise<void>;
  onCancel: () => void;
  cancelText?: JSX.Element;
  okText?: JSX.Element;
  children: ReactNode;
  'data-test'?: string;
  title?: JSX.Element;
  isBodyCentered?: boolean;
  okButtonProps?: {
    disabled: boolean;
  };
  cancelButtonProps?: {
    disabled: boolean;
  };
};

export function SDSDialog(props: Props) {
  const [isSaving, setIsSaving] = useState(false);

  const onOkClick = useCallback(async () => {
    if (props.okButtonProps?.disabled || isSaving) {
      return;
    }

    if (props.onOk) {
      setIsSaving(true);
      try {
        await props.onOk();
      } finally {
        setIsSaving(false);
      }
    }
  }, [isSaving, props]);

  const defaultMessageOk = (
    <FormattedMessage
      id="confirm-button-modal"
      description="Text indicating to user to confirm a prompt"
      defaultMessage="Confirm"
    />
  );

  const defaultMessageDeleteText = (
    <FormattedMessage id="cancel-button-label" defaultMessage="Cancel" description="Label for cancel button" />
  );

  const cancelText = props.cancelText ? props.cancelText : defaultMessageDeleteText;

  const okText = props.okText ? props.okText : defaultMessageOk;

  const footer = (
    <>
      <SDSButton
        type={ButtonType.SECONDARY}
        size={ButtonSize.LARGE}
        onClick={props.onCancel}
        data-test={`${props['data-test']}.cancel.button`}
        disabled={props.cancelButtonProps?.disabled || isSaving}
      >
        {cancelText}
      </SDSButton>
      <SDSButton
        type={ButtonType.PRIMARY}
        size={ButtonSize.LARGE}
        onClick={onOkClick}
        data-test={`${props['data-test']}.ok.button`}
        disabled={props.okButtonProps?.disabled || isSaving}
      >
        {okText}
      </SDSButton>
    </>
  );

  const modalStyle = props.isBodyCentered ? classNames(style.modal, style.centerBody) : style.modal;

  return (
    <Modal
      className={modalStyle}
      title={props.title}
      visible={props.visible}
      width={props.width}
      footer={footer}
      centered
      zIndex={Z_INDEX}
      confirmLoading={isSaving}
      closable
      onCancel={props.onCancel}
    >
      {props.children}
    </Modal>
  );
}

export default SDSDialog;
