import classnames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import styleSheet from './OriginalSDSTile.scss';

export type BaseComponentProps = {
  className?: string;
  style?: any;
};

export type Hoverable = {
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
};

export const TileTypes = {
  DEFAULT: 'default',
  RADIO: 'radio',
};

export type TileType = typeof TileTypes.DEFAULT | typeof TileTypes.RADIO;

export type TileProps = {
  children?: ReactNode;
  description?: ReactNode;
  disabled?: boolean;
  emphasized?: boolean;
  onClick?: () => any;
  readOnly?: boolean;
  selected?: boolean;
  title?: ReactNode;
  type?: TileType;
  value?: any;
};

type Props = TileProps & Hoverable & BaseComponentProps;

const Tile = (props: Props) => {
  const {
    children,
    className,
    description,
    disabled,
    emphasized,
    onClick = () => null,
    onMouseEnter,
    onMouseLeave,
    readOnly,
    selected,
    style,
    title,
    type = TileTypes.DEFAULT,
  } = props;

  const isRadio = type === TileTypes.RADIO;

  const tileClass = classnames(styleSheet.sdsTile, className, {
    [styleSheet.emphasized]: emphasized,
    [styleSheet.selected]: selected,
    [styleSheet.sdsRadioTile]: isRadio,
    [styleSheet.sdsReadOnly]: readOnly,
  });

  return (
    <button
      className={tileClass}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type="button"
      style={style}
      disabled={disabled}
    >
      {isRadio ? (
        <div className={styleSheet.sdsRadio}>
          <div className={styleSheet.sdsRadioButtonContainer}>
            <div className={styleSheet.sdsRadioButton} />
            <div className={styleSheet.sdsRadioContents}>
              {title && <div className={styleSheet.sdsRadioTitle}>{title}</div>}
              {description && <div className={styleSheet.sdsRadioDescription}>{description}</div>}
            </div>
          </div>
          {selected && children && <div className={styleSheet.sdsRadioChildrenContainer}>{children}</div>}
        </div>
      ) : (
        children
      )}
    </button>
  );
};

Tile.displayName = 'Tile';

export default Tile;
