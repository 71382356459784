import invariant from 'invariant';
import { get } from 'lodash';
import log from 'loglevel';

import { getAssetById } from 'state/asset/selectors/assetSelectors';
import * as autocompleteActions from 'state/autocomplete/actions/autocompleteActions';
import * as buildStatusActions from 'state/buildStatus/actions/buildStatusActions';
import { createActivatingAction, createClearingAction } from 'state/common/actionFactories';
import * as editionsActions from 'state/editions/actions/editionsActions';
import { getEdition } from 'state/editions/actions/getEditionActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as editorActions from 'state/editor/actions/editorActions';
import * as editorSelectors from 'state/editor/selectors/editorSelectors';
import { isAdvancedCurationEnabled } from 'state/features/selectors/featuresSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as mediaLibraryTrayActions from 'state/mediaLibrary/actions/mediaLibraryTrayActions';
import * as publisherStoryEditorModeActions from 'state/publisherStoryEditor/actions/publisherStoryEditorModeActions';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as routerActions from 'state/router/actions/routerActions';
import { goToNoPermission } from 'state/router/actions/routerActions';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import {
  hasBottomSnap,
  getNextBottomSnap,
  isEmptyTopsnap,
  isSubscribeSnap,
  isCuratedSnap,
} from 'state/snaps/schema/snapEntityHelpers';
import { richSnapSchema } from 'state/snaps/schema/snapsSchema';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import {
  SET_ACTIVE_EDITION,
  SET_LAST_VISITED_SNAP_PUB_URL,
  ARTICLE_IMPORT_DIFFBOT,
  ARTICLE_IMPORT,
  TOGGLE_MEDIA_LIBRARY_DRAWER,
} from '../actionIds/publisherStoryEditorActionIds';
import * as publisherStoryEditorSelectors from '../selectors/publisherStoryEditorSelectors';

import { EDITOR_MODES, BuildType } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import * as proxyAPI from 'utils/apis/proxyAPI';
import { assertArg, assertState } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { mediaErrorHandler } from 'utils/errors/media/mediaErrorUtils';
import { bulkDownloadFiles } from 'utils/files/downloadFilesUtil';
import { functionRef } from 'utils/functionUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { createAssetUrl } from 'utils/media/assetUtils';
import { serializePromises } from 'utils/promiseUtils';
import * as publisherStoryEditorValidation from 'utils/publisherStoryEditor/publisherStoryEditorValidation';

import { assertSnapId } from 'types/common';
import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import { PublisherID } from 'types/publishers';
import type { Dispatch, GetState } from 'types/redux';
import { SnapType } from 'types/snaps';
import type { TopSnap } from 'types/snaps';

export const setActiveEdition = createActivatingAction(
  SET_ACTIVE_EDITION,
  'edition',
  functionRef(editionsSelectors, 'getEditionById')
);
export const clearActiveEdition = createClearingAction(SET_ACTIVE_EDITION, 'edition');
let lastInitializedEditionId: any = null;
export type InitOpts = {
  editionId: EditionID;
  publisherId: PublisherID;
  publisherFetchPromise: Promise<unknown>;
};
export const initializePublisherStoryEditor = ({
  editionId,
  publisherId,
  publisherFetchPromise = Promise.resolve(),
}: InitOpts) => async (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  lastInitializedEditionId = editionId;
  return Promise.resolve()
    .then(() => {
      // The build status can be fetched asynchronously
      dispatch(buildStatusActions.fetchEditionBuildStatus({ editionId }));
      // Autocomplete tags can be fetched asynchronously
      dispatch(autocompleteActions.autocompleteSCCGetTags());
      return Promise.all([
        dispatch(editorActions.unloadBottomSnap()),
        dispatch(editionsActions.getEditionIfStale({ editionId })),
      ]);
    })
    .then(() => {
      const edition = editionsSelectors.getEditionById(getState())(editionId);
      if (!edition) {
        throw new Error(`Missing edition ${editionId}`);
      }
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(edition).is.object();
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(edition.publisherId).is.number();
      if (edition.publisherId !== publisherId) {
        incrementCounter('PublisherStoryEditor.PublisherMismatch', {
          publisherId: publisherId.toString(),
          editionId: editionId.toString(),
        });
        log.error('Edition publisher and url publisher dont match');

        return dispatch(goToNoPermission({ publisherId, overwriteHistory: true }));
      }
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(edition.snapIds).is.array();
      if (edition.snapIds.length === 0) {
        return Promise.resolve();
      }
      return dispatch(snapsActions.loadSnapsIfRequired({ snapIds: edition.snapIds }));
    })
    .then(() => publisherFetchPromise) // Needs publisher data from here down
    .then(() => {
      // By the time this part is reached the user might have selected another edition so to avoid a race condition
      // we need to validate if that has not happened before activating the edition
      if (editionId === lastInitializedEditionId) {
        return dispatch(setActiveEdition(editionId));
      }
      return null;
    });
};
export const addNewSnapToEdition = ({
  editionId,
  index,
  snapType,
}: {
  editionId: number;
  index: number;
  snapType: SnapType;
}) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(index).is.number();
  return (dispatch: Dispatch, getState: GetState) => {
    // Always get latest edition before add a new snap to it.
    return dispatch(getEdition({ editionId })).then(() => {
      const edition = editionsSelectors.getEditionById(getState())(editionId);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(edition).is.object();
      invariant(edition, 'missing active edition');
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(edition.snapIds).is.array();
      return dispatch(snapsActions.createSnap(snapType, {}, { editionId, atIndex: index }))
        .then((createAction: any) => {
          const newSnapId = createAction.payload.result;
          assertSnapId(newSnapId);
          return dispatch(
            editionsActions.addSnapAndSave({
              editionId,
              snapId: newSnapId,
              index,
            })
          ).then(() => newSnapId);
        })
        .catch(apiErrorHandler(dispatch, ErrorContexts.ADD_NEW_SNAP_TO_EDITION));
    });
  };
};
function uploadFileToSnapAsync(file: Blob, snapId: SnapId, editionId: EditionID, dispatch: Dispatch) {
  // We don't return it so that the next promises in the chain do not have to wait for the media to upload
  dispatch(mediaActions.uploadMediaToTopsnap({ file, snapId, editionId }));
  return Promise.resolve(snapId);
}
export const addMultipleSnapsWithMediaToEdition = ({
  editionId,
  initialIndex,
  snapType,
  files,
}: {
  editionId: EditionID;
  initialIndex: number;
  snapType: SnapType;
  files: Blob[];
}) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  return (dispatch: Dispatch, getState: GetState) => {
    const promises = files.map((file, index) => {
      const nextIndex = initialIndex + index;
      const snapAtIndex = editionsSelectors.getSnapAtIndex(getState())(editionId)(nextIndex);
      if (snapAtIndex != null && isEmptyTopsnap(snapAtIndex) && !isSubscribeSnap(snapAtIndex)) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Blob | undefined' is not assigna... Remove this comment to see the full error message
        return () => uploadFileToSnapAsync(files[index], snapAtIndex.id, editionId, dispatch);
      }
      return () =>
        dispatch(addNewSnapToEdition({ editionId, index: initialIndex + index, snapType })).then((snapId: any) => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Blob | undefined' is not assigna... Remove this comment to see the full error message
          return uploadFileToSnapAsync(files[index], snapId, editionId, dispatch);
        });
    });
    return publisherStoryEditorValidation
      .validateFiles(files)
      .catch(mediaErrorHandler(dispatch, ErrorContexts.VALIDATE_SNAP_UPLOAD))
      .then(() => serializePromises(promises));
  };
};
export const deleteSnapFromActiveEdition = ({ snapId }: { snapId: SnapId }) => {
  assertSnapId(snapId);
  return (dispatch: Dispatch, getState: GetState) => {
    const publisherId = userSelectors.getActivePublisherId(getState());
    const activeEdition = publisherStoryEditorSelectors.getActiveEdition(getState());
    const snapToDelete = snapsSelectors.getSnapById(getState())(snapId);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(publisherId).is.number();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(activeEdition).is.object();
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(snapToDelete).is.object();
    invariant(activeEdition, 'missing active edition');
    const activeWholeSnapId = editorSelectors.getActiveWholeSnapId(getState());
    // Change route if we are deleting the active snap
    const routePromises = [];
    if (activeWholeSnapId === snapId) {
      routePromises.push(
        dispatch(
          routerActions.goToPublisherStoryEditor({ publisherId, editionId: activeEdition.id, overwriteHistory: true })
        )
      );
    }
    return Promise.all(routePromises)
      .then(() => dispatch(editionsActions.removeSnapAndSave({ editionId: activeEdition.id, snapId })))
      .then(() => dispatch(snapsActions.deleteWholeSnap({ snapId })))
      .catch(apiErrorHandler(dispatch, ErrorContexts.DELETE_SNAP_FROM_EDITION));
  };
};
export const takeDownLiveEdition = (maybeEditionId?: number) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const getActiveEditionId = () => {
      const activeEdition = publisherStoryEditorSelectors.getActiveEdition(getState());
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertState(activeEdition).is.object();
      return activeEdition && activeEdition.id;
    };
    const editionId = maybeEditionId || getActiveEditionId();
    invariant(editionId, 'could not determine active edition id');
    return dispatch(editionsActions.takeDownLiveEdition({ editionId })).catch(
      apiErrorHandler(dispatch, ErrorContexts.TAKE_DOWN_LIVE_EDITION)
    );
  };
};
export const loadActiveEditionsForActivePublisher = () => (dispatch: Dispatch, getState: GetState) => {
  const publisherId = userSelectors.getActivePublisherId(getState());
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(publisherId).is.number();
  return (dispatch(publishersActions.getActiveEditionsForPublisher({ publisherId })) as any).catch(
    apiErrorHandler(dispatch, ErrorContexts.LOAD_ACTIVE_EDITIONS_FOR_PUBLISHER)
  );
};
export const duplicateStory = ({ editionId, publisherId }: { editionId: number; publisherId: number }) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  return (dispatch: Dispatch) =>
    dispatch(
      editionsActions.duplicateStory({
        sourceStoryId: editionId,
        destinationPublisherId: publisherId,
      })
    );
};
export const setSnapPropertiesAndUpdateBuildStatus = (
  {
    editionId,
    snapId,
  }: {
    editionId: number;
    snapId: SnapId;
  },
  properties: {}
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number();
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.not.empty();
  return (dispatch: Dispatch) => {
    return dispatch(snapsActions.setSnapPropertiesAndSave({ snapId }, properties)).then(() =>
      dispatch(buildStatusActions.fetchEditionBuildStatus({ editionId }))
    );
  };
};
type IngestOpts = {
  snapId: SnapId;
  url: string;
  publisherId: number;
};
export const ingestUrlForArticleSnap = ({ snapId, url, publisherId }: IngestOpts) => (dispatch: any) => {
  const actionPromise = () =>
    dispatch({
      type: ARTICLE_IMPORT_DIFFBOT,
      meta: {
        [CALL_API]: {
          method: 'get',
          endpoint: proxyAPI.attachments.ingestArticle({ snapId, publisherId, url }),
          finalizer: optimisticJsonFinalizer,
        },
        schema: richSnapSchema,
      },
      params: { snapId, publisherId, url },
    });
  return dispatch({
    type: ARTICLE_IMPORT,
    payload: {
      promise: actionPromise,
    },
    params: { snapId, publisherId, url },
  }).catch(apiErrorHandler(dispatch, ErrorContexts.ARTICLE_INGESTION));
};
type CreateArticleIngest = (a: IngestOpts) => (b: Dispatch, a: GetState) => Promise<any>;
export const createArticleAttachmentAndIngestFromUrl: CreateArticleIngest = ({
  snapId,
  url,
  publisherId,
}: IngestOpts) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const snap = snapsSelectors.getSnapById(getState())(snapId);
    invariant(snap, `could not find snap [${snapId}]`);
    if (hasBottomSnap(snap)) {
      return Promise.resolve();
    }
    return dispatch(snapsActions.addInteractionAndSave({ snapId }, SnapType.ARTICLE, {}))
      .then(() => {
        const newSnap = snapsSelectors.getSnapById(getState())(snapId);
        invariant(newSnap, `could not find snap [${snapId}]`);
        const bottomsnap = getNextBottomSnap(newSnap);
        invariant(bottomsnap, `could not find bottom snap for snap [${snapId}]`);
        dispatch(editorActions.setUnreadAttachment(bottomsnap.id));
        return dispatch(
          exports.ingestUrlForArticleSnap({
            snapId: bottomsnap.id,
            url,
            publisherId,
          })
        );
      })
      .then(() => {
        const isInSnapPubMode =
          publisherStoryEditorSelectors.getStoryEditorMode(getState()).editorMode === EDITOR_MODES.SNAP_PUB;
        if (isInSnapPubMode) {
          // We only want to move to article mode if the user is already in Snap Pub mode
          // If they aren't, we'll be bringing up Snap Pub when that's not what they want, and will create a strange UI experience
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          dispatch(publisherStoryEditorModeActions.setEditorMode(EDITOR_MODES.SNAP_PUB_AND_ARTICLE, url));
        }
      });
  };
};
const overlayAssetsToDownload = (topSnaps: TopSnap[], snapId: SnapId | undefined | null, editionId: EditionID) => {
  return topSnaps
    .map((topsnap: TopSnap, index: number) => {
      const assetId = get(topsnap, 'overlayImageAssetId');
      if (!assetId) {
        return null;
      }
      if (snapId && topsnap.id !== snapId) {
        return null;
      }
      const extension = 'png';
      const paddedIndex = String(index + 1).padStart(2, '0');
      const filename = `Story_${editionId}_Snap_${paddedIndex}_Overlay.${extension}`;
      return {
        filename,
        href: createAssetUrl(
          assetId,
          {
            attachment: true,
            buildTypeId: BuildType.IMAGE_PREVIEW,
            buildSettingId: 'none',
            downloadFilename: filename,
          },
          mediaLibraryAPI.asset.downloadBuild
        ),
      };
    })
    .filter(Boolean);
};
export const downloadTopsnapAssets = ({
  editionId,
  snapId,
  includeOverlay = false,
}: {
  editionId: EditionID;
  snapId?: SnapId;
  includeOverlay?: boolean;
}) => (dispatch: Dispatch, getState: GetState) => {
  const snaps = publisherStoryEditorSelectors.getEditionSnaps(getState())(editionId) as TopSnap[];
  const snapAssetsToDownload = snaps
    .map((topsnap, index) => {
      const assetId = get(topsnap, 'imageAssetId') || get(topsnap, 'videoAssetId');
      if (!assetId || topsnap?.isContentDeleted) {
        return null;
      }
      if (
        isCuratedSnap(topsnap) &&
        !(userSelectors.isCurationSnapDownloader(getState()) && isAdvancedCurationEnabled(getState()))
      ) {
        return null;
      }
      if (snapId && topsnap.id !== snapId) {
        return null;
      }
      const extension = topsnap.type === SnapType.VIDEO ? 'mp4' : 'jpg';
      const paddedIndex = String(index + 1).padStart(2, '0');
      const filename = `Story_${editionId}_Snap_${paddedIndex}.${extension}`;
      return {
        filename,
        href: createAssetUrl(assetId, { downloadFilename: filename }, mediaLibraryAPI.asset.forceDownload),
      };
    })
    .filter(Boolean);
  const allAssets = [
    ...snapAssetsToDownload,
    ...(includeOverlay ? overlayAssetsToDownload(snaps, snapId, editionId) : []),
  ];
  if (allAssets.length > 0) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '({ filename: string; href: strin... Remove this comment to see the full error message
    bulkDownloadFiles(allAssets);
  }
};
export const downloadSASVideoAsset = ({ storyId }: { storyId: EditionID }) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const transcodedMediaId = publisherStoryEditorSelectors.getTranscodedMediaIdForStory(getState())(storyId);
  if (!transcodedMediaId) {
    return;
  }
  const asset = getAssetById(getState())(transcodedMediaId);
  if (!asset) {
    return;
  }
  const filename = `Story_${storyId}.mp4`;
  const file = {
    filename,
    href: createAssetUrl(
      transcodedMediaId,
      { downloadFilename: filename, forceSourceMedia: true },
      mediaLibraryAPI.asset.forceDownload
    ),
  };
  bulkDownloadFiles([file]);
};
export const toggleMediaLibraryDrawer = () => {
  return (dispatch: Dispatch, getState: GetState) => {
    const toggleDispatchResult = dispatch({
      type: TOGGLE_MEDIA_LIBRARY_DRAWER,
    });
    // Clear tray after toggling
    if (publisherStoryEditorSelectors.isMediaLibraryDrawerVisible(getState())) {
      dispatch(mediaLibraryTrayActions.clearTray());
    }
    return toggleDispatchResult;
  };
};
export const setLastVisitedSnapPublisherUrl = (lastVisitedSnapPublisherUrl: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    return dispatch({
      type: SET_LAST_VISITED_SNAP_PUB_URL,
      payload: { lastVisitedSnapPublisherUrl },
    });
  };
};
