import is from 'is_js';
import _ from 'lodash';
import log from 'loglevel';
import { combineReducers } from 'redux';

import {
  createSequenceHandlingReducer,
  createSequenceCountingByIdReducer,
  serializeReducers,
} from 'state/common/reducerFactories';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as videoLibraryActions from 'state/videoLibrary/actions/videoLibraryActions';

import { EMPTY_OBJECT } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import u from 'utils/safeUpdeep';

// TODO (piers) Mass rename `media` > `asset`
const uploadResults = createSequenceHandlingReducer({}, [mediaActions.UPLOAD_MEDIA, mediaActions.CLAIM_MEDIA], {
  start(state: any, action: any) {
    const { dataUri, params } = action;
    const overlayUrl = params && params.overlayUrl;
    const baseUrl = params && params.baseMediaUrl;

    if (!dataUri) {
      return state;
    }

    if (dataUri in state) {
      log.warn(`result ${dataUri} already exists in state`);
    }
    return u(
      {
        [dataUri]: {
          uploading: true,
          mediaId: null,
        },
        ...(is.truthy(overlayUrl) && {
          [overlayUrl]: {
            uploading: true,
            mediaId: null,
          },
        }),
        ...(is.truthy(baseUrl) && {
          [baseUrl]: {
            uploading: true,
            mediaId: null,
          },
        }),
      },
      state
    );
  },

  done(state: any, action: any) {
    const { dataUri, params } = action;
    const overlayUrl = params && params.overlayUrl;
    const baseUrl = params && params.baseMediaUrl;

    if (!dataUri) {
      return state;
    }
    const mediaId = action.payload && action.payload.id;
    const overlayId = action.payload && action.payload.overlayTranscodeMediaId;
    const baseTranscodeMediaId = action.payload && action.payload.baseTranscodeMediaId;

    const { error } = action;
    return mediaId || error
      ? u(
          {
            [dataUri]: {
              uploading: false,
              mediaId,
              error: action.error,
            },
            ...(is.truthy(overlayId) && {
              [overlayUrl]: {
                uploading: false,
                mediaId: overlayId,
                error: action.error,
              },
            }),
            ...(is.truthy(baseTranscodeMediaId) && {
              [baseUrl]: {
                uploading: false,
                mediaId: baseTranscodeMediaId,
                error: action.error,
              },
            }),
          },
          state
        )
      : state;
  },
});

const uploadProgressSequence = createSequenceHandlingReducer(
  {},
  [mediaActions.UPLOAD_MEDIA, videoLibraryActions.UPLOAD_TO_ENDPOINT],
  {
    start(state: any, action: any) {
      if (action.mediaId) {
        return u(
          {
            activeMediaId: action.mediaId,
            [action.mediaId]: {
              progressPercent: 0,
            },
          },
          state
        );
      }

      return state;
    },

    progress(state: any, action: any) {
      if (action.dataUri && _.get(action, ['params', 'componentId'])) {
        return u(
          {
            [action.params.componentId]: {
              [action.dataUri]: {
                totalSizeBytes: action.meta.totalSizeBytes,
                progressBytes: action.meta.progressBytes,
              },
            },
          },
          state
        );
      }

      if (action.mediaId && _.get(action, ['meta', 'progressPercent'])) {
        return u(
          {
            [action.mediaId]: {
              progressPercent: action.meta.progressPercent,
            },
          },
          state
        );
      }

      return state;
    },

    done(state: any, action: any) {
      if (action.dataUri && _.get(action, ['params', 'componentId'])) {
        return u(
          {
            [action.params.componentId]: u.omit(action.dataUri),
          },
          state
        );
      }

      if (action.mediaId) {
        return u(
          {
            [action.mediaId]: {
              progressPercent: 100,
            },
          },
          state
        );
      }

      return state;
    },
  }
);

const resetActiveMediaId = (state = EMPTY_OBJECT, action: any) => {
  if (action.type === mediaActions.RESET_ACTIVE_MEDIA_ID) {
    return u({ activeMediaId: null }, state);
  }

  return state;
};

const uploadProgress = serializeReducers([uploadProgressSequence, resetActiveMediaId]);

// The data can be for various types of entities as long as their id is converted to componentId - i.e. publishers, snaps, editions
const uploadCountsByPurposeForSingleEntity = createSequenceCountingByIdReducer(
  (action: any) => _.get(action, ['params', 'purpose']),
  [mediaActions.UPLOAD_MEDIA_FINALIZE, videoLibraryActions.UPLOAD_VIDEO_LIBRARY]
);

const activeUploadsCountsByPurpose = (state = {}, action: any) => {
  switch (action.type) {
    case mediaActions.UPLOAD_MEDIA_FINALIZE:
    case videoLibraryActions.UPLOAD_VIDEO_LIBRARY: {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(action.params.componentId).is.null.or.is.string();
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const subState = state[action.params.componentId] || {};
      return u(
        {
          [action.params.componentId]: uploadCountsByPurposeForSingleEntity(subState, action),
        },
        state
      );
    }
    default:
      return state;
  }
};

const frameResults = createSequenceHandlingReducer({}, mediaActions.GRAB_FRAME, {
  start(state: any, action: any) {
    const { frameKey } = action;
    if (!frameKey) {
      return state;
    }
    return u(
      {
        [frameKey]: {
          creating: true,
          result: null,
        },
      },
      state
    );
  },

  done(state: any, action: any) {
    const { frameKey } = action;
    if (!frameKey) {
      return state;
    }
    const result = _.get(action, ['payload', 'result']);
    const { error } = action;

    return u(
      {
        [frameKey]: {
          creating: false,
          result,
          error,
        },
      },
      state
    );
  },
});

export default combineReducers({
  uploadProgress,
  activeUploadsCountsByPurpose,
  uploadResults,
  frameResults,
});
