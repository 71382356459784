import is from 'is_js';

import { assertArg } from 'utils/assertionUtils';

let defaultEndpoint: any;

const decorationEndpoints: any = [];

/*
 * Provides a means to register endpoints from which snap data will be loaded.
 *
 * At a minimum there must be at least one endpoint, which must be registered as the
 * default. Additional endpoints will be registered as 'decoration' endpoints, which
 * means that they are used by snapsActions.loadSnap() to populate the `decorations`
 * object on each snap.
 *
 * The system is designed such that additional decoration endpoints can be plugged in
 * if necessary in future, for example if the ads team created an 'Ad Snap' endpoint
 * which decorates Rich Snaps with additional domain-specific data.
 *
 * @see state/snaps/endpoints/snapEndpointConfig.js for example usage.
 */
export function registerSnapEndpoint({
  name,
  urlBuilder,
  urlBuilderMultiple,
  snapSchema,
  isDefault = false,
  supportsPartialUpdates = false,
}: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(name).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(urlBuilder).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(urlBuilderMultiple).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapSchema).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapSchema.getKey).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(isDefault).is.boolean();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(supportsPartialUpdates).is.boolean();

  if (getSnapEndpointByName(name)) {
    throw new Error(`A snap endpoint with name ${name} is already registered`);
  }

  if (getSnapEndpointBySchemaKey(snapSchema.getKey())) {
    throw new Error(`A snap schema with key ${snapSchema.getKey()} is already registered`);
  }

  if (isDefault && is.existy(defaultEndpoint)) {
    throw new Error('Cannot register more than one default endpoint');
  }

  const endpoint = {
    name,
    urlBuilder,
    urlBuilderMultiple,
    snapSchema,
    isDefault,
    supportsPartialUpdates,
  };

  if (isDefault) {
    defaultEndpoint = endpoint;
  } else {
    decorationEndpoints.push(endpoint);
  }
}

export function clear() {
  defaultEndpoint = null;
  decorationEndpoints.length = 0;
}

export function getAllSnapEndpoints() {
  return [getDefaultSnapEndpoint(), ...getDecorationSnapEndpoints()];
}

export function getAllSnapSchemaKeys() {
  return getAllSnapEndpoints().map(endpointInfo => endpointInfo.snapSchema.getKey());
}

export function getDefaultSnapEndpoint() {
  if (!defaultEndpoint) {
    throw new Error('Attempt to retrieve default snap endpoint before it has been defined');
  }

  return defaultEndpoint;
}

export function getDecorationSnapEndpoints() {
  return decorationEndpoints;
}

export function hasDecorationEndpoints() {
  return getDecorationSnapEndpoints().length > 0;
}

function getAllSnapEndpointsNoThrow() {
  return [defaultEndpoint, ...decorationEndpoints].filter(is.existy);
}

function getSnapEndpointByName(name: any) {
  return getAllSnapEndpointsNoThrow().filter(endpointInfo => endpointInfo.name === name)[0] || null;
}

function getSnapEndpointBySchemaKey(key: any) {
  return getAllSnapEndpointsNoThrow().filter(endpointInfo => endpointInfo.snapSchema.getKey() === key)[0] || null;
}
