import invariant from 'invariant';
import _ from 'lodash';

import { submitTilePreviewBuild } from 'state/asset/actions/assetActions';
import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as uploadRequestBuilder from 'state/media/actions/uploadRequestBuilder';
import { getActivePublisherPrimaryColor } from 'state/publishers/selectors/publishersSelectors';
import * as segmentsActions from 'state/segments/actions/segmentsActions';
import { getOriginalSegmentIdFromReduxId } from 'state/segments/schema/segmentEntityHelpers';
import * as segmentsSelectors from 'state/segments/selectors/segmentsSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import type { TileContainer, ContainerId } from 'state/tiles/schema/tilesEntityHelpers';
import { getTileId, getTileIdOrNull, describeTileContainer } from 'state/tiles/schema/tilesEntityHelpers';
import { addIdToEditionTile, addIdToSnapTile, addIdToSegmentTile } from 'state/tiles/schema/tilesIdUtils';
import * as tilesSelectors from 'state/tiles/selectors/tilesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import type { IdPropertyByTileContainerEnum } from 'config/constants';
import {
  EndpointName,
  IdPropertyByTileContainer,
  ErrorType,
  FileType,
  UploadPurpose,
  DEFAULT_TRIM_DURATION,
} from 'config/constants';
import { TileSpecifications, TileCropType } from 'config/tileConfig';
import { assertArg } from 'utils/assertionUtils';
import { buildComponentIdForSnapId } from 'utils/componentUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { createAssetUrl } from 'utils/media/assetUtils';
import * as tileCropUtils from 'utils/media/tileCropUtils';
import u from 'utils/safeUpdeep';

import { AssetType } from 'types/assets';
import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';
import { assertSnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { Dispatch, GetState, ThunkAction, ReduxAction } from 'types/redux';
import type { State } from 'types/rootState';
import type { SegmentReduxID } from 'types/segments';
import { Snap, SnapType } from 'types/snaps';
import type { Tile, TileID, TileShape } from 'types/tiles';

export const NOTIFY_TILE_ID_CHANGED = 'tile/NOTIFY_TILE_ID_CHANGED';
export const selectorByIdProperty: {
  [k in IdPropertyByTileContainerEnum]: (state: State) => (id: ContainerId) => Tile[] | undefined | null;
} = {
  [IdPropertyByTileContainer.EDITION]: editionsSelectors.getTilesByEditionId,
  [IdPropertyByTileContainer.SNAP]: snapsSelectors.getTilesByWholeSnapId,
  [IdPropertyByTileContainer.SEGMENT]: segmentsSelectors.getTilesBySegmentId,
};
export type TileTypes = {
  editionId: EditionID;
  snapId: SnapId;
  segmentId: SegmentReduxID;
};
// @ts-expect-error ts-migrate(2322) FIXME: Type '{ [x: string]: (({ editionId, }: { editionId... Remove this comment to see the full error message
export const savingActionByContainerIdProperty: {
  [k in IdPropertyByTileContainerEnum]: (
    b: Partial<TileTypes>,
    a: {
      tiles: Tile[];
    }
  ) => ThunkAction;
} = {
  [IdPropertyByTileContainer.EDITION]: editionsActions.setEditionPropertiesAndSave,
  [IdPropertyByTileContainer.SNAP]: setSnapTilePropertiesAndSave,
  [IdPropertyByTileContainer.SEGMENT]: segmentsActions.setSegmentPropertiesAndSave,
};
function getContainerFunctionsFromProviders(
  containerIdProperty: IdPropertyByTileContainerEnum = IdPropertyByTileContainer.EDITION
) {
  const getContainerTilesById = selectorByIdProperty[containerIdProperty];
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(getContainerTilesById).is.function();
  const saveContainer = savingActionByContainerIdProperty[containerIdProperty];
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(saveContainer).is.function();
  return { getContainerTilesById, saveContainer };
}
export function resetEditionTilesAndSave(editionId: EditionID, tileArray: Tile[] = []) {
  return resetTilesAndSave(
    { [IdPropertyByTileContainer.EDITION]: editionId },
    tileArray,
    IdPropertyByTileContainer.EDITION,
    true
  );
}
export function resetSnapTilesAndSave(snapId: SnapId, tileArray: Tile[] = []) {
  return resetTilesAndSave(
    { [IdPropertyByTileContainer.SNAP]: snapId },
    tileArray,
    IdPropertyByTileContainer.SNAP,
    true
  );
}
export function resetTilesAndSave(
  container: {
    [k in IdPropertyByTileContainerEnum]: ContainerId;
  },
  tileArray: Tile[],
  containerIdProperty: IdPropertyByTileContainerEnum = IdPropertyByTileContainer.EDITION,
  allowEmpty: boolean = false
) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(container[containerIdProperty]).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileArray).is.array();
  if (!allowEmpty) {
    // tileArray should not be empty
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(tileArray.length).is.above(0);
    // verify all objects in the array are tiles
    tileArray.forEach(tile => {
      const newTileId = getTileId(tile);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      assertArg(newTileId).is.string();
    });
  }
  const { saveContainer } = getContainerFunctionsFromProviders(containerIdProperty);
  return (dispatch: Dispatch, getState: GetState) => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    return dispatch(saveContainer(container, { tiles: tileArray }));
  };
}
function insertTileAndSave(tileContainer: TileContainer, tileProperties: TileShape) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileProperties).is.object();
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TileShape' is not assignable to ... Remove this comment to see the full error message
  const newTileId = getTileId(tileProperties);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(newTileId).is.string();
  const { container, containerIdProperty } = describeTileContainer(tileContainer);
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const { getContainerTilesById, saveContainer } = getContainerFunctionsFromProviders(containerIdProperty);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    const preUpdateContainer = { tiles: getContainerTilesById(getState())(container[containerIdProperty]) };
    if (preUpdateContainer) {
      const postUpdateContainer = u(
        {
          tiles: (tiles: any) => (Array.isArray(tiles) ? tiles.concat(tileProperties) : [tileProperties]),
        },
        preUpdateContainer
      );
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      return dispatch(saveContainer(container, { tiles: postUpdateContainer.tiles })).then(() =>
        dispatch(tryUpdateVideoTileBuild(tileProperties))
      );
    }
    return Promise.resolve();
  };
}
export function updateTileAndSave(tileContainer: TileContainer, tileId: TileID, tileProperties: TileShape) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileId).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileProperties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(getTileId(tileProperties)).is.string();
  const { container, containerIdProperty } = describeTileContainer(tileContainer);
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    invariant(container[containerIdProperty], 'containerId');
    const { getContainerTilesById, saveContainer } = getContainerFunctionsFromProviders(containerIdProperty);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    const preUpdateContainer = { tiles: getContainerTilesById(getState())(container[containerIdProperty]) };
    if (preUpdateContainer) {
      let tileList = preUpdateContainer.tiles || [];
      const existingTileIndex = tileList.findIndex(tile => getTileId(tile) === tileId);
      const previousTile = tileList[existingTileIndex];
      if (existingTileIndex > -1) {
        const operation = (tiles: any) => {
          const tilesCopy = [...tiles];
          tilesCopy.splice(existingTileIndex, 1, tileProperties);
          return tilesCopy;
        };
        const postUpdateContainer = u({ tiles: operation }, preUpdateContainer);
        tileList = postUpdateContainer.tiles;
      }
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      return dispatch(saveContainer(container, { tiles: tileList })).then(() =>
        dispatch(tryUpdateVideoTileBuild(tileProperties, previousTile))
      );
    }
    return Promise.resolve();
  };
}
const DEFAULT_VIDEO_TILE_START_MILLIS = 0;
const TRIM_START_PATH = ['videoTrimPositions', 'START_MS'];
const TRIM_DURATION_PATH = ['videoTrimPositions', 'DURATION_MS'];
export function tryUpdateVideoTileBuild(newTileProperties: TileShape, oldTile?: Tile) {
  return (dispatch: Dispatch): Promise<unknown> => {
    const newVideoAssetId = _.get(newTileProperties, 'videoAssetId', null);
    const oldVideoAssetId = _.get(oldTile, 'videoAssetId', null);
    const newTrimStart = _.get(newTileProperties, TRIM_START_PATH, null);
    const newTrimDuration = _.get(newTileProperties, TRIM_DURATION_PATH, null);
    const oldTrimStart = _.get(oldTile, TRIM_START_PATH, null);
    const oldTrimDuration = _.get(oldTile, TRIM_DURATION_PATH, null);
    const videoAssetId = newVideoAssetId || oldVideoAssetId;
    const hasVideoAsset = Boolean(videoAssetId);
    const isNewVideoAsset = Boolean(newVideoAssetId) && newVideoAssetId !== oldVideoAssetId;
    const hasTrimChanged =
      (newTrimStart !== null || newTrimDuration !== null) &&
      (newTrimStart !== oldTrimStart || newTrimDuration !== oldTrimDuration);
    if (hasVideoAsset && (isNewVideoAsset || hasTrimChanged)) {
      const trimStart = newTrimStart || oldTrimStart || DEFAULT_VIDEO_TILE_START_MILLIS;
      const trimDuration = newTrimDuration || oldTrimDuration || DEFAULT_TRIM_DURATION;
      return dispatch(submitTilePreviewBuild(videoAssetId, trimStart, trimDuration));
    }
    return Promise.resolve();
  };
}
export function deleteTileAndSave(tileContainer: TileContainer, tileId: TileID) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileId).is.string();
  const { container, containerIdProperty } = describeTileContainer(tileContainer);
  const { getContainerTilesById, saveContainer } = getContainerFunctionsFromProviders(containerIdProperty);
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    const preUpdateContainer = { tiles: getContainerTilesById(getState())(container[containerIdProperty]) };
    if (preUpdateContainer) {
      const postUpdateContainer = u(
        {
          tiles: (tiles: any) => {
            const tilesCopy = [].concat(tiles);
            const tileIndexToDelete = tiles.findIndex((tile: any) => getTileId(tile) === tileId);
            if (tileIndexToDelete > -1) {
              tilesCopy.splice(tileIndexToDelete, 1);
            }
            return tilesCopy;
          },
        },
        preUpdateContainer
      );
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      return dispatch(saveContainer(container, { tiles: postUpdateContainer.tiles }));
    }
    return Promise.resolve();
  };
}
const addTileId = ({ editionId, snapId, segmentId }: TileContainer, tile: Tile): Tile => {
  if (editionId && snapId) {
    throw new Error('Only one of editionId and snapId should be passed to calculate tile id');
  }
  if (segmentId) {
    // real segment id must be extracted from the redux id, as that is the basis for the tile id
    const originalSegmentId = getOriginalSegmentIdFromReduxId(segmentId);
    if (!originalSegmentId) {
      throw new Error('Segment id is invalid');
    }
    return addIdToSegmentTile(originalSegmentId, tile);
  }
  if (snapId) {
    return addIdToSnapTile(snapId, tile);
  }
  if (editionId) {
    return addIdToEditionTile(editionId, tile);
  }
  throw new Error('No parent entity to get new tile id from');
};
export function setTileMediaPropertiesAndSave(
  tileContainer: TileContainer,
  tileId: TileID | undefined | null,
  properties: TileShape,
  errorType: string
): ThunkAction {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(tileId).is.string.or.is.undefined.or.is.null();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(errorType).is.string();
  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    // Calculating the new tile id
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TileShape' is not assignable to ... Remove this comment to see the full error message
    const propertiesWithId = addTileId(tileContainer, properties);
    // activate the tileId in the properties to update/save
    let activateTileId = getTileIdOrNull(propertiesWithId);
    // insert tile OR ..
    let action = insertTileAndSave(tileContainer, propertiesWithId);
    // check if we are replacing a previous tile
    if (tileId) {
      // if we have a tileId from the properties we are updating, use that (we are replacing the tile),
      //  otherwise use the referenced tileId
      activateTileId = activateTileId || tileId;
      // replace action
      action = updateTileAndSave(tileContainer, tileId, propertiesWithId);
    }
    // We first notify the tile changed so active component can be set to the new tile immediately
    // or there will be no active component while the entity is saved
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ oldTileId: string | null; newT... Remove this comment to see the full error message
    dispatch(notifyTileIdChanged({ ...tileContainer, oldTileId: tileId || null, newTileId: activateTileId }));
    // save or update
    return dispatch(action).catch(err => {
      // If error, we rollback the tile id change notification
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ oldTileId: string | null | und... Remove this comment to see the full error message
      dispatch(notifyTileIdChanged({ ...tileContainer, oldTileId: activateTileId, newTileId: tileId || null }));
      throw err;
    });
  };
}
function notifyTileIdChanged({
  editionId,
  snapId,
  segmentId,
  oldTileId,
  newTileId,
}: {
  editionId: EditionID | undefined | null;
  snapId: SnapId | undefined | null;
  segmentId: SegmentReduxID | undefined | null;
  oldTileId: TileID | undefined | null;
  newTileId: TileID | undefined | null;
}): ReduxAction {
  return {
    type: NOTIFY_TILE_ID_CHANGED,
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ type: string; params: { editionId: number ... Remove this comment to see the full error message
    params: {
      editionId,
      snapId,
      segmentId,
      oldTileId,
      newTileId,
    },
  };
}
// Proxy for setSnapPropertiesAndSave that prepares the payload
export function setSnapTilePropertiesAndSave(
  {
    snapId,
  }: {
    snapId: SnapId;
  },
  properties: {
    tiles: TileShape[];
  }
) {
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  return (dispatch: Dispatch) => {
    const discoverSnapProperties = {
      decorations: {
        [EndpointName.DISCOVER]: properties,
      },
    };
    return dispatch(snapsActions.setSnapPropertiesAndSave({ snapId }, discoverSnapProperties));
  };
}
const TILE_VIDEO_DIMENSIONS = {
  width: 540,
  height: 960,
};
export async function createVideoThumbnailAsset(
  src: string,
  currentTime: number,
  editionId: EditionID | undefined | null,
  snapId: SnapId | undefined | null,
  segmentId: SegmentReduxID | undefined | null,
  dispatch: Dispatch
) {
  const imageBlob = await mediaActions.createThumbnailFromVideo({
    src,
    videoWidth: TILE_VIDEO_DIMENSIONS.width,
    videoHeight: TILE_VIDEO_DIMENSIONS.height,
    trimWidth: TileSpecifications[TileCropType.VERTICAL].width,
    trimHeight: TileSpecifications[TileCropType.VERTICAL].height,
    currentTime,
  });
  invariant(snapId, 'snapId');
  return dispatch(
    mediaActions.uploadMediaAndFinalize({
      media: imageBlob,
      fileType: FileType.IMAGE,
      params: {
        purpose: UploadPurpose.TILE_IMAGE,
        componentId: buildComponentIdForSnapId(snapId),
        editionId,
        snapId,
        segmentId,
      },
    })
  );
}
type TileAsset = {
  assetId: AssetID;
  file?: File;
  src?: string;
};
export const handleTileUploaded = ({
  tile,
  asset,
  snapId,
  segmentId,
  isDuplicateOperation,
  newTileDefaultProperties,
}: {
  tile: Tile;
  asset: TileAsset;
  editionId: EditionID | undefined | null;
  snapId: SnapId | undefined | null;
  segmentId?: SegmentReduxID | null;
  isDuplicateOperation?: boolean;
  newTileDefaultProperties?: object;
}) => {
  return async (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const state = getState();
    const publisherId = userSelectors.getActivePublisherId(state);
    const tileContainer = segmentId ? { segmentId } : { snapId };
    // when duplicating a tile we explicitly set a null tile id to stop the original tile being replaced
    const tileId = isDuplicateOperation ? null : getTileIdOrNull(tile);
    let tileProperties: TileShape;

    const defaultProperties = newTileDefaultProperties || {};
    tileProperties = {
      type: AssetType.IMAGE,
      baseImageAssetId: asset.assetId,
      ...defaultProperties,
    } as Partial<Tile>;

    const tileLogos = tilesSelectors.getTileLogosByPublisherId(getState())(publisherId);
    if (tileLogos.length > 0) {
      const primaryColor = getActivePublisherPrimaryColor(getState());
      tileProperties = tileCropUtils.ensureTileLogoIsSet(tileProperties, tileLogos[0], primaryColor);
    } else {
      tileProperties.isLogoEnabled = false;
    }
    if (tile) {
      if (tile.headline) {
        tileProperties.headline = tile.headline;
      }
      if (tile.tileFlavor) {
        tileProperties.tileFlavor = tile.tileFlavor;
      }
    }
    // Call using exports so we can mock this function
    return dispatch(
      exports.setTileMediaPropertiesAndSave(tileContainer, tileId, tileProperties, ErrorType.SAVE_TILE_MEDIA)
    );
  };
};
export const createTileFromExistingSource = ({
  tile,
  editionId,
  snap,
  snapId,
  segmentId,
  isDuplicate,
  newTileDefaultProperties,
}: {
  tile: Tile | undefined | null;
  editionId: EditionID | undefined | null;
  snap: Snap;
  snapId?: SnapId | null;
  segmentId?: SegmentReduxID | null;
  isDuplicate?: boolean | null;
  newTileDefaultProperties?: object;
}) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(snap.type).is.inArray([SnapType.VIDEO, SnapType.IMAGE, SnapType.SINGLE_ASSET]);
    const type = AssetType.IMAGE;
    let assetId = _.get(snap, 'imageAssetId');
    if (isDuplicate) {
      assetId = _.get(tile, 'baseImageAssetId');
    } else if (snap.type === SnapType.VIDEO) {
      // if the topsnap is a video, generate an image asset from the first fragment
      const assetResult = await exports.createVideoThumbnailAsset(
        createAssetUrl((snap as any).videoAssetId),
        0,
        editionId,
        snapId,
        segmentId,
        dispatch
      );
      assetId = assetResult.assetId;
    }
    // We must duplicate the asset or the ids might collide with other tiles
    const autoBuild = uploadRequestBuilder.getAutoBuildParam(UploadPurpose.TILE_IMAGE, type);
    let newAsset;
    try {
      newAsset = await dispatch(mediaActions.duplicateAsset({ assetId, autoBuild }));
    } catch (error) {
      return apiErrorHandler(dispatch, ErrorContexts.IMPORT_TILE_FROM_TOPSNAP)(error);
    }
    const newAssetId = _.get(newAsset, ['payload', 'id']);
    const src: string = createAssetUrl(newAssetId);
    const asset = {
      assetId: newAssetId,
      type,
      src,
    };
    return dispatch(
      exports.handleTileUploaded({
        tile,
        asset,
        editionId,
        snapId,
        segmentId,
        isDuplicateOperation: isDuplicate,
        newTileDefaultProperties,
      })
    );
  };
};
