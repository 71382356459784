import _ from 'lodash';

import { fetchMultipleEditionBuildStatuses } from 'state/buildStatus/actions/buildStatusActions';
import * as editionsActions from 'state/editions/actions/editionsActions';
import { PaginationState } from 'state/homepage/homepageState';
import * as homepageSelectors from 'state/homepage/selectors/homepageSelectors';
import * as publishersActions from 'state/publishers/actions/publishersActions';

import { EMPTY_OBJECT } from 'config/constants';
import { GetState } from 'src/types/redux';
import { assertArg } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';

import { ServerSideStoryState } from 'types/editions';

// Stores information that helps with pagination retrieval - batchSize and cursor
export const SET_PAGINATION = 'homepage/SET_PAGINATION';

// Called when search criteria change. Used for UI Loading indicator
export const LOADING_NEW_SEARCH_CRITERIA = 'homepage/LOADING_NEW_SEARCH_CRITERIA';

// Called when search criteria has stayed the same but we need more pages. Used for row loading indicator
export const LOADING_MORE_PAGES = 'homepage/LOADING_MORE_PAGES';

// Default size of a batch of data
export const DEFAULT_BATCH_SIZE = 15;

// Init state of the pagination.
export const EMPTY_PAGINATION_STATE: PaginationState = {
  publisherId: '',
  cursor: '',
  batchSize: DEFAULT_BATCH_SIZE,
};

const setPagination = (pagination = EMPTY_OBJECT) => {
  return {
    type: SET_PAGINATION,
    payload: pagination,
  };
};

export const resetCursor = (emptyState: PaginationState) => (dispatch: any) => {
  dispatch(setPagination(emptyState));
};

export const getStoriesForPublisher = ({ publisherId, searchCriteria, pagination, keepExistingStories }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(keepExistingStories).is.boolean();
  return publishersActions.getStoriesForPublisher({
    type: publishersActions.GET_HOMEPAGE_STORIES,
    publisherId,
    searchCriteria,
    pagination,
    keepExistingStories,
  });
};

export const setLoadingNewSearchCriteria = () => ({ type: LOADING_NEW_SEARCH_CRITERIA });
export const setLoadingMorePages = () => ({ type: LOADING_MORE_PAGES });

// The UI may call this method multiple times. We need to throttle the requests to only when they are needed
export const getDisplayStories = (
  { publisherId, fetchOptions = {}, batchSize = 0, keepExistingStories = false, forceReload = false }: any,
  fetchedSoFar = 0
) => (dispatch: any, getState: GetState) => {
  // Asking for all available states means we do not want to filter by them => remove
  const searchCriteria = fetchOptions;

  const oldSearchCriteria = homepageSelectors.getSearchCriteria(getState());
  const searchCriteriaChanged = !_.isEqual(searchCriteria, oldSearchCriteria);

  // If search did not change and we don't have more to load, return
  if (!forceReload && !searchCriteriaChanged && !homepageSelectors.hasMorePagesToLoad(getState())) {
    return Promise.resolve();
  }

  // If search did not change and we are loading more content, return
  if (
    !forceReload &&
    !searchCriteriaChanged &&
    (homepageSelectors.getLoadingMorePages(getState()) || homepageSelectors.getLoadingNewSearchCriteria(getState()))
  ) {
    return Promise.resolve();
  }

  // If search criteria have changed, reset the cursor. Inform the UI
  // Cursor won't work with new search criteria
  const shouldResetCursor = forceReload || searchCriteriaChanged;
  const emptyPaginationState = {
    ...EMPTY_PAGINATION_STATE,
    publisherId,
    batchSize: homepageSelectors.getPagination(getState()).batchSize,
  };
  if (shouldResetCursor) {
    dispatch(setLoadingNewSearchCriteria());
    dispatch(resetCursor(emptyPaginationState));
  }

  // If we are requesting batches, inform UI to display loading row
  if (batchSize > 0) {
    dispatch(setLoadingMorePages());
  }

  const pagination = {
    ...(shouldResetCursor ? emptyPaginationState : homepageSelectors.getPagination(getState())),
    batchSize,
  };

  // If search criteria is empty we should ensure the DELETED state is absent so we must list all the other states
  if (!searchCriteria.state || searchCriteria.state.length === 0) {
    searchCriteria.state = _.without(Object.keys(ServerSideStoryState), ServerSideStoryState.DELETED);
  }

  return dispatch(
    exports.getStoriesForPublisher({
      publisherId,
      searchCriteria,
      keepExistingStories,
      pagination,
    })
  )
    .then((resultAction: any) => {
      // We only want to get the tiles and build status for the latest loaded content
      const storyIds = _.get(resultAction, ['payload', 'result']);
      const totalFetched = fetchedSoFar + storyIds.length;
      const searchChanged = !_.isEqual(searchCriteria, homepageSelectors.getSearchCriteria(getState()));

      if (!searchChanged && totalFetched < batchSize && homepageSelectors.hasMorePagesToLoad(getState())) {
        _.defer(() =>
          dispatch(
            getDisplayStories(
              {
                publisherId,
                fetchOptions,
                batchSize,
                keepExistingStories: true,
              },
              totalFetched
            )
          )
        );
      }

      dispatch(editionsActions.fetchFirstSnapOfEditions(storyIds));

      // Required to display the edition state on Schedule Button
      dispatch(fetchMultipleEditionBuildStatuses({ editionIds: storyIds }));
    })
    .catch(apiErrorHandler(dispatch, ErrorContexts.GET_HOMEPAGE_DATA));
};
