import {
  // discover-cms/no-snapnet
  Background,
  Page,
  Grid,
  // @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
} from '@snapchat/snapnet';
import classNames from 'classnames';
import { get } from 'lodash';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RouteComponentProps } from 'react-router';
import { pure } from 'recompose';

import { setPublisherDetails } from 'state/publishers/actions/publishersActions';
import { getActivePublisherDetails, getEditorialStatus } from 'state/publishers/selectors/publishersSelectors';
import { goToSettings } from 'state/router/actions/routerActions';
import * as snapAdminActions from 'state/snapAdmin/actions/snapAdminActions';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';
import { getActiveCreatorBusinessProfileId } from 'state/user/selectors/userSelectors';

import { EditorialStatus } from 'config/constants';
import withRequestReviewMutation, { WithRequestReviewMutationProps } from 'gql/hocs/withRequestReviewMutation';
import { RequestReviewResponse, RequestReviewResponseData } from 'gql/types/requestReviewTypes';
import { cross } from 'icons/SDS/allIcons';
import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { withRouter } from 'utils/routerUtils';

import AlertBox, { AlertType } from 'views/common/components/AlertBox/AlertBox';
import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';
import SDSDialog from 'views/common/components/SDSDialog/SDSDialog';
import SnapNavBar from 'views/common/containers/SnapNavBar/SnapNavBar';
import SnapSideNav from 'views/common/containers/SnapSideNav/SnapSideNav';

import style from './SnapNavLayout.scss';

import { Claim } from 'types/permissions';
import { ExtractDispatchProps } from 'types/redux';

const PureBackground = pure(Background);

function SideNavGrid({ location, match, mergedPageWithPaddingClassName, children, pageRef }: any) {
  return (
    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element; location: any; match: a... Remove this comment to see the full error message
    <SnapSideNav location={location} match={match}>
      <Page className={mergedPageWithPaddingClassName} ref={pageRef}>
        <Grid>{children}</Grid>
      </Page>
    </SnapSideNav>
  );
}

const PureSideNavGrid = pure(SideNavGrid);

export const mapStateToProps = (state: State) => {
  const activePublisherId = userSelectors.getActivePublisherId(state);

  return {
    cmsNotificationSetting: snapAdminSelectors.getCmsNotificationSetting(state),
    activePublisherId,
    isActivePublisherDetailsLoading: getActivePublisherDetails(state) === null,
    activeBusinessProfileId: getActiveCreatorBusinessProfileId(state),
    isNotificationBannerEditor: userSelectors.hasClaimForActivePublisher(state, Claim.NOTIFICATION_BANNER_EDITOR),
    isRequestReviewEnabled: userSelectors.hasClaimForActivePublisher(state, Claim.STORY_PUBLISHER),
    canRequestReview: getEditorialStatus(state) === EditorialStatus.TO_BE_REVIEWED,
  };
};

const mapDispatchToProps = {
  getNotificationSettingData: snapAdminActions.getNotificationSettingData,
  initNotificationUpdateLoop: snapAdminActions.initNotificationUpdateLoop,
  goToSettings,
  setPublisherDetails,
};

type OwnState = {
  notificationBarHiddenForThisPageRefresh: boolean;
  isReviewRequested: boolean;
  isRequestReviewModalVisible: boolean;
};

type OwnProps = {
  children?: any;
  pageClassName: string;
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & WithRequestReviewMutationProps & RouteComponentProps;

export class SnapNavLayout extends React.Component<Props, OwnState> {
  static childContextTypes = {
    getScrollContainer: PropTypes.func,
  };

  state = {
    notificationBarHiddenForThisPageRefresh: false,
    isReviewRequested: false,
    isRequestReviewModalVisible: false,
  };

  element: HTMLElement | undefined | null;

  getChildContext() {
    // This is being exposed to the children so the overlays can be attached to a container that scrolls. This allows
    // the overlays to scroll with the container if they want instead of being appended to body and be in a fixed position
    return {
      getScrollContainer: () => this.element,
    };
  }

  componentDidMount() {
    this.props.getNotificationSettingData();
    this.props.initNotificationUpdateLoop();
  }

  pageRef = (element?: HTMLElement | null) => {
    this.element = element;
  };

  hideNotificationBar = () => {
    this.setState({ notificationBarHiddenForThisPageRefresh: true });
  };

  goToSettings = () => {
    return this.props.goToSettings(this.props.activePublisherId);
  };

  requestReview = async () => {
    this.setState({ isReviewRequested: true, isRequestReviewModalVisible: false });
    this.props
      .requestReview({ variables: { businessProfileId: this.props.activeBusinessProfileId } })
      .then((response: RequestReviewResponse) => {
        const data: RequestReviewResponseData = response.data.requestEditorialReview;
        return this.props.setPublisherDetails({
          id: this.props.activePublisherId,
          editorialApproval: data.editorialApproval,
        });
      })
      .finally(() => this.setState({ isReviewRequested: false }));
  };

  showModal = () => {
    this.setState({ isRequestReviewModalVisible: true });
  };

  handleModalCancel = () => {
    this.setState({ isRequestReviewModalVisible: false });
  };

  renderNotificationBar = () => {
    const { cmsNotificationSetting, isNotificationBannerEditor, isRequestReviewEnabled, canRequestReview } = this.props;
    const showNotification = get(cmsNotificationSetting, 'showNotificationMessage', 'false') === 'true';

    if (showNotification && !this.state.notificationBarHiddenForThisPageRefresh) {
      const notificationMessage = get(cmsNotificationSetting, 'notificationMessage', '');
      const notificationMessageTimestamp = moment
        .utc(parseInt(get(cmsNotificationSetting, 'notificationMessageTimestamp', 0), 10))
        .local()
        .format('MMM D');
      const barClassNames = classNames(style.topNotification, style.warning);
      const shouldShowRequestReviewBar = isRequestReviewEnabled && canRequestReview;
      const showNotificationCloseButton = isNotificationBannerEditor || shouldShowRequestReviewBar;

      return (
        <div className={barClassNames} data-test="common.snapNavLayout.notificationBar">
          {showNotificationCloseButton && (
            <SDSButton
              type={ButtonType.SECONDARY}
              shape={ButtonShape.CIRCLE}
              inlineIcon={cross}
              onClick={this.hideNotificationBar}
              data-test="common.snapNavLayout.notificationBar.close.button"
            />
          )}
          <div className={style.notificationText}>{`${notificationMessageTimestamp}: ${notificationMessage}`}</div>
        </div>
      );
    }

    if (
      this.props.isRequestReviewEnabled &&
      !this.props.isActivePublisherDetailsLoading &&
      this.props.canRequestReview
    ) {
      return (
        <div
          className={classNames(style.topNotification, style.info)}
          data-test="common.snapNavLayout.requestReviewNotification"
        >
          <div className={style.notificationText}>
            <div className={style.notificationDescription}>
              <FormattedMessage
                id="notification-bar-request-review-text"
                description="Message displayed for profiles which can request editorial review"
                defaultMessage="When you have uploaded your content please"
              />
            </div>
            <SDSButton
              data-test="common.snapNavLayout.requestReviewNotification.button"
              type={ButtonType.SECONDARY}
              disabled={this.state.isReviewRequested}
              onClick={this.showModal}
            >
              <FormattedMessage
                id="notification-bar-request-review"
                description="Button to request profile review"
                defaultMessage="Request Review"
              />
            </SDSButton>
            <SDSDialog
              data-test="common.snapNavLayout.requestReviewModal"
              title={
                <FormattedMessage
                  id="request-review-modal-title"
                  defaultMessage="Request Review"
                  description="Text for request review modal title"
                />
              }
              visible={this.state.isRequestReviewModalVisible}
              onOk={this.requestReview}
              okText={
                <FormattedMessage
                  id="request-review-modal-ok-text"
                  defaultMessage="Yes, request review"
                  description="Text for request review modal ok button"
                />
              }
              onCancel={this.handleModalCancel}
            >
              <AlertBox type={AlertType.WARNING}>
                <FormattedMessage
                  id="request-review-modal-pilot-reminder"
                  defaultMessage="We can only review your profile when a pilot episode has been uploaded. Have you uploaded a pilot episode?"
                  description="Reminding the user they must upload a pilot episode before they request a review."
                />
              </AlertBox>
            </SDSDialog>
          </div>
        </div>
      );
    }

    return null;
  };

  render() {
    const { children, cmsNotificationSetting, location, match, activePublisherId } = this.props;

    const showNotificationMessage = get(cmsNotificationSetting, 'showNotificationMessage', 'false') === 'true';
    const shouldShowWarningBar = showNotificationMessage && !this.state.notificationBarHiddenForThisPageRefresh;
    const shouldShowRequestReviewBar = this.props.isRequestReviewEnabled && this.props.canRequestReview;
    const shouldShowNotificationBar = shouldShowWarningBar || shouldShowRequestReviewBar;

    const mergedPageWithPaddingClassName = classNames({
      [style.page]: true,
      [style.pagePadding]: shouldShowNotificationBar,
    });

    const mergedSnapNavLayoutClassName = classNames({
      [style.snapNavLayout]: true,
      [style.sideTopPadding]: shouldShowNotificationBar,
    });

    return (
      <div className={classNames(mergedSnapNavLayoutClassName)}>
        {this.renderNotificationBar()}
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ className: string; }' is not assignable to... Remove this comment to see the full error message */}
        {activePublisherId && <PureBackground className={style.background} />}
        <SnapNavBar topPadding={shouldShowNotificationBar} location={location} />
        <PureSideNavGrid
          location={location}
          match={match}
          mergedPageWithPaddingClassName={mergedPageWithPaddingClassName}
          pageRef={this.pageRef}
        >
          {children}
        </PureSideNavGrid>
      </div>
    );
  }
}

const ConnectedSnapNavLayout = intlConnect(mapStateToProps, mapDispatchToProps)(SnapNavLayout);

export default withRouter(withRequestReviewMutation(ConnectedSnapNavLayout));
