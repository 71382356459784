import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

import { BuildSetting } from 'types/build';

export type Snapcode = {
  id: number;
  name: string;
  shareableHeadline: string;
  fullDeepLinkUrl: string;
  downloadLinkUrl: string;
  cb: string;
};

// Taken from:
// https://github.sc-corp.net/Snapchat/content-platform/blob/main/discover-service
//   /share-model/src/main/java/snapchat/discover/shared/DeepLinkStatus.java
const DeepLinkStatus = enumObject({
  SNAP: 'SNAP',
  EDITION: 'EDITION',
  PUBLISHER: 'PUBLISHER',
});

type DeepLinkStatusEnum = Enum<typeof DeepLinkStatus>;

export type SnapcodeEntity = {
  id: number;
  deepLinkStatus: DeepLinkStatusEnum;
  name: string;
  shareableHeadline: string;
  deepLinkUrl: string;
  cb?: string;
};

export const Quality = enumObject({
  HIGH: 'HIGH',
  LOW: 'LOW',
});

export type QualityEnum = Enum<typeof Quality>;

export const BuildSettingByQuality = {
  [Quality.HIGH]: BuildSetting.med9to16,
  [Quality.LOW]: BuildSetting.low9to16,
};
