// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'deno... Remove this comment to see the full error message
import { denormalize } from 'denormalizr';

import { createKeySelector, createDynamicKeySelector, reselectById } from 'state/common/selectorFactories';
import { segmentSchema } from 'state/segments/schema/segmentsSchema';
import * as tilesSelectors from 'state/tiles/selectors/tilesSelectors';

import { EMPTY_OBJECT } from 'config/constants';

import type { Segment, SegmentID } from 'types/segments';
import type { Tile } from 'types/tiles';

export const getSegmentData = (state: any) => state.segments || EMPTY_OBJECT;
export const getSegmentMap = createKeySelector(getSegmentData, 'byId', EMPTY_OBJECT);
export const getSegmentById = createDynamicKeySelector<Segment | undefined | null, SegmentID>(getSegmentMap, null);

export const getTilesBySegmentId = reselectById<Tile[] | undefined | null, SegmentID>(
  null,
  (state: any, segmentId: any) => getSegmentById(state)(segmentId),
  tilesSelectors.getTileMap,
  (segment: any, tileMap: any) => {
    if (!segment) {
      return null;
    }

    const denormalizedSegment = denormalize(
      segment,
      {
        tile: tileMap,
      },
      segmentSchema
    );
    return denormalizedSegment.tiles || null;
  }
);
