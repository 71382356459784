import AntdCarousel, { CarouselProps } from 'antd/lib/carousel';
import React, { RefAttributes } from 'react';

import style from './SDSCarousel.scss';

export type SDSCarouselRef = AntdCarousel;

const settings: CarouselProps = {
  customPaging(index) {
    return <div className={style.dot} data-test={`carousel-${index}`} />;
  },
  dots: true,
  dotsClass: 'slick-dots slick-thumb',
};

const SDSCarousel: React.FC<CarouselProps & RefAttributes<SDSCarouselRef>> = React.forwardRef<
  SDSCarouselRef,
  CarouselProps
>((props, ref) => {
  return (
    <AntdCarousel ref={ref} {...props} {...settings}>
      {props.children}
    </AntdCarousel>
  );
});

export default SDSCarousel;
