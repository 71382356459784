import is from 'is_js';
import { camelCase, snakeCase, get } from 'lodash';

import { EMPTY_OBJECT } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';

export const Recursion = {
  RECURSIVE: 'RECURSIVE',
  NON_RECURSIVE: 'NON_RECURSIVE',
};

export const Undoability = {
  UNDOABLE: 'RECURSIVE',
  NON_UNDOABLE: 'NON_RECURSIVE',
};

export function camelCaseKeys(obj: any, options: any = EMPTY_OBJECT) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(obj).is.object();
  return transformKeys(obj, camelCase, {
    recursion: Recursion.NON_RECURSIVE,
    undoability: Undoability.NON_UNDOABLE,
    ...options,
  });
}

export function snakeCaseKeys(obj: any, options: any = EMPTY_OBJECT) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(obj).is.object();
  return transformKeys(obj, snakeCase, {
    recursion: Recursion.NON_RECURSIVE,
    undoability: Undoability.NON_UNDOABLE,
    ...options,
  });
}

export function recursiveCamelCaseKeys(inputObject: any, options: any = EMPTY_OBJECT) {
  return camelCaseKeys(inputObject || EMPTY_OBJECT, { ...options, recursion: Recursion.RECURSIVE });
}

export function recursiveFreeze(obj: any) {
  if (is.object(obj)) {
    Object.freeze(obj);
    Object.values(obj).forEach(recursiveFreeze);
  }
  return obj;
}

export function safeShallowMerge(objOne: any, objTwo: any) {
  const safeToMerge = Object.keys(objOne).every(key => !(key in objTwo));
  if (!safeToMerge) {
    throw new Error('key collision when merging');
  }
  return {
    ...objOne,
    ...objTwo,
  };
}

function transformKeys(obj: any, transformation: any, options: any) {
  const recursive = options.recursion === Recursion.RECURSIVE;
  const undoable = options.undoability === Undoability.UNDOABLE;
  const overrides = options.overrides || {};
  const objCopy: any = Array.isArray(obj) ? [] : {};

  if (undoable) {
    objCopy.__originalKeys__ = {};
  }

  Object.keys(obj).forEach(key => {
    if (key !== '__originalKeys__') {
      let newKey = transformation(key, obj, options);
      if (key in overrides) {
        newKey = overrides[key];
      }
      if (newKey in objCopy) {
        throw new Error(`Object has two keys that map to the same key: ${newKey}.`);
      }
      let value = obj[key];
      if (recursive && is.object(value)) {
        value = transformKeys(value, transformation, options);
      }
      objCopy[newKey] = value;

      if (undoable) {
        objCopy.__originalKeys__[newKey] = key;
      }
    }
  });

  return objCopy;
}

export function undoKeyTransformation(obj: any, options: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(obj).is.object();
  return transformKeys(obj, revertToOriginalKey, {
    fallbackTransformation: (key: any) => key,
    removeOriginalKeysMap: true,
    recursion: Recursion.NON_RECURSIVE,
    ...options,
  });
}

function revertToOriginalKey(key: any, obj: any, options: any) {
  const originalKey = obj.__originalKeys__ && obj.__originalKeys__[key];

  if (originalKey) {
    return originalKey;
  }

  // If no __originalKey__ mapping was found, apply whatever the fallback
  // transformation is. This allows keys that were not part of the original
  // object to be transformed to a standard casing.
  return options.fallbackTransformation(key);
}

// https://github.com/facebook/flow/issues/4771#issuecomment-326571246
// Disabling eslint to allow for the weak "Object" type that bypasses type-checking
export function getValues<Obj extends any>(object: Obj): Array<Obj[keyof Obj]> {
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  return Object.values(object);
}

/**
 * Equivalent of calling _.get(obj, path, defaultValue) || defaultValue;
 *  only better
 */
export function getExisty(
  obj: any | undefined | null | Array<any> | undefined | null,
  path: string | Array<string>,
  defaultValue: any
): any {
  const value: any = get(obj, path, defaultValue);
  return is.existy(value) ? value : defaultValue;
}

export type RemoveField<T, Drop> = T extends object ? { [K in Exclude<keyof T, Drop>]: RemoveField<T[K], Drop> } : T;

// Given an object with nested objects, we can filter out a key/value pair recursively.
// Our RemoveField type, creates a type that is the same as the input object
// but without the removed key/value pair.

export function removeFieldRecursively(input: any, fieldToBeRemoved: any): RemoveField<typeof input, string> {
  // If Array, we can map through and apply function to each element.
  if (Array.isArray(input)) {
    return input.map(x => removeFieldRecursively(x, fieldToBeRemoved));
  }

  // If value is object but not an array, we look at the keys
  // filter out '__typename' key, and start a reducer.
  // reducer calls base function on each value
  // which allows us to recursively tread through the object's branches
  // before reconstructing the object sans unwanted key-pair.
  if (typeof input === 'object' && input != null) {
    return Object.keys(input)
      .filter(key => key !== fieldToBeRemoved)
      .reduce((acc: Record<string, any>, key) => {
        acc[key] = removeFieldRecursively(input[key], fieldToBeRemoved);
        return acc;
      }, {});
  }
  // if value is a 'leaf value', we just return.
  return input;
}
