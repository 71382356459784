import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SpinnerIcon } from 'views/common/components/SpinnerIcon/SpinnerIcon';
import { updateIfPropsAndStateChanged } from 'views/propTypes/utils';

import style from './Spinner.scss';

export const SpinnerLabels = {
  LOADING: (
    <FormattedMessage
      id="spinner-loading-text"
      description="Text indicating that something is loading"
      defaultMessage="Loading..."
    />
  ),
  SAVING: (
    <FormattedMessage
      id="spinner-saving-text"
      description="Text indicating that changes are being saved"
      defaultMessage="Saving..."
    />
  ),
  UPLOADING: (
    <FormattedMessage
      id="spinner-uploading-text"
      description="Text indicating something is being uploaded"
      defaultMessage="Uploading..."
    />
  ),
  CROPPING: (
    <FormattedMessage
      id="spinner-cropping-text"
      description="Text indicating image is being cropped"
      defaultMessage="CROPPING..."
    />
  ),
  PROCESSING: (
    <FormattedMessage
      id="spinner-processing-text"
      description="Text indicating that the video is being processed"
      defaultMessage="Processing..."
    />
  ),
  UPLOADING_SUBTITLES: (
    <FormattedMessage
      id="spinner-uploading-subtitles-text"
      description="Text indicating that subtitles are being uploaded"
      defaultMessage="Uploading Subtitles & Captions"
    />
  ),
  SAVING_CONTENT: (
    <FormattedMessage
      id="spinner-saving-content-text"
      description="Text indicating that content changes are being saved"
      defaultMessage="Saving Content..."
    />
  ),
  BUILDING: (
    <FormattedMessage
      id="building"
      description="Notice displayed while a snap is building"
      defaultMessage="Building..."
    />
  ),
};

export const SpinnerSizes = {
  SMALL: 'SMALL',
  MEDIUM: 'MEDIUM',
  LARGE: 'LARGE',
};

type OwnProps = {
  loading?: number | boolean;
  backdrop?: boolean;
  'data-test'?: string;
  message?: any;
  size?: any; // TODO: PropTypes.oneOf(Object.values(SpinnerSizes))
  noMargin?: boolean;
  withBox?: boolean;
  noOffset?: boolean;
  className?: string;
};

type State = any;

type Props = OwnProps & typeof Spinner.defaultProps;

export class Spinner extends React.Component<Props, State> {
  static defaultProps = {
    size: SpinnerSizes.MEDIUM,
    withBox: false,
    noOffset: false,
  };

  static sizeStyles = {
    [SpinnerSizes.SMALL]: style.spinnerSmall,
    [SpinnerSizes.MEDIUM]: style.spinnerMedium,
    [SpinnerSizes.LARGE]: style.spinnerLarge,
  };

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    return updateIfPropsAndStateChanged(this.props, this.state, nextProps, nextState);
  }

  render() {
    const message = this.props.message || '';

    if (!this.props.loading) {
      return null;
    }

    const spinnerIconClass = Spinner.sizeStyles[this.props.size] || style.spinnerMedium;
    const iconClass = classNames({
      [style.spinner]: true,
      [spinnerIconClass]: true,
      [style.noOffset]: this.props.noOffset,
    });

    const classes = classNames(style.spinnerContainer, this.props.className, {
      [style.backdrop]: this.props.backdrop,
      [style.noMargin]: this.props.noMargin,
      [style.withBox]: this.props.withBox,
    });
    return (
      <div className={classes} data-test={this.props['data-test']}>
        <SpinnerIcon className={iconClass} />
        <span className={style.spinnerText}>{message}</span>
      </div>
    );
  }
}

export default Spinner;
