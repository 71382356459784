// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'data... Remove this comment to see the full error message
import toBlob from 'data-uri-to-blob';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { document, XMLHttpRequest, URL } from 'global';
import { defer } from 'q';

function videoURLToBlob(videoUrl: any) {
  const deferral = defer();
  const request = new XMLHttpRequest();
  request.onreadystatechange = () => {
    if (request.readyState !== 4) {
      return;
    }

    if (request.status !== 200) {
      deferral.reject(new Error(`response status ${request.status}`));
      return;
    }
    deferral.resolve(request.response);
  };
  request.withCredentials = true;
  request.responseType = 'blob';
  request.open('GET', videoUrl);
  request.send();

  return deferral.promise;
}

export function grabFrame(videoUrl: string, width: number, height: number) {
  return videoURLToBlob(videoUrl).then(videoBlob => {
    const deferral = defer();
    const video = document.createElement('video');
    video.addEventListener(
      'loadeddata',
      onLoadForGrabFrame.bind(video, {
        resolve: deferral.resolve,
        reject: deferral.reject,
        width,
        height,
      }),
      false
    );

    // Starting with a chrome version: 76.0.3809.100 (Official Build) (64-bit)
    // We reproduced the strange behaviour, that after the video has been upload, the preview became black.
    // https://publish-snapchat.zendesk.com/agent/tickets/5455, https://publish-snapchat.zendesk.com/agent/tickets/5449
    // Seeking to any non zero time fixes the issue.
    video.currentTime = 0.000001; // gets first frame. This matches the thumbnail that the service generates.
    video.addEventListener('error', deferral.reject, false);
    video.src = URL.createObjectURL(videoBlob);

    video.load();
    return deferral.promise;
  });
}

function onLoadForGrabFrame(this: any, { resolve, reject, width, height }: any) {
  const canvas = captureVideoFrame(this, width, height);

  let blob;

  try {
    blob = toBlob(canvas.toDataURL('image/png', 1));
  } catch (e) {
    reject(e);
  }

  if (blob) {
    blob.width = width;
    blob.height = height;
    resolve(blob);
  }
}

// Taken from Our Story Curation @author daniel.lewis - snap-lion repository
// http://appcropolis.com/blog/web-technology/using-html5-canvas-to-capture-frames-from-a-video/
// takes a video element and captures the pixels of the current frame onto a canvas
// returns a new canvas. The video will be stretched to fit the width/height
function captureVideoFrame(video: any, width: any, height: any) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(video, 0, 0, width, height);
  return canvas;
}
