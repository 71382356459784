import { routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { Middleware } from 'redux';
import normalizrMiddleware from 'redux-normalizr-middleware';
import thunk from 'redux-thunk';

import { assertArg } from 'utils/assertionUtils';

import actionCounterMiddleware from './middleware/actionCounterMiddleware';
import apiMiddleware from './middleware/apiMiddleware';
import authMiddleware from './middleware/authMiddleware';
import bailoutMiddleware from './middleware/bailoutMiddleware';
import breadcrumbMiddleware from './middleware/breadcrumbMiddleware';
import errorsMiddleware from './middleware/errorsMiddleware';
import expiredActionCatcherMiddleware from './middleware/expiredActionCatcherMiddleware';
import modalsMiddleware from './middleware/modalsMiddleware';
import observerMiddleware from './middleware/observerMiddleware';
import promiseMiddleware from './middleware/promiseMiddleware';
import timestampMiddleware from './middleware/timestampMiddleware';

export const MiddlewareNames = {
  THUNK: 'THUNK',
  PROMISE: 'PROMISE',
  ROUTER: 'ROUTER',
  AUTH: 'AUTH',
  API: 'API',
  NORMALIZR: 'NORMALIZR',
  ERRORS: 'ERRORS',
  ACTION_COUNTER: 'ACTION_COUNTER',
  EXPIRED_ACTION_CATCHER: 'EXPIRED_ACTION_CATCHER',
  BAILOUT: 'BAILOUT',
  OBSERVER: 'OBSERVER',
  TIMESTAMP: 'TIMESTAMP',
  BARRIER: 'BARRIER',
  MODALS: 'MODALS',
  BREADCRUMB: 'BREADCRUMB',
};

// channelFactory arg is just used for testing
type CreateMiddlewareArgs = {
  history: History;
  observerMap: any;
  channelFactory?: any;
};

type MiddlewareObj = {
  name: string;
  func: Middleware;
};

export default function createMiddleware({
  history,
  observerMap,
  channelFactory,
}: CreateMiddlewareArgs): MiddlewareObj[] {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(observerMap).is.object();

  const middleware = [];

  // Thunk should always be first. We receive some actions as functions expecting for thunk to call them with dispatch
  // Hence, they are not real well formed action objects until after thunk
  middleware.push({ name: MiddlewareNames.THUNK, func: thunk });
  middleware.push({ name: MiddlewareNames.ACTION_COUNTER, func: actionCounterMiddleware });
  middleware.push({ name: MiddlewareNames.BAILOUT, func: bailoutMiddleware() });
  middleware.push({ name: MiddlewareNames.ERRORS, func: errorsMiddleware() });
  middleware.push({ name: MiddlewareNames.PROMISE, func: promiseMiddleware() });
  middleware.push({ name: MiddlewareNames.ROUTER, func: routerMiddleware(history) });
  middleware.push({ name: MiddlewareNames.AUTH, func: authMiddleware });
  middleware.push({ name: MiddlewareNames.API, func: apiMiddleware() });
  middleware.push({ name: MiddlewareNames.NORMALIZR, func: normalizrMiddleware() });
  middleware.push({ name: MiddlewareNames.TIMESTAMP, func: timestampMiddleware });
  middleware.push({ name: MiddlewareNames.MODALS, func: modalsMiddleware() });
  middleware.push({ name: MiddlewareNames.BREADCRUMB, func: breadcrumbMiddleware });
  middleware.push({ name: MiddlewareNames.OBSERVER, func: observerMiddleware(observerMap) });
  middleware.push({ name: MiddlewareNames.EXPIRED_ACTION_CATCHER, func: expiredActionCatcherMiddleware });

  return middleware;
}
