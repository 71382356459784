import { combineReducers } from 'redux';

import { createMergeReducer } from 'state/common/reducerFactories';

import { SET_LAYOUT_FLAG } from '../actions/navLayoutActions';

const layoutFlags = createMergeReducer(SET_LAYOUT_FLAG);

export default combineReducers({
  layoutFlags,
});
