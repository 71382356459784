import is from 'is_js';
import { combineReducers } from 'redux';

import {
  createSequenceHandlingReducer,
  createSequenceCountingByIdReducer,
  createAgeByIdReducer,
  createActivatingIdReducer,
} from 'state/common/reducerFactories';

import * as snapcodesActions from '../actions/snapcodesActions';

import { DeeplinkType } from 'config/constants';
import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

const getLinkableIdIfLinkableType = (linkableType: any) => (action: any) => {
  return action.params.linkableType === linkableType ? action.params.linkableId : null;
};

// TODO: Given that we currently dispatch data loading actions lazily in the React components that
// need it, not sure about the value of isLoadingPerLinkable. We use isLoadingPerLinkable in
// mapStateToDispatch e.g., to compute isSnapcodesLoading prop, but on first mount/render, this prop
// has value false, which is misleading because we are loading data.
const isLoadingPerLinkable = combineReducers({
  [DeeplinkType.SNAP]: createSequenceCountingByIdReducer(getLinkableIdIfLinkableType(DeeplinkType.SNAP), [
    snapcodesActions.LOAD_SNAPCODES,
  ]),
  [DeeplinkType.EDITION]: createSequenceCountingByIdReducer(getLinkableIdIfLinkableType(DeeplinkType.EDITION), [
    snapcodesActions.LOAD_SNAPCODES,
  ]),
  [DeeplinkType.PUBLISHER]: createSequenceCountingByIdReducer(getLinkableIdIfLinkableType(DeeplinkType.PUBLISHER), [
    snapcodesActions.LOAD_SNAPCODES,
  ]),
});

const lastUpdatedPerLinkable = combineReducers({
  [DeeplinkType.SNAP]: createAgeByIdReducer(getLinkableIdIfLinkableType(DeeplinkType.SNAP), [
    snapcodesActions.LOAD_SNAPCODES,
  ]),
  [DeeplinkType.EDITION]: createAgeByIdReducer(getLinkableIdIfLinkableType(DeeplinkType.EDITION), [
    snapcodesActions.LOAD_SNAPCODES,
  ]),
  [DeeplinkType.PUBLISHER]: createAgeByIdReducer(getLinkableIdIfLinkableType(DeeplinkType.PUBLISHER), [
    snapcodesActions.LOAD_SNAPCODES,
  ]),
});

const byId = createSequenceHandlingReducer(
  {},
  [snapcodesActions.LOAD_SNAPCODES, snapcodesActions.UPDATE_SNAPCODE, snapcodesActions.CREATE_SNAPCODE],
  {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      let snapcodeIds;
      if (is.array(action.payload.result)) {
        snapcodeIds = action.payload.result;
      } else {
        snapcodeIds = [action.payload.result];
      }

      const snapcodeEntities = snapcodeIds.reduce((entities: any, snapcodeId: any) => {
        // When a snapcode is updated, its image URL doesn't change. Hence, we need a cache-buster to
        // force the browser to show the updated image.
        // FIXME: Discover service owns creating and updating snapcodes, as well as vending their URLs;
        // so ultimately it should also vend cache-busting tags as part of its response.
        const entity = { cb: `${Date.now()}`, ...action.payload.entities.snapcode[snapcodeId] };
        return {
          ...entities,
          [snapcodeId]: entity,
        };
      }, {});

      return u(wrapEntitiesInUpdeepConstants(snapcodeEntities), state);
    },
  }
);

const snapcodeListPerLinkable = createSequenceHandlingReducer(
  {},
  [snapcodesActions.LOAD_SNAPCODES, snapcodesActions.CREATE_SNAPCODE],
  {
    start(state: any, action: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      // returned whole load
      if (is.array(action.payload.result)) {
        return u(
          {
            [action.params.linkableType]: {
              [action.params.linkableId]: u.constant(action.payload.result),
            },
          },
          state
        );
      }

      // create response, let's be optimistic and add that to what the existing list of snapcodes
      return u(
        {
          [action.params.linkableType]: {
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            [action.params.linkableId]: (existing: any) => [].concat(existing || [], [action.payload.result]),
          },
        },
        state
      );
    },
  }
);

const quality = createActivatingIdReducer('quality', snapcodesActions.SNAPCODE_PREVIEW_QUALITY);

export default combineReducers({
  byId,
  snapcodeListPerLinkable,
  isLoadingPerLinkable,
  lastUpdatedPerLinkable,
  quality,
});
