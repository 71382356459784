import makeIntlMessage from 'utils/intlMessages/intlMessages';
import type { IntlMessage } from 'utils/intlMessages/intlMessages';

export const MAX_NUMBER_OF_UPLOADED_FILES = 30;

export function validateFiles(files: Blob[]) {
  return Promise.resolve().then(() => {
    if (files.length > MAX_NUMBER_OF_UPLOADED_FILES) {
      const error: Error & {
        intlError?: IntlMessage;
      } = new Error(`Only ${MAX_NUMBER_OF_UPLOADED_FILES} files can be uploaded at a time`);
      error.intlError = makeIntlMessage('error-too-many-files-to-upload', {
        filesLimit: MAX_NUMBER_OF_UPLOADED_FILES,
      });
      throw error;
    }
  });
}
