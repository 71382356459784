import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import * as notificationsActions from '../actions/notificationsActions';

const toastMessages = (state = {}, action: any) => {
  switch (action.type) {
    case notificationsActions.ADD_NOTIFICATION_MESSAGE:
      if (!action.payload.messages || action.payload.messages.length === 0) {
        return state;
      }

      return u(
        {
          [action.payload.id]: action.payload,
        },
        state
      );

    case notificationsActions.DELETE_TOAST_MESSAGE:
      return u(u.omit(action.payload.id), state);
    default:
      return state;
  }
};

const invalidAdToastOpened = (state: any, action: any) => {
  let updatedState = state;
  if (typeof state === 'undefined') {
    updatedState = false;
  }

  switch (action.type) {
    case notificationsActions.SET_INVALID_AD_TOAST:
      return action.invalidAdToastOpened;
    default:
      return updatedState;
  }
};

export default combineReducers({
  toastMessages,
  invalidAdToastOpened,
});
