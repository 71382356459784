import { get } from 'lodash';
import { createSelector as s } from 'reselect';

import * as assetSelectors from 'state/asset/selectors/assetSelectors';
import {
  createDynamicKeySelector,
  createKeySelector,
  createKeySelectorFromDynamicKeySelector,
  reselectById,
} from 'state/common/selectorFactories';
import { getActivePublisherDefaultSubtitlesLanguage } from 'state/publishers/selectors/publishersSelectors';

import { EMPTY_OBJECT, EMPTY_ARRAY } from 'config/constants';
import type { SubtitleTrack } from 'constants/subtitles';

import type { AssetID } from 'types/assets';
import type { State } from 'types/rootState';

export function getSubtitlesData(state: State) {
  return get(state, ['subtitles']) || {};
}

export const getSubtitlesByTrackIdMap = createKeySelector(getSubtitlesData, 'byId', EMPTY_OBJECT);

export const getSubtitlesTrackLoadingMap = createKeySelector(getSubtitlesData, 'tracksLoadingById', EMPTY_OBJECT);
export const getSubtitlesVisibility = createKeySelector(getSubtitlesData, 'previewVisible', true);

const getSelectedLanguageCode = createKeySelector(getSubtitlesData, 'selectedLanguage', null);

export const getSubtitlesActiveLanguageCode = s(
  getSelectedLanguageCode,
  getActivePublisherDefaultSubtitlesLanguage,
  (selectedLanguageCode, defaultPublisherLanguage) => selectedLanguageCode || defaultPublisherLanguage
);

export const getSubtitlesTrackById = createDynamicKeySelector<string | undefined | null, string>(
  getSubtitlesByTrackIdMap,
  null
);
export const getSubtitlesTrackLoadingById = createDynamicKeySelector<boolean, string>(
  getSubtitlesTrackLoadingMap,
  false
);

export const getSubtitlesTrackContentById = reselectById<string | undefined | null, AssetID>(
  null,
  (state: any, subtitleId: any) => getSubtitlesTrackById(state)(subtitleId),
  (subtitle: any) => (subtitle ? subtitle.rawContent : null)
);

export const getSubtitlesByVideoAssetId = reselectById<any | undefined | null, AssetID>(
  null,
  (state: any, videoAssetId: any) => assetSelectors.getAssetById(state)(videoAssetId),
  (state: any) => getSubtitlesByTrackIdMap(state),
  (videoAsset: any, subtitleTracksMap: any) => {
    if (videoAsset && videoAsset.subtitles) {
      return videoAsset.subtitles.map((subtitleId: any) => subtitleTracksMap[subtitleId]).filter((sub: any) => !!sub);
    }

    return EMPTY_ARRAY;
  }
);

export const getSubtitlesByLanguageCode = reselectById<SubtitleTrack[], AssetID>(
  EMPTY_ARRAY,
  (state: any, languageCode: any) => languageCode,
  (state: any) => getSubtitlesByTrackIdMap(state),
  (languageCode: string, subtitleTracksMap: Object) =>
    Object.values(subtitleTracksMap).filter(track => track.language === languageCode)
);

export const getSubtitlesByActiveLanguageAndVideoAssetId = reselectById<SubtitleTrack | undefined | null, AssetID>(
  null,
  (state: any, assetId: any) => getSubtitlesByVideoAssetId(state)(assetId) || [],
  getSubtitlesActiveLanguageCode,
  (allSubtitles: any, activeLanguage: any) => {
    if (allSubtitles.length > 0) {
      // This iterates over all tracks to find needed. While we keep number of tracks small, it is fine to keep things simple
      // When we cross 10-15 subtitle tracks, it might be better to move to map instead of list
      const selectedLanguageTrack = allSubtitles.find(
        (subtitleTrack: any) => subtitleTrack && subtitleTrack.language === activeLanguage
      );
      if (selectedLanguageTrack) {
        return selectedLanguageTrack;
      }
      return null;
    }
    return null;
  }
);

export const getAvailableSubtitlesLanguageCodesByVideoAssetId = reselectById<string[], AssetID>(
  EMPTY_ARRAY,
  (state: any, assetId: any) => getSubtitlesByVideoAssetId(state)(assetId),
  (availableTracks?: SubtitleTrack[] | null) => {
    return availableTracks ? availableTracks.map(subtitleTrack => subtitleTrack.language) : EMPTY_ARRAY;
  }
);

export const getSubtitlesSourceByVideoAssetId = createKeySelectorFromDynamicKeySelector(
  getSubtitlesByActiveLanguageAndVideoAssetId,
  'source',
  null
);

export const getSubtitlesAssetIdByVideoAssetId = createKeySelectorFromDynamicKeySelector(
  getSubtitlesByActiveLanguageAndVideoAssetId,
  'assetId',
  ''
);
