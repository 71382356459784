import { get, size, some } from 'lodash';
import log from 'loglevel';
import { createSelector as s } from 'reselect';

import { getAssetById } from 'state/asset/selectors/assetSelectors';
import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import { createKeySelector, reselectById } from 'state/common/selectorFactories';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import {
  isHostUserRequired,
  isPublishingPaused,
  isSingleAssetStoryEditorEnabled,
} from 'state/features/selectors/featuresSelectors';
import * as mediaSelectors from 'state/media/selectors/mediaSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { EMPTY_ARRAY, LocalStorage, EDITOR_MODES, ExternalSnapSource } from 'config/constants';
import * as componentUtils from 'utils/componentUtils';
import { functionRef } from 'utils/functionUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { localStorage } from 'utils/localStorageUtils';

import { assertSnapId, SnapId } from 'types/common';
import type { Edition, EditionID, VideoTrack } from 'types/editions';
import { LiveEditStatus } from 'types/editions';
import { PublisherID } from 'types/publishers';
import { State } from 'types/rootState';
import type { SingleAssetSnap, TopSnap } from 'types/snaps';

const getEditor = (state: any) => {
  return state.publisherStoryEditor || {};
};
const getIngestingArticleBySnapMap = createKeySelector(getEditor, 'ingestingArticleBySnapId', {});
export const getIngestingArticleBySnapId = s(getIngestingArticleBySnapMap, ingestingMap => (snapId: any) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  Boolean(ingestingMap[snapId])
);
export const getActiveEditionId = createKeySelector(getEditor, 'activeEditionId', null);
export const getEditorMode = createKeySelector(getEditor, 'editorMode', null);
export const getEditorModeMetadata = createKeySelector(getEditor, 'editorModeMetadata', '');
export const getEditorModeOptions = createKeySelector(getEditor, 'editorModeOptions', {});
export const getLastVisitedSnapPublisherUrl = createKeySelector(getEditor, 'lastVisitedSnapPublisherUrl', null);
export function getActiveEdition(state: State) {
  const editionID = getActiveEditionId(state);
  if (editionID) {
    return editionsSelectors.getEditionById(state)(editionID);
  }
  return null;
}
export const getEditionSnaps = reselectById<TopSnap[] | SingleAssetSnap[], EditionID>(
  [],
  (state: State, editionId: EditionID) => editionsSelectors.getEditionById(state)(editionId),
  snapsSelectors.getAllSnaps,
  (
    edition: Edition,
    allSnaps: {
      [K in SnapId]: TopSnap;
    }
  ) => {
    if (edition && edition.snapIds) {
      return edition.snapIds.map((snapId: SnapId) => allSnaps[snapId]);
    }
    return EMPTY_ARRAY;
  }
);

// Get SAS story media, based on the SAS type:
// 1. Single Snap Story
// 2. Legacy SAS - multiple snaps
export const getTranscodedMediaIdForSingleSnapStory = s(getEditionSnaps, getEditionSnapsFn => (storyId: EditionID) => {
  return snapEntityHelpers.getUnifiedSingleSnap(getEditionSnapsFn(storyId) as SingleAssetSnap[])?.videoAssetId || null;
});

export const getStoryChapters = s(getEditionSnaps, getEditionSnapsFn => (storyId: EditionID) => {
  return snapEntityHelpers.getUnifiedSingleSnap(getEditionSnapsFn(storyId) as SingleAssetSnap[])?.chapters;
});

export const getTranscodedMediaIdForLegacySAS = s(
  editionsSelectors.getEditionById,
  getEditionByIdFn => (storyId: EditionID) => {
    const story = getEditionByIdFn(storyId);
    const firstVideoTrack: VideoTrack = get(story, ['videoTracks', 0], null);
    return firstVideoTrack ? firstVideoTrack.transcodedMediaId : null;
  }
);
export const getTranscodedMediaIdForStory = s(
  getTranscodedMediaIdForSingleSnapStory,
  getTranscodedMediaIdForLegacySAS,
  (getTranscodedMediaIdForSingleSnapStoryFn, getTranscodedMediaIdForLegacySASFn) => (storyId: EditionID) => {
    return getTranscodedMediaIdForSingleSnapStoryFn(storyId) || getTranscodedMediaIdForLegacySASFn(storyId);
  }
);

export const hasStoryAnySubtitles = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getEditionSnaps(state)(editionId),
  (editionSnaps: Array<TopSnap | undefined | null>) => {
    return editionSnaps.some(
      snap => snap && (snap as any).subtitlesAssetIds && size((snap as any).subtitlesAssetIds) > 0
    );
  }
);
export const hasSingleAssetStorySubtitles = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getTranscodedMediaIdForStory(state)(editionId),
  getAssetById,
  (transcodedMediaId: string | number, getAssetByIdFn: any) => {
    return !!(transcodedMediaId && getAssetByIdFn(transcodedMediaId)?.subtitles?.length);
  }
);
export const getDismissGenerateSubtitlesModalFromLocalStorage = () =>
  localStorage.getItem(LocalStorage.DISMISS_GENERATE_SUBTITLES_MODAL);

// Choose between SAS editors
export const shouldUseSingleSnapBuilder = s(
  getTranscodedMediaIdForSingleSnapStory,
  getTranscodedMediaIdForSingleSnapStoryFn => (storyId?: EditionID | null) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
    return Boolean(getTranscodedMediaIdForSingleSnapStoryFn(storyId));
  }
);
export const shouldUseLegacySASEditor = s(
  getTranscodedMediaIdForLegacySAS,
  getTranscodedMediaIdForLegacySASFn => (storyId?: EditionID | null) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | null | undefined' is no... Remove this comment to see the full error message
    return Boolean(getTranscodedMediaIdForLegacySASFn(storyId));
  }
);
export const shouldUseSingleAssetEditor = s(
  shouldUseLegacySASEditor,
  shouldUseSingleSnapBuilder,
  (shouldUseLegacySASEditorFn, shouldUseSingleSnapBuilderFn) => (storyId?: EditionID | null) => {
    return shouldUseLegacySASEditorFn(storyId) || shouldUseSingleSnapBuilderFn(storyId);
  }
);

export const shouldShowGenerateSubtitlesPrompt = reselectById<boolean, EditionID>(
  false,
  (state: any, storyId: any) => shouldUseSingleAssetEditor(state)(storyId),
  functionRef(publishersSelectors, 'activePublisherIsShow'),
  getDismissGenerateSubtitlesModalFromLocalStorage,
  (state: any, storyId: any) => hasStoryAnySubtitles(state)(storyId),
  (state: any, storyId: any) => hasSingleAssetStorySubtitles(state)(storyId),
  (
    isSingleAssetEditor: any,
    isShow: any,
    hasDismissedGeneratedSubtitlesModal: any,
    hasAnySubtitles: any,
    hasSingleAssetSubtitles: any
  ) =>
    isShow &&
    (isSingleAssetEditor ? !hasSingleAssetSubtitles : !hasAnySubtitles) &&
    hasDismissedGeneratedSubtitlesModal !== 'true'
);
export const isEditionVideoOnly = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getEditionSnaps(state)(editionId),
  (editionSnaps: any) =>
    !editionSnaps.some(
      (topsnap: any) => !snapEntityHelpers.isSubscribeSnap(topsnap) && !snapEntityHelpers.topsnapHasVideoMedia(topsnap)
    )
);
export const hasAnyVideoSnap = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getEditionSnaps(state)(editionId),
  (editionSnaps: any) =>
    editionSnaps.some(
      (topsnap: any) => snapEntityHelpers.topsnapHasVideoMedia(topsnap) && !snapEntityHelpers.isSubscribeSnap(topsnap)
    )
);
export const hasMediaOnEachSnap = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getEditionSnaps(state)(editionId),
  (editionSnaps: any) =>
    editionSnaps.every(
      (topsnap: any) =>
        snapEntityHelpers.topsnapHasVideoMedia(topsnap) || snapEntityHelpers.topsnapHasImageMedia(topsnap)
    )
);
export const getActiveEditionSnaps = s(
  getActiveEditionId,
  state => state,
  (activeEditionId, state) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    return getEditionSnaps(state)(activeEditionId) || EMPTY_ARRAY;
  }
);
export const getActiveEditionIsReadOnly = s(
  getActiveEditionId,
  functionRef(editionsSelectors, 'editionIsReadOnly'),
  functionRef(editionsSelectors, 'getEditionSavingById'),
  (activeEditionId, editionIsReadOnlyFn, editionSavingByIdFn) => {
    return (editionIsReadOnlyFn as any)(activeEditionId) || (editionSavingByIdFn as any)(activeEditionId) > 0;
  }
);
export const hasPendingBuildStatus = s(
  getEditionSnaps,
  functionRef(snapsSelectors, 'pendingBuildStatusFetchByIds'),
  (getEditionSnapsFn, pendingBuildStatusFetchByIds) => (storyId: any) => {
    if (!storyId) {
      return true;
    }
    const snapIds = (getEditionSnapsFn(storyId) as TopSnap[]).filter(snap => snap !== undefined).map(snap => snap.id);
    return snapIds.length > 0 && (pendingBuildStatusFetchByIds as any)(snapIds);
  }
);
export const getStoryIsReadOnlyOrSaving = s(
  functionRef(editionsSelectors, 'editionIsReadOnly'),
  functionRef(editionsSelectors, 'getEditionSavingById'),
  hasPendingBuildStatus,
  (storyIsReadOnlyFn, storySavingByIdFn, hasPendingBuildStatusFn) => (storyId: any) => {
    if (!storyId) {
      return true;
    }
    return (
      (storyIsReadOnlyFn as any)(storyId) || (storySavingByIdFn as any)(storyId) > 0 || hasPendingBuildStatusFn(storyId)
    );
  }
);
export const getActiveEditionCanDeleteSnaps = s(
  getActiveEditionIsReadOnly,
  getActiveEditionSnaps,
  (isActiveEditionReadOnly, activeEditionSnaps) => {
    if (isActiveEditionReadOnly) {
      return false;
    }
    // Can't delete if only 1 non-subscription snaps
    const minNumberOfSnaps = 1;
    if (activeEditionSnaps.length > minNumberOfSnaps + 1) {
      return true;
    }
    if (activeEditionSnaps.length === minNumberOfSnaps + 1) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TopSnap | undefined' is not assi... Remove this comment to see the full error message
      return !snapEntityHelpers.isSubscribeSnap(activeEditionSnaps[minNumberOfSnaps]);
    }
    return false;
  }
);

const isStoryLiveEdited = (story: Edition | null) => {
  return story && editionEntityHelpers.isAvailable(story) && story.liveEditStatus === LiveEditStatus.IN_PROGRESS;
};
export const getStoryStatusMessageForStoryId = s(
  functionRef(editionsSelectors, 'editionIsReadOnly'),
  functionRef(editionsSelectors, 'getStoryScheduleStatus'),
  functionRef(editionsSelectors, 'getEditionById'),
  functionRef(buildStatusSelectors, 'getEditionSnapBuildStatuses'),
  isSingleAssetStoryEditorEnabled,
  (
    storyIsReadOnlyFn,
    getStoryScheduleStatusFn,
    getStoryByIdFn,
    getStorySnapBuildStatusesFn,
    sasUnificationEnabled
  ) => ({ storyId, defaultMessage = null }: any) => {
    if (!storyId) {
      return defaultMessage;
    }
    const storyIsReadOnly = (storyIsReadOnlyFn as any)(storyId);
    const storyStatus = (getStoryScheduleStatusFn as any)(storyId);
    const story = (getStoryByIdFn as any)(storyId);
    const isDraft = editionEntityHelpers.isDraft(story);
    if (storyIsReadOnly && isDraft) {
      return intlMessages.getMessageFromId('story-status-is-read-only');
    }
    const snapBuildStatuses = (getStorySnapBuildStatusesFn as any)(story);
    const storyStatusStringId = editionEntityHelpers.advancedStoryStatusToStringId(storyStatus, snapBuildStatuses);
    if (storyStatusStringId) {
      const extraValues = editionEntityHelpers.getStoryStatusExtraValues(story, snapBuildStatuses);
      return intlMessages.getMessageFromId(storyStatusStringId, extraValues);
    }
    if (isStoryLiveEdited(story)) {
      return intlMessages.getMessageFromId('story-unpublished-changes');
    }
    return defaultMessage;
  }
);
export const getIsStoryLiveEdited = s(editionsSelectors.getEditionById, getStoryByIdFn => (storyId: EditionID) => {
  return isStoryLiveEdited(getStoryByIdFn(storyId));
});
export const getPublisherStatusMessageForStoryId = s(
  publishersSelectors.getActivePublisherDetails,
  isHostUserRequired,
  isPublishingPaused,
  (activePublisher, hostUserRequired, publishingPaused) => ({ storyId, defaultMessage = null }: any) => {
    if (!storyId) {
      return defaultMessage;
    }
    if (!get(activePublisher, 'publishingEnabled', true)) {
      return intlMessages.getMessageFromId('publishing-disabled');
    }
    if (hostUserRequired && !get(activePublisher, 'hostUsername', '')) {
      return intlMessages.getMessageFromId('publishing-disabled-host-user');
    }
    if (publishingPaused) {
      return intlMessages.getMessageFromId('publishing-paused');
    }
    return defaultMessage;
  }
);
export const getFirstBuildStatusWithErrorOrIncompleteForStory = s(
  functionRef(editionsSelectors, 'getEditionById'),
  functionRef(buildStatusSelectors, 'getEditionSnapBuildStatuses'),
  (getStoryByIdFn, getStorySnapBuildStatusesFn) => (storyId: any) => {
    if (!storyId) {
      return null;
    }
    const story = (getStoryByIdFn as any)(storyId);
    const snapBuildStatuses = (getStorySnapBuildStatusesFn as any)(story);
    const buildStatusesWithErrors = editionEntityHelpers.getBuildStatusesWithErrors(snapBuildStatuses);
    if (buildStatusesWithErrors.length) {
      return buildStatusesWithErrors[0];
    }
    const incompleteBuildStatuses = editionEntityHelpers.getIncompleteBuildStatuses(snapBuildStatuses);
    if (incompleteBuildStatuses.length) {
      return incompleteBuildStatuses[0];
    }
    const nonBlockingBuildStatuses = editionEntityHelpers.getNonBlockingBuildStatuses(snapBuildStatuses);
    if (nonBlockingBuildStatuses.length) {
      return nonBlockingBuildStatuses[0];
    }
    return null;
  }
);
export const getEditableActiveEditionsForPublisher = s(
  functionRef(publishersSelectors, 'getPublisherActiveEditionsById'),
  functionRef(editionsSelectors, 'getEditionById'),
  functionRef(editionsSelectors, 'editionIsReadOnly'),
  (
    publisherActiveEditionsFn: (publisherId: PublisherID) => EditionID[],
    getEditionByIdFn: (editionId: EditionID) => Edition,
    editionIsReadOnlyFn: (editionId: EditionID, publisherId: PublisherID) => boolean
  ) => (publisherId: PublisherID | null) => {
    if (!publisherId) {
      return EMPTY_ARRAY;
    }
    return publisherActiveEditionsFn(publisherId)
      .map((editionId: EditionID) => getEditionByIdFn(editionId))
      .filter((edition: Edition) => !editionIsReadOnlyFn(edition?.id, publisherId));
  }
);
export const getEditableActiveEditionsForActivePublisher = s(
  functionRef(userSelectors, 'getActivePublisherId'),
  getEditableActiveEditionsForPublisher,
  (activePublisherId: PublisherID, editableActiveEditionsForPublisher) => {
    if (!activePublisherId) {
      log.warn('No active publisher set when getting active editions');
    }
    return editableActiveEditionsForPublisher(activePublisherId);
  }
);
// Currently we are locking the UI whenever there's a transaction or an uploading job in progress
export const getWholeSnapIsUploadingById = s(
  functionRef(mediaSelectors, 'isUploadingByComponentId'),
  functionRef(snapsSelectors, 'getSnapById'),
  (isUploadingByComponentId, getSnapByIdFn) => (snapId: any) => {
    assertSnapId(snapId);
    const snap = (getSnapByIdFn as any)(snapId);
    if (!snap) {
      return false;
    }
    // We need to check if none of the subSnaps has any uploads in progress
    const snapIds = snapEntityHelpers.getAllSnapIdsForSnap(snap);
    const componentIds = snapIds.map(id => componentUtils.buildComponentIdForSnapId(id));
    return Boolean(componentIds.some(componentId => (isUploadingByComponentId as any)(componentId)));
  }
);
export const getStoryEditorMode = s(
  functionRef(componentsSelectors, 'getActiveComponent'),
  getEditorMode,
  getEditorModeMetadata,
  getEditorModeOptions,
  (activeComponent, editorMode, metadata, options) => {
    if (!activeComponent) {
      // at this point, the user just clicked to go into a story.
      // because Happening Now stories only have one snap,
      // SimpleStoryBuilder has some additional logic to open the *Story Studio editor*
      // of the first and only snap (see openHappeningNowSnap method).
      // forceGoToSnapPublisher will inform if SimpleStoryBuilder should open the Story Studio editor,
      // so that the if the user was trying to reach the Snap Publisher Editor instead, the action won't get overridden.
      const forceGoToSnapPublisher = editorMode === EDITOR_MODES.SNAP_PUB;
      return {
        editorMode: EDITOR_MODES.STORY,
        metadata: '',
        options: {},
        forceGoToSnapPublisher,
      };
    }
    if (activeComponent && !editorMode) {
      return { editorMode: EDITOR_MODES.EDITOR, metadata: '', options: {} };
    }
    return { editorMode, metadata, options };
  }
);
export const activeEditionHasAtLeastOneCuratedSnap = s(getActiveEditionSnaps, snaps =>
  some(snaps, snap => snap?.externalSnapSource === ExternalSnapSource.STORY_KIT_OUR_STORIES)
);
export const isMediaLibraryDrawerVisible = createKeySelector(getEditor, 'isMediaLibraryDrawerVisible', false);

export const getIsAdvertisingDisabled = s(
  getEditionSnaps,
  shouldUseSingleSnapBuilder,
  (getEditionSnapsFn, shouldUseSingleSnapBuilderFn) => (edition: Edition) => {
    if (shouldUseSingleSnapBuilderFn(edition.id)) {
      return snapEntityHelpers.isAdvertisingDisabledForUnifiedSingleSnap(
        getEditionSnapsFn(edition.id) as SingleAssetSnap[]
      );
    }
    return editionEntityHelpers.isAdvertisingDisabledForStory(edition);
  }
);
