import Select, { SelectValue } from 'antd/lib/select';
import classNames from 'classnames';
import { omit } from 'lodash';
import React from 'react';
import 'antd/lib/select/style/css';
import type { ReactNode } from 'react';

import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

import style from './SDSDropdown.scss';

export const DropdownType = enumObject({
  GREY: 'grey',
});

export const DropdownStatusType = enumObject({
  ERROR: 'error',
});

export type DropdownTypeEnum = Enum<typeof DropdownType>;
export type DropdownStatusTypeEnum = Enum<typeof DropdownStatusType>;

export const DropdownSize = enumObject({
  SMALL: 'small',
  MEDIUM: 'medium',
});

export type DropdownSizeEnum = Enum<typeof DropdownSize>;

// TODO @yyan show arrow for SDSDropdown
export type Props = {
  className?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  children: ReactNode;
  defaultValue?: string;
  disableClear?: boolean;
  dropdownAlign?: {
    offset: Array<number>;
  };
  onChange?: (a: any) => any;
  onSelect?: (a: SelectValue) => void;
  disabled?: boolean;
  placeholder?: string | ReactNode;
  errorMessage?: string;
  dropdownWidthMatchContent?: boolean;
  'data-test'?: string;

  // @ts-expect-error ts-migrate(2702) FIXME: 'Select' only refers to a type, but is being used ... Remove this comment to see the full error message
  filterOption?: (b: string, a: Select.Option) => boolean;
  showSearch?: boolean;
  value?: string | string[];
  type?: DropdownTypeEnum;
  size?: DropdownSizeEnum;
  statusType?: DropdownStatusTypeEnum;
  suffixIcon?: ReactNode;
  optionLabelProp?: string;
  mode?: string;
  autoClearSearchValue?: boolean;
};

export class SDSDropdown extends React.PureComponent<Props> {
  handleDropdownVisibleChange = (open: boolean) => {
    if (open && this.props.onFocus) {
      this.props.onFocus();
    }
    if (!open && this.props.onBlur) {
      this.props.onBlur();
    }
  };

  renderErrorMessage = () => {
    return <div className={style.errorMsg}>{this.props.errorMessage}</div>;
  };

  render() {
    // Select doesn't handle placeholder props correctly, this logic is used to force correct styling to render placeholder text.
    const isPlaceholder = Boolean(this.props.value === null && this.props.placeholder !== null);
    return (
      <>
        <Select
          {...omit(this.props, [
            'onFocus',
            'onBlur',
            'size',
          ])} /* allows uncontrolled value to be passed and omits blur/focus handled above */
          onDropdownVisibleChange={this.handleDropdownVisibleChange}
          dropdownClassName={classNames(style.dropdownOptionDisabled, {
            [style.dropdownWidthAuto]: this.props.dropdownWidthMatchContent,
          })}
          className={classNames(
            style.dropdownOverride,
            this.props.size && this.props.size === DropdownSize.MEDIUM
              ? style.dropdownOverrideMedium
              : style.dropdownOverride,
            style.input,
            this.props.className,
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            style[this.props.size || ''],
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            style[this.props.type || ''],
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            style[this.props.statusType || ''],
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            style[isPlaceholder ? 'showPlaceholder' : '']
          )}
          allowClear={!this.props.disableClear}
          onChange={this.props.onChange}
          defaultValue={this.props.defaultValue}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          showSearch={this.props.showSearch}
          filterOption={this.props.filterOption}
          data-test={this.props['data-test']}
          onSelect={this.props.onSelect}
          suffixIcon={this.props.suffixIcon}
          mode={this.props.mode}
          autoClearSearchValue={this.props.autoClearSearchValue}
        >
          {this.props.children}
        </Select>
        {this.props.errorMessage && this.renderErrorMessage()}
      </>
    );
  }
}

export default SDSDropdown;
