import { createSelector as s } from 'reselect';

import { createKeySelector, createDynamicKeySelector } from 'state/common/selectorFactories';

import { DeeplinkType } from 'config/constants';
import { createSnapcodeFromSnapcodeEntity } from 'utils/snapcodeUtils';

import { Quality } from 'types/snapcodes';

function getSnapcodeData(state: any) {
  return state.snapcodes || {};
}

export const getSnapcodeMap = createKeySelector(getSnapcodeData, 'byId', {});
export const getSnapcodeById = createDynamicKeySelector(getSnapcodeMap, null);

const getIsLoadingPerLinkableMap = createKeySelector(getSnapcodeData, 'isLoadingPerLinkable', {});
const getLastUpdatedPerLinkableMap = createKeySelector(getSnapcodeData, 'lastUpdatedPerLinkable', {});
const getSnapcodeListPerLinkableMap = createKeySelector(getSnapcodeData, 'snapcodeListPerLinkable', {});

// Snap snapcodes
const getIsLoadingPerSnapMap = createKeySelector(getIsLoadingPerLinkableMap, DeeplinkType.SNAP, {});
export const getIsLoadingPerSnapId = createDynamicKeySelector(getIsLoadingPerSnapMap, 0);

const getLastUpdatedPerSnapMap = createKeySelector(getLastUpdatedPerLinkableMap, DeeplinkType.SNAP, {});
export const getLastUpdatedPerSnapId = createDynamicKeySelector(getLastUpdatedPerSnapMap, 0);

const getSnapcodeListPerSnapMap = createKeySelector(getSnapcodeListPerLinkableMap, DeeplinkType.SNAP, {});
export const getSnapcodeListPerSnapId = createDynamicKeySelector(getSnapcodeListPerSnapMap, []);

export const getSnapcodesPerSnapId = s(
  getSnapcodeById,
  getSnapcodeListPerSnapId,
  (getSnapcodeByIdFn, getSnapcodeListPerSnapIdFn) => (snapId: any) => {
    return getSnapcodeListPerSnapIdFn(snapId).map(snapcodeId => {
      return getSnapcodeByIdFn(snapcodeId);
    });
  }
);

export const getDecoratedSnapcodesPerSnapId = s(getSnapcodesPerSnapId, getSnapcodesPerSnapIdFn => (snapId: any) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(snapcode: SnapcodeEntity) => Sn... Remove this comment to see the full error message
  return getSnapcodesPerSnapIdFn(snapId).map(createSnapcodeFromSnapcodeEntity(DeeplinkType.SNAP, snapId));
});

// Story snapcodes
const getSnapcodeListPerStoryMap = createKeySelector(getSnapcodeListPerLinkableMap, DeeplinkType.EDITION, {});
export const getSnapcodeListPerStoryId = createDynamicKeySelector(getSnapcodeListPerStoryMap, []);

export const getSnapcodesPerStoryId = s(
  getSnapcodeById,
  getSnapcodeListPerStoryId,
  (getSnapcodeByIdFn, getSnapcodeListPerStoryIdFn) => (storyId: any) => {
    return getSnapcodeListPerStoryIdFn(storyId).map(snapcodeId => {
      return getSnapcodeByIdFn(snapcodeId);
    });
  }
);

export const getDecoratedSnapcodesPerStoryId = s(getSnapcodesPerStoryId, getSnapcodesPerStoryIdFn => (storyId: any) => {
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(snapcode: SnapcodeEntity) => Sn... Remove this comment to see the full error message
  return getSnapcodesPerStoryIdFn(storyId).map(createSnapcodeFromSnapcodeEntity(DeeplinkType.EDITION, storyId));
});

// Publisher snapcodes
const getSnapcodeListPerPublisherMap = createKeySelector(getSnapcodeListPerLinkableMap, DeeplinkType.PUBLISHER, {});
export const getSnapcodeListPerPublisherId = createDynamicKeySelector(getSnapcodeListPerPublisherMap, []);

export const getSnapcodesPerPublisherId = s(
  getSnapcodeById,
  getSnapcodeListPerPublisherId,
  (getSnapcodeByIdFn, getSnapcodeListPerPublisherIdFn) => (publisherId: any) => {
    return getSnapcodeListPerPublisherIdFn(publisherId).map(snapcodeId => getSnapcodeByIdFn(snapcodeId));
  }
);

export const getDecoratedSnapcodesPerPublisherId = s(
  getSnapcodesPerPublisherId,
  getSnapcodesPerPublisherIdFn => (publisherId: any) => {
    return getSnapcodesPerPublisherIdFn(publisherId).map(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(snapcode: SnapcodeEntity) => Sn... Remove this comment to see the full error message
      createSnapcodeFromSnapcodeEntity(DeeplinkType.PUBLISHER, publisherId)
    );
  }
);

export const getSnapcodePreviewQuality = s(getSnapcodeData, snapcodeData => {
  return snapcodeData.quality || Quality.HIGH;
});
