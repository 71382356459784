import invariant from 'invariant';
import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { getLastVisitedSnapPublisherUrl } from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import { goBack } from 'state/router/actions/routerActions';
import * as supportTicketsSelectors from 'state/supportTickets/selectors/supportTicketsSelectors';
import { getActivePublisherId } from 'state/user/selectors/userSelectors';

import * as discoverAPI from 'utils/apis/discoverAPI';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import * as grapheneUtils from 'utils/grapheneUtils';
import { redirectToUrl } from 'utils/locationUtils';

import type { PriorityEnum, CategoryEnum } from 'views/supportTickets/SupportTicketsFormOptions';
import { Priority } from 'views/supportTickets/SupportTicketsFormOptions';

import type { GetState } from 'types/redux';

export const AUTH = 'supportTickets/AUTH';
export const CATEGORY = 'supportTickets/CATEGORY';
export const CLEAR_SCREENSHOT = 'supportTickets/CLEAR_SCREENSHOT';
export const DESCRIPTION = 'supportTickets/DESCRIPTION';
export const FLUSH = 'supportTickets/FLUSH';
export const PRIORITY = 'supportTickets/PRIORITY';
export const RAISE_TICKET = 'supportTickets/RAISE_TICKET';
export const SUBJECT = 'supportTickets/SUBJECT';
export const SAVE_SCREENSHOT = 'supportTickets/SAVE_SCREENSHOT';
export const URL = 'supportTickets/URL';
export const setPriority = (priority: PriorityEnum) => ({
  type: PRIORITY,
  payload: { priority },
});
export const setCategory = (category: CategoryEnum) => ({
  type: CATEGORY,
  payload: { category },
});
export const setDescription = (description: string) => ({
  type: DESCRIPTION,
  payload: { description },
});
export const setSubject = (subject: string) => ({
  type: SUBJECT,
  payload: { subject },
});
export const setURL = (url: string) => ({
  type: URL,
  payload: { url },
});
export const flush = () => ({ type: FLUSH });
export const sendTicket = () => (dispatch: Dispatch, getState: GetState) => {
  const screenshot = supportTicketsSelectors.getScreenshot(getState());
  return (
    (dispatch(
      createCallAction(
        {
          type: RAISE_TICKET,
        },
        {
          endpoint: discoverAPI.supportTickets.raiseTicket(),
          method: 'post',
          body: {
            description: supportTicketsSelectors.getDescription(getState()),
            lastSeenPublisherId: getActivePublisherId(getState()),
            lastVisitedSnapPublisherUrl: getLastVisitedSnapPublisherUrl(getState()),
            priority: Priority.NORMAL,
            category: supportTicketsSelectors.getCategory(getState()),
            subject: supportTicketsSelectors.getSubject(getState()),
            url: supportTicketsSelectors.getURL(getState()),
            screenshotBlobBase64: screenshot && (screenshot as any).blobBase64,
          },
        }
      )
    ) as any)
      .then(() => dispatch(flush()))
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
      .then(() => dispatch(goBack()))
      .catch(apiErrorHandler(dispatch, ErrorContexts.SUPPORT_RAISE_TICKET))
  );
};
export const getZendeskJWTToken: any = () =>
  createCallAction(
    {
      type: AUTH,
    },
    {
      endpoint: discoverAPI.supportTickets.auth(),
      method: 'POST',
    }
  );
export const authZendesk = (
  { jwtToken, returnTo }: { jwtToken: string; returnTo?: string },
  zendeskBaseURL: string
): Promise<unknown> => {
  invariant(jwtToken, 'Something went wrong as jwt token is empty');
  let zendeskUrl = `${zendeskBaseURL}/access/jwt?jwt=${jwtToken}`;
  if (returnTo) {
    zendeskUrl = `${zendeskUrl}&return_to=${returnTo}`;
  }
  redirectToUrl(zendeskUrl);
  return Promise.resolve();
};
function sleep(ms: any) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
async function doTakeScreenshot() {
  const displayMedia = await (navigator.mediaDevices as any).getDisplayMedia({
    video: {
      displaySurface: 'browser',
    },
  });
  // allow time for any platform screenshot prompt to disappear
  await sleep(1000);
  const tracks = displayMedia.getVideoTracks();
  const track = tracks[0];
  const capture = new (window as any).ImageCapture(track);
  const frame = await capture.grabFrame();
  const canvas = document.createElement('canvas');
  canvas.width = 1280; // frame.width;
  canvas.height = 720; // frame.height;
  const ctx = canvas.getContext('2d');
  // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
  ctx.drawImage(frame, 0, 0, canvas.width, canvas.height);
  const blob = await new Promise(res => {
    canvas.toBlob(res);
  });
  track.stop();
  return blob;
}
export const takeScreenshot = () => async (dispatch: Dispatch, getState: GetState) => {
  try {
    const blob = await doTakeScreenshot();
    const blobBase64 = await new Promise(res => {
      const reader = new FileReader();
      reader.onload = () => res(reader.result);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
      reader.readAsDataURL(blob);
    });
    dispatch({
      type: SAVE_SCREENSHOT,
      payload: {
        screenshot: {
          blob,
          blobBase64,
        },
      },
    });
  } catch (e) {
    // probably user cancelled the screenshot - we hear about this via a "no perms" error
    grapheneUtils.incrementCounter('takeScreenshotAction', { failed: 'true' });
    dispatch({
      type: CLEAR_SCREENSHOT,
      payload: {
        screenshot: {},
      },
    });
  }
};
export const clearScreenshot = () => ({
  type: CLEAR_SCREENSHOT,
  payload: {
    screenshot: {},
  },
});
