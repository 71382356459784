/**
 * Flattens the supplied list of Images to a single image and returns
 * it as a data URI.
 *
 * The returned image size will be based on the size of the first image
 * in the supplied list. Each subsequent image will be composited on top,
 * and aligned to the centre of the image.
 *
 * @param {Array<Image>} images
 * @returns {string} dataURI
 */
export function flatten(images: any, { mimeType = 'image/png', quality = 0.9 }: any = {}) {
  if (!Array.isArray(images) || images.length < 1) {
    throw new Error('imageCompositor.flatten expects an array containing at least one Image');
  }

  const canvas = document.createElement('canvas');

  // TODO: handle null
  const ctx: any = canvas.getContext('2d');

  const baseImage = images[0];
  canvas.width = baseImage.width;
  canvas.height = baseImage.height;

  images.forEach(image => {
    drawImageInCenter(canvas, ctx, image);
  });

  return canvas.toDataURL(mimeType, quality);
}

function drawImageInCenter(canvas: any, ctx: any, image: any) {
  ctx.drawImage(
    image,
    (canvas.width - image.width) / 2, // x offset (relative to top left of canvas)
    (canvas.height - image.height) / 2, // y offset (relative to top left of canvas)
    image.width, // widthwe could actually probably combine
    image.height // height
  );
}
