import Button from 'antd/lib/button';
import classNames from 'classnames';
import { mapValues } from 'lodash';
import React from 'react';

import activeOnWhiteButtonStyle from 'styles/activeOnWhiteButton.scss';
import linkButtonStyle from 'styles/linkButton.scss';
import primaryButtonStyle from 'styles/primaryButton.scss';
import primaryOnGreyButtonStyle from 'styles/primaryOnGreyButton.scss';
import redButtonStyle from 'styles/redButton.scss';
import secondaryButtonStyle from 'styles/secondaryButton.scss';
import whiteButtonStyle from 'styles/whiteButton.scss';
import whiteOnGreyButtonStyle from 'styles/whiteOnGrey.scss';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';
import { incrementCounter } from 'utils/grapheneUtils';

import Icon from 'views/common/components/Icon/Icon';
import Spinner, { SpinnerSizes } from 'views/common/components/Spinner/Spinner';

import style from './SDSButton.scss';

// view what the types look like here: https://publish.snap-dev.net/v2/debug/buttons/sds
export const ButtonType = enumObject({
  PRIMARY: 'primary', // blue on a white background
  PRIMARY_ON_GREY: 'primaryOnGrey', // blue on a grey background
  SECONDARY: 'secondary', // grey
  WHITE: 'white', // white on a white background
  WHITE_ON_GREY: 'whiteOnGrey', // white on a grey background
  RED_ON_WHITE: 'red',
  ACTIVE_ON_WHITE: 'activeOnWhite', // light blue
  LINK: 'link', // blue text with transparent background
  INLINE_LINK: 'inlineLink', // blue text
  WARNING_RED: 'warningRed',
  WARNING_ORANGE: 'warningOrange',
  WARNING_YELLOW: 'warningYellow',
});

export type ButtonTypeEnum = Enum<typeof ButtonType>;

export const ButtonShape = enumObject({
  CIRCLE: 'circle',
});

export type ButtonShapeEnum = Enum<typeof ButtonShape>;

export const ButtonSize = enumObject({
  SMALL: 'small',
  MEDIUM: 'default',
  LARGE: 'large',
});

export type ButtonSizeEnum = Enum<typeof ButtonSize>;

type OwnProps = {
  className?: string;
  onClick?: (a: Event) => any;
  shape?: ButtonShapeEnum;
  img?: string;
  inlineIcon?: string;
  disabled?: boolean;
  children?: any;
  loading?: boolean;
  metricName?: string;
  metricDimensions?: {
    [key: string]: number | string | boolean;
  };
  'data-test'?: string;
  type?: ButtonTypeEnum;
  block?: boolean; // fills available width,
  size?: ButtonSizeEnum;
};

type Props = OwnProps;

export class SDSButton extends React.PureComponent<Props> {
  static defaultProps = {
    size: ButtonSize.MEDIUM,
  };

  // Don't render content for circle, because spinner will be rendered instead.
  shouldRenderContent = () => {
    if (!this.isCircle()) {
      return true;
    }

    return !this.props.loading;
  };

  isCircle = () => this.props.shape === ButtonShape.CIRCLE;

  handleOnClick = (e: Event) => {
    const { metricName, metricDimensions, onClick } = this.props;
    if (onClick) {
      onClick(e);
    }

    if (metricName) {
      incrementCounter(
        metricName,
        mapValues(metricDimensions, v => String(v))
      );
    }
  };

  renderIcon = () => {
    const { size } = this.props;
    const iconClassName = classNames({
      [style.splitter]: !this.isCircle(),
      [style.small]: size === ButtonSize.SMALL,
      [style.medium]: size === ButtonSize.MEDIUM,
      [style.large]: size === ButtonSize.LARGE,
    });
    return (
      <Icon
        img={this.props.img}
        className={iconClassName}
        inlineIcon={this.props.inlineIcon}
        data-test="SDSButton.Icon"
      />
    );
  };

  renderSpinner = (loading?: boolean) => {
    if (loading) {
      return <Spinner loading className={style.spinner} size={SpinnerSizes.SMALL} data-test="SDSButton.spinner" />;
    }
    return null;
  };

  renderSplitter = (loading?: boolean) => {
    if (loading && this.props.shape !== 'circle') {
      return <div className={style.splitter} />;
    }

    return null;
  };

  render() {
    const { type, size } = this.props;
    const buttonClassNames = classNames(this.props.className, {
      [style.button]: !type,
      [primaryButtonStyle.primaryButton]: type === ButtonType.PRIMARY,
      [primaryOnGreyButtonStyle.primaryOnGreyButton]: type === ButtonType.PRIMARY_ON_GREY,
      [secondaryButtonStyle.secondaryButton]: type === ButtonType.SECONDARY,
      [whiteButtonStyle.whiteButton]: type === ButtonType.WHITE,
      [whiteOnGreyButtonStyle.whiteOnGreyButton]: type === ButtonType.WHITE_ON_GREY,
      [redButtonStyle.redButton]: type === ButtonType.RED_ON_WHITE,
      [activeOnWhiteButtonStyle.activeOnWhiteButton]: type === ButtonType.ACTIVE_ON_WHITE,
      [linkButtonStyle.linkButton]: type === ButtonType.LINK,
      [linkButtonStyle.inlineLinkButton]: type === ButtonType.INLINE_LINK,
      [redButtonStyle.redButton]: type === ButtonType.WARNING_RED,
      [style.orangeButton]: type === ButtonType.WARNING_ORANGE,
      [style.yellowButton]: type === ButtonType.WARNING_YELLOW,
    });

    // antd size prop only sets width and height - we want to set min-width and min-height to force the correct size
    let minSize = 24;
    if (size === ButtonSize.MEDIUM) {
      minSize = 32;
    } else if (size === ButtonSize.LARGE) {
      minSize = 40;
    }

    return (
      <Button
        style={{ minWidth: minSize, minHeight: minSize }}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        shape={this.props.shape}
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '"small" |... Remove this comment to see the full error message
        size={this.props.size}
        block={this.props.block}
        className={buttonClassNames}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '(e: Event) => void' is not assignable to typ... Remove this comment to see the full error message
        onClick={this.handleOnClick}
        disabled={this.props.disabled || this.props.loading}
        data-test={this.props['data-test'] ? `${this.props['data-test']}.SDSButton` : 'SDSButton'}
      >
        {this.renderSpinner(this.props.loading)}
        {this.renderSplitter(this.props.loading)}
        {this.shouldRenderContent() && this.renderIcon()}
        {this.shouldRenderContent() && this.props.children}
      </Button>
    );
  }
}

export default SDSButton;
