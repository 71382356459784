// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'deno... Remove this comment to see the full error message
import { denormalize } from 'denormalizr';
import { createSelector as s } from 'reselect';

import { createDynamicKeySelector, reselectById } from 'state/common/selectorFactories';
import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import * as extrasSelectors from 'state/extras/selectors/extrasSelectors';
import { isShowsSchedulingV2Enabled } from 'state/features/selectors/featuresSelectors';
import * as publisherStoryEditorSelectors from 'state/publisherStoryEditor/selectors/publisherStoryEditorSelectors';
import * as seasonsSelectors from 'state/seasons/selectors/seasonsSelectors';
import { showSchema } from 'state/shows/schema/showsSchema';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { functionRef } from 'utils/functionUtils';

import { LiveEditStatus, StoryState } from 'types/editions';
import { Claim } from 'types/permissions';
import type { PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';
import type { Show, ShowsMap, ShowID } from 'types/shows';

export const getShowsData = (state: State): ShowsMap => state.shows;

export const getShowsMap = createDynamicKeySelector<Show[] | undefined | null, PublisherID>(getShowsData, null);

export const getShows = s(
  getShowsMap,
  seasonsSelectors.getSeasonMap,
  episodesSelectors.getEpisodeMap,
  extrasSelectors.getExtrasMap,
  (getShowsMapFn, seasonMap, episodeMap, extrasMap) => (publisherId: any) => {
    if (!publisherId) {
      return [];
    }

    const showsByPublisher = getShowsMapFn(publisherId);
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return Object.values(showsByPublisher).map(show => {
      return denormalize(show, { season: seasonMap, episode: episodeMap, extra: extrasMap }, showSchema);
    });
  }
);

export const getShowById = reselectById<Show | undefined | null, ShowID>(
  null,
  getShowsMap,
  seasonsSelectors.getSeasonMap,
  episodesSelectors.getEpisodeMap,
  extrasSelectors.getExtrasMap,
  functionRef(userSelectors, 'getActivePublisherId'),
  (state: any, showId: any) => showId,
  (getShowsMapFn: any, seasonMap: any, episodeMap: any, extrasMap: any, activePublisherId: any, showId: any) => {
    const showsMap = getShowsMapFn(activePublisherId);
    if (!showsMap) {
      return null;
    }

    const show = showsMap[showId];
    if (!show) {
      return null;
    }

    return denormalize(show, { season: seasonMap, episode: episodeMap, extra: extrasMap }, showSchema);
  }
);

export const isAutomaticEpisodeAllocationEnabled = (state: State) => {
  return isShowsSchedulingV2Enabled(state);
};

export const canEditEpisodeMetadata = (state: State) => {
  const isSuperAdmin = userSelectors.hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER);
  const isShowsSchedulingV2 = isShowsSchedulingV2Enabled(state);
  const activeStory = publisherStoryEditorSelectors.getActiveEdition(state);
  const episodeIsEditable =
    activeStory?.state === StoryState.SCHEDULED ||
    (activeStory?.state === StoryState.LIVE && activeStory?.liveEditStatus === LiveEditStatus.IN_PROGRESS);

  return isSuperAdmin && isShowsSchedulingV2 && episodeIsEditable;
};
