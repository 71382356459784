import { BroadcastChannel } from 'broadcast-channel';
import React from 'react';
import { RouteComponentProps } from 'react-router';

import { withRouter } from 'utils/routerUtils';

import { USERNAME_AUTH_BROADCAST_CHANNEL } from 'views/onboarding/components/UserAssociationView/UserAssociationConstants';

type Props = RouteComponentProps;

export class UserAssociationReceiverView extends React.Component<Props> {
  sendToken() {
    const query = new URLSearchParams(this.props.location.search);
    // Broadcast channel for sending username auth token from accounts to the user association view in the parent window
    // Docs: https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
    const bc = new BroadcastChannel(USERNAME_AUTH_BROADCAST_CHANNEL);
    return bc.postMessage(query.get('ticket'));
  }

  async componentDidMount() {
    await this.sendToken();
    window.close();
  }

  render() {
    return null;
  }
}

export default withRouter(UserAssociationReceiverView);
