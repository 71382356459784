import _ from 'lodash';
import { combineReducers } from 'redux';

import {
  createRemoverByIdReducer,
  createClearingReducer,
  createSequenceHandlingReducer,
  serializeReducers,
} from 'state/common/reducerFactories';

import * as buildStatusActions from '../actions/buildStatusActions';

import { Sequence, EMPTY_OBJECT } from 'config/constants';
import { SnapId } from 'src/types/common';
import u from 'utils/safeUpdeep';

import { CREATE_SNAP, DELETE_SNAP } from 'types/actions/snapsActionsTypes';
import { SnapProblem } from 'types/build';

const removeSnapById = createRemoverByIdReducer('snapId', DELETE_SNAP);

const editionsById = createSequenceHandlingReducer(
  {},
  [buildStatusActions.FETCH_EDITION_BUILD_STATUS, buildStatusActions.FETCH_EDITION_BUILD_STATUS_MULTIPLE],
  {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      const editionsResult = _.get(action, ['payload', 'entities', 'editions']);
      if (action.error || !editionsResult) {
        return state;
      }

      return u(
        _.mapValues(editionsResult, entity => u.ifDiff(entity)),
        state
      );
    },
  }
);

const editionTimestamp = createSequenceHandlingReducer(
  {},
  [buildStatusActions.FETCH_EDITION_BUILD_STATUS, buildStatusActions.FETCH_EDITION_BUILD_STATUS_MULTIPLE],
  {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      const { editionIds } = action.params;
      const timestamp = _.get(action, 'meta.timestamp', state);
      return u(_.zipObject(editionIds, _.fill(new Array(editionIds.length), timestamp)), state);
    },
  }
);

const fetchEditionsLoadingById = createSequenceHandlingReducer(
  {},
  [buildStatusActions.FETCH_EDITION_BUILD_STATUS, buildStatusActions.FETCH_EDITION_BUILD_STATUS_MULTIPLE],
  {
    start(state: any, action: any) {
      const { editionIds } = action.params;

      return u(_.zipObject(editionIds, _.fill(new Array(editionIds.length), true)), state);
    },

    done(state: any, action: any) {
      return u(u.omit(action.params.editionIds), state);
    },
  }
);

const clearEditionsLoadingById = createClearingReducer(
  EMPTY_OBJECT,
  buildStatusActions.RESET_LOADING_EDITION_BUILD_STATUS
);

const editionsLoadingById = serializeReducers([fetchEditionsLoadingById, clearEditionsLoadingById]);

const editions = combineReducers({
  byId: editionsById,
  loadingById: editionsLoadingById,
  ageById: editionTimestamp,
});

const discoverSnapsFetchBuildStatus = createSequenceHandlingReducer(
  {},
  [buildStatusActions.FETCH_EDITION_BUILD_STATUS, buildStatusActions.FETCH_EDITION_BUILD_STATUS_MULTIPLE],
  {
    start(state: any) {
      return state;
    },

    done(state: any, action: any) {
      const discoverSnaps = _.get(action, ['payload', 'entities', 'discoverSnaps']);

      if (action.error || !discoverSnaps) {
        return state;
      }
      return u(
        _.mapValues(discoverSnaps, entity => u.ifDiff(entity)),
        state
      );
    },
  }
);

const snapBuildStatusPlaceholder = ({
  snapId,
  status = SnapProblem.INCOMPLETE,
}: {
  snapId: SnapId;
  status: SnapProblem;
}) => {
  return {
    [snapId]: u.ifDiff({
      id: snapId,
      status,
    }),
  };
};

// When creating/duplicating a snap, backfill the snapBuildStatus as incomplete
const createSnapPlaceholder = (state = {}, action: any) => {
  if (action.error) {
    return state;
  }

  switch (action.type) {
    case CREATE_SNAP: {
      if (action.sequence === Sequence.DONE) {
        const snapsByIdArray = _.get(action, ['payload', 'entities', 'richSnap']);
        if (!snapsByIdArray || Object.keys(snapsByIdArray).length !== 1) {
          return state;
        }

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return u(snapBuildStatusPlaceholder({ snapId: parseInt(Object.keys(snapsByIdArray)[0], 10) }), state);
      }

      return state;
    }
    case buildStatusActions.ADD_SNAP_BUILD_STATUS_PLACEHOLDER: {
      const { snapId, status } = action.payload;
      return u(snapBuildStatusPlaceholder({ snapId, status }), state);
    }
    default:
      return state;
  }
};

const discoverSnaps = serializeReducers([removeSnapById, discoverSnapsFetchBuildStatus, createSnapPlaceholder]);

const segments = createSequenceHandlingReducer(
  {},
  [buildStatusActions.FETCH_EDITION_BUILD_STATUS, buildStatusActions.FETCH_EDITION_BUILD_STATUS_MULTIPLE],
  {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      const segmentsResult = _.get(action, ['payload', 'entities', 'segments']);
      if (action.error || !segmentsResult) {
        return state;
      }

      return u(
        _.mapValues(segmentsResult, entity => u.ifDiff(entity)),
        state
      );
    },
  }
);

const tiles = createSequenceHandlingReducer(
  {},
  [buildStatusActions.FETCH_EDITION_BUILD_STATUS, buildStatusActions.FETCH_EDITION_BUILD_STATUS_MULTIPLE],
  {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      const tileResults = _.get(action, 'payload.entities.tiles', []);

      if (action.error || !tiles) {
        return state;
      }

      return u(
        _.mapValues(tileResults, entity => u.ifDiff(entity)),
        state
      );
    },
  }
);

export default combineReducers({
  discoverSnaps,
  editions,
  segments,
  tiles,
});
