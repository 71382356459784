/* eslint-disable camelcase */
import { isNaN } from 'lodash';
import { ResultObject, Track } from 'mediainfo.js/dist/types';

import { IMAGE_DURATION_SECS } from 'config/constants';
import { getEnumValueFromString } from 'utils/enum';

const safeParseFloat = (str: string | undefined): number | undefined => {
  const result = str === undefined ? undefined : parseFloat(str);
  return isNaN(result) ? undefined : result;
};

export enum VideoFrameRateMode {
  VFR = 'VFR',
  CFR = 'CFR',
}

export enum CodecType {
  Audio = 'audio',
  Video = 'video',
}

export enum AudioType {
  /* Audio stream embedded in the video */
  Embedded = 'Embedded',
  /* Audio stream attached separately */
  Attached = 'Attached',
  /* No audio stream found */
  None = 'None',
}

export enum VideoQuality {
  UHD = 'UHD',
  HD = 'HD',
  SD = 'SD',
}

export type VideoSoundInfo = {
  hasSound: boolean;
  audioType: AudioType;
};

// Original Track type does not include all fields so we must define new ones and cast
type GeneralTrack = Track & {
  Duration?: string;
  FileSize?: string;
  VideoCount?: string;
  ImageCount?: string;
  Encoded_Date?: string;
  Format?: string;
  Format_Profile?: string;
  FrameRate?: string;
  IsStreamable?: string;
  Encoded_Library?: string;
  Encoded_Library_Name?: string;
};
type VideoTrack = Track & {
  Width?: string;
  Height?: string;
  Format?: string;
  BitRate?: string;
  PixelAspectRatio?: string;
  Rotation?: string;
  CodecID?: string;
  FrameRate_Mode?: string;
};
type ImageTrack = Track & {
  Width?: string;
  Height?: string;
  Format?: string;
  PixelAspectRatio?: string;
  CodecID?: string;
};
type AudioTrack = Track & { Format?: string; ChannelLayout?: string; Origin?: string; CodecID?: string };

// Wraps and parses info returned by media info lib
export class MediaMetadata {
  info: ResultObject;

  encodingDate: Date | undefined;

  constructor(info: ResultObject) {
    this.info = info;
  }

  generalTrack(): GeneralTrack | undefined {
    return this.getTracksByType('General')[0] as GeneralTrack | undefined;
  }

  videoTrack(): VideoTrack | undefined {
    return this.getTracksByType('Video')[0] as VideoTrack | undefined;
  }

  imageTrack(): ImageTrack | undefined {
    return this.getTracksByType('Image')[0] as ImageTrack | undefined;
  }

  audioTrack(): AudioTrack | undefined {
    return this.getTracksByType('Audio')[0] as AudioTrack | undefined;
  }

  getTracksByType(type: Track['@type']): Track[] {
    return this.info.media.track.filter(track => track['@type'] === type);
  }

  getEncodingDate(): Date | undefined {
    if (this.encodingDate) {
      return this.encodingDate;
    }

    const track = this.generalTrack();
    if (!track?.Encoded_Date) {
      return undefined;
    }

    const date = new Date(track?.Encoded_Date);
    if (isNaN(date.getTime())) {
      return undefined;
    }
    this.encodingDate = date;
    return date;
  }

  // If the media is a video, we can get the video duration. If media is an image we pass the standard duration for an image (10).
  // If media is not video or image we pass undefined.
  getDurationSeconds(): number | undefined {
    if (this.getVideoCount()) {
      return safeParseFloat(this.generalTrack()?.Duration);
    }
    if (this.getImageCount()) {
      return IMAGE_DURATION_SECS;
    }
    return undefined;
  }

  getFileSizeBytes(): number | undefined {
    return safeParseFloat(this.generalTrack()?.FileSize);
  }

  getVideoCount(): number | undefined {
    return safeParseFloat(this.generalTrack()?.VideoCount);
  }

  getImageCount(): number | undefined {
    return safeParseFloat(this.generalTrack()?.ImageCount);
  }

  getVideoWidth(): number | undefined {
    return safeParseFloat(this.videoTrack()?.Width);
  }

  getImageWidth(): number | undefined {
    return safeParseFloat(this.imageTrack()?.Width);
  }

  getMediaWidth(): number | undefined {
    if (this.getVideoCount()) {
      return safeParseFloat(this.videoTrack()?.Width);
    }
    if (this.getImageCount()) {
      return safeParseFloat(this.imageTrack()?.Width);
    }
    return undefined;
  }

  getVideoHeight(): number | undefined {
    return safeParseFloat(this.videoTrack()?.Height);
  }

  getImageHeight(): number | undefined {
    return safeParseFloat(this.imageTrack()?.Height);
  }

  getMediaHeight(): number | undefined {
    if (this.getVideoCount()) {
      return safeParseFloat(this.videoTrack()?.Height);
    }
    if (this.getImageCount()) {
      return safeParseFloat(this.imageTrack()?.Height);
    }
    return undefined;
  }

  getHasSound(): boolean {
    return this.audioTrack() != null;
  }

  getVideoBitRate() {
    return safeParseFloat(this.videoTrack()?.BitRate!);
  }

  getVideoQuality() {
    const width = parseInt(this.videoTrack()?.Width!, 10);
    const height = parseInt(this.videoTrack()?.Width!, 10);
    if (width >= 3840 && height >= 2160) {
      return VideoQuality.UHD;
    }
    if (width >= 1280 && height >= 720) {
      return VideoQuality.HD;
    }
    return VideoQuality.SD;
  }

  getVideoFormat(): string | undefined {
    return this.videoTrack()?.Format;
  }

  getImageFormat(): string | undefined {
    return this.imageTrack()?.Format;
  }

  getGeneralFormat(): string | undefined {
    return this.generalTrack()?.Format;
  }

  getGeneralFormatProfile(): string | undefined {
    return this.generalTrack()?.Format_Profile;
  }

  getIsStreamable(): string | undefined {
    return this.generalTrack()?.IsStreamable;
  }

  getEncodingLibrary(): string | undefined {
    return this.generalTrack()?.Encoded_Library;
  }

  getEncodingLibraryName(): string | undefined {
    return this.generalTrack()?.Encoded_Library_Name;
  }

  getVideoBitrate(): number | undefined {
    return safeParseFloat(this.videoTrack()?.BitRate);
  }

  getVideoPixelAspectRatio(): number | undefined {
    return safeParseFloat(this.videoTrack()?.PixelAspectRatio);
  }

  getImagePixelAspectRatio(): number | undefined {
    return safeParseFloat(this.imageTrack()?.PixelAspectRatio);
  }

  getVideoRotation(): number | undefined {
    return safeParseFloat(this.videoTrack()?.Rotation);
  }

  getFramerate(): number | undefined {
    return safeParseFloat(this.generalTrack()?.FrameRate);
  }

  getFrameRateMode(): VideoFrameRateMode | undefined {
    return getEnumValueFromString(VideoFrameRateMode, this.videoTrack()?.FrameRate_Mode);
  }

  getVideoCodec(): string | undefined {
    return this.videoTrack()?.CodecID;
  }

  getAudioCodec(): string | undefined {
    return this.audioTrack()?.CodecID;
  }

  getImageCodec(): string | undefined {
    return this.imageTrack()?.CodecID;
  }
}
