import { createSelector as s } from 'reselect';

import {
  isFirstSnapInEdition,
  findSegmentForSnap,
  findSnapIndexInSegment,
} from 'state/editions/schema/editionEntityHelpers';
import { getEditionById, getTilesByEditionId } from 'state/editions/selectors/editionsSelectors';
import {
  getActiveEdition,
  getActiveEditionId,
  getActiveSegment,
  getActiveSegmentReduxId,
  getActiveTopsnap,
  getActiveWholeSnapId,
  getEditor,
  isReadOnly,
} from 'state/editor/selectors/editorSelectors';
import { getTilesBySegmentId } from 'state/segments/selectors/segmentsSelectors';
import {
  getAllBottomSnaps,
  getNextBottomSnap,
  isBottomSnap,
  isSubscribeSnap,
} from 'state/snaps/schema/snapEntityHelpers';
import { getSnapById, getTilesByWholeSnapId } from 'state/snaps/selectors/snapsSelectors';

import {
  RichSnapComponentType,
  RichSnapActiveComponentType,
  TileButtonRenderType,
  EMPTY_ARRAY,
  MAXIMUM_TILE_COUNT,
  MINIMUM_TILE_COUNT,
} from 'config/constants';
import {
  extractSnapIdFromComponentId,
  buildComponentId,
  buildComponentIdForSnap,
  buildComponentIdForSnapId,
  buildComponentIdForTile,
  isTileComponentId,
  bottomHasSnapNoAttachmentsCheck,
  isSubtitleComponentId,
} from 'utils/componentUtils';
import * as sentryServerLogging from 'utils/logging/sentryServerLoggingUtil';

import { SnapId } from 'types/common';
import {
  isComponentSnap,
  isComponentTile,
  isComponentTilePlaceholder,
  RichSnapComponent,
  RichSnapComponentId,
} from 'types/components';
import { EditionID } from 'types/editionID';
import { StoryState } from 'types/editions';
import { State } from 'types/rootState';
import { SegmentID } from 'types/segments';

export const TILE_PLACEHOLDER_COMPONENT_ID = buildComponentId(RichSnapComponentType.TILE_PLACEHOLDER, 0);
export const HEADLINE_TILE_PLACEHOLDER_COMPONENT_ID = buildComponentId(
  RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER,
  0
);
export const SNAP_PLACEHOLDER_COMPONENT_ID = buildComponentId(RichSnapComponentType.SNAP_PLACEHOLDER, 0);
export const SUBTITLES_COMPONENT_ID = buildComponentId(RichSnapComponentType.SUBTITLES, 0);
const getComponentsForSnapId = (state: State) => (
  snapId: SnapId,
  editionId: EditionID,
  segmentId: SegmentID | null
) => {
  const snap = getSnapById(state)(snapId);
  const editionTiles = getTilesByEditionId(state)(editionId);
  const activeEdition = getEditionById(state)(editionId);
  const snapTiles = getTilesByWholeSnapId(state)(snapId);
  const segmentTiles = segmentId ? getTilesBySegmentId(state)(segmentId) : null;
  if (!snap) {
    return EMPTY_ARRAY;
  }
  const bottomSnaps = getAllBottomSnaps(snap);
  const components: RichSnapComponent[] = [snap, ...bottomSnaps].map(snapItem => ({
    componentId: buildComponentIdForSnap(snapItem),
    componentType: RichSnapComponentType.SNAP,
    snap: snapItem,
  }));
  if (activeEdition) {
    // The archived editions of multiTile publishers cannot be migrated via the automated script
    // as they are in a locked state when the page is open
    // Manually take the tiles from the snap
    let tiles = snapTiles;
    if (!tiles && activeEdition.state === StoryState.ARCHIVED && isFirstSnapInEdition(activeEdition, snap.id)) {
      tiles = editionTiles;
    }
    const segment = findSegmentForSnap(activeEdition, snap.id);
    if (segment) {
      if (findSnapIndexInSegment(segment, snap.id) === 0) {
        tiles = segmentTiles;
      } else {
        tiles = null;
      }
    }
    if (tiles && tiles.length > 0) {
      const tileComponents = tiles.map(tile => ({
        componentId: buildComponentIdForTile(tile),
        componentType: RichSnapComponentType.TILE,
        tile,
      }));
      components.unshift(...tileComponents);
      // keep the one buff if there is less than MAXIMUM_TILE_COUNT tiles, in order to add variant
      if (tiles.length < MAXIMUM_TILE_COUNT) {
        const tileSinglePlaceholderComponent = {
          componentId: TILE_PLACEHOLDER_COMPONENT_ID,
          componentType: RichSnapComponentType.TILE_PLACEHOLDER,
        };
        const headlineTilePlaceholderComponent = {
          componentId: HEADLINE_TILE_PLACEHOLDER_COMPONENT_ID,
          componentType: RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER,
        };
        components.unshift(tileSinglePlaceholderComponent);
        components.unshift(headlineTilePlaceholderComponent);
      }
    } else {
      for (let i = 0; i < MINIMUM_TILE_COUNT; i++) {
        const tilePlaceholderComponent = {
          componentId: buildComponentId(RichSnapComponentType.TILE_PLACEHOLDER, i),
          componentType: RichSnapComponentType.TILE_PLACEHOLDER,
        };
        const headlineTilePlaceholderComponent = {
          componentId: buildComponentId(RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER, i),
          componentType: RichSnapComponentType.HEADLINE_TILE_PLACEHOLDER,
        };
        components.unshift(tilePlaceholderComponent);
        components.unshift(headlineTilePlaceholderComponent);
      }
    }
  }
  components.push({
    componentId: SNAP_PLACEHOLDER_COMPONENT_ID,
    componentType: RichSnapComponentType.SNAP_PLACEHOLDER,
  });
  components.push({
    componentId: SUBTITLES_COMPONENT_ID,
    componentType: RichSnapComponentType.SUBTITLES,
  });
  return components;
};
export const getComponentsForActiveSnap = (state: State) => {
  const activeTopsnapId = getActiveWholeSnapId(state);
  const activeSegmentId = getActiveSegmentReduxId(state);
  if (!activeTopsnapId) {
    return EMPTY_ARRAY;
  }
  return getComponentsForSnapId(state)(activeTopsnapId, getActiveEditionId(state), activeSegmentId);
};
export const getActiveComponentId = s(getEditor, editor => {
  return editor.activeComponentId;
});

export const getComponentByIdForActiveSnap = s(getComponentsForActiveSnap, components => {
  return (componentId: RichSnapComponentId) => {
    return (components.find(component => component.componentId === componentId) as RichSnapComponent) || null;
  };
});

// createComponentTypeSelector creates a selector that filters the snapComponents passed from parentSelector
// and selects the component with matching compontent type.
function createComponentTypeSelector(
  parentSelector: (state: State) => RichSnapComponent[],
  componentType: RichSnapComponentType
) {
  return s(parentSelector, components => {
    return components ? components.filter(component => component.componentType === componentType) : EMPTY_ARRAY;
  });
}

export const getComponentsOfTypeSnap = s(
  createComponentTypeSelector(getComponentsForActiveSnap, RichSnapComponentType.SNAP),
  // This will return true on all items but checking here enforces proper strict typing.
  components => components.filter(isComponentSnap)
);
export const getComponentsOfTypeTile = s(
  createComponentTypeSelector(getComponentsForActiveSnap, RichSnapComponentType.TILE),
  // This will return true on all items but checking here enforces proper strict typing.
  components => components.filter(isComponentTile)
);
export const getComponentsOfTypeTilePlaceholder = s(
  createComponentTypeSelector(getComponentsForActiveSnap, RichSnapComponentType.TILE_PLACEHOLDER),
  // This will return true on all items but checking here enforces proper strict typing.
  components => components.filter(isComponentTilePlaceholder)
);
export const getComponentBySnapId = s(getComponentsForActiveSnap, components => (snapId: SnapId) => {
  return (
    components.find(
      component =>
        (component as any).componentType === RichSnapComponentType.SNAP &&
        (component as any).componentId === buildComponentIdForSnapId(snapId)
    ) || null
  );
});
export const getActiveComponent = s(
  getActiveComponentId,
  getComponentByIdForActiveSnap,
  (activeComponentId, getComponentByIdFn) => {
    if (!activeComponentId) {
      return null;
    }
    return getComponentByIdFn(activeComponentId);
  }
);
export const getActiveComponentType = s(getActiveComponentId, getSnapById, (activeComponentId, getSnapByIdFn) => {
  if (!activeComponentId) {
    return null;
  }

  if (isTileComponentId(activeComponentId)) {
    return RichSnapActiveComponentType.TILE;
  }
  if (isSubtitleComponentId(activeComponentId)) {
    return RichSnapActiveComponentType.SUBTITLES;
  }
  const snapId = extractSnapIdFromComponentId(activeComponentId);
  const snap = (getSnapByIdFn as any)(snapId);
  const bottomSnapCheck = snap ? isBottomSnap(snap) : null;
  if (bottomSnapCheck || bottomHasSnapNoAttachmentsCheck(snapId)) {
    return RichSnapActiveComponentType.ATTACHMENT;
  }
  return RichSnapActiveComponentType.SNAP;
});
export const getActiveComponentOrFirstSnapComponent = s(
  getActiveComponent,
  getComponentsOfTypeSnap,
  (activeComponent, componentsOfTypeSnap) => {
    if (activeComponent) {
      return activeComponent;
    }
    // If no component matches the activeComponentId, fall back to selecting the first snap component by default
    return componentsOfTypeSnap.length > 0 ? componentsOfTypeSnap[0] : null;
  }
);
export const getBottomsnapComponentForActiveComponent = s(
  getActiveComponent,
  getComponentBySnapId,
  (activeComponent, getComponentBySnapIdFn) => {
    const topsnap = activeComponent && isComponentSnap(activeComponent) ? activeComponent.snap : null;
    const bottomsnap = topsnap && getNextBottomSnap(topsnap);
    return bottomsnap && getComponentBySnapIdFn(bottomsnap.id);
  }
);
export const getTileButtonRenderType = (state: State) => {
  const activeTopsnap = getActiveTopsnap(state);
  const componentsOfTypeTile = getComponentsOfTypeTile(state);
  const activeEdition = getActiveEdition(state);
  const activeComponent = getActiveComponent(state);
  const readOnly = isReadOnly(state);
  const activeSegment = getActiveSegment(state);
  if (!activeTopsnap) {
    return TileButtonRenderType.NONE;
  }
  // all related tiles
  const tiles = componentsOfTypeTile;
  // is the topsnap a subscribe snap?
  const subscribeSnap = activeTopsnap ? isSubscribeSnap(activeTopsnap) : false;
  // is topsnap the first snap?
  const isFirstSnap = activeTopsnap && activeEdition ? isFirstSnapInEdition(activeEdition, activeTopsnap.id) : false;
  // should we display a blank tile?
  const displayBlank = tiles.length === 0;
  // are we currently editing a "tile placeholder"
  const tilePlaceholderIsActive =
    activeComponent && activeComponent.componentType === RichSnapComponentType.TILE_PLACEHOLDER && tiles.length === 0;
  const indexInSegment = findSnapIndexInSegment(activeSegment, activeTopsnap.id);
  const nonFirstSnapInSegment = indexInSegment !== null && indexInSegment !== undefined && indexInSegment > 0;
  if (nonFirstSnapInSegment) {
    return TileButtonRenderType.NOT_ALLOWED;
  }
  // should we display a button with an image?
  if ((displayBlank || subscribeSnap) && !tilePlaceholderIsActive) {
    // is this NOT a subscribe snap?
    if (!subscribeSnap) {
      if (readOnly) {
        return TileButtonRenderType.NONE;
      }
      return TileButtonRenderType.BLANK;
    }
    return TileButtonRenderType.NOT_ALLOWED;
  }
  if (tiles.length > 0) {
    // is this feature enabled for this publisher?
    if (!isFirstSnap) {
      return TileButtonRenderType.SINGLE_TILE;
    }
    return TileButtonRenderType.TILE_WITH_VARIANTS;
  }
  return TileButtonRenderType.PLACEHOLDER;
};
sentryServerLogging.setProvider('activeComponentId', getActiveComponentId);
