import { SnapType } from 'types/snaps';
import type { BitmojiVideoSnap } from 'types/snaps';
import type { Stage } from 'types/stages';

type BitmojiVideoStage = Stage<BitmojiVideoSnap, Partial<BitmojiVideoSnap>, void>;

export const BITMOJI_REMOTE_VIDEO_STAGE_CONFIG: BitmojiVideoStage = {
  stageName: SnapType.BITMOJI_REMOTE_VIDEO,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: ['remoteVideoId'],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => properties,
};
