import { createHttpLink } from '@apollo/client';

import { ADS_GATEWAY_GRAPHQL_BASE_URL, SSAPI_PROXY_GRAPHQL_BASE_URL } from 'config/constants';

const options = {
  fetch,
  // https://www.apollographql.com/docs/react/networking/authentication/#cookie
  credentials: 'include',
};

export const storyStudioProxyGrapqhlFetchLink = createHttpLink({
  uri: SSAPI_PROXY_GRAPHQL_BASE_URL,
  ...options,
});

export const adsGatewayGraphqlFetchLink = createHttpLink({
  uri: ADS_GATEWAY_GRAPHQL_BASE_URL,
  ...options,
});
