import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { setPublisherDetailsWithGraphQLResponse } from 'state/publishers/actions/publishersActions';

import { UIFeature } from '../featuresAdminState';

import { ROOT_GET_PUBLISHER } from 'gql/queries/publisher/rootPublisherQuery';
import * as discoverAPI from 'utils/apis/discoverAPI';
import { fetchPublisherDataWithApolloQuery } from 'utils/apis/graphQLUtils';
import { assertState } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { clearInfoMessage, InfoContext, infoMessageHandler } from 'utils/errors/infoMessage/infoMessageUtils';

import { FeatureFlag } from 'types/featureFlags';
import { FeatureBulkUpdateModel } from 'types/features';
import type { PublisherID } from 'types/publishers';
import type { Dispatch, GetState } from 'types/redux';

export const CREATE_FEATURE = 'features/CREATE_FEATURE';
export const LOAD_FEATURES = 'features/LOAD_FEATURES';
export const LOAD_ALL_FEATURES = 'features/LOAD_ALL_FEATURES';
export const SAVE_FEATURE = 'features/SAVE_FEATURE';
export const ENABLE_FOR_ALL = 'features/ENABLE_FOR_ALL';
export const DISABLE_FOR_ALL = 'features/DISABLE_FOR_ALL';
export const ADD_TO_TIER = 'features/ADD_TO_TIER';
export const REMOVE_FROM_TIER = 'features/REMOVE_FROM_TIER';
export const BULK_UPDATE = 'features/BULK_UPDATE';
export function loadFeaturesForPublisher(businessProfileId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    return dispatch(
      createCallAction(
        {
          type: LOAD_FEATURES,
        },
        {
          endpoint: discoverAPI.features.fullListByPublisher({
            businessProfileId,
          }),
        }
      )
    );
  };
}
export function loadAllFeatures() {
  return (dispatch: Dispatch, getState: GetState) => {
    return dispatch(
      createCallAction(
        {
          type: LOAD_ALL_FEATURES,
        },
        {
          endpoint: discoverAPI.features.fullList(),
        }
      )
    );
  };
}
export function saveFeatureForPublisher(businessProfileId: string, feature: string, enable: boolean) {
  return (dispatch: Dispatch, getState: GetState) => {
    return dispatch(
      createCallAction(
        {
          type: SAVE_FEATURE,
        },
        {
          method: 'post',
          endpoint: discoverAPI.features.set({ businessProfileId, feature, enable }),
        }
      )
    );
  };
}
function handleSASrelatedFeatureFlags(
  features: Array<{
    feature: string;
    enable: boolean;
  }>,
  currentFeatures: Array<UIFeature>
): Array<{
  feature: string;
  enable: boolean;
}> {
  const featuresToChange = features;
  const singleAssetFeatureIndex = featuresToChange?.findIndex(o => o.feature === FeatureFlag.SINGLE_ASSET_STORY_EDITOR);
  const generateSubsFeatureIndex = featuresToChange?.findIndex(
    o => o.feature === FeatureFlag.GENERATE_SUBTITLES_ON_UPLOAD
  );

  if (featuresToChange?.[singleAssetFeatureIndex]?.enable) {
    // Enable generate subtitles
    if (generateSubsFeatureIndex === -1) {
      featuresToChange.push({ feature: FeatureFlag.GENERATE_SUBTITLES_ON_UPLOAD, enable: true });
    }
  }

  return featuresToChange;
}
export function saveAllFeaturesForPublisher(
  publisherId: PublisherID,
  businessProfileId: string,
  featureChanges: Array<{
    feature: string;
    enable: boolean;
  }>,
  currentFeatures: Array<UIFeature>
): (b: Dispatch, a: GetState) => Promise<unknown> {
  const featuresToChange = handleSASrelatedFeatureFlags(featureChanges, currentFeatures);
  return (dispatch: Dispatch, getState: GetState) => {
    function saveAll() {
      return Promise.all(
        featuresToChange.map(featureChange =>
          dispatch(saveFeatureForPublisher(businessProfileId, featureChange.feature, featureChange.enable))
        )
      );
    }
    const graphQLQuery = ROOT_GET_PUBLISHER;
    infoMessageHandler(dispatch, InfoContext.SAVING);
    return dispatch(() => saveAll())
      .then(() => dispatch(loadFeaturesForPublisher(businessProfileId)))
      .then(() => fetchPublisherDataWithApolloQuery(graphQLQuery, String(publisherId)))
      .then(response => {
        if (response == null || 'message' in response) {
          return apiErrorHandler(dispatch, ErrorContexts.SAVE_ONE_PUBLISHER_DATA);
        }
        return dispatch(setPublisherDetailsWithGraphQLResponse(response));
      })
      .then(
        clearInfoMessage(getState, dispatch, InfoContext.SAVING),
        clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
      );
  };
}
export const createFeature = ({ featureId, description }: { featureId: string; description: string }) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(featureId && featureId.length > 0).is.truthy();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(description && description.length > 0).is.truthy();
  return dispatch(
    createCallAction(
      {
        type: CREATE_FEATURE,
      },
      {
        method: 'PUT',
        endpoint: discoverAPI.features.createFeature({ featureId }),
        body: {
          description,
        },
      }
    )
  );
};
export function enableFeatureForAll(featureId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    return (dispatch(
      createCallAction(
        {
          type: ENABLE_FOR_ALL,
        },
        {
          endpoint: discoverAPI.features.enableForAll({ featureId }),
          method: 'POST',
        }
      )
    ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.FEATURE_FLAGS));
  };
}
export function disableFeatureForAll(featureId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    return (dispatch(
      createCallAction(
        {
          type: DISABLE_FOR_ALL,
        },
        {
          endpoint: discoverAPI.features.disableForAll({ featureId }),
          method: 'POST',
        }
      )
    ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.FEATURE_FLAGS));
  };
}
export function addFeatureToTier(featureId: string, tierId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    return (dispatch(
      createCallAction(
        {
          type: ADD_TO_TIER,
        },
        {
          endpoint: discoverAPI.features.addToTier({ featureId, tierId }),
          method: 'PUT',
        }
      )
    ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.FEATURE_FLAGS));
  };
}
export function removeFeatureFromTier(featureId: string, tierId: string) {
  return (dispatch: Dispatch, getState: GetState) => {
    return (dispatch(
      createCallAction(
        {
          type: REMOVE_FROM_TIER,
        },
        {
          endpoint: discoverAPI.features.removeFromTier({ featureId, tierId }),
          method: 'PUT',
        }
      )
    ) as any).catch(apiErrorHandler(dispatch, ErrorContexts.FEATURE_FLAGS));
  };
}

export function bulkUpdate(featureId: string, model: FeatureBulkUpdateModel, enable: boolean) {
  return (dispatch: Dispatch, getState: GetState) => {
    return dispatch(
      createCallAction(
        {
          type: BULK_UPDATE,
        },
        {
          endpoint: discoverAPI.features.bulkUpdate({ featureId, enable }),
          method: 'POST',
          body: model,
        }
      )
    );
  };
}
