import { gql } from '@apollo/client';

export const HOME_PUBLISHER_FIELDS = gql`
  fragment HomePublisherFields on Publisher {
    id
    businessProfileId
    hostUsername
    mutablePublisherName
    formalName
    description
    tier
    primaryColor
    secondaryColor
    permitAutoPublishFromFeed
    horizontalIconId
    defaultFilledIconId
    tileLogos {
      mediaId
      color
    }
    tags {
      SCC
      WIKI
      MANUAL
    }
    defaultNumSnaps
    targetSnapLengthMs
    advertisingEnabled
    advancedAdsEnabled
    publishingEnabled
    topsnapLimit
    videoModeEnabled
    editorialApproval
    enabledFeatures
  }
`;

export const HOME_GET_PUBLISHER = gql`
  ${HOME_PUBLISHER_FIELDS}
  query HomePublisherDetailsQuery($publisherId: ID!) {
    publisher(id: $publisherId) {
      ...HomePublisherFields
    }
  }
`;
