import log from 'loglevel';
import { Dispatch, Middleware } from 'redux';

import { assertArg } from 'utils/assertionUtils';

/**
 * Provides a means to define a list of observers for different action types.
 * Observers will be called in the order they were registered, and will be
 * provided with the action, as well as the dispatch(), getState() and
 * getPrevState() methods.
 *
 * Observers are registered at app startup time in createObservers.js - see
 * the documentation there for more details.
 */
const observerMiddleware: Middleware = (observerMap: any) => (store: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(observerMap).is.object();

  return (next: Dispatch) => (action: any) => {
    const observers = observerMap.getObserversForAction(action);
    const prevState = store.getState();
    const result = next(action);

    if (observers.length > 0) {
      // Provides access to the previous value of the state, so that observers
      // may diff it against the current value if necessary.
      const getPrevState = () => prevState;

      observers.forEach((observer: any) => {
        try {
          observer(action, store.dispatch, store.getState, getPrevState);
        } catch (error) {
          log.error(`Error encountered when executing observer for ${action.type}`, error);
        }
      });
    }

    return result;
  };
};

export default observerMiddleware;
