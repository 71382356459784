import is from 'is_js';
import log from 'loglevel';
import { TimingArgs } from 'react-ga';

import { scrubParsedUrlForReporting } from 'utils/apis/apiUtils';

import * as gaUtils from './gaUtils';
import * as grafanaUtils from './grafanaUtils';

// To track time sensitive metrics
export const SiteSpeed = {
  API_LOAD_TIMES: 'api-load-times',
  PAGE_LOAD_PARTIAL_TIMES: 'page-load-partial-times',
  PAGE_LOAD_FULL_TIMES: 'page-load-full-times',
  PAGE_LOAD_FIRST_TIMES: 'page-load-first-times',
  MEDIA_UPLOAD: 'media-upload',
};

/**
 *
 * @param path - the path of the page being accessed
 */
export function logPageview(path: string) {
  const scrubbedPath = scrubParsedUrlForReporting({ path });
  if (!scrubbedPath.pathName) {
    log.error('Cannot log pageviews without a valid url');
    return;
  }

  gaUtils.logGAPageView(scrubbedPath.pathName);
  grafanaUtils.logGrafanaPageview(scrubbedPath.pathName);
}

export function logTiming(category: string, variable: string, startTimeMs: number, label?: any) {
  const value = new Date().getTime() - startTimeMs;

  if (!category) {
    log.error('Cannot log timing without a category');
    return;
  }
  if (!variable) {
    log.error('Cannot log timing without a variable');
    return;
  }

  const params: TimingArgs = {
    category,
    variable,
    value,
  };

  if (label) {
    params.label = is.object(label) ? JSON.stringify(label) : label;
  }

  gaUtils.logGATiming(params);
  grafanaUtils.logGrafanaTiming(params.category, params.variable, params.value);
}

export function logPromiseTiming<TProm>(fn: () => Promise<TProm>, category: string, variable: string): Promise<TProm> {
  const startTime = performance.now();

  return fn().then(
    (args: TProm): TProm => {
      grafanaUtils.logGrafanaTiming(category, variable, performance.now() - startTime);
      return args;
    }
  );
}
