import { getShouldUseUnifiedLogin } from 'state/auth/selectors/authSelectors';
import { getPublishers } from 'state/publishers/actions/publishersActions';
import * as routerActions from 'state/router/actions/routerActions';
import {
  isGlobalRoleUser,
  getPublishers as getPublishersFromState,
  getUseUnifiedLoginOverride,
} from 'state/user/selectors/userSelectors';

import { fetchCreators } from 'utils/apis/graphQLUtils';
import { getCreatorHostUsernameFromRouteOrStorage } from 'utils/routerUtils';

export const requireHostUsername = (store: any) => async ({ match }: any) => {
  // Try to get the hostUsername from the route or the local storage
  let hostUsername = getCreatorHostUsernameFromRouteOrStorage({ match });
  if (!hostUsername) {
    // Otherwise, fetch the profiles for the user and send the user to the first one
    const { creators } = await fetchCreators();
    if (getShouldUseUnifiedLogin(store.getState()) || getUseUnifiedLoginOverride(store.getState())) {
      hostUsername = creators.length > 0 && creators[0] ? creators[0].hostUsername : null;
    } else {
      // TODO(gulsum): Remove filtering for publisherId once we roll-out to all creators
      const publishers = creators.filter(({ publisherId }) => publisherId);
      hostUsername = publishers.length > 0 && publishers[0] ? publishers[0].hostUsername : null;
    }
  }

  // If the logged-in user has a global role, they might not have direct access to a profile
  // await for getPublisher call in this case
  if (!hostUsername && isGlobalRoleUser(store.getState())) {
    await store.dispatch(getPublishers());
    hostUsername =
      getPublishersFromState(store.getState()).find(publisher => !!publisher.hostUsername)?.hostUsername || '';
  }

  if (hostUsername) {
    store.dispatch(routerActions.goToHomepage({ hostUsername }));
  } else {
    // If the user has no profiles, send them to the not found page
    store.dispatch(routerActions.goToNotFound({ overwriteHistory: true }));
  }
};
