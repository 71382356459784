import { gql } from '@apollo/client';

export const GET_CREATORS = gql`
  query GetCreators {
    creators {
      title
      hostUserId
      hostUsername
      publisherId
      businessProfileId
      isTestProfile
    }
  }
`;
