import _ from 'lodash';
import { createSelector as s } from 'reselect';

import {
  createDynamicKeySelector,
  createKeySelectorFromDynamicKeySelector,
  reselectById,
} from 'state/common/selectorFactories';
import * as feedsSelectors from 'state/feeds/selectors/feedsSelectors';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as stagesConfig from 'state/stages/registry/stagesRegistry';

import type { StageId } from '../actions/stagesActions';

import { ContentStatus, StageTypeEnum } from 'config/constants';

import { Feed } from 'types/feeds';

function getStages(state: any) {
  return state.stages || {};
}

const getStageById = createDynamicKeySelector(getStages, {});
export const isDirty = createKeySelectorFromDynamicKeySelector(getStageById, 'dirty', false);
export const getData = createKeySelectorFromDynamicKeySelector(getStageById, 'data', null);

export const getContentStatus = s(
  getData,
  isDirty,
  snapsSelectors.isLoadingByIds,
  snapsSelectors.isSavingByIds,
  (getDataFn, isDirtyFn, isLoadingByIds, isSavingByIds) => {
    return (...snapIds: any[]) => {
      if (isSavingByIds(snapIds)) {
        return ContentStatus.SAVING;
      }

      if (isLoadingByIds(snapIds) || _.some(snapIds, snapId => getDataFn(snapId) === null)) {
        return ContentStatus.LOADING;
      }

      if (_.some(snapIds, snapId => isDirtyFn(snapId))) {
        return ContentStatus.LOADED_DIRTY;
      }

      return ContentStatus.LOADED_CLEAN;
    };
  }
);

export const isStageNetDirty = reselectById(
  false,
  (state: any, stageId: StageId) => getData(state)(stageId),
  (state: any, stageId: StageId) => isDirty(state)(stageId),
  (state: any, stageId: StageId) => feedsSelectors.getFeedById(state)(stageId),
  (state: any, stageId: StageId, stageType: StageTypeEnum) => stageType,
  (stagedData: any, isStageDirty: boolean, feed: Feed, stageType: StageTypeEnum) => {
    if (isStageDirty) {
      const stagedProperties = stagesConfig.transformInstanceFromStaged(stageType, stagedData);

      const propertiesToCommit = _.pick(
        stagedProperties,
        Object.keys(stagedProperties).filter(val => stagedProperties[val] !== undefined)
      );

      const propertiesFromFeed = _.pick(feed, Object.keys(propertiesToCommit));

      return !_.isEqual(propertiesToCommit, propertiesFromFeed);
    }

    return false;
  }
);

export const areStagesNetDirty = s(isStageNetDirty, isStageNetDirtyFn => {
  return (stageIds: StageId[], stageType: StageTypeEnum) => {
    return stageIds.reduce((acc, id) => acc || isStageNetDirtyFn(id, stageType), false);
  };
});
