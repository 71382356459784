import _ from 'lodash';
import React from 'react';
import { intlShape } from 'react-intl';
import InlineSVG from 'svg-inline-react';

import { ellipsis, warningTriangle } from 'icons/SDS/allIcons';
import * as localRoutes from 'utils/apis/localRoutes';
import { getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';
import * as locationUtils from 'utils/locationUtils';

import SDSButton, { ButtonShape, ButtonType } from 'views/common/components/SDSButton/SDSButton';

import style from './PublisherOption.scss';

import { PublisherWithGroup, PublisherID, PublisherType } from 'types/publishers';

type Props = {
  title: string;
  hostUsername: string;
  mutablePublisherName: string;
  formalName: string;
  publishingEnabled: boolean;
  isGroup: boolean;
  groupType: keyof typeof PublisherType;
  id: PublisherID;
  isDebugEntityViewer: boolean;
  showDebugButton: boolean;
};
export class PublisherOption extends React.Component<Props> {
  static contextTypes = {
    intl: intlShape,
  };

  handleDebugPublisher = (e: Event) => {
    e.stopPropagation();
    locationUtils.openInNewWindow(localRoutes.debug.creator({ hostUsername: this.props.hostUsername }));
  };

  renderIcon() {
    if (this.props.publishingEnabled) {
      return null;
    }
    return <InlineSVG src={warningTriangle} className={style.warningIconSize} data-test="PublisherOption.warning" />;
  }

  renderDebugButton() {
    return (
      <SDSButton
        type={ButtonType.SECONDARY}
        shape={ButtonShape.CIRCLE}
        inlineIcon={ellipsis}
        onClick={this.handleDebugPublisher}
        data-test="PublisherOption.debugButton"
      />
    );
  }

  renderDebugButtonOrWarningIcon() {
    if (this.props.showDebugButton && this.props.isDebugEntityViewer) {
      return this.renderDebugButton();
    }
    return this.renderIcon();
  }

  generateSubtitle(mutablePublisherName: string, formalName: string) {
    const localisedMessage = getLocalisedMessageFromId(this.context, 'publishing-disabled-warning');
    if (formalName !== mutablePublisherName) {
      if (!this.props.publishingEnabled && localisedMessage != null) {
        return `${formalName} - ${localisedMessage}`;
      }
      return formalName;
    }
    if (!this.props.publishingEnabled) {
      return localisedMessage;
    }
    return '';
  }

  render() {
    const { isGroup, groupType, title, mutablePublisherName, formalName } = this.props;
    if (isGroup) {
      return (
        <div className={style.publisherGroup}>
          <span className={(style as any).groupName} data-test="PublisherOption.group">
            {groupType}
          </span>
        </div>
      );
    }
    // Prioritize mutablePublisherName over title, which is a creator property
    const profileName = mutablePublisherName || title;
    const subtitle = this.generateSubtitle(profileName, formalName);
    return (
      <div className={style.publisherOption}>
        <div className={style.nameColumn}>
          <div className={style.title} data-test="PublisherOption.title">
            {profileName}
          </div>
          {subtitle && (
            <span className={style.subtitle} data-test="PublisherOption.subtitle">
              {subtitle}
            </span>
          )}
        </div>
        <div className={style.debugButtonOrWarningIcon}>{this.renderDebugButtonOrWarningIcon()}</div>
      </div>
    );
  }
}
export const makeOptionsWithGroups = _.memoize(groupedPublishers => {
  const optionsWithGroups: any = [];
  Object.keys(PublisherType).forEach(publisherType => {
    const publishersInGroup = groupedPublishers[publisherType];
    if (!publishersInGroup || publishersInGroup.length === 0) {
      return;
    }
    optionsWithGroups.push({
      isGroup: true,
      groupType: publisherType,
    });
    optionsWithGroups.push(...publishersInGroup);
  });
  return optionsWithGroups;
});
export const filterPublishers = (publishers: Array<PublisherWithGroup>, filterValue: string) => {
  if (!filterValue) {
    return publishers;
  }
  const lowerValue = filterValue.toLowerCase();
  // Assumes the group options are present
  const publisherOutput = [];
  let currentGroup: any = [];
  // Normally we avoid for loops but this is a bit nicer performance-wise
  // for a function that gets called with each keystroke, lets us bail on the
  // exact match.
  for (let i = 0; i < publishers.length; i++) {
    const publisher = publishers[i];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const isGroup = Boolean(publisher.isGroup);
    const lowerTitle = _.get(publisher, 'title', '').toLowerCase();
    const lowerName = _.get(publisher, 'mutablePublisherName', '').toLowerCase();
    const formalNameLowerCase = _.get(publisher, 'formalName', '').toLowerCase();
    if (isGroup) {
      if (currentGroup && currentGroup.length > 1) {
        // Don't add non empty groups
        publisherOutput.push(...currentGroup);
      }
      currentGroup = [publisher];
    } else if (
      lowerTitle.indexOf(lowerValue) >= 0 ||
      lowerName.indexOf(lowerValue) >= 0 ||
      formalNameLowerCase.indexOf(lowerValue) >= 0
    ) {
      // Matching value, push to current group
      currentGroup.push(publisher);
    }
  }
  if (currentGroup && currentGroup.length > 1) {
    publisherOutput.push(...currentGroup);
  }
  return publisherOutput;
};
export default PublisherOption;
