import is from 'is_js';
import { snakeCase, omit, pick, omitBy } from 'lodash';

import { parseTileLogoMetadata, getTileLogoMetadata, sortTileLogos } from 'state/snapAdmin/schema/tileLogoUtils';

import { createChainedFn } from 'utils/functionUtils';
import * as objectUtils from 'utils/objectUtils';

const UNDO_KEY_TRANSFORMATION_OPTIONS = {
  recursion: objectUtils.Recursion.RECURSIVE,
  fallbackTransformation: snakeCase,
};

export function undoKeyTransformationPreprocessor(request: any) {
  if (is.object(request) && is.object(request.body)) {
    return {
      ...request,
      body: objectUtils.undoKeyTransformation(request.body, UNDO_KEY_TRANSFORMATION_OPTIONS),
    };
  }

  return request;
}

export const pickKeysFinalizer = (keys: any) => (data: any) => {
  return pick(data, keys);
};

export const pickKeysPreprocessor = (keys: any) => (request: any) => {
  if (is.object(request) && is.object(request.body)) {
    return {
      ...request,
      body: pickKeysFinalizer(keys)(request.body),
    };
  }

  return request;
};

export const omitEmptyValuesPreprocessor = (request: any) => {
  if (is.object(request) && is.object(request.body)) {
    return {
      ...request,
      body: omitBy(request.body, val => !val && val !== false),
    };
  }

  return request;
};

export const standardJsonPreprocessors = createChainedFn([undoKeyTransformationPreprocessor]);

export function undoKeyTransformationPreprocessorNoFallBack(request: any) {
  if (is.object(request) && is.object(request.body)) {
    return {
      ...request,
      body: objectUtils.undoKeyTransformation(request.body, {
        recursion: objectUtils.Recursion.RECURSIVE,
      }),
    };
  }

  return request;
}

export const standardJsonPreprocessorsNoFallBack = createChainedFn([undoKeyTransformationPreprocessorNoFallBack]);

export const standardJsonFinalizers = createChainedFn([optimisticJsonFinalizer, camelCaseKeysFinalizer]);

export const preprocessAndFinalize = {
  preprocessor: standardJsonPreprocessors,
  finalizer: standardJsonFinalizers,
};

/**
 * Helper designed for use with the CALL_API middleware, when calling APIs that return
 * JSON but do not correctly set the content-type header in the response. Checks whether
 * the response appears to be JSON, and if so runs it through JSON.parse(). If the response
 * does not appear to be JSON, it is returned unchanged.
 *
 * Use via the CALL_API meta block in any action:
 *
 *     meta: {
 *       [CALL_API]: {
 *         endpoint: 'my/api/url',
 *         method: 'post',
 *         body: data,
 *         finalizer: optimisticJsonFinalizer,
 *       }
 *     }
 *
 */
export function optimisticJsonFinalizer(data: any) {
  if (appearsToBeJson(data)) {
    return JSON.parse(data);
  }
  return data;
}

function appearsToBeJson(data: any) {
  return is.string(data) && data.length > 0 && (data[0] === '{' || data[0] === '[');
}

const CAMEL_CASE_OPTIONS = {
  recursion: objectUtils.Recursion.RECURSIVE,
  undoability: objectUtils.Undoability.UNDOABLE,
};

export function camelCaseKeysFinalizer(data: any) {
  if (is.object(data)) {
    return objectUtils.camelCaseKeys(data, CAMEL_CASE_OPTIONS);
  }
  return data;
}

/*
 * Tile logo request processing
 */

const metadataAsObjectFinalizer = (data: any) => {
  if (is.array(data)) {
    return (
      data
        // sort by id, so that old ids are last (in case they don't have an order property)
        .map(parseTileLogoMetadata)
        .sort(sortTileLogos)
    );
  }
  return data;
};

export const tileLogoFinalizers = createChainedFn([optimisticJsonFinalizer, metadataAsObjectFinalizer]);

const stringifyTileLogoMetadata = (tileLogo: any) => {
  if (is.object(tileLogo) && !Array.isArray(tileLogo)) {
    const metadata = omit(getTileLogoMetadata(tileLogo), ['needsMetadataSchemaMigration']);
    return { ...tileLogo, metadata: JSON.stringify(metadata) };
  }
  return tileLogo;
};

export const preProcessTileLogoData = createChainedFn([stringifyTileLogoMetadata]);
