import type { ValidateTextResponses } from 'config/textValidationConstants';
import type { LogoPositionEnum } from 'utils/logoConfig';

import type { AssetID } from './assets';

// Tile id is a generated string
export type TileID = string;

export type TileLocalization = {
  language: string;
  headline: string;
};

export type CameoTile = {
  previewUrl: string;
  cameoId: number | string | undefined | null;
  revision: number | string | undefined | null;
};

export enum TileFlavor {
  UNKNOWN_UNSET = 'UNKNOWN_UNSET',
  DISCOVER_FEED_RECTANGULAR = 'DISCOVER_FEED_RECTANGULAR',
  HN_SQUARE = 'HN_SQUARE',
}

export type Tile = {
  headline: string | undefined | null;
  tileLocalizations: TileLocalization[];
  isHeadlineEnabled: boolean;
  headlineTextValidationResult: ValidateTextResponses;
  isLogoEnabled: boolean;
  baseImageAssetId: AssetID;
  croppedImageAssetId: AssetID | undefined | null;
  logoImageAssetId: AssetID | undefined | null;
  logoReadStateOverlayColor: string | undefined | null;
  baseVerticalCropPosition: number | undefined | null;
  baseHorizontalCropPosition: number | undefined | null;
  imageCropPositions: {
    [x: string]: number;
  };
  id: TileID;
  scsId?: TileID;
  logoPosition: LogoPositionEnum | undefined | null;
  bitmojiTileTemplateId: string | undefined | null;
  cameoTile: CameoTile | undefined | null;
  tileFlavor: TileFlavor | null;
};

export type TileShape = Partial<Tile>;

export type TileEditorState = {
  videoStartPosition?: number;
  language?: string | null;
};

export type StoreTileMap = {
  [key in TileID]: Tile;
};

export type PendingTile = Tile & {
  generatedBlobURI?: string;
  initialize?: boolean;
};
