import { gql } from '@apollo/client';

export const ANALYTICS_PUBLISHER_FIELDS = gql`
  fragment AnalyticsPublisherFields on Publisher {
    id
    businessProfileId
    mutablePublisherName
    tier
    advertisingEnabled
    advancedAdsEnabled
    advancedAdsMinSlots
    advancedAdsMaxSlots
    advancedAdsMinSpacing
    advancedAdsLatestFirstSlot
    hostUsername
    publisherFeatures
    defaultFilledIconId
    subscribable
    primaryLanguage
    homeCountry
    regions
    tags {
      SCC
      WIKI
      MANUAL
    }
    enabledFeatures
  }
`;

export const ANALYTICS_GET_PUBLISHER = gql`
  ${ANALYTICS_PUBLISHER_FIELDS}
  query AnalyticsPublisherDetailsQuery($publisherId: ID!) {
    publisher(id: $publisherId) {
      ...AnalyticsPublisherFields
    }
  }
`;
