import { createSelector as s } from 'reselect';

import { createDynamicKeySelector, createKeySelector } from 'state/common/selectorFactories';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'config/constants';

import type { Feed, FeedID, FeedsState } from 'types/feeds';
import type { BusinessProfileID } from 'types/publishers';
import type { State } from 'types/rootState';

const getFeeds = (state: State): FeedsState => state.feeds;

const byId = createKeySelector(getFeeds, 'byId', EMPTY_OBJECT);

const byOwnerId = s(
  byId,
  (
    feedsById: {
      [k in FeedID]: Feed;
    }
  ) => {
    // using Object.keys because Flow can't seem to infer the type when using Object.values
    // https://github.com/facebook/flow/issues/2221
    return Object.keys(feedsById).reduce((feedsByOwnerIdMap, feedId) => {
      const feed = feedsById[feedId];
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const ownerId = feed.ownerId;

      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (feedsByOwnerIdMap[ownerId]) {
        const newFeedIds = [
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          ...feedsByOwnerIdMap[ownerId],
          feed,
        ];
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        feedsByOwnerIdMap[ownerId] = newFeedIds; // eslint-disable-line no-param-reassign
      } else {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        feedsByOwnerIdMap[ownerId] = [feed]; // eslint-disable-line no-param-reassign
      }

      return feedsByOwnerIdMap;
    }, {});
  }
);

const byOwnerIdLoading = createKeySelector(getFeeds, 'byOwnerIdLoading', EMPTY_OBJECT);

export const getFeedById = createDynamicKeySelector<Feed | undefined | null, FeedID>(byId, null);
export const getFeedsByOwnerId = createDynamicKeySelector<Array<Feed>, BusinessProfileID>(byOwnerId, EMPTY_ARRAY);

export const getFeedsByOwnerIdLoading = s(byOwnerIdLoading, byOwnerIdLoadingMap => (ownerId: any) =>
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  !!byOwnerIdLoadingMap[ownerId]
);
