import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import * as assetActions from 'state/asset/actions/assetActions';
import { createSequenceHandlingReducer } from 'state/common/reducerFactories';

import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';

export const byId = createSequenceHandlingReducer({}, assetActions.LOAD_ASSET_INFO, {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    if (action.error) {
      return state;
    }

    return u(wrapEntitiesInUpdeepConstants(action.payload.entities.assets), state);
  },
});

export default combineReducers({
  byId,
});
