import _ from 'lodash';

import * as articleSelectors from 'state/article/selectors/articleSelectors';
import * as assetActions from 'state/asset/actions/assetActions';
import * as attachmentsActions from 'state/attachments/actions/attachmentsActions';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { FileType, UploadPurpose } from 'config/constants';
import { EditionID } from 'src/types/editionID';
import { GetState } from 'src/types/redux';
import { assertArg, assertState } from 'utils/assertionUtils';
import { getClientAttachments } from 'utils/attachmentUtils';

import { processInput } from 'views/editor/containers/ArticleEditor/utils/editorHTMLInputProcessor';

import {
  ensureAllImagesAreAssets,
  embedImageAspectRatios,
  ensureAllVideosAreTranscodedMediaIds,
} from './articleImageProcessor';

import { AttachmentType } from 'types/attachments';
import { SnapId, assertSnapId } from 'types/common';
import { SnapType } from 'types/snaps';
// Add a hook into new ARTICLE snap creation so that we can add the publisher's CSS and
// JS attachments.
snapsActions.registerSnapBuilderAction(SnapType.ARTICLE, (dispatch, getState, snap) => {
  return Promise.resolve({
    ...snap,
  });
});
export const LOAD_ARTICLE = 'article/LOAD_ARTICLE';
export const UNLOAD_ARTICLE = 'article/UNLOAD_ARTICLE';
// Rather than loading the article from the server, this actually reads the article html from
// the snaps slice of the store, parses it, and stores the result in the article slice.
//
// This helps with performance, because updating the snap HTML on each keypress as an
// article is being edited is prohibitively expensive (leads to severe latency issues),
// and also allows us to delay writing the changes to the snap entity until the user
// clicks save.
//
// TODO (piers) Rename to parseAndMountArticle() as part of renaming PR
export function loadArticle({ snapId, htmlParser, reload }: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(htmlParser).is.function();
  assertSnapId(snapId);
  return (dispatch: any, getState: GetState) => {
    const businessProfileId = publishersSelectors.getActivePublisherBusinessProfileId(getState());
    const articleSnap = snapsSelectors.getSnapById(getState())(snapId);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(articleSnap).is.object();
    const articleFields = articleSelectors.getFields(getState());
    const currentlyLoadedArticleId = _.get(articleFields, ['id']);
    if (currentlyLoadedArticleId && articleSnap && currentlyLoadedArticleId === articleSnap.id && !reload) {
      return Promise.resolve(articleFields);
    }
    const editorHtml = (articleSnap as any).editorHtml || '';
    const attachments = getClientAttachments(SnapType.ARTICLE).map((attachment: any) => {
      return {
        businessProfileId,
        id: attachment.id,
        type: SnapType.ARTICLE,
        fileType: attachment.type,
      };
    });
    attachments.push({
      businessProfileId,
      id: 0,
      type: SnapType.ARTICLE,
      fileType: AttachmentType.CSS,
    });
    return dispatch({
      type: LOAD_ARTICLE,
      payload: {
        promise: dispatch(attachmentsActions.loadAttachments(attachments))
          .then(() => processInput(editorHtml, {}, htmlParser))
          .then((parsedFields: any) => ({
            ...parsedFields,
            id: articleSnap?.id,
            attachmentIds: attachments.map((attachment: any) => attachment.id),
            imageAssetIds: (articleSnap as any).imageAssetIds,
            longformVideoAssetIds: (articleSnap as any).longformVideoAssetIds,
          })),
      },
    });
  };
}
export function unloadArticle() {
  return {
    type: UNLOAD_ARTICLE,
  };
}
export const NOTIFY_ARTICLE_EDITED = 'article/NOTIFY_ARTICLE_EDITED';
export function notifyArticleEdited(ids: any, fields: any) {
  return {
    type: NOTIFY_ARTICLE_EDITED,
    payload: {
      articleId: ids.snap,
      fields,
    },
    meta: {
      method: 'push',
      messageName: 'NOTIFY_ARTICLE_EDITED',
    },
  };
}
export const NOTIFY_ARTICLE_SAVED = 'article/NOTIFY_ARTICLE_SAVED';
export function notifyArticleSaved(ids: any, fields: any) {
  return {
    type: NOTIFY_ARTICLE_SAVED,
    payload: {
      articleId: ids.snap,
      fields,
    },
    meta: {
      method: 'push',
      messageName: 'NOTIFY_ARTICLE_SAVED',
    },
  };
}
export const PROCESS_ARTICLE_IMAGES = 'article/PROCESS_ARTICLE_IMAGES';
export const PROCESS_ARTICLE_VIDEOS = 'article/PROCESS_ARTICLE_VIDEOS';
export function processArticleImages(articleHTMLBuilder: any, existingImageAssetIds: any, editionId: EditionID) {
  return (dispatch: any, getState: GetState) => {
    const uploadFn = (data: any) =>
      dispatch(mediaActions.uploadMedia(data, FileType.IMAGE, { editionId, purpose: UploadPurpose.ARTICLE_IMAGE }));
    const loadAssetInfoFn = (assetId: any) => dispatch(assetActions.loadAssetInfo(assetId));
    const entityOwner = userSelectors.getActivePublisherIdAsString(getState());
    return dispatch({
      type: PROCESS_ARTICLE_IMAGES,
      payload: {
        promise: ensureAllImagesAreAssets({
          articleHTMLBuilder,
          existingImageAssetIds,
          entityOwner,
          loadAssetInfoFn,
          uploadFn,
        }).then(() => {
          return embedImageAspectRatios(articleHTMLBuilder);
        }),
      },
    });
  };
}
export function processArticleVideos(articleHTMLBuilder: any) {
  return (dispatch: any) => {
    const getFreshTranscode = (mediaId: any) => dispatch(mediaActions.getFreshTranscode(mediaId));
    return dispatch({
      type: PROCESS_ARTICLE_VIDEOS,
      payload: {
        promise: ensureAllVideosAreTranscodedMediaIds({
          articleHTMLBuilder,
          getFreshTranscode,
        }),
      },
    });
  };
}
export const SAVE_ARTICLE = 'article/SAVE_ARTICLE';
export function saveArticle(ids: any, htmlBuilder: any, attachmentsByType: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(ids).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(htmlBuilder).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(attachmentsByType).is.object();
  return (dispatch: any, getState: GetState) => {
    const attachmentIds = Object.values(attachmentsByType).map(attachment => (attachment as any).id);
    const imageAssetIds = htmlBuilder.extractImageIds();
    const longformVideoAssetIds = htmlBuilder.extractVideoIds();
    // These are written to the snap entity and saved to the backend
    const updatedSnapProperties = {
      editorHtml: htmlBuilder.getWrapperHTMLForEditor(),
      appHtml: htmlBuilder.getWrapperHTMLForApp(),
      attachmentIds,
      imageAssetIds,
      longformVideoAssetIds,
    };
    // These are written to the article slice of the store so that it's kept in sync
    const updatedArticleFields = {
      channel: htmlBuilder.getChannel(),
      headline: htmlBuilder.getHeadline(),
      secondaryHeadline: htmlBuilder.getSecondaryHeadline(),
      articleContent: htmlBuilder.getContentHTML(),
      attachmentIds,
      imageAssetIds,
      longformVideoAssetIds,
    };
    return dispatch({
      type: SAVE_ARTICLE,
      payload: {
        promise: dispatch(snapsActions.setSnapPropertiesAndSave({ snapId: ids.snap }, updatedSnapProperties)).then(() =>
          dispatch(notifyArticleSaved(ids, updatedArticleFields))
        ),
      },
    });
  };
}

export const saveOriginalArticleUrl = (snapId: SnapId, originalUrl: string) => (dispatch: any, getState: GetState) => {
  return dispatch({
    type: SAVE_ARTICLE,
    payload: {
      promise: dispatch(snapsActions.setSnapPropertiesAndSave({ snapId }, { originalUrl })),
    },
  });
};
