import type { Dispatch } from 'redux';

import type { UserId } from 'state/user/userState';

import { subscriptionSchema, subscriptionsSchema } from '../schema/subscriptionsSchema';
import { getSubscriptionByPublisherUserEventMethod } from '../selectors/subscriptionsSelectors';

import { NotificationMethod, SubscriberType, SubscriberEvent } from 'constants/subscriptions';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as discoverAPI from 'utils/apis/discoverAPI';

import type { PublisherID } from 'types/publishers';
import type { GetState } from 'types/redux';
import type { NotificationMethodEnum, Subscription, SubscriberTypeEnum, SubscriptionId } from 'types/subscriptions';

export const FETCH_SUBSCRIPTION = 'subscriptions/FETCH_SUBSCRIPTION';
export const CREATE_SUBSCRIPTION = 'subscriptions/CREATE_SUBSCRIPTION';
export const DELETE_SUBSCRIPTION = 'subscriptions/DELETE_SUBSCRIPTION';

export const createSubscription = ({
  publisherId,
  event,
  subscriberType,
  userId,
  method = NotificationMethod.EMAIL,
}: {
  publisherId: PublisherID;
  event: SubscriberEvent;
  subscriberType: SubscriberTypeEnum;
  userId: UserId;
  method?: NotificationMethodEnum;
}) => {
  return {
    type: CREATE_SUBSCRIPTION,
    meta: {
      [CALL_API]: {
        endpoint: discoverAPI.subscription.create(),
        method: 'post',
        finalizer: optimisticJsonFinalizer,
        body: {
          publisherId,
          event,
          subscriberType,
          user: {
            userId,
          },
          method,
        },
      },
      schema: subscriptionSchema,
    },
  };
};

export const fetchSubscriptions = ({
  publisherId,
  event,
  subscriberType,
  userId,
  method,
}: {
  publisherId: PublisherID;
  event: SubscriberEvent;
  subscriberType: SubscriberTypeEnum;
  userId: UserId;
  method: NotificationMethodEnum;
}) => {
  return {
    type: FETCH_SUBSCRIPTION,
    meta: {
      [CALL_API]: {
        endpoint: discoverAPI.subscription.fetch({
          publisherId,
          event,
          subscriberType,
          userId,
          method,
        }),
        finalizer: optimisticJsonFinalizer,
      },
      schema: subscriptionsSchema,
    },
  };
};

export const deleteSubscription = ({ subscriptionId }: { subscriptionId: SubscriptionId }) => {
  return {
    type: DELETE_SUBSCRIPTION,
    meta: {
      [CALL_API]: {
        method: 'delete',
        endpoint: discoverAPI.subscription.delete({
          subscriptionId,
        }),
        finalizer: optimisticJsonFinalizer,
      },
    },
    params: {
      subscriptionId,
    },
  };
};

export const updateUserSubscriptionsForPub = ({
  userId,
  publisherId,
  subscriptions,
}: {
  userId: UserId;
  publisherId: PublisherID;
  subscriptions: {
    [k in SubscriberEvent]: boolean;
  };
}) => (dispatch: Dispatch, getState: GetState) => {
  const method = NotificationMethod.EMAIL;

  const updateSubscriptionRequests = (Object.keys(subscriptions) as SubscriberEvent[])
    .map((eventId: SubscriberEvent) => {
      // Find out if there has been a change in any of the subcriptions and act accordingly
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'Subscrip... Remove this comment to see the full error message
      const subscription: Subscription = getSubscriptionByPublisherUserEventMethod(getState())({
        userId,
        publisherId,
        event: eventId,
        method,
      });

      if (subscription.subscriptionId && !subscriptions[eventId]) {
        return dispatch(deleteSubscription({ subscriptionId: subscription.subscriptionId }));
      }
      if (!subscription.subscriptionId && subscriptions[eventId]) {
        return dispatch(
          createSubscription({
            publisherId,
            event: eventId,
            subscriberType: SubscriberType.DISCOVER_USER,
            userId,
            method,
          })
        );
      }
      return null;
    })
    .filter(nonNullEl => nonNullEl);

  return Promise.all(updateSubscriptionRequests);
};
