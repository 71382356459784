import { combineReducers } from 'redux';

import * as mediaLibraryTrayActions from 'state/mediaLibrary/actions/mediaLibraryTrayActions';

import { createSelectedItemsList } from '../../snapGrid/reducers/snapGridReducersUtils';

const selectedMediaList = createSelectedItemsList(
  mediaLibraryTrayActions.TOGGLE_MEDIA,
  mediaLibraryTrayActions.CLEAR_TRAY,
  mediaLibraryTrayActions.CLEAR_AND_SELECT_MEDIA
);

export default combineReducers({
  selectedMediaList,
});
