import { assertArg } from 'utils/assertionUtils';

import { TierLevel } from 'types/publishers';
import type { Publisher } from 'types/publishers';

export const isDynamicEditionsPublisher = (publisher?: Publisher | null): boolean => {
  return publisher?.tier === TierLevel.DYNAMIC_EDITIONS;
};
export const isShowPublisher = (publisher?: Publisher | null): boolean => {
  return publisher?.tier === TierLevel.SHOW;
};

export const isCurationPublisher = (publisher?: Publisher | null): boolean => {
  // These three publishers are exceptions: they're HN or show publishers but have access to the curation tool.
  // Community Happening Now, SeleniumHNCurationV3, People Like Us, Best Snaps Show.
  // Todo gmcquaid: change this check next week, this solution isn't scalable.
  const CurationExceptionPublisherIds = new Set([
    5475601438998544,
    6433671171801104,
    5533707558977552,
    5262832093102096,
  ]);
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
  return publisher?.tier === TierLevel.CURATION || CurationExceptionPublisherIds.has(publisher?.id);
};

export const getPublisherId = (publisher: Publisher) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisher).is.object();
  return publisher.id;
};
