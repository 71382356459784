import classNames from 'classnames';
import React from 'react';
import InlineSVG from 'svg-inline-react';

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './innerSpinner.svg.inline' or ... Remove this comment to see the full error message
import innerSpinner from './innerSpinner.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module './outerSpinner.svg.inline' or ... Remove this comment to see the full error message
import outerSpinner from './outerSpinner.svg.inline';

import style from './SpinnerIcon.scss';

type Props = {
  className?: string;
};

export class SpinnerIcon extends React.Component<Props> {
  render() {
    const classes = classNames(style.spinnerContainer, this.props.className);

    return (
      <div className={classes} data-test="spinnerIcon">
        <InlineSVG src={outerSpinner} className={style.outerSpinner} element="div" />
        <InlineSVG src={innerSpinner} className={style.innerSpinner} element="div" />
      </div>
    );
  }
}

export default SpinnerIcon;
