import Select from 'antd/lib/select';
import React from 'react';
import type { ReactNode } from 'react';

import type { DropdownOption } from 'types/SDSDropdown';

export function createSDSDropdownOptions(options: Array<DropdownOption>): ReactNode {
  if (!options) {
    return null;
  }
  return options.map((option: DropdownOption) => (
    <Select.Option
      key={`SDSDropdownOption.${option.value}`}
      value={option.value}
      data-test={`SDSDropdown.selectOptions.${option.value}`}
      disabled={option.disabled}
    >
      {option.label}
    </Select.Option>
  ));
}
