import { compact } from 'lodash';
import log from 'loglevel';

import { showModal } from 'state/modals/actions/modalsActions';
import * as userSelectors from 'state/user/selectors/userSelectors';
import { User } from 'state/user/userState';

import { LocalStorage } from 'config/constants';
import { GetUserInfo } from 'gql/queries/user/__ssp-generated__/GetUserInfo';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as discoverAPI from 'utils/apis/discoverAPI';
import { assertArg } from 'utils/assertionUtils';
import * as dateUtils from 'utils/dateUtils';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { fetchLocaleJson } from 'utils/i18nLibrary';
import { localStorage as localStorageUtils } from 'utils/localStorageUtils';
import { ModalType } from 'utils/modalConfig';
import { removeFieldRecursively } from 'utils/objectUtils';
import { getPublisherIdFromRouteOrStorage } from 'utils/routerUtils';

import {
  PUBLISHER_GETTING_STARTED,
  modalHeader as newPublisherHeader,
} from 'views/modals/containers/NewUserExperienceHelpModal/ModalData/NewUserExperienceHelpContent';
import {
  STORY_GETTING_STARTED,
  modalHeader as newStoryHeader,
} from 'views/modals/containers/NewUserExperienceHelpModal/ModalData/NewUserExperienceStoryHelpContent';
import { HelpModalTypes } from 'views/modals/containers/NewUserExperienceHelpModal/NewUserExperienceHelpModal';

import { Creator } from 'types/creator';
import { Dispatch, GetState } from 'types/redux';
import { State } from 'types/rootState';

// ------------------------------------
// Constants
// ------------------------------------
export const INFO = 'users/INFO';
export const SET_USER_INFO = 'users/SET_USER_INFO';
export const PUBLISHER = 'users/PUBLISHER';
export const SET_ACTIVE_PUBLISHER = 'users/SET_ACTIVE_PUBLISHER';
export const SET_ACTIVE_CREATOR = 'users/SET_ACTIVE_CREATOR';
export const SET_AD_CONTROLS = 'users/SET_AD_CONTROLS';
export const SET_SEEN_USER_HELP_MODAL = 'users/SET_SEEN_USER_HELP_MODAL';
export const SET_SHOW_HELP_MODAL_FLAG = 'users/SET_SHOW_HELP_MODAL_FLAG';
export const SET_SHOW_TILES = 'users/SET_SHOW_TILES';
export const SET_LOCALE = 'users/SET_LOCALE';
export const STORE_USER_ID = 'users/STORE_USER_ID';
export const SET_USER_FLAG = 'users/SET_USER_FLAG';
export const VALIDATE_EMAIL = 'users/VALIDATE_EMAIL';
export const SET_LAST_VIEWED_REVISE_FILTER = 'users/SET_LAST_VIEWED_REVISE_FILTER';
export const SET_SELECTED_AD_ACCOUNT_ID = 'users/SET_SELECTED_AD_ACCOUNT_ID';
export const SET_USE_UNIFIED_LOGIN = 'users/SET_USE_UNIFIED_LOGIN';

// ------------------------------------
// Actions
// ------------------------------------
export const getUserInfo = () => ({
  type: INFO,
  bailout: (state: State) => !dateUtils.isExpired(userSelectors.getUserInfoAge(state)),
  meta: {
    [CALL_API]: {
      endpoint: discoverAPI.user.login({
        forcedRoles: compact((localStorageUtils.getItem(LocalStorage.FORCED_ROLES) || '').split(',')),
      }),
      method: 'post',
      finalizer: optimisticJsonFinalizer,
    },
  },
});

export const setUserDataWithGraphQLResponse = (queryData: GetUserInfo) => (dispatch: any, getState: GetState) => {
  if (queryData) {
    const cleanedData = removeFieldRecursively(queryData, '__typename');
    const userData = (cleanedData as unknown) as User;

    dispatch({
      type: SET_USER_INFO,
      payload: { ...userData },
    });
  }
};

export const storeUserId = (userId: any) => ({
  type: STORE_USER_ID,
  payload: { userId },
});

export const setActiveCreator = (activeCreator: Creator) => {
  return (dispatch: any) => {
    // Update the active publisher if the creator has a publisher for backwards compatibility
    dispatch({
      type: SET_ACTIVE_PUBLISHER,
      activePublisherId: activeCreator?.publisherId || null,
    });

    return dispatch({
      type: SET_ACTIVE_CREATOR,
      activeCreator,
    });
  };
};

const setActivePublisherInner = (activePublisherId: any) => {
  let activePublisherIdAsNumber = activePublisherId;
  if (activePublisherId !== null) {
    activePublisherIdAsNumber = parseInt(activePublisherId, 10);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(activePublisherIdAsNumber).is.number();
  }
  return {
    type: SET_ACTIVE_PUBLISHER,
    activePublisherId: activePublisherIdAsNumber,
    params: {
      publisherId: activePublisherIdAsNumber,
    },
  };
};
export const setActivePublisher = (activePublisherId: any) => {
  return (dispatch: any, getState: GetState) => {
    return dispatch(setActivePublisherInner(activePublisherId));
  };
};
export const helpers = {
  setActivePublisher,
};
export const setDefaultActivePublisherId = () => {
  return (dispatch: any, getState: GetState) => {
    const activePublisherId = userSelectors.getActivePublisherId(getState());
    const storedPublisherId = getPublisherIdFromRouteOrStorage();

    if (!activePublisherId && !storedPublisherId) {
      const publishers = userSelectors.getPublishers(getState());
      if (publishers.length === 0) {
        // corner case, that user don't have access to any publisher
        log.error("doesn't have any publisher access when try to set the default active publisher");
        return null;
      }
      let publisherId = publishers.find(publisher => !!publisher.hostUsername)?.id;
      if (!Number.isNaN(Number(storedPublisherId))) {
        const storedPublisherIndex = publishers.findIndex(publisher => (publisher as any).id === storedPublisherId);
        if (storedPublisherIndex >= 0 && storedPublisherId) {
          publisherId = storedPublisherId;
        }
      }
      return dispatch(helpers.setActivePublisher(publisherId));
    }

    if (storedPublisherId && !activePublisherId) {
      return dispatch(helpers.setActivePublisher(storedPublisherId));
    }
    return null;
  };
};
export const setAdControls = (adControlsEnabled: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(adControlsEnabled).is.boolean();
  return {
    type: SET_AD_CONTROLS,
    adControlsEnabled,
  };
};
export const setShowTiles = (showTilesEnabled: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(showTilesEnabled).is.boolean();
  return {
    type: SET_SHOW_TILES,
    payload: {
      showTilesEnabled,
    },
  };
};

export const setSeenUserHelpModal = () => ({
  type: SET_SEEN_USER_HELP_MODAL,
});

export const setShowHelpModalFlag = (doNotShowNewUserModalAgain: boolean) => ({
  type: SET_SHOW_HELP_MODAL_FLAG,
  payload: doNotShowNewUserModalAgain,
});

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'userFlag' implicitly has an 'any' type.
export const setUserFlag = (userFlag, enable = true) => ({
  type: SET_USER_FLAG,
  meta: {
    [CALL_API]: {
      method: 'PUT',
      endpoint: discoverAPI.user.setFlag({ userFlag, enable }),
      finalizer: optimisticJsonFinalizer,
    },
  },
});
export const setLastViewedReviseFilter = (publisherId: any, time: any) => {
  return {
    type: SET_LAST_VIEWED_REVISE_FILTER,
    publisherId,
    time,
  };
};

const getHelpModalConfig = (modalType: HelpModalTypes, dispatch: Dispatch) => {
  switch (modalType) {
    case HelpModalTypes.NEW_PUBLISHER_MODAL:
      return {
        hasMailingForm: false,
        modalSlideContents: PUBLISHER_GETTING_STARTED,
        modalTitle: newPublisherHeader,
        onDontShowModalAgain: (dontShowModalAgain: boolean) => {
          dispatch(setShowHelpModalFlag(dontShowModalAgain));
        },
      };
    case HelpModalTypes.NEW_STORY_MODAL:
      return {
        hasMailingForm: false,
        modalSlideContents: STORY_GETTING_STARTED,
        modalTitle: newStoryHeader,
        onDontShowModalAgain: (dontShowModalAgain: boolean) => {
          localStorage.setItem(LocalStorage.DONT_SHOW_HELP_MODALS_STORY, String(dontShowModalAgain));
        },
      };
    default:
      return null;
  }
};

export const openNewUserExperienceHelpModal = (source: string, modalType: HelpModalTypes) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  const modalConfig = getHelpModalConfig(modalType, dispatch);
  if (modalConfig) {
    incrementCounter(GrafanaMetrics.NEW_USER_EXPERIENCE_HELP_MODAL, { type: modalType });
    return dispatch(
      showModal(ModalType.PUBLISHER_HELP_MODAL, source, {
        onConfirm: () => {},
        modalConfig: getHelpModalConfig(modalType, dispatch),
      })
    );
  }
  return Promise.resolve();
};

export const setSelectedAdAccountId = (adAccountId: string | null) => {
  return {
    type: SET_SELECTED_AD_ACCOUNT_ID,
    adAccountId,
  };
};

export const setUseUnifiedLogin = (useUnifiedLogin: boolean) => {
  return {
    type: SET_USE_UNIFIED_LOGIN,
    useUnifiedLogin,
  };
};

export const setLocale = (localeId: string) => async (dispatch: Dispatch) => {
  localStorage.setItem('localeId', localeId);
  const messages = await fetchLocaleJson(localeId);
  return dispatch({
    type: SET_LOCALE,
    localeId,
    messages,
  });
};
