import { UploadPurpose, AspectRatio, ImageType, FileType } from 'config/constants';
import * as videoUtils from 'utils/media/videoUtils';

import type { MediaValidation } from 'types/media';
import { MediaInfo } from 'types/media';

const defaultMaxDuration = 10; // Default max duration unless overridden per-publisher

const VALIDATION_OPTIONS_BY_PURPOSE: {
  [x: string]: MediaValidation | ((a?: MediaInfo) => MediaValidation);
} = {
  [UploadPurpose.ARTICLE_IMAGE]: {
    // This may seem random, but it matches what we had in the existing Angular
    // implementation when this code was ported over. I'm not entirely sure why
    // it's there, but guess that it might be something to do with pasted article
    // content sometimes containing very small images – for example the type of
    // 1x1 pixel transparent gif images that companies sometimes use for analytics.
    //
    // TODO: I'd like to experiment with removing this type of questionable logic,
    // but prefer to do that after we've successfully completed the porting exercise
    // so that it's clearer where any issues have arisen from. So for now, I've just
    // brought this over directly.
    sizeLimit: {
      [ImageType.GIF]: 360, // KB
    },
    minSizePx: 5,
  },
  [UploadPurpose.TOP_SNAP]: mediaInfo => {
    if (mediaInfo && videoUtils.isCircularVideo(mediaInfo)) {
      return {
        minWidthPx: 1080,
        minHeightPx: 1080,
        maxDurationSeconds: defaultMaxDuration,
        aspectRatio: AspectRatio.ONExONE,
      };
    }
    return {
      minWidthPx: 1080,
      minHeightPx: 1920,
      lowResMinWidthPx: 540,
      lowResMinHeightPx: 960,
      maxDurationSeconds: defaultMaxDuration,
      aspectRatio: AspectRatio.NINExSIXTEEN,
    };
  },
  [UploadPurpose.TILE_IMAGE]: (mediaInfo?: MediaInfo) => {
    if (mediaInfo && mediaInfo.type === FileType.VIDEO) {
      return {
        minWidthPx: 540,
        minHeightPx: 900,
        aspectRatio: AspectRatio.NINExSIXTEEN,
        minDurationSeconds: 3,
        maxDurationSeconds: defaultMaxDuration,
      };
    }
    return {
      minWidthPx: 540,
      minHeightPx: 900,
    };
  },
  [UploadPurpose.TILE_LOGO]: {
    minWidthPx: 993,
    minHeightPx: 284,
    aspectRatio: { width: 993, height: 284, tolerance: 0 },
  },
  [UploadPurpose.HAPPENING_NOW_TILE_IMAGE]: {
    minWidthPx: 160,
    minHeightPx: 160,
  },
  [UploadPurpose.HAPPENING_NOW_TILE_CROP_IMAGE]: {
    minWidthPx: 160,
    minHeightPx: 160,
    aspectRatio: { width: 1, height: 1, tolerance: 0 },
  },
  [UploadPurpose.TILE_CROP_IMAGE]: {
    allowAnyAspectRatio: true,
  },
  [UploadPurpose.OVERLAY_MEDIA]: {
    minWidthPx: 1080,
    minHeightPx: 1920,
    aspectRatio: AspectRatio.NINExSIXTEEN,
  },
  [UploadPurpose.SUBTITLE]: {},
  [UploadPurpose.TOPSNAP_SUBTITLES]: {},
  [UploadPurpose.EDITION_SUBTITLES]: {},
  [UploadPurpose.LONGFORM_VIDEO]: {
    maxDurationSeconds: 40 * 60,
  },
  [UploadPurpose.POLL_IMAGE]: {
    minSizePx: 5,
  },
  [UploadPurpose.SQUARE_HERO_IMAGE]: {
    minWidthPx: 1080,
    minHeightPx: 1080,
    aspectRatio: { width: 1, height: 1, tolerance: 0 },
  },
  [UploadPurpose.SINGLE_ASSET_STORY]: {
    minWidthPx: 1080,
    minHeightPx: 1920,
    lowResMinWidthPx: 540,
    lowResMinHeightPx: 960,
    minDurationSeconds: 30,
    maxDurationSeconds: 1200,
    aspectRatio: AspectRatio.NINExSIXTEEN,
    disAllowCorruptVideo: true,
    disAllowVariableFrameRateVideo: true,
    disAllowVideoWithoutSound: true,
    maxSdBitrate: 50 * (1000 * 1000), // 50Mbps
    maxHdBitrate: 50 * (1000 * 1000), // 50Mbps
    maxUHdBitrate: 16 * (1000 * 1000), // 16Mbps
  },
  [UploadPurpose.SQUARE_ICON]: {
    minWidthPx: 160,
    minHeightPx: 160,
    aspectRatio: { width: 160, height: 160, tolerance: 0 },
  },
  [UploadPurpose.WIDE_ICON]: {
    minWidthPx: 993,
    minHeightPx: 284,
    aspectRatio: { width: 993, height: 284, tolerance: 0 },
  },
  [UploadPurpose.SPOTLIGHT]: {
    minWidthPx: 1080,
    minHeightPx: 1920,
    lowResMinWidthPx: 540,
    lowResMinHeightPx: 960,
    minDurationSeconds: 5,
    maxDurationSeconds: 60 * 5, // 5 mins
    aspectRatio: AspectRatio.NINExSIXTEEN,
    disAllowCorruptVideo: true,
    disAllowVariableFrameRateVideo: true,
    disAllowVideoWithoutSound: true,
    maxSdBitrate: 50 * (1000 * 1000), // 50Mbps
    maxHdBitrate: 50 * (1000 * 1000), // 50Mbps
    maxUHdBitrate: 16 * (1000 * 1000), // 16Mbps
  },
  [UploadPurpose.PUBLIC_STORY]: {
    minWidthPx: 1080,
    minHeightPx: 1920,
    lowResMinWidthPx: 540,
    lowResMinHeightPx: 960,
    minDurationSeconds: 1,
    maxDurationSeconds: 60,
    aspectRatio: AspectRatio.NINExSIXTEEN,
    disAllowCorruptVideo: true,
    disAllowVariableFrameRateVideo: true,
    disAllowVideoWithoutSound: false,
    maxSdBitrate: 50 * (1000 * 1000), // 50Mbps
    maxHdBitrate: 50 * (1000 * 1000), // 50Mbps
    maxUHdBitrate: 16 * (1000 * 1000), // 16Mbps
  },
};

export function hasValidationOptions(purpose: string, mediaInfo?: MediaInfo): boolean {
  let options = VALIDATION_OPTIONS_BY_PURPOSE[purpose];
  if (typeof options === 'function') {
    options = options(mediaInfo);
  }

  return !!options;
}

export function getValidationOptions(purpose: string, mediaInfo?: MediaInfo): MediaValidation {
  let options = VALIDATION_OPTIONS_BY_PURPOSE[purpose];
  if (typeof options === 'function') {
    options = options(mediaInfo);
  }

  if (!options) {
    throw new Error(`Validation options not implemented for purpose: ${purpose}`);
  }

  if (typeof options === 'function') {
    throw new Error(`Unable to call options method for purpose: ${purpose}`);
  }
  return options;
}
