import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';

import { EMPTY_OBJECT } from 'config/constants';
import { NotificationMethod, SubscriberEvent } from 'constants/subscriptions';
import { functionRef } from 'utils/functionUtils';

function getSubscriptions(state: any) {
  return state.subscriptions || {};
}
export const getSubscriptionMap = createKeySelector(getSubscriptions, 'byId', {});
export const getSubscriptionByPublisherUserEventMethod = s(
  getSubscriptionMap,
  subscriptions => ({ publisherId, event, userId, method }: any) =>
    Object.values(subscriptions).filter(
      subscription =>
        (subscription as any).publisherId === publisherId &&
        (subscription as any).event === event &&
        (subscription as any).method === method &&
        (subscription as any).user &&
        (subscription as any).user.userId === userId
    )[0] || EMPTY_OBJECT
);
const getAvailableSubscriptions = s(
  functionRef(featuresSelectors, 'isModerationTilesOnlyEnabled'),
  moderationTilesOnlyEnabled => {
    const maybeModerationEvent = moderationTilesOnlyEnabled ? [SubscriberEvent.MODERATION] : [];
    return [...maybeModerationEvent];
  }
);
/**
 * Checks what subscriptions are available for a publisher (based on feature flags, etc.)
 * and creates a tailored to a given user list of subscriptions
 */
export const getAvailableSubscriptionsByPublisherUser = s(
  getSubscriptionByPublisherUserEventMethod,
  getAvailableSubscriptions,
  (subscriptionsFn, availableSubscriptionIds) => ({ publisherId, userId }: any) =>
    availableSubscriptionIds.reduce((acc, eventId) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      acc[eventId] = !!(subscriptionsFn as any)({
        publisherId,
        event: eventId,
        userId,
        method: NotificationMethod.EMAIL,
      }).event;
      return acc;
    }, {})
);
