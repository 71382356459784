import { FormattedMessage } from 'react-intl';

import { SharingMethod, UrlType } from 'config/constants';

import { SnapType } from './snaps';

import { State } from 'types/rootState';

export enum AttachmentType {
  CSS = 'CSS',
  JS = 'JS',
}

export type ServerAttachment = {
  id: number;
  type: AttachmentType;
  content: string;
};

export type AttachmentSnapProperties = {
  allowExternalSharing?: boolean;
  sharingMethod?: SharingMethod;
  pollType?: string;
  urlType?: UrlType;
};

export type AttachmentOptions = {
  index: number;
  name: FormattedMessage.MessageDescriptor;
  icon: any;
  tooltip?: JSX.Element;
  snapType: SnapType;
  snapProperties?: AttachmentSnapProperties;
  title: string;
  enabled: (state: State) => boolean;
};
