import type { Dispatch } from 'redux';

import { assertArg } from 'utils/assertionUtils';

import type { GetState } from 'types/redux';

export const ENABLE_BARRIER = 'barrier/ENABLE_BARRIER';
export const DISABLE_BARRIER = 'barrier/DISABLE_BARRIER';

export const BarrierTypes = {
  AUTH: 'AUTH',
};

export type ValidBarrier = typeof BarrierTypes[keyof typeof BarrierTypes];

export type EnableAction = {
  payload: {
    barrierType: ValidBarrier;
  };
  type: typeof ENABLE_BARRIER;
};

export type DisableAction = {
  payload: {
    barrierType: ValidBarrier;
  };
  type: typeof DISABLE_BARRIER;
};

export type BarrierAction = EnableAction | DisableAction;

export const enableBarrier = (barrierType: ValidBarrier) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(barrierType).is.inValues(BarrierTypes);

  return (dispatch: Dispatch, getState: GetState) =>
    dispatch({
      type: ENABLE_BARRIER,
      payload: {
        barrierType,
      },
    });
};

export const disableBarrier = (barrierType: ValidBarrier) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(barrierType).is.inValues(BarrierTypes);

  return (dispatch: Dispatch, getState: GetState) =>
    dispatch({
      type: DISABLE_BARRIER,
      payload: {
        barrierType,
      },
    });
};
