import { isEmpty } from 'lodash';
import { combineReducers } from 'redux';

import {
  createEntityAgeReducer,
  createRemoverByIdReducer,
  createSequenceCountingByIdReducer,
  createSequenceErrorsByIdReducer,
  createTransactionalSequenceHandlingReducer,
  serializeReducers,
} from 'state/common/reducerFactories';
import * as publisherStoryEditorActionIds from 'state/publisherStoryEditor/actionIds/publisherStoryEditorActionIds';

import { replaceRelatedSnapsMapWithRelatedSnapIdsMap } from '../actions/snapNormalization';

import { TransactionType } from 'config/constants';
import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

import {
  CREATE_SNAP,
  DELETE_SNAP,
  LOAD_SNAP,
  LOAD_SNAPS,
  MARK_SAVE_AS_PENDING,
  SET_SNAP_PROPERTIES,
  SET_SNAP_PROPERTIES_AND_SAVE,
} from 'types/actions/snapsActionsTypes';

const loadSaveDataHandler = createTransactionalSequenceHandlingReducer(
  {},
  TransactionType.SET_SNAP_PROPERTY,
  [
    CREATE_SNAP,
    LOAD_SNAP,
    SET_SNAP_PROPERTIES_AND_SAVE,
    LOAD_SNAPS,
    publisherStoryEditorActionIds.ARTICLE_IMPORT_DIFFBOT,
  ],
  {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      const originalSnaps = action.payload.entities.richSnap;

      // SET_SNAP_PROPERTIES_AND_SAVE is fired when we add attachments to single asset snaps. This wipes the decorations property from snaps,
      // which is where [shots, deeplinks, tags and tiles] are stored for single asset snaps, and breaks the single asset editor timeline (no shots == 0 width).
      // [shots, deeplinks, tags and tiles] property has been added to RichSnapWireModel snaps, so if [shots, deeplinks, tags and tiles] property exists
      // but decorations is empty, recreate the decorations property and add the [shots, deeplinks, tags and tiles] so they are not temporarily lost
      // (we get [shots, deeplinks, tags and tiles] back when loading the snap).
      // This implementation is only temporary as we would not require this change after merging the richSnap and discoverSnap.
      if (action.type === SET_SNAP_PROPERTIES_AND_SAVE || action.type === CREATE_SNAP) {
        Object.keys(originalSnaps).forEach(key => {
          if (isEmpty(originalSnaps[key].decorations)) {
            originalSnaps[key].decorations = { discover: {} };
            if (originalSnaps[key].shots) {
              originalSnaps[key].decorations.discover = {
                ...originalSnaps[key].decorations.discover,
                ...{ shots: originalSnaps[key].shots },
              };
            }
            if (originalSnaps[key].tiles) {
              originalSnaps[key].decorations.discover = {
                ...originalSnaps[key].decorations.discover,
                tiles: originalSnaps[key].tiles,
              };
            }
            if (originalSnaps[key].deeplinks) {
              originalSnaps[key].decorations.discover = {
                ...originalSnaps[key].decorations.discover,
                deeplinks: originalSnaps[key].deeplinks,
              };
            }
            if (originalSnaps[key].tags) {
              originalSnaps[key].decorations.discover = {
                ...originalSnaps[key].decorations.discover,
                tags: originalSnaps[key].tags,
              };
            }
          }
        });
      }

      const modifiedSnaps = replaceRelatedSnapsMapWithRelatedSnapIdsMap(originalSnaps);
      const constantSnaps = wrapEntitiesInUpdeepConstants(modifiedSnaps);

      return u(constantSnaps, state);
    },
  }
);

function setSnapPropertiesHandler(state: any, action: any) {
  return u(action.payload.entities.richSnap, state);
}

const removeSnapById = createRemoverByIdReducer('snapId', DELETE_SNAP);

const bySingleId = (state = {}, action: any) => {
  switch (action.type) {
    case SET_SNAP_PROPERTIES:
      return setSnapPropertiesHandler(state, action);
    default:
      return loadSaveDataHandler(state, action);
  }
};

const byId = serializeReducers([bySingleId, removeSnapById]);

const loadingBySingleId = createSequenceCountingByIdReducer('snapId', LOAD_SNAP);
const loadingByMultipleIds = createSequenceCountingByIdReducer('snapIds', LOAD_SNAPS);
const loadingById = serializeReducers([loadingBySingleId, loadingByMultipleIds, removeSnapById]);

// Keeps track of any snaps which have undergone a request for
// change in the UI - but have you to update their build status.
// This allows us to inform the user that the Snap is 'dirty'
// and prevent them making changes midway through a transaction.
function pendingBuildStatusFetchById(state = {}, action: any) {
  switch (action.type) {
    case MARK_SAVE_AS_PENDING:
      return u(
        {
          [action.snapId]: true,
        },
        state
      );
    default:
      break;
  }
  return state;
}

const savingBySingleId = createSequenceCountingByIdReducer('snapId', SET_SNAP_PROPERTIES_AND_SAVE);
const savingById = serializeReducers([savingBySingleId, removeSnapById]);

const errorBySingleId = createSequenceErrorsByIdReducer('snapId', [LOAD_SNAP, SET_SNAP_PROPERTIES_AND_SAVE]);
const errorByMultipleIds = createSequenceErrorsByIdReducer('snapIds', LOAD_SNAPS);
const errorById = serializeReducers([errorBySingleId, errorByMultipleIds, removeSnapById]);

const lastUpdatedBySingleId = createEntityAgeReducer('richSnap', [
  LOAD_SNAP,
  SET_SNAP_PROPERTIES_AND_SAVE,
  CREATE_SNAP,
]);
const lastUpdatedByMultipleIds = createEntityAgeReducer('richSnap', LOAD_SNAPS);
const lastUpdatedById = serializeReducers([lastUpdatedBySingleId, lastUpdatedByMultipleIds, removeSnapById]);

export default combineReducers({
  byId,
  loadingById,
  savingById,
  pendingBuildStatusFetchById,
  errorById,
  lastUpdatedById,
});
