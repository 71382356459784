import JSZip from 'jszip';

import { StorySnap } from 'gql/queries/spotlight/storySnaps';

export interface DecryptedMedia {
  snapUrl: string;
  posterUrl: string;
}

export const hasAllEncryptedMedia = (snap: StorySnap): boolean => {
  return snap?.video?.iv !== undefined && snap.video.key !== undefined && snap.video.url !== undefined;
};

export const decryptFile = async ({
  url,
  mediaKey,
  mediaIv,
}: {
  url: string;
  mediaKey: string;
  mediaIv: string;
}): Promise<ArrayBuffer> => {
  const resp = await fetch(url);
  const bytes = await resp.arrayBuffer();

  const key = await crypto.subtle.importKey(
    'raw',
    Uint8Array.from(atob(mediaKey), c => c.charCodeAt(0)),
    'AES-CBC',
    false,
    ['decrypt']
  );

  return window.crypto.subtle.decrypt(
    {
      iv: Uint8Array.from(atob(mediaIv), c => c.charCodeAt(0)),
      name: 'AES-CBC',
    } as AesCbcParams,
    key,
    bytes
  );
};

export interface GetMediaDataFromZipResult {
  mediaArrayBuffer: ArrayBuffer;
}

export const getMediaDataFromZip = async (mediaData: ArrayBuffer): Promise<GetMediaDataFromZipResult | null> => {
  const zip = new JSZip();
  const output = await zip.loadAsync(mediaData);

  const mediaFile = output.file(/^media~/i)[0];

  if (!mediaFile) {
    return null;
  }

  const mediaArrayBuffer = await mediaFile.async('arraybuffer');

  return {
    mediaArrayBuffer,
  };
};

export const decryptMedia = async (snap: StorySnap): Promise<DecryptedMedia | null> => {
  if (!hasAllEncryptedMedia(snap)) {
    return null;
  }

  const sourceMediaAsync = decryptFile({
    // hasAllEncryptedMedia makes sure relevant fields are present
    url: snap.video?.url || '',
    mediaIv: snap.video?.iv || '',
    mediaKey: snap.video?.key || '',
  });

  let sourceMediaUrl = '';

  const sourceMediaData = await sourceMediaAsync;
  try {
    const res = await getMediaDataFromZip(sourceMediaData);
    if (!res) {
      return null;
    }
    sourceMediaUrl = URL.createObjectURL(new Blob([new Uint8Array(res.mediaArrayBuffer)], { type: 'video/mp4' }));
  } catch (e) {
    sourceMediaUrl = URL.createObjectURL(new Blob([new Uint8Array(sourceMediaData)], { type: 'video/mp4' }));
  }

  return {
    snapUrl: sourceMediaUrl,
    posterUrl: snap.posterUrl || '',
  };
};
