import { IMPORTED_SNAP_ID_PREFIX } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';

export type AssetID = string;

export const MimeType = enumObject({
  HLS: 'application/x-mpegURL',
  MP4: 'video/mp4',
});

export type MimeTypeEnum = Enum<typeof MimeType>;

type StrippedContextHint = {
  [key: string]: boolean;
};

type AssetCommon = {
  strippedContextHint: StrippedContextHint;
  mimeType: MimeTypeEnum | undefined | null;
  hlsManifest: string | undefined | null;
  subtitles: string[];
  mediaAnnotations: MediaAnnotations | null;
};

export type VideoAsset = AssetCommon & {
  type: AssetType.VIDEO;
  durationMillis: number;
};

export type ImageAsset = AssetCommon & {
  type: AssetType.IMAGE;
};

export type Asset = AssetCommon & (VideoAsset | ImageAsset);

export enum AssetBuildStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type MediaAnnotations = {
  explicitContentAnnotations: ExplicitContentAnnotation[];
  cloudVisionAnnotations: CloudVisionContentAnnotation[];
};

export type ExplicitContentAnnotation = {
  category: string;
  likelihood: string;
};

export type CloudVisionContentAnnotation = {
  label: string;
  score: number;
};

function isUUID(value: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/.test(value);
}

export function isTranscodedMediaID(value: any) {
  return value && typeof value === 'string' && isUUID(value);
}

const MEDIA_ID_PREFIX = 'MediaID-';

export function isMediaID(value: any) {
  return (
    value &&
    typeof value === 'string' &&
    value.startsWith(MEDIA_ID_PREFIX) &&
    isUUID(value.replace(MEDIA_ID_PREFIX, ''))
  );
}

export function isImportedSnapID(value: any) {
  return (
    value &&
    typeof value === 'string' &&
    value.startsWith(IMPORTED_SNAP_ID_PREFIX) &&
    isUUID(value.replace(IMPORTED_SNAP_ID_PREFIX, ''))
  );
}

/**
 * Create a MediaID starting with MEDIA_ID_PREFIX if the input value is not already so,
 * or null if the input is neither a MediaID nor a UUID
 */
export function createMediaID(value: any) {
  if (isImportedSnapID(value)) {
    return value;
  }

  if (isMediaID(value)) {
    return value;
  }
  if (!isUUID(value)) {
    return null;
  }
  return MEDIA_ID_PREFIX + value;
}

export function isMediaLibraryID(value: any) {
  return isTranscodedMediaID(value) || isMediaID(value) || isImportedSnapID(value);
}

export function isAssetID(value: any) {
  return isMediaLibraryID(value);
}

export function toAssetID(value: any): AssetID {
  if (isMediaLibraryID(value)) {
    return value;
  }
  throw new Error(`Not an asset ID: ${String(value)}`);
}

// TODO: consider using invariant(isAssetID(x)) instead of assertions as that'll result in refined flow types.
export function assertAssetId(assetId: any) {
  // TODO: remove is.number from the check later
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(assetId).is.number.or.is.string();
}

export enum AssetType {
  FILE = 'FILE',
  SUBTITLE = 'SUBTITLE',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  LONGFORM_VIDEO = 'LONGFORM_VIDEO',
  AUDIO = 'AUDIO',
  UNKNOWN = 'UNKNOWN',
}
