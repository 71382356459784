// DEPRECATED DON'T USE THOSE FOR ANY NEW SELECTORS.
// Use selectorFactoriesTyped instead.

// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'deno... Remove this comment to see the full error message
import { denormalize } from 'denormalizr';
import is from 'is_js';
import { every } from 'lodash';
import createCachedSelector from 're-reselect';
import { createSelector as s, createSelectorCreator, defaultMemoize, Selector } from 'reselect';
import { createObjectSelector } from 'reselect-map';

import { editionSchema } from 'state/editions/schema/editionsSchema';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'config/constants';
import { State } from 'src/types/rootState';
import { assertArg } from 'utils/assertionUtils';
import u from 'utils/safeUpdeep';

import type { EditionID, StoreEdition, StoreEditionMap, Edition } from 'types/editions';
import type { StoreSegmentMap, SegmentID } from 'types/segments';
import type { StoreTileMap } from 'types/tiles';

/*
  createKeySelector

  Creates a selector that checks if the key given by propertyName exists.
  If the key does not exist, it returns a clone of defaultState.

  Note: this is NOT type-safe.  parent[propertyName] can be the wrong type (something other than T).
  You should check that the returned data has the correct type at runtime.

  TODO: change `T` to `mixed` in the type for this function.
*/
export function createKeySelector<T>(
  parentSelector: (a: State) => {} | undefined | null,
  propertyName: string,
  defaultState: T
): Selector<State, T> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(parentSelector).is.function();
  // 2016-10-12 - changed the order of the arguments - propertyName and defaultState
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(propertyName).is.string();
  // Always use the same default state object to avoid mutating state unnecessarily
  const frozenDefault = is.object(defaultState) && !is.null(defaultState) ? u.freeze(defaultState) : defaultState;
  return s(parentSelector, parent => {
    if (!parent) {
      return frozenDefault;
    }
    if (propertyName in parent) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return parent[propertyName];
    }
    return frozenDefault;
  });
}
export function reselectById<T, ID extends string | number>(
  defaultState: T,
  ...args: unknown[]
): (a: State) => (id: ID, ...rest: any[]) => T {
  // Always use the same default state object to avoid mutating state unnecessarily
  const frozenDefault = is.object(defaultState) && !is.null(defaultState) ? u.freeze(defaultState) : defaultState;
  // @ts-expect-error ts-migrate(2556) FIXME: Expected 2-13 arguments, but got 0 or more.
  const reselectFunc = createCachedSelector(...args)((state: State, id: ID) => id);
  return (state: State) => (entityId: ID, ...rest: any[]) => {
    // re-reselect returns null when entityId is not number of stringa
    if (typeof entityId !== 'number' && typeof entityId !== 'string') {
      return frozenDefault;
    }
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 3 or more.
    const ret = reselectFunc(state, entityId, ...rest);
    return ret === undefined ? frozenDefault : ret;
  };
}
type SelectID = string | number;
type ParentSelectorOutput = any;
type ByIdFn<T> = (b: ParentSelectorOutput, a: SelectID) => T;
type ById<T> = (a: SelectID) => T;
type SelectorHelpers<T> = {
  mapSelector: (a: State) => ParentSelectorOutput;
  byIdFn: ByIdFn<T>;
};
export function createByIdSelector<T>(
  parentSelector: (a: State) => ParentSelectorOutput,
  byIdSelect: ByIdFn<T>
): ((a: State) => ById<T>) & SelectorHelpers<T> {
  const byIdSelector = s(parentSelector, parentMap => (id: any) => byIdSelect(parentMap, id));
  function selectorWrapper(...args: any[]) {
    // @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 0 or more.
    return byIdSelector(...args);
  }
  selectorWrapper.mapSelector = parentSelector;
  selectorWrapper.byIdFn = byIdSelect;
  return selectorWrapper;
}
export function createDynamicKeySelector<T, ID extends SelectID>(
  parentSelector: (
    a: State
  ) =>
    | {
        [key in ID]: T;
      }
    | undefined
    | null,
  defaultState: T
): ((a: State) => ById<T>) & SelectorHelpers<T> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(parentSelector).is.function();
  const frozenDefault = is.object(defaultState) && !is.null(defaultState) ? u.freeze(defaultState) : defaultState;
  return createByIdSelector(parentSelector, (parentResult, id) => {
    if (!parentResult) {
      return frozenDefault;
    }
    if (id in parentResult) {
      return parentResult[id];
    }
    return frozenDefault;
  });
}
/*
  createKeyThenDynamicKeySelector

  Creates a selector from a parentSelector that expects a map at the key propertyName.
  Once this map is found, it uses that selector as a parent selector to select by id.
*/
export function createKeyThenDynamicKeySelector<T, ID extends string | number>(
  parentSelector: (a: State) => {} | undefined | null,
  propertyName: string,
  defaultObjectState: T
): (a: State) => (a: ID) => T {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(parentSelector).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(propertyName).is.string();
  const mapSelector: (a: State) => {} = createKeySelector(parentSelector, propertyName, EMPTY_OBJECT);
  return createDynamicKeySelector(mapSelector, defaultObjectState);
}
/**
 * This Selector takes the dynamic key selector as an argument and it will return
 * the field from the result of the dynamic key selector.
 */
export function createKeySelectorFromDynamicKeySelector(
  parentSelector: any,
  propertyName: string,
  defaultObjectState: any
) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(parentSelector).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(propertyName).is.string();
  return s(parentSelector, parentSelectorFn => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertArg(parentSelectorFn).is.function();
    return (id: any) => {
      const parentSelectorMap = (parentSelectorFn as any)(id);
      if (parentSelectorMap && is.existy(parentSelectorMap[propertyName])) {
        return parentSelectorMap[propertyName];
      }
      return defaultObjectState;
    };
  });
}
export function createEditionsSelector(getEditionMap: any, getTileMap: any, getSegmentMap: any) {
  return createObjectSelector(getEditionMap, getTileMap, getSegmentMap, (edition, tileMap, segmentMap) => {
    if (!edition) {
      return null;
    }
    return denormalize('0', { edition: { '0': edition }, tile: tileMap, segment: segmentMap }, editionSchema);
  });
}
type EditionTileSegmentTuple = [StoreEdition, StoreTileMap, StoreSegmentMap];
export function createEditionByIdSelector(
  getEditionMap: (a: State) => StoreEditionMap,
  getTileMap: (a: State) => StoreTileMap,
  getSegmentMap: (a: State) => StoreSegmentMap
): (a: State) => (a: EditionID) => Edition | null {
  // We want to avoid re-running the transform code that denormalizes the edition,
  // because that always yields a new object and triggers a re-render.
  //
  // The tile map contains all tiles, not just those referenced by the edition.
  //
  // We only want to re-run our transform when the tiles referenced by the edition change.
  //
  // To that aim, we customize reselect with an equality check `editionTileSegmentTuplesEqual`
  // that compares only the tiles referenced by the edition.
  const reselectComparingEditionTileSegmentTuples = createSelectorCreator(
    defaultMemoize,
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '([editionA, tileMapA, segmentMap... Remove this comment to see the full error message
    editionTileSegmentTuplesEqual
  );
  const resolver = (state: State, editionId: EditionID) => editionId;
  const getById: (b: State, a: EditionID) => Edition | null = createCachedSelector(
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    (state: any, editionId: any) => [getEditionMap(state)[editionId], getTileMap(state), getSegmentMap(state)],
    ([edition, tileMap, segmentMap]: EditionTileSegmentTuple) => {
      if (!edition) {
        return null;
      }
      return denormalize('0', { edition: { '0': edition }, tile: tileMap, segment: segmentMap }, editionSchema);
    }
  )({
    keySelector: resolver,
    selectorCreator: reselectComparingEditionTileSegmentTuples,
  });
  // Existing callers use the curried interface get(state)(id), so wrap get(state, id).
  return (state: State) => (editionId: EditionID) => getById(state, editionId);
}
// Used by createEditionByIdSelector.  See description above.
function editionTileSegmentTuplesEqual(
  [editionA, tileMapA, segmentMapA]: EditionTileSegmentTuple,
  [editionB, tileMapB, segmentMapB]: EditionTileSegmentTuple
) {
  if (editionA !== editionB) {
    return false;
  }
  if (tileMapA === tileMapB && segmentMapA === segmentMapB) {
    return true;
  }
  const tiles = (editionB && editionB.tiles) || [];
  const segments: SegmentID[] = (editionB && editionB.segments) || [];
  return (
    tiles &&
    Boolean(tiles.length) &&
    every(tiles, id => tileMapA[id] === tileMapB[id]) &&
    segments &&
    Boolean(segments.length) &&
    every(segments, (id: any) => segmentMapA[id] === segmentMapB[id])
  );
}
export function createFirstItemSelector(parentSelector: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(parentSelector).is.function();
  return s(parentSelector, parent => {
    if (!Array.isArray(parent)) {
      return null;
    }
    if (parent.length > 0) {
      return parent[0];
    }
    return null;
  });
}
export function createListOfIdsByProperty(parentSelector: any, propertyName: string) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(parentSelector).is.function();
  return s(parentSelector, components => {
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return Object.keys(components).sort((a, b) => {
      return (components as any)[a][propertyName]
        .toLowerCase()
        .localeCompare((components as any)[b][propertyName].toLowerCase());
    });
  });
}
export function normalizeEmpty(array?: Array<any> | null): Array<any> | undefined | null {
  return array && !array.length ? EMPTY_ARRAY : array;
}
