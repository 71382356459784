import _ from 'lodash';
import moment from 'moment-timezone';
import { Middleware } from 'redux';
/**
 * Attaches a timestamp to all of our asyncronous actions
 */

const timestampMiddleware: Middleware = () => (next: any) => (action: any) => {
  if (action.sequence) {
    // Can't use updeep, there's a number of places in our codebase that mutate
    //   the action value for testing purposes, and updeep freezes objects.

    // Would prefer to do it that way, but those need to be removed.
    const currentMeta = _.get(action, ['meta'], {});

    const meta = {
      ...currentMeta,
      timestamp: moment.now(),
    };

    return next({ ...action, meta });
  }
  return next(action);
};

export default timestampMiddleware;
