import { updateEditorState, discardData } from 'state/editor/actions/editorStateActions';

import { ComponentScope } from 'config/constants';

import type { SubtitlesEditorState } from 'views/subtitles/state/types/subtitles';

import type { AssetID } from 'types/assets';
import type { Dispatch } from 'types/redux';

export function updateSubtitlesState(subtitlesTrackId: AssetID, subtitlesEditorState: Partial<SubtitlesEditorState>) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(updateEditorState(ComponentScope.SUBTITLES, subtitlesTrackId, subtitlesEditorState));
    return Promise.resolve();
  };
}

export function discardSubtitlesState(subtitlesTrackId: AssetID) {
  return (dispatch: Dispatch): Promise<unknown> => {
    dispatch(discardData(ComponentScope.SUBTITLES, subtitlesTrackId));
    return Promise.resolve();
  };
}
