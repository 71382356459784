import { combineReducers } from 'redux';

import { createSequenceCountingReducer, createSequenceHandlingReducer } from 'state/common/reducerFactories';
import {
  CREATE_ORG_ONBOARDING,
  DELETE_ORG_ONBOARDING,
  LOAD_ORG_ONBOARDINGS_LIST,
  OrgOnboardingAction,
} from 'state/orgOnboardings/actions/orgOnboardingsActions';
import { OrgOnboardingsMap } from 'state/orgOnboardings/orgOnboardingsState';

import { EMPTY_OBJECT } from 'config/constants';
import u from 'utils/safeUpdeep';

import { OrgOnboarding } from 'types/orgOnboarding';

const onboardings = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  [LOAD_ORG_ONBOARDINGS_LIST, CREATE_ORG_ONBOARDING, DELETE_ORG_ONBOARDING],
  {
    start: (state: OrgOnboardingsMap) => {
      return state;
    },
    done: (state: OrgOnboardingsMap, action: OrgOnboardingAction) => {
      if (action.error) {
        return state;
      }
      switch (action.type) {
        case LOAD_ORG_ONBOARDINGS_LIST: {
          const onboardingsList: OrgOnboarding[] = action.payload as OrgOnboarding[];
          return Object.fromEntries(onboardingsList.map(onboarding => [onboarding.id, onboarding]));
        }
        case CREATE_ORG_ONBOARDING: {
          const onboarding: OrgOnboarding = action.payload as OrgOnboarding;
          return u({ [onboarding.id]: onboarding }, state);
        }
        case DELETE_ORG_ONBOARDING: {
          const onboarding: OrgOnboarding = action.payload as OrgOnboarding;
          return u(u.omit(onboarding.id), state);
        }
        default:
          return state;
      }
    },
  }
);

const isLoading = createSequenceCountingReducer([
  LOAD_ORG_ONBOARDINGS_LIST,
  CREATE_ORG_ONBOARDING,
  DELETE_ORG_ONBOARDING,
]);

export default combineReducers({
  onboardings,
  isLoading,
});
