import { AD_SLOTS_STAGE_CONFIG } from 'state/adSlots/stages/adSlotsStageConfig';
import { BITMOJI_REMOTE_VIDEO_STAGE_CONFIG } from 'state/bitmojiRemoteVideo/stages/bitmojiRemoteVideoStageConfig';
import { BITMOJI_REMOTE_WEB_STAGE_CONFIG } from 'state/bitmojiRemoteWeb/stages/bitmojiRemoteWebStageConfig';
import { CAMERA_ATTACHMENT_STAGE_CONFIG } from 'state/cameraAttachment/stages/cameraAttachmentStageConfig';
import { FEEDS_STAGE_CONFIG } from 'state/feeds/stages/feedsStageConfig';
import { LONGFORM_VIDEO_STAGE_CONFIG } from 'state/longformVideo/stages/longformVideoStageConfig';
import { POLL_STAGE_CONFIG } from 'state/poll/stages/pollStageConfig';
import { PUBLISHERS_STAGE_CONFIG } from 'state/publishers/stages/publishersStageConfig';
import { REMOTE_WEB_STAGE_CONFIG } from 'state/remoteWeb/stages/remoteWebStageConfig';
import { IMAGE_STAGE_CONFIG } from 'state/topsnap/stages/imageStageConfig';
import { VIDEO_STAGE_CONFIG } from 'state/topsnap/stages/videoStageConfig';

import { registerStageConfig } from './stagesRegistry';

export default function configureStages() {
  // Register your config here...
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ stageName: string; properties:... Remove this comment to see the full error message
  registerStageConfig(IMAGE_STAGE_CONFIG);
  registerStageConfig(VIDEO_STAGE_CONFIG);
  registerStageConfig(POLL_STAGE_CONFIG);
  registerStageConfig(REMOTE_WEB_STAGE_CONFIG);
  registerStageConfig(LONGFORM_VIDEO_STAGE_CONFIG);
  registerStageConfig(AD_SLOTS_STAGE_CONFIG);
  registerStageConfig(PUBLISHERS_STAGE_CONFIG);
  registerStageConfig(BITMOJI_REMOTE_WEB_STAGE_CONFIG);
  registerStageConfig(CAMERA_ATTACHMENT_STAGE_CONFIG);
  registerStageConfig(BITMOJI_REMOTE_VIDEO_STAGE_CONFIG);
  registerStageConfig(FEEDS_STAGE_CONFIG);
}
