import { arrayOf } from 'normalizr';
import { Dispatch } from 'redux';

import { getSnapcodeListPerPublisherId } from 'state/snapcodes/selectors/snapcodesSelectors';

import { snapcodeSchema } from '../schema/snapcodesSchema';

import { DeeplinkType } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import * as discoverAPI from 'utils/apis/discoverAPI';
import * as scsAPI from 'utils/apis/scsAPI';
import { assertArg } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';

import { assertLinkableId } from 'types/common';
import { PublisherID } from 'types/publishers';
import { GetState } from 'types/redux';

export const UPDATE_SNAPCODE = 'snapcodes/UPDATE_SNAPCODE';
export const CREATE_SNAPCODE = 'snapcodes/CREATE_SNAPCODE';
export const LOAD_SNAPCODES = 'snapcodes/LOAD_SNAPCODES';
export const FETCH_PREVIEW_SNAPCODE = 'snapcodes/FETCH_PREVIEW_SNAPCODE';
export const SNAPCODE_PREVIEW_QUALITY = 'snapcodes/SNAPCODE_PREVIEW_QUALITY';

const loadSnapcodes = ({ linkableType, linkableId }: any) => (dispatch: any) => {
  assertLinkableId(linkableId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(linkableType).is.inValues(DeeplinkType);

  // Publisher deeplinks are still handled by discover-service
  const endpoint =
    linkableType === DeeplinkType.PUBLISHER
      ? discoverAPI.snapcode.load({ linkableType, linkableId })
      : scsAPI.snapcode.load({ linkableType, linkableId });

  return dispatch({
    type: LOAD_SNAPCODES,
    meta: {
      [CALL_API]: {
        endpoint,
        method: 'get',
        finalizer: optimisticJsonFinalizer,
      },
      schema: arrayOf(snapcodeSchema),
    },
    params: {
      linkableType,
      linkableId,
    },
  });
};

export const loadSnapSnapcodes = (snapId: any) => (dispatch: any) => {
  return loadSnapcodes({ linkableType: DeeplinkType.SNAP, linkableId: snapId })(dispatch).catch(
    apiErrorHandler(dispatch, ErrorContexts.LOAD_SNAP_SNAPCODES)
  );
};

export const loadStorySnapcodes = (storyId: any) => (dispatch: any) => {
  return loadSnapcodes({ linkableType: DeeplinkType.EDITION, linkableId: storyId })(dispatch).catch(
    apiErrorHandler(dispatch, ErrorContexts.LOAD_STORY_SNAPCODES)
  );
};

export const loadPublisherSnapcodes = (publisherId: PublisherID) => (dispatch: Dispatch) => {
  return loadSnapcodes({ linkableType: DeeplinkType.PUBLISHER, linkableId: publisherId })(dispatch).catch(
    apiErrorHandler(dispatch, ErrorContexts.LOAD_PUBLISHER_SNAPCODES)
  );
};

export const loadPublisherSnapcodesUntilUpdated = (
  publisherId: PublisherID,
  currentSnapcode: number | null,
  callback: () => void,
  retries: number
) => async (dispatch: Dispatch, getState: GetState) => {
  if (retries < 0) {
    apiErrorHandler(dispatch, ErrorContexts.CREATE_PUBLISHER_SNAPCODE)(new Error('Retry limit reached'));
    callback();
    return;
  }

  await loadPublisherSnapcodes(publisherId)(dispatch);
  const snapcodes = getSnapcodeListPerPublisherId(getState())(publisherId);
  if (snapcodes.length > 0 && snapcodes[snapcodes.length - 1] !== currentSnapcode) {
    callback();
    return;
  }

  // Poll for changes after 2s.
  setTimeout(() => {
    loadPublisherSnapcodesUntilUpdated(publisherId, currentSnapcode, callback, retries - 1)(dispatch, getState);
  }, 2000);
};

const updateSnapcode = ({ linkableType, linkableId }: any, body: any) => (dispatch: any) => {
  assertLinkableId(linkableId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(linkableType).is.inValues(DeeplinkType);
  assertLinkableId(body.id);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(body.name).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(body.shareableHeadline).is.string.or.is.null();

  // Publisher deeplinks are still handled by discover-service
  const endpoint =
    linkableType === DeeplinkType.PUBLISHER
      ? discoverAPI.snapcode.update({ linkableType, linkableId, deeplinkId: body.id })
      : scsAPI.snapcode.update({ linkableType, linkableId, deeplinkId: body.id });

  return dispatch({
    type: UPDATE_SNAPCODE,
    meta: {
      [CALL_API]: {
        endpoint,
        method: 'post',
        finalizer: optimisticJsonFinalizer,
        body,
      },
      schema: snapcodeSchema,
    },
    params: {
      linkableType,
      linkableId,
      body,
    },
  });
};

export const updateSnapSnapcode = (snapId: any, body: any) => (dispatch: any) => {
  return updateSnapcode(
    { linkableType: DeeplinkType.SNAP, linkableId: snapId },
    body
  )(dispatch).catch(apiErrorHandler(dispatch, ErrorContexts.UPDATE_SNAP_SNAPCODE));
};

export const updateStorySnapcode = (storyId: any, body: any) => (dispatch: any) => {
  return dispatch(updateSnapcode({ linkableType: DeeplinkType.EDITION, linkableId: storyId }, body)).catch(
    apiErrorHandler(dispatch, ErrorContexts.UPDATE_STORY_SNAPCODE)
  );
};

const createSnapcode = ({ linkableType, linkableId }: any, body: any) => (dispatch: any) => {
  assertLinkableId(linkableId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(linkableType).is.inValues(DeeplinkType);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(body.id).is.undefined();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(body.name).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(body.shareableHeadline).is.string.or.is.null();

  // Publisher deeplinks are still handled by discover-service
  const endpoint =
    linkableType === DeeplinkType.PUBLISHER
      ? discoverAPI.snapcode.new({ linkableType, linkableId })
      : scsAPI.snapcode.new({ linkableType, linkableId });

  return dispatch({
    type: CREATE_SNAPCODE,
    meta: {
      [CALL_API]: {
        endpoint,
        method: 'post',
        finalizer: optimisticJsonFinalizer,
        body,
      },
      schema: snapcodeSchema,
    },
    params: {
      linkableType,
      linkableId,
      body,
    },
  });
};

export const createSnapSnapcode = (snapId: any, body: any) => (dispatch: any) => {
  return createSnapcode(
    { linkableType: DeeplinkType.SNAP, linkableId: snapId },
    body
  )(dispatch).catch(apiErrorHandler(dispatch, ErrorContexts.CREATE_SNAP_SNAPCODE));
};

export const createPublisherSnapcode = (publisherId: any, body: any) => (dispatch: any) => {
  const snapcodeBody = {
    shareableHeadline: null,
    ...body,
  };
  return createSnapcode(
    { linkableType: DeeplinkType.PUBLISHER, linkableId: publisherId },
    snapcodeBody
  )(dispatch).catch(apiErrorHandler(dispatch, ErrorContexts.CREATE_PUBLISHER_SNAPCODE));
};

export const fetchEditionPreviewSnapcode = (editionId: any, buildSetting: any) => (dispatch: any) => {
  return dispatch({
    type: FETCH_PREVIEW_SNAPCODE,
    meta: {
      [CALL_API]: {
        endpoint: scsAPI.snapcode.preview({
          linkableType: DeeplinkType.EDITION,
          linkableId: editionId,
          buildSetting,
        }),
        finalizer: optimisticJsonFinalizer,
      },
    },
  });
};

export const setSnapcodePreviewQuality = (quality: any) => (dispatch: any) => {
  return dispatch({
    type: SNAPCODE_PREVIEW_QUALITY,
    payload: {
      quality,
    },
  });
};
