import is from 'is_js';
import { get } from 'lodash';
import log from 'loglevel';
import { Dispatch, Middleware } from 'redux';

import { toErrorAction } from 'utils/middlewareUtils';

// This middleware lives at the start of the chain and catches any synchronous errors or uncaught rejected promises
// that have not been handled by previous middlewares. The former case throws the error; the latter case logs a
// message to the console for developer awareness, but passes the rejected promise through in case the calling code
// has a catch block.
//
// It is also responsible for dispatching an 'error action', i.e. an action that has the error attached to it,
// so that this may be handled by reducers. This is done once, here, rather than being done in a specific middleware,
// as this avoids situations whereby an error action may be dispatched multiple times as an error propagates up
// through the middleware stack.
export default function errorsMiddleware(errorLogger = logError): Middleware {
  return (store: any) => (next: Dispatch) => (action: any) => {
    return cautiouslyCallNext(next, action, { isErrorAction: false });
  };

  function cautiouslyCallNext(next: any, action: any, { isErrorAction = false } = {}) {
    try {
      const returnValue = next(action);

      // If the action returned a promise, add a rejection handler that will log
      // the error and attempt to dispatch an error action.
      if (is.object(returnValue) && is.function(returnValue.catch)) {
        return returnValue.catch((caughtObj: any) => {
          const errorAction = toErrorAction(caughtObj, action);

          // Using a copy of the error in the action because some reducers freeze it
          // and make it impossible for the error object to be modified afterwards
          const { error } = errorAction;
          errorAction.error = createNewError(error);
          if (!isFrozen(errorAction)) {
            Object.assign(errorAction.error, error); // Copy own properties
          }

          errorLogger(`Promise rejected during dispatch of ${action.type}`, error, action, isErrorAction);

          // Give reducers a chance to handle the error by dispatching the error
          // action. Note that we guard this by checking whether this call itself
          // was an error action, in order to prevent infinite recursion.
          if (!isErrorAction) {
            cautiouslyCallNext(next, errorAction, { isErrorAction: true });
          }

          // Reject with the actual error object so that the calling code can
          // implement any specific failure handling that it needs to.
          return Promise.reject(error);
        });
      }

      return returnValue;
    } catch (error) {
      errorLogger(`Error thrown during dispatch of ${action.type}`, error, action, isErrorAction);

      throw error;
    }
  }
}

function isFrozen(errorAction: any) {
  const frozenError = Object.isFrozen(errorAction.error);
  const hasIsTrustedAttribute = is.existy(get(errorAction, ['error', 'isTrusted']));
  return frozenError || (hasIsTrustedAttribute && Object.isFrozen(get(errorAction, ['error', 'isTrusted'])));
}

function createNewError(error: any) {
  try {
    // Some "error" objects can be contructed like this...
    return error.constructor(error.message, error.fileName, error.lineNumber);
  } catch (constructionError) {
    // ...however, some "error" objects (e.g. ProgressEvent) will throw an error if not contructed like this
    // with an error message along the lines of:
    // Uncaught TypeError: Failed to construct 'ProgressEvent':
    //   Please use the 'new' operator, this DOM object constructor cannot be called as a function
    return new error.constructor(error.message, error.fileName, error.lineNumber);
  }
}

function logError(intro: any, error: any, action: any, isErrorAction: any) {
  if (__DEBUG__) {
    const args = [intro, '\n  Error:', error, '\n  Action:', action];

    if (isErrorAction) {
      args.push('\nThis was the error action dispatch – will not try again');
    } else {
      args.push('\nWill now try to dispatch an error action with the same action type');
    }

    log.error(...args);
  }
}
