import { includes, compact } from 'lodash';

import { LocalStorage } from 'config/constants';
import { localStorage } from 'utils/localStorageUtils';

export function toggleForcedRole(role: any) {
  const roles = getForcedRoles();
  const containsRole = includes(roles, role);
  if (containsRole) {
    roles.splice(roles.indexOf(role), 1);
  } else {
    roles.push(role);
  }
  localStorage.setItem(LocalStorage.FORCED_ROLES, roles.join(','));
}
export function clearForcedRoles() {
  localStorage.removeItem(LocalStorage.FORCED_ROLES);
}
export function hasForcedRole(role: any) {
  return includes(getForcedRoles(), role);
}
export function getForcedRoles() {
  return compact((localStorage.getItem(LocalStorage.FORCED_ROLES) || '').split(','));
}
export function getForcedRolesLabel() {
  return getForcedRoles()
    .map(r => (r as any)[0].toUpperCase() + (r as any).slice(1, (r as any).length).toLowerCase())
    .join(', ');
}
export function hasAnyForcedRoles() {
  return getForcedRoles().length !== 0;
}
