import { gql } from '@apollo/client';

export const ADMIN_PUBLISHER_FIELDS = gql`
  fragment AdminPublisherFields on Publisher {
    id
    businessProfileId
    mutablePublisherName
    enabledFeatures
  }
`;

export const ADMIN_GET_PUBLISHER = gql`
  ${ADMIN_PUBLISHER_FIELDS}
  query AdminPublisherDetailsQuery($publisherId: ID!) {
    publisher(id: $publisherId) {
      ...AdminPublisherFields
    }
  }
`;
