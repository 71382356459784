import _ from 'lodash';
import moment from 'moment-timezone';

import type { CreateStoryParams } from 'state/editions/actions/editionsActions';
import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as episodesActions from 'state/episodes/actions/episodesActions';
import * as episodesSelectors from 'state/episodes/selectors/episodesSelectors';
import * as extrasActions from 'state/extras/actions/extrasActions';
import * as extrasSelectors from 'state/extras/selectors/extrasSelectors';
import { isNewUserExperienceEnabled } from 'state/features/selectors/featuresSelectors';
import * as homepageActions from 'state/homepage/actions/homepageActions';
import { HomepageSortBy } from 'state/homepage/selectors/homepageSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as modalsActions from 'state/modals/actions/modalsActions';
import * as routerActions from 'state/router/actions/routerActions';
import { openNewUserExperienceHelpModal } from 'state/user/actions/userActions';
import { isRecentlyCreatedUser } from 'state/user/selectors/userSelectors';

import type { EditionScheduleEnum } from 'config/constants';
import { LocalStorage, SortDirection } from 'config/constants';
import { assertArg, assertState } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { ModalType } from 'utils/modalConfig';

import { HelpModalTypes } from 'views/modals/containers/NewUserExperienceHelpModal/NewUserExperienceHelpModal';

import type { EditionID } from 'types/editionID';
import { StoryState, DiscardStoryMedatada } from 'types/editions';
import type { BusinessProfileID } from 'types/publishers';
import type { Dispatch, GetState } from 'types/redux';
import { State } from 'types/rootState';
import type { EditableEpisodeProperties } from 'types/shows';
import { PostingStoryType } from 'types/storySnapEditor';

type CreateParams = CreateStoryParams;
export const initializeHomepage = ({
  publisherId,
  parentPromise,
}: {
  parentPromise: any;
  publisherId: number;
}) => async (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  await parentPromise;
  await dispatch(
    homepageActions.getDisplayStories({
      publisherId,
      keepExistingStories: false,
      fetchOptions: {
        sortBy: HomepageSortBy.CREATED_AT,
        sortDirection: SortDirection.DESCENDING,
        state: [],
        startDate: null,
        endDate: null,
        titleSearch: '',
      },
      batchSize: homepageActions.DEFAULT_BATCH_SIZE,
    })
  );
};
export const moveStoryToDrafts = ({ storyId }: { storyId: number }) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(storyId).is.number();
  return dispatch(editionsActions.setEditionState(storyId, StoryState.NEW)).catch(
    apiErrorHandler(dispatch, ErrorContexts.SET_EDITION_STATE)
  );
};
const isErrorRegardingScheduledStartDateInPast = (error: any) => {
  const errorMessage = _.get(error, ['fetchResults', 'data', 'message']);
  return errorMessage ? errorMessage.includes("Can't set startDate to past datetime") : false;
};
const isErrorRegardingSchedulingTooCloseToAnotherEdition = (error: any) => {
  const errorMessage = _.get(error, ['fetchResults', 'data', 'message']);
  return errorMessage ? errorMessage.includes('startDate overlaps with existing edition') : false;
};
type MoveStoryToScheduledArgs = {
  startDate: string;
  storyId: number;
  endDate?: string | null;
  scheduleType: EditionScheduleEnum;
};
export const moveStoryToScheduled = ({ storyId, startDate, endDate, scheduleType }: MoveStoryToScheduledArgs) => (
  dispatch: Dispatch,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(storyId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(startDate).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endDate).is.null.or.is.undefined.or.is.string();
  return dispatch(editionsActions.scheduleEdition(storyId, startDate, endDate, scheduleType)).catch(error => {
    if (isErrorRegardingScheduledStartDateInPast(error) || isErrorRegardingSchedulingTooCloseToAnotherEdition(error)) {
      return apiErrorHandler(dispatch, ErrorContexts.SET_EDITION_STATE_INVALID_DATE)(error);
    }
    return apiErrorHandler(dispatch, ErrorContexts.SET_EDITION_STATE)(error);
  });
};

function canShowStoryHelpModal(state: State) {
  return (
    !localStorage.getItem(LocalStorage.DONT_SHOW_HELP_MODALS_STORY) &&
    isNewUserExperienceEnabled(state) &&
    isRecentlyCreatedUser(state)
  );
}

const createStoryAndFocusReject = ({
  publisherId,
  title,
  numberOfSnaps,
  singleAssetVideoFile,
  singleAssetVideoId,
  postingStoryType,
}: any) => async (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(title).is.string.and.is.truthy();
  await dispatch(
    routerActions.goToNewEditionSpinner({
      publisherId,
      overwriteHistory: false,
    })
  );
  let firstFrameKey;
  if (singleAssetVideoFile) {
    const grabFrameResult = await dispatch(mediaActions.grabFrameOnce(singleAssetVideoFile, 360, 640));
    firstFrameKey = grabFrameResult.frameKey;
  }
  dispatch(editionsActions.setNewStoryInfo({ firstFrameKey, title }));
  try {
    const createAction = await dispatch(
      editionsActions.createStory({
        publisherId,
        title,
        numberOfSnaps,
        singleAssetVideoFile,
        singleAssetVideoId,
        postingStoryType,
      })
    );
    const editionId = createAction.payload.result;
    await dispatch(
      routerActions.goToPublisherStoryEditor({
        publisherId,
        editionId,
        overwriteHistory: true,
      })
    );
    if (canShowStoryHelpModal(getState())) {
      await dispatch(openNewUserExperienceHelpModal('StoryCreation', HelpModalTypes.NEW_STORY_MODAL));
    }
    return createAction;
  } catch (e) {
    dispatch(routerActions.goBack());
    throw e;
  } finally {
    dispatch(editionsActions.setNewStoryInfo(null));
  }
};
export const createStoryAndFocus = (params: CreateParams) => (dispatch: Dispatch, getState: GetState) =>
  dispatch(createStoryAndFocusReject(params)).catch(apiErrorHandler(dispatch, ErrorContexts.CREATE_STORY));
export const createStory = (params: CreateParams) => (dispatch: Dispatch, getState: GetState) => {
  return dispatch(exports.createStoryAndFocus(params));
};
export const createStoryPrompt = ({
  publisherId,
  withStoryTypeOption,
  postingStoryType = PostingStoryType.UNKNOWN,
}: {
  publisherId: number;
  withStoryTypeOption: boolean;
  postingStoryType?: PostingStoryType;
}) => (dispatch: Dispatch, getState: GetState) =>
  dispatch(
    modalsActions.showModal(ModalType.CREATE_STORY, 'createStoryPrompt', {
      withStoryTypeOption,
      modalType: ModalType.CREATE_STORY,
      existingTitle: moment().format('YYYY-MM-DD HH:mm:ss'),
      'data-test': 'publisherTools.createNewStory',
      onSave: (title: any, singleAssetVideoFile: any) =>
        dispatch(
          createStory({
            publisherId,
            title,
            singleAssetVideoFile,
            postingStoryType,
          })
        ),
    })
  );
export type CreateOrUpdateEpisodeWithEpisodeMetadataParams = {
  id: string;
  isPartOfShow: boolean;
  title: string;
  numberOfSnaps: number;
  showId: string;
  seasonId: string;
  seasonNumber: number;
  seasonDisplayName?: string | null;
  episodeNumber: number | undefined | null;
  singleAssetVideoId: string;
  isStoryEditable: boolean | undefined | null;
};
export type ScheduleEpisodeParams = {
  id: EditionID;
  title: string;
  showId: string;
  seasonId: string | undefined | null;
  seasonNumber: number;
  seasonDisplayName?: string | null;
  episodeNumber: number;
};
export type CreateOrUpdateEpisodeParams = {
  title: string;
  numberOfSnaps: number;
  singleAssetVideoId: string;
};
export type ReplaceStoryMediaParams = {
  discardMetadata: DiscardStoryMedatada;
  singleAssetVideoId: string;
};
export const createEpisodePrompt = ({
  publisherId,
  businessProfileId,
}: {
  publisherId: number;
  businessProfileId: BusinessProfileID;
}) => {
  return (dispatch: Dispatch, getState: GetState) =>
    dispatch(
      modalsActions.showModal(ModalType.CREATE_EPISODE, 'createEpisodePrompt', {
        modalType: ModalType.CREATE_EPISODE,
        existingTitle: moment().format('YYYY-MM-DD HH:MM:SS'),
        'data-test': 'publisherTools.createNewEpisode',
        onSaveEpisodeOrExtra: async (params: CreateOrUpdateEpisodeWithEpisodeMetadataParams) => {
          const action = await dispatch(
            createStory({
              publisherId,
              title: params.title,
              numberOfSnaps: params.numberOfSnaps,
              singleAssetVideoId: params.singleAssetVideoId,
            })
          );
          const { seasonNumber, seasonDisplayName, isPartOfShow, episodeNumber } = params;
          const storyId = String((action as any).payload.result);
          if (isPartOfShow && Number.isInteger(seasonNumber)) {
            if (typeof episodeNumber === 'number') {
              const episodeProperties = toEpisodeProperties(params, episodeNumber);
              await dispatch(
                episodesActions.createEpisode(publisherId, businessProfileId, seasonNumber, seasonDisplayName, {
                  ...episodeProperties,
                  id: storyId,
                })
              );
            } else {
              // if we don't have an episode number, it means that we're creating an extra
              await dispatch(
                extrasActions.createExtra(publisherId, businessProfileId, seasonNumber, seasonDisplayName, {
                  ...params,
                  id: storyId,
                })
              );
            }
          }
        },
        onSaveStory: (params: CreateOrUpdateEpisodeParams) => {
          // in episode scheduling V2, we create a simple story and at the point of scheduling it gets assigned an episode number & season
          dispatch(
            createStory({
              publisherId,
              title: params.title,
              numberOfSnaps: params.numberOfSnaps,
              singleAssetVideoId: params.singleAssetVideoId,
            })
          );
        },
      })
    );
};
export const updateEpisodePrompt = ({
  publisherId,
  businessProfileId,
  storyId,
}: {
  publisherId: number;
  businessProfileId: BusinessProfileID;
  storyId: number;
}) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const episode = episodesSelectors.getEpisodeById(getState())(storyId);
    const edition = editionsSelectors.getEditionById(getState())(storyId);
    const extra = extrasSelectors.getExtraById(getState())(storyId);
    const hasEpisode = Boolean(episode);
    const hasExtra = Boolean(extra);
    if (!edition) {
      return Promise.resolve();
    }
    return dispatch(
      modalsActions.showModal(ModalType.UPDATE_EPISODE, 'updateEpisodePrompt', {
        modalType: ModalType.UPDATE_EPISODE,
        existingTitle: edition.title,
        isUpdateModal: true,
        onSaveEpisodeOrExtra: async (params: CreateOrUpdateEpisodeWithEpisodeMetadataParams) => {
          if (params.isStoryEditable) {
            await dispatch(
              editionsActions.setEditionPropertiesAndSave({ editionId: storyId }, { title: params.title })
            );
          }
          const { episodeNumber } = params;
          if (params.isPartOfShow && Number.isInteger(params.seasonNumber)) {
            // if an episode is already present, we're just updating it
            if (hasEpisode && typeof episodeNumber === 'number') {
              const episodeProperties = toEpisodeProperties(params, episodeNumber);
              return dispatch(
                episodesActions.updateEpisode(publisherId, businessProfileId, params.seasonNumber, episodeProperties)
              );
            }
            if (typeof episodeNumber === 'number') {
              const episodeProperties = toEpisodeProperties(params, episodeNumber);
              return dispatch(
                episodesActions.createEpisode(
                  publisherId,
                  businessProfileId,
                  params.seasonNumber,
                  params.seasonDisplayName,
                  { ...episodeProperties, id: String(storyId) }
                )
              );
            }
            return dispatch(
              extrasActions.createExtra(publisherId, businessProfileId, params.seasonNumber, params.seasonDisplayName, {
                ...params,
                id: String(storyId),
              })
            );
          }
          if (hasEpisode && typeof episodeNumber === 'number' && !params.isPartOfShow) {
            const episodeProperties = toEpisodeProperties(params, episodeNumber);
            return dispatch(episodesActions.unlinkEpisode(publisherId, businessProfileId, episodeProperties));
          }
          if (hasExtra && !params.isPartOfShow) {
            return dispatch(extrasActions.unlinkExtra(publisherId, businessProfileId, params));
          }
          return Promise.resolve();
        },
        onSaveStory: (params: CreateOrUpdateEpisodeParams) => {
          dispatch(editionsActions.setEditionPropertiesAndSave({ editionId: storyId }, { title: params.title }));
        },
      })
    );
  };
};

export const handleOpenNewContentPrompt = () => {
  return (dispatch: Dispatch) => {
    return dispatch(
      modalsActions.showModal(ModalType.CREATE_CONTENT, 'createContentPrompt', {
        modalType: ModalType.CREATE_CONTENT,
      })
    );
  };
};

export function toEpisodeProperties(
  params: CreateOrUpdateEpisodeWithEpisodeMetadataParams,
  episodeNumber: number
): EditableEpisodeProperties {
  return {
    episodeNumber,
    showId: params.showId,
    seasonId: params.seasonId,
    title: params.title,
    id: params.id,
  };
}
export const renameStoryPrompt = ({ storyId }: { storyId: any }) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(storyId).is.number.or.is.string();
  const story = editionsSelectors.getEditionById(getState())(storyId);
  if (!story) {
    throw new Error(`Missing story ${storyId}`);
  }
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(story).is.object();
  const existingTitle = story.title;
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(existingTitle).is.string();
  return dispatch(
    modalsActions.showModal(ModalType.RENAME_STORY, 'renameStoryPrompt', {
      withStoryTypeOption: false,
      existingTitle,
      modalType: ModalType.RENAME_STORY,
      'data-test': 'publisherTools.renameStory',
      onSave: (title: any) => {
        dispatch(editionsActions.setEditionPropertiesAndSave({ editionId: storyId }, { title }));
      },
    })
  );
};
