import { editionSchema } from 'state/editions/schema/editionsSchema';
import { generateEditionTileIds } from 'state/tiles/schema/tilesIdUtils';

import { createCallAction } from '../../apiMiddleware/actions/apiMiddlewareActions';

import * as scsAPI from 'utils/apis/scsAPI';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';

import { GET_EDITION } from 'types/actions/editionsActionTypes';
import type { EditionID } from 'types/editions';
import { Dispatch } from 'types/redux';

const noBailout = () => false;
export const getEdition = ({ editionId, bailout = noBailout }: { editionId: EditionID; bailout?: () => boolean }) => (
  dispatch: Dispatch
) => {
  return dispatch(
    createCallAction(
      {
        type: GET_EDITION,
        params: { editionId },
        bailout,
        meta: {
          schema: editionSchema,
        },
      },
      {
        endpoint: scsAPI.story.existing({ editionId }),
        finalizer: generateEditionTileIds,
      }
    )
  ).catch(apiErrorHandler(dispatch, ErrorContexts.GET_EDITION));
};
