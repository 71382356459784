// TODO add flow annotation. https://jira.sc-corp.net/browse/PUB-7857
import _ from 'lodash';

import * as userSelectors from 'state/user/selectors/userSelectors';

import { GetState } from 'src/types/redux';
import { assertArg } from 'utils/assertionUtils';
import type { ErrorContextEnum } from 'utils/errors/errorConstants';
import { incrementCounter, incrementCounterByPublisher } from 'utils/grapheneUtils';

import type { Notification } from 'types/notifications';

// Notification messages are not tied to a specific place in redux store, can be reduced to e.g.,
// toast messages queue, notification centre messages queue, etc.
export const ADD_NOTIFICATION_MESSAGE = 'notifications/ADD_NOTIFICATION_MESSAGE';
export const DELETE_TOAST_MESSAGE = 'notifications/DELETE_TOAST_MESSAGE';
export const SET_INVALID_AD_TOAST = 'notifications/SET_INVALID_AD_TOAST';

let nextSequence = 0;

export const sendNotificationMessage = (message: Notification, params: { context: ErrorContextEnum }) => {
  incrementCounter('notification.message', {
    severity: message.severity,
    scope: message.scope,
  });
  // Create unique sequence id so that message can be referenced later for deletion
  const id = nextSequence++;

  return (dispatch: any, getState: GetState) => {
    const activePublisher = userSelectors.getActivePublisher(getState());
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: null; primaryColorRgb: num... Remove this comment to see the full error message
    incrementCounterByPublisher(activePublisher, 'notification.message', {
      severity: message.severity,
      scope: message.scope,
    });
    // Generating a translated string depending on locale
    const plainMessage = message.plainMessage;

    if (message.richMessage) {
      const results = message.richMessage;
      return dispatch({
        type: ADD_NOTIFICATION_MESSAGE,
        payload: {
          id,
          timestamp: Date.now(),
          messages: results,
          ..._.omit(message, ['plainMessage', 'richMessage']),
          params,
        },
      });
    }

    // wrapper the single plainMessage into richMessage format
    if (plainMessage) {
      return dispatch({
        type: ADD_NOTIFICATION_MESSAGE,
        payload: {
          id,
          timestamp: Date.now(),
          messages: [{ plainText: plainMessage }],
          ..._.omit(message, ['plainMessage', 'richMessage']),
          params,
        },
      });
    }

    return Promise.resolve();
  };
};

export const deleteToastMessage = (message: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(message).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(message.id).is.number();
  const payload = { id: message.id };

  return (dispatch: any, getState: GetState) => {
    return dispatch({
      type: DELETE_TOAST_MESSAGE,
      payload,
    });
  };
};

export const setInvalidAdToast = (invalidAdToastOpened: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(invalidAdToastOpened).is.boolean();

  return {
    type: SET_INVALID_AD_TOAST,
    invalidAdToastOpened,
  };
};
