/* eslint-disable spaced-comment, no-redeclare */

import loadable from '@loadable/component';
import { withSentryRouting } from '@sentry/react';
import * as React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';

import { PlatformProvider } from 'context/platform/platformContext';
import { PublisherQueryType } from 'gql/types/publisherQueryTypeEnum';
import { getValues } from 'utils/objectUtils';
import {
  clearAnalyticsHook,
  clearEditionHook,
  clearMediaLibraryHook,
  clearStoreAndSanitizeTicketIfAny,
  clearStoryAnalyticsHook,
  clearStoryPreloadGapiAndSanitizeTicketIfAny,
  clearTopsnapHook,
  preloadActiveEditions,
  requireClaim,
  requireCreatorAndPublisherInfoHook,
  requirePublisherInfoAndEditionHook,
  requireEditionHook,
  requireFeaturesManagementHook,
  requireLoginAndUserInfoHook,
  requireLoginHook,
  requireSnapHook,
  requireUncachedPublishersHook,
  requireUserInfoHook,
  zendeskLoginHook,
  zendeskLogoutHook,
  requireOrgOnboardingsHook,
  enterShowsHook,
  enterHomePageForCreatorHook,
  redirectToCreatorFromPublisherHook,
  requireLoginAndHostUsernameHook,
} from 'utils/router/routeHooks';
import { gaTiming } from 'utils/router/routerUtils';

import { ANALYTICS_TABS } from 'views/analytics/containers/AnalyticsView/analyticsTabsConfig';
import ChromelessLayout from 'views/common/components/ChromelessLayout/ChromelessLayout';
import LoadingView from 'views/common/components/LoadingView/LoadingView';
import App from 'views/common/containers/App/App';
import MobileSnapNavLayout from 'views/common/containers/MobileSnapNavLayout/MobileSnapNavLayout';
import SnapNavLayout from 'views/common/containers/SnapNavLayout/SnapNavLayout';
import PlatformRenderer from 'views/common/platform/PlatformRenderer';
import { requirePublisherDebugInfo } from 'views/debug/utils/debugRouteHooks';
import UserAssociationReceiverView from 'views/onboarding/containers/UserAssociationReceiverView/UserAssociationReceiverView';
import GatedRoute from 'views/routes/GatedRoute';

import ReloadPageOnError from './ReloadPageOnError';

import { Claim } from 'types/permissions';
import type { Store } from 'types/redux';
import { SnapType } from 'types/snaps';

const fallback = <LoadingView messageId="loading-message-generic" />;

const PublisherUserManagement = loadable(
  () => import('views/adminTools/containers/PublisherUserManagement/PublisherUserManagement'),
  { fallback }
);
const AnalyticsInsightsReportWrapper = loadable(
  () => import('views/analytics/components/AnalyticsInsightsPublisherReport/AnalyticsInsightsReportWrapper'),
  { fallback }
);
const AnalyticsEditionView = loadable(
  () => import('views/analytics/containers/AnalyticsEditionSwitch/AnalyticsEditionSwitch'),
  { fallback }
);

const AnalyticsView = loadable(() => import('views/analytics/containers/AnalyticsView/AnalyticsView'), { fallback });
const LoginWithGoogle = loadable(() => import('views/auth/containers/LoginWithGoogle/LoginWithGoogle'), { fallback });
const LoginWithSnapAuth = loadable(() => import('views/auth/containers/LoginWithSnapAuth/LoginWithSnapAuth'), {
  fallback,
});
const UnsupportedBrowser = loadable(() => import('views/common/components/UnsupportedBrowser/UnsupportedBrowser'), {
  fallback,
});
const NoPermissionView = loadable(() => import('views/common/containers/NoPermissionView/NoPermissionView'), {
  fallback,
});
const NotFoundView = loadable(() => import('views/common/containers/NotFoundView/NotFoundView'), { fallback });
const SpotlightUploaderView = loadable(() => import('views/spotlight/SpotlightUploaderView/SpotlightUploaderView'), {
  fallback,
});
const CreativeSuiteTemplates = loadable(() => import('views/creative/CreativeSuiteTemplates/CreativeSuiteTemplates'), {
  fallback,
});
const WithNewUserExperienceHelpModal = loadable(
  () => import('views/common/components/WithHelpModal/WithNewUserExperienceHelpModal'),
  {
    fallback,
  }
);
const DebugIcons = loadable(() => import('views/debug/DebugIcons/DebugIcons'), { fallback });
const DebugSDSBoxes = loadable(() => import('views/debug/DebugSDSBoxes/DebugSDSBoxes'), { fallback });
const DebugSDSButtons = loadable(() => import('views/debug/DebugSDSButtons/DebugSDSButtons'), { fallback });
const DebugSDSIcons = loadable(() => import('views/debug/DebugSDSIcons/DebugSDSIcons'), { fallback });
const DebugSDSTooltips = loadable(() => import('views/debug/DebugSDSTooltips/DebugSDSTooltips'), { fallback });
const DebugSearch = loadable(() => import('views/debug/DebugSearch/DebugSearch'), { fallback });
const DebugEditionView = loadable(() => import('views/debug/containers/DebugEditionView'), { fallback });
const DebugPublisherView = loadable(() => import('views/debug/containers/DebugPublisherView'), { fallback });
const DebugSnapView = loadable(() => import('views/debug/containers/DebugSnapView'), { fallback });
const CreateNewOrg = loadable(() => import('views/organisations/containers/CreateNewOrg/CreateNewOrg'), { fallback });
const NewStoryView = loadable(() => import('views/publisherStoryEditor/containers/NewStoryView/NewStoryView'), {
  fallback,
});
const AdminUserManagement = loadable(
  () => import('views/snapAdmin/containers/AdminUserManagement/AdminUserManagement'),
  { fallback }
);
const FeaturesManagement = loadable(() => import('views/snapAdmin/containers/FeaturesManagement/FeaturesManagement'), {
  fallback,
});
const NewPublisherPage = loadable(() => import('views/snapAdmin/containers/NewPublisherPage/NewPublisherPage'), {
  fallback,
});
const NotificationBannerPage = loadable(
  () => import('views/snapAdmin/containers/NotificationBannerPage/NotificationBannerPage'),
  { fallback }
);
const SupportTicketForm = loadable(() => import('views/supportTickets/SupportTicketForm/SupportTicketForm'), {
  fallback,
});
const StoryEditorSwitch = loadable(
  () => import('views/singleAssetStoryEditor/containers/StoryEditorSwitch/StoryEditorSwitch'),
  { fallback }
);
const OrgOnboardingsManagement = loadable(
  () => import('views/snapAdmin/containers/OrgOnboardingsManagement/OrgOnboardingsManagement'),
  { fallback }
);
const MediaLibrary = loadable(() => import('views/common/containers/MediaLibrary/MediaLibrary'), { fallback });
const ShowsView = loadable(() => import('views/shows/containers/ShowsView/ShowsView'), { fallback });
const SettingsView = loadable(() => import('views/onboarding/containers/SettingsView/SettingsView'), { fallback });
const MobileStoryView = loadable(() => import('views/common/containers/MobileStoryView/MobileStoryView'), { fallback });
const HomepageSwitch = loadable(() => import('views/homepage/containers/HomepageSwitch/HomepageSwitch'), { fallback });
const MobileHomepageView = loadable(() => import('views/homepage/containers/MobileHomepageView/MobileHomepageView'), {
  fallback,
});

// Due to the blocked updates issue with react-router, we need to wrap
// the platform provider with the `withRouter` so that it re-renders when
// there is a location change. This will be fixed after they integrate the new
// context API. See: https://github.com/ReactTraining/react-router/issues/5901

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'typeof PlatformProvider' is not ... Remove this comment to see the full error message
const PlatformProviderUnblocked = withRouter(PlatformProvider);

// Create Custom Sentry Route component
const SentryRoute = withSentryRouting(Route);

const routes = (store: Store) => (
  <PlatformProviderUnblocked>
    <App>
      <UnsupportedBrowser>
        <Switch>
          <GatedRoute path="/debug" onEnter={requireLoginHook(store)}>
            <ChromelessLayout>
              <Switch>
                <SentryRoute exact path="/debug/snap/:snapId" component={DebugSnapView} />
                <GatedRoute
                  exact
                  path="/debug/user/:hostUsername"
                  onEnter={requirePublisherDebugInfo(store)}
                  component={DebugPublisherView}
                />
                <GatedRoute
                  path="/debug/user/:hostUsername/"
                  onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Root)}
                >
                  <SentryRoute exact path="/debug/user/:hostUsername/snap/:snapId" component={DebugSnapView} />
                  <SentryRoute
                    exact
                    path="/debug/user/:hostUsername/edition/:editionId/snap/:snapId"
                    component={DebugSnapView}
                  />
                  <SentryRoute
                    exact
                    path="/debug/user/:hostUsernamed/edition/:editionId"
                    component={DebugEditionView}
                  />
                </GatedRoute>
                <SentryRoute exact path="/debug/edition/:editionId" component={DebugEditionView} />
                <SentryRoute exact path="/debug/search" component={DebugSearch} />
                <SentryRoute exact path="/debug/icons" component={DebugIcons} />
                <SentryRoute exact path="/debug/icons/sds" component={DebugSDSIcons} />
                <SentryRoute exact path="/debug/buttons/sds" component={DebugSDSButtons} />
                <SentryRoute exact path="/debug/boxes/sds" component={DebugSDSBoxes} />
                <SentryRoute exact path="/debug/tooltips/sds" component={DebugSDSTooltips} />
              </Switch>
            </ChromelessLayout>
          </GatedRoute>

          <GatedRoute
            exact
            path="/"
            onEnter={requireLoginAndHostUsernameHook(store)}
            loadingView={<LoadingView messageId="loading-message-generic" />}
          />

          <GatedRoute
            exact
            path="/login"
            component={LoginWithSnapAuth}
            onEnter={clearStoreAndSanitizeTicketIfAny(store)}
          />

          <GatedRoute
            exact
            path="/login/sc"
            component={LoginWithGoogle}
            onEnter={clearStoryPreloadGapiAndSanitizeTicketIfAny(store)}
          />

          <GatedRoute
            exact
            path="/login/snap"
            component={LoginWithSnapAuth}
            onEnter={clearStoreAndSanitizeTicketIfAny(store)}
          />
          <GatedRoute
            exact
            path="/login/snap_continue"
            loadingView={<LoadingView />}
            onEnter={requireUserInfoHook(store)}
          />
          <GatedRoute exact path="/login/ticket" component={UserAssociationReceiverView} />

          <SentryRoute exact path="/notFound">
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <NotFoundView />
            </PlatformRenderer>
          </SentryRoute>

          <SentryRoute exact path="/noPermission">
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <NoPermissionView />
            </PlatformRenderer>
          </SentryRoute>

          <GatedRoute exact path="/create/:publisherId" component={CreateNewOrg} />

          <GatedRoute exact path="/zendesk/login" loadingView={<LoadingView />} onEnter={zendeskLoginHook(store)} />
          <GatedRoute exact path="/zendesk/logout" loadingView={<LoadingView />} onEnter={zendeskLogoutHook(store)} />

          <GatedRoute
            exact
            path="/publisher/:publisherId/home"
            onEnter={requireLoginHook(store)}
            ignoreHooksOnUpdate
            loadingView={<LoadingView messageId="loading-message-generic" />}
          >
            <GatedRoute
              exact
              path="/publisher/:publisherId/home"
              onEnter={redirectToCreatorFromPublisherHook(store)}
              ignoreHooksOnUpdate
              loadingView={<LoadingView messageId="loading-message-generic" />}
            >
              <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
                <WithNewUserExperienceHelpModal>
                  <GatedRoute
                    path="/publisher/:publisherId/home"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-generic" />}
                  >
                    <PlatformRenderer desktop={HomepageSwitch} mobile={MobileHomepageView} />
                  </GatedRoute>
                </WithNewUserExperienceHelpModal>
              </PlatformRenderer>
            </GatedRoute>
          </GatedRoute>

          <GatedRoute
            path="/user/:hostUsername/noPermission"
            onEnter={requireLoginHook(store)}
            ignoreHooksOnUpdate
            loadingView={<LoadingView messageId="loading-message-generic" />}
          >
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <NoPermissionView />
            </PlatformRenderer>
          </GatedRoute>

          <GatedRoute
            exact
            path="/user/:hostUsername/home"
            onEnter={requireLoginHook(store)}
            ignoreHooksOnUpdate
            loadingView={<LoadingView messageId="loading-message-generic" />}
          >
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <WithNewUserExperienceHelpModal>
                <GatedRoute
                  path="/user/:hostUsername/home"
                  onEnter={enterHomePageForCreatorHook(store)}
                  loadingView={<LoadingView messageId="loading-message-generic" />}
                >
                  <PlatformRenderer desktop={HomepageSwitch} mobile={MobileHomepageView} />
                </GatedRoute>
              </WithNewUserExperienceHelpModal>
            </PlatformRenderer>
          </GatedRoute>

          <GatedRoute
            path="/user/:hostUsername/analytics"
            onEnter={requireLoginHook(store)}
            ignoreHooksOnUpdate
            loadingView={<LoadingView messageId="loading-message-generic" />}
          >
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <GatedRoute
                path="/user/:hostUsername/analytics"
                onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Root)}
              >
                <WithNewUserExperienceHelpModal>
                  <Switch>
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.AUDIENCE} />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics/profile"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.PROFILE} />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics/behavior"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.BEHAVIOR} />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics/insights"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.INSIGHTS} />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics/insights/report"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsInsightsReportWrapper />
                    </GatedRoute>
                    <Redirect
                      from="/user/:hostUsername/analytics/editions"
                      to="/user/:hostUsername/analytics/stories"
                    />
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics/stories_v2"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      onExit={clearAnalyticsHook(store)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.STORIES_V2} />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics/stories"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      onExit={clearAnalyticsHook(store)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.STORIES} />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      async
                      path="/user/:hostUsername/analytics/spotlight"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      onExit={clearAnalyticsHook(store)}
                      loadingView={<LoadingView messageId="loading-message-analytics" />}
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.SPOTLIGHT} />
                    </GatedRoute>
                    <Redirect
                      from="/user/:hostUsername/analytics/edition/:editionId"
                      to="/user/:hostUsername/analytics/story/:editionId"
                    />
                    <GatedRoute
                      async // don't delete this, it make it able to mount without wait requirePublisherInfoHook outside
                      exact
                      path="/user/:hostUsername/analytics/story/:editionId"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      onExit={clearStoryAnalyticsHook(store)}
                    >
                      <AnalyticsEditionView />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Analytics)}
                      path="/user/:hostUsername/analytics/revenue"
                    >
                      <AnalyticsView tab={ANALYTICS_TABS.REVENUE} />
                    </GatedRoute>
                  </Switch>
                </WithNewUserExperienceHelpModal>
              </GatedRoute>
            </PlatformRenderer>
          </GatedRoute>

          <GatedRoute
            path="/user/:hostUsername"
            onEnter={requireLoginHook(store)}
            ignoreHooksOnUpdate
            loadingView={<LoadingView messageId="loading-message-generic" />}
          >
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <GatedRoute
                path="/user/:hostUsername"
                onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Root)}
              >
                <WithNewUserExperienceHelpModal>
                  <Switch>
                    <SentryRoute exact path="/user/:hostUsername/contact" component={SupportTicketForm} />

                    <GatedRoute
                      exact
                      path="/user/:hostUsername/spotlight"
                      loadingView={<LoadingView messageId="loading-message-generic" />}
                    >
                      <SpotlightUploaderView />
                    </GatedRoute>
                    <GatedRoute
                      exact
                      path="/user/:hostUsername/onboarding"
                      onEnter={requireUncachedPublishersHook(store)}
                      loadingView={<LoadingView messageId="loading-message-onboarding" />}
                    >
                      <Switch>
                        <Redirect from="/user/:hostUsername/onboarding" to="/user/:hostUsername/home" />
                      </Switch>
                    </GatedRoute>

                    <GatedRoute
                      exact
                      path="/user/:hostUsername/home/shows"
                      onEnter={enterShowsHook(store)}
                      loadingView={<LoadingView messageId="loading-message-generic" />}
                    >
                      <ShowsView />
                    </GatedRoute>

                    <GatedRoute
                      exact
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Settings)}
                      path="/user/:hostUsername/settings"
                      loadingView={<LoadingView messageId="loading-message-generic" />}
                    >
                      <SettingsView />
                    </GatedRoute>

                    {/* Temporary view designed to show a spinner while a new story is being created */}
                    <GatedRoute exact path="/user/:hostUsername/newEdition" onEnter={gaTiming} async>
                      <NewStoryView />
                    </GatedRoute>

                    <GatedRoute
                      path="/user/:hostUsername/edition/:editionId"
                      onEnter={requirePublisherInfoAndEditionHook(store, PublisherQueryType.Editions)}
                      onExit={clearEditionHook(store)}
                      loadingView={<LoadingView messageId="loading-message-story" />}
                    >
                      <PlatformRenderer mobile={MobileStoryView} desktop={StoryEditorSwitch}>
                        <Switch>
                          <GatedRoute exact path="/user/:hostUsername/edition/:editionId" isEditionView />
                          <GatedRoute
                            path="/user/:hostUsername/edition/:editionId/snap/:snapId"
                            onExit={clearTopsnapHook(store)}
                          >
                            <Switch>
                              <GatedRoute
                                path="/user/:hostUsername/edition/:editionId/snap/:snapId"
                                onEnter={requireSnapHook(store)}
                                async
                                exact
                              />
                              {getValues(SnapType).map((richSnapType: string, index: number) => {
                                return (
                                  <GatedRoute
                                    path={`/user/:hostUsername/edition/:editionId/snap/:snapId/${richSnapType.toLowerCase()}/:attachmentId`} // eslint-disable-line max-len
                                    onEnter={requireSnapHook(store)}
                                    async
                                    exact
                                    key={richSnapType}
                                  />
                                );
                              })}
                            </Switch>
                          </GatedRoute>
                        </Switch>
                      </PlatformRenderer>
                    </GatedRoute>

                    <GatedRoute
                      exact
                      path="/user/:hostUsername/admin"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Admin)}
                      component={PublisherUserManagement}
                    />
                    <GatedRoute
                      exact
                      path="/user/:hostUsername/creative/templates"
                      component={CreativeSuiteTemplates}
                    />
                    <GatedRoute
                      path="/user/:hostUsername/media"
                      onEnter={requireCreatorAndPublisherInfoHook(store, PublisherQueryType.Media)}
                    >
                      <Switch>
                        <GatedRoute
                          exact
                          path="/user/:hostUsername/media"
                          component={MediaLibrary}
                          onEnter={preloadActiveEditions(store)}
                          onExit={clearMediaLibraryHook(store)}
                        />
                        <GatedRoute
                          exact
                          path="/user/:hostUsername/media/edition/:editionId/snap/:snapId"
                          component={MediaLibrary}
                          onEnter={requireEditionHook(store)}
                          onExit={clearMediaLibraryHook(store)}
                        />
                        <GatedRoute
                          exact
                          path="/user/:hostUsername/media/edition/:editionId/snap/:snapId/attachment/:attachmentId"
                          component={MediaLibrary}
                          onEnter={requireEditionHook(store)}
                          onExit={clearMediaLibraryHook(store)}
                        />
                      </Switch>
                    </GatedRoute>
                    <Redirect from="/user/:hostUsername" to="/user/:hostUsername/home" />
                  </Switch>
                </WithNewUserExperienceHelpModal>
              </GatedRoute>
            </PlatformRenderer>
          </GatedRoute>

          <GatedRoute
            path="/publisher/:publisherId/analytics"
            onEnter={requireLoginHook(store)}
            ignoreHooksOnUpdate
            loadingView={<LoadingView messageId="loading-message-generic" />}
          >
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <WithNewUserExperienceHelpModal>
                <Switch>
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics/profile"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-analytics" />}
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.PROFILE} />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-analytics" />}
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.AUDIENCE} />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics/behavior"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-analytics" />}
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.BEHAVIOR} />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics/insights"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-analytics" />}
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.INSIGHTS} />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics/insights/report"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-analytics" />}
                  >
                    <AnalyticsInsightsReportWrapper />
                  </GatedRoute>
                  <Redirect
                    from="/publisher/:publisherId/analytics/editions"
                    to="/publisher/:publisherId/analytics/stories"
                  />
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics/stories_v2"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    onExit={clearAnalyticsHook(store)}
                    loadingView={<LoadingView messageId="loading-message-analytics" />}
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.STORIES_V2} />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics/stories"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    onExit={clearAnalyticsHook(store)}
                    loadingView={<LoadingView messageId="loading-message-analytics" />}
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.STORIES} />
                  </GatedRoute>
                  <Redirect
                    from="/publisher/:publisherId/analytics/edition/:editionId"
                    to="/publisher/:publisherId/analytics/story/:editionId"
                  />
                  <GatedRoute
                    async // don't delete this, it make it able to mount without wait requirePublisherInfoHook outside
                    exact
                    path="/publisher/:publisherId/analytics/story/:editionId"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    onExit={clearStoryAnalyticsHook(store)}
                  >
                    <AnalyticsEditionView />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    async
                    path="/publisher/:publisherId/analytics/spotlight"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.SPOTLIGHT} />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    path="/publisher/:publisherId/analytics/revenue"
                  >
                    <AnalyticsView tab={ANALYTICS_TABS.REVENUE} />
                  </GatedRoute>
                </Switch>
              </WithNewUserExperienceHelpModal>
            </PlatformRenderer>
          </GatedRoute>

          <GatedRoute
            path="/publisher/:publisherId"
            onEnter={requireLoginHook(store)}
            ignoreHooksOnUpdate
            loadingView={<LoadingView messageId="loading-message-generic" />}
          >
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <WithNewUserExperienceHelpModal>
                <Switch>
                  <SentryRoute exact path="/publisher/:publisherId/noPermission" component={NoPermissionView} />
                  <GatedRoute
                    exact
                    path="/publisher/:publisherId"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                  />
                  <GatedRoute
                    exact
                    path="/publisher/:publisherId/contact"
                    component={SupportTicketForm}
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                  />
                  <GatedRoute
                    exact
                    path="/publisher/:publisherId/spotlight"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-generic" />}
                  >
                    <SpotlightUploaderView />
                  </GatedRoute>
                  <GatedRoute
                    exact
                    path="/publisher/:publisherId/onboarding"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-onboarding" />}
                  >
                    <Switch>
                      <Redirect from="/publisher/:publisherId/onboarding" to="/publisher/:publisherId/home" />
                    </Switch>
                  </GatedRoute>

                  <GatedRoute
                    exact
                    path="/publisher/:publisherId/home/shows"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    loadingView={<LoadingView messageId="loading-message-generic" />}
                  >
                    <ShowsView />
                  </GatedRoute>

                  <GatedRoute
                    exact
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    path="/publisher/:publisherId/settings"
                    loadingView={<LoadingView messageId="loading-message-generic" />}
                  >
                    <SettingsView />
                  </GatedRoute>

                  {/* Temporary view designed to show a spinner while a new story is being created */}
                  <GatedRoute exact path="/publisher/:publisherId/newEdition" onEnter={gaTiming} async>
                    <NewStoryView />
                  </GatedRoute>

                  <GatedRoute
                    path="/publisher/:publisherId/edition/:editionId"
                    onExit={clearEditionHook(store)}
                    loadingView={<LoadingView messageId="loading-message-story" />}
                  >
                    <GatedRoute
                      exact
                      path="/publisher/:publisherId/edition/:editionId"
                      onEnter={redirectToCreatorFromPublisherHook(store)}
                      isEditionView
                    />
                    <GatedRoute
                      path="/publisher/:publisherId/edition/:editionId/snap/:snapId"
                      onExit={clearTopsnapHook(store)}
                    >
                      <Switch>
                        <GatedRoute
                          path="/publisher/:publisherId/edition/:editionId/snap/:snapId"
                          onEnter={redirectToCreatorFromPublisherHook(store)}
                          async
                          exact
                        />
                        {getValues(SnapType).map((richSnapType: string, index: number) => {
                          return (
                            <GatedRoute
                              path={`/publisher/:publisherId/edition/:editionId/snap/:snapId/${richSnapType.toLowerCase()}/:attachmentId`} // eslint-disable-line max-len
                              onEnter={redirectToCreatorFromPublisherHook(store)}
                              async
                              exact
                              key={richSnapType}
                            />
                          );
                        })}
                      </Switch>
                    </GatedRoute>
                  </GatedRoute>
                  <GatedRoute
                    exact
                    path="/publisher/:publisherId/admin"
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                    component={PublisherUserManagement}
                  />
                  <GatedRoute
                    exact
                    path="/publisher/:publisherId/creative/templates"
                    component={CreativeSuiteTemplates}
                    onEnter={redirectToCreatorFromPublisherHook(store)}
                  />
                  <GatedRoute path="/publisher/:publisherId/media" onEnter={redirectToCreatorFromPublisherHook(store)}>
                    <Switch>
                      <GatedRoute
                        exact
                        path="/publisher/:publisherId/media"
                        component={MediaLibrary}
                        onEnter={redirectToCreatorFromPublisherHook(store)}
                        onExit={clearMediaLibraryHook(store)}
                      />
                      <GatedRoute
                        exact
                        path="/publisher/:publisherId/media/edition/:editionId/snap/:snapId"
                        component={MediaLibrary}
                        onEnter={redirectToCreatorFromPublisherHook(store)}
                        onExit={clearMediaLibraryHook(store)}
                      />
                      <GatedRoute
                        exact
                        path="/publisher/:publisherId/media/edition/:editionId/snap/:snapId/attachment/:attachmentId"
                        component={MediaLibrary}
                        onEnter={redirectToCreatorFromPublisherHook(store)}
                        onExit={clearMediaLibraryHook(store)}
                      />
                    </Switch>
                  </GatedRoute>
                </Switch>
              </WithNewUserExperienceHelpModal>
            </PlatformRenderer>
          </GatedRoute>

          {/* Snap admin routes*/}
          <GatedRoute path="/snap_admin" onEnter={requireLoginAndUserInfoHook(store)}>
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <Switch>
                <GatedRoute
                  exact
                  path="/snap_admin/publishers/new"
                  onEnter={requireClaim(Claim.SUPER_PUBLISHER_SETTINGS_EDITOR, store)}
                >
                  <NewPublisherPage />
                </GatedRoute>
                <GatedRoute exact path="/snap_admin/users" onEnter={requireClaim(Claim.ADMIN_USER_MANAGER, store)}>
                  <AdminUserManagement />
                </GatedRoute>
                <GatedRoute
                  exact
                  path="/snap_admin/notification"
                  onEnter={requireClaim(Claim.NOTIFICATION_BANNER_EDITOR, store)}
                >
                  <NotificationBannerPage />
                </GatedRoute>
                <GatedRoute
                  exact
                  path="/snap_admin/features"
                  loadingView={<LoadingView messageId="loading-message-generic" />}
                  onEnter={requireFeaturesManagementHook(Claim.SUPER_PUBLISHER_SETTINGS_EDITOR, store)}
                >
                  <FeaturesManagement />
                </GatedRoute>

                <GatedRoute
                  exact
                  path="/snap_admin/onboarding"
                  loadingView={<LoadingView messageId="loading-message-generic" />}
                  onEnter={requireOrgOnboardingsHook(store)}
                >
                  <OrgOnboardingsManagement />
                </GatedRoute>
              </Switch>
            </PlatformRenderer>
          </GatedRoute>
          {/* Not found view*/}
          <GatedRoute onEnter={requireLoginHook(store)}>
            <PlatformRenderer mobile={MobileSnapNavLayout} desktop={SnapNavLayout}>
              <NotFoundView />
            </PlatformRenderer>
          </GatedRoute>
        </Switch>
      </UnsupportedBrowser>
    </App>
  </PlatformProviderUnblocked>
);

export default (store: Store) => <ReloadPageOnError>{routes(store)}</ReloadPageOnError>;
