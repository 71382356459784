import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';
import type { ReactNode } from 'react';

import { logout } from 'state/auth/actions/authActions';
import { goToHomepage, push } from 'state/router/actions/routerActions';
import { setActivePublisher } from 'state/user/actions/userActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import * as localRoutes from 'utils/apis/localRoutes';
import { intlConnect } from 'utils/connectUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';
import { withRouter } from 'utils/routerUtils';

import PublisherDropdown from 'views/common/components/PublisherDropdown/PublisherDropdown';

import style from './MobileSnapNavLayout.scss';

import type { Publisher } from 'types/publishers';
import { ExtractDispatchProps } from 'types/redux';
import type { State } from 'types/rootState';

type DefaultProps = {
  children: ReactNode | undefined | null;
};

type StateProps = {
  activePublisher: Publisher;
  publishers: Publisher[];
  userName: string;
};

type DefaultState = {
  showBlackOverlay: boolean;
};

const mapStateToProps = (state: State): StateProps => {
  return {
    // @ts-expect-error ts-migrate(2740) FIXME: Type '{ id: null; primaryColorRgb: number[]; horiz... Remove this comment to see the full error message
    activePublisher: userSelectors.getActivePublisher(state),
    publishers: userSelectors.getPublishers(state),
    userName: userSelectors.getUsername(state),
  };
};

const mapDispatchToProps = {
  push,
  goToHomepage,
  setActivePublisher,
  logout,
};

type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = DefaultProps & StateProps & DispatchProps;

export class MobileSnapNavLayout extends React.Component<Props, DefaultState> {
  state = {
    showBlackOverlay: false,
  };

  componentDidUpdate(prevProps: Props) {
    const prevHostUsername = get(prevProps.activePublisher, 'hostUsername');
    const hostUsername = get(this.props.activePublisher, 'hostUsername');
    if (prevHostUsername && prevHostUsername !== hostUsername) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      this.props.push(localRoutes.creator.home({ hostUsername }));
    }
  }

  openBlackOverlay = () => {
    this.setState({
      showBlackOverlay: true,
    });
  };

  closeBlackOverlay = () => {
    this.setState({
      showBlackOverlay: false,
    });
  };

  goToHomepageHandler = () => {
    const { activePublisher } = this.props;
    if (activePublisher) {
      this.props.goToHomepage({
        overwriteHistory: false,
        publisherId: activePublisher.id,
      });
    }
  };

  render() {
    const { activePublisher } = this.props;
    if (!activePublisher) {
      return null;
    }

    const icon = activePublisher.defaultFilledIconId ? createAssetUrl(activePublisher.defaultFilledIconId) : '';

    return (
      <div className={classNames(style.mobileSnapNavLayout, 'storyStudio')}>
        <div className={style.mobileNavBar}>
          <span data-test="MobileSnapNavLayout.storyStudio" onClick={this.goToHomepageHandler}>
            {getMessageFromId('story-studio')}
          </span>
          <PublisherDropdown
            mutablePublisherName={activePublisher.mutablePublisherName}
            publisherId={activePublisher.id}
            publisherLogoUrl={icon}
            userName={this.props.userName}
            publishers={this.props.publishers}
            logout={this.props.logout}
            setActivePublisher={this.props.setActivePublisher}
            onDropdownOpen={this.openBlackOverlay}
            onDropdownClose={this.closeBlackOverlay}
          />
        </div>
        <div className={classNames(style.mobileContent, { [style.blackOverlay]: this.state.showBlackOverlay })}>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(MobileSnapNavLayout));
