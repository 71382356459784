import classNames from 'classnames';
import React, { memo, useCallback } from 'react';
import type { ReactNode } from 'react';

import style from './SDSLabel.scss';

export enum LabelStatusTypeEnum {
  ERROR = 'error',
}

export enum LabelAlignItemsTypeEnum {
  START = 'alignStart',
  END = 'alignEnd',
  CENTER = 'alignCenter',
}

export enum LabelSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
}

type Props = {
  labelTitle: ReactNode;
  className?: string | null;
  alignType?: LabelAlignItemsTypeEnum;
  size?: LabelSizeEnum;
  children: ReactNode;
  allowEventPropagation?: boolean;
  'data-test'?: string;
  withoutDefaultMargin?: boolean;
  statusType?: LabelStatusTypeEnum;
};

// Usage: nest input inside of SDSLabel (note that for/id attributes are not needed).

function SDSLabel(props: Props) {
  const { labelTitle, children, className, allowEventPropagation } = props;

  const stopLabelPropagation = useCallback(
    e => {
      if (!allowEventPropagation) {
        e.preventDefault();
      }
    },
    [allowEventPropagation]
  );

  return (
    <label
      data-test={props['data-test'] ? `${props['data-test']}.SDSLabel` : 'SDSLabel'}
      className={classNames(
        className,
        style.inputLabel,
        { [style.noMargin]: props.withoutDefaultMargin },
        style[(props.alignType as keyof typeof style) || LabelAlignItemsTypeEnum.START],
        style[(props.statusType as keyof typeof style) || '']
      )}
      onClick={stopLabelPropagation}
    >
      <span className={classNames(style.labelText, style[(props.size as keyof typeof style) || LabelSizeEnum.MEDIUM])}>
        {labelTitle}
      </span>
      {children}
    </label>
  );
}

export default memo(SDSLabel);
