import loadable from '@loadable/component';
import React from 'react';

import * as modalsActions from 'state/modals/actions/modalsActions';
import * as modalsSelectors from 'state/modals/selectors/modalsSelectors';

import { State } from 'src/types/rootState';
import { intlConnect } from 'utils/connectUtils';
import { ModalType } from 'utils/modalConfig';

import LoadingView from 'views/common/components/LoadingView/LoadingView';

import { ExtractDispatchProps } from 'types/redux';

const fallback = <LoadingView messageId="loading-message-generic" />;
const EpisodeModal = loadable(() => import('views/modals/components/EpisodeModal/EpisodeModal'), { fallback });
const EpisodeScheduleModal = loadable(
  () => import('views/modals/components/EpisodeScheduleModal/EpisodeScheduleModal'),
  { fallback }
);
const OneTimeConfirmationModal = loadable(
  () => import('views/modals/components/OneTimeConfirmationModal/OneTimeConfirmationModal'),
  { fallback }
);
const PlayVideoModal = loadable(() => import('views/modals/components/PlayVideoModal/PlayVideoModal'), { fallback });
const StoryNameModal = loadable(() => import('views/modals/components/StoryNameModal/StoryNameModal'), { fallback });
const AddPublisherDetailsModal = loadable(
  () => import('views/modals/containers/AddPublisherDetailsModal/AddPublisherDetailsModal'),
  { fallback }
);
const AlertModal = loadable(() => import('views/modals/containers/AlertModal/AlertModal'), { fallback });
const FeatureBulkUpdateModal = loadable(
  () => import('views/modals/containers/ApplyFeatureFlagModal/FeatureBulkUpdateModal'),
  { fallback }
);
const CopyToPublisherModal = loadable(
  () => import('views/modals/containers/CopyToPublisherModal/CopyToPublisherModal'),
  { fallback }
);
const CreateFeatureFlagModal = loadable(
  () => import('views/modals/containers/CreateFeatureFlagModal/CreateFeatureFlagModal'),
  { fallback }
);
const DeleteSnapComponentModal = loadable(
  () => import('views/modals/containers/DeleteSnapComponentModal/DeleteSnapComponentModal'),
  { fallback }
);
const GenerateSubtitlesConfirmModal = loadable(
  () => import('views/modals/containers/GenerateSubtitlesConfirmModal/GenerateSubtitlesConfirmModal'),
  { fallback }
);
const LoadLocalContentModal = loadable(
  () => import('views/modals/containers/LoadLocalContentModal/LoadLocalContentModal'),
  { fallback }
);
const ManageSubtitlesModal = loadable(
  () => import('views/modals/containers/ManageSubtitlesModal/ManageSubtitlesModal'),
  { fallback }
);
const NewUserModal = loadable(() => import('views/modals/containers/NewUserModal/NewUserModal'), { fallback });

const SponsoredContentModal = loadable(
  () => import('views/modals/containers/SponsoredContentModal/SponsoredContentModal'),
  { fallback }
);
const RSSFeedModal = loadable(() => import('views/modals/containers/RSSFeedModal/RSSFeedModal'), { fallback });
const TakeScreenshotModal = loadable(() => import('views/modals/containers/TakeScreenshotModal/TakeScreenshotModal'), {
  fallback,
});
const UploadImageModal = loadable(() => import('views/modals/containers/UploadImageModal/UploadImageModal'), {
  fallback,
});
const UploadVideoModal = loadable(() => import('views/modals/containers/UploadVideoModal/UploadVideoModal'), {
  fallback,
});

const ScheduleStoryModal = loadable(
  () => import('views/publisherStoryEditor/containers/ScheduleStoryModal/ScheduleStoryModal'),
  { fallback }
);
const StorySnapcodeSharingModal = loadable(
  () => import('views/publisherStoryEditor/containers/StorySnapcodeSharingModal/StorySnapcodeSharingModal'),
  { fallback }
);
const PublisherHelpModal = loadable(
  () => import('views/modals/containers/NewUserExperienceHelpModal/NewUserExperienceHelpModal'),
  {
    fallback,
  }
);
const OrgOnboardingModal = loadable(() => import('views/snapAdmin/components/OrgOnboardingModal/OrgOnboardingModal'), {
  fallback,
});

const StoryMediaReplacementModal = loadable(
  () => import('views/modals/components/StoryMediaReplacementModal/StoryMediaReplacementModal'),
  {
    fallback,
  }
);

const TileViolationAppealModal = loadable(
  () => import('views/modals/containers/TileViolationAppealModal/TileViolationAppealModal'),
  {
    fallback,
  }
);

const SpotlightDetailsModal = loadable(
  () => import('views/modals/components/SpotlightDetailsModal/SpotlightDetailsModal'),
  {
    fallback,
  }
);

const RevenueCSVModal = loadable(() => import('views/modals/components/RevenueCSVModal/RevenueCSVModal'), {
  fallback,
});

const EditionAnalyticsCSVModal = loadable(
  () => import('views/modals/components/AnalyticsEditionCSVModal/AnalyticsEditionCSVModal'),
  {
    fallback,
  }
);

const DialogModal = loadable(() => import('views/common/components/DialogModal/DialogModal'), {
  fallback,
});

const NewContentModal = loadable(() => import('views/modals/components/NewContentModal/NewContentModal'), {
  fallback,
});

// Configures the Modal component should show for each Modal Type
const modalByModalType = () => ({
  [ModalType.ADD_PUBLISHER_DETAILS]: AddPublisherDetailsModal,
  [ModalType.ALERT]: AlertModal,
  [ModalType.FEATURE_BULK_UPDATE]: FeatureBulkUpdateModal,
  [ModalType.COPY_TO_PUBLISHER]: CopyToPublisherModal,
  [ModalType.CREATE_FEATURE_FLAG]: CreateFeatureFlagModal,
  [ModalType.CREATE_EPISODE]: EpisodeModal,
  [ModalType.CREATE_STORY]: StoryNameModal,
  [ModalType.DELETE_SNAP_COMPONENT]: DeleteSnapComponentModal,
  [ModalType.IMAGE_UPLOAD]: UploadImageModal,
  [ModalType.GENERATE_SUBTITLES]: GenerateSubtitlesConfirmModal,
  [ModalType.LOAD_LOCAL_CONTENT]: LoadLocalContentModal,
  [ModalType.NEW_USER]: NewUserModal,
  [ModalType.OPEN_EPISODE_CREATOR]: AlertModal,
  [ModalType.PLAY_VIDEO]: PlayVideoModal,
  [ModalType.SPONSORED_CONTENT_MODAL]: SponsoredContentModal,
  [ModalType.RENAME_STORY]: StoryNameModal,
  [ModalType.SCHEDULE_STORY]: ScheduleStoryModal,
  [ModalType.SCHEDULE_EPISODE]: EpisodeScheduleModal,
  [ModalType.STORY_SNAPCODE_SHARING_MODAL]: StorySnapcodeSharingModal,
  [ModalType.ONE_TIME_CONFIRMATION]: OneTimeConfirmationModal,
  [ModalType.TAKE_SCREENSHOT_MODAL]: TakeScreenshotModal,
  [ModalType.VIDEO_UPLOAD]: UploadVideoModal,
  [ModalType.MANAGE_SUBTITLES]: ManageSubtitlesModal,
  [ModalType.RSS_FEED_MODAL]: RSSFeedModal,
  [ModalType.UPDATE_EPISODE]: EpisodeModal,
  [ModalType.ORG_ONBOARDING]: OrgOnboardingModal,
  [ModalType.PUBLISHER_HELP_MODAL]: PublisherHelpModal,
  [ModalType.REPLACE_STORY_MEDIA]: StoryMediaReplacementModal,
  [ModalType.CREATE_CONTENT]: NewContentModal,
  [ModalType.TILE_VIOLATION_APPEAL]: TileViolationAppealModal,
  [ModalType.SPOTLIGHT_DETAILS]: SpotlightDetailsModal,
  [ModalType.REVENUE_CSV_MODAL]: RevenueCSVModal,
  [ModalType.DIALOG]: DialogModal,
  [ModalType.EDITION_ANALYTICS_CSV_MODAL]: EditionAnalyticsCSVModal,
});
export const mapStateToProps = (state: State) => {
  return {
    activeModals: modalsSelectors.getActiveModals(state),
    modalConfigMap: modalsSelectors.getModalConfigMap(state),
  };
};
const mapDispatchToProps = {
  hideModal: modalsActions.hideModal,
  setModalConfigProperties: modalsActions.setModalConfigProperties,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

export class ModalsContainer extends React.PureComponent<Props> {
  // On the screen, the modals will stack on the top of one another in the activeModals
  render() {
    const ModalList = this.props.activeModals.map(modal => {
      const CurrentModal = modalByModalType()[modal.modalType];
      return (
        <CurrentModal
          key={modal.modalId}
          modalId={modal.modalId}
          hideModal={this.props.hideModal}
          setModalConfigProperties={this.props.setModalConfigProperties}
          modalConfig={this.props.modalConfigMap[modal.modalId]}
          options={modal.options}
          data-test={`modal.${modal.modalType}`}
        />
      );
    });
    return <div>{ModalList}</div>;
  }
}
export default intlConnect(mapStateToProps, mapDispatchToProps)(ModalsContainer);
