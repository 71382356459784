import invariant from 'invariant';
import _ from 'lodash';
import log from 'loglevel';
import { arrayOf } from 'normalizr';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import * as actionFactories from 'state/common/actionFactories';
import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as componentsSelectors from 'state/editor/selectors/componentsSelectors';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as subtitlesActions from 'state/subtitles/actions/subtitlesActions';
import * as userSelectors from 'state/user/selectors/userSelectors';
import { defaultVideoParams, modalVideoParams } from 'state/videoLibrary/actions/videoLibraryActionConfig';
import { videoResult } from 'state/videoLibrary/schema/videoLibrarySchema';
import {
  shouldNotLoadVideoLibraryResults,
  getVideoResultById,
  subtitleProcessingStatusByVideoId,
} from 'state/videoLibrary/selectors/videoLibrarySelectors';

import { FileType, UploadPurpose, ErrorType, SECONDS, Sequence, VIDEO_UPLOAD_ANALYTICS } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { preprocessAndFinalize, optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import { GetState } from 'src/types/redux';
import { State } from 'src/types/rootState';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import { assertArg, assertState } from 'utils/assertionUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { clearInfoMessage, InfoContext, infoMessageHandler } from 'utils/errors/infoMessage/infoMessageUtils';
import { mediaErrorHandler } from 'utils/errors/media/mediaErrorUtils';
import * as formUtils from 'utils/formUtils';
import { applyFnChain } from 'utils/functionUtils';
import { blobAsUrl } from 'utils/media/blobUtils';
import { validateFile } from 'utils/media/fileValidation';
import { validateMedia } from 'utils/media/mediaValidation';
import { getValidationOptions } from 'utils/media/mediaValidationConfig';
import { snakeCaseKeys } from 'utils/objectUtils';
import * as promiseUtils from 'utils/promiseUtils';
import { isS3Endpoint } from 'utils/uploadUtils';

import { assertAssetId } from 'types/assets';
import { assertSnapId } from 'types/common';

export const GET_VIDEO_COUNT = 'videoLibrary/GET_VIDEO_COUNT';
export const GET_VIDEO_RESULTS = 'videoLibrary/GET_VIDEO_RESULTS';
export const FETCH_VIDEO_INFO = 'videoLibrary/FETCH_VIDEO_INFO';
export const GET_VIDEO_METADATA = 'videoLibrary/GET_VIDEO_METADATA';
export const SET_CURRENT_SEARCH = 'videoLibrary/SET_CURRENT_SEARCH';
export const INVALIDATE_SEARCH_RESULTS = 'videoLibrary/INVALIDATE_SEARCH_RESULTS';
export const DELETE_VIDEO = 'videoLibrary/DELETE_VIDEO';
export const DELETE_VIDEO_SUBTITLE = 'videoLibrary/DELETE_VIDEO_SUBTITLE';
export const FLAG_VIDEO_SUBTITLE_PROCESSING = 'videoLibrary/FLAG_VIDEO_SUBTITLE_PROCESSING';
export const GET_UPLOAD_ENDPOINT = 'videoLibrary/GET_UPLOAD_ENDPOINT';
export const UPLOAD_TO_ENDPOINT = 'videoLibrary/UPLOAD_TO_ENDPOINT';
export const UPLOAD_VIDEO_LIBRARY = 'videoLibrary/UPLOAD_VIDEO_LIBRARY';
export const RETRY_MAX = 15;
export const RETRY_TIMEOUT_RESOLVE_URL = 5000;
const getAndAssertEntityOwner = (state: State) => {
  const entityOwner = userSelectors.getActivePublisherIdAsString(state);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(entityOwner).is.string();
  return entityOwner;
};
export const getVideoCount = ({ searchTerm, offset }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(searchTerm).is.string.or.is.not.existy();
  const entityOwner = getAndAssertEntityOwner(getState());
  const params = {
    entityOwner,
    offset: offset?.length > 0 ? JSON.stringify(offset) : offset,
    searchTerm,
    // TODO (piers) Get search_term changed to searchTerm on the backend
    search_term: searchTerm,
  };
  return dispatch({
    type: GET_VIDEO_COUNT,
    params,
    meta: {
      [CALL_API]: {
        endpoint: mediaLibraryAPI.brightcove.videoCount(params),
        ...preprocessAndFinalize,
      },
    },
  });
};
export const fetchVideoInformation = ({ assetId }: any) => {
  assertAssetId(assetId);
  const params = { assetId };
  return {
    type: FETCH_VIDEO_INFO,
    params,
    meta: {
      schema: videoResult,
      [CALL_API]: {
        endpoint: mediaLibraryAPI.brightcove.videoMetadata(params),
        ...preprocessAndFinalize,
      },
    },
  };
};
/**
 * Takes a transcodedMediaId and attempts to resolve it on the client - get the manifest url which is ready once processing completes.
 * The method keeps on calling backend until the media is processed and a url is returned
 * OR until it runs out of retries
 */
export const resolveVideoUntilComplete = ({ assetId }: any, retryCount = 0) => (dispatch: any, getState: GetState) => {
  assertAssetId(assetId);
  dispatch(fetchVideoInformation({ assetId })).then(() => {
    const resolvedVideo = getVideoResultById(getState())(assetId);
    const isStillProcessing = !_.get(resolvedVideo, 'complete', false);
    if (isStillProcessing && retryCount < RETRY_MAX) {
      setTimeout(() => dispatch(resolveVideoUntilComplete({ assetId }, retryCount + 1)), RETRY_TIMEOUT_RESOLVE_URL);
    }
  });
};
export const getVideoMetadata = ({ assetId }: any = {}) => {
  assertAssetId(assetId);
  return (dispatch: any) => {
    return dispatch({
      type: GET_VIDEO_METADATA,
      meta: {
        [CALL_API]: {
          endpoint: mediaLibraryAPI.brightcove.longformVideoCatalog({ id: assetId }),
          finalizer: optimisticJsonFinalizer,
        },
      },
      params: {
        assetId,
      },
    });
  };
};
export const fetchVideoInformationRejectIncomplete = ({ assetId, waitImgSrc = false }: any) => (
  dispatch: any,
  getState: GetState
) =>
  dispatch(fetchVideoInformation({ assetId })).then(() => {
    const result = getVideoResultById(getState())(assetId);
    if (!waitImgSrc) {
      if (result.complete) {
        return result;
      }
    } else if (result.complete && _.get(result, ['images', 'poster', 'src'], null)) {
      return result;
    }
    const err = new Error(`incomplete video for assetId ${assetId}: ${result}`);
    (err as any).errorType = ErrorType.INCOMPLETE_VIDEO;
    throw err;
  });
export const RETRY_TIMEOUT = 15 * SECONDS;
// TODO - merge with previewAction so we are only polling this endpoint in one place
export const fetchVideoInformationUntilComplete = (video: any) => {
  // Videos transcoded by the internal transcoding service currently takes longer to complete than
  // Brightcove, and is proportional to the video file size. As such, total time to wait should also
  // be proportional to the video file size. Through experimentation, maxRetries should be roughly
  // 20% of the video size in MB.
  const retriesLowerBound = 15;
  const maxRetries = Math.max(Math.ceil((video.size / 1024 / 1024) * 0.2), retriesLowerBound);
  return actionFactories.createRetryAction(fetchVideoInformationRejectIncomplete, {
    maxRetries,
    timeout: RETRY_TIMEOUT,
    errorsMatching: (err: any) => {
      return _.get(err, 'errorType') === ErrorType.INCOMPLETE_VIDEO;
    },
  });
};
export const setCurrentSearch = (params: any) => (dispatch: any, getState: GetState) => {
  const { endpoint, requestParams } = buildSearchRequest(params, getState);
  return dispatch({
    type: SET_CURRENT_SEARCH,
    payload: {
      params: requestParams,
      endpoint,
    },
  });
};
export const getVideoResults = (params: any) => (dispatch: any, getState: GetState) => {
  const { endpoint, requestParams } = buildSearchRequest(params, getState);
  return dispatch({
    type: GET_VIDEO_RESULTS,
    params: requestParams,
    meta: {
      [CALL_API]: {
        endpoint,
        preprocessor: preprocessAndFinalize.preprocessor,
        finalizer: (initialPayload: any) =>
          applyFnChain(initialPayload, [preprocessAndFinalize.finalizer, removeVideosWithNoReferenceId]),
      },
      schema: arrayOf(videoResult),
    },
  });
};
// Works around an issue with the backend that means videos can infrequently end up
// without their referenceId being populated after being uploaded, which makes them
// unusable in snaps.
function removeVideosWithNoReferenceId(videoResults: any) {
  return videoResults.filter((result: any) => !!result.referenceId);
}
export const setCurrentSearchAndFetch = (params: any, validate: any) => (dispatch: any, getState: GetState) => {
  const { endpoint } = buildSearchRequest(params, getState);
  dispatch(setCurrentSearch(params));
  if (shouldNotLoadVideoLibraryResults(getState())(endpoint)) {
    return Promise.resolve();
  }
  const doValidate = (result: any) => {
    if (validate && !validate(result)) {
      dispatch({
        type: INVALIDATE_SEARCH_RESULTS,
        payload: {},
      });
      return false;
    }
    return true;
  };
  return withRetries(
    'setCurrentSearchAndFetch',
    () => dispatch(getVideoResults(params)),
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(result: any) => boolean' is not... Remove this comment to see the full error message
    doValidate
  ).then((videoResults: any) => dispatch(getVideoCount(params)).then((count: any) => [videoResults, count]));
};
export function buildSearchRequest({ limit, sort, offset, searchTerm, keepExistingMedia }: any, getState: GetState) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(limit).is.number.or.is.not.existy();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(sort).is.string.or.is.not.existy();

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(searchTerm).is.string.or.is.not.existy();
  const entityOwner = getAndAssertEntityOwner(getState());
  const formattedOffset = offset.length > 0 ? JSON.stringify(offset) : offset;
  const requestParams = {
    entityOwner,
    limit,
    sort,
    offset: formattedOffset,
    searchTerm,
    // TODO (piers) Get search_term changed to searchTerm on the backend
    search_term: searchTerm,
    keepExistingMedia,
  };
  const endpoint = mediaLibraryAPI.brightcove.videos(requestParams);
  return {
    requestParams,
    endpoint,
  };
}
export const deleteVideoSubtitle = ({ assetId, language }: any) => {
  assertAssetId(assetId);
  return (dispatch: any) =>
    dispatch({
      type: DELETE_VIDEO_SUBTITLE,
      params: { assetId },
      meta: {
        [CALL_API]: {
          method: 'delete',
          endpoint: mediaLibraryAPI.asset.subtitles({ assetId, language }),
          finalizer: optimisticJsonFinalizer,
        },
      },
    }).catch(apiErrorHandler(dispatch, ErrorContexts.DELETE_SUBTITLES));
};
export function deleteTopsnapSubtitles(topsnapId: any, videoAssetId: any, language: any) {
  return (dispatch: any, getState: GetState) => {
    infoMessageHandler(dispatch, InfoContext.SAVING);
    return dispatch(deleteVideoSubtitle({ assetId: videoAssetId, language }))
      .then((response: any) => {
        dispatch(
          snapsActions.setSnapPropertiesAndSave(
            { snapId: topsnapId },
            { videoAssetId: response.payload.id },
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
            ErrorType.SAVE_SNAP_MEDIA
          )
        );
        return response.payload.id;
      })
      .then((newVideoAssetId: any) => dispatch(subtitlesActions.loadSubtitles(newVideoAssetId)))
      .then(clearInfoMessage(getState, dispatch, InfoContext.SAVING));
  };
}
export function deleteLongformSubtitles(longformAttachmentId: any, videoAssetId: any, language: any) {
  return (dispatch: any, getState: GetState) => {
    infoMessageHandler(dispatch, InfoContext.SAVING);
    return dispatch(deleteVideoSubtitle({ assetId: videoAssetId, language }))
      .then((response: any) => {
        dispatch(
          snapsActions.setSnapPropertiesAndSave(
            { snapId: longformAttachmentId },
            { longformVideoAssetId: response.payload.id },
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 3.
            ErrorType.SAVE_SNAP_MEDIA
          )
        );
        return response.payload.id;
      })
      .then(clearInfoMessage(getState, dispatch, InfoContext.SAVING));
  };
}
export function deleteSingleAssetStorySubtitles(storyId: any, videoAssetId: any, language: any) {
  return (dispatch: any, getState: GetState) => {
    infoMessageHandler(dispatch, InfoContext.SAVING);
    return dispatch(deleteVideoSubtitle({ assetId: videoAssetId, language }))
      .then((response: any) => {
        const edition = editionsSelectors.getEditionById(getState())(storyId);
        invariant(edition, 'Edition cannot be null');
        const updatedVideoTracks = u({ 0: { transcodedMediaId: response.payload.id } }, edition.videoTracks);
        return dispatch(
          editionsActions.setEditionPropertiesAndSave(
            { editionId: storyId },
            {
              videoTracks: updatedVideoTracks,
            }
          )
        ).then(() => response.payload.id);
      })
      .then((newVideoAssetId: any) => dispatch(subtitlesActions.loadSubtitles(newVideoAssetId)))
      .finally(clearInfoMessage(getState, dispatch, InfoContext.SAVING));
  };
}
export const flagVideoSubtitleProcessing = ({ assetId }: any) => {
  assertAssetId(assetId);
  return {
    type: FLAG_VIDEO_SUBTITLE_PROCESSING,
    params: {
      assetId,
    },
  };
};
export const pollUntilVideoHasSubtitles = ({ assetId }: any, retryCount = 0) => {
  assertAssetId(assetId);
  return (dispatch: any, getState: GetState) => {
    return dispatch(fetchVideoInformation({ assetId })).then(() => {
      const stillProcessing = subtitleProcessingStatusByVideoId(getState())(assetId);
      if (stillProcessing && retryCount < RETRY_MAX) {
        setTimeout(() => dispatch(pollUntilVideoHasSubtitles({ assetId }, retryCount + 1)), RETRY_TIMEOUT);
      }
    });
  };
};
const getUploadEndpoint = ({ filename }: any) => (dispatch: any, getState: GetState) => {
  const entityOwner = getAndAssertEntityOwner(getState());
  const fileNameObject = filename ? { filename } : {};
  const params = {
    entityOwner,
    ...fileNameObject,
  };
  return dispatch({
    type: GET_UPLOAD_ENDPOINT,
    meta: {
      [CALL_API]: {
        endpoint: mediaLibraryAPI.asset.longformUpload(params),
        method: 'get',
        ...preprocessAndFinalize,
      },
    },
  });
};
const getUploadEndpointResolveUrl = (params: any) => (dispatch: any) =>
  dispatch(getUploadEndpoint(params)).then((getEndpointAction: any) => {
    return getEndpointAction.payload.url;
  });
export const resetSearchResults = (params = defaultVideoParams, validate = () => true) => (dispatch: any) => {
  dispatch({
    type: INVALIDATE_SEARCH_RESULTS,
    payload: {},
  });
  return dispatch(setCurrentSearchAndFetch(params, validate));
};
const performUploadToEndpoint = ({ endpoint, params, video }: any) => (dispatch: any) => {
  const uploadToS3 = isS3Endpoint(endpoint);
  const body = uploadToS3
    ? video
    : formUtils.toFormData({
        ...snakeCaseKeys(params),
        data: video,
      });
  return dispatch({
    type: UPLOAD_TO_ENDPOINT,
    meta: {
      [CALL_API]: {
        // uploads to S3 must be done with HTTP PUT
        // uploads to GCS must be done with HTTP POST
        method: uploadToS3 ? 'put' : 'post',
        endpoint,
        body,
        withProgress: true,
      },
    },
    params,
    dataUri: blobAsUrl(video),
  });
};
const saveUploadedVideoId = (snapId: any, uploadResult: any) => (dispatch: any) => {
  assertSnapId(snapId);
  return dispatch(
    snapsActions.setSnapPropertiesAndSave(
      { snapId },
      {
        longformVideoAssetId: uploadResult.payload.id,
      }
    )
  );
};
const TWO_GIGABYTES = 2 * 1024 * 1024 * 1024;
export const helpers = {
  getUploadEndpointResolveUrl,
  performUploadToEndpoint,
  saveUploadedVideoId,
  resetSearchResults,
  fetchVideoInformationUntilComplete,
};
export const deleteVideo = ({ assetId, params = modalVideoParams }: any) => (dispatch: any) => {
  assertAssetId(assetId);
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: DELETE_VIDEO,
        params: { assetId },
        meta: {
          [CALL_API]: {
            method: 'delete',
            endpoint: mediaLibraryAPI.brightcove.videoDelete({ assetId }),
          },
        },
      })
    )
    .catch(apiErrorHandler(dispatch, ErrorContexts.DELETE_VIDEO))
    .then(() =>
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(videos: any) => boolean' is not... Remove this comment to see the full error message
      dispatch(helpers.resetSearchResults(params, (videos: any) => !(assetId in videos.payload.entities.videoResult)))
    );
};
const uploadInnerVideo = (video: any, isVideoLibraryModal: any, longFormAnalyticsFn: any) => (
  dispatch: any,
  getState: GetState
) => {
  const purpose = UploadPurpose.LONGFORM_VIDEO;
  const component = componentsSelectors.getActiveComponent(getState());
  const videoSnapId = component ? (component as any).snap.id : null;
  const params = { purpose, componentId: component ? (component as any).componentId : null };
  let assetId: any;
  const entityOwner = userSelectors.getActivePublisherIdAsString(getState());
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertState(entityOwner).is.string();
  dispatch({
    type: UPLOAD_VIDEO_LIBRARY,
    params,
    payload: {},
    sequence: Sequence.START,
  });
  const sendDone = () =>
    dispatch({
      type: UPLOAD_VIDEO_LIBRARY,
      params,
      payload: {},
      sequence: Sequence.DONE,
    });
  const videoParams = isVideoLibraryModal ? modalVideoParams : defaultVideoParams;
  return (
    Promise.resolve()
      .then(() => {
        validateFile(video, { maxSizeBytes: TWO_GIGABYTES, requiredFileType: FileType.VIDEO }, longFormAnalyticsFn);
        return validateMedia(blobAsUrl(video), FileType.VIDEO, getValidationOptions(purpose), longFormAnalyticsFn);
      })
      .catch(err => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATION_MEDIA_ERROR_HANDLER);
        return mediaErrorHandler(dispatch, ErrorContexts.VALIDATE_MEDIA)(err);
      })
      .then(() => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.GET_UPLOAD_ENDPOINT_RESOLVE_URL);
        return dispatch(
          helpers.getUploadEndpointResolveUrl({
            filename: video.name,
            entityOwner,
          })
        );
      }) // ask for GCS URL
      .then(endpoint => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.PERFORM_UPLOAD_TO_ENDPOINT);
        return dispatch(helpers.performUploadToEndpoint({ endpoint, params, video }));
      })
      .then(uploadMediaAction =>
        mediaActions.claimMediaIfS3Upload(uploadMediaAction, dispatch, undefined, uploadMediaAction.params)
      )
      // upload to GCS bucket
      .then(result => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.UPLOAD_TO_ENDPOINT_RESULT);
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        assertArg(result).is.object();
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        assertArg(result.payload).is.object();
        assertAssetId(result.payload.id);
        if (isVideoLibraryModal) {
          assetId = result.payload.prefixedMediaId;
          return result.payload;
        }
        return Promise.resolve()
          .then(() => {
            longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.SAVE_UPLOADED_VIDEO_ID);
            return dispatch(helpers.saveUploadedVideoId(videoSnapId, result));
          })
          .then(() => {
            longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.GET_SNAP_BY_ID);
            assetId = (snapsSelectors.getSnapById(getState())(videoSnapId) as any).longformVideoAssetId;
            return result.payload;
          });
      })
      .then(asset => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.RESET_SEARCH_RESULTS);
        const original = asset.prefixedMediaId || asset.id;
        const updatedParams = { ...videoParams };
        updatedParams.offset = '';
        return dispatch(
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(videos: any) => boolean' is not... Remove this comment to see the full error message
          helpers.resetSearchResults(updatedParams, (videos: any) => original in videos.payload.entities.videoResult)
        );
      })
      .then(
        ...promiseUtils.finallyPromise(() => {
          longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.SEND_DONE);
          sendDone();
        })
      )
      .then(() => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.FETCH_VIDEO_INFORMATION_UNTIL_COMPLETE);
        return dispatch(
          helpers.fetchVideoInformationUntilComplete(video)({ assetId, waitImgSrc: isVideoLibraryModal })
        );
      })
      .then(() => {
        return { assetId };
      })
      .catch(err => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.UPLOAD_MEDIA_ERROR_HANDLER);
        return mediaErrorHandler(dispatch, ErrorContexts.UPLOAD_MEDIA)(err);
      })
      .catch(err => {
        longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.UPLOAD_CATCH);
        log.error('Error while uploading longform video', err);
        return Promise.reject(err);
      })
  );
};
export const uploadModalLibraryVideo = (video: any, longFormAnalyticsFn: any) => (dispatch: any) =>
  dispatch(uploadInnerVideo(video, true, longFormAnalyticsFn));
export const uploadLibraryVideo = (video: any, longFormAnalyticsFn: any) => (dispatch: any) =>
  dispatch(uploadInnerVideo(video, false, longFormAnalyticsFn));
const initialBackoffMillis = 250;
const pauseMillis = 250;
const maxAttempts = 8;
function withRetries(name: any, doTry: any, validate = () => true, attempt = 0) {
  return doTry().then((result: any) => {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
    if (validate(result)) {
      return result;
    }
    if (attempt < maxAttempts) {
      const backoff = pauseMillis + initialBackoffMillis * 2 ** attempt;
      return delay(backoff, () => withRetries(name, doTry, validate, attempt + 1));
    }
    throw new Error(`${name} timed out after ${maxAttempts} attempts`);
  });
}
function delay(ms: any, thunk: any) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        resolve(thunk());
      } catch (e) {
        reject(e);
      }
    }, ms);
  });
}
