// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { setTimeout } from 'global';
import is from 'is_js';

import { assertArg } from 'utils/assertionUtils';
import { limitCallOnce } from 'utils/functionUtils';

// Loosely checking just by then property
// Modeled after https://github.com/pburtchaell/redux-promise-middleware
export function isPromise(value: any): boolean {
  if (!is.object(value)) {
    return false;
  }
  return is.function(value.then);
}

// Creates a promise which will resolve to undefined in `delay` milliseconds
// Useful for inserting delays into promise chains.
export function makeDelayPromise(delay: number): Promise<void> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(delay).is.number();

  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export const passThroughSuccess = (fn: any): any => (result: any): any =>
  new Promise(resolve => {
    resolve(fn());
  })
    .catch(() => {})
    .then(() => Promise.resolve(result));

export const passThroughRejection = (fn: any): any => (error: any): any =>
  new Promise((resolve: any): any => {
    resolve(fn());
  })
    .catch(() => {})
    .then(() => Promise.reject(error));

export const finallyPromise = (fn: any) => {
  const fnCallableOnce = limitCallOnce(fn);
  const onSuccess = passThroughSuccess(fnCallableOnce);
  const onReject = passThroughRejection(fnCallableOnce);

  return [onSuccess, onReject];
};

export function serializePromises(promiseGenerators: any, ...args: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(promiseGenerators).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(promiseGenerators).is.all.function();

  const allResults: any = [];

  return promiseGenerators
    .reduce((prev: any, next: any) => {
      return prev.then(() =>
        next(...args).then((result: any) => {
          allResults.push(result);
        })
      );
    }, Promise.resolve())
    .then(() => allResults);
}

// Class which will return the previous promise on execute if it's not yet resolved.

// This avoids executing multiple promises when they all should have the same result.
export class PromiseSingleton {
  _currentPromise = null;

  _nullifyPromise = () => {
    this._currentPromise = null;
  };

  execute(generator: any): any {
    if (this._currentPromise) {
      return this._currentPromise;
    }
    try {
      this._currentPromise = generator();
    } catch (error) {
      this._nullifyPromise();
      return Promise.reject(error);
    }

    if (!isPromise(this._currentPromise)) {
      this._nullifyPromise();
      return Promise.reject(new Error('generator did not return a promise'));
    }

    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    this._currentPromise.then(this._nullifyPromise, this._nullifyPromise);
    return this._currentPromise;
  }
}

type BooleanPromiseCreator = () => Promise<boolean>;

// This function will keep executing promises in the list one after each other
// while the promises are returning true. If one of the promises in the chain returns false,
// the rest will not be executed.
export function executePromisesWhileTrue(promiseCreators: Array<BooleanPromiseCreator>) {
  return promiseCreators.reduce(
    (promise: Promise<boolean>, nextPromise: BooleanPromiseCreator) =>
      promise.then(shouldContinue => {
        if (shouldContinue) {
          return nextPromise();
        }

        return Promise.resolve(shouldContinue);
      }),
    Promise.resolve(true)
  );
}
