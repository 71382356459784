import _ from 'lodash';
import { createSelector as s } from 'reselect';

import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import { createKeySelector } from 'state/common/selectorFactories';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as publisherToolsSelectors from 'state/publisherTools/selectors/publisherToolsSelectors';

import { SortDirection, EMPTY_OBJECT, EMPTY_ARRAY } from 'config/constants';
import { functionRef } from 'utils/functionUtils';

import { ServerSideStoryState } from 'types/editions';
import type { State } from 'types/rootState';

export const HomepageSortBy = {
  START_DATE: 'START_DATE',
  CREATED_AT: 'CREATED_AT',
};
export type HomepageSortByEnum = typeof HomepageSortBy[keyof typeof HomepageSortBy];
export const getHomepage = (state: State) => {
  return state.homepage || {};
};
export const getOrderedStoryIdList = createKeySelector(getHomepage, 'orderedStoryIdList', EMPTY_ARRAY);
export const getSearchCriteria = createKeySelector(getHomepage, 'searchCriteria', EMPTY_OBJECT);
export const getSortBy = createKeySelector(getSearchCriteria, 'sortBy', HomepageSortBy.CREATED_AT);
export const getSortDirection = createKeySelector(getSearchCriteria, 'sortDirection', SortDirection.DESCENDING);
export const getTitleSearch = createKeySelector(getSearchCriteria, 'titleSearch', '');
export const getStartDate = createKeySelector<number | undefined | null>(getSearchCriteria, 'startDate', null);
export const getEndDate = createKeySelector<number | undefined | null>(getSearchCriteria, 'endDate', null);
export const getPagination = s(getHomepage, state => state.pagination);
export const getLastBatchSize = createKeySelector(getPagination, 'batchSize', 0);
export const getCursor = createKeySelector(getPagination, 'cursor', null);
export const getLoadingNewSearchCriteria = createKeySelector(getHomepage, 'loadingNewSearchCriteria', false);
export const getLoadingMorePages = createKeySelector(getHomepage, 'loadingMorePages', false);
export const getSelectedStates = createKeySelector(
  getSearchCriteria,
  'state',
  _.without(Object.values(ServerSideStoryState), ServerSideStoryState.DELETED)
);
export const getSelectedHnCategoryId = createKeySelector(getSearchCriteria, 'hnCategoryId', EMPTY_ARRAY);
export const getSelectedHnBreakingNews = createKeySelector(getSearchCriteria, 'hnBreakingNews', false);
export const getOrderedStories = s(
  getOrderedStoryIdList,
  functionRef(editionsSelectors, 'getAllEditions'),
  functionRef(publisherToolsSelectors, 'getPresentationTileForAllEditionsMap'),
  functionRef(buildStatusSelectors, 'getEditionBuildStatuses'),
  (orderedStoryIdList, allEditionsMap, presentationTileForAllEditionsMap, editionBuildStatusesMap) => {
    const orderedStoryList = orderedStoryIdList.map(storyId => (allEditionsMap as any)[storyId]).filter(Boolean);
    const stories = orderedStoryList.map((story, index) => {
      const tile = (presentationTileForAllEditionsMap as any)[story.id];
      const storyBuildStatus = _.get((editionBuildStatusesMap as any)[story.id], 'editionStatus');
      return { story, tile, storyBuildStatus };
    });
    return stories;
  }
);
export const hasMorePagesToLoad = s(getLastBatchSize, getCursor, (lastBatchSize, cursor) => {
  return !!(lastBatchSize > 0 && cursor);
});
