import { combineReducers } from 'redux';

import {
  createEntityReducer,
  serializeReducers,
  createSequenceSimpleReducer,
  createRemoverByIdReducer,
  createSequenceCountingReducer,
} from 'state/common/reducerFactories';
import * as snapAdminActions from 'state/snapAdmin/actions/snapAdminActions';

import { Sequence } from 'config/constants';
import u from 'utils/safeUpdeep';

const removePublisherById = createRemoverByIdReducer('publisherId', snapAdminActions.ARCHIVE_ONE_PUBLISHER);

const publishers = serializeReducers([removePublisherById]);

const updateUsers = createEntityReducer('user', [
  snapAdminActions.GET_SNAP_ADMIN_USERS,
  snapAdminActions.EDIT_SNAP_ADMIN_USER,
]);

const removeUserById = createRemoverByIdReducer('userId', snapAdminActions.DELETE_SNAP_ADMIN_USER);
const users = serializeReducers([updateUsers, removeUserById]);

const activeEditPublisher = (state = {}, action: any) => {
  switch (action.type) {
    case snapAdminActions.ARCHIVE_ONE_PUBLISHER: {
      return {};
    }
    case snapAdminActions.SAVE_ONE_PUBLISHER_SETTING_DATA:
    case snapAdminActions.GET_ONE_PUBLISHER_SETTING_DATA:
    case snapAdminActions.SET_ONE_PUBLISHER_SETTINGS: {
      return u(action.payload, state);
    }
    default:
      return state;
  }
};

const cmsNotificationSetting = createSequenceSimpleReducer([
  snapAdminActions.GET_CMS_NOTIFICATION_SETTING,
  snapAdminActions.SET_CMS_NOTIFICATION_SETTING,
]);

function setTempTileLogoPropertiesHandler(state: any, action: any) {
  const update = {
    pendingTileLogoState: {
      [action.payload.componentId]: action.payload.properties,
    },
  };
  return u(update, state);
}

function removeTempTileLogoPropertiesHandler(state: any, action: any) {
  return u(
    {
      pendingTileLogoState: {
        [action.payload.componentId]: u.omit(action.payload.properties),
      },
    },
    state
  );
}

const adminSettingTempState = (state = {}, action: any) => {
  switch (action.type) {
    case snapAdminActions.SET_TILE_LOGO_PROPERTIES_DATA:
      return setTempTileLogoPropertiesHandler(state, action);
    case snapAdminActions.REMOVE_TILE_LOGO_PROPERTIES_DATA:
      return removeTempTileLogoPropertiesHandler(state, action);
    default:
      return state;
  }
};

const tileLogosByPublisherId = (state = {}, action: any) => {
  switch (action.type) {
    case snapAdminActions.UPDATE_TILE_LOGO:
      if (action.sequence !== Sequence.DONE || action.error) {
        return state;
      }
      return u(
        {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          [action.params.publisherId]: u.constant([].concat(state[action.params.publisherId], action.payload.id)),
        },
        state
      );
    default:
      return state;
  }
};

const tileLogosById = (state = {}, action: any) => {
  switch (action.type) {
    case snapAdminActions.UPDATE_TILE_LOGO: {
      if (action.sequence !== Sequence.DONE || action.error) {
        return state;
      }
      return u(
        {
          [action.params.assetId]: u.constant(action.payload),
        },
        state
      );
    }
    case snapAdminActions.DELETE_TILE_LOGO: {
      if (action.sequence !== Sequence.DONE || action.error) {
        return state;
      }
      return u(u.omit(action.params.assetId), state);
    }
    default:
      return state;
  }
};

const tileLogosSavingStatus = (state = {}, action: any) => {
  switch (action.type) {
    case snapAdminActions.SAVE_TILE_LOGO:
      if (action.sequence === Sequence.START) {
        return u(
          {
            [action.params.componentId]: true,
          },
          state
        );
      }
      if (action.sequence === Sequence.DONE) {
        return u(
          {
            [action.params.componentId]: false,
          },
          state
        );
      }
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  publishers,
  activeEditPublisher,
  cmsNotificationSetting,
  users,
  usersLoading: createSequenceCountingReducer(snapAdminActions.GET_SNAP_ADMIN_USERS),
  savingUser: createSequenceCountingReducer([
    snapAdminActions.CREATE_NEW_SNAP_ADMIN_USER,
    snapAdminActions.EDIT_SNAP_ADMIN_USER,
  ]),
  adminSettingTempState,
  tileLogosById,
  tileLogosSavingStatus,
  tileLogosByPublisherId,
});
