import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { hideNewInteractionPlaceholder } from 'state/editor/actions/editorActions';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as mediaLibrarySelectors from 'state/mediaLibrary/selectors/mediaLibrarySelectors';
import * as publisherStoryEditorActions from 'state/publisherStoryEditor/actions/publisherStoryEditorActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';

import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import { GrafanaMetrics } from 'utils/grafanaUtils';
import { incrementCounterByPublisher } from 'utils/grapheneUtils';
import { serializePromises } from 'utils/promiseUtils';

import {
  convertFilterByToQueries,
  generateSnapProperties,
  getRichSnapTypeFromMediaType,
  canMediaBeAddedToSnap,
  mapTargetTypeToMediaType,
} from 'views/mediaLibrary/utils/MediaLibraryUtils';

import { createMediaID } from 'types/assets';
import type { SnapId } from 'types/common';
import type { CuratedSnapMediaType } from 'types/curation';
import type { EditionID } from 'types/editionID';
import type { FilterByType, MediaItem, MediaType, TargetType } from 'types/mediaLibrary';
import { TargetEnum, FilterByEnum, MediaAssetType } from 'types/mediaLibrary';
import type { GetState } from 'types/redux';
import type { State } from 'types/rootState';
import { SnapType } from 'types/snaps';

export const SET_FILTER = 'mediaLibrary/SET_FILTER';
export const GET_MEDIA = 'mediaLibrary/GET_MEDIA';
export const TOGGLE_ZOOM = 'mediaLibrary/TOGGLE_ZOOM';

const defaultGetMediaArgs = {
  keepExistingSnaps: false,
  limit: 50,
  filterBy: undefined,
  target: TargetEnum.ANY,
};

export function setFilter(filterBy: FilterByType, target: TargetType) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: SET_FILTER,
      payload: {
        filter: filterBy,
      },
    });
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
    return dispatch(getMedia({ filterBy, target }));
  };
}

/**
 * If filterBy is empty or undefined, most recent snaps will be returned.
 * Because the backend doesn't support complex queries for Recent filter, the results
 * will be filtered again after response is returned with the filter specified in recentFilter.
 */
export function getMedia({
  // Do not rename to any other one since it's used in SnapGrid as keepExistingSnaps
  keepExistingSnaps = defaultGetMediaArgs.keepExistingSnaps,

  limit = defaultGetMediaArgs.limit,
  filterBy,
  target = defaultGetMediaArgs.target,
}: {
  keepExistingSnaps?: boolean;
  limit?: number;
  filterBy?: FilterByType;
  target?: TargetType;
} = defaultGetMediaArgs) {
  return (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    if (keepExistingSnaps && !mediaLibrarySelectors.hasMoreResults(state)) {
      return Promise.resolve();
    }

    const businessProfileId = publishersSelectors.getActivePublisherBusinessProfileId(state);
    const isShow = publishersSelectors.activePublisherIsShow(state);
    // Get current filter if filterBy is not provided
    const { mediaProfile, contentType, entryType } = convertFilterByToQueries(
      !filterBy ? mediaLibrarySelectors.currentFilter(state) : filterBy,
      target,
      isShow
    );

    const cursor = keepExistingSnaps ? mediaLibrarySelectors.getNextCursor(state) : '';

    return dispatch(
      createCallAction(
        {
          type: GET_MEDIA,
          keepExistingSnaps,
          responseFilter:
            filterBy === FilterByEnum.RECENT && target !== TargetEnum.ANY ? mapTargetTypeToMediaType[target] : null,
          bailout: (bailoutState: State) => mediaLibrarySelectors.isLoading(bailoutState),
        },
        {
          method: 'GET',
          endpoint: mediaLibraryAPI.mediaLibrary.getMedia({
            businessProfileId,
            mediaProfile,
            contentType,
            entryType,
            limit,
            cursor: cursor.length === 0 ? cursor : JSON.stringify(cursor),
          }),
        }
      )
    );
  };
}

export const toggleZoom = () => {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: TOGGLE_ZOOM,
    });
  };
};

export const addCuratedSnapToStory = (
  snapId: SnapId,
  mediaId: string,
  mediaType: MediaType | CuratedSnapMediaType,
  name?: string | null
) => {
  return (dispatch: Dispatch) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any) => any' is not a... Remove this comment to see the full error message
    return dispatch(mediaActions.getFreshTranscode(createMediaID(mediaId))).then((response: any) => {
      return dispatch(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        snapsActions.setSnapPropertiesAndSave(
          { snapId },
          { ...generateSnapProperties(mediaType, response.payload.id), name }
        )
      );
    });
  };
};

const addCuratedSnapWithOverlayToStory = (
  snapId: SnapId,
  mediaId: string,
  mediaType: MediaType | CuratedSnapMediaType,
  name?: string | null
) => {
  return (dispatch: Dispatch) => {
    Promise.all([
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any) => any' is not a... Remove this comment to see the full error message
      dispatch(mediaActions.getFreshTranscodeWithAssetType(createMediaID(mediaId), MediaAssetType.BASE_MEDIA)),
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any) => any' is not a... Remove this comment to see the full error message
      dispatch(mediaActions.getFreshTranscodeWithAssetType(createMediaID(mediaId), MediaAssetType.OVERLAY)),
    ]);
  };
};

export const addMediaToStory = ({
  snapId: topSnapId,
  attachmentId,
  editionId,
  snaps,
  initialIndex,
  isCuratedLayerEnabled = false,
}: {
  snapId?: SnapId;
  attachmentId?: SnapId;
  editionId: EditionID;
  snaps: MediaItem[];
  initialIndex: number;
  isCuratedLayerEnabled?: boolean;
}) => {
  return (dispatch: Dispatch, getState: GetState) => {
    // Log usage of the add media to story to grafana
    incrementCounterByPublisher(publishersSelectors.getActivePublisherDetails(getState()), GrafanaMetrics.MEDIA_V2, {
      type: 'otherUsage',
    });

    let filteredMedia = snaps;
    if (topSnapId) {
      // Based on whether the snap these media are being added to is top or bottom snap,
      // filter media to keep only those compatible ones. Although the UI should prevent selecting
      // media that are incompatible with the snap those media are being added to, add this filtering
      // logic just to be safe
      const isTopSnap = !attachmentId;
      filteredMedia = filteredMedia.filter(media => canMediaBeAddedToSnap(media, isTopSnap));
      // If adding to bottom snap, only keep the first long form video
      if (!isTopSnap) {
        filteredMedia = filteredMedia.slice(0, 1);
      }
    }

    const snapId = attachmentId || topSnapId;

    const promises = filteredMedia.map((media, index) => {
      return () => {
        const snapIdPromise: Promise<SnapId> = getSnapId(editionId, index, media, snapId, dispatch);

        let snapIdWithVideoPromise: Promise<SnapId>;
        if (snapId == null && media.mediaType === 'VIDEO_ATTACHMENT') {
          // Create bottom snap, will attach video to it.
          snapIdWithVideoPromise = createBottomSnap(snapIdPromise, dispatch);
        } else {
          // Attach video to exist snap.
          snapIdWithVideoPromise = snapIdPromise;
        }

        return snapIdWithVideoPromise.then(nonNullSnapId => {
          // We do not wait for the curated snap to upload before starting on the next one
          if (media.haveBaseMediaAndOverlay && isCuratedLayerEnabled) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => void' is... Remove this comment to see the full error message
            dispatch(addCuratedSnapWithOverlayToStory(nonNullSnapId, media.id, media.mediaType, media.creatorUsername));
          } else {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => any' is ... Remove this comment to see the full error message
            dispatch(addCuratedSnapToStory(nonNullSnapId, media.id, media.mediaType, media.creatorUsername));
          }
          return Promise.resolve(nonNullSnapId);
        });
      };
    });

    return serializePromises(promises);
  };

  // Create the snap if it is not exist.
  function getSnapId(
    snapEditionId: EditionID,
    index: number,
    media: MediaItem,
    snapId: SnapId | any,

    dispatch: Dispatch
  ): Promise<SnapId> {
    if (index === 0 && snapId) {
      return Promise.resolve(snapId);
    }

    // @ts-expect-error ts-migrate(2739) FIXME: Type 'AnyAction' is missing the following properti... Remove this comment to see the full error message
    return dispatch(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
      publisherStoryEditorActions.addNewSnapToEdition({
        editionId: snapEditionId,
        index: initialIndex + index,
        snapType: getRichSnapTypeFromMediaType(media.mediaType),
      })
    );
  }

  function createBottomSnap(topSnapIdPromise: Promise<SnapId>, dispatch: Dispatch): Promise<SnapId> {
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Promise<string | number | AnyAction>' is not... Remove this comment to see the full error message
    return topSnapIdPromise.then((nonNullSnapId: SnapId) => {
      return (
        Promise.resolve()
          .then(() => dispatch(snapsActions.markSnapSaveAsPending(nonNullSnapId)))
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
          .then(() => dispatch(hideNewInteractionPlaceholder()))
          .then(() =>
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
            dispatch(snapsActions.addInteractionAndSave({ snapId: nonNullSnapId }, SnapType.LONGFORM_VIDEO, {}))
          )
      );
    });
  }
};
