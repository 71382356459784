import { normalize, arrayOf } from 'normalizr';
import { combineReducers } from 'redux';

import { createSequenceHandlingReducer } from 'state/common/reducerFactories';
import * as showsActions from 'state/shows/actions/showsActions';
import { showSchema } from 'state/shows/schema/showsSchema';

import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

export const byId = createSequenceHandlingReducer({}, showsActions.LOAD_SHOWS, {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    const normalized = normalize(action.payload, arrayOf(showSchema));
    if ('result' in normalized) {
      return u(wrapEntitiesInUpdeepConstants(normalized.entities.season), state);
    }
    return state;
  },
});

export default combineReducers({
  byId,
});
