import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export default function init(registerIntlMessage: any) {
  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="width-less-than-min-media-validation-error"
        defaultMessage="The width was {width}. The minimum width is {minWidthPx}."
        description="Error message displayed to user when image/video is too small in width"
      />
    ),
    params: ['width', 'minWidthPx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="height-less-than-min-media-validation-error"
        defaultMessage="The height was {height}. The minimum height is {minHeightPx}."
        description="Error message displayed to user when image/video is too small in height"
      />
    ),
    params: ['height', 'minHeightPx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="width-more-than-max-media-validation-error"
        defaultMessage="The width was {width}. The maximum width is {maxWidthPx}."
        description="Error message displayed to user when image/video is too large in width"
      />
    ),
    params: ['width', 'maxWidthPx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="end-snap-default-label-2"
        description="End Snap default label"
        defaultMessage="Add for more!"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="height-more-than-max-media-validation-error"
        defaultMessage="The height was {height}. The maximum height is {maxHeightPx}."
        description="Error message displayed to user when image/video is too large in height"
      />
    ),
    params: ['height', 'maxHeightPx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="image-video-small-size-less-than-min-media-validation-error"
        defaultMessage="Image/video you have uploaded is too small in size. Either the width ({width}) or the height ({height}) is less than the minimum, {minSizePx}."
        description="Error message displayed to user when image/video is too small in size"
      />
    ),
    params: ['width', 'height', 'minSizePx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="size-not-equal-require-media-validation-error"
        defaultMessage="{width} x {height} is not the required size, {reqWidthPx} x {reqHeightPx}."
        description="Error message displayed to user when image/video is not exact size"
      />
    ),
    params: ['width', 'height', 'reqWidthPx', 'reqHeightPx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="width-not-equal-require-media-validation-error"
        defaultMessage="{width} is not the required size, {reqWidthPx}."
        description="Error message displayed to user when image/video is not exact width"
      />
    ),
    params: ['width', 'reqWidthPx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="height-not-equal-require-media-validation-error"
        defaultMessage="{height} is not the required size, {reqHeightPx}."
        description="Error message displayed to user when image/video is not exact height"
      />
    ),
    params: ['height', 'reqHeightPx'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="size-exceed-limit-validation-error"
        defaultMessage="The {limitType} type of image/video should not exceed size {limitSize} KB"
        description="Error message displayed to user when image/video is too big in file size"
      />
    ),
    params: ['limitType', 'limitSize'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="video-corrupt-validation-error"
        defaultMessage="The video file is corrupted. Please ensure your video is not corrupted and try uploading again"
        description="Error message displayed to user when video uploaded is corrupt"
      />
    ),
    params: ['limitType', 'limitSize'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="video-variable-frame-rate-validation-error"
        defaultMessage="The video file has a variable frame rate, ensure the video has a constant frame rate."
        description="Error message displayed to user when video uploaded is has a variable frame rate."
      />
    ),
    params: ['limitType', 'limitSize'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="video-no-audio-validation-error"
        defaultMessage="The video file doesn't have sound, kindly upload a video with sound."
        description="Error message displayed to user when video uploaded doesn't have sound"
      />
    ),
    params: ['limitType', 'limitSize'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="aspect-media-validation-error"
        description="Error message displayed to user when image/video is the wrong aspect ratio"
        defaultMessage="{width} x {height} does not meet {aspectRatio} aspect ratio."
      />
    ),
    params: ['width', 'height', 'aspectRatio'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="single-asset-aspect-media-validation-error"
        description="Error message displayed to user when image/video is the wrong aspect ratio, linking to episode creator"
        defaultMessage="{width} x {height} does not meet {aspectRatio} aspect ratio. Please use Episode Creator to upload this video and format it to portrait aspect ratio: {url}"
      />
    ),
    params: ['width', 'height', 'aspectRatio', 'url'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="generic-aspect-media-validation-error"
        description="Error message displayed to user when image/video is the wrong aspect ratio"
        defaultMessage="Video does not meet the correct aspect ratio, see the Help Centre for more information"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="duration-longer-than-max-duration-validation-error"
        description="Error message displayed to user when video duration is too long"
        defaultMessage="Duration of {duration}s is longer than the maximum duration of {maxDurationSeconds}s."
      />
    ),
    params: ['duration', 'maxDurationSeconds'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="duration-shorter-than-min-duration-validation-error"
        description="Error message displayed to user when video duration is too short"
        defaultMessage="Your video file is {duration} seconds. The minimum video length is {minDurationSeconds} seconds. Please upload a longer video file."
      />
    ),
    params: ['duration', 'maxDurationSeconds'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="error-reading-media-file"
        description="Error message displayed to the user when a media file cannot be read"
        defaultMessage="Error reading media file."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="media-file" description="Media file" defaultMessage="Media file" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="media-file-label" description="Media file label" defaultMessage="Media" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="subtitles-label" description="Subtitles field label" defaultMessage="Subtitles" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="snap-tags-label" description="Snap tags label" defaultMessage="Tags" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="connection-error-on-upload"
        description="Error message displayed to the user when upload fails for connection reasons"
        defaultMessage="Sorry but your network connection has been interrupted, please check your connection and try again."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="detail-error-reading-media-file"
        description="Error message displayed to the user when a media file cannot be read with detail hint"
        defaultMessage="Error reading media file. The file you uploaded appears to be corrupted. Please validate the file is a supported type and try again." /* eslint max-len: ["error", 250] */
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="detail-error-reading-video-file"
        description="Error message displayed to the user when a video file cannot be read with detail hint"
        defaultMessage="Unable to load video. Please ensure video uses H264 encoding mov or mp4 file" /* eslint max-len: ["error", 250] */
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-is-read-only"
        description="Show to the user when the story is read only and can't be modified"
        defaultMessage="You cannot modify this story."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publishing-disabled"
        description="Publishers may be created but not yet approved for publishing"
        defaultMessage="Publishing is disabled"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publishing-disabled-host-user"
        description="Publisher is missing host user which prevents publishing"
        defaultMessage="Please associate a channel username to re-enable publishing"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publishing-paused"
        description="Publishing has been temporarily paused"
        defaultMessage="You are unable to Publish new Stories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publishing-disabled-warning"
        description="Publishing has been disabled for the publisher"
        defaultMessage="Publishing Disabled"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-is-scheduled"
        description="Show to the user when the story is scheduled and can't be modified"
        defaultMessage="Scheduled stories cannot be modified. To edit, drag this story out of 'Scheduled' in the Home tab"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-is-saving"
        description="Show to the user when the story is being saved"
        defaultMessage="Saving..."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="edition-is-not-publishable"
        description="Show to the user when the the story is not ready to be published"
        defaultMessage="This story is not ready to be published."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-story"
        defaultMessage="Schedule Story"
        description="title of the modal that lets users schedule a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirm-button-modal"
        description="Text indicating to user to confirm a prompt"
        defaultMessage="Confirm"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="ok-button-modal"
        description="Text indicating to user to agree on a prompt"
        defaultMessage="Okay"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="send-button-modal"
        description="Text indicating to user to send information"
        defaultMessage="Send"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="cancel-button-modal"
        description="Text indicating to user to cancel a prompt"
        defaultMessage="Cancel"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-next-available"
        description="Select the next available time a story can be scheduled"
        defaultMessage="Next available time"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="describe-a-problem"
        description="Placeholder asking the user to describe the problem they are having"
        defaultMessage="Describe the problem, idea or question"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="send-relevant-url-mandatory"
        description="Placeholder asking the user to send a relevant url"
        defaultMessage="Send us a relevant URL"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-submit-datetime"
        description="Submit Schedule date time selection"
        defaultMessage="Submit"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-story-modal"
        description="Schedule Story date time picker"
        defaultMessage="Schedule Story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-time-locally"
        description="Prefix text showing the local publish time"
        defaultMessage="Publish time locally"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-time-to-live"
        description="Prefix text showing how long until an edition is live"
        defaultMessage="Live in"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-time-hours-mins"
        description="Text showing how long until an edition is live"
        defaultMessage="{hours} hrs {minutes} mins"
      />
    ),
    params: ['hours', 'minutes'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="yes-button"
        description="Text indicating to user to select yes on a given question"
        defaultMessage="Yes"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="no-button"
        description="Text indicating to user to select no on a given question"
        defaultMessage="No"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-not-ready"
        defaultMessage="This Story isn't ready to be scheduled."
        description="tooltip message when user hovers over the schedule button and can't schedule"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-combine-video-building"
        defaultMessage="Preparing your episode for Snapchat, this may take several minutes."
        description="tooltip message when user hovers over the schedule button and can't schedule"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-missing-end-snaps"
        defaultMessage="This Story is missing its end Snap."
        description="tooltip message when user hovers over the schedule button and it's missing the end Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-tile-missing-headline"
        defaultMessage="Tile missing headline"
        description="tooltip message when a tile is missing its headline"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-invalid-tile-headline-text"
        defaultMessage="Tile headline text invalid"
        description="tooltip message when a tile has a headline with invalid text"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-unhealthy-tile-headline-text"
        defaultMessage="Tile headline text inadvisable"
        description="tooltip message when a tile has a headline with unhealthy text"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-tile-missing"
        defaultMessage="Tile required for Snap"
        description="tooltip message when a required tile is missing from the Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-tile-missing-logo"
        defaultMessage="No tile logos set up for this account. Please upload one in the Settings page"
        description="tooltip message when a tile logo is missing from a tile"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-illegal-publish-order-seasons"
        defaultMessage="You have content in an earlier Season that needs to be published first"
        description="tooltip message when the publisher tries to publish seasons out of order"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-illegal-episode-state"
        defaultMessage="Edition must be part of a show to be published"
        description="tooltip message when the publisher hasn't marked an episode part of a show"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-incomplete-publisher-profile"
        defaultMessage="Complete your Profile in Settings"
        description="tooltip message when the publisher profile has not been completed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-stitched-video-stale"
        defaultMessage="Processing Failed"
        description="Message appearing when something goes wrong with processing the story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-tile-incomplete"
        defaultMessage="Tile missing media or headline"
        description="tooltip message when a tile is incomplete"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-segment-tile-missing"
        defaultMessage="Tile required for Segment"
        description="tooltip message when a tile is missing from a segment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-missing-tags"
        defaultMessage="Snap requires content tags"
        description="tooltip message when a snap does not have the required tags"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-snap-incomplete"
        defaultMessage="Incomplete Snap"
        description="tooltip message when a snap is incomplete"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-snap-attachment-incomplete"
        defaultMessage="Build failed: Re-upload media"
        description="tooltip message when a snap has an incomplete attachment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="tile-build-error"
        defaultMessage="Tile build failed: Re-upload media"
        description="tooltip message when a tile build failed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="tile-logo-build-error"
        defaultMessage="Tile logo build failed: Re-upload media"
        description="tooltip message when a tile logo failed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-snap-inconsistent-dimentions"
        defaultMessage="Please upload a video that is the same resolution as the other snaps in your story"
        description="tooltip message when a snap has inconsistent dimentions"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-moderation-flagged"
        defaultMessage="Moderation issue"
        description="tooltip message when a snap has been flagged by moderation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-build-error"
        defaultMessage="Build failed: Re-upload media"
        description="tooltip message when a build error happens to a snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-contains-image"
        defaultMessage="Episodes must contain video snaps only"
        description="tooltip message when a snap part of show is an image"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-building"
        defaultMessage="Snap is building"
        description="tooltip message when a snap is building"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-sensitive-content"
        defaultMessage="Flagged for Sensitive Content"
        description="tooltip message when a snap is flagged for sensitive content"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-build-oversized"
        defaultMessage="Snap file size too large: Compress and re-upload media"
        description="tooltip message when the build size is too large"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-generating-subtitles"
        defaultMessage="Generating subtitles"
        description="tooltip message when subtitles generation is in progress"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-incomplete-snaps"
        defaultMessage="This Story has incomplete Snaps."
        description="tooltip message when user hovers over the schedule button and it has incomplete Snaps"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-incomplete-snaps"
        defaultMessage="Multiple Snaps are incomplete"
        description="tooltip message when user hovers over the schedule button and it has incomplete Snaps"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-moderation-flagged-snaps"
        defaultMessage="Multiple Snaps have been flagged by moderation"
        description="pill message on moderation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-incomplete-snap"
        defaultMessage="Snap {index} is incomplete"
        description="message when one single snap is incomplete"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-one-and-incomplete-snap"
        defaultMessage="Snap is incomplete"
        description="message when story has just one snap and it is incomplete"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-moderation-flagged-snap"
        defaultMessage="Snap {index} flagged by moderation"
        description="message when one single snap was flagged by moderation"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-one-moderation-flagged-snap"
        defaultMessage="Snap flagged by moderation"
        description="message when story has just one snap and was flagged by moderation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-snaps-with-errors"
        defaultMessage="Multiple Snaps have errors"
        description="message when multiple snaps have errors"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-snap-with-error"
        defaultMessage="Snap {index} has an error"
        description="message when one single snap has an error"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-has-one-snap-with-error"
        defaultMessage="Snap has an error"
        description="message when story has just one snap and it has an error"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-building-for-discover"
        defaultMessage="Snaps building for Discover"
        description="message when snaps are being built"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-building-for-discover-tooltip"
        defaultMessage="We're encoding your videos for Discover"
        description="message for tooltip when snaps are being built"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="drag-and-drop-snap-replace-media-subtitle"
        defaultMessage="Drop to replace Snap # {snapIndex} media"
        description="Subtitle for replacing a Snap using Drag and Drop"
      />
    ),
    params: ['snapIndex'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-user-missing-name"
        description="All users require name"
        defaultMessage="All users require a name."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-user-missing-snapUsername"
        description="All users require snap username"
        defaultMessage="All users require a snap username."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-user-missing-email"
        description="All users require emails"
        defaultMessage="All users require a valid email."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-internal-admin-invalid-email"
        description="snap admins require an email that ends with @snapchat.com"
        defaultMessage="Snap employees must have an email that ends in @snapchat.com or @c.snap.com"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-incomplete-tiles"
        defaultMessage="This Story has incomplete tiles."
        description="tooltip message when user hovers over the schedule button and it has incomplete tiles"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-edit-tile"
        defaultMessage="Edit tile"
        description="specifies that tiles can be edited from the modal"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-oversized-snaps"
        defaultMessage="Please check the build size indicators above each Snap and adjust your content as necessary."
        description="tooltip message when user hovers over the schedule button and it has oversized Snaps"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-build-failed"
        defaultMessage="We were unable to build the Story."
        description="tooltip message when user hovers over the schedule button and build has failed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-no-snaps"
        defaultMessage="This Story doesn't have any Snaps."
        description="tooltip message when user hovers over the schedule button and the story has no snaps"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-generating-subtitles"
        defaultMessage="Generating subtitles from your video"
        description="tooltip message when user hovers over the schedule button and subtitles generation is in progress"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-shot-detection-in-progress"
        defaultMessage="Generating Snaps from your video"
        description="tooltip message when user hovers over the schedule button and snap generation is in progress"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="unified-story-status-shot-detection-in-progress"
        defaultMessage="Processing your video"
        description="tooltip message when user hovers over the schedule button in the Single Snap Builder and shot generation is in progress"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-shot-detection-failed"
        defaultMessage="Failed to generate Snaps"
        description="tooltip message when user hovers over the schedule button and snap generation has failed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="edit-story-button"
        defaultMessage="Edit Story"
        description="[Button] unlocks a story and allows user to edit it"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirm-edit-story"
        defaultMessage="Are you sure you want to edit this story?"
        description="[Alert Title] verifies if user wants to unlock a story for editing"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="view-story-button"
        defaultMessage="View Story"
        description="[Button] views the story in the Story Editor"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirm-delete-outcome"
        defaultMessage="Are you sure you want to delete this outcome?"
        description="[Alert Title] verifies if user wants to delete the outcome"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirm-delete-tile"
        defaultMessage="Are you sure you want to delete this tile?"
        description="[Alert Title] verifies if user wants to delete the tile"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirm-delete-tile-headline"
        defaultMessage="Are you sure you want to delete this headline?"
        description="[Alert Title] verifies if user wants to delete the tile headline"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="status-incomplete"
        defaultMessage="Incomplete"
        description="Status message used to signal the incomplete work"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="edit-move-to-drafts-button"
        defaultMessage="Edit & Move to Drafts"
        description="[Button] unlocks the story for editing and moves it to drafts"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="time-am-moniker" defaultMessage="am E.T" description="AM time notation" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="time-pm-moniker" defaultMessage="pm E.T" description="PM time notation" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="error-mls-claim"
        description="Generc error message for uploads that were rejected by the service"
        defaultMessage="Error uploading media"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-validation-error-excessive-bitrate"
        description="Error message for uploaded media that contains a bitrate that is rejected by the service for being too high"
        defaultMessage="Your video failed to upload due to it having higher bitrate than we support"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-validation-error-variable-frame-rate"
        description="Error message for media that contains variable frame rate, any media that contains variable frame rate is invalid and rejected by the service"
        defaultMessage="Your video failed to upload due to it being variable frame rate"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-validation-error-unexpected-file-type"
        description="Error message for media that is not a video"
        defaultMessage="The file uploaded is not a video, please try a different format."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-validation-error-unexpected-duration"
        description="Error message for media that the video does not the duration requirements"
        defaultMessage="The video uploaded does not meet the duration requirements for Spotlight."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-validation-error-wrong-aspect-ratio"
        description="Error message for media that the video does not meet the aspect ratio requirements"
        defaultMessage="The video uploaded does not meet the 9:16 aspect ratio required."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-validation-error-no-sound"
        description="Error message for media that the video does does not have sound"
        defaultMessage="No sound track detected on the video uploaded."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="error-file-too-large"
        description="Error message for uploads that are too large"
        defaultMessage="File size of {fileSize} exceeds the maximum size of {maxFileSize}"
      />
    ),
    params: ['fileSize', 'maxFileSize'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="error-too-many-files-to-upload"
        description="Error when user tries to upload more than the allowed number of files"
        defaultMessage="Only {filesLimit} files can be uploaded at a time"
      />
    ),
    params: ['filesLimit'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-dropdown-prompt"
        description="[Dropdown] Placeholder to prompt user to select a story"
        defaultMessage="Select story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-option-create"
        defaultMessage="Create a story"
        description="[Dropdown] Create a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-dropdown-placeholder"
        defaultMessage="Select a story"
        description="[Dropdown] placeholder for when no story is selected"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-dropdown-no-results"
        defaultMessage="No Story Found"
        description="[Dropdown] message when no results are found"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="loading-message-generic"
        defaultMessage="Loading..."
        description="Loading message to be shown below a progress bar"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="loading-message-onboarding"
        defaultMessage="Configuring new profile..."
        description="Loading message to be shown below a progress after creating profile via onboarding wizard"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="loading-message-archive"
        defaultMessage="Loading Archive..."
        description="Loading message to be shown when the archive is loading"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="loading-message-analytics"
        defaultMessage="Loading Analytics Metrics..."
        description="Loading message to be shown below a progress bar"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="loading-message-story"
        defaultMessage="Loading Story..."
        description="Loading message to be shown below a progress bar"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="close-button-label" defaultMessage="Close" description="Label for close button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="create-button-label" defaultMessage="Create" description="Label for create button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="show-tiles" defaultMessage="Show Tiles" description="[Toggle] shows or hides tiles" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="preview-button-label" defaultMessage="Preview" description="Label for Preview button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="restart-button-label" defaultMessage="Restart" description="Label for restart button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="discard-button" description="Discard button" defaultMessage="Discard" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-publishing-info"
        defaultMessage="Publishing Info"
        description="Publishing info label message"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-snap-tile"
        defaultMessage="Tiles for Snap #{index}"
        description="Title for list of tiles per snap in mobile analytics page"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="title-create-new-story"
        defaultMessage="New Story"
        description="[Title] Title of pop up when user creates a new story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="title-edit-story-name"
        defaultMessage="Edit Story"
        description="[Title] Title of pop up when user edits a story name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="give-your-story-a-name"
        defaultMessage="Give your story a name"
        description="[Placeholder] Text field placeholder for story name field"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-name-field"
        defaultMessage="Story Name"
        description="[Field name] Form field - name for a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-name-field-hint"
        defaultMessage="You can change the name of a draft at any time."
        description="[TextField Hint] Instructs user how to fill out field"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="submit-button-text" defaultMessage="Submit" description="[Button] generic submit button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="save-button-text" defaultMessage="Save" description="[Button] generic save button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="update-snapchat-article-message"
        defaultMessage="Update Snapchat to watch this video!"
        description="Error message shown to user when App version is too low to watch video"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="snap" defaultMessage="Snap" description="Name of an individual snap" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-with-index"
        defaultMessage="Snap {index}"
        description="Snap with appended number, e.g. 'Snap 23'"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="username-edit-user-modal"
        description="username field in edit user modal"
        defaultMessage="Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snapUsername-edit-user-modal"
        description="snap username field in edit user modal"
        defaultMessage="Snap Username"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="ready-to-schedule"
        description="Text indicating to user that the story is complete and ready for scheduling"
        defaultMessage="Ready to be scheduled"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-draft-story-title"
        defaultMessage="Draft story"
        description="Analytics draft story title message"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="analytics-published-story-title" defaultMessage="Story was published {timePublished}" />
    ),
    params: ['timePublished'],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="analytics-updated-story-title" defaultMessage="Last updated {timeUpdated}" />,
    params: ['timeUpdated'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="goes-live"
        description="Story state is 'scheduled', indicates that it goes live in X time"
        defaultMessage="Goes Live"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="becomes-available"
        description="Story state is 'scheduled', indicates that it become available in X time"
        defaultMessage="Becomes available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="link-message-insert"
        defaultMessage="link"
        description="Text for a link inserted into other messages"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-pill-headline"
        defaultMessage="Headline"
        description="Title for a headline pill in the CMS"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="tile"
        defaultMessage="Tile"
        description="Title for a tile in the CMS. Tiles are the images+text used as entry point to a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="hour-min-sec"
        description="Show time in format of hours - minutes - seconds"
        defaultMessage="{hours}h {minutes}m {seconds}s"
      />
    ),
    params: ['hours', 'minutes', 'seconds'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="attachment" defaultMessage="Attachment" description="Title for a bottom snap in the CMS" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-long-form-video"
        defaultMessage="Long Form Video"
        description="Attachment Type - Long Form Video"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="poll-expiration-after-1-day"
        defaultMessage="after 1 day"
        description="Defines a label for poll, which expires after 1 day"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="poll-expiration-after-x-day"
        defaultMessage="after {days} days"
        description="Defines a label for poll, which expires after 1 day"
      />
    ),
    params: ['days'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="attachment-article" defaultMessage="Article" description="Attachment Type - Article" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-opinion-poll"
        defaultMessage="Opinion Poll"
        description="Attachment Type - Opinion Poll"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="attachment-vote" defaultMessage="Vote" description="Attachment Type - Vote" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-trivia-quiz"
        defaultMessage="Trivia Quiz"
        description="Attachment Type - Trivia Quiz"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-personality-quiz"
        defaultMessage="Personality Quiz"
        description="Attachment Type - Personality Quiz"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="attachment-web-view" defaultMessage="Web View" description="Attachment Type - Web View" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-web-view-bitmoji"
        defaultMessage="Bitmoji Web View"
        description="Attachment Type - Web View"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-app-install"
        defaultMessage="App Install"
        description="Attachment Type - App Install"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-deep-link"
        defaultMessage="Deep Link"
        description="Attachment Type - Deep Link"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="attachment-camera"
        defaultMessage="Camera Attachment"
        description="Attachment Type - Camera"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="outcome-default-text"
        defaultMessage="Outcome {index}"
        description="Default description for outcome"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="tile-default-text"
        defaultMessage="Tile {index}"
        description="Default Description for Tiles"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="poll-question-indicator"
        defaultMessage="Question {index} of {count}"
        description="Indicator of the selected question in polls."
      />
    ),
    params: ['index', 'count'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="terms-and-conditions-title"
        defaultMessage="Terms & Conditions"
        description="Terms and conditions modal title"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="do-not-show-this-message-again"
        defaultMessage="Do not show this message again"
        description="Checkbox text to select if the modal message is to be shown again"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="article-ingestion-terms"
        defaultMessage="All imported material is Content that complies with our agreements and guidelines."
        description="Article ingestion terms and conditions"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-title"
        defaultMessage="Take down Live Story"
        description="Title on pop up informing user options for taking down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="public-archive-label"
        defaultMessage="Available"
        description="Available Label for Dropdown Menu"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-paragraph"
        defaultMessage="This action will remove your Story from Stories & Discover in Snapchat."
        description="Paragraph on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="draft-status"
        description="Cell text signifying that a story is a draft"
        defaultMessage="Draft"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-publishing"
        description="Story state is 'publishing'"
        defaultMessage="Publishing"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-processing"
        description="Story state is 'publishing'"
        defaultMessage="Processing"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-unpublishing"
        description="Story state is 'unpublishing'"
        defaultMessage="Unpublishing"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="status-column-header"
        description="Column Header with label Status on homepage table"
        defaultMessage="Status"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="name-column-header"
        description="Column Header with label Name on user management table"
        defaultMessage="Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="url-msg" description="URL" defaultMessage="URL" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="private-archive-label"
        defaultMessage="Unavailable"
        description="Unavailable Label for Dropdown Menu"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-instructions"
        defaultMessage="Please select where you would like to send your Story."
        description="Instructions on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="scheduled-status"
        description="Cell text signifying that a story is scheduled"
        defaultMessage="Scheduled"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-drafts"
        defaultMessage="Drafts"
        description="Section on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-drafts-description"
        defaultMessage="Send your story here if you plan to republish it. Remember, you don't need to take down your story to make a live edit."
        description="Draft Section Description on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-public"
        defaultMessage="Available"
        description="Section on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-public-description"
        defaultMessage="Send your story here if you still want Snapchatters to have access to your story via Search, Snapcodes, Deeplinks, and Sharing."
        description="Public Section Description on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-private-archive-title"
        defaultMessage="Unavailable"
        description="Section on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-private-description"
        defaultMessage="Send your story here if you don't want Snapchatters to have access to your story in Snapchat at all."
        description="Private Section Description on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-private-danger"
        defaultMessage="This is a dangerous operation to perform on a Live Story, and will result in a negative user experience."
        description="Private Section Danger on pop up informing user they are about to take down a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="take-down-live-story-modal-type-instructions"
        defaultMessage="Type {takeDown} Below to Confirm"
        description="Instructions to type text into below text box to confirm"
      />
    ),
    params: ['takeDown'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-since"
        defaultMessage="Live since {date}"
        description="When did the content become live"
      />
    ),
    params: ['date'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="auto-publish-in"
        defaultMessage="Auto publish in"
        description="When will the story be published"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="headline-too-long"
        defaultMessage="Headline too long"
        description="Used for happening now stories, when the headline provided by publisher is too long and will be truncated"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="all-archive-label" defaultMessage="All" description="All Archive Label for Dropdown Menu" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="private-archive-confirmation-message"
        description="Modal message for confirming that the user wants to send a story to the private archive"
        defaultMessage="Are you sure you want make this Story unavailable?"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="private-archive-non-snap-admin-warning"
        description="Warning to non admins that they cannot reverse moving to private archive."
        defaultMessage="This action is not reversible."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="private-archive-from-archive-confirmation-message"
        description="Modal message for confirming that the user wants to send a story to the private archive which was live."
        defaultMessage="Snapchatters will no longer be able to load this story."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="private-archive-from-archive-warning"
        description="Warning message for the user about making the story private too quickly"
        defaultMessage="If this edition was recently live, it will result in a poor user experience."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="set-archive-title"
        description="Message title for popup when making stories available"
        defaultMessage="Make Story available?"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="set-archive-description-paragraph"
        description="Message in popup describing to user that available stories are visible in app"
        defaultMessage="Making a Story available will make it visible to all users in the app."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="set-archive-requirements"
        description="Requirements for a story to be moved to the public archive"
        defaultMessage="Requires a story to be Publishable."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="private-archive-lock-menu-item"
        description="Menu item for an admin to lock a story"
        defaultMessage="Blocked"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="set-archive-admin-disabled"
        description="Warning to user that they won't be able to move a story to the archive because an admin has locked it"
        defaultMessage="An admin has locked this story. You will be unable to move it to the archive."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="set-available-disabled-by-admin"
        description="Warning to user that they won't be able to make a story available because an admin has locked it"
        defaultMessage="An admin has locked this story. You will be unable to make it available."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="archived-status"
        description="Cell text signifying that a story is archived"
        defaultMessage="Archived"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="private-archive-story-title"
        description="Title for popup to send a story to the private archive"
        defaultMessage="Unavailable"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="upload-alert-one-file"
        description="Error message indicating the user must upload only one file at time"
        defaultMessage="Please select exactly one file to upload"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-user-snap-email"
        description="Error message when trying to add snap employee via a regular way"
        defaultMessage="Cannot add Snap employees as regular users"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="create-employee-user-email-field-placeholder"
        description="Placeholder text for create new admin user email field"
        defaultMessage="Email @snapchat.com or @c.snap.com"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="email-edit-user-modal"
        description="email field in edit user modal"
        defaultMessage="Email"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="description-table-in-edit-user-modal"
        description="Table header for the description column in the edit user modal"
        defaultMessage="Description"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="upload-alert-file-type"
        description="Error message indicating the user has uploaded an invalid file type"
        defaultMessage="The file type '{type}' is not a valid file type, please select another file and try again."
      />
    ),
    params: ['type'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-status"
        description="Cell text signifying that a story is live"
        defaultMessage="Live"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="homepage-analytics-button"
        defaultMessage="Analytics"
        description="The text on a button that links to analytics for a story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="sidenav-template-option"
        description="Sidenav Creative Templates title option"
        defaultMessage="Templates"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-calendar-view-headline"
        description="Headline title for edition on Analytics Calendar View"
        defaultMessage="Edition from {date} {time}"
      />
    ),
    params: ['date', 'time'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="filters-recent"
        description="Show most recent Media Library entries"
        defaultMessage="Recent"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="edit-button-label" defaultMessage="Edit" description="Label for edit button" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="add-outcome-button-label"
        defaultMessage="Add outcome"
        description="Label for Add outcome button"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="add-new-tile-button-label"
        defaultMessage="Add tile"
        description="Label for add new tile button"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="replace-button-label" defaultMessage="Replace" description="Label for replace button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="upload-button-label" defaultMessage="Upload" description="Label for upload button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="delete-button-label" defaultMessage="Delete" description="Label for delete button" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="done-editing-button-label"
        defaultMessage="Done Editing?"
        description="Label for Done Editing button."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="site-name" defaultMessage="Publisher Stories" description="The name of the website" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="publisher-tools" defaultMessage="Publisher Tools" description="The name of the website" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-studio"
        defaultMessage="Story Studio"
        description="The name of the website for new creator platform users"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="mobile-homepage-view-title"
        defaultMessage="Stories ready for preview"
        description="Title on mobile homepage for the stories that are ready for preview"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="tile-headline-hide-modal"
        defaultMessage="Are you sure you want to hide tile headline?"
        description="Modal text asking if the user wants to hide their tile headline"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-header-available"
        description="Modal header asking the user when he wants the story to become available"
        defaultMessage="When do you want this story to become available?"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-header-unavailable"
        description="Modal header asking the user when he wants the story to become unavailable"
        defaultMessage="When do you want this story to become unavailable?"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-header-available-multiple"
        description="Modal header asking the user when he wants multiple stories to become available"
        defaultMessage="When do you want the {noOfStories} selected stories to become available?"
      />
    ),
    params: ['noOfStories'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-header-unavailable-multiple"
        description="Modal header asking the user when he wants multiple stories to become unavailable"
        defaultMessage="When do you want the {noOfStories} selected stories to become unavailable?"
      />
    ),
    params: ['noOfStories'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-subheader-unavailable"
        description="Second part of the modal header asking the user when he wants the story to become unavailable"
        defaultMessage="This will hide content in the Discover feed and Search and stop Users from accessing the content from links in chat messages and snapcodes." /* eslint max-len: ["error", 250] */
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-button-label"
        description="Button label to open schedule modal"
        defaultMessage="Schedule"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-unavailable-immediately"
        description="Button label to make unavailable as soon as possible"
        defaultMessage="Immediately"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-available-immediately"
        description="Button label to make available as soon as possible"
        defaultMessage="Immediately"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-next-available-time"
        description="Button label to schedule as soon as possible. Mins means minutes"
        defaultMessage="Next available time (2 mins)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="make-available-label-schedule-modal"
        description="Make available label"
        defaultMessage="Make Available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="make-unavailable-label-schedule-modal"
        description="Make unavailable label"
        defaultMessage="Make Unavailable"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="make-available-instruction-schedule-modal"
        description="Make available instructions"
        defaultMessage="Select a date and time for your story to become available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publish-story-label-schedule-modal"
        description="Publish story label"
        defaultMessage="Publish Story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="make-unavailable-instruction-schedule-modal"
        description="Make unavailable instructions"
        defaultMessage="Select a date and time for your story to become unavailable"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="tile-logo-warning-hide-modal"
        defaultMessage="Your publisher logo must be present in your uploaded media. Are you sure you want to hide tile logo?"
        description="Modal text explaining that logo should be in media and asking if the user wants to hide their tile logo"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-private-archive"
        description="Story state is 'hidden'"
        defaultMessage="Unavailable"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-submitting"
        description="Story state is scheduled_pending_approval, indicates that it will be submitted"
        defaultMessage="Submitting"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-submitting-at"
        description="Story state is scheduled_pending_approval, indicates that it will be submitted in X time"
        defaultMessage="Will be submitted at {date}"
      />
    ),
    params: ['date'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-revise"
        description="Story state is need revision. Indicates the story needs to be revised before going live"
        defaultMessage="Revise story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-revise-since"
        description="Story state is need revision since X time. Indicates the story needs to be revised before going live"
        defaultMessage="Revise story since {date}"
      />
    ),
    params: ['date'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-live-revise"
        description="Story state is need revision. Indicates the story needs to be revised before going live"
        defaultMessage="Live, revise story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-live-revise-since"
        description="Story state is need revision since X time. Indicates the story needs to be revised before going live"
        defaultMessage="Live, revise story since {date}"
      />
    ),
    params: ['date'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-reviewed"
        description="Story state is Reviewed. Indicates the story was reviewed"
        defaultMessage="Reviewed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-submitted"
        description="Story state is Submitted. Indicates the story was submitted"
        defaultMessage="Submitted"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirmation-label-schedule-modal"
        description="Confirmation label"
        defaultMessage="Confirmation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirmation-instruction-schedule-modal-available"
        description="Confirmation instructions"
        defaultMessage="This is when your story will become available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirmation-instruction-schedule-modal-unavailable"
        description="Confirmation instructions"
        defaultMessage="This is when your story will become unavailable"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirmation-time-to-live-schedule-modal-available"
        description="Time until story becomes available"
        defaultMessage="Becomes available {timeToLive}"
      />
    ),
    params: ['timeToLive'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirmation-time-to-live-schedule-modal-available-immediately"
        description="Story will become available immediately"
        defaultMessage="Becomes available now"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirmation-time-to-live-schedule-modal-unavailable"
        description="Time until story becomes unavailable"
        defaultMessage="Becomes unavailable {timeToLive}"
      />
    ),
    params: ['timeToLive'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="confirmation-time-to-live-schedule-modal-unavailable-immediately"
        description="Story becomes unavailable now"
        defaultMessage="Becomes unavailable now"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-end-date"
        description="label for final date in period"
        defaultMessage="End Date"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-state-public-archive"
        description="Indicates the story state is searchable/viewable to the public"
        defaultMessage="Available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-end-time"
        description="label for final time in period"
        defaultMessage="End Time"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="cat" description="Label for a zendesk option" defaultMessage="CAT" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="build-upload-error"
        description="Label for a zendesk option"
        defaultMessage="Build/Upload error or Delay"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="content-guidelines"
        description="Label for a zendesk option"
        defaultMessage="Content Guidelines"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="feature-request"
        description="Label for a zendesk option"
        defaultMessage="Feature Request"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="logging-in" description="Label for a zendesk option" defaultMessage="Logging in" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-save"
        description="Label for a zendesk option"
        defaultMessage="Unable to Save/Publish Story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="question" description="Label for a zendesk option" defaultMessage="Question" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="sum-up-request"
        description="Placeholder asking the user to add subject to their ticket request"
        defaultMessage="Sum up your request"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="snapcodes" description="Label for a zendesk option" defaultMessage="Snapcodes" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="low-priority" description="Label for a zendesk priority" defaultMessage="Low" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="normal-priority" description="Label for a zendesk priority" defaultMessage="Normal" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="high-priority" description="Label for a zendesk priority" defaultMessage="High" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="urgent-priority" description="Label for a zendesk priority" defaultMessage="Urgent" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-snap-promote"
        description="Zendesk dropdown snap promote option"
        defaultMessage="Snap Promote"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-snap-publisher-tools"
        description="Zendesk dropdown snap publisher option"
        defaultMessage="Snap Publisher Tools (ie:  Curation Tool, Episode Creator)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-other"
        description="Zendesk dropdown other option"
        defaultMessage="Other"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-snapcode"
        description="Zendesk dropdown Snapcode option"
        defaultMessage="Snapcode"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-story-build-issues"
        description="Zendesk dropdown story creation option"
        defaultMessage="Story build issue (ie: cannot upload assets, slow build times, etc.)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-unable-to-publish"
        description="Zendesk dropdown unable to publish option"
        defaultMessage="Unable to Publish Story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-user-management-logging-in"
        description="Zendesk dropdown user management and logging in option"
        defaultMessage="User Management / Logging in"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-analytics"
        description="Zendesk dropdown analytics option"
        defaultMessage="Analytics"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-in-app"
        description="Zendesk dropdown in app option"
        defaultMessage="In App"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-post-launch"
        description="Zendesk dropdown post launch option"
        defaultMessage="In-app / Post Launch (ie: Discover feed quality, loading)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-content-violations"
        description="Zendesk dropdown content violations option"
        defaultMessage="Content Violations"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="zendesk-dropdown-feature-request"
        description="Zendesk dropdown feature request option"
        defaultMessage="Feature Request"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="show-crop-guide"
        defaultMessage="Show crop guide"
        description="Button text to show the crop area"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-start-date"
        description="label for initial date in period"
        defaultMessage="Start Date"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="original-start-date"
        description="label for original start date of a replay"
        defaultMessage="Original Start Date"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="hide-crop-guide"
        defaultMessage="Hide crop guide"
        description="Button text to hide the crop area"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-start-time"
        description="label for initial time in period"
        defaultMessage="Start Time"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="original-start-time"
        description="label for original start time of a replay"
        defaultMessage="Original Start Time"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="cropped-area"
        defaultMessage="Cropped area"
        description="Text indicating, that the rectangle visible indicates the cropped area"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="schedule-modal-timezone" description="timezone label" defaultMessage="Timezone" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="headline-placeholder-text-2"
        description="Placeholder text to direct the user where to enter the headline"
        defaultMessage="Type headline here"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-export-csv-text"
        description="Text on the button that exports analytics as a CSV"
        defaultMessage="Export CSV"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="schedule-modal-show-end-date-time"
        description="label for showing the controls for selecting end date and time"
        defaultMessage="Set end date/time"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="date-range-picker-text"
        description="Title of the date range picker modal"
        defaultMessage="Select date"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="date-range-picker-apply-button-text"
        description="Text of the button that applies the date range in the date range picker"
        defaultMessage="Apply"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="date-range-picker-clear-button-text"
        description="Text of the button that clears the date range in the date range picker"
        defaultMessage="Clear filter"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="available-status"
        description="Cell text signifying that a story is available"
        defaultMessage="Available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="unavailable-status"
        description="Cell text signifying that a story is unavailable"
        defaultMessage="Unavailable"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="autogenerate-tile-button"
        description="Text on the button that autogenerates a tile from a topsnap"
        defaultMessage="Auto-generate"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="time-range-today" description="Text indicating today" defaultMessage={'Today, {today}'} />
    ),
    params: ['today'],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="message-today" description="Text indicating today" defaultMessage="Today" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="time-range-yesterday"
        description="Text indicating yesterday"
        defaultMessage={'Yesterday, {yesterday}'}
      />
    ),
    params: ['yesterday'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-unique-viewers"
        defaultMessage="Unique Viewers"
        description="Graph legend label for unique viewers series"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-unique-viewers-organic"
        defaultMessage="Unique Viewers Organic"
        description="Graph legend label for unique viewers organic series"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-unique-viewers-paid"
        defaultMessage="Unique Viewers Paid"
        description="Graph legend label for unique viewers paid series"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-average"
        defaultMessage="Average"
        description="Graph legend label for average series"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="incomplete-story-disable-message"
        description="Tooltip describing that a checkbox is disabled because their story is incomplete"
        defaultMessage="Story is incomplete"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="selection-limit-disable-message"
        description="Tooltip describing that a checkbox is disabled because the user has selected too many stories"
        defaultMessage="Cannot select more than {limit} stories"
      />
    ),
    params: ['limit'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="incorrect-status-disable-message"
        description="Tooltip describing that a checkbox is disabled because the story does not have the same status \
        as the other selected stories"
        defaultMessage="All selected stories must have the same status"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-unpublished-changes"
        defaultMessage="Unpublished changes"
        description="Message explaining that a story has unpublished changes"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-incomplete-story"
        defaultMessage="Incomplete Story"
        description="Message explaining that a story is incomplete"
      />
    ),
    params: [],
  });
  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publishing-disabled-stories-not-visible"
        defaultMessage="Publishing on this account has been disabled, stories will not be visible in Snapchat"
        description="Message explaining why available stories appear as disabled"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-publishable"
        defaultMessage="Schedule to become available"
        description="Message describing making a story available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-unavailable"
        defaultMessage="Schedule to become unavailable"
        description="Message describing making a story unavailable"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-status-analytics"
        defaultMessage="View Analytics"
        description="Message describing opening story analytics"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="show-video-thumbnail"
        defaultMessage="Show poster image"
        description="Message describing viewing the image thumbnail instead of the video"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="hide-video-thumbnail"
        defaultMessage="Hide poster image"
        description="Message describing viewing the video instead of the image thumbnail"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="video-thumbnail-tooltip"
        defaultMessage="This image will be shown if the video is loading or unavailable"
        description="Message explaining when the video thumbnail is shown"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="mutli-select-loading"
        defaultMessage="Loading..."
        description="Message explaining that suggestions are loading"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="mutli-select-no-results"
        defaultMessage="No results"
        description="Message explaining that there are no results"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="preview-story-snapcode"
        defaultMessage="Preview Story"
        description="Modal title of a dialog presented for the user to preview their story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-tag-wikipedia"
        defaultMessage="Keywords"
        description="Text explaining that tags are keywords"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-tag-scc"
        defaultMessage="Categories"
        description="Text explaining that tags are categories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-daily-uniques"
        description="Daily Uniques in Daily Audience Analytics"
        defaultMessage="Daily Uniques"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-daily-follower-uniques"
        description="Daily Follower Uniques in Daily Audience Analytics"
        defaultMessage="Daily unique viewers (followers)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-daily-non-followers-uniques"
        description="Daily Non-Followers Uniques in Daily Audience Analytics"
        defaultMessage="Daily unique viewers (non-followers)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-daily-uniques-explanation"
        description="Daily Uniques Explanation in Daily Audience Analytics"
        defaultMessage="Daily Uniques represent how many Unique Snapchatters that engaged with your channel per 24 hours."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-V3-monthly-uniques"
        description="Monthly Uniques in Daily Audience Analytics"
        defaultMessage="Monthly Uniques"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-V3-monthly-uniques-explanation"
        description="Monthly Uniques Explanation in Daily Audience Analytics"
        defaultMessage="Monthly Uniques represent how many Unique Snapchatters that engaged with your channel per 30 days."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-followers"
        description="Followers in Daily Audience Analytics"
        defaultMessage="Followers"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-followers-explanation"
        description="Followers Explanation in Daily Audience Analytics"
        defaultMessage="Followers represent how many unique Snapchatters are followers of your profile on a given day."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-user-loyalty"
        description="User Loyalty in Daily Audience Analytics"
        defaultMessage="User Loyalty"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="user-loyalty-new-users-1-day-per-week"
        description="User Loyalty metric for new users that are seen 1 day per week"
        defaultMessage="New Users (1 Day/Wk)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="user-loyalty-returning-users-2-days-per-week"
        description="User Loyalty metric for returning users that has been seen 2 days per week"
        defaultMessage="Returning Users (2 Days/Wk)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="user-loyalty-frequent-users-3-4-days-per-week"
        description="User Loyalty metric for frequent users that has been seen 3-4 days per week"
        defaultMessage="Frequent Users (3-4 Days/Wk)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="user-loyalty-loyal-users-5-7-days-per-week"
        description="User Loyalty metric for loyal users that has been seen 5-7 days per week"
        defaultMessage="Loyal Users (5-7 Days/Wk)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-total-metric"
        description="Generic message for displaying a total value for a collection of metrics"
        defaultMessage="Total"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-user-loyalty-explanation"
        description="User Loyalty Explanation in Daily Audience Analytics"
        defaultMessage={
          'User loyalty represents how frequently Snapchatters who view your channel return to your channel' +
          ' in a given weekly period. Data is shown for the average of the last 7 days'
        }
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-gender-demographics"
        description="Gender Demographics in Daily Audience Analytics"
        defaultMessage="Gender Demographics"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-gender-demographics-explanation"
        description="Gender Demographics Explanation in Daily Audience Analytics"
        defaultMessage="Gender Demographics represent how many Unique Snapchatters of each gender category engaged with your channel per 24 hours"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-age-demographics"
        description="Age Demographics in Daily Audience Analytics"
        defaultMessage="Age Demographics"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-audience-age-demographics-explanation"
        description="Age Demographics Explanation in Daily Audience Analytics"
        defaultMessage="Age Demographics represent how many Unique Snapchatters of each age category engaged with your channel per 24 hours"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-completion-rate"
        description="Completion Rate in Daily Behavior Analytics"
        defaultMessage="Completion Rate"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-completion-rate-explanation"
        description="Completion Rate Explanation in Daily Behavior Analytics"
        defaultMessage="Completion rate shows the percent of Snapchatters who finished the entire story out of those who started it"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-avg-unique-topsnap-view"
        description="Unique Topsnaps Per User in Daily Behavior Analytics"
        defaultMessage="Unique Topsnaps Per User"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-avg-unique-topsnap-view-explanation"
        description="Unique Topsnaps Per User Explanation in Daily Behavior Analytics"
        defaultMessage="The average number of topsnaps in your story viewed by Snapchatters"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-time-spent"
        description="Time Spent in Daily Behavior Analytics"
        defaultMessage="Time Spent"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-time-spent-explanation"
        description="Time Spent Explanation in Daily Behavior Analytics"
        defaultMessage="Average Daily View Duration shows how long the average Snapchatter stayed on your channel on a single day."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-attachment-conversion"
        description="Attachment Conversion in Daily Behavior Analytics"
        defaultMessage="Attachment Conversion"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-attachment-conversion-explanation"
        description="Attachment Conversion Explanation in Daily Behavior Analytics"
        defaultMessage="Percentage of Unique Snapchatters that swiped up on snaps with attachments."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-topsnap-attachment-views"
        description="Topsnap and Attachment Views in Daily Behavior Analytics"
        defaultMessage="Topsnap & Attachment Views"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-topsnap-attachment-views-explanation"
        description="Topsnap and Attachment Views Explanation in Daily Behavior Analytics"
        defaultMessage="Shows total number of topsnap and attachment views on a given day."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-topsnap-views"
        description="Topsnap Views in Daily Behaviour Analytics"
        defaultMessage="Topsnap Views"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-video-attachment-views"
        description="Video Attachment Views in Daily Behaviour Analytics"
        defaultMessage="Video Attachment Views"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-article-attachment-views"
        description="Article Attachment Views in Daily Behaviour Analytics"
        defaultMessage="Article Attachment Views"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-screenshots"
        description="Screenshots in Daily Behavior Analytics"
        defaultMessage="Screenshots"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-shares"
        description="Shares in Daily Behavior Analytics"
        defaultMessage="Shares"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-screenshots-shares"
        description="Screenshots and Shares in Daily Behavior Analytics"
        defaultMessage="Screenshots & Shares"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="daily-behavior-screenshots-shares-explanation"
        description="Screenshots and Shares Explanation in Daily Behavior Analytics"
        defaultMessage={
          'Screenshots shows the total number of screenshots on a given day.' +
          'Shares shows the total number of shares on a given day.'
        }
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-chart-unique-viewers"
        description="Unique Viewers in Live Story Analytics"
        defaultMessage="Unique Viewers"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-chart-unique-viewers-explanation"
        description="Unique Viewers Explanation in Publisher Story Analytics"
        defaultMessage="Total number of unique Snapchatters that engaged with each snap in this story."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-snap-drop-off-rate"
        description="Snap Drop-off Rate in Publisher Story Analytics"
        defaultMessage="Snap Drop-off Rate"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-snap-drop-off-rate-explanation"
        description="Snap Drop-off Rate Explanation in Publisher Story Analytics"
        // eslint-disable-next-line max-len
        defaultMessage="Snap Drop-off Rate represents the percentage of people that dropped off from one snap to the next. The larger the drop-off rate, the more people dropped off."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-topsnap-time-spent"
        description="Topsnap Time Spent in Publisher Story Analytics"
        defaultMessage="Topsnap Time Spent"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-topsnap-time-spent-explanation"
        description="Topsnap Time Spent Explanation in Publisher Story Analytics"
        defaultMessage="Amount of time on average that Unique Snapchatters spent viewing each topsnap."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-attachment-time-spent"
        description="Attachment Time Spent in Publisher Story Analytics"
        defaultMessage="Attachment Time Spent"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-attachment-conversion"
        description="Attachment Conversion in Publisher Story Analytics"
        defaultMessage="Attachment Conversion"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="live-publisher-story-attachment-conversion-explanation"
        description="Attachment Conversion Explanation in Publisher Story Analytics"
        defaultMessage="Percentage of Unique Snapchatters that swiped up to view attachment content."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-chart-daily-hourly"
        description="Unique Viewers in for a Publisher Story over a daily or hourly period"
        defaultMessage="Unique Viewers"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-chart-unique-viewers"
        description="Unique Viewers in Publisher Story Analytics"
        defaultMessage="Unique Viewers"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-chart-unique-follower-viewers"
        description="Unique Follower Viewers in Publisher Story Analytics"
        defaultMessage="Unique viewers who are followers."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-chart-unique-non-follower-viewers"
        description="Unique Non-followers Viewers in Publisher Story Analytics"
        defaultMessage="Total unique viewers who are not followers."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-chart-unique-viewers-explanation"
        description="Unique Viewers Explanation in Publisher Story Analytics"
        defaultMessage="Total number of unique Snapchatters that engaged with each snap in this story."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-chart-exit-rate"
        description="Snap Drop-off Rate in Publisher Story Analytics"
        defaultMessage="Snap Drop-off Rate"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-chart-exit-rate-explanation"
        description="Snap Drop-off Rate Explanation in Publisher Story Analytics"
        // eslint-disable-next-line max-len
        defaultMessage="Snap Drop-off Rate represents the percentage of people that dropped off from one snap to the next. The larger the drop-off rate, the more people dropped off."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-topsnap-time-spent"
        description="Topsnap Time Spent in Publisher Story Analytics"
        defaultMessage="Topsnap Time Spent"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-topsnap-time-spent-explanation"
        description="Topsnap Time Spent Explanation in Publisher Story Analytics"
        defaultMessage="Amount of time on average that Unique Snapchatters spent viewing each topsnap."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-completion-rate"
        description="Topsnap Completion Rate"
        defaultMessage="Topsnap Completion Rate"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-attachment-conversion"
        description="Attachment Conversion in Publisher Story Analytics"
        defaultMessage="Attachment Conversion"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-attachment-conversion-explanation"
        description="Attachment Conversion Explanation in Publisher Story Analytics"
        defaultMessage="Percentage of Unique Snapchatters that swiped up to view attachment content."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-attachment-time-spent"
        description="Attachment Time Spent in Publisher Story Analytics"
        defaultMessage="Attachment Time Spent"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-attachment-time-spent-explanation"
        description="Attachment Time Spent Explanation in Publisher Story Analytics"
        defaultMessage="Amount of time on average that Unique Snapchatters spent viewing attachment content."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-screenshots-shares"
        description="Screenshots and Shares in Publisher Story Analytics"
        defaultMessage="Screenshots & Shares"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-screenshots-shares-explanation"
        description="Screenshots and Shares Explanation in Publisher Story Analytics"
        defaultMessage={
          'Screenshots shows the total number of screenshots of each snap.' +
          'Shares shows the total number of shares of each snap.'
        }
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-story-demographics"
        description="Story Demographics in Publisher Story Analytics"
        defaultMessage="Story Demographics"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-story-gender"
        description="Story Gender in Publisher Story Analytics"
        defaultMessage="Story Gender"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="welcome"
        description="Title congratulating the user for logging in for the first time"
        defaultMessage="Welcome!"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="welcome-text"
        description="Message notifying the new user that they can watch an intro video"
        defaultMessage="Watch our video or get started with your first story."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="welcome-button"
        description="Button text telling the user that they can create a new story"
        defaultMessage="Create new story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="post-to-snapchat"
        description="Button text telling the user that they can create new content"
        defaultMessage="Upload"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="unsupported-browser"
        description="Button text telling the user that they can create new content"
        defaultMessage="Browser unsupported"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="new-button" description="Button for creating new story" defaultMessage="New" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="welcome-story-title-user-name"
        description="Title of the first story for an onboarded publisher"
        defaultMessage={"{name}'s first story"}
      />
    ),
    params: ['name'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-snapcode-sharing"
        description="The title of the Sharing Snapcode modal"
        defaultMessage="Sharing"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-snapcode-sharing-download"
        description="The title of the Download Snapcode button"
        defaultMessage="Download Snapcode"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-snapcode-sharing-copy-to-clipboard"
        description="The title of the button that copies the Snapcode URL"
        defaultMessage="Copy URL"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-snapcode-sharing-explanation"
        description="The description of what the Story Snapcode does"
        defaultMessage="Users that scan this Snapcode in Snapchat will be sent to your story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-button"
        description="Button asking the user to add their publisher details"
        defaultMessage="Complete your profile"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-header"
        description="Header for the page asking for more publisher details"
        defaultMessage="Tell us about yourself"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-sub-header-with-end-snap"
        description="Sub header for the page asking for more publisher details"
        defaultMessage="We need some more information before you can post."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-profile-header"
        description="Header for the profile section of the page asking for more publisher details"
        defaultMessage="Profile"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-profile-image"
        description="Sub header for the publisher's profile image field"
        defaultMessage="Publisher Profile Image"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-profile-preview-message-crop"
        description="Message about publisher preview image cropping"
        defaultMessage="Cropped to fit profile header"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-profile-preview-message-version"
        description="Message about publisher preview old version on older clients"
        defaultMessage="Users who do not update their Snapchat app may see the old profile page"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-profile-display"
        description="Sub header for the publisher's profile display field"
        defaultMessage="Show Logo in Profile for older Snapchat versions"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-profile-bitmoji-template-id"
        description="Text input for publisher profile Bitmoji template ID for hero image"
        defaultMessage="Bitmoji Template ID"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-shows-header"
        description="Header for the show's profile of the page asking for more shows details"
        defaultMessage="Show"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-shows-name"
        description="Sub header for the show's name field"
        defaultMessage="Show Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-shows-description"
        description="Sub header for the show's description field"
        defaultMessage="Show Description"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-shows-logo-square"
        description="Name for the square logo for the shows profile image"
        defaultMessage="Show Profile Image"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="publisher-details-shows-tile" description="Tile for the show" defaultMessage="Show Tile" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-styling-download"
        description="Link for the styling section of the page to download style templates"
        defaultMessage="Download Templates"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-continue"
        description="Button for the page asking to for more publisher details"
        defaultMessage="Continue"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-tou-3"
        description="Header for the profile section of the page asking for more publisher details"
        defaultMessage="By pressing Save, you agree to our Terms of Use"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-profile-name"
        description="Name for the publishing name field on the page asking for more publisher details"
        defaultMessage="Profile Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publication-name"
        description="Name for the publication name field on the settings page"
        defaultMessage="Publication Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publishing-name-description"
        description="Description for the publishing name field on the page asking for more publisher details"
        defaultMessage="This name is your business account name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publishing-descr"
        description="Name for the publishing description field on the page asking for more publisher details"
        defaultMessage="Description"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publishing-descr-description"
        description="Description for the publishing description field on the page asking for more publisher details"
        defaultMessage="Users will see this description in your mini profile (example below)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publishing-tags"
        description="Name for the publishing tags field on the page asking for more publisher details"
        defaultMessage="Content Tags"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publishing-tags-description"
        description="Description for the publishing tags field on the page asking for more publisher details"
        defaultMessage="Choose 3 tags to help with recommending your stories to users"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publishing-color"
        description="Name for the color field on the page asking for more publisher details"
        defaultMessage="Primary Color"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-publishing-color-description"
        description="Description for the color field on the page asking for more publisher details"
        defaultMessage="This color is used to theme elements of the Snapchat UI within your stories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-horizontal-icon"
        description="Name for the wide logo field on the page asking for more publisher details"
        defaultMessage="Wide Format Logo"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-square-icon"
        description="Name for the square logo field on the page asking for more publisher details"
        defaultMessage="Square Format Logo"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-settings-header"
        description="Header for the settings page"
        defaultMessage="Settings"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-preview"
        description="Name for the preview field on the page showing icon previews"
        defaultMessage="Preview"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="save-changes" description="Button to save changes" defaultMessage="Save Changes" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="add-feed" description="Button to add an RSS feed" defaultMessage="Add Feed" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="saving" description="Button when saving" defaultMessage="Saving..." />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="add" description="Text for the add button" defaultMessage="Add" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-playback"
        description="Name for the playback field on the page asking for publisher details"
        defaultMessage="Default Snap Playback"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-playback-description"
        description="Description for the playback field on the page asking for publisher details"
        defaultMessage="Snaps created for your story will either loop or play once before advancing the story. This can be changed in the story editor for individual snaps."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-num-snaps"
        description="Name for the number of snaps field on the page asking for publisher details"
        defaultMessage="Default Number of Snaps"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-num-snaps-description"
        description="Description for the number of snaps field on the page asking for publisher details"
        defaultMessage="Define how many empty snaps to put in new stories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="none"
        description="None string, used to indicate the unavailablity of some value"
        defaultMessage="None"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="simple-status-add-tile"
        description="Header explaining that a tile can be added to a snap"
        defaultMessage="Add a Tile to this Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="banner-message"
        description="Title for the notification banner input"
        defaultMessage="Banner message"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="simple-status-add-attachment"
        description="Header explaining that an attachment can be added to a snap"
        defaultMessage="Add an Attachment to this Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-updating"
        defaultMessage="Updating"
        description="Snap status message to indicate that the snap is processing an update"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="simple-status-tooltip-attachment"
        description="Tooltip explaining that an attachment can be accessed by swiping up"
        defaultMessage="Users can view the Attachment by swiping up on the Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-error"
        defaultMessage="Error"
        description="Snap status message to indicate that there was an error when building"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="simple-status-tooltip-tile"
        description="Tooltip explaining that a tile can be seen on the discover page"
        defaultMessage="This tile is shown to users to represent your Story within Discover"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="enable-banner-message"
        description="Text next to checkbox to inform that the checkbox enables the notification banner"
        defaultMessage="Enable banner message"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="delete-component-alert"
        description="Delete Component Alert"
        defaultMessage={'Are you sure you wish to delete this {snapType} attachment?'}
      />
    ),
    params: ['snapType'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="delete-component-delete-button" description="Delete Button" defaultMessage="Delete" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="today" description="Text indicating today" defaultMessage="Today" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="poll-total-votes-title"
        description="The total number of responses in a poll"
        defaultMessage="Total response: "
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="results-title" description="A title for results" defaultMessage="Results:" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-index-in-edition"
        defaultMessage="Snap #{index}"
        description="Label for snap index in edition"
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="demographics-male"
        defaultMessage="Male"
        description="Description of the male demographic"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="demographics-female"
        defaultMessage="Female"
        description="Description of the female demographic"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="demographics-unknown"
        defaultMessage="Unknown"
        description="Description of the unknown demographic"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="demographics-age-13-to-17"
        defaultMessage="13 to 17"
        description="The age group describing people between 13 years and 17 years"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="demographics-age-18-to-24"
        defaultMessage="18 to 24"
        description="The age group describing people between 18 years and 24 years"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="demographics-age-25-to-34"
        defaultMessage="25 to 34"
        description="The age group describing people between 25 years and 34 years"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="demographics-age-35-plus"
        defaultMessage="35+"
        description="The age group describing people aged 35 or older"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-metric-screenshots"
        defaultMessage="Screenshots"
        description="A metric describing the number of screenshots taken"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-metric-shares"
        defaultMessage="Shares"
        description="A metric describing the number of times a snap or edition has been shared"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-metric-topsnap-runtime"
        defaultMessage="Topsnap Runtime"
        description="A metric describing the runtime of a topsnap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-metric-topsnap-completion-rate"
        defaultMessage="Topsnap Completion Rate"
        description="A metric describing at which rate people completed viewing a snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-toggle-group"
        defaultMessage="Group"
        description="A toggle setting saying that a bar chart should be grouped rather than stacked"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-toggle-stack"
        defaultMessage="Stack"
        description="A toggle setting saying that a bar chart should be stacked rather than grouped"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-toggle-area-expanded"
        defaultMessage="Expanded"
        description="A toggle setting saying that an area chart should be expanded rather than stacked"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-toggle-area-stacked"
        defaultMessage="Stacked"
        description="A toggle setting saying that an area chart should be stacked rather than expanded"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-revenue-missing-business-account"
        defaultMessage="No business account found"
        description="An error displayed when there is no business account"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="spectacles-video"
        defaultMessage="Spectacles Video"
        description="Describes a video recorded on snapchat spectacles"
      />
    ),
    params: ['questionTitle'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-quiz-question-graph"
        defaultMessage="Quiz: {questionTitle}"
        description="Title of the quiz question"
      />
    ),
    params: ['questionTitle'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-quiz-question"
        defaultMessage="Question #{questionIndex}"
        description="Title of a quiz question"
      />
    ),
    params: ['questionIndex'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-quiz-legend"
        defaultMessage="Question response"
        description="The description of the legend found in the quiz graph"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-story-quiz-outcome"
        defaultMessage="Outcome"
        description="The description of an outcome when talking about a quiz"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="first-segment-created-header"
        defaultMessage="New Segment"
        description="Header text of a modal when you first create a segment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="spectacles-video-tooltip"
        defaultMessage="Users can tilt their phone to see this video from any angle"
        description="A tooltip that describes how a user can interact with a spectacels video"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="first-segment-created-body"
        defaultMessage="You have created a segment, this makes this group of snaps eligible for optimisation"
        description="Body text of a modal when you first create a segment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="first-segment-categories-created-body"
        defaultMessage="Segments are thematic groupings of Snaps, which can be distributed separately from the other Snaps in your Publisher Story and promoted to Snapchatters based on their interests."
        description="Body text of a modal when you first create a segment for categorisation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="first-segment-categories-created-body-2"
        defaultMessage="By creating a Segment, you acknowledge that we may distribute the Segment independently from the rest of your Publisher Story or reorder the story to show a given segment first."
        description="Additional body text of a modal when you first create a segment for categorisation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="first-segment-created-what-is-segment"
        defaultMessage="Segments are thematic groupings of Snaps, which can be distributed independently from other Snaps in your story."
        description="Body text of a modal when you first create a segment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="first-segment-created-segment-distribution"
        defaultMessage="By creating a Segment, you acknowledge that we may distribute the Segment independently from the rest of your story."
        description="Body text of a modal when you first create a segment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-video-attachment-type"
        description="The video attachment type"
        defaultMessage="Video"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-article-attachment-type"
        description="The article attachment type"
        defaultMessage="Article"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-poll-attachment-type"
        description="The opinion poll attachment type"
        defaultMessage="Poll"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="snap-vote-attachment-type" description="The vote attachment type" defaultMessage="Vote" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-personality-quiz-attachment-type"
        description="The personality quiz attachment type"
        defaultMessage="Quiz"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-trivia-quiz-attachment-type"
        description="The trivia quiz attachment type"
        defaultMessage="Quiz"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-remote-web-attachment-type"
        description="The web view attachment type"
        defaultMessage="Web View"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-app-install-attachment-type"
        description="The app install attachment type"
        defaultMessage="App Install"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-notification-attachment-type"
        description="The notification attachment type"
        defaultMessage="Notifications"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-end-snap-header"
        description="The end snap in a publisher story"
        defaultMessage="End Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="long-form-article"
        description="Long form Article"
        defaultMessage="This Snap contains a long form article"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="long-form-video"
        description="Long form Video"
        defaultMessage="This Snap contains a long form video"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="long-form-poll" description="Long form Poll" defaultMessage="This Snap contains a poll" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="long-form-vote" description="Long form Vote" defaultMessage="This Snap contains a vote" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="long-form-personality-quiz"
        description="Long form Personality Quiz"
        defaultMessage="This Snap contains a personality quiz"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="long-form-trivia-quiz"
        description="Long form Trivia Quiz"
        defaultMessage="This Snap contains a trivia quiz"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="long-form-web-view"
        description="Long form Web View"
        defaultMessage="This Snap contains a web view"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="long-form-app-install"
        description="Long form App Install"
        defaultMessage="This Snap contains an app install"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="long-form-notification"
        description="Long form Notifications attachment"
        defaultMessage="This Snap contains a notification"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="alert-zero-countries"
        description="Alert message for not selecting any country"
        defaultMessage="Please select at least one country."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="users"
        description="Message to indicate a collection of people that interact with the app"
        defaultMessage="Users"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-snap-account"
        description="Button that takes the user to creating a new snapchat account"
        defaultMessage="New Snapchat account"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="contact-support"
        description="Button that opens zendesk and allows the user to raise a support ticket"
        defaultMessage="Contact Support"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="title-help" description="Help label" defaultMessage="Story Studio Support" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="contact-title" description="Title for the contact support page" defaultMessage="Help" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="use-another-account"
        description="Button that takes user to Snap Login view"
        defaultMessage="Use another account"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="notes-placeholder"
        description="Placeholder text for the notes field"
        defaultMessage="Notes for this Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-snapcode-title"
        description="Title for the section showing the publisher Snapcode"
        defaultMessage="SNAPCODE"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-snapcode-part-1"
        description="Explaining that the Snapcode send users to their latest story"
        defaultMessage="Anyone who scans this Snapcode will be able to see your latest story."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-snapcode-part-2"
        description="Explaining that story Snapcodes can be created in the story settings menu"
        defaultMessage={"Create Snapcodes for specific stories under 'Sharing' in your story's settings menu."}
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-no-snapcode-part-1"
        description="Explaining that no Snapcode exists because the profile is incomplete"
        defaultMessage="No Snapcode has been generated yet as your profile is incomplete."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-no-snapcode-part-2"
        description="Explaining that the Styling section of the settings needs to be completed"
        defaultMessage={"Complete the 'Styling' section of your profile to get a shareable Snapcode."}
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-styling-simple"
        description="Button allowing the user to switch to simple editing mode in the styling box"
        defaultMessage="Simple Mode"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-styling-advanced"
        description="Button allowing the user to switch to advanced editing mode in the styling box"
        defaultMessage="Advanced Mode"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-backing-color"
        description="The background color for the tile logo"
        defaultMessage="Backing Color"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-details-optional-horizontal-icon"
        description="An additional tile logo option"
        defaultMessage="Optional Tile Logo"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-edition-segment-info"
        defaultMessage="Segment Info"
        description="Show segment info subtitle text"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-edition-story-info"
        defaultMessage="Story Info"
        description="Show story info subtitle text"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-segments-index"
        description="Text that shows the segment position in the edition"
        defaultMessage={'Segment {index}'}
      />
    ),
    params: ['index'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-audience"
        description="Button text to select Audience view in Analytics"
        defaultMessage="Audience"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-stories"
        description="Button text to select Stories view in Analytics"
        defaultMessage="Stories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-behavior"
        description="Button text to select Behavior view in Analytics"
        defaultMessage="Behavior"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights"
        description="Button text to select Insights view in Analytics"
        defaultMessage="Insights"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-revenue"
        description="Button text to select Revenue view in Analytics"
        defaultMessage="Revenue"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-profile"
        description="Button text to select Profile view in Analytics"
        defaultMessage="Profile"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-audience-data"
        description="Button text to download Audience data in Analytics"
        defaultMessage="Audience data"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-stories-data"
        description="Button text to download Stories data in Analytics"
        defaultMessage="Stories data"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-stories-data-all-countries"
        description="Button text to download Stories data in Analytics"
        defaultMessage="Stories data (all countries)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-stories-data-global-only"
        description="Button text to download Stories data summary in Analytics"
        defaultMessage="Stories data (global only)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-stories-data-global-only-story-only"
        description="Button text to download Stories data summary (story only) in Analytics"
        defaultMessage="Stories data (global only, story-level only)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-single-story-data"
        description="Button text to download single story data in Analytics"
        defaultMessage="Story data"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-single-story-data-all-countries"
        description="Button text to download single story data in Analytics"
        defaultMessage="Story data (all countries)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-single-story-data-global-only"
        description="Button text to download single story data summary in Analytics"
        defaultMessage="Story data (global only)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-behavior-data"
        description="Button text to download Behavior data in Analytics"
        defaultMessage="Behavior data"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-email-subscription"
        description="Toggle text to subscribe to emails in Analytics"
        defaultMessage="Subscribe to emails"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-slc-title"
        description="Header of the slc component on the insights page"
        defaultMessage="Top Lifestyle Categories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-tiles-title"
        description="Header of the top performing tiles component on the insights page"
        defaultMessage="Top Performing Tiles"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="load-more-button"
        description="text on the expand button on the top performing stories component on the insights page"
        defaultMessage="Load More"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-stories-title"
        description="Header of the top performing stories component on the insights page"
        defaultMessage="Top Performing Stories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-slc-tooltip-info"
        description="Info that comes from the Tooltip at the Header of the SLC component on the insights page"
        defaultMessage="Displays the top performing Snapchat Lifestyle categories based on unique viewer for your content vs the Snapchat average"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-slc-multiplier-text"
        description="Information about the value on the Analytics Insights Lifestyle Category carousel"
        defaultMessage={'{score} x more engagement'}
      />
    ),
    params: ['score'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-tiles-reference-text"
        description="comparison explanation text on the top peforming tiles insights page"
        defaultMessage="vs. your average"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-snaps-and-attachments-position-reference-text"
        description="explaining that the statistic is relative to the selected filtered positions"
        defaultMessage="(for selection)"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-snaps-and-attachments-position-text"
        description="making explicit that the value is the position of the snap"
        defaultMessage="snap {position}"
      />
    ),
    params: ['position'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-tiles-table-tile-column-header-text"
        description="Tile column header text on the top performing tiles table on insights page"
        defaultMessage="Tile"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-tiles-table-no-data-text"
        description="What to display when top tiles has no data"
        defaultMessage="Add multiple tiles to your snaps to see insights"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-stories-table-no-data-text"
        description="What to display when top tiles has no data"
        defaultMessage="Not enough stories published recently for insights data"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-tiles-table-indexed-ctr-column-header-text-new"
        description="Indexed CTR column header text on the top performing tiles table on insights page"
        defaultMessage="Indexed CTR"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-tiles-table-time-viewed-column-header-text"
        description="AvgTotalTimeViewed column header text on the top performing tiles table on insights page"
        defaultMessage="Time Viewed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-tiles-table-snaps-viewed-column-header-text"
        description="UniqueTopsnapsPerUser column header text on the top performing tiles table on insights page"
        defaultMessage="Snaps Viewed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-stories-table-tile-column-header-text"
        description="Stories column header text on the top performing stories table on insights page"
        defaultMessage="Stories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-stories-table-status-column-header-text"
        description="status column header text on the top performing storeis table on insights page"
        defaultMessage="Status"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-stories-table-unique-dau-column-header-text"
        description="unique dau column header text on the top performing stories table on insights page"
        defaultMessage="Unique Viewers"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-stories-table-time-viewed-column-header-text"
        description="AvgTotalTimeViewed column header text on the top performing stories table on insights page"
        defaultMessage="Time Viewed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-performing-stories-table-follower-count-column-header-text"
        description="follower count column header text on the top performing storeis table on insights page"
        defaultMessage="Followers added"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-top-tile-view-default-title"
        description="Title for page of analytics about editions"
        defaultMessage="Publisher Story #{editionId}"
      />
    ),
    params: ['editionId'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-media-preview-story-published-time-optional"
        defaultMessage=" | Published {date} @ {time} ({timeAgo})"
        description="Add info of when the edition was last published"
      />
    ),
    params: ['date', 'time', 'timeAgo'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-top-performing-stories-lifestyle-categories-multiplier-text"
        description="Text showing the multiplier of engagement"
        defaultMessage={'{score} x more engagement'}
      />
    ),
    params: ['score'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-top-lifestyle-categories"
        description="Header of the lifestyle categories component on the analytics insights report"
        defaultMessage="Top Lifestyle Categories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-top-age-group"
        description="Header of the top age group component on the analytics insights report"
        defaultMessage="Most Popular Age Group"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-gender-group-male"
        description="Name of the gender group for male users in the analytics insights report"
        defaultMessage="Male"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-gender-group-female"
        description="Name of the gender group for female users in the analytics insights report"
        defaultMessage="Female"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-age-group-13-to-17"
        description="Name of the age range users group with age between 13 and 17in the analytics insights report"
        defaultMessage="13 to 17"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-age-group-18-to-24"
        description="Name of the age range users group with age between 13 and 17in the analytics insights report"
        defaultMessage="18 to 24"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-age-group-25-to-34"
        description="Name of the age range users group with age between 13 and 17in the analytics insights report"
        defaultMessage="25 to 34"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-age-group-35-plus"
        description="Name of the age range users group with age between 13 and 17in the analytics insights report"
        defaultMessage="35+"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="analytics-insights-report-kpi-days"
        description="Text appearing giving context on data over time range"
        defaultMessage={'{nbOfDays} day {context}'}
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="loading-snapcode"
        description="Indicates that a Snapcode is currently loading"
        defaultMessage="Loading Snapcode"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="generic-error"
        description="Generic error for something that has gone wrong and a prompt for the user to try again"
        defaultMessage="Error, please try again"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snapcode-up-to-date"
        description="Indicates that a Snapcode displayed to the user is recent"
        defaultMessage="Snapcode up to date"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snapcode-scan-warning"
        description="Warning to the user that a snapcode they are seeing can be scanned by anyone"
        defaultMessage="Anyone can scan this Snapcode in Snapchat to preview this story before it is available"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="preview-story-in"
        description="First part of a message asking the user what quality they would like to preview in, message followed by 'high quality' or 'low quality' covered in a separate translation"
        defaultMessage="Preview this story in"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="high-quality"
        description="Indicating to the user that something is high quality resolution"
        defaultMessage="High Quality"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="low-quality"
        description="Indicating to the user that something is low quality resolution"
        defaultMessage="Low Quality"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-type"
        description="Title of dropdown for selecting story type when creating new story"
        defaultMessage="Story Type"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story"
        description="Story Type describing that a story is a publisher story"
        defaultMessage="Story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="home-page-cell-text-episode-metadata"
        description="Show metadata shown on the home page, describing which show, season and episode this story is"
        defaultMessage="| {showName} - Season {seasonNumber} Episode {episodeNumber}"
      />
    ),
    params: ['showName', 'seasonNumber', 'episodeNumber'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="home-page-cell-text-trailer"
        description="Show metadata shown on the home page, describing which show and season this trailer story belongs to"
        defaultMessage="| {showName} - Season {seasonNumber} Trailer"
      />
    ),
    params: ['showName', 'seasonNumber'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="stories"
        description="Title of homepage tab that shows publisher stories"
        defaultMessage="Stories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="curation-tool-location-custom-label"
        description="Curation tool custom location filter label"
        defaultMessage="Custom"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-creation-header"
        description="Header for the topsnap creation page suggesting the user adds some content"
        defaultMessage="Fill this Snap with some content!"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-creation-upload-media"
        description="Button text for the topsnap creation page suggesting the user uploads media"
        defaultMessage="Upload Media"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-creation-creative-tools"
        description="Button text for the topsnap creation page suggesting the user uses the creative tools"
        defaultMessage="Creative Tools"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-creation-customize-a-template"
        description="Button text for the topsnap creation page suggesting the user uses the creative tools"
        defaultMessage="Customize a Template"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-creation-design-from-scratch"
        description="Button text for the topsnap creation page suggesting the user uses the advanced creative tools"
        defaultMessage="Design from Scratch"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-creation-media-library"
        description="Button text for the topsnap creation page suggesting the user uses media library"
        defaultMessage="Media Library"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="topsnap-creation-web-import"
        description="Button text for the topsnap creation page suggesting the user imports from a url"
        defaultMessage="Web Import"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-web-import-header"
        description="Header text for the snap web import page"
        defaultMessage="Web Import"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-web-import-info"
        description="Info text explaining that the import will create a snap from the url inputted"
        defaultMessage="Enter a web URL to import content from your site"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-web-import-url-error"
        description="Error text explaining that the url entered is not valid"
        defaultMessage="Enter a valid web URL"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-web-import-text-placeholder"
        description="Placeholder text for the input field where the user can add their url"
        defaultMessage="Web URL"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-web-import-button"
        description="Button text for the user to import the content"
        defaultMessage="Import"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="invalid-url-error"
        defaultMessage="Please enter a valid url"
        description="Error message when the user has not entered a valid url"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="authorized-user-ids"
        defaultMessage="Authorized User IDs"
        description="Header text for the textbox to enter authorized user IDs"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="authorized-user-ids-placeholder"
        defaultMessage="Enter Authorized User IDs (separated by commas)"
        description="Placeholder text that tells the user to enter authorized user IDs"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="restricted-country-codes"
        defaultMessage="Restricted Country Codes"
        description="Header text for the textbox to enter restricted country codes"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="restricted-country-codes-placeholder"
        defaultMessage="Enter Restricted Country Codes (separated by commas)"
        description="Placeholder text that tells the user to enter restricted country codes"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="create-story-field"
        defaultMessage="Create story"
        description="[Field name] Form field - Create story"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="update-episode-modal-title"
        description="Update episode modal title"
        defaultMessage="Story settings"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="episode-number-field"
        defaultMessage="Episode number"
        description="[Field name] Form field - Episode"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="extra-toggle-field"
        defaultMessage="Extra content (e.g. trailer)"
        description="A field name for extras which are special show stories like trailers or behind the scenes content"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="season-field" defaultMessage="Season" description="[Field name] Form field - Season" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="season-field-with-description"
        defaultMessage="Season {description}"
        description="[Field name] Form field - Season"
      />
    ),
    params: ['description'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="season-placeholder-with-season-number"
        defaultMessage="Season {number}"
        description="[Field name] Placeholder field - Season number"
      />
    ),
    params: ['number'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="season-placeholder-with-season-number-latest"
        defaultMessage="Season {number} (latest)"
        description="[Field name] Placeholder field - Season number"
      />
    ),
    params: ['number'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="season-field-number"
        defaultMessage="Season Number"
        description="[Field name] Form field - Season Number"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="season-field-display-name"
        defaultMessage="Season Name"
        description="[Field name] Form field - Season Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="show-field" defaultMessage="Show" description="[Field name] Form field - Show" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="story-part-of-show-field"
        defaultMessage="This story is part of a show"
        description="[Field name] Checkbox - This story is part of a show"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="number-empty-snaps-field"
        defaultMessage="Number of empty Snaps"
        description="[Field name] Form field - number of empty snaps"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="cropping"
        description="header text for the cropping section of tile editor"
        defaultMessage="Cropping"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="ended" description="Story status indicating the story has ended" defaultMessage="Ended" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-season-dropdown"
        defaultMessage="+ New Season"
        description="[Dropdown] Dropdown value - The plus sign (+) indicates creating a new Season"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="season-dropdown-current"
        description="Text that highlights that this season number is the current one"
        defaultMessage={'{seasonNumber} (current)'}
      />
    ),
    params: ['seasonNumber'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="episode-dropdown-next"
        description="Text that highlights that this episode number is the next episode"
        defaultMessage={'{episodeNumber} (next)'}
      />
    ),
    params: ['episodeNumber'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="episode-with-number"
        description="Text describing the episode with number"
        defaultMessage={'Episode {episodeNumber}'}
      />
    ),
    params: ['episodeNumber'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="segment-number"
        description="Text mentioning which segment number is being shown"
        defaultMessage={'Segment #{segmentNumber}'}
      />
    ),
    params: ['segmentNumber'],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="signout-nav-click" defaultMessage="Sign Out" description="Sign out button" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-dropdown-switch-publisher"
        defaultMessage="Switch Publisher"
        description="Switch Publisher dropdown button"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-dropdown-current-publisher"
        defaultMessage="Current Publisher"
        description="Show current publisher title"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-dropdown-subtitle-currently-selected"
        defaultMessage="Currently selected"
        description="Subtitle for currently selected publisher"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="new-user-modal-title" defaultMessage="New User" description="New User Modal title" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-snap-employee-modal-title"
        defaultMessage="New Snap Employee"
        description="New Snap Employee Modal title"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="stand-alone-segment-disabled"
        description="Tooltip text when the stand alone segment is disabled"
        defaultMessage="Segment will not appear separately from this story. Please edit the segment and republish to enable this"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="segment-eligibility-too-short"
        description="Tooltip text when the segment is too short"
        defaultMessage="Segment cannot appear separately from this story in the Discover feed as we require at least 3 snaps in the segment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="segment-eligibility-ad-after-last-snap"
        description="Tooltip text when there is an ad after the last snap"
        defaultMessage="Segment cannot appear separately in the Discover feed as there is an ad on the last snap of the segment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="segment-eligibility-eligible"
        description="Tooltip text when the segment is eligible"
        defaultMessage="This segment may appear separately from this story in the Discover feed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-removed"
        description="Message to the user to signal that a snap has been deleted or removed"
        defaultMessage="Snap removed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="archive-publisher"
        description="Button text to archive a publisher"
        defaultMessage="Archive Publisher"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-deleted-by-owner"
        description="Message to the user to signal who has deleted a snap"
        defaultMessage="Snap deleted by owner"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="archive-publisher-description"
        description="Text explaining what happens when archiving a publisher"
        defaultMessage="Archiving this Publisher will hide it in Story Studio, all users that have access will not be able to log into it"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-has-been-removed"
        description="Message to the user to signal that a snap in the story has been removed"
        defaultMessage="Snap has been removed"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="dismiss"
        description="Text for a button that closes a section of the UI"
        defaultMessage="Dismiss"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="archive-publisher-confirmation"
        description="Text confirming that the superadmin wants to archive a publisher"
        defaultMessage="Are you sure you want to Archive this account?"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="source"
        description="label stating that the following text will describe where something has come from, or where it was 'sourced' from"
        defaultMessage="Source"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="form-control-placeholder"
        description="Placeholder value for text input fields"
        defaultMessage="Enter text"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="transform-to-bitmoji-webpage"
        description="Transform top snap to bitmoji webpage view"
        defaultMessage="Transform To Bitmoji Webview"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="transform-to-bitmoji-remote-video"
        description="Transform top snap to bitmoji remote video"
        defaultMessage="Transform To Bitmoji Remote Video"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-publisher-create"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Button to create a new publisher"
        defaultMessage="Create Publisher"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-publisher-org"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Input label for selecting an organisation"
        defaultMessage="Organisation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-publisher-admin-name"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Input label for adding an admin name"
        defaultMessage="Admin setup name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-publisher-admin-email"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Input label for adding an admin email"
        defaultMessage="Admin setup email"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-publisher-location"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Input label for adding a publisher location"
        defaultMessage="Publisher location"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-publisher-header"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Header text for the new publisher page"
        defaultMessage="New Publisher"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-publisher-details-header"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Header text for the details section of the new publisher page"
        defaultMessage="Publisher details"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="warning-too-many-episodes-in-a-season"
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        desription="Warning that there are more then the max number of episodes in a season"
        defaultMessage="The maximum number of Episodes in a season is {maxNumberOfEpisodes}, please create a new season for this content"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-details"
        description="Header text explaining that the user is filling in their organisation details"
        defaultMessage="Your organisation details"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-button"
        description="Button text to create a new organisation"
        defaultMessage="New organisation"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-details-header"
        description="Header text for the organisation details section of the form"
        defaultMessage="Organisation Details"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-details-name"
        description="The field for the user to enter the organisation name"
        defaultMessage="Organisation Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-details-address"
        description="The field for the user to enter the organisation address"
        defaultMessage="Organisation Address"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-finance-header"
        description="Header text for the organisation finance details section of the form"
        defaultMessage="Finance Details"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-finance-name"
        description="The field for the user to enter the finance contact name"
        defaultMessage="Contact Name"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-finance-email"
        description="The field for the user to enter the finance contact email"
        defaultMessage="Contact Email"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-warning-new-org"
        description="The warning message when creating a new org"
        defaultMessage="Warning: Creating new Organizations may affect revenue reporting. Please be sure to check if the required Organization exists before creating a new Organization."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="new-org-warning-existing-org"
        description="The warning message when associating a publisher with an existing org"
        defaultMessage="Caution: Associating Accounts with the wrong Organization may expose sensitive revenue data to the wrong users.  Please confirm that you are associating this Account with the correct Organization."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="media-library-title" description="Title of the Media Library" defaultMessage="Media" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="datetime"
        description="Timestamp consisting of date and time"
        defaultMessage="{date} {time}"
      />
    ),
    params: ['date', 'time'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="video-library-panel-choose-from-media-library"
        description="Text on button for user to go to Media Library and select a snap to add to Story"
        defaultMessage="Choose from Media Library"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="download" description="Menu item or button for downloading" defaultMessage="Download" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-library-uploaded-snap"
        description="Label for uploaded top snap videos"
        defaultMessage="Uploaded Snap"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-library-uploaded-image"
        description="Label for uploaded top snap images"
        defaultMessage="Uploaded Image"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="media-library-uploaded-video-attachment"
        description="Label for uploaded video attachments"
        defaultMessage="Uploaded Video Attachment"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="publisher-search-dropdown-filter-input-placeholder"
        description="Placeholder text for the filter input on the publisher search dropdown"
        defaultMessage="Filter"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="select-all-text"
        description="Text for select all link or button"
        defaultMessage="Select All"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="clear-text" description="text for clear link or button" defaultMessage="Clear" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="media-type-image" description="Label for image media type" defaultMessage="Image" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="media-type-video" description="Label for video media type" defaultMessage="Video" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-source-camera-roll"
        description="text for camera roll snap snap source"
        defaultMessage="Cam roll"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-source-memories"
        description="text for memories snap snap source"
        defaultMessage="Memories"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="subtitles-title" description="Text for subtitles title" defaultMessage="Subtitles" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="copy"
        defaultMessage="Copy"
        description="button text describing that clicking the button will copy some text to the user's clipboard"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-tray-snaps-selected"
        description="Number of selected snaps"
        defaultMessage="{snapCount, plural, one {1 Snap} other {# Snaps}} selected"
      />
    ),
    params: ['snapCount'],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-webview-limit-reached"
        defaultMessage="Webview attachment limit is reached, please remove a webview attachment from any of the snaps"
        description="tooltip message when webview limit for a story is reached"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-tooltip-missing-end-snap-overlay"
        defaultMessage="Please update the headline."
        description="tooltip message when end snap is missing an overlay"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-webview-limit-reached-primary"
        defaultMessage="Web View attachment limit reached"
        description="Primary message to shown in bold when webview limit is reached"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="snap-status-webview-limit-reached-secondary"
        defaultMessage="Remove a web view attachment from any other Snap"
        description="Secondary message to shown when webview limit is reached"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="subscription-analytics-excludes-spotlight-subscription-notice"
        description="Notice to user that the number of subscribers displayed does not include Spotlight subscribers"
        defaultMessage="Please note: This does not include Spotlight Subscriptions."
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        defaultMessage="Content"
        id="content-sidebar-indicator"
        description="Sidebar grouping for content"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        defaultMessage="Home"
        id="sidebar-content-homepage"
        description="Sidebar menu item for content"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="analytics" description="Analytics Title" defaultMessage="Analytics" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        defaultMessage="Support"
        id="snap-support-sidebar-indicator"
        description="Sidebar grouping for snap-support links"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="settings-help" description="settings" defaultMessage="Settings" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="contact-title-sidebar" description="Contact Support" defaultMessage="Contact" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="help-center-sidebar" description="Help center label" defaultMessage="Help Center" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        defaultMessage="Snap Internal"
        id="snap-internal-sidebar-indicator"
        description="Sidebar grouping for snap-internal links"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="title-notification-message"
        description="Title for the notification message"
        defaultMessage="Notification"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        id="title-admin-user-management"
        description="Title Snap Accounts"
        defaultMessage="Snap Accounts"
      />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="feature-flags" description="Feature flags section" defaultMessage="Feature Flags" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage id="org-onboarding" description="Organisation onboarding section" defaultMessage="Onboarding" />
    ),
    params: [],
  });

  registerIntlMessage({
    intlMessage: <FormattedMessage id="revenue" description="Revenue Title" defaultMessage="Revenue" />,
    params: [],
  });

  registerIntlMessage({
    intlMessage: (
      <FormattedMessage
        defaultMessage="Administration"
        id="administration-sidebar-indicator"
        description="Sidebar grouping for administration links"
      />
    ),
    params: [],
  });
}
