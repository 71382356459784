import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import { createSequenceHandlingReducer, createSequenceCountingReducer } from 'state/common/reducerFactories';
import * as featuresActions from 'state/features/admin/actions/featuresActions';

import { EMPTY_OBJECT, EMPTY_ARRAY } from 'config/constants';

export const list = createSequenceHandlingReducer(EMPTY_ARRAY, featuresActions.LOAD_FEATURES, {
  start(state: any) {
    return state;
  },
  done(state: any, action: any) {
    return action.payload;
  },
});

export const byId = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  [
    featuresActions.LOAD_ALL_FEATURES,
    featuresActions.CREATE_FEATURE,
    featuresActions.ENABLE_FOR_ALL,
    featuresActions.DISABLE_FOR_ALL,
    featuresActions.ADD_TO_TIER,
    featuresActions.REMOVE_FROM_TIER,
  ],
  {
    start(state: any) {
      return state;
    },
    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      // Handles loading all features
      if (Array.isArray(action.payload)) {
        return action.payload.reduce((acc: any, feature: any) => {
          acc[feature.id] = feature;
          return acc;
        }, {});
      }

      // Handles adding new feature
      const feature = {
        [action.payload.id]: action.payload,
      };
      return u(feature, state);
    },
  }
);

export const requestsCount = createSequenceCountingReducer([
  featuresActions.LOAD_FEATURES,
  featuresActions.SAVE_FEATURE,
]);

export default combineReducers({
  byId,
  list,
  requestsCount,
});
