import { LangParsedData } from 'utils/i18nLangBuilder';
import u from 'utils/safeUpdeep';

import { Creator } from 'types/creator';
import { ResourceRole } from 'types/permissions';
import { BusinessProfileID, Publisher, PublisherID } from 'types/publishers';

export type UserId = string;

export enum UserFlags {}

// TODO: redux: id and userId is using the same value, deprecate one
export type User = {
  id: UserId;
  snapUsername: string;
  username: string;
  email: string;
  defaultLanguage: string;
  snapchatEmployee: boolean;
  userId: UserId;
  resourceRoles: ResourceRole[];
  userFlags: UserFlags[];
  bitmojiAvatarId: string;
  isRecentlyCreatedUser: boolean;
};

export type UserLocale = {
  localeId: LocaleID;
  messages?: LangParsedData;
};

export type UserState = {
  info: User;
  infoAge: number;
  lastUpdatedPublishersDate: number;
  locale: UserLocale;
  publishers: Publisher[];
  publishersById: {
    [key in PublisherID]: Publisher;
  };
  publishersByBusinessProfileId: {
    [key in BusinessProfileID]: Publisher;
  };
  lastViewedReviseFilter: {
    [key in PublisherID]: number;
  };
  preferences: UserPreference;
  activePublisherId: PublisherID | null;
  activeCreator: Creator | null;
  adControlsEnabled: boolean;
  showTilesEnabled: boolean;
  selectedAdAccountId: string | null;
  useUnifiedLogin: boolean;
};

export type UserPreference = {
  hasSeenNewUserModalInSession: boolean;
  doNotShowNewUserModalAgain: boolean;
};

export type Locale = {
  lang: string;
  localLang: string;
  abbr: string;
};

export const LOCALES: Locale[] = [
  {
    lang: 'English',
    localLang: 'English',
    abbr: 'en',
  },
  {
    lang: 'Spanish',
    localLang: 'Spanish',
    abbr: 'es',
  },
  {
    lang: 'French',
    localLang: 'Français',
    abbr: 'fr',
  },
  {
    lang: 'German',
    localLang: 'Deutsch',
    abbr: 'de',
  },
  {
    lang: 'Arabic',
    localLang: 'عربى',
    abbr: 'ar',
  },
  {
    lang: 'Dutch',
    localLang: 'Dutch',
    abbr: 'nl',
  },
  {
    lang: 'Norwegian',
    localLang: 'Norwegian',
    abbr: 'no',
  },
  {
    lang: 'Swedish',
    localLang: 'Swedish',
    abbr: 'se',
  },
  {
    lang: 'Japanese',
    localLang: '日本語',
    abbr: 'ja',
  },
];
export const DEFAULT_LOCALE: Locale = u.freeze({
  lang: 'English',
  localLang: 'English',
  abbr: 'en',
});
export type LocaleID = typeof LOCALES[number]['abbr'];
