// Including these here guarantees that they will be included before any
// app-specific or component-specific styles.
import '@snapchat/snapnet/lib/styles/colors.scss';
import '@snapchat/snapnet/lib/styles/main.scss';
import '@snapchat/web-attachments/lib/stylesheets/styles.css';
import '@snapchat/snapnet/lib/avenir-next/avenirnext.font.css';
import 'styles/core.scss';
import 'fonts/publisher-tools/styles.scss';
import 'font-awesome/scss/font-awesome.scss';
import { init as SentryInit, Integrations } from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';
import { reactRouterV5Instrumentation } from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import log from 'loglevel';
import * as React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { addLocaleData } from 'react-intl';
import ar from 'react-intl/locale-data/ar';
import de from 'react-intl/locale-data/de';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import fr from 'react-intl/locale-data/fr';
import ja from 'react-intl/locale-data/ja';
import nl from 'react-intl/locale-data/nl';
import no from 'react-intl/locale-data/no';
import se from 'react-intl/locale-data/se';

import initSnapEndpoints from 'state/snaps/endpoints/snapEndpointConfig';
import configureStages from 'state/stages/registry/configureStages';

import { BASE_PATH } from 'config/constants';
import { initStore as initGraphqlAuthLink } from 'utils/apis/apolloLinks/authLink';
import * as apiErrorConfig from 'utils/errors/api/apiErrorConfig';
import { installUnhandledErrorHandler } from 'utils/errors/errorHandlers';
import * as grafanaUtils from 'utils/grafanaUtils';
import * as grapheneUtils from 'utils/grapheneUtils';
import { initializeLocale } from 'utils/localeUtils';
import { beforeBreadcrumb, beforeSend, beforeSendTransaction } from 'utils/logging/sentryServerLoggingUtil';
import { removeErrorParamIfAnyFromUrl } from 'utils/uriUtils';
import { startCheckingForTheLatestStoryStudioVersion } from 'utils/version/versionChecker';

import Root from 'views/common/components/Root/Root';

import installPerfMonitors from './perf';
import configureStore from './redux/configureStore';
import makeRoutes from './routes/routes';

const gaTrackingId = __PROD__ ? 'UA-52008332-2' : 'UA-52008332-3';

// Configure history
const browserHistory = createBrowserHistory({ basename: BASE_PATH });

if (!__DEBUG__) {
  SentryInit({
    beforeBreadcrumb,
    beforeSend,
    beforeSendTransaction,
    // https://sentry.sc-corp.net/settings/sentry/projects/publisher-tools/keys/
    dsn: 'https://ee9a32331b514a0dbcb4bb664615eda0@sentry.sc-prod.net/25',
    // https://docs.sentry.io/workflow/releases/?platform=javascript#tag-errors
    release: `${__SHA_1__ || 'UNKNOWN'}`.substring(0, 7),
    // https://docs.sentry.io/enriching-error-data/environments/?platform=javascript
    environment: process.env.NODE_ENV,
    // Overriding default integrations to avoid installing global unhandled errors and rejected promises handler.
    // We are already catching those.
    defaultIntegrations: [
      new Integrations.InboundFilters(),
      new Integrations.FunctionToString(),
      new Integrations.TryCatch(),
      new Integrations.HttpContext(),
      new Integrations.Breadcrumbs({
        xhr: false,
      }),
      new Integrations.LinkedErrors(),
      new CaptureConsole({ levels: ['error'] }),
      new TracingIntegrations.BrowserTracing({
        // @ts-ignore
        // This is an issue with react-router 5, we need to update react-router version.
        routingInstrumentation: reactRouterV5Instrumentation(browserHistory),
      }),
    ],
    tracesSampleRate: 0.5,
  });
}
addLocaleData([...en, ...fr, ...de, ...ar, ...es, ...nl, ...no, ...se, ...ja]);
if (!document.cookie.includes('sc-cookies-accepted=true')) {
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/user-opt-out
  // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
  window[`ga-disable-${gaTrackingId}`] = true;
}
// Our google analytics tracking id
ReactGA.initialize(gaTrackingId, {
  // Prevent react-ga from converting event strings to Title Case.
  titleCase: false,
});
ReactGA.set({ anonymizeIp: true });
// Enable the React devtools when running within an iframe.
// @see https://gist.github.com/jaredly/593c9b22c619aaf0e0cf
if (__DEBUG__) {
  // eslint-disable-next-line no-restricted-globals
  window.React = (parent as any).React = React;
}
if (__DEBUG__) {
  installPerfMonitors();
}
// This is the default level of logging which gets shown on the console.
// It can be overridden at runtime by just typing "log.setLevel('trace')"
// in the browser console.
// https://github.com/pimterry/loglevel
if (__DEBUG__) {
  log.setLevel('debug');
} else {
  log.setLevel('warn');
}
// @ts-expect-error ts-migrate(2551) FIXME: Property 'log' does not exist on type 'Window & ty... Remove this comment to see the full error message
window.log = log; // For setting the log level at runtime
// Sometimes we may add error param to the url. This is used by the UI to display a helpful message
// However, the message will not be relevant, if the user has copied and pasted the url in a new window
// Clear the message on first load
removeErrorParamIfAnyFromUrl(window.location.href);

const store = configureStore(browserHistory);
// Install unhandled promise rejection handler
installUnhandledErrorHandler(store);
// Start checking for the new version available
startCheckingForTheLatestStoryStudioVersion(store);
// Initialize the AuthLink so it is easier to obtain a token when making
// graphql request
initGraphqlAuthLink(store);
// Configure sending usage analytics to the server
grafanaUtils.init();
// Configure using Graphene for easy Grafana metric reporting
grapheneUtils.initializeGraphene(false);
// Register api notification messages
apiErrorConfig.init();
// Configure the list of endpoints that snaps can be acquired from
initSnapEndpoints();
// Configure the types of `staging` states that can be created
configureStages();

// We need to load the cached or default locale id
initializeLocale(store).then(() => {
  // Now that we have the Redux store, we can create our routes. We provide
  // the store to the route definitions so that routes have access to it for
  // hooks such as `onEnter`.
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Store<{ actions: { lastActionIdB... Remove this comment to see the full error message
  const routes = makeRoutes(store);

  // Now that redux and react-router have been configured, we can render the
  // React application to the DOM!
  const rootElement = document.getElementById('root');
  if (rootElement) {
    ReactDOM.render(<Root history={browserHistory} routes={routes} store={store} />, rootElement);
  } else {
    log.error('Could not find root element in the DOM, page will not render!');
  }
});
