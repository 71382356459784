import * as React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import * as notificationsActions from 'state/notifications/actions/notificationsActions';
import * as notificationsSelectors from 'state/notifications/selectors/notificationsSelectors';
import * as routerActions from 'state/router/actions/routerActions';

import ToastMessage from '../../components/ToastMessage/ToastMessage';
import toastMessageStyle from '../../components/ToastMessage/ToastMessage.scss';

import { intlConnect } from 'utils/connectUtils';

import { getButtonConfig } from './ToastMessageButtonConfig';
import style from './ToastMessagesOverlay.scss';

import { ExtractDispatchProps } from 'types/redux';
import { State } from 'types/rootState';

const mapStateToProps = (state: State) => ({
  toastMessages: notificationsSelectors.getToastMessagesList(state),
});

const mapDispatchToProps = {
  deleteToastMessage: notificationsActions.deleteToastMessage,
  goToLogin: routerActions.goToLogin,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ExtractDispatchProps<typeof mapDispatchToProps>;
type Props = StateProps & DispatchProps;

// TODO Move this out to the SnapNavLayout
export class ToastMessagesOverlay extends React.Component<Props> {
  shouldComponentUpdate(nextProps: {
    deleteToastMessage: (message: any) => any;
    goToLogin: (object: any) => any;
    toastMessages: any;
  }) {
    return nextProps.toastMessages !== this.props.toastMessages;
  }

  getButtons(message: any) {
    const buttonFunctions = {
      dismiss: this.props.deleteToastMessage.bind(undefined, message),
      login: this.props.goToLogin.bind(undefined, { error: null }),
    };
    return getButtonConfig(message, buttonFunctions);
  }

  renderToastMessages() {
    return this.props.toastMessages.map((message: any) => (
      <CSSTransition classNames={toastMessageStyle} timeout={500} key={message.id}>
        <ToastMessage toastMessage={message} buttons={this.getButtons(message)} />
      </CSSTransition>
    ));
  }

  render() {
    return <TransitionGroup className={style.toastOverlay}>{this.renderToastMessages()}</TransitionGroup>;
  }
}

export default intlConnect(mapStateToProps, mapDispatchToProps)(ToastMessagesOverlay);
