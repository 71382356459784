import { ConnectedRouter } from 'connected-react-router';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import global from 'global';
import Intl from 'intl';
import React from 'react';
import { Provider } from 'react-redux'; // eslint-disable-line no-restricted-imports

import { logPageview } from 'utils/metricsUtils';

import ConnectedIntlProvider from './ConnectedIntlProvider';

type Props = {
  history: any;
  routes: React.ReactElement;
  store: any;
};
export default class Root extends React.Component<Props> {
  lastPathname: any;

  UNSAFE_componentWillMount() {
    if (!global.Intl) {
      global.Intl = Intl;
    }
    this.handleRouteChange(this.props.history.location);
    this.props.history.listen(this.handleRouteChange);
  }

  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  get content() {
    return <ConnectedRouter history={this.props.history}>{this.props.routes}</ConnectedRouter>;
  }

  handleRouteChange = (location: any) => {
    // Route can change to the same location so we ignore when that happens
    if (this.lastPathname !== location.pathname) {
      logPageview(location.pathname);
      this.lastPathname = location.pathname;
    }
  };

  render() {
    return (
      <Provider store={this.props.store}>
        {/* @ts-expect-error ts-migrate(2559) FIXME: Type '{ children: Element; }' has no properties in... Remove this comment to see the full error message */}
        <ConnectedIntlProvider>
          <div style={{ height: '100%' }}>{this.content}</div>
        </ConnectedIntlProvider>
      </Provider>
    );
  }
}
