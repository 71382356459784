import moment from 'moment-timezone';

import { AudioClassification, EditorialStatusEnum } from 'config/constants';
import type { SnapTagEnum, AgeGateEnum, ShareOptionEnum, LogoDisplayEnum } from 'config/constants';

import type { ContentAccessLists } from 'types/countries';
import { FeatureFlag } from 'types/featureFlags';

export type PublisherID = number;

export type BusinessProfileID = string;

export type TagMap = {
  [key in SnapTagEnum]: string[];
};

export type PublisherTileLogo = {
  mediaId: string;
  color: string;
};

export type HourMin = {
  hour: number;
  minute: number;
};

export enum TierLevel {
  PREMIUM = 'PREMIUM',
  SHOW = 'SHOW',
  CURATION = 'CURATION',
  HAPPENING_NOW = 'HAPPENING_NOW',
  DYNAMIC_EDITIONS = 'DYNAMIC_EDITIONS',
}

export enum PublisherType {
  NORMAL = 'NORMAL',
  TEST = 'TEST',
}

export enum PublisherFeature {
  EVERGREEN = 'EVERGREEN',
  SUBSCRIBER_ONLY = 'SUBSCRIBER_ONLY',
  EXCLUSIVE_SHOW = 'EXCLUSIVE_SHOW',
  ORIGINAL_SHOW = 'ORIGINAL_SHOW',
  FIXED_PAYMENTS = 'FIXED_PAYMENTS',
  TEAM_SNAPCHAT = 'TEAM_SNAPCHAT',
  TIMELY = 'TIMELY',
  FRIENDS_CAROUSEL = 'FRIENDS_CAROUSEL',
  MADE_FOR_US = 'MADE_FOR_US',
}

export type Publisher = {
  adsPreRollEnabled: boolean;
  advertisingEnabled: boolean;
  advancedAdsEnabled: boolean;
  advancedAdsMinSlots: number;
  advancedAdsMaxSlots: number;
  advancedAdsMinSpacing: number;
  advancedAdsLatestFirstSlot: number;
  adSetting?: string;
  ageGate: AgeGateEnum;
  allowlistLanguage: string[];
  articleCSS: string;
  audioClassification: AudioClassification;
  blocklistLanguage: string[];
  businessProfileId: BusinessProfileID;
  contentAccessLists: ContentAccessLists;
  defaultAdSlots: number[];
  defaultAdSlotsMillis: number[];
  defaultNumSnaps: number;
  defaultShareOption: ShareOptionEnum;
  defaultSubtitlesLanguage: string;
  description: string | undefined | null;
  dynamicAdsEnabled: boolean;
  enabledFeatures?: FeatureFlag[];
  formalName: string;
  geofenceIds: string[];
  homeCountry: string;
  id: PublisherID;
  hostUserId: string;
  hostUsername: string;
  isComScoreIntegrationEnabled: boolean;
  isEmployeeOnly: boolean;
  isOurStories: boolean;
  launchDate: moment.Moment | undefined | null;
  localContent: string[];
  managerEmail: string | undefined | null;
  managerName?: string;
  moderationLevel: string | undefined | null;
  mutablePublisherName: string;
  name: string;
  nicknames: string[];
  dynamicEditionAutoCreateStoryTimeUTC: HourMin | undefined | null;
  orgId: string;
  permitAutoPublishFromFeed?: boolean;
  primaryColor: string | undefined | null;
  primaryColorRgb: number[];
  primaryLanguage: string;
  publishingEnabled: boolean;
  editorialApproval: EditorialStatusEnum;
  editorialRationale: string;
  publisherFeatures: PublisherFeature[];
  profileLogoDisplay?: LogoDisplayEnum;
  squareHeroImageBitmojiTemplateId?: string;
  rollingNewsEnabled: boolean;
  searchable: boolean;
  secondaryColor: string | undefined | null;
  secondaryColorRGB: number[];
  subscribable: boolean | undefined | null;
  tags: TagMap;
  targetSnapLengthMs: number;
  tier: TierLevel;
  tileLogos: PublisherTileLogo[];
  timeUntilAutoPublishFeedStory?: number;
  title: string;
  topsnapLimit: number;
  type: PublisherType;
  urlSafeFormalName: string;
  whitelistLanguage: string[];
  blacklistLanguage: string[];
  videoModeEnabled: boolean | undefined | null;
  websiteUrl?: string;
  webviewLimit: number;
  whitelistCountry: string[];
  blacklistCountry: string[];
  whitelistDistributionCountry: string[];
  tileLogoComponents?: Array<any>; // Circular dependency. Temporary using any,
  autoApproveComments?: boolean;
  // MLS ids
  horizontalIconId?: string;
  defaultFilledIconId?: string;
  squareHeroImageId?: string;
};

export type PublisherDetails = {
  age: number;
  data: Publisher | undefined | null;
  error: Error | undefined | null;
  loading: number;
};

export type ActivePublisher = {
  name: string;
  id: PublisherID;
};

export type PublisherWithGroup = {
  isGroup: boolean;
  groupType: keyof typeof PublisherType;
} & Publisher;
