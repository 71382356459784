/* eslint-disable no-param-reassign */
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { CKEDITOR } from 'global';
import { AllHtmlEntities } from 'html-entities';
import log from 'loglevel';

import { CrossOrigin } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { createAssetUrl } from 'utils/media/assetUtils';

import SCVideo, { MetaAttributes } from 'views/editor/containers/ArticleEditor/plugins/SCVideo';

import { isAssetID } from 'types/assets';

export default function processInput(html: any, params: any, htmlParser: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(html).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(params).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(htmlParser).is.function();
  return htmlParser(html)
    .then((root: any) => splitFields(root))
    .then((fields: any) => convertAssetIdsToImageSources(fields))
    .then((fields: any) => addPlayIconToVideoPreviewImages(fields))
    .then((fields: any) => serializeFields(fields));
}
function splitFields(root: any) {
  //
  // The wrapper html structure looks like this:
  //
  //       <html>
  //         <head>
  //           <link rel="stylesheet" type="text/css" href="style.css" />
  //         </head>
  //         <body>
  //           <div class="in-preview-inner">
  //             <div class="in-preview-container">
  //  [OPTIONAL]   <div class="in-preview-channel">
  //                 <div class="inner">${fields.channel}</div>
  //               </div>
  //  [OPTIONAL]   <div class="in-preview-headline">
  //  [OPTIONAL]     <div class="inner">${fields.headline}</div>
  //  [OPTIONAL]     <div class="secondary">${fields.secondaryHeadline || ''}</div>
  //               </div>
  //  [OPTIONAL]   <div class="in-preview-content">
  //                 <div class="inner">${this._contentWithEmbeddedVideoOverlays()}</div>
  //               </div>
  //             </div>
  //           </div>
  //           <script src="snapchat.js"></script>
  //         </body>
  //       </html>
  //
  // So the most straightforward way to split out each of fields is first to find each
  // of the `class="in-preview-XXXX"` nodes that wrap each `class="inner"` node, and then
  // to pull out the content of the inner nodes.
  //
  const fieldOuters = getFieldOuters(root);
  const fieldInners = getFieldInners(fieldOuters);
  return fieldInners;
}
//
// The structure here is:
//
//   'field-outer-class-name': {
//     'field-inner-class-name': 'fieldName',
//   },
//
const FIELD_CONFIG = {
  'in-preview-channel': {
    inner: 'channel',
  },
  'in-preview-headline': {
    inner: 'headline',
    secondary: 'secondaryHeadline',
  },
  'in-preview-content': {
    inner: 'articleContent',
  },
};
function getFieldOuters(container: any) {
  return findNodesByClassName(container, Object.keys(FIELD_CONFIG));
}
function getFieldInners(fieldOuters: any) {
  const fields = {};
  Object.keys(FIELD_CONFIG).forEach(outerClassName => {
    const fieldContainer = fieldOuters[outerClassName];
    if (fieldContainer) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const innerFieldConfig = FIELD_CONFIG[outerClassName];
      const innerClassNames = Object.keys(innerFieldConfig);
      const innerNodes = findNodesByClassName(fieldContainer, innerClassNames);
      Object.keys(innerFieldConfig).forEach(innerClassName => {
        const fieldName = innerFieldConfig[innerClassName];
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        fields[fieldName] = innerNodes[innerClassName];
      });
    }
  });
  return fields;
}
function findNodesByClassName(container: any, classNames: any) {
  const results = {};
  forAllDescendants(container, (node: any) => {
    classNames.forEach((className: any) => {
      if (node.hasClass(className)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        results[className] = node;
      }
    });
  });
  return results;
}
function splitNodesByType(root: any) {
  const nodesByType = {};
  forAllDescendants(root, (node: any) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    let nodes = nodesByType[node.name];
    if (!nodes) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      nodes = nodesByType[node.name] = [];
    }
    nodes.push(node);
  });
  return nodesByType;
}
function forAllDescendants(element: any, callback: any, type = CKEDITOR.NODE_ELEMENT) {
  // The `forEach()` method being called here is not the standard Array.forEach - rather
  // it's the one provided by CKEditor.htmlParser.fragment, which recursively returns
  // all nodes in the tree at any depth.
  //
  // @see http://docs.ckeditor.com/#!/api/CKEDITOR.htmlParser.fragment-method-forEach
  if (element && element.forEach) {
    element.forEach(callback, type);
  } else {
    log.error('Expected element to have a forEach() method', element);
  }
}
// On the backend, the src attribute of each image node contains just the asset id for
// the corresponding image. This is unsuitable for rendering in the editor as the browser
// obviously can't resolve the asset id to an image.
//
// Here we swap out the asset ids for full asset URLs so that they can be loaded by the
// browser. When the article is saved, ArticleHTMLBuilder reverses this so that each image
// src is replaced with just the asset id.
//
// @see inverse function: ArticleHTMLBuilder._convertImageSourcesToAssetIds()
function convertAssetIdsToImageSources(fields: any) {
  if (!fields.articleContent) {
    return Promise.resolve(fields);
  }
  const imgNodes = (splitNodesByType(fields.articleContent) as any).img || [];
  imgNodes.forEach((img: any) => {
    const { src } = img.attributes;
    if (isAssetID(src)) {
      img.attributes.src = createAssetUrl(src);
      img.attributes.crossOrigin = CrossOrigin.USE_CREDENTIALS;
    } else {
      // Should never happen
      log.error(`Unexpected image src format - cannot convert to asset URL: ${src}`);
    }
  });
  return fields;
}
function addPlayIconToVideoPreviewImages(fields: any) {
  if (!fields.articleContent) {
    return Promise.resolve(fields);
  }
  const imgNodes = (splitNodesByType(fields.articleContent) as any).img || [];
  const videoPreviewImgNodes = imgNodes.filter((img: any) => !!img.attributes[MetaAttributes.VIDEO_ID]);
  const imageCompositingPromises = videoPreviewImgNodes.map((img: any) => {
    const { src } = img.attributes;
    const videoId = img.attributes[MetaAttributes.VIDEO_ID];
    return SCVideo.buildVideoPreviewImage(src, videoId).then(result => {
      img.attributes.src = result.src;
      img.attributes[MetaAttributes.ORIGINAL_SRC] = result[MetaAttributes.ORIGINAL_SRC];
    });
  });
  return Promise.all(imageCompositingPromises).then(() => fields);
}
function serializeFields(fields: any) {
  if (Object.keys(fields).length === 0) {
    return {};
  }
  const serializedFields = {
    channel: toString(fields.channel),
    headline: toString(fields.headline),
    secondaryHeadline: toString(fields.secondaryHeadline),
    articleContent: toHtml(fields.articleContent),
  };
  return serializedFields;
}
function toString(node: any) {
  // It's valid for fields to be omitted
  if (!node) {
    return '';
  }
  if (node.children.length === 1 && node.children[0].type === CKEDITOR.NODE_TEXT) {
    return new AllHtmlEntities().decode(node.children[0].value);
  }
  if (node.children.length === 0) {
    return '';
  }
  log.error('Unexpected text node children', node);
  return '';
}
function toHtml(node: any) {
  // It's valid for fields to be omitted
  if (!node) {
    return '';
  }
  const writer = new CKEDITOR.htmlWriter(); // eslint-disable-line new-cap
  writer.indentationChars = '';
  writer.lineBreakChars = '';
  node.children.forEach((child: any) => child.writeHtml(writer));
  return writer.getHtml();
}
export { processInput };
