// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { NavDropdown, MenuItem } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';

import * as userSelectors from 'state/user/selectors/userSelectors';

import { intlConnect } from 'utils/connectUtils';

import style from './ForcedRolesDropdown.scss';
import {
  clearForcedRoles,
  hasForcedRole,
  getForcedRoles,
  hasAnyForcedRoles,
  toggleForcedRole,
  getForcedRolesLabel,
} from './forcedRolesUtils';

import { Roles } from 'types/permissions';

// @ts-expect-error ts-migrate(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const mapStateToProps = (state, props) => ({
  isEmployee: userSelectors.hasSnapchatEmployeePermissions(state),
});

type ForcedRolesDropdownProps = {
  isEmployee?: boolean;
  className?: string;
  onUpdate?: (...args: any[]) => any;
};

class ForcedRolesDropdown extends React.Component<ForcedRolesDropdownProps> {
  toggleForcedRole = _.memoize(role => () => {
    toggleForcedRole(role);
    this.onUpdate();
  });

  onUpdate() {
    this.forceUpdate();

    if (this.props.onUpdate) {
      this.props.onUpdate(getForcedRoles());
    }
  }

  clearForcedRoles() {
    return () => {
      clearForcedRoles();
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      this.onUpdate(getForcedRoles());
    };
  }

  renderTitle() {
    if (hasAnyForcedRoles()) {
      return `Forced as ${getForcedRolesLabel()}`;
    }
    return 'Force role';
  }

  render() {
    return (
      this.props.isEmployee && (
        <NavDropdown
          title={
            <span
              className={classNames({
                [style.selected]: hasAnyForcedRoles(),
                [style.coloured]: hasAnyForcedRoles(),
              })}
              id="title"
            >
              {this.renderTitle()}
            </span>
          }
          id="force-role-nav-dropdown"
          className={this.props.className}
          noCaret
        >
          <MenuItem
            className={classNames({ [style.selected]: !hasAnyForcedRoles() })}
            key="none"
            eventKey="none"
            onClick={this.clearForcedRoles()}
          >
            None
          </MenuItem>
          {[
            { role: Roles.ADMIN.toUpperCase(), label: 'Admin' },
            { role: Roles.CONTRIBUTOR.toUpperCase(), label: 'Editor' },
            { role: Roles.VIEWER.toUpperCase(), label: 'Viewer' },
            { role: Roles.ANALYST.toUpperCase(), label: 'Analyst' },
          ].map(roleConfig => {
            const roleStyle = classNames({
              [style.selected]: hasForcedRole(roleConfig.role),
              [style.coloured]: hasForcedRole(roleConfig.role),
            });
            return (
              <MenuItem
                className={roleStyle}
                key={roleConfig.label}
                eventKey={roleConfig.label}
                onClick={this.toggleForcedRole(roleConfig.role)}
              >
                {roleConfig.label}
              </MenuItem>
            );
          })}
        </NavDropdown>
      )
    );
  }
}

export default intlConnect(mapStateToProps, null)(ForcedRolesDropdown);
