import _ from 'lodash';
import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

function getNotifications(state: any) {
  return state.notifications || {};
}

export const getInvalidAdToastOpened = createKeySelector(getNotifications, 'invalidAdToastOpened', false);

const getToastMessages = createKeySelector(getNotifications, 'toastMessages', {});

export const getToastMessagesList = s(getToastMessages, toastMessages => {
  return _.sortBy(_.values(toastMessages), ['id']);
});

export const getToastMessagesFilteredByContext = s(getToastMessages, toastMessages => (context: any) => {
  return _.values(toastMessages).filter(message => _.get(message, ['params', 'context']) === context);
});
