import { gql } from '@apollo/client';

export const ROOT_PUBLISHER_FIELDS = gql`
  fragment RootPublisherFields on Publisher {
    id
    businessProfileId
    orgId
    hostUsername
    mutablePublisherName
    formalName
    tier
    type
    primaryLanguage
    publishingEnabled
    editorialApproval
    enabledFeatures
  }
`;

export const ROOT_GET_PUBLISHER = gql`
  ${ROOT_PUBLISHER_FIELDS}
  query RootPublisherDetailsQuery($publisherId: ID!) {
    publisher(id: $publisherId) {
      ...RootPublisherFields
    }
  }
`;
