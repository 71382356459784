import { UploadPurpose } from 'config/constants';

import type { MediaDimensions } from 'types/media';

export const MediaOutputDimensions: {
  [x: string]: MediaDimensions;
} = {
  [UploadPurpose.SQUARE_ICON]: { width: 160, height: 160 },
  [UploadPurpose.SQUARE_HERO_IMAGE]: { width: 1080, height: 1080 },
  [UploadPurpose.TILE_IMAGE]: { width: 540, height: 900 },
  [UploadPurpose.TILE_LOGO]: { width: 993, height: 284 },
};

export const MediaOutputFormat: {
  [x: string]: string;
} = {
  [UploadPurpose.SQUARE_ICON]: 'PNG',
  [UploadPurpose.SQUARE_HERO_IMAGE]: 'JPEG',
  [UploadPurpose.TILE_IMAGE]: 'PNG',
  [UploadPurpose.TILE_LOGO]: 'PNG',
};
