export const AUTH_TYPE = 'auth/AUTH_TYPE';
export const GOOGLE_REFRESH_TOKEN = 'auth/GOOGLE_REFRESH_TOKEN';
export const REFRESH_TOKEN = 'auth/REFRESH_TOKEN';
export const SNAP_REFRESH_TOKEN = 'auth/SNAP_REFRESH_TOKEN';
export const LOGOUT = 'auth/LOGOUT';
export const PRELOAD_GAPI = 'auth/PRELOAD_GAPI';
export const SET_RICHSNAP_COOKIE = 'auth/SET_RICHSNAP_COOKIE';
export const SET_REFRESH_LOOP = 'auth/SET_REFRESH_LOOP';
export const FLAG_AUTH_FAILED = 'auth/FLAG_AUTH_FAILED';

export const SNAP_AUTH_TICKET = 'auth/SNAP_AUTH_TICKET';
export const GOOGLE_LOGIN = 'auth/GOOGLE_LOGIN';

export const SET_ASSOCIATED_USER = 'auth/SET_ASSOCIATED_USER';
export const CLEAR_ASSOCIATED_USER = 'auth/CLEAR_ASSOCIATED_USER';

export const TOKEN_REFRESH_RETRY_OPTIONS = {
  retries: 2,
  maxTimeout: 5000,
};
