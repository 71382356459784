import { UploadPurpose } from 'config/constants';

const Bucket = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const BucketByPurpose = {
  [UploadPurpose.ARTICLE_IMAGE]: Bucket.MEDIUM,
  [UploadPurpose.LONGFORM_VIDEO]: Bucket.LARGE,
  [UploadPurpose.OVERLAY_MEDIA]: Bucket.MEDIUM,
  [UploadPurpose.SUBTITLE]: Bucket.SMALL,
  [UploadPurpose.TILE_IMAGE]: Bucket.MEDIUM,
  [UploadPurpose.TILE_CROP_IMAGE]: Bucket.SMALL,
  [UploadPurpose.HAPPENING_NOW_TILE_IMAGE]: Bucket.MEDIUM,
  [UploadPurpose.HAPPENING_NOW_TILE_CROP_IMAGE]: Bucket.SMALL,
  [UploadPurpose.TOP_SNAP]: Bucket.MEDIUM,
  [UploadPurpose.TOPSNAP_SUBTITLES]: Bucket.SMALL,
  [UploadPurpose.EDITION_SUBTITLES]: Bucket.SMALL,
  [UploadPurpose.POLL_IMAGE]: Bucket.MEDIUM,
  [UploadPurpose.TILE_LOGO]: Bucket.SMALL,
  [UploadPurpose.SQUARE_HERO_IMAGE]: Bucket.MEDIUM,
  [UploadPurpose.SQUARE_ICON]: Bucket.SMALL,
  [UploadPurpose.WIDE_ICON]: Bucket.SMALL,
  [UploadPurpose.SINGLE_ASSET_STORY]: Bucket.LARGE,
  [UploadPurpose.CURATED_SNAP_MEDIA]: Bucket.MEDIUM,
  [UploadPurpose.CURATED_SNAP_OVERLAY_MEDIA]: Bucket.MEDIUM,
  [UploadPurpose.SPOTLIGHT]: Bucket.MEDIUM,
  [UploadPurpose.PUBLIC_STORY]: Bucket.MEDIUM,
};

export function getAdditionalDimensions(action: any) {
  const purpose: UploadPurpose | null = action?.params?.purpose || null;
  return purpose ? { purpose, size: BucketByPurpose[purpose] || 'unknown' } : {};
}
