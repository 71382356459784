import { StageType } from 'config/constants';
import * as objectUtils from 'utils/objectUtils';

import type { Publisher } from 'types/publishers';
import type { Stage } from 'types/stages';

type PublishersStage = Stage<Publisher, Partial<Publisher>, void>;
export const PUBLISHERS_STAGE_CONFIG: PublishersStage = {
  stageName: StageType.PUBLISHER,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: [
    'adSetting',
    'adsPreRollEnabled',
    'advancedAdsEnabled',
    'advancedAdsLatestFirstSlot',
    'advancedAdsMaxSlots',
    'advancedAdsMinSlots',
    'advancedAdsMinSpacing',
    'advertisingEnabled',
    'advertisingEnabled',
    'ageGate',
    'allowlistCountry',
    'allowlistDistributionCountry',
    'allowlistLanguage',
    'articleCSS',
    'audioClassification',
    'blocklistCountry',
    'blocklistDistributionCountry',
    'blocklistLanguage',
    'businessProfileId',
    'contentAccessLists',
    'defaultAdSlots',
    'defaultAdSlotsMillis',
    'defaultFilledIconId',
    'defaultNumSnaps',
    'defaultShareOption',
    'defaultSubtitlesLanguage',
    'description',
    'dynamicAdsEnabled',
    'editorialApproval',
    'editorialRationale',
    'formalName',
    'geofenceIds',
    'homeCountry',
    'horizontalIconId',
    'hostUsername',
    'isComScoreIntegrationEnabled',
    'isEmployeeOnly',
    'isOurStories',
    'localContent',
    'managerEmail',
    'managerName',
    'moderationLevel',
    'mutablePublisherName',
    'nicknames',
    'orgId',
    'permitAutoPublishFromFeed',
    'primaryColor',
    'primaryLanguage',
    'profileLogoDisplay',
    'publisherFeatures',
    'publishingApprovals',
    'publishingEnabled',
    'regions',
    'rollingNewsEnabled',
    'searchable',
    'secondaryColor',
    'squareHeroImageBitmojiTemplateId',
    'squareHeroImageId',
    'subscribable',
    'tags',
    'targetSnapLengthMs',
    'dynamicEditionAutoCreateStoryTimeUTC',
    'tier',
    'tileLogos',
    'topSnapLimit',
    'topsnapLimit',
    'type',
    'urlSafeFormalName',
    'videoModeEnabled',
    'websiteUrl',
    'websiteUrl',
    'webviewLimit',
  ],
  transformToStaged: (properties: Partial<Publisher>) => {
    const { tileLogos, tags } = properties;

    // Remove __originalKeys___
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const tileLogosWithoutOriginalKeys = tileLogos.map(objectUtils.undoKeyTransformation);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    const tagsWithoutOriginalKeys = objectUtils.undoKeyTransformation(tags);

    return { ...properties, tags: tagsWithoutOriginalKeys, tileLogos: tileLogosWithoutOriginalKeys };
  },
  transformFromStaged: (properties: Partial<Publisher>) => properties,
};
