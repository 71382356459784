import is from 'is_js';
import { normalize } from 'normalizr';
import { combineReducers } from 'redux';

import { createTransactionalSequenceHandlingReducer } from 'state/common/reducerFactories';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as scsStoryActions from 'state/scs/actions/storyActions';

import { TransactionType } from 'config/constants';
import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

import {
  CREATE_STORY,
  GET_EDITION,
  SAVE_EDITION,
  SET_EDITION_PROPERTIES,
  SET_EDITION_STATE,
} from 'types/actions/editionsActionTypes';

const segmentHandler = createTransactionalSequenceHandlingReducer(
  {},
  TransactionType.SET_EDITION_PROPERTY,
  [
    GET_EDITION,
    CREATE_STORY,
    SAVE_EDITION,
    SET_EDITION_STATE,
    publishersActions.GET_LIVE_EDITIONS,
    publishersActions.GET_ACTIVE_EDITIONS,
    publishersActions.GET_UNAVAILABLE_EDITIONS,
    publishersActions.GET_HOMEPAGE_STORIES,
    publishersActions.GET_SCHEDULED_LIVE_STORIES,
    scsStoryActions.UPDATE_STORY,
  ],
  {
    start(state: any, action: any) {
      return state;
    },

    done(state: any, action: any) {
      if (action.error) {
        return state;
      }

      const originalSegments = action.payload.entities.segment;
      if (is.existy(originalSegments)) {
        const constantSegments = wrapEntitiesInUpdeepConstants(originalSegments);
        return u(constantSegments, state);
      }
      return state;
    },
  }
);

const byId = (state = {}, action: any) => {
  switch (action.type) {
    case SET_EDITION_PROPERTIES: {
      // Edition at this point is denormalized. We must normalize it to get to edition segments.
      const normalized = normalize(action.payload, editionSchema);
      if ('result' in normalized && normalized.entities.segment) {
        return u(normalized.entities.segment, state);
      }
      return state;
    }
    default:
      break;
  }
  return segmentHandler(state, action);
};

export default combineReducers({
  byId,
});
