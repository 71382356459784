import loadable from '@loadable/component';
import React from 'react';

import { enumObject, Enum } from 'utils/enum';

import LoadingView from 'views/common/components/LoadingView/LoadingView';

const fallback = <LoadingView messageId="loading-message-generic" />;

const AnalyticsDailyView = loadable(() => import('views/analytics/containers/AnalyticsDailyView/AnalyticsDailyView'), {
  fallback,
});
const AnalyticsOverviewView = loadable(
  () => import('views/analytics/containers/AnalyticsOverviewView/AnalyticsOverviewView'),
  {
    fallback,
  }
);
const AnalyticsOverviewViewV2 = loadable(
  () => import('views/analytics/containers/AnalyticsOverviewView/AnalyticsOverviewV2/AnalyticsOverviewV2'),
  {
    fallback,
  }
);
const AnalyticsInsightsView = loadable(
  () => import('views/analytics/containers/AnalyticsInsightsView/AnalyticsInsightsView'),
  { fallback }
);
const RevenueView = loadable(() => import('views/analytics/containers/RevenueView/RevenueView'), { fallback });

const ProfileAnalyticsView = loadable(
  () => import('views/analytics/containers/ProfileAnalyticsView/ProfileAnalyticsView'),
  { fallback }
);

const SpotlightAnalyticsView = loadable(
  () => import('views/analytics/containers/SpotlightAnalyticsView/SpotlightAnalyticsView'),
  { fallback }
);

export const ANALYTICS_TABS = enumObject({
  PROFILE: 'PROFILE',
  AUDIENCE: 'AUDIENCE',
  BEHAVIOR: 'BEHAVIOR',
  INSIGHTS: 'INSIGHTS',
  STORIES: 'STORIES',
  STORIES_V2: 'STORIES_V2',
  SPOTLIGHT: 'SPOTLIGHT',
  REVENUE: 'REVENUE',
});

export type AnalyticsTabsEnum = Enum<typeof ANALYTICS_TABS>;

export const TABS_CONFIG = {
  [ANALYTICS_TABS.AUDIENCE]: {
    content: AnalyticsDailyView,
    props: { viewType: 'audience' },
  },
  [ANALYTICS_TABS.BEHAVIOR]: {
    content: AnalyticsDailyView,
    props: { viewType: 'behavior' },
  },
  [ANALYTICS_TABS.INSIGHTS]: {
    content: AnalyticsInsightsView,
    props: {},
  },
  [ANALYTICS_TABS.STORIES]: {
    content: AnalyticsOverviewView,
    props: {},
  },
  [ANALYTICS_TABS.STORIES_V2]: {
    content: AnalyticsOverviewViewV2,
    props: {},
  },
  [ANALYTICS_TABS.SPOTLIGHT]: {
    content: SpotlightAnalyticsView,
    props: {},
  },
  [ANALYTICS_TABS.REVENUE]: {
    content: RevenueView,
    props: {},
  },
  [ANALYTICS_TABS.PROFILE]: {
    content: ProfileAnalyticsView,
    props: { viewType: 'profile' },
  },
};
