import * as discoverAPI from 'utils/apis/discoverAPI';
import * as scsAPI from 'utils/apis/scsAPI';

import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';

export function debugSnapEndpoint(snapId: SnapId) {
  return scsAPI.richSnap.snap.debug({ snapId });
}

export function debugEditionEndpoint(editionId: EditionID) {
  return scsAPI.story.debug({ editionId });
}

export function debugPublisherEndpoint(publisherId: PublisherID) {
  return discoverAPI.publishers.debug({ publisherId });
}
