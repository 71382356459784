import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import * as modalsActions from 'state/modals/actions/modalsActions';

function addModal(state: any, action: any) {
  return [...state, action.payload];
}

function removeModal(state: any, action: any) {
  return state.filter((modal: any) => modal.modalId !== action.payload.modalId);
}

const activeModals = (state = [], action: any) => {
  switch (action.type) {
    case modalsActions.SHOW_MODAL:
      return addModal(state, action);
    case modalsActions.HIDE_MODAL:
      return removeModal(state, action);
    default:
      return state;
  }
};

const modalConfig = (state = {}, action: any) => {
  switch (action.type) {
    case modalsActions.SET_MODAL_CONFIG_PROPERTIES: {
      const update = {
        [action.payload.modalId]: action.payload.diffObject,
      };
      return u(update, state);
    }
    case modalsActions.HIDE_MODAL:
      return u(u.omit(action.payload.modalId), state);
    default:
      return state;
  }
};

export default combineReducers({
  activeModals,
  modalConfig,
});
