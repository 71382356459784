import { State } from 'src/types/rootState';
import u from 'utils/safeUpdeep';

type SelectID = string | number | symbol;

type ParentSelector<T> =
  | ((state: State) => T)
  | ((state: State) => T | null)
  | ((state: State) => T | undefined)
  | ((state: State) => T | null | undefined);

type ParentByIdSelector<T, ID extends SelectID> = (state: State) => (id: ID) => T;

// This is required as typescript compiler does not perform higher level analysis.
function isNonNullable<T>(x: T): x is NonNullable<T> {
  return x !== null && x !== undefined;
}

// Always use the same default state object to avoid mutating state unnecessarily.
function getDefault<T>(defaultState: T): T {
  return typeof defaultState === 'object' && isNonNullable(defaultState) ? u.freeze(defaultState) : defaultState;
}

// createKeySelector creates a selector that checks if the key given by propertyName exists.
// It returns defaultState if the property does not exist.
// It does NOT return the defaultState if the property itself is null.
export function createKeySelector<Parent, Property extends keyof NonNullable<Parent>>(
  parentSelector: ParentSelector<Parent>,
  property: Property,
  defaultState: NonNullable<Parent>[Property]
) {
  return (state: State) => {
    const parent = parentSelector(state);
    // @ts-ignore
    if (isNonNullable(parent) && property in parent) {
      return parent[property];
    }
    return getDefault(defaultState);
  };
}

// createNullableKeySelector works like createKeySelector but allows the defaultState to be null even if the property is non-nullable.
export function createNullableKeySelector<Parent, Property extends keyof NonNullable<Parent>>(
  parentSelector: ParentSelector<Parent>,
  property: Property,
  defaultState: NonNullable<Parent>[Property] | null = null
) {
  return (state: State) => {
    const parent = parentSelector(state);
    // @ts-ignore
    if (isNonNullable(parent) && property in parent) {
      return parent[property];
    }
    return getDefault(defaultState);
  };
}

// createNonNullableKeySelector works like createKeySelector but return the defaultState if the property itself is null.
export function createNonNullableKeySelector<Parent, Property extends keyof NonNullable<Parent>>(
  parentSelector: ParentSelector<Parent>,
  property: Property,
  defaultState: NonNullable<NonNullable<Parent>[Property]>
): (state: State) => NonNullable<NonNullable<Parent>[Property]> {
  return (state: State) => {
    const parent = parentSelector(state);
    // @ts-ignore
    if (isNonNullable(parent) && property in parent) {
      const value = parent[property];
      if (isNonNullable(value)) {
        return value;
      }
    }
    return getDefault(defaultState);
  };
}

// createByIdSelector creates a selector that finds object with a key in the map and applies selector to return the value.
// It returns defaultState if object with the given key is not present in the map.
// It does NOT return the defaultState if the value at given key itself is null.
export function createByIdSelector<T, ID extends SelectID, Parent>(
  parentSelector: (state: State) => { [id in ID]: Parent },
  selector: (item: Parent) => T,
  defaultState: T
) {
  return (state: State) => (id: ID) => {
    const parent = parentSelector(state);
    if (id in parent) {
      return selector(parent[id]);
    }
    return getDefault(defaultState);
  };
}

// createByIdKeySelector works like createByIdSelector but simply returns the object found at the given key.
export function createByIdKeySelector<T, ID extends SelectID>(
  parentSelector: (state: State) => { [id in ID]: T },
  defaultState: T
) {
  return createByIdSelector(parentSelector, x => x, defaultState);
}

// reselectById takes an byId selector and applies a child selector to the returned object.
export function reselectById<T, Parent, ID extends SelectID>(
  parentSelector: ParentByIdSelector<Parent, ID>,
  selector: (item: Parent) => T
) {
  return (state: State) => (id: ID) => {
    const parent = parentSelector(state)(id);
    return selector(parent);
  };
}

// reselectByIdKey takes an byId selector and selects the property from the returned object.
// It returns default state if the returned object is null/undefined or property does not exist on the object.
// It does NOT return defualt state if the value at given key itself is null.
export function reselectByIdKey<Parent, ID extends SelectID, Property extends keyof NonNullable<Parent>>(
  parentSelector: ParentByIdSelector<Parent, ID>,
  property: Property,
  defaultState: NonNullable<Parent>[Property]
) {
  return (state: State) => (id: ID) => {
    const parent = parentSelector(state)(id);
    // @ts-ignore
    if (isNonNullable(parent) && property in parent) {
      return parent[property];
    }
    return getDefault(defaultState);
  };
}

// reselectByIdNullableKey works the same as reselectKey but allows the defaultState to be null even if the property is non-nullable.
export function reselectByIdNullableKey<Parent, ID extends SelectID, Property extends keyof NonNullable<Parent>>(
  parentSelector: ParentByIdSelector<Parent, ID>,
  property: Property,
  defaultState: NonNullable<Parent>[Property] | null = null
) {
  return (state: State) => (id: ID) => {
    const parent = parentSelector(state)(id);
    // @ts-ignore
    if (isNonNullable(parent) && property in parent) {
      return parent[property];
    }
    return getDefault(defaultState);
  };
}
