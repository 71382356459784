import * as reducerFactory from 'state/common/reducerFactories';
import * as editorStateActions from 'state/editor/actions/editorStateActions';
import type { UpdateEditorStateAction } from 'state/editor/actions/editorStateActions';

import u from 'utils/safeUpdeep';

/**
 * The reducer can be attached to any part of the datastore, we are going to attach it to the 'editor'
 * store {
 *   editor {
 *     editorState: {
 *       SNAP: {
 *         1234: {
 *           prop: 'value',
 *         }
 *       },
 *       OTHER_COMPONENT: {
 *         1234: {
 *           prop: 'value',
 *         }
 *       }
 *     }
 *   }
 * }
 */

const editorStateReducer = <EditorState extends {}>(state = {}, action: UpdateEditorStateAction<EditorState>) => {
  switch (action.type) {
    case editorStateActions.UPDATE_EDITOR_STATE:
      return state ? u(action.payload, state) : action.payload || null;
    default:
      return state;
  }
};

const editorIdReducer = reducerFactory.createByIdReducer('editorId', editorStateReducer);
const discardEditorId = reducerFactory.createRemoverByIdReducer('editorId', editorStateActions.DISCARD);
const editorReducer = reducerFactory.serializeReducers([editorIdReducer, discardEditorId]);
const byComponentReducer = reducerFactory.createByIdReducer('componentScope', editorReducer);

export default byComponentReducer;
