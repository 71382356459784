import { get } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';

import { EMPTY_OBJECT } from 'config/constants';

const getSupportTicketsState = (state: any) => get(state, ['supportTickets']) || {};
const getFormFields = createKeySelector(getSupportTicketsState, 'formFields', EMPTY_OBJECT);
export const getDescription = createKeySelector(getFormFields, 'description', null);
export const getCategory = createKeySelector(getFormFields, 'category', null);
export const getSubject = createKeySelector(getFormFields, 'subject', null);
export const getURL = createKeySelector(getFormFields, 'url', null);
export const getScreenshot = createKeySelector(getFormFields, 'screenshot', null);

export const isSubmittingTicket = createKeySelector(getSupportTicketsState, 'isSubmittingTicket', 0);

export const getIsSendButtonEnabledAndTooltip = s(
  getSubject,
  getCategory,
  getDescription,
  getURL,
  getActivePublisherDetails,
  (subject, category, description, url, publisher) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const completedForm = !!(subject && subject.length > 0 && description && description.length > 0 && category);
    if (!completedForm) {
      return {
        isSendButtonEnabled: false,
        sendButtonTooltip: (
          <FormattedMessage
            id="support-ticket-incomplete-form-tooltip"
            defaultMessage="Complete the contact form before sending"
            description="tooltip text for when the contact form send button is disabled due to an incomplete form"
          />
        ),
      };
    }

    return {
      isSendButtonEnabled: true,
    };
  }
);
