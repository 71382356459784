import _ from 'lodash';
import { createSelector as s } from 'reselect';

import { shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import {
  createByIdKeySelector,
  createByIdSelector,
  createKeySelector,
  reselectByIdNullableKey,
  reselectByIdKey,
  reselectById,
} from 'state/common/selectorFactoriesTyped';

import { EMPTY_ARRAY } from 'config/constants';

import { InfoStatus, StoryScoreType } from 'types/build';
import type { Edition, EditionID } from 'types/editions';
import { StoryModerationScope, StoryModerationStatus } from 'types/moderation';
import type { State } from 'types/rootState';
import { TileID } from 'types/tiles';

const getAllBuildStatuses = (state: State) => state.buildStatus;

const getDiscoverSnapBuildStatuses = createKeySelector(getAllBuildStatuses, 'discoverSnaps', {});
export const getDiscoverSnapBuildStatus = createByIdKeySelector(getDiscoverSnapBuildStatuses, null);
export const getModerationTagsBySnapId = reselectByIdKey(getDiscoverSnapBuildStatus, 'moderationTags', {});

export const getSegmentBuildStatuses = createKeySelector(getAllBuildStatuses, 'segments', {});
export const getSegmentBuildStatusById = createByIdKeySelector(getSegmentBuildStatuses, null);
export const getSegmentAudience = reselectByIdKey(getSegmentBuildStatusById, 'audience', []);

export const getEditionBuildStatusState = createKeySelector(getAllBuildStatuses, 'editions', {
  loadingById: {},
  byId: {},
  ageById: {},
});
export const getEditionBuildStatuses = createKeySelector(getEditionBuildStatusState, 'byId', {});
export const getEditionBuildStatusById = createByIdKeySelector(getEditionBuildStatuses, null);
export const getStoryScoreById = createByIdSelector(
  getEditionBuildStatuses,
  buildStatus => {
    const score = buildStatus.computedScore;
    return {
      achievedPercentage: score?.percentageAchieved,
      achievedScore: score?.scoreAchieved,
      failedScores: score?.failedScores.filter((scoreType: StoryScoreType) =>
        _.values(StoryScoreType).includes(scoreType)
      ),
      fulfilledScores: score?.fulfilledScores.filter((scoreType: StoryScoreType) =>
        _.values(StoryScoreType).includes(scoreType)
      ),
    };
  },
  {
    achievedPercentage: 0,
    achievedScore: 0,
    failedScores: [],
    fulfilledScores: [],
  }
);

const getEditionBuildStatusTimestamps = createKeySelector(getEditionBuildStatusState, 'ageById', {});
export const getEditionBuildStatusAgeById = createByIdKeySelector(getEditionBuildStatusTimestamps, 0);

export const getEditionBuildStatusesLoading = createKeySelector(getEditionBuildStatusState, 'loadingById', {});
export const isEditionBuildStatusLoading = createByIdKeySelector(getEditionBuildStatusesLoading, false);
export const getSingleAssetTranscodeStatus = reselectByIdNullableKey(
  getEditionBuildStatusById,
  'singleAssetTranscodeStatus',
  null
);
export const editionScheduleStatus = reselectByIdNullableKey(getEditionBuildStatusById, 'editionStatus', null);
export const getEditionBuildStatus = editionScheduleStatus;

export const getEditionSnapBuildStatuses = s(
  getDiscoverSnapBuildStatus,
  getDiscoverSnapBuildStatusFn => (edition: Edition | null) => {
    if (!edition || !edition.snapIds) {
      return [];
    }
    return edition.snapIds.map(snapId => getDiscoverSnapBuildStatusFn(snapId));
  }
);

export const getStoryAudience = reselectByIdKey(getEditionBuildStatusById, 'audience', []);
export const getStoryModerationStatus = reselectByIdKey(
  getEditionBuildStatusById,
  'moderationStatus',
  StoryModerationStatus.NONE
);

export const getStoryModerationScope = reselectByIdKey(
  getEditionBuildStatusById,
  'moderationScope',
  StoryModerationScope.UNSET
);

export const getTileBuildStatuses = createKeySelector(getAllBuildStatuses, 'tiles', {});
export const getTileBuildStatusById = createByIdKeySelector(getTileBuildStatuses, null);

// TileId is the croppedImageAssetId or scsId
export const getTileAudience = reselectByIdKey(getTileBuildStatusById, 'audience', []);
export const getTileViolations = reselectByIdKey(getTileBuildStatusById, 'moderationViolations', []);
export const getTileModerationAppealStatus = reselectByIdNullableKey(
  getTileBuildStatusById,
  'moderationAppealStatus',
  null
);

function getTileStatusWireModels<ID, T extends { tileStatusWireModels?: Array<TileID> }>(
  statuses: Array<ID> | null,
  selector: (id: ID) => T | null
) {
  if (!statuses) {
    return [];
  }
  return statuses.flatMap(id => {
    const status = selector(id);
    return status?.tileStatusWireModels ? status.tileStatusWireModels : [];
  });
}
export const getTileStatusesForStory = s(
  getEditionBuildStatusById,
  getDiscoverSnapBuildStatus,
  getSegmentBuildStatusById,
  getTileBuildStatusById,
  (storyBuildStatusesById, snapBuildStatusesById, segmentBuildStatusesById, tileBuildStatusesById) => (
    storyId: EditionID
  ) => {
    const storyBuildStatuses = storyBuildStatusesById(storyId);
    if (!storyBuildStatuses) {
      return EMPTY_ARRAY;
    }
    const tileIds = [
      ...getTileStatusWireModels(storyBuildStatuses.snapStatuses, snapBuildStatusesById),
      ...getTileStatusWireModels(storyBuildStatuses.segmentStatuses, segmentBuildStatusesById),
    ];
    return tileIds.map(tileId => tileBuildStatusesById(tileId));
  }
);

export const hasAnyTilesBeenFlaggedForModerationForStoryId = s(
  getTileStatusesForStory,
  tileStatusesByStoryId => (storyId: EditionID) => {
    return tileStatusesByStoryId(storyId).some(tileStatus => shouldShowModeration(tileStatus?.audience));
  }
);

export const getSubtitlesStatus = reselectByIdKey(getEditionBuildStatusById, 'subtitlesStatus', {
  allSubtitlesAreGenerated: false,
  languages: [],
});
export const getAllStorySubtitlesGenerated = reselectByIdKey(getSubtitlesStatus, 'allSubtitlesAreGenerated', false);
export const getStorySubtitlesLanguages = reselectByIdKey(getSubtitlesStatus, 'languages', []);
export const getInfoStatuses = reselectById(getAllStorySubtitlesGenerated, isGenerated =>
  isGenerated ? [InfoStatus.AUTO_GENENERATED_SUBS] : EMPTY_ARRAY
);
