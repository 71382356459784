import log from 'loglevel';

import { CrossOrigin, SSAPI_PROXY_BASE_URL } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import * as brightcoveUtils from 'utils/brightcoveUtils';
import * as gaUtils from 'utils/gaUtils';
import { ImageLoader } from 'utils/media/ImageLoader';
import { ModalType } from 'utils/modalConfig';

import ImageStyleUtil from './utils/ImageStyleUtil';

export const MetaAttributes = {
  // For backwards compatibility reasons we use `rel` and `alt` here rather than using HTML5 data attributes
  VIDEO_ID: 'rel',
  ORIGINAL_SRC: 'alt',
};

export default class SCVideo {
  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get pluginName() {
    return 'SCVideo';
  }

  // @ts-expect-error ts-migrate(1056) FIXME: Accessors are only available when targeting ECMASc... Remove this comment to see the full error message
  static get label() {
    return 'Video';
  }

  static define(
    registry: any,
    elementCreator: any,
    showModalAction: any,
    store: any,
    openBrightcovePreviewAction: any
  ) {
    registry.registerButton(SCVideo, {
      command: {
        allowedContent: `img[!src,${MetaAttributes.VIDEO_ID},${MetaAttributes.ORIGINAL_SRC}]`,
        requiredContent: `img[src,${MetaAttributes.VIDEO_ID},${MetaAttributes.ORIGINAL_SRC}]`,

        exec(editor: any) {
          showModalAction(ModalType.VIDEO_UPLOAD, SCVideo.name).then((result: any) => {
            this._hideModalCallBack(result, editor);
          });
          gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-editor-open-video-modal');
        },

        _hideModalCallBack(result = null, editor: any) {
          // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
          if (result && typeof result.payload === 'object') {
            gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-editor-hide-video-modal');
            this._insertVideoPreviewIntoEditor(result, editor);
          }
        },

        _insertVideoPreviewIntoEditor(result: any, editor: any) {
          if (result && typeof result.payload === 'object') {
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
            assertArg(result.payload.id).is.string.or.is.number();
            gaUtils.logGAEvent(gaUtils.GAUserActions.RICHSNAP_EDITOR, 'article-editor-insert-video-modal');
            const videoImage = result.payload.images.poster.src;
            const videoId = result.payload.id;

            SCVideo.buildVideoPreviewImage(videoImage, videoId).then(attributes => {
              elementCreator.createAndInsert(editor, 'img', attributes);
              brightcoveUtils.addBrightcovePreviewHandlers(editor.element.$, openBrightcovePreviewAction);
            });
          } else {
            log.error('Unexpected modal result when trying to insert video. Result was:', result);
          }
        },
      },
    });
  }

  static buildVideoPreviewImage(originalSrc: any, videoId: any) {
    const isBeingLoadedFromMediaLibrary = originalSrc.indexOf(SSAPI_PROXY_BASE_URL) === 0;
    const crossOrigin = isBeingLoadedFromMediaLibrary ? CrossOrigin.USE_CREDENTIALS : CrossOrigin.ANONYMOUS;
    const imageLoader = new ImageLoader({ crossOrigin });

    return (
      ImageStyleUtil.addPlayIcon(originalSrc, imageLoader)
        // If we succeed in adding the play icon overlay to the image, insert the result
        // into the editor.
        //
        // Note: the ORIGINAL_SRC attribute is used when the article is saved, in order
        // to be able to swap the image source back to the version that doesn't have the
        // play icon flattened into it.
        .then(videoImageWithPlayIcon => {
          // eslint-disable-line arrow-body-style
          return {
            src: videoImageWithPlayIcon,
            [MetaAttributes.VIDEO_ID]: videoId,
            [MetaAttributes.ORIGINAL_SRC]: originalSrc,
          };
        })

        // If adding the play icon failed for some reason, just display the normal image.
        .catch(err => {
          log.warn('Failed to add play icon to video preview image', err);
          return {
            src: originalSrc,
            [MetaAttributes.VIDEO_ID]: videoId,
          };
        })
    );
  }
}
