import md5 from 'md5';
import { ReactElement } from 'react';
import { MessageValue } from 'react-intl';
import { createSelector as s } from 'reselect';

import {
  createByIdKeySelector,
  createKeySelector,
  createNonNullableKeySelector,
  createNullableKeySelector,
} from 'state/common/selectorFactoriesTyped';
import { UserFlags, LOCALES, DEFAULT_LOCALE } from 'state/user/userState';

import { GLOBAL_PUBLISHER_NAME } from 'config/constants';
import { getMessageBody } from 'utils/intlMessages/intlMessages';
import * as sentryServerLogging from 'utils/logging/sentryServerLoggingUtil';

import { Claim, SecureAction } from 'types/permissions';
import { Publisher, PublisherID, PublisherType } from 'types/publishers';
import { State } from 'types/rootState';

export const getUserState = (state: State) => state?.user || null;
const getActiveLocaleState = createNullableKeySelector(getUserState, 'locale');

export const getActiveLocaleId = createNonNullableKeySelector(getActiveLocaleState, 'localeId', DEFAULT_LOCALE.abbr);
export const getActiveLocale = s(
  getActiveLocaleId,
  localeId => LOCALES.find(locale => locale.abbr === localeId) || DEFAULT_LOCALE
);

export const getUserPreferences = createKeySelector(getUserState, 'preferences', {
  hasSeenNewUserModalInSession: false,
  doNotShowNewUserModalAgain: false,
});

export const getPrimaryLanguageMessage = s(getActiveLocaleId, async primaryLanguage => {
  const normalizedPrimaryLanguage = primaryLanguage.toLowerCase();
  return async (message: ReactElement, params: { [key: string]: MessageValue }) => {
    return getMessageBody(message, params, normalizedPrimaryLanguage);
  };
});

export const getUserInfo = createNullableKeySelector(getUserState, 'info');
export const getUserBitmojiAvatarId = createNullableKeySelector(getUserInfo, 'bitmojiAvatarId');
export const isRecentlyCreatedUser = createKeySelector(getUserInfo, 'isRecentlyCreatedUser', false);
export const getUserInfoAge = createKeySelector(getUserState, 'infoAge', 0);
export const getUsername = createKeySelector(getUserInfo, 'username', '');
export const getUserFlags = createKeySelector(getUserInfo, 'userFlags', []);
export const getUserFlagEnabled = (state: State) => (flag: UserFlags) => {
  return getUserFlags(state).indexOf(flag) !== -1;
};
export const getPublishers = createKeySelector(getUserState, 'publishers', []);
export const getLastUpdatedPublishersDate = createKeySelector(getUserState, 'lastUpdatedPublishersDate', 0);
export const getGroupedPublishers = s(getPublishers, publishers => {
  const filteredPublishers = publishers.filter(publisher => !!publisher.hostUsername);
  const groups: { [key in PublisherType]: Publisher[] } = {
    [PublisherType.TEST]: [],
    [PublisherType.NORMAL]: [],
  };

  return filteredPublishers.reduce((groupMap, publisher) => {
    groupMap[publisher.type].push(publisher);
    return groupMap;
  }, groups);
});
export const userId = createNullableKeySelector(getUserInfo, 'userId', null);
export const getHashedUserId = s(userId, id => (id === null ? null : md5(id)));
export const getPublishersByIdAll = createKeySelector(getUserState, 'publishersById', {});
export const getPublisherById = createByIdKeySelector(getPublishersByIdAll, null);
export const getResourceRoles = createKeySelector(getUserInfo, 'resourceRoles', []);
export const getSnapchatEmployee = createKeySelector(getUserInfo, 'snapchatEmployee', false);
export const getActivePublisherId = createKeySelector(getUserState, 'activePublisherId', null);
export const getActiveCreator = createKeySelector(getUserState, 'activeCreator', null);
export const getActiveCreatorHostUsername = s(getActiveCreator, activeCreator => activeCreator?.hostUsername);
export const getActiveCreatorHostUserId = s(getActiveCreator, activeCreator => activeCreator?.hostUserId);
export const getSelectedAdAccountId = createKeySelector(getUserState, 'selectedAdAccountId', null);
export const getUseUnifiedLoginOverride = createKeySelector(getUserState, 'useUnifiedLogin', false);
const placeholderPublisher = {
  id: null,
  businessProfileId: null,
  primaryColorRgb: [255, 255, 255],
  horizontalIconId: '',
  publisherName: '',
  mutablePublisherName: '',
};
export const getActivePublisher = s(getPublisherById, getActivePublisherId, (getPublisherByIdFn, activePublisherId) => {
  return activePublisherId ? getPublisherByIdFn(activePublisherId) : placeholderPublisher;
});
export const getActiveCreatorBusinessProfileId = createKeySelector(getActiveCreator, 'businessProfileId', '');
// If you want to decide whether to show ad controls or markers in the UI, consider instead using
// adMarkersSelectors.getShouldShowAdMarkers(), which will consider the ad takeover state of the current story.
export const getAdControlsEnabled = createKeySelector(getUserState, 'adControlsEnabled', false);
export const getShowTilesEnabled = createKeySelector(getUserState, 'showTilesEnabled', true);

export const hasClaimForPublisher = s(
  getResourceRoles,
  resourceRoles => (publisherId: PublisherID | null, claim: Claim, needsExplicitClaim: boolean = false) => {
    return resourceRoles
      .filter(({ resource }) => {
        // We don't allow claims with global scope if explicit claim is needed
        const hasAllowedGlobalClaim = resource.includes(GLOBAL_PUBLISHER_NAME) && !needsExplicitClaim;
        return hasAllowedGlobalClaim || (publisherId && resource.includes(publisherId.toString()));
      })
      .some(({ claims, explicit }) => {
        // if we need explicit claim, we only check the roles that user has been assigned explicitly
        const allowedRole = !needsExplicitClaim || explicit;
        return allowedRole && claims.includes(claim);
      });
  }
);
export const hasSecureActionForCreator = s(
  getResourceRoles,
  resourceRoles => (identifier: string, secureAction: SecureAction) => {
    return (
      resourceRoles
        .filter(({ resource }) => identifier && resource.includes(identifier))
        // TODO(gulsum): Secure action for global roles is not supported yet
        .some(({ allowedActions }) => {
          return allowedActions.includes(secureAction);
        })
    );
  }
);

export const createActiveCreatorSecureActionChecker = s(
  hasSecureActionForCreator,
  getActiveCreatorHostUserId,
  (hasSecureActionForCreatorFn, activeHostUserId) => {
    return (secureAction: SecureAction) => {
      return hasSecureActionForCreatorFn(activeHostUserId || '', secureAction);
    };
  }
);

export const hasSecureActionForActiveCreator = (state: State, secureAction: SecureAction) =>
  createActiveCreatorSecureActionChecker(state)(secureAction);

export const createActivePublisherClaimChecker = s(
  hasClaimForPublisher,
  getActivePublisherId,
  (hasClaimForPublisherFn, activePublisherId) => {
    return (claim: Claim) => {
      return hasClaimForPublisherFn(activePublisherId, claim, false);
    };
  }
);
export const hasClaimForActivePublisher = (state: State, claim: Claim) =>
  createActivePublisherClaimChecker(state)(claim);

export const createActivePublisherExplicitClaimChecker = s(
  hasClaimForPublisher,
  getActivePublisherId,
  (hasClaimForPublisherFn, activePublisherId) => {
    return (claim: Claim) => {
      return hasClaimForPublisherFn(activePublisherId, claim, true);
    };
  }
);
export const hasExplicitClaimForActivePublisher = (state: State, claim: Claim) =>
  createActivePublisherExplicitClaimChecker(state)(claim);

export const isCurationSnapDownloader = (state: State) =>
  hasExplicitClaimForActivePublisher(state, Claim.CURATION_SNAP_DOWNLOADER);

export const getAllPublishersForWhichUserHasClaim = s(
  hasClaimForPublisher,
  getPublishersByIdAll,
  (hasClaimForPublisherFn, publishersMap) => (claim: Claim) => {
    return Object.values(publishersMap).filter(publisher => hasClaimForPublisherFn(publisher.id, claim));
  }
);

export const hasClaimForAtLeastOnePublisher = s(
  hasClaimForPublisher,
  getPublishersByIdAll,
  (hasClaimForPublisherFn, publishersMap) => {
    return (claim: Claim) => {
      return Object.values(publishersMap).some(publisher => hasClaimForPublisherFn(publisher.id, claim, false));
    };
  }
);

export const hasSnapchatEmployeePermissions = s(getSnapchatEmployee, snapchatEmployee => {
  return snapchatEmployee;
});

// @see 'Entity Owners' on https://apidocs-dot-rich-snap-platform-dev.appspot.com/
export const getActivePublisherIdAsString = s(
  getActivePublisherId,
  activePublisherId => (activePublisherId && activePublisherId.toString()) || null
);

export const getLastViewedReviseFilter = createKeySelector(getUserState, 'lastViewedReviseFilter', {});
export const getLastViewedReviseFilterByPublisherId = createByIdKeySelector(getLastViewedReviseFilter, 0);
sentryServerLogging.setProvider('userState', getUserState);

export const isHostUserEnabledForPublisher = s(hasClaimForPublisher, hasClaimFn => {
  return (publisherId: PublisherID | null) => hasClaimFn(publisherId, Claim.PUBLISHER_HOST_USER_PROFILE_EDITOR);
});

export const isGlobalRoleUser = s(getResourceRoles, roles =>
  roles?.some(role => role.resource.includes(GLOBAL_PUBLISHER_NAME))
);

export const isActiveCreatorPublisher = s(getActiveCreator, creator => !!creator?.publisherId);
