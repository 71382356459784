// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'file... Remove this comment to see the full error message
import filesize from 'filesize';

import { VIDEO_UPLOAD_ANALYTICS } from 'config/constants';
import makeIntlMessage from 'utils/intlMessages/intlMessages';
import type { IntlMessage } from 'utils/intlMessages/intlMessages';

import { getFileType } from './fileUtils';

export const SIZE_ERROR = 'SizeError';

export function validateFile(file: File, options: any = {}, longFormAnalyticsFn: any = () => {}) {
  longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_FILE);

  if (!file || !(file instanceof Blob)) {
    longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_FILE_INVALID);
    throw new Error('The `file` argument is required and must be a Blob instance.');
  }

  validateFileType(file, options, longFormAnalyticsFn);
  validateFileSize(file, options, longFormAnalyticsFn);
}

function validateFileType(file: any, options: any, longFormAnalyticsFn: any) {
  longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_FILE_TYPE);
  const fileType = getFileType(file);

  if (!fileType || (options.requiredFileType && fileType !== options.requiredFileType)) {
    longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_FILE_TYPE_INVALID);
    throw new Error(`Invalid file of type '${String(fileType)}' supplied.`);
  }
}

function validateFileSize(file: any, options: any, longFormAnalyticsFn: any) {
  longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_FILE_SIZE);

  if (!file.size) {
    longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_FILE_SIZE_INVALID_PROPERTY);
    throw new Error('File is missing `size` property.');
  }

  if (options.maxSizeBytes && file.size > options.maxSizeBytes) {
    longFormAnalyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_FILE_SIZE_INVALID_SIZE);
    const fileSize = filesize(file.size);
    const maxFileSize = filesize(options.maxSizeBytes);
    const error: Error & {
      intlError?: IntlMessage;
    } = new Error(`File size of ${fileSize} exceeds the maximum size of ${maxFileSize}`);
    error.name = SIZE_ERROR;
    error.intlError = makeIntlMessage('error-file-too-large', { fileSize, maxFileSize });
    throw error;
  }
}
