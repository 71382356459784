// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { POLL_TYPE } from '@snapchat/web-attachments/lib/polls/pollConstants';
import clone from 'clone';
import invariant from 'invariant';
import is from 'is_js';
import _ from 'lodash';
import { valuesOf } from 'normalizr';
import type { Dispatch } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import { fetchEditionBuildStatus } from 'state/buildStatus/actions/buildStatusActions';
import { isSnapStatusIncompleteOrWithError } from 'state/buildStatus/schema/buildStatusHelpers';
import { getDiscoverSnapBuildStatus } from 'state/buildStatus/selectors/buildStatusSelectors';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as featuresSelectors from 'state/features/selectors/featuresSelectors';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getAssignToAllTags } from 'state/snaps/schema/snapEntityHelpers';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import {
  generateSnapTileIds,
  generateSnapTileIdsMultiple,
  removeSnapTileIds,
  removeTileIdsFromSnap,
} from 'state/tiles/schema/tilesIdUtils';
import { withTransaction } from 'state/transactions/actions/transactionsActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { buildHashForExistingKeys, buildShallowDiff } from '../../../utils/apis/partialUpdateUtil';
import type { DoneAction, MetaType } from '../../apiMiddleware/actions/apiMiddlewareActions';
import { createCallAction } from '../../apiMiddleware/actions/apiMiddlewareActions';
import { createPromiseAction } from '../../promiseMiddleware/actions/promiseMiddlewareActions';
import * as snapEndpointRegistry from '../endpoints/snapEndpointRegistry';
import { richSnapSchema } from '../schema/snapsSchema';

import type { CallToActionOptionsEnum } from 'config/constants';
import {
  BuildType,
  CallToActionOptions,
  CropPosition,
  EndpointName,
  ErrorType,
  TransactionType,
} from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { EditionID } from 'src/types/editionID';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import * as scsAPI from 'utils/apis/scsAPI';
import { assertArg, assertState } from 'utils/assertionUtils';
import { apiErrorHandler, isApiError } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import {
  clearInfoMessage,
  showInfoMessage,
  InfoContext,
  infoMessageHandler,
} from 'utils/errors/infoMessage/infoMessageUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { serializePromises } from 'utils/promiseUtils';

import { buildDecoratedSnaps, hasPropertiesForEndpoint, splitByEndpoint } from './snapDecoration';
import { buildNormalizedPropertiesForEndpoint, extractNonSnapEntities } from './snapNormalization';

import {
  BUILD_ASSET,
  BUILD_SNAP,
  CREATE_SNAP,
  DELETE_SNAP,
  LOAD_SNAP,
  LOAD_SNAPS,
  MARK_SAVE_AS_PENDING,
  SAVE_SNAP,
  SET_SNAP_PROPERTIES,
  SET_SNAP_PROPERTIES_AND_SAVE,
} from 'types/actions/snapsActionsTypes';
import type { AssetID } from 'types/assets';
import { assertAssetId } from 'types/assets';
import type { SnapId } from 'types/common';
import { assertSnapId, isSnapIdType } from 'types/common';
import type { Endpoint } from 'types/endpoint';
import type { PollType } from 'types/polls';
import type { GetState } from 'types/redux';
import { BottomSnap, BottomSnapType, SnapRelationship, SnapType } from 'types/snaps';
import type { NormalizedSnap, TagsMap } from 'types/snaps';

type CreateSnapAction = {
  type: typeof CREATE_SNAP;
  meta?: MetaType;
};
const RICHSNAP_TYPE_TO_CALL_TO_ACTION_MAP: {
  [key in BottomSnapType]: CallToActionOptionsEnum;
} = {
  [BottomSnapType.ARTICLE]: CallToActionOptions.READ,
  [BottomSnapType.LONGFORM_VIDEO]: CallToActionOptions.WATCH,
  [BottomSnapType.REMOTE_WEB]: CallToActionOptions.VIEW,
  // currently all camera attachments send to our story
  [BottomSnapType.CAMERA_ATTACHMENT]: CallToActionOptions.TRY_LENS,
  [BottomSnapType.POLL]: CallToActionOptions.BLANK,
  [BottomSnapType.SUBSCRIBE]: CallToActionOptions.BLANK,
};
// Testing the CTA override for one day with UK publisher.
// We are aware, this is not translated to all languages. https://jira.sc-corp.net/browse/PUB-2022
const CALL_TO_ACTION_OVERRIDE: {
  [key in BottomSnapType]: CallToActionOptionsEnum | null;
} = {
  [SnapType.REMOTE_WEB]: CallToActionOptions.READ,
  [BottomSnapType.ARTICLE]: null,
  [BottomSnapType.LONGFORM_VIDEO]: null,
  [BottomSnapType.CAMERA_ATTACHMENT]: null,
  [BottomSnapType.POLL]: null,
  [BottomSnapType.SUBSCRIBE]: null,
};
const RICHSNAP_POLL_TYPE_TO_CALL_TO_ACTION_MAP: {
  [key in PollType]: CallToActionOptionsEnum;
} = {
  [POLL_TYPE.POLL]: CallToActionOptions.POLL,
  [POLL_TYPE.VOTE]: CallToActionOptions.VOTE,
  [POLL_TYPE.FACTUAL_QUESTION]: CallToActionOptions.TAKE_QUIZ,
  [POLL_TYPE.OPEN_QUESTION]: CallToActionOptions.TAKE_QUIZ,
};
// Default set of properties used when creating a new Rich Snap entity
const DEFAULT_RICH_SNAP_PROPERTIES = {
  cropPosition: CropPosition.MIDDLE,
};
// Properties that should not be sent to the server when saving a snap.
const NON_SAVING_PROPERTIES = [
  // This property is read only because changes to snap relations are
  // instead communicated to the API via `relatedSnapIds`. This avoids
  // sending full data for related snaps when a parent snap is changed.
  'relatedSnaps',
];
type SnapBuilderAction = (c: Dispatch, b: GetState, a: {}) => Promise<{}>;
// Map of type-specific builder actions that can be registered for any snap type.
// This gives different parts of the codebase chance to augment the set of properties
// that is assigned to a newly created snap. For example, in the case of articles,
// the CSS and JS attachments need to be added.
const snapBuilderActions: {
  [key: string]: SnapBuilderAction;
} = {};
const noopSnapBuilderAction: SnapBuilderAction = (dispatch: Dispatch, getState: GetState, snap: {}) =>
  Promise.resolve(snap);
export function registerSnapBuilderAction(type: string, actionCreator: SnapBuilderAction) {
  snapBuilderActions[type] = actionCreator;
}
export function getSnapBuilderAction(type: string) {
  return snapBuilderActions[type] || noopSnapBuilderAction;
}
export function createSnap(
  type: SnapType,
  properties: {} = {},
  options: {} = {}
): (b: Dispatch, a: GetState) => Promise<DoneAction<CreateSnapAction>> {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  return (dispatch: Dispatch, getState: GetState): Promise<DoneAction<CreateSnapAction>> => {
    const entityOwner = userSelectors.getActivePublisherIdAsString(getState());
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(entityOwner).is.string();
    const snap = {
      ...clone(DEFAULT_RICH_SNAP_PROPERTIES),
      ...properties,
      type,
      entityOwner,
      shareOption: publishersSelectors.getActivePublisherDefaultShareOption(getState()),
    };
    const typeSpecificBuilder: SnapBuilderAction = getSnapBuilderAction(type);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'Promise<DoneAction<CreateSnapAction> | CallA... Remove this comment to see the full error message
    return typeSpecificBuilder(dispatch, getState, snap).then((finalSnap: {}) => {
      const createSnapAction = {
        type: CREATE_SNAP,
        meta: {
          schema: richSnapSchema,
        },
      };
      const callAction = createCallAction(createSnapAction, {
        endpoint: scsAPI.richSnap.snap.new({ autoBuild: true, ...options }),
        method: 'post',
        body: finalSnap,
        preprocessor: removeSnapTileIds,
        finalizer: generateSnapTileIds,
      });
      return dispatch(callAction);
    });
  };
}
export function deleteSnap({ snapId }: { snapId: SnapId }) {
  assertSnapId(snapId);
  return {
    type: DELETE_SNAP,
    params: { snapId },
  };
}
export function deleteWholeSnap({ snapId }: { snapId: SnapId }) {
  assertSnapId(snapId);
  return (dispatch: Dispatch, getState: GetState) => {
    const snap = snapsSelectors.getSnapById(getState())(snapId);
    invariant(snap, `could not find snap [${snapId}]`);
    const wholeSnapIds = snapEntityHelpers.getAllSnapIdsForSnap(snap);
    return Promise.all(
      wholeSnapIds.map(wholeSnapId => {
        return dispatch(deleteSnap({ snapId: wholeSnapId }));
      })
    );
  };
}
const noBailout = () => false;
type LoadSnapResult = {
  payload: {
    entities: {
      richSnap: {};
    };
  };
};
/*
 * Loads data for the provided snap id from each of the registered snap endpoints,
 * and returns the result as a decorated snap. The resulting snap object will contain
 * the base Rich Snap properties at the root level, and decorator properties returned
 * by other endpoints nested within a `decorations` object. For example:
 *
 *   {
 *     id: 42,
 *     type: SnapType.IMAGE,
 *     name: 'foo',
 *     decorations: {
 *       discover: {
 *         id: 42,
 *         tiles: ['bar'],
 *       },
 *     },
 *   }
 *
 * Some notes on the implementation:
 *
 *   - All endpoint calls are made in parallel, so loading from multiple endpoints
 *     has little effect on latency.
 *
 *   - The LOAD_SNAP action returns a promise that resolves when all calls have completed.
 *     If one or more of the calls fails, the promise will reject.
 *
 *   - Internally, each endpoint call is modelled as a separate [CALL_API] action,
 *     where the type of the action will be `LOAD_SNAP/${endpointName}`. However, these
 *     actions are really an implementation detail and should not really be observed by
 *     other parts of the application. Instead observe the overall LOAD_SNAP action.
 *
 *   - Endpoints are registered with the snapEndpointRegistry in snapEndpointConfig.js.
 */
export function loadSnap({ snapId, bailout = noBailout }: { bailout?: () => boolean; snapId: SnapId }) {
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(bailout).is.function();
  return (dispatch: Dispatch, getState: GetState) => {
    const endpoints = snapEndpointRegistry.getAllSnapEndpoints();
    // Load snaps from each endpoint separately. These will later be combined
    // to build a decorated snap containing the data from each endpoint.
    const loadSnapPromises = endpoints.map(endpointInfo =>
      dispatch(loadSnapFromEndpoint(snapId, endpointInfo, bailout))
    );
    const promise = Promise.all(loadSnapPromises)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(results: LoadSnapResult[]) => {... Remove this comment to see the full error message
      .then((results: LoadSnapResult[]) => {
        // Results can be returned empty in the case where the `bailout`
        // function returned true.
        if (results.length > 0 && results[0] !== undefined) {
          return {
            entities: {
              richSnap: {
                ...buildDecoratedSnaps(results, endpoints),
                ...extractRelatedSnaps([snapId], results),
              },
              ...extractAllNonSnapEntities(results),
            },
          };
        }
        return null;
      });
    const action = {
      type: LOAD_SNAP,
      params: { snapId },
      bailout,
    };
    return (dispatch(createPromiseAction(action, promise)) as any).catch(
      apiErrorHandler(dispatch, ErrorContexts.LOAD_SNAP)
    );
  };
}
function loadSnapFromEndpoint(snapId: any, endpointInfo: any, bailout: any) {
  const loadAction = {
    type: `${LOAD_SNAP}/${endpointInfo.name}`,
    meta: {
      schema: endpointInfo.snapSchema,
    },
    bailout,
    params: { snapId },
  };
  return createCallAction(loadAction, {
    endpoint: endpointInfo.urlBuilder({ snapId }),
    preprocessor: removeSnapTileIds,
    finalizer: generateSnapTileIds,
  });
}
// Note that related snaps aren't currently decorated (i.e. we only decorate the topsnap)
function extractRelatedSnaps(snapIds: Array<SnapId>, results: LoadSnapResult[]) {
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  return _.omit(results[0].payload.entities.richSnap, snapIds as any);
}
function loadMultipleSnapsFromEndpoint(snapIds: any, endpointInfo: any, bailout: any) {
  const loadAction = {
    type: `${LOAD_SNAPS}/${endpointInfo.name}`,
    meta: {
      schema: valuesOf(endpointInfo.snapSchema),
    },
    params: { snapIds },
    bailout,
  };
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; meta: { schema: ... Remove this comment to see the full error message
  return createCallAction(loadAction, {
    endpoint: endpointInfo.urlBuilderMultiple(),
    finalizer: generateSnapTileIdsMultiple,
    method: 'post',
    body: snapIds,
  });
}
function extractAllNonSnapEntities(results: any) {
  const allNonSnapEntities = {};
  results.forEach((result: any) => {
    const entities = _.get(result, ['payload', 'entities']) || {};
    Object.assign(allNonSnapEntities, extractNonSnapEntities(entities));
  });
  return allNonSnapEntities;
}
export function triggerAssetBuild({ assetId, buildTypeId }: { assetId: string; buildTypeId: string }) {
  assertAssetId(assetId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(buildTypeId).is.inValues(BuildType);
  return {
    type: BUILD_ASSET,
    meta: {
      [CALL_API]: {
        method: 'post',
        endpoint: mediaLibraryAPI.asset.build({ assetId, buildTypeId }),
      },
    },
    params: { assetId, buildTypeId },
  };
}
export function forceSnapRebuild({ snapId }: { snapId: SnapId }) {
  assertSnapId(snapId);
  const forceRebuild = true;
  return {
    type: BUILD_SNAP,
    meta: {
      [CALL_API]: {
        method: 'post',
        endpoint: scsAPI.richSnap.snap.build.trigger({ snapId, forceRebuild }),
      },
    },
    params: { snapId },
  };
}
const DEFAULT_MAX_AGE_FOR_LOADING_MILLIS = 10000;
export function loadSnapIfRequired({
  snapId,
  maxAgeMillis = DEFAULT_MAX_AGE_FOR_LOADING_MILLIS,
}: {
  maxAgeMillis?: number;
  snapId: SnapId;
}) {
  const hasDecorations = snapEndpointRegistry.hasDecorationEndpoints();
  const bailout = (state: any) => {
    const lastUpdated = snapsSelectors.getLastUpdatedById(state)(snapId) || 0;
    const updatedRecently = Date.now() - lastUpdated < maxAgeMillis;
    const snap = snapsSelectors.getNormalizedSnapById(state)(snapId);
    const decorationsReady = hasDecorations ? _.has(snap, ['decorations']) : true;
    return updatedRecently && decorationsReady;
  };
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(state: any) => boolean' is not assignable t... Remove this comment to see the full error message
  return loadSnap({ snapId, bailout });
}
export function setSnapProperties(
  snapId: SnapId,
  properties: {},
  endpointInfo: Endpoint = snapEndpointRegistry.getDefaultSnapEndpoint()
) {
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.not.empty();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointInfo).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointInfo.name).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointInfo.snapSchema).is.object();
  // To handle cases where a property is being set in a Normalized sub-entity (e.g. Tiles),
  // we need to apply Normalizr to the properties object before continuing.
  const normalized = buildNormalizedPropertiesForEndpoint(snapId, properties, endpointInfo);
  const snapSchemaName = endpointInfo.snapSchema.getKey();
  const snapEntity = normalized.entities[snapSchemaName][snapId];
  const nonSnapEntities = extractNonSnapEntities(normalized.entities);
  const wrapper = endpointInfo.isDefault
    ? snapEntity
    : {
        decorations: {
          [endpointInfo.name]: snapEntity,
        },
      };
  return {
    type: SET_SNAP_PROPERTIES,
    params: {
      snapId,
    },
    payload: {
      entities: {
        richSnap: {
          [snapId]: wrapper,
        },
        ...nonSnapEntities,
      },
    },
  };
}
type URLParams = {
  autoBuild: boolean;
  hash?: string;
  snapId: SnapId;
};
// NOTE: This is generally not called directly - instead call setSnapPropertiesAndSave(),
//       which will handle the diffSnap and hash creation, as well as working out whether
//       the snap needs to be saved to multiple endpoints.
export function saveSnap(
  {
    snapId,
  }: {
    snapId: SnapId;
  },
  diffSnap: {},
  hash: string | null,
  endpointInfo: Endpoint
) {
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(diffSnap).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(hash).is.string.or.is.null();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(endpointInfo).is.object();
  if (endpointInfo.supportsPartialUpdates && is.not.string(hash)) {
    throw new Error('hash is required for endpoints that support partial saves');
  } else if (!endpointInfo.supportsPartialUpdates && is.string(hash)) {
    throw new Error('hash is not allowed for endpoints that do not support partial saves');
  }
  const hasNonSavingProperties = NON_SAVING_PROPERTIES.some(element => {
    return element in diffSnap;
  });
  if (hasNonSavingProperties) {
    throw new Error('diff snap should not contain non-saving properties');
  }
  return (dispatch: Dispatch) => {
    const urlParams: URLParams = {
      snapId,
      autoBuild: true,
    };
    if (hash) {
      urlParams.hash = hash;
    }
    const saveAction = {
      type: `${SAVE_SNAP}/${endpointInfo.name}`,
      meta: {
        schema: endpointInfo.snapSchema,
      },
      params: { snapId },
    };
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ type: string; meta: { schema: ... Remove this comment to see the full error message
    const callAction = createCallAction(saveAction, {
      method: 'put',
      endpoint: endpointInfo.urlBuilder(urlParams),
      body: diffSnap,
      preprocessor: removeSnapTileIds,
      finalizer: generateSnapTileIds,
    });
    return (dispatch(callAction) as any).then(
      (saveSnapAction: {
        payload?: {
          entities: {
            richSnap: any;
          };
        };
      }) => {
        if (endpointInfo.name === EndpointName.RICH_SNAP) {
          // The endpoint returns both top and bottom snap regardless of whether only one or the other was changed
          // This can cause lost of data on related snaps. Just return the information for the snap that was actually changed
          if (_.keys(_.get(saveSnapAction, ['payload', 'entities', EndpointName.RICH_SNAP])).length > 1) {
            const { payload } = saveSnapAction;
            if (
              payload &&
              payload.entities &&
              typeof payload.entities === 'object' &&
              payload.entities.richSnap &&
              typeof payload.entities.richSnap === 'object'
            ) {
              const { entities } = payload;
              entities.richSnap = {
                // eslint-disable-line no-param-reassign
                [snapId]: payload.entities.richSnap[String(snapId)],
              };
            }
          }
        }
        return saveSnapAction;
      }
    );
  };
}
/*
 * Saves the snap to one or more of the registered snap endpoints, depending upon
 * which properties were modified. If only Rich Snap data was modified, only the
 * Rich Snap endpoint will be called; if only Discover data was modified, only
 * the Discover endpoint will be called; and if both sets of data are modified,
 * both endpoints will be called.
 *
 * For example, a call like:
 *
 *   dispatch(snapsActions.setSnapPropertiesAndSave({ snapId: 42 }, {
 *     name: 'foo',
 *     decorations: {
 *       discover: {
 *         tiles: ['bar'],
 *       },
 *     },
 *   }));
 *
 * Will result in calls to both Rich Snap API and Discover API.
 *
 * Some notes on the implementation:
 *
 *   - Requests are run in series – this is done so that our transaction system can roll
 *     back just the data for a single endpoint if that call fails. However, as in the
 *     majority case we're only saving data to a single endpoint, calling in series
 *     doesn't really add any additional latency.
 *
 *   - The SET_SNAP_PROPERTIES_AND_SAVE action returns a promise that resolves when all
 *     calls have completed. If one of the calls fails, the promise will reject and no
 *     further calls will be attempted.
 *
 *   - Internally, each endpoint call is modelled as a separate [CALL_API] action,
 *     where the type of the action will be `SAVE_SNAP/${endpointName}`. However, these
 *     actions are really an implementation detail and should not really be observed by
 *     other parts of the application. Instead observe the overall SET_SNAP_PROPERTIES_AND_SAVE
 *     action.
 *
 *   - Endpoints are registered with the snapEndpointRegistry in snapEndpointConfig.js.
 */
type PropertiesWithVideoId = {
  videoAssetId: AssetID;
};
export function setSnapPropertiesAndSave(
  {
    snapId,
  }: {
    snapId: SnapId;
  },
  properties: PropertiesWithVideoId | {}
) {
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.not.empty();
  return (dispatch: Dispatch, getState: GetState) => {
    const endpoints = snapEndpointRegistry.getAllSnapEndpoints();
    infoMessageHandler(dispatch, InfoContext.SAVING);
    // We need to mark show snaps as seekable so they are transcoded to allow seeking
    const state = getState();
    if ((properties as any).videoAssetId) {
      const isShow = publishersSelectors.activePublisherIsShow(state);
      if (isShow) {
        properties = { ...properties, seekable: true }; // eslint-disable-line no-param-reassign
      }
    }
    // Create a list of transactions, one for each endpoint, that will try to apply
    // the properties for that endpoint and then save the result.
    const transactions = endpoints.map(endpointInfo =>
      setSnapPropertiesAndSaveForEndpoint(snapId, properties, endpointInfo)
    );
    // Run each of the transactions in series. This ensures that if any of the save
    // calls fail, the subsequent rollback will only affect the properties of the
    // endpoint that failed.
    //
    // Note that in almost all cases we will only actually save to a single endpoint
    // at a time, so this approach has practically no effect on latency.
    const serializedTransactions = serializePromises(transactions, dispatch, getState);
    const promise = serializedTransactions
      // As each save is performed in a transaction, we will get back an array of
      // `COMPLETE_TRANSACTION` actions. Each of these has a finalResult property
      // that contains the result of the last then() call within the transaction.
      .then((transactionActions: any) => transactionActions.map((action: any) => action.payload.finalResult))
      // We can now combine the results together into a decorated snap
      .then((results: any) => {
        return {
          entities: {
            richSnap: {
              ...buildDecoratedSnaps(results, endpoints),
            },
            ...extractAllNonSnapEntities(results),
          },
        };
      })
      .then((decoratedSnap: any) => {
        // If it's a bottom snap, we use the topsnap id, otherwise use the given snap id
        const snap = snapsSelectors.getSnapById(getState())(snapId);
        const topsnapId = snap?.relatedSnapIds?.[SnapRelationship.TOP] || snapId;

        const buildStatus = getDiscoverSnapBuildStatus(getState())(topsnapId);
        if (!isSnapStatusIncompleteOrWithError(buildStatus)) {
          return decoratedSnap;
        }
        const edition = editionsSelectors.getEditionBySnapId(getState())(topsnapId);
        if (!edition) {
          return decoratedSnap;
        }
        // We skip the bailout cause we need to update the edition status right away
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        dispatch(fetchEditionBuildStatus({ editionId: edition.id, noBailout: true }));
        return decoratedSnap;
      });
    const action = {
      type: SET_SNAP_PROPERTIES_AND_SAVE,
      params: { snapId },
    };
    return (dispatch(createPromiseAction(action, promise)) as any)
      .then(
        clearInfoMessage(getState, dispatch, InfoContext.SAVING),
        clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
      )
      .catch(apiErrorHandler(dispatch, ErrorContexts.SAVE_SNAP));
  };
}
function setSnapPropertiesAndSaveForEndpoint(snapId: any, properties: any, endpointInfo: any) {
  return withTransaction(
    TransactionType.SET_SNAP_PROPERTY,
    { [richSnapSchema.getKey()]: snapId },
    (dispatch: any, getState: GetState) => {
      const snap = snapsSelectors.getSnapById(getState())(snapId);
      const endpointName = endpointInfo.name;
      // If we don't have any modified properties for this endpoint, we can
      // return early here rather than making a pointless request to the backend.
      if (!hasPropertiesForEndpoint(properties, endpointName)) {
        return Promise.resolve(getExistingSnapDataForEndpoint(snapId, endpointInfo, getState));
      }
      const { snapForSaving, hash } = buildSnapForSaving(snap, u(properties, {}), endpointInfo);
      return Promise.resolve()
        .then(() => dispatch(setSnapProperties(snapId, properties, endpointInfo)))
        .then(() => dispatch(saveSnap({ snapId }, snapForSaving, hash, endpointInfo)));
    },
    (dispatch: any, error: any) => {
      // withTransaction error handler
      // Api errors are treated inside saveSnap method so no need to send another notification
      if (!isApiError(error)) {
        dispatch(showInfoMessage(InfoContext.ERROR_SAVING_SNAP));
        // TODO: replace Error with custom cms hierarchy
        error.alreadyHandled = true; // eslint-disable-line no-param-reassign
      }
      throw error;
    }
  );
}
function getExistingSnapDataForEndpoint(snapId: any, endpointInfo: any, getState: GetState) {
  const snap = snapsSelectors.getNormalizedSnapById(getState())(snapId);
  invariant(snap, `could not find snap with id ${snapId}`);
  // Extract the set of snap properties that originated from this endpoint
  const snapForThisEndpoint = splitByEndpoint(snap)[endpointInfo.name];
  return {
    params: {
      snapId: snap.id,
    },
    payload: {
      entities: {
        [endpointInfo.snapSchema.getKey()]: {
          // Note that it's valid for `snapForThisEndpoint` to be null, as
          // the snap may only have been loaded from a single endpoint.
          [snap.id]: snapForThisEndpoint || {},
        },
      },
    },
  };
}
// TODO (dbelli): JSON cannot represent unordered arrays
// but in some instances we do not care if the ordering
// of an array has changed. Call out these properties so
// we can sort them before hashing. The snap service
// will do the same resulting in an unchanged hash.
const snapUnorderedArrayProperties = ['imageAssetIds', 'longformVideoAssetIds', 'attachmentIds'];
function buildSnapForSaving(existingSnap: any, updatedProperties: any, endpointInfo: any) {
  if (!endpointInfo.supportsPartialUpdates) {
    return {
      snapForSaving: buildWholeSnap(existingSnap, updatedProperties, endpointInfo.name),
      hash: null, // Hash is only required for partial updates
    };
  }
  const diffSnap = removeTileIdsFromSnap(buildDiffSnap(existingSnap, updatedProperties, endpointInfo.name));
  return {
    snapForSaving: diffSnap,
    hash: buildHash(removeTileIdsFromSnap(existingSnap), diffSnap),
  };
}
// Used for endpoints that don't support partial updates. Builds a valid snap
// payload containing the updated properties merged into the existing properties.
function buildWholeSnap(existingSnap: any, updatedProperties: any, endpointName: any) {
  invariant(existingSnap, 'existing snap is null');
  const existingPropertiesForThisEndpoint = splitByEndpoint(existingSnap)[endpointName];
  const updatedPropertiesForThisEndpoint = splitByEndpoint(updatedProperties)[endpointName];
  return {
    id: existingSnap.id,
    ...existingPropertiesForThisEndpoint,
    ...updatedPropertiesForThisEndpoint,
  };
}
// Used for endpoints that do support partial updates. Builds a valid snap payload
// containing just the data that has changed for the given endpoint.
function buildDiffSnap(existingSnap: any, updatedProperties: any, endpointName: any) {
  const updatedPropertiesForThisEndpoint = splitByEndpoint(updatedProperties)[endpointName];
  const shallowDiff = buildShallowDiff(
    existingSnap,
    updatedPropertiesForThisEndpoint,
    ['id', 'type'],
    NON_SAVING_PROPERTIES
  );
  return shallowDiff;
}
// Generates the hash used to protect against inadvertent overwrites from
// different users/tabs. The server will generate an equivalent hash on
// the backend, and reject the save if the hashes do not match.
function buildHash(existingSnap: any, diffSnap: any) {
  const hash = buildHashForExistingKeys(existingSnap, diffSnap, snapUnorderedArrayProperties, ['id', 'type']);
  return hash;
}
export function deleteInteraction(
  {
    snapId,
  }: {
    snapId: SnapId;
  },
  interactionId: SnapId,
  // @ts-expect-error ts-migrate(2741) FIXME: Property 'errorType' is missing in type '{}' but r... Remove this comment to see the full error message
  {
    errorType = ErrorType.DELETE,
  }: {
    errorType: string;
  } = {}
) {
  assertSnapId(snapId);
  assertSnapId(interactionId);
  return (dispatch: Dispatch, getState: GetState) => {
    const snap = snapsSelectors.getNormalizedSnapById(getState())(snapId);
    invariant(snap, `could not find snap with id ${snapId}`);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(snap).is.object();
    // Note that we don't need a transaction here because removeRelatedSnap()
    // uses setSnapPropertiesAndSave(), which already uses a transaction
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
    return dispatch(removeRelatedSnap(snap, interactionId));
  };
}
export function markSnapSaveAsPending(snapId: SnapId) {
  assertSnapId(snapId);
  return {
    type: MARK_SAVE_AS_PENDING,
    snapId,
  };
}
export function addInteractionAndSave(
  {
    snapId,
  }: {
    snapId: SnapId;
  },
  type: SnapType,
  snapProperties: {
    relatedSnapIds?: number[];
  }
) {
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapProperties).is.object.or.is.undefined();
  // Note that we don't need a transaction here because addRelatedSnap()
  // uses setSnapPropertiesAndSave(), which already uses a transaction
  return (dispatch: Dispatch, getState: GetState) => {
    const snap = snapsSelectors.getNormalizedSnapById(getState())(snapId);
    invariant(snap, `could not find snap with id ${snapId}`);
    const properties = { ...snapProperties };
    // @ts-expect-error ts-migrate(2740) FIXME: Type '{ [x: string]: SnapId; }' is missing the fol... Remove this comment to see the full error message
    properties.relatedSnapIds = { [SnapRelationship.TOP]: snapId };
    return (
      Promise.resolve()
        .then(() => {
          const action: (b: Dispatch, a: GetState) => Promise<DoneAction<CreateSnapAction>> = createSnap(
            type,
            properties,
            { relatedSnapId: snapId }
          );
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(b: Dispatch<AnyAction>, a: GetS... Remove this comment to see the full error message
          return dispatch(action);
        })
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(createAction: DoneAction<Create... Remove this comment to see the full error message
        .then((createAction: DoneAction<CreateSnapAction>) => {
          invariant(isSnapIdType(createAction.payload.result), 'payload contains snap ID in result');
          const newSnapId = createAction.payload.result;
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
          return dispatch(updateCallToAction(snap, newSnapId)).then(() => newSnapId);
        })
        .catch(apiErrorHandler(dispatch, ErrorContexts.SAVE_SNAP))
    );
  };
}
function updateCallToAction(normalizedSnap: NormalizedSnap, bottomSnapId: SnapId) {
  return (dispatch: any, getState: GetState) => {
    const bottomSnap = snapsSelectors.getSnapById(getState())(bottomSnapId) as BottomSnap;
    invariant(bottomSnap, `could not find snap with id ${bottomSnapId}`);
    const isCallToActionOverrideEnabled = featuresSelectors.isCallToActionOverrideEnabled(getState());
    return dispatch(
      setSnapPropertiesAndSave(
        { snapId: normalizedSnap.id },
        {
          callToAction: getCallToActionForSnap(bottomSnap, isCallToActionOverrideEnabled),
        }
      )
    );
  };
}
export function assignTagsToSnapsInStory(id: SnapId, storyId: EditionID | undefined, tagsInInput: TagsMap[]) {
  return (dispatch: any, getState: GetState) => {
    const story = storyId ? editionsSelectors.getEditionById(getState())(storyId) : null;
    invariant(story, `could not find story with id ${storyId}`);
    const snapIds = story?.snapIds;
    if (!snapIds) {
      return Promise.reject();
    }
    return Promise.all(
      snapIds
        .filter(snapId => snapId !== id)
        .map(snapId => {
          const sourceSnap = snapsSelectors.getSnapById(getState())(id);
          invariant(sourceSnap, `could not find snap with id ${id}`);
          const originalTagsOnSourceSnap = sourceSnap?.decorations.discover.tags;
          // if the tags have been edited get them from state otherwise get the from original snap
          const tagsOnSourceSnap = Object.keys(tagsInInput).length ? tagsInInput : originalTagsOnSourceSnap;
          // tags on the snap we are copying to
          const destinationSnap = snapsSelectors.getSnapById(getState())(snapId);
          const tagsOnDestinationSnap = destinationSnap?.decorations?.discover?.tags;
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'TagsMap[] | { [x: string]: strin... Remove this comment to see the full error message
          const tags = getAssignToAllTags(tagsOnSourceSnap, tagsOnDestinationSnap);
          return dispatch(
            setSnapPropertiesAndSave(
              { snapId },
              {
                decorations: {
                  discover: {
                    tags,
                  },
                },
              }
            )
          );
        })
    ).then(() => incrementCounter('assignTagsToAllSnaps', { succeeded: 'true' }));
  };
}
export function clearTagsForSnapsInStory(id: SnapId, storyId: EditionID | undefined) {
  return (dispatch: Dispatch, getState: GetState) => {
    const story = storyId ? editionsSelectors.getEditionById(getState())(storyId) : null;
    if (!story) {
      return Promise.reject();
    }
    const snapIds = story?.snapIds;
    if (!snapIds) {
      return Promise.reject();
    }
    return Promise.all(
      snapIds
        .filter(snapId => snapId !== id)
        .map(snapId => {
          return dispatch(
            // @ts-ignore
            setSnapPropertiesAndSave(
              { snapId },
              {
                decorations: {
                  discover: {
                    tags: {},
                  },
                },
              }
            )
          );
        })
    ).then(() => incrementCounter('clearTagsForSnapsInStory', { succeeded: 'true' }));
  };
}
function getCallToActionForSnap(bottomSnap: BottomSnap, isOverrideEnabled: any) {
  // differentiate between opinion polls and votes
  if (bottomSnap.type === SnapType.POLL) {
    return RICHSNAP_POLL_TYPE_TO_CALL_TO_ACTION_MAP[bottomSnap.pollType] || '';
  }
  const overriddenCallToAction: CallToActionOptionsEnum = isOverrideEnabled && CALL_TO_ACTION_OVERRIDE[bottomSnap.type];
  return overriddenCallToAction || RICHSNAP_TYPE_TO_CALL_TO_ACTION_MAP[bottomSnap.type] || '';
}
function removeRelatedSnap(normalizedSnap: NormalizedSnap, relatedSnapId: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(normalizedSnap.relatedSnaps).is.object();
  const relationshipToRemove = findRelationship(normalizedSnap, relatedSnapId);
  const newRelatedSnaps = clone(normalizedSnap.relatedSnaps);
  delete newRelatedSnaps[relationshipToRemove];
  const newRelatedSnapIds = clone(normalizedSnap.relatedSnapIds);
  delete newRelatedSnapIds[relationshipToRemove];
  return (dispatch: any, getState: GetState) =>
    dispatch(
      setSnapPropertiesAndSave(
        { snapId: normalizedSnap.id },
        {
          relatedSnaps: u.constant(newRelatedSnaps),
          relatedSnapIds: u.constant(newRelatedSnapIds),
          callToAction: null,
        }
      )
    );
}
function findRelationship(normalizedSnap: any, relatedSnapId: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(normalizedSnap).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(normalizedSnap.relatedSnapIds).is.object();
  if (normalizedSnap.relatedSnapIds[SnapRelationship.TOP] === relatedSnapId) {
    return SnapRelationship.TOP;
  }
  if (normalizedSnap.relatedSnapIds[SnapRelationship.BOTTOM] === relatedSnapId) {
    return SnapRelationship.BOTTOM;
  }
  throw new Error('Snaps are not related');
}
export function loadSnaps({ snapIds, bailout = noBailout }: { snapIds: Array<SnapId>; bailout?: () => boolean }) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapIds).is.array();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(snapIds.length).is.number.and.is.above(0);
  return (dispatch: Dispatch) => {
    const endpoints = snapEndpointRegistry.getAllSnapEndpoints();
    // Load snaps from each endpoint separately. These will later be combined
    // to build a decorated snap containing the data from each endpoint.
    const loadSnapPromises = endpoints.map(endpointInfo =>
      dispatch(loadMultipleSnapsFromEndpoint(snapIds, endpointInfo, bailout))
    );
    const promise = Promise.all(loadSnapPromises).then(results => {
      // Results can be returned empty in the case where the `bailout`
      // function returned true.
      if (results.length > 0 && results[0] !== undefined) {
        return {
          entities: {
            richSnap: {
              ...buildDecoratedSnaps(results, endpoints),
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'CallAction<ReduxAction>[]' is no... Remove this comment to see the full error message
              ...extractRelatedSnaps(snapIds, results),
            },
            ...extractAllNonSnapEntities(results),
          },
        };
      }
      return null;
    });
    const action = {
      type: LOAD_SNAPS,
      params: { snapIds },
      bailout,
    };
    return (dispatch(createPromiseAction(action, promise)) as any).catch(
      apiErrorHandler(dispatch, ErrorContexts.LOAD_SNAPS)
    );
  };
}
export function loadSnapsIfRequired({
  snapIds,
  maxAgeMillis = DEFAULT_MAX_AGE_FOR_LOADING_MILLIS,
}: {
  maxAgeMillis?: number;
  snapIds: SnapId[];
}) {
  const hasDecorations = snapEndpointRegistry.hasDecorationEndpoints();
  const bailout = (state: any) => {
    return snapIds.every(snapId => {
      const lastUpdated = snapsSelectors.getLastUpdatedById(state)(snapId) || 0;
      const updatedRecently = Date.now() - lastUpdated < maxAgeMillis;
      const snap = snapsSelectors.getNormalizedSnapById(state)(snapId);
      const decorationsReady = hasDecorations ? _.has(snap, ['decorations']) : true;
      return updatedRecently && decorationsReady;
    });
  };
  // @ts-expect-error ts-migrate(2322) FIXME: Type '(state: any) => boolean' is not assignable t... Remove this comment to see the full error message
  return loadSnaps({ snapIds, bailout });
}
