import { get } from 'lodash';

import { loadSnapPreviewIfMissing } from 'state/previews/actions/previewsActions';

import { Sequence } from 'config/constants';

import { SET_SNAP_PROPERTIES_AND_SAVE } from 'types/actions/snapsActionsTypes';

export default (on: any) => {
  on(SET_SNAP_PROPERTIES_AND_SAVE, loadSnapPreview);
};

function loadSnapPreview(action: any, dispatch: any) {
  const isDone = get(action, ['sequence']) === Sequence.DONE;

  if (!isDone) {
    return;
  }

  const snapId = get(action, ['params', 'snapId']) || null;
  if (snapId) {
    dispatch(loadSnapPreviewIfMissing(snapId));
  }
}
