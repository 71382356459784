import is from 'is_js';
import log from 'loglevel';
import ReactGA, { TimingArgs } from 'react-ga';

import { UploadPurpose } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';

import { enqueue as enqueueMetrics } from './metricsQueue';
// Tracks all actions that the user can perform on the CMS – things like 'Create Edition', 'Create Topsnap', 'Save Article' etc.;
export const GAUserActions = {
  // RichSnap Editor level actions
  RICHSNAP_EDITOR: 'richsnap-editor',

  // Used for user actions related to media
  MEDIA: 'media',

  // Story level actions
  EDITION: 'edition',

  // All analytics
  ANALYTICS: 'analytics',

  // Any publisher performed admin tasks
  PUBLISHER_ADMIN: 'publisher-admin',
};

// Metrics specific to the quality of service. Used to monitor important processes that measure the user experience
// i.e. number of errors
export const GAQoSMetrics = {
  ERROR: 'qos-error',
  GENERAL: 'qos-general',
  MEDIA: 'qos-media',
  AUTH: 'qos-auth',
};

type Label = string | {} | void;
type Value = number | string | null;

export function createActionLogger(category: string, label: Label, valueFunction: () => Value) {
  return (action: string): void => logGAEvent(category, action, label, valueFunction());
}

export function logGAEvent(category: string, action: string, label: Label, value: Value = null): void {
  if (!category) {
    log.error('Cannot log Google Analytics event without a category');
    return;
  }
  if (!action) {
    log.error('Cannot log Google Analytics event without an action');
    return;
  }

  log.info('logGAEvent', category, action, label);

  const params: any = {
    category,
    action,
  };

  if (value !== null) {
    params.value = value;
  }

  if (label) {
    params.label = is.object(label) ? JSON.stringify(label) : label;
  }

  enqueueMetrics(() => ReactGA.event(params));
  storeRecentEvent(params);
}

export function logGATiming(params: TimingArgs) {
  enqueueMetrics(() => ReactGA.timing(params));
}

export function logGAPageView(path: string) {
  enqueueMetrics(() => ReactGA.pageview(path));
}

export function getGAParamsForUploadPurpose(purpose: string, actionPrefix: string) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(purpose).is.inValues(UploadPurpose);
  const kebabCasePurpose = purpose.replace(/_/g, '-').toLowerCase();
  const action = `${kebabCasePurpose}-${actionPrefix}`;

  return {
    category: GAQoSMetrics.MEDIA,
    action,
  };
}

const MAX_RECENT_EVENTS = 50;
const recentEvents: any = [];

function storeRecentEvent(params: any) {
  recentEvents.push(params);

  if (recentEvents.length > MAX_RECENT_EVENTS) {
    recentEvents.shift();
  }
}

export function getRecentEvents() {
  return recentEvents;
}

export function clearRecentEvents() {
  recentEvents.length = 0;
}
