import { createSelector as s } from 'reselect';

import { createDynamicKeySelector, createKeySelector } from 'state/common/selectorFactories';

import { State } from 'src/types/rootState';

export default function editorStateSelectors(
  getStateSelector: (a: State) => any,
  propertyName: string,
  defaultValue: any = null
): (a: State) => any {
  const stateSelector = createKeySelector(getStateSelector, propertyName, {});
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  const getByComponentScope = createDynamicKeySelector(stateSelector);

  return s(getByComponentScope, getComponentByTypeFn => {
    return (componentScope: any, editorId: any) => {
      const componentByType = getComponentByTypeFn(componentScope) || {};
      return componentByType[editorId] || defaultValue;
    };
  });
}
