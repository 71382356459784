import { assertArg } from 'utils/assertionUtils';

import type { Dispatch, ReduxAction, ThunkAction } from 'types/redux';

type ComponentScope = string;
type EditorId = string | number;

type Params = {
  componentScope: ComponentScope;
  editorId: EditorId;
};

export const UPDATE_EDITOR_STATE = 'editorState/UPDATE_EDITOR_STATE';
export const DISCARD = 'editorState/DISCARD';

export type UpdateEditorStateAction<EditorState extends {}> = {
  params: Params;
  payload: Partial<EditorState>;
  type: typeof UPDATE_EDITOR_STATE;
};

type DiscardAction = {
  params: Params;
  type: typeof DISCARD;
};

export type Action<EditorState extends {}> = UpdateEditorStateAction<EditorState> | DiscardAction;

function createParams(componentScope: ComponentScope, editorId: EditorId): Params {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(componentScope).is.string.or.is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editorId).is.string.or.is.number();
  return {
    componentScope,
    editorId,
  };
}

export function updateEditorState(componentScope: ComponentScope, editorId: EditorId, properties: {}): ThunkAction {
  return (dispatch: Dispatch): ReduxAction => {
    return dispatch({
      type: UPDATE_EDITOR_STATE,
      params: createParams(componentScope, editorId),
      payload: {
        ...properties,
      },
    });
  };
}

export function discardData(componentScope: ComponentScope, editorId: EditorId) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: DISCARD,
      params: createParams(componentScope, editorId),
    });
  };
}
