import { omit, get, sortBy } from 'lodash';
import log from 'loglevel';
import { arrayOf } from 'normalizr';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'quer... Remove this comment to see the full error message
import queryString from 'query-string';

import { loopAction } from 'state/common/actionFactories';
import * as mediaActions from 'state/media/actions/mediaActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { publisherSchema, userSchema } from 'state/snapAdmin/schema/snapAdminSchema';
import { parseTileLogoMetadata, getTileLogoOrder } from 'state/snapAdmin/schema/tileLogoUtils';
import * as snapAdminSelectors from 'state/snapAdmin/selectors/snapAdminSelectors';
import * as stagesActions from 'state/stages/actions/stagesActions';
import { getData as getStagesData } from 'state/stages/selectors/stagesSelectors';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { FileType, UploadPurpose, DEFAULT_LOGO_COLOR, MINUTES } from 'config/constants';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { preprocessAndFinalize, preProcessTileLogoData } from 'redux/middleware/requestProcessing';
import { GetState } from 'src/types/redux';
import * as discoverAPI from 'utils/apis/discoverAPI';
import { assertArg, assertState } from 'utils/assertionUtils';
import * as browserUtils from 'utils/browserUtils';
import { extractTileLogoIdFromComponentId } from 'utils/componentUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { infoMessageHandler, clearInfoMessage, InfoContext } from 'utils/errors/infoMessage/infoMessageUtils';
import { preparePublisherForSaving } from 'utils/managePublisherUtils';
import * as blobUtils from 'utils/media/blobUtils';
import u from 'utils/safeUpdeep';
import { getNotificationBarSettings } from 'utils/settingHelper';
import { getRolesForSaving } from 'utils/userManagementUtils';

import { AssetType, assertAssetId, isMediaLibraryID } from 'types/assets';

export const CREATE_NEW_PUBLISHER = 'snapAdmin/CREATE_NEW_PUBLISHER';
export const GET_CMS_NOTIFICATION_SETTING = 'snapAdmin/GET_CMS_NOTIFICATION_SETTING';
export const SET_CMS_NOTIFICATION_SETTING = 'snapAdmin/SET_CMS_NOTIFICATION_SETTING';
export const SAVE_ONE_PUBLISHER_DATA = 'snapAdmin/SAVE_ONE_PUBLISHER_DATA';
export const ARCHIVE_ONE_PUBLISHER = 'snapAdmin/ARCHIVE_ONE_PUBLISHER';
export const UPDATE_TILE_LOGO = 'snapAdmin/UPDATE_TILE_LOGO';
export const DELETE_TILE_LOGO = 'snapAdmin/DELETE_TILE_LOGO';
export const SAVE_TILE_LOGO = 'snapAdmin/SAVE_TILE_LOGO';
export const SET_TILE_LOGO_PROPERTIES_DATA = 'snapAdmin/SET_TILE_LOGO_PROPERTIES_DATA';
export const REMOVE_TILE_LOGO_PROPERTIES_DATA = 'snapAdmin/REMOVE_TILE_LOGO_PROPERTIES_DATA';
export const GET_SNAP_ADMIN_USERS = 'snapAdmin/GET_SNAP_ADMIN_USERS';
export const CREATE_NEW_SNAP_ADMIN_USER = 'snapAdmin/CREATE_NEW_SNAP_ADMIN_USER';
export const EDIT_SNAP_ADMIN_USER = 'snapAdmin/EDIT_SNAP_ADMIN_USER';
export const DELETE_SNAP_ADMIN_USER = 'snapAdmin/DELETE_SNAP_ADMIN_USER';
export const GET_ONE_PUBLISHER_SETTING_DATA = 'snapAdmin/GET_ONE_PUBLISHER_SETTING_DATA';
export const SET_ONE_PUBLISHER_SETTINGS = 'snapAdmin/SET_ONE_PUBLISHER_SETTINGS';
export const SAVE_ONE_PUBLISHER_SETTING_DATA = 'snapAdmin/SAVE_ONE_PUBLISHER_SETTING_DATA';
export const createNewPublisher = (publisher: any) => {
  return (dispatch: any, getState: GetState) => {
    return dispatch({
      type: CREATE_NEW_PUBLISHER,
      meta: {
        [CALL_API]: {
          method: 'post',
          endpoint: discoverAPI.snapAdmin.createPublisher(),
          body: preparePublisherForSaving(publisher),
        },
        schema: publisherSchema,
      },
    }).catch((error: any) => {
      const errorMessage = get(error, ['fetchResults', 'data', 'message']);
      if (errorMessage && errorMessage.includes('publisher already existed with name')) {
        return apiErrorHandler(dispatch, ErrorContexts.CREATE_NEW_PUBLISHER_INVALID_NAME)(error);
      }
      return apiErrorHandler(dispatch, ErrorContexts.CREATE_NEW_PUBLISHER_INVALID_DETAILS)(error);
    });
  };
};
export const getOnePublisherSettingsData = ({ publisherId }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  return {
    type: GET_ONE_PUBLISHER_SETTING_DATA,
    params: { publisherId },
    meta: {
      [CALL_API]: {
        endpoint: discoverAPI.snapAdmin.singlePublisherSettingData({ publisherId }),
      },
    },
  };
};
export const setOnePublisherSettingsData = (properties: any) => {
  return {
    type: SET_ONE_PUBLISHER_SETTINGS,
    payload: properties,
  };
};
export const saveOnePublisherSettingsData = ({ publisherId }: any) => async (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  const publisherData = snapAdminSelectors.getActiveEditPublisher(getState());
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherData).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg((publisherData as any).id).is.number();
  infoMessageHandler(dispatch, InfoContext.SAVING);
  return dispatch({
    type: SAVE_ONE_PUBLISHER_SETTING_DATA,
    params: { publisherId },
    meta: {
      [CALL_API]: {
        method: 'put',
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
        body: preparePublisherForSaving(publisherData),
        endpoint: discoverAPI.snapAdmin.singlePublisherSettingData({ publisherId }),
      },
    },
  })
    .then(
      clearInfoMessage(getState, dispatch, InfoContext.SAVING),
      clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
    )
    .catch(apiErrorHandler(dispatch, ErrorContexts.SAVE_ONE_PUBLISHER_DATA));
};
export const archiveOnePublisher = ({ publisherId }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  return (dispatch: any) =>
    dispatch({
      type: ARCHIVE_ONE_PUBLISHER,
      params: { publisherId },
      payload: { publisherId },
      meta: {
        [CALL_API]: {
          method: 'delete',
          endpoint: discoverAPI.snapAdmin.publisherLevelService({ publisherId }),
        },
      },
    });
};
export const getNotificationSettingData = () => {
  return (dispatch: any, getState: GetState) => {
    return dispatch({
      type: GET_CMS_NOTIFICATION_SETTING,
      meta: {
        [CALL_API]: {
          endpoint: discoverAPI.settings.cmsSetting({ params: getNotificationBarSettings() }),
        },
      },
    });
  };
};
export const setNotificationSettingData = ({ notificationMessage, showNotificationMessage }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(notificationMessage).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(showNotificationMessage).is.boolean();
  const body = {
    notificationMessageTimestamp: Date.now(),
    notificationMessage,
    showNotificationMessage,
  };
  return {
    type: SET_CMS_NOTIFICATION_SETTING,
    meta: {
      [CALL_API]: {
        method: 'post',
        endpoint: discoverAPI.settings.setCmsSetting(),
        body,
      },
    },
  };
};
export const getNotificationDataIfActive = () => (dispatch: any) => {
  if (!browserUtils.isDocumentHidden()) {
    return dispatch(getNotificationSettingData());
  }
  return Promise.resolve();
};
// Guard to disable execution of the loop twice
let wasNotificationUpdateLoopStarted = false;
export function isNotificationLoopRunning() {
  return wasNotificationUpdateLoopStarted;
}
export function setNotificationLoopRunning(value: any) {
  wasNotificationUpdateLoopStarted = value;
}
export const initNotificationUpdateLoop = () => (dispatch: any, getState: GetState) => {
  if (isNotificationLoopRunning()) {
    return Promise.resolve();
  }
  setNotificationLoopRunning(true);
  // Creating here to bind to loopId
  const loopedRefresh = loopAction(() => getNotificationDataIfActive(), {
    cancelIf: () => false,
    onError: (error: any) => {
      log.error(error);
      setNotificationLoopRunning(false);
      // Start the loop again...
      dispatch(initNotificationUpdateLoop);
    },
    delay: 10 * MINUTES,
  });
  return dispatch(loopedRefresh);
};
export const getUsers = () => {
  return {
    type: GET_SNAP_ADMIN_USERS,
    meta: {
      [CALL_API]: {
        endpoint: discoverAPI.users.snapAdminUsers(),
      },
      schema: arrayOf(userSchema),
    },
  };
};
const createNewSnapAdminUser = ({ username, email }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(username).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(email).is.string();
  const user = { username, email };
  const searchParams = queryString.stringify(user);
  return {
    type: CREATE_NEW_SNAP_ADMIN_USER,
    meta: {
      [CALL_API]: {
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        endpoint: discoverAPI.users.createSnapAdminUser(),
        body: searchParams,
        ...preprocessAndFinalize,
      },
    },
  };
};
export const editSnapAdminUser = (user: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(user).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(user.id).is.string();
  return {
    type: EDIT_SNAP_ADMIN_USER,
    payload: user,
    meta: {
      [CALL_API]: {
        method: 'post',
        endpoint: discoverAPI.users.editSnapAdminUser({ userId: user.id }),
        body: getRolesForSaving(user.resourceRoles),
      },
      schema: userSchema,
    },
  };
};
export const deleteSnapAdminUser = (userId: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(userId).is.string();
  return {
    type: DELETE_SNAP_ADMIN_USER,
    params: { userId },
    payload: { userId },
    meta: {
      [CALL_API]: {
        method: 'delete',
        endpoint: discoverAPI.users.deleteSnapAdminUser({ userId }),
      },
    },
  };
};
export const createAndAddRolesToSnapAdminUser = (user: any) => {
  return (dispatch: any) => {
    return dispatch(createNewSnapAdminUser(user)).then((result: any) => {
      const resultUser = user;
      resultUser.id = result.payload.id;
      return dispatch(editSnapAdminUser(resultUser));
    });
  };
};
export const setTempTileLogoProperties = (componentId: any, properties: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(componentId).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  return {
    type: SET_TILE_LOGO_PROPERTIES_DATA,
    payload: {
      componentId,
      properties,
    },
  };
};
export const removeTempTileLogoProperties = (componentId: any, properties: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(componentId).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.string.or.is.array();
  return {
    type: REMOVE_TILE_LOGO_PROPERTIES_DATA,
    payload: {
      componentId,
      properties,
    },
  };
};
const getStagedPublisherDataOrFallback = (state: any, publisherId: any) => {
  let publisher = getStagesData(state)(publisherId);
  if (!publisher) {
    log.warn('No staged publisher found, using original publisher data');
    publisher = publishersSelectors.getPublisherDetailsDataById(state)(publisherId);
  }
  return publisher;
};
// wrap the mediaActions to customize it for the tileLogo
export const updateTileLogoInfo = ({ assetId, oldAssetId, properties }: any) => {
  assertAssetId(assetId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(properties).is.object();
  return (dispatch: any, getState: GetState) => {
    const publisherId = userSelectors.getActivePublisherId(getState());
    if (isMediaLibraryID(assetId)) {
      const props = JSON.parse(properties.metadata);
      const publisher = getStagedPublisherDataOrFallback(getState(), publisherId);
      let foundLogo = false;
      let tileLogos = publisher.tileLogos
        .filter((logo: any) => !!logo.mediaId)
        .map((logo: any, index: any) => {
          if (logo.mediaId === oldAssetId) {
            foundLogo = true;
            return {
              mediaId: assetId,
              color: logo.color,
              order: index,
              ...props,
            };
          }
          return { mediaId: logo.mediaId, color: logo.color, order: index };
        });
      // If could not find the asset in tile logos, it's an add operation
      if (!foundLogo) {
        tileLogos.push({ mediaId: assetId, order: tileLogos.length, ...props });
      }
      // Sort by order and remove it
      tileLogos = sortBy(tileLogos, ['order']).map(logo => omit(logo, ['order']));
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      return dispatch(stagesActions.updateProperties(publisherId, { tileLogos }));
    }
    return dispatch({
      type: UPDATE_TILE_LOGO,
      params: { assetId, publisherId },
      payload: {
        promise: async () => {
          const result = await dispatch(
            mediaActions.updateAssetProperties({
              assetId,
              properties,
              type: AssetType.IMAGE,
            })
          );
          return parseTileLogoMetadata(result.payload);
        },
      },
    }).catch(apiErrorHandler(dispatch, ErrorContexts.UPDATE_TILE_LOGO));
  };
};
export const uploadTileLogo = (blob: any, publisherId: any, properties: any, oldAssetId = null) => async (
  dispatch: any,
  getState: GetState
) => {
  const imageData = await blobUtils.blobUrlToBlob(blob);
  const mediaResult = await dispatch(
    mediaActions.uploadMediaGetResult(imageData, FileType.IMAGE, {
      purpose: UploadPurpose.TILE_LOGO,
      customValidationOptions: {},
      publisherId,
    })
  );
  const assetId = mediaResult.mediaId;
  // you will get a new image
  if (oldAssetId) {
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    assertState(assetId).is.not.equal(oldAssetId);
  }
  await dispatch(exports.updateTileLogoInfo({ assetId, oldAssetId, properties })).catch(
    apiErrorHandler(dispatch, ErrorContexts.UPLOAD_TILE_ICON)
  );
};
// wrap the mediaActions to customize it for the tileLogo
// This is a soft delete by updating a flag
export const deleteTileLogoInfo = ({ assetId, publisherId }: any) => {
  assertAssetId(assetId);
  return (dispatch: any, getState: GetState) => {
    if (isMediaLibraryID(assetId)) {
      const activePublisherId = publisherId || userSelectors.getActivePublisherId(getState());
      const publisher = getStagedPublisherDataOrFallback(getState(), activePublisherId);
      const tileLogos = publisher.tileLogos
        .filter((tile: any) => tile.mediaId !== assetId)
        .map((tile: any) => ({
          mediaId: tile.mediaId,
          color: tile.color,
        }));
      return dispatch(stagesActions.updateProperties(activePublisherId, { tileLogos })).catch(
        apiErrorHandler(dispatch, ErrorContexts.UPDATE_TILE_LOGO)
      );
    }
    return dispatch({
      type: DELETE_TILE_LOGO,
      params: { assetId },
      payload: {
        promise: async () => {
          const result = await dispatch(
            mediaActions.updateAssetProperties({
              assetId,
              properties: { hidden: true },
              type: AssetType.IMAGE,
            })
          );
          return result.payload;
        },
      },
    }).catch(apiErrorHandler(dispatch, ErrorContexts.UPDATE_TILE_LOGO));
  };
};
// this function just splits for the customization
// the purpose of this function: when you replace a tileLogo, you need to keep some other old property except image assetId
// make sure to extend this when you add new property
const combineTileLogoProperties = (oldProperties: any, newProperties: any) => {
  return u(newProperties, { metadata: get(oldProperties, 'metadata') });
};
export const saveTileLogoInfo = (componentId: any, publisherId: any, horizontalLogoMediaId = null) => {
  return async (dispatch: any, getState: GetState) => {
    infoMessageHandler(dispatch, InfoContext.SAVING);
    const tileLogoComponentsOnly = snapAdminSelectors.getTileLogoComponentsOnly(getState());
    const highestOrderProperty = tileLogoComponentsOnly.reduce((accumulator, currentLogoComponent) => {
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      return Math.max(accumulator, getTileLogoOrder((currentLogoComponent as any).logo));
    }, tileLogoComponentsOnly.length);
    // get the original assetId
    const originalAssetId = extractTileLogoIdFromComponentId(componentId);
    assertAssetId(originalAssetId);
    // get the old properties, possible null
    const oldProperties = snapAdminSelectors.getTileLogoById(getState())(originalAssetId);
    // get the pending change
    const newProperties = snapAdminSelectors.getPendingTileLogoById(getState())(componentId);
    const metadataDefaults = {
      order: highestOrderProperty + 1,
      color: DEFAULT_LOGO_COLOR,
    };
    const combinedProperties = combineTileLogoProperties(oldProperties, {
      ...newProperties,
      metadata: {
        ...metadataDefaults,
        ...oldProperties.metadata,
        ...(newProperties as any).metadata,
      },
    });
    // wrap here in order to keep track of the saving tileLogo status, doesn't need the payload result
    return dispatch({
      type: SAVE_TILE_LOGO,
      params: { componentId },
      payload: {
        promise: async () => {
          const tileLogoPropertiesForSaving = preProcessTileLogoData(combinedProperties);
          if (horizontalLogoMediaId) {
            await dispatch(
              updateTileLogoInfo({
                assetId: horizontalLogoMediaId,
                oldAssetId: originalAssetId,
                properties: tileLogoPropertiesForSaving,
              })
            );
            return Promise.resolve();
          }
          if (combinedProperties.image) {
            // Changing image asset id, have to delete the mediaId in metadata as well
            const combinedPropertiesNoMediaId = {
              ...combinedProperties,
              metadata: omit(combinedProperties.metadata, ['mediaId']),
            };
            const tileLogoPropertiesForSavingNoMediaId = preProcessTileLogoData(
              omit(combinedPropertiesNoMediaId, ['image'])
            );
            await dispatch(
              exports.uploadTileLogo(
                combinedPropertiesNoMediaId.image,
                publisherId,
                tileLogoPropertiesForSavingNoMediaId,
                originalAssetId
              )
            );
            return Promise.resolve();
          }
          return dispatch(
            updateTileLogoInfo({
              assetId: originalAssetId,
              oldAssetId: originalAssetId,
              properties: tileLogoPropertiesForSaving,
            })
          );
        },
      },
    })
      .then(
        clearInfoMessage(getState, dispatch, InfoContext.SAVING),
        clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
      )
      .catch(apiErrorHandler(dispatch, ErrorContexts.SAVE_TILE_LOGO));
  };
};
