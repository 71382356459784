import {
  isSnapStatusIncomplete,
  isSnapStatusWithError,
  isSnapStatusNonBlockingWarning,
  isSnapStatusAModerationFlagging,
} from 'state/buildStatus/schema/buildStatusHelpers';

import {
  ShareOption,
  AgeGate,
  StoryStatus,
  SimplifiedCheetahEditionState,
  EditionSchedule,
  AdPlacementMode,
} from 'config/constants';
import type { SimplifiedCheetahEditionStateEnum } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { isInThePastOrPresent } from 'utils/dateUtils';

import type { AnalyticsStoryMetadata } from 'types/analytics';
import type { BuildStatusType } from 'types/build';
import type { SnapId } from 'types/common';
import { StoryState, LiveEditStatus } from 'types/editions';
import type { Edition } from 'types/editions';
import type { Segment } from 'types/segments';

export const isScheduled = (edition: Edition) => edition.state === StoryState.SCHEDULED;
export const isHidden = (edition: Edition) => edition.state === StoryState.HIDDEN;
export const isStateAvailable = (state: StoryState) => state === StoryState.LIVE || state === StoryState.ARCHIVED;
export const isAvailable = (edition: Edition | AnalyticsStoryMetadata) => isStateAvailable(edition.state);

export const isAvailableAndLocked = (edition: Edition) =>
  isStateAvailable(edition.state) && edition.liveEditStatus !== LiveEditStatus.IN_PROGRESS;

export const isReadOnly = (edition: Edition) => isAvailableAndLocked(edition);

export const isUnpublishing = (edition: Edition) => {
  return isAvailable(edition) && edition.endDate && isInThePastOrPresent(Date.parse(edition.endDate));
};

export const isPublishing = (edition: Edition) => {
  const isScheduledPublishing =
    isScheduled(edition) && edition.startDate && isInThePastOrPresent(Date.parse(edition.startDate));
  const isLiveEditPublishing =
    !isUnpublishing(edition) && isAvailable(edition) && edition.liveEditStatus === LiveEditStatus.READY_FOR_PUBLISHING;

  return isScheduledPublishing || isLiveEditPublishing;
};

export const isScheduleToLive = (edition: Edition) =>
  isScheduled(edition) && edition.scheduleType === EditionSchedule.SCHEDULE_TO_GO_LIVE;

export const isScheduleToArchive = (edition: Edition) =>
  isScheduled(edition) && edition.scheduleType === EditionSchedule.SCHEDULE_FOR_ARCHIVING;

export const isDraft = (story: Edition) => {
  return story.state === StoryState.READY || story.state === StoryState.IN_PROGRESS || story.state === StoryState.NEW;
};

export const getSimplifiedCheetahEditionState = (
  edition?: Edition | null
): SimplifiedCheetahEditionStateEnum | undefined | null => {
  if (!edition) {
    return null;
  }

  switch (edition.state) {
    case StoryState.NEW:
    case StoryState.IN_PROGRESS:
    case StoryState.READY:
      return SimplifiedCheetahEditionState.DRAFT;
    case StoryState.SCHEDULED:
    case StoryState.PUBLISHING:
      return SimplifiedCheetahEditionState.SCHEDULED;
    case StoryState.LIVE:
    case StoryState.ARCHIVED:
      return SimplifiedCheetahEditionState.AVAILABLE;
    case StoryState.HIDDEN:
      return SimplifiedCheetahEditionState.UNAVAILABLE;
    default:
      return null;
  }
};

export const findSnapIndexInEdition = (edition?: Edition | null, snapId?: SnapId | null) => {
  return edition && edition.snapIds && snapId && edition.snapIds.indexOf(snapId);
};

export const findSegmentForSnap = (edition: Edition, snapId: SnapId) => {
  if (!edition || !edition.segments) {
    return null;
  }

  const isSnapInSegment = (segment: any) => segment.snapIds && segment.snapIds.indexOf(snapId) > -1;

  return edition.segments.find(isSnapInSegment) || null;
};

export const findSnapIndexInSegment = (
  segment: Segment | undefined | null,
  snapId: SnapId
): number | undefined | null => {
  if (!segment || !segment.snapIds) {
    return null;
  }
  const index: number = segment.snapIds.indexOf(snapId);
  return index !== -1 ? index : null;
};

export const isFirstSnapInSegment = (edition: Edition | undefined | null, snapId: SnapId) => {
  if (!edition) {
    return false;
  }
  const segment = findSegmentForSnap(edition, snapId);
  if (!segment) {
    return false;
  }
  return findSnapIndexInSegment(segment, snapId) === 0;
};

export const isAdEnabledAfterSnap = (edition: Edition | null, adSnapIndexes: Array<number>, snapId: SnapId) => {
  const index = findSnapIndexInEdition(edition, snapId);

  // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
  return adSnapIndexes.indexOf(index + 1) !== -1;
};

export const isFixedAdSlotsOverrideEnabled = (edition: Edition) => {
  return edition.adPlacementMode === AdPlacementMode.FIXED_AD_SLOTS;
};

export const isAdvertisingDisabledForStory = (edition: Edition) => {
  return edition.adPlacementMode === AdPlacementMode.FIXED_AD_SLOTS && edition.adSnapIndexes.length === 0;
};

export function isShareable(edition: Edition) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(edition).is.object();
  return edition.shareOption !== ShareOption.NO_SHARE && edition?.ageGate !== AgeGate.EXPLICIT;
}

export const isLastNonSubscribeSnapInEdition = (edition: Edition, snapId: SnapId, editionHasSubscribeSnap: boolean) => {
  const numSnapsToGetToNonSubscribeSnap = editionHasSubscribeSnap ? 2 : 1;
  if (!edition || !edition.snapIds || edition.snapIds.length < numSnapsToGetToNonSubscribeSnap) {
    return false;
  }
  const lastNonSubscribeIndex = edition.snapIds.length - numSnapsToGetToNonSubscribeSnap;
  return findSnapIndexInEdition(edition, snapId) === lastNonSubscribeIndex;
};

export const isFirstSnapInEdition = (edition: Edition, snapId: SnapId) => {
  return findSnapIndexInEdition(edition, snapId) === 0;
};

export function isAgeGateEnabled(edition: Edition) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(edition).is.object();
  return edition.ageGate === AgeGate.EXPLICIT;
}

export function getIncompleteBuildStatuses(snapBuildStatuses: BuildStatusType[]): BuildStatusType[] {
  return snapBuildStatuses.filter(snapStatus => isSnapStatusIncomplete(snapStatus));
}

export function getBuildStatusesWithErrors(snapBuildStatuses: BuildStatusType[]): BuildStatusType[] {
  return snapBuildStatuses.filter(snapStatus => isSnapStatusWithError(snapStatus));
}

export function getNonBlockingBuildStatuses(snapBuildStatuses: BuildStatusType[]): BuildStatusType[] {
  return snapBuildStatuses.filter(snapStatus => isSnapStatusNonBlockingWarning(snapStatus));
}

export function getModerationFlaggedBuildStatuses(snapBuildStatuses: BuildStatusType[]): BuildStatusType[] {
  return snapBuildStatuses.filter(snapStatus => isSnapStatusAModerationFlagging(snapStatus));
}

export function advancedStoryStatusToStringId(storyStatus: string, snapBuildStatuses: BuildStatusType[]) {
  if (storyStatus === StoryStatus.CONTENT_DELETED) {
    return 'snap-has-been-removed';
  }

  if (storyStatus === StoryStatus.SHOT_DETECTION_FAILED) {
    return 'story-status-shot-detection-failed';
  }

  if (storyStatus === StoryStatus.SHOT_DETECTION_IN_PROGRESS) {
    return 'unified-story-status-shot-detection-in-progress';
  }

  const buildStatusesWithErrors = getBuildStatusesWithErrors(snapBuildStatuses);
  if (buildStatusesWithErrors.length > 0) {
    if (snapBuildStatuses.length > 1) {
      // the story has more than one snap and one or more have errors
      return buildStatusesWithErrors.length > 1
        ? 'story-status-has-snaps-with-errors'
        : 'story-status-has-snap-with-error';
    }
    return 'story-status-has-one-snap-with-error'; // the story has only one snap and it has an error
  }

  const incompleteBuildStatuses = getIncompleteBuildStatuses(snapBuildStatuses);
  if (incompleteBuildStatuses.length > 0) {
    if (snapBuildStatuses.length > 1) {
      return incompleteBuildStatuses.length > 1
        ? 'story-status-has-incomplete-snaps'
        : 'story-status-has-incomplete-snap';
    }
    return 'story-status-has-one-and-incomplete-snap'; // the story has only one snap and the snap is incomplete
  }

  const nonBlockingBuildStatuses = getNonBlockingBuildStatuses(snapBuildStatuses);
  if (nonBlockingBuildStatuses.length > 0) {
    const moderationFlaggingStatuses = getModerationFlaggedBuildStatuses(snapBuildStatuses);
    if (moderationFlaggingStatuses.length > 0) {
      if (snapBuildStatuses.length > 1) {
        return moderationFlaggingStatuses.length > 1
          ? 'story-status-has-moderation-flagged-snaps'
          : 'story-status-has-moderation-flagged-snap';
      }
      return 'story-status-has-one-moderation-flagged-snap'; // the story has only one snap and the snap has moderation flag
    }
  }

  switch (storyStatus) {
    case StoryStatus.GENERATING_SUBTITLES:
      return 'story-status-generating-subtitles';
    case StoryStatus.NO_SNAPS:
      return 'story-status-no-snaps';
    case StoryStatus.HIDDEN_LOCK:
      return 'set-available-disabled-by-admin';
    case StoryStatus.ILLEGAL_EPISODE_STATE:
      return 'story-status-illegal-episode-state';
    case StoryStatus.ILLEGAL_PUBLISH_ORDER:
      return 'story-status-illegal-publish-order-seasons';
    case StoryStatus.INCOMPLETE_PROFILE:
      return 'story-status-incomplete-publisher-profile';
    case StoryStatus.STITCHED_VIDEO_STALE:
      return 'story-status-stitched-video-stale';
    case StoryStatus.STITCHED_VIDEO_BUILDING:
    case StoryStatus.STITCHED_VIDEO_MISSING:
    case StoryStatus.STITCHED_VIDEO_ERROR:
      return 'story-status-combine-video-building';
    case StoryStatus.PUBLISHABLE:
    case StoryStatus.SAVING:
    case null:
      return null;
    default:
      return 'story-status-not-ready';
  }
}

export function getStoryStatusExtraValues(edition: Edition, snapBuildStatuses: BuildStatusType[]) {
  const buildStatusesWithErrors = getBuildStatusesWithErrors(snapBuildStatuses);
  if (buildStatusesWithErrors.length > 0) {
    return {
      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      index: findSnapIndexInEdition(edition, buildStatusesWithErrors[0].snapId) + 1,
    };
  }

  const incompleteBuildStatuses = getIncompleteBuildStatuses(snapBuildStatuses);
  if (incompleteBuildStatuses.length > 0) {
    return {
      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      index: findSnapIndexInEdition(edition, incompleteBuildStatuses[0].snapId) + 1,
    };
  }

  const nonBlockingBuildStatuses = getNonBlockingBuildStatuses(snapBuildStatuses);
  if (nonBlockingBuildStatuses.length > 0) {
    return {
      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      index: findSnapIndexInEdition(edition, nonBlockingBuildStatuses[0].snapId) + 1,
    };
  }

  return null;
}
