import invariant from 'invariant';
import type { Dispatch } from 'redux';

import * as editionsActions from 'state/editions/actions/editionsActions';
import * as editionsSelectors from 'state/editions/selectors/editionsSelectors';
import * as feedsActions from 'state/feeds/actions/feedsActions';
import * as feedsSelectors from 'state/feeds/selectors/feedsSelectors';
import { createPromiseAction } from 'state/promiseMiddleware/actions/promiseMiddlewareActions';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import * as snapsActions from 'state/snaps/actions/snapsActions';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';

import * as stagesConfig from '../registry/stagesRegistry';
import * as stageSelectors from '../selectors/stagesSelectors';

import { StageType, EditionStageType } from 'config/constants';
import type { StageTypeEnum } from 'config/constants';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';

import type { SnapId as SnapID } from 'types/common';
import type { Edition, EditionID } from 'types/editions';
import type { Publisher, PublisherID } from 'types/publishers';
import type { GetState } from 'types/redux';
import type { Stage } from 'types/stages';

export const COMMIT = 'stages/COMMIT';
export const STAGE = 'stages/STAGE';
export const DISCARD = 'stages/DISCARD';
export const UPDATE_PROPERTIES = 'stages/UPDATE_PROPERTIES';
export const REMOVE_PROPERTIES = 'stages/REMOVE_PROPERTIES';

export type StageId = string | number;

type Params = {
  stageId: StageId;
};

type StageAction<Staged extends {}> = {
  params: Params;
  payload:
    | Staged
    | {
        promise: Promise<Staged>;
      };
  type: 'stages/STAGE';
};

type UpdateAction<Staged extends {}> = {
  params: Params;
  payload: Partial<Staged>;
  type: 'stages/UPDATE_PROPERTIES';
};

type RemoveAction = {
  params: Params;
  payload: {
    propertyList: string[];
  };
  type: 'stages/REMOVE_PROPERTIES';
};

type CommitAction = {
  params: Params;
  payload: {
    promise: Promise<void>;
  };
  type: 'stages/COMMIT';
};

type DiscardAction = {
  params: Params;
  type: 'stages/DISCARD';
};

// The type of Action depends on the staged state because each stage config has different state
export type Action<Staged extends {}> =
  | StageAction<Staged>
  | UpdateAction<Staged>
  | RemoveAction
  | CommitAction
  | DiscardAction;

function createParams(stageId: StageId): Params {
  return {
    stageId,
  };
}

export function stageData(stageId: StageId, type: StageTypeEnum = StageType.SNAP) {
  return (dispatch: Dispatch, getState: GetState) => {
    let stagedData;

    if (type === StageType.SNAP) {
      const snap = snapsSelectors.getSnapById(getState())(stageId);
      invariant(snap, `could not find snap with id ${stageId}`);
      const stageName = snap.type;
      stagedData = stagesConfig.transformInstanceToStaged(stageName, snap);
    } else if (type === StageType.EDITION) {
      const edition: Edition | undefined | null = editionsSelectors.getEditionById(getState())(stageId as EditionID);
      const stageName = EditionStageType.AD_SLOTS;
      if (edition) {
        stagedData = stagesConfig.transformInstanceToStaged(stageName, edition);
      }
    } else if (type === StageType.PUBLISHER) {
      const publisherId = Number(stageId);
      const publisher: Publisher | undefined | null = publishersSelectors.getPublisherDetailsDataById(getState())(
        publisherId
      );
      invariant(publisher, `could not find publisher details with id ${stageId}`);
      const stageName = StageType.PUBLISHER;
      stagedData = stagesConfig.transformInstanceToStaged(stageName, publisher);
    }

    return dispatch(
      createPromiseAction(
        {
          type: STAGE,
          params: createParams(stageId),
        },
        Promise.resolve(stagedData)
      )
    );
  };
}

export function discardData(stageId: StageId) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: DISCARD,
      params: createParams(stageId),
    });
  };
}

export function updateProperties(stageId: StageId, properties: {}) {
  return async (dispatch: Dispatch) => {
    return dispatch({
      type: UPDATE_PROPERTIES,
      params: createParams(stageId),
      payload: {
        ...properties,
      },
    });
  };
}

export function removeProperties(stageId: StageId, propertyList: string[]) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: REMOVE_PROPERTIES,
      params: createParams(stageId),
      payload: {
        propertyList,
      },
    });
  };
}

export function commitData(stageId: StageId, type: StageTypeEnum = StageType.SNAP) {
  return (dispatch: Dispatch, getState: GetState) => {
    if (type === StageType.EDITION) {
      const stagedEdition = stageSelectors.getData(getState())(stageId);
      const stageName = EditionStageType.AD_SLOTS;
      const propertiesToCommit = stagesConfig.transformInstanceFromStaged(stageName, stagedEdition);
      return dispatch(commitEditionProperties(stageId as EditionID, propertiesToCommit) as any);
    }

    if (type === StageType.PUBLISHER) {
      const stagedPublisher = stageSelectors.getData(getState())(stageId);
      const stageName = StageType.PUBLISHER;
      const propertiesToCommit = stagesConfig.transformInstanceFromStaged(stageName, stagedPublisher);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
      return dispatch(commitPublisherProperties(stageId as PublisherID, propertiesToCommit));
    }

    if (type === StageType.HN_FEED) {
      if (stageSelectors.isStageNetDirty(getState())(stageId, StageType.HN_FEED)) {
        const stagedFeed = stageSelectors.getData(getState())(stageId);
        const stageName = StageType.HN_FEED;
        const propertiesToCommit = stagesConfig.transformInstanceFromStaged(stageName, stagedFeed);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        return dispatch(commitFeedProperties(stageId, propertiesToCommit));
      }

      return Promise.resolve(); // NOP
    }

    const snap = snapsSelectors.getSnapById(getState())(stageId);
    invariant(snap, `could not find snap with id ${stageId}`);
    const stagedSnap = stageSelectors.getData(getState())(stageId);
    const stageName = snap.type;
    const propertiesToCommit = stagesConfig.transformInstanceFromStaged(stageName, stagedSnap);
    return dispatch(commitSnapProperties(stageId as SnapID, propertiesToCommit) as any);
  };
}

function commitSnapProperties(snapId: SnapID, propertiesToCommit: {}) {
  return (dispatch: Dispatch, getState: GetState) => {
    const promise = Promise.resolve(propertiesToCommit)
      .then(result => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
        return dispatch(snapsActions.setSnapPropertiesAndSave({ snapId }, result));
      })
      .then(() => {
        const stageAction: any = stageData(snapId);
        return dispatch(stageAction);
      });

    const commitAction = {
      type: COMMIT,
      params: createParams(snapId),
    };

    return dispatch(createPromiseAction(commitAction, promise));
  };
}

function commitPublisherProperties(publisherId: PublisherID, propertiesToCommit: Partial<Publisher>) {
  return (dispatch: Dispatch, getState: GetState) => {
    const promise = Promise.resolve(propertiesToCommit)
      .then(result => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
        return dispatch(publishersActions.updatePublisherDetails(publisherId, result));
      })
      .then(() => {
        const stageAction: any = stageData(publisherId, StageType.PUBLISHER);
        return dispatch(stageAction);
      });

    const commitAction = {
      type: COMMIT,
      params: createParams(publisherId),
    };

    return dispatch(createPromiseAction(commitAction, promise));
  };
}

function commitEditionProperties(stageId: EditionID, propertiesToCommit: {}) {
  return (dispatch: Dispatch, getState: GetState) => {
    const promise = Promise.resolve(propertiesToCommit)
      .then(result => {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(dispatch: any, getState: GetState) =... Remove this comment to see the full error message
        return dispatch(editionsActions.setEditionPropertiesAndSave({ editionId: stageId }, result));
      })
      .then(() => {
        const stageAction: any = stageData(stageId, StageType.EDITION);
        return dispatch(stageAction);
      });

    const commitAction = {
      type: COMMIT,
      params: createParams(stageId),
    };

    return dispatch(createPromiseAction(commitAction, promise));
  };
}

export function commitStagedSnap<State extends {}, Staged extends {}, UnstagingOpts>(
  snapId: SnapID,
  stage: Stage<State, Staged, UnstagingOpts>,
  opts: UnstagingOpts
) {
  return (dispatch: Dispatch, getState: GetState) => {
    const staged = stageSelectors.getData(getState())(snapId);

    const propertiesToCommit = stage.transformFromStaged(staged, opts);

    return dispatch(commitSnapProperties(snapId, propertiesToCommit) as any);
  };
}

function commitFeedProperties(feedId: any, propertiesToCommit: any) {
  return (dispatch: Dispatch, getState: GetState) => {
    const promise = Promise.resolve(propertiesToCommit)
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(result: any) => Promise<void> |... Remove this comment to see the full error message
      .then(result => {
        const businessProfileId = publishersSelectors.getActivePublisherBusinessProfileId(getState());
        if (!businessProfileId) {
          return Promise.resolve();
        }

        const feed = feedsSelectors.getFeedById(getState())(feedId);

        if (!feed) {
          return dispatch(
            feedsActions.createFeed(
              businessProfileId,
              result.url,
              result.name,
              result.categoryId,
              result.templateId,
              result.autoPublish,
              result.approvalDelay,
              result.feedType,
              result.apiType,
              result.useContentFromFeed,
              result.useMediaFromFeed,
              result.useMediaHeadlineFromFeed,
              result.useUrlFromFeed
            )
          );
        }

        return dispatch(
          feedsActions.updateFeed(
            feed.id,
            result.name,
            result.categoryId,
            result.templateId,
            result.autoPublish,
            result.approvalDelay,
            result.enabled,
            result.useContentFromFeed,
            result.useMediaFromFeed,
            result.useMediaHeadlineFromFeed,
            result.useUrlFromFeed
          )
        );
      })
      .then(() => {
        const stageAction: any = discardData(feedId);
        return dispatch(stageAction);
      })
      .catch(
        apiErrorHandler(
          dispatch,
          !feedsSelectors.getFeedById(getState())(feedId) ? ErrorContexts.CREATE_FEED : ErrorContexts.UPDATE_FEED
        ) /* context */
      );

    const commitAction = {
      type: COMMIT,
      params: createParams(feedId),
    };

    return dispatch(createPromiseAction(commitAction, promise));
  };
}
