import { SELF_SERVE_CREATIVE_SUITE_GOOGLE_BASE_URL, SELF_SERVE_CREATIVE_SUITE_SNAP_BASE_URL } from 'config/constants';
import { ScopedURLBuilder } from 'utils/linkUtils';

import type { CreativeID } from 'types/snaps';

const googleSelfServeBuilder = new ScopedURLBuilder(SELF_SERVE_CREATIVE_SUITE_GOOGLE_BASE_URL);
const snapSelfServeBuilder = new ScopedURLBuilder(SELF_SERVE_CREATIVE_SUITE_SNAP_BASE_URL);

const preserveCasing = (input: any) => input;
const formatCreativeId = (creativeId: any) => {
  return creativeId ? `edit/${creativeId}` : 'customize/new';
};

export const creative = {
  thumbnailImage: (mediaId: string, frame: number) => {
    return snapSelfServeBuilder.createAPICall(`api/v1/media/${mediaId}/frame/${frame}?h=113&w=64`);
  },
  previewImage: (mediaId: string, frame: number) => {
    return snapSelfServeBuilder.createAPICall(`api/v1/media/${mediaId}/frame/${frame}?h=229&w=128`);
  },
  snapAuthHandshake: snapSelfServeBuilder.createAPICall('me.html', {
    query: ['ref'],
    convertQueryCasing: preserveCasing,
  }),
  createEpisode: (isSnapSSO: boolean) => {
    let builder = googleSelfServeBuilder;
    if (isSnapSSO) {
      builder = snapSelfServeBuilder;
    }
    return builder.createAPICall('projects/convert/new', {
      query: ['publisherId'],
      convertQueryCasing: preserveCasing,
    });
  },
  embedMode: (creativeId: CreativeID | undefined | null, isSnapSSO: boolean) => {
    let builder = googleSelfServeBuilder;
    if (isSnapSSO) {
      builder = snapSelfServeBuilder;
    }
    return builder.createAPICall(`embedded/projects/${formatCreativeId(creativeId)}`, {
      query: ['publisherId', 'editionId', 'snapId', 'ref'],
      convertQueryCasing: preserveCasing,
    });
  },
  embedAdvancedMode: (isSnapSSO: boolean) => {
    let builder = googleSelfServeBuilder;
    if (isSnapSSO) {
      builder = snapSelfServeBuilder;
    }
    return builder.createAPICall('embedded/projects/design/new', {
      query: ['publisherId', 'editionId', 'snapId', 'ref'],
      convertQueryCasing: preserveCasing,
    });
  },
  embedTemplateMode: (isSnapSSO: boolean) => {
    let builder = googleSelfServeBuilder;
    if (isSnapSSO) {
      builder = snapSelfServeBuilder;
    }
    return builder.createAPICall('embedded/projects/templates', {
      query: ['publisherId'],
      convertQueryCasing: preserveCasing,
    });
  },
  embedImportMode: (creativeId: CreativeID | undefined | null, isSnapSSO: boolean) => {
    let builder = googleSelfServeBuilder;
    if (isSnapSSO) {
      builder = snapSelfServeBuilder;
    }
    return builder.createAPICall(`embedded/projects/${formatCreativeId(creativeId)}`, {
      query: ['publisherId', 'editionId', 'snapId', 'sourceContentUrl', 'ref'],
      convertQueryCasing: preserveCasing,
    });
  },
  embedCacheMode: (isSnapSSO: boolean) => {
    let builder = googleSelfServeBuilder;
    if (isSnapSSO) {
      builder = snapSelfServeBuilder;
    }
    return builder.createAPICall('embedded/projects/customize/new', {
      query: ['ref'],
      convertQueryCasing: preserveCasing,
    });
  },
  embedImportTopsnapAssetMode: (isSnapSSO: boolean) => {
    const builder = isSnapSSO ? snapSelfServeBuilder : googleSelfServeBuilder;
    return builder.createAPICall('embedded/projects/design/new', {
      query: ['publisherId', 'editionId', 'snapId', 'ref', 'importAssetId'],
      convertQueryCasing: preserveCasing,
    });
  },
};
