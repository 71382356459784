import { ApolloClient, ApolloLink, InMemoryCache, Operation } from '@apollo/client';
import type { NormalizedCacheObject } from '@apollo/client/cache/inmemory/types';
import { relayStylePagination } from '@apollo/client/utilities/policies/pagination';

import { apiMetricsLink } from 'utils/apis/apolloLinks/apiMetricsLink';
import { adsGatewayAuthLink, storyStudioProxyAuthLink } from 'utils/apis/apolloLinks/authLink';
import { errorLink } from 'utils/apis/apolloLinks/errorLink';
import { adsGatewayGraphqlFetchLink, storyStudioProxyGrapqhlFetchLink } from 'utils/apis/apolloLinks/uriLink';

const cache = new InMemoryCache({
  typePolicies: {
    RevenueResult: {
      fields: {
        bundlesRevenue: relayStylePagination(),
      },
    },
  },
});

const apolloClient = new ApolloClient<NormalizedCacheObject>({
  cache,
  link: ApolloLink.split(
    (operation: Operation) => operation.getContext().clientName === 'ads-gateway',
    ApolloLink.from([apiMetricsLink, adsGatewayAuthLink, errorLink, adsGatewayGraphqlFetchLink]),
    ApolloLink.from([apiMetricsLink, storyStudioProxyAuthLink, errorLink, storyStudioProxyGrapqhlFetchLink])
  ),
});

export default apolloClient;
