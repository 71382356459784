export const CREATE_SNAP = 'snap/CREATE_SNAP';
export const DELETE_SNAP = 'snap/DELETE_SNAP';
export const LOAD_SNAP = 'snap/LOAD_SNAP';
export const BUILD_SNAP = 'snap/BUILD_SNAP';
export const SAVE_SNAP = 'snap/SAVE_SNAP';
export const MARK_SAVE_AS_PENDING = 'snap/MARK_SAVE_AS_PENDING';
export const BUILD_ASSET = 'snap/BUILD_ASSET';
export const SET_SNAP_PROPERTIES = 'snap/SET_SNAP_PROPERTIES';
export const SET_SNAP_PROPERTIES_AND_SAVE = 'snap/SET_SNAP_PROPERTIES_AND_SAVE';
export const CREATE_INTERACTION = 'snap/CREATE_INTERACTION';
export const UPDATE_INTERACTION = 'snap/UPDATE_INTERACTION';
export const LOAD_SNAPS = 'snap/LOAD_SNAPS';
