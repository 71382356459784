import React from 'react';

declare var __DEBUG__: boolean;
declare var __USE_PERF_TRACE__: boolean;

export default function installPerfMonitors() {
  // TODO: currently react 16 does not have support for perf monitor,
  // we should re-visit this in the future.

  /* eslint-disable spaced-comment */
  if (__DEBUG__ && __USE_PERF_TRACE__) {
    /* eslint-disable global-require, max-len */
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      trackAllPureComponents: true,
    });
  }
  /* eslint-enable spaced-comment */
}
