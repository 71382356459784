// Not using flow as it doesn't support inline SVGs

// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Archive.svg.... Remove this comment to see the full error message
import archive from 'icons/SDS/Actions/Archive.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Attachment.s... Remove this comment to see the full error message
import attachment from 'icons/SDS/Actions/Attachment.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Chart.svg.in... Remove this comment to see the full error message
import chart from 'icons/SDS/Actions/Chart.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Check Circle... Remove this comment to see the full error message
import checkCircle from 'icons/SDS/Actions/Check Circle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Circle Fille... Remove this comment to see the full error message
import circleFilled from 'icons/SDS/Actions/Circle Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Circle.svg.i... Remove this comment to see the full error message
import circle from 'icons/SDS/Actions/Circle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Create Group... Remove this comment to see the full error message
import createGroup from 'icons/SDS/Actions/Create Group.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Cross Circle... Remove this comment to see the full error message
import crossCircle from 'icons/SDS/Actions/Cross Circle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Cross.svg.in... Remove this comment to see the full error message
import cross from 'icons/SDS/Actions/Cross.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Cut.svg.inli... Remove this comment to see the full error message
import cut from 'icons/SDS/Actions/Cut.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Download.svg... Remove this comment to see the full error message
import download from 'icons/SDS/Actions/Download.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Filter Fille... Remove this comment to see the full error message
import filterFilled from 'icons/SDS/Actions/Filter Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Filter.svg.i... Remove this comment to see the full error message
import filter from 'icons/SDS/Actions/Filter.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Folder.svg.i... Remove this comment to see the full error message
import folder from 'icons/SDS/Actions/Folder.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Heart Filled... Remove this comment to see the full error message
import heartFilled from 'icons/SDS/Actions/Heart Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Heart.svg.in... Remove this comment to see the full error message
import heart from 'icons/SDS/Actions/Heart.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Message Fill... Remove this comment to see the full error message
import messageFilled from 'icons/SDS/Actions/Message Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Message.svg.... Remove this comment to see the full error message
import message from 'icons/SDS/Actions/Message.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Minus.svg.in... Remove this comment to see the full error message
import minus from 'icons/SDS/Actions/Minus.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/No Replay.sv... Remove this comment to see the full error message
import noReplay from 'icons/SDS/Actions/No Replay.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Pin.svg.inli... Remove this comment to see the full error message
import pin from 'icons/SDS/Actions/Pin.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Play Filled.... Remove this comment to see the full error message
import playFilled from 'icons/SDS/Actions/Play Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Play.svg.inl... Remove this comment to see the full error message
import play from 'icons/SDS/Actions/Play.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Plus.svg.inl... Remove this comment to see the full error message
import plus from 'icons/SDS/Actions/Plus.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Profile Minu... Remove this comment to see the full error message
import profileMinus from 'icons/SDS/Actions/Profile Minus.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Profile Mult... Remove this comment to see the full error message
import profileMultiple from 'icons/SDS/Actions/Profile Multiple.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Profile Plus... Remove this comment to see the full error message
import profilePlus from 'icons/SDS/Actions/Profile Plus.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Profile Sing... Remove this comment to see the full error message
import profileSingle from 'icons/SDS/Actions/Profile Single.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Rectangle Fi... Remove this comment to see the full error message
import rectangleFilled from 'icons/SDS/Actions/Rectangle Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Rectangle.sv... Remove this comment to see the full error message
import rectangle from 'icons/SDS/Actions/Rectangle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Redo.svg.inl... Remove this comment to see the full error message
import redo from 'icons/SDS/Actions/Redo.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Screenshot.s... Remove this comment to see the full error message
import screenshot from 'icons/SDS/Actions/Screenshot.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Search.svg.i... Remove this comment to see the full error message
import search from 'icons/SDS/Actions/Search.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Send Outline... Remove this comment to see the full error message
import sendOutlined from 'icons/SDS/Actions/Send Outlined.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Send.svg.inl... Remove this comment to see the full error message
import send from 'icons/SDS/Actions/Send.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Star Favorit... Remove this comment to see the full error message
import starFavoriteFilled from 'icons/SDS/Actions/Star Favorite Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Star Favorit... Remove this comment to see the full error message
import starFavorite from 'icons/SDS/Actions/Star Favorite.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Subscribe Fi... Remove this comment to see the full error message
import subscribeFilled from 'icons/SDS/Actions/Subscribe Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Subscribe.sv... Remove this comment to see the full error message
import subscribe from 'icons/SDS/Actions/Subscribe.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Trash.svg.in... Remove this comment to see the full error message
import trash from 'icons/SDS/Actions/Trash.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Tree.svg.inl... Remove this comment to see the full error message
import tree from 'icons/SDS/Actions/Tree.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Undo.svg.inl... Remove this comment to see the full error message
import undo from 'icons/SDS/Actions/Undo.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Upload.svg.i... Remove this comment to see the full error message
import upload from 'icons/SDS/Actions/Upload.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Zoom In.svg.... Remove this comment to see the full error message
import zoomIn from 'icons/SDS/Actions/Zoom In.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/Zoom Out.svg... Remove this comment to see the full error message
import zoomOut from 'icons/SDS/Actions/Zoom Out.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Actions/replay.svg.i... Remove this comment to see the full error message
import replay from 'icons/SDS/Actions/replay.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Calendar.svg.i... Remove this comment to see the full error message
import calendar from 'icons/SDS/Alert/Calendar.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Campaign.svg.i... Remove this comment to see the full error message
import campaign from 'icons/SDS/Alert/Campaign.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Flag.svg.inlin... Remove this comment to see the full error message
import flag from 'icons/SDS/Alert/Flag.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Gear.svg.inlin... Remove this comment to see the full error message
import gear from 'icons/SDS/Alert/Gear.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Help.svg.inlin... Remove this comment to see the full error message
import help from 'icons/SDS/Alert/Help.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Notification B... Remove this comment to see the full error message
import notificationBell from 'icons/SDS/Alert/Notification Bell.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Question.svg.i... Remove this comment to see the full error message
import question from 'icons/SDS/Alert/Question.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Support Chat.s... Remove this comment to see the full error message
import supportChat from 'icons/SDS/Alert/Support Chat.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Warning Circle... Remove this comment to see the full error message
import warningCircle from 'icons/SDS/Alert/Warning Circle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Alert/Warning Triang... Remove this comment to see the full error message
import warningTriangle from 'icons/SDS/Alert/Warning Triangle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Align Bottom.... Remove this comment to see the full error message
import alignBottom from 'icons/SDS/Editor/Align Bottom.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Align Center.... Remove this comment to see the full error message
import alignCenter from 'icons/SDS/Editor/Align Center.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Art.svg.inlin... Remove this comment to see the full error message
import art from 'icons/SDS/Editor/Art.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Assign To All... Remove this comment to see the full error message
import assignToAll from 'icons/SDS/Editor/Assign To All.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Contrast.svg.... Remove this comment to see the full error message
import contrast from 'icons/SDS/Editor/Contrast.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Cursor.svg.in... Remove this comment to see the full error message
import cursor from 'icons/SDS/Editor/Cursor.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Droplet.svg.i... Remove this comment to see the full error message
import droplet from 'icons/SDS/Editor/Droplet.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Eye Drop.svg.... Remove this comment to see the full error message
import eyeDrop from 'icons/SDS/Editor/Eye Drop.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Grid.svg.inli... Remove this comment to see the full error message
import grid from 'icons/SDS/Editor/Grid.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Key Frame.svg... Remove this comment to see the full error message
import keyFrame from 'icons/SDS/Editor/Key Frame.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Keyframe Fill... Remove this comment to see the full error message
import keyframeFilled from 'icons/SDS/Editor/Keyframe Filled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Loop.svg.inli... Remove this comment to see the full error message
import loop from 'icons/SDS/Editor/Loop.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Magic Wand.sv... Remove this comment to see the full error message
import magicWand from 'icons/SDS/Editor/Magic Wand.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Paper and Pen... Remove this comment to see the full error message
import paperAndPencil from 'icons/SDS/Editor/Paper and Pencil.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Pencil.svg.in... Remove this comment to see the full error message
import pencil from 'icons/SDS/Editor/Pencil.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Resize.svg.in... Remove this comment to see the full error message
import resize from 'icons/SDS/Editor/Resize.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Return.svg.in... Remove this comment to see the full error message
import returnIcon from 'icons/SDS/Editor/Return.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Save.svg.inli... Remove this comment to see the full error message
import save from 'icons/SDS/Editor/Save.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Segment Categ... Remove this comment to see the full error message
import segmentCategorization from 'icons/SDS/Editor/Segment Categorization.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Segment Catig... Remove this comment to see the full error message
import segmentCatigorizationDisabled from 'icons/SDS/Editor/Segment Catigorization Disabled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Segment Reord... Remove this comment to see the full error message
import segmentReorderingDisabled from 'icons/SDS/Editor/Segment Reordering Disabled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Segment Reord... Remove this comment to see the full error message
import segmentReordering from 'icons/SDS/Editor/Segment Reordering.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Sub Item.svg.... Remove this comment to see the full error message
import subItem from 'icons/SDS/Editor/Sub Item.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Text Align Ce... Remove this comment to see the full error message
import textAlignCenter from 'icons/SDS/Editor/Text Align Center.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Text Align Le... Remove this comment to see the full error message
import textAlignLeft from 'icons/SDS/Editor/Text Align Left.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Text Align Ri... Remove this comment to see the full error message
import textAlignRight from 'icons/SDS/Editor/Text Align Right.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Text Vertical... Remove this comment to see the full error message
import textVerticalAlignBottom from 'icons/SDS/Editor/Text Vertical Align Bottom.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Text Vertical... Remove this comment to see the full error message
import textVerticalAlignCenter from 'icons/SDS/Editor/Text Vertical Align Center.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Text Vertical... Remove this comment to see the full error message
import textVerticalAlignTop from 'icons/SDS/Editor/Text Vertical Align Top.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Text.svg.inli... Remove this comment to see the full error message
import text from 'icons/SDS/Editor/Text.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Theme Paint B... Remove this comment to see the full error message
import themePaintBrush from 'icons/SDS/Editor/Theme Paint Brush.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Top Align.svg... Remove this comment to see the full error message
import topAlign from 'icons/SDS/Editor/Top Align.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Transition Cu... Remove this comment to see the full error message
import transitionCurveUp from 'icons/SDS/Editor/Transition Curve Up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Transition Fa... Remove this comment to see the full error message
import transitionFastUp from 'icons/SDS/Editor/Transition Fast Up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Transition Gr... Remove this comment to see the full error message
import transitionGradualUp from 'icons/SDS/Editor/Transition Gradual Up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Transition Ju... Remove this comment to see the full error message
import transitionJumpUp from 'icons/SDS/Editor/Transition Jump Up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Transition Li... Remove this comment to see the full error message
import transitionLinearUp from 'icons/SDS/Editor/Transition Linear Up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Transition.sv... Remove this comment to see the full error message
import transition from 'icons/SDS/Editor/Transition.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Video Edit.sv... Remove this comment to see the full error message
import videoEdit from 'icons/SDS/Editor/Video Edit.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Editor/Wand Circle.s... Remove this comment to see the full error message
import magicWandCircle from 'icons/SDS/Editor/Wand Circle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Ad Marker.svg.... Remove this comment to see the full error message
import adMarker from 'icons/SDS/Media/Ad Marker.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Ad Set.svg.inl... Remove this comment to see the full error message
import adSet from 'icons/SDS/Media/Ad Set.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Ad.svg.inline'... Remove this comment to see the full error message
import ad from 'icons/SDS/Media/Ad.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Browser.svg.in... Remove this comment to see the full error message
import browser from 'icons/SDS/Media/Browser.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Camera.svg.inl... Remove this comment to see the full error message
import camera from 'icons/SDS/Media/Camera.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Disable Advert... Remove this comment to see the full error message
import disableAdvertisment from 'icons/SDS/Media/Disable Advertisment.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Duplicate.svg.... Remove this comment to see the full error message
import duplicate from 'icons/SDS/Media/Duplicate.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Gallery.svg.in... Remove this comment to see the full error message
import gallery from 'icons/SDS/Media/Gallery.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Grid Content.s... Remove this comment to see the full error message
import gridContent from 'icons/SDS/Media/Grid Content.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Image Gallery.... Remove this comment to see the full error message
import imageGallery from 'icons/SDS/Media/Image Gallery.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Media.svg.inli... Remove this comment to see the full error message
import media from 'icons/SDS/Media/Media.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Mobile Video.s... Remove this comment to see the full error message
import mobileVideo from 'icons/SDS/Media/Mobile Video.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Music.svg.inli... Remove this comment to see the full error message
import music from 'icons/SDS/Media/Music.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/News.svg.inlin... Remove this comment to see the full error message
import news from 'icons/SDS/Media/News.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Overlay.svg.in... Remove this comment to see the full error message
import overlay from 'icons/SDS/Media/Overlay.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Phone.svg.inli... Remove this comment to see the full error message
import phone from 'icons/SDS/Media/Phone.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Ring.svg.inlin... Remove this comment to see the full error message
import ring from 'icons/SDS/Media/Ring.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Scan.svg.inlin... Remove this comment to see the full error message
import scan from 'icons/SDS/Media/Scan.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Snapcode.svg.i... Remove this comment to see the full error message
import snapcode from 'icons/SDS/Media/Snapcode.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Sticker.svg.in... Remove this comment to see the full error message
import sticker from 'icons/SDS/Media/Sticker.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Subtitles.svg.... Remove this comment to see the full error message
import subtitles from 'icons/SDS/Media/Subtitles.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Tile with Text... Remove this comment to see the full error message
import tileWithTextLines from 'icons/SDS/Media/Tile with Text Lines.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Tile.svg.inlin... Remove this comment to see the full error message
import tile from 'icons/SDS/Media/Tile.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Media/Video Camera.s... Remove this comment to see the full error message
import videoCamera from 'icons/SDS/Media/Video Camera.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Arrow.svg... Remove this comment to see the full error message
import arrow from 'icons/SDS/Navigation/Arrow.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Chevron D... Remove this comment to see the full error message
import chevronDown from 'icons/SDS/Navigation/Chevron Down.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Chevron L... Remove this comment to see the full error message
import chevronLeft from 'icons/SDS/Navigation/Chevron Left.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Chevron R... Remove this comment to see the full error message
import chevronRight from 'icons/SDS/Navigation/Chevron Right.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Chevron U... Remove this comment to see the full error message
import chevronUp from 'icons/SDS/Navigation/Chevron Up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Context M... Remove this comment to see the full error message
import contextMenu from 'icons/SDS/Navigation/Context Menu.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Ellipsis.... Remove this comment to see the full error message
import ellipsis from 'icons/SDS/Navigation/Ellipsis.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Exit.svg.... Remove this comment to see the full error message
import exit from 'icons/SDS/Navigation/Exit.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/External ... Remove this comment to see the full error message
import externalLink from 'icons/SDS/Navigation/External Link.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Forward.s... Remove this comment to see the full error message
import forward from 'icons/SDS/Navigation/Forward.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Grab Hand... Remove this comment to see the full error message
import grabHandle from 'icons/SDS/Navigation/Grab Handle.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Hamburger... Remove this comment to see the full error message
import hamburger from 'icons/SDS/Navigation/Hamburger.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Home.svg.... Remove this comment to see the full error message
import home from 'icons/SDS/Navigation/Home.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/List.svg.... Remove this comment to see the full error message
import list from 'icons/SDS/Navigation/List.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Logout.sv... Remove this comment to see the full error message
import logout from 'icons/SDS/Navigation/Logout.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Sort.svg.... Remove this comment to see the full error message
import sort from 'icons/SDS/Navigation/Sort.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Top Level... Remove this comment to see the full error message
import topLevelMenu from 'icons/SDS/Navigation/Top Level Menu.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Triangle ... Remove this comment to see the full error message
import triangleDown from 'icons/SDS/Navigation/Triangle Down.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Triangle ... Remove this comment to see the full error message
import triangleLeft from 'icons/SDS/Navigation/Triangle Left.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Triangle ... Remove this comment to see the full error message
import triangleRight from 'icons/SDS/Navigation/Triangle Right.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Navigation/Triangle ... Remove this comment to see the full error message
import triangleUp from 'icons/SDS/Navigation/Triangle Up.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Balloon.svg.in... Remove this comment to see the full error message
import balloon from 'icons/SDS/Other/Balloon.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Basket.svg.inl... Remove this comment to see the full error message
import basket from 'icons/SDS/Other/Basket.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Business.svg.i... Remove this comment to see the full error message
import business from 'icons/SDS/Other/Business.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Cash.svg.inlin... Remove this comment to see the full error message
import cash from 'icons/SDS/Other/Cash.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Color Circles.... Remove this comment to see the full error message
import colorCircles from 'icons/SDS/Other/Color Circles.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Credit Card.sv... Remove this comment to see the full error message
import creditCard from 'icons/SDS/Other/Credit Card.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Face Happy.svg... Remove this comment to see the full error message
import faceHappy from 'icons/SDS/Other/Face Happy.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Face Playful.s... Remove this comment to see the full error message
import facePlayful from 'icons/SDS/Other/Face Playful.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Flash.svg.inli... Remove this comment to see the full error message
import flash from 'icons/SDS/Other/Flash.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/FlashFilled.sv... Remove this comment to see the full error message
import flashFilled from 'icons/SDS/Other/FlashFilled.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Gift.svg.inlin... Remove this comment to see the full error message
import gift from 'icons/SDS/Other/Gift.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Globe.svg.inli... Remove this comment to see the full error message
import globe from 'icons/SDS/Other/Globe.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/IO.svg.inline'... Remove this comment to see the full error message
import io from 'icons/SDS/Other/IO.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Identification... Remove this comment to see the full error message
import identification from 'icons/SDS/Other/Identification.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Lens Explorer.... Remove this comment to see the full error message
import lensExplorer from 'icons/SDS/Other/Lens Explorer.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Megaphone.svg.... Remove this comment to see the full error message
import megaphone from 'icons/SDS/Other/Megaphone.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Party Popper.s... Remove this comment to see the full error message
import partyPopper from 'icons/SDS/Other/Party Popper.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Pause.svg.inli... Remove this comment to see the full error message
import pause from 'icons/SDS/Other/Pause.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Person Man.svg... Remove this comment to see the full error message
import personMan from 'icons/SDS/Other/Person Man.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Person Woman.s... Remove this comment to see the full error message
import personWoman from 'icons/SDS/Other/Person Woman.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Printer.svg.in... Remove this comment to see the full error message
import printer from 'icons/SDS/Other/Printer.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Rocket.svg.inl... Remove this comment to see the full error message
import rocket from 'icons/SDS/Other/Rocket.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Shield.svg.inl... Remove this comment to see the full error message
import shield from 'icons/SDS/Other/Shield.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Shopping Cart.... Remove this comment to see the full error message
import shoppingCart from 'icons/SDS/Other/Shopping Cart.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Spectacles.svg... Remove this comment to see the full error message
import spectacles from 'icons/SDS/Other/Spectacles.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/SubtileAlignBo... Remove this comment to see the full error message
import subtitleAlignBottom from 'icons/SDS/Other/SubtileAlignBottom.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/SubtitleAlignT... Remove this comment to see the full error message
import subtitleAlignTop from 'icons/SDS/Other/SubtitleAlignTop.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Support 2.svg.... Remove this comment to see the full error message
import support2 from 'icons/SDS/Other/Support 2.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Support.svg.in... Remove this comment to see the full error message
import support from 'icons/SDS/Other/Support.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/TV.svg.inline'... Remove this comment to see the full error message
import tv from 'icons/SDS/Other/TV.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Tag.svg.inline... Remove this comment to see the full error message
import tag from 'icons/SDS/Other/Tag.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Topic.svg.inli... Remove this comment to see the full error message
import topic from 'icons/SDS/Other/Topic.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Train.svg.inli... Remove this comment to see the full error message
import train from 'icons/SDS/Other/Train.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Truck.svg.inli... Remove this comment to see the full error message
import truck from 'icons/SDS/Other/Truck.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Other/Up Arrow.svg.i... Remove this comment to see the full error message
import upArrow from 'icons/SDS/Other/Up Arrow.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Available Sto... Remove this comment to see the full error message
import availableStory from 'icons/SDS/Status/Available Story.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Blocker.svg.i... Remove this comment to see the full error message
import blocker from 'icons/SDS/Status/Blocker.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Camera Flip.s... Remove this comment to see the full error message
import cameraFlip from 'icons/SDS/Status/Camera Flip.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Check.svg.inl... Remove this comment to see the full error message
import check from 'icons/SDS/Status/Check.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Deactivate.sv... Remove this comment to see the full error message
import deactivate from 'icons/SDS/Status/Deactivate.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Email Deliver... Remove this comment to see the full error message
import emailDelivered from 'icons/SDS/Status/Email Delivered.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Email Not Del... Remove this comment to see the full error message
import emailNotDelivered from 'icons/SDS/Status/Email Not Delivered.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Email Open.sv... Remove this comment to see the full error message
import emailOpen from 'icons/SDS/Status/Email Open.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Email.svg.inl... Remove this comment to see the full error message
import email from 'icons/SDS/Status/Email.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Hidden.svg.in... Remove this comment to see the full error message
import hidden from 'icons/SDS/Status/Hidden.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Link Broken.s... Remove this comment to see the full error message
import linkBroken from 'icons/SDS/Status/Link Broken.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Link.svg.inli... Remove this comment to see the full error message
import link from 'icons/SDS/Status/Link.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Loading.svg.i... Remove this comment to see the full error message
import loading from 'icons/SDS/Status/Loading.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Lock.svg.inli... Remove this comment to see the full error message
import lock from 'icons/SDS/Status/Lock.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Moon.svg.inli... Remove this comment to see the full error message
import moon from 'icons/SDS/Status/Moon.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Mute.svg.inli... Remove this comment to see the full error message
import mute from 'icons/SDS/Status/Mute.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Pin.svg.inlin... Remove this comment to see the full error message
import locationPin from 'icons/SDS/Status/Pin.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Premium.svg.i... Remove this comment to see the full error message
import premium from 'icons/SDS/Status/Premium.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Recent.svg.in... Remove this comment to see the full error message
import recent from 'icons/SDS/Status/Recent.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Scheduled Sto... Remove this comment to see the full error message
import scheduledStory from 'icons/SDS/Status/Scheduled Story.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Sound Audio.s... Remove this comment to see the full error message
import soundAudio from 'icons/SDS/Status/Sound Audio.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Spinner.svg.i... Remove this comment to see the full error message
import spinner from 'icons/SDS/Status/Spinner.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Story Draft.s... Remove this comment to see the full error message
import storyDraft from 'icons/SDS/Status/Story Draft.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Story Unavail... Remove this comment to see the full error message
import disabledStory from 'icons/SDS/Status/Story Unavailable/Blocked Story/Disabled Story.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Test.svg.inli... Remove this comment to see the full error message
import test from 'icons/SDS/Status/Test.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Trophy.svg.in... Remove this comment to see the full error message
import trophy from 'icons/SDS/Status/Trophy.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Unlock.svg.in... Remove this comment to see the full error message
import unlock from 'icons/SDS/Status/Unlock.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Status/Visible.svg.i... Remove this comment to see the full error message
import visible from 'icons/SDS/Status/Visible.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 1.svg.inl... Remove this comment to see the full error message
import timer1 from 'icons/SDS/Time/Timer 1.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 10.svg.in... Remove this comment to see the full error message
import timer10 from 'icons/SDS/Time/Timer 10.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 2.svg.inl... Remove this comment to see the full error message
import timer2 from 'icons/SDS/Time/Timer 2.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 3.svg.inl... Remove this comment to see the full error message
import timer3 from 'icons/SDS/Time/Timer 3.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 4.svg.inl... Remove this comment to see the full error message
import timer4 from 'icons/SDS/Time/Timer 4.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 5.svg.inl... Remove this comment to see the full error message
import timer5 from 'icons/SDS/Time/Timer 5.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 6.svg.inl... Remove this comment to see the full error message
import timer6 from 'icons/SDS/Time/Timer 6.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 7.svg.inl... Remove this comment to see the full error message
import timer7 from 'icons/SDS/Time/Timer 7.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 8.svg.inl... Remove this comment to see the full error message
import timer8 from 'icons/SDS/Time/Timer 8.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer 9.svg.inl... Remove this comment to see the full error message
import timer9 from 'icons/SDS/Time/Timer 9.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer Blank.svg... Remove this comment to see the full error message
import timerBlank from 'icons/SDS/Time/Timer Blank.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/SDS/Time/Timer.svg.inlin... Remove this comment to see the full error message
import timer from 'icons/SDS/Time/Timer.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/delete.svg.inline' or it... Remove this comment to see the full error message
import deleteIcon from 'icons/delete.svg.inline';
// @ts-expect-error ts-migrate(2307) FIXME: Cannot find module 'icons/delete.svg.inline' or it... Remove this comment to see the full error message
import deleteIconMap from 'icons/deleteMapArea.svg.inline';

export {
  checkCircle,
  crossCircle,
  gear,
  assignToAll,
  notificationBell,
  help,
  supportChat,
  warningCircle,
  calendar,
  warningTriangle,
  flag,
  campaign,
  question,
  faceHappy,
  business,
  flash,
  flashFilled,
  balloon,
  globe,
  personWoman,
  shoppingCart,
  colorCircles,
  tv,
  facePlayful,
  gift,
  identification,
  truck,
  train,
  megaphone,
  shield,
  support2,
  pause,
  personMan,
  partyPopper,
  creditCard,
  cash,
  io,
  upArrow,
  lensExplorer,
  support,
  tag,
  topic,
  basket,
  spectacles,
  rocket,
  ellipsis,
  arrow,
  hamburger,
  chevronDown,
  triangleRight,
  home,
  triangleDown,
  exit,
  chevronUp,
  topLevelMenu,
  contextMenu,
  sort,
  forward,
  list,
  chevronLeft,
  chevronRight,
  grabHandle,
  logout,
  externalLink,
  triangleUp,
  triangleLeft,
  mute,
  availableStory,
  email,
  locationPin,
  check,
  unlock,
  emailOpen,
  lock,
  emailNotDelivered,
  spinner,
  linkBroken,
  test,
  disabledStory,
  visible,
  cameraFlip,
  deactivate,
  trophy,
  moon,
  storyDraft,
  scheduledStory,
  blocker,
  soundAudio,
  premium,
  recent,
  emailDelivered,
  link,
  loading,
  hidden,
  timer1,
  timer9,
  timer4,
  timer3,
  timer10,
  timer6,
  timer8,
  timer5,
  timer,
  timer7,
  timer2,
  timerBlank,
  archive,
  search,
  plus,
  rectangleFilled,
  download,
  zoomIn,
  filterFilled,
  pin,
  circleFilled,
  cross,
  folder,
  noReplay,
  upload,
  minus,
  trash,
  tree,
  zoomOut,
  send,
  circle,
  message,
  profileSingle,
  profileMultiple,
  subscribeFilled,
  cut,
  starFavoriteFilled,
  undo,
  sendOutlined,
  chart,
  heart,
  play,
  screenshot,
  subscribe,
  playFilled,
  starFavorite,
  createGroup,
  redo,
  messageFilled,
  replay,
  attachment,
  heartFilled,
  filter,
  profileMinus,
  rectangle,
  profilePlus,
  cursor,
  textAlignLeft,
  grid,
  paperAndPencil,
  save,
  segmentCatigorizationDisabled,
  transition,
  keyFrame,
  alignBottom,
  segmentReorderingDisabled,
  videoEdit,
  contrast,
  loop,
  returnIcon,
  themePaintBrush,
  text,
  eyeDrop,
  textAlignRight,
  textAlignCenter,
  transitionGradualUp,
  pencil,
  alignCenter,
  transitionCurveUp,
  textVerticalAlignBottom,
  art,
  subItem,
  textVerticalAlignTop,
  keyframeFilled,
  segmentReordering,
  resize,
  segmentCategorization,
  magicWand,
  magicWandCircle,
  transitionFastUp,
  textVerticalAlignCenter,
  transitionJumpUp,
  topAlign,
  droplet,
  transitionLinearUp,
  duplicate,
  mobileVideo,
  music,
  gridContent,
  media,
  gallery,
  disableAdvertisment,
  tile,
  adSet,
  scan,
  ad,
  adMarker,
  tileWithTextLines,
  browser,
  snapcode,
  sticker,
  subtitles,
  phone,
  ring,
  imageGallery,
  camera,
  videoCamera,
  news,
  overlay,
  printer,
  subtitleAlignBottom,
  subtitleAlignTop,
  deleteIcon,
  deleteIconMap,
};
