import { Schema, valuesOf, arrayOf } from 'normalizr';

import { tileSchema } from 'state/tiles/schema/tilesSchema';

export const richSnapSchema = new Schema('richSnap', { idAttribute: 'id' });

richSnapSchema.define({
  relatedSnaps: valuesOf(richSnapSchema),
});

export const discoverSnapSchema = new Schema('discoverSnap', { idAttribute: 'id' });

discoverSnapSchema.define({
  tiles: arrayOf(tileSchema),
});
