import { createSelector as s } from 'reselect';

import { createKeySelector } from 'state/common/selectorFactories';

import type { MediaItem } from 'types/mediaLibrary';

function getMediaLibraryTrayData(state: any) {
  return state.mediaLibraryTray;
}

export const getSelectedMediaList: (a: any) => MediaItem[] = createKeySelector(
  getMediaLibraryTrayData,
  'selectedMediaList',
  []
);

export const getSelectedMediaIdSet = s(getSelectedMediaList, mediaList => {
  const map: {
    [x: string]: boolean;
  } = {};
  mediaList.forEach(media => {
    map[media.id] = true;
  });
  return map;
});

export const getLastSelectedMedia: (a: any) => MediaItem | undefined | null = s(getSelectedMediaList, mediaList => {
  if (mediaList.length === 0) {
    return null;
  }
  return mediaList[mediaList.length - 1];
});
