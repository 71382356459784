import { createSelector as s } from 'reselect';

import { AssociatedUser } from 'state/auth/authState';
import { createKeySelector } from 'state/common/selectorFactories';
import { getUserInfo } from 'state/user/selectors/userSelectors';

import { AuthType, AuthTypeEnum, EMPTY_OBJECT, UNIFIED_LOGIN_ROLLOUT_PERCENTAGE } from 'config/constants';
import { State } from 'src/types/rootState';

function getAuthState(state: State) {
  return state.auth || EMPTY_OBJECT;
}

export const getAuthLoaded = createKeySelector(getAuthState, 'loaded', null);
export const getAuthLoading = createKeySelector(getAuthState, 'loading', null);
export const getAuthType = createKeySelector<AuthTypeEnum | null>(getAuthState, 'authType', null);
export const getUser = createKeySelector(getAuthState, 'googleUser', null);
export const getAuthFailed = createKeySelector(getAuthState, 'authFailed', false);
export const getAuthAge = createKeySelector(getAuthState, 'authAge', 0);
export const lastRichsnapToken = createKeySelector(getAuthState, 'lastRichsnapToken', null);

export const getAuthRefreshLoop = createKeySelector(getAuthState, 'authRefreshLoop', EMPTY_OBJECT);
export const authRefreshLoopId = createKeySelector(getAuthRefreshLoop, 'activeLoopId', 1);

// GoogleAuth related selectors
export const getUsername = createKeySelector(getUser, 'name', '');
export const getUserId = createKeySelector(getUser, 'id', null);
export const getUserAuth = createKeySelector(getUser, 'auth', null);
export const getGoogleToken = createKeySelector(getUserAuth, 'access_token', null);
export const getGapi = createKeySelector(getAuthState, 'gapi', EMPTY_OBJECT);
export const gapiIsLoaded = createKeySelector(getGapi, 'loaded', false);
export const gapiFailedToLoad = createKeySelector(getGapi, 'failed', false);

export const isGoogleAuthLoading = s(
  getAuthLoading,
  getAuthLoaded,
  gapiIsLoaded,
  gapiFailedToLoad,
  getUserInfo,
  (authLoading, authLoaded, gapiLoaded, gapiFailedLoading, userInfo) => {
    return !!userInfo || authLoading || authLoaded || (!gapiLoaded && !gapiFailedLoading);
  }
);

// SnapAuth related selectors
const getSnapAuth = createKeySelector(getAuthState, 'snapAuth', null);
export const getSnapTicket = createKeySelector(getSnapAuth, 'ticket', EMPTY_OBJECT);
export const getSnapUsername = createKeySelector(getSnapAuth, 'snapUsername', null);

export const getToken = s(getAuthType, getGoogleToken, getSnapTicket, (authType, googleToken, snapTicket) => {
  if (authType === AuthType.SNAPCHAT) {
    return snapTicket;
  }
  return googleToken;
});

// Associated user selectors
export const getAssociatedUser: (state: State) => AssociatedUser | null = createKeySelector(
  getAuthState,
  'associatedUser',
  null
);
export const getAssociatedUserToken = createKeySelector(getAssociatedUser, 'token', null);
export const getAssociatedUsername = createKeySelector(getAssociatedUser, 'name', null);
export const getAssociatedPublisher = createKeySelector(getAssociatedUser, 'businessProfileId', null);
export const getIsAssociating = createKeySelector(getAssociatedUser, 'isAssociating', false);
export const getShouldUseUnifiedLogin = s(getToken, token => {
  if (!token) {
    return false;
  }

  // Calculate the numeric hash using DJB2 algorithm based on auth token to bucket users into 2 groups.
  // unifiedLoginRolloutPerc of users will be bucketed into the group that uses unified login.
  // remaining will continue using legacy login.
  const numericHash =
    token
      .toString()
      .split('')
      // 0xffffffff is the max 32 bit unsigned integer, prevents overflow
      .reduce((hash, currChar) => (Math.imul(31, hash) + currChar.charCodeAt(0)) & 0xffffffff, 0) >>> 0;
  return numericHash % 100 < UNIFIED_LOGIN_ROLLOUT_PERCENTAGE;
});
