import is from 'is_js';
import { normalize } from 'normalizr';
import { combineReducers } from 'redux';

import { createTransactionalSequenceHandlingReducer, serializeReducers } from 'state/common/reducerFactories';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import * as publishersActions from 'state/publishers/actions/publishersActions';
import * as scsStoryActions from 'state/scs/actions/storyActions';

import { TransactionType } from 'config/constants';
import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

import {
  CREATE_STORY,
  GET_EDITION,
  SAVE_EDITION,
  SET_EDITION_PROPERTIES,
  SET_EDITION_STATE,
} from 'types/actions/editionsActionTypes';
import {
  CREATE_SNAP,
  LOAD_SNAP,
  LOAD_SNAPS,
  SET_SNAP_PROPERTIES,
  SET_SNAP_PROPERTIES_AND_SAVE,
} from 'types/actions/snapsActionsTypes';

const transactionHandler = {
  start(state: any, action: any) {
    return state;
  },

  done(state: any, action: any) {
    if (action.error) {
      return state;
    }

    const originalTiles = action.payload.entities.tile;
    if (is.existy(originalTiles)) {
      const constantTiles = wrapEntitiesInUpdeepConstants(originalTiles);
      return u(constantTiles, state);
    }
    return state;
  },
};

const loadSaveTileDataHandler = serializeReducers([
  createTransactionalSequenceHandlingReducer(
    {},
    TransactionType.SET_SNAP_PROPERTY,
    [CREATE_SNAP, SET_SNAP_PROPERTIES_AND_SAVE, LOAD_SNAP, LOAD_SNAPS],
    transactionHandler
  ),
  createTransactionalSequenceHandlingReducer(
    {},
    TransactionType.SET_EDITION_PROPERTY,
    [
      GET_EDITION,
      CREATE_STORY,
      SAVE_EDITION,
      scsStoryActions.UPDATE_STORY,
      SET_EDITION_STATE,
      publishersActions.GET_LIVE_EDITIONS,
      publishersActions.GET_ACTIVE_EDITIONS,
      publishersActions.GET_UNAVAILABLE_EDITIONS,
      publishersActions.GET_HOMEPAGE_STORIES,
      publishersActions.GET_SCHEDULED_LIVE_STORIES,
    ],
    transactionHandler
  ),
]);

const byId = (state = {}, action: any) => {
  switch (action.type) {
    case SET_EDITION_PROPERTIES: {
      // Edition at this point is denormalized. We must normalize it to get to edition tiles and segment tiles.
      const normalized = normalize(action.payload, editionSchema);
      if ('result' in normalized) {
        return u(wrapEntitiesInUpdeepConstants(normalized.entities.tile), state);
      }

      return state;
    }
    case SET_SNAP_PROPERTIES:
      return u(wrapEntitiesInUpdeepConstants(action.payload.entities.tile), state);
    default:
      break;
  }
  return loadSaveTileDataHandler(state, action);
};

export default combineReducers({
  byId,
});
