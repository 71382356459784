import { find } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { showModal } from 'state/modals/actions/modalsActions';

import { reload } from 'utils/browserUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { ModalType } from 'utils/modalConfig';

import { DialogModalOptions } from 'views/common/components/DialogModal/DialogModal';

const APP_JS = /.*\/app\.[^.]*\.js/;
// 6 hours
export const VERSION_CHECK_PERIOD = 6 * 60 * 60 * 1000;
let isMessageDisplayed = false;
/**
 * How this works?
 * We will periodically try to make a head request for the app.[HASH].js,
 * If the request returns 404, that means the story studio is on a new version,
 * and the browser needs to be refreshed. We will display the dialog to prompt
 * the user to refresh.
 */
export function startCheckingForTheLatestStoryStudioVersion(store: any, fetchFn: any = fetch) {
  setInterval(() => checkLatestVersion(store, fetchFn), VERSION_CHECK_PERIOD);
}
// Exported just for testing
export function checkLatestVersion(store: any, fetchFn: any) {
  if (!isMessageDisplayed) {
    return fetchIsLatestStoryStudioVersion(fetchFn).then(
      isLatestVersion => {
        if (isLatestVersion) {
          return null;
        }
        incrementMetricsCounter('messageShown');
        return displayModal(store);
      },
      () => {
        incrementMetricsCounter('error.failedToCheckVersion');
      }
    );
  }
  return Promise.resolve();
}
function fetchIsLatestStoryStudioVersion(fetchFn: any): Promise<boolean> {
  const scriptElements = document.getElementsByTagName('script') || [];
  const element = find(scriptElements, scriptElement => scriptElement.src.match(APP_JS));
  return new Promise<boolean>((resolve, reject) => {
    if (!element) {
      incrementMetricsCounter('error.appNotFound');
      resolve(true);
    }
    fetchFn((element as any).src, {
      method: 'HEAD',
    }).then(
      (response: any) => {
        const responseStatus = response.status || 0;
        incrementMetricsCounter(`fetch.byStatus.${responseStatus}`);
        // If we could not fetch the app.js, that means we are on the newer version of story studio
        resolve(responseStatus !== 404);
      },
      (error: any) => {
        reject(error);
      }
    );
  });
}

function displayModal(store: any) {
  isMessageDisplayed = true;

  const options: DialogModalOptions = {
    visible: true,
    onConfirm: () => {
      incrementMetricsCounter('refresh.confirm');
      isMessageDisplayed = false;
      reload();
    },
    onCancel: () => {
      isMessageDisplayed = false;
      incrementMetricsCounter('refresh.cancel');
    },
    okText: (
      <FormattedMessage
        id="version-check-prompt-confirm"
        defaultMessage="Refresh"
        description="Button caption to confirm page refresh"
      />
    ),
    cancelText: (
      <FormattedMessage
        id="version-check-prompt-cancel"
        defaultMessage="Not now"
        description="Button caption to dismiss the refresh page dialog"
      />
    ),
    title: (
      <FormattedMessage
        id="version-check-prompt-title"
        defaultMessage="Refresh"
        description="Headline at refresh page dialog"
      />
    ),
    body: (
      <FormattedMessage
        id="version-check-prompt-message-line"
        defaultMessage="A New version of Story Studio is now available, please refresh your browser to use it."
        description="Message to prompt users to refresh the browser"
      />
    ),
  };

  return store.dispatch(showModal(ModalType.DIALOG, 'VersionChecker', options));
}
function incrementMetricsCounter(metricName: string) {
  incrementCounter(`ApplicationVersionCheck.${metricName}`);
}
