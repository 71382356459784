// @flow
import { get, memoize } from 'lodash';
import { createSelector as s } from 'reselect';

import { createKeySelector, createDynamicKeySelector } from 'state/common/selectorFactories';

import { EMPTY_OBJECT } from 'config/constants';

import type { State } from 'types/rootState';

function getInventoryBundles(state: State) {
  return get(state, ['inventory']) || {};
}
export const getPublisherInventoryItemByBpidMap = createKeySelector(
  getInventoryBundles,
  'inventoryItemByBpid',
  EMPTY_OBJECT
);
export const getInventoryBundlesByIdMap = createKeySelector(getInventoryBundles, 'inventoryBundlesById', EMPTY_OBJECT);
export const getInventoryBundleItemMappingsByBundleIdMap = createKeySelector(
  getInventoryBundles,
  'mappingsByBundleId',
  EMPTY_OBJECT
);
export const isInventoryDataLoading = createKeySelector(getInventoryBundles, 'isLoadingInventoryData', false);
export const getActiveInventoryBundlesAge = createKeySelector(getInventoryBundles, 'bundlesByIdAge', 0);
export const getPublisherInventoryItemByBpid = createDynamicKeySelector<any | undefined | null, string>(
  getPublisherInventoryItemByBpidMap,
  null
);
const getActiveInventoryBundlesByNameMapFunc = memoize(inventoryBundlesByIdMap => {
  const inventoryBundlesMap = {};
  Object.values(inventoryBundlesByIdMap).reduce((acc, bundle) => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    inventoryBundlesMap[(bundle as any).displayName] = bundle;
    return inventoryBundlesMap;
  }, EMPTY_OBJECT);
  return inventoryBundlesMap;
});
export const getActiveInventoryBundlesByNameMap = s(getInventoryBundlesByIdMap, inventoryBundlesByIdMap =>
  getActiveInventoryBundlesByNameMapFunc(inventoryBundlesByIdMap)
);
