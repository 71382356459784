export const getPathName = (): string => {
  // NOTE: The undefined check here covers cases where a call to getPathName() is made from a
  //       Selenium test runner, which is a Node process and so doesn't have the Window object.
  if (typeof window !== 'undefined') {
    return window.location.pathname;
  }

  return '';
};

// @ts-expect-error ts-migrate(2556) FIXME: Expected 1 arguments, but got 2 or more.
export const replaceLocation = (url: string, ...args: any) => window.location.replace(url, ...args);

export const assignLocation = (url: string) => window.location.assign(url);

export const openInNewWindow = (url: string) => window.open(url, '_blank');

export const isLocalhost = (): boolean => window.location.hostname === 'localhost';

export const getHostname = () => {
  return `${window.location.protocol}//${window.location.host}`;
};

export const getLocationHref = () => {
  return window.location.href;
};

export const getOriginHref = () => {
  return window.location.origin;
};

export const redirectToUrl = (url: string) => {
  // eslint-disable-next-line no-restricted-globals
  location.href = url;
};

export const printWindow = () => window.print();

export const isAcceptanceTestRunHref = () => {
  return getLocationHref().includes('seleniumtest');
};
