import { parseLangFile } from './i18nLangBuilder';

export type LocalesMap = Map<string, string>;

export const locales: LocalesMap = new Map([
  ['en', 'en-US'],
  ['fr', 'fr-FR'],
  ['de', 'de-DE'],
  ['ar', 'ar'],
  ['es', 'es-MX'],
  ['nl', 'nl-NL'],
  ['no', 'nb-NO'],
  ['se', 'sv-SE'],
  ['ja', 'ja-JP'],
]);

export const getLocaleFileName = (localeId: string) => {
  return locales.has(localeId) ? locales.get(localeId) : locales.get('en');
};

export async function fetchLocaleJson(localeId: string) {
  const fileName = getLocaleFileName(localeId);
  const languageFile = await import(`/lang/${fileName}.json`);
  const json = await languageFile.default;
  return parseLangFile(json);
}
