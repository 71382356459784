import Checkbox from 'antd/lib/checkbox';
import React from 'react';
import 'antd/lib/checkbox/style/css';
import type { ReactNode } from 'react';

import style from './SDSCheckbox.scss';

export type CheckboxEvent<T> = {
  target: {
    checked: boolean;
    value: T;
  };
  shiftKey?: boolean;
  preventDefault: () => void;
};

export type Props<T> = {
  children?: ReactNode;
  onChange: (event: CheckboxEvent<T>) => void;
  checked?: boolean;
  disabled?: boolean;
  value?: T;
  defaultChecked?: boolean;
};

export class SDSCheckbox<T> extends React.PureComponent<Props<T>> {
  render() {
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <Checkbox {...this.props} className={style.checkbox}>
        {this.props.children}
      </Checkbox>
    );
  }
}

export default SDSCheckbox;
