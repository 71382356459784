import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { List } from 'react-virtualized';
// @ts-expect-error ts-migrate(2305) FIXME: Module '"react-virtualized"' has no exported membe... Remove this comment to see the full error message
import type { RowRendererParams } from 'react-virtualized';

import { Key } from 'config/constants';

import PublisherOption from 'views/dashboard/components/PublisherOption/PublisherOption';

import styles from './PublisherList.scss';

import type { PublisherWithGroup } from 'types/publishers';

type Props = {
  filteredList: Array<PublisherWithGroup>;
  optionSelected: (a: PublisherWithGroup) => void;
  isDebugEntityViewer: boolean;
};

type State = {
  scrollIndex: number | typeof undefined;
};

const MAX_ROWS = 7;
const ROW_HEIGHT = 40;
const LIST_WIDTH = 320;

class PublisherList extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      scrollIndex: 0,
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.filteredList.length !== prevProps.filteredList.length) {
      this.setState({ scrollIndex: 0 }); // eslint-disable-line
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    const index = this.state.scrollIndex === undefined ? 0 : this.state.scrollIndex;
    const list = this.props.filteredList;

    switch (e.key) {
      case Key.ARROW_UP:
        e.preventDefault();

        if (index > 0) {
          let i = 1;

          if (index > 1) {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            i = list[index - 1].isGroup ? 2 : 1;
          }

          this.setState({ scrollIndex: index - i });
        }
        break;
      case Key.ARROW_DOWN:
        if (e.key === Key.ARROW_DOWN) {
          e.preventDefault();

          if (index < list.length - 1) {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            const i = list[index + 1].isGroup ? 2 : 1;

            this.setState({ scrollIndex: index + i });
          }
        }
        break;
      case Key.ENTER:
        if (e.key === 'Enter') {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          if (index < list.length && !list[index].isGroup) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PublisherWithGroup | undefined' ... Remove this comment to see the full error message
            this.props.optionSelected(list[index]);
          }
        }
        break;
      default:
    }
  };

  mouseEnterOption = (index: number) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (!this.props.filteredList[index].isGroup) {
      this.setState({ scrollIndex: index });
    } else {
      this.setState({ scrollIndex: 0 });
    }
  };

  handleMouseLeaveDropdown = () => {
    this.setState({ scrollIndex: undefined });
  };

  calcListHeight() {
    if (!this.props.filteredList.length) {
      return ROW_HEIGHT;
    }

    if (this.props.filteredList.length < MAX_ROWS) {
      return ROW_HEIGHT * this.props.filteredList.length;
    }

    return ROW_HEIGHT * MAX_ROWS;
  }

  rowStylePicker(index: number) {
    if (index === this.state.scrollIndex && index > 0) {
      return classNames(styles.option, styles.scrollIndex);
    }

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return this.props.filteredList[index].isGroup ? styles.group : styles.option;
  }

  handleOptionSelected = (option: PublisherWithGroup) => () => this.props.optionSelected(option);

  handleMouseEnterOption = (index: number) => () => this.mouseEnterOption(index);

  rowRenderer = ({
    key,
    index,
    isScrolling,
    isVisible,

    style,
  }: RowRendererParams) => {
    if (!this.props.filteredList.length) {
      return null;
    }

    return (
      <div
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'PublisherWithGroup | undefined' ... Remove this comment to see the full error message
        onClick={this.handleOptionSelected(this.props.filteredList[index])}
        onMouseEnter={this.handleMouseEnterOption(index)}
        key={key}
        style={style}
        className={this.rowStylePicker(index)}
        data-test="publisherList.publisherOption"
      >
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <PublisherOption
          data-test="PublisherList.PublisherOption"
          {...this.props.filteredList[index]}
          isDebugEntityViewer={this.props.isDebugEntityViewer}
          showDebugButton={this.state.scrollIndex === index}
        />
      </div>
    );
  };

  noRowsRenderer() {
    return (
      <div>
        <span className={styles.noResults} data-test="PublisherList.noPublishers">
          <FormattedMessage
            id="publisher-list-no-results-found"
            description="No publishers were found using the current filter text"
            defaultMessage="No results found"
          />
        </span>
      </div>
    );
  }

  render() {
    return (
      <div onMouseLeave={this.handleMouseLeaveDropdown}>
        <List
          width={LIST_WIDTH}
          height={this.calcListHeight()}
          rowCount={this.props.filteredList.length}
          rowHeight={ROW_HEIGHT}
          rowRenderer={this.rowRenderer}
          noRowsRenderer={this.noRowsRenderer}
          className={styles.list}
          scrollToIndex={this.state.scrollIndex}
          data-test="PublisherList.List"
        />
      </div>
    );
  }
}

export default PublisherList;
