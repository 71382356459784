// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import global from 'global';

export function toFormData(obj: any) {
  const formData = new global.FormData();

  Object.keys(obj).forEach(key => {
    formData.append(key, obj[key]);
  });

  return formData;
}
