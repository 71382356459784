/* eslint-disable space-infix-ops */

export const DEFAULT_ADS_MIN_SLOTS = 3;
export const DEFAULT_ADS_MAX_SLOTS = 5;
export const DEFAULT_ADS_MIN_SPACING = 4;
export const DEFAULT_ADS_LATEST_FIRST_SLOT = 5;
export const INFINITE_ADS_ID = -1;

export const AdvancedAdsState = {
  VALID: 'VALID',
  INVALID_MIN_SLOTS: 'INVALID_MIN_SLOTS',
  INVALID_MAX_SLOTS: 'INVALID_MAX_SLOTS',
  INVALID_MIN_SPACING: 'INVALID_MIN_SPACING',
  INVALID_LATEST_FIRST_SLOT: 'INVALID_LATEST_FIRST_SLOT',
};
