import _ from 'lodash';
import { createSelector as s } from 'reselect';

import { createKeySelector, createDynamicKeySelector } from 'state/common/selectorFactories';

import { DEFAULT_VOLUME } from 'config/constants';
import { State } from 'src/types/rootState';

import { SnapId } from 'types/common';

const getPreviews = (state: State) => {
  return state.previews || {};
};
const getSnapPreviewsMetadata = createKeySelector(getPreviews, 'snapPreviews', {});
export const getSnapPreviews = s(getSnapPreviewsMetadata, snapPreviews => {
  return _.mapValues(snapPreviews, preview => (preview as any).result || null);
});
export const getSnapPreviewMetadataById = createDynamicKeySelector(getSnapPreviewsMetadata, {});
export const getSnapPreviewById = createDynamicKeySelector<string | null, SnapId>(getSnapPreviews, null);
export const isPreviewMuted = createKeySelector(getPreviews, 'isPreviewMuted', false);
export const getPreviewVolume = createKeySelector(getPreviews, 'previewVolume', DEFAULT_VOLUME);
