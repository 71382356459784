// Wait 5 seconds for the initial page load, then send metrics
// TODO: wait for some "content loaded" or "content interactive" marker instead of 5sec
// TODO: batch metrics

let queue: any = [];
const timeout = setTimeout(process, 5000);

// exported for testing
export function process(reset?: boolean) {
  clearTimeout(timeout);
  if (queue) {
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
    queue.forEach(item => item());
  }
  queue = reset ? [] : null;
}

export function enqueue(work: () => unknown) {
  if (queue) {
    queue.push(work);
  } else {
    work();
  }
}
