import { get, uniq } from 'lodash';

import { isAdvancedCurationEnabled } from 'state/features/selectors/featuresSelectors';
import { isDynamicEditionsPublisher } from 'state/publishers/schema/publisherEntityHelpers';
import { getPublisherDetailsDataById } from 'state/publishers/selectors/publishersSelectors';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';

import {
  DEFAULT_ADS_LATEST_FIRST_SLOT,
  DEFAULT_ADS_MAX_SLOTS,
  DEFAULT_ADS_MIN_SLOTS,
  DEFAULT_ADS_MIN_SPACING,
} from 'config/adsConfig';
import { EMPTY_STORY_SNAPS, INITIAL_STORY_SNAPS } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { isInfiniteAdsEnabled } from 'utils/publisherSettings/publisherSettingsUtils';

import type { SnapId } from 'types/common';
import { assertAllSnapIds } from 'types/common';
import { Publisher, PublisherID, TierLevel } from 'types/publishers';
import type { State } from 'types/rootState';
import { TopSnap } from 'types/snaps';

// This fallback ad slots config come from the Angular code and it's what we've been using since the beginning.
// @ts-expect-error ts-migrate(4104) FIXME: The type 'readonly number[]' is 'readonly' and can... Remove this comment to see the full error message
const FALLBACK_AD_SLOTS: number[] = Object.freeze([3, 2, 2, 3, 3, 3, 3]);
// @ts-expect-error ts-migrate(4104) FIXME: The type 'readonly never[]' is 'readonly' and cann... Remove this comment to see the full error message
const EMPTY_AD_SLOTS: number[] = Object.freeze([]);

/**
 * This is required because any index smaller than 1 is invalid
 *  0 is an invalid position for an ad on the clients (both iOS and Android)
 *  https://jira.sc-corp.net/browse/BRO-12960
 *
 * @param adSlotIndexes - Array<int>
 * @returns Array<int>
 */
export function sanitizeAdSlotIndexes(adSlotIndexes: number[] = []): number[] {
  return uniq(
    adSlotIndexes.filter(adSlotIndex => {
      return adSlotIndex > 0;
    })
  );
}

function getDefaultAdSlots(publisher?: Publisher | null): number[] {
  if (!publisher) {
    return FALLBACK_AD_SLOTS;
  }

  if (!publisher.advertisingEnabled) {
    return EMPTY_AD_SLOTS;
  }

  if (!publisher.advancedAdsEnabled && !isInfiniteAdsEnabled(publisher)) {
    return get(publisher, 'defaultAdSlots', FALLBACK_AD_SLOTS);
  }

  const advancedAdsMaxSlots = publisher.advancedAdsMaxSlots || DEFAULT_ADS_MAX_SLOTS;
  const advancedAdsLatestFirstSlot = publisher.advancedAdsLatestFirstSlot || DEFAULT_ADS_LATEST_FIRST_SLOT;
  const advancedAdsMinSpacing = publisher.advancedAdsMinSpacing || DEFAULT_ADS_MIN_SPACING;

  const adSlots = [];
  for (let i = 0; i < advancedAdsMaxSlots; i++) {
    if (i === 0) {
      // Set the first ad to be minSpacing away unless the latestFirstSlot rule says the first ad must be placed earlier
      adSlots.push(Math.min(advancedAdsLatestFirstSlot, advancedAdsMinSpacing));
    } else {
      // Use the minimum set spacing as the gap between ads
      adSlots.push(advancedAdsMinSpacing);
    }
  }

  return adSlots;
}

export function getMinSnapsToSatisfyAdRules(getState: () => State, publisherId: PublisherID): number {
  const publisher: Publisher | undefined | null = getPublisherDetailsDataById(getState())(publisherId);
  const isAdvancedCurator = isAdvancedCurationEnabled(getState());
  return getMinSnapsToSatisfyAdRulesByPublisher(publisher, isAdvancedCurator);
}

export function isDefaultNumSnapsAllowed(publisher: Publisher, isAdvancedCurator: boolean): boolean {
  if (publisher.tier === TierLevel.DYNAMIC_EDITIONS) {
    return false;
  }
  return (!publisher.advancedAdsEnabled && !isInfiniteAdsEnabled(publisher)) || isAdvancedCurator;
}

export function getMinSnapsToSatisfyAdRulesByPublisher(
  publisher?: Publisher | null,
  isAdvancedCurator: boolean = false
): number {
  if (!publisher) {
    return INITIAL_STORY_SNAPS;
  }

  if (isDynamicEditionsPublisher(publisher)) {
    return EMPTY_STORY_SNAPS;
  }

  if (isDefaultNumSnapsAllowed(publisher, isAdvancedCurator)) {
    return publisher.defaultNumSnaps || INITIAL_STORY_SNAPS;
  }

  const advancedAdsMinSlots = publisher.advancedAdsMinSlots || DEFAULT_ADS_MIN_SLOTS;
  const defaultAdSlots = getDefaultAdSlots(publisher);

  let minSnaps = 1;

  for (let i = 0; i < Math.min(advancedAdsMinSlots, defaultAdSlots.length); i++) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    minSnaps += defaultAdSlots[i];
  }
  return Math.max(minSnaps, publisher.defaultNumSnaps || INITIAL_STORY_SNAPS);
}

export function getUpdatedAdSnapIndexesAfterRemovingSnap(
  getState: () => State,
  snapIds: SnapId[],
  adSnapIndexes: number[]
): number[] | undefined | null {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(getState).is.function();
  assertAllSnapIds(snapIds);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(adSnapIndexes).is.all.number();

  const maxAdSnapIndex = getMaxAdSnapIndex(getState, snapIds);
  const updatedAdSnapIndexes = adSnapIndexes.filter(index => index <= maxAdSnapIndex);

  if (updatedAdSnapIndexes.length !== adSnapIndexes.length) {
    return sanitizeAdSlotIndexes(updatedAdSnapIndexes);
  }

  return null;
}

function editionHasSubscribeSnap(getState: () => State, snapIds: SnapId[]): boolean {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(getState).is.function();
  assertAllSnapIds(snapIds);

  if (snapIds.length) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId | undefined' is not assig... Remove this comment to see the full error message
    const lastSnap = snapsSelectors.getSnapById(getState())(snapIds[snapIds.length - 1]) as TopSnap;

    if (lastSnap) {
      return snapEntityHelpers.isSubscribeSnap(lastSnap);
    }

    throw new Error(
      'Last snap did not exist in the store when trying to determine whether ad snap index should be added'
    );
  }

  return false;
}

function getMaxAdSnapIndex(getState: () => State, snapIds: SnapId[]): number {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(getState).is.function();
  assertAllSnapIds(snapIds);

  return getMaxAdSnapIndexImpl(snapIds.length, editionHasSubscribeSnap(getState, snapIds));
}

function getMaxAdSnapIndexImpl(numberOfSnaps: number, hasSubscribeSnap: boolean): number {
  // Should not count last snap or subscribe snap as no ads should go after these
  return numberOfSnaps - (hasSubscribeSnap ? 2 : 1);
}

function getSimpleAdSlots(publisher: Publisher, numberOfSnaps: number, hasSubscribeSnap: boolean): number[] {
  const maxAdSnapIndex = getMaxAdSnapIndexImpl(numberOfSnaps, hasSubscribeSnap);
  const adIndexes = [...publisher.defaultAdSlots.slice(0, publisher.defaultAdSlots.length - 1)];

  // If the element doesn't exist, insert ads after every second snap
  let sum = adIndexes[0] || 2;
  let i = 1;
  const indexes = [];
  while (sum <= maxAdSnapIndex) {
    indexes.push(sum);
    sum += adIndexes[i] || 2;

    if (i < adIndexes.length - 1) {
      i++;
    }
  }

  return indexes;
}

export function generateNewAdIndexes(publisher: Publisher, numberOfSnaps: number, hasSubscribeSnap: boolean): number[] {
  if (!publisher.advancedAdsEnabled && isInfiniteAdsEnabled(publisher)) {
    return getSimpleAdSlots(publisher, numberOfSnaps, hasSubscribeSnap);
  }

  const maxAdSnapIndex = getMaxAdSnapIndexImpl(numberOfSnaps, hasSubscribeSnap);
  let currentAdIndex = 0;
  const adSlotIndexes = getDefaultAdSlots(publisher)
    .map(adSlot => {
      currentAdIndex += adSlot;
      return currentAdIndex;
    })
    .filter(adSlot => adSlot <= maxAdSnapIndex);
  return sanitizeAdSlotIndexes(adSlotIndexes);
}
