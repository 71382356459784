import { createSelector as s } from 'reselect';

import {
  createByIdKeySelector,
  createByIdSelector,
  createKeySelector,
  createNonNullableKeySelector,
  createNullableKeySelector,
} from 'state/common/selectorFactoriesTyped';
import type { PublishersState } from 'state/publishers/publishersState';
import * as publisherEntityHelpers from 'state/publishers/schema/publisherEntityHelpers';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { AgeGate, DEFAULT_LOGO_COLOR, ShareOption } from 'config/constants';
import { getMessageBody } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';

import { Publisher, PublisherDetails, PublisherID, TierLevel } from 'types/publishers';
import type { State } from 'types/rootState';

const getPublishers = (state: State): PublishersState => state.publishers;

export const getPublisherDetails = createKeySelector(getPublishers, 'detailsById', {});
export const getPublisherActiveEditions = createKeySelector(getPublishers, 'activeEditionsById', {});
export const getPublisherArchivedEditions = createKeySelector(getPublishers, 'archivedEditionsById', {});
export const getPublisherUnavailableEditions = createKeySelector(getPublishers, 'unavailableEditionsById', {});
export const getLoadingActiveEditions = createKeySelector(getPublishers, 'loadingActiveEditionsById', {});

const getPublisherDetailsData = (publisherDetails: PublisherDetails) => publisherDetails.data || null;
export const getPublisherDetailsDataById = createByIdSelector(getPublisherDetails, getPublisherDetailsData, null);
export const getPublisherActiveEditionsById = createByIdKeySelector(getPublisherActiveEditions, []);
export const getPublisherArchivedEditionsById = createByIdKeySelector(getPublisherArchivedEditions, []);
export const getPublisherUnavailableEditionsById = createByIdKeySelector(getPublisherUnavailableEditions, []);
export const getActivePublisherDetails = s(
  userSelectors.getActivePublisherId,
  getPublisherDetailsDataById,
  (activePublisherId, getPublisherData) => {
    if (!activePublisherId) {
      return null;
    }
    return getPublisherData(activePublisherId);
  }
);
export const isAdvertisingEnabled = createKeySelector(getActivePublisherDetails, 'advertisingEnabled', false);
export const isDynamicAdsEnabled = createKeySelector(getActivePublisherDetails, 'dynamicAdsEnabled', false);
export const isPublishingEnabled = createKeySelector(getActivePublisherDetails, 'publishingEnabled', false);

export const getActivePublisherOrgId = createKeySelector(getActivePublisherDetails, 'orgId', '');
export const getActivePublisherPrimaryColor = createNonNullableKeySelector(
  getActivePublisherDetails,
  'primaryColor',
  DEFAULT_LOGO_COLOR
);
export const getActivePublisherPrimaryLanguage = createNonNullableKeySelector(
  getActivePublisherDetails,
  'primaryLanguage',
  'en'
);
export const getActivePublisherDefaultSubtitlesLanguage = createKeySelector(
  getActivePublisherDetails,
  'defaultSubtitlesLanguage',
  'en-US'
);
export const isPublisherAgeGateEnabled = s(getActivePublisherDetails, (publisherDetails: Publisher | null) => {
  return publisherDetails?.ageGate === AgeGate.EXPLICIT;
});
/**
 * Support for translations based on the publishers primary language.
 * It returns a function accepting message an message format params.
 * Usage:
 *   const formatMessage = getPrimaryLanguageMessage(state);
 *   formatMessage(<FormattedMessage id="id" description="description" defaultMessage="default" />)
 *   formatMessage({ id, description, defaultMessage });
 */
export const getPrimaryLanguageMessage = s(getActivePublisherPrimaryLanguage, (primaryLanguage: string) => {
  return async (message: any, params?: any): Promise<string> => {
    return getMessageBody(message, params, primaryLanguage.toLowerCase());
  };
});
export const getActivePublisherBusinessProfileId = createNullableKeySelector(
  getActivePublisherDetails,
  'businessProfileId'
);
export const getActivePublisherLogoId = createNullableKeySelector(getActivePublisherDetails, 'horizontalIconId');
export const getActivePublisherLogo = s(getActivePublisherLogoId, (horizontalIconId: string | null | undefined) =>
  horizontalIconId ? createAssetUrl(horizontalIconId) : null
);
export const getEnabledFeatures = createNonNullableKeySelector(getActivePublisherDetails, 'enabledFeatures', []);
export const getLoadingActiveEditionsById = createByIdKeySelector(getLoadingActiveEditions, 0);
export const getActivePublisherId = s(
  userSelectors.getActivePublisherId,
  (activePublisherId: PublisherID | null) => activePublisherId
);
export const getActivePublisherDefaultShareOption = createNonNullableKeySelector(
  getActivePublisherDetails,
  'defaultShareOption',
  ShareOption.NO_SHARE
);
export const hideCommunitySnaps = s(getActivePublisherDetails, (activePublisher: Publisher | null) => {
  if (!activePublisher) {
    return true;
  }
  return activePublisher.tier !== TierLevel.CURATION;
});
export const activePublisherIsDynamicEditions = s(getActivePublisherDetails, (activePublisher: Publisher | null) =>
  publisherEntityHelpers.isDynamicEditionsPublisher(activePublisher)
);
export const activePublisherIsShow = s(getActivePublisherDetails, (activePublisher: Publisher | null) =>
  publisherEntityHelpers.isShowPublisher(activePublisher)
);
export const activePublisherIsCuration = s(getActivePublisherDetails, (activePublisher: Publisher | null) =>
  publisherEntityHelpers.isCurationPublisher(activePublisher)
);
export const isRollingNewsEnabledForPublisherWithId = s(
  getPublisherDetailsDataById,
  detailsDataByIdFn => (publisherId: PublisherID | null | undefined) => {
    if (!publisherId) {
      return false;
    }
    const detailsData = detailsDataByIdFn(publisherId);
    return detailsData?.rollingNewsEnabled || false;
  }
);
// subscribable
export const isActivePublisherSubscribable = createNonNullableKeySelector(
  getActivePublisherDetails,
  'subscribable',
  false
);

export const activePublisherHasAddedRequiredDetails = s(
  getActivePublisherDetails,
  (activePublisher: Publisher | null) => {
    if (!activePublisher) {
      return false;
    }
    const hasAddedFormalName = !!activePublisher.formalName && activePublisher.formalName !== '';
    const hasAddedDescription = !!activePublisher.description && activePublisher.description !== '';
    const hasAddedPrimaryColor = !!activePublisher.primaryColor && activePublisher.primaryColor !== '';
    const hasAddedSecondaryColor = !!activePublisher.secondaryColor && activePublisher.secondaryColor !== '';
    const hasAddedColors = hasAddedPrimaryColor && hasAddedSecondaryColor;
    const hasAddedHorizontalIcon = !!activePublisher.horizontalIconId;
    const hasAddedDefaultFilledIcon = !!activePublisher.defaultFilledIconId;
    const hasAddedIcons = hasAddedHorizontalIcon && hasAddedDefaultFilledIcon;
    return hasAddedFormalName && hasAddedDescription && hasAddedColors && hasAddedIcons;
  }
);

export const getPublisherHostUsername = createKeySelector(getActivePublisherDetails, 'hostUsername', '');
export const getEditorialStatus = createNullableKeySelector(getActivePublisherDetails, 'editorialApproval');
