import moment from 'moment-timezone';
import { arrayOf } from 'normalizr';

import * as apiMiddlewareActions from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import * as mediaActions from 'state/media/actions/mediaActions';
import { showModal } from 'state/modals/actions/modalsActions';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { generateEditionTileIdsMultiple } from 'state/tiles/schema/tilesIdUtils';
import * as userSelectors from 'state/user/selectors/userSelectors';

import { FileType, SnapTag, UploadPurpose } from 'config/constants';
import { PublisherQueryResponsePublisherType } from 'gql/types/publisherQueryTypeEnum';
import { UpdatePublisherResponseDetails } from 'gql/types/updatePublisherTypes';
import { CALL_API } from 'redux/middleware/apiMiddleware';
import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import { UserId } from 'src/state/user/userState';
import { GetState } from 'src/types/redux';
import * as discoverAPI from 'utils/apis/discoverAPI';
import * as proxyAPI from 'utils/apis/proxyAPI';
import { assertArg } from 'utils/assertionUtils';
import { isExpired } from 'utils/dateUtils';
import { apiErrorHandler } from 'utils/errors/api/apiErrorUtils';
import { ErrorContexts } from 'utils/errors/errorConstants';
import { clearInfoMessage, InfoContext, infoMessageHandler } from 'utils/errors/infoMessage/infoMessageUtils';
import * as blobUtils from 'utils/media/blobUtils';
import { ModalType } from 'utils/modalConfig';
import { camelCaseKeys, Recursion, RemoveField, removeFieldRecursively, Undoability } from 'utils/objectUtils';

import { StoryState } from 'types/editions';
import { Publisher, PublisherID } from 'types/publishers';
import { State } from 'types/rootState';

export const GET_HOMEPAGE_STORIES = 'publishers/GET_HOMEPAGE_STORIES';
export const GET_SCHEDULED_LIVE_STORIES = 'publishers/GET_SCHEDULED_LIVE_STORIES';
export const GET_IF_PUBLISHER_HAS_AVAILABLE_STORIES = 'publishers/GET_IF_PUBLISHER_HAS_AVAILABLE_STORIES';
export const GET_ACTIVE_EDITIONS = 'publishers/GET_ACTIVE_EDITIONS';
export const GET_ARCHIVED_EDITIONS = 'publishers/GET_ARCHIVED_EDITIONS';
export const GET_UNAVAILABLE_EDITIONS = 'publishers/GET_UNAVAILABLE_EDITIONS';
export const GET_LIVE_EDITIONS = 'publishers/GET_LIVE_EDITIONS';
export const UPDATE_PUBLISHER_DETAILS = 'publishers/UPDATE_PUBLISHER_DETAILS';
export const SET_PUBLISHER_DETAILS_GRAPHQL = 'publishers/SET_PUBLISHER_DETAILS_GRAPHQL';
export const SET_PUBLISHER_DETAILS = 'publishers/SET_PUBLISHER_DETAILS';
export const UPDATE_HOST_ACCOUNT = 'publishers/UPDATE_HOST_ACCOUNT';
export const GET_PUBLISHERS = 'publishers/GET_PUBLISHERS';

export const HOMEPAGE_NEXT_PAGE_CURSOR = 'editions-next-page-cursor';

export const getPublishers = (noBailout?: boolean) => ({
  type: GET_PUBLISHERS,
  bailout: (state: State) => (noBailout ? false : !isExpired(userSelectors.getLastUpdatedPublishersDate(state))),
  meta: {
    [CALL_API]: {
      endpoint: discoverAPI.user.getPublisherList(),
      finalizer: optimisticJsonFinalizer,
    },
  },
});

export const getPublishersUncached = (userId: UserId, noBailout?: boolean) => ({
  type: GET_PUBLISHERS,
  bailout: (state: State) => (noBailout ? false : !isExpired(userSelectors.getLastUpdatedPublishersDate(state))),
  meta: {
    [CALL_API]: {
      endpoint: discoverAPI.user.getPublisherListUncached({ userId }),
      finalizer: optimisticJsonFinalizer,
    },
  },
});

function publisherDetailsKeys(obj: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(obj).is.object();
  return camelCaseKeys(obj, {
    recursion: Recursion.RECURSIVE,
    undoability: Undoability.UNDOABLE,
    overrides: {
      article_css: 'articleCSS',
      ...SnapTag,
    },
  });
}

function publisherDetailsFinalizer(input: any) {
  const parsedInput = optimisticJsonFinalizer(input);
  return publisherDetailsKeys(parsedInput);
}

export const updateHostAccount = (publisherId: PublisherID, businessProfileId: string, token: string) => ({
  type: UPDATE_HOST_ACCOUNT,
  params: { publisherId, businessProfileId, token },
  meta: {
    [CALL_API]: {
      method: 'PUT',
      endpoint: discoverAPI.publishers.associateHostUser({ bpid: businessProfileId, token }),
      finalizer: publisherDetailsFinalizer,
    },
  },
});

export const getActiveEditionsForPublisher = ({ publisherId }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();

  return {
    type: GET_ACTIVE_EDITIONS,
    params: { publisherId },
    meta: {
      [CALL_API]: {
        endpoint: proxyAPI.publisher.activeEditions({ publisherId }),
        finalizer: generateEditionTileIdsMultiple,
      },
      schema: arrayOf(editionSchema),
    },
  };
};

export const getLiveEditionsForPublisher = ({ publisherId }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();

  return {
    type: GET_LIVE_EDITIONS,
    params: { publisherId },
    meta: {
      [CALL_API]: {
        endpoint: proxyAPI.publisher.liveEditions({ publisherId }),
        finalizer: generateEditionTileIdsMultiple,
      },
      schema: arrayOf(editionSchema),
    },
  };
};

export const getStoriesForPublisher = ({ type, publisherId, searchCriteria, pagination, keepExistingStories }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(searchCriteria).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(pagination).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(pagination.batchSize).is.number();

  // If we have existing cursor, add it to the request headers
  // It will instruct the service to get the next batch of results
  const requestHeaders = pagination.cursor ? { [HOMEPAGE_NEXT_PAGE_CURSOR]: pagination.cursor } : {};

  return {
    type,
    params: { publisherId },
    keepExistingStories,
    searchCriteria,
    pagination,
    meta: {
      [CALL_API]: {
        endpoint: proxyAPI.publisher.editions({ publisherId, ...searchCriteria, batchSize: pagination.batchSize }),
        finalizer: generateEditionTileIdsMultiple,
        headers: requestHeaders,
        responseHeaders: [HOMEPAGE_NEXT_PAGE_CURSOR],
      },
      schema: arrayOf(editionSchema),
    },
  };
};

export const getScheduledAndLiveStoriesForPublisher = ({ publisherId, startDate }: any) => {
  const pagination = { batchSize: 0 };

  const searchCriteria =
    startDate && moment(startDate).isValid()
      ? { state: [StoryState.SCHEDULED, StoryState.LIVE], startDate }
      : { state: [StoryState.SCHEDULED, StoryState.LIVE] };

  return getStoriesForPublisher({
    type: GET_SCHEDULED_LIVE_STORIES,
    publisherId,
    searchCriteria,
    pagination,
  });
};

export const getStoriesForPublisherInAvailableState = (publisherId: any) => {
  const pagination = { batchSize: 3 };

  const searchCriteria = {
    state: [StoryState.LIVE, StoryState.SCHEDULED_LIVE_EDIT_PENDING_APPROVAL, StoryState.LIVE_EDIT_PENDING_APPROVAL],
  };

  return getStoriesForPublisher({
    type: GET_IF_PUBLISHER_HAS_AVAILABLE_STORIES,
    publisherId,
    searchCriteria,
    pagination,
  });
};

export const reloadEditionStates = (publisherId: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number();

  return Promise.all([exports.getLiveEditionsForPublisher({ publisherId })]);
};

export const updatePublisherDetails = (publisherId: any, publisherProperties: any) => (
  dispatch: any,
  getState: GetState
) => {
  infoMessageHandler(dispatch, InfoContext.SAVING);
  return dispatch(
    apiMiddlewareActions.createCallAction(
      {
        type: UPDATE_PUBLISHER_DETAILS,
        publisherId,
      },
      {
        method: 'PUT',
        endpoint: proxyAPI.publisher.updatePublisher({ publisherId }),
        finalizer: publisherDetailsFinalizer,
        body: publisherProperties,
      }
    )
  )
    .then(
      clearInfoMessage(getState, dispatch, InfoContext.SAVING),
      clearInfoMessage(getState, dispatch, InfoContext.SAVING, true)
    )
    .catch(apiErrorHandler(dispatch, ErrorContexts.UPDATE_PUBLISHER_DETAILS));
};

export const openPublisherDetailsModal = (source: any) => async (dispatch: any, getState: GetState) => {
  const hasAddedPublisherDetails = publishersSelectors.activePublisherHasAddedRequiredDetails(getState());
  if (hasAddedPublisherDetails) {
    return Promise.resolve();
  }
  await dispatch(showModal(ModalType.ADD_PUBLISHER_DETAILS, source, {})).then(() => {});
  return Promise.reject();
};

export const uploadToMls = (blobUrl: any, mlsUploadPurpose: UploadPurpose) => async (dispatch: any) => {
  const blob = await blobUtils.blobUrlToBlob(blobUrl);
  const uploadResponse = await dispatch(
    mediaActions.uploadMediaGetResult(blob, FileType.IMAGE, {
      purpose: mlsUploadPurpose,
      customValidationOptions: {},
    })
  );

  return { transcodedMediaId: uploadResponse.mediaId };
};

export const uploadAndGetPath = (iconBlob: any, mlsIconType: UploadPurpose) => async (dispatch: any) => {
  return dispatch(uploadToMls(iconBlob, mlsIconType));
};

export const setPublisherDetailsWithGraphQLResponse = (
  queryData: PublisherQueryResponsePublisherType | UpdatePublisherResponseDetails,
  userProperties?: any
) => (dispatch: any, getState: GetState) => {
  const dispatchType = SET_PUBLISHER_DETAILS_GRAPHQL;

  if (queryData) {
    type CleanedData = RemoveField<typeof queryData, '__typename'>;
    const cleanedData: CleanedData = removeFieldRecursively(queryData, '__typename');
    let publisherId = publishersSelectors.getActivePublisherId(getState());
    const publisherData = cleanedData as Publisher;
    if (userProperties !== undefined) {
      publisherData.autoApproveComments = userProperties?.autoApproveComments;
    }

    // As we use a reducer to remove __typename, which defaults to an empty object, we need to check for id.
    if (cleanedData && 'id' in cleanedData) {
      publisherId = parseInt(cleanedData.id, 10);
      publisherData.id = parseInt(cleanedData.id, 10);
    }
    dispatch({
      type: dispatchType,
      publisherId,
      payload: { ...publisherData },
    });
  }
};

export const setPublisherDetails = (publisherDetails: any) => (dispatch: any) => {
  const dispatchType = SET_PUBLISHER_DETAILS;

  dispatch({
    type: dispatchType,
    publisherId: publisherDetails.id,
    payload: { ...publisherDetails },
  });
};
