// @flow

import { combineReducers } from 'redux';

import {
  createSequenceHandlingReducer,
  serializeReducers,
  createTimestampReducer,
} from 'state/common/reducerFactories';
import * as inventoryActions from 'state/inventory/actions/inventoryActions';

import { EMPTY_OBJECT } from 'config/constants';
import u from 'utils/safeUpdeep';

import { BundleStatusType } from 'types/inventory';
import { State } from 'types/rootState';

const inventoryItemByBpid = createSequenceHandlingReducer(EMPTY_OBJECT, [inventoryActions.LOAD_INVENTORY_ITEM], {
  start(state: State, action: any) {
    return state;
  },
  done(state: State, action: any) {
    if (action.error) {
      return state;
    }

    const inventoryItemsMap: any = {};
    action.payload.inventoryItems.forEach((item: any) => {
      inventoryItemsMap[item.discoverStoryMetadata.businessProfileId] = item;
    });
    return u(inventoryItemsMap, state);
  },
});

const bundlesById = createSequenceHandlingReducer(EMPTY_OBJECT, [inventoryActions.LOAD_INVENTORY_BUNDLES], {
  start(state: State, action: any) {
    return state;
  },
  done(state: State, action: any) {
    if (action.error) {
      return state;
    }

    const bundlesMap: any = {};
    action.payload.inventoryBundles
      .filter((bundle: any) => bundle.status !== BundleStatusType.STATUS_UNSET)
      .forEach((bundle: any) => {
        bundlesMap[bundle.id] = bundle;
      });
    return bundlesMap;
  },
});

const bundlesByIdAge = createTimestampReducer(inventoryActions.LOAD_INVENTORY_BUNDLES);

const createInventoryBundles = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  [inventoryActions.CREATE_INVENTORY_BUNDLES],
  {
    start(state: State, action: any) {
      return state;
    },
    done(state: State, action: any) {
      if (action.error) {
        return state;
      }

      const bundlesMap: any = {};
      action.payload.inventoryBundles
        .filter((bundle: any) => bundle.status !== BundleStatusType.STATUS_UNSET)
        .forEach((bundle: any) => {
          bundlesMap[bundle.id] = bundle;
        });
      return u(bundlesMap, state);
    },
  }
);

const inventoryBundlesById = serializeReducers([bundlesById, createInventoryBundles]);

const mappingsById = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  [inventoryActions.LOAD_INVENTORY_BUNDLE_ITEM_MAPPINGS],
  {
    start(state: State, action: any) {
      return state;
    },
    done(state: State, action: any) {
      if (action.error) {
        return state;
      }

      return action.payload.entities.mappingByBundleId;
    },
  }
);

const isLoadingInventoryData = createSequenceHandlingReducer(
  false,
  inventoryActions.LOAD_INVENTORY_BUNDLE_ITEM_MAPPINGS,
  {
    start(_: any) {
      // Set isLoading to true when API request starts
      return true;
    },
    done(_1: any, _2: any) {
      // Set back to false when the request finishes
      return false;
    },
  }
);

const createInventoryBundleItemMappings = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  [inventoryActions.CREATE_INVENTORY_BUNDLE_ITEM_MAPPINGS],
  {
    start(state: State, action: any) {
      return state;
    },
    done(state: State, action: any) {
      if (action.error) {
        return state;
      }

      return u(action.payload.entities.mappingByBundleId, state);
    },
  }
);

const deleteInventoryBundleItemMappings = createSequenceHandlingReducer(
  EMPTY_OBJECT,
  [inventoryActions.DELETE_INVENTORY_BUNDLE_ITEM_MAPPINGS],
  {
    start(state: State, action: any) {
      return state;
    },
    done(state: State, action: any) {
      if (action.error) {
        return state;
      }
      return u(u.omit(action.params.inventoryBundleIds), state);
    },
  }
);

const mappingsByBundleId = serializeReducers([
  mappingsById,
  createInventoryBundleItemMappings,
  deleteInventoryBundleItemMappings,
]);

// ------------------------------------
// Reducer
// ------------------------------------

export default combineReducers({
  inventoryBundlesById,
  mappingsByBundleId,
  isLoadingInventoryData,
  bundlesByIdAge,
  inventoryItemByBpid,
});
