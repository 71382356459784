// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import { document } from 'global';
import is from 'is_js';
import { memoize } from 'lodash';
import log from 'loglevel';
import numeral from 'numeral';

import { MediaValidation } from '../errors/media/mediaConstants';
import { isAcceptanceTestRunHref } from '../locationUtils';

import {
  FileType,
  UriType,
  ErrorType,
  KB,
  VIDEO_UPLOAD_ANALYTICS,
  AspectRatio,
  CrossOrigin,
  UploadPurpose,
} from 'config/constants';
import { ACCEPTED_VIDEO_ONLY_FILE_TYPES } from 'config/storySnapConstants';
import { assertArg } from 'utils/assertionUtils';
import { isVideoCorrupted } from 'utils/ffmpeg/FFmpegLib';
import { getMediaMetadata } from 'utils/ffmpeg/MediaInfoLib';
import { VideoFrameRateMode, VideoQuality } from 'utils/ffmpeg/MediaMetadata';
import { incrementCounter } from 'utils/grapheneUtils';
import type { IntlMessage } from 'utils/intlMessages/intlMessages';
import makeIntlMessage from 'utils/intlMessages/intlMessages';
import { createFileFromBlob } from 'utils/media/blobUtils';
import { getValidationOptions } from 'utils/media/mediaValidationConfig';
import type { TileShape } from 'utils/media/tileCropUtils';
import * as videoUtils from 'utils/media/videoUtils';
import { getUriType } from 'utils/uriUtils';

import { ImageLoader } from './ImageLoader';

import type { MediaValidation as MediaValidationOptions } from 'types/media';

// https://jira.sc-corp.net/browse/ADS-8589
const MAX_DURATION_TOLERANCE_SECONDS = 0.5;

export function getImageInfo(uri: any): Promise<TileShape> {
  return new ImageLoader({ crossOrigin: CrossOrigin.NONE })
    .loadImage(uri)
    .then((image: any) => ({
      width: image.width,
      height: image.height,
      type: FileType.IMAGE,
    }))
    .catch((error: any) => {
      const validationParams = { info: {}, uri, options: {} };
      fail(validationParams, `Error reading media file, ${error}`, 'detail-error-reading-media-file');

      // this is only here because flow complains if we don't return a TileShape
      //  in reality it should never reach this point because calling `fail` throws!
      return { width: -1, height: -1 };
    });
}

export type VideoInfo = {
  width: number;
  height: number;
  duration: number;
  hasAudio?: boolean;
  type: typeof FileType.VIDEO;
};

export const getVideoInfo = memoize((uri: string) => {
  return new Promise<VideoInfo>((resolve, reject) => {
    const videoElem = document.createElement('video');

    videoElem.onerror = (error: any) => {
      reject(`Unable to load video. Please ensure video uses H264 encoding: ${error}`);
    };
    videoElem.onloadeddata = () => {
      resolve({
        width: videoElem.videoWidth,
        height: videoElem.videoHeight,
        duration: videoElem.duration,
        type: FileType.VIDEO,
      });
    };

    videoElem.src = uri;
    videoElem.load();
  }).catch(error => {
    const validationParams = { info: {}, uri, options: {} };
    fail(validationParams, `Error reading video file. ${uri}, Error: ${error}`, 'detail-error-reading-video-file');
    // This is to ensure Typescript recognises the return type of the Promise properly.
    // Since fail function always throws the error so this will never be returned.
    return {
      width: 0,
      height: 0,
      duration: 0,
      type: FileType.VIDEO,
    };
  });
});

export function getSubtitleInfo(uri: any): any {
  return Promise.resolve({
    type: FileType.TEXT,
  });
}

export function getAudioInfo(uri: any): any {
  return new Promise((resolve, reject) => {
    const audioElem = document.createElement('audio');
    audioElem.onerror = (error: any) => {
      reject('Unable to load audio. Please ensure video uses MP3 encoding');
    };
    audioElem.onloadeddata = () => {
      resolve({
        duration: audioElem.duration,
        type: FileType.AUDIO,
      });
    };
    audioElem.src = uri;
    audioElem.load();
  });
}

function getInfoFunction(type: FileType | null) {
  switch (type) {
    case FileType.IMAGE:
      return exports.getImageInfo;
    case FileType.VIDEO:
      return exports.getVideoInfo;
    case FileType.TEXT:
      return exports.getSubtitleInfo;
    case FileType.AUDIO:
      return exports.getAudioInfo;
    default:
      return null;
  }
}

export function validateMedia(
  uri: any,
  type: FileType | null,
  options: any,
  analyticsFn: any = () => {},
  fetchFn: any = fetch
): any {
  incrementCounter('MediaAction.validateMedia');

  analyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_MEDIA);

  if (!uri || is.not.string(uri)) {
    analyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_MEDIA_INVALID_URI);
    throw new Error(`Invalid URI argument: ${uri}`);
  } else if (getUriType(uri) === null) {
    analyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_MEDIA_INVALID_URI_TYPE);
    throw new Error('Could not determine URI type');
  } else if (is.not.object(options) && is.not.array(options)) {
    analyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_MEDIA_INVALID_OPTIONS);
    throw new Error('No validation options were supplied');
  }

  const getInfo = getInfoFunction(type);

  if (!getInfo) {
    analyticsFn(VIDEO_UPLOAD_ANALYTICS.VALIDATE_MEDIA_UNKNOWN_FILE_TYPE);
    throw new Error(`Unrecognized file type: ${type}`);
  }

  return Promise.resolve()
    .then(() => getInfo(uri))
    .then(info => {
      if (is.function(options)) {
        options = options(info); // eslint-disable-line no-param-reassign
      }
      return validate(info, type, uri, options, fetchFn);
    });
}

export type ValidateOptions = MediaValidationOptions & {
  isSingleAssetVideo: boolean;
  isSpectaclesVideoEnabled: boolean;
  limitSize?: number;
  limitType?: string;
};

async function validate(info: any, type: FileType | null, uri: any, options: ValidateOptions, fetchFn = fetch) {
  incrementCounter('MediaValidation.validationStart');

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(type).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(uri).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(options).is.object();

  if (is.function(options)) {
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    options = options(mediaInfo);
  }

  const validationParams = { info, uri, options };

  if (options.minWidthPx && info.width < options.minWidthPx) {
    fail(
      validationParams,
      `width ${info.width} is less than minWidth ${options.minWidthPx}`,
      'width-less-than-min-media-validation-error'
    );
  }

  if (options.minHeightPx && info.height < options.minHeightPx) {
    fail(
      validationParams,
      `height ${info.height} is less than minHeight ${options.minHeightPx}`,
      'height-less-than-min-media-validation-error'
    );
  }

  if (options.maxWidthPx && info.width > options.maxWidthPx) {
    fail(
      validationParams,
      `width ${info.width} is more than maxWidth ${options.maxWidthPx}`,
      'width-more-than-max-media-validation-error'
    );
  }

  if (options.maxHeightPx && info.height > options.maxHeightPx) {
    fail(
      validationParams,
      `height ${info.height} is more than maxHeight ${options.maxHeightPx}`,
      'height-more-than-max-media-validation-error'
    );
  }

  if (
    is.number(options.reqWidthPx) &&
    is.number(options.reqHeightPx) &&
    (info.width !== options.reqWidthPx || info.height !== options.reqHeightPx)
  ) {
    fail(
      validationParams,
      `size ${info.width} x ${info.height} must be ${options.reqWidthPx} x ${options.reqHeightPx}`,
      'size-not-equal-require-media-validation-error'
    );
  }

  if (is.number(options.reqHeightPx) && info.height !== options.reqHeightPx) {
    fail(
      validationParams,
      `height ${info.height} must be ${options.reqHeightPx}`,
      'height-not-equal-require-media-validation-error'
    );
  }

  if (is.number(options.reqWidthPx) && info.width !== options.reqWidthPx) {
    fail(
      validationParams,
      `width ${info.width} must be ${options.reqWidthPx}`,
      'width-not-equal-require-media-validation-error'
    );
  }

  if (options.minSizePx && info.width < options.minSizePx && info.height < options.minSizePx) {
    fail(
      validationParams,
      `either width or height must be greater than minSize ${options.minSizePx}`,
      'image-video-small-size-less-than-min-media-validation-error'
    );
  }

  // validate sizeLimit here by fetching image, we can only guarantee the size will be limited if upload from desktop
  if (options.sizeLimit && (uri.startsWith('blob:') || uri.startsWith('data:'))) {
    const limitMap = options.sizeLimit;

    const response = await fetchFn(uri);
    const data = await response.blob();

    if (data.type in limitMap) {
      const limitType = data.type;
      const limitSize = limitMap[limitType];
      if (limitSize && data.size / KB > limitSize) {
        validationParams.options = {
          ...validationParams.options,
          limitSize,
          limitType,
        };
        fail(validationParams, `${limitType} should not exceed ${limitSize} KB`, 'size-exceed-limit-validation-error');
      }
    }
  }

  if (options.aspectRatio) {
    const { width, height, tolerance } = options.aspectRatio;

    if (Math.abs(info.width * (height / width) - info.height) > tolerance) {
      if (options.isSingleAssetVideo) {
        // Generating an error that should not trigger notification. Instead it should be caught by the
        // component that started the operation.
        fail(
          { info, uri, options: { ...options } },
          `${info.width} x ${info.height} does not meet ${width}:${height} aspect ratio`,
          'single-asset-aspect-media-validation-error',
          ErrorType.HORIZONTAL_SINGLE_ASSET,
          true
        );
      }
      fail(
        validationParams,
        `${info.width} x ${info.height} does not meet ${width}:${height} aspect ratio`,
        'aspect-media-validation-error'
      );
    }

    const isSquare = options.aspectRatio === AspectRatio.ONExONE;
    const isCircularVideo = type === FileType.VIDEO && videoUtils.isCircularVideo(info);
    if (isSquare && isCircularVideo && is.falsy(options.isSpectaclesVideoEnabled)) {
      fail(
        validationParams,
        'attempted to upload a circular video to a publisher that does not have spectacles video enabled',
        'generic-aspect-media-validation-error'
      );
    }
  }

  if (type === FileType.VIDEO) {
    incrementCounter('MediaValidation.video');
    const response = await fetchFn(uri);
    const data = await response.blob();
    const file = createFileFromBlob(data);
    const mediaMetaData = await getMediaMetadata(file);

    const videoInfo = {
      height: mediaMetaData.getMediaHeight()!,
      width: mediaMetaData.getMediaWidth()!,
      duration: mediaMetaData.getDurationSeconds() ?? 0,
      hasSound: mediaMetaData.getHasSound(),
      type,
    };

    log.info(videoInfo);
    if (options.disAllowCorruptVideo && (await isVideoCorrupted(file))) {
      incrementCounter('VideoValidation.CorruptMedia.Wasm', {}, 1);
      fail(validationParams, 'Video file is corrupt kindly upload a valid video', 'video-corrupt-validation-error');
    }

    if (options.disAllowVideoWithoutSound && !mediaMetaData.getHasSound()) {
      incrementCounter('VideoValidation.NoSound.Wasm', {}, 1);
      fail(validationParams, "Video uploaded deosn't have sound", 'video-no-audio-validation-error');
    }

    if (options.disAllowVariableFrameRateVideo && mediaMetaData.getFrameRateMode() === VideoFrameRateMode.VFR) {
      incrementCounter('VideoValidation.VFR.Wasm', {}, 1);
      fail(
        validationParams,
        'Variable frame rate video are not allowed.',
        'video-variable-frame-rate-validation-error'
      );
    }

    if (
      options.maxSdBitrate &&
      mediaMetaData.getVideoQuality() === VideoQuality.SD &&
      mediaMetaData.getVideoBitRate()! > options.maxSdBitrate
    ) {
      incrementCounter('VideoValidation.BitRate.Wasm', {}, 1);
      // based on https://publish-snapchat.zendesk.com/hc/en-us/articles/360043118074-Snap-Content-Specifications
      fail(
        validationParams,
        'Video has higher bit rate than what we allow',
        'media-validation-error-excessive-bitrate'
      );
    }

    if (
      options.maxHdBitrate &&
      mediaMetaData.getVideoQuality() === VideoQuality.HD &&
      mediaMetaData.getVideoBitRate()! > options.maxHdBitrate
    ) {
      incrementCounter('VideoValidation.BitRate.Wasm', {}, 1);
      // based on https://publish-snapchat.zendesk.com/hc/en-us/articles/360043118074-Snap-Content-Specifications
      fail(
        validationParams,
        'Video has higher bit rate than what we allow',
        'media-validation-error-excessive-bitrate'
      );
    }

    if (
      options.maxUHdBitrate &&
      mediaMetaData.getVideoQuality() === VideoQuality.UHD &&
      mediaMetaData.getVideoBitRate()! > options.maxUHdBitrate
    ) {
      incrementCounter('VideoValidation.BitRate.Wasm', {}, 1);
      // based on https://publish-snapchat.zendesk.com/hc/en-us/articles/360043118074-Snap-Content-Specifications
      fail(
        validationParams,
        'Video has higher bit rate than what we allow',
        'media-validation-error-excessive-bitrate'
      );
    }

    if (
      options.maxDurationSeconds &&
      videoInfo.duration > options.maxDurationSeconds + MAX_DURATION_TOLERANCE_SECONDS
    ) {
      fail(
        validationParams,
        `duration of ${videoInfo.duration}s was longer than maxDuration of ${options.maxDurationSeconds}s`,
        'duration-longer-than-max-duration-validation-error'
      );
    }

    if (options.minDurationSeconds && videoInfo.duration < options.minDurationSeconds) {
      fail(
        validationParams,
        `duration of ${videoInfo.duration}s was shorter than minDuration of ${options.minDurationSeconds}s`,
        'duration-shorter-than-min-duration-validation-error'
      );
    }
  }
  incrementCounter('MediaValidation.validationComplete');
  log.info(info);
  return info;
}

export type MediaError = Error & {
  errorType?: typeof ErrorType[keyof typeof ErrorType];
  intlError?: IntlMessage;
  alreadyHandled?: boolean;
};

type Info = {
  duration?: number;
};

function fail(
  {
    info: inInfo,
    uri,
    options,
  }: {
    info: Info;
    uri: string;
    options: any;
  },
  reason: any,
  intlId: any,
  errorType: string = ErrorType.MEDIA_VALIDATION_ERROR,
  alreadyHandled: boolean = false
) {
  const isDataUri = getUriType(uri) === UriType.DATA_URI;
  const srcDescription = isDataUri ? '' : `URL was ${uri}.`;

  const info: Info = {
    ...inInfo,
  };
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
  info.duration = numeral(info.duration).format('0.0a');

  const mediaValidationError: MediaError = new Error(`Media validation failed: ${reason}. ${srcDescription}`);
  mediaValidationError.intlError = makeIntlMessage(intlId, {
    ...info,
    ...options,
    aspectRatio: (options.aspectRatio && `${options.aspectRatio.width}:${options.aspectRatio.height}`) || 'undefined',
  });
  mediaValidationError.errorType = errorType;
  mediaValidationError.alreadyHandled = alreadyHandled;
  incrementCounter('MediaValidation.failValidation');
  throw mediaValidationError;
}

const isVideoWithoutSound = (video: HTMLVideoElement): boolean => {
  // TODO(hack mmeroi):Acceptance tests always fail the audio test, bypass the validation until we migrate to webassembly
  if (isAcceptanceTestRunHref()) {
    return false;
  }

  // @ts-expect-error Property 'webkitAudioDecodedByteCount' does not exist on type 'HTMLVideoElement'.
  return !video.webkitAudioDecodedByteCount && !video.audioTracks && !video.audioTracks?.length && !video.mozHasAudio;
};

const isFileTypeInvalid = (fileType: string): boolean => !ACCEPTED_VIDEO_ONLY_FILE_TYPES.includes(fileType);

export const validateStorySnapVideo = async (
  file: File,
  uploadPurpose: UploadPurpose
): Promise<MediaValidation | VideoInfo> => {
  const fileType = file.type;
  if (isFileTypeInvalid(fileType)) {
    return Promise.reject(MediaValidation.UNEXPECTED_FILE_TYPE);
  }

  const url = URL.createObjectURL(file);
  const video = document.createElement('video');
  video.preload = 'metadata';
  video.src = URL.createObjectURL(file);

  return new Promise<any>((resolve, reject) => {
    video.onloadedmetadata = async () => {
      video.muted = true;
      video.play();
      await new Promise(res => setTimeout(res, 50));
      video.pause();
      window.URL.revokeObjectURL(video.src);

      if (isVideoWithoutSound(video)) {
        incrementCounter('VideoValidation.NoSound.Html', {}, 1);
      }

      const validationOption = {
        ...getValidationOptions(uploadPurpose),
        isSingleAssetVideo: false,
        isSpectaclesVideoEnabled: false,
      };

      try {
        const videoInfo = {
          height: video.videoHeight,
          width: video.videoWidth,
          duration: video.duration * 1000,
        };
        const result = await validate(videoInfo, FileType.VIDEO, url, validationOption);
        result.hasAudio = true;
        resolve(result);
      } catch (error) {
        reject(error);
      }
    };
  });
};
