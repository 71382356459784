/* eslint-disable no-param-reassign */
import is from 'is_js';
import util from 'util';

const AssertionType = {
  ARGUMENT: 'ARGUMENT',
  STATE: 'STATE',
};

const Modifiers = {
  not: Symbol('not'),
  all: Symbol('all'),
};

const Combinators = {
  and: Symbol('and'),
  or: Symbol('or'),
};

/**
 * Thin wrapper around http://is.js.org/ for doing argument assertions. Any of the is.js functions can be used for
 * checking the values of arguments, and an error will be thrown with a descriptive error message if any of the
 * assertions fail.
 *
 * Assertions can be negated using the `not` keyword and chained using the `and` or `or` keywords.
 *
 * Usage examples:
 *
 *   // Basic
 *   assertArg(foo, 'foo').is.number();
 *
 *   // Negations via `not`
 *   assertArg(foo, 'foo').is.not.number();
 *
 *   // Multiple checks via `and`
 *   assertArg(foo, 'foo').is.number.and.is.above(42);
 *   assertArg(foo, 'foo').is.number.and.is.above(42).and.is.below(84);
 *
 *   // Alternative checks via `or`
 *   assertArg(foo, 'foo').is.number.or.is.string();
 *   assertArg(foo, 'foo').is.null.or.is.array();
 *
 *   // More exotic checks – see is.js.org for a full list
 *   assertArg(foo, 'foo').is.email();                                  // @see http://is.js.org/#email
 *   assertArg(foo, 'foo').is.palindrome();                             // @see http://is.js.org/#palindrome :)
 *
 * @param argValue  The argument value being tested
 * @param [argName] The name of the argument (optional)
 * @returns {{is: AssertionChain}}
 */
export function assertArg(argValue, argName) {
  return {
    is: new AssertionChain(argValue, argName, AssertionType.ARGUMENT),
  };
}

/**
 * Identical to assertArg() except that the error thrown when the assertion is not met does not imply that the value
 * is an argument. Intended for cases where you want to assert on something that isn't a function/method argument.
 *
 * Naming chosen in order to align with Google's Preconditions library for Java, which provides checkArg and checkState.
 *
 * @param value  The value being tested
 * @param [name] The name of the variable/property associated with the value (optional)
 * @returns {{is: AssertionChain}}
 */
export function assertState(value, name) {
  return {
    is: new AssertionChain(value, name, AssertionType.STATE),
  };
}

function AssertionChain(value, name, assertionType) {
  if (name !== undefined && is.not.string(name)) {
    const fnName = assertionType === AssertionType.ARGUMENT ? 'assertArg' : 'assertState';
    throw new Error(`If provided, the name argument to ${fnName}() must be a string.`);
  }

  this._value = value;
  this._name = name;
  this._assertionType = assertionType;
  this._chain = null;
  this._chainContainsAnd = false;
  this._chainContainsOr = false;
}

const assertionFns = {};

// Include all assertion functions from is.js
Object.keys(is)
  .filter(fnName => is.function(is[fnName]))
  .forEach(fnName => {
    assertionFns[fnName] = is[fnName];
  });

// Add some of our own
assertionFns.inValues = (value, obj) => {
  if (is.not.object(obj)) {
    return false;
  }

  return Object.values(obj).indexOf(value) !== -1;
};

assertionFns.numeric = value => /^\d+$/.test(value);

Object.keys(assertionFns).forEach(fnName => {
  defineGetter(AssertionChain.prototype, fnName, function pushAssertion() {
    pushToChain.call(this, fnName);
    return createExecutor(this);
  });
});

defineGetter(AssertionChain.prototype, 'not', function pushNegation() {
  pushToChain.call(this, Modifiers.not);
  return this;
});

defineGetter(AssertionChain.prototype, 'all', function pushAll() {
  pushToChain.call(this, Modifiers.all);
  return this;
});

function defineGetter(obj, name, getter) {
  Object.defineProperty(obj, name, {
    get: getter,
    enumerable: true,
    configurable: false,
  });
}

function pushToChain(chainItem) {
  if (!this._chain) {
    this._chain = chainItem;
  } else if (Array.isArray(this._chain)) {
    this._chain.push(chainItem);
  } else {
    this._chain = [this._chain, chainItem];
  }
}

function failAssertion() {
  let description;

  if (this._assertionType === AssertionType.ARGUMENT) {
    description = this._name ? `the ${this._name} argument` : 'argument';
  } else {
    description = this._name ? this._name : 'value';
  }

  const chainAsString = Array.isArray(this._chain) ? this._chain.map(formatChainItem).join('.') : this._chain;

  const detail = __PROD__ ? `Type was ${typeof this._value}` : `Value was ${util.inspect(this._value)}`;

  throw new Error(`Expected ${description} to pass is.${chainAsString}(). ${detail}.`);
}

function formatChainItem(chainItem) {
  switch (chainItem) {
    case Modifiers.not:
      return 'not';
    case Modifiers.all:
      return 'all';
    case Combinators.and:
      return 'and.is';
    case Combinators.or:
      return 'or.is';
    default:
      return chainItem.toString();
  }
}

function createExecutor(self) {
  function execute(additionalParam) {
    let expectedValue = true;
    let applyToAll = false;

    if (Array.isArray(self._chain)) {
      let hasPendingFailures = false;

      self._chain.every(chainItem => {
        // Handle negations by inverting the expected value.
        if (chainItem === Modifiers.not) {
          expectedValue = !expectedValue;

          // Handle the 'all' modifier, which ensures all items in an array match the expectation.
        } else if (chainItem === Modifiers.all) {
          applyToAll = true;

          // Handle assertions, which are all in the form of the string name of the function.
        } else if (is.string(chainItem)) {
          // This logic gets kind hard to understand and is of dubious value anyway, so it's
          // easier to just forbid it. Can be added in future if required.
          if (expectedValue === false && applyToAll) {
            throw new Error('`all` cannot be used in combination with `not`');
          }

          if (!valueOrValuesPassAssertion(chainItem)) {
            hasPendingFailures = true;

            // If the chain doesn't contain an 'or' combinator then we can fail here - there's
            // not point running the remaining assertions.
            if (!self._chainContainsOr) {
              return false;
            }

            // If the value did pass the assertion and the chain does contain the 'or' combinator,
            // we can clear any earlier failures and also skip checking the rest of the chain.
          } else if (self._chainContainsOr) {
            hasPendingFailures = false;
            return false;
          }
          expectedValue = true;
          applyToAll = false;
        }

        return true;
      });

      if (hasPendingFailures) {
        failAssertion.call(self);
      }
      // Optimised case where only one assertion has been defined. This saves allocating an
      // array for the most common cases – assertArg(foo).is.number() etc.
    } else if (!valuePassesAssertion(self._chain)) {
      failAssertion.call(self);
    }

    function valueOrValuesPassAssertion(chainItem) {
      if (applyToAll) {
        if (!is.array(self._value)) {
          return false;
        }

        return self._value.every(arrayItem => valuePassesAssertion(chainItem, arrayItem));
      }

      return valuePassesAssertion(chainItem);
    }

    function valuePassesAssertion(chainItem, value = self._value) {
      return assertionFns[chainItem](value, additionalParam) === expectedValue;
    }
  }

  const continuation = {
    is: self,
  };

  defineGetter(execute, 'and', () => {
    if (self._chainContainsOr) {
      throwInvalidLogicError();
    }

    self._chainContainsAnd = true;
    pushToChain.call(self, Combinators.and);
    return continuation;
  });

  defineGetter(execute, 'or', () => {
    if (self._chainContainsAnd) {
      throwInvalidLogicError();
    }

    self._chainContainsOr = true;
    pushToChain.call(self, Combinators.or);
    return continuation;
  });

  return execute;
}

// Without having parentheses to define how pairings of boolean operations like 'and' and 'or'
// should work together, it is impossible to infer what the user's intent could be. For this
// reason, we disallow any assertion chains that contain both 'and' and 'or' together.
function throwInvalidLogicError() {
  throw new Error("An assertion chain cannot contain both 'and' and 'or'.");
}
