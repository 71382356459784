import log from 'loglevel';
import React, { ErrorInfo, PropsWithChildren } from 'react';

import { incrementCounter } from 'utils/grapheneUtils';

export default class ReloadPageOnError extends React.Component<PropsWithChildren<any>, any> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    log.error('Caught global error: ', error);
    if (process.env.NODE_ENV !== 'development' && window.location.hostname === 'publish.snapchat.com') {
      incrementCounter('RefreshPageOnError.refresh');
      window.location.reload();
    }
  }

  render() {
    return this.props.children;
  }
}
