import type { Enum } from 'utils/enum';
import { getLocalisedMessageFromId } from 'utils/intlMessages/intlMessages';

import type { Context } from 'types/common';
import type { Publisher } from 'types/publishers';

export const Priority = {
  LOW: 'LOW',
  PS_PRIORITY_LOW: 'PS_PRIORITY_LOW',
  NORMAL: 'NORMAL',
  PS_PRIORITY_NORMAL: 'PS_PRIORITY_NORMAL',
  HIGH: 'HIGH',
  PS_PRIORITY_HIGH: 'PS_PRIORITY_HIGH',
  URGENT: 'URGENT',
  PS_PRIORITY_URGENT: 'PS_PRIORITY_URGENT',
};

export type PriorityEnum = Enum<typeof Priority>;

export const Category = {
  ANALYTICS: 'ANALYTICS',
  PS_ANALYTICS: 'PS_ANALYTICS',
  PS_MONETIZATION: 'PS_MONETIZATION',
  PS_FEEDBACK: 'PS_FEEDBACK',
  PS_IN_APP: 'PS_IN_APP',
  PS_ONBOARDING: 'PS_ONBOARDING',
  PS_PROFILE_SETTINGS: 'PS_PROFILE_SETTINGS',
  PS_USER_MANAGEMENT_LOGIN: 'PS_USER_MANAGEMENT_LOGIN',
  FEATURE_REQUEST: 'FEATURE_REQUEST',
  LOGGING_IN: 'LOGGING_IN',
  OTHER: 'OTHER',
  PS_OTHER_HELP: 'PS_OTHER_HELP',
  POST_LAUNCH: 'POST_LAUNCH',
  SNAP_PROMOTE: 'SNAP_PROMOTE',
  SNAP_PUBLISHER: 'SNAP_PUBLISHER',
  SNAPCODE: 'SNAPCODE',
  STORY_CREATION: 'STORY_CREATION',
  PS_STORY_CREATION: 'PS_STORY_CREATION',
  UNABLE_TO_PUBLISH: 'UNABLE_TO_PUBLISH',
  PS_UNABLE_TO_PUBLISH: 'PS_UNABLE_TO_PUBLISH',
};

export type CategoryEnum = Enum<typeof Category>;

const SupportCategoryOptionIdsV2 = [
  {
    labelId: 'zendesk-dropdown-analytics',
    value: Category.PS_ANALYTICS,
  },
  {
    labelId: 'zendesk-dropdown-ads-monetization',
    value: Category.PS_MONETIZATION,
  },
  {
    labelId: 'zendesk-dropdown-in-app',
    value: Category.PS_IN_APP,
  },
  {
    labelId: 'zendesk-dropdown-onboarding',
    value: Category.PS_ONBOARDING,
  },
  {
    labelId: 'zendesk-dropdown-profile-settings',
    value: Category.PS_PROFILE_SETTINGS,
  },
  {
    labelId: 'zendesk-dropdown-story-creation',
    value: Category.PS_STORY_CREATION,
  },
  {
    labelId: 'zendesk-dropdown-unable-to-publish',
    value: Category.PS_UNABLE_TO_PUBLISH,
  },
  {
    labelId: 'zendesk-dropdown-user-management-logging-in',
    value: Category.PS_USER_MANAGEMENT_LOGIN,
  },
  {
    labelId: 'zendesk-dropdown-other',
    value: Category.PS_OTHER_HELP,
  },
  {
    labelId: 'zendesk-dropdown-feedback',
    value: Category.PS_FEEDBACK,
  },
];

const UrgentCategoryIds = [Category.UNABLE_TO_PUBLISH, Category.PS_UNABLE_TO_PUBLISH];

const isUrgentCategory = (category: CategoryEnum, publisher: Publisher): boolean => {
  return category === Category.UNABLE_TO_PUBLISH || category === Category.PS_UNABLE_TO_PUBLISH
    ? publisher.publishingEnabled
    : UrgentCategoryIds.includes(category);
};

const SupportPriorityOptionIdsV2 = [
  {
    labelId: 'low-priority',
    value: Priority.PS_PRIORITY_LOW,
  },
  {
    labelId: 'normal-priority',
    value: Priority.PS_PRIORITY_NORMAL,
  },
  {
    labelId: 'high-priority',
    value: Priority.PS_PRIORITY_HIGH,
  },
  {
    labelId: 'urgent-priority',
    value: Priority.PS_PRIORITY_URGENT,
  },
];

type SupportOptionId = {
  labelId: string;
  value: PriorityEnum | CategoryEnum;
};

export type GenericSupportOption<T> = {
  label: string | undefined | null;
  value: T;
};

export type SupportPriorityOption = GenericSupportOption<PriorityEnum>;

export type SupportCategoryOption = GenericSupportOption<CategoryEnum>;

// The label needs to be localised before returned as string
const getLocalizedOptions = (
  context: Context,
  options: SupportOptionId[]
): GenericSupportOption<PriorityEnum | CategoryEnum>[] =>
  options.map(option => ({
    value: option.value,
    label: getLocalisedMessageFromId(context, option.labelId),
  }));

export const getSupportPriorityOptions = (
  context: Context,
  publisher: Publisher,
  selectedCategory?: CategoryEnum | null
): SupportPriorityOption[] => {
  const allOptions = getLocalizedOptions(context, SupportPriorityOptionIdsV2);
  // If the selected category is not urgent, then filter out urgent option
  if (!selectedCategory || !isUrgentCategory(selectedCategory, publisher)) {
    return allOptions.filter(
      option => option.value !== Priority.URGENT && option.value !== Priority.PS_PRIORITY_URGENT
    );
  }

  return allOptions;
};

export const getSupportCategoryOptions = (context: Context): SupportCategoryOption[] =>
  getLocalizedOptions(context, SupportCategoryOptionIdsV2);
