import PropTypes from 'prop-types';
import React from 'react';

import style from './ChromelessLayout.scss';

type Props = {};

export class ChromelessLayout extends React.Component<Props> {
  static childContextTypes = {
    getScrollContainer: PropTypes.func,
  };

  element: any;

  getChildContext() {
    // This is being exposed to the children so the overlays can be attached to a container that scrolls. This allows
    // the overlays to scroll with the container if they want instead of being appended to body and be in a fixed position
    return {
      getScrollContainer: () => this.element,
    };
  }

  setRef = (element: any) => {
    this.element = element;
  };

  render() {
    return (
      <div className={style.root} ref={this.setRef}>
        {this.props.children}
      </div>
    );
  }
}

export default ChromelessLayout;
