import classNames from 'classnames';
import React from 'react';

import OriginalSDSTile from './OriginalSDSTile/OriginalSDSTile';
import type { TileProps } from './OriginalSDSTile/OriginalSDSTile';
import style from './SDSTile.scss';

type OwnProps = {
  handleClick?: () => void;
};

type Props = OwnProps & TileProps;

function SDSTile(props: Props) {
  const { children, description, value, title, type, selected, disabled, handleClick } = props;

  const updatedClassName = classNames(style.tileWrapper, {
    [style.selectedContainer]: selected,
  });

  return (
    <OriginalSDSTile
      description={description}
      title={title}
      value={value}
      onClick={handleClick}
      selected={selected}
      disabled={disabled}
      type={type}
      className={updatedClassName}
      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: ReactNode; description: ReactNod... Remove this comment to see the full error message
      animated={false}
    >
      {children}
    </OriginalSDSTile>
  );
}

export default SDSTile;
