import _ from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import * as notificationsActions from 'state/notifications/actions/notificationsActions';
import * as notificationsSelectors from 'state/notifications/selectors/notificationsSelectors';

import { StatusMessageSeverity, NotificationScope, StatusMessageButton } from 'config/constants';
import { Dispatch, GetState } from 'src/types/redux';
import { getMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';

import type { Notification } from 'types/notifications';

const DELAY_BEFORE_SHOWING_MS = 300;
const MIN_DISPLAY_INTERVAL_MS = 1500;
export const InfoContext = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
  SAVING: 'SAVING',
  UPLOAD_MEDIA: 'UPLOAD_MEDIA',
  PUBLISH_LIVE_CHANGES: 'PUBLISH_LIVE_CHANGES',
  EDIT_LIVE_STORY: 'EDIT_LIVE_STORY',
  DUPLICATING: 'DUPLICATING',
  ARCHIVING: 'ARCHIVING',
  INVALID_AD_LAYOUT: 'INVALID_AD_LAYOUT',
  ERROR_SAVING_STORY: 'ERROR_SAVING_STORY',
  ERROR_SAVING_SNAP: 'ERROR_SAVING_SNAP',
  ERROR_SENSITIVE_SNAP: 'ERROR_SENSITIVE_SNAP',
  SCHEDULING_STORY: 'SCHEDULING_STORY',
  DELETING_STORY: 'DELETING_STORY',
  MAKE_STORY_AVAILABLE: 'MAKE_STORY_AVAILABLE',
  MAKE_STORY_UNAVAILABLE: 'MAKE_STORY_UNAVAILABLE',
  SHOW_TOPSNAP_IMAGE_DISALLOWED: 'SHOW_TOPSNAP_IMAGE_DISALLOWED',
  ERROR_BAD_VTT: 'ERROR_BAD_VTT',
  ERROR_INCORRECT_VTT: 'ERROR_INCORRECT_VTT',
  TAGS_LIST_TRUNCATED: 'TAGS_LIST_TRUNCATED',
  POST_STORY_SNAP_ERROR: 'POST_STORY_SNAP_ERROR',
};
export type InfoContextEnum = typeof InfoContext[keyof typeof InfoContext];
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="publishing-changes-takes-time"
      defaultMessage="Publishing changes... This usually takes a few minutes."
      description="[Toast] informs user that his changes are being published"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="editing-live-story-publish-when-done"
      defaultMessage="Editing live Story. Click Publish button when done editing"
      description="[Toast] informs user that he needs to publish changes after done editing to take effect"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="story-status-is-archiving"
      description="Show to the user when the story is being archived"
      defaultMessage="Archiving..."
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="story-status-is-duplicating"
      description="Show to the user when the story is being duplicated"
      defaultMessage="Duplicating..."
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="ad-layout-invalid"
      defaultMessage="Advertising Slot Placement rules have not been met. Your ad changes have not been saved."
      description="Warning informing that ad rules haven't been followed"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="error-saving-story"
      defaultMessage="An error occurred while saving your story. Please try again."
      description="Message to the user when a generic error occurred when saving story"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="error-sensitive-snap"
      defaultMessage="Snap may contain sensitive content. Ads have been disabled for this Snap."
      description="Message to show when snap is flagged as sensitive to advise ads are not allowed."
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="error-saving-snap"
      defaultMessage="An error occurred while saving your snap. Please try again."
      description="Message to the user when a generic error occurred when saving snap"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="error-uploading-media"
      defaultMessage="An error occurred while uploading your media. Please try again."
      description="Message to the user when a generic error occurred when uploading media"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="unknown-error-message"
      defaultMessage="An unknown error occurred."
      description="Message to the user when a generic error occurred"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="scheduling-story"
      defaultMessage="Scheduling story..."
      description="Message to tell the user that the story is being scheduled"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="deleting-story"
      defaultMessage="Deleting story..."
      description="Message to tell the user that the story is being deleted"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="make-story-available-info"
      defaultMessage="Scheduling Stories..."
      description="Message to tell the user that the stories are being scheduled"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="make-story-unavailable-info"
      defaultMessage="Making Stories unavailable..."
      description="Message to tell the user that the stories are being made unavailable"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="show-topsnap-image-disallowed"
      description="Error message shown when user attempts to upload an image to a show"
      defaultMessage="You cannot add image snaps to Snapchat Shows"
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="bad-vtt-uploaded"
      description="Error message shown when user attempts to upload bad vtt file"
      defaultMessage="Sorry, we can't read your subtitle file. Please contact us for assistance."
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="incorrect-vtt-uploaded"
      description="Error message shown when user attempts to upload an incorrect vtt file"
      defaultMessage="Failed to upload subtitles. Please check none of the cues exceed the length of the video and try again."
    />
  ),
  params: [],
});
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="tags-list-truncated"
      description="Info message shown when user pastes too many content tags and the list is truncated"
      defaultMessage="Maximum number of content tags exceeded. The list has been truncated."
    />
  ),
  params: [],
});

registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="post-story-snap-error"
      description="Info message shown when user snap failed to post"
      defaultMessage="Your Snap failed to post. Please try again."
    />
  ),
  params: [],
});

type InfoMessageConfig = {
  [key in InfoContextEnum]: Notification;
};

export const infoMessageConfig: InfoMessageConfig = {
  [InfoContext.SAVING]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('story-status-is-saving', {}),
    buttons: [],
  },
  [InfoContext.PUBLISH_LIVE_CHANGES]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('publishing-changes-takes-time', {}),
    buttons: [],
  },
  [InfoContext.EDIT_LIVE_STORY]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('editing-live-story-publish-when-done', {}),
    buttons: [],
  },
  [InfoContext.ARCHIVING]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('story-status-is-archiving', {}),
    buttons: [],
  },
  [InfoContext.DUPLICATING]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('story-status-is-duplicating', {}),
    buttons: [],
  },
  [InfoContext.INVALID_AD_LAYOUT]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('ad-layout-invalid', {}),
    buttons: [],
  },
  [InfoContext.ERROR_SAVING_STORY]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('error-saving-story', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.ERROR_SENSITIVE_SNAP]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('error-sensitive-snap', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.ERROR_SAVING_SNAP]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.SNAP,
    plainMessage: getMessageFromId('error-saving-snap', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.UPLOAD_MEDIA]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.SNAP,
    plainMessage: getMessageFromId('error-uploading-media', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.UNKNOWN_ERROR]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.GLOBAL,
    plainMessage: getMessageFromId('unknown-error-message', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.SCHEDULING_STORY]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('scheduling-story', {}),
    buttons: [],
  },
  [InfoContext.DELETING_STORY]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('deleting-story', {}),
    buttons: [],
  },
  [InfoContext.MAKE_STORY_AVAILABLE]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('make-story-available-info', {}),
    buttons: [],
  },
  [InfoContext.MAKE_STORY_UNAVAILABLE]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.EDITION,
    plainMessage: getMessageFromId('make-story-unavailable-info', {}),
    buttons: [],
  },
  [InfoContext.SHOW_TOPSNAP_IMAGE_DISALLOWED]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.GLOBAL,
    plainMessage: getMessageFromId('show-topsnap-image-disallowed', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.ERROR_BAD_VTT]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.GLOBAL,
    plainMessage: getMessageFromId('bad-vtt-uploaded', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.ERROR_INCORRECT_VTT]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.GLOBAL,
    plainMessage: getMessageFromId('incorrect-vtt-uploaded', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.TAGS_LIST_TRUNCATED]: {
    severity: StatusMessageSeverity.INFO,
    scope: NotificationScope.GLOBAL,
    plainMessage: getMessageFromId('tags-list-truncated', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
  [InfoContext.POST_STORY_SNAP_ERROR]: {
    severity: StatusMessageSeverity.ERROR,
    scope: NotificationScope.GLOBAL,
    plainMessage: getMessageFromId('post-story-snap-error', {}),
    buttons: [StatusMessageButton.DISMISS],
  },
};
const uiMessageTimers = {};
export function clearUIMessageTimers() {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  Object.keys(uiMessageTimers).forEach(key => delete uiMessageTimers[key]);
}

export const showInfoMessage = (context: InfoContextEnum) => (dispatch: Dispatch) => {
  const notificationMsg = infoMessageConfig[context];

  if (notificationMsg) {
    dispatch(notificationsActions.sendNotificationMessage(notificationMsg, { context }));
  }
};

export function infoMessageHandler(dispatch: any, context: any): any {
  // Avoid displaying same info message twice
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (!uiMessageTimers[context]) {
    const timer = setTimeout(() => dispatch(showInfoMessage(context)), DELAY_BEFORE_SHOWING_MS);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    uiMessageTimers[context] = {
      timer,
      count: 0,
    };
  }
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  uiMessageTimers[context].count++;
  return Promise.resolve();
}
function clearContextTimer(context: any) {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (uiMessageTimers[context]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    clearTimeout(uiMessageTimers[context].timer);
  }
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  uiMessageTimers[context] = null;
}
export const clearInfoMessage = (getState: GetState, dispatch: any, context: any, rejected: boolean = false): any => (
  arg: any
): any => {
  // This function should always resolve if rejected is false, and always reject if rejected is true.
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (!uiMessageTimers[context]) {
    return rejected ? Promise.reject(arg) : Promise.resolve(arg);
  }
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  uiMessageTimers[context].count--;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (uiMessageTimers[context].count > 0) {
    return rejected ? Promise.reject(arg) : Promise.resolve(arg);
  }
  const toastMessages = notificationsSelectors.getToastMessagesFilteredByContext(getState())(context);
  let shouldInvokeAgainInterval = 0;
  // We should never have more than one message per context displaying. If this happens, clear all of them
  _.forEach(toastMessages, message => {
    const shownInterval = Date.now() - (message as any).timestamp;
    // Check if the message has been displayed long enough so it can be read
    // If message has been shown for little time, set a timer to invoke clearing function again
    if (shownInterval >= MIN_DISPLAY_INTERVAL_MS) {
      dispatch(notificationsActions.deleteToastMessage(message));
    } else {
      shouldInvokeAgainInterval = Math.max(shouldInvokeAgainInterval, MIN_DISPLAY_INTERVAL_MS - shownInterval);
    }
  });
  if (shouldInvokeAgainInterval > 0) {
    // Setting 'rejected' to false so the returned promise is not orphaned
    setTimeout(() => clearInfoMessage(getState, dispatch, context, false)(arg), shouldInvokeAgainInterval);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    uiMessageTimers[context].count++;
  } else {
    // Prevent any timers that would show info message for this context to fire
    clearContextTimer(context);
  }
  return rejected ? Promise.reject(arg) : Promise.resolve(arg);
};
