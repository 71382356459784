export enum UploaderState {
  NOT_STARTED,
  UPLOADING, // The media is being validated and uploaded
  DONE, // The snap is ready to be posted
  ERROR, // The media failed to be uploaded
  SUBMITTING, // Awaiting response from submit call
  SUBMITTED, // The snap was posted to Spotlight successfully
  SUBMITTED_ERROR, // The snap failed to post to Spotlight
}

export enum PostingStoryType {
  UNKNOWN = 'UNKNOWN',
  SAVED_STORY = 'SAVED_STORY', // Permanent story is saved to public profile and never expires
  PUBLIC_STORY = 'PUBLIC_STORY', // Ephemeral story expires after 24 hours
}
