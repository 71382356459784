import is from 'is_js';
import _ from 'lodash';
import log from 'loglevel';

import { hasActionExpired } from 'state/actions/selectors/actionSelectors';

import { GetState } from 'src/types/redux';
import { assertArg } from 'utils/assertionUtils';

export const onExpiredAction = (action: any): any => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(action).is.object();
  if (action.error) {
    log.error('Dropping expired action with type =', action.type, ', containing the following error: ', action.error);
  } else {
    log.warn('Dropping expired action with type =', action.type, '. Request made after the store was reset.');
  }
  return Promise.resolve();
};
// It gets incremented every time when invoked
export function actionCounter() {
  (actionCounter as any).count = ++(actionCounter as any).count || 1;
  return (actionCounter as any).count;
}
export const rejectWithErrorAction = (caughtObj: any, originalAction: any, modifications: any): any => (
  getState: GetState
): any => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(getState).is.function();
  const errorAction = toErrorAction(caughtObj, originalAction);
  // If an action has expired, do not reject the promise. We want to prevent error handlers from making further actions
  // that may alter the store. Expired action === we no longer care about it.
  if (hasActionExpired(errorAction)(getState())) {
    return exports.onExpiredAction(errorAction);
  }
  // If the caller provided some additional modifications, merge them in to the error action.
  const errorActionWithModifications = modifications ? _.merge({}, errorAction, modifications) : errorAction;
  return Promise.reject(errorActionWithModifications);
};
// In the middleware stack, rejected promises sometimes receive an actual error object and
// other times receive an 'error action' (i.e. an action with a .error property). This function
// resolves the caught object and guarantees to return a valid error action regardless of
// what was caught, by some some duck-type checking on the caught object.
export function toErrorAction(caughtObj: any, originalAction: any) {
  // Quick duck-type check to see if the thing we caught was already an action, rather than
  // an error object or an error message string.
  const caughtAnErrorAction = is.object(caughtObj) && is.string(caughtObj.type) && is.existy(caughtObj.error);
  // If the object we caught was already an error action, use that. If not, add the .error
  // property to a clone of the original action.
  const errorAction = caughtAnErrorAction ? caughtObj : _.merge({}, originalAction, { error: caughtObj || true });
  return errorAction;
}
