import { goBack as connectedGoBack, push as connectedPush, replace as connectedReplace } from 'connected-react-router';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'quer... Remove this comment to see the full error message
import { stringify } from 'query-string';
import { Dispatch } from 'redux';

import { getSearchString, getRedirectPath } from 'state/router/selectors/routerSelectors';
import { getActiveCreatorHostUsername } from 'state/user/selectors/userSelectors';

import { GetState } from 'src/types/redux';
import * as localRoutes from 'utils/apis/localRoutes';
import { magic } from 'utils/apis/magicAPI';
import { assertArg } from 'utils/assertionUtils';
import * as browserUtils from 'utils/browserUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import { getLocationHref, openInNewWindow } from 'utils/locationUtils';
import { errorCodeToMessage } from 'utils/router/authErrorUtils';

import { assertSnapId } from 'types/common';

export const push = connectedPush;
export const replace = connectedReplace;

export const goToLink = ({ path, overwriteHistory }: any) => (dispatch: any, getState: GetState) =>
  performRouting(dispatch, path, overwriteHistory);

export const goBack = () => (dispatch: any, getState: GetState) => dispatch(connectedGoBack());

export const goToLanding = () => (dispatch: any) => {
  return performRouting(dispatch, '/', true);
};

export const goToSnap = ({ publisherId, editionId, snapId, overwriteHistory }: any) => (
  dispatch: any,
  getState: GetState
) => {
  // This will purely go to the snap given. To prevent the subscribe snap from being
  // opened if publisher details are incomplete, use openSnapEditor in publisherStoryEditorModeActions
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number.or.is.string();
  assertSnapId(snapId);

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorEditor.snap({
    editionId,
    snapId,
    hostUsername: activeHostUsername,
  })}${searchString}`;
  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const goToAttachment = ({
  publisherId,
  editionId,
  snapId,
  attachmentId,
  attachmentType,
  overwriteHistory,
}: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number.or.is.string();
  assertSnapId(snapId);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(attachmentId).is.number.or.is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(attachmentType).is.string();

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorEditor.attachment({
    editionId,
    snapId,
    attachmentId,
    hostUsername: activeHostUsername,
    attachmentType: attachmentType.toLowerCase(),
  })}${searchString}`;

  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const goToRevenueAnalytics = ({ publisherId }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = localRoutes.creatorAnalytics.revenueAnalytics({ hostUsername: activeHostUsername });
  return performRouting(dispatch, newRoute, false);
};

export const goToProfileAnalytics = ({ publisherId }: any) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = localRoutes.creatorAnalytics.profileAnalytics({ hostUsername: activeHostUsername });
  return performRouting(dispatch, newRoute, false);
};

export const goToSpotlightAnalytics = ({ publisherId }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = localRoutes.creatorAnalytics.spotlightAnalytics({ hostUsername: activeHostUsername });
  return performRouting(dispatch, newRoute, false);
};

export const goToAnalytics = ({ publisherId }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorAnalytics.analytics({ hostUsername: activeHostUsername })}${searchString}`;
  return performRouting(dispatch, newRoute, false);
};

export const goToBehaviorAnalytics = ({ publisherId }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorAnalytics.behaviorAnalytics({
    hostUsername: activeHostUsername,
  })}${searchString}`;
  return performRouting(dispatch, newRoute, false);
};

export const goToInsightsAnalytics = ({ publisherId }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = localRoutes.creatorAnalytics.insightsAnalytics({ hostUsername: activeHostUsername });
  return performRouting(dispatch, newRoute, false);
};

export const goToMagicSearch = (query: any) => {
  openInNewWindow(magic.getSearch({ searchItem: query }));
};

export const goToInsightsAnalyticsReport = ({ publisherId }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = localRoutes.creatorAnalytics.insightsAnalyticsReport({ hostUsername: activeHostUsername });
  return performRouting(dispatch, newRoute, false);
};

export const goToStoriesAnalytics = ({ publisherId }: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorAnalytics.storiesAnalytics({
    hostUsername: activeHostUsername,
  })}${searchString}`;
  return performRouting(dispatch, newRoute, false);
};

export const goToStoriesAnalyticsV2 = ({ publisherId }: any) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorAnalytics.storiesAnalyticsV2({
    hostUsername: activeHostUsername,
  })}${searchString}`;
  return performRouting(dispatch, newRoute, false);
};

export const goToStoryAnalytics = ({ publisherId, editionId }: any) => (dispatch: Dispatch, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number.or.is.string();

  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = localRoutes.creatorAnalytics.storyAnalytics({ editionId, hostUsername: activeHostUsername });
  return performRouting(dispatch, newRoute, false);
};

export const goToPublisherStoryEditor = ({ publisherId, editionId, overwriteHistory }: any) => (
  dispatch: any,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(editionId).is.number.or.is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(overwriteHistory).is.boolean();

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorEditor.edition({
    editionId,
    hostUsername: activeHostUsername,
  })}${searchString}`;

  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const LOCATION_CHANGE_REJECTED = 'LOCATION_CHANGE_REJECTED';

/* Use this function if you want a promise returned that is rejected if the URL doesn't change after the callback
   has been resolved
   For example, a user is editing a bottom snap and has not saved their changes. They click on a tile to start editing
   that instead, internally we will to two things at this point:
   1. change the url from the attachment deep link to the topsnap
   2. change the active component from the attachment to the tile

   However, because they have unsaved changes they are presented with a warning, if they choose to remain on the
   bottom snap then the url will not change and the promise will be rejected. If they choose to discard their changes
   then the promise is resolved. We can activate the tile in the resolved callback and do nothing in the rejected
 */
export const trackLocationChange = (callback: any) => () => {
  const hrefBefore = getLocationHref();
  return new Promise((resolve, reject) => {
    Promise.resolve()
      .then(callback)
      .then(() => {
        if (getLocationHref() !== hrefBefore) {
          // @ts-expect-error ts-migrate(2794) FIXME: Expected 1 arguments, but got 0. Did you forget to... Remove this comment to see the full error message
          resolve();
        } else {
          reject(LOCATION_CHANGE_REJECTED);
        }
      });
  });
};

function performRouting(dispatch: any, path: any, overwriteHistory: any) {
  return new Promise(resolve => {
    // routing usually triggers a lot of rerendering. Doing that in the beginning of next frame
    // frees the input handlers and makes the app more responsive
    browserUtils.requestAnimationFrame(() => {
      if (overwriteHistory) {
        Promise.resolve()
          .then(() => dispatch(exports.replace(path)))
          .then(resolve);
      } else {
        Promise.resolve()
          .then(() => dispatch(exports.push(path)))
          .then(resolve);
      }
    });
  });
}

export const goToNewEditionSpinner = ({ publisherId, overwriteHistory }: any) => (
  dispatch: any,
  getState: GetState
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(publisherId).is.number.or.is.string();

  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creator.newEdition({ hostUsername: activeHostUsername })}${searchString}`;

  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const goToNotFound = ({ overwriteHistory }: any) => (dispatch: any, getState: GetState) => {
  const searchString = getSearchString(getState());
  const newRoute = `${localRoutes.creator.notFound()}${searchString}`;
  return performRouting(dispatch, newRoute, overwriteHistory);
};
export const goToHomepage = ({ overwriteHistory, hostUsername }: any) => (dispatch: any, getState: GetState) => {
  const searchString = getSearchString(getState());
  const activeHostUsername = hostUsername || getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creator.home({ hostUsername: activeHostUsername })}${searchString}`;
  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const goToShows = ({ overwriteHistory, publisherId }: any) => (dispatch: any, getState: GetState) => {
  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creator.shows({ hostUsername: activeHostUsername })}${searchString}`;
  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const goToNoPermission = ({ publisherId, overwriteHistory }: any) => (dispatch: any, getState: GetState) => {
  const searchString = getSearchString(getState());
  const newRoute = `${localRoutes.publisher.noPermission({ publisherId })}${searchString}`;
  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const goToCreatorNoPermission = ({ hostUsername, overwriteHistory }: any) => (
  dispatch: any,
  getState: GetState
) => {
  const searchString = getSearchString(getState());
  const newRoute = `${localRoutes.creator.noPermission({ hostUsername })}${searchString}`;
  return performRouting(dispatch, newRoute, overwriteHistory);
};

export const goToCreatorPathFromPublisher = ({ match, hostUsername }: any) => (dispatch: any, getState: GetState) => {
  const publisherId = match.params.publisherId;
  if (!match.url.startsWith('/publisher') || !publisherId) {
    // When the url doesn't start with publisher, or we don't have a publisher id, do a noop
    return Promise.resolve();
  }

  const searchString = getSearchString(getState());
  const newRoute = `${match.url.replace(`publisher/${publisherId}`, `user/${hostUsername}`)}${searchString}`;
  incrementCounter('publisher_to_creator_forward', {
    path: match.path,
  });
  return performRouting(dispatch, newRoute, true);
};

export const goToSettings = (publisherId: any) => (dispatch: any, getState: GetState) => {
  const searchString = getSearchString(getState());
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creator.settings({ hostUsername: activeHostUsername })}${searchString}`;
  return performRouting(dispatch, newRoute, true);
};
export const goToSpotlightUploader = () => (dispatch: any, getState: GetState) => {
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creatorEditor.spotlight({ hostUsername: activeHostUsername })}`;
  return performRouting(dispatch, newRoute, false);
};

export const goToLogin = ({ error }: any) => (dispatch: any, getState: GetState) => {
  const newPath = '/login';

  let route;
  if (error) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ newPath: string; query: { erro... Remove this comment to see the full error message
    route = getRedirectPath(getState())({ newPath, query: errorCodeToMessage(error) });
  } else {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ newPath: string; }' is not ass... Remove this comment to see the full error message
    route = getRedirectPath(getState())({ newPath });
  }
  return performRouting(dispatch, route, true);
};

export const goToSnapContinue = ({ query, basePath }: any) => (dispatch: any, getState: GetState) => {
  const path = `${basePath}?${stringify(query)}`;
  return performRouting(dispatch, path, true);
};

export const goToContact = () => (dispatch: any, getState: GetState) => {
  const activeHostUsername = getActiveCreatorHostUsername(getState());
  const newRoute = `${localRoutes.creator.contact({ hostUsername: activeHostUsername })}`;
  return performRouting(dispatch, newRoute, true);
};
