import { StoryStatus } from 'config/constants';
import type { StoryStatusEnum } from 'config/constants';

import {
  SnapProblem,
  INCOMPLETE_STATUSES,
  ERROR_STATUSES,
  NON_BLOCKING_STATUSES,
  ExtendedIncompleteStatus,
  BUILDING_STATUSES,
  MODERATION_FLAGGED_STATUSES,
  SNAP_MEDIA_ERROR_STATUSES,
} from 'types/build';
import type { BuildStatusType } from 'types/build';

export const isSnapStatusIncomplete = (buildStatus?: BuildStatusType | null): boolean => {
  if (!buildStatus) {
    return false;
  }

  return INCOMPLETE_STATUSES.includes(buildStatus.extendedStatus);
};

// Statuses that do not prevent publishing
export const isSnapStatusNonBlockingWarning = (buildStatus?: BuildStatusType | null): boolean => {
  if (!buildStatus) {
    return false;
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ExtendedBuildStatus' is not assi... Remove this comment to see the full error message
  return NON_BLOCKING_STATUSES.includes(buildStatus.extendedStatus);
};

export const isSnapStatusAModerationFlagging = (buildStatus?: BuildStatusType | null): boolean => {
  if (!buildStatus) {
    return false;
  }

  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ExtendedBuildStatus' is not assi... Remove this comment to see the full error message
  return MODERATION_FLAGGED_STATUSES.includes(buildStatus.extendedStatus);
};

export const isSnapStatusWithError = (buildStatus?: BuildStatusType | null): boolean => {
  if (!buildStatus) {
    return false;
  }

  return ERROR_STATUSES.includes(buildStatus.extendedStatus);
};

export const isSnapMediaWithError = (buildStatus?: BuildStatusType | null): boolean => {
  if (!buildStatus) {
    return false;
  }

  return SNAP_MEDIA_ERROR_STATUSES.includes(buildStatus.extendedStatus);
};

export const isSnapStatusBuilding = (buildStatus?: BuildStatusType | null): boolean => {
  if (!buildStatus) {
    return false;
  }

  return BUILDING_STATUSES.includes(buildStatus.extendedStatus);
};

export const isSnapStatusNotPublishable = (buildStatus?: BuildStatusType | null): boolean => {
  return isSnapStatusIncomplete(buildStatus) || isSnapStatusWithError(buildStatus) || isSnapStatusBuilding(buildStatus);
};

export const isSnapStatusIncompleteOrWithError = (buildStatus?: BuildStatusType | null): boolean => {
  return isSnapStatusIncomplete(buildStatus) || isSnapStatusWithError(buildStatus);
};

export function isGeneratingSubtitles(buildStatus?: BuildStatusType | null): boolean {
  if (!buildStatus) {
    return false;
  }

  return buildStatus.extendedStatus === ExtendedIncompleteStatus.GENERATING_SUBTITLES;
}

export function isGeneratingStorySubtitles(storyStatus?: StoryStatusEnum | null): boolean {
  if (!storyStatus) {
    return false;
  }

  return storyStatus === StoryStatus.GENERATING_SUBTITLES;
}

export const buildStatusToStringId = (buildStatus?: BuildStatusType | null): string | undefined | null => {
  if (!buildStatus) {
    return null;
  }

  switch (buildStatus.extendedStatus) {
    case ExtendedIncompleteStatus.ATTACHMENT_INCOMPLETE:
      return 'snap-status-tooltip-snap-attachment-incomplete';
    case ExtendedIncompleteStatus.TILE_BUILD_ERROR:
      return 'tile-build-error';
    case ExtendedIncompleteStatus.TILE_LOGO_BUILD_ERROR:
      return 'tile-logo-build-error';
    case ExtendedIncompleteStatus.TILE_MISSING_HEADLINE:
      return 'snap-status-tooltip-tile-missing-headline';
    case ExtendedIncompleteStatus.INVALID_TILE_HEADLINE_TEXT:
      return 'snap-status-tooltip-invalid-tile-headline-text';
    case ExtendedIncompleteStatus.TILE_MISSING:
      return 'snap-status-tooltip-tile-missing';
    case ExtendedIncompleteStatus.SEGMENT_TILE_INCOMPLETE:
    case ExtendedIncompleteStatus.TILE_INCOMPLETE:
      return 'snap-status-tooltip-tile-incomplete';
    case ExtendedIncompleteStatus.TILE_MISSING_LOGO:
      return 'snap-status-tooltip-tile-missing-logo';
    case ExtendedIncompleteStatus.SEGMENT_TILE_MISSING:
      return 'snap-status-tooltip-segment-tile-missing';
    case ExtendedIncompleteStatus.MISSING_TAGS:
      return 'snap-status-tooltip-missing-tags';
    case ExtendedIncompleteStatus.SNAP_AND_TILE_MODERATION_FLAGGED:
    case ExtendedIncompleteStatus.SNAP_MODERATION_FLAGGED:
    case ExtendedIncompleteStatus.TILE_MODERATION_FLAGGED:
      return 'snap-status-tooltip-moderation-flagged';
    case ExtendedIncompleteStatus.UNHEALTHY_TILE_HEADLINE_TEXT:
      return 'snap-status-tooltip-unhealthy-tile-headline-text';
    case ExtendedIncompleteStatus.GENERATING_SUBTITLES:
      return 'snap-status-tooltip-generating-subtitles';
    case ExtendedIncompleteStatus.WEBVIEW_LIMIT_REACHED:
      return 'snap-status-tooltip-webview-limit-reached';
    case ExtendedIncompleteStatus.MISSING_SUBSCRIBE_SNAP_OVERLAY:
      return 'snap-status-tooltip-missing-end-snap-overlay';
    case SnapProblem.ILLEGAL_STATE:
    case SnapProblem.NO_BUILD:
    case SnapProblem.BUILD_ERROR:
      return 'snap-status-tooltip-build-error';
    case ExtendedIncompleteStatus.SNAP_PART_OF_STITCHING_CONTAINS_IMAGE:
      return 'snap-status-tooltip-contains-image';
    case ExtendedIncompleteStatus.INCONSISTENT_DIMENSIONS:
      return 'snap-status-tooltip-snap-inconsistent-dimentions';
    case SnapProblem.BUILD_OVERSIZED:
      return 'snap-status-tooltip-build-oversized';
    case SnapProblem.BUILDING:
      return 'snap-status-tooltip-building';
    case ExtendedIncompleteStatus.SENSITIVE_CONTENT:
      return 'snap-status-tooltip-sensitive-content';
    case SnapProblem.INCOMPLETE:
    default:
      return 'snap-status-tooltip-snap-incomplete';
  }
};

export const buildStatusToPrimaryMessageId = (buildStatus?: BuildStatusType | null): string | undefined | null => {
  if (!buildStatus) {
    return null;
  }

  switch (buildStatus.extendedStatus) {
    case ExtendedIncompleteStatus.WEBVIEW_LIMIT_REACHED:
      return 'snap-status-webview-limit-reached-primary';
    default:
      return null;
  }
};

export const buildStatusToSecondaryMessageId = (buildStatus?: BuildStatusType | null): string | undefined | null => {
  if (!buildStatus) {
    return null;
  }

  switch (buildStatus.extendedStatus) {
    case ExtendedIncompleteStatus.WEBVIEW_LIMIT_REACHED:
      return 'snap-status-webview-limit-reached-secondary';
    default:
      return null;
  }
};
