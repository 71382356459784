import axios from 'axios';
import { saveAs } from 'file-saver';
import invariant from 'invariant';
import JSZip from 'jszip';

import { DecryptedMedia, decryptMedia } from '../media/crypto';

import { StorySnap } from 'gql/queries/spotlight/storySnaps';

export type FileInput = {
  href: string;
  filename: string;
};

const DOWNLOAD_TIMEOUT = 5000;
const FILE_NAME = 'Snapchat Story';
const ZIP_FILE_NAME = 'Snapchat Stories';

function downloadFile({ href, filename }: any) {
  const a = document.createElement('a');
  a.href = href;
  a.target = '_parent';
  a.download = filename;
  invariant(document.body, 'Body is not defined.');
  document.body.appendChild(a);
  a.click();
  if (a.parentNode) {
    a.parentNode.removeChild(a);
  }
}

export function bulkDownloadFiles(files: FileInput[]): Promise<void> {
  return new Promise(resolve => {
    downloadFile(files.shift());
    const downloadInterval = setInterval(() => {
      if (files.length === 0) {
        clearInterval(downloadInterval);
        resolve();
      } else {
        downloadFile(files.shift());
      }
    }, DOWNLOAD_TIMEOUT);
  });
}

// ZIP and download snap media which is encrypted
export const zipDownLoadEncryptedMedia = async (snaps: StorySnap[]): Promise<void> => {
  const zip = new JSZip();

  await Promise.all(
    snaps.map(async (snap, index) => {
      let snapUrl = snap.video?.url;
      if (!snapUrl) {
        await decryptMedia(snap)
          .then((decryptedMedia: DecryptedMedia | null) => {
            snapUrl = decryptedMedia?.snapUrl || '';
          })
          .catch(e => Promise.reject(e));
      }

      // Fetch the image and parse the response stream as a blob
      const { data } = await axios({
        url: snapUrl,
        method: 'GET',
        responseType: 'blob', // important
      });

      const fileExtension = 'mp4';

      const fileName = `${FILE_NAME} ${index + 1}.${fileExtension}`; // without the index it only writes to one file

      // create a new file from the blob object
      const mediaFile = new File([data], fileName);

      // save videos in a folder
      const mediaFolder = zip.folder(ZIP_FILE_NAME);
      mediaFolder?.file(fileName, mediaFile, { base64: true });
    })
  );

  return zip.generateAsync({ type: 'blob' }).then((content: any) => {
    saveAs(content, ZIP_FILE_NAME);
  });
};
