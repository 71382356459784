import is from 'is_js';

export const isMobile = () => {
  return is.mobile() || is.tablet();
};
export const isDesktop = () => {
  return is.chrome() || is.firefox() || is.edge() || is.safari();
};
export const isScreenshotSupportAvailable = () => {
  return !!(
    navigator &&
    navigator.mediaDevices &&
    typeof (navigator.mediaDevices as any).getDisplayMedia === 'function' &&
    typeof (window as any).ImageCapture === 'function'
  );
};

export const isWasmEnabled = () => {
  return typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function';
};
