import Input from 'antd/lib/input';
import type { InputProps } from 'antd/lib/input';
import classNames from 'classnames';
import is from 'is_js';
import React from 'react';
import type { ReactNode, ChangeEvent } from 'react';
import { intlShape, FormattedMessage } from 'react-intl';

import { enumObject } from 'utils/enum';
import type { Enum } from 'utils/enum';
import { getLocalisedMessageFromId, registerIntlMessage } from 'utils/intlMessages/intlMessages';

import Icon from 'views/common/components/Icon/Icon';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';

import style from './SDSInput.scss';
import { checkExceededMaxLength } from './inputValidationUtils';

// Tooltip message
registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="input-exceeded-max-length"
      description="Error message if input text length exceeds max length"
      defaultMessage={'Enter a maximum of {maxLength} characters'}
    />
  ),
  params: ['maxLength'],
});

export const InputType = enumObject({
  NUMBER: 'number',
  FILE: 'file',
});

export type InputTypeEnum = Enum<typeof InputType>;

type Props = InputProps & {
  errorMessage?: ReactNode;
  labelTitle?: ReactNode;
  placeholder?: ReactNode;
  suffix?: string | ReactNode;
  inlineIcon?: string;
  value?: string | number;
  className?: string;
  onKeyDown?: (event: KeyboardEvent) => void;
  validateInput?: (value: any) => boolean;
  onValidation?: (value: boolean) => void;
  'data-test': string;
  type?: InputTypeEnum;
  withoutDefaultMargin?: boolean;
  min?: number;
};

export type OwnState = {
  isValid: boolean;
  exceededLength: boolean | undefined | null;
  inputValue?: string | null;
};

class SDSInput extends React.Component<Props, OwnState> {
  static contextTypes = {
    intl: intlShape,
  };

  state = {
    isValid: true,
    exceededLength: false,
    inputValue: null,
  };

  componentDidMount() {
    let value: string | number | undefined = this.props.value;
    if (typeof value === 'number') {
      value = value.toString();
    }
    this.setState({ inputValue: value });
  }

  componentDidUpdate(prevProps: Props, prevState: OwnState) {
    // React suggests setState can be called as long as wrapped in an if statement
    if (prevProps.value !== this.props.value && prevProps.value === this.state.inputValue) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type '((string | number | readonly string[]) & (st... Remove this comment to see the full error message
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ inputValue: this.props.value });
    }

    // handle Error if it exists
    if (prevProps.errorMessage !== this.props.errorMessage || prevState.exceededLength !== this.state.exceededLength) {
      this.validateDisplayedValue();
    }
  }

  getCharCount = () => {
    const { maxLength } = this.props;

    if (maxLength) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return this.state.inputValue ? maxLength - Number(this.state.inputValue.length) : maxLength;
    }
    return null;
  };

  getInputValue = () => {
    const { maxLength, value } = this.props;
    const charCount = this.getCharCount();

    if (this.state.inputValue && charCount) {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      return charCount < 0 ? this.state.inputValue.substr(0, maxLength) : this.state.inputValue;
    }

    return value;
  };

  onChangeHandler = (event: ChangeEvent<EventTarget>) => {
    const { validateInput, onChange, maxLength } = this.props;
    if (!onChange) {
      return;
    }

    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'EventTarg... Remove this comment to see the full error message
      target: { value },
    } = event;

    let isValid = true;
    let exceededLength = false;

    if (maxLength) {
      const inputValue = value;
      exceededLength = checkExceededMaxLength(inputValue, maxLength);

      this.setState({ exceededLength, inputValue });
    }

    if (validateInput) {
      isValid = validateInput(value);
      this.setState({ isValid });
    }

    // note that the validation will not fail if value exceeded maxLength,
    // the onChange should go ahead, crop the input value to the fit maxLength and show an error message
    if (!isValid) {
      return;
    }

    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'ChangeEvent<EventTarget>' is not... Remove this comment to see the full error message
    onChange(event);
  };

  renderIcon = () => {
    const { inlineIcon } = this.props;
    if (inlineIcon) {
      return <Icon className={style.inputIcon} inlineIcon={inlineIcon} />;
    }
    return null;
  };

  renderInput = (inputHasError?: boolean | null) => {
    const {
      labelTitle,
      placeholder,
      type,
      value,
      disabled,
      onPressEnter,
      onBlur,
      maxLength,
      onKeyDown,
      suffix,
      autoFocus,
      min,
    } = this.props;

    const placeholderMessage = placeholder || getLocalisedMessageFromId(this.context, 'enter-text');

    const controlledInput = (
      <Input
        className={style.input}
        placeholder={placeholderMessage}
        onChange={this.onChangeHandler}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        data-test={`${this.props['data-test']}.SDSInput`}
        type={type}
        value={this.getInputValue()}
        disabled={disabled}
        suffix={suffix || this.getCharCount()}
        onKeyDown={onKeyDown}
        prefix={this.renderIcon()}
        autoFocus={autoFocus}
        min={min}
      />
    );

    const unControlledInput = (
      <Input
        className={style.input}
        placeholder={placeholderMessage}
        onChange={this.onChangeHandler}
        onPressEnter={onPressEnter}
        onBlur={onBlur}
        data-test={`${this.props['data-test']}.SDSInput`}
        type={type}
        disabled={disabled}
        onKeyDown={onKeyDown}
        suffix={suffix}
        prefix={this.renderIcon()}
        autoFocus={autoFocus}
        min={min}
      />
    );

    const input = value || maxLength ? controlledInput : unControlledInput;

    if (labelTitle) {
      return (
        <SDSLabel
          data-test={`${this.props['data-test']}.label`}
          labelTitle={labelTitle}
          className={inputHasError ? style.labelWithError : null}
          withoutDefaultMargin={this.props.withoutDefaultMargin}
        >
          {input}
        </SDSLabel>
      );
    }
    return input;
  };

  validateDisplayedValue = () => {
    const { errorMessage, onValidation } = this.props;
    if (onValidation && (errorMessage || this.state.exceededLength)) {
      onValidation(false);
    } else if (onValidation) {
      onValidation(true);
    }
  };

  shouldShowErrorMessage = () => {
    return this.props.errorMessage || !this.state.isValid || this.state.exceededLength;
  };

  renderErrorMessage = () => {
    const { errorMessage, maxLength, labelTitle, withoutDefaultMargin } = this.props;

    const errorMsgClassNames = classNames({
      [style.errorMsg]: true,
      [style.errorMsgWithLabel]: is.existy(labelTitle),
      [style.noMargin]: withoutDefaultMargin,
    });

    return (
      <>
        {maxLength &&
          this.state.exceededLength && ( // Number helps handle a case where charCount is null
            <div data-test={`${this.props['data-test']}.maxLength.exceeded.errorMsg`} className={errorMsgClassNames}>
              {getLocalisedMessageFromId(this.context, 'input-exceeded-max-length', {
                maxLength,
              })}
            </div>
          )}
        {errorMessage && <div className={errorMsgClassNames}>{errorMessage}</div>}
      </>
    );
  };

  render() {
    const inputContainerClassNames = classNames({
      [style.parent]: true,
      // @ts-expect-error ts-migrate(2464) FIXME: A computed property name must be of type 'string',... Remove this comment to see the full error message
      [this.props.className]: true,
      [style.error]: this.shouldShowErrorMessage(),
    });

    return (
      <div className={inputContainerClassNames}>
        {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message */}
        {this.renderInput(this.shouldShowErrorMessage())}
        {this.shouldShowErrorMessage() ? this.renderErrorMessage() : null}
      </div>
    );
  }
}

export default SDSInput;
