import React from 'react';
import type { ReactNode } from 'react';

import { help } from 'icons/SDS/allIcons';

import Icon from 'views/common/components/Icon/Icon';
import SDSLabel from 'views/common/components/SDSLabel/SDSLabel';
import SDSTilesGroup from 'views/common/components/SDSTilesGroup/SDSTilesGroup';
import SDSTooltip, { TooltipPosition } from 'views/common/components/SDSTooltip/SDSTooltip';

import style from './PublisherDetailOptionRow.scss';

type ButtonParams = {
  key: any;
  text: string | ReactNode;
};

type TilesGroupInterface = {
  buttons: ButtonParams[];
  selectedKey: any;
  onKeySelected: (a: any) => void;
};

type Props = TilesGroupInterface & {
  className?: string;
  disabled?: boolean;
  toolTipText?: string;
  title: ReactNode;
};

export default class PublisherDetailOption extends React.PureComponent<Props> {
  render() {
    const { buttons, onKeySelected, selectedKey, disabled, title, toolTipText } = this.props;

    const tiles = buttons.map(button => ({
      ...button,
      callback: () => onKeySelected(button.key),
      selected: button.key === selectedKey,
      disabled,
    }));

    var labelTitle = toolTipText ? (
      <div className={style.labelWrap}>
        {title}
        <SDSTooltip
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          placement={TooltipPosition.TOP}
          title={toolTipText}
          id="publisher-detail-option-tooltip"
        >
          <Icon inlineIcon={help} className={style.infoIcon} />
        </SDSTooltip>
      </div>
    ) : (
      title
    );

    return (
      <div className={style.labelTitle}>
        <SDSLabel labelTitle={labelTitle}>
          <SDSTilesGroup tiles={tiles} />
        </SDSLabel>
      </div>
    );
  }
}
