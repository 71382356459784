import type { Dispatch } from 'redux';

import * as mediaLibrarySelectors from 'state/mediaLibrary/selectors/mediaLibrarySelectors';
import * as mediaLibraryTraySelectors from 'state/mediaLibrary/selectors/mediaLibraryTraySelectors';

import { createToggleItem, createClearTray } from '../../snapGrid/actions/snapGridActionsUtils';

import type { GetState } from 'types/redux';

export const TOGGLE_MEDIA = 'mediaLibraryTray/TOGGLE_MEDIA';
export const CLEAR_TRAY = 'mediaLibraryTray/CLEAR_TRAY';
export const CLEAR_AND_SELECT_MEDIA = 'mediaLibraryTray/CLEAR_AND_SELECT_MEDIA'; // Select only the media in the payload

export const toggleMedia = createToggleItem(
  mediaLibrarySelectors.getMediaById,
  mediaLibrarySelectors.getMediaList,
  mediaLibraryTraySelectors.getLastSelectedMedia,
  TOGGLE_MEDIA
);
export const clearTray = createClearTray(CLEAR_TRAY);
export const clearAndSelectMedia = (itemId: string) => {
  return (dispatch: Dispatch, getState: GetState) => {
    const item = mediaLibrarySelectors.getMediaById(getState())(itemId);
    return dispatch({
      type: CLEAR_AND_SELECT_MEDIA,
      payload: {
        itemId,
        item,
      },
    });
  };
};
