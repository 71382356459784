import is from 'is_js';

import * as creativeSuiteAPI from 'utils/apis/creativeSuiteAPI';
import * as locationUtils from 'utils/locationUtils';

import type { AssetID } from 'types/assets';
import type { SnapId } from 'types/common';
import type { EditionID } from 'types/editionID';
import type { PublisherID } from 'types/publishers';
import type { CreativeID } from 'types/snaps';

type SnapPublisherEmbedUrlParams = {
  publisherId: PublisherID;
  editionId: EditionID;
  snapId: SnapId;
  creativeId?: CreativeID;
  isSnapSSO: boolean;
  sourceContentUrl?: string | null;
  assetId?: AssetID | null;
  advancedMode?: boolean;
  trimMode?: boolean | null;
  layerAssetId?: AssetID;
};

export const snapPublisherEmbedUrl = ({
  publisherId,
  editionId,
  snapId,
  creativeId,
  isSnapSSO,
  sourceContentUrl,
  assetId,
  advancedMode,
  trimMode,
  layerAssetId,
}: SnapPublisherEmbedUrlParams) => {
  if (sourceContentUrl) {
    return creativeSuiteAPI.creative.embedImportMode(
      creativeId,
      isSnapSSO
    )({
      publisherId,
      editionId,
      snapId,
      sourceContentUrl,
      trimMode,
      ref: locationUtils.getOriginHref(),
    });
  }

  // If we detect that this function has been passed an assetId (video or image) and we also have
  // not been passed a creativeId, then we import the asset
  // We do it this way round because we want to prioritise the creativeId import over the asset import
  if (assetId && !creativeId) {
    return creativeSuiteAPI.creative.embedImportTopsnapAssetMode(isSnapSSO)({
      publisherId,
      editionId,
      snapId,
      trimMode,
      importAssetId: assetId,
      ...(is.truthy(layerAssetId !== undefined) && { layerAssetId }),
      ref: locationUtils.getOriginHref(),
    });
  }

  if (advancedMode && !creativeId) {
    return creativeSuiteAPI.creative.embedAdvancedMode(isSnapSSO)({
      publisherId,
      editionId,
      snapId,
      trimMode,
      ref: locationUtils.getOriginHref(),
    });
  }

  return creativeSuiteAPI.creative.embedMode(
    creativeId,
    isSnapSSO
  )({
    publisherId,
    editionId,
    snapId,
    trimMode,
    ref: locationUtils.getOriginHref(),
  });
};

export const snapPublisherEmbedCacheUrl = (isSnapSSO: boolean) => {
  return creativeSuiteAPI.creative.embedCacheMode(isSnapSSO)({
    ref: locationUtils.getOriginHref(),
  });
};

export const snapPublisherSnapAuthHandshake = () => {
  return creativeSuiteAPI.creative.snapAuthHandshake({
    ref: locationUtils.getOriginHref(),
  });
};

export const snapPublisherFrameThumbnailImage = (mediaId: string, frame: number) => {
  return creativeSuiteAPI.creative.thumbnailImage(mediaId, frame)();
};
