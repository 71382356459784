import type { QueryEntryTypeEnum } from 'config/constants';
import { MlsMediaProfile, QueryEntryType } from 'config/constants';
import * as mediaLibraryAPI from 'utils/apis/mediaLibraryAPI';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { createAssetUrl } from 'utils/media/assetUtils';

import type { CuratedSnapMediaType } from 'types/curation';
import { CuratedSnapType } from 'types/curation';
import type { FilterByType, MediaItem, MediaType, TargetType } from 'types/mediaLibrary';
import { FilterByEnum, MediaTypeEnum, TargetEnum } from 'types/mediaLibrary';
import { SnapType } from 'types/snaps';

export function isVideo(mediaType: MediaType): boolean {
  return mediaType === MediaTypeEnum.VIDEO_SNAP || mediaType === MediaTypeEnum.VIDEO_ATTACHMENT;
}

export function convertResponseToMediaItem(apiResponseMedia: any) {
  return {
    id: apiResponseMedia.id,
    fileName: getOrFillInFileName(apiResponseMedia),
    mediaType: convertMediaProfileToMediaType(apiResponseMedia),
    mediaUrl: apiResponseMedia.previewUrl,
    createdAt: apiResponseMedia.createdAt,
    contentLength: apiResponseMedia.contentLength,
    width: getDimensionFromResponse(apiResponseMedia, 'width'),
    height: getDimensionFromResponse(apiResponseMedia, 'height'),
    duration: apiResponseMedia.videoMetadata ? apiResponseMedia.videoMetadata.durationMs : undefined,
    mimeType: apiResponseMedia.mimeType,
    haveBaseMediaAndOverlay: apiResponseMedia.haveBaseMediaAndOverlay,
    creatorUsername: apiResponseMedia.creatorUsername,
    isCuratedMedia: apiResponseMedia.mediaProfile === MlsMediaProfile.CURATED_SNAP_MEDIA,
  };
}

function getOrFillInFileName(apiResponseMedia: any) {
  if (apiResponseMedia.fileName) {
    return apiResponseMedia.fileName;
  }
  switch (apiResponseMedia.mediaProfile) {
    case MlsMediaProfile.TOP_SNAP_MEDIA:
    case MlsMediaProfile.CURATED_SNAP_MEDIA:
      if (apiResponseMedia.videoMetadata) {
        return getMessageFromId('media-library-uploaded-snap');
      }
      return getMessageFromId('media-library-uploaded-image');
    case MlsMediaProfile.LONGFORM_VIDEO:
      return getMessageFromId('media-library-uploaded-video-attachment');
    default:
      // Should never get here because response should only be top snap or LFV for now
      return null;
  }
}

function getDimensionFromResponse(apiResponseMedia: any, fieldName: any) {
  if (apiResponseMedia.videoMetadata) {
    return apiResponseMedia.videoMetadata[fieldName];
  }
  if (apiResponseMedia.imageMetadata) {
    return apiResponseMedia.imageMetadata[fieldName];
  }
  return null;
}

export function convertFilterByToQueries(
  filterBy: FilterByType,
  target: TargetType,
  isShowPublisher: boolean
): {
  mediaProfile: Array<MlsMediaProfile>;
  contentType?: string;
  entryType?: QueryEntryTypeEnum;
} {
  // Show publisher
  if (isShowPublisher) {
    switch (filterBy) {
      case FilterByEnum.VIDEO_SNAPS:
        return {
          mediaProfile: [MlsMediaProfile.SHOWS_SNAP_MEDIA],
          contentType: 'video',
        };
      case FilterByEnum.VIDEO_ATTACHMENTS:
        return {
          mediaProfile: [MlsMediaProfile.LONGFORM_VIDEO],
          contentType: 'video',
        };
      case FilterByEnum.RECENT:
        if (target === TargetEnum.TOP_SNAP) {
          return {
            mediaProfile: [MlsMediaProfile.SHOWS_SNAP_MEDIA],
            contentType: 'video',
          };
        }
        if (target === TargetEnum.VIDEO_ATTACHMENT) {
          return {
            mediaProfile: [MlsMediaProfile.LONGFORM_VIDEO],
            contentType: 'video',
          };
        }
        return {
          mediaProfile: [MlsMediaProfile.SHOWS_SNAP_MEDIA, MlsMediaProfile.LONGFORM_VIDEO],
          contentType: 'video',
        };
      default:
        return {
          mediaProfile: [MlsMediaProfile.SHOWS_SNAP_MEDIA, MlsMediaProfile.LONGFORM_VIDEO],
          contentType: 'video',
        };
    }
  }

  // default publisher
  switch (filterBy) {
    case FilterByEnum.VIDEO_SNAPS:
      return {
        mediaProfile: [MlsMediaProfile.TOP_SNAP_MEDIA],
        contentType: 'video',
      };
    case FilterByEnum.IMAGE_SNAPS:
      return {
        mediaProfile: [MlsMediaProfile.TOP_SNAP_MEDIA],
        contentType: 'image',
      };
    case FilterByEnum.VIDEO_ATTACHMENTS:
      return {
        mediaProfile: [MlsMediaProfile.LONGFORM_VIDEO],
        contentType: 'video',
      };
    case FilterByEnum.RECENT:
      if (target === TargetEnum.TOP_SNAP) {
        return {
          mediaProfile: [MlsMediaProfile.TOP_SNAP_MEDIA],
        };
      }
      if (target === TargetEnum.VIDEO_ATTACHMENT) {
        return {
          mediaProfile: [MlsMediaProfile.LONGFORM_VIDEO],
        };
      }
      return {
        mediaProfile: [MlsMediaProfile.TOP_SNAP_MEDIA, MlsMediaProfile.LONGFORM_VIDEO],
      };
    case FilterByEnum.CURATED_SNAPS:
      return {
        mediaProfile: [MlsMediaProfile.TOP_SNAP_MEDIA, MlsMediaProfile.CURATED_SNAP_MEDIA],
        entryType: QueryEntryType.IMPORTED_SNAP,
      };
    default:
      return {
        mediaProfile: [MlsMediaProfile.TOP_SNAP_MEDIA, MlsMediaProfile.LONGFORM_VIDEO],
      };
  }
}

export function generateSnapProperties(mediaType: MediaType | CuratedSnapMediaType, assetId: string) {
  switch (mediaType) {
    case CuratedSnapType.VIDEO:
    case MediaTypeEnum.VIDEO_SNAP:
      return {
        videoAssetId: assetId,
        type: SnapType.VIDEO,
      };
    case CuratedSnapType.IMAGE:
    case MediaTypeEnum.IMAGE_SNAP:
      return {
        imageAssetId: assetId,
        type: SnapType.IMAGE,
      };
    case MediaTypeEnum.VIDEO_ATTACHMENT:
      return {
        longformVideoAssetId: assetId,
        type: SnapType.LONGFORM_VIDEO,
      };
    default:
      return {};
  }
}

export function getRichSnapTypeFromMediaType(mediaType: MediaType) {
  if (mediaType === MediaTypeEnum.IMAGE_SNAP) {
    return SnapType.IMAGE;
  }
  return SnapType.VIDEO;
}

/**
 * Whether a MediaItem can be added to a snap, given whether the snap is a top
 * or bottom snap
 */
export function canMediaBeAddedToSnap(mediaItem: MediaItem, isTopSnap: boolean) {
  if (isTopSnap) {
    return mediaItem.mediaType === MediaTypeEnum.VIDEO_SNAP || mediaItem.mediaType === MediaTypeEnum.IMAGE_SNAP;
  }
  return mediaItem.mediaType === MediaTypeEnum.VIDEO_ATTACHMENT;
}

function convertMediaProfileToMediaType(apiResponseMedia: any) {
  switch (apiResponseMedia.mediaProfile) {
    case MlsMediaProfile.TOP_SNAP_MEDIA:
    case MlsMediaProfile.CURATED_SNAP_MEDIA:
      if (apiResponseMedia.videoMetadata) {
        return MediaTypeEnum.VIDEO_SNAP;
      }
      return MediaTypeEnum.IMAGE_SNAP;
    case MlsMediaProfile.LONGFORM_VIDEO:
      return MediaTypeEnum.VIDEO_ATTACHMENT;
    default:
      return MediaTypeEnum.VIDEO_SNAP;
  }
}

// Map target page (top snap editor, video attachment editor, or none) to accepted media types
export const mapTargetTypeToMediaType = {
  [TargetEnum.ANY]: [
    MediaTypeEnum.IMAGE_SNAP,
    MediaTypeEnum.VIDEO_SNAP,
    MediaTypeEnum.VIDEO_ATTACHMENT,
    MediaTypeEnum.UNKNOWN_SNAP,
  ],
  [TargetEnum.TOP_SNAP]: [MediaTypeEnum.IMAGE_SNAP, MediaTypeEnum.VIDEO_SNAP, MediaTypeEnum.UNKNOWN_SNAP],
  [TargetEnum.VIDEO_ATTACHMENT]: [MediaTypeEnum.VIDEO_ATTACHMENT],
};

export const getDownloadFilename = (mediaItem: MediaItem) => {
  // Check whether the file has extension name. If the media does not have a filename on server, the filename
  // field may be 'Uploaded Snap' etc. and we need to append an extension to it
  if (mediaItem.fileName.split('.').length > 1) {
    return mediaItem.fileName;
  }
  if (mediaItem.mimeType) {
    return `${mediaItem.fileName}.${mediaItem.mimeType.split('/').pop()}`;
  }

  return mediaItem.fileName;
};

export const getDownloadUrl = (mediaItem: MediaItem) => {
  return createAssetUrl(
    mediaItem.id,
    { downloadFilename: getDownloadFilename(mediaItem) },
    mediaLibraryAPI.asset.forceDownload
  );
};
