export enum BITMOJI_TEMPLATES {
  USER_LAPTOP = '20054902',
  USER_CHECKBOX = '20053717',
  USER_OKAYS = '20053717',
  USER_SELFIE = '20085427',
  USER_GRAPH = '20076487',
  USER_QUESTION = '10222419',
  USER_NICE_SNAP = '20022695',
  USER_CORRECT = '20002520',
  USER_LAPTOP_READ = '20054939',
  USER_CHECK = '20002520',
  USER_COOL = '10219858',
  USER_GUITAR = '10221214',
  USER_POINT_UPWARD = '20053424',
  USER_ERROR = '20054601',
}

export const DEFAULT_BITMOJI_AVATAR = '424563504_3-s5';

export function getBitmojiUrl(templateId: BITMOJI_TEMPLATES, avatarId = DEFAULT_BITMOJI_AVATAR) {
  return `https://us-east1-aws.api.snapchat.com/render/panel/${templateId}-${avatarId}-v1.png?transparent=1&palette=1`;
}
