import { createSelector as s } from 'reselect';

import { shouldShowModeration } from 'state/buildStatus/schema/moderationHelpers';
import { getTileAudience } from 'state/buildStatus/selectors/buildStatusSelectors';
import { reselectById } from 'state/common/selectorFactories';
import {
  isFirstSnapInEdition,
  findSegmentForSnap,
  findSnapIndexInSegment,
} from 'state/editions/schema/editionEntityHelpers';
import { getEditionById, getTilesByEditionId, getAllEditions } from 'state/editions/selectors/editionsSelectors';
import { getSegmentReduxId } from 'state/segments/schema/segmentEntityHelpers';
import { getTilesBySegmentId } from 'state/segments/selectors/segmentsSelectors';
import { getSnapById, getTilesByWholeSnapId } from 'state/snaps/selectors/snapsSelectors';
import { getTileId } from 'state/tiles/schema/tilesIdUtils';

import type { SnapId } from 'types/common';
import type { Edition, EditionID } from 'types/editions';
import type { Segment } from 'types/segments';
import type { TopSnap } from 'types/snaps';
import type { Tile, TileID } from 'types/tiles';
import { TileFlavor } from 'types/tiles';

function filterDiscoverFeedPresentationTiles(tiles: Tile[]): Tile[] {
  return tiles.filter(tile => tile.tileFlavor !== TileFlavor.HN_SQUARE);
}

const getPresentationalTilesForSnap = reselectById(
  null,
  (state: any, snapId: any, editionId: any) => getEditionById(state)(editionId),
  (state: any, snapId: any, editionId: any) => getSnapById(state)(snapId),
  getTilesByWholeSnapId,
  getTilesBySegmentId,
  getTilesByEditionId,
  (
    edition?: Edition | null,
    snap?: TopSnap | null,
    // @ts-expect-error ts-migrate(1016) FIXME: A required parameter cannot follow an optional par... Remove this comment to see the full error message
    getTilesByWholeSnapIdFn: any,
    getTilesBySegmentIdFn: any,
    getTilesByEditionIdFn: any
  ): Tile[] | undefined | null => {
    if (!snap || !edition) {
      return null;
    }

    const isFirstSnap = edition ? isFirstSnapInEdition(edition, snap.id) : false;

    const segment: Segment | undefined | null = edition ? findSegmentForSnap(edition, snap.id) : null;
    const snapIndexInSegment = findSnapIndexInSegment(segment, snap.id);

    // The archived editions of multiTile publishers cannot be migrated via the automated script
    // as they are in a locked state when the page is open
    // Manually take the tiles from the snap
    let tiles: Tile[] | undefined | null = getTilesByWholeSnapIdFn(snap.id);
    if (segment && snapIndexInSegment === 0) {
      tiles = getTilesBySegmentIdFn(getSegmentReduxId(segment));
    } else if (snapIndexInSegment != null && snapIndexInSegment > 0) {
      tiles = null;
    } else if ((!tiles || tiles.length === 0) && isFirstSnap) {
      tiles = getTilesByEditionIdFn(edition.id);
    }

    return tiles;
  }
);

export const getPresentationalTileForSnap = reselectById<Tile | undefined | null, SnapId>(
  null,
  (state: any, snapId: any, editionId: any) => getPresentationalTilesForSnap(state)(snapId, editionId),
  (tiles: Tile[] | null | undefined): Tile | undefined | null => {
    if (!tiles) {
      return null;
    }

    const filteredTiles = filterDiscoverFeedPresentationTiles(tiles);
    return filteredTiles[0] || null;
  }
);

export const getPresentationalTilesForEdition = reselectById<Tile[] | undefined | null, EditionID>(
  null,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  getPresentationalTilesForSnap,
  // @ts-expect-error ts-migrate(1016) FIXME: A required parameter cannot follow an optional par... Remove this comment to see the full error message
  (edition?: Edition | null, getPresentationalTilesForSnapFn: any): Tile | undefined | null => {
    if (!edition) {
      return null;
    }

    const firstSnapId = edition.snapIds && edition.snapIds.length > 0 ? edition.snapIds[0] : null;
    if (!firstSnapId) {
      return null;
    }

    return getPresentationalTilesForSnapFn(firstSnapId, edition.id);
  }
);

export const getAllTilesForEdition = reselectById<Tile[] | undefined | null, EditionID>(
  null,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  getTilesByEditionId,
  getTilesByWholeSnapId,
  getTilesBySegmentId,
  (
    edition?: Edition | null,
    // @ts-expect-error ts-migrate(1016) FIXME: A required parameter cannot follow an optional par... Remove this comment to see the full error message
    getTilesByEditionIdFn: any,
    getTilesByWholeSnapIdFn: any,
    getTilesBySegmentIdFn: any
  ): Tile[] | undefined | null => {
    if (!edition) {
      return null;
    }

    let tiles: Tile[] = getTilesByEditionIdFn(edition.id) || [];

    if (edition.segments) {
      tiles = edition.segments.reduce((acc, segment) => {
        const segmentTiles = getTilesBySegmentIdFn(getSegmentReduxId(segment));
        return segmentTiles ? acc.concat(segmentTiles) : acc;
      }, tiles);
    }

    if (edition.snapIds) {
      tiles = edition.snapIds.reduce((acc, snapId) => {
        const snapTiles = getTilesByWholeSnapIdFn(snapId);
        return snapTiles ? acc.concat(snapTiles) : acc;
      }, tiles);
    }

    return tiles;
  }
);

export const getPresentationalTileForEdition = reselectById<Tile | undefined | null, EditionID>(
  null,
  (state: any, editionId: any) => getPresentationalTilesForEdition(state)(editionId),
  (tiles: Tile[] | null | undefined): Tile | undefined | null => {
    if (!tiles) {
      return null;
    }

    const filteredTiles = filterDiscoverFeedPresentationTiles(tiles);
    return filteredTiles[0] || null;
  }
);

export const getPresentationTileForAllEditionsMap = s(
  getAllEditions,
  getPresentationalTileForEdition,
  (allEditions, getPresentationalTileForEditionFn) => {
    return (
      Object.keys(allEditions)
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        .reduce((map, editionId) => ({ ...map, [editionId]: getPresentationalTileForEditionFn(editionId) }), {})
    );
  }
);

export const getTileForEditionByCroppedImageAssetId = reselectById<Tile | undefined | null, EditionID>(
  null,
  (state: any, editionId: any) => getAllTilesForEdition(state)(editionId),
  (state: any, editionId: any, croppedImageAssetId: any) => croppedImageAssetId,
  (tiles?: Tile[] | null, croppedImageAssetId?: TileID | null) => {
    if (!tiles) {
      return null;
    }
    return tiles.filter(tile => String(tile.croppedImageAssetId) === String(croppedImageAssetId))[0] || null;
  }
);

export const getTileForEditionByScsId = reselectById<Tile | undefined | null, EditionID>(
  null,
  (state: any, editionId: any) => getAllTilesForEdition(state)(editionId),
  (state: any, editionId: any, scsId: any) => scsId,
  (tiles?: Tile[] | null, scsId?: TileID | null) => {
    if (!tiles) {
      return null;
    }
    return tiles.filter(tile => String(tile.scsId) === String(scsId))[0] || null;
  }
);

export const areAllPresentationTilesForEditionFlaggedByModeration = reselectById<boolean, EditionID>(
  false,
  (state: any, editionId: any) => getPresentationalTilesForEdition(state)(editionId),
  getTileAudience,
  // @ts-expect-error ts-migrate(1016) FIXME: A required parameter cannot follow an optional par... Remove this comment to see the full error message
  (tiles?: Tile[] | null, getTileAudienceFn: any): boolean => {
    if (!tiles || tiles.length === 0) {
      return false;
    }

    return !tiles.some(tile => !shouldShowModeration(getTileAudienceFn(getTileId(tile))));
  }
);
