import { gql } from '@apollo/client';

export const CREATOR_FIELDS = gql`
  fragment CreatorFields on Creator {
    title
    hostUsername
    publisherId
    businessProfileId
    hostUserId
    isTestProfile
    userProperties {
      autoApproveComments
    }
  }
`;
