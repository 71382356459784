import React from 'react';
import InlineSVG from 'svg-inline-react';

import { CrossOrigin } from 'config/constants';

import style from './PublisherDropdownMenu.scss';

type Props = {
  menuLogoIcon?: string;
  menuLogoSvg?: string;
  menuName: string;
  menuSubtitle: string | undefined | null;
  menuActionSvg: string | undefined | null;
  style?: string;
  onClick?: (() => void) | null;
};

export class PublisherDropdownMenu extends React.PureComponent<Props> {
  renderMenuLogo = () => {
    if (this.props.menuLogoIcon) {
      return (
        <img
          className={style.menuLogoIcon}
          crossOrigin={CrossOrigin.USE_CREDENTIALS}
          src={this.props.menuLogoIcon}
          alt={this.props.menuName}
        />
      );
    }

    return <InlineSVG src={this.props.menuLogoSvg} className={style.menuLogoIcon} />;
  };

  render() {
    const { menuName, menuSubtitle, menuActionSvg, onClick } = this.props;
    return (
      // @ts-expect-error ts-migrate(2322) FIXME: Type '(() => void) | null | undefined' is not assi... Remove this comment to see the full error message
      <div className={style.dropdownMenu} onClick={onClick}>
        <div className={style.menuContainer}>
          {this.renderMenuLogo()}
          <div className={style.menuDetails}>
            <div className={style.menuName}>{menuName}</div>
            <div className={style.menuSubtitle}>{menuSubtitle}</div>
          </div>
        </div>
        {menuActionSvg ? <InlineSVG className={style.menuAction} src={menuActionSvg} /> : null}
      </div>
    );
  }
}

export default PublisherDropdownMenu;
