import is from 'is_js';
import _ from 'lodash';
import log from 'loglevel';

import * as transactionsSelectors from 'state/transactions/selectors/transactionsSelectors';

import { Sequence } from 'config/constants';
import { GetState } from 'src/types/redux';
import { assertArg } from 'utils/assertionUtils';
import { isPromise } from 'utils/promiseUtils';
import u from 'utils/safeUpdeep';

export const START_TRANSACTION = 'transaction/START_TRANSACTION';
export const COMPLETE_TRANSACTION = 'transaction/COMPLETE_TRANSACTION';
export const ROLLBACK_TRANSACTION = 'transaction/ROLLBACK_TRANSACTION';

const SELECTOR_MAPS = {};
const NORMALIZE_MAPS = {};

export const registerEntityTransaction = (entityType: any, selector: any, normalizeFunc: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityType).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(selector).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(normalizeFunc).is.function();

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (SELECTOR_MAPS[entityType]) {
    log.error(`A selector is already mapped for entityType '${entityType}'`);
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (NORMALIZE_MAPS[entityType]) {
    log.error(`A normalization function is already mapped for entityType '${entityType}'`);
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  SELECTOR_MAPS[entityType] = selector;
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  NORMALIZE_MAPS[entityType] = normalizeFunc;
};

export const unregisterEntityTransaction = (entityType: any) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  delete SELECTOR_MAPS[entityType];
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  delete NORMALIZE_MAPS[entityType];
};

/**
 * Creates a new transaction relating to a given set of entities in the store. When the
 * transaction starts, a snapshot of each of the entities is taken and placed on the
 * transaction object. This is later used by the rollbackTransaction() action if the
 * transaction fails.
 *
 * The following safety checks are enforced:
 *
 *   - A transaction cannot start if another transaction of the same type is already in progress
 *   - A transaction cannot start if another transaction is already using one or more of its entities
 *
 * Usage:
 *
 *   // With a single entity
 *   dispatch(startTransaction('MY_TRANSACTION', { snap: 42 }));
 *
 *   // With multiple entities of the same type
 *   dispatch(startTransaction('MY_TRANSACTION', { snap: [ 42, 84 ] }));
 *
 *   // With entities of different types
 *   dispatch(startTransaction('MY_TRANSACTION', { snap: [ 42, 84 ], edition: 99 }));
 *
 * Note that that withTransaction() helper will be more convenient than manually calling
 * start/complete/rollbackTransaction() in most circumstances.
 *
 * @param transactionType
 * @param entityMap
 */
export const startTransaction = (transactionType: any, entityMap: any) => (dispatch: any, getState: GetState) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(transactionType).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityMap).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityMap).is.not.empty();

  const entityMapCopy = standardizeEntityMap(entityMap);
  const entities = createSnapshotOfEntities(entityMapCopy, transactionType, getState);

  transactionSafetyCheck(getState, entityMapCopy);

  return dispatch({
    type: START_TRANSACTION,
    sequence: Sequence.START,
    payload: {
      transactionType,
      entityMap: entityMapCopy,
      entities,
    },
  });
};

// Standardize so that all entries in the entity map are lists of ids (just makes
// the rest of the code easier to write).
function standardizeEntityMap(entityMap: any) {
  const entityMapCopy = {};

  Object.keys(entityMap).forEach(entityType => {
    const ids = entityMap[entityType];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    entityMapCopy[entityType] = Array.isArray(ids) ? ids : [ids];
  });

  return entityMapCopy;
}

function createSnapshotOfEntities(entityMap: any, transactionType: any, getState: GetState) {
  let entities = {};

  Object.keys(entityMap).forEach(entityType => {
    const ids = entityMap[entityType];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const selector = SELECTOR_MAPS[entityType];
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const normalizeFunc = NORMALIZE_MAPS[entityType];

    if (!selector) {
      throw new Error(`Cannot start transaction - no selector is mapped for entity ${entityType}`);
    }

    if (!normalizeFunc) {
      throw new Error(`Cannot start transaction - no normalizer function is mapped for entity ${entityType}`);
    }

    // Take a snapshot of the entities we're locking
    const entityArray = ids
      .map((id: any) => {
        const entity = selector(getState())(id);

        if (is.not.existy(entity)) {
          log.error(
            `A ${entityType} entity with id ${id} could not be found when starting transaction ${transactionType}`
          );
        }
        return entity;
      })
      .filter((entity: any) => is.existy(entity));

    const normalizedEntities = normalizeFunc(entityArray);
    if (is.array(normalizedEntities.result)) {
      // Must merge all entities together
      // entities = u(normalizedEntities.entities, entities);
      entities = u(
        _.mapValues(normalizedEntities.entities, entitiesOfType =>
          _.mapValues(entitiesOfType, entity => u.constant(entity))
        ),
        entities
      );
    }
  });

  return entities;
}

/**
 * Must be dispatched when a transaction has successfully completed. No special
 * handling in a reducer is necessary, but not calling this method to complete
 * your transactions will result in new transactions failing to start.
 *
 * @param transaction
 */
export const completeTransaction = (transaction: any, finalResult = undefined) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(transaction).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(transaction.payload).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(transaction.payload.transactionType).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(transaction.payload.entityMap).is.object();

  const { transactionType, entityMap } = transaction.payload;

  return {
    type: COMPLETE_TRANSACTION,
    sequence: Sequence.DONE,
    payload: {
      transactionType,
      finalResult,
      entityMap,
    },
  };
};

/**
 * Can be dispatched to roll back a transaction in the event of an error. Note that
 * in order to implement the actual rollback of an entity, you must add handling for
 * the ROLLBACK_TRANSACTION action type in your reducer(s).
 *
 * @param transaction
 */
export const rollbackTransaction = (transaction: any) => (dispatch: any, getState: GetState) => {
  const { transactionType, entityMap, entities } = transaction.payload;

  return dispatch({
    type: ROLLBACK_TRANSACTION,
    sequence: Sequence.DONE,
    payload: {
      transactionType,
      entityMap,
      entities,
    },
  });
};

function transactionSafetyCheck(getState: GetState, entityMap: any) {
  const transactions = transactionsSelectors.getTransactions(getState());

  Object.keys(transactions).forEach(transactType => {
    if (!transactions[transactType].error) {
      entitySafetyCheck(entityMap, transactions[transactType].entityMap);
    }
  });
}

function entitySafetyCheck(entityMapA: any, entityMapB: any) {
  Object.keys(entityMapA).forEach(entityType => {
    if (!entityMapB[entityType]) {
      return;
    }

    const intersection = _.intersection(entityMapA[entityType], entityMapB[entityType]);

    if (intersection.length) {
      const entityLabel = intersection.length > 1 ? 'entities' : 'entity';
      const intersectionLabel = intersection.length > 1 ? `[${intersection}]` : intersection[0];
      const errorMessage = `A transaction has already locked ${entityType} ${entityLabel} ${intersectionLabel}`;
      log.error(errorMessage);
      throw new Error(errorMessage);
    }
  });
}

/**
 * Convenience wrapper for use with transactions wrap a promise. Calls startTransaction(),
 * completeTransaction() and rollbackTransaction() for you, based on the outcome of your
 * promise.
 *
 * Real world example:
 *
 *   export function setSnapPropertiesAndSave({ snapId }, properties) {
 *     assertSnapId(snapId);
 *     assertArg(name).is.string();
 *
 *     return withTransaction(TransactionType.SET_SNAP_PROPERTY, { snap: snapId }, dispatch => {
 *       return Promise.resolve()
 *         .then(() => dispatch(setSnapProperty(snapId, name, value)))
 *         .then(() => dispatch(saveSnap({ snapId })));
 *      });
 *   }
 *
 * This type of usage will be sufficient for most cases. For more complex cases, you can
 * also define your own additional rollback steps, which will be executed _after_ the
 * transaction has been rolled back, using the afterRollback() hook:
 *
 *   return withTransaction(TransactionType.SET_SNAP_PROPERTY, { snap: snapId }, (dispatch, getState, afterRollback) => {
 *     afterRollback(() => {
 *       dispatch(myCustomRollbackAction());
 *     });
 *
 *     return Promise.resolve()
 *       .then(() => dispatch(setSnapProperty(snapId, name, value)))
 *       .then(() => dispatch(saveSnap({ snapId })));
 *    });
 *
 * @param transactionType
 * @param entityMap
 * @param fn
 */
export function withTransaction(transactionType: any, entityMap: any, fn: any, errorHandler: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(transactionType).is.string();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(entityMap).is.object();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(fn).is.function();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(errorHandler).is.function();

  return (dispatch: any, getState: GetState) => {
    try {
      return dispatch(() => {
        const transaction = startTransaction(transactionType, entityMap)(dispatch, getState);

        // Gives the calling code a chance to define some additional actions that
        // should be performed after the transaction itself has been rolled back.
        // This is useful for rolling back state that doesn't directly relate to
        // a specific entity in the store.
        const afterRollbackFns: any = [];
        function afterRollback(rollbackFn: any) {
          afterRollbackFns.push(rollbackFn);
        }

        function errorRollback(error: any) {
          dispatch(rollbackTransaction(transaction));
          // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'rollbackFn' implicitly has an 'any' typ... Remove this comment to see the full error message
          afterRollbackFns.forEach(rollbackFn => rollbackFn());
          return Promise.reject(error);
        }

        // Invoke the action, expecting that it returns a promise
        let result;
        try {
          result = fn(dispatch, getState, afterRollback);
        } catch (error) {
          errorRollback(error);
          throw error;
        }
        if (!isPromise(result)) {
          const error = new Error('Transacted actions must return a promise');
          errorRollback(error);
          throw error;
        }

        return result
          .then((finalResult: any) => dispatch(completeTransaction(transaction, finalResult)))
          .catch((error: any) => errorRollback(error));
      }).catch((err: any) => errorHandler(dispatch, err));
    } catch (err) {
      return errorHandler(dispatch, err);
    }
  };
}
