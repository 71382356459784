import { captureException, withScope } from '@sentry/browser';
import is from 'is_js';
import _ from 'lodash';
import log from 'loglevel';
import React from 'react';
import { FormattedMessage, MessageValue } from 'react-intl';

import * as notificationsActions from 'state/notifications/actions/notificationsActions';

import { NotificationScope, StatusMessageSeverity, StatusMessageButton } from 'config/constants';
import { assertArg } from 'utils/assertionUtils';
import { ErrorCodes, ErrorCodesEnum } from 'utils/errors/api/apiErrorConstants';
import { apiSpecificErrorContext } from 'utils/errors/api/apiErrorFunc';
import { ErrorContexts } from 'utils/errors/errorConstants';
import type { ErrorContextEnum } from 'utils/errors/errorConstants';
import * as gaUtils from 'utils/gaUtils';
import { incrementCounter } from 'utils/grapheneUtils';
import * as intlMessages from 'utils/intlMessages/intlMessages';
import { registerIntlMessage } from 'utils/intlMessages/intlMessages';

import { ApiError, ApiErrorCodeMessage, ApiErrorNotification, ParamExtractor } from 'types/errors';
import type { NotificationScopeEnum, StatusMessageSeverityEnum, Notification } from 'types/notifications';

export const missingParamValueParamExtractor: ParamExtractor = (error: any) => {
  const apiResponse = _.get(error, ['fetchResults', 'data']);
  if (!apiResponse) {
    return {};
  }

  const params = _.get(apiResponse, ['params'], {});
  return {
    params: is.array(params) ? params[0] : params,
    code: apiResponse.code,
    status: apiResponse.status,
  };
};

// this should only be used, when there is type error
export const typeErrorParamExtractor = (error: any) => {
  const apiResponse = _.get(error, ['fetchResults', 'data']);
  if (!apiResponse) {
    return {};
  }

  const params = _.get(apiResponse, ['params'], {});

  return {
    type: params
      .find((param: any) => param.indexOf('Unsupported mimeType') >= 0)
      .split(' ')
      .pop(),
  };
};

export const errorCodeParamExtractor: ParamExtractor = (error: any) => _.get(error, ['fetchResults', 'data']);

const messageHasFinalPeriod = (message: any) => {
  const defaultMessage = _.get(message, ['props', 'defaultMessage']) || '';
  return defaultMessage.endsWith('.');
};

const unknownContextMessage = intlMessages.registerIntlMessage({
  intlMessage: (
    <FormattedMessage
      id="error-message-unknown"
      description="Message shown to user when an error could not be traced"
      defaultMessage="An unknown error happened"
    />
  ),
  params: [],
});

const unknownErrorCodeMessage = {
  message: (
    <FormattedMessage
      id="error-message-error-code-unknown"
      description="Generic message for an API error code response"
      defaultMessage="Error status: {status}, error code: {code}"
    />
  ),
  paramExtractor: errorCodeParamExtractor,
};

const sentenceContatenationMessage = (
  <FormattedMessage
    id="error-message-concatenation-structure"
    description="Sentence structure designed to concatenate two sentences that have no final period"
    defaultMessage="{sentence1}. {sentence2}."
  />
);

registerIntlMessage({
  intlMessage: sentenceContatenationMessage,
  params: [],
});

const mergeContextAndErrorCodeMessages = (
  contextMessage: any,
  errorCodeMessage: any,
  params: { [key: string]: MessageValue }
) => {
  const renderedContextMessage = intlMessages.getMessageBodyFromIntlMessage(contextMessage, params);
  const renderedErrorCodeMessage = intlMessages.getMessageBodyFromIntlMessage(errorCodeMessage, params);
  return intlMessages.getMessageBodyFromIntlMessage(sentenceContatenationMessage, {
    sentence1: renderedContextMessage,
    sentence2: renderedErrorCodeMessage,
  });
};

const mergeAndGenerateRichErrorMessage = (
  contextMessage: any,
  errorCodeMessage: any,
  params: any,
  linkMessage: any,
  uriLink: any
) => {
  const renderedContextMessage = intlMessages.getMessageBodyFromIntlMessage(contextMessage, params);
  const renderedErrorCodeMessage = intlMessages.getMessageBodyFromIntlMessage(errorCodeMessage, params);
  const renderLinkMessage = intlMessages.getMessageBodyFromIntlMessage(linkMessage, {});
  const mergedText = intlMessages.getMessageBodyFromIntlMessage(sentenceContatenationMessage, {
    sentence1: renderedContextMessage,
    sentence2: renderedErrorCodeMessage,
  });

  const results = [];
  results.push(
    {
      plainText: mergedText,
    },
    {
      plainText: renderLinkMessage,
      link: uriLink,
    }
  );
  return results;
};

let messagePerErrorCode: { [key in ErrorCodesEnum]: ApiErrorCodeMessage } = {};

let notificationMessages: { [key in ErrorContextEnum]: ApiErrorNotification } = {};

/*
 * Registers a new error context. These enable different error notifications to be displayed
 * so that a specific api error can map to different error messages depending on context.
 *
 * An optional 'defaultMessage' can be passed so that a 'catch-all' message is displayed when a message for the
 * (context, errorCode) pair is not registered. This allows new backend error codes to be added without breaking
 * the application
 */
export const registerNewApiErrorContext = (
  context: ErrorContextEnum,
  notificationScope: NotificationScopeEnum,
  severity: StatusMessageSeverityEnum,
  message: JSX.Element,
  options: { hideErrorCode: boolean } = { hideErrorCode: false }
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(context).is.inArray(Object.values(ErrorContexts));

  if (notificationMessages[context]) {
    throw new Error(`Error context ${context} already registered`);
  }

  if (messageHasFinalPeriod(message) && !options.hideErrorCode) {
    log.warn(`API error message for context ${context} should not have final period as it will be concatenated`);
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(notificationScope).is.inArray(Object.values(NotificationScope));
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(severity).is.inArray(Object.values(StatusMessageSeverity));
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(message).is.object();

  notificationMessages[context] = {
    notificationScope,
    severity,
    messagesPerErrorCode: {},
    options,
    message: intlMessages.registerIntlMessage({
      intlMessage: message,
      params: [],
    }),
  };
};

/*
 * Registers a new message for an API error code. Can pass an optional parameter to extract parameters from the error
 * to include in the message.
 */
export const registerNewApiErrorCode = (
  errorCode: string,
  message: JSX.Element,
  paramExtractor: ParamExtractor = errorCodeParamExtractor
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(errorCode).is.inValues(ErrorCodes);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(paramExtractor).is.function();

  if (messagePerErrorCode[errorCode]) {
    throw new Error(`Error code ${errorCode} already registered`);
  }

  if (messageHasFinalPeriod(message)) {
    log.warn(`API error code message for ${errorCode} should not have final period as it will be concatenated`);
  }

  messagePerErrorCode[errorCode] = {
    message,
    paramExtractor,
  };
};

/*
 * Registers a new message for a specific (context, errorCode) pair. When an api error that matches this pair is received,
 * this specific error will be shown to the user instead of the default message registered with registerNewApiErrorContext.
 *
 * A context must have been previously registered with registerNewApiErrorContext.
 */
export const registerNewApiErrorMessage = (context: any, errorCode: any, message: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(context).is.inArray(Object.values(ErrorContexts));

  if (!notificationMessages[context]) {
    throw new Error(`Error context ${context} is not registered`);
  }

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(errorCode).is.inArray(Object.values(ErrorCodes));

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (notificationMessages[context].messagesPerErrorCode[errorCode]) {
    throw new Error(`Error code ${errorCode} is already registered in context ${context}`);
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  notificationMessages[context].messagesPerErrorCode[errorCode] = {
    message: intlMessages.registerIntlMessage({
      intlMessage: message,
      params: [],
    }),
  };
};

export const getApiErrorMessage = (context: any, error: ApiError, options: any = null): Notification => {
  const contextConfig = notificationMessages[context] ||
    notificationMessages[ErrorContexts.UNKNOWN] || {
      notificationScope: NotificationScope.GLOBAL,
      severity: StatusMessageSeverity.ERROR,
      options: {
        hideErrorCode: false,
      },
    };

  incrementCounter('notification.apiError', {
    context: context || ErrorContexts.UNKNOWN,
  });

  const apiResponse = _.get(error, ['fetchResults', 'data'], {});
  const specificMessage = _.get(contextConfig, ['messagesPerErrorCode', apiResponse.code]);
  if (specificMessage) {
    return {
      severity: contextConfig.severity,
      scope: contextConfig.notificationScope,
      plainMessage: specificMessage.message.intlMessage,
      buttons: [StatusMessageButton.DISMISS],
    };
  }

  const config = _.get(contextConfig, ['message']);

  let contextMessage;
  if (!config) {
    log.error(`Could not find notification message for context ${context}`);
    contextMessage = unknownContextMessage.intlMessage;

    gaUtils.logGAEvent(gaUtils.GAQoSMetrics.ERROR, 'unknown-error-context', {
      context,
    });
  } else {
    contextMessage = config.intlMessage;
  }

  const result = apiSpecificErrorContext(
    context,
    apiResponse,
    messagePerErrorCode,
    options,
    error,
    errorCodeParamExtractor,
    contextConfig,
    mergeContextAndErrorCodeMessages,
    mergeAndGenerateRichErrorMessage,
    StatusMessageButton,
    contextMessage
  )();

  if (result) {
    return result;
  }

  if (!messagePerErrorCode[apiResponse.code]) {
    gaUtils.logGAEvent(gaUtils.GAQoSMetrics.ERROR, 'unknown-error-body', {
      context,
      code: apiResponse.code,
    });
  }

  const errorCodeConfig = messagePerErrorCode[apiResponse.code] || unknownErrorCodeMessage;
  const params = errorCodeConfig.paramExtractor(error, options) || errorCodeParamExtractor(error);
  const plainMessage = contextConfig.options.hideErrorCode
    ? _.get(contextConfig, ['message', 'intlMessage'])
    : mergeContextAndErrorCodeMessages(contextMessage, errorCodeConfig.message, params);

  return {
    severity: contextConfig.severity,
    scope: contextConfig.notificationScope,
    plainMessage,
    buttons: [StatusMessageButton.DISMISS],
  };
};

export const clearAllMessages = () => {
  notificationMessages = {};
  messagePerErrorCode = {};
};

export const isApiError = (error: any) => Boolean(_.get(error, ['fetchResults', 'data']));

const ERROR_CONTEXT_TAG = 'ErrorContext';
export const reportCustomErrorToSentry = (error: any, errorContextTag: string) => {
  withScope(scope => {
    scope.setTag(ERROR_CONTEXT_TAG, errorContextTag);
    captureException(error);
  });
};

export const apiErrorHandler = (dispatch: any, context: any, options: any = null): any => {
  return (error: any) => {
    if (isApiError(error) && !error.alreadyHandled) {
      reportCustomErrorToSentry(error, context);
      dispatch(
        notificationsActions.sendNotificationMessage(getApiErrorMessage(context, error, options), {
          context,
        })
      );

      // TODO: replace Error with custom cms hierarchy
      error.alreadyHandled = true; // eslint-disable-line no-param-reassign
    }
    return Promise.reject(error);
  };
};
