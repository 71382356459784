import { ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import invariant from 'invariant';
import log from 'loglevel';
import type { Store } from 'redux';

import { flagAuthAsFailedPermanently, performAuthRefresh } from 'state/auth/actions/authActions';
import { getAuthAge, getAuthType, getToken } from 'state/auth/selectors/authSelectors';

import { ADS_GATEWAY_GRAPHQL_BASE_URL, AuthType, BearerPrefix, SSAPI_PROXY_GRAPHQL_BASE_URL } from 'config/constants';
import { getAuthHeaderForEndpoint } from 'utils/authUtils';
import { isExpired } from 'utils/dateUtils';
import { incrementCounter } from 'utils/grapheneUtils';

import { GetState } from 'types/redux';

let store: Store | null = null;

export function initStore(reduxStore: Store) {
  store = reduxStore;
}

function dispatchWithStore(reduxStore: Store | null, givenAction: (dispatch: any, getState: GetState) => any) {
  invariant(reduxStore, 'Store was not set in graphql link');
  return reduxStore.dispatch(givenAction(reduxStore.dispatch, reduxStore.getState));
}

function getAuthToken() {
  invariant(store, 'Store was not set in graphql link');
  return getToken(store.getState());
}

const BearerPrefixByEndpoint = {
  [ADS_GATEWAY_GRAPHQL_BASE_URL]: {
    [AuthType.GOOGLE]: BearerPrefix.BEARER,
    [AuthType.SNAPCHAT]: BearerPrefix.BEARER,
  },
  [SSAPI_PROXY_GRAPHQL_BASE_URL]: {
    [AuthType.GOOGLE]: BearerPrefix.BEARER,
    [AuthType.SNAPCHAT]: BearerPrefix.SNAP_BEARER,
  },
};

function getBearerPrefix(endpoint: string): string | null {
  invariant(store, 'Store was not set in graphql link');
  const authType = getAuthType(store.getState());
  if (!authType) {
    log.error('AuthType not set in apollo auth link.');
    return null;
  }

  const bearerConfigPerEndpoint = BearerPrefixByEndpoint[endpoint];
  if (!bearerConfigPerEndpoint) {
    log.error(`BearerConfig not defined for ${endpoint} in apollo auth link.`);
    return null;
  }

  return bearerConfigPerEndpoint[authType] || null;
}

function generateAuthHeaders(authHeader: any, bearerPrefix: string, authToken: Readonly<{}>): Record<string, string> {
  return {
    [authHeader]: `${bearerPrefix} ${authToken}`,
  };
}

async function getAuthHeaders(graphqlEndpoint: string): Promise<Record<string, string>> {
  const bearerPrefix = getBearerPrefix(graphqlEndpoint);
  if (!bearerPrefix) {
    log.error(`Bearer prefix not defined for endpoint: ${graphqlEndpoint}`);
    throw new Error(`Bearer prefix not defined for: ${graphqlEndpoint}`);
  }

  const authToken = getAuthToken();
  if (!authToken) {
    log.error(`Auth token not defined for: ${graphqlEndpoint}`);
    throw new Error(`Auth token not defined for: ${graphqlEndpoint}`);
  }

  const authHeader = getAuthHeaderForEndpoint(graphqlEndpoint);
  if (!authHeader) {
    log.error(`Auth header not defined for: ${graphqlEndpoint}`);
    throw new Error(`Auth header not defined for: ${graphqlEndpoint}`);
  }

  invariant(store, 'Store was not set in graphql link');

  // Check the token age
  const authAge = getAuthAge(store.getState());

  // If it's not expired then return the headers
  if (!isExpired(authAge)) {
    return generateAuthHeaders(authHeader, bearerPrefix, authToken);
  }

  // If the token is expired
  try {
    // Perform an auth refresh
    await dispatchWithStore(store, performAuthRefresh);
    const newAuthToken = getAuthToken();

    if (!newAuthToken) {
      log.error(`Auth token not defined for: ${graphqlEndpoint}`);
      await dispatchWithStore(store, flagAuthAsFailedPermanently);
      throw new Error(`Auth token not defined for: ${graphqlEndpoint}`);
    }
    incrementCounter('Login.GraphQL', { action: 'RefreshToken.Success' });
    return generateAuthHeaders(authHeader, bearerPrefix, newAuthToken);
  } catch (e) {
    // if the auth refresh fails then fail permanently
    log.error(`Failed to perform auth refresh for: ${graphqlEndpoint}`);
    incrementCounter('Login.GraphQL', { action: 'RefreshToken.Failed' });
    await dispatchWithStore(store, flagAuthAsFailedPermanently);
    throw e;
  }
}

const withHeaders = (graphqlEndpoint: string) =>
  setContext(async () => {
    const authHeaders = await getAuthHeaders(graphqlEndpoint);
    return { authHeaders };
  });

const authMiddleware = new ApolloLink((operation, forward) => {
  const { authHeaders } = operation.getContext();
  operation.setContext(({ headers }: Record<string, any>) => ({
    headers: {
      ...headers,
      ...authHeaders,
    },
  }));
  return forward(operation);
});

export const storyStudioProxyAuthLink = ApolloLink.from([withHeaders(SSAPI_PROXY_GRAPHQL_BASE_URL), authMiddleware]);
export const adsGatewayAuthLink = ApolloLink.from([withHeaders(ADS_GATEWAY_GRAPHQL_BASE_URL), authMiddleware]);
