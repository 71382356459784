import { parse, format } from 'url';

import is from 'is_js';
import _ from 'lodash';
import isURL from 'validator/lib/isURL';

import { UriType, FileType } from 'config/constants';

import { replaceLocation } from './locationUtils';

const URL_VALIDATION_OPTIONS = {
  protocols: ['http', 'https'],
};

export function getUriType(uri: any) {
  if (is.string(uri)) {
    if (uri.startsWith('data:')) {
      return UriType.DATA_URI;
    }
    if (uri.startsWith('blob:')) {
      return UriType.BLOB_URI;
    }
    if (uri.startsWith('http')) {
      // TODO: bug in the next line? flow says UriType.HTTP_URI does not exist
      return (UriType as any).HTTP_URI;
    }
  }

  return null;
}

export function isDataUri(uri: any) {
  return getUriType(uri) === UriType.DATA_URI;
}

export function isUri(uri: any) {
  return getUriType(uri) !== null;
}

export function isValidUri(uri: any, options: any) {
  if (!uri || !is.string(uri)) {
    return false;
  }

  // validation module does not test for unsafe characters
  const hasUnsafeChars = /[<>'"{}|\\^~`]/.test(uri);
  return !hasUnsafeChars && isURL(uri, options || URL_VALIDATION_OPTIONS);
}

// Affiliate url should match https://go.skimresources.com?id=[ID]X[SUB_ID]&xs=[NONCE]&url=[MERCHANT_URL]
// Example: https://go.skimresources.com/?id=189983X1660937&xs=1&url=https%3A%2F%2Fwww.saatva.com%2F
//
// Skimlinks Redirect: https://go.skimresources.com/
// Publisher ID X Domain ID: id=189983X1660937
// Nonce (unique/anonymized identifier for the user/click): xs=1
// Encoded Product URL: url=https%3A%2F%2Fwww.saatva.com%2F

const affiliatePattern = /^(?:https:\/\/)?go.skimresources.com\/\?id=([0-9]+X[0-9]+)&xs=([0-9]+)&url=(.*)$/;

export function isValidAffiliateUrl(url: string): boolean {
  if (!affiliatePattern.test(url)) {
    return false;
  }
  const match = affiliatePattern.exec(url);
  if (!match || match.length < 4) {
    return false;
  }
  const productUrl = match[3];
  if (!productUrl) {
    return false;
  }
  return isValidUri(decodeURIComponent(productUrl), { protocols: ['http', 'https'] });
}

export function removeErrorParamIfAnyFromUrl(windowUrl: any) {
  if (_.includes(windowUrl, 'error=')) {
    const parsedUrl = parse(windowUrl, true);

    if (parsedUrl.query) {
      delete parsedUrl.query.error;
    }

    // @ts-expect-error ts-migrate(2790) FIXME: The operand of a 'delete' operator must be optiona... Remove this comment to see the full error message
    delete parsedUrl.search;

    replaceLocation(format(parsedUrl));
  }
}

export const inferFileTypeFromUrl = (url: string) => {
  // Check for a .mp4 extension on the url
  // This is the advised method of detecting the file type from the asset passed from stories
  // everywhere integration

  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  if (!isValidUri(url)) {
    return FileType.UNKNOWN;
  }

  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const extension = url.split(/#|\?/)[0].split('.').pop().trim();
  if (extension === 'mp4') {
    return FileType.VIDEO;
  }
  return FileType.IMAGE;
};
