import { Schema, arrayOf } from 'normalizr';

import { idFromBrightcoveReferenceId } from 'utils/brightcoveUtils';

export const videoResult = new Schema('videoResult', {
  // The `referenceId` is the longformVideoAssetId. This is the most useful id to
  // index on, as it is stored on the snap and therefore the most commonly available
  // value when we need to look up metadata for a video.
  idAttribute: result => {
    return idFromBrightcoveReferenceId(result.referenceId);
  },
});

export const imageAsset = new Schema('imageAsset', {
  idAttribute: 'assetId',
});

export const subtitle = new Schema('subtitle', {
  idAttribute: 'id',
});

videoResult.define({
  images: {
    thumbnail: imageAsset,
    poster: imageAsset,
  },
  textTracks: arrayOf(subtitle),
});
