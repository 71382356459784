// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module '@sna... Remove this comment to see the full error message
import { NavItem, NavItemsGroup, SideNavigation } from '@snapchat/snapnet'; // discover-cms/no-snapnet
import classNames from 'classnames';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'glob... Remove this comment to see the full error message
import global from 'global';
import _ from 'lodash';
import React from 'react';
import { intlShape } from 'react-intl';

import { isSingleAssetStoryEditorEnabled } from 'state/features/selectors/featuresSelectors';
import { showModal } from 'state/modals/actions/modalsActions';
import { closeSidebar } from 'state/navLayout/actions/navLayoutActions';
import { isSidebarVisible } from 'state/navLayout/selectors/navLayoutSelectors';
import { getActivePublisherDetails } from 'state/publishers/selectors/publishersSelectors';
import { goToCreatorNoPermission, goToNoPermission, push } from 'state/router/actions/routerActions';
import {
  getActiveCreatorHostUsername,
  getActivePublisherId,
  hasClaimForActivePublisher,
  hasSecureActionForActiveCreator,
  hasSnapchatEmployeePermissions,
  isActiveCreatorPublisher,
} from 'state/user/selectors/userSelectors';

import { State } from 'src/types/rootState';
import { creator } from 'utils/apis/localRoutes';
import { intlConnect } from 'utils/connectUtils';
import { withRouter } from 'utils/routerUtils';

import style from './SnapSideNav.scss';
import { getSidebarConfig, getSidebarConfigSimpleStoryBuilder } from './sidebarConfig';

import { Claim, SecureAction } from 'types/permissions';
import { PublisherDetails } from 'types/publishers';

const mapStateToProps = (state: State, ownProps: any) => {
  const { match } = ownProps;
  const paramCopy = { ...match.params };
  if (!paramCopy.publisherId && ownProps.activePublisherId) {
    paramCopy.publisherId = ownProps.activePublisherId;
  }
  const activePublisherId = getActivePublisherId(state);
  const activePublisherDetails = getActivePublisherDetails(state);
  return {
    activePublisherDetails,
    activePublisherId,
    activeCreatorHostUsername: getActiveCreatorHostUsername(state),
    hasSnapchatEmployeePermissions: hasSnapchatEmployeePermissions(state),
    isAdminUserManager: hasClaimForActivePublisher(state, Claim.ADMIN_USER_MANAGER),
    isAnalyticsViewer: hasClaimForActivePublisher(state, Claim.ANALYTICS_VIEWER),
    isDailyAnalyticsViewer: hasClaimForActivePublisher(state, Claim.ANALYTICS_DAILY_VIEWER),
    isInternalAdminDashboardViewer: hasClaimForActivePublisher(state, Claim.INTERNAL_ADMIN_DASHBOARD_VIEWER),
    isMediaEditor: hasClaimForActivePublisher(state, Claim.MEDIA_EDITOR),
    isMonetizationAnalystViewer: hasClaimForActivePublisher(state, Claim.MONETIZATION_ANALYST_VIEWER),
    isNotificationBannerEditor: hasClaimForActivePublisher(state, Claim.NOTIFICATION_BANNER_EDITOR),
    isOldSCAdminEditor: hasClaimForActivePublisher(state, Claim.OLD_SC_ADMIN_EDITOR),
    isPublisherUserManager: hasClaimForActivePublisher(state, Claim.PUBLISHER_USER_MANAGER),
    isRevenueViewer: hasClaimForActivePublisher(state, Claim.REVENUE_VIEWER),
    isSubscriptionsManager: hasClaimForActivePublisher(state, Claim.SUBSCRIPTIONS_MANAGER),
    isSuperAdminViewer: hasClaimForActivePublisher(state, Claim.SUPER_ADMIN_VIEWER),
    isSuperNavViewer: hasClaimForActivePublisher(state, Claim.SUPER_NAV_VIEWER),
    isSuperOnboardingManager: hasClaimForActivePublisher(state, Claim.SUPER_ONBOARDING_MANAGER),
    isStoryViewer:
      hasClaimForActivePublisher(state, Claim.STORY_VIEWER) ||
      hasSecureActionForActiveCreator(state, SecureAction.READ_CONTAINER_ENTITY),
    isTopicAdminManager: hasClaimForActivePublisher(state, Claim.TOPIC_STICKER_CRUD),
    isAdvancedCurator: hasClaimForActivePublisher(state, Claim.ADVANCED_CURATOR),
    open: isSidebarVisible(state),
    isSingleAssetStoryEditorEnabled: isSingleAssetStoryEditorEnabled(state),
    isActiveCreatorPublisher: isActiveCreatorPublisher(state),
  };
};
const mapDispatchToProps = {
  push,
  goToNoPermission,
  goToCreatorNoPermission,
  showModal,
  closeSidebar,
};

type SnapSideNavProps = {
  activePublisherDetails?: PublisherDetails;
  activePublisherId?: number;
  activeCreatorHostUsername: string | null;
  hasSnapchatEmployeePermissions?: boolean;
  isOrgOnboardingManager?: boolean;
  isAdminUserManager?: boolean;
  isAnalyticsViewer?: boolean;
  isInternalAdminDashboardViewer?: boolean;
  isPublisherUserManager?: boolean;
  isRevenueViewer?: boolean;
  isStoryViewer?: boolean;
  isSubscriptionsManager?: boolean;
  isSuperAdminViewer?: boolean;
  isSuperNavViewer?: boolean;
  isTopicAdminManager?: boolean;
  isAdvancedCurator?: boolean;
  isSingleAssetStoryEditorEnabled: boolean;
  isMediaLibraryUIEnabled: boolean;
  location: any;
  match: any;
  open: boolean;
};

export class SnapSideNav extends React.PureComponent<SnapSideNavProps> {
  static contextTypes = {
    intl: intlShape,
  };

  _params: any;

  _sidebarConfig: any;

  UNSAFE_componentWillMount() {
    this.refreshSidebarConfig(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (this.props.open !== nextProps.open) {
      // Value 150 is same as Snapnet animation time 0.15s
      // Send this event to resize analytics graphs
      const { dispatchEvent, Event } = global;
      setTimeout(() => {
        dispatchEvent(new Event('resize'));
      }, 150 * 1.2);
    }
    const oldSidebarConfig = this._sidebarConfig;
    const oldParams = this._params;
    this.refreshSidebarConfig(nextProps);

    // If the user is in noPermission page, there won't be any active creator
    const isNotFoundOrNoPermissionPage =
      this.props.match.path === creator.noPermission.path || this.props.match.path === creator.notFound.path;
    if (isNotFoundOrNoPermissionPage && nextProps.activeCreatorHostUsername) {
      (this.props as any).push(creator.home({ hostUsername: nextProps.activeCreatorHostUsername }));
    }
    // If active publisher changed we must redirect to a valid path
    // We try to use the same path that is already active, if that is not possible (different permissions, etc)
    // then we redirect to home/analytics depending on role.
    else if (
      this.props.activeCreatorHostUsername &&
      this.props.activeCreatorHostUsername !== nextProps.activeCreatorHostUsername
    ) {
      // Determining the active item from the side nav bar for the old publisher
      const links = _.flatten(oldSidebarConfig.map((group: any) => group.links));
      const activeItem = _.find(
        links,
        link => (link as any).checkIfRouteActive(this.props.location.pathname, oldParams).active
      );
      // Finding the same active item from the side nav for the new publisher
      const newLinks = _.flatten(this._sidebarConfig.map((group: any) => group.links));
      const newActiveItem = _.find(newLinks, link => (link as any).key === _.get(activeItem, ['key']));
      if (newActiveItem) {
        // If new publisher has access to the same path as the old, redirect to it
        (newActiveItem as any).clickHandler();
      } else {
        // If no path selected in sidenav (not found, no permission page, etc), redirect according to permission
        (this.props as any).push(creator.home({ hostUsername: nextProps.activeCreatorHostUsername }));
      }
    }
  }

  refreshSidebarConfig(props: any) {
    const { match } = props;
    const paramCopy = { ...match.params };
    paramCopy.hostUsername = props.activeCreatorHostUsername;
    const sidebarProps = _.pick(props, [
      'activePublisherDetails',
      'activePublisherId',
      'hasSnapchatEmployeePermissions',
      'isAdminUserManager',
      'isAnalyticsViewer',
      'isDailyAnalyticsViewer',
      'isInternalAdminDashboardViewer',
      'isMediaEditor',
      'isMonetizationAnalystViewer',
      'isNotificationBannerEditor',
      'isOldSCAdminEditor',
      'isRevenueViewer',
      'isSubscriptionsManager',
      'isSuperAdminViewer',
      'isSuperOnboardingManager',
      'isPublisherUserManager',
      'isStoryViewer',
      'isTopicAdminManager',
      'isAdvancedCurator',
      'isSingleAssetStoryEditorEnabled',
      'isActiveCreatorPublisher',
      'location',
      'push',
      'showModal',
    ]);
    this._params = paramCopy;
    if (!this.props.isSuperNavViewer) {
      this._sidebarConfig = getSidebarConfigSimpleStoryBuilder({ props: sidebarProps, params: paramCopy });
    } else {
      this._sidebarConfig = getSidebarConfig({ props: sidebarProps, params: paramCopy });
    }
  }

  handleClick(clickHandler: any, event: any) {
    (this.props as any).closeSidebar();
    event.preventDefault();
    clickHandler();
  }

  doNothing(event: any) {
    event.preventDefault();
  }

  renderSidebarItems() {
    const { intl } = this.context;
    return this._sidebarConfig.map((
      group: any // TODO: (Alex) modify snapnet to accept nodes here
    ) => (
      //              modify snapnet to event.preventDefault the group click?
      <NavItemsGroup key={group.key} title={intl.formatMessage(group.title.props)} className={style.navGroup} open>
        {group.links.map(({ path, title, clickHandler, checkIfRouteActive }: any) => {
          const { active, clickable } = checkIfRouteActive(this.props.location.pathname, this._params);
          const navProps = {
            className: clickable ? style.clickable : '',
            active,
            href: path.startsWith('http') || path.startsWith('mailto') ? path : `/v2${path}`,
            onClick: clickable ? this.handleClick.bind(this, clickHandler) : this.doNothing.bind(this),
          };
          const dataTestName = typeof title === 'object' ? title.props.defaultMessage : title;
          return (
            <NavItem key={path} {...navProps} data-test={`snapSideNav.navItem.${dataTestName}`}>
              {title}
            </NavItem>
          );
        })}
      </NavItemsGroup>
    ));
  }

  render() {
    const contentClasses = classNames({
      [style.sidenavContent]: true,
      [style.showSidenav]: this.props.open,
    });
    return (
      <SideNavigation.Wrapper open={this.props.open}>
        <SideNavigation.Content className={contentClasses}>{this.renderSidebarItems()}</SideNavigation.Content>
        <SideNavigation.Page className={style.navPage} data-test="SideNavigation.page">
          {this.props.children}
        </SideNavigation.Page>
      </SideNavigation.Wrapper>
    );
  }
}
export default withRouter(intlConnect(mapStateToProps, mapDispatchToProps)(SnapSideNav));
