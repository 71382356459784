import { attachmentSchema } from '../schema/attachmentSchema';

import { CALL_API } from 'redux/middleware/apiMiddleware';
import * as proxyAPI from 'utils/apis/proxyAPI';
import { assertArg } from 'utils/assertionUtils';

export const LOAD_ATTACHMENT = 'attachments/LOAD_ATTACHMENT';

export const loadAttachment = ({ businessProfileId, attachmentId, attachmentType, fileType }: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(attachmentId).is.numeric();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(attachmentType).is.string();

  return {
    type: LOAD_ATTACHMENT,
    params: { attachmentId },
    meta: {
      [CALL_API]: {
        endpoint: proxyAPI.attachment.existing({
          businessProfileId,
          attachmentId,
          attachmentType,
          fileType,
        }),
      },
      schema: attachmentSchema,
    },
  };
};

export const loadAttachments = (attachments: any) => (dispatch: any) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(attachments).is.array();

  const promises = attachments.map((attachment: any) =>
    dispatch(
      loadAttachment({
        businessProfileId: attachment.businessProfileId,
        attachmentId: attachment.id,
        attachmentType: attachment.type,
        fileType: attachment.fileType,
      })
    )
  );

  return Promise.all(promises);
};
