import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import * as showsActions from 'state/shows/actions/showsActions';

import { pickKeysFinalizer, pickKeysPreprocessor } from 'redux/middleware/requestProcessing';
import * as scsAPI from 'utils/apis/scsAPI';

import type { BusinessProfileID, PublisherID } from 'types/publishers';
import type { GetState, Dispatch } from 'types/redux';
import { ExtraProperties } from 'types/shows';
import type { Extra } from 'types/shows';

export const CREATE_EXTRA = 'extras/CREATE_EXTRA';
export const UNLINK_EXTRA = 'extras/UNLINK_EXTRA';

export const createExtra = (
  publisherId: PublisherID,
  businessProfileId: BusinessProfileID,
  seasonNumber: number,
  seasonDisplayName: string | undefined | null,
  extra: Extra
) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(
      createCallAction(
        {
          type: CREATE_EXTRA,
          params: {
            publisherId,
          },
        },
        {
          method: 'POST',
          endpoint: scsAPI.shows.createExtra({ publisherId, seasonNumber, seasonDisplayName }),
          body: extra,
          preprocessor: pickKeysPreprocessor(Object.values(ExtraProperties)),
          finalizer: pickKeysFinalizer(Object.keys(extra)),
        }
      )
    );

    return dispatch(showsActions.loadShows(publisherId, businessProfileId));
  };
};

export const unlinkExtra = (publisherId: PublisherID, businessProfileId: BusinessProfileID, extra: Extra) => {
  return async (dispatch: Dispatch, getState: GetState) => {
    await dispatch(
      createCallAction(
        {
          type: UNLINK_EXTRA,
          params: {
            publisherId,
            extraId: extra.id,
          },
        },
        {
          method: 'DELETE',
          endpoint: scsAPI.shows.unlinkExtra({ publisherId }),
          body: extra,
          preprocessor: pickKeysPreprocessor(Object.values(ExtraProperties)),
          finalizer: pickKeysFinalizer(Object.keys(extra)),
        }
      )
    );

    return dispatch(showsActions.loadShows(publisherId, businessProfileId));
  };
};
