import { SnapType } from 'types/snaps';
import type { LongformVideoSnap } from 'types/snaps';
import type { Stage } from 'types/stages';

type LongformVideoStage = Stage<LongformVideoSnap, Partial<LongformVideoSnap>, void>;

export const LONGFORM_VIDEO_STAGE_CONFIG: LongformVideoStage = {
  stageName: SnapType.LONGFORM_VIDEO,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: ['longformVideoAssetId', 'manifestUrl'],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => properties,
};
