import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

const MAX_LOCATION_HISTORY = 5;

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

// These reducers are related to the router but the 'router' substate is owned by another module.
// So we have 'extraRouter' selectors and reducers to avoid polluting state we don't own.

function addLocation(state: any, action: any) {
  const paths = state.paths || [];
  const newPaths = [action.payload.location.pathname, ...paths.slice(0, MAX_LOCATION_HISTORY - 1)];
  return u(
    {
      paths: newPaths,
    },
    state
  );
}

const locationHistory = (state = {}, action: any) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return addLocation(state, action);
    default:
      return state;
  }
};

export default combineReducers({
  locationHistory,
});
