import type { Dispatch } from 'redux';

import { createCallAction } from 'state/apiMiddleware/actions/apiMiddlewareActions';
import { TagMap } from 'state/autocomplete/autocompleteState';
import * as autocompleteSelectors from 'state/autocomplete/selectors/autocompleteSelectors';

import { optimisticJsonFinalizer } from 'redux/middleware/requestProcessing';
import { autocomplete } from 'utils/apis/autocomplete';
import { assertArg } from 'utils/assertionUtils';

import type { GetState } from 'types/redux';

export const GET_AUTOCOMPLETE_WIKI_SUGGESTIONS = 'snapAdmin/GET_AUTOCOMPLETE_WIKI_SUGGESTIONS';
export const GET_AUTOCOMPLETE_SCC_SUGGESTIONS = 'snapAdmin/GET_AUTOCOMPLETE_SCC_SUGGESTIONS';

export const autocompleteWikiTagSearch = (search: string): ((b: Dispatch, a: GetState) => Promise<unknown>) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(search).is.string();

  return (dispatch: Dispatch, getState: GetState): Promise<unknown> => {
    const action = createCallAction(
      {
        type: GET_AUTOCOMPLETE_WIKI_SUGGESTIONS,
        params: { search },
      },
      {
        endpoint: autocomplete.wikipedia({ search }),
        finalizer: optimisticJsonFinalizer,
      }
    );
    // @ts-expect-error ts-migrate(2739) FIXME: Type 'CallAction<{ type: string; params: { search:... Remove this comment to see the full error message
    return dispatch(action);
  };
};

export const autocompleteSCCGetTags = () => (dispatch: Dispatch, getState: GetState) => {
  const sccTags = autocompleteSelectors.getSccTags(getState());
  if (!sccTags || Object.getOwnPropertyNames(sccTags).length === 0) {
    return dispatch(
      createCallAction(
        {
          type: GET_AUTOCOMPLETE_SCC_SUGGESTIONS,
        },
        {
          endpoint: autocomplete.scc(),
          finalizer: optimisticJsonFinalizer,
        }
      )
    );
  }

  return Promise.resolve({ payload: sccTags });
};

const searchForTag = (search: string, tagMap: TagMap): string[] => {
  return Object.entries(tagMap)
    .filter(
      ([key, tag]) =>
        !tag.isDeprecated && tag.isNonSensitive && tag.displayName.toLowerCase().indexOf(search.toLowerCase()) !== -1
    )
    .map(([key, tag]) => key);
};

export const autocompleteSCCTagSearch = (search: string) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(search).is.string();

  return (dispatch: Dispatch, getState: GetState): Promise<string[]> => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(b: Dispatch<AnyAction>, a: GetS... Remove this comment to see the full error message
    return dispatch(autocompleteSCCGetTags()).then(outcome => {
      return searchForTag(search, outcome.payload);
    });
  };
};
