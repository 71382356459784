import type { LogoDisplayEnum } from 'config/constants';
import { enumObject } from 'utils/enum';

import type { EditionID } from 'types/editions';
import type { PublisherID } from 'types/publishers';

export type Episode = {
  id: EditionID;
  title: string;
  episodeNumber: number;
  seasonId: string;
};

export type Extra = {
  id: string;
  seasonId: string;
  showId: string;
};

export type Season = {
  id: string;
  displayName: string;
  seasonNumber: number;
  episodes: Episode[];
  extras: Extra[];
  lastUpdatedAt: string;
};

export type ShowID = string;

export type Show = {
  description: string;
  discoverFeedLogoUrl: string;
  id: ShowID;
  imageUrl: string;
  lastUpdatedAt: string;
  logoUrl: string;
  name: string;
  profileLogoDisplay: LogoDisplayEnum;
  seasons: Season[];
  showId: ShowID; // Shows Service ID,
  showTileImageUrl: string | undefined | null;
  stageId: number; // datastore ID,
};

export type ShowsMap = {
  [id in PublisherID]: Show[] | undefined | null;
};

export type EditableEpisodeProperties = {
  id: string;
  showId: string;
  seasonId: string | undefined | null;
  episodeNumber: number;
  title: string;
};

type EditableEpisodePropertyKey = keyof EditableEpisodeProperties;

export const EpisodeProperties: {
  [key: string]: EditableEpisodePropertyKey;
} = enumObject({
  ID: 'id',
  SHOW_ID: 'showId',
  SEASON_ID: 'seasonId',
  EPISODE_NUMBER: 'episodeNumber',
  TITLE: 'title',
});

export type EditableExtraProperties = {
  id: string;
  showId: string;
  seasonId: string;
};

type EditableExtraPropertyKey = keyof EditableExtraProperties;

export const ExtraProperties: {
  [key: string]: EditableExtraPropertyKey;
} = enumObject({
  ID: 'id',
  SHOW_ID: 'showId',
  SEASON_ID: 'seasonId',
});

export type SeasonUpdate = {
  id: string;
  displayName: string;
  seasonNumber: number;
  episodeIds: EditionID[];
};

export type IndexedEpisode = {
  index: number;
  episode: Episode;
};

type NumberOrEmptyString = number | '';

export type ShowsEditorState = {
  isSaving?: boolean;
  selectedSeasonIndex: number;
  season: Season;
  displayNames: string[];
  seasonNumbers: NumberOrEmptyString[];
  indexedEpisodes: IndexedEpisode[];
};

type ArrayIndex<T> = {
  [index: number]: T;
};

type ArrayUpdateFn<T> = (a: T[]) => T[];

export type UpdeepCompatible<T> = ArrayIndex<T> | ArrayUpdateFn<T>;

export type ShowsEditorStateUpdeep = {
  isSaving: boolean;
  selectedSeasonIndex: number;
  season: Season;
  displayNames: string[] | UpdeepCompatible<string>;
  seasonNumbers: NumberOrEmptyString[] | UpdeepCompatible<NumberOrEmptyString>;
  indexedEpisodes: IndexedEpisode[] | UpdeepCompatible<IndexedEpisode>;
};

export const NEW_SHOW_TEMP_ID = 'NEW_SHOW_TEMP_ID';
