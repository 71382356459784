import { combineReducers } from 'redux';

import {
  createEntityAgeReducer,
  createSequenceCountingByIdReducer,
  createSequenceHandlingReducer,
} from 'state/common/reducerFactories';
import { LOAD_SHOT_AD_SLOTS } from 'state/singleAssetStory/actions/singleAssetStoryActions';

import { wrapEntitiesInUpdeepConstants } from 'utils/reducerUtils';
import u from 'utils/safeUpdeep';

import { State } from 'types/rootState';

const loadingByStoryId = createSequenceCountingByIdReducer((action: any) => action.params.storyId, [
  LOAD_SHOT_AD_SLOTS,
]);

const lastUpdatedByStoryId = createEntityAgeReducer('shotAdSlots', [LOAD_SHOT_AD_SLOTS]);

const byStoryId = createSequenceHandlingReducer({}, [LOAD_SHOT_AD_SLOTS], {
  start(state: State, action: any) {
    return state;
  },
  done(state: State, action: any) {
    if (action.error) {
      return state;
    }
    return u(wrapEntitiesInUpdeepConstants(action.payload.entities.shotAdSlots), state);
  },
});

export default combineReducers({
  shotAdSlots: combineReducers({
    byStoryId,
    lastUpdatedByStoryId,
    loadingByStoryId,
  }),
});
