import { UrlType } from 'config/constants';

import { SnapType } from 'types/snaps';
import type { RemoteWebSnap } from 'types/snaps';
import type { Stage } from 'types/stages';

type RemoteWebStage = Stage<RemoteWebSnap, Partial<RemoteWebSnap>, void>;

export interface RemoteWeb {
  remoteUrl: string;
  allowExternalSharing: string;
  sharingMethod: string;
  allowedCountries: string[];
  useReferrer: boolean;
  urlType: UrlType;
}

export const REMOTE_WEB_STAGE_CONFIG: RemoteWebStage = {
  stageName: SnapType.REMOTE_WEB,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string[]' is not assignable to type 'number ... Remove this comment to see the full error message
  properties: ['remoteUrl', 'allowExternalSharing', 'sharingMethod', 'allowedCountries', 'useReferrer', 'urlType'],
  transformToStaged: (properties: {}) => properties,
  transformFromStaged: (properties: {}) => properties,
};
