import { gql } from '@apollo/client';

export const EDITIONS_PUBLISHER_FIELDS = gql`
  fragment EditionsPublisherFields on Publisher {
    id
    businessProfileId
    mutablePublisherName
    formalName
    description
    tier
    primaryColor
    secondaryColor
    tileLogos {
      mediaId
      color
    }
    tags {
      SCC
      WIKI
      MANUAL
    }
    defaultNumSnaps
    topsnapLimit
    ageGate
    targetSnapLengthMs
    advertisingEnabled
    advancedAdsEnabled
    advancedAdsMinSlots
    advancedAdsMaxSlots
    advancedAdsMinSpacing
    advancedAdsLatestFirstSlot
    defaultAdSlots
    defaultAdSlotsMillis
    dynamicAdsEnabled
    rollingNewsEnabled
    videoModeEnabled
    permitAutoPublishFromFeed
    timeUntilAutoPublishFeedStory
    urlSafeFormalName
    primaryLanguage
    defaultSubtitlesLanguage
    defaultShareOption
    enabledFeatures

    # Fields needed for complete your profile modal
    squareHeroImageBitmojiTemplateId
    profileLogoDisplay
    horizontalIconId
    horizontalIcon
    websiteUrl
    defaultFilledIconId
    squareHeroImageId
    editorialApproval
  }
`;

export const EDITIONS_GET_PUBLISHER = gql`
  ${EDITIONS_PUBLISHER_FIELDS}
  query EditionsPublisherDetailsQuery($publisherId: ID!) {
    publisher(id: $publisherId) {
      ...EditionsPublisherFields
    }
  }
`;
