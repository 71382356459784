import Tooltip from 'antd/lib/tooltip';
import type { TooltipProps as AntTooltipProps } from 'antd/lib/tooltip';
import classNames from 'classnames';
import React from 'react';
import type { ReactNode } from 'react';

import tooltipStyle from 'styles/tooltip.scss';
import { enumObject } from 'utils/enum';

type Props = {
  children: ReactNode;
  overlayClassName?: string;
  disablePointerEvents?: boolean;
} & AntTooltipProps;

// view tooltip examples here: https://publish.snap-dev.net/v2/debug/tooltips/sds
// see DebugSDSTooltips for implementation
export const TooltipPosition = enumObject({
  TOP_LEFT: 'topLeft',
  TOP: 'top',
  TOP_RIGHT: 'topRight',
  BOTTOM_LEFT: 'bottomLeft',
  BOTTOM: 'bottom',
  BOTTOM_RIGHT: 'bottomRight',
  LEFT_TOP: 'leftTop',
  LEFT: 'left',
  LEFT_BOTTOM: 'leftBottom',
  RIGHT_TOP: 'rightTop',
  RIGHT: 'right',
  RIGHT_BOTTOM: 'rightBottom',
});

// In order for the tooltip to show, there are two workaround:
// * either wrap the children in a <div>
// * or explicitly allow the child component to receive all the props - https://github.com/ant-design/ant-design/issues/15528#issuecomment-474661137
// I have gone with the first approach as it is sounds like a better long term solution
export default class SDSTooltip extends React.Component<Props> {
  render() {
    const { children, overlayClassName, ...tooltipProps } = this.props;

    const defaultStyle = classNames(tooltipStyle.tooltip, {
      // It may happen we want to show tooltip by default, but hide it when hovering over the element
      // Seems like the tooltip hover target is linked to the parent, even when not displayed directly over it
      // Disabling the pointer events, prevents the prerent onMouseEnter/Leave to fire
      [tooltipStyle.disablePointerEvents]: this.props.disablePointerEvents,
    });

    // Apply default style for all tooltips
    const style = overlayClassName || defaultStyle;
    return (
      <Tooltip {...tooltipProps} overlayClassName={style}>
        <div className={tooltipStyle.alignment}>{children}</div>
      </Tooltip>
    );
  }
}
