import _ from 'lodash';
import log from 'loglevel';

import { MAX_TILE_HEIGHT, DEFAULT_LOGO_COLOR } from 'config/constants';
import { TileCropType, TileSpecifications } from 'config/tileConfig';

/** Utility type TileShape that defines a width and a height */
export type TileShape = {
  height: number;
  width: number;
};

export type CropPositions = Partial<{ [key in TileCropType]: number }>;

/**
 * Utility function to center a TileShape inside another TileShape
 * returns an array of coordinates
 * [
 *   (int) {from the left} the left edge,
 *   (int) {from the top} the top edge,
 *   (int) {from the left} the right edge,
 *   (int) {from the top} the bottom edge,
 * ]
 */
export function centeredCoordinates(surroundDimensions: TileShape, internalDimensions: TileShape): Array<number> {
  let halfAcross = (surroundDimensions.width - internalDimensions.width) / 2;
  halfAcross = Math.round(halfAcross);

  let halfDown = (surroundDimensions.height - internalDimensions.height) / 2;
  halfDown = Math.round(halfDown);

  return [halfAcross, halfDown, halfAcross + internalDimensions.width, halfDown + internalDimensions.height];
}

/**
 * Logic that defines the initial crops using no reference other than the Vertical crop
 *
 *  This is required to initialize the base crops when we initialize crop the tile asset.
 *  With base crops I mean the crops that are strictly required to populate others
 *  see positionMissingCrops, where the main difference with that is that this function needs
 *  no references to generate crops.
 */
export function ensureBaseCropPositionsAreCentered() {
  const dims = {
    width: TileSpecifications[TileCropType.VERTICAL].width,
    height: TileSpecifications[TileCropType.VERTICAL].height,
  };

  const locationH = centeredCoordinates(dims, TileSpecifications[TileCropType.HORIZONTAL]);
  const locationC = centeredCoordinates(dims, TileSpecifications[TileCropType.COLLAPSED]);

  const cropPosition = {
    imageCropPositions: {
      [TileCropType.VERTICAL]: 0,
      [TileCropType.HORIZONTAL]: locationH[1],
      [TileCropType.COLLAPSED]: locationC[1],
    },
  };

  return { imageCropPositions: positionMissingCrops(cropPosition.imageCropPositions) };
}

/**
 * When updating or initializing a tile, we set the default tile logo and overlay color
 *  based on the default logo asset properties
 */
export function ensureTileLogoIsSet(preliminaryTile: any, defaultLogoAsset: any, defaultColor: string) {
  if (!defaultLogoAsset.id) {
    return preliminaryTile;
  }
  const tileCopy = { ...preliminaryTile };

  if (!tileCopy.logoImageAssetId) {
    tileCopy.logoImageAssetId = defaultLogoAsset.id;
  }
  if (!tileCopy.logoReadStateOverlayColor) {
    tileCopy.logoReadStateOverlayColor = defaultLogoAsset.color || defaultColor || DEFAULT_LOGO_COLOR;
  }
  return tileCopy;
}

/**
 * Required because Croppie might return dimensions that are too small (by 1 pixel IIRC)
 *
 * @param currentDimensions
 * @param requiredDimensions
 * @returns {*}
 */
export function forceDimensionsToMatchRequiredDimensions(currentDimensions: any, requiredDimensions: any) {
  if (currentDimensions.width < requiredDimensions.width || currentDimensions.height < requiredDimensions.height) {
    throw new Error('current dimensions must be greater than or equal to than required dimensions');
  }

  const resultDimensions = { ...requiredDimensions };
  if (currentDimensions.width - requiredDimensions.width < requiredDimensions.points[0]) {
    resultDimensions.points[0] = currentDimensions.width - requiredDimensions.width;
  }
  if (currentDimensions.height - requiredDimensions.height < requiredDimensions.points[1]) {
    resultDimensions.points[1] = currentDimensions.height - requiredDimensions.height;
  }
  resultDimensions.points[2] = parseInt(resultDimensions.points[0], 10) + requiredDimensions.width;
  resultDimensions.points[3] = parseInt(resultDimensions.points[1], 10) + requiredDimensions.height;

  return resultDimensions;
}

/**
 * Backwards and forwards compatibility adjustments
 */

/**
 * Crops that don't need to be initialized
 * (set in 'ensureBaseCropPositionsAreCentered')
 */
const baseCrops = [TileCropType.VERTICAL, TileCropType.HORIZONTAL, TileCropType.COLLAPSED];

/**
 * Compatibility References
 *
 * If you're filling a non existing crop ensure that the reference listed here exists
 * If unknown always fallback to vertical
 */
const positionableCropReference: { [key in TileCropType]: TileCropType } = {
  // don't add TileCropType.VERTICAL here
  [TileCropType.HORIZONTAL]: TileCropType.HORIZONTAL_V2,
  [TileCropType.COLLAPSED]: TileCropType.COLLAPSED_V2,

  [TileCropType.VERTICAL_V2]: TileCropType.VERTICAL,
  [TileCropType.HORIZONTAL_V2]: TileCropType.HORIZONTAL,
  [TileCropType.MEDIUM_V2]: TileCropType.HORIZONTAL,
  [TileCropType.COLLAPSED_V2]: TileCropType.COLLAPSED,
  [TileCropType.NARROW_V2]: TileCropType.VERTICAL,
  [TileCropType.VERTICAL]: TileCropType.VERTICAL,
  [TileCropType.CHEETAH]: TileCropType.VERTICAL,
  [TileCropType.SQUARE]: TileCropType.VERTICAL,
};

// Positioning strategies
const POSITIONING_STRATEGIES = {
  /**
   * Given a center coordinate, calculate the top coordinate of a crop
   */
  TOP_FROM_CENTER_COORDINATE_CROP: (cropKeyToPosition: TileCropType, allExistingCropPositions: CropPositions) => {
    const baseCropKey = positionableCropReference[cropKeyToPosition];

    const currentCropHeight = TileSpecifications[cropKeyToPosition].height;

    // to get the top, offset the crop by half the height
    const offset = Math.round(currentCropHeight / 2);

    // calculate the coordinate, and make sure the minimum we allow is 0;
    const coordinate = Math.max((allExistingCropPositions[baseCropKey] || 0) - offset, 0);

    // make sure we don't collide with the bottom edge of the base image
    return Math.min(coordinate, MAX_TILE_HEIGHT - currentCropHeight);
  },

  /**
   * Given a top coordinate, calculate the center coordinate of a crop
   */
  CENTERED_FROM_TOP_COORDINATE_CROP: (cropKeyToPosition: TileCropType, allExistingCropPositions: CropPositions) => {
    const baseCropKey = positionableCropReference[cropKeyToPosition];
    const baseCropHeight = TileSpecifications[baseCropKey].height;

    const currentCropHeight = TileSpecifications[cropKeyToPosition].height;
    //             (pixels between the both crop top coordinates)       + (half the height of the current crop)
    const offset = Math.floor((baseCropHeight - currentCropHeight) / 2 + currentCropHeight / 2);
    let coordinate = Math.max((allExistingCropPositions[baseCropKey] || 0) + offset, 0);

    // set the minimum allowable position if it goes below 0
    if (coordinate - currentCropHeight / 2 < 0) {
      coordinate = Math.ceil(currentCropHeight / 2);
    }

    // set the maximum allowable position if it goes over MAX_TILE_HEIGHT
    if (coordinate + currentCropHeight / 2 > MAX_TILE_HEIGHT) {
      coordinate = Math.floor(MAX_TILE_HEIGHT - currentCropHeight / 2);
    }

    return coordinate;
  },

  // always position the crop at the top of the image
  ALWAYS_ZERO: () => 0,
};

// How to position each crop
//  (if WRITE permission is not present for that tile version)
const cropsToFillUsingAPositioningStrategy: {
  [key in TileCropType]: ((cropKeyToPosition: TileCropType, allExistingCropPositions: CropPositions) => number) | null;
} = {
  [TileCropType.HORIZONTAL]: POSITIONING_STRATEGIES.TOP_FROM_CENTER_COORDINATE_CROP,
  [TileCropType.COLLAPSED]: POSITIONING_STRATEGIES.TOP_FROM_CENTER_COORDINATE_CROP,

  [TileCropType.VERTICAL_V2]: POSITIONING_STRATEGIES.CENTERED_FROM_TOP_COORDINATE_CROP,
  [TileCropType.HORIZONTAL_V2]: POSITIONING_STRATEGIES.CENTERED_FROM_TOP_COORDINATE_CROP,
  [TileCropType.MEDIUM_V2]: POSITIONING_STRATEGIES.CENTERED_FROM_TOP_COORDINATE_CROP,
  [TileCropType.COLLAPSED_V2]: POSITIONING_STRATEGIES.CENTERED_FROM_TOP_COORDINATE_CROP,
  [TileCropType.NARROW_V2]: POSITIONING_STRATEGIES.ALWAYS_ZERO,
  [TileCropType.VERTICAL]: null,
  [TileCropType.CHEETAH]: null,
  [TileCropType.SQUARE]: null,
};

/**
 * Return the mutated cropPositions object with altered positions of the calculated crops
 */
export function positionMissingCrops(cropPositions: CropPositions = {}) {
  const newCropPositions: CropPositions = {};

  // only remove the base crops so that we don't overwrite them when setting the vertical crop
  //  these crops should have been initialized in 'ensureBaseCropPositionsAreCentered'
  const positionableCrops = (Object.keys(positionableCropReference) as TileCropType[]).filter(cropType => {
    return !baseCrops.includes(cropType);
  });

  positionableCrops.forEach(key => {
    if (!cropsToFillUsingAPositioningStrategy[key]) {
      return;
    }
    if (typeof cropPositions[key] !== 'undefined') {
      return;
    }

    const positioningStrategy = cropsToFillUsingAPositioningStrategy[key];
    const baseCropKey = positionableCropReference[key];
    if (!(baseCropKey in TileSpecifications) || !(key in TileSpecifications) || !(baseCropKey in cropPositions)) {
      const context = { cropKey: key, baseCropKey, cropPositions };
      log.error(`error positioning missing crops: missing crop parameter. ${JSON.stringify(context)}`);
      return;
    }

    if (positioningStrategy) {
      newCropPositions[key] = positioningStrategy(key, cropPositions);
    }
  });
  return { ...cropPositions, ...newCropPositions };
}
