import is from 'is_js';
import log from 'loglevel';
import { combineReducers } from 'redux';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'upde... Remove this comment to see the full error message
import u from 'updeep';

import * as publisherStoryEditorActionIds from 'state/publisherStoryEditor/actionIds/publisherStoryEditorActionIds';

import * as mediaActions from '../../media/actions/mediaActions';
import * as articleActions from '../actions/articleActions';

import { UploadPurpose, ContentStatus, Sequence, EMPTY_OBJECT } from 'config/constants';

function tristateSequence(action: any, loading: any, success: any, errorStatus: any) {
  switch (action.sequence) {
    case Sequence.START:
    case Sequence.PROGRESS:
      return loading;

    case Sequence.DONE:
      if (action.error) {
        return errorStatus;
      }
      return success;

    default:
      throw new Error(`Unsupported sequence state: ${action.sequence}`);
  }
}

function status(state = ContentStatus.NOT_LOADED, action: any) {
  switch (action.type) {
    case articleActions.LOAD_ARTICLE:
      return tristateSequence(action, ContentStatus.LOADING, ContentStatus.LOADED_CLEAN, ContentStatus.LOAD_ERROR);

    case articleActions.NOTIFY_ARTICLE_EDITED:
      return ContentStatus.LOADED_DIRTY;

    case articleActions.SAVE_ARTICLE:
      return tristateSequence(action, ContentStatus.SAVING, ContentStatus.LOADED_CLEAN, ContentStatus.SAVE_ERROR);

    case publisherStoryEditorActionIds.ARTICLE_IMPORT:
      return tristateSequence(action, ContentStatus.SAVING, ContentStatus.LOADED_CLEAN, ContentStatus.SAVE_ERROR);

    // Handles cases where article images are rejected client-side before they hit the server
    case articleActions.PROCESS_ARTICLE_IMAGES:
      return tristateSequence(action, ContentStatus.SAVING, ContentStatus.SAVING, ContentStatus.SAVE_ERROR);

    case articleActions.PROCESS_ARTICLE_VIDEOS:
      return tristateSequence(action, ContentStatus.SAVING, ContentStatus.SAVING, ContentStatus.SAVE_ERROR);

    // Handles cases where article images are rejected by the server
    case mediaActions.UPLOAD_MEDIA:
      if (isArticleImageUploadError(action)) {
        return ContentStatus.SAVE_ERROR;
      }
      return state;

    case articleActions.UNLOAD_ARTICLE:
      return ContentStatus.NOT_LOADED;

    default:
      return state;
  }
}

function isArticleImageUploadError(action: any) {
  return action.error && action.payload && action.payload.purpose === UploadPurpose.ARTICLE_IMAGE;
}

export const ErrorPhase = {
  LOADING: 'LOADING',
  PROCESSING_IMAGES: 'PROCESSING_IMAGES',
  UPLOADING_IMAGES: 'UPLOADING_IMAGES',
  SAVING: 'SAVING',
};

const ERROR_PHASE_MAP = {
  [articleActions.LOAD_ARTICLE]: ErrorPhase.LOADING,
  [articleActions.SAVE_ARTICLE]: ErrorPhase.SAVING,
  [articleActions.PROCESS_ARTICLE_IMAGES]: ErrorPhase.PROCESSING_IMAGES,
  [mediaActions.UPLOAD_MEDIA]: ErrorPhase.UPLOADING_IMAGES,
};

function error(state = null, action: any) {
  switch (action.type) {
    case articleActions.LOAD_ARTICLE:
    case articleActions.SAVE_ARTICLE:
      if (action.error) {
        return formatError(action.type, action.error);
      }
      return null;

    case articleActions.PROCESS_ARTICLE_IMAGES:
      if (action.error) {
        return formatError(action.type, action.error);
      }
      return null;
    case articleActions.PROCESS_ARTICLE_VIDEOS:
      if (action.error) {
        return formatError(action.type, action.error);
      }
      return null;
    case mediaActions.UPLOAD_MEDIA:
      if (isArticleImageUploadError(action)) {
        return formatError(action.type, action.error);
      }
      return null;

    case articleActions.UNLOAD_ARTICLE:
      return null;

    default:
      return state;
  }
}

export function formatError(actionType: any, err: any) {
  let message;

  if (err) {
    if (is.object(err)) {
      message = err.message ? err.message : err.toString();
    } else if (is.string(err)) {
      message = err;
    }
  }

  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const phase = ERROR_PHASE_MAP[actionType];

  if (!phase) {
    log.warn(`Could not determine error phase for action type ${actionType}`);
  }

  return {
    phase,
    message,
  };
}

function fields(state = {}, action: any) {
  switch (action.type) {
    case articleActions.LOAD_ARTICLE:
      if (action.sequence === Sequence.DONE && !action.error) {
        return u(action.payload, state);
      }
      return state;

    case articleActions.NOTIFY_ARTICLE_EDITED:
    case articleActions.NOTIFY_ARTICLE_SAVED:
      return { ...action.payload.fields };

    case articleActions.UNLOAD_ARTICLE:
      return is.empty(state) ? state : EMPTY_OBJECT;

    default:
      return state;
  }
}

export default combineReducers({
  status,
  error,
  fields,
});
