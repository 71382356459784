import invariant from 'invariant';
import is from 'is_js';
import { compact, concat, flatten, forOwn, get, groupBy, pick, pickBy, values } from 'lodash';
import moment from 'moment-timezone';
import { arrayOf, normalize } from 'normalizr';
import { createSelector as s } from 'reselect';

import * as buildStatusSelectors from 'state/buildStatus/selectors/buildStatusSelectors';
import {
  createDynamicKeySelector,
  createEditionByIdSelector,
  createEditionsSelector,
  createKeySelector,
  normalizeEmpty,
  reselectById,
} from 'state/common/selectorFactories';
import * as editionEntityHelpers from 'state/editions/schema/editionEntityHelpers';
import { findSegmentForSnap, findSnapIndexInSegment } from 'state/editions/schema/editionEntityHelpers';
import { editionSchema } from 'state/editions/schema/editionsSchema';
import * as publishersSelectors from 'state/publishers/selectors/publishersSelectors';
import { getSegmentReduxId } from 'state/segments/schema/segmentEntityHelpers';
import * as segmentsSelectors from 'state/segments/selectors/segmentsSelectors';
import * as snapEntityHelpers from 'state/snaps/schema/snapEntityHelpers';
import * as snapsSelectors from 'state/snaps/selectors/snapsSelectors';
import * as tilesSelectors from 'state/tiles/selectors/tilesSelectors';
import * as transactionsActions from 'state/transactions/actions/transactionsActions';
import * as userSelectors from 'state/user/selectors/userSelectors';

import type { SegmentCategorisationEligibilityEnum } from 'config/constants';
import { EMPTY_ARRAY, EMPTY_OBJECT, SegmentCategorisationEligibility, StoryStatus } from 'config/constants';
import { functionRef } from 'utils/functionUtils';
import { getMessageFromId } from 'utils/intlMessages/intlMessages';
import { getValues } from 'utils/objectUtils';

/**
 * Keep in mind that editions returned from most of these functions will not contain
 *  tile details any more unless you use getEditionById
 */
import type { SnapId } from 'types/common';
import type { Edition, EditionID, LiveEdition, NewStoryInfo, ScheduledEdition, Stories } from 'types/editions';
import { StoryState } from 'types/editions';
import { Claim } from 'types/permissions';
import type { PublisherID } from 'types/publishers';
import type { State } from 'types/rootState';
import type { SegmentID, SegmentReduxID } from 'types/segments';
import type { Snap, SubscribeSnap, TopSnap } from 'types/snaps';
import type { AggregatedSnapTag } from 'types/tags';
import type { Tile } from 'types/tiles';

export type SnapIdToEditionMap = {
  [key in SnapId]: Edition;
};
export type EditionIdToEditionMap = {
  [key in EditionID]: Edition;
};
function getEditionData(state: State) {
  return state.editions || EMPTY_OBJECT;
}

export const getEditionMap = createKeySelector(getEditionData, 'byId', EMPTY_OBJECT);
export const getEditionLoadingMap = createKeySelector(getEditionData, 'loadingById', EMPTY_OBJECT);
export const getEditionSavingMap = createKeySelector(getEditionData, 'savingById', EMPTY_OBJECT);
export const getEditionErrorMap = createKeySelector(getEditionData, 'errorsById', EMPTY_OBJECT);
export const getAllEditions = createEditionsSelector(
  getEditionMap,
  functionRef(tilesSelectors, 'getTileMap'),
  segmentsSelectors.getSegmentMap
);
export const getEditionById = createEditionByIdSelector(
  getEditionMap,
  functionRef(tilesSelectors, 'getTileMap'),
  segmentsSelectors.getSegmentMap
);
export const getNormalizedEditionById = createDynamicKeySelector<Edition | undefined | null, EditionID>(
  getEditionMap,
  null
);
export const getEditionLoadingById = createDynamicKeySelector<number, EditionID>(getEditionLoadingMap, 0);
export const getEditionSavingById = createDynamicKeySelector<number, EditionID>(getEditionSavingMap, 0);
export const getEditionErrorById = createDynamicKeySelector<string | undefined | null, EditionID>(
  getEditionErrorMap,
  null
);
export const getLastUpdatedMap = createKeySelector(getEditionData, 'lastUpdatedById', EMPTY_OBJECT);
export const getLastUpdatedById = createDynamicKeySelector<number | undefined | null, EditionID>(
  getLastUpdatedMap,
  null
);
transactionsActions.registerEntityTransaction(editionSchema.getKey(), getEditionById, (entityArray: any) =>
  normalize(entityArray, arrayOf(editionSchema))
);
export const editionShouldBeLoaded = s(
  getEditionById,
  getEditionLoadingById,
  (getEditionByIdFn, getEditionLoadingByIdFn) => (id: any) => {
    const isLoading = getEditionLoadingByIdFn(id);
    if (isLoading) {
      return false;
    }
    const edition = getEditionByIdFn(id);
    return is.not.object(edition);
  }
);
// Default to read only until told otherwise
const DEFAULT_READ_ONLY_STATE = true;
export const editionIsReadOnly = s(
  getEditionById,
  functionRef(userSelectors, 'getActivePublisherId'),
  functionRef(userSelectors, 'hasClaimForPublisher'),
  (getEditionByIdFn, activePublisherId, hasClaimForPublisher) => (editionId: any, publisherId = activePublisherId) => {
    const edition = getEditionByIdFn(editionId);
    if (!edition) {
      return DEFAULT_READ_ONLY_STATE;
    }
    return (
      editionEntityHelpers.isReadOnly(edition) ||
      !(hasClaimForPublisher as any)(publisherId, Claim.STORY_CONTENT_EDITOR) ||
      editionEntityHelpers.isScheduled(edition)
    );
  }
);
export const getEditionLastSnap = reselectById<TopSnap | undefined | null, EditionID>(
  null,
  (state: State, editionId: EditionID) => getEditionById(state)(editionId),
  (state: State) => snapsSelectors.getSnapById(state),
  (edition: any, getSnapByIdFn: any) => {
    if (!edition || !edition.snapIds) {
      return null;
    }
    if (edition.snapIds.length < 1) {
      return null;
    }
    const lastSnapId = edition.snapIds[edition.snapIds.length - 1];
    const lastSnap = getSnapByIdFn(lastSnapId);
    invariant(lastSnap, 'could not find last snap');
    return lastSnap;
  }
);
export const getSnapAtIndex = reselectById<(a: number) => TopSnap | undefined | null, EditionID>(
  (_: number) => null,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  (state: any) => snapsSelectors.getSnapById(state),
  (edition: any, getSnapByIdFn: any) => {
    return (snapIndex: number): Snap | undefined | null => {
      if (!edition || !edition.snapIds) {
        return null;
      }
      if (edition.snapIds.length <= snapIndex) {
        return null;
      }
      const snapIdAtIndex = edition.snapIds[snapIndex];
      const snapAtIndex = getSnapByIdFn(snapIdAtIndex);
      invariant(snapAtIndex, `could not find snap at index: ${snapIndex}`);
      return snapAtIndex;
    };
  }
);
export const getEditionSubscribeSnap = reselectById<SubscribeSnap | undefined | null, EditionID>(
  null,
  (state: any, editionId: any) => getEditionLastSnap(state)(editionId),
  (lastSnap: any) => {
    if (!lastSnap) {
      return null;
    }
    if (!snapEntityHelpers.isSubscribeSnap(lastSnap)) {
      return null;
    }
    return snapEntityHelpers.getNextBottomSnap(lastSnap);
  }
);

export const getDefaultSubscriptionMessage = s(
  publishersSelectors.getPrimaryLanguageMessage,
  async getPrimaryLanguageMessageFn => getPrimaryLanguageMessageFn(getMessageFromId('end-snap-default-label-2'))
);

export const editionSubscriptionText = reselectById<string | undefined | null, EditionID>(
  null,
  (state: State, editionId: EditionID) => getEditionSubscribeSnap(state)(editionId),
  getDefaultSubscriptionMessage,
  async (subscribeSnap: SubscribeSnap, defaultMessagePromise: Promise<string>) => {
    const defaultMessage = await defaultMessagePromise;
    return (subscribeSnap && subscribeSnap.subscriptionText) || defaultMessage;
  }
);
export const getEditionHasSubscribeSnap = (state: State) => (edition: Edition) => {
  if (edition.snapIds.length < 1) {
    return false;
  }
  const lastSnapId = edition.snapIds[edition.snapIds.length - 1];
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'SnapId | undefined' is not assig... Remove this comment to see the full error message
  const lastSnap = snapsSelectors.getSnapById(state)(lastSnapId);
  invariant(lastSnap, 'could not find last snap');
  return snapEntityHelpers.isSubscribeSnap(lastSnap);
};
export const getTilesByEditionId = reselectById<Tile[] | undefined | null, EditionID | string>(
  null,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  (edition: any) => {
    if (!edition) {
      return null;
    }
    return edition.tiles || null;
  }
);
export const getSnapAndSegmentTilesByEditionId = reselectById<Tile[], EditionID>(
  EMPTY_ARRAY,
  (state: any, editionId: any) => getEditionById(state)(editionId),
  functionRef(snapsSelectors, 'getTilesByWholeSnapId'),
  functionRef(segmentsSelectors, 'getTilesBySegmentId'),
  (edition: any, getTilesByWholeSnapIdFn: any, getTilesBySegmentIdFn: any): Tile[] => {
    if (!edition) {
      return [];
    }
    const snapTiles = flatten(edition.snapIds.map((snapId: any) => getTilesByWholeSnapIdFn(snapId)));
    const segmentTiles = flatten(edition.segments.map((segmentId: any) => getTilesBySegmentIdFn(segmentId)));
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown[]' is not assignable to type 'Tile[]... Remove this comment to see the full error message
    return compact(concat(snapTiles, segmentTiles));
  }
);
// only returns tiles that will be sent downstream - ignores potentially incomplete tiles on snaps moved to segments
export const getVendingTilesByEditionId = reselectById<Tile[], EditionID>(
  [],
  (state: State, editionId: EditionID) => getEditionById(state)(editionId),
  functionRef(snapsSelectors, 'getTilesByWholeSnapId'),
  functionRef(segmentsSelectors, 'getTilesBySegmentId'),
  (
    edition: Edition,
    getTilesByWholeSnapIdFn: (snapId: SnapId) => Tile[],
    getTilesBySegmentIdFn: (segment: SegmentID) => Tile[]
  ): Tile[] => {
    if (!edition) {
      return [];
    }
    return flatten(
      edition.snapIds.map(snapId => {
        const segment = findSegmentForSnap(edition, snapId);
        if (segment) {
          if (findSnapIndexInSegment(segment, snapId) === 0) {
            return getTilesBySegmentIdFn(getSegmentReduxId(segment)) || [];
          }
          return [];
        }
        return getTilesByWholeSnapIdFn(snapId) || [];
      })
    );
  }
);
export const getAggregatedTagsByEditionId = s(
  getEditionById,
  functionRef(snapsSelectors, 'getAggregatedTagsBySnapIds'),
  (getEditionByIdFn, getAggregatedTagsBySnapIdsFn) => (editionId: any): AggregatedSnapTag[] => {
    const edition = getEditionByIdFn(editionId);
    if (!edition) {
      return [];
    }
    return (getAggregatedTagsBySnapIdsFn as any)(edition.snapIds);
  }
);
export const getFirstSnapIdForEditionWithId = s(getEditionById, getEditionByIdFn => (editionId: any) => {
  const edition = getEditionByIdFn(editionId);
  if (!edition || !get(edition, ['snapIds', '0'], null)) {
    return null;
  }
  return edition.snapIds[0];
});
const getPublisherActiveEditionList = reselectById(
  [],
  (state: any, publisherId: any) => publishersSelectors.getPublisherActiveEditionsById(state)(publisherId),
  getAllEditions,
  (activeStories: any, allEditions: any) => {
    return normalizeEmpty(activeStories.map((storyID: any) => allEditions[storyID]).filter(is.object));
  }
);
const getPublisherActiveEditionListGroupedByState = reselectById(
  {},
  (state: any, publisherId: any) => getPublisherActiveEditionList(state)(publisherId),
  (editionList: any) => {
    return groupBy(editionList, edition => edition.state);
  }
);
export const creatingActivePublisherStorySelectorMultiple = (listOfStates: any) => {
  const getListForStates = reselectById(
    [],
    (state: any, publisherId: any) => getPublisherActiveEditionListGroupedByState(state)(publisherId),
    (groupedActiveEditions: any) => {
      return normalizeEmpty(
        flatten(values(pick(groupedActiveEditions, listOfStates)))
          // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
          .sort((a, b) => moment(b.createdAt) - moment(a.createdAt))
      );
    }
  );
  return s(userSelectors.getActivePublisherId, getListForStates, (activePublisherId, getListForStatesFn) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    return normalizeEmpty(getListForStatesFn(activePublisherId)) || EMPTY_ARRAY;
  });
};
export const createActivePublisherStorySelectorByState = (state: any) =>
  creatingActivePublisherStorySelectorMultiple([state]);
export const getActivePublisherDraftStoryList: (a: State) => Stories = creatingActivePublisherStorySelectorMultiple([
  StoryState.NEW,
  StoryState.IN_PROGRESS,
  StoryState.READY,
]);
export const getActivePublisherScheduledStoryList: (a: State) => Stories = createActivePublisherStorySelectorByState(
  StoryState.SCHEDULED
);
export const getActivePublisherLiveStories: (a: State) => Stories = createActivePublisherStorySelectorByState(
  StoryState.LIVE
);
export const getAllStoriesFromActivePublisher = s(
  creatingActivePublisherStorySelectorMultiple(Object.values(StoryState)),
  activeStories => [...activeStories]
);
export const getStoriesForPublisherId = s(
  getAllEditions,
  (allEditions: EditionIdToEditionMap) => (publisherId: PublisherID) => {
    const editions: Array<Edition> = getValues(allEditions);
    return editions.filter(edition => edition.publisherId === publisherId);
  }
);
export const getEditableStoriesLabelValuePairs = s(
  getAllStoriesFromActivePublisher,
  editionIsReadOnly,
  (allEditions, editionIsReadOnlyFn) => {
    const filteredStories = pickBy(allEditions, (edition: Edition) => {
      return !editionIsReadOnlyFn(edition.id);
    });
    return Object.keys(filteredStories).map(key => ({
      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      label: filteredStories[key].title,
      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
      value: filteredStories[key].id,
    }));
  }
);
export const getLiveAndScheduledStories: (
  a: State
) => (a: PublisherID) => (LiveEdition | ScheduledEdition)[] = reselectById(
  [],
  (state: State, publisherId: PublisherID) => getStoriesForPublisherId(state)(publisherId),
  (stories: Edition[]) =>
    stories.filter(edition => edition.state === StoryState.SCHEDULED || edition.state === StoryState.LIVE)
);

export const getStoryScheduleStatus = s(
  functionRef(buildStatusSelectors, 'editionScheduleStatus'),
  getEditionSavingById,
  (editionStatusFn, editionSavingByIdFn) => (editionId: any) => {
    if (editionSavingByIdFn(editionId)) {
      return StoryStatus.SAVING;
    }
    return (editionStatusFn as any)(editionId);
  }
);
const getEditionBySnapIdMap = s(
  getAllEditions,
  (editions: EditionIdToEditionMap): SnapIdToEditionMap => {
    const snapIdToEdition = {};
    forOwn(editions, edition => {
      if (!edition || !edition.snapIds) {
        return;
      }
      edition.snapIds.forEach(snapId => {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        snapIdToEdition[snapId] = edition;
      });
    });
    return snapIdToEdition;
  }
);
export const getEditionBySnapId = reselectById<Edition | undefined | null, SnapId>(
  null,
  getEditionBySnapIdMap,
  (state: any, snapId: any) => snapId,
  (editionBySnapId: SnapIdToEditionMap, snapId: SnapId) => {
    return editionBySnapId[snapId] || null;
  }
);
export const getSegmentCategorisationEligibility = reselectById<
  SegmentCategorisationEligibilityEnum | undefined | null,
  SegmentID
>(
  null,
  (state: any, segmentId: any) => segmentsSelectors.getSegmentById(state)(segmentId),
  (segment: any) => {
    if (!segment || !segment.standAloneStatus) {
      return SegmentCategorisationEligibility.DISABLED;
    }
    return segment.standAloneStatus;
  }
);
export const isSegmentEligibleForCategorisation = (state: State) => (segmentId?: SegmentReduxID | null) => {
  if (!segmentId) {
    return false;
  }
  return exports.getSegmentCategorisationEligibility(state)(segmentId) === SegmentCategorisationEligibility.ELIGIBLE;
};

export const getNewStoryInfo: (state: State) => NewStoryInfo | undefined | null = createKeySelector(
  getEditionData,
  'newStoryInfo',
  null
);
