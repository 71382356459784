import { noop } from 'lodash';

export function loadWasm<T>(
  load: () => Promise<T>,
  timeout: number
): {
  loadPromise: Promise<T>;
  triggerLoad: () => void;
} {
  if (typeof window === 'undefined') {
    return { loadPromise: Promise.reject(), triggerLoad: noop };
  }
  // Loading WASM after a delay to avoid blocking other network requests on load
  const delayedPromise = new Promise<void>(resolve => {
    setTimeout(() => resolve(), timeout);
  });

  // Providing a function to load wasm on request
  let triggerLoad: () => void = noop;
  const triggerPromise = new Promise<void>(resolve => {
    triggerLoad = resolve;
  });

  // Loads wasm library on the first occurrence of 1) delay time passed or 2) user triggered
  const loadPromise: Promise<T> = Promise.race<void>([delayedPromise, triggerPromise]).then(load);

  return { loadPromise, triggerLoad };
}
