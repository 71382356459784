import { get } from 'lodash';

import { assertArg } from 'utils/assertionUtils';

export function isCircularVideo(media: any) {
  if (!media) {
    return false;
  }
  // This is our best guess at the file being a circular video if the width and height are the same
  const width = get(media, 'width');
  const height = get(media, 'height');
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(width).is.number();
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  assertArg(height).is.number();

  return width === height;
}
